import React from 'react'
import UserStoriesCreate from './UserStoriesCreate';



class UserModal extends React.Component {
    render() {
        return (
            <div className="task-modal">
                <button type="button"  className="btn btn-sm btn-primary" data-toggle="modal" data-target="#myModal2">
                    U
                </button>


                <div className="modal  fade" id="myModal2">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">UserStory</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-body p-0">
                            <UserStoriesCreate/>
                            </div>
                            {/* <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div> */}
                            <div className="modal-footer pull-right">
                                <button type="button" className="btn btn-success" data-dismiss="modal">Save</button>
                              {/* <span><Alert/></span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserModal;

