import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";

import Table from "../../_common/Table";
import Pagination from "../../_common/Pagination";
import { paginate } from "../../../utils/paginate";
import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";
import {
  getVenAccounts,
  createVenAccount,
  saveVenAccount,
  deleteVenAccount,
} from "../../../services/requestV1/AccountServices";
class AccountsVenForm extends FormLib {
  state = {
    data: {
      ACCOUNT_ID: "",
      VEN_CODE: "",
      NAME: "",
      ACCOUNT_TYPE: "",
      ACCOUNT_NO: "",
      IFSC: "",
      BANK: "",
      BRANCH: "",
    },
    errors: {},
  };

  schema = {
    ACCOUNT_ID: Joi.optional().label("Account Id"),
    VEN_CODE: Joi.required().label("Vendor Code"),
    ACCOUNT_TYPE: Joi.string().required().label("account type"),
    ACCOUNT_NO: Joi.required().label("Account No"),
    NAME: Joi.string().required().label("Name"),
    IFSC: Joi.string().required().label("IFSC"),
    BANK: Joi.string().required().label("Bank"),
    BRANCH: Joi.optional().label("Branch"),
  };
  componentDidMount() {
    // console.log(JSON.stringify(this.props.curVenAccounts));
    this.loadCurAccount(this.props.curVenAccounts);
  }

  loadCurAccount = (a) => {
    if (a.ACCOUNT_ID && a.ACCOUNT_ID !== "") {
      delete a["CREATED_AT"];
      delete a["CREATED_BY"];
      delete a["UPDATED_AT"];
      delete a["UPDATED_BY"];
      this.setState({ data: a });
    } else {
      this.resetData();
    }
  };

  async loadVenAccounts(pageNo) {
    this.setState({ loading: true });
    const res = await getVenAccounts(pageNo);

    if (res.data.status === 1) {
      this.setState({ venAccounts: res.data.data });
      let pageLinks = res.data.pageLinks;
      pageLinks["itemsCount"] = pageLinks.count;
      this.setState({ pageLinks: pageLinks });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createVenAccount(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveVenAccount(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "delete") {
      res = await deleteVenAccount(this.state.data);
      this.resetData();
    }
    if (res.data.status === 1) {
      toast.toastSuccess(res.data.msg);
      this.props.onDataChange();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  resetData() {
    this.setState({
      data: {
        ACCOUNT_ID: "",
        VEN_CODE: "",
        NAME: "",
        ACCOUNT_TYPE: "",
        ACCOUNT_NO: "",
        IFSC: "",
        BANK: "",
        BRANCH: "",
      },
    });
  }
  actVal = [
    { sortKey: "SB Account", sort: "SB Account" },
    { sortKey: "Current", sort: "Current" },
  ];
  actConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    return (
      <React.Fragment>
        <form>
          <div className="form-row">
            {this.rTBH("Account Id", "ACCOUNT_ID", "col-1")}

            {this.rTB("Vender Code", "VEN_CODE", "col-2")}
            {this.rTB("Name", "NAME", "col-6")}
            <div className="col-4">
              {this.rRBL("Account Type", "ACCOUNT_TYPE", this.actVal, {
                value: "sortKey",
                label: "sort",
              })}
            </div>

            {this.rTB("AccountNo", "ACCOUNT_NO", "col-6")}
            {this.rTB("IFSC", "IFSC", "col-6")}
            {this.rTB("Bank", "BANK", "col-4")}
            {this.rTB("Branch", "BRANCH", "col-4")}
          </div>
        </form>
        {this.state.data.ACCOUNT_ID === "" && this.rBtnSP("Create", "create")}
        {this.state.data.ACCOUNT_ID !== "" && (
          <>
            {this.rBtnSS("Edit", "edit")}
            {this.rBtnSD("Delete", "delete")}
          </>
        )}
      </React.Fragment>
    );
  }
}
export default AccountsVenForm;
