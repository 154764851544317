import React, { useState } from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";


import { getFlows } from './../../../services/coreItAdmin/flowsService';
import {
    getBrandsInfo,
    getMasterData,
    getRetailersInfo,
    uploadExcelTicketData,
} from "../../../services/Tickets/TicketServices";
import {
    getProductsInfo,
    getModelsInfo,
} from "./../../../services/Tickets/TicketServices";
import { connect } from 'react-redux';
import {
    getTicketsList,
} from "./../../../services/Tickets/TicketServices";
import { getTicketDetails } from '../.././../services/TicketHistory/TicketHistory';


class TicketSubUploadNewModal extends FormLib {
    state = {
        data: {
            ticketSearch: "",
            ticketPriority: "",
            conComplaintNo: "",
            flowId: "",
            retailerCode: "",
            productCode: "",
            modelCode: "",
            modelName: "",
            brandCode: "",
            brandName: "",
            isAppointmentReq: "",
            isInstallaationReq: "",
            isPhysicalEval: "",
            isTechEval: "",
            isPackingReq: "",
            retailerNo: "",
            invoiceNo: "",
            orderNo: "",
            ticketDetails: "",
            specialInstructions: "",
            dateOfPurchase: "",
            identificationNo: "",
            productDesc: "",
            problemDesc: "",
            productValue: "",
            cost: "",
            isUnderWarranty: "",
            srcContactPerson: "",
            srcAdd1: "",
            srcAdd2: "",
            srcLocation: "",
            srcContact1: "",
            srcCity: "",
            srcState: "",
            srcPincode: "",
            srcType: "",
            dstContactPerson: "",
            dstAdd1: "",
            dstAdd2: "",
            dstLocation: "",
            dstContact1: "",
            dstCity: "",
            dstState: "",
            dstPincode: "",
            dstType: "",
            retailerId: "",
            retailerName: "",
            productId: "",
            productName: "",
            brandId: "",
            modelId: "",
            productId2: "",
            productCode2: "",
            productName2: "",
            brandId2: "",
            brandCode2: "",
            brandName2: "",
            modelId2: "",
            modelCode2: "",
            modelName2: ""
        },
        excelData: [],
        curTempExcelData: [],
        errors: {},
        // ticketSearch: "",
        sampleValue: 0,
        TicketProductDetails: [],
        TicketPrimarytDetails: [],
        MISData: [],
        suggestions: [],




    };

    schema = {
        parentTicket: Joi.optional().label("parentTicket"),
        flowId: Joi.required().label("flowId"),
        ticketPriority: Joi.required().label("ticketPriority"),
        conComplaintNo: Joi.required().label("conComplaintNo"),
        retailerCode: Joi.required().label("retailerCode"),
        retailerNo: Joi.optional().label("retailerNo"),
        invoiceNo: Joi.optional().label("invoiceNo"),
        orderNo: Joi.required().label("orderNo"),
        ticketDetails: Joi.optional().label("ticketDetails"),
        specialInstructions: Joi.optional().label("specialInstructions"),
        isAppointmentReq: Joi.optional().label("isAppointmentReq"),
        isInstallaationReq: Joi.optional().label("isInstallaationReq"),
        isPhysicalEval: Joi.required().label("isPhysicalEval"),
        isTechEval: Joi.required().label("isTechEval"),
        isPackingReq: Joi.optional().label("isPackingReq"),
        productCode: Joi.required().label("productCode"),
        modelCode: Joi.optional().label("modelCode"),
        modelName: Joi.optional().label("modelName"),
        brandCode: Joi.optional().label("brandCode"),
        brandName: Joi.optional().label("brandName"),
        identificationNo: Joi.required().label("identificationNo"),
        dateOfPurchase: Joi.required().label("dateOfPurchase"),
        productDesc: Joi.optional().label("productDesc"),
        problemDesc: Joi.optional().label("problemDesc"),
        productValue: Joi.optional().label("productValue"),
        cost: Joi.optional().label("cost"),
        isUnderWarranty: Joi.optional().label("isUnderWarranty"),
        srcContactPerson: Joi.required().label("srcContactPerson"),
        srcAdd1: Joi.required().label("srcAdd1"),
        srcAdd2: Joi.optional().label("srcAdd2"),
        srcLocation: Joi.optional().label("srcLocation"),
        srcContact1: Joi.required().label("srcContact1"),
        srcContact2: Joi.optional().label("srcContact2"),
        srcCity: Joi.optional().label("srcCity"),
        srcState: Joi.optional().label("srcState"),
        srcPincode: Joi.required().label("srcPincode"),
        srcLandmark: Joi.optional().label("srcLandmark"),
        srcEmailId: Joi.optional().label("srcEmailId"),
        srcEmailIdAlt: Joi.optional().label("srcEmailIdAlt"),
        srcType: Joi.optional().label("srcType"),
        dstContactPerson: Joi.required().label("dstContactPerson"),
        dstAdd1: Joi.required().label("dstAdd1"),
        dstAdd2: Joi.optional().label("dstAdd2"),
        dstLocation: Joi.optional().label("dstLocation"),
        dstContact1: Joi.required().label("dstContact1"),
        dstContact2: Joi.optional().label("dstContact2"),
        dstCity: Joi.optional().label("dstCity"),
        dstState: Joi.optional().label("dstState"),
        dstPincode: Joi.required().label("dstPincode"),
        dstLandmark: Joi.optional().label("dstLandmark"),
        dstEmailId: Joi.optional().label("dstEmailId"),
        dstEmailIdAlt: Joi.optional().label("dstEmailIdAlt"),
        dstType: Joi.optional().label("dstType"),
        retailerId: Joi.optional().label("retailerId"),
        retailerName: Joi.optional().label("retailerName"),
        productId: Joi.optional().label("productId"),
        productName: Joi.optional().label("productName"),
        brandId: Joi.optional().label("brandId"),
        modelId: Joi.optional().label("modelId"),
        productId2: Joi.optional().label("productId2"),
        productCode2: Joi.optional().label("productCode2"),
        productName2: Joi.optional().label("productName2"),
        brandId2: Joi.optional().label("brandId2"),
        brandCode2: Joi.optional().label("brandCode2"),
        brandName2: Joi.optional().label("brandName2"),
        modelId2: Joi.optional().label("modelId2"),
        modelCode2: Joi.optional().label("modelCode2"),
        modelName2: Joi.optional().label("modelName2")


    };
    componentDidMount() {
        this.resetData();
        this.loadFlows();
    }

    onTextChanged = (e) => {
        try {
            const value = e.target.value;
            let suggestions = [];

            if (value.length > 8) {
                this.getTicketsList(value);
            }

            this.setState(() => ({ suggestions, text: value, selectedTicket: "" }));

            let data = this.state.data;
            data.ref_id = value;

            this.setState({ data });
        }
        catch {
            console.log("Error in TicketSubUploadNewModal>>>>>>onTextChanged");
        }
    };

    async getTicketsList(value) {
        try {
            let suggestions = [];
            let ticketsList = await getTicketsList(value);
            if (ticketsList !== undefined) {

                this.setState({ items: ticketsList.data.data.tickets });
                suggestions = this.state.items;
                this.setState(() => ({ suggestions, text: value }));
            }
        }
        catch {
            console.log("Error in TicketSubUploadNewModal>>>>>>getTicketsList");
        }
    }
    renderSuggestions() {
        try {
            const { suggestions } = this.state;
            if (suggestions.length === 0) {
                return null;
            }
            return (
                <div className="srchList">
                    <ul>
                        {suggestions.map((item) => (
                            <li onClick={() => this.suggestionSelected(item)}>{item}</li>
                        ))}
                    </ul>
                </div>
            );
        }
        catch {
            console.log("Error in TicketSubUploadNewModal>>>>>>renderSuggestions");
        }
    }

    suggestionSelected(value) {
        try {
            let ticketlist = this.state.data;
            ticketlist["ref_id"] = value;
            this.setState(() => ({
                ticketlist,
                text: value,
                suggestions: [],
                selectedTicket: value,
            }));
            this.state.data.ticketSearch = value;

        }
        catch {
            console.log("Error in TicketSubUploadNewModal>>>>>>suggestionSelected");
        }

    }

    loadTicket = async (id, ticketSearch) => {
        try {
            // this.state.sampleValue=0;
            this.state.mode = "";
            this.setState({ loading: true });
            this.state.sampleValue = id;
            this.state.TicketProductDetails = [];
            this.state.TicketPrimarytDetails = [];
            this.state.MISData = [];
            this.state.TicketProcessId = "";
            const ticketNumber = ticketSearch;
            let res = await getTicketDetails(ticketNumber);
            if (res !== undefined && res.data.data.TicketDetails.productDetails[0] !== undefined) {

                if (res.data.success === true && res.data.data !== []) {
                    this.setState({ TicketPrimarytDetails: res.data.data.TicketDetails.primaryDetails });
                    this.setState({ TicketProductDetails: res.data.data.TicketDetails.productDetails[0] });
                    this.setState({ TicketProcessId: res.data.data.TicketDetails.productDetails[0].processId });
                    this.setState({ MISData: res.data.data.MisReportHistory });
                    // this.flowData(res.data.data.TicketDetails.primaryDetails.flowId);
                    this.getPrimaryDetails(res.data.data.TicketDetails.primaryDetails, res.data.data.TicketDetails.productDetails[0], ticketNumber);

                } else if (res.data.success === false) {
                    toast.error("No Details Available for this Ticket");
                }
            }
        }
        catch {
            console.log("Error in TicketSubUploadNewModal>>>>>>loadTicket");
        }

    };
    getPrimaryDetails = async (primaryDetails, productDetails, ticketNumber) => {
        let ticketData = {};
        ticketData.parentTicket = ticketNumber;
        ticketData.ticketPriority = primaryDetails.ticketPriority.toLowerCase();
        ticketData.flowId = primaryDetails.flowId;
        ticketData.conComplaintNo = primaryDetails.conComplaintNo;
        ticketData.retailerCode = primaryDetails.retailerCode;
        ticketData.retailerNo = primaryDetails.retailerNo;
        ticketData.invoiceNo = primaryDetails.invoiceNo;
        ticketData.orderNo = primaryDetails.orderNo;
        ticketData.ticketDetails = primaryDetails.ticketDetails;
        ticketData.specialInstructions = primaryDetails.specialInstructions;
        ticketData.isAppointmentReq = primaryDetails.isAppointmentReq;
        ticketData.isInstallaationReq = primaryDetails.isInstallaationReq;
        ticketData.isPackingReq = primaryDetails.isPackingReq;
        ticketData.isPhysicalEval = primaryDetails.isPhysicalEval;
        ticketData.isTechEval = primaryDetails.isTechEval;
        ticketData.isUnderWarranty = productDetails.isUnderWarranty;
        ticketData.productCode = productDetails.productCode;
        ticketData.modelName = productDetails.model;
        ticketData.brandName = productDetails.brand;
        ticketData.identificationNo = productDetails.identificationNo;
        ticketData.productDesc = productDetails.productDesc;
        ticketData.problemDesc = productDetails.problemDesc;
        ticketData.productValue = productDetails.productValue;
        ticketData.cost = productDetails.cost;
        ticketData.dateOfPurchase = productDetails.dateOfPurchase;
        ticketData.srcContactPerson = productDetails.dstContactPerson;
        ticketData.srcAdd1 = productDetails.dstAdd1;
        ticketData.srcAdd2 = productDetails.dstAdd2;
        ticketData.srcLocation = productDetails.dstLocation;
        ticketData.srcContact1 = productDetails.dstContact1;
        ticketData.srcContact2 = productDetails.dstContact2;
        ticketData.srcCity = productDetails.dstCity;
        ticketData.srcState = productDetails.dstState;
        ticketData.srcPincode = productDetails.dstPincode;
        ticketData.srcLandmark = productDetails.dstLandmark;
        ticketData.srcEmailId = productDetails.dstEmailId;
        ticketData.srcEmailIdAlt = productDetails.dstEmailIdAlt;
        ticketData.srcType = productDetails.dstType;

        ticketData.dstContactPerson = productDetails.srcContactPerson;
        ticketData.dstAdd1 = productDetails.srcAdd1;
        ticketData.dstAdd2 = productDetails.srcAdd2;
        ticketData.dstLocation = productDetails.srcLocation;
        ticketData.dstContact1 = productDetails.srcContact1;
        ticketData.dstContact2 = productDetails.srcContact2;
        ticketData.dstCity = productDetails.srcCity;
        ticketData.dstState = productDetails.srcState;
        ticketData.dstPincode = productDetails.srcPincode;
        ticketData.dstLandmark = productDetails.srcLandmark;
        ticketData.dstEmailId = productDetails.srcEmailId;
        ticketData.dstEmailIdAlt = productDetails.srcEmailIdAlt;
        ticketData.dstType = productDetails.srcType;
        this.setState({ data: ticketData });

    }

    async loadFlows() {
        try {
            const res = await getFlows();
            if (res !== undefined) {
                if (res.data.success === true) {
                    this.setState({ flows: res.data.data.flows });
                } else {
                    toast.toastError(res.data.msg);
                }
            }
            this.setState({ loading: false });
        }
        catch {
            console.log("Error in TicketSubUploadNewModal>>>>>>loadFlows");
        }
    }

    ticketPriority = [
        { priorityId: "high", ticketPriority: "high" },
        { priorityId: "medium", ticketPriority: "medium" },
        { priorityId: "low", ticketPriority: "low" },
    ];

    isAppointmentReq = [
        { appointmentId: "yes", isAppointmentReq: "yes" },
        { appointmentId: "no", isAppointmentReq: "no" },
    ]
    isInstallaationReq = [
        { installationId: "yes", isInstallaationReq: "yes" },
        { installationId: "no", isInstallaationReq: "no" },
    ]
    isPhysicalEval = [
        { physicalEvalId: "yes", isPhysicalEval: "yes" },
        { physicalEvalId: "no", isPhysicalEval: "no" },
    ]
    isTechEval = [
        { techEvalId: "yes", isTechEval: "yes" },
        { techEvalId: "no", isTechEval: "no" },
    ]
    isPackingReq = [
        { packingReqId: "yes", isPackingReq: "yes" },
        { packingReqId: "no", isPackingReq: "no" },
    ]
    isUnderWarranty = [
        { underWarrantyId: "yes", isUnderWarranty: "yes" },
        { underWarrantyId: "no", isUnderWarranty: "no" },
    ]


    async doSubmit() {
        try {
            let data = [];
            data.push(this.state.data);
            this.setState({
                excelData: [],
            });
            let tempExcelData = [];

            for (let r in data) {
                let retailerCode = data[r]["retailerCode"];
                let productCode = data[r]["productCode"];
                let productName = data[r]["productName"];

                let brandCode = data[r]["brandCode"];
                let modelCode = data[r]["modelCode"];
                let modelName = data[r]["modelName"];
                let brandName = data[r]["brandName"];

                let retailerInfo = JSON.parse(localStorage.getItem("retailersInfo"));
                let productInfo = JSON.parse(localStorage.getItem("productsInfo"));
                let brandInfo = JSON.parse(localStorage.getItem("brandsInfo"));
                let modelInfo = JSON.parse(localStorage.getItem("modelsInfo"));

                ////logic IN service
                let curRetailer = await getRetailersInfo(retailerInfo, retailerCode);
                let curProduct = await getProductsInfo(productInfo, productCode);
                let curBrand = await getBrandsInfo(brandInfo, brandCode);
                let curModel = await getModelsInfo(modelInfo, modelCode);

                // console.log("R value====>" + curProduct);

                if (
                    curRetailer !== "" &&
                    curProduct !== "" &&
                    curBrand !== "" &&
                    curModel !== ""
                ) {
                    if (Array.isArray(curRetailer) && curRetailer.length > 0) {
                        data[r]["retailerId"] = curRetailer[0].teamId;
                        data[r]["retailerName"] = curRetailer[0].name;
                        data[r]["retailerCode"] = curRetailer[0].code;

                    } else {
                        data[r]["retailerId"] = "";
                        data[r]["retailerCode"] = "";
                        data[r]["retailerName"] = "";
                    }

                    if (Array.isArray(curProduct) && curProduct.length > 0) {
                        data[r]["productId"] = curProduct[0].productId;
                        data[r]["productName"] = curProduct[0].product;
                    } else {
                        data[r]["productId"] = "";
                        data[r]["productCode"] = productCode;
                        data[r]["productName"] = productName;
                    }

                    if (Array.isArray(curBrand) && curBrand.length > 0) {
                        data[r]["brandId"] = curBrand[0].prodBrandId;
                        data[r]["brandName"] = brandName;
                    } else {
                        data[r]["brandId"] = brandCode;
                        data[r]["brandCode"] = brandCode;
                        data[r]["brandName"] = brandName;
                    }

                    if (Array.isArray(curModel) && curModel.length > 0) {
                        data[r]["modelId"] = curModel[0].prodModelId;
                        data[r]["modelName"] = modelName;
                    } else {
                        data[r]["modelId"] = modelCode;
                        data[r]["modelCode"] = modelCode;
                        data[r]["modelName"] = modelName;
                    }

                    let productCode2 = data[r]["productCode2"];
                    let brandCode2 = data[r]["brandCode2"];
                    let modelCode2 = data[r]["modelCode2"];

                    let curProduct2 = await getProductsInfo(productInfo, productCode2);
                    let curBrand2 = await getBrandsInfo(brandInfo, brandCode2);
                    let curModel2 = await getModelsInfo(modelInfo, modelCode2);


                    if (Array.isArray(curProduct2) && curProduct2.length > 0) {
                        data[r]["productId2"] = curProduct2[0].productId;
                        data[r]["productName2"] = curProduct2[0].product;
                    } else {
                        data[r]["productId2"] = "";
                        data[r]["productCode2"] = "";
                        data[r]["productName2"] = "";
                    }

                    if (Array.isArray(curBrand2) && curBrand2.length > 0) {
                        data[r]["brandId2"] = curBrand2[0].prodBrandId;
                        data[r]["brandName2"] = curBrand2[0].brand;
                        data[r]["brandCode"] = brandCode;
                        data[r]["brandName"] = brandName;
                    } else {
                        data[r]["brandId2"] = "";
                        data[r]["brandCode2"] = "";
                        data[r]["brandName2"] = "";
                        data[r]["brandCode"] = brandCode;
                        data[r]["brandName"] = brandName;
                    }

                    if (Array.isArray(curModel2) && curModel2.length > 0) {
                        data[r]["modelId2"] = curModel2[0].prodModelId;
                        data[r]["modelName2"] = curModel2[0].model;
                        data[r]["modelCode"] = modelCode;
                        data[r]["modelName"] = modelName;
                    } else {
                        data[r]["modelId2"] = "";
                        data[r]["modelCode2"] = "";
                        data[r]["modelName2"] = "";
                        data[r]["modelCode"] = modelCode;
                        data[r]["modelName"] = modelName;
                    }

                }
                tempExcelData.push(data[r]);
            }
            this.setState({
                excelData: tempExcelData,
            });
            this.sendStateDatatoparent(tempExcelData);
            this.resetData();
            // this.props.handleOnTicket(tempExcelData);
        }
        catch {
            console.log("Error in TicketSubUploadNewModal>>>>>>doSubmit");
        }
    }
    resetData() {
        this.setState({
            data: {
                ticketSearch: "",
                ticketPriority: "",
                conComplaintNo: "",
                flowId: "",
                parentTicket: "",
                retailerCode: "",
                productCode: "",
                modelCode: "",
                modelName: "",
                brandCode: "",
                brandName: "",
                isAppointmentReq: "",
                isInstallaationReq: "",
                isPhysicalEval: "",
                isTechEval: "",
                isPackingReq: "",
                retailerNo: "",
                invoiceNo: "",
                orderNo: "",
                ticketDetails: "",
                specialInstructions: "",
                dateOfPurchase: "",
                identificationNo: "",
                productDesc: "",
                problemDesc: "",
                productValue: "",
                cost: "",
                isUnderWarranty: "",
                srcContactPerson: "",
                srcAdd1: "",
                srcAdd2: "",
                srcLocation: "",
                srcContact1: "",
                srcCity: "",
                srcState: "",
                srcPincode: "",
                srcType: "",
                dstContactPerson: "",
                dstAdd1: "",
                dstAdd2: "",
                dstLocation: "",
                dstContact1: "",
                dstCity: "",
                dstState: "",
                dstPincode: "",
                dstType: "",
                retailerId: "",
                retailerName: "",
                productId: "",
                productName: "",
                brandId: "",
                modelId: "",
                productId2: "",
                productCode2: "",
                productName2: "",
                brandId2: "",
                brandCode2: "",
                brandName2: "",
                modelId2: "",
                modelCode2: "",
                modelName2: ""
            },
        });
    }
    sendStateDatatoparent = (data) => {
        this.props.onHandleGetFormData(data);
    }

    render() {
        // const tasks = this.props.tasks;
        // const curUserstoryId = this.props.curUserstoryId;
        const { text } = this.state;

        return (

            <div className="container-fluid  ">
                <div className="modal fade " id="mySubTicketModal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content ScrollStyleModal">
                            <div className="modal-header" style={{ backgroundColor: "#e7d9d9", borderBottom: "1px solid #a7a8a9", borderTop: "1px solid #a7a8a9", borderLeft: "1px solid rgb(167, 168, 169)", borderRight: "1px solid rgb(167, 168, 169)" }}>
                                <h4 className="modal-title">Sub Ticket Creation</h4>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body " style={{ backgroundColor: "white", borderBottom: "1px solid #a7a8a9", borderTop: "1px solid #a7a8a9", borderLeft: "1px solid rgb(167, 168, 169)", borderRight: "1px solid rgb(167, 168, 169)" }}>
                                <div className="row" style={{ marginTop: "12px" }}>
                                    <div className="form-group col-5">
                                        <input
                                            value={text}
                                            name="ref_id"
                                            onChange={this.onTextChanged}
                                            type="text"
                                            autoComplete="off"

                                        />
                                        <label htmlFor="ref_id" className="control-label">
                                            Search Ticket Number *
                                        </label>
                                        <i className="bar"></i>
                                        {this.renderSuggestions()}
                                    </div>
                                    <div className="menu col-2">
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-outline-info pull-left"
                                            title=" Search Tickets by ticket number "
                                            disabled={this.state.data.ticketSearch === ""}
                                            onClick={() => this.loadTicket(
                                                this.state.sampleValue = 1, this.state.data.ticketSearch
                                            )}
                                        >
                                            <i className={"fa fa-search"} />
                                        </button>
                                    </div>
                                </div>
                                {this.state.sampleValue === 1 && (
                                    <form>
                                        <div className="form-row" style={{ marginTop: "12px" }}>
                                            {this.rTBD("Flow *", "flowId", " col-3")}
                                            {this.rRBLD(
                                                "Ticket Priority *",
                                                "ticketPriority",
                                                this.ticketPriority,
                                                {
                                                    value: "priorityId",
                                                    label: "ticketPriority",
                                                },
                                                "col-md-4"
                                            )}


                                        </div>
                                        <div className="form-subHeader" style={{ color: "#0c0c0c", backgroundColor: "rgb(204 213 255)" }}>Primary Details</div>
                                        <div className='form-row'>
                                            {this.rTBD("Consumer Complaint No. *", "conComplaintNo", " col-3")}
                                            {this.rTBD("Reatiler Code *", "retailerCode", " col-3")}
                                            {this.rTBD("Reatiler Number ", "retailerNo", " col-3")}
                                            {this.rTBD("Invoice Number *", "invoiceNo", " col-3")}
                                            {this.rTBD("Order Number *", "orderNo", " col-3")}
                                            {this.rTBD("Ticket Details ", "ticketDetails", " col-3")}
                                            {this.rTBD("Special Instructions ", "specialInstructions", " col-3")}

                                            {this.rRBLD(
                                                "Appointment Req.",
                                                "isAppointmentReq",
                                                this.isAppointmentReq,
                                                {
                                                    value: "appointmentId",
                                                    label: "isAppointmentReq",
                                                },
                                                "col-md-3"
                                            )}
                                            {this.rRBLD(
                                                "Installation Req.",
                                                "isInstallaationReq",
                                                this.isInstallaationReq,
                                                {
                                                    value: "installationId",
                                                    label: "isInstallaationReq",
                                                },
                                                "col-md-3"
                                            )}
                                            {this.rRBLD(
                                                "PhysicalEval Req.",
                                                "isPhysicalEval",
                                                this.isPhysicalEval,
                                                {
                                                    value: "physicalEvalId",
                                                    label: "isPhysicalEval",
                                                },
                                                "col-md-3"
                                            )}
                                            {this.rRBLD(
                                                "TechEval Req.",
                                                "isTechEval",
                                                this.isTechEval,
                                                {
                                                    value: "techEvalId",
                                                    label: "isTechEval",
                                                },
                                                "col-md-3"
                                            )}
                                            {this.rRBLD(
                                                "Packing Req.",
                                                "isPackingReq",
                                                this.isPackingReq,
                                                {
                                                    value: "packingReqId",
                                                    label: "isPackingReq",
                                                },
                                                "col-md-3"
                                            )}

                                        </div>
                                        <div className="form-subHeader" style={{ color: "#131314", backgroundColor: "rgb(254 226 211)" }}>Product Details</div>
                                        <div className='form-row'>
                                            {this.rTBD("Product Code *", "productCode", " col-3")}
                                            {this.rTBD("Model Code ", "modelCode", " col-3")}
                                            {this.rTBD("Model Name ", "modelName", " col-3")}
                                            {this.rTBD("Brand Code ", "brandCode", " col-3")}
                                            {this.rTBD("Brand Name ", "brandName", " col-3")}
                                            {this.rTBD("Identification Number ", "identificationNo", " col-3")}
                                            {this.rDPD("Date of purchase *", "dateOfPurchase", "col-md-3")}
                                            {this.rTBD("Product Description ", "productDesc", " col-3")}
                                            {this.rTBD("Problem Description ", "problemDesc", " col-3")}
                                            {this.rTBD("Product Value ", "productValue", " col-3")}
                                            {this.rTBD("Cost ", "cost", " col-3")}
                                            {this.rRBLD(
                                                "isUnderWarranty.",
                                                "isUnderWarranty",
                                                this.isUnderWarranty,
                                                {
                                                    value: "underWarrantyId",
                                                    label: "isUnderWarranty",
                                                },
                                                "col-md-3"
                                            )}

                                        </div>
                                        <div className="form-subHeader" style={{ color: "#050505", backgroundColor: "#ddc4e3" }}>Source Details</div>
                                        <div className='form-row'>
                                            {this.rTBD("Contact person *", "srcContactPerson", " col-3")}
                                            {this.rTBD("Address1 *", "srcAdd1", " col-3")}
                                            {this.rTBD("Address2 ", "srcAdd2", " col-3")}
                                            {this.rTBD("Location *", "srcLocation", " col-3")}
                                            {this.rTBD("Contact1 *", "srcContact1", " col-3")}
                                            {this.rTBD("Contact2 ", "srcContact2", " col-3")}
                                            {this.rTBD("City ", "srcCity", " col-3")}
                                            {this.rTBD("State ", "srcState", " col-3")}
                                            {this.rTBD("Pincode*", "srcPincode", " col-3")}
                                            {this.rTBD("Landmark", "srcLandmark", " col-3")}
                                            {this.rTBD("EmailId ", "srcEmailId", " col-3")}
                                            {this.rTBD("EmailId Alternative ", "srcEmailIdAlt", " col-3")}
                                            {this.rTBD("Source Type ", "srcType", " col-3")}

                                        </div>
                                        <div className="form-subHeader" style={{ color: "#080808", backgroundColor: "#ffd3d3" }}>Destination Details</div>
                                        <div className='form-row'>
                                            {this.rTBD("Contact person *", "dstContactPerson", " col-3")}
                                            {this.rTBD("Address1 *", "dstAdd1", " col-3")}
                                            {this.rTBD("Address2 ", "dstAdd2", " col-3")}
                                            {this.rTBD("Location *", "dstLocation", " col-3")}
                                            {this.rTBD("Contact1 *", "dstContact1", " col-3")}
                                            {this.rTBD("Contact2 ", "dstContact2", " col-3")}
                                            {this.rTBD("City ", "dstCity", " col-3")}
                                            {this.rTBD("State ", "dstState", " col-3")}
                                            {this.rTBD("Pincode*", "dstPincode", " col-3")}
                                            {this.rTBD("Landmark", "dstLandmark", " col-3")}
                                            {this.rTBD("EmailId ", "dstEmailId", " col-3")}
                                            {this.rTBD("EmailId Alternative ", "dstEmailIdAlt", " col-3")}
                                            {this.rTBD("Destination Type ", "dstType", " col-3")}

                                        </div>
                                    </form>
                                )}
                            </div>

                            <div className="modal-footer">
                                {this.rBtnS("Create", "create")}
                                {/* <button type="button" className="close" data-dismiss="modal"
                                    onClick={() => this.doSubmit()}>
                                    Send to parent
                                </button> */}
                                {/* {this.rBtnSP("Create", "create")} */}
                                {/* {this.state.data.packMaterialId === "" && */}
                                {/* this.rBtnSP("Create", "create")} */}
                                {/* {this.state.data.packMaterialId !== "" && ( */}
                                <>
                                    {/* {this.rBtnSS("Edit", "edit")}
                                    {this.rBtnSD("Delete", "delete")} */}
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default TicketSubUploadNewModal;
