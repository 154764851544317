import React from "react";
import FormLib from "./../../../_common/FormLib";
import Table from "./../../../_common/Table";
import Joi from "joi-browser";
import toast from "./../../../../services/_toastService";
// import ReactTooltip from "react-tooltip";
import {
  getLinehaulReqCount,
  getLinehaulBySrcDst,
  createManifests,
  createManifestsAsOld,
  getLogisticsProbviderData,
} from "../../../../services/linehaul/linehaulServices";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { getHubDetails } from "../../../../services/masterLocations/hubServices";
import swal from "sweetalert";
import { getDetailsBySlNoStatus } from "../../../../services/masterLocations/barcodeServices";

export default class CreateManifest extends FormLib {
  state = {
    data: {
      destinationType: "",
      transMode: "",
      linehaulMode: "road",
      logisticsProvider: "Delhivery",
      paymentMode: "prepaid",
      shipmentNo: "",
      vehicleNo: "",
      shipmentStatus: "00Created",
      isFragile: "",
      boxLength: "",
      boxBreadth: "",
      boxHeight: "",
      boxActualWeight: "",
      boxVolumetricWeight: "",
      boxBarcode: "",
      boxBarcodeId: "",
      boxUOM: "",
      dstType: "",
      srcLocation: "",
      dstLocation: "",
      dstContactPerson: "",
      srcContactPerson: "",
      srcAdd1: "",
      dstAdd1: "",
      fileInfo: "",
    },
    selectedSrcHub: "",
    curSrcHub: [],
    curHub: [],
    errors: {},
    output: {},
    searchText: "",
    linehaulCount: [],
    linehaulList: [],
    linehaulProductList: [],
    filteredLinehaulList: [],
    myArr: {},
    moreInfo: {},
    requiredFile: [],
    prdBrcRes: true,
    transport: [
      {
        transportId: "express",
        transport: "Express",
      },
      {
        transportId: "surface",
        transport: "Surface",
      },
      // {
      //   transportId: "air",
      //   transport: "byAir",
      // },
      // {
      //   transportId: "road",
      //   transport: "byRoad",
      // },
      // {
      //   transportId: "ship",
      //   transport: "byShip",
      // },
    ],
    linehaulMode: [
      { linehaulModeId: "air", linehaulMode: "Air" },
      { linehaulModeId: "road", linehaulMode: "Road" },
    ],
    paymentModes: [
      { paymentModeId: "toPay", paymentMode: "To Pay" },
      { paymentModeId: "prepaid", paymentMode: "Prepaid" },
    ],
    // logistics:[],
    logistics: [
      {
        logisticsId: "Delhivery",
        logistics: "Delhivery",
      },
      {
        logisticsId: "Bluedart",
        logistics: "Bluedart",
      },
      {
        logisticsId: "Spoton",
        logistics: "Spoton",
      },
      {
        logisticsId: "DskCargoLogistics",
        logistics: "DSK CARGO AND LOGISTICS ( CHN )",
      },
      {
        logisticsId: "SachetExpressCargo",
        logistics: "SACHET EXPRESS CARGO ( HYD )",
      },
      {
        logisticsId: "DskCargoLogistics",
        logistics: "DSK CARGO AND LOGISTICS (MUM)",
      },
      {
        logisticsId: "AirPointInternational",
        logistics: "AIR POINT INTERNATIONAL (BLR)",
      },
      {
        logisticsId: "AirPointLogistics",
        logistics: "AIR POINT LOGISTICS (BLR)",
      },
      {
        logisticsId: "ChallengeXpress",
        logistics: "CHALLENGE XPRESS",
      },
      {
        logisticsId: "MetropolisLogistics",
        logistics: "METROPOLIS LOGISTICS PVT LTD",
      },

      {
        logisticsId: "Others",
        logistics: "Others",
      },
    ],
    manifestsLoc: [],
    manifests: [],
    // resetData: {},
    blocks: {},
    blockSelected: false,
    curBlockData: {},
    blockData: {},
    checkStatus: false,
    checkTicketBlockId: "",
    productModalShow: false,
    hubCode: "",
    scanBarcodeId: "",
    blocksArr: [],
    file: [],
    validationArr: [],
    validationArrNew: []
  };

  componentDidMount() {
    this.getLinehaulReqCount();
    if (this.props?.refreshListCallback) {
      this.props.refreshListCallback(this.getLinehaulReqCount);
    }
    // this.getActiveLogisticsProvider();
  }

  resetData = () => {
    this.setState({
      data: {
        destinationType: "",
        transMode: "",
        linehaulMode: "road",
        // logisticsProvider: "",
        paymentMode: "prepaid",
        shipmentNo: "",
        vehicleNo: "",
        shipmentStatus: "00Created",
        srcHubId: "",
        srcHub: "",
        srcType: "",
        srcOrg: "",
        srcContactPerson: "",
        srcAdd1: "",
        srcAdd2: "",
        srcLocation: "",
        srcLandmark: "",
        srcCity: "",
        srcState: "",
        srcPincode: "",
        srcContact1: "",
        srcContact2: "",
        srcEmailId: "",
        srcEmailIdAlt: "",
        dstHubId: "",
        dstHub: "",
        dstType: "",
        dstOrg: "",
        dstContactPerson: "",
        dstAdd1: "",
        dstAdd2: "",
        dstLocation: "",
        dstLandmark: "",
        dstCity: "",
        dstState: "",
        dstPincode: "",
        dstContact1: "",
        dstContact2: "",
        dstEmailId: "",
        dstEmailIdAlt: "",
        logisticsMode: "",
        isFragile: "",
        boxLength: "",
        boxBreadth: "",
        boxActualWeight: "",
        boxVolumetricWeight: "",

        boxHeight: "",
        boxBarcode: "",
        boxBarcodeId: "",
        boxUOM: "",
      },
    });
    this.setState({ moreInfo: {} });
    this.setState({ output: {} });
  };

  resetDataForm = () => {
    this.setState({
      data: {
        destinationType: "",
        transMode: "",
        // logisticsProvider: "",
        shipmentNo: "",
        vehicleNo: "",
        shipmentStatus: "00Created",
        paymentMode: "prepaid",
        linehaulMode: "road",

        srcHubId: this.state.data.srcHubId,
        srcHub: this.state.data.srcHub,
        srcType: "Hub",
        srcOrg: "BIZLOG",
        srcContactPerson: this.state.data.srcContactPerson,
        srcAdd1: this.state.data.srcAdd1,
        srcAdd2: this.state.data.srcAdd2,
        srcLocation: this.state.data.srcLocation,
        srcLandmark: this.state.data.srcLandmark,
        srcCity: this.state.data.srcCity,
        srcState: this.state.data.srcState,
        srcPincode: this.state.data.srcPincode,
        srcContact1: this.state.data.srcContact1,
        srcContact2: this.state.data.srcContact2,
        srcEmailId: this.state.data.srcEmailId,
        srcEmailIdAlt: this.state.data.srcEmailIdAlt,
        dstHubId: this.state.data.dstHubId,
        dstHub: "",
        dstType: "",
        dstOrg: "",
        dstContactPerson: "",
        dstAdd1: "",
        dstAdd2: "",
        dstLocation: "",
        dstLandmark: "",
        dstCity: "",
        dstState: "",
        dstPincode: "",
        dstContact1: "",
        dstContact2: "",
        dstEmailId: "",
        dstEmailIdAlt: "",
        isFragile: "",
        boxLength: "",
        boxBreadth: "",
        boxActualWeight: "",
        boxVolumetricWeight: "",

        boxHeight: "",
        boxBarcode: "",
        boxBarcodeId: "",
        boxUOM: "",
      },
      errors: {},
      moreInfo: {
        ...this.state.moreInfo,
        boxLength: "",
        boxBreadth: "",
        boxActualWeight: "",
        boxVolumetricWeight: "",
        boxHeight: "",
        isFragile: "",
        paymentMode: this.state.data.paymentMode,
        boxBarcode: "",
        boxBarcodeId: "",
        boxUOM: "",
      },
    });
  };

  getLinehaulReqCount = async () => {
    try {
      let selectedHub = localStorage.getItem("curHub");
      this.setState({ loading: true });
      const res = await getLinehaulReqCount(selectedHub);
      if (res.data.success === true) {
        this.setState({ linehaulCount: res.data.data.destinations });
      } else {
        toast.toastError(res.data.msg);
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in createManifest>>>>>> getLinehaulReqCount");
    }
  };

  // getActiveLogisticsProvider = async () => {
  //   try {
  //     let resp = await getLogisticsProbviderData();
  //     if(resp.data.success === true && resp.data.data.logisticProviders.length > 0){
  //       this.setState({logistics:resp.data.data.logisticProviders})
  //     }else{
  //       toast.toastError("No active logistics providers are present")
  //     }
  //   } catch {
  //     console.log("Error in getting active logistics provider");
  //   }
  // }

  getLinehaulBySrcDst = async (dstHubId) => {
    try {
      let selectedHub = localStorage.getItem("curHub");
      this.setState({ loading: true });
      this.setState({ selectedSrcHub: selectedHub });

      const res = await getLinehaulBySrcDst({
        srcHubId: selectedHub,
        dstHubId: dstHubId,
      });
      if (res.data.success === true) {
        this.setState({ linehaulList: res.data.data });

        const linehaulProductList = [];
        if (res.data.data && res.data.data.length) {
          res.data.data.map((data) => {
            const { products, ...ticket } = data;
            // console.log({ products, ticket });

            products.map((product) => {
              if (
                ticket.flowId === "AdvanceExchange" &&
                product.productType === "return"
              )
                return null;

              linehaulProductList.push({
                ...ticket,
                products: [{ ...product }],
              });
            });
          });
        }
        this.setState({ linehaulProductList: linehaulProductList });
        this.setState({ filteredLinehaulList: linehaulProductList });
      } else {
        toast.toastError(res.data.msg);
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in createManifest>>>>>> getLinehaulBySrcDst");
    }
  };

  handleDstClick = (r) => {
    this.setState({ hubCode: r.hubCode,
      validationArr: [],  
      prdBrcRes: true
    });
    this.resetData();
    // this.resetDataForm()
    // console.log({handleDstClick : r})
    if (Object.keys(this.state.blocks).length > 0) {
      for (const block in this.state.blocks) {
        const curProductId = this.state.blocks[block].blockProducts[0];
        delete this.state[`${curProductId}-ln`];
        delete this.state[`${curProductId}-bd`];
        delete this.state[`${curProductId}-ht`];
        delete this.state[`${curProductId}-actWt`];
      }
    }
    this.setState({ moreInfo: {} });
    this.setState({ blocks: {} });

    this.setState({ curDstHubId: r.dstHubId });
    this.getLinehaulBySrcDst(r.dstHubId);
  };

  destinationType = [
    { destinationTypeId: "hub", destinationType: "Hub" },
    { destinationTypeId: "customer", destinationType: "Customer" },
  ];

  logisticsMode = [
    { logisticsByValue: "3PL", logisticsByLabel: "3PL" },
    { logisticsByValue: "Vehicle", logisticsByLabel: "Vehicle" },
  ];

  isFragile = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  uom = [
    { uomValue: "inches", uomLabel: "inches" },
    { uomValue: "feet", uomLabel: "feet" },
    { uomValue: "cm", uomLabel: "cm" },
  ];

  handleOldNewTicketValidation = (r) => {
    this.state.validationArr.push(r)
    // console.log("this.state.validationArr",this.state.validationArr);
    let res;
    this.state.validationArr.map((m) => {
      if(m.productBarcodeId === null){
        // console.log(`================>m ${m.productBarcode}`,m)
        res = true;
      }
      if(m.productBarcodeId !== null){
        res = false
      }
    })
    return res;
  }

  handleCheckBoxClick = (e, r) => {
    const curProductId = r.products[0]?.ticketProductId;
    // changes Start
    let blockObj = {};
    // console.log({ linehaul: this.state.linehaulList })
    const prodArr = [];
    this.state.linehaulList.map((prod) => {
      if (prod.ticketNo === r.ticketNo) {
        prodArr.push(prod);
      }
    })

    // console.log("finalProdArr", prodArr);

    let res;

   


    const finalArr = prodArr[0].products.map((p) => {
      return {
        blockId: p.ticketBlockId,
        blockProducts: p.ticketProductId,
        prodBarcode: p.productBarcode,
        prodBarcodeId: p.productBarcodeId,
        productL: p.productL,
        productB: p.productB,
        productH: p.productH,
        productActualWeight: p.productActualWeight,
        productVolumetricWeight: p.productVolumetricWeight,
        productUOM: "cm",
        productWeightUOM: "kg"
      }
    })

    // console.log("finalProdArr", finalArr)

    // changes End
    if (e.target.checked) {
      // console.log({ reff: r });
      // let data = { ...this.state.data };
      // data = r.products[0];
      // console.log("data" + JSON.stringify(r));

      prodArr[0].products.map((r) => {
        if (r.productBarcodeId === null) {
          // console.log("rtrt",r)
          this.state.validationArr.push(r)
          // console.log("this.state.validationArr111", this.state.validationArr)
          return res = false;
        }
        else{
          // this.state.validationArr.push(r)
          // this.state.validationArr.pop(r)
          // console.log("this.state.validationArr222", this.state.validationArr)  
         return res = true;
        }
      })
      prodArr[0].products.map((r) => {
        if (r.productBarcodeId !== null) {
          // console.log("rCheck",r)
          this.state.validationArrNew.push(r)
          // console.log("this.state.validationArrNew111Check", this.state.validationArrNew)
          return res = true;
        }
        else{
          // this.state.validationArr.push(r)
          // this.state.validationArr.pop(r)
          // console.log("this.state.validationArrNew222Check", this.state.validationArrNew)  
         return res = false;
        }
      })
      

      if(res === true && this.state.validationArr.length !== 0){
        this.setState({validationArrNew: []})
        swal({
          icon: "error",
          title: "Don't Select Old and New ticket at the same time ",
          dangerMode: true,
          }).then((res) => {
            return null;
          });
          return null;
}
      if(res === false && this.state.validationArrNew.length !== 0){
        this.setState({validationArr: []})
        swal({
          icon: "error",
          title: "Don't Select Old and New ticket at the same time ",
          dangerMode: true,
          }).then((res) => {
            return null;
          });
          return null;
}

    //   if(res === false){

    //   if(this.state.validationArr.length === 0 ){
    //     this.state.validationArr.push(...prodArr[0].products)
    //   }
    //   else{
    //     const result = this.handleOldNewTicketValidation(...prodArr[0].products)
    //     console.log("result====>",result)
    //     if(result === false){
    //       this.state.validationArr.pop(...prodArr[0].products)
    //       console.log("this.state.validationArr", this.state.validationArr)
        //   swal({
        //           icon: "error",
        //           title: "Don't Select Old and New ticket at the same time",
        //           dangerMode: true,
        //           }).then((res) => {
        //             return null;
        //           });
        //           return null;
        // }
      // }
    // }
      if (res === false) {
        // alert('call')
        this.setState({ prdBrcRes: false })
      }

      this.state.blocksArr.push(...finalArr)


      this.setState({ blockSelected: true, });
      let blockData = {
        // ticketDetails: {...r},

        srcHub: r.srcHub,
        srcHubId: r.srcHubId,
        srcType: r.products[0].srcType,
        srcOrg: r.products[0].srcOrg,
        srcContactPerson: r.products[0].srcContactPerson,
        srcAdd1: r.products[0].srcAdd1,
        srcAdd2: r.products[0].srcAdd2,
        srcLocation: r.products[0].srcLocation,
        srcLandmark: r.products[0].srcLandmark,
        srcCity: r.products[0].srcCity,
        srcState: r.products[0].srcState,
        srcPincode: r.products[0].srcPincode,
        srcContact1: r.products[0].srcContact1,
        srcContact2: r.products[0].srcContact2,
        srcEmailId: r.products[0].srcEmailid,
        srcEmailIdAlt: r.products[0].srcEmailidAlt,
        direction: r.direction,
        dstHub: r.dstHub,
        dstHubId: r.dstHubId,
        dstType: r.products[0].dstType,
        dstOrg: r.products[0].dstOrg,
        dstContactPerson: r.products[0].dstContactPerson,
        dstAdd1: r.products[0].dstAdd1,
        dstAdd2: r.products[0].dstAdd2,
        dstLocation: r.products[0].dstLocation,
        dstLandmark: r.products[0].dstLandmark,
        dstCity: r.products[0].dstCity,
        dstState: r.products[0].dstState,
        dstPincode: r.products[0].dstPincode,
        dstContact1: r.products[0].dstContact1,
        dstContact2: r.products[0].dstContact2,
        dstEmailId: r.products[0].dstEmailid,
        dstEmailIdAlt: r.products[0].dstEmailidAlt,
        productL: r.products[0].productL,
        productB: r.products[0].productB,
        productH: r.products[0].productH,
        productVolumetricWeight: r.products[0].productVolumetricWeight,
      };

      let curBlockData = blockData;
      this.setState({ curBlockData });

      let blocks = { ...this.state.blocks };
      let param = {
        blockId: r.ticketBlockId,
        blockProducts: r.products.map((prod) => {
          return prod.ticketProductId;
        }),
      };
      blocks[r.ticketBlockId] = param;
      blocks[r.ticketBlockId]["checkState"] = true;

      this.setState({ blocks });

      if (!this.state.moreInfo[r.ticketBlockId]) {
        this.state.moreInfo = {
          ...this.state.moreInfo,
          [r.ticketBlockId]: {},
        };
      }

      if (!(curProductId in this.state.moreInfo[r.ticketBlockId])) {
        this.state.moreInfo = {
          ...this.state.moreInfo,
          paymentMode: this.state.data.paymentMode,
          [r.ticketBlockId]: {
            ...this.state.moreInfo[r.ticketBlockId],
            [curProductId]: {
              productCode: r.products[0]?.productCode,
              productName: r.products[0]?.productName,
              length: "",
              breadth: "",
              height: "",
              actualWt: "",
              volumetricWt: "",
              identificationNo: r.products[0]?.identificationNo,
            },
          },
        };
      }
      const length = parseInt(blockData.productL);
      const breadth = parseInt(blockData.productB);
      const height = parseInt(blockData.productH);

      let volWt = "";
      if (length > 0 && breadth > 0 && height > 0) {
        volWt = (length * breadth * height) / 5;
        this.setState({
          [`${curProductId}-ln`]: length,
          [`${curProductId}-bd`]: breadth,
          [`${curProductId}-ht`]: height,
          moreInfo: {
            ...this.state.moreInfo,

            [r.ticketBlockId]: {
              ...this.state.moreInfo[r.ticketBlockId],

              [curProductId]: {
                ...this.state.moreInfo[r.ticketBlockId][curProductId],
                length: length,
                breadth: breadth,
                height: height,
                volumetricWt: volWt,
              },
            },
          },
        });
      }

      // console.log("selected block.. " + JSON.stringify(blocks));
      // this.setState({ data });
    } else {
      // this.setState({ data: this.state.resetData });
      this.resetData();
      let blocks = { ...this.state.blocks };
      delete blocks[r.ticketBlockId];
      this.setState({ blocks });
      let moreInfo = { ...this.state.moreInfo };
      delete moreInfo[r.ticketBlockId];
      this.setState({ moreInfo });
      delete this.state[`${curProductId}-ln`];
      delete this.state[`${curProductId}-bd`];
      delete this.state[`${curProductId}-ht`];
      delete this.state[`${curProductId}-actWt`];
      this.handleProductModalClose();
      this.state.blocksArr.pop(...finalArr)
      prodArr[0].products.map((r) => {
        if (r.productBarcodeId === null) {
          // console.log("rtrt",r)
          this.state.validationArr.pop(r)
          // console.log("this.state.validationArr111", this.state.validationArr)
          return res = true;
        }
        else{
          // this.state.validationArr.pop(r)
          return res = false;
        }
        
      })
      prodArr[0].products.map((r) => {
        if (r.productBarcodeId !== null) {
          // console.log("rtrt",r)
          this.state.validationArrNew.pop(r)
          // console.log("this.state.validationArrNew111UnCheck", this.state.validationArrNew)
          return res = false;
        }
        else{
          // this.state.validationArr.push(r)
          // this.state.validationArr.pop(r)
          // console.log("this.state.validationArrNew222UnCheck", this.state.validationArrNew)  
         return res = true;
        }
      })
      // this.state.validationArr.pop(r)
      // console.log("this.state.validationArr000", this.state.validationArr)
     if(this.state.validationArr.length === 0){
       this.setState({ prdBrcRes: true })
     }
     if(this.state.validationArrNew.length === 0){
       this.setState({ prdBrcRes: true })
     }

      if (res === true) {
        // alert('call')
      }

      // console.log("selected block " + JSON.stringify(blocks));
    }
  };

  handleCheckBoxProduct = (e, p, i) => {
    if (e.target.checked) {
      // console.log("Ticket details linehaul 2" + JSON.stringify(p));

      let selectedHub = localStorage.getItem("curHub");
      let datas = {};
      datas = { ...this.state.data };
      let output = {};
      output = this.jsonConcat(output, datas);

      let blockData = {
        srcHub: p.srcHub,
        srcHubId: selectedHub,
        srcType: p.srcType,
        srcOrg: p.srcOrg,
        srcContactPerson: p.srcContactPerson,
        srcAdd1: p.srcAdd1,
        srcAdd2: p.srcAdd2,
        srcLocation: p.srcLocation,
        srcLandmark: p.srcLandmark,
        srcCity: p.srcCity,
        srcState: p.srcState,
        srcPincode: p.srcPincode,
        srcContact1: p.srcContact1,
        srcContact2: p.srcContact2,
        srcEmailId: p.srcEmailid,
        srcEmailIdAlt: p.srcEmailidAlt,

        dstHub: p.dstHub,
        dstHubId: this.state.curDstHubId,
        dstType: p.dstType,
        dstOrg: p.dstOrg,
        dstContactPerson: p.dstContactPerson,
        dstAdd1: p.dstAdd1,
        dstAdd2: p.dstAdd2,
        dstLocation: p.dstLocation,
        dstLandmark: p.dstLandmark,
        dstCity: p.dstCity,
        dstState: p.dstState,
        dstPincode: p.dstPincode,
        dstContact1: p.dstContact1,
        dstContact2: p.dstContact2,
        dstEmailId: p.dstEmailid,
        dstEmailIdAlt: p.dstEmailidAlt,
      };

      output = this.jsonConcat(output, blockData);
      this.setState({ data: output });

      let key = "moreInfo";
      let obj = {};
      let key1 = "blocks";
      let obj1 = {};

      const modObj = [];
      for (var keys of Object.keys(this.state.blocks)) {
        delete this.state.blocks[keys]["checkState"];
        modObj.push(this.state.blocks[keys]);
      }
      obj[key] = this.state.moreInfo;
      obj1[key1] = modObj;
      output = this.jsonConcat(output, obj);
      output = this.jsonConcat(output, obj1);

      this.setState({ output });
      // console.log("click radio button...." + JSON.stringify(output));
    } else if (!e.target.checked) {
      // this.setState({ data: this.state.resetData });
      this.resetData();
    }
  };

  handleView = () => {
    $(".modal-content").resizable({
      //alsoResize: ".modal-dialog",
      minHeight: 300,
      minWidth: 300,
    });
    $("#myModalProduct").draggable();

    $("#myModalProduct").on("show.bs.modal", function () {
      $(this).find(".modal-body").css({
        "max-height": "50%",
      });
    });
  };

  handleProductModalClose = () => {
    this.setState({ productModalShow: false });
  };
  handleProductModalShow = () => {
    this.setState({ productModalShow: true });
  };

  handleProduct = (r) => {
    if (
      this.state.blocks[r.ticketBlockId] &&
      this.state.blocks[r.ticketBlockId]["checkState"] === true
    ) {
      this.handleProductModalClose();
      let productsList = { ...this.state.productsList };
      productsList = r.products;
      productsList.srcHub = r.srcHub;
      productsList.dstHub = r.dstHub;
      this.setState({
        productsList,
        curBlockSrcHub: r.srcHub,
        curBlockDstHub: r.dstHub,
      });
      this.handleProductModalShow();
    } else {
      toast.toastWarn("Block is not Checked");
    }
  };
  handleProductConfirm = (r) => {
    let datas = {};
    datas = { ...this.state.data };
    let output = {};
    output = this.jsonConcat(output, datas);
    output = this.jsonConcat(output, this.state.curBlockData);
    this.setState({ data: output });

    let key = "moreInfo";
    let obj = {};
    let key1 = "blocks";
    let obj1 = {};

    const modObj = [];
    for (var keys of Object.keys(this.state.blocks)) {
      delete this.state.blocks[keys]["checkState"];
      modObj.push(this.state.blocks[keys]);
    }
    obj[key] = this.state.moreInfo;
    obj1[key1] = modObj;
    output = this.jsonConcat(output, obj);
    output = this.jsonConcat(output, obj1);

    this.setState({ output });
    // console.log("confirm.........."+JSON.stringify(output));
  };

  jsonConcat = (o1, o2) => {
    for (var key in o2) {
      o1[key] = o2[key];
    }
    return o1;
  };

  tableMeta = {
    key: "sl_no",
    columns: [
      {
        key: "Drop_Location",
        action: (r) => (
          <>
            {/* {this.state.hubCode !== "HO" && ( */}
            <label>
              <input
                type="checkbox"
                name={r.TicketNO}
                value={r.TicketNO}
                checked={r.ticketBlockId in this.state.blocks}
                onClick={(e) => this.handleCheckBoxClick(e, r)}
              />
              <i className="helper"></i>
            </label>
          </>
        ),
        cusClass: " ",
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      // { path: "blockCode", label: "Block" },
      // {
      //   key: "blockCode",
      //   action: (r) => (
      //     <>
      //       {/* {" "} */}
      //       {/* onClick={(e) => this.handleProduct(r)} */}
      //       <i className="cursorPointor" >
      //         {r.blockCode}
      //       </i>
      //       {this.state.blocks[r.ticketBlockId] &&
      //         this.state.blocks[r.ticketBlockId]["checkState"] === true && (
      //           <span>
      //             {/* <button
      //               className="badge badge-success ml-4 "
      //               onClick={(e) => this.handleProductConfirm(r)}
      //             >
      //               Confirm
      //             </button> */}
      //           </span>
      //         )}
      //     </>
      //   ),
      //   cusClass: " ",
      //   label: "Block",
      // },
      {
        key: "TicketNO",
        action: (r) => (
          <>
            <span>{r.ticketNo}</span>
          </>
        ),
        cusClass: " ",
        icon: () => (
          <div>
            <p>Ticket</p>
            <input
              type="text"
              name="searchTicket"
              id="serachTicket"
              placeholder="search"
              value={this.state.searchText}
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
                const filteredLinehaulList =
                  this.state.linehaulProductList?.filter((item) => {
                    const ticketNo = item.ticketNo;
                    return ticketNo
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  });
                this.setState({ filteredLinehaulList });
              }}
            />
          </div>
        ),
      },
      {
        key: "productBarcode",
        action: (r) => (
          <>
            <span>{r.products[0]?.productBarcode}</span>
          </>
        ),
        cusClass: " ",
        icon: () => <>Product Barcode</>,
      },
      {
        key: "ProductCode",
        action: (r) => (
          <>
            <span>{r.products[0]?.productName}</span>
          </>
        ),
        cusClass: " ",
        icon: () => <>Product Name</>,
      },

      {
        key: "IdentificationNo",
        action: (r) => (
          <>
            <span>{r.products[0]?.identificationNo}</span>
          </>
        ),
        cusClass: " ",
        icon: () => <>Product Id.</>,
      },
      // { path: "dstHub", label: "Dest Hub" },
      // { path: "dstHubId", label: "Dst HubId " },
      // { path: "dstHub", label: "Dst Hub " },
      // { path: "Actual_Weight", label: "Wt (Kgs)." },
      {
        key: "ticketDimensions",
        action: (r) => {
          // const curData = JSON.parse(JSON.stringify(this.state.data));
          const curProductId = r.products[0]?.ticketProductId;

          if (r.ticketBlockId in this.state.blocks) {
            return (
              <div
                className="d-flex flex-column px-1"
                style={{ width: "100%" }}
              >
                <div className="col m-1 p-0">
                  {/* {this.rNB("L", `${curProductId}-ln`)} */}
                  <input
                    className="col m-0 p-0"
                    name={`${curProductId}-ln`}
                    type="number"
                    min="0"
                    placeholder="L"
                    value={this.state[`${curProductId}-ln`]}
                    // value={this.state.moreInfo[r.ticketBlockId][curProductId]["length"]}
                    onChange={(e) => {
                      this.handleDimensionInputChange(e, r);
                    }}
                  />
                </div>
                <div className="col m-1 p-0">
                  {/* {this.rNB("B", `${curProductId}-bd`)} */}
                  <input
                    className="col m-0 p-0"
                    name={`${curProductId}-bd`}
                    type="number"
                    min={0}
                    placeholder="B"
                    value={this.state[`${curProductId}-bd`]}
                    // value={this.state.moreInfo[r.ticketBlockId][curProductId]["breadth"]}
                    onChange={(e) => {
                      this.handleDimensionInputChange(e, r);
                    }}
                  />
                </div>
                <div className="col m-1 p-0">
                  {/* {this.rNB("H", `${curProductId}-ht`)} */}
                  <input
                    className="col m-0 p-0"
                    name={`${curProductId}-ht`}
                    type="number"
                    min={0}
                    placeholder="H"
                    value={this.state[`${curProductId}-ht`]}
                    // value={this.state.moreInfo[r.ticketBlockId][curProductId]["height"]}
                    onChange={(e) => {
                      this.handleDimensionInputChange(e, r);
                    }}
                  />
                </div>
              </div>
            );
          } else {
            return <></>;
          }
        },
        cusClass: "",
        icon: () => (
          <p className="d-flex flex-column mt-3">
            <span>Product Dimensions (cm)</span>
            {/* <span style={{
          fontWeight: "normal",
          fontSize: "12px"
        }}></span> */}
          </p>
        ),
      },
      {
        key: "ticketVolWt",
        action: (r) => {
          const curProductId = r.products[0]?.ticketProductId;
          return (
            <>
              {this.state.moreInfo &&
                this.state.moreInfo[r.ticketBlockId] &&
                this.state.moreInfo[r.ticketBlockId][curProductId] &&
                this.state.moreInfo[r.ticketBlockId][curProductId][
                "volumetricWt"
                ] &&
                this.state.moreInfo[r.ticketBlockId][curProductId][
                "volumetricWt"
                ]}
            </>
          );
        },

        cusClass: "",
        icon: () => <div>Volumetric Wt. (grams)</div>,
      },

      {
        key: "actualWt",
        action: (r) => {
          const curProductId = r.products[0]?.ticketProductId;
          if (r.ticketBlockId in this.state.blocks) {
            return (
              <>
                <input
                  className="col m-0 p-0"
                  name={`${curProductId}-actWt`}
                  type="number"
                  min={0}
                  placeholder="Gross Wt."
                  value={this.state[`${curProductId}-actWt`]}
                  // value={this.state.moreInfo[r.ticketBlockId][curProductId]["actualWt"]}
                  onChange={(e) => {
                    this.handleDimensionInputChange(e, r);
                  }}
                />
              </>
            );
          } else {
            return <></>;
          }
        },
        cusClass: "",
        icon: () => (
          <p className="d-flex flex-column mt-3">
            <span>Gross Wt. (grams)</span>
            <span
              style={{
                fontWeight: "normal",
                fontSize: "12px",
              }}
            >
              (includes packing material)
            </span>
          </p>
        ),
      },
      // { path: "Quantity", label: "Q" },
    ],
  };

  schema = {
    destinationType: Joi.string().required().label("Destination Type"),
    transMode: Joi.string().required().label("Mode of Transport"),
    linehaulMode: Joi.string().required().label("Linehaul Mode"),
    logisticsProvider: Joi.string().required().label("Logistics Provider"),
    shipmentNo: Joi.optional().label("Shipment No."),
    paymentMode: Joi.optional().label("Payment Mode"),
    vehicleNo: Joi.optional().label("Vehicle No."),
    srcHubId: Joi.string().required().label("Source Hub Id"),
    srcHub: Joi.string()
      .regex(/^([A-Za-z ])*$/)
      .required()
      .label("Source Hub")
      .error(() => {
        return {
          message: "Only alphabets allowed",
        };
      }),
    srcType: Joi.optional().label("Source Type"),
    srcOrg: Joi.optional().label("Source Org"),
    srcContactPerson: Joi.string()
      .regex(/^[A-Za-z ]+$/)
      .required()
      .label("Source Contact Person")
      .error(() => {
        return {
          message: " Only alphabets allowed",
        };
      }),
    srcAdd1: Joi.string().min(1).max(400).required().label("Source Add1"),
    srcAdd2: Joi.optional().label("Source Add2"),
    srcLocation: Joi.string()
      .regex(/^([A-Za-z ])*$/)
      .required()
      .label("Source Location")
      .error(() => {
        return {
          message: " Only alphabets allowed",
        };
      }),
    srcLandmark: Joi.optional().label("Source Landmark"),
    srcCity: Joi.optional().label("Source City"),
    srcState: Joi.optional().label("Source State"),
    srcPincode: Joi.string()
      .regex(/^[0-9]{6}$/)
      .required()
      .label("Source Pincode")
      .error(() => {
        return {
          message: "Only numbers allowed & length equal to 6",
        };
      }),
    // srcContact1: Joi.isNumber().required().label("Source Contact1"),
    // srcContact1: Joi.string().regex(/^[0-9]{11}$/).required().label("Source Contact1").error(() => {
    //   return {
    //     message: 'Allow only 10/11 digit numbers.',
    //   }
    // }),
    srcContact1: Joi.string()
      .min(10)
      .max(11)
      .regex(/^[0-9]+$/)
      .required()
      .label("Source Contact1")
      .error(() => {
        return {
          message: "Allow only 10/11 digit numbers.",
        };
      }),

    srcContact2: Joi.optional().label("Source Contact2"),
    // srcEmailId: Joi.optional().label("Source Emailid").email({ minDomainAtoms: 2 }),
    srcEmailId: Joi.optional().label("Source Emailid"),
    srcEmailIdAlt: Joi.optional().label("Source EmailidAlt"),
    dstHubId: Joi.required().label("Destination Hub Id"),
    dstHub: Joi.string()
      .regex(/^([A-Za-z ])*$/)
      .required()
      .label("Destination Hub")
      .error(() => {
        return {
          message: "Only alphabets allowed",
        };
      }),
    dstType: Joi.optional().label("Destination Type"),
    dstOrg: Joi.optional().label("Destination Org"),
    dstContactPerson: Joi.string()
      .regex(/^([A-Za-z ])*$/)
      .required()
      .label("Destination ContactPerson")
      .error(() => {
        return {
          message: "Only alphabets allowed",
        };
      }),
    dstAdd1: Joi.string().min(1).max(400).required().label("Destination Add1"),
    dstAdd2: Joi.optional().label("Destination Add2"),
    dstLocation: Joi.string()
      .regex(/^([A-Za-z ])*$/)
      .required()
      .label("Destination Location")
      .error(() => {
        return {
          message: "Only alphabets allowed",
        };
      }),
    dstCity: Joi.optional().label("Destination City"),
    dstState: Joi.optional().label("Destination State"),
    dstPincode: Joi.string()
      .regex(/^[0-9]{6}$/)
      .required()
      .label("Destination Pincode")
      .error(() => {
        return {
          message: "Only numbers allowed & length equal to 6",
        };
      }),
    dstLandmark: Joi.optional().label("Destination Landmark"),
    dstContact1: Joi.string()
      .min(10)
      .max(11)
      .regex(/^[0-9]+$/)
      .required()
      .label("Destination Contact1")
      .error(() => {
        return {
          message: "Allow only 10/11 digit numbers.",
        };
      }),
    dstContact2: Joi.optional().label("Destination Contact2"),
    // dstEmailId: Joi.optional().label("Destination Emailid").email({ minDomainAtoms: 2 }),
    dstEmailId: Joi.optional().label("Destination Emailid"),
    dstEmailIdAlt: Joi.optional().label("Destination EmailidAlt"),
    shipmentStatus: Joi.optional().label("Shipment Status"),
    moreInfo: Joi.optional().label("moreInfo"),
    blocks: Joi.optional().label("blocks"),
    logisticsMode: Joi.required().label("Logistics mode"),
    vehicleEmptyWt: Joi.optional().label("Vehicle Empty Weight"),
    vehicleLoadedWt: Joi.optional().label("Vehicle Loaded Weight"),
    isFragile: Joi.optional().label("Is fragile"),
    // boxLength: Joi.number().positive().label("Box length"),
    boxLength: Joi.optional().label("Box length"),
    boxBreadth: Joi.optional().label("Box Breadth"),
    boxHeight: Joi.optional().label("Box Height"),
    boxActualWeight: Joi.optional().label("Box Actual Weight"),
    boxVolumetricWeight: Joi.optional().label("Box Barcode"),

    boxBarcode: Joi.optional().label("Box Barcode"),
    boxBarcodeId: Joi.optional().label("Box Barcode"),
    boxUOM: Joi.optional().label("Box UOM"),
    fileInfo: Joi.optional().label('File Info'),
    // fileInfo:  Joi.when('boxBarcode', {
    //   is: Joi.exist(),
    //   then: Joi.string().min(5).required().label('File Info'),
    //   otherwise: Joi.optional().label('File Info'),
    // }),

    selectedFilesList: Joi.optional().label("selected FilesList"),
  };

  // async doSubmit() {
  //   try {
  //     this.setState({ loading: true });

  //     this.state.output.destinationType = this.state.data.destinationType;
  //     this.state.output.logisticsProvider = this.state.data.logisticsProvider;
  //     this.state.output.transMode = this.state.data.transMode;
  //     this.state.output.linehaulMode = this.state.data.linehaulMode;
  //     this.state.output.paymentMode = this.state.data.paymentMode;
  //     this.state.output.shipmentNo = this.state.data.shipmentNo;
  //     this.state.output.vehicleNo = this.state.data.vehicleNo;

  //     this.state.output.srcHubId = this.state.data.srcHubId;
  //     this.state.output.srcHub = this.state.data.srcHub;
  //     this.state.output.srcType = this.state.data.srcType;
  //     this.state.output.srcOrg = this.state.data.srcOrg;
  //     this.state.output.srcContactPerson = this.state.data.srcContactPerson;
  //     this.state.output.srcAdd1 = this.state.data.srcAdd1;
  //     this.state.output.srcAdd2 = this.state.data.srcAdd2;
  //     this.state.output.srcLocation = this.state.data.srcLocation;
  //     this.state.output.srcLandmark = this.state.data.srcLandmark;
  //     this.state.output.srcCity = this.state.data.srcCity;
  //     this.state.output.srcState = this.state.data.srcState;
  //     this.state.output.srcPincode = this.state.data.srcPincode;
  //     this.state.output.srcContact1 = this.state.data.srcContact1;
  //     this.state.output.srcContact2 = this.state.data.srcContact2;
  //     this.state.output.srcEmailId = this.state.data.srcEmailId;
  //     this.state.output.srcEmailIdAlt = this.state.data.srcEmailIdAlt;

  //     this.state.output.dstHubId = this.state.data.dstHubId;
  //     this.state.output.dstHub = this.state.data.dstHub;
  //     this.state.output.dstType = this.state.data.dstType;
  //     this.state.output.dstOrg = this.state.data.dstOrg;
  //     this.state.output.dstContactPerson = this.state.data.dstContactPerson;
  //     this.state.output.dstAdd1 = this.state.data.dstAdd1;
  //     this.state.output.dstAdd2 = this.state.data.dstAdd2;
  //     this.state.output.dstLocation = this.state.data.dstLocation;
  //     this.state.output.dstLandmark = this.state.data.dstLandmark;
  //     this.state.output.dstCity = this.state.data.dstCity;
  //     this.state.output.dstState = this.state.data.dstState;
  //     this.state.output.dstPincode = this.state.data.dstPincode;
  //     this.state.output.dstContact1 = this.state.data.dstContact1;
  //     this.state.output.dstContact2 = this.state.data.dstContact2;
  //     this.state.output.dstEmailId = this.state.data.dstEmailId;
  //     this.state.output.dstEmailIdAlt = this.state.data.dstEmailIdAlt;
  //     this.state.output.direction = this.state.curBlockData.direction;
  //     // console.log("vehicleLoadedWt", this.state.data.vehicleEmptyWt, this.state.data.vehicleLoadedWt)

  //     if (
  //       this.state.data.vehicleLoadedWt &&
  //       this.state.data.vehicleEmptyWt &&
  //       parseFloat(this.state.data.vehicleLoadedWt) <=
  //       parseFloat(this.state.data.vehicleEmptyWt)
  //     ) {
  //       swal({
  //         icon: "error",
  //         title:
  //           "Vehicle loaded weight cannot be less than vehicle empty weight",
  //       });
  //       return;
  //     }
  //     if (
  //       this.state.data.srcLandmark?.length > 150 ||
  //       this.state.data.dstLandmark?.length > 150
  //     ) {
  //       swal({
  //         icon: "error",
  //         title:
  //           "Kindly check the landmarks field character length, it should not exceed more than 150 characters",
  //         dangerMode: true,
  //       }).then((res) => {
  //         return null;
  //       });
  //       return null;
  //     }
  //     if (
  //       this.state.data.dstAdd2?.length > 400 ||
  //       this.state.data.srcAdd2?.length > 400
  //     ) {
  //       swal({
  //         icon: "error",
  //         title:
  //           "Kindly check the Source/Destination Add2 field character length, it should not exceed more than 400 characters",
  //         dangerMode: true,
  //       }).then((res) => {
  //         return null;
  //       });
  //       return null;
  //     }

  //     if (
  //       !this.state.data.srcAdd1?.trim() ||
  //       !this.state.data.dstAdd1?.trim()
  //     ) {
  //       swal({
  //         icon: "error",
  //         title: "Source/Destination Add1 field cannot be empty",
  //         dangerMode: true,
  //       }).then((res) => {
  //         return null;
  //       });
  //       return null;
  //     }

  //     if (
  //       !this.state.data.srcLocation?.trim() ||
  //       !this.state.data.dstLocation?.trim()
  //     ) {
  //       swal({
  //         icon: "error",
  //         title: "Source/Destination Location field cannot be empty",
  //         dangerMode: true,
  //       }).then((res) => {
  //         return null;
  //       });
  //       return null;
  //     }
  //     if (
  //       !this.state.data.srcContactPerson?.trim() ||
  //       !this.state.data.dstContactPerson?.trim()
  //     ) {
  //       swal({
  //         icon: "error",
  //         title: "Source/Destination Contact Person field cannot be empty",
  //         dangerMode: true,
  //       }).then((res) => {
  //         return null;
  //       });
  //       return null;
  //     }

  //     // if (this.state.file.length === 0) {
  //     //     swal({
  //     //       icon: "error",
  //     //       title:
  //     //         "Upload Atleast one File",
  //     //     });
  //     //     return null;
  //     //   }

  //       // console.log({ output: this.state.output });

  //     //   const blocksSelected = Object.keys(this.state.blocks);
  //     //   const validBlockList =  blocksSelected.filter((block)=>{

  //     //   const productId = this.state.blocks[block].blockProducts[0]

  //     //   const productDetails = this.state.moreInfo[block][productId]

  //     //   if(+productDetails.length && +productDetails.height && +productDetails.breadth && +productDetails.actualWt){
  //     //     return true
  //     //   }
  //     //   return false;
  //     // })

  //     // if(validBlockList.length !== blocksSelected.length) {
  //     //   swal({
  //     //     icon: "error",
  //     //     title: "Please enter valid dimensions"
  //     //   })
  //     //   return;
  //     // }

  //     if (this.state.btnValue === "create") {
  //       // console.log({ output: this.state.output });
  //       // console.log(JSON.stringify(this.state.output));
  //       // console.log({ output: this.state.output });
  //       // console.log({ output: this.state.blocksArr });

  //       this.state.output.blocks = this.state.blocksArr;
  //       // this.state.output.selectedFiles = this.state.requiredFile;
  //       // console.log({ output: this.state.output.selectedFilesList });
  //       // console.log({ selectedFilesLis: this.state.data.selectedFilesList });
  //       this.state.output.selectedFilesList = this.state.data.selectedFilesList
  //       this.state.output.fileInfo = this.state.data.fileInfo
  //       // console.log({final :this.state.output})

  //       if (this.state.output.selectedFilesList) {  
  //         const resp = await createManifests(this.state.output);
  //         if (resp.data.success === true) {
  //           this.state.output.destinationType = "";
  //           this.state.data.destinationType = "";
  //           // this.state.linehaulProductList = {};
  //           toast.toastSuccess(resp.data.msg);
  //           this.getLinehaulReqCount();
  //           this.getLinehaulBySrcDst(this.state.curDstHubId);
  //           // this.setState({ data: this.state.resetData });
  //           this.resetData();
  //           this.setState({ blocks: {} });
  //           document.getElementById("refDocFiles").value = "";
  //           let data = this.state.data;
  //           data.selectedFilesList = [];
  //           this.setState({data});
  //           this.setState({file: [], blocksArr: []})
  //           this.setState({data: {boxBarcode : "",boxBarcodeId: "",}})
  //         } else {
  //           this.setState({
  //             data: {
  //               ...this.state.data,
  //               boxBarcodeId: "",
  //               boxUOM: "",
  //             },
  //             moreInfo: {
  //               ...this.state.moreInfo,
  //               boxBarcodeId: "",
  //               boxUOM: "",
  //             },
  //           });
  //           toast.error(() => <div>{resp.data.msg}</div>);

  //         }
  //       } else {
  //         toast.toastError("Upload Atleast one File");
  //       }
  //         this.setState({ loading: false });
  //       }


  //   } catch (error) {
  //     console.log("Error in createManifest>>>>>> doSubmit");
  //     console.error(error);
  //   }
  // }

  async doSubmit() {
    try {
      this.setState({ loading: true });

      this.state.output.destinationType = this.state.data.destinationType;
      this.state.output.logisticsProvider = this.state.data.logisticsProvider;
      this.state.output.transMode = this.state.data.transMode;
      this.state.output.linehaulMode = this.state.data.linehaulMode;
      this.state.output.paymentMode = this.state.data.paymentMode;
      this.state.output.shipmentNo = this.state.data.shipmentNo;
      this.state.output.vehicleNo = this.state.data.vehicleNo;

      this.state.output.srcHubId = this.state.data.srcHubId;
      this.state.output.srcHub = this.state.data.srcHub;
      this.state.output.srcType = this.state.data.srcType;
      this.state.output.srcOrg = this.state.data.srcOrg;
      this.state.output.srcContactPerson = this.state.data.srcContactPerson;
      this.state.output.srcAdd1 = this.state.data.srcAdd1;
      this.state.output.srcAdd2 = this.state.data.srcAdd2;
      this.state.output.srcLocation = this.state.data.srcLocation;
      this.state.output.srcLandmark = this.state.data.srcLandmark;
      this.state.output.srcCity = this.state.data.srcCity;
      this.state.output.srcState = this.state.data.srcState;
      this.state.output.srcPincode = this.state.data.srcPincode;
      this.state.output.srcContact1 = this.state.data.srcContact1;
      this.state.output.srcContact2 = this.state.data.srcContact2;
      this.state.output.srcEmailId = this.state.data.srcEmailId;
      this.state.output.srcEmailIdAlt = this.state.data.srcEmailIdAlt;

      this.state.output.dstHubId = this.state.data.dstHubId;
      this.state.output.dstHub = this.state.data.dstHub;
      this.state.output.dstType = this.state.data.dstType;
      this.state.output.dstOrg = this.state.data.dstOrg;
      this.state.output.dstContactPerson = this.state.data.dstContactPerson;
      this.state.output.dstAdd1 = this.state.data.dstAdd1;
      this.state.output.dstAdd2 = this.state.data.dstAdd2;
      this.state.output.dstLocation = this.state.data.dstLocation;
      this.state.output.dstLandmark = this.state.data.dstLandmark;
      this.state.output.dstCity = this.state.data.dstCity;
      this.state.output.dstState = this.state.data.dstState;
      this.state.output.dstPincode = this.state.data.dstPincode;
      this.state.output.dstContact1 = this.state.data.dstContact1;
      this.state.output.dstContact2 = this.state.data.dstContact2;
      this.state.output.dstEmailId = this.state.data.dstEmailId;
      this.state.output.dstEmailIdAlt = this.state.data.dstEmailIdAlt;
      this.state.output.direction = this.state.curBlockData.direction;
      // console.log("vehicleLoadedWt", this.state.data.vehicleEmptyWt, this.state.data.vehicleLoadedWt)

      if (
        this.state.data.vehicleLoadedWt &&
        this.state.data.vehicleEmptyWt &&
        parseFloat(this.state.data.vehicleLoadedWt) <=
        parseFloat(this.state.data.vehicleEmptyWt)
      ) {
        swal({
          icon: "error",
          title:
            "Vehicle loaded weight cannot be less than vehicle empty weight",
        });
        return;
      }
      if (
        this.state.data.srcLandmark?.length > 150 ||
        this.state.data.dstLandmark?.length > 150
      ) {
        swal({
          icon: "error",
          title:
            "Kindly check the landmarks field character length, it should not exceed more than 150 characters",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null;
      }
      if (
        this.state.data.dstAdd2?.length > 400 ||
        this.state.data.srcAdd2?.length > 400
      ) {
        swal({
          icon: "error",
          title:
            "Kindly check the Source/Destination Add2 field character length, it should not exceed more than 400 characters",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null;
      }

      if (
        !this.state.data.srcAdd1?.trim() ||
        !this.state.data.dstAdd1?.trim()
      ) {
        swal({
          icon: "error",
          title: "Source/Destination Add1 field cannot be empty",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null;
      }

      if (
        !this.state.data.srcLocation?.trim() ||
        !this.state.data.dstLocation?.trim()
      ) {
        swal({
          icon: "error",
          title: "Source/Destination Location field cannot be empty",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null;
      }
      if (
        !this.state.data.srcContactPerson?.trim() ||
        !this.state.data.dstContactPerson?.trim()
      ) {
        swal({
          icon: "error",
          title: "Source/Destination Contact Person field cannot be empty",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null;
      }

      
      if(this.state.prdBrcRes !== false && this.state.data.fileInfo === undefined){
        swal({
          icon: "error",
          title: "Please Enter File Info",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null
      }

      // if (this.state.file.length === 0) {
      //     swal({
      //       icon: "error",
      //       title:
      //         "Upload Atleast one File",
      //     });
      //     return null;
      //   }

      // console.log({ output: this.state.output });

      //   const blocksSelected = Object.keys(this.state.blocks);
      //   const validBlockList =  blocksSelected.filter((block)=>{

      //   const productId = this.state.blocks[block].blockProducts[0]

      //   const productDetails = this.state.moreInfo[block][productId]

      //   if(+productDetails.length && +productDetails.height && +productDetails.breadth && +productDetails.actualWt){
      //     return true
      //   }
      //   return false;
      // })

      // if(validBlockList.length !== blocksSelected.length) {
      //   swal({
      //     icon: "error",
      //     title: "Please enter valid dimensions"
      //   })
      //   return;
      // }

      // m.productActualWeight === 0 ||
      // m.productVolumetricWeight === 0 ||
      // m.productL === 0 ||
      // m.productB === 0 || 
      // m.productH === 0 ||
      // this.state.output.blocks = this.state.blocksArr;

      
      /* 
      const result = resp.data.data.linehaulTicketDetails.every(
        (ticket)
        => ticket.linehaulProductStatus === 'lineHaulProdReceived'
        );
        if(result){
          this.props.closeModal();
        }
        */
       if(this.state.prdBrcRes !== false){

      
     this.state.output.blocks = this.state.blocksArr;
      let count = 0;

      const arr = this.state.output.blocks;
      const arrLength = this.state.output.blocks.length;

      // || arr[i].productActualWeight !== 0
      // || arr[i].productVolumetricWeight !== 0
      for (let i = 0; i <= arr.length - 1; i++) {
        if (arr[i].productActualWeight !== "0" && arr[i].productVolumetricWeight !== "0") {
          count += 1;
        }
      }

      //  console.log("count::", count)
      //  console.log("arrLength::", arrLength)


      if (arrLength !== count) {
        swal({
          icon: "error",
          title: "Please Enter Product LBH",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return
      }


    }



      //  let prodStatus;
      //  this.state.output.blocks.map((m) => {
      // let result = this.state.output.blocks.every(
      //     (ticket) => ticket.productVolumetricWeight !== '0' 
      //  )
      //  console.log(result)
      //  if(result === false){
      //    return prodStatus = false
      //  }else{
      //    prodStatus = true
      //  }

      // })



      //  if (prodStatus === false) {
      //     swal({
      //       icon: "error",
      //       title: "Please Enter Product LBH",
      //       dangerMode: true,
      //     }).then((res) => {
      //       return null;
      //     });
      //     return null;
      //   }


      // let prodStatus;
      //   if ((m.productActualWeight === "0" || m.productActualWeight === 0) || (m.productVolumetricWeight === "0" || m.productVolumetricWeight === 0) ||
      //     (m.productL === "0" || m.productL === 0) || (m.productB === "0" || m.productB === 0) || (m.productH === "0" || m.productH === 0)) {
      //     return prodStatus = false
      //   }
      //   else {
      //     prodStatus = true
      //   }
      // })
      // alert(prodStatus)
      // if (prodStatus === false) {
      //   swal({
      //     icon: "error",
      //     title: "Please Enter Product LBH",
      //     dangerMode: true,
      //   }).then((res) => {
      //     return null;
      //   });
      //   return null;
      // }


      if (this.state.btnValue === "create") {
        // console.log({ output: this.state.output });
        // console.log(JSON.stringify(this.state.output));
        // console.log({ output: this.state.output });
        // console.log({ output: this.state.blocksArr });

        if(this.state.prdBrcRes === false){
          console.log({output: this.state.output})
          const resp = await createManifestsAsOld(this.state.output);
          if (resp.data.success === true) {
            this.state.output.destinationType = "";
            this.state.data.destinationType = "";
            // this.state.linehaulProductList = {};
            toast.toastSuccess(resp.data.msg);
            this.getLinehaulReqCount();
            this.getLinehaulBySrcDst(this.state.curDstHubId);
            // this.setState({ data: this.state.resetData });
            this.resetData();
            this.setState({ blocks: {} });
            this.setState({ prdBrcRes: true })
          } else {
            toast.error(() => <div>{resp.data.msg}</div>);
          }
          this.setState({ loading: false });

        }
        else{
          // console.log("this.state.data.fileInfo",this.state.data.fileInfo)
         

        this.state.output.blocks = this.state.blocksArr;
        // this.state.output.selectedFiles = this.state.requiredFile;
        // console.log({ output: this.state.output.selectedFilesList });
        // console.log({ selectedFilesLis: this.state.data.selectedFilesList });
        this.state.output.selectedFilesList = this.state.data.selectedFilesList
        this.state.output.fileInfo = this.state.data.fileInfo
        console.log({ final: this.state.output })

        if (this.state.output.selectedFilesList) {  
          const resp = await createManifests(this.state.output);
          if (resp.data.success === true) {
            this.state.output.destinationType = "";
            this.state.data.destinationType = "";
            // this.state.linehaulProductList = {};
            toast.toastSuccess(resp.data.msg);
            this.getLinehaulReqCount();
            this.getLinehaulBySrcDst(this.state.curDstHubId);
            // this.setState({ data: this.state.resetData });
            this.resetData();
            this.setState({ blocks: {} });
            document.getElementById("refDocFiles").value = "";
            let data = this.state.data;
            data.selectedFilesList = [];
            this.setState({data});
            this.setState({file: [], blocksArr: []})
            this.setState({data: {boxBarcode : "",boxBarcodeId: "",}})
          } else {
            this.setState({
              data: {
                ...this.state.data,
                boxBarcodeId: "",
                boxUOM: "",
              },
              moreInfo: {
                ...this.state.moreInfo,
                boxBarcodeId: "",
                boxUOM: "",
              },
            });
            toast.error(() => <div>{resp.data.msg}</div>);

          }
        } else {
          toast.toastError("Upload Atleast one File");
        }
          this.setState({ loading: false });
      }
    }
  


    } catch (error) {
      console.log("Error in createManifest>>>>>> doSubmit");
      console.error(error);
    }
  }


  localHandleChange = async (e) => {
    try {
      if (e.target.value === "hub") {
        let curSourceHub = [];
        const respSrc = await getHubDetails(this.state.selectedSrcHub);
        if (respSrc.data.success === true) {
          this.setState({ curSrcHub: respSrc.data.data.hubs });
          curSourceHub = respSrc.data.data.hubs;
          this.setState({
            data: {
              ...this.state.data,
              destinationType: "hub",
              transMode: "",
              // logisticsProvider: "",
              shipmentNo: "",
              vehicleNo: "",
              shipmentStatus: "00Created",

              srcHubId: curSourceHub.hubId,
              srcHub: curSourceHub.hub,
              srcType: "",
              srcOrg: "BIZLOG",
              srcContactPerson: curSourceHub.contactPerson,
              srcAdd1: curSourceHub.add1,
              srcAdd2: curSourceHub.add2,
              srcLocation: curSourceHub.location,
              srcLandmark: curSourceHub.landmark,
              srcCity: curSourceHub.city,
              srcState: curSourceHub.state,
              srcPincode: curSourceHub.pincode,
              srcContact1: curSourceHub.contact1,
              srcContact2: curSourceHub.contact2,
              srcEmailId: curSourceHub.emailId,
              srcEmailIdAlt: curSourceHub.emailIdAlt,
            },
          });
        }

        const respDst = await getHubDetails(this.state.curDstHubId);
        if (respDst.data.success === true) {
          this.setState({ curHub: respDst.data.data.hubs });
          this.setState({
            data: {
              ...this.state.data,
              destinationType: "hub",
              transMode: "",
              // logisticsProvider: "",
              shipmentNo: "",
              vehicleNo: "",
              shipmentStatus: "00Created",

              srcHubId: curSourceHub.hubId,
              srcHub: curSourceHub.hub,
              srcType: "Hub",
              srcOrg: "BIZLOG",
              srcContactPerson: curSourceHub.contactPerson,
              srcAdd1: curSourceHub.add1,
              srcAdd2: curSourceHub.add2,
              srcLocation: curSourceHub.location,
              srcLandmark: curSourceHub.landmark,
              srcCity: curSourceHub.city,
              srcState: curSourceHub.state,
              srcPincode: curSourceHub.pincode,
              srcContact1: curSourceHub.contact1,
              srcContact2: curSourceHub.contact2,
              srcEmailId: curSourceHub.emailId,
              srcEmailIdAlt: curSourceHub.emailIdAlt,

              dstHubId: this.state.curHub.hubId,
              dstHub: this.state.curHub.hub,
              dstType: "hub",
              dstOrg: "",
              dstContactPerson: this.state.curHub.contactPerson,
              dstAdd1: this.state.curHub.add1,
              dstAdd2: this.state.curHub.add2,
              dstLocation: this.state.curHub.location,
              dstLandmark: this.state.curHub.landmark,
              dstCity: this.state.curHub.city,
              dstState: this.state.curHub.state,
              dstPincode: this.state.curHub.pincode,
              dstContact1: this.state.curHub.contact1,
              dstContact2: this.state.curHub.contact2,
              dstEmailId: this.state.curHub.emailId,
              dstEmailIdAlt: this.state.curHub.emailIdAlt,
            },
          });
        }
      } else if (e.target.value === "customer") {
        let curSourceHub = [];
        const respSrc = await getHubDetails(this.state.selectedSrcHub);
        if (respSrc.data.success === true) {
          this.setState({ curSrcHub: respSrc.data.data.hubs });
          curSourceHub = respSrc.data.data.hubs;
          this.setState({
            data: {
              ...this.state.data,
              destinationType: "customer",
              transMode: "",
              // logisticsProvider: "",
              shipmentNo: "",
              vehicleNo: "",
              shipmentStatus: "00Created",

              srcHubId: curSourceHub.hubId,
              srcHub: curSourceHub.hub,
              // srcType: "",
              srcType: "Hub",
              srcOrg: "BIZLOG",
              srcContactPerson: curSourceHub.contactPerson,
              srcAdd1: curSourceHub.add1,
              srcAdd2: curSourceHub.add2,
              srcLocation: curSourceHub.location,
              srcLandmark: curSourceHub.landmark,
              srcCity: curSourceHub.city,
              srcState: curSourceHub.state,
              srcPincode: curSourceHub.pincode,
              srcContact1: curSourceHub.contact1,
              srcContact2: curSourceHub.contact2,
              srcEmailId: curSourceHub.emailId,
              srcEmailIdAlt: curSourceHub.emailIdAlt,

              dstHubId: this.state.curBlockData.dstHubId,
              dstHub: this.state.curBlockData.dstHub,
              // dstType: this.state.curBlockData.dstType,
              dstType: "customer",
              dstOrg: this.state.curBlockData.dstOrg,
              dstContactPerson: this.state.curBlockData.dstContactPerson,
              dstAdd1: this.state.curBlockData.dstAdd1,
              dstAdd2: this.state.curBlockData.dstAdd2,
              dstLocation: this.state.curBlockData.dstLocation,
              dstLandmark: this.state.curBlockData.dstLandmark,
              dstCity: this.state.curBlockData.dstCity,
              dstState: this.state.curBlockData.dstState,
              dstPincode: this.state.curBlockData.dstPincode,
              dstContact1: this.state.curBlockData.dstContact1,
              dstContact2: this.state.curBlockData.dstContact2,
              dstEmailId: this.state.curBlockData.dstEmailId,
              dstEmailIdAlt: this.state.curBlockData.dstEmailIdAlt,
            },
          });
        }
      } else if (e.target.name === "logisticsMode") {
        this.setState({
          moreInfo: {
            ...this.state.moreInfo,
            paymentMode: this.state.data.paymentMode,
          },
        });
        if (e.target.value === "3PL") {
          const moreInfo = structuredClone(this.state.moreInfo);
          delete moreInfo["vehicleEmptyWt"];
          delete moreInfo["vehicleLoadedWt"];
          moreInfo["paymentMode"] = "prepaid";
          this.setState({
            ...this.state,
            data: {
              ...this.state.data,
              logisticsProvider: "Delhivery",
              vehicleNo: "",
              logisticsMode: "3PL",
              vehicleEmptyWt: "",
              vehicleLoadedWt: "",
              shipmentNo: "",
            },
            moreInfo,
          });
        } else if (e.target.value === "Vehicle") {
          this.setState({
            ...this.state,
            data: {
              ...this.state.data,
              logisticsProvider: "",
              logisticsMode: "Vehicle",
            },
          });
        }
      } else if (e.target.name === "logisticsProvider") {
        if (e.target.value === "Delhivery") {
          this.setState({
            ...this.state,
            data: {
              ...this.state.data,
              logisticsProvider: "Delhivery",
              shipmentNo: "",
            },
          });
        }
      } else if (e.target.name === "vehicleEmptyWt") {
        this.setState({
          data: {
            ...this.state.data,
            vehicleEmptyWt: Math.abs(e.target.value),
          },
          moreInfo: {
            ...this.state.moreInfo,
            vehicleEmptyWt: Math.abs(e.target.value),
          },
        });
      } else if (e.target.name === "vehicleLoadedWt") {
        this.setState({
          data: {
            ...this.state.data,
            vehicleLoadedWt: Math.abs(e.target.value),
          },
          moreInfo: {
            ...this.state.moreInfo,
            vehicleLoadedWt: Math.abs(e.target.value),
          },
        });
      } else if (e.target.name === "paymentMode") {
        this.setState({
          data: {
            ...this.state.data,
            paymentMode: e.target.value,
          },

          moreInfo: {
            ...this.state.moreInfo,
            paymentMode: e.target.value,
          },
        });
      } else if (e.target.name === "isFragile") {
        this.setState({
          data: {
            ...this.state.data,
            isFragile: e.target.value,
          },
          moreInfo: {
            ...this.state.moreInfo,
            isFragile: e.target.value,
          },
        });
      } else if (e.target.name === "boxLength") {
        const length = Math.abs(+e.target.value);
        const breadth = Math.abs(+this.state.data.boxBreadth);
        const height = Math.abs(+this.state.data.boxHeight);

        let volWt = "";
        if (length && breadth && height) {
          volWt = (length * breadth * height) / 5;
        }
        this.setState({
          data: {
            ...this.state.data,
            boxLength: e.target.value,
            boxVolumetricWeight: volWt,
          },
          moreInfo: {
            ...this.state.moreInfo,
            boxLength: e.target.value,
            boxVolumetricWeight: volWt,
          },
        });
      } else if (e.target.name === "boxBreadth") {
        const length = Math.abs(+this.state.data.boxLength);
        const breadth = Math.abs(+e.target.value);
        const height = Math.abs(+this.state.data.boxHeight);

        let volWt = "";
        if (length && breadth && height) {
          volWt = (length * breadth * height) / 5;
        }
        this.setState({
          data: {
            ...this.state.data,
            boxBreadth: e.target.value,
            boxVolumetricWeight: volWt,
          },
          moreInfo: {
            ...this.state.moreInfo,
            boxBreadth: e.target.value,
            boxVolumetricWeight: volWt,
          },
        });
      } else if (e.target.name === "boxHeight") {
        const length = Math.abs(+this.state.data.boxLength);
        const height = Math.abs(+e.target.value);
        const breadth = Math.abs(+this.state.data.boxBreadth);

        let volWt = "";
        if (length && breadth && height) {
          volWt = (length * breadth * height) / 5;
        }
        this.setState({
          data: {
            ...this.state.data,
            boxHeight: e.target.value,
            boxVolumetricWeight: volWt,
          },
          moreInfo: {
            ...this.state.moreInfo,
            boxHeight: e.target.value,
            boxVolumetricWeight: volWt,
          },
        });
      } else if (e.target.name === "boxActualWeight") {
        this.setState({
          data: {
            ...this.state.data,
            boxActualWeight: e.target.value,
          },
          moreInfo: {
            ...this.state.moreInfo,
            boxActualWeight: e.target.value,
          },
        });
      } else if (
        e.target.id === "srcAddressHeading" ||
        e.target.id === "dstAddressHeading" ||
        e.target.id === "boxDetailsHeading"
      ) {
        e.target.classList.toggle("toggleInput");
      }
      else if (e.target.name === "fileUpload") {
        // console.log(e.target.files[0]);
        this.setState({ requiredFile: e.target.files[0] })

      }
      // else if (e.target.name === "boxBarcode") {
      //   this.setState({
      //     data: {
      //       ...this.state.data,
      //       boxBarcode: e.target.value,
      //     },
      //     moreInfo: {
      //       ...this.state.moreInfo,
      //       boxBarcode: e.target.value,
      //     },
      //   });
      // }

      // else{
      //   // this.resetDataForm();

      // }
      let datas = {};
      datas = { ...this.state.data };
      let output = {};
      // console.log("localHandleChange output initialization", datas)
      output = this.jsonConcat(output, datas);

      let key = "moreInfo";
      let obj = {};
      let key1 = "blocks";
      let obj1 = {};

      const modObj = [];
      for (var keys of Object.keys(this.state.blocks)) {
        delete this.state.blocks[keys]["checkState"];
        modObj.push(this.state.blocks[keys]);
      }
      obj[key] = this.state.moreInfo;
      obj1[key1] = modObj;
      output = this.jsonConcat(output, obj);
      output = this.jsonConcat(output, obj1);

      this.setState({ output });
    } catch {
      console.log("Error in createManifest>>>>>> localHandleChange");
    }
  };

  handleBarcodeChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        boxBarcode: e.target.value,
      },
      moreInfo: {
        ...this.state.moreInfo,
        boxBarcode: e.target.value,
      },
    });
  };

  handleDimensionInputChange = (e, r) => {
    const curProductId = r.products[0]?.ticketProductId;

    if (!this.state.moreInfo[r.ticketBlockId]) {
      this.state.moreInfo = {
        ...this.state.moreInfo,
        [r.ticketBlockId]: {},
      };
    }

    if (!(curProductId in this.state.moreInfo[r.ticketBlockId])) {
      this.state.moreInfo = {
        ...this.state.moreInfo,
        [r.ticketBlockId]: {
          ...this.state.moreInfo[r.ticketBlockId],
          [curProductId]: {
            productCode: r.products[0]?.productCode,
            productName: r.products[0]?.productName,
            length: "",
            breadth: "",
            height: "",
            actualWt: "",
            volumetricWt: "",
          },
        },
      };
    }

    if (e.target.name === `${curProductId}-ln`) {
      const length = Math.abs(+e.target.value);

      const breadth = Math.abs(
        +this.state.moreInfo[r.ticketBlockId][curProductId].breadth
      );
      const height = Math.abs(
        +this.state.moreInfo[r.ticketBlockId][curProductId].height
      );


      this.state.blocksArr.map((b) => {
        if (curProductId === b.blockProducts) {
          b.productL = +e.target.value
        }
      })

      let volWt = "";
      if (length && breadth && height) {
        volWt = (length * breadth * height) / 5;
      }
      this.setState({
        [`${curProductId}-ln`]: length,
        moreInfo: {
          ...this.state.moreInfo,

          [r.ticketBlockId]: {
            ...this.state.moreInfo[r.ticketBlockId],

            [curProductId]: {
              ...this.state.moreInfo[r.ticketBlockId][curProductId],
              length: length,
              volumetricWt: volWt,
            },
          },
        },
      });
    }
    if (e.target.name === `${curProductId}-bd`) {
      const length = +this.state.moreInfo[r.ticketBlockId][curProductId].length;
      const breadth = Math.abs(+e.target.value);
      const height = +this.state.moreInfo[r.ticketBlockId][curProductId].height;

      this.state.blocksArr.map((b) => {
        if (curProductId === b.blockProducts) {
          b.productB = +e.target.value
        }
      })

      let volWt = "";
      if (length && breadth && height) {
        volWt = (length * breadth * height) / 5;
      }
      this.setState({
        [`${curProductId}-bd`]: breadth,
        moreInfo: {
          ...this.state.moreInfo,
          [r.ticketBlockId]: {
            ...this.state.moreInfo[r.ticketBlockId],
            [curProductId]: {
              ...this.state.moreInfo[r.ticketBlockId][curProductId],
              breadth: breadth,
              volumetricWt: volWt,
            },
          },
        },
      });
    }
    if (e.target.name === `${curProductId}-ht`) {
      const length = +this.state.moreInfo[r.ticketBlockId][curProductId].length;
      const breadth =
        +this.state.moreInfo[r.ticketBlockId][curProductId].breadth;
      const height = Math.abs(+e.target.value);

      this.state.blocksArr.map((b) => {
        if (curProductId === b.blockProducts) {
          b.productH = +e.target.value
        }
      })

      let volWt = "";
      if (length && breadth && height) {
        volWt = (length * breadth * height) / 5;
      }

      this.state.blocksArr.map((b) => {
        if (curProductId === b.blockProducts) {
          b.productVolumetricWeight = +volWt
        }
      })

      this.setState({
        [`${curProductId}-ht`]: height,
        moreInfo: {
          ...this.state.moreInfo,
          [r.ticketBlockId]: {
            ...this.state.moreInfo[r.ticketBlockId],
            [curProductId]: {
              ...this.state.moreInfo[r.ticketBlockId][curProductId],
              height: height,
              volumetricWt: volWt,
            },
          },
        },
      });
    }

    if (e.target.name === `${curProductId}-actWt`) {
      this.setState({
        [`${curProductId}-actWt`]: Math.abs(+e.target.value),
        moreInfo: {
          ...this.state.moreInfo,
          [r.ticketBlockId]: {
            ...this.state.moreInfo[r.ticketBlockId],
            [curProductId]: {
              ...this.state.moreInfo[r.ticketBlockId][curProductId],
              actualWt: Math.abs(+e.target.value),
            },
          },
        },
      });

      this.state.blocksArr.map((b) => {
        if (curProductId === b.blockProducts) {
          b.productActualWeight = +e.target.value
        }
      })
    }

    // if(this.state[`${curProductId}-ln`] &&
    // this.state[`${curProductId}-bd`] &&
    // this.state[`${curProductId}-ht`]){
    //   this.setState({
    //     moreInfo: {
    //       ...this.state.moreInfo,
    //       [r.ticketBlockId] : {
    //       ...this.state.moreInfo[r.ticketBlockId],
    //       [curProductId]: {
    //         ...this.state.moreInfo[r.ticketBlockId][curProductId],
    //         volumetricWt: (parseFloat(this.state[`${curProductId}-ln`]) * parseFloat(this.state[`${curProductId}-bd`]) * parseFloat(this.state[`${curProductId}-ht`])) / 5000
    //       }
    //     }
    //     }
    //   })
    // }
  };

  renderBoxDimensionsForm = () => {
    const boxDetailRow = [];

    for (let i = 1; i <= this.state.data.boxCount; i++) {
      const temp = (
        <div key={`box#${i}`} className="form-row">
          {`Box  ${i}/${this.state.data.boxCount}`}

          {this.rTB("Length", `lnBox#${i}`, "col-2")}
          {this.rTB("Breadth", `btBox#${i}`, "col-2")}
          {this.rTB("Height", `htBox#${i}`, "col-2")}
          {this.rTBD("Vol. Wt", `volWt#${i}`, "col-2")}
          {this.rTB("Actual Wt (kg)", `actWt#${i}`, "col-2")}
        </div>
      );

      if (
        this.state.data[`lnBox#${i}`] &&
        this.state.data[`btBox#${i}`] &&
        this.state.data[`htBox#${i}`]
      ) {
        const vol =
          this.state.data[`lnBox#${i}`] *
          this.state.data[`btBox#${i}`] *
          this.state.data[`htBox#${i}`];
        this.state.data[`volWt#${i}`] = vol;
      }
      boxDetailRow.push(temp);
    }

    return boxDetailRow;
  };

  handleScan = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (this.state.data.boxBarcode.length === 6) {
        const resp = await getDetailsBySlNoStatus(this.state.data.boxBarcode);
        if (resp.data.success === true) {
          this.setState({
            data: {
              ...this.state.data,
              boxBarcodeId: resp.data.data.slNo[0].slNoId,
              boxUOM: "cm",
            },
            moreInfo: {
              ...this.state.moreInfo,
              boxBarcodeId: resp.data.data.slNo[0].slNoId,
              boxUOM: "cm",
            },
          });
        } else {
          this.setState({
            data: {
              ...this.state.data,
              boxBarcodeId: "",
              boxUOM: "",
            },
            moreInfo: {
              ...this.state.moreInfo,
              boxBarcodeId: "",
              boxUOM: "",
            },
          });
        }
      } else {
        toast.toastError("Barcode number should be exactly 6 digits long")
      }
    }
  };

  selectedFilesLists = (e) => {
    let fileObj = [];
    let fileArray = [];
    fileObj.push(e);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(fileObj[0][i]);
    }
    this.setState({ file: fileArray });

    let data = this.state.data;
    data.selectedFilesList = e;
    this.setState({ data });
  };

  deleteFile(e) {
    const s = this.state.file.filter((item, index) => index !== e);
    this.setState({ file: s });
    let data = this.state.data;
    data.selectedFilesList = s;
    data.selectedFilesList = "";
    this.setState({ data });
    document.getElementById("refDocFiles").value = "";
  }

  render() {
    // const manifestsJSON = this.state.manifests;
    if (this.state.data.logisticsMode === "Vehicle") {
      this.state.data.transMode = "road";
      this.state.data.linehaulMode = "road";
      this.state.data.shipmentNo = "NA";
    } else {
      this.state.data.paymentMode = "prepaid";
    }
    let volWt = "";
    if (
      +this.state.boxLength &&
      +this.state.boxBreadth &&
      +this.state.boxHeight
    ) {
      volWt = (
        (this.state.boxLength * this.state.boxBreadth * this.state.boxHeight) /
        5000
      ).toFixed(2);
    }

    return (
      <>
        <div className="form-row col-12">
          <div className="card col-2">
            <div className="card-body">
              <div className="page-todo">
                <div className="tasks">
                  {this.state.linehaulCount.map((c) => (
                    <div
                      className={"task nil"}
                      key={c.hub}
                      style={{
                        backgroundColor:
                          this.state.curDstHubId === c.dstHubId
                            ? "wheat"
                            : undefined,
                      }}
                    >
                      <div className="desc">
                        <span
                          className="span-click"
                          onClick={() => this.handleDstClick(c)}
                        >
                          <div className="title">{c.hub}</div>
                        </span>
                      </div>
                      <div className="pull-right">
                        <div className="menu pt-3">
                          <span className={"badge badge-success pull-right "}>
                            {c.count}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-10">
            <div className="row">
              <div className="col-8">
                {this.props.loading && (
                  <div className="container-fluid">
                    <div> Loading users...</div>
                  </div>
                )}
                {!this.props.loading && (
                  <>
                    {this.productModalShow}
                    {/* <div style={{
                      marginTop : "2rem",
                     
                    }}>
                      {this.rRBL(
                      "Destination *",
                      "destinationType",
                      this.destinationType,
                      {
                        value: "destinationTypeId",
                        label: "destinationType",
                      },
                      "col-4"
                    )}
                    </div> */}
                    <Table
                      clsName="table table-striped table-bordered table-hover"
                      tableMeta={this.tableMeta}
                      data={this.state.filteredLinehaulList}
                      dropMenu={[]}
                    />
                    {/* <Pagination
                                    pageNo={pageNo}
                                    perPage={perPage}
                                    itemsCount={count}
                                    onPageChange={this.handlePageChange}
                                    /> */}
                  </>
                )}
              </div>
              {/* {this.state.hubCode !== "HO" && ( */}
              <div className="col-4">
                <br />
                <form id="myForm" onSubmit={this.handleSubmit}>
                  <div className="form-row col-12">
                    <div className="form-row col-12">
                      {this.rRBL(
                        "Destination type *",
                        "destinationType",
                        this.destinationType,
                        {
                          value: "destinationTypeId",
                          label: "destinationType",
                        },
                        "col-6"
                      )}
                      {this.rRBL(
                        "Logistics mode *",
                        "logisticsMode",
                        this.logisticsMode,
                        {
                          value: "logisticsByValue",
                          label: "logisticsByLabel",
                        },
                        "col-6"
                      )}
                    </div>
                    {this.state.data.logisticsMode === "3PL" && (
                      <>
                        <div className="form-row col-12 border border-info rounded pt-5 ps-4 mb-3">
                          {/* {this.rTBD("Logistics Provider *", "logisticsProvider", " col-4")} */}
                          {this.rSB(
                            "Logistics Provider *",
                            "logisticsProvider",
                            this.state.logistics,
                            { value: "logisticsId", label: "logistics" },
                            " col-4"
                          )}
                          {this.rRBL(
                            "Mode of Transport *",
                            "transMode",
                            this.state.transport,
                            { value: "transportId", label: "transport" },
                            " col-4"
                          )}
                          {this.rRBL(
                            "Linehaul Mode *",
                            "linehaulMode",
                            this.state.linehaulMode,
                            { value: "linehaulModeId", label: "linehaulMode" },
                            " col-4"
                          )}
                          {this.rRBLD(
                            "Mode of Payment *",
                            "paymentMode",
                            this.state.paymentModes,
                            { value: "paymentModeId", label: "paymentMode" },
                            " col-4"
                          )}
                        </div>
                      </>
                    )}

                    {this.state.data.logisticsMode === "Vehicle" && (
                      <>
                        <div className="form-row col-12 border border-info rounded pt-3 ps-3 mb-3">
                          {this.rTB("Vehicle No ", "vehicleNo", " col-4")}
                          {this.rTB(
                            "Logistics Provider *",
                            "logisticsProvider",
                            " col-5"
                          )}
                          {this.rNB(
                            "Vehicle Empty weight (kgs) ",
                            "vehicleEmptyWt",
                            " col-5"
                          )}
                          {this.rNB(
                            "Vehicle Loaded weight (kgs) ",
                            "vehicleLoadedWt",
                            " col-5"
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div className="form-row col-12">
                    {/* {this.state.data.logisticsProvider !== "Delhivery" &&
                      this.state.data.logisticsMode === "3PL" &&
                      this.rTB("Shipment No", "shipmentNo", " col-11")} */}
                    {/* {this.rTB("Vehicle No ", "vehicleNo", " col-4")} */}
                    <button
                      className="badge badge-danger "
                      style={{
                        width: "16%",
                        height: " 30px",
                        marginLeft: "300px",
                      }}
                      onClick={() => this.resetDataForm()}
                    >
                      Reset form
                    </button>
                  </div>
                  {/*   */}
                  <div className="card">
                    <div className="card-body">
                      <h5
                        className="card-subtitle mb-2 text-center mx-0 rounded px-0 mt-0 py-1 shadow-sm"
                        id="boxDetailsHeading"
                        onClick={this.localHandleChange}
                      >
                        Box Details
                      </h5>
                      <div className="form-row col-12 boxLevelInputs ">
                        {this.rRBL(
                          "is Fragile ",
                          "isFragile",
                          this.isFragile,
                          { value: "value", label: "label" },
                          " col-6 mt-3 mb-0"
                        )}
                        {/* {this.rTBBlur("Box Barcode *", "boxBarcode", " col-12")} */}
                        {this.state.prdBrcRes !== false && (
                          <div>
                            <label
                              htmlFor={"Box Barcode "}
                              style={{ color: "#999" }}
                              className="control-label"
                            >
                              {"Box Barcode"}
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Barcode"
                              className="col-12 mb-3"
                              style={{
                                border: "none",
                                outline: "none",
                                borderBottom: "1px solid #999",
                                backgroundColor: "#f2f3f8",
                              }}
                              onChange={(e) => this.handleBarcodeChange(e)}
                              onKeyDown={(e) => this.handleScan(e)}
                              value={this.state.data.boxBarcode}
                            />
                          </div>
                        )}
                        {this.state.data.boxBarcodeId !== "" && this.state.prdBrcRes !== false && (
                          <>
                            {this.rNB(
                              "Box Actual Weight (grams) *",
                              "boxActualWeight",
                              " col-12"
                            )}

                            {this.rNB(
                              "Box Length (cm) *",
                              "boxLength",
                              " col-12"
                            )}
                            {this.rNB(
                              "Box Breadth (cm) *",
                              "boxBreadth",
                              " col-12"
                            )}
                            {this.rNB(
                              "Box Height (cm) *",
                              "boxHeight",
                              " col-12"
                            )}

                            <p>
                              Box volumetric weight (grams):{" "}
                              {this.state.moreInfo?.boxVolumetricWeight}
                            </p>
                          </>
                        )}
                        {this.state.prdBrcRes === false && (
                          <>
                            {this.rNB(
                              "Box Actual Weight (grams) *",
                              "boxActualWeight",
                              " col-12"
                            )}

                            {this.rNB(
                              "Box Length (cm) *",
                              "boxLength",
                              " col-12"
                            )}
                            {this.rNB(
                              "Box Breadth (cm) *",
                              "boxBreadth",
                              " col-12"
                            )}
                            {this.rNB(
                              "Box Height (cm) *",
                              "boxHeight",
                              " col-12"
                            )}

                            <p>
                              Box volumetric weight (grams):{" "}
                              {this.state.moreInfo?.boxVolumetricWeight}
                            </p>
                          </>
                        )}
                      </div>

                      <h5
                        className="card-subtitle mb-2 text-center mx-0 rounded px-0 mt-0 py-1 shadow-sm"
                        id="srcAddressHeading"
                        onClick={this.localHandleChange}
                      >
                        Source Address
                      </h5>
                      <div className="form-row col-12 srcAddressInputs">
                        {this.rTB("Src Hub *", "srcHub", " col-6")}
                        {this.rTB("Src Type ", "srcType", " col-6")}
                        {/* {this.rTB("Org ", "srcOrg", " col-6")} */}
                        {this.rTB(
                          "Contact Person *",
                          "srcContactPerson",
                          " col-6"
                        )}

                        {this.rTB("Add1 *", "srcAdd1", " col-6")}
                        {this.rTB("Add2", "srcAdd2", " col-6")}
                        {this.rTB("Loc *", "srcLocation", " col-6")}
                        {/* {this.rTB("Landmark ", "srcLandmark", " col-6")} */}

                        {/* {this.rTB("City ", "srcCity", " col-6")} */}
                        {this.rTB("State ", "srcState", " col-6")}
                        {this.rTB("Pincode *", "srcPincode", " col-6")}

                        {this.rTB("Phone 1 *", "srcContact1", " col-6")}
                        {/* {this.rTB("Phone 2 ", "srcContact2", " col-3")} */}
                        {/* {this.rTB("Email Id", "srcEmailId", " col-6")} */}
                        {/* {this.rTB("Email Id Alt ", "srcEmailIdAlt", " col-6")} */}
                      </div>

                      <h5
                        className="card-subtitle mb-2   text-center mx-0 rounded px-0 mt-0 py-1 shadow-sm"
                        id="dstAddressHeading"
                        onClick={this.localHandleChange}
                      >
                        Destination Address
                      </h5>
                      <div className="form-row col-12 dstAddressInputs">
                        {this.rTB("Dst Hub *", "dstHub", " col-6")}
                        {this.rTBD("Dst Type ", "dstType", " col-6")}
                        {/* {this.rTB("Org ", "dstOrg", " col-6")} */}
                        {this.rTB(
                          "Contact Person *",
                          "dstContactPerson",
                          " col-6"
                        )}

                        {this.rTB("Add1 *", "dstAdd1", " col-6")}
                        {this.rTB("Add2", "dstAdd2", " col-6")}
                        {this.rTB("Loc * ", "dstLocation", " col-6")}

                        {/* {this.rTB("City", "dstCity", " col-6")} */}
                        {this.rTB("State", "dstState", " col-6")}
                        {this.rTB("Pincode *", "dstPincode", " col-6")}
                        {/* {this.rTB("Landmark ", "dstLandmark", " col-6")} */}
                        {this.rTB("Phone 1 *", "dstContact1", " col-6")}
                        {/* {this.rTB("Phone 2 ", "dstContact2", " col-3")} */}
                        {/* {this.rTB("Email Id", "dstEmailId", " col-6")} */}
                        {/* {this.rTB("Email Id Alt ", "dstEmailIdAlt", " col-6")} */}
                      </div>

                      {this.state.prdBrcRes !== false && (
                        <>
                          <h5
                            className="card-subtitle mb-2 text-center mx-0 rounded px-0 mt-0 py-1 shadow-sm"
                            id="boxDetailsHeading"
                            onClick={this.localHandleChange}
                          >
                            Upload File
                          </h5>
                          <div className="form-row col-12 boxLevelInputs ">
                            <div
                              className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3 "
                              style={{ backgroundColor: "#f5f0f0" }}
                            >
                              <div className="m-4 p-1 col-10 mt-2 ml-4">
                                <label
                                  htmlFor="refDocFiles"
                                  className="form-label font-monospace"
                                >
                                  Upload Files *
                                </label>
                                <input
                                  className="form-control form-control-sm"
                                  multiple
                                  id="refDocFiles"
                                  type="file"
                                  name="selectedFilesList"
                                  onChange={(e) => {
                                    this.selectedFilesLists(e.target.files);
                                  }}
                                />
                              </div>
                              {this.rTB("File Info *", "fileInfo", " col-12 mt-3 mb-4")}
                              <div
                                className="col-12 page-todo overflow-auto"
                                style={{ height: "15rem" }}
                              >
                                <div className="tasks">
                                  <div className="form-group multi-preview">
                                    {(this.state.file || []).map((url, index) => (
                                      <div key={url}>
                                        <li className="list-group-item" key={index}>
                                          <p>
                                            <a>{url.name}</a>
                                          </p>{" "}
                                          <img
                                            className="mt-5"
                                            src={URL.createObjectURL(url)}
                                            alt={url.name}
                                            key={index}
                                            style={{ height: "50px", width: "100px" }}
                                          />
                                          <button
                                            type="button"
                                            style={{
                                              position: "absolute",
                                              marginLeft: "30px",
                                            }}
                                            onClick={() => this.deleteFile(index)}
                                          >
                                            delete
                                          </button>
                                        </li>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                    </div>
                  </div>

                  {this.rBtnSP("Request Manifest ", "create", "btn btn-primary btn-sm pull-right")}
                </form>
              </div>
            </div>

            <br />
          </div>
        </div>
        {this.state.productModalShow && (
          <div className="cus-modal1" id="myModalProduct">
            <button
              onClick={() => this.handleProductModalClose()}
              type="button"
              className="close"
            >
              <span>&times;</span>
            </button>
            <div role="tabpanel">
              <div className="text-center">
                {Array.isArray(this.state.productsList) &&
                  this.state.productsList.map((p, index) => {
                    p.srcHub = this.state.curBlockSrcHub;
                    p.dstHub = this.state.curBlockDstHub;
                    return (
                      <div
                        className="card mb-2 cursorPointor"
                        key={
                          p.processId +
                          p.ticketBlockId +
                          p.ticketProductId +
                          index
                        }
                      >
                        <label>
                          <input
                            type="radio"
                            name={p.productName}
                            value={index}
                            onClick={(e) =>
                              this.handleCheckBoxProduct(e, p, index)
                            }
                          />
                          &nbsp;{p.productName} - [{p.productCode}]
                          <i className="helper"></i>
                        </label>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
