import FormLib from "../../_common/FormLib";
import React from "react";
import ZTitle from "./../../_subhead/ZTitle";
import { getTeams } from "./../../../services/players/teamServices";
import toast from "../../../services/_toastService";
import {
  getActionCategory,
  getTicketActionDetails,
  getTicketActionsDetails,
} from "./../../../services/Tickets/TicketProcessServices";
import Joi from "joi-browser";
import Table from "./../../_common/Table";
import { TicketActionsData } from "./data";
import { dropMenuFull } from "./../../../utils/DropMenuUtils";
import DocumentDetails from "./actions/DocumentDetails";
import ExpenseRise from "./actions/ExpenseRise";
import TicketList from "./actions/TicketDetailsList";

import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import Pagination from "../../_common/Pagination";
import { async } from "rxjs";
import { blockStatus } from "./../../../services/linehaul/linehaulServices";

class TicketActivities extends FormLib {
  state = {
    data: {
      startDate: this.getDatebyDays(0),
      endDate: this.getDatebyDays(0),
      hubId: [],
      searchString: "",
      actionCatId: "",
    },
    TicketActionsDatas: [],
    TicketActionsData: [],

    userCode: "",
    errors: {},
    hubs: [],
    clients: [],
    actionsList: [],
    currentRequest: {},
    openStatus: true,
    documentDetails: false,
    ticketStatus: true,
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };

  schema = {
    startDate: Joi.string().required().label("Start Date"),
    endDate: Joi.string().required().label("End Date"),
    hubId: Joi.required().label("Hub"),
    searchString: Joi.optional().label("searchString"),
    actionCatId: Joi.required().label("actionCatId"),
  };

  tableMeta = {
    key: "refNo",
    columns: [
      { path: "actionNo", label: "ActionNo." },
      { path: "actionCat", label: "Action" },
      { path: "hub", label: "Hub" },
      // { path: "clientName", label: "Client" },
      // { path: "ticketNo", label: "TicketNo" },
      { path: "venCode", label: "Vendor Code" },
      { path: "vendor", label: "Vendor" },
      { path: "isBillable", label: "isBillable" },

      // {
      //   key: "flowStatus",
      //   action: (r) => (
      //     <b>
      //       {r.flowStatus === "PICK_WEB" &&
      //         (r.blockStatus !== "LINEHAUL" ||
      //           r.blockStatus !== "LINEHAUL_IN_TRANSIT") &&
      //         "First Mile"}{" "}
      //       {(r.blockStatus === "LINEHAUL" ||
      //         r.blockStatus === "LINEHAUL_IN_TRANSIT") &&
      //         "Middle Mile"}{" "}
      //       {r.flowStatus === "DROP_WEB" && "Last Mile"}
      //     </b>
      //   ),
      //   cusClass: "",
      //   icon: () => <>Flow</>,
      // },

      // {
      //   key: "blockStatus",
      //   action: (r) => <b>{this.replaceString(r.blockStatus)}</b>,
      //   cusClass: "",
      //   icon: () => <>Job Status</>,
      // },
      {
        key: "Files",
        action: (r) => (
          <>
            <div className="d-flex justify-content-center">
              <i
                className="fa fa-upload cursorPointor ="
                style={{ fontSize: "30px", color: "#1974D2" }}
                onClick={() => this.onloadDocumentDetails(r)}
                title="View / Upload files"
              ></i>
            </div>
          </>
        ),
        icon: () => <div className="d-flex justify-content-center">Files</div>,
      },
      {
        key: "refNo",
        action: (r) => (
          <>
            <div className="d-flex justify-content-center">
              <i
                className="fa fa-ticket cursorPointor ="
                style={{ fontSize: "30px", color: "#930000" }}
                onClick={() => this.viewTicketsList(r)}
                title="View Tickets"
              ></i>
            </div>
          </>
        ),
        icon: () => (
          <div className="d-flex justify-content-center">Ticket Details</div>
        ),
      },
      { path: "expenseNo", label: "ExpenseNo." },

      {
        key: "Expense",
        action: (r) => (
          <>
            <div className="d-flex justify-content-center">
              {(r.count === 0 ||
                r.count === null ||
                r.count === "null" ||
                r.count === "") && (
                <>
                  <i
                    className="fa fa-telegram cursorPointor ="
                    style={{ fontSize: "30px", color: "green" }}
                    onClick={() => this.handleExpenseRise(r)}
                    title="Raise Expense"
                  ></i>
                </>
              )}
              {r.count > 0 && (
                <>
                  <i
                    className="fa fa-telegram cursorPointor ="
                    style={{ fontSize: "30px", color: "#DB9706" }}
                    onClick={() => this.handleExpenseRise(r)}
                    title="Raise Expense"
                  ></i>
                  <div class="icon-badge-container">
                    <div
                      class="icon-badge cursorPointor"
                      title="Number of Expense Raised"
                    >
                      {r.count}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ),
        icon: () => (
          <div className="d-flex justify-content-center">Raise Expense</div>
        ),
      },
    ],
  };

  replaceString = (str) => {
    if (str) {
      str = str.replace(/_/g, " ");
      return str.replace("BLOCK", "JOB");
    }
  };
  onloadDocumentDetails = (r) => {
    // alert(JSON.stringify(r));
    this.setState({ documentDetails: "true" });
    this.setState({ openStatus: "false" });
    this.setState({ ticketStatus: "true" });

    this.setState({ currentRequest: r });
  };

  handleExpenseRise = async (r) => {
    let resp = await getTicketActionsDetails(r.ticketActionId);
    if (resp.data.success === true) {
      if (resp.data.data) {
        // toast.success("Ticket Action Fetched Successfully");
        this.setState({ ticketActions: resp.data.data.actions });
        this.setState({ ticketDetails: resp.data.data.tickets });
      } else {
        !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
      }
    } else {
      toast.error(() => <div>{resp.data.msg}</div>);
    }
    this.setState({ documentDetails: "false" });
    this.setState({ openStatus: "true" });
    this.setState({ ticketStatus: "false" });

    this.setState({ currentRequest: r });
  };

  viewTicketsList = async (r) => {
    let resp = await getTicketActionsDetails(r.ticketActionId);
    if (resp.data.success === true) {
      if (resp.data.data) {
        this.setState({ ticketActions: resp.data.data.actions });
        this.setState({ ticketDetails: resp.data.data.tickets });
      } else {
        !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
      }
    } else {
      toast.error(() => <div>{resp.data.msg}</div>);
    }
    this.setState({ documentDetails: "false" });
    this.setState({ openStatus: "false" });
    this.setState({ ticketStatus: "true" });
  };

  componentDidMount() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.state.userCode = userInfo.code;
    this.state.userInfoTeamId = userInfo.teamId;
    this.loadHubs();
    this.loadRetailers();
    this.getActionCategory();
    this.getTicketActionsData();
    this.setState({ loading: true });
  }
  async loadHubs() {
    try {
      let hubList = JSON.parse(localStorage.getItem("hubs"));
      this.setState({ hubs: hubList });
    } catch {
      console.log("Error in TicketActivities>>>>>> loadHubs");
    }
  }

  async loadRetailers() {
    try {
      const res = await getTeams();
      if (res !== undefined) {
        if (res.data.success === true) {
          this.setState({ clients: res.data.data.teams });
        } else {
          toast.toastError(res.data.msg);
        }
      }
    } catch {
      console.log("Error in TicketActivities>>>>>> loadRetailers");
    }
  }

  getTicketActionsData = async () => {
    try {
      let TicketActionsDatas = await TicketActionsData;
      // console.log(JSON.stringify(TicketActionsDatas));
      this.setState({ TicketActionsDatas });
      this.setState({ loading: false });
    } catch {
      console.log("Error in TicketActivities>>>>>> getTicketActionsData");
    }
  };

  getActionCategory = async () => {
    try {
      let actionsList = [];
      let resp = await getActionCategory();
      if (resp.data.success === true) {
        if (resp.data.data) {
          let result = resp.data.data.ticketActionsCatList;
          this.setState({ actionsList: result });
          toast.success(() => <div>{resp.data.msg}</div>);
        } else {
          toast.warning(() => <div>{"Data not available"}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in TicketActivities>>>>>> getActionCategory");
    }
  };
  async doSubmit() {
    try {
      let res = await getTicketActionDetails(
        this.state.data,
        this.state.pageLinks.pageNo
      );
      if (res.data.data.ticketActions.length === 0) {
        toast.toastInfo("No data");
        this.setState({ loading: true });
      } else {
        this.setState({ TicketActionsData: res.data.data.ticketActions });
        this.setState({ pageLinks: res.data.data.pageLinks });

        this.setState({ loading: false });
      }
    } catch {
      console.log("Error in TicketActivities>>>>>> doSubmit");
    }
  }

  localHandleChangeMulti = async (e) => {
    let pageLinks = this.state.pageLinks;
    pageLinks["pageNo"] = "1";
    this.setState({ pageLinks });
  };

  handleView = () => {
    $("#myDocumentDetails").draggable();
  };
  closeModal = () => {
    this.setState({ openStatus: "false" });
    this.setState({ documentDetails: "false" });
    this.setState({ ticketStatus: "false" });
  };

  handlePageChange = (pageNo) => {
    this.props.history.push("/ticketActivities/" + pageNo);
    let pageLinks = this.state.pageLinks;
    pageLinks["pageNo"] = pageNo;
    this.setState({ pageLinks });
    this.doSubmit();
  };

  handleRequestChange = async () => {
    try {
      this.doSubmit();
    } catch {
      console.log("Error in TicketActivities>>>>>> handleRequestChange");
    }
  };
  render() {
    const reportJSON = this.state.TicketActionsDatas;
    const ticketActionJSON = this.state.TicketActionsData;
    const currentRequest = this.state.currentRequest;
    const ticketRequest = this.state.ticketDetails;
    const ticketActions = this.state.ticketActions;

    const { pageNo, perPage, itemsCount } = this.state.pageLinks;

    return (
      <>
        <ZTitle subHeading="Ticket Activities" />
        <div
          className="pageContent"
          // style={{ backgroundColor: "rgb(251 112 3 / 18%)" }}
        >
          <div
            className="card"
            style={{ backgroundColor: "rgb(210 126 49 / 0%)" }}
          >
            <div
              className="card-body"
              style={{
                backgroundColor: "white",
                maxWidth: "97%",
                marginLeft: "1%",
              }}
            >
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-11">
                    <div className="form-row mt-2 ">
                      {this.rDPF("Start Date *", "startDate", " col-md-2 ")}
                      {this.rDPF("End Date *", "endDate", " col-md-2 ")}
                      <div style={{ paddingLeft: "15px", marginTop: "-5px" }}>
                        {this.rSBMulti(
                          "Hub",
                          "hubId",
                          this.state.hubs,
                          { value: "hubId", label: "hub" },
                          " col-md-2 "
                        )}
                      </div>

                      {/* {this.rTB(
                        "Search ticket number",
                        "searchString",
                        "col-md-2"
                      )} */}
                      <div
                        style={{
                          paddingLeft: "15px",
                          paddingRight: "15px",
                          marginTop: "-5px",
                        }}
                      >
                        {this.rSBMulti(
                          "ExpenseHead",
                          "actionCatId",
                          this.state.actionsList,
                          { value: "actionCatId", label: "actionCat" },
                          " col-md-2"
                        )}
                      </div>
                      {/* {this.rSB(
                        "Expenses Head :",
                        "actionCatId",
                        this.state.actionsList,
                        { value: "actionCatId", label: "actionCat" },
                        " col-md-2 ml-4"
                      )} */}
                    </div>
                  </div>
                  <div
                    className="col-1 pull-right"
                    style={{ paddingTop: "65px", paddingLeft: "37px" }}
                  >
                    {this.rBtnSP("Submit", "Submit")}
                  </div>
                </div>
              </form>
            </div>
            {ticketActionJSON.length > 0 && (
              <div className="form-row mt-5">
                {this.props.loading && (
                  <div className="container-fluid">
                    <div> Loading Tickets Activities...</div>
                  </div>
                )}
                {!this.props.loading && (
                  <>
                    <div className="container-fluid">
                      <div style={{ overflow: "auto" }}>
                        <Table
                          clsName="table table-striped table-bordered table-hover"
                          tableMeta={this.tableMeta}
                          data={this.state.TicketActionsData}
                          dropMenu={[]}
                        />
                      </div>
                    </div>
                    <Pagination
                      pageNo={pageNo}
                      perPage={perPage}
                      itemsCount={itemsCount}
                      onPageChange={this.handlePageChange}
                    />
                  </>
                )}
              </div>
            )}

            <div
              className="draggable col-10 ml-5 mr-5"
              id="myDocumentDetails"
              onClick={this.handleView}
            >
              {this.state.TicketActionsDatas &&
                this.state.openStatus === "false" &&
                this.state.documentDetails === "true" &&
                this.state.ticketStatus === "true" && (
                  <DocumentDetails
                    key={currentRequest.refNo}
                    data={currentRequest}
                    closeModal={this.closeModal}
                  />
                )}
            </div>

            <div
              className="draggable  col-sm-12 col-md-8 col-lg-8 "
              id="myModalApprove"
              onClick={this.handleView}
            >
              {this.state.TicketActionsDatas &&
                this.state.openStatus === "true" &&
                this.state.documentDetails === "false" &&
                this.state.ticketStatus === "false" && (
                  <ExpenseRise
                    key={currentRequest.ticketNo}
                    data={currentRequest}
                    type="report"
                    ticketDetails={ticketRequest}
                    ticketActionId={currentRequest.ticketActionId}
                    requestChange={this.handleRequestChange}
                    // user={this.state.user}
                    closeModal={this.closeModal}
                  />
                )}
            </div>

            <div
              className="draggable  col-sm-12 col-md-8 col-lg-8"
              id="myModalTicket"
              onClick={this.handleView}
            >
              {this.state.TicketActionsDatas &&
                this.state.openStatus === "false" &&
                this.state.documentDetails === "false" &&
                this.state.ticketStatus === "true" && (
                  <TicketList
                    key={currentRequest.ticketNo}
                    data={currentRequest}
                    type="report"
                    ticketDetails={ticketActions}
                    // requestStatusChange={this.handleRequestStatusChange}
                    // user={this.state.user}
                    closeModal={this.closeModal}
                  />
                )}
            </div>
          </div>
        </div>
        {this.state.loading == true && (
          <div className="container-fluid jumbotron text-center">
            <h4>No Data Available...</h4>
          </div>
        )}
      </>
    );
  }
}

export default TicketActivities;
