import React from "react";
import FormLib from "./../../../_common/FormLib";
import Table from "./../../../_common/Table";
import { toast } from "react-toastify";
import Pagination from "./../../../_common/Pagination";
// import { getHubs } from "../../../../services/masterLocations/hubServices";
// import { getClients } from "../../../../services/masters/hubServices";
import {
  // getOutgoing,
  getOutgoingReview,
  getOutgoingReviewExport,
  updateShipmentNo,
  blockStatus,
  getLinehaulTicktes,
  getLinehaulProductsV1,
  updateAwbNO,
} from "../../../../services/linehaul/linehaulServices";
import dateFormat from "dateformat";

import Joi, { relative } from "joi-browser";
import UpdateShipmentNoModal from "./UpdateShipmentNoModal";
import {
  IstToUTC,
  convertyyyymmddToddmmyyyy,
  epoch2yyyymmddhhmmssv1,
} from "../../../../utils/DateUtils";
import { getTeams } from "../../../../services/players/teamServices";
import { ExportXLSXReqLinehaul } from "./../../requestV1/ExportXLSX";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import DocumentDetails from "./actions/DocumentDetails";
import ExpenseRise from "./actions/ExpenseRise";
import { epoch2ddmmyyyyhhmmss, epoch2yyyymmddhhmmss } from "../../../../utils/DateUtils";
import BarCodePrintSVGLinehaul from "../BarcodePrintSVGLinehaul";
import EwayBillPrintSVG from "../EwayBillPrintSVG";
import { IoBarcodeOutline } from "react-icons/io5";
import UpdateShipmentNoModalV1 from "./UpdateShipmentNoModalV1";

export default class ReviewWrapper extends FormLib {
  state = {
    outgoing: [],
    data: {
      search: "",
      hubId: [],
      retailerId: [],
      status: "empty",
    },
    errors: {},
    hubs: [],
    clients: [],
    status: "empty",
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    pageLinksExport: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    currentShipment: {},
    showUpdateBtn: false,
    designation: "",
    fileName: "",
    linehaulTempArray: [],
    documentDetails: false,
    openStatus: "true",
    currentRequest: {},
    productArr: [],
    // reviewManifestBoxDetails: false,
    updateAwbParams: {}
  };
  schema = {
    search: Joi.optional().label("search"),
  };
  // statusData = [
  //   {
  //     label: "Manifested", value: "empty"
  //   },
  //   {
  //     label: "With ShipmentNo", value: "available"
  //   },
  // ]

  async componentDidMount() {
    try {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      userInfo = userInfo.designation;
      this.setState({ designation: userInfo });

      const isAllowed = this.isShipmentNoVisibile();
      this.setState({ showUpdateBtn: isAllowed });

      let hubs = JSON.parse(localStorage.getItem("hubs"));
      this.setState({ hubs });

      await this.loadRetailers();

      if (this.props?.refreshListCallback) {
        await this.props.refreshListCallback(this.getLinehaulList);
      }
      await this.getLinehaulList(this.state.pageLinks.pageNo);
    } catch (error) {
      console.log("Error in >>>>> componentDidMount");
      console.error(error);
      toast.toastError("Error in fetching data");
    }
  }
  async loadHubs() {
    try {
      let hubList = JSON.parse(localStorage.getItem("hubs"));
      this.setState({ hubs: hubList });
    } catch {
      console.log("Error in >>>>>> loadHubs");
    }
  }

  async loadRetailers() {
    try {
      const res = await getTeams();
      // console.log({res});
      if (res !== undefined) {
        if (res.data.success === true) {
          this.setState({ clients: res.data.data.teams });
        } else {
          toast.toastError(res.data.msg);
        }
      }
    } catch {
      console.log("Error in >>>>>> loadRetailers");
    }
  }

  getLinehaulList = async (pageNo = this.state.pageLinks.pageNo) => {
    // console.log({ stateData: this.state });
    try {
      this.setState({ loading: true });
      // let selectedHub = localStorage.getItem("curHub");
      let params = {
        // srcHubId: selectedHub,
        hubId: this.state.data.hubId,
        clientId: this.state.data.retailerId,
        shipmentNo: this.state.data.status,
        searchTicket: this.state.data.search,
      };

      // console.log('====================================');
      // console.log("params",params);
      // console.log('====================================');

      const res = await getOutgoingReview(params, pageNo);
      if (res.data.success === true) {
        if (res.data.data.outgoingShipment !== 0) {
          this.setState({ outgoing: res.data.data.outgoingShipment });
          // this.formatExcelReport(res.data.data.outgoingShipment);

          res.data.data.pageLinks &&
            this.setState({ pageLinks: res.data.data.pageLinks });
        } else {
          toast.info(() => <div>{res.data.msg}</div>);
          this.state.outgoing = [];
        }
      } else {
        toast.error(res.data.msg);
      }
      this.setState({ loading: false });
      this.state.linehaulTempArray = [];

      const resp = await getOutgoingReview(params, 1);
      if (resp.data.success === true) {
        if (res.data.data.outgoingShipment !== 0) {
          resp.data.data.pageLinks &&
            this.setState({ pageLinksExport: resp.data.data.pageLinks });
        } else {
          toast.info(() => <div>{resp.data.msg}</div>);
          this.state.outgoing = [];
        }
      } else {
        toast.error(resp.data.msg);
      }
      // --------------------------------Excel Data Start-------------------------------------//
      for (
        var i = this.state.pageLinksExport.pageNo;
        i <= this.state.pageLinksExport.lastPage;
        i++
      ) {
        const res = await getOutgoingReview(params, i);
        if (res) {
          if (res.data.success === true && res.data.data.outgoingShipment) {
            this.setState({ report: res.data.data.outgoingShipment });
            this.state.report.map(async (r) => {
              this.state.linehaulTempArray.push(r);
            });

            this.setState({ loaderCountCur: i });
          } else {
            const res = await getOutgoingReview(params, i);
            if (res.data.success === true && res.data.data.outgoingShipment) {
              this.setState({ report: res.data.data.outgoingShipment });
              this.state.report.map(async (r) => {
                this.state.linehaulTempArray.push(r);
              });

              this.setState({ loaderCountCur: i });
            }
          }
        } else {
          const res = await getOutgoingReview(params, i);
          if (res.data.success === true && res.data.data.outgoingShipment) {
            this.setState({ report: res.data.data.outgoingShipment });
            this.state.report.map(async (r) => {
              this.state.linehaulTempArray.push(r);
            });

            this.setState({ loaderCountCur: i });
          }
        }
      }
      this.setState({ loaderCountCur: 0 });
      this.setState({ loaderCount: 0 });
      this.setState({ report: this.state.linehaulTempArray });
      ///Excel
      this.formatExcelReport(this.state.report);

      // ---------------------------------Excel Data end--------------------------------//

      // console.log('====================================');
      // console.log({ooy:this.outgoing});
      // console.log('====================================');
    } catch {
      console.log("Error in OutgoingManifest>>>>>> getLinehaulList");
    }
  };
  handleSearch = () => {
    let pageLinks = this.state.pageLinks;
    pageLinks["pageNo"] = "1";

    this.setState({ pageLinks });

    this.getLinehaulList(this.state.pageLinks.pageNo);
  };

  handlePageChange = (pageNo) => {
    // this.props.history.push("/ticketProcess/"+ pageNo);
    this.getLinehaulList(pageNo);
  };

  isLPVehicle = () => { };

  isShipmentNoVisibile = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const curHub = JSON.parse(localStorage.getItem("curHub"));

    const allowed =
      userInfo.username === "admin" ||
      userInfo.designation === "Key Accounts Manager" ||
      this.state.designation === "Operations Head" ||
      this.state.designation === "HO Operations Admin" ||
      (curHub === "5067" && userInfo.designation === "Hub Coordinator");
    // || userInfo.username === "admin";
    return allowed;
  };


  replaceString = (str) => {
    return str.replace(/_/g, " ");
  };
  // getManifestedBoxDetails() {
  //   this.setState({ reviewManifestBoxDetails: true });
  //   alert(this.state.reviewManifestBoxDetails);
  // }

  // getManifestedBoxDetails = () => {
  //   // alert("callingManifestedBoxDetails")
  //   // this.setState({ hubId: e.target.value }, () => {
  //   //   this.loadUsers();
  //   // });
  //   // this.setState({ barcodeData: data }, () => {
  //   //   this.loadSlNoId();
  //   // });
  //   this.setState({ reviewManifestBoxDetails: true });
  // };

  tableMeta = {
    key: "shipmentNo",
    columns: [
      // { path: "linehaulSeq", label: "Linehaul Ref No" },
      {
        key: "linehaulRefNo",
        action: (r) => (
          <>
            <span
              // className="badge badge-success link-over"

              title="Click to Print Barcode"
            >
              {r.linehaulSeq}
              <button
                className="border-0 fs-5 ms-3 bg-transparent"
                title="Print Barcode"
                onClick={() => this.onloadBarcodeDetails(r)}
              >
                {/* {r.boxBarcode && <IoBarcodeOutline />} */}
              </button>
              {/* <i
                className="fa-sharp fa-solid fa-barcode"
                style={{ fontSize: "30px", marginLeft: "50px" }}
                onClick={() => this.onloadBarcodeDetails(r)}
                title="Print Barcode"
              ></i> */}
            </span>
          </>
        ),
        icon: () => <>Linehaul Ref No</>,
        label: "#",
      },

      {
        // ***************************************************dfdfdfd**********************************************************

        // path: "REF_ID",  label: "Ref No."

        key: "ticketNo",
        action: (r) => (
          <>
            <span
              className="badge badge-success link-over"
              onClick={() => this.onloadDocumentDetails(r)}
              title="Click to View/Upload files"
            >
              {/* {r["ticketNo"] && r["ticketNo"].substring(0, 30)}{" "} */}
              {r["multiTicketIds"]
                ? r["multiTicketIds"].substring(0, 30)
                : r["multiTicketIds"]}
            </span>
            <div class="icon-badge-container ">
              <div
                class="icon-badge cursorPointor"
                style={{
                  marginLeft: "96%",
                  marginTop: "-14%",
                  position: "relative",
                  backgroundColor: "#ff6c00",
                }}
                title="Number of Tickets"
              >
                {r["multiTicketIds"] && (r["multiTicketIds"].split(",").length)}
              </div>
            </div>
          </>
        ),
        icon: () => <>Ticket Number</>,
        label: "#",
      },

      // { path: "ticketNo", label: "Ticket Number" },
      // { path: "shipmentNo", label: "Shipment No." },
      {
        key: "ticketCreationDate",
        action: (r) => <>{epoch2ddmmyyyyhhmmss(r.ticketCreationDate)}</>,
        cusClass: " ",
        icon: () => <>Ticket Creation Date</>,
      },
      {
        key: "shipmentTrackingURL",
        action: (r) => {
          if (this.state.showUpdateBtn && !r.shipmentNo) {
            return (
              <div>
                <input
                  type="text"
                  name={`sh_${r.linehaulSeq}`}
                  id={`sh_${r.linehaulSeq}`}
                  className="shipmentNoInputManifest"
                  onFocus={() => {
                    // console.log({focusOn: r.linehaulSeq})
                    this.setState({ currentShipment: r });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (!e.target.value) return;
                      this.handleChangeLhDocketNo(r, e.target.value);
                    }
                  }}
                />
              </div>
            );
          }

          if (r.shipmentNo && r.logisticsProvider === "Delhivery") {
            return (
              <div className="clearfix shipmentNo">
                <span className="pull-left">
                  <a
                    href={`https://www.delhivery.com/track/lr/${r.shipmentNo}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {r.shipmentNo}
                  </a>
                </span>

                <i
                  className="fa fa-pencil fa-2x pull-right fs-5 edit-shipmentNo"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  aria-hidden="true"
                  title="Edit shipmentNo"
                  onClick={() => this.openShipmentNoModal(r)}
                />
              </div>
            );
          } else {
            return (
              <div className="clearfix shipmentNo">
                {r.shipmentNo}

                <i
                  className="fa fa-pencil fa-2x pull-right fs-5 edit-shipmentNo"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  aria-hidden="true"
                  title="Edit shipmentNo"
                  onClick={() => this.openShipmentNoModal(r)}
                />
              </div>
            );
          }
        },
        icon: () => <>LH docket</>,
      },

      {
        key: "createdAt",
        action: (r) => <>{epoch2ddmmyyyyhhmmss(r.createdAt)}</>,
        cusClass: " ",
        icon: () => <>Manifest Creation Date</>,
      },
      { path: "transMode", label: "Delivery Type" },
      { path: "srcLocation", label: "Source Location" },
      { path: "dstLocation", label: "Drop Location" },
      // { path: "logisticsProvider", label: "Logistics Provider" },
      {
        key: "logisticsProvider",
        action: (r) => <>{this.replaceString(r.logisticsProvider)}</>,
        cusClass: " ",
        icon: () => <>Logistics Provider</>,
      },

      { path: "destination", label: "Destination Type" },

      {
        key: "shipmentNo",
        actionKey: "sh",
        action: (r) => (
          <>
            {r.shipmentNo ? (
              // r.destination === "customer" && (
              //   <button
              //     className="btn  btn-success  btn-sm link-over"
              //     onClick={() => this.onBlockSelection(r, "20DIRECT_DELIVERY")}
              //   >
              //     Delivered
              //   </button>
              // )
              <></>
            ) : (
              <div>
                {
                  // this.state.designation === "Key Accounts Manager"
                  // || this.state.designation === "Hub Coordinator"
                  this.state.showUpdateBtn && (
                    <>
                      {r["transMode"] !== "road" ? (
                        <button
                          className="btn  btn-outline-secondary  btn-sm link-over"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                          onClick={() => this.openShipmentNoModal(r)}
                        >
                          Update AWB/LR no
                        </button>
                      ) : (
                        <> Shipment No updation not required </>
                      )}
                    </>
                  )
                }
              </div>
            )}
          </>
        ),
        cusClass: " ",
        icon: () => <i className="fa fa-cogs" aria-hidden="true"></i>,
      },
      {
        key: "Expense",
        action: (r) => (
          <>
            <div className="d-flex justify-content-center">
              <>
                <i
                  className="fa fa-telegram cursorPointor ="
                  style={{ fontSize: "30px", color: "green" }}
                  onClick={() => this.handleExpenseRise(r)}
                  title="Raise Expense"
                ></i>
              </>
            </div>
          </>
        ),
        icon: () => (
          <div className="d-flex justify-content-center">Raise Expense</div>
        ),
      },
    ],
  };

  // onloadDocumentDetails = (r) => {
  //   this.setState({ documentDetails: "true" });
  //   this.setState({ openStatus: "false" });
  //   this.setState({ currentRequest: r });
  // };

  // closeModal = () => {
  //   this.setState({ openStatus: "false" });
  //   this.setState({ documentDetails: "false" });
  // };
  onloadDocumentDetails = (r) => {
    this.setState({ documentDetails: "true" });
    this.setState({ openStatus: "false" });
    this.setState({ barcodeDetails: "false" });
    this.setState({ currentRequest: r });
  };

  onloadBarcodeDetails = (r) => {
    // alert(JSON.stringify(r));
    this.setState({ barcodeDetails: "true" });
    this.setState({ documentDetails: "false" });
    this.setState({ openStatus: "false" });
    this.setState({ barcodeRequest: r });
  };

  closeModal = () => {
    this.setState({ openStatus: "false" });
    this.setState({ documentDetails: "false" });
    this.setState({ barcodeDetails: "false" });
  };
  // handleView = () => {
  //   $("#myDocumentDetails").draggable();
  // };
  handleExpenseRise = async (r) => {
    try {
      let resp = await getLinehaulTicktes(r.linehaulSeq);
      if (resp.data.success === true) {
        if (resp.data.data) {
          toast.success("Ticket Action Fetched Successfully");
          this.setState({ ticketDetails: resp.data.data });
        } else {
          !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
      this.setState({ documentDetails: "false" });
      this.setState({ openStatus: "true" });
      this.setState({ ticketStatus: "false" });

      this.setState({ currentRequest: r });
    } catch {
      console.log("ReviewWrapper----------->>> handleExpenseRise");
    }
  };
  async onBlockSelection(r, status) {
    try {
      this.setState({ loading: true });
      let params = {
        ticketLinehaulBlockId: r.ticketLinehaulBlockId,
        ticketBlockId: r.ticketBlockId,
        status: this.status,
        direction: r.direction,
        dropTime: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        dropDate: IstToUTC(new Date()).slice(0, 10),
      };
      let resp = await blockStatus(params);
      if (resp.data.success === true) {
        toast.success(() => <div>{resp.data.msg}</div>);
        this.getLinehaulList(this.state.pageLinks.pageNo);
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in OutgoingManifest>>>>>> onBlockSelection");
    }
  }

  async openShipmentNoModal(r) {
    //*call to open shipment modal
    try {
      // updateAwbParams
      // console.log("rowDetails", r)
      const params = {
        lineHaulId: r.ticketLineHaulId,
        shipmentNo: "",
        srcHubId: r.srcHubId,
        dstHubId: r.dstHubId,
        processAt: "",
        productStatus: "lineHaulProdManifested",
        boxStatus: "lineHaulBoxManifested"
      }

      this.setState({ updateAwbParams: params })

      const resp = await getLinehaulProductsV1(r.ticketLineHaulId, 1)
      // console.log(resp.data.data.linehaulTicketDetails)
      this.setState({ productArr: resp.data.data.linehaulTicketDetails })
      this.setState({ currentShipment: r });
      const shipmentInput = document.getElementById("inputShipmentNo");
      const modalShipmentNoUpdate = document.getElementById("staticBackdrop");

      modalShipmentNoUpdate.addEventListener("shown.bs.modal", () => {
        if (shipmentInput) {
          shipmentInput.focus();
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleSaveShipmentNo = async (shipmentNumber) => {
    // console.log({shipmentDetails: this.state.currentShipment, shipmentNumber})
    // this.getLinehaulList(this.state.pageLinks.pageNo);
    const ticketLineHaulId = this.state.currentShipment.ticketLineHaulId;

    try {
      if (this.state.currentShipment.info === null || this.state.currentShipment.info === "") {
        const resp = await updateShipmentNo(ticketLineHaulId, shipmentNumber);

        if (resp.data.success) {
          const updatedOutgoingShipment = this.state.outgoing.map((s) => ({
            ...s,
            shipmentNo:
              s.ticketLineHaulId === this.state.currentShipment.ticketLineHaulId
                ? shipmentNumber
                : s.shipmentNo,
          }));
          this.setState({ outgoing: updatedOutgoingShipment });

          toast.success(() => <div>{resp.data.msg}</div>);

          return true;
        }
        toast.error(() => <div>{resp.data.msg}</div>);
        return false;
      }
      else {
        this.state.updateAwbParams.shipmentNo = shipmentNumber;
        this.state.updateAwbParams.processAt = epoch2yyyymmddhhmmssv1(new Date());

        const resp = await updateAwbNO(this.state.updateAwbParams);
        // console.log("awbResp", resp)

        if (resp.data.success) {
          const updatedOutgoingShipment = this.state.outgoing.map((s) => ({
            ...s,
            shipmentNo:
              s.ticketLineHaulId === this.state.currentShipment.ticketLineHaulId
                ? shipmentNumber
                : s.shipmentNo,
          }));
          this.setState({ outgoing: updatedOutgoingShipment });

          toast.success(() => <div>{resp.data.msg}</div>);



          return true;
        }
        toast.error(() => <div>{resp.data.msg}</div>);
        return false;

      }
    } catch (error) {
      console.log("ERROR in handleSaveShipmentNo");
      console.error(error);
    }
  };


  handleChangeLhDocketNo = async (r, shipmentNumber) => {
    try {


      if (r.info === null || r.info === "") {
        const resp = await updateShipmentNo(r.ticketLineHaulId, shipmentNumber);

        if (resp.data.success) {
          const updatedOutgoingShipment = this.state.outgoing.map((s) => ({
            ...s,
            shipmentNo:
              s.ticketLineHaulId === this.state.currentShipment.ticketLineHaulId
                ? shipmentNumber
                : s.shipmentNo,
          }));
          this.setState({ outgoing: updatedOutgoingShipment });

          toast.success(() => <div>{resp.data.msg}</div>);

          return true;
        }
      }
        else {
          const params = {
            lineHaulId: r.ticketLineHaulId,
            shipmentNo: shipmentNumber,
            srcHubId: r.srcHubId,
            dstHubId: r.dstHubId,
            processAt: epoch2yyyymmddhhmmssv1(new Date()),
            productStatus: "lineHaulProdManifested",
            boxStatus: "lineHaulBoxManifested"
          }
          // this.state.updateAwbParams.shipmentNo = shipmentNumber;
          //   this.state.updateAwbParams.processAt = epoch2ddmmyyyyhhmmss(new Date());

          // console.log("params", params)

          const resp = await updateAwbNO(params);
          // console.log("awbResp", resp)

          if (resp.data.success) {
            const updatedOutgoingShipment = this.state.outgoing.map((s) => ({
              ...s,
              shipmentNo:
                s.ticketLineHaulId === this.state.currentShipment.ticketLineHaulId
                  ? shipmentNumber
                  : s.shipmentNo,
            }));
            this.setState({ outgoing: updatedOutgoingShipment });

            toast.success(() => <div>{resp.data.msg}</div>);



            return true;
          }
          toast.error(() => <div>{resp.data.msg}</div>);
          return false;
        }
      
    } catch (error) {
      console.log("ERROR in handleSaveShipmentNo");
      console.error(error);
    }
  }

  onTextChanged = (e) => {
    const value = e.target.value;
    this.setState(() => ({
      fileName: value,
    }));
  };

  formatExcelReport = async (r) => {
    let formatedData = await Promise.all(
      r.filter(async (t) => {
        delete t.dstContact1;
        delete t.dstEmailIdAlt;
        delete t.moreInfo;
        delete t.srcEmailId;
        delete t.dstHubId;
        delete t.srcContact2;
        delete t.srcOrg;
        delete t.srcState;
        delete t.ticketLineHaulId;
        delete t.ticketLinehaulBlockId;
        delete t.shipmentNo;
        delete t.processId;
        delete t.dstAdd1;
        delete t.dstContact2;
        delete t.srcHubId;
        delete t.dstState;
        delete t.shipmentStatus;
        delete t.srcAdd1;
        delete t.direction;
        delete t.ticketBlockId;
        delete t.srcContact1;
        delete t.dstLandmark;
        delete t.dstOrg;
        delete t.shBlockStatus;
        delete t.vehicleNo;
        delete t.dstCity;
        delete t.dstContactPerson;
        delete t.srcCity;
        delete t.dstAdd2;
        delete t.srcEmailIdAlt;
        delete t.dstPincode;
        delete t.srcType;
        delete t.srcLandmark;
        // delete t.destination;
        delete t.ticketNo;
        delete t.srcContactPerson;
        delete t.srcPincode;
        delete t.srcAdd2;
        delete t.dstType;
        delete t.dstEmailId;
        delete t.transMode;
        // delete t.ticketCreationDate;
        // t.createdAt = epoch2ddmmyyyyhhmmss(t.createdAt);
        t.shipmentCreatedAt = epoch2ddmmyyyyhhmmss(t.createdAt);
        t.ticketCreatedAt = epoch2ddmmyyyyhhmmss(t.ticketCreationDate);

        delete t.createdAt;
        delete t.ticketCreationDate;

        return t;
      })
    );

    this.setState({ formatData: formatedData });
  };

  excelHeaders = [
    "linehaulSeq",
    "multiTicketIds",
    "ticketCreatedAt",
    "shipmentCreatedAt",
    "srcLocation",
    "dstLocation",
    "logisticsProvider",
    "destination",
  ];

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    const outgoingJSON = this.state.outgoing;
    const currentRequest = this.state.currentRequest;
    const ticketRequest = this.state.ticketDetails;

    // console.log({ outgoingJSON });
    // console.log({ state: this.state });
    return (
      <>
        <div>
          <div className="form-subHeader">Review Manifest</div>
          <div className="form-row col-12">
            <div
              className="col-md-1"
              style={{ paddingLeft: "15px", marginTop: "-5px" }}
            >
              {this.rSBMulti(
                "Hub",
                "hubId",
                this.state.hubs,
                { value: "hubId", label: "hub" },
                " col-md-3 "
              )}
            </div>
            <div
              className="col-md-1"
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                marginTop: "-5px",
              }}
            >
              <>
                {this.rSBMulti(
                  "Client",
                  "retailerId",
                  this.state.clients,
                  { value: "teamId", label: "name" },
                  " col-md-3 "
                )}
              </>
            </div>
            {/* {this.rSB(
              "Status *",
              "status",
              this.statusData,
              { value: "value", label: "label" },
              " col-md-2 "
            )} */}
            <div className="col-md-2">
              {this.rTB("Search Ticket No.", "search")}
            </div>

            <div className="col-2">
              <button
                className="btn  btn-primary  btn-sm"
                onClick={() => this.handleSearch(this.state.data.search)}
              >
                Search
              </button>
            </div>

            <div
              className="col-2 form-group "
              style={{ position: "relative", left: "10%" }}
            >
              <input
                value={this.state.fileName}
                name="fileName"
                onChange={this.onTextChanged}
                type="text"
                autoComplete="off"
              />
              <label htmlFor="fileName" className="control-label">
                FileName *
              </label>
              <i className="bar"></i>
              <ExportXLSXReqLinehaul
                csvData={this.state.formatData}
                header={this.excelHeaders}
                fileName={this.state.fileName + "-" + this.getDatebyDays(0)}
              />
            </div>
          </div>
          <div className="form-row col-12">
            {this.props.loading && (
              <div className="container-fluid pull-right">
                <div> Loading users...</div>
              </div>
            )}
            {!this.props.loading && (
              <>
                <Table
                  clsName="table table-striped table-bordered table-hover"
                  tableMeta={this.tableMeta}
                  data={outgoingJSON}
                  dropMenu={[]}
                />
                {!this.props.loading && this.state.outgoing.length !== 0 && (
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div
          className="draggable col-10 ml-5 mr-5"
          id="myDocumentDetails"
          onClick={this.handleView}
        >
          {this.state.openStatus === "false" &&
            this.state.documentDetails === "true" && (
              <DocumentDetails
                key={currentRequest.ticketNo}
                data={currentRequest}
                // requestStatusChange={this.handleRequestStatusChange}
                // user={this.state.user}
                closeModal={this.closeModal}
              />
            )}
        </div>
        <div
          className="draggable  col-sm-12 col-md-8 col-lg-8 "
          id="myModalApprove"
          onClick={this.handleView}
        >
          {
            // this.state.TicketActionsDatas &&
            this.state.openStatus === "true" &&
            this.state.documentDetails === "false" &&
            this.state.ticketStatus === "false" && (
              <ExpenseRise
                key={currentRequest.ticketNo}
                data={currentRequest}
                type="report"
                ticketDetails={ticketRequest}
                // ticketActionId={currentRequest.ticketActionId}
                // requestChange={this.handleRequestChange}
                // user={this.state.user}
                closeModal={this.closeModal}
              />
            )
          }
        </div>

        {this.state.outgoing.length === 0 && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">No Tickets Found</p>
          </div>
        )}
        {/* <div
          className="draggable col-10 ml-5 mr-5"
          id="myDocumentDetails"
          onClick={this.handleView}
        >
          {this.state.openStatus === "false" &&
            this.state.documentDetails === "false" &&
            this.state.barcodeDetails === "true" &&
             (
              <EwayBillPrintSVG
                // BarCodePrintSVGLinehaul
                key={outgoingJSON.ticketNo}
                data={this.state.barcodeRequest}
                barcodeDetails = {this.state.barcodeRequest}
                closeModal={this.closeModal}
              />
            )}
        </div> */}
        <iframe
          id="ifmcontentstoprint"
          title="toPrint"
          style={{
            height: "0px",
            width: "0px",
            position: "absolute",
          }}
        ></iframe>
        {/* <UpdateShipmentNoModal
          shipmentDetails={this.state.currentShipment}
          handleSave={this.handleSaveShipmentNo}
          shipmentInputId="inputShipmentNo"
          modalId="staticBackdrop"
        /> */}
        <UpdateShipmentNoModalV1
          shipmentDetails={this.state.currentShipment}
          handleSave={this.handleSaveShipmentNo}
          shipmentInputId="inputShipmentNo"
          modalId="staticBackdrop"
          productArr={this.state.productArr}
        />
        {/* {this.state.reviewManifestBoxDetails === true && <BoxDetailsIncoming />}

        {this.state.reviewManifestBoxDetails === true && (
            <BoxDetailsIncoming
             
              key={this.state.reviewManifestBoxDetails}
            />
          )} */}
      </>
    );
  }
}
