import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="footWrap">
        <footer className="footer">
          <div>Theme By Bizlog Team</div>
        </footer>
      </div>
    );
  }
}

export default Footer;
