import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";

import {
  createRoleDivs,
  saveRoleDivs,
  deleteRoleDivs,
} from "../../../services/appRoles/roleDivsServices";

class ModalDivision extends FormLib {
  state = {
    data: {
      divisionId: "",
      division: "",
      status: "",
      sort: "",
    },
    errors: {},
  };

  schema = {
    divisionId: Joi.optional().label("Division Id"),
    division: Joi.string().required().label("Division"),
    status: Joi.string().required().label("Status"),
    sort: Joi.string().required().label("Sort"),
  };

  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createRoleDivs(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveRoleDivs(this.state.data);
    }
    if (this.state.btnValue === "delete") {
      res = await deleteRoleDivs(this.state.data);
      this.resetData();
    }
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.props.onloadRoleDivs();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  componentDidMount() {
    this.setCurDivision(this.props.curDivision);
  }

  setCurDivision(d) {
    if (d.divisionId !== "") {
      d.status = "active";
      this.setState({ data: d });
    } else {
      this.resetData();
    }
  }

  resetData() {
    this.setState({
      data: {
        divisionId: "",
        division: "",
        status: "active",
        sort: "",
      },
    });
  }

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  sort = [
    { sortKey: "asc", sort: "ASC" },
    { sortKey: "dsc", sort: "DSC" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;
    return (
      <div className="container-fluid">
        <div className="modal fade" id="myDivisionModel">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Role Division</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  <div className="form-row">
                    {this.rTBH("DivisionId", "divisionId", "col-6")}
                    {this.rTB("Division", "division", "col-6")}

                  </div>
                  <div className="form-row">
                    {this.rRBL("Status", "status", this.status, this.stsConf)}
                    {this.rRBL("Sort", "sort", this.sort, this.stsConf)}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {this.state.data.divisionId === "" &&
                  this.rBtnSP("Create", "create")}
                {this.state.data.divisionId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {this.rBtnSD("Delete", "delete")}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalDivision;
