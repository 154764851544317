import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLCore;
function api(path, subURL = "/tracker/settings/products-cat-models") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getProductModel(id, pageNo) {
  return _http.get(api("all/" + id + "/10/" + pageNo), _http.headerJWT());
}

export function getModelBySearch(data, pageNo) {
  return _http.put(
    api("all/searchModel/10/" + pageNo),
    data,
    _http.headerJWT()
  );
}

export function createProductModel(req) {
  const param = { ...req };
  delete param.prodModelId;
  return _http.post(api(""), param, _http.headerJWT());
}

export function updateProductModel(req) {
  const param = { ...req };
  return _http.put(api("" + req.prodModelId), param, _http.headerJWT());
}

export function deleteProductModel(req) {
  return _http.delete(api("" + req.prodModelId), _http.headerJWT());
}
