import React from 'react'
import { getFlows, getRetailer } from '../../../services/masters/hubServices';
import FormLib from './../../_common/FormLib';


class RetailerProductsWrapper extends FormLib {
  state = {
    data: { client: "" },
    clients: [],
    errors: {},
  };

  state = {
    data: { client: "" },
    clients: [],
    flows: [],
    errors: {},
  };


  async componentDidMount() {
    let list = await getRetailer();
    this.setState({ clients: list.data.data.teams });

    list = await getFlows();
    this.setState({ flows: list.data.data.flows });
  }

  handleflow = (flowID) => {
    // console.log("-------->" + JSON.stringify(this.state.data.client));
    console.log(flowID);
  };

  localHandleChange = (e) => {
    if (e.target.name === "client") {
      const { value } = e.target;
      let data = this.state.data;
      let retailer = this.state;

      // find the clients with the current selected value
      const clients = retailer.clients.find((c) => c.name === value);

      if (clients !== undefined) {
        // console.log("------>bhanthu " + JSON.stringify(clients.teamId));
        data["client"] = clients.teamId;
        this.setState({
          data,
        });
      }
      this.validateProperty(e.target.name, e.target.value);
    }
  };

  render() {
    return (
      <div className="col-4">
        <div className="card">
          <div className="card-body">
            <form id="myform" onSubmit={this.handleSubmit}>
              <div className="form-row">
                {this.rSBF(
                  "Clients *",
                  "client",
                  this.state.clients,
                  { value: "teamId", label: "name" },
                  " col-md-12 "
                )}
              </div>
              <div className="form-row">
                <div className="card-body">
                  <div className="page-todo">
                    <div className=" tasks">
                      <div className={"task nil "}>
                        <div className="desc">
                          <span className="span-click">
                            {this.state.flows.map((flow) => (
                              <div
                                className="title mt-2"
                                key={flow.flowId}
                                onClick={() => this.handleflow(flow.flowId)}
                              >
                                <b>{flow.flow}</b>
                              </div>
                            ))}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default RetailerProductsWrapper;
