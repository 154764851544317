import React from "react";
import FormLib from "./../../_common/FormLib";
import LocationTitle from "../../_subhead/LocationTitle";
import TicketDetails from "./TicketDetails";
import TicketFiles from "./TicketFiles";
import TicketProcess from "./TicketProcess";
import TicketProductSummary from "./TicketProductSummary";
import TicketAdditionalFiles from "./TicketAdditionalFiles";

import TicketProductSummaryFurn from "./TicketProductSummaryFurn";
import toast from "../../../services/_toastService";
import { getTicketDetails } from "./../../../services/TicketHistory/TicketHistory";
import TicketEvaluation from "./TicketEvaluation";

class TicketHistory extends FormLib {
  state = {
    data: {
      ticketSearch: "",
    },
    TicketProductDetails: [],
    TicketPrimarytDetails: [],
    TicketProductsSummary: [],
    MISData: [],
    TicketProcessId: "",
    mode: "",
    sampleValue: 0,
    sampleselect: 0,
    errors: {},
    loading: true,
    pageHide: false,
    userCode: "",
    designation: "",
  };
  componentDidMount() {
    if (this.props.ticketDetails) {
      let data = {
        ticketSearch: this.props.ticketDetails.ticketNo,
      };
      this.setState({ data });
      this.loadTicket(1, this.props.ticketDetails.ticketNo);
    }
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({ userCode: userInfo.code });
    this.setState({ designation: userInfo.designation });
  }
  loadTicket = async (id, ticketSearch) => {
    try {
      // this.state.sampleValue=0;
      this.state.mode = "";
      this.setState({ loading: true });
      this.state.sampleValue = id;
      this.state.TicketProductDetails = [];
      this.state.TicketPrimarytDetails = [];
      this.state.TicketProductsSummary = [];
      this.state.MISData = [];
      this.state.TicketProcessId = "";
      const ticketNumber = ticketSearch;
      let res = await getTicketDetails(ticketNumber);

      if (
        res !== undefined &&
        res.data.data.TicketDetails.productDetails[0] !== undefined
      ) {
        if (res.data.success === true) {
          this.setState({
            TicketPrimarytDetails: res.data.data.TicketDetails.primaryDetails,
          });
          this.setState({
            TicketProductDetails: res.data.data.TicketDetails.productDetails[0],
          });
          this.setState({
            TicketProductsSummary: res.data.data.TicketDetails.productDetails,
          });
          this.setState({
            TicketProcessId:
              res.data.data.TicketDetails.productDetails[0].processId,
          });
          this.setState({ MISData: res.data.data.MisReportHistory });
          this.setState({ mode: "TicketDetails" });
        } else if (res.data.success === false) {
          toast.error("No Details Available for this Ticket");
        }
      } else {
        this.setState({ sampleValue: 0 });
      }
    } catch (e) {
      this.setState({ sampleValue: 0 });
      console.log("Error in TicketHistory>>>>>> loadTicket");
    }
  };

  onModeChange = (mode) => {
    this.setState({ mode: mode });

    switch (mode) {
      case "TicketDetails":
        // this.loadHubs(this.state.curPlayer.playerId);
        break;
      case "Files":
        // this.loadRetailers(this.state.curPlayer.playerId);
        break;
      case "Evaluation":
        // this.loadRetailers(this.state.curPlayer.playerId);
        break;
      case "TicketProcess":
        // this.loadZones(this.state.curPlayer.playerId);
        break;
      case "AdditionalFiles":
        // this.loadZones(this.state.curPlayer.playerId);
        break;
    }
  };

  render() {

    return (
      <React.Fragment>
        {/* <LocationTitle subHeading="Ticket History" /> */}
        <div className="pageContent">
          <div className="row">
            {/* this.state.pageHide === "false" && */}
            {this.state.loading && (
              // {!this.state.loading && (
              <>
                <div className="col-3 ml-3">
                  {this.rTB("Search Ticket number...", "ticketSearch")}
                </div>
                <div className="menu col-2">
                  <button
                    id="button"
                    type="button"
                    className="btn btn-sm btn-outline-info pull-left"
                    title=" Search Tickets by ticket number "
                    disabled={this.state.data.ticketSearch === ""}
                    onClick={() =>
                      this.loadTicket(
                        (this.state.sampleValue = 1),
                        this.state.data.ticketSearch
                      )
                    }
                  >
                    <i className={"fa fa-search"} />
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-sm btn-outline-info pull-left"
                    title="Refresh"
                    onClick={() =>
                      this.loadTicket(
                        this.state.sampleValue=1
                      )} 
                      >
                    <i className={"fa fa-refresh"} />
                  </button> */}
                </div>
              </>
            )}
          </div>
          {this.state.sampleValue === 1 && (
            <div className="row mb-4">
              <div className="container-fluid">
                <div className="text-center">
                  <button
                    onClick={() => this.onModeChange("TicketDetails")}
                    className={
                      this.state.mode === "TicketDetails"
                        ? "btn btn-md btn-success"
                        : "btn btn-md btn-outline-success"
                    }
                    style={{ width: "10rem" }}
                  >
                    Ticket Details
                  </button>
                  <button
                    onClick={() => this.onModeChange("Files")}
                    className={
                      this.state.mode === "Files"
                        ? "btn btn-md btn-info"
                        : "btn btn-md btn-outline-info"
                    }
                    style={{ width: "10rem" }}
                  >
                    Files
                  </button>
                  {/* <button
                    onClick={() => this.onModeChange("Evaluation")}
                    className={
                      this.state.mode === "Evaluation"
                        ? "btn btn-md btn-secondary"
                        : "btn btn-md btn-outline-secondary"
                    }
                    style={{ width: "10rem" }}
                  >
                    Evaluation
                  </button> */}
                  <button
                    onClick={() => this.onModeChange("TicketProcess")}
                    className={
                      this.state.mode === "TicketProcess"
                        ? "btn btn-md btn-warning"
                        : "btn btn-md btn-outline-warning"
                    }
                    style={{ width: "10rem" }}
                  >
                    Ticket Process
                  </button>
                  {this.state.userCode === "BIZ" &&
                    this.state.designation !== "Field Engineer" && (
                      <>
                        <button
                          onClick={() => this.onModeChange("AdditionalFiles")}
                          className={
                            this.state.mode === "AdditionalFiles"
                              ? "btn btn-md btn-primary"
                              : "btn btn-md btn-outline-primary"
                          }
                          style={{ width: "10rem" }}
                        >
                          Additional Files
                        </button>
                      </>
                    )}
                  {(this.state.TicketPrimarytDetails.flowId === "BulkQC" ||
                    this.state.TicketPrimarytDetails.flowId === "Bulk" ||
                    this.state.TicketPrimarytDetails.flowId === "eWaste" ||
                    (this.state.TicketPrimarytDetails.flowId === "DropOff" &&
                      this.state.TicketProductsSummary.length > 1)) && (
                    <button
                      onClick={() => this.onModeChange("ProductSummary")}
                      className={
                        this.state.mode === "ProductSummary"
                          ? "btn btn-md btn-secondary"
                          : "btn btn-md btn-outline-secondary"
                      }
                      style={{ width: "10rem" }}
                    >
                      Products Summary
                    </button>
                  )}

                  {/* {(this.state.TicketPrimarytDetails.flowId === "Furniture") && (

                    < button
                      onClick={() => this.onModeChange("ProductSummaryFurn")}
                      className={
                        this.state.mode === "ProductSummaryFurn"
                          ? "btn btn-md btn-secondary"
                          : "btn btn-md btn-outline-secondary"
                      }
                      style={{ width: "10rem" }}
                    >
                      Products Summary
                    </button>

                  )
                  } */}
                </div>
              </div>
            </div>
          )}
        </div>
        {this.state.sampleValue === 0 && (
          <div className="container-fluid jumbotron text-center">
            <h4>Please search with the correct ticket number...</h4>
          </div>
        )}

        {this.state.sampleValue === 1 && this.state.mode === "" && (
          <div className="container-fluid jumbotron text-center">
            <h4>Please select the required field...</h4>
          </div>
        )}

        {this.state.mode === "TicketDetails" && (
          <TicketDetails
            ticketProductDetails={this.state.TicketProductDetails}
            ticketPrimarytDetails={this.state.TicketPrimarytDetails}
            curTicket={this.state.data.ticketSearch}
          />
        )}
        {this.state.mode === "Files" && (
          <TicketFiles
            curProcessId={this.state.TicketProcessId}
            key={this.state.TicketProcessId + 1}
          />
        )}
        {this.state.mode === "Evaluation" && (
          <TicketEvaluation
            curProcessId={this.state.TicketProcessId}
            curTicket={this.state.data.ticketSearch}
            curMisHistory={this.state.MISData}
            key={this.state.TicketProcessId + 2}
          />
        )}
        {this.state.mode === "TicketProcess" && (
          <TicketProcess
            curProcessId={this.state.TicketProcessId}
            curTicket={this.state.data.ticketSearch}
            ticketProductDetails={this.state.TicketProductDetails}
            key={this.state.TicketProcessId + 1}
          />
        )}
        {this.state.mode === "AdditionalFiles" && (
          <TicketAdditionalFiles
            ticketProductDetails={this.state.TicketProductDetails}
            ticketPrimarytDetails={this.state.TicketPrimarytDetails}
            curTicket={this.state.data.ticketSearch}
            curProcessId={this.state.TicketProcessId}
            key={this.state.data.ticketSearch + 1}
          />
        )}
        {this.state.mode === "ProductSummary" && (
          <TicketProductSummary
            ticketProductDetails={this.state.TicketProductsSummary}
            ticketPrimarytDetails={this.state.TicketPrimarytDetails}
            curTicket={this.state.data.ticketSearch}
            key={this.state.data.ticketSearch + 1}
          />
        )}
        {/* {
          this.state.mode === "ProductSummaryFurn" && (
            <TicketProductSummaryFurn
              ticketProductDetails={this.state.TicketProductsSummary}
              ticketPrimarytDetails={this.state.TicketPrimarytDetails}
              curTicket={this.state.data.ticketSearch}
              key={this.state.data.ticketSearch + 1}
            />
          )
        } */}
      </React.Fragment>
    );
  }
}

export default TicketHistory;
