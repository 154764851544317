
import React from "react";
import $ from "jquery";
import FormLib from "../../../_common/FormLib";
import { IstToUTC, IstToUTCDate } from "../../../../utils/DateUtils";
import Joi from "joi-browser";
import toast from "./../../../../services/_toastService";
// import { getTripDetails, setTicketVehicleInfoUpdate } from "./../../../../services/Tickets/TicketProcessServices";
import { getTripDetails, updateTripClosure } from "./../../../../services/Tickets/TicketProcessServices";
// import {
//   getDocuments,
//   getSaveFile,
// } from "../../../../../services/requestV1/requestServices";
// import { getLinehaulTicktes } from "../../../../../services/linehaul/linehaulServices";

class FileDetails extends FormLib {
    state = {
        data: {
            tripEnd: "",
            fileInfo: "",
            selectedFilesList: [],
        },
        errors: {},
        currentRequest: [],
        user: {},
        selectedFile: null,
        ticketDetails: [],
    };
    schema = {
        selectedFilesList: Joi.optional().label("selectedFilesList"),
    };

    onFileChange = (event) => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    componentDidMount() {
        this.loadData()
    }

    loadData = async () => {
        try {
            const resp = await getTripDetails(this.props.data.tripId)
            // console.log({ resp });
            // console.log("response", resp.data.data.tripAndTktDetails.files)
            this.setState({ currentRequest: resp.data.data.tripAndTktDetails.files })
        } catch (error) {
            console.log(error)
        }
    }

    selectedFilesLists = (e) => {
        let fileObj = [];
        let fileArray = [];
        fileObj.push(e);
        for (let i = 0; i < fileObj[0].length; i++) {
            fileArray.push(fileObj[0][i]);
        }
        this.setState({ file: fileArray });

        let data = this.state.data;
        data.selectedFilesList = e;
        this.setState({ data });
    };

    deleteFile(e) {
        const s = this.state.file.filter((item, index) => index !== e);
        this.setState({ file: s });
        let data = this.state.data;
        data.selectedFilesList = s;
        this.setState({ data });
    }

    async onVehicleInfo(r) {
        let curHub = localStorage.getItem("curHub");
        let date = IstToUTCDate(new Date());
        let now = new Date().valueOf(); // Unix timestamp in milliseconds
        let tripId = curHub + "-" + date + "-" + now;
        let override = { ...this.state.data };
        override["actionCode"] = "wUpdateVehicle";
        override["action"] = "Vehicle Details";
        this.setState({ override });

        if (this.state.data.fileInfo === "") {
            toast.toastWarn("file and file info is mandatory")
        }
        else {
            let resultSet = {
                tripId: this.props.data.tripId,
            };
            const data = {
                info: this.state.data.fileInfo,
                resultSet: resultSet,
                selectedFilesList: this.state.data.selectedFilesList,
            };

            let resp = await updateTripClosure(data);

            if (resp.data.success === true) {
                if (resp.data.data) {
                    toast.toastSuccess(resp.data.msg)
                    let data = { ...this.state.data };
                    data["fileInfo"] = "";
                    this.setState({ data, file: [] });
                    this.props.submit();
                    this.props.closeModal();
                }
                else {
                    toast.toastError(resp.data.error)
                }
            } else {
                toast.toastError(resp.data.msg);
            }
        }
    }

    render() {
        const imgStyle = {
            border: "1px solid #d3c4c4",
            width: "130px",
            height: "130px",
            "line-height": "130px",
            float: "left",
            cursor: "pointer",
            "font-size": "48px",
            color: "#d7caca",
            "background-color": "#f9f1f1",
            "border-radius": "6px",
        };
        return (
            <React.Fragment>
                <div
                    className="card "
                >
                    <div className="card-header bg-success text-white">
                        <div className="row">
                            <div className="col">
                                View Vehicle Trip Details :
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={this.props.closeModal}
                                    style={{
                                        display: "inline",
                                        float: "right",
                                        marginTop: "-0.4rem",
                                    }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body mt-0 ">
                        <div className="fixTableHead"
                            style={{ height: "20rem", overflow: "scroll" }}>
                            <div className="col mb-4" style={{ fontSize: 15, fontWeight: "bolder" }}>
                                <h5>TicketNumber : <span className="pull-right badge badge-secondary"> {this.props.data.ticketsOftheTrip}</span></h5>
                            </div>
                            <div className="col mb-4" style={{ fontSize: 15, fontWeight: "bolder" }}>
                                <h5>TripId :  <span className="pull-right badge badge-secondary"> {this.props.data.tripId}</span></h5>
                            </div>
                            <div className="col mb-4" style={{ fontSize: 15, fontWeight: "bolder" }}>
                                <h5>Logistics Provider :  <span className="pull-right badge badge-secondary"> {this.props.data.logisticsProvider}</span></h5>
                            </div>
                            <div className="col mb-4" style={{ fontSize: 15, fontWeight: "bolder" }}>
                                <h5>VehicleNumber :  <span className="pull-right badge badge-secondary"> {this.props.data.vehicleNo}</span></h5>
                            </div>
                            <div className="col mb-4" style={{ fontSize: 15, fontWeight: "bolder" }}>
                                <h5>vehicleType : <span className="pull-right badge badge-secondary"> {this.props.data.vehicleType}</span></h5>
                            </div>
                            <div className="col mb-4" style={{ fontSize: 15, fontWeight: "bolder" }}>
                                <h5>TripStatus :  <span className="pull-right badge badge-secondary"> {this.props.data.tripStatus}</span></h5>
                            </div>
                            <div className="col mb-4" style={{ fontSize: 15, fontWeight: "bolder" }}>
                                <h5>TripStart :  <span className="pull-right badge badge-secondary"> {this.props.data.tripStart}</span></h5>
                            </div>
                            <div className="col mb-4" style={{ fontSize: 15, fontWeight: "bolder" }}>
                                <h5>TripEnd :  <span className="pull-right badge badge-secondary"> {this.props.data.tripEnd}</span></h5>
                            </div>
                            <div className="row">
                                <div className="m-4" style={{ fontSize: 15, fontWeight: "bold" }}>
                                    Files :
                                </div>
                                <div style={{ display: "flex", flexWrap: "wrap", marginLeft: "5px" }} className="ml-4">
                                    {this.state.currentRequest.map((r) => {
                                        return (
                                            <div key={r} style={{ flexBasis: "48%", marginBottom: "10px" }}>
                                                {r.fileType === "jpg" && (
                                                    <>
                                                        <p>
                                                            <a
                                                                href={r.fileS3Path}
                                                                data-toggle="tooltip"
                                                                title={""}
                                                                style={{ display: "inline-block", marginRight: "1px" }}
                                                            >
                                                                <img
                                                                    src={r.fileS3Path}
                                                                    style={{
                                                                        width: "130px",
                                                                        height: "130px",
                                                                        margin: "3px",

                                                                    }}
                                                                />
                                                            </a>
                                                            <br></br>
                                                        </p>
                                                    </>
                                                )}
                                                {r.fileType === "jpeg" && (
                                                    <>
                                                        <p>
                                                            <a
                                                                href={r.fileS3Path}
                                                                data-toggle="tooltip"
                                                                title={""}
                                                            >
                                                                <img
                                                                    src={r.fileS3Path}
                                                                    style={{
                                                                        width: "130px",
                                                                        height: "130px",
                                                                        margin: "3px",
                                                                    }}
                                                                />
                                                            </a>
                                                            <br></br>
                                                        </p>
                                                    </>
                                                )}
                                                {r.fileType === "png" && (
                                                    <>
                                                        <p>
                                                            <a
                                                                href={r.fileS3Path}
                                                                data-toggle="tooltip"
                                                                title={""}
                                                            >
                                                                <img
                                                                    src={r.fileS3Path}
                                                                    style={{
                                                                        width: "130px",
                                                                        height: "130px",
                                                                        margin: "3px",
                                                                    }}
                                                                />
                                                            </a>
                                                            <br></br>

                                                        </p>
                                                    </>
                                                )}
                                                {r.fileType === "gif" && (
                                                    <>
                                                        <p>
                                                            <a
                                                                href={r.fileS3Path}
                                                                data-toggle="tooltip"
                                                                title={""}
                                                            >
                                                                <img
                                                                    src={r.fileS3Path}
                                                                    style={{
                                                                        width: "130px",
                                                                        height: "130px",
                                                                        margin: "3px",
                                                                    }}
                                                                />
                                                            </a>
                                                            <br></br>
                                                        </p>
                                                    </>
                                                )}
                                                {r.fileType === "xlsx" && (
                                                    <>
                                                        <p>
                                                            <a
                                                                href={r.fileS3Path}
                                                                target="_self"
                                                                title="Click to download"
                                                            >
                                                                <i
                                                                    className="fa fa-file-excel-o text-center"
                                                                    style={imgStyle}
                                                                ></i>
                                                            </a>
                                                            <br></br>
                                                        </p>
                                                    </>
                                                )}
                                                {r.fileType === "txt" && (
                                                    <>
                                                        <p>
                                                            <a
                                                                href={r.fileS3Path}
                                                                target="_self"
                                                                title="Click to download"
                                                            >
                                                                <i
                                                                    className="fa fa-file-text mb-1 text-center"
                                                                    style={imgStyle}
                                                                ></i>
                                                            </a>
                                                            <br></br>
                                                        </p>
                                                    </>
                                                )}
                                                {r.fileType === "pdf" && (
                                                    <>
                                                        <p>
                                                            <a
                                                                href={r.fileS3Path}
                                                                target="_self"
                                                                title="Click to download"
                                                            >
                                                                <i
                                                                    className="fa fa-file-pdf-o mb-1 text-center"
                                                                    style={imgStyle}
                                                                ></i>
                                                            </a>
                                                            <br></br>
                                                        </p>
                                                    </>
                                                )}
                                                {r.fileType === "docx" && (
                                                    <>
                                                        <p>
                                                            <a
                                                                href={r.fileS3Path}
                                                                target="_self"
                                                                title="Click to download"
                                                            >
                                                                <i
                                                                    className="fa fa-file-word-o mb-1 text-center"
                                                                    style={imgStyle}
                                                                ></i>
                                                            </a>
                                                            <br></br>
                                                        </p>
                                                    </>
                                                )}
                                                {r.fileType === "bmp" && (
                                                    <>
                                                        <p>
                                                            <a
                                                                href={r.fileS3Path}
                                                                target="_self"
                                                                title="Click to download"
                                                            >
                                                                <i
                                                                    className="fa fa-file-word-o mb-1 text-center"
                                                                    style={imgStyle}
                                                                ></i>
                                                            </a>
                                                            <br></br>

                                                        </p>
                                                    </>
                                                )}
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className="m-0 p-0 col-9 mt-2 ml-4">
                                    <label
                                        htmlFor="refDocFiles"
                                        className="form-label font-monospace"
                                    >
                                        Upload Files <span style={{color:"red"}}>*</span>
                                    </label>
                                    <input
                                        className="form-control form-control-sm "
                                        multiple
                                        id="refDocFiles"
                                        type="file"
                                        style={{ cursor: "pointer" }}
                                        onChange={(e) => {
                                            this.selectedFilesLists(e.target.files);
                                        }}
                                    />
                                </div>
                                <div>
                                    {this.rTB(
                                        "File Info *",
                                        "fileInfo",
                                        " col-12 mt-3 mb-3"
                                    )}
                                </div>
                                <div>
                                    <button type="submit"
                                        className="btn btn-success pull-right mr-5 mt-3"
                                        onClick={(r) => this.onVehicleInfo(r)}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr />

                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default FileDetails;