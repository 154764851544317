import React from "react";
import FormLib from "../../_common/FormLib";
import ZTitle from "./../../_subhead/ZTitle";
import { getFlows } from "./../../../services/masters/hubServices";
import {
  getHubs,
  getClients,
  getActivity,
} from "./../../../services/masters/hubServices";
import Joi from "joi-browser";
import { getRequestReports } from "./../../../services/requestV1/requestServices";
import { toast } from "react-toastify";
import Table from "./../../_common/Table";

import Pagination from "./../../_common/Pagination";
import dateFormat from "dateformat";
export default class RequestReports extends FormLib {
  state = {
    data: {
      ref_id: "",
      hub: "",
      client: "",
      flow: "",
      activity: "",
      status: "",
    },
    errors: {},
    hubs: [],
    clients: [],
    activities: [],
    flows: [],
    statusData: [
      {
        status: "open",
      },
      {
        status: "closed",
      },
      {
        status: "rejected",
      },
    ],
    RequestReports: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      count: 0,
      lastPage: 0,
    },
  };

  schema = {
    status: Joi.string().required().label("Status"),
    ref_id: Joi.any().optional().label("Reference No."),
    hub: Joi.any().optional().label("Hub"),
    client: Joi.any().optional().label("Client"),
    flow: Joi.any().optional().label("Flow"),
    activity: Joi.any().optional().label("Activity"),
  };

  tableMeta = {
    key: "REQUEST_ID",
    columns: [
      {
        key: "Seq",
        action: (r) => (
          <>{(r["RE_PREFIX"].trim() + String(r["SEQ"]).trim()).trim()}</>
        ),
        cusClass: " ",
        icon: () => <>Seq.</>,
      },
      {
        key: "REF_ID",
        action: (r) => (
          <>
            {r.MULTI_REF_IDS === "" ||
              r.MULTI_REF_IDS === "null" ||
              (r.MULTI_REF_IDS === null && r.REF_ID)}{" "}
            {(r.MULTI_REF_IDS !== "" ||
              r.MULTI_REF_IDS !== "null" ||
              r.MULTI_REF_IDS !== null) &&
              r.MULTI_REF_IDS}{" "}
          </>
        ),
        cusClass: "",
        icon: () => <>Reference No.</>,
      },

      // { path: "REF_ID", label: "Reference No." },
      { path: "M_HUB", label: "Hub" },
      // { path: "M_CLIENT", label: "Client" },

      {
        key: "CREATED_AT",
        action: (r) => <>{dateFormat(r.CREATED_AT, "dd-mm-yyyy")}</>,
        cusClass: " ",
        icon: () => <>Request Date</>,
      },

      { path: "REQUEST_TYPE", label: "Request Type" },
      // { path: "M_FLOW", label: "Flow" },
      { path: "M_ACTIVITY", label: "Activity" },
      { path: "AMT", label: "Total Req" },
      { path: "AMT_APPROVED", label: "Appr .Amt" },
      { path: "PAID", label: "Amount Paid" },
      { path: "STATUS", label: "Status" },
    ],
  };

  async componentDidMount() {
    let list = await getHubs();
    this.setState({ hubs: list.data.data.hubs });

    list = await getClients();
    this.setState({ clients: list.data.data.clients });

    list = await getActivity();
    this.setState({ activities: list.data.data });

    list = await getFlows();
    this.setState({ flows: list.data.data.flows });
  }

  async doSubmit() {
    this.getReports(this.state.data, 1);

    // // console.log("Submitted" + JSON.stringify(this.state.data));
    // let reports = await getRequestReports(
    //   this.state.data,
    //   this.state.pageLinks.pageNo
    // );

    // // console.log("Response" + JSON.stringify(reports));

    // if (reports.data.success === true) {
    //   this.setState({ RequestReports: reports.data });
    //   // this.setState({ pageLinks: reports.data.pageLinks });
    //   toast.success(() => <div>{reports.data.msg}</div>);
    // } else {
    //   toast.error(() => <div>{reports.data.msg}</div>);
    // }
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/v1/request/reports/" + pageNo);
    this.getReports(this.state.data, pageNo);
  };

  async getReports(params, pageNo) {
    let reports = await getRequestReports(params, pageNo);
    // console.log(reports.data.data.reportsRequests);
    if (reports.data.success === true) {
      this.setState({ RequestReports: reports.data.data.reportsRequests });
      this.setState({ pageLinks: reports.data.data.pageLinks });
      toast.success(() => <div>{reports.data.msg}</div>);
    } else {
      toast.error(() => <div>{reports.data.msg}</div>);
    }
  }

  render() {
    const { pageNo, perPage, count } = this.state.pageLinks;

    const reports = this.state.RequestReports;
    return (
      <React.Fragment>
        <ZTitle subHeading="Expenses :: Expense Reports" />
        <div className="pageContent">
          <div className="card">
            <div className="card-body">
              <form id="myForm" onSubmit={this.handleSubmit}>
                <div className="form-row">
                  {this.rTB("Reference No", "ref_id", "col-md-2")}
                  {this.rSB(
                    "Hub",
                    "hub",
                    this.state.hubs,
                    { value: "hubId", label: "hub" },
                    " col-md-2 "
                  )}
                  {/* {this.rSB(
                    "Client",
                    "client",
                    this.state.clients,
                    { value: "clientId", label: "client" },
                    " col-md-2 "
                  )} */}

                  {/* {this.rSB(
                    "Flow",
                    "flow",
                    this.state.flows,
                    { value: "flowId", label: "flow" },
                    " col "
                  )} */}
                  {this.rSB(
                    "Activity",
                    "activity",
                    this.state.activities,
                    { value: "EXPENSE_HEAD_ID", label: "EXPENSE_HEAD" },
                    " col-md-2"
                  )}
                  {this.rSB(
                    "Status *",
                    "status",
                    this.state.statusData,
                    { value: "status", label: "status" },
                    " col-md-2 "
                  )}
                </div>
                <div className="col-9">
                  {this.rBtnS("Submit", "Submit", "btn btn-primary  btn-sm")}
                </div>
              </form>
              <br />
              <div className="row">
                {this.props.loading && (
                  <div className="container-fluid">
                    <div> Loading Tickets...</div>
                  </div>
                )}
                {!this.props.loading && count > 0 && (
                  <div className="container-fluid">
                    <div style={{ overflow: "auto" }}>
                      <div className="fixTableHead">
                        <Table
                          clsName="table table-striped table-bordered table-hover"
                          tableMeta={this.tableMeta}
                          data={reports}
                          dropMenu={[]}
                        />
                      </div>
                      <Pagination
                        pageNo={pageNo}
                        perPage={perPage}
                        itemsCount={count}
                        onPageChange={this.handlePageChange}
                      />
                    </div>
                  </div>
                )}
                {!this.props.loading && count === 0 && (
                  <div className="alert alert-info col-12" role="alert">
                    <div className="text-center"> No Records Found! </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
