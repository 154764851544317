import React from "react";
import FormLib from "../../../_common/FormLib";
import toast from "../../../../services/_toastService";
import Table from "../../../_common/Table";
import { IstToUTC } from "../../../../utils/DateUtils";
import { insertScannedItems } from "../../../../services/stockManagement/inventory";
import { Joi } from "joi-browser";

class SearchedItemList extends FormLib {
    state = {
        data: {
            request_details: ""
        },
        searchItem: [],
        searchProduct: [],
        packing: false
    }

    componentDidMount() {
        this.setState({ searchItem: this.props.data })
        this.setState({ searchProduct: this.props.data[0].productDetails })
        this.loadData()
    }

    async loadData() {
        if (this.props.data[0].packMaterialId != "") {
            this.setState({ packing: true })
        }
    }

    tableMeta = {
        key: "ticketNo",
        columns: [
            { path: "ticketNo", label: "ticketNo" },

            { path: "slNo", label: "Barcode" },

        ]
    }

    handleInventoryItemUpdate = async () => {
        if (this.state.packing === true) {
            let params = {
                packing: this.state.packing,
                slNoId: this.props.data[0].packMaterialId,
                hubId: this.props.hubId,
                remarks: "",
                processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
                hubScanDetails: this.props.data[0].hubScanDetails
            }

            const resp = await insertScannedItems(params)
            if (resp.data.success === true) {
                toast.toastSuccess(resp.data.msg)
                this.props.onHandleGetStatus();
                this.setState(this.props.closeModal);

            } else {
                toast.toastError(resp.data.msg)
            }
        } else {
            let params = {
                packing: this.state.packing,
                slNoId: this.state.searchProduct[0].slNoId,
                hubId: this.props.hubId,
                remarks: "",
                processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T")
            }

            const resp = await insertScannedItems(params)
            if (resp.data.success === true) {
                toast.toastSuccess(resp.data.msg)
                this.props.onHandleGetStatus()
                this.setState(this.props.closeModal);

            } else {
                toast.toastError(resp.data.msg)
            }
        }
    }
    render() {

        return (
            <React.Fragment>

                <div className="row col-md-6 pull-right ">
                    <div className="col-12">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={this.props.closeModal}
                        >
                            <span aria-hidden="true"> Searched Barcode &times;</span>
                        </button>
                        <div className="card">
                            <div className="card-header bg-success text-white ">
                                <div className="row">
                                    <div className="col-4"></div>
                                    <div className="col-4 text-right"></div>
                                </div>
                                <div className="row">
                                    <div className="col-4">{"Ticket Details"}</div>
                                    <div className="col-4 text-right"></div>
                                </div>
                            </div>

                            {/* End of Header */}
                            <div>
                                {this.props.data[0].packMaterialId === ""}{
                                    <div style={{ margin: 10, fontSize: 18, fontWeight: "bold" }}>PackMaterial Barcode : {this.props.data[0].packMaterialSl}</div>
                                }
                            </div>

                            <div
                                className="card-body border"
                                style={{ backgroundColor: "rgb(255 255 255)" }}
                            >
                                <div className="row">
                                    <div className="col-8"></div>
                                    <div className="container-fluid">
                                        <div style={{ overflow: "auto" }}>
                                            {/* {this.state.ticketJSON > 0 && ( */}
                                            <Table
                                                clsName="table table-striped table-bordered table-hover"
                                                tableMeta={this.tableMeta}
                                                data={this.state.searchProduct}
                                                dropMenu={[]}
                                            />
                                            {/* )} */}
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-success pull-right mt-2"
                                        onClick={this.handleInventoryItemUpdate}
                                    >
                                        Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </React.Fragment>
        )
    }

}
export default SearchedItemList