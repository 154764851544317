import React from "react";
import FormLib from "./../../_common/FormLib";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import {
  getTicketDetails,
  getTicketDetailsNewOne,
  getTicketInfo,
  getTicketMisHistory,
  getTicketsCounts,
  getPincodesList,
  getPlayersbyRole,
  getPincodesListByStatus,
} from "./../../../services/Tickets/TicketServices";
import { epoch2ddmmyyyy,epoch2ddmmyy } from "./../../../utils/DateUtils";
import { toast } from "react-toastify";
import Pagination from "./../../_common/Pagination";
import Joi from "joi-browser";
import ReactTooltip from "react-tooltip";
import { epoch2ddmmyyyyhhmmss, IstToUTC } from "../../../utils/DateUtils";

import LineHaulWrapper from "./Manifest/LineHaulWrapper";
import { getTicketRemarks } from "../../../services/TicketHistory/TicketHistory";
import { Redirect } from "react-router-dom";
class TicketsList extends FormLib {
  state = {
    btnValue: "unassigned",
    data: {
      search: "",
      hub: "",
      ticketStatus: "all",
      flow: "all",
      order: "lifo",
      pincodeList: "all",
      usersList: "all",
      ticketScope: "ticket",
    },
    // blockStatus
    errors: {},
    ticketDetails: [],
    curBlockDetails: [],
    curTicketDetails: [],
    curMultiBlockDetails: [],
    curMultiTickets: [],
    curMultiTicketDetails: [],
    TicketProductDetails: [],
    TicketProductDetails1: [],
    TicketPrimarytDetails: [],
    curMisReportHistory: [],
    TicketRemarks: [],
    ticketStatusForPincodes: "unassigned",
    pageLinks: {
      pageNo: 1,
      perPage: 8,
      itemsCount: 0,
      lastPage: 0,
    },
    curHubCode: [],
    mode: "",
    captureInfo: false,
  };
  onModeChange(mode) {
    this.setState({ mode: mode });
    this.setState({ btnValue: mode });
  }
  flow = [
    { sortKey: "all", sort: "All" },
    { sortKey: "pick", sort: "PickUp" },
    { sortKey: "drop", sort: "Drop" },
  ];
  order = [
    { sortKey: "lifo", sort: "LIFO" },
    { sortKey: "fifo", sort: "FIFO" },
  ];
  ticketScope = [
    { sortKey: "ticket", sort: "TicketNo." },
    { sortKey: "conComplaintNo", sort: "ConComplaintNo." },
    { sortKey: "LRNumber", sort: "LRNo." },
    { sortKey: "invoiceNo", sort: "InvoiceNo." },
    { sortKey: "orderNo", sort: "OrderNo." },
  ];
  schema = {
    search: Joi.optional().label("search"),
    includeVehicleInfo: Joi.optional().label("includeVehicleInfo"),
    ticketScope: Joi.optional().label("ticketScope"),
  };
  includeVehicleInfo = [{ sortKey: "yes", sort: "Capture Expenses??" }];

  handlebtn(e) {
    try {
      this.setState({ ticketStatusForPincodes: e });
      let selectedHub = localStorage.getItem("curHub");
      this.getPincodesListByHubByStatus(selectedHub, e);

      localStorage.setItem("blockStatus", "all");
      localStorage.setItem("pincode", "all");
      localStorage.setItem("player", "all");

      let selectedBlockStatus = localStorage.getItem("blockStatus");
      let pincode = localStorage.getItem("pincode");
      let player = localStorage.getItem("player");

      if (e === "unassigned") {
        this.setState({
          data: {
            search: this.state.data.search,
            hub: this.state.data.hub,
            ticketStatus: selectedBlockStatus,
            flow: "pick",
            order: "lifo",
            pincodeList: "all",
            usersList: "all",
            ticketScope: "ticket",
          },
        });
        let selectedHub = localStorage.getItem("curHub");
        this.setState({ btnValue: e });
        localStorage.setItem("actionBtn", e);
        localStorage.setItem("flow", "pick");
        localStorage.setItem("order", "lifo");
        // localStorage.setItem("pincode", "all");
        let flowStatus = localStorage.getItem("flow");
        let order = localStorage.getItem("order");
        let player = localStorage.getItem("player");

        this.getTicketlist(
          selectedHub,
          selectedBlockStatus,
          e,
          "1",
          order,
          flowStatus,
          pincode,
          player
        );
      } else {
        this.setState({
          data: {
            search: this.state.data.search,
            hub: this.state.data.hub,
            ticketStatus: selectedBlockStatus,
            flow: "all",
            order: "lifo",
            pincodeList: "all",
            usersList: "all",
            ticketScope: "ticket",
          },
        });
        let selectedHub = localStorage.getItem("curHub");
        this.setState({ btnValue: e });
        localStorage.setItem("actionBtn", e);
        localStorage.setItem("flow", "all");
        localStorage.setItem("order", "lifo");
        // localStorage.setItem("pincode", "all");
        let flowStatus = localStorage.getItem("flow");
        let order = localStorage.getItem("order");
        let player = localStorage.getItem("player");

        this.getTicketlist(
          selectedHub,
          selectedBlockStatus,
          e,
          "1",
          order,
          flowStatus,
          pincode,
          player
        );
      }
    } catch {
      console.log("Error in TicketList>>>>>>handlebtn");
    }
  }

  componentDidMount() {
    this.resizeTicketList();
    this.loadHubs();
    let curHub = localStorage.getItem("curHub");
    this.getTicketsCount(curHub);
    this.getPincodesListByHub(curHub);
    this.getPincodesListByHubByStatus(
      curHub,
      this.state.ticketStatusForPincodes
    );
    this.getPlayers();
  }
  async getTicketsCount(curHub) {
    try {
      let param = {
        hubId: curHub,
      };
      let resp = await getTicketsCounts(param);
      if (resp !== undefined) {
        if (resp.data.data) {
          this.setState({ unAssigned: resp.data.data.unAssigned });
          this.setState({ assigned: resp.data.data.assigned });
          this.setState({ rescheduled: resp.data.data.rescheduled });
          this.setState({ cancelled: resp.data.data.cancelled });
          this.setState({ completed: resp.data.data.completed });
          this.setState({ field: resp.data.data.field });
        }
      }
    } catch {
      console.log("Error in TicketList>>>>>>getTicketsCount");
    }
  }
  async getPincodesListByHub(curHub) {
    // try {
    //   let pincodeList = [];
    //   let resp = await getPincodesList(curHub);
    //   if (resp !== undefined) {
    //     if (resp.data.data) {
    //       let result = resp.data.data.filter((e) => e);
    //       result.forEach((element, index) => {
    //         if (element !== "null") {
    //           pincodeList.push({ sortKey: element, sort: element });
    //         }
    //       });
    //       pincodeList.unshift({ sortKey: "all", sort: "All" });
    //       this.setState({ pincodeList });
    //     }
    //   }
    // } catch {
    //   console.log("Error in TicketList>>>getPincodesListByHub");
    // }
  }

  async getPincodesListByHubByStatus(curHub, status) {
    try {
      let pincodeList = [];
      let resp = await getPincodesListByStatus(curHub, status);
      // console.log("oldd=>", resp);
      if (resp !== undefined) {
        if (resp.data.data) {
          let result = resp.data.data.filter((e) => e);
          result.forEach((element, index) => {
            if (element !== "null") {
              pincodeList.push({ sortKey: element, sort: element });
            }
          });
          pincodeList.unshift({ sortKey: "all", sort: "All" });
          this.setState({ pincodeList });
        }
      }
    } catch {
      console.log("Error in TicketList>>>getPincodesListByHub");
    }
  }

  async loadHubs() {
    try {
      let hubList = JSON.parse(localStorage.getItem("hubs"));
      this.setState({ hubList });

      let curHub = localStorage.getItem("curHub");

      if (!curHub) {
        localStorage.setItem(
          "curHub",
          JSON.parse(JSON.stringify(hubList[0].hubId))
        );
        let override = this.state.data;
        override["hub"] = JSON.parse(JSON.stringify(hubList[0].hubId));
        this.setState({ override });
      } else {
        let override = this.state.data;
        override["hub"] = curHub;
        this.setState({ override });
      }
      this.loadTickets();
    } catch {
      console.log("Error in TicketList>>>>>>loadHubs");
    }
  }

  async loadTickets() {
    try {
      let actionBtn = localStorage.getItem("actionBtn");
      if (actionBtn === "unassigned") {
        localStorage.setItem("flow", "pick");
      } else {
        localStorage.setItem("flow", "all");
      }
      let selectedHub = localStorage.getItem("curHub");
      localStorage.setItem("blockStatus", "all");
      localStorage.setItem("order", "lifo");
      localStorage.setItem("pincode", "all");
      let flowStatus = localStorage.getItem("flow");
      let order = localStorage.getItem("order");
      let pincode = localStorage.getItem("pincode");
      let selectedBlockStatus = localStorage.getItem("blockStatus");
      let player = localStorage.getItem("player");

      if (actionBtn) {
        this.getTicketlist(
          selectedHub,
          selectedBlockStatus,
          actionBtn,
          this.state.pageLinks.pageNo,
          order,
          flowStatus,
          pincode,
          player
        );
        this.setState({ btnValue: actionBtn });
      } else {
        this.getTicketlist(
          selectedHub,
          selectedBlockStatus,
          this.state.btnValue,
          this.state.pageLinks.pageNo,
          order,
          flowStatus,
          pincode,
          player
        );
      }
    } catch {
      console.log("Error in TicketList>>>>>>loadTickets");
    }
  }
  // let selectedStatus = localStorage.getItem("blockStatus");
  localHandleChange = (e) => {
    try {
      if (e.target.name === "hub") {
        let selectedBlockStatus = localStorage.getItem("blockStatus");
        let flowStatus = localStorage.getItem("flow");
        let order = localStorage.getItem("order");
        let pincode = localStorage.getItem("pincode");
        localStorage.setItem("curHub", e.target.value);
        let player = localStorage.getItem("player");

        this.getTicketlist(
          e.target.value,
          selectedBlockStatus,
          this.state.btnValue,
          this.state.pageLinks.pageNo,
          order,
          flowStatus,
          pincode,
          player
        );
        this.getPlayers();
        this.getTicketsCount(e.target.value);
        // this.getPincodesListByHub(e.target.value);
        this.getPincodesListByHubByStatus(
          e.target.value,
          this.state.ticketStatusForPincodes
        );
      } else if (e.target.name === "ticketStatus") {
        let blockStatusValue = e.target.value;
        localStorage.setItem("blockStatus", blockStatusValue);
        let selectedHub = localStorage.getItem("curHub");
        let flowStatus = localStorage.getItem("flow");
        let order = localStorage.getItem("order");
        let pincode = localStorage.getItem("pincode");
        let player = localStorage.getItem("player");

        this.getTicketlist(
          selectedHub,
          blockStatusValue,
          this.state.btnValue,
          this.state.pageLinks.pageNo,
          order,
          flowStatus,
          pincode,
          player
        );
      } else if (e.target.name === "flow") {
        let flowStatus = e.target.value;
        localStorage.setItem("flow", flowStatus);
        let selectedBlockStatus = localStorage.getItem("blockStatus");
        let selectedHub = localStorage.getItem("curHub");
        let order = localStorage.getItem("order");
        let pincode = localStorage.getItem("pincode");
        let player = localStorage.getItem("player");

        this.getTicketlist(
          selectedHub,
          selectedBlockStatus,
          this.state.btnValue,
          this.state.pageLinks.pageNo,
          order,
          flowStatus,
          pincode,
          player
        );
      } else if (e.target.name === "order") {
        let order = e.target.value;
        localStorage.setItem("order", order);
        let selectedBlockStatus = localStorage.getItem("blockStatus");
        let selectedHub = localStorage.getItem("curHub");
        let flowStatus = localStorage.getItem("flow");
        let pincode = localStorage.getItem("pincode");
        let player = localStorage.getItem("player");

        this.getTicketlist(
          selectedHub,
          selectedBlockStatus,
          this.state.btnValue,
          this.state.pageLinks.pageNo,
          order,
          flowStatus,
          pincode,
          player
        );
      } else if (e.target.name === "pincodeList") {
        let pincode = e.target.value;
        localStorage.setItem("pincode", pincode);
        let selectedBlockStatus = localStorage.getItem("blockStatus");
        let selectedHub = localStorage.getItem("curHub");
        let flowStatus = localStorage.getItem("flow");
        let order = localStorage.getItem("order");
        let player = localStorage.getItem("player");

        this.getTicketlist(
          selectedHub,
          selectedBlockStatus,
          this.state.btnValue,
          this.state.pageLinks.pageNo,
          order,
          flowStatus,
          pincode,
          player
        );
      } else if (e.target.name === "usersList") {
        let player = e.target.value;
        localStorage.setItem("player", player);
        let selectedBlockStatus = localStorage.getItem("blockStatus");
        let selectedHub = localStorage.getItem("curHub");
        let flowStatus = localStorage.getItem("flow");
        let order = localStorage.getItem("order");
        let pincode = localStorage.getItem("pincode");

        this.getTicketlist(
          selectedHub,
          selectedBlockStatus,
          this.state.btnValue,
          this.state.pageLinks.pageNo,
          order,
          flowStatus,
          pincode,
          player
        );
      } else if (e.target.name === "includeVehicleInfo") {
        this.setState({ captureInfo: e.target.checked });
      }
    } catch {
      console.log("Error in TicketList>>>>>>localHandleChange");
    }
  };

  // let selectedBlockStatus = localStorage.getItem("blockStatus");
  async getTicketlist(
    selectedHub,
    selectedBlockStatus,
    e,
    pageNo,
    order,
    flowStatus,
    pincode,
    player
  ) {
    try {
      this.setState({ loading: true });

      let hubList = JSON.parse(localStorage.getItem("hubs"));

      const curHubCode = hubList.filter((h) => {
        return h.hubId == selectedHub ? h.hubCode : "";
      });

      if (curHubCode != "") {
        this.setState({ curHubCode: curHubCode[0].hubCode });
        this.setState({ curHubName: curHubCode[0].hub });
      } else {
        this.setState({ curHubName: curHubCode[0].hub });
        this.setState({ curHubCode: hubList[0].hubCode });
        localStorage.setItem("curHub", hubList[0].hubId);
        selectedHub = hubList[0].hubId;
        let override = this.state.data;
        override["hub"] = selectedHub;
        this.setState({ override });
      }

      // let hub = "5067";
      // let hub = "1111";
      let hub = selectedHub;

      let params = {
        hubId: hub.toString(),
        action: e,
        searchString: this.state.data.search,
        blockStatus: selectedBlockStatus,
        order: order,
        flowStatus: flowStatus,
        pincode: pincode,
        player: player,
        searchStringType: this.state.data.ticketScope,
      };
      // console.log(JSON.stringify(params));
      let resp = await getTicketDetailsNewOne(params, pageNo);
      // let resp = await getTicketDetails(params, pageNo);
      if (resp !== undefined) {
        if (resp.data.success === true && resp.data.data) {
          resp.data.data.tickets &&
            this.setState({ ticketDetails: resp.data.data.tickets });

          resp.data.data.pageLinks &&
            this.setState({ pageLinks: resp.data.data.pageLinks });
          // resp.data.data.tickets && toast.success(() => <div>{resp.data.msg}</div>);
          !resp.data.data.tickets && toast.warning(() => <div>No Tickets</div>);
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in TicketList>>>>>>getTicketlist");
    }
  }

  handlePageChange = (pageNo) => {
    try {
      let selectedHub = localStorage.getItem("curHub");
      let selectedBlockStatus = localStorage.getItem("blockStatus");
      let flowStatus = localStorage.getItem("flow");
      let order = localStorage.getItem("order");
      let pincode = localStorage.getItem("pincode");
      let player = localStorage.getItem("player");

      this.getTicketlist(
        selectedHub,
        selectedBlockStatus,
        this.state.btnValue,
        pageNo,
        order,
        flowStatus,
        pincode,
        player
      );
    } catch {
      console.log("Error in TicketList>>>>>>handlePageChange");
    }
  };

  resizeTicketList = () => {
    $("#cocard").css("max-height", $(window).height() - 68);
    $("#cocardHistory").css("max-height", $(window).height() - 15);
  };

  handleView = () => {
    $(".modal-content").resizable({
      //alsoResize: ".modal-dialog",
      minHeight: 300,
      minWidth: 300,
    });
    $("#myModal").draggable();
    $("#myModalProduct").draggable();

    $("#myModal").on("show.bs.modal", function () {
      $(this).find(".modal-body").css({
        "max-height": "100%",
        "max-width": "100%",
      });
    });
    $("#myModalProduct").on("show.bs.modal", function () {
      $(this).find(".modal-body").css({
        "max-height": "100%",
      });
    });
  };

  setCurBlockTicket = (b, t) => {
    this.setState({ curBlockDetails: b });
    this.setState({ curTicketDetails: t });
    this.props.onTicketChange(
      b,
      t,
      "notBulk",
      "ticket",
      this.state.captureInfo
    );
    this.getTicketInfo(t.ticketNo);
    this.getTicketMisHistory(t.ticketNo);
  };

  async getTicketInfo(ticketNo) {
    try {
      this.state.TicketProductDetails1 = [];
      this.state.TicketProductDetails = [];
      this.state.TicketPrimarytDetails = [];
      const respDst = await getTicketInfo(ticketNo);
      if (respDst !== undefined) {
        if (respDst.data.success === true && respDst.data.data) {
          this.setState({
            TicketPrimarytDetails:
              respDst.data.data.TicketDetails.primaryDetails,
          });
          this.setState({
            TicketProductDetails:
              respDst.data.data.TicketDetails.productDetails[0],
          });
          this.setState({
            TicketProductDetails1:
              respDst.data.data.TicketDetails.productDetails[1],
          });
        } else if (respDst.data.success === false) {
          toast.error("No Details Available for this Ticket");
        }
      }
    } catch {
      console.log("Error in TicketList>>>>>>getTicketInfo");
    }
  }

  async getTicketMisHistory(ticketNo) {
    try {
      this.state.curMisReportHistory = [];
      this.state.TicketRemarks = [];
      const respDst = await getTicketMisHistory(ticketNo);
      const resRemarks = await getTicketRemarks(ticketNo);
      if (resRemarks.data.success === true && resRemarks.data.data) {
        this.setState({ TicketRemarks: resRemarks.data.data.ticketRemarks });
      }
      if (respDst !== undefined) {
        if (respDst.data.success === true && respDst.data.data) {
          this.setState({
            curMisReportHistory: respDst.data.data.MisReportHistory,
          });
        } else if (respDst.data.success === false) {
          toast.error("No Details Available for this Ticket");
        }
      }
    } catch {
      console.log("Error in TicketList>>>getTicketMisHistory");
    }
  }

  replaceString = (str) => {
    if (str) {
      str = str.replace(/_/g, " ");
      return str.replace("BLOCK", "JOB");
    }
  };

  async doSubmit() {
    try {
      let selectedHub = localStorage.getItem("curHub");
      let selectedBlockStatus = localStorage.getItem("blockStatus");
      let flowStatus = localStorage.getItem("flow");
      let order = localStorage.getItem("order");
      let pincode = localStorage.getItem("pincode");
      let player = localStorage.getItem("player");

      this.getTicketlist(
        selectedHub,
        selectedBlockStatus,
        this.state.btnValue,
        this.state.pageLinks.pageNo,
        order,
        flowStatus,
        pincode,
        player
      );
    } catch {
      console.log("Error in TicketList>>>>>>doSubmit");
    }
  }

  handleSearch = () => {
    try {
      let selectedHub = localStorage.getItem("curHub");
      let pageLinks = this.state.pageLinks;
      pageLinks["pageNo"] = "1";

      this.setState({ pageLinks });
      let selectedBlockStatus = localStorage.getItem("blockStatus");
      let flowStatus = localStorage.getItem("flow");
      let order = localStorage.getItem("order");
      let pincode = localStorage.getItem("pincode");
      let player = localStorage.getItem("player");
      this.getTicketlist(
        selectedHub,
        selectedBlockStatus,
        this.state.btnValue,
        this.state.pageLinks.pageNo,
        order,
        flowStatus,
        pincode,
        player
      );
      this.getTicketsCount(selectedHub);
      // this.getPincodesListByHub(selectedHub);
      this.getPincodesListByHubByStatus(
        selectedHub,
        this.state.ticketStatusForPincodes
      );
    } catch {
      console.log("Error in TicketList>>>>>>handleSearch");
    }
  };

  handleHubModalShow = () => {
    window.$("#myModalProduct").show();
  };

  stsConf = {
    value: "hubId",
    label: "hub",
  };
  ticketStatus = [
    { sortKey: "all", sort: "All" },
    { sortKey: "ASSIGN_TO_HO", sort: "ASSIGN TO HO" },
    { sortKey: "APPOINTMENT_FIXED", sort: "APPOINTMENT FIXED" },
    { sortKey: "CHOOSE_FE_FOR_DROP", sort: "CHOOSE FE FOR DROP" },
    { sortKey: "CHOOSE_FE_FOR_DROP_ASC", sort: "CHOOSE FE FOR DROP ASC" },
    { sortKey: "DROP_DONE", sort: "DROP DONE" },
    { sortKey: "DROP_DONE_ASC", sort: "DROP DONE ASC" },
    { sortKey: "DROP_CONFIRMED", sort: "DROP CONFIRMED" },
    { sortKey: "EVALUATION_RESULT_DONE", sort: "EVALUATION RESULT DONE" },
    {
      sortKey: "HANDED_OVER_PACKING_MATERIALS",
      sort: "HANDED OVER PACKING MATERIALS",
    },
    // { sortKey: "IDLE_FOR_15MIN", sort: "IDLE FOR 15MIN" },
    { sortKey: "INSTALL_SERVICE_DONE", sort: "INSTALL SERVICE DONE" },
    { sortKey: "LINEHAUL", sort: "LINEHAUL" },
    { sortKey: "LINEHAUL_IN_TRANSIT", sort: "LINEHAUL IN TRANSIT" },
    {
      sortKey: "OLD_PRODUCT_DAMAGED_EXCHANGE_CANCELLED",
      sort: "OLD PRODUCT DAMAGED EXCHANGE CANCELLED",
    },
    {
      sortKey: "PICK_UP_DONE_EXCHANGE_SUCCESSFULLY",
      sort: "PICK UP DONE EXCHANGE SUCCESSFULLY",
    },
    { sortKey: "PICKUP_DONE", sort: "PICKUP DONE" },
    { sortKey: "PICKUP_DONE_ASC", sort: "PICKUP DONE ASC" },
    {
      sortKey: "PROBLEM_SOLVED_DURING_INITIAL_CALL",
      sort: "PROBLEM SOLVED DURING INITIAL CALL",
    },
    { sortKey: "PART_RECEIVED", sort: "PART RECEIVED" },
    { sortKey: "PART_MISSING", sort: "PART MISSING" },
    {
      sortKey: "RESCHEDULE_REQUEST_FE_AT_PICKUP",
      sort: "RESCHEDULE REQUEST FE AT PICKUP",
    },
    {
      sortKey: "RESCHEDULE_REQUEST_FE_AT_DROP",
      sort: "RESCHEDULE REQUEST FE AT DROP",
    },
    { sortKey: "RTO_INITIATE", sort: "RTO INITIATE" },
    { sortKey: "RTO_REQUEST", sort: "RTO REQUEST" },
    { sortKey: "RTO_REJECT", sort: "RTO REJECT" },
    { sortKey: "REJECT_FE", sort: "REJECT FE" },
    {
      sortKey: "REJECT_SERVICE_FE_AT_PICKUP",
      sort: "REJECT SERVICE FE AT PICKUP",
    },
    { sortKey: "REJECT_SERVICE_FE_AT_DROP", sort: "REJECT SERVICE FE AT DROP" },
  ];
  stsConfig = {
    value: "sortKey",
    label: "sort",
  };

  handleCheckBoxClick = (e, b, t) => {
    try {
      if (e.target.checked) {
        this.state.curMultiBlockDetails.push(b);
        this.state.curMultiTicketDetails.push(t);
        // t["blocks"].push(b)
        // this.state.curMultiTickets.push(t);
        this.setState({ blockSelected: true });
        // console.log("b..........." + JSON.stringify(b));
        // console.log("t..........." + JSON.stringify(t));
      } else {
        let index = this.state.curMultiBlockDetails.indexOf(b);
        if (index > -1) {
          this.state.curMultiBlockDetails.splice(index, 1);
        }
        let index1 = this.state.curMultiTicketDetails.indexOf(t);
        if (index1 > -1) {
          this.state.curMultiTicketDetails.splice(index1, 1);
        }
      }
      this.props.onTicketChange(
        this.state.curMultiBlockDetails,
        this.state.curMultiTicketDetails,
        "bulk",
        "ticket",
        this.state.captureInfo
      );
    } catch {
      console.log("Error in handleCheckBoxClick");
    }
  };

  async getPlayers() {
    try {
      let hub = localStorage.getItem("curHub");
      let resp = await getPlayersbyRole(hub);
      // let resp = await getPlayersbyRoleCount(hub);

      if (resp.data.data) {
        let users = resp.data.data.playersHubs;

        const filter = resp.data.data.playersHubs.filter((r) => {
          if (!r.rlastName) {
            r.rlastName = "";
          }
          r["display"] = r.firstName + " " + r.rlastName + " / " + r.userName; //+ " - " + r.count;
          return r;
        });
        let usersList = [];
        if (resp.data.success === true) {
          resp.data.data.playersHubs && this.setState({ users: filter });
          let result = resp.data.data.playersHubs.filter((e) => e);
          result.forEach((element, index) => {
            if (element !== "null") {
              usersList.push({
                sortKey: element.playerId,
                sort: element.display,
              });
            }
          });
          usersList.unshift({ sortKey: "all", sort: "All" });
          this.setState({ usersList });
          !resp.data.data.playersHubs &&
            toast.warning(() => <div>{resp.data.msg}</div>);
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      }
    } catch {
      console.log("Error in getPlayers");
    }
  }
  navigateToHistory = (t) => {
    this.props.onTicketChange(
      this.state.curBlockDetails,
      t,
      "notBulk",
      "ticketHistory",
      this.state.captureInfo
    );
  };
  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <div id="cocard" className="col-12  ml-1 mr-4">
          <div id="primary-ticket-search" className="row">
            <div
              className="form-row "
              style={{ marginTop: "4px", marginLeft: "12px" }}
            >
              <div>
                <button
                  onClick={() => this.handlebtn("unassigned")}
                  className={
                    this.state.btnValue === "unassigned"
                      ? " badge badge-warning  "
                      : "badge badge-info  "
                  }
                  style={{
                    fontSize: "82%",
                    borderRadius: "2.25rem ",
                    border: "none",
                    fontWeight: "normal",
                    marginRight: "2px",
                  }}
                  title="UnAssigned"
                >
                  UnAssigned<b>({this.state.unAssigned})</b>
                </button>
              </div>
              <div>
                <button
                  onClick={() => this.handlebtn("assigned")}
                  className={
                    this.state.btnValue === "assigned"
                      ? " badge badge-warning "
                      : "badge badge-info "
                  }
                  title="Assigned"
                  style={{
                    fontSize: "82%",
                    borderRadius: "2.25rem ",
                    border: "none",
                    fontWeight: "normal",
                    marginRight: "2px",
                  }}
                >
                  Assigned<b>({this.state.assigned})</b>
                </button>
              </div>
              <div>
                <button
                  onClick={() => this.handlebtn("rescheduled")}
                  className={
                    this.state.btnValue === "rescheduled"
                      ? " badge badge-warning"
                      : "badge badge-info  "
                  }
                  title="Rescheduled"
                  style={{
                    fontSize: "82%",
                    borderRadius: "2.25rem ",
                    border: "none",
                    fontWeight: "normal",
                    marginRight: "2px",
                  }}
                >
                  Rescheduled<b>({this.state.rescheduled})</b>
                </button>
              </div>
              {/* <div >
                <button
                  onClick={() => this.handlebtn("cancelled")}
                  className={
                    this.state.btnValue === "cancelled"
                      ? " badge badge-warning  "
                      : "badge badge-info "
                  }
                  title="Cancelled"
                  style={{ fontSize: "80%", borderRadius: "2.25rem ", border: "none", fontWeight: "normal", marginRight: "2px" }}
                >
                  Cancelled
                </button>
              </div> */}
              {/* <div >
                <button
                  onClick={() => this.handlebtn("completed")}
                  className={
                    this.state.btnValue === "completed"
                      ? " badge badge-warning  "
                      : "badge badge-info "
                  }
                  title="completed"
                  style={{ fontSize: "80%", borderRadius: "2.25rem ", border: "none", fontWeight: "normal", marginRight: "2px" }}
                >
                  Completed
                </button>
              </div> */}
              <div>
                <button
                  onClick={() => this.handlebtn("field")}
                  className={
                    this.state.btnValue === "field"
                      ? " badge badge-warning "
                      : "badge badge-info "
                  }
                  title="FE Tickets"
                  style={{
                    fontSize: "82%",
                    borderRadius: "2.25rem ",
                    border: "none",
                    fontWeight: "normal",
                    marginRight: "6px",
                  }}
                >
                  FE Tickets<b>({this.state.field})</b>
                </button>
              </div>
              <div>
                {/* <a
                      className="dropdown-item"
                      type="button"
                      data-target="#myModals"
                      data-backdrop="false"
                      data-toggle="modal"
                      onClick={() => this.onModeChange("LineHaul")}
                    >
                      LineHaul Process
                    </a> */}
                <button
                  onClick={() => this.onModeChange("LineHaul")}
                  className={
                    this.state.btnValue === "LineHaul"
                      ? " badge badge-warning "
                      : "badge badge-info "
                  }
                  title="LineHaul"
                  data-bs-target="#myModals"
                  data-backdrop="false"
                  data-bs-toggle="modal"
                  style={{
                    fontSize: "82%",
                    borderRadius: "2.25rem ",
                    border: "none",
                    fontWeight: "normal",
                    marginRight: "6px",
                  }}
                >
                  LineHaul
                </button>
              </div>

              <div className="dropdown">
                <button
                  className="badge badge-info dropdown-toggle"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    fontSize: "82%",
                    borderRadius: "2.25rem ",
                    border: "none",
                    fontWeight: "normal",
                    marginRight: "2px",
                  }}
                >
                  More Actions
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <a
                      className="dropdown-item"
                      type="button"
                      onClick={() => this.handlebtn("cancelled")}
                      style={{ marginRight: "3px" }}
                    >
                      Cancelled <b>({this.state.cancelled})</b>
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      type="button"
                      onClick={() => this.handlebtn("completed")}
                      style={{ marginRight: "2px" }}
                    >
                      Completed <b>({this.state.completed})</b>
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="dropdown-item"
                      type="button"
                      data-target="#myModals"
                      data-backdrop="false"
                      data-toggle="modal"
                      onClick={() => this.onModeChange("LineHaul")}
                    >
                      LineHaul Process
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div
              className="form-row "
              style={{
                marginTop: "25px",
                marginLeft: "5px",
                marginBottom: "-35px",
                position: "relative",
              }}
            >
              {this.rRBL("Search by :", "ticketScope", this.ticketScope, {
                value: "sortKey",
                label: "sort",
              })}
            </div>
            <div className="col-4 mt-4 " style={{ marginLeft: "-10%" }}>
              {this.state.btnValue === "assigned" && (
                <div style={{ marginLeft: "24%" }}>
                  {this.rSB(
                    "Ticket Status",
                    "ticketStatus",
                    this.ticketStatus,
                    this.stsConfig,
                    " col-12   "
                  )}
                </div>
              )}

              {this.state.btnValue === "field" && (
                <div style={{ marginLeft: "24%" }}>
                  {this.rSB(
                    "FE List :",
                    "usersList",
                    this.state.usersList,
                    this.stsConfig,
                    " col-12"
                  )}
                </div>
              )}
            </div>
            <div className="col-4 pull-right" style={{ marginLeft: "-2%" }}>
              <form onSubmit={this.handleSubmit}>
                {this.rTB("Search .....", "search", "mt-3")}
              </form>
            </div>
            <div className="col-5 pull-right mt-3">
              <i
                className="fa fa-search cursorPointor fa-lg pr-1"
                style={{ marginLeft: "-1px" }}
                onClick={this.handleSearch}
              ></i>

              <a>
                <i
                  id="btn1"
                  data-target="#myModalProduct"
                  data-backdrop="false"
                  data-toggle="modal"
                  className="badge badge-warning  cursorPointor pr-1 zoom"
                  aria-hidden="true"
                  title={"Select Hub"}
                  style={{ marginLeft: "-1px" }}
                >
                  <b>{this.state.curHubName}</b>
                </i>
              </a>
              <i
                className="fa fa-refresh cursorPointor fa-lg  pr-2 "
                title="Refresh"
                onClick={this.handleSearch}
              />

              {this.rCBHL("includeVehicleInfo", this.includeVehicleInfo, {
                value: "sortKey",
                label: "sort",
              })}
            </div>

            <div
              className="form-row "
              style={{
                marginTop: "4px",
                marginLeft: "1%",
                marginBottom: "-24px",
              }}
            >
              {(this.state.btnValue === "assigned" ||
                this.state.btnValue === "unassigned") && (
                <>
                  {this.rSB(
                    "Pincodes :",
                    "pincodeList",
                    this.state.pincodeList,
                    this.stsConfig,
                    " col-2"
                  )}
                </>
              )}
              {(this.state.btnValue === "unassigned" ||
                this.state.btnValue === "assigned" ||
                this.state.btnValue === "rescheduled") && (
                <div
                  className="col-4"
                  style={{
                    marginLeft: "14px",
                    marginRight: "-24px",
                    marginBottom: "-24px",
                  }}
                >
                  {this.rRBL("Order :", "order", this.order, {
                    value: "sortKey",
                    label: "sort",
                  })}
                </div>
              )}

              {(this.state.btnValue === "assigned" ||
                this.state.btnValue === "rescheduled") && (
                <div className="col-6">
                  {this.rRBL("Flow :", "flow", this.flow, {
                    value: "sortKey",
                    label: "sort",
                  })}
                </div>
              )}
            </div>
            <div className="col-12">
              {this.state.loading && (
                <div className="container-fluid">
                  <div> Loading Tickets...</div>
                </div>
              )}
              {!this.state.loading && itemsCount >= 0 && (
                <>
                  <div className="container-fluid pull-right">
                    <Pagination
                      pageNo={pageNo}
                      perPage={perPage}
                      itemsCount={itemsCount}
                      NameOfComp="Tickets"
                      onPageChange={(pageNo) => this.handlePageChange(pageNo)}
                    />
                    {!this.state.loading && itemsCount === 0 && (
                      <div className="alert alert-info" role="alert">
                        <div> No Records Found! </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            {/*  */}
          </div>
          <div>
            {this.state.ticketDetails.map((t, index) => {
              return (
                <div className="row">
                  <div className="col-11">
                    <span key={t.processId + "spanCover1" + index}>
                      {t.blocks.map((b, index) => {
                        return (
                          <span key={b.ticketBlockId + "spanCover1" + index}>
                            {this.state.data.includeVehicleInfo === "yes" && (
                              <input
                                type="checkbox"
                                name={t.ticketNo}
                                value={t.ticketNo}
                                title="Bulk Ticket Selection"
                                onClick={(e) =>
                                  this.handleCheckBoxClick(e, b, t)
                                }
                                className="multiInput"
                              />
                            )}
                            {(this.state.btnValue === "assigned" ||
                              this.state.btnValue === "unassigned" ||
                              this.state.btnValue === "rescheduled" ||
                              this.state.btnValue === "cancelled" ||
                              this.state.btnValue === "completed" ||
                              this.state.btnValue === "field") &&
                              ((b.blockStatus === "APPOINTMENT_FIXED" &&
                                ((t.products &&
                                  t.products[0].prodStatus !== null &&
                                  t.retailerCode !== "DRP") ||
                                  (t.retailerCode === "DRP" &&
                                    b.blockInventory !== null))) ||  
                                b.blockStatus === "CHOOSE_FE_FOR_DROP_ASC" ||
                                b.blockStatus === "DROP_DONE_ASC" ||
                                b.blockStatus === "PICKUP_DONE_ASC" ||
                                (b.blockStatus === "APPOINTMENT_FIXED" &&
                                  b.flowStatus === "DROP_WEB") ||
                                (b.blockStatus === "APPOINTMENT_FIXED" &&
                                  t.flowCode === "INSV1" &&
                                  t.retailerCode !== "DRP" &&
                                  t.retailerCode !== "EFL" &&
                                  b.blockStatus !== "INSTALL_SERVICE_DONE") ||
                                (b.blockStatus === "APPOINTMENT_FIXED" &&
                                  b.blockStatus === "PART_RECEIVED") ||
                                (b.blockStatus === "APPOINTMENT_FIXED" &&
                                  b.blockStatus === "RTO_INITIATE") ||
                                (b.blockStatus === "APPOINTMENT_FIXED" &&
                                  b.blockStatus === "RTO_REJECT") ||
                                (t.flowId === "BulkQC" &&
                                  b.blockStatus === "APPOINTMENT_FIXED")) &&
                              t.flowId !== "Furniture" &&
                              t.flowId !== "eWaste" && (
                                <input
                                  type="checkbox"
                                  name={t.ticketNo}
                                  value={t.ticketNo}
                                  title="Bulk Ticket Selection"
                                  onClick={(e) =>
                                    this.handleCheckBoxClick(e, b, t)
                                  }
                                  className="multiInput"
                                />
                              )}
                            <div
                              className="card mb-2 cursorPointor"
                              onClick={() => this.setCurBlockTicket(b, t)}
                              key={t.processId + b.ticketBlockId + index}
                            >
                              <div
                                className="card-header page-todo task"
                                style={
                                  this.state.curBlockDetails.ticketBlockId &&
                                  this.state.curBlockDetails.ticketBlockId ===
                                    b.ticketBlockId
                                    ? { backgroundColor: "#cfedda" }
                                    : { backgroundColor: "white" }
                                }
                              >
                                <div className="row">
                                  <div className="col-12">
                                    <a>
                                      <i
                                        id="btn1"
                                        // data-target="#myModal"
                                        // data-backdrop="false"
                                        // data-toggle="modal"
                                        // className="cursorPointor"
                                        aria-hidden="true"
                                        // title="Ticket Details"
                                      >
                                        <b style={{ color: "#737CA1" }}>
                                          {t.ticketNo}{" "}
                                        </b>
                                      </i>
                                    </a>{" "}
                                    {this.state.data.ticketScope ===
                                      "conComplaintNo" && (
                                      <>
                                        |{" "}
                                        <span title="conComplaintNo">
                                          {t.conComplaintNo}
                                        </span>
                                      </>
                                    )}
                                    {this.state.data.ticketScope ===
                                      "LRNumber" && (
                                      <>
                                        |{" "}
                                        <span title="LRNumber">
                                          {t.LrNumber}
                                        </span>
                                      </>
                                    )}
                                    {this.state.data.ticketScope ===
                                      "invoiceNo" && (
                                      <>
                                        |{" "}
                                        <span title="invoiceNo">
                                          {t.invoiceNo}
                                        </span>
                                      </>
                                    )}
                                    {this.state.data.ticketScope ===
                                      "orderNo" && (
                                      <>
                                        |{" "}
                                        <span title="orderNumber">
                                          {t.orderNo}
                                        </span>
                                      </>
                                    )}
                                    {b.blockStatus && (
                                      <span
                                        className={
                                          "badge badge-success badge-normal pull-right"
                                        }
                                      >
                                        {this.replaceString(b.blockStatus)}
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-12">
                                    <span
                                      className={
                                        t.ticketPriority === "high"
                                          ? "badge badge-danger"
                                          : "badge badge-primary badge-05assign"
                                      }
                                    >
                                      {t.ticketPriority}
                                    </span>
                                    <span className="badge badge-info mr-1 ml-1">
                                      {t.flowCode}
                                    </span>
                                    {t.flowId === "InstallationV1" && (
                                      <span className="badge badge-warning mr-1 ml-1">
                                        {t.primaryDetails.ticketType}
                                      </span>
                                    )}
                                    {t.flowId === "InstallationV1" &&
                                      t.retailerCode === "DRP" &&
                                      t.primaryDetails &&
                                      t.primaryDetails.serviceLevel && (
                                        <span className="badge badge-success badge-normal mr-1 ml-1">
                                          {t.primaryDetails.serviceLevel}
                                        </span>
                                      )}
                                    <span>
                                      {epoch2ddmmyy(t.dateOfTicket)}
                                    </span>{" "}
                                    {(b.flowStatus === "PICK_WEB" ||
                                      b.flowStatus === "PICK_MOB") &&
                                      t.products && (
                                        <span
                                          className="badge badge-secondary mr-1 ml-1"
                                          data-tip={
                                            t.products[0].srcAdd1 +
                                            " : " +
                                            t.products[0].srcAdd2
                                          }
                                        >
                                          {"Pickup-" + t.products[0].srcPincode}
                                          <ReactTooltip />
                                        </span>
                                      )}
                                    {(b.flowStatus === "DROP_WEB" ||
                                      b.flowStatus === "DROP_MOB") &&
                                      t.products && (
                                        <span
                                          className="badge badge-secondary  mr-1 ml-1"
                                          data-tip={
                                            t.products[0].dstAdd1 +
                                            " : " +
                                            t.products[0].dstAdd2
                                          }
                                        >
                                          {"Drop-" + t.products[0].dstPincode}
                                          <ReactTooltip multiline={true} />
                                        </span>
                                      )}
                                    {(b.blockStatus.includes("RESCHEDULE") ||
                                      b.blockStatus.includes("IDLE")) &&
                                      !b.blockStatus.includes(
                                        "RESCHEDULE_REQUEST"
                                      ) &&
                                      b.nextActionAfter && (
                                        <span
                                          style={{
                                            backgroundColor: "rgb(252 224 224)",
                                            marginLeft: "2%",
                                            borderRadius: "0.2rem",
                                          }}
                                          data-tip={epoch2ddmmyyyyhhmmss(
                                            b.nextActionAfter
                                          )}
                                        >
                                          {epoch2ddmmyy(b.nextActionAfter)}
                                          <ReactTooltip id="path" />
                                        </span>
                                      )}
                                    {t.retailerCode === "OA" && (
                                      <span className="badge badge-warning mr-1 ml-1">
                                        {t?.carrier}
                                      </span>
                                    )}
                                    <span className="pull-right">
                                      {t.retailerNickName}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </span>
                        );
                      })}
                    </span>
                  </div>
                  <div className="col-1">
                    <span
                      className="badge badge-info mr-0 ml-0 mt-4 cursorPointor"
                      title="Ticket History"
                      onClick={() => this.navigateToHistory(t)}
                      style={{ backgroundColor: "#a7aba8" }}
                    >
                      <i className="fa fa-history fa-2x "></i>
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="modal fade"
          id="myModal"
          role="dialog"
          aria-labelledby="myModal"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog momodel modal-fluid modal-xl "
            style={{ marginLeft: "35%", marginTop: "0%" }}
            role="document"
          >
            <div
              className="modal-content-full-width modal-content"
              style={{ backgroundColor: " #e8e8e8" }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  <h5>
                    <b title="TicketNumber">
                      {" "}
                      {this.state.curTicketDetails.ticketNo} |{" "}
                      <span title="conComplaintNumber">
                        {this.state.curTicketDetails.conComplaintNo}
                      </span>{" "}
                      |{" "}
                      <span className="badge badge-info mr-1 ml-1">
                        {this.state.curTicketDetails.flowId}
                      </span>
                    </b>
                  </h5>
                </h5>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="container-fluid">
                  <h1 className="h3 mb-2"></h1>
                  <div className="row">
                    <div className="col-lg-5" style={{ marginTop: "-16px" }}>
                      {this.state.curTicketDetails.flowId !==
                        "AdvanceExchange" && (
                        <>
                          <div
                            className="card "
                            style={{ marginBottom: "1rem" }}
                          >
                            <div className="card-body ">
                              <p
                                style={{
                                  marginBottom: "1rem",
                                }}
                              >
                                <b>Status :</b>{" "}
                                <span className="text-success">
                                  <b>
                                    {this.replaceString(
                                      this.state.curBlockDetails.blockStatus
                                    )}
                                  </b>
                                </span>
                              </p>
                              <p>
                                <b>Product Category:</b>{" "}
                                {this.state.TicketProductDetails?.productCode} |{" "}
                                {this.state.TicketProductDetails?.productName}{" "}
                              </p>
                              <p>
                                <b>Model & Brand:</b>{" "}
                                {this.state.TicketProductDetails?.model} ,{" "}
                                {this.state.TicketProductDetails?.brand}{" "}
                              </p>
                              <p>
                                <b>Created At : </b>
                                {epoch2ddmmyyyyhhmmss(
                                  this.state.TicketPrimarytDetails.createdAt
                                )}
                              </p>
                              <p>
                                <b>Appointment At:</b>{" "}
                                {epoch2ddmmyyyyhhmmss(
                                  this.state.curBlockDetails
                                    .feAppointmentDateTime
                                )}
                              </p>
                              <p>
                                <b>Linehaul At:</b>{" "}
                                {this.state.TicketProductDetails?.linehaulAwb} |{" "}
                                {epoch2ddmmyyyyhhmmss(
                                  this.state.TicketProductDetails
                                    ?.linehaulDispatchedAt
                                )}
                              </p>
                            </div>
                          </div>
                          <div
                            className="card "
                            style={{ marginBottom: "1rem" }}
                          >
                            <div className="card-body ">
                              <h1
                                style={{
                                  // backgroundColor: "#e2cdcd",
                                  textAlign: "center",
                                  marginTop: "-20px",
                                  marginLeft: "-20px",
                                  marginRight: "-20px",
                                  padding: "5px",
                                  marginBottom: "1rem",
                                }}
                              >
                                PickUp Location
                              </h1>
                              <p>
                                <b>Consumer Name :</b>{" "}
                                {
                                  this.state.TicketProductDetails
                                    ?.srcContactPerson
                                }
                              </p>
                              <p>
                                <b>Address :</b>{" "}
                                {this.state.TicketProductDetails?.srcAdd1},
                                {this.state.TicketProductDetails?.srcLandmark},
                                {this.state.TicketProductDetails?.srcCity}-
                                {this.state.TicketProductDetails?.srcState}-
                                {this.state.TicketProductDetails?.srcPincode}
                              </p>
                              <p>
                                <b>Email :</b>{" "}
                                {this.state.TicketProductDetails?.srcEmailId}
                              </p>
                              {/* <p>
                                <b>Phone :</b>{" "}
                                {this.state.TicketProductDetails.srcContact1},
                                {this.state.TicketProductDetails.srcContact2}
                              </p> */}
                              <p>
                                <b>FE Info :</b>{" "}
                                {this.state.TicketProductDetails?.pickUpFeName}{" "}
                                |{" "}
                                {epoch2ddmmyyyyhhmmss(
                                  this.state.TicketProductDetails?.pickUpTime
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="card ">
                            <div className="card-body ">
                              <h1
                                style={{
                                  // backgroundColor: "#ddb2c8",
                                  textAlign: "center",
                                  marginTop: "-20px",
                                  marginLeft: "-20px",
                                  marginRight: "-20px",
                                  padding: "5px",
                                  marginBottom: "1rem",
                                }}
                              >
                                Drop Location
                              </h1>
                              <p>
                                <b>Contact Person : </b>
                                {
                                  this.state.TicketProductDetails
                                    ?.dstContactPerson
                                }
                              </p>
                              <p>
                                <b>Address : </b>
                                {this.state.TicketProductDetails?.dstAdd1},
                                {this.state.TicketProductDetails?.dstLandmark},
                                {this.state.TicketProductDetails?.dstLocation},
                                {this.state.TicketProductDetails?.dstCity},
                                {this.state.TicketProductDetails?.dstState}-
                                {this.state.TicketProductDetails?.dstPincode}
                              </p>
                              {/* <p>
                                <b>Phone :</b>
                                {this.state.TicketProductDetails.dstContact1},
                                {this.state.TicketProductDetails.dstContact2}
                              </p> */}
                              <p>
                                <b>FE Info :</b>{" "}
                                {this.state.TicketProductDetails?.dropFeName} |{" "}
                                {epoch2ddmmyyyyhhmmss(
                                  this.state.TicketProductDetails?.dropTime
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {this.state.curTicketDetails.flowId ===
                        "AdvanceExchange" && (
                        <>
                          <div
                            className="card "
                            style={{ marginBottom: "1rem" }}
                          >
                            <div className="card-body ">
                              <p
                                style={{
                                  marginBottom: "1rem",
                                }}
                              >
                                <b>Status :</b>{" "}
                                <span className="text-success">
                                  <b>
                                    {this.replaceString(
                                      this.state.curBlockDetails.blockStatus
                                    )}
                                  </b>
                                </span>
                              </p>
                              <p>
                                <b>Product Category:</b>{" "}
                                {this.state.TicketProductDetails1?.productCode}{" "}
                                |{" "}
                                {this.state.TicketProductDetails1?.productName}{" "}
                              </p>
                              <p>
                                <b>Model & Brand:</b>{" "}
                                {this.state.TicketProductDetails1?.model} ,{" "}
                                {this.state.TicketProductDetails1?.brand}{" "}
                              </p>
                              <p>
                                <b>Created At : </b>
                                {epoch2ddmmyyyyhhmmss(
                                  this.state.TicketPrimarytDetails.createdAt
                                )}
                              </p>
                              <p>
                                <b>Appointment At:</b>{" "}
                                {epoch2ddmmyyyyhhmmss(
                                  this.state.curBlockDetails
                                    .feAppointmentDateTime
                                )}
                              </p>
                              <p>
                                <b>Linehaul At:</b>{" "}
                                {this.state.TicketProductDetails1?.linehaulAwb}{" "}
                                |{" "}
                                {epoch2ddmmyyyyhhmmss(
                                  this.state.TicketProductDetails1
                                    ?.linehaulDispatchedAt
                                )}
                              </p>
                            </div>
                          </div>
                          <div
                            className="card "
                            style={{ marginBottom: "1rem" }}
                          >
                            <div className="card-body ">
                              <h1
                                style={{
                                  // backgroundColor: "#e2cdcd",
                                  textAlign: "center",
                                  marginTop: "-20px",
                                  marginLeft: "-20px",
                                  marginRight: "-20px",
                                  padding: "5px",
                                  marginBottom: "1rem",
                                }}
                              >
                                PickUp Location
                              </h1>
                              <p>
                                <b>Consumer Name :</b>{" "}
                                {
                                  this.state.TicketProductDetails1
                                    ?.srcContactPerson
                                }
                              </p>
                              <p>
                                <b>Address :</b>{" "}
                                {this.state.TicketProductDetails1?.srcAdd1},
                                {this.state.TicketProductDetails1?.srcLandmark},
                                {this.state.TicketProductDetails1?.srcCity}-
                                {this.state.TicketProductDetails1?.srcState}-
                                {this.state.TicketProductDetails1?.srcPincode}
                              </p>
                              <p>
                                <b>Email :</b>{" "}
                                {this.state.TicketProductDetails1?.srcEmailId}
                              </p>
                              <p>
                                <b>Phone :</b>{" "}
                                {this.state.TicketProductDetails1?.srcContact1},
                                {this.state.TicketProductDetails1?.srcContact2}
                              </p>
                              <p>
                                <b>FE Info :</b>{" "}
                                {this.state.TicketProductDetails1?.pickUpFeName}{" "}
                                |{" "}
                                {epoch2ddmmyyyyhhmmss(
                                  this.state.TicketProductDetails1?.pickUpTime
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="card ">
                            <div className="card-body ">
                              <h1
                                style={{
                                  // backgroundColor: "#ddb2c8",
                                  textAlign: "center",
                                  marginTop: "-20px",
                                  marginLeft: "-20px",
                                  marginRight: "-20px",
                                  padding: "5px",
                                  marginBottom: "1rem",
                                }}
                              >
                                Drop Location
                              </h1>
                              <p>
                                <b>Contact Person : </b>
                                {
                                  this.state.TicketProductDetails1
                                    ?.dstContactPerson
                                }
                              </p>
                              <p>
                                <b>Address : </b>
                                {this.state.TicketProductDetails1?.dstAdd1},
                                {this.state.TicketProductDetails1?.dstLandmark},
                                {this.state.TicketProductDetails1?.dstLocation},
                                {this.state.TicketProductDetails1?.dstCity},
                                {this.state.TicketProductDetails1?.dstState}-
                                {this.state.TicketProductDetails1?.dstPincode}
                              </p>
                              <p>
                                <b>Phone :</b>
                                {this.state.TicketProductDetails1?.dstContact1},
                                {this.state.TicketProductDetails1?.dstContact2}
                              </p>
                              <p>
                                <b>FE Info :</b>{" "}
                                {this.state.TicketProductDetails1?.dropFeName} |{" "}
                                {epoch2ddmmyyyyhhmmss(
                                  this.state.TicketProductDetails1?.dropTime
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div
                      id="cocardHistory"
                      className="col-lg-7"
                      style={{ marginTop: "-16px" }}
                    >
                      <div className="card" style={{ width: "101%" }}>
                        <div className="p-2 bg-light bg-opacity-10">
                          <div className="row">
                            <div className="col-md-12">
                              <ul className="cbp_tmtimeline">
                                {this.state.curMisReportHistory && (
                                  <>
                                    {this.state.curMisReportHistory.map((t) => {
                                      return (
                                        <>
                                          {t.blockStatus && (
                                            <li>
                                              {t.processedAt && (
                                                <time
                                                  className="cbp_tmtime"
                                                  dateTime="2022-04-04T03:45"
                                                >
                                                  <span
                                                    style={{ fontSize: "10px" }}
                                                  >
                                                    {IstToUTC(t.processedAt)}
                                                  </span>{" "}
                                                </time>
                                              )}
                                              {!t.processedAt && (
                                                <time
                                                  className="cbp_tmtime"
                                                  dateTime="2022-04-04T03:45"
                                                >
                                                  <span>{"--"}</span>{" "}
                                                </time>
                                              )}

                                              <div className="cbp_tmicon bg-info">
                                                <i className="zmdi zmdi-label"></i>
                                              </div>
                                              {t.blockStatus !== "null" && (
                                                <div className="cbp_tmlabel ">
                                                  <h2>
                                                    <a
                                                      style={{
                                                        color: "#75bb06",
                                                      }}
                                                    >
                                                      <b
                                                        style={{
                                                          fontSize: "11px",
                                                        }}
                                                      >
                                                        {this.replaceString(
                                                          t.blockStatus
                                                        )}
                                                      </b>{" "}
                                                    </a>
                                                  </h2>
                                                  <time
                                                    className="cbp_tmtime "
                                                    dateTime="2022-04-04T03:45"
                                                  >
                                                    <span
                                                      className="pull-right"
                                                      style={{
                                                        marginRight: "-330px",
                                                        color: "#c6bcbc",
                                                        fontSize: "10px",
                                                        marginTop: "-44px",
                                                      }}
                                                    >
                                                      {IstToUTC(t.createdAt)}
                                                    </span>{" "}
                                                    <span
                                                      className="pull-right"
                                                      style={{
                                                        marginRight: "-330px",
                                                        color: "#c6bcbc",
                                                        fontSize: "10px",
                                                        marginTop: "-10px",
                                                      }}
                                                    >
                                                      {t.firstName} {t.lastName}{" "}
                                                      | {t.userName} | {t.phone}
                                                    </span>{" "}
                                                  </time>
                                                </div>
                                              )}
                                            </li>
                                          )}
                                        </>
                                      );
                                    })}
                                  </>
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className="row">
                            {this.state.TicketRemarks.length > 0 && (
                              <div className="col-md-12">
                                <div className="card ">
                                  <div className="card-body bg-custom-6">
                                    <h1
                                      style={{
                                        backgroundColor: "#ced4da",
                                        textAlign: "center",
                                        marginTop: "-20px",
                                        marginLeft: "-20px",
                                        marginRight: "-20px",
                                        padding: "5px",
                                      }}
                                    >
                                      Remarks
                                    </h1>
                                    {this.state.TicketRemarks.map((t) => {
                                      return (
                                        <>
                                          <p>
                                            {">> " + t.remark} |{" "}
                                            {epoch2ddmmyyyyhhmmss(t.createdAt)}
                                          </p>
                                        </>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="myModalProduct"
          role="dialog"
          aria-labelledby="myModalProduct"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Select Hub
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  {this.rRBL("", "hub", this.state.hubList, this.stsConf)}
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* {this.state.mode === "LineHaul" && (
          <LineHaulWrapper
            data-target="#myModals"
            data-backdrop="false"
            data-toggle="modal"
          />
        )} */}
        {true && (
          <LineHaulWrapper
            data-target="#myModals"
            data-backdrop="false"
            data-toggle="modal"
          />
        )}
      </React.Fragment>
    );
  }
}

export default TicketsList;
