import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiStaticReport;
function api(path, subURL = "/ticketProcess") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getSMSByTicketBlockId(jobId) {
  const path = `OTPReports/${jobId}`;
  return _http.get(api(path), _http.headerJWT());
}
