import React from "react";
import FormLib from "../../_common/FormLib";
import swal from "sweetalert";
import Joi from "joi-browser";

import Table from "../../_common/Table";
import Pagination from "../../_common/Pagination";
import $ from "jquery";
import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";

import {
  getActiveClients,
  getActiveCategoryList,
  getSubCategoryList,
  getUOM,
  createInvItems,
  // createInvItems1,
  // getItemsList,
  deleteItems,
  // getSearchItems,
  updateItemsData,
  getItemsListV1,
  getSearchItemsV1,
  isDefaultStatus,
  getSearchItems,
} from "../../../services/masterLocations/clientService";
// import FilterScreenOfItemMaster from "./barcode/FilterScreenOfItemMaster";

class ItemMaster extends FormLib {
  state = {
    data: {
      item: "",
      itemCode: "",
      itemSkuBarcode: "",
      // productValue:0,
      inwardUomId: "",
      teamId: "",
      catId1: "",
      subCatId: "",
      outwardUomId: "",
      status: "active",
      isSerial: "yes",
      itemType: "",
      wpmL: 0,
      wpmB: 0,
      wpmH: 0,
      wpmActualWeight: 0,
      wpmVolumetricWeight: 0,
      pmL: "",
      pmB: "",
      pmH: "",
      pmActualWeight: "",
      pmVolumetricWeight: "",
      isDefault: "no",
    },
    itemMaster: [],
    clients: [],
    subCat: [],
    cat: [],
    itemsLists: [],
    itemsLists1: [],
    errors: {},
    itemCategory: [],
    items: [],
    itemsSub: [],
    itemsSubAll: [],
    curItemCategory: { catId1: "" },
    curItemSubCategory: { subCatId: "" },
    // showWithoutpackingMaterial: false,
    // showWithpackingMaterial: false,

    formMode: "create",
    // hubs: [],
    filterData: [],
    loading: true,
    makeDefault: [],
    searchClicked: false,

    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };
  componentDidMount() {
    this.loadClients();
    this.loadCategories();
    // this.loadSubCategories();
    this.loadUOM();
    this.loadItemsList(this.state.pageLinks.pageNo);
    // this.searchItems();
  }
  schema = {
    itemId: Joi.string().optional().label("itemId"),
    teamId: Joi.string().required().label("clientId"),
    catId1: Joi.string().required().label("catId"),
    subCatId: Joi.string().required().label("Sub Category Id"),
    item: Joi.string()
      .regex(/^[a-zA-Z0-9][\sa-zA-Z]*/)
      .required()
      .label("Item Name"),
    itemCode: Joi.string()
      .regex(/^[a-zA-Z0-9][\sa-zA-Z]*/)
      .required()
      .label("Item Code"),
    itemSkuBarcode: Joi.string().optional().allow('').allow(null).regex(/^[a-zA-Z0-9][\sa-zA-Z]*/).label("ItemSkuBarcode"),
    // productValue:Joi.number().required().min(1).label("productValue"),
    inwardUomId: Joi.string().required().label("inwardUomId"),
    outwardUomId: Joi.string().required().label("outwardUomId"),
    status: Joi.string().required().label("Status"),
    isSerial: Joi.string().required().label("Serial"),
    isDefault: Joi.string().required().label("Default"),
    itemType: Joi.string().required().label("itemType"),

    wpmL: Joi.number().optional().min(0).label("wpLength").allow(null),

    wpmB: Joi.number().optional().min(0).label("wpBreadth").allow(null),
    wpmH: Joi.number().optional().min(0).label("wpHeight").allow(null),
    wpmActualWeight: Joi.number()
      .optional()
      .min(0)
      .label("wpActualWeight")
      .allow(null),
    wpmVolumetricWeight: Joi.number()
      .optional()
      .min(0)

      .label("wpVolWeight")
      .allow(null),
    // field2: Joi.number().required()
    // .when('field1', {
    //   is: Joi.number().greater(Joi.ref('field1')),
    //   then: Joi.number().required().min(Joi.ref('field1'))
    // })

    pmL: Joi.number().optional().label("pmLength").allow(""),
    pmB: Joi.number().optional().label("pmBreadth").allow(""),
    pmH: Joi.number().optional().label("pmHeight").allow(""),
    pmActualWeight: Joi.number()
      .optional()
      
      .label("pmActualWeight")
      .allow(""),
    pmVolumetricWeight: Joi.number()
      .optional()
      
      .label("pmVolWeight")
      .allow(""),
  };

  tableMeta = {
    key: "itemId",
    columns: [
      {
        key: "itemId1",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                // disabled={this.state.data.itemCode}
                onClick={() => this.handleOnItem(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>

              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(r)}
              >
                <i className="fa fa-trash" aria-hidden="true" /> Delete
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true" />,
      },
      { path: "itemCode", label: "Code" },
      { path: "isSerial", label: "BarcodeAvailable" },
      { path: "itemType", label: "ItemType" },
      { path: "item", label: "Item" },
      { path: "itemSkuBarcode", label: "SKU" },
      { path: "status", label: "Status" },

      { path: "inwardUomId", label: "InwardUOM" },
      { path: "outwardUomId", label: "OutwardUOM" },
      { path: "isDefault", label: "Default" },
      {
        key: "itemId2",
        action: (r) => (
          <div>
            {this.state.data.teamId !== "" &&
              this.state.data.catId1 !== "" &&
              this.state.data.subCatId !== "" &&
              this.state.searchClicked &&
              (
                <div>
                  <button
                    className="btn btn-sm btn-outline-info"
                    type="button"
                    onClick={() => this.handleMakeDefault(r)}
                  >
                    Make Default
                  </button>
                </div>
              )}
          </div>
        ),
      },
    ],
  };

  async searchItems() {
    // console.log({ gdhjgdefg: pageNo });
    this.setState({ loading: true });
    // console.log({ pageNo });
    const result = await getSearchItems(this.state.data);
    this.setState({ searchClicked: true });
    // console.log({ result });
    // this.resetData();
    if (result.data.success && result.data.data.filteredData.length !== 0) {
      this.setState({ itemsLists1: result.data.data.filteredData, itemsLists: [] });
      // this.setState({ pageLinks: result.data.data.pageLinks });
    } else {
      toast.toastInfo("No data found!!!");
      this.setState({ itemsLists1: [] });
      this.setState({
        pageLinks: {
          pageNo: 1,
          perPage: 0,
          itemsCount: 0,
          lastPage: 0,
        },
      });
    }
    this.setState({ loading: false });
  }

  handleOnItem(r) {
    this.resetData();
    // console.log({ r });
    let itemMaster = {};
    itemMaster.itemId = r.itemId;
    itemMaster.itemCode = r.itemCode;
    itemMaster.itemSkuBarcode = r.itemSkuBarcode;
    // itemMaster.productValue = r.productValue;
    itemMaster.isSerial = r.isSerial;
    itemMaster.isDefault = r.isDefault;
    itemMaster.itemType = r.itemType;
    itemMaster.item = r.item;
    itemMaster.status = r.status;
    itemMaster.inwardUomId = r.inwardUomId;
    itemMaster.catId1 = r.catId;
    itemMaster.subCatId = r.subCatId;
    itemMaster.teamId = r.teamId;
    itemMaster.outwardUomId = r.outwardUomId;
    itemMaster.wpmL = r.wpmL;
    itemMaster.wpmB = r.wpmB;
    itemMaster.wpmH = r.wpmH;
    itemMaster.wpmActualWeight = r.wpmActualWeight;
    itemMaster.wpmVolumetricWeight = r.wpmVolumetricWeight;
    itemMaster.pmL = r.pmL;
    itemMaster.pmB = r.pmB;
    itemMaster.pmH = r.pmH;
    itemMaster.pmActualWeight = r.pmActualWeight;
    itemMaster.pmVolumetricWeight = r.pmVolumetricWeight;
    this.loadSubCategories(r.catId);
    this.setState({ data: itemMaster, formMode: "edit" });
    $("#itemCode").attr("disabled", "disabled");
    // $("#itemSkuBarcode").attr("disabled", "disabled");
  }

  resetEdit() {
    this.setState({
      data: {
        teamId: "",
        item: "",
        itemCode: "",
        itemSkuBarcode: "",
        // productValue:0,
        catId1: "",
        subCatId: "",
        inwardUomId: "",
        outwardUomId: "",
        wpmL: 0,
        wpmB: 0,
        wpmH: 0,
        wpmActualWeight: 0,
        wpmVolumetricWeight: 0,
        pmL: "",
        pmB: "",
        pmH: "",
        pmActualWeight: "",
        pmVolumetricWeight: "",
        status: "open",
        isSerial: "yes",
        itemType: "",
        isDefault: "no",
      },
    });
  }

  async loadClients() {
    this.setState({ loading: true });
    const res = await getActiveClients("active");

    if (res.data.success === true) {
      this.setState({ clients: res.data.data.clients });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  async loadItemsList(pageNo) {
    this.setState({ itemsLists1: [] })
    this.setState({searchClicked:false})
    // console.log({ ttt: pageNo });
    // console.log({ ppppp: this.state.pageLinks.pageNo });
    this.setState({ loading: true });
    const res = await getItemsListV1(pageNo);
    // console.log({ res });
    if (res.data.success === true) {
      this.setState({ itemsLists: res.data.data.itemsList });
      this.setState({ pageLinks: res.data.data.pageLinks });
      this.resetData();
    } else {
      toast.toastError(res.data.msg);
      this.setState({ itemsLists: [] });
      this.setState({
        pageLinks: {
          pageNo: 1,
          perPage: 0,
          itemsCount: 0,
          lastPage: 0,
        },
      });
    }
    this.setState({ loading: false });
  }

  async loadCategories() {
    this.setState({ loading: true });
    const res = await getActiveCategoryList("active");

    if (res.data.success === true) {
      this.setState({ cat: res.data.data.cat });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  async loadSubCategories(catId1) {
    // this.setState({ loading: true });
    const res = await getSubCategoryList(catId1);
    if (res.data.success === true) {
      if (res.data.data.length !== 0) {
        this.setState({ subCat: res.data.data.subCat });
        toast.toastSuccess(res.data.msg);
      } else {
        toast.toastInfo("No Items found for this category");
        this.setState({ itemsSub: [] });
      }
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/stockManagement/" + pageNo);
    this.loadItemsList(pageNo);
  };
  // handlePageChange1 = (pageNo) => {
  //   this.props.history.push("/stockManagement/" + pageNo);
  //   this.searchItems(pageNo);
  // };

  loadCurrectCategory = async (catId1, modReset = true) => {
    if (modReset === true) {
      this.setState({ itemsSubAll: [] });
      this.setState({ curItemCategory: { catId1: "" } });
    }
    let curItemCat;
    const filter = this.state.cat.filter((r) => {
      return r.catId1 === catId1 ? r : null;
    });

    if (!filter[0]) {
      curItemCat = { catId1: "" };
    } else {
      curItemCat = filter[0];
    }

    this.setState({ curItemCategory: curItemCat });

    if (catId1 !== "") {
      this.loadSubCategories(catId1);
    } else {
      this.setState({ itemsSub: [] });
    }
  };

  handleCategoryChange = (e) => {
    this.loadCurrectCategory(e.target.value);
    this.state.curItemSubCategory.subCatId = "";
    let data = this.state.data;
    data.catId1 = e.target.value;
    data.subCatId = "";
    this.setState({ data });
  };

  localHandleChange = (e) => {
    if (e.target.id === "catId1") {
      this.handleCategoryChange(e);
    }
    if (e.target.id === "subCatId") {
      this.handleSubCategoryChange(e);
    }

    if (e.target.name === "wpmL") {
      // alert("wpml");
      const length = Math.abs(+e.target.value);
      const breadth = Math.abs(+this.state.data.wpmB);
      const height = Math.abs(+this.state.data.wpmH);

      let volWt = "";
      if (length && breadth && height) {
        volWt = (length * breadth * height) / 5;
      }
      this.setState({
        data: {
          ...this.state.data,
          wpmL: e.target.value,
          wpmVolumetricWeight: volWt,
        },
      });
    } else if (e.target.name === "wpmB") {
      const length = Math.abs(+this.state.data.wpmL);
      const breadth = Math.abs(+e.target.value);
      const height = Math.abs(+this.state.data.wpmH);

      let volWt = "";
      if (length && breadth && height) {
        volWt = (length * breadth * height) / 5;
      }
      this.setState({
        data: {
          ...this.state.data,
          wpmB: e.target.value,
          wpmVolumetricWeight: volWt,
        },
      });
    } else if (e.target.name === "wpmH") {
      const length = Math.abs(+this.state.data.wpmL);
      const height = Math.abs(+e.target.value);
      const breadth = Math.abs(+this.state.data.wpmB);

      let volWt = "";
      if (length && breadth && height) {
        volWt = (length * breadth * height) / 5;
      }
      this.setState({
        data: {
          ...this.state.data,
          wpmH: e.target.value,
          wpmVolumetricWeight: volWt,
        },
      });
    } else if (e.target.name === "wpmActualWeight") {
      this.setState({
        data: {
          ...this.state.data,
          wpmActualWeight: e.target.value,
        },
      });
    }

    if (e.target.name === "pmL") {
      // alert("pml");
      const length = Math.abs(+e.target.value);
      const breadth = Math.abs(+this.state.data.pmB);
      const height = Math.abs(+this.state.data.pmH);

      let volWt = "";
      if (length && breadth && height) {
        volWt = (length * breadth * height) / 5;
        volWt = volWt.toString();
      }
      this.setState({
        data: {
          ...this.state.data,
          pmL: e.target.value,
          pmVolumetricWeight: volWt,
        },
      });
    } else if (e.target.name === "pmB") {
      const length = Math.abs(+this.state.data.pmL);
      const breadth = Math.abs(+e.target.value);
      const height = Math.abs(+this.state.data.pmH);

      let volWt = "";
      if (length && breadth && height) {
        volWt = (length * breadth * height) / 5;
        volWt = volWt.toString();
      }
      this.setState({
        data: {
          ...this.state.data,
          pmB: e.target.value,
          pmVolumetricWeight: volWt,
        },
      });
    } else if (e.target.name === "pmH") {
      const length = Math.abs(+this.state.data.pmL);
      const height = Math.abs(+e.target.value);
      const breadth = Math.abs(+this.state.data.pmB);

      let volWt = "";
      if (length && breadth && height) {
        volWt = (length * breadth * height) / 5;
        volWt = volWt.toString();
      }
      this.setState({
        data: {
          ...this.state.data,
          pmH: e.target.value,
          pmVolumetricWeight: volWt,
        },
      });
    } else if (e.target.name === "pmActualWeight") {
      this.setState({
        data: {
          ...this.state.data,
          pmActualWeight: e.target.value,
        },
      });
    }
    if (e.target.name === "") {
      return "fields are mandatory";
    }
  };

  handleSubCategoryChange = (e) => {
    this.loadCurrectSubCategory(e.target.value);
  };

  async loadUOM() {
    this.setState({ loading: true });
    const res = await getUOM("active");
    // console.log({ res });
    if (res.data.success === true) {
      this.setState({ UOMList: res.data.data.uomList });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      this.setState({
        pageLinks: {
          pageNo: 1,
          perPage: 0,
          itemsCount: 0,
          lastPage: 0,
        },
      })
      res = await createInvItems(this.state.data);
      if (res.data.success === true) {
        toast.toastSuccess(res.data.msg);
        this.loadItemsList(this.state.pageLinks.pageNo);
        this.resetData();
      } else {
        toast.toastError(res.data.msg);
        // alert("kindly check LBH fields");
      }

      // this.setState({ itemMaster: res.data.data.itemList });
    }
    if (this.state.btnValue === "edit") {
      res = await updateItemsData(this.state.data);
      // console.log({ res });
      // this.resetData();

      if (res.data.success === true) {
        toast.toastSuccess(res.data.msg);
        // this.setState({loading:true})
        this.loadItemsList(this.state.pageLinks.pageNo);
        // this.setState({loading:false})
      } else {
        toast.toastError("Enter Valid Details/Check For Duplicates");
      }
    }
  }

  doSubmitDelete = async (handleDelete, r) => {
    // this.setState({ loading: true });

    if (handleDelete) {
      const res = await deleteItems(r.itemId);
      this.loadItemsList(this.state.pageLinks.pageNo);
      if (res.data.success === true) {
        toast.toastSuccess(res.data.msg);
        swal("Item Data has been deleted!", {
          icon: "success",
        });
      } else {
        toast.toastError(res.data.msg);
        // swal("Item Data is safe!");
      }
    } else {
      swal("Item Data is safe!");
    }
  };

  handleDelete = async (r) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, r);
    });
  };

  handleMakeDefault = async (r) => {
    let params = {
      subCatId: this.state.data.subCatId,
      teamId: this.state.data.teamId,
      itemId: r.itemId,
    };

    const resp = await isDefaultStatus(params);
    // console.log({ resp });

    if (resp.data.success === true) {
      toast.toastSuccess("Data Updated Successfully");
      this.searchItems(this.state.pageLinks.pageNo);
    } else {
      // toast.toastError(resp.data.msg);
    }
    // this.setState({ loading: true });
  };

  resetData() {
    this.setState({
      data: {
        teamId: "",
        item: "",
        itemCode: "",
        itemSkuBarcode: "",
        // productValue:0,
        catId1: "",
        subCatId: "",
        inwardUomId: "",
        outwardUomId: "",
        wpmL: 0,
        wpmB: 0,
        wpmH: 0,
        wpmActualWeight: 0,
        wpmVolumetricWeight: 0,
        pmL: "",
        pmB: "",
        pmH: "",
        pmActualWeight: "",
        pmVolumetricWeight: "",
        status: "active",
        isSerial: "yes",
        itemType: "",
        isDefault: "no",
      },
      formMode: "create",
    });
    $("#itemCode").removeAttr("disabled");
    // $("#itemSkuBarcode").removeAttr("disabled");
  }

  typConf = {
    value: "sortKey",
    label: "sort",
  };
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inActive", sort: "InActive" },
  ];
  // isSerial = [
  //   { sortKey: "yes", sort: "Yes" },
  //   { sortKey: "no", sort: "No" },
  //   { sortKey: "both", sort: "Both" },
  // ];
  isDefault = [{ sortKey: "no", sort: "No" }];
  itemType = [
    { sortKey: "spares", sort: "Spares" },
    { sortKey: "returnSpares", sort: "Return Spares" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    // console.log({ ggg: this.state.itemsLists });

    // console.log({ state: this.state.clients });
    // console.log({ blah: this.state.hubs });
    // console.log({ sxgrfdyd: this.state.itemsLists });

    return (
      <React.Fragment>
        <LocationTitle subHeading="Inventory Item Master" />
        <div className="pageContent">
          <div className="row">
            {!this.state.loading && (
              <>
                {/* <FilterScreenOfItemMaster/> */}

                <div className="col-3 ml-3">
                  <div className="form-row multiselect">
                    {this.rSB(
                      "ClientId *",
                      "teamId",
                      this.state.clients,
                      { value: "TEAM_ID", label: "NAME" },
                      " col-md-3"
                    )}

                    {this.rSB(
                      "Item Category *",
                      "catId1",
                      this.state.cat,
                      { value: "catId", label: "cat" },
                      " col-md-4"
                    )}

                    {this.rSB(
                      "ItemSubCategory *",
                      "subCatId",
                      this.state.subCat,
                      { value: "subCatId", label: "subCat" },
                      " col-md-5"
                    )}
                  </div>
                </div>
                <div className="menu col-2">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-info pull-left"
                    title=" Search by client, cat and subCat"
                    disabled={
                      (this.state.data.teamId ||
                        (this.state.data.catId1 &&
                          this.state.data.subCatId)) === ""
                    }
                    onClick={() => this.searchItems()}
                  >
                    <i className={"fa fa-search"} />
                  </button>

                  <button
                    type="button"
                    className="btn btn-sm btn-outline-info pull-left"
                    onClick={() =>
                      this.loadItemsList(this.state.pageLinks.pageNo)
                    }
                  >
                    <i className={"fa fa-refresh"} />
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-8">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Items</div>
                </div>
              )}
              {!this.state.loading && (
                <div>
                  {this.state.itemsLists !== 0 && this.state.itemsLists1.length === 0 && (
                    <div>
                      <div
                        className="container-fluid fixTableHead"
                        style={{ height: "35rem", overflow: "scroll" }}
                      >
                        <Table
                          clsName="table table-striped tabele-bordered table-hover"
                          tableMeta={this.tableMeta}
                          data={this.state.itemsLists}
                          dropMenu={[]}
                        />
                      </div>

                      <div>
                        <Pagination
                          pageNo={pageNo}
                          perPage={perPage}
                          itemsCount={itemsCount}
                          onPageChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  )}

                  {this.state.itemsLists1 !== 0 && this.state.itemsLists.length === 0 && (
                    <div>
                      <div
                        className="container-fluid fixTableHead"
                        style={{ height: "35rem", overflow: "scroll" }}
                      >
                        <Table
                          clsName="table table-striped tabele-bordered table-hover"
                          tableMeta={this.tableMeta}
                          data={this.state.itemsLists1}
                          dropMenu={[]}
                        />
                      </div>

                      {/* <div>
          <Pagination
            pageNo={pageNo}
            perPage={perPage}
            itemsCount={itemsCount}
            onPageChange={this.handlePageChange}
          />
        </div> */}
                    </div>
                  )}
                </div>
              )}

            </div>
            <div className="col-md-4 col-sm-12">
              {/* {this.state.formMode === "view" && (
                <div className="card">
                  <div className="card-body">
                    <>
                      <h6>Item:{this.state.itemName["item"]}</h6>
                      <h6>ItemCode:{this.state.itemName["itemCode"].trim()}</h6>
                      <h6>IsSerial:{this.state.itemName["isSerial"].trim()}</h6>
                      <br />
                      <div className="pull-right">
                        <span
                          className={"badge   badge-light pull-left ml-1 "}
                        ></span>
                        <span
                          className={"badge   badge-success pull-left  mr-1 "}
                        >
                          <h6>{this.state.itemName["status"]}</h6>
                        </span>
                      </div>
                    </>
                  </div>
                </div>
              )} */}
              {this.state.formMode !== "view" && (
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="form-row">
                        {this.rSB(
                          "ClientId *",
                          "teamId",
                          this.state.clients,
                          { value: "TEAM_ID", label: "NAME" },
                          " col-md-12"
                        )}
                        {this.rSB(
                          "Item Category *",
                          "catId1",
                          this.state.cat,
                          { value: "catId", label: "cat" },
                          " col-md-6"
                        )}

                        {this.rSB(
                          "ItemSubCategory *",
                          "subCatId",
                          this.state.subCat,
                          { value: "subCatId", label: "subCat" },
                          " col-md-6"
                        )}
                      </div>
                      {/* </div> */}
                      <div className="form-row mt-2">
                        {this.rTB("Item Name *", "item", "col-4")}
                        {this.rTB("Item Code *", "itemCode", "col-4")}
                        {this.rTB("Item Sku Barcode", "itemSkuBarcode", "col-4")}
                        {/* {this.rNB("Product Value *", "productValue", "col-6")} */}
                      </div>
                      <div className="card">
                        <h5
                          className="card-subtitle mb-4 text-center mx-0 rounded px-0 mt-0 py-1 shadow-sm"
                          id="boxDetailsHeading"
                          onClick={this.handleClick1}
                        >
                          Without Packing Material <span style={{color:"red"}}>*</span>
                        </h5>
                        {/* {this.state.showWithoutpackingMaterial === true && ( */}
                        <div>
                          <div className="d-flex">
                            {this.rNB("Length (cm) *", "pmL", " col-4")}
                            {this.rNB("Breadth (cm) *", "pmB", " col-4")}
                            {this.rNB("Height (cm) *", "pmH", " col-4")}
                          </div>
                          <div className="d-flex">
                            {this.rNB(
                              "ActualWeight (kg) *",
                              "pmActualWeight",
                              " col-4"
                            )}
                            {/* {this.rNB(
                                "VolWeight (cm3) *",
                                "pmVolumetricWeight",
                                " col-4"
                              )} */}
                            <p style={{ paddingTop: "1rem" }}>
                              volumetric weight (grams):{" "}
                              {this.state.data.pmVolumetricWeight}
                            </p>
                          </div>
                        </div>
                        {/* )} */}
                      </div>
                      <div className="card">
                        <h5
                          className="card-subtitle mb-4 text-center mx-0 rounded px-0 mt-0 py-1 shadow-sm"
                          id="boxDetailsHeading"
                          onClick={this.handleClick}
                        >
                          With Packing Material
                        </h5>

                        {/* {this.state.showWithpackingMaterial === true && ( */}
                        <div>
                          <div className="d-flex">
                            {/* {this.rTB("Length", "wpmL", "col-4")} */}
                            {this.rNB("Length (cm)", "wpmL", " col-4")}
                            {this.rNB("Breadth (cm)", "wpmB", " col-4")}
                            {this.rNB("Height (cm)", "wpmH", " col-4")}
                          </div>

                          <div className="d-flex">
                            {this.rNB(
                              "ActualWeight (kg)",
                              "wpmActualWeight",
                              " col-4"
                            )}
                            {/* {this.rNB(
                                "VolWeight (cm3) *",
                                "wpmVolumetricWeight",
                                " col-4"
                              )} */}
                            <p style={{ paddingTop: "1rem" }}>
                              volumetric weight (grams):{" "}
                              {this.state.data.wpmVolumetricWeight}
                            </p>
                          </div>
                        </div>
                        {/* )} */}
                      </div>

                      <div className="card pt-3">
                        <div className="form-row mt-2">
                          {/* <div className="col-3">
                            {this.rRBL(
                              "isBarcode Available",
                              "isSerial",
                              this.isSerial,
                              this.stsConf
                            )}
                          </div> */}

                          <div className="col-4">
                            {this.rRBL(
                              "Status",
                              "status",
                              this.status,
                              this.stsConf
                            )}
                          </div>
                          <div className="col-5">
                            {this.rRBL(
                              "ItemType *",
                              "itemType",
                              this.itemType,
                              this.stsConf
                            )}
                          </div>
                        </div>

                        <div className="form-row">
                          {this.rSB(
                            "Inward UOM *",
                            "inwardUomId",
                            this.state.UOMList,
                            { value: "inwardUomId", label: "uom" },
                            " col-md-6"
                          )}

                          {this.rSB(
                            "Outward UOM *",
                            "outwardUomId",
                            this.state.UOMList,
                            { value: "inwardUomId", label: "uom" },
                            " col-md-6"
                          )}
                        </div>
                        <div>{this.state.formMode === "edit"}</div>
                      </div>
                    </form>

                    {this.state.formMode === "create" &&
                      this.rBtnSP("Create", "create")}

                    {this.state.formMode === "edit" && (
                      <>
                        {this.rBtnSS("Edit", "edit")}
                        {/* <button
                          type="button"
                          className="btn btn-sm btn-outline-info pull-right"
                          onClick={() => this.resetEdit()}
                        >
                          <i className={"fa fa-refresh"} />
                        </button> */}
                      </>
                    )}
                    <>
                      {this.state.formMode !== "edit" &&
                        this.mResetBtn(
                          () => this.resetData(),
                          " pull-right ",
                          "Reset"
                        )}
                    </>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ItemMaster;
