import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLMasterLoc;
function api(path, subURL = "/regions") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getRegions() {
  return _http.get(api(""), _http.headerJWT());
}

export function getActiveRegions() {
  return _http.get(api("active"), _http.headerJWT());
}

export function createRegions(req) {
  const param = { ...req };
  delete param.regionId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveRegions(req) {
  const param = { ...req };
  const id = param.regionId;
  //delete param.cityId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteRegions(req) {
  const param = { ...req };
  return _http.delete(api(param.regionId), _http.headerJWT());
}
