import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import swal from "sweetalert";

import Table from "../../_common/Table";
// import Pagination from "../../_common/Pagination";

import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";
import {
  getEmpBankAccs,
  createEmpBankAccs,
  saveEmpBankAccs,
  deleteEmpBankAccs,
} from "../../../services/players/empBankAccsServices";
import { getPlayers } from "./../../../services/players/playerServices";
class EmpBankAccs extends FormLib {
  state = {
    data: {
      empId: "",
      playerId: "",
      accType: "",
      accNo: "",
      name: "",
      ifsc: "",
      bank: "",
      branch: "",
    },
    errors: {},
    formMode: "create",
    empsBankAccs: [],
    players: [],
    loading: true,
    // pageLinks: {
    //   pageNo: 1,
    //   perPage: 0,
    //   itemsCount: 0,
    //   lastPage: 0,
    // },
  };
  schema = {
    empId: Joi.optional().label("Account Emp Id"),
    playerId: Joi.required().label("Player Id"),
    accType: Joi.string().required().label("Account type"),
    accNo: Joi.string().required().label("Account No"),
    name: Joi.string().regex(/^[a-zA-Z ]*$/).required().label("Name").error(() => {
      return {
        message: 'Allow only characters.',
      }
    }),
    // name: Joi.string().optional().label("Name"),
    ifsc: Joi.string().required().label("Ifsc"),
    bank: Joi.string().regex(/^[a-zA-Z ]*$/).required().label("Bank").error(() => {
      return {
        message: 'Allow only characters.',
      }
    }),
    // bank: Joi.required().label("Bank"),
    branch: Joi.string().required().label("Branch"),
  };
  componentDidMount() {
    this.loadEmpBankAccs();
    this.loadPlayers();
  }

  //   handlePageChange = (pageNo) => {
  //     this.props.history.push("/accounts/accounts" + pageNo);

  //     this.loadVendAccounts();
  //   };

  async loadEmpBankAccs() {
    this.setState({ loading: true });
    const res = await getEmpBankAccs();
    if (res.data.success === true) {
      // console.log(res.data.data.empsBankAccs);
      this.setState({ empsBankAccs: res.data.data.empsBankAccs });
      // console.log(this.state.empsBankAccs);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  async loadPlayers() {
    this.setState({ loading: true });
    const res = await getPlayers();
    if (res.data.success === true) {
      // console.log(res.data.data.players);
      this.setState({ players: res.data.data.players });
      // console.log(this.state.players);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  handleOnEmpsBankAccs(e) {
    let empsBankAccs = {};
    empsBankAccs.empId = e.empId;
    empsBankAccs.playerId = e.playerId;
    empsBankAccs.accType = e.accType;
    empsBankAccs.accNo = e.accNo;
    empsBankAccs.name = e.name;
    empsBankAccs.ifsc = e.ifsc;
    empsBankAccs.bank = e.bank;
    empsBankAccs.branch = e.branch;
    this.setState({ data: empsBankAccs, formMode: "edit" });
  }

  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createEmpBankAccs(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveEmpBankAccs(this.state.data);
      this.resetData();
    }

    if (res.data.status === 1) {
      toast.toastError(res.data.msg);
      this.loadEmpBankAccs();
    } else {
      toast.toastSuccess(res.data.msg);
      this.loadEmpBankAccs();
    }
  }

  doSubmitDelete = async (handleDelete, e) => {
    if (handleDelete) {
      await deleteEmpBankAccs(e);
      this.loadEmpBankAccs();

      swal("Emp Bank Account has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Emp Bank Account is safe!");
    }
  };

  handleDelete = async (e) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, e);
    });
  };

  handleView = (e) => {
    this.setState({ curEmpBankAccName: e, formMode: "view" });
  };

  resetData() {
    this.setState({
      data: {
        empId: "",
        playerId: "",
        accType: "",
        accNo: "",
        name: "",
        ifsc: "",
        bank: "",
        branch: "",
      },
      formMode: "create",
    });
  }

  tableMeta = {
    key: "empId",
    columns: [
      {
        key: "EMP_ID",
        action: (e) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnEmpsBankAccs(e)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleView(e)}
              >
                <i className="fa fa-binoculars" aria-hidden="true"></i> View
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(e)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i> Delete
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      // { path: "playerId", label: "Player" },
      { path: "name", label: "Player" },
      { path: "bank", label: "Bank" },
      { path: "branch", label: "Branch" },
      { path: "accType", label: "Account Type" },
      { path: "accNo", label: "Account No" },
      { path: "ifsc", label: "IFSC" },
    ],
  };
  actVal = [
    { sortKey: "SB Account", sort: "SB Account" },
    { sortKey: "Current", sort: "Current" },
  ];
  actConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Emp Bank Accs" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Accounts</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.empsBankAccs}
                    dropMenu={[]}
                  />
                  {/* <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  /> */}
                </div>
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              {this.state.formMode === "view" && (
                <div className="card">
                  <div className="card-body">
                    <>
                      {this.state.curEmpBankAccName["name"].trim()}
                      <br />
                      <div className="pull-right">
                        <span className={"badge   badge-light pull-left ml-1 "}>
                          {this.state.curEmpBankAccName["empId"]}
                        </span>
                        <span
                          className={"badge   badge-success pull-left  mr-1 "}
                        >
                          {this.state.curEmpBankAccName["status"]}
                        </span>
                      </div>
                    </>
                  </div>
                </div>
              )}
              {this.state.formMode !== "view" && (
                <div className="card">
                  <div className="card-body">
                    <form>
                      {this.rTBH("Emp Account", "empId", "col-3")}
                      {/* {this.rTB("Player Id", "PLAYER_ID", "col-3")} */}
                      {this.rSB("Player", "playerId", this.state.players, {
                        value: "playerId",
                        label: "firstname",
                      })}

                      {/* {this.rRBL("Account Type", "accType", this.actVal, {
                        value: "sortKey",
                        label: "sort",
                      })} */}



                      <div className="form-row">
                        {this.rTB("Name", "name", "col-6")}
                        {this.rTB("Account No", "accNo", "col-6")}
                      </div>
                      <div className="form-row">
                        {this.rTB("Bank", "bank", "col-4")}
                        {this.rTB("Branch", "branch", "col-4")}
                        {this.rTB("IFSC", "ifsc", "col-4")}
                      </div>

                      <div className="form-row">
                        {this.rRBL("Account Type", "accType", this.actVal, {
                          value: "sortKey",
                          label: "sort",
                        })}
                      </div>
                    </form>
                    {this.state.formMode === "create" &&
                      this.rBtnSP("Create", "create")}
                    {this.state.formMode === "edit" && (
                      <>
                        {this.rBtnSS("Edit", "edit")}
                        {this.mResetBtn(() => this.resetData(), " pull-right ")}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <span>{JSON.stringify(this.state.data)}</span> */}
        </div>
      </React.Fragment>
    );
  }
}
export default EmpBankAccs;
