import React from "react";
import $ from "jquery";
import FormLib from "../../../_common/FormLib";
import { IstToUTC, IstToUTCDate } from "../../../../utils/DateUtils";
import Joi from "joi-browser";
import toast from "./../../../../services/_toastService";
import { setTicketVehicleInfoUpdate } from "./../../../../services/Tickets/TicketProcessServices";
// import {
//   getDocuments,
//   getSaveFile,
// } from "../../../../../services/requestV1/requestServices";
// import { getLinehaulTicktes } from "../../../../../services/linehaul/linehaulServices";

class DocumentDetails extends FormLib {
  state = {
    data: {
      tripEnd: "",
      fileInfo: "",
      selectedFilesList: [],
    },
    errors: {},
    currentRequest: {},
    user: {},
    selectedFile: null,
    ticketDetails: [],
  };
  schema = {
    tripEnd: Joi.string()
      .regex(/^[0-9]+$/)
      .required()
      .label("tripEnd")
      .error(() => {
        return {
          message: "Allow only numbers",
        };
      }),
    selectedFilesList: Joi.optional().label("selectedFilesList"),
  };
  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  componentDidMount() {
    this.loadData(this.props.data);
  }

  loadData = async (r) => {
    try {
      this.setState({ currentRequest: this.props.data });
    } catch {
      console.log("DocumentsDetails>>>>>>>>>>>>loadData");
    }
  };
  selectedFilesLists = (e) => {
    let fileObj = [];
    let fileArray = [];
    fileObj.push(e);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(fileObj[0][i]);
    }
    this.setState({ file: fileArray });

    let data = this.state.data;
    data.selectedFilesList = e;
    this.setState({ data });
  };

  deleteFile(e) {
    const s = this.state.file.filter((item, index) => index !== e);
    this.setState({ file: s });
    let data = this.state.data;
    data.selectedFilesList = s;
    this.setState({ data });
  }
  async onVehicleInfo() {
    let curHub = localStorage.getItem("curHub");
    let date = IstToUTCDate(new Date());
    let now = new Date().valueOf(); // Unix timestamp in milliseconds
    let tripId = curHub + "-" + date + "-" + now;
    let override = { ...this.state.data };
    override["actionCode"] = "wUpdateVehicle";
    override["action"] = "Vehicle Details";
    this.setState({ override });

    let resultSet = {
      tripEnd: this.state.data.tripEnd,
      tripId: this.state.currentRequest.tripId,
    };
    const data = {
      selectedFilesList: this.state.data.selectedFilesList,
      info: this.state.data.fileInfo,
      resultSet: resultSet,
    };
    let resp = await setTicketVehicleInfoUpdate(data);
    if (resp.data.success === true) {
      if (resp.data.data) {
        toast.success("Vehicle Details Updated Successfully");
        let data = { ...this.state.data };
        data["tripEnd"] = "";
        data["fileInfo"] = "";
        this.setState({ data });
      } else {
        !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
      }
    } else {
      toast.error(() => <div>{resp.data.msg}</div>);
    }
  }

  render() {
    const fileStyle = {
      width: "130px",
      height: "130px",
      float: "left",
      cursor: "pointer",
      border: "1px solid #d3c4c4",
      "border-radius": "6px",
    };
    const imgStyle = {
      border: "1px solid #d3c4c4",
      width: "130px",
      height: "130px",
      "line-height": "130px",
      float: "left",
      cursor: "pointer",
      "font-size": "48px",
      color: "#d7caca",
      "background-color": "#f9f1f1",
      "border-radius": "6px",
    };

    return (
      <React.Fragment>
        <div
          className="card "
          // style={{ width: "88%", height: "100%", position: "relative" }}
        >
          <div className="card-header bg-success text-white">
            <div className="row">
              <div className="col">
                Update Trip End
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                  style={{
                    display: "inline",
                    float: "right",
                    marginTop: "-0.4rem",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-body mt-0 ">
            <div className="row">
              <div className="col">
                <div
                  className="container   overflow-auto"
                  style={{ height: "50rem" }}
                >
                  <>
                    <b className="mt-5">&nbsp;&nbsp;Vehicle Info :</b>
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3">
                        {this.rNB(
                          "Trip End (in Kms) ",
                          "tripEnd",
                          " col-6 mt-1 mb-3"
                        )}
                      </div>

                      <>
                        <b className="mt-5">&nbsp;&nbsp;File Upload :</b>

                        <div className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3">
                          <div className="m-0 p-0 col-9 mt-2">
                            <label
                              htmlFor="refDocFiles"
                              className="form-label font-monospace"
                            >
                              Upload Files
                            </label>
                            <input
                              className="form-control form-control-sm "
                              multiple
                              id="refDocFiles"
                              type="file"
                              style={{ cursor: "pointer" }}
                              onChange={(e) => {
                                this.selectedFilesLists(e.target.files);
                              }}
                            />
                          </div>
                          {this.rTB(
                            "File Info ",
                            "fileInfo",
                            " col-12 mt-3 mb-3"
                          )}

                          <div
                            className="col-12 page-todo overflow-auto"
                            style={{ height: "10rem" }}
                          >
                            <div className="tasks">
                              <div className="form-group multi-preview">
                                {(this.state.file || []).map((url, index) => (
                                  <div key={url}>
                                    <li className="list-group-item" key={index}>
                                      <p>
                                        <a>{url.name}</a>
                                      </p>{" "}
                                      <img
                                        className="mt-5"
                                        src={URL.createObjectURL(url)}
                                        alt={url.name}
                                        key={index}
                                        style={{
                                          height: "50px",
                                          width: "100px",
                                        }}
                                      />
                                      <button
                                        type="button"
                                        style={{
                                          position: "absolute",
                                          marginLeft: "30px",
                                        }}
                                        onClick={() => this.deleteFile(index)}
                                      >
                                        delete
                                      </button>
                                    </li>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          {/* <div className="form-group preview">
    {file.length > 0 &&
      file.map((item, index) => {
        return (
          <div key={item}>
            <img src={item} alt="" />
            <button
              type="button"
              onClick={() => this.deleteFile(index)}
            >
              delete
            </button>
          </div>
        );
      })}
  </div> */}
                        </div>
                      </>

                      <div>
                        <button
                          className="btn btn-primary btn-sm pull-right"
                          onClick={() => this.onVehicleInfo()}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </div>
            <hr />

            {/* <div className="row">
              <div
                className="col-12 page-todo overflow-auto"
                style={{ height: "20rem" }}
              >
                <div className="tasks">
                  {this.state.photoDetails.map((r) => {
                    return (
                      <div className="task" key={r.S3_FILE_ID}>
                        <div className="desc mb-0 pb-0">
                          <p>
                            {r.FILE_TYPE == "xlsx" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_self"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-excel-o mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  ></i>
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "docx" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_self"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-word-o mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  ></i>
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "txt" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-text mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  ></i>
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "pdf" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-pdf-o mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  />
                                </a>
                              </>
                            )}

                            {r.FILE_TYPE == "jpg" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.FILE_PATH}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "jpeg" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.FILE_PATH}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "png" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.FILE_PATH}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "gif" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.FILE_PATH}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            <div>
                              <div>
                                <h5>
                                  <span> Upload By </span>
                                  <text>{r["MULTI_REF_IDS"]}</text>
                                  <span className="ml-2">
                                    {" "}
                                    : &nbsp;{r.CREATED_BY}
                                  </span>
                                </h5>
                              </div>
                              <div>
                                <h5>
                                  <span> Info </span>
                                  <span className="ml-5">
                                    {" "}
                                    : &nbsp;{r.INFO}
                                  </span>
                                </h5>
                              </div>
                              <div>
                                <h5>
                                  <span> File Type </span>
                                  <span className="ml-3">
                                    {" "}
                                    : &nbsp;{r.FILE_TYPE}
                                  </span>
                                </h5>
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default DocumentDetails;
