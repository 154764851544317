import React from "react";

import FormLib from "../../../_common/FormLib";
import Table from "./../../../_common/Table";
import { dropMenuFull } from "./../../../../utils/DropMenuUtils";
import { getPaymentsList } from "../../../../services/requestV1/requestServices";
import dateFormat from "dateformat";

class RequestPaid extends FormLib {
  state = {
    requestDetails: {},
    paymentDetails: [],
  };

  componentDidMount() {
    this.setRequest();
  }

  setRequest() {
    this.setState({ requestDetails: this.props.data });
    this.getPayments();
  }

  async getPayments() {
    let paymentsList = await getPaymentsList(this.props.data.REQUEST_ID);
    this.setState({ paymentDetails: paymentsList.data.data });
  }

  tableMeta = {
    key: "APPROVE_ID",
    columns: [
      {
        key: "PYMT_MODE",
        action: (r) => (
          <>
            {r.PYMT_MODE}
            <br />
            {r.UTR_NO}
          </>
        ),
        label: "Mode | UTR",
      },
      { path: "APPROVER_NAME", label: "Approver" },
      { path: "APPROV_AMT", label: "App. Amt" },
      {
        key: "PAID_AT",
        action: (r) => <>{dateFormat(r.PAID_AT, "dd-mm-yyyy")}</>,
        label: "Paid Date",
      },
    ],
  };

  render() {
    const invoiceData = this.state.paymentDetails;
    // console.log("invoiceData--------->" + JSON.stringify(invoiceData));
    return (
      <React.Fragment>
        <div style={{ marginTop: "-4rem", position: "fixed" }}>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.closeModal}
          >
            <span aria-hidden="true">Paid &times;</span>
          </button>

          <div className="card">
            <div className="card-header bg-primary text-white">
              <div className="row">
                <div className="col-6">
                  {this.state.requestDetails.M_CLIENT}
                </div>
                <div className="col-6 text-right">
                  {dateFormat(
                    this.state.requestDetails.CREATED_AT,
                    "dd-mm-yyyy"
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  {this.state.requestDetails.REF_ID} [
                  {this.state.requestDetails.M_HUB} ]
                </div>
                <div className="col-4 text-right">
                  #{this.state.requestDetails.RE_PREFIX}
                  {this.state.requestDetails.SEQ}
                </div>
              </div>
            </div>
            <div className="card-body border-top">
              <div className="row">
                <div className="col-4">
                  <span className="req-label">Request Type </span>
                  <span className="req-value">
                    {this.state.requestDetails.REQUEST_TYPE}
                  </span>
                </div>
                {this.state.requestDetails.PLAYER_TYPE === "employee" && (
                  <div className="col-4">
                    <span className="req-label">Employee</span>
                    <span className="req-value">
                      {this.state.requestDetails.E_NAME} [
                      {this.state.requestDetails.PLAYER_CODE}]
                    </span>
                  </div>
                )}
                {this.state.requestDetails.PLAYER_TYPE === "vendor" && (
                  <div className="col-4">
                    <span className="req-label">Vendor</span>
                    <span className="req-value">
                      {this.state.requestDetails.V_NAME} [
                      {this.state.requestDetails.PLAYER_CODE}]
                    </span>
                  </div>
                )}
                <div className="col-4">
                  <span className="req-label">Flow </span>
                  <span className="req-value">
                    {this.state.requestDetails.M_FLOW}
                  </span>
                </div>
              </div>
            </div>

            <div className="panel-group widget-request">
              <div className="panel panel-info">
                <label>Activity Details</label>
                <div className="panel-body">
                  {this.state.requestDetails.M_ACTIVITY}
                </div>
              </div>
              <div className="panel panel-info">
                <label>Request Details</label>
                <div className="panel-body">
                  {this.state.requestDetails.REQUEST_DETAILS}
                </div>
              </div>
              <div className="panel panel-info">
                <label className="label-approve">Approve Remarks</label>
                <div className="panel-body">
                  {this.state.requestDetails.APPROV_REMARK}
                </div>
              </div>
            </div>

            <div className="card-body row">
              <div className="col-4 text-center">
                <span className="req-label">Total Amt Approved </span>
                <span className="req-value">
                  {this.state.requestDetails.AMT_APPROVED}
                </span>
              </div>
              <div className="col-4 text-center">
                <span className="req-label">Requested Amount </span>
                <span className="req-value">
                  {this.state.requestDetails.REQ_AMT}
                </span>
              </div>
              <div className="col-4 text-center">
                <span className="req-label">Approved Amount </span>
                <span className="req-value">
                  {this.state.requestDetails.APPROV_AMT}
                </span>
              </div>
            </div>
            <div className="card-body border-top dropup-center dropup">
              <div
                className="form-subHead "
                data-toggle="collapse"
                href="#Payments"
                aria-expanded="false"
                aria-controls="Payments"
              >
                Payments
                <i
                  className="fa fa-angle-down fa-1x text-danger ml-1 pull-right"
                  aria-hidden="true"
                ></i>
              </div>

              <div className="collapse panel-heading fixTableHead" id="Payments">
                {
                  <div style={{ height:"8rem", overflow:"scroll"}}>
                  <Table
                  clsName="table table-striped table-bordered table-hover"
                  tableMeta={this.tableMeta}
                  data={invoiceData}
                  dropMenu={dropMenuFull()}
                />
                </div>
                }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default RequestPaid;
