import * as act from "./locationTypes";

const initCountryState = {
  countries: [],
};
const initStateState = {
  states: [],
};
const initCityState = {
  cities: [],
};

export const countryReducer = (state = initCountryState, action) => {
  switch (action.type) {
    case act.COUNTRY_LIST_ALL:
      return {
        countries: action.payload.countries,
      };

    default:
      return state;
  }
};

export const stateReducer = (state = initStateState, action) => {
  switch (action.type) {
    case act.STATE_LIST_ALL_BY_COUNTRY:
      return {
        ...state,
        states: action.payload.states,
      };

    default:
      return state;
  }
};

export const cityReducer = (state = initCityState, action) => {
  switch (action.type) {
    case act.CITY_LIST_ALL_BY_STATE:
      return {
        cities: action.payload.cities,
      };

    default:
      return state;
  }
};

// export  countryReducer;
// export  stateReducer;
// export  cityReducer ;
