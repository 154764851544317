import React from "react";
import FormLib from "./../../_common/FormLib";

class ProjectModules extends FormLib {
  state = {
    data: {
      moduleId: "",
      projectId: "",
      prModule: "",
      podOwner: "",
      status: "true",
    },
    errors: {},
    curProject: {},
  };

  isCur(id, cur) {
    if (cur.indexOf(id) >= 0) {
      return " curTasks ";
    }
    return " ";
  }

  componentDidMount() {
    this.setCurProject(this.props.curProject);
  }
  setCurProject(p) {
    this.setState({ curProject: p });
  }
  setCurModule(m) {
    if (m.moduleId !== "") {
      m.status = 1;
      this.setState({ data: m });
    } else {
      this.resetData();
    }
    // console.log(JSON.stringify(this.state.data));
  }

  resetData() {
    this.setState({
      data: {
        projectId: "",
        moduleId: "",
        prModule: "",
        podOwner: "",
        status: "true",
      },
    });
  }
  render() {
    const modules = this.props.modules;
    const curProject = this.props.curProject;
    const curModule = this.props.curModule;
    const curModulesList = this.props.curModulesList;

    return (
      <div className="card  col-3" style={{ margin: 0, padding: 2 }}>
        <h5 className="card-header">
          Functionality
          {curProject.projectId !== "" && !curModule.moduleId && (
            <button
              type="button"
              className="btn btn-sm btn-primary pull-right"
              data-toggle="modal"
              data-target="#myModuleModel"
            >
              <i className={"fa  fa-plus  "}></i>
            </button>
          )}
          {curModule.moduleId && curModule.moduleId !== "" && (
            <>
              <button
                type="button"
                className="btn btn-sm btn-warning pull-right"
                onClick={() => this.props.onModuleUnload()}
              >
                <i className={"fa  fa-unlink  "}></i>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-success pull-right"
                data-toggle="modal"
                data-target="#myModuleModel"
              >
                <i className={"fa  fa-pencil  "}></i>
              </button>
            </>
          )}
        </h5>

        <div className="card-body" style={{ padding: 2 }}>
          <div className="page-todo">
            <div className="tasks">
              {modules.map((mi) => (
                <div
                  className={
                    "task nil " + this.isCur(mi.moduleId, curModulesList)
                  }
                  key={mi.moduleId}
                >
                  <div className="desc">
                    <span
                      className="span-click"
                      onClick={() => this.props.onModuleChange(mi)}
                    >
                      <div className="title">{mi.prModule}</div>
                    </span>
                    <div>{mi.modOwner}</div>
                  </div>

                  <div className="pull-right">
                    <div className="menu">
                      <span
                        className={
                          "badge   badge-success pull-left " +
                          "badge-" +
                          mi.status
                        }
                      >
                        {mi.sort + " : " + mi.status.substring(2)}
                      </span>
                    </div>
                    <div className="pr-2">{mi.seq}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectModules;
