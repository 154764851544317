import React, { Component } from "react";
import { Link } from "react-router-dom";
import Table from "../../_common/Table";
import Pagination from "./../../_common/Pagination";
import { paginate } from "./../../../utils/paginate";

import { dropMenuFull } from "./../../../utils/DropMenuUtils";

import { fakeGetUserAll } from "./../../../services/userService";

class TableListDemo extends Component {
  subHeading = "User";
  state = {
    users: [],
    pageLinks: {
      pageNo: 1,
      perPage: 3,
    },
  };

  componentDidMount() {
    this.loadUsers();
  }

  async loadUsers() {
    const resp = await fakeGetUserAll();
    this.setState({ users: resp.data.users });

    const pageNo = this.props.match.params.pageNo
      ? parseInt(this.props.match.params.pageNo)
      : 1;
    if (pageNo !== 1) this.handlePageChange(pageNo);
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/demo/list/client-side-pagination/" + pageNo);
    const pageLinks = this.state.pageLinks;
    pageLinks.pageNo = pageNo;
    this.setState({ pageLinks });
  };

  onDelete = (r) =>{
    console.log(JSON.stringify(r));
  }

  tableMeta = {
    key: "reqcode",
    columns: [
      {
        path: "reqcode",
        label: "ReqCode",
        action: (r) => (
          <Link to={`/demo/list/${r.reqcode}`}>{r.reqcode}</Link>
        ),
      },
      { path: "hub", label: "Hub",
      // action: movie => (
      //   <Like liked={movie.liked} onClick={() => this.props.onLike(movie)} />
      // ) 
    },
      { path: "customer", label: "Customer" },
      { path: "activity", label: "Activity" },
      {
        key: "delete",
        action: r => (
          <button
            onClick={() => this.onDelete(r)}
            className="btn btn-danger btn-sm"
          >
            Delete
          </button>
        )
      }
    ],
  };

  render() {
    const { length: itemsCount } = this.state.users;
    const { pageNo, perPage } = this.state.pageLinks;

    if (itemsCount === 0) return <p>No Data Found</p>;

    const users = paginate(this.state.users, pageNo, perPage);

    return (
      <React.Fragment>
        <div className="pageContent">
          {this.props.loading && (
            <div className="container-fluid">
              <div> Loading users...</div>
            </div>
          )}

          {!this.props.loading && itemsCount && (
            <div className="container-fluid">
              <Table
                clsName="table table-striped table-bordered table-hover"
                tableMeta={this.tableMeta}
                data={users}
                dropMenu={dropMenuFull("/demo/list")}
              />
              <Pagination
                itemsCount={itemsCount}
                perPage={perPage}
                pageNo={pageNo}
                onPageChange={this.handlePageChange}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default TableListDemo;
