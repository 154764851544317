import React from "react";
import swal from "sweetalert";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import $ from "jquery";

import Table from "../../_common/Table";

import Players from "../../_subhead/Players";
import toast from "../../../services/_toastService";
import {
  getTeamTypes,
  createTeamTypes,
  saveTeamTypes,
  deleteTeamTypes,
} from "../../../services/players/teamTypeServices";
class TeamTypes extends FormLib {
  state = {
    data: {
      typeId: "",
      type: "",
      status: "active",
    },
    errors: {},
    formMode: "create",
    teamTypes: [],
    loading: true,
  };
  schema = {
    //flowId: Joi.string().regex(/^[a-zA-Z0-9]*$/).required().label("FlowId").error(() => {
      // return {
      //   message: 'Special character not allow',
      // }}),
    // typeId: Joi.string().trim().min(3).max(10).required().label("TypeId"),
    typeId: Joi.string().min(3).max(10).regex(/^[a-zA-Z0-9]+$/).required().label("TypeId").error(() => {
      return {
        message: 'Special character not allow',
      }}),
    type: Joi.string().trim().required().label("Team Type"),
    status: Joi.string().trim().required().label("Status"),
  };

  componentDidMount() {
    this.loadTeamTypes();
  }

  async loadTeamTypes() {
    this.setState({ loading: true });
    const res = await getTeamTypes();
    if (res.data.success === true) {
      this.setState({ teamTypes: res.data.data.teamTypes });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createTeamTypes(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveTeamTypes(this.state.data);
      this.resetData();
    }

    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadTeamTypes();
    } else {
      toast.toastError(res.data.msg);
    }
  }
  doSubmitDelete = async (handleDelete, r) => {
    if (handleDelete) {
      await deleteTeamTypes(r);
      this.loadTeamTypes();

      swal("Team Type has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Team Type is safe!");
    }
  };
  handleEdit = (r) => {
    let teamTypes = {};
    teamTypes.typeId = r.typeId;
    teamTypes.type = r.type;
    teamTypes.status = r.status;
    this.setState({ data: teamTypes, formMode: "edit" });
    $("#typeId").attr("disabled", "disabled");
  };

  handleDelete = async (r) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, r);
    });
  };
  handleView = (r) => {
    this.setState({ curTeamType: r, formMode: "view" });
  };

  resetData() {
    this.setState({
      data: {
        typeId: "",
        teamType: "",
        status: "active",
      },
      formMode: "create",
    });
    $("#typeId").removeAttr("disabled");
  }

  tableMeta = {
    key: "typeId",
    columns: [
      {
        key: "TYPE_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleEdit(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleView(r)}
              >
                <i className="fa fa-binoculars" aria-hidden="true"></i> View
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(r)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i> Delete
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      { path: "typeId", label: "Id" },
      { path: "type", label: "Team Type" },
      { path: "status", label: "Status" },
    ],
  };
  typConf = {
    value: "sortKey",
    label: "sort",
  };
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    return (
      <React.Fragment>
        <Players subHeading="Team Types" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              {/* {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Team Types</div>
                </div>
              )} */}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.teamTypes}
                    dropMenu={[]}
                  />
                </div>
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              {this.state.formMode === "view" && (
                <div className="card ">
                  <div className="card-body">
                    <>
                      {this.state.curTeamType["type"].trim()}
                      <br />
                      <div className="pull-right">
                        <span className={"badge   badge-light pull-left ml-1 "}>
                          {this.state.curTeamType["typeId"]}
                        </span>
                        <span
                          className={"badge   badge-success pull-left  mr-1 "}
                        >
                          {this.state.curTeamType["status"]}
                        </span>
                      </div>
                    </>
                  </div>
                </div>
              )}
              {this.state.formMode !== "view" && (
                <div className="card pt-3">
                  <div className="card-body">
                    <form>
                      {this.rTB("Id", "typeId")}
                      {this.rTB("Team Type", "type")}

                      <div className="form-row">
                        {this.rRBL(
                          "Status",
                          "status",
                          this.status,
                          this.stsConf
                        )}
                      </div>
                    </form>

                    {this.state.formMode === "create" &&
                      this.rBtnSP("Create", "create")}
                    {this.state.formMode === "edit" && (
                      <>
                        {this.rBtnSS("Edit", "edit")}
                      </>
                    )}
                    {this.mResetBtn(() => this.resetData(), " pull-right ")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default TeamTypes;
