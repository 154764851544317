import React from "react";
import FormLib from "./../../_common/FormLib";
import toast from "../../../services/_toastService";
import { assign, unassign } from '../../../services/coreItAdmin/PlayerDesignationsServices';


class PlayerDesignationsMapper extends FormLib {

    state = {
        data: {},
        errors: {},
        curPlayer: {},
    };

    componentDidMount() {
        this.setState({ curPlayer: this.props.curPlr });
    }

    localHandleChange = (e) => {
        this.asignUnAsignPlayersDesignation(e);
    }

    asignUnAsignPlayersDesignation = (e) => {
        let paramData = {};
        paramData.playerId = this.state.curPlayer.playerId;
        if (e.target.checked) {
            paramData.designationId = e.target.value;
            // paramData.isPrimary = "true";
            this.assignUnassign('assign', paramData);
        } else {
            paramData.designationId = e.target.value;
            // paramData.isPrimary = "false";
            this.assignUnassign('unAssign', paramData);
        }
    }

    assignUnassign = async (type, paramData) => {
        if (type === 'assign') {
            const res = await assign(paramData);
            if (res.data.success) {
                toast.toastSuccess(res.data.msg);
            } else {
                toast.toastError(res.data.msg);
            }
        } else {
            const res = await unassign(paramData);
            if (res.data.success) {
                toast.toastSuccess(res.data.msg);
            } else {
                toast.toastError(res.data.msg);
            }
        }
        this.props.onLoadDesignation(this.state.curPlayer.playerId);
    }
    chkStatus = false;
    render() {
        const designations = this.props.designations;
        return (
            <div className="card overflow-auto" style={{height:'30rem'}}>
                <div className="card-body">
                    <div className="page-todo">
                        <div className="tasks">
                            {designations.map((r) => {
                                 this.chkStatus = r.playerId === null ? false : true;
                                return <div className="task nil" key={r.designationId}>
                                    <div className="desc">
                                        <span>
                                            <div className="title">
                                                {/* {this.rCBHL("hl", [{ label: r.designation, value: r.designationId }])} */}
                                                {this.rCBHLLS(
                                                    "cb_" + r.designationId,
                                                    [{ label: r.designation, value: r.designationId }],
                                                    { value: "value", label: "label" },
                                                    this.chkStatus,
                                                    "m-0"
                                                )}
                                                
                                                </div>
                                        </span>
                                        <div>
                                            <span className={"badge badge-success pull-left mr-1 " + "badge-" +
                                                r.department}>{r.department}
                                            </span>
                                            <span className={"badge badge-success pull-left mr-1 " + "badge-" +
                                                r.teamtype}>{r.teamtype}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PlayerDesignationsMapper;