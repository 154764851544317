import React from "react";
import "jquery-ui-dist/jquery-ui";
import CreateManifest from "./CreateManifest";
// import CreateManifestNew from "./CreateManifestNew";

import IncomingManifest from "./IncomingManifest";
import FormLib from "./../../../_common/FormLib";
import OutgoingManifest from "./OutgoingManifest";
import CompletedManifest from "./CompletedManifest";
import LineHaulWrapperNew from "./LineHaulWrapperNew";
import ReversedManifest from "./ReversedManifest";

export default class LineHaulWrapper extends FormLib {
  state = {
    mode: "Create",
  };

  onModeChange(mode) {
    this.setState({ mode: mode });
  }

  render() {
    return (
      <>
        <div
          className="modal fade pr-0"
          id="myModals"
          tabindex="-1"
          aria-labelledby="linehaul_modal"
          aria-hidden="true"
        >
          <div
            className="modal-dialog-full-width modal-dialog momodel modal-fluid"
            role="document"
          >
            <div className="modal-content-full-width modal-content">
              {/* <div className="modal-header-full-width modal-header">
                <h4 className="modal-title text-center">Manifest</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span>&times;</span>
                </button>
              </div> */}
              {/* <LineHaulWrapperNew /> */}
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span>&times;</span>
                </button>
                <div role="tabpanel">
                  <div className="text-center">
                    {/* <button
                      onClick={() => this.onModeChange("CreateNew")}
                      className={
                        this.state.mode === "CreateNew"
                          ? " btn btn-success btn-sm "
                          : "btn  btn-default btn-sm "
                      }
                    >
                      CreateNew
                    </button> */}
                    <button
                      onClick={() => this.onModeChange("Create")}
                      className={
                        this.state.mode === "Create"
                          ? " btn btn-success btn-sm "
                          : "btn  btn-default btn-sm "
                      }
                    >
                      Create
                    </button>
                    <button
                      onClick={() => this.onModeChange("Incoming")}
                      className={
                        this.state.mode === "Incoming"
                          ? " btn btn-success btn-sm "
                          : "btn  btn-default btn-sm "
                      }
                    >
                      Incoming
                    </button>
                    <button
                      onClick={() => this.onModeChange("Outgoing")}
                      className={
                        this.state.mode === "Outgoing"
                          ? " btn btn-success btn-sm "
                          : "btn  btn-default btn-sm "
                      }
                    >
                      Outgoing
                    </button>
                    <button
                      onClick={() => this.onModeChange("Completed")}
                      className={
                        this.state.mode === "Completed"
                          ? " btn btn-success btn-sm "
                          : "btn  btn-default btn-sm "
                      }
                    >
                      Completed
                    </button>
                    <button
                      onClick={() => this.onModeChange("Reversed")}
                      className={
                        this.state.mode === "Reversed"
                          ? " btn btn-success btn-sm "
                          : "btn  btn-default btn-sm "
                      }
                    >
                      Reversed
                    </button>
                  </div>
                  <hr />
                  <div className="tab-content">
                    {/* {this.state.mode === "CreateNew" && <CreateManifestNew />} */}
                    {this.state.mode === "Create" && <CreateManifest />}
                    {this.state.mode === "Incoming" && <IncomingManifest />}
                    {this.state.mode === "Outgoing" && <OutgoingManifest />}
                    {this.state.mode === "Completed" && <CompletedManifest />}
                    {this.state.mode === "Reversed" && <ReversedManifest />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
