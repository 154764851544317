import React, { useState, useEffect } from "react";
import FormLib from "../../_common/FormLib";
import swal from "@sweetalert/with-react";

const UpdateSKUCode = ({ itemId, itemCode, handleSave }) => {
  const [selectItemCode, setItemCode] = useState("");
  const closeButtonRef = React.useRef();

  const handleClickSave = async (e) => {
    if (selectItemCode === "") {
      swal({ icon: "error", title: "SKU code cannot be empty" });
      return null;
    }

    const resp = await handleSave(selectItemCode);
    if (closeButtonRef.current && resp) closeButtonRef.current.click();
  };

  return (
    <div className="container-fluid">
      <div className="modal fade" id="myBarcodeModalSlNo">
        <div className="modal-dialog modal-md">
          <div className="modal-content" style={{ maxHeight: "100vh" }}>
            <div className="modal-header">
              <h4 className="modal-title">Item Details</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <div>
                <label className="col clearfix ">
                  <span className="ml-0 pl-0">Enter Item SKU Code</span>
                </label>
                <input
                  type="text"
                  id={itemCode}
                  autoComplete="false"
                  className="form-control"
                  value={selectItemCode}
                  onChange={(e) => setItemCode(e.target.value)}
                />
              </div>
              <div>
                <label className="col clearfix ">
                  <span className="ml-0 pl-0">Item </span>
                </label>
                <input
                  type="text"
                  autoComplete="false"
                  className="form-control"
                  value={itemId.item}
                  // onChange={(e) => setItemCode(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={handleClickSave}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdateSKUCode;
