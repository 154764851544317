import React from "react";

function Textarea({
  name,
  label,
  value,
  rows,
  error,
  classAppend = "",
  ...rest
}) {
  return (
    <div className={" form-group " + classAppend}>
      <textarea
        {...rest}
        rows={rows}
        id={name}
        name={name}
        value={value || ""}
        style={{ resize: "none" }}
      ></textarea>
      <label htmlFor={name} className="control-label">
        {label}
      </label>
      <i className="bar"></i>
      {error && <span className="alert alert-danger">{error}</span>}
    </div>
  );
}

export default Textarea;
