
export const TicketActionsData = [
    {
        "date": "2023-05-17",
        "TicketNo": "BIZ-MOB-101-1007239",
        "actionCategory": "Man Power",
        "refNo": "001",
        "isBilling": "Yes",
        "InvoiceNo": "020202",
        "vendorInfo": "Sat",
        "Files": "",
        "Expense": "",

    },
    {
        "date": "2023-05-17",
        "TicketNo": "EFL-MOB-101-1006046",
        "actionCategory": "Vehicle",
        "refNo": "002",
        "isBilling": "Yes",
        "InvoiceNo": "323232323",
        "vendorInfo": "Sam",
        "Files": "",
        "Expense": "",

    },
    {
        "date": "2023-05-16",
        "TicketNo": "HMD-MOB-101-1007140",
        "actionCategory": "Packing",
        "refNo": "0012",
        "isBilling": "No",
        "InvoiceNo": "6565565",
        "vendorInfo": "Del",
        "Files": "",
        "Expense": "",

    },
    {
        "date": "2023-05-16",
        "TicketNo": "BIZ-MOB-101-1007238",
        "actionCategory": "Vehicle",
        "refNo": "0013",
        "isBilling": "Yes",
        "InvoiceNo": "2326659",
        "vendorInfo": "Dart",
        "Files": "",
        "Expense": "",

    },
    {
        "date": "2023-05-15",
        "TicketNo": "EFL-MOB-101-1006921 ",
        "actionCategory": "Toll",
        "refNo": "0025",
        "isBilling": "Yes",
        "InvoiceNo": "98956565",
        "vendorInfo": "Bhag",
        "Files": "",
        "Expense": "",

    }

];

