import React from "react";
import FormLib from "../../_common/FormLib";
import swal from "sweetalert";
import Joi from "joi-browser";
import $ from "jquery";
import Table from "../../_common/Table";
import CoreItAdmin from "../../_subhead/CoreItAdmin";
import toast from "../../../services/_toastService";
import {
  getFlows,
  createFlows,
  saveFlows,
  deleteFlows,
} from "../../../services/coreItAdmin/flowsService";
class Flows extends FormLib {
  state = {
    data: {
      flowId: "",
      flowName: "",
      flowCode: "",
      flowStatus: "active",
    },
    errors: {},
    formMode: "create",
    flows: [],
    loading: true,
  };
  schema = {
    flowId: Joi.string().regex(/^[a-zA-Z0-9]*$/).required().label("FlowId").error(() => {
      return {
        message: 'Special character not allow',
      }
    }),
    flowName: Joi.string().required().label("Flow Name"),
    flowCode: Joi.string().required().label("Flow Code"),
    flowStatus: Joi.string().required().label("Flow Status"),
  };
  tableMeta = {
    key: "flowId",
    columns: [
      {
        key: "FLOW_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnFlows(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleView(r)}
              >
                <i className="fa fa-binoculars" aria-hidden="true"></i> View
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(r)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i> Delete
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      { path: "flowId", label: "Flow Id" },
      { path: "flowName", label: "Name" },
      { path: "flowCode", label: "Code" },
      { path: "flowStatus", label: "Status" },
    ],
  };
  handleOnFlows(r) {
    let flows = {};
    flows.flowId = r.flowId;
    flows.flowName = r.flowName;
    flows.flowCode = r.flowCode;
    flows.flowStatus = r.flowStatus;
    this.setState({ data: flows, formMode: "edit" });
    $("#flowId").attr("disabled", "disabled");
    $("#flowCode").attr("disabled", "disabled");
  }
  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createFlows(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveFlows(this.state.data);
      this.resetData();
    }
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadFlows();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  doSubmitDelete = async (handleDelete, r) => {
    if (handleDelete) {
      await deleteFlows(r);
      this.loadFlows();

      swal("Flow has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Flow is safe!");
    }
  };

  handleDelete = async (r) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, r);
    });
  };

  handleView = (r) => {
    this.setState({ curFlowName: r, formMode: "view" });
  };

  componentDidMount() {
    this.loadFlows();
  }
  async loadFlows() {
    this.setState({ loading: true });
    const res = await getFlows();
    if (res.data.success === true) {
      this.setState({ flows: res.data.data.flows });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }
  resetData() {
    this.setState({
      data: {
        flowId: "",
        flowName: "",
        flowCode: "",
        flowStatus: "active",
      },
      formMode: "create",
    });
    $("#flowId").removeAttr("disabled");
    $("#flowCode").removeAttr("disabled");
  }

  typConf = {
    value: "sortKey",
    label: "sort",
  };
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    return (
      <React.Fragment>
        <CoreItAdmin subHeading="Flows" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Flows</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.flows}
                    dropMenu={[]}
                  />
                </div>
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              {this.state.formMode === "view" && (
                <div className="card">
                  <div className="card-body">
                    <>
                      {this.state.curFlowName["flowName"].trim()}
                      <br />
                      <div className="pull-right">
                        <span className={"badge   badge-light pull-left ml-1 "}>
                          {this.state.curFlowName["flowId"]}
                        </span>
                        <span
                          className={"badge   badge-success pull-left  mr-1 "}
                        >
                          {this.state.curFlowName["status"]}
                        </span>
                      </div>
                    </>
                  </div>
                </div>
              )}
              {this.state.formMode !== "view" && (
                <div className="card pt-4">
                  <div className="card-body">
                    <form>
                      <div className="form-row">
                        {this.rTB("Flow Id", "flowId", "col-6")}
                        {this.rTB("Flow Code", "flowCode", "col-6")}
                        {this.rTB("Flow Name", "flowName", "col-6")}
                        {this.rRBL(
                          "Flow Status",
                          "flowStatus",
                          this.status,
                          this.stsConf,
                          "col-6"
                        )}
                      </div>
                    </form>

                    {this.state.formMode === "create" &&
                      this.rBtnSP("Create", "create")}
                    {this.state.formMode === "edit" && (
                      <>
                        {this.rBtnSS("Edit", "edit")}
                      </>
                    )}
                    {this.mResetBtn(() => this.resetData(), " pull-right ")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Flows;
