import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";

import { createBulkBarcode } from "./../../../services/packing/BarcodeService";
import { getHubsAll } from "../../../services/masterLocations/hubServices";
import { getTeams } from "../../../services/players/teamServices";
import {
  getPackingMaterials,
} from "./../../../services/packing/PackingMaterialService";
class ModalBarcodeN extends FormLib {
  state = {
    data: {
      packMaterialId: "",
      barcodeType: "prePrint",
      // barcodeStart: "",
      numbers: "",
      status: "00available",
      hubId: "",
      teamId: "",
    },
    hubs: [],
    curMaterial: "",
    errors: {},
    curBarcode: {},
    status: [
      {
        statusId: "00available",
        status: "available",
      },
      {
        statusId: "10issued",
        status: "issued",
      },
      {
        statusId: "20used",
        status: "used",
      },
      {
        statusId: "30received",
        status: "received",
      },
      {
        statusId: "40damaged",
        status: "damaged",
      },
      {
        statusId: "50scraped",
        status: "scraped",
      },
    ],

  };

  schema = {
    packMaterialId: Joi.optional().label("packMaterialId"),
    hubId: Joi.string().required().label("hubId"),
    teamId: Joi.optional().label("teamId"),
    barcodeType: Joi.string().required().label("barcodeType"),
    // barcodeStart: Joi.required().label("barcodeStart"),
    numbers: Joi.required().label("numbers"),
    status: Joi.string().required().label("status"),
  };

  componentDidMount() {
    this.loadPackingMaterial();
    this.loadHubs();
    this.loadTeams();
    this.setCurMaterialHub(this.props.curBarcode, this.props.curHubId);
  }

  async loadTeams() {
    this.setState({ loading: true });
    const res = await getTeams();
    if (res !== undefined) {
      if (res.data.success === true) {
        this.setState({ teams: res.data.data.teams });
        this.state.data.teamId = res.data.data.teams[8]['teamId'];
        this.setState({ curTeam: res.data.data.teams[8] });
        this.setState({
          data: {
            hubId: this.state.data.hubId,
            teamId: res.data.data.teams[8]['teamId'],
            status: this.state.data.status,
            packMaterialId: this.state.data.packMaterialId,
            barcodeType: "prePrint",
            numbers: "",
          }
        });

      } else {
        toast.toastError(res.data.msg);
      }
    }
    this.setState({ loading: false });

  }
  async loadHubs() {
    let hubList = JSON.parse(localStorage.getItem("hubs"));
    this.setState({ hubList });
    let curHub = localStorage.getItem("curHub");
    if (!curHub) {
      let override = this.state.data;
      override['hubId'] = JSON.parse(JSON.stringify(hubList[0].hubId));
      this.setState({ override })
    } else {
      let override = this.state.data;
      override['hubId'] = curHub;
      this.setState({ override })
    }
  }
  async loadPackingMaterial() {
    this.setState({ loading: true });
    const res = await getPackingMaterials();
    if (res !== undefined) {
      if (res.data.success === true) {
        this.setState({ packingmaterials: res.data.data.packingMaterials });

        this.state.data.packMaterialId = res.data.data.packingMaterials[4]['packMaterialId'];

        this.setState({ curPack: res.data.data.packingMaterials[4] });

        this.setState({
          data: {
            packMaterialId: res.data.data.packingMaterials[4]['packMaterialId'],
            hubId: this.state.data.hubId,
            teamId: this.state.data.teamId,
            status: this.state.data.status,
            barcodeType: "prePrint",
            numbers: "",
          }
        });
      } else {
        toast.toastError("failed");
      }
    }
    this.setState({ loading: false });
  }
  setCurMaterialHub = (p, h) => {
    const data = { ...this.state.data };
    if (p.packMaterialId !== "") {
      data.packMaterialId = p.packMaterialId;
    }
    if (p.hubId !== "") {
      data.hubId = p.hubId;
      data.teamId = p.teamId;
    }
    this.setState({ data });

  }
  async doSubmit() {
    let res;
    // console.log(JSON.stringify(this.state.data));
    if (this.state.btnValue === "create") {
      res = await createBulkBarcode(this.state.data);
      if (res.data.success) {
        this.resetData();
      }

    }
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.props.onloadBarcodes();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  resetData() {
    this.setState({
      data: {
        packMaterialId: "",
        barcodeType: "prePrint",
        // barcodeStart: "",
        numbers: "",
        status: "",
      },
      hubs: [],
      teams: [],
    });
  }

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  barcodeType = [
    { sortKey: "prePrint", sort: "prePrint" },
    { sortKey: "sticker", sort: "sticker" },

  ];
  barConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    return (
      <div className="container-fluid">
        <div className="modal fade" id="myBarcodeModalN">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Barcode</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  <div className="form-row">
                    <div className="col-md-3">
                      {this.rRBL(
                        "Barcode Type",
                        "barcodeType",
                        this.barcodeType,
                        this.barConf
                      )}
                    </div>

                    <div className="col-md-3 ">
                      {this.rSB("Hub", "hubId", this.state.hubList, {
                        value: "hubId",
                        label: "hub",
                      })}
                      {/* {this.rTB("Hub", "hubId", "")} */}
                    </div>
                    <div className="col-md-3 ">
                      {this.rSBA("Team", "teamId", this.state.teams, {
                        value: "teamId",
                        label: "name",
                      })}
                    </div>
                    <div className="col-md-3">
                      {this.rSBD(
                        "Status *",
                        "status",
                        this.state.status,
                        { value: "statusId", label: "status" },

                      )}
                    </div>

                  </div>
                  <div className="form-row">
                    <div className="col-md-3">
                      {this.rSB(
                        "Packing Material",
                        "packMaterialId",
                        this.state.packingmaterials,
                        {
                          value: "packMaterialId",
                          label: "name",
                        }
                      )}
                    </div>

                    {/* {this.rNB("Barcode Start", "barcodeStart", "col-3")} */}
                    {this.rNB("Number", "numbers", "col-3")}
                    {/* </div> */}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {
                  this.rBtnSP("Create", "create")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalBarcodeN;
