import React from 'react'
import { getHubsAll } from '../../../services/masterLocations/hubServices';
import FormLib from './../../_common/FormLib';
import { toast } from 'react-toastify';
import Joi from 'joi-browser';
import { updateDstHub, updateCurHub } from './../../../services/Tickets/TicketProcessServices';

export default class HubAssignment extends FormLib {
    state = {
        data: { hubId: "" },
        errors: {},
        curblockDetails: {},
        curProductDetails: {},
        hubs: [],
    }

    schema = {
        hubId: Joi.string().required().label("Hub"),
    }


    componentDidMount() {
        this.loadHubs();
    }

    async loadHubs() {
        try {

            const res = await getHubsAll();
            if (res.data.success === true) {
                this.setState({ hubs: res.data.data.hubs });
            } else {
                toast.error(res.data.msg);
            }
            this.loadInit();
        }
        catch {
            console.log("Error in HubAssignment>>>> loadhubs");
        }
    }

    loadInit() {
        const curblockDetails = this.props.curblockDetails;
        const curProductDetails = this.props.curProductDetails;

        this.setState({ curblockDetails });
        this.setState({ curProductDetails });
    }

    async doSubmit() {
        try {
            const curHub = this.state.hubs.filter((h) => {
                return h.hubId === this.state.data.hubId ? h.hub : "";
            });

            let params = {
                "ticketBlockId": this.state.curblockDetails.ticketBlockId,
                "hubId": this.state.data.hubId,
                "hubName": curHub[0].hub,
                "oldHubId": this.state.curblockDetails.dstHubId,
                "oldHubName": this.state.curblockDetails.dstHub,
                "processId": this.state.curblockDetails.processId
            }
            // console.log("DESTINATIONNNNNNNNNNNNNNNN-------> " + JSON.stringify(this.state.curblockDetails.flowStatus));
            if (this.state.curblockDetails.flowStatus === "DROP_WEB") {
                await updateCurHub(this.state.curblockDetails.ticketBlockId, this.state.data.hubId);
            }

            let resp = await updateDstHub(params);
            if (resp.data.success === true) {
                resp.data && toast.success(() => <div>{resp.data.msg}</div>);;
                !resp.data && toast.warning(() => <div>{resp.data.msg}</div>);
            } else {
                toast.error(() => <div>{resp.data.msg}</div>);
            }
        }
        catch {
            console.log("Error in HubAssignment>>>>>>doSubmit");
        }
    }

    render() {
        return (
            <>
                <div>
                    <span className='badge badge-success block pull-right' style={{ "fontSize": "90%" }}><b>{this.state.curblockDetails.dstHub} - ({this.state.curProductDetails.dstPincode})</b></span>
                    <br />
                    <form onSubmit={this.handleSubmit}>
                        {this.rSB(
                            "Dst Hub",
                            "hubId",
                            this.state.hubs,
                            { value: "hubId", label: "hub" },
                            ""
                        )}
                        {this.rBtnLP(
                            "Save",
                            "Submit",
                            "btn btn-primary btn-sm pull-right"
                        )}
                    </form>
                </div>
            </>
        )
    }
}
