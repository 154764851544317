import React from "react";
import FormLib from "./../../_common/FormLib";
import LocationTitle from "../../_subhead/LocationTitle";
import HubStores from "./HubStores";
import StoresItems from "./StoresItems";

import StoresBaysItems from "./StoresBaysItems";

// import TicketDetails from "./TicketDetails";
// import TicketFiles from "./TicketFiles";
// import TicketProcess from "./TicketProcess";
// import toast from "../../../services/_toastService";
// import { getTicketDetails } from './../../../services/TicketHistory/TicketHistory';
// import TicketEvaluation from './TicketEvaluation';
import {
  getStores,
  getItemStockListByStore,
  getItemStockListByBays,
} from "./../../../services/stockManagement/inventory";

class Inventory extends FormLib {
  state = {
    hubs: [],
    errors: {},
    hubLists: [],
    storesList: [],
    storeItemsList: [],
    baysItemsList: [],

    curHub: { hubId: "" },
    curStore: { storeId: "" },
    curItem: { itemId: "" },
  };
  componentDidMount() {
    this.loadHubs();
    this.loadStores(this.props.hubValue)
  }
  handleStoreChange = (st) => {
    this.loadItems(st);
  };
  handleItemChange = (item) => {
    this.loadBaysItems(item);
  };
  handleStoreUnload = () => {
    // this.setState({ userstories: [], tasks: [] });
    // this.setState({ curModule: {}, curUserstory: {}, curTask: {} });
  };

  componentDidUpdate(prevProps) {
    // Here updating the new hubValue
    if (this.props.hubValue !== prevProps.hubValue) {
      this.loadStores(this.props.hubValue);
    }
  }
  async loadHubs() {
    try {
      let hubList = JSON.parse(localStorage.getItem("hubs"));
      this.setState({ hubs: hubList });
      // console.log(JSON.stringify(hubList));
    } catch {
      console.log("Error in Inventory>>>>>> loadHubs");
    }
  }
  isCur(id, cur) {
    // console.log(JSON.stringify(id) + "::::::" + JSON.stringify(cur));
    if (id === cur) {
      return " curTasks ";
    }
    return " ";
  }

  loadStores = async (hubId, modReset = true) => {
    this.setState({ stores: [] });
    this.setState({ storeItemsList: [] });
    this.setState({ baysItemsList: [] });
    if (modReset === true) {
      // this.setState({ userstories: [], tasks: [] });
      // this.setState({ curUserstory: {}, curTask: {} });
      // this.setState({ curStore: {} });
    }
    let curHub;
    const filter = this.state.hubs.filter((r) => {
      return r.hubId === hubId ? r : null;
    });

    if (!filter[0]) {
      curHub = { hubId: "" };
    } else {
      curHub = filter[0];
    }

    this.setState({ curHub: curHub });

    if (hubId !== "") {
      localStorage.setItem("inventoryHub", hubId);
      const res = await getStores(hubId);
      this.setState({ stores: res.data.data.stores });
    } else {
      this.setState({ stores: [] });
    }
    this.setState({ data: { hubs: this.props.hubValue } });
  };

  loadItems = async (st) => {
    this.setState({ storeItemsList: [] });
    this.setState({ baysItemsList: [] });

    if (st.storeId !== "") {
      const res = await getItemStockListByStore(st.storeId);
      console.log({ res });
      this.setState({ storeItemsList: res.data.data.items });
    } else {
      this.setState({ storeItemsList: [] });
    }
    this.setState({ curStore: { storeId: st.storeId } });
  };

  loadBaysItems = async (item) => {
    this.setState({ baysItemsList: [] });
    if (item.storeId !== "") {
      const res = await getItemStockListByBays(
        this.state.curStore.storeId,
        item.itemId
      );
      this.setState({ baysItemsList: res.data.data.itemsStockList });
    } else {
      this.setState({ baysItemsList: [] });
    }
  };

  onReload = async (item) => {
    this.setState({ baysItemsList: [] });
    if (item.storeId !== "") {
      const res = await getItemStockListByBays(
        this.state.curStore.storeId,
        item,
      );
      this.setState({ baysItemsList: res.data.data.itemsStockList });
    } else {
      this.setState({ baysItemsList: [] });
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* <LocationTitle subHeading="Inventory" /> */}
        <h1 className='fs-4 mt-2'> Inventory</h1>
        <div className="pageContent">
          <div className="container-fluid">
            <div className="card-deck">
              {/* <div className="card  col-1" style={{ margin: 0, padding: 2 }}>
                <h5 className="card-header"> Hub List</h5>
                <div className="card-body" style={{ padding: 2 }}>
                  <div className="page-todo">
                    <div className="tasks">
                      {this.state.hubs.map((pr) => (
                        <div
                          key={pr.hubId}
                          className={
                            "task nil " +
                            this.isCur(pr.hubId, this.state.curHub.hubId)
                          
                          }
                   
                        >
                          <div
                            className="desc"
                            style={{
                              width: "100%",
                              fontSize: "16px",
                              padding: "4px 0px",
                            }}
                          >
                            <span
                              className="span-click"
                              // onClick={() => this.loadStores(pr.hubId)}
                            >
                              <div
                                className="title"
                                style={{ fontSize: "12px" }}
                              >
                                {pr.hub}
                              </div>
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div> */}
              <HubStores
                stores={this.state.stores}
                curHub={this.state.curHub}
                curStore={this.state.curStore}
                onStoreChange={this.handleStoreChange}
                onStoreUnload={this.handleStoreUnload}
              />
              <StoresItems
                storeItemsList={this.state.storeItemsList}
                curStore={this.state.curStore}
                curItem={this.state.curItem}
                onItemChange={this.handleItemChange}
              />

              <StoresBaysItems
                baysItemsList={this.state.baysItemsList}
                stores={this.state.stores}
                onReload={this.onReload}
              />
              {/* <InventorySlNo
              paramsData = {this.state.baysItemsList}
              /> */}
              {/* <HubItems
                                userstories={this.state.userstories}
                                curModule={this.state.curModule}
                                curUserstory={this.state.curUserstory}
                                onUserstoryChange={this.handleUserstoryChange}
                                onUserstoryUnload={this.handleUserstoryUnload}
                            /> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Inventory;
