import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

const Pagination = ({
  itemsCount,
  perPage,
  pageNo,
  onPageChange,
  divisor = 3,
}) => {
  const pagesCount = Math.ceil(itemsCount / perPage);
  if (pagesCount === 1) return null;

  let startPage = 1;
  if (pageNo - divisor > 0) startPage = pageNo - divisor;

  let endPage = pagesCount;
  if (pageNo + divisor < endPage) endPage = pageNo + divisor;

  const pages = _.range(startPage, endPage + 1);
  let prevPage = pageNo - 1;
  let nexPage = pageNo + 1;

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-end">
        {startPage !== 1 && (
          <li className="page-item ">
            <button className="page-link" onClick={() => onPageChange(1)}>
              1
            </button>
          </li>
        )}
        {pageNo > 2 && (
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => onPageChange(prevPage)}
            >
              <i className="fa fa-angle-left" aria-hidden="true"></i>
            </button>
          </li>
        )}
        {startPage !== 1 && (
          <li className="page-item">
            <button className="page-link">
              <i className="fa fa-ellipsis-h fa-1" aria-hidden="true"></i>
            </button>
          </li>
        )}

        {pages.map((page) => (
          <li
            key={page}
            className={page === pageNo ? "page-item active" : "page-item"}
          >
            <button className="page-link" onClick={() => onPageChange(page)}>
              {page}
            </button>
          </li>
        ))}
        {pageNo + divisor < pagesCount && (
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => onPageChange(prevPage)}
            >
              <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
            </button>
          </li>
        )}
        {pagesCount - pageNo > 1 && (
          <li className="page-item">
            <button className="page-link" onClick={() => onPageChange(nexPage)}>
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </button>
          </li>
        )}
        {pageNo + divisor < pagesCount && (
          <li className="page-item">
            <button
              className="page-link"
              onClick={() => onPageChange(pagesCount)}
            >
              {pagesCount}
            </button>
          </li>
        )}
        <li className="page-item"> <button
          className="page-link"
        >
          Items: {itemsCount}
        </button></li>
      </ul>
    </nav >
  );
};

Pagination.propTypes = {
  itemsCount: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  pageNo: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default Pagination;
