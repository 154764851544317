import React from "react";
import FormLib from "./../../_common/FormLib";
import PickUpVerificationProduct from "./PickUpVerificationProduct";
import PickUpVerificationTicket from "./PickUpVerificationTicket";
import PickUpVerificationBlock from "./PickUpVerificationBlock";
import { getImages } from "./../../../services/Tickets/TicketServices";
import { setTicketActionsNew } from "./../../../services/Tickets/TicketProcessServices";
import { ticketListReload } from "../../../store";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { IstToUTC } from "../../../utils/DateUtils";
import { setTicketActionsTicketCancel } from "./../../../services/Tickets/TicketProcessServices";
import swal from "sweetalert";
import Joi from "joi-browser";

class PickUpNew extends FormLib {
  state = {
    data: {},
    errors: {},
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    curAction: [],
    setImages: [],
    pageHide: "false",
    pickStatus: "false",
    cancelStatus: "false",
  };
  includeCancelTicket = [
    { sortKey: "yes", sort: "Do you want to cancel Ticket??" },
  ];
  schema = {
    includeCancelTicket: Joi.string().optional().label("includeCancelTicket"),
  };
  componentDidMount() {
    try {
      this.getlocalDateTime();
      this.loadInit();
      let pageHide = this.props.hideAction;
      this.setState({ pageHide });
      this.setState({ curStatus: this.props.curStatus });
      this.loadImages();
      let hoForm = localStorage.getItem("curHub");
      if (hoForm === "1lUeT6ivx3d1DXPKW74SdKyLQrM=") {
        this.setState({ hideForm: "true" });
      } else {
        this.setState({ hideForm: "false" });
      }
    } catch {
      console.log("Error in PickUpNew>>>>>>doSubmit");
    }
  }

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }

  loadInit() {
    try {
      const curTicketDetails = this.props.ticketDetails;
      const curBlockDetails = this.props.blockDetails;
      const curProductDetails = this.props.ticketDetails.products;

      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails });
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.setState({ designation: userInfo.designation });
    } catch {
      console.log("Error in PickUpNew>>>>>>loadInit");
    }
  }

  async loadImages() {
    try {
      const processId = this.props.ticketDetails.processId;
      let res = await getImages(processId);
      if (res !== undefined) {
        if (res.data.success === true && res.data.data != []) {
          this.setState({ setImages: res.data.data.images });
        } else if (res.data.success === false) {
          toast.error("No Images Available for this Block");
        }
      }
    } catch {
      console.log("Error in load images");
    }
  }

  doSubmit = async () => {
    try {
      let override = { ...this.state.data };
      override["actionCode"] = "wPickupConfirm";
      override["action"] = "Confirm Pickup";
      // this.setState({ override });
      let resultSet = {
        blockId: this.state.curBlockDetails.ticketBlockId,
        processId: this.state.curBlockDetails.processId,
        actionStatus: "PICKUP_CONFIRMED",
        flowId: this.state.curTicketDetails.flowId,
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
      };

      // console.log("cameeeeeeeeeeee " + JSON.stringify(this.state.data));
      // console.log("Block Details " + JSON.stringify(this.state.curBlockDetails));

      let resp = await setTicketActionsNew(
        override,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        resultSet
      );
      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ pickStatus: "true" });
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in PickUpNew>>>>>>doSubmit");
    }
  };

  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };
  replaceString = (str) => {
    return str.replace(/_/g, " ");
  };
  stringReplace(data) {
    return data
      .replace(/{/g, "")
      .replace(/}/g, "")
      .replace(/]/g, "")
      .replace(/"/g, "")
      .replace(/:/g, " : ")
      .replace(/,/g, " , ");
  }
  checkIloaded(t) {
    // console.log(JSON.stringify(t.actionCode));
    // if (t.actionCode === "mFrntPhoto") {
    //   alert("confirm Front Photo");
    // }
    // if (t.actionCode === "mBackPhoto") {
    //   alert("confirm Back Photo");
    // }
    // // "actionCode":"mFrntPhoto"
  }
  handleCancelTicket = async () => {
    swal({
      title: "Are you sure want to Cancel the Ticket?",
      text: "Kindly Enter the Reason for Ticket Cancellation",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: "input",
      placeholder: "Write something",
    }).then(async (handleCancelTicket) => {
      this.doCancelTicket(handleCancelTicket);
    });
  };
  doCancelTicket = async (handleCancelTicket) => {
    if (handleCancelTicket) {
      // alert(handleRTO)
      swal(
        "Ticket has been successfully Cancelled with the following remarks: " +
          handleCancelTicket,
        {
          icon: "success",
        }
      );
      this.submitCancelTicket(handleCancelTicket);
    } else {
      swal("Ticket not cancelled!", { icon: "error" });
    }
  };
  submitCancelTicket = async (handleCancelTicket) => {
    let resultSet = {
      remarks: handleCancelTicket,
      actionStatus: "OPS_CANCALLATION",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    let data = this.state.data;
    data = {
      actionCode: "wOPSCancelled",
      action: "Ticket Cancelled",
    };
    this.setState({ data });

    let resp = await setTicketActionsTicketCancel(
      this.state.data,
      this.state.curTicketDetails,
      this.state.curBlockDetails,
      resultSet
    );
    if (resp !== undefined) {
      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ cancelStatus: "true" });
          // this.setState({ rtoStatus: "false" });
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
  };
  render() {
    const ticketDetails = this.state.curTicketDetails;
    return (
      <>
        <div className="row  "></div>
        <div className="row ">
          {this.state.pageHide === "false" &&
            this.state.pickStatus === "false" &&
            this.state.hideForm === "false" &&
            this.state.cancelStatus === "false" && (
              <>
                {this.state.setImages.length !== 0 && (
                  <div className="col-md-12">
                    <div className="card ">
                      <div className="card-body bg-light">
                        <div className="form-subHeader">
                          <b>{ticketDetails.ticketNo}</b>
                          {this.state.designation === "Operations Head" && (
                            <div
                              className="pull-right"
                              style={{ marginTop: "-1%", marginRight: "23%" }}
                            >
                              {this.rCBHL(
                                "includeCancelTicket",
                                this.includeCancelTicket,
                                {
                                  value: "sortKey",
                                  label: "sort",
                                }
                              )}
                            </div>
                          )}
                          {this.state.data.includeCancelTicket === "yes" && (
                            <div
                              className="pull-right"
                              style={{ marginTop: "1%", marginRight: "-40%" }}
                            >
                              <button
                                title="Cancel Ticket"
                                className="badge badge-danger  pull-right"
                                type="button"
                                onClick={() => this.handleCancelTicket()}
                                style={{
                                  fontSize: "82%",
                                  borderRadius: "2.25rem ",
                                  border: "none",
                                  fontWeight: "bold",
                                  marginRight: "6px",
                                }}
                              >
                                CANCEL TICKET
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="form-subHeader">
                          Before Packing Photos Verification
                        </div>

                        {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode !== "mSignature" && t.actionCode !== "mPhotoId" && t.actionCode !== "mPhotoAftrPackng" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode !== "mSignature" &&
                                  t.actionCode !== "mPhotoId" &&
                                  t.actionCode !== "mPhotoAftrPackng" && (
                                    <div className="col-md-4">
                                      <span>
                                        <a
                                          href="javascript:void(0);"
                                          data-toggle="tooltip"
                                          title={this.stringReplace(
                                            t.geoLocation
                                          )}
                                        >
                                          <img
                                            src={t.imageUrl}
                                            style={{
                                              width: "150px",
                                              height: "150px",
                                              margin: "3px",
                                            }}
                                            onload={this.checkIloaded(t)}
                                          />
                                        </a>
                                        <br></br>
                                        <span
                                          className="badge badge-info link-over ml-2"
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            margin: "3px",
                                          }}
                                        >
                                          {t.action}
                                        </span>
                                      </span>
                                      <br></br>
                                      <br></br>
                                    </div>
                                  )}
                              </>
                            );
                          })}
                        </div>

                        {ticketDetails.flowCode !== "PND2W" && (
                          <div className="form-subHeader mt-2">
                            After Packing Photos Verification
                          </div>
                        )}
                        {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode === "mPhotoAftrPackng" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}>
                                    <img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a>
                                    <span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode === "mPhotoAftrPackng" && (
                                  <div className="col-md-4">
                                    <span>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="tooltip"
                                        title={this.stringReplace(
                                          t.geoLocation
                                        )}
                                      >
                                        <img
                                          src={t.imageUrl}
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            margin: "3px",
                                          }}
                                        />
                                      </a>
                                      <br></br>
                                      <span
                                        className="badge badge-warning link-over ml-2"
                                        style={{
                                          width: "auto",
                                          height: "auto",
                                          margin: "3px",
                                        }}
                                      >
                                        {t.action}
                                      </span>
                                    </span>
                                    <br></br>
                                    <br></br>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                        <div className="form-subHeader mt-2">
                          Photo Id Verification
                        </div>
                        {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode === "mPhotoId" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode === "mPhotoId" && (
                                  <div className="col-md-4">
                                    <span>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="tooltip"
                                        title={this.stringReplace(
                                          t.geoLocation
                                        )}
                                      >
                                        <img
                                          src={t.imageUrl}
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            margin: "3px",
                                          }}
                                        />
                                      </a>
                                      <br></br>
                                      <span
                                        className="badge badge-danger link-over ml-2"
                                        style={{
                                          width: "auto",
                                          height: "auto",
                                          margin: "3px",
                                        }}
                                      >
                                        {t.action}
                                      </span>
                                    </span>
                                    <br></br>
                                    <br></br>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                        <div className="form-subHeader mt-2">
                          Signature Verification
                        </div>
                        {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode === "mSignature" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{this.replaceString(t.action)}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode === "mSignature" && (
                                  <div className="col-md-4">
                                    <span>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="tooltip"
                                        title={this.stringReplace(
                                          t.geoLocation
                                        )}
                                      >
                                        <img
                                          src={t.imageUrl}
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            margin: "3px",
                                          }}
                                        />
                                      </a>
                                      <br></br>
                                      <span
                                        className="badge badge-success link-over ml-2"
                                        style={{
                                          width: "auto",
                                          height: "auto",
                                          margin: "3px",
                                        }}
                                      >
                                        {this.replaceString(t.action)}
                                      </span>
                                    </span>
                                    <br></br>
                                    <br></br>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <div className="form-subHeader">
                    <b>{ticketDetails.ticketNo}</b>
                  </div>
                  <div className="card ">
                    <div className="card-body bg-light">
                      <div className="row mb-2">
                        <div className="col-3">
                          <span
                            className={"badge badge-info"}
                            style={{ fontSize: "12px" }}
                          >
                            <b>{ticketDetails.orderNo}</b>
                          </span>
                        </div>
                        <div className="col-3">
                          <span
                            className={"badge badge-info"}
                            style={{ fontSize: "12px" }}
                          >
                            <b>{ticketDetails.ticketNo}</b>
                          </span>
                        </div>
                        <div className="col-3">
                          <span
                            className={"badge badge-info"}
                            style={{ fontSize: "12px" }}
                          >
                            <b>{ticketDetails.flowName}</b>
                          </span>
                        </div>
                        <div className="col-3">
                          <span
                            className={"badge badge-info"}
                            style={{ fontSize: "12px" }}
                          >
                            <b>{ticketDetails.retailerName}</b>
                          </span>
                        </div>
                      </div>
                      <form onSubmit={this.handleSubmit}>
                        <PickUpVerificationProduct
                          productDetails={this.state.curProductDetails}
                          ticketDetails={this.state.curTicketDetails}
                          blockDetails={this.state.curBlockDetails}
                          key={"p_" + this.state.curBlockDetails.ticketBlockId}
                        />
                        <PickUpVerificationBlock
                          productDetails={this.state.curProductDetails}
                          ticketDetails={this.state.curTicketDetails}
                          blockDetails={this.state.curBlockDetails}
                          key={"b_" + this.state.curBlockDetails.ticketBlockId}
                        />
                        <PickUpVerificationTicket
                          productDetails={this.state.curProductDetails}
                          ticketDetails={this.state.curTicketDetails}
                          blockDetails={this.state.curBlockDetails}
                          key={"t_" + this.state.curBlockDetails.ticketBlockId}
                        />
                        {this.rBtnSP(
                          "Confirm Pickup",
                          "Confirm",
                          "pull-right mt-2"
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
        {this.state.pageHide === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Kindly Complete the Previous Process
            </p>
          </div>
        )}
        {this.state.pickStatus === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Ticket No: <b>{ticketDetails.ticketNo}</b> PickUp Verification
              Process Completed Successfully
            </p>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PickUpNew);
