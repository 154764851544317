import React from "react";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
// import ZTitle from "../../_subhead/ZTitle";
import FormLib from "../../../_common/FormLib";
import Joi from "joi-browser";
import {
  getHubs,
  getClients,
  getActivity,
  getFlows,
  getHubsActive,
} from "./../../../../services/masters/hubServices";
import _auth from "./../../../../services/_authService";
import {
  createRequestTicketActivities,
  getTicketsList,
  getTicketsListNew,
  getEmployeesList,
  getVendorsList,
  getExpenseHeadType,
} from "./../../../../services/requestV1/requestServices";
import { toast } from "react-toastify";
import swal from "@sweetalert/with-react";
import Table from "../../../_common/Table";
import "./ticketActions.css";

class ExpenseRise extends FormLib {
  subHeading = "User";

  state = {
    data: {
      request_type: "ticket",
      player_type: "employee",
      player_code: "",
      hubId: "",
      expense_type: "ticketActions",
      // client: "",
      activity: "",
      // activity_info: "",
      // flow: "",
      ref_id: "",
      refsNos: [],
      amt: "",
      req_amt: "",
      request_details: "",
      request_by: "",
      override: "false",
      status: "open",
    },
    errors: {},
    hubs: [],
    // clients: [],
    activities: [],
    suggestions: [],
    selectedTicket: "",
    text: "",
    empSuggestions: [],
    selectedEmpCode: "",
    empText: "",
    empName: "",
    venSuggestions: [],
    selectedVenCode: "",
    venText: "",
    venName: "",
    items: [],
    flows: [],
  };

  schema = {
    request_type: Joi.string().required().label("Request Type"),
    player_type: Joi.string().required().label("Raise Type"),
    player_code: Joi.string().required().label("Player Code"),
    hubId: Joi.string().optional().label("Hub"),
    expense_type: Joi.optional().label("Expense Type"),

    // client: Joi.string().required().label("Client"),
    activity: Joi.string().required().label("Activity"),
    // activity_info: Joi.optional().label("Activity Info"),
    // flow: Joi.string().required().label("Flow"),
    refsNos: Joi.string().required().label("Ticket Number"),
    ref_id: Joi.string().optional().label("Reference Id"),
    amt: Joi.number().required().min(0).label("Total Amount"),
    req_amt: Joi.number().required().min(0).label("Request Amount"),
    request_details: Joi.string().required().label("Request Details"),
    request_by: Joi.string().required().label("Request by"),
    override: Joi.string().required().label("Override"),
    status: Joi.string().required().label("Status"),
  };

  request_type = [
    { request_typeId: "ticket", request_type: "Ticket" },
    { request_typeId: "hubExpense", request_type: "Hub Expenses" },
    // { request_typeId: "other", request_type: "Other" },
  ];

  player_type = [
    { player_typeId: "employee", player_type: "Employee" },
    { player_typeId: "vendor", player_type: "Vendor" },
  ];

  localHandleChange = (e) => {
    if (e.target.name === "req_amt") {
      if (Number(this.state.data.amt) < Number(e.target.value)) {
        let errors = this.state.errors;
        errors["req_amt"] =
          `Request Amount should be less than or equal to  ` +
          this.state.data.amt;
        this.setState({
          errors,
        });
      } else {
        let errors = this.state.errors;
        delete errors["req_amt"];
        this.setState({
          errors,
        });
      }
      this.validateProperty(e.target.name, e.target.value);
    }
  };

  async doSubmit() {
    if (Number(this.state.data.req_amt) > Number(this.state.data.amt)) {
      // console.log("-------------> " + JSON.stringify(this.state.data.req_amt));
      let errors = this.state.errors;
      errors["req_amt"] =
        `Request Amount should be less than or equal to  ` +
        this.state.data.amt;
      this.setState({
        errors,
      });
      return;
    }
    let data = {
      hubId: this.state.data.hubId,
      expense_type: this.state.data.expense_type,
      request_type: this.state.data.request_type,
      player_type: this.state.data.player_type,
      player_code: this.state.data.player_code,
      activity: this.state.data.activity,
      ref_id: this.state.data.ref_id,
      refsNos: this.state.data.refsNos.split(","),
      amt: this.state.data.amt,
      req_amt: this.state.data.req_amt,
      request_details: this.state.data.request_details,
      request_by: this.state.data.request_by,
      override: this.state.data.override,
      status: this.state.data.status,
    };
    let requestraise = await createRequestTicketActivities(data);
    if (requestraise.data.success === true) {
      toast.success(() => <div>{requestraise.data.msg}</div>);
      document.getElementById("myForm").reset();
      this.reSetFormData();
      this.props.requestChange();
    } else {
      // toast.error(() => <div>{requestraise.data.msg}</div>);
      this.raisedRequestModal(requestraise);
    }
  }

  raisedRequestModal(requestraise) {
    let requestRaise = requestraise.data.data;
    swal({
      title: "Records are found, Do you want to continue?",
      content: this.paidData(requestRaise),
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: [true],
      minWidth: "1000px",
    }).then((willDelete) => {
      if (willDelete) {
        let override = this.state.data;
        override["override"] = "true";
        this.setState({ override });
        this.requestCreate(this.state.data);
      } else {
        swal("You can edit now and retry to raise the request again");
      }
    });
  }

  async requestCreate(data) {
    let requestraise = await createRequestTicketActivities(this.state.data);
    if (requestraise.data.success === true) {
      toast.success(() => <div>{requestraise.data.msg}</div>);
      document.getElementById("myForm").reset();
      this.reSetFormData();
    }
  }

  tableMeta = {
    key: "REQUEST_ID",
    columns: [
      { path: "SEQ", label: "Request No" },
      // { path: "TICKET_NO", label: "Ticket No." },
      { path: "AMT", label: "Total Requested Amount" },
      { path: "AMT_APPROVED", label: "Approved Amount" },
      { path: "PAID", label: "Amount Paid" },
      { path: "STATUS", label: "STATUS" },
    ],
  };

  paidData(requestRaise) {
    return (
      <div className="row">
        <Table
          clsName="table table-striped table-bordered table-hover"
          tableMeta={this.tableMeta}
          data={requestRaise}
          dropMenu={[]}
        />
      </div>
    );
  }

  reSetFormData() {
    const user = _auth.getCurrentUser();
    let data = {
      request_type: "ticket",
      player_type: "employee",
      player_code: "",
      hubId: "",
      expense_type: "ticketActions",
      // client: "",
      activity: "",
      // activity_info: "",
      // flow: "",
      refsNos: [],
      ref_id: "",
      amt: "",
      req_amt: "",
      request_details: "",
      request_by: "",
      override: "false",
      status: "open",
    };

    data.request_by = user.playerId;
    // data.hubId = user.locationId;
    this.setState({ data });

    this.setState(() => ({ suggestions: [], text: "", selectedTicket: "" }));
    this.setState(() => ({
      empSuggestions: [],
      empText: "",
      empName: "",
      selectedEmpCode: "",
    }));
    this.setState(() => ({
      venSuggestions: [],
      venText: "",
      venName: "",
      selectedVenCode: "",
    }));
  }

  loadHubs = async () => {
    let hubList = JSON.parse(localStorage.getItem("hubs"));
    this.setState({ hubs: hubList });
    // let list = await getHubsActive();
    // if (list !== undefined) {
    //   this.setState({ hubs: list.data.data.hubs });
    // }

    // alert(JSON.stringify(this.state.data));
  };

  async componentDidMount() {
    this.setCurExpenseRise();

    this.loadHubs();
    let list = [];
    // list = await getClients();
    // if (list !== undefined) {
    //   this.setState({ clients: list.data.data.clients });
    // }

    // list = await getActivity();
    // if (list !== undefined) {
    //   this.setState({ activities: list.data.data });
    // }
    const list1 = await getExpenseHeadType("ticketExpense");
    if (list1 !== undefined) {
      this.setState({ activities: list1.data.data.expenseHeadTypes });
    }

    list = await getFlows();
    if (list !== undefined) {
      this.setState({ flows: list.data.data.flows });
    }

    const user = _auth.getCurrentUser();
    let data = this.state.data;
    data.request_by = user.playerId;

    // data.hubId = user.locationId;

    this.setState({ data });
  }

  setCurExpenseRise = async () => {
    this.setState({ riseJSON: this.props.data });
    if (this.props.type !== "report") {
      if (this.props.data[0]["isVendor"] === "Yes") {
        this.onVenTextChanged("props");
      } else {
        this.setState({
          data: {
            hubId: this.props.data[0]["hubId"],
            request_type: "ticket",
            expense_type: "ticketActions",
            player_type: "employee",
            player_code: this.props.data[0]["hubId"],
            activity: this.props.data[0]["actionCatCode"],
            ref_id: this.props.ticketActionId,
            refsNos: this.props.ticketDetails.toString(),
            amt: "",
            req_amt: "",
            request_details: "",
            request_by: "",
            override: "false",
            status: "open",
          },
        });
      }
    } else {
      if (this.props.data["isVendor"] === "Yes") {
        this.onVenTextChanged("props");
      } else {
        this.setState({
          data: {
            hubId: this.props.data["hubId"],
            request_type: "ticket",
            expense_type: "ticketActions",
            player_type: "employee",
            player_code: this.props.data["hubId"],
            activity: this.props.data["actionCatCode"],
            ref_id: this.props.ticketActionId,
            refsNos: this.props.ticketDetails.toString(),
            amt: "",
            req_amt: "",
            request_details: "",
            request_by: "",
            override: "false",
            status: "open",
          },
        });
      }
    }
  };

  // sleep(ms) {
  //   return new Promise((resolve) => {
  //     setTimeout(resolve, ms);
  //   });
  // }
  onTextChanged = (e, src = "new") => {
    const value = e.target.value;
    let suggestions = [];

    if (value.length > 8) {
      if (src === "new") {
        this.getTicketsListNew(value);
      } else {
        this.getTicketsList(value);
      }
    }

    this.setState(() => ({ suggestions, text: value, selectedTicket: "" }));

    let data = this.state.data;
    data.ref_id = value;

    this.setState({ data });
  };

  onEmpTextChanged = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let empSuggestions = [];

    if (value.length > 1) {
      this.getEmployeesList(value, name);
    }

    this.setState(() => ({
      empSuggestions,
      empText: value,
      empName: name,
      selectedEmpCode: "",
    }));

    let data = this.state.data;

    data.player_code = value;
    this.setState({ data });
  };

  onVenTextChanged = (e) => {
    let value = "";
    let name = "";
    if (e === "props") {
      if (this.props.type !== "report") {
        if (
          this.props.data[0]["venCode"] === "null" ||
          this.props.data[0]["venCode"] === null ||
          this.props.data[0]["vendor"] === "null" ||
          this.props.data[0]["vendor"] === null
        ) {
          value = "";
          name = "";
        } else {
          value = this.props.data[0]["venCode"];
          name = this.props.data[0]["vendor"];
        }
        let venSuggestions = [];

        if (value.length > 1) {
          this.getVendorsList(value);
        }

        this.setState(() => ({
          venSuggestions,
          venText: value,
          venName: name,
          selectedVenCode: value,
        }));
        this.setState({
          data: {
            hubId: this.props.data[0]["hubId"],
            request_type: "ticket",
            expense_type: "ticketActions",
            player_type: "vendor",
            player_code: value,
            activity: this.props.data[0]["actionCatCode"],
            ref_id: this.props.ticketActionId,
            refsNos: this.props.ticketDetails.toString(),
            amt: "",
            req_amt: "",
            request_details: "",
            request_by: "",
            override: "false",
            status: "open",
          },
        });
      } else {
        if (
          this.props.data["venCode"] === "null" ||
          this.props.data["venCode"] === null ||
          this.props.data["vendor"] === "null" ||
          this.props.data["vendor"] === null
        ) {
          value = "";
          name = "";
        } else {
          value = this.props.data["venCode"];
          name = this.props.data["vendor"];
        }
        // const value = this.props.data["venCode"];
        // const name = this.props.data["vendor"];
        let venSuggestions = [];

        if (value.length > 1) {
          this.getVendorsList(value);
        }

        this.setState(() => ({
          venSuggestions,
          venText: value,
          venName: name,
          selectedVenCode: value,
        }));

        this.setState({
          data: {
            hubId: this.props.data["hubId"],
            request_type: "ticket",
            expense_type: "ticketActions",
            player_type: "vendor",
            player_code: value,
            activity: this.props.data["actionCatCode"],
            ref_id: this.props.ticketActionId,
            refsNos: this.props.ticketDetails.toString(),
            amt: "",
            req_amt: "",
            request_details: "",
            request_by: "",
            override: "false",
            status: "open",
          },
        });
      }
    } else {
      const value = e.target.value;
      const name = e.target.name;
      let venSuggestions = [];

      if (value.length > 1) {
        this.getVendorsList(value);
      }

      this.setState(() => ({
        venSuggestions,
        venText: value,
        venName: name,
        selectedVenCode: "",
      }));

      let data = this.state.data;

      data.player_code = value;
      this.setState({ data });
    }
  };

  suggestionSelected(value) {
    let ticketlist = this.state.data;
    ticketlist["ref_id"] = value;
    this.setState(() => ({
      ticketlist,
      text: value,
      suggestions: [],
      selectedTicket: value,
    }));
  }

  empSuggestionsSelected(value, name) {
    let employeelist = this.state.data;
    employeelist["player_code"] = value;
    this.setState(() => ({
      employeelist,
      empText: value,
      empName: name,
      empSuggestions: [],
      selectedEmpCode: value,
    }));
  }

  venSuggestionsSelected(value, name) {
    let vendorlist = this.state.data;
    vendorlist["player_code"] = value;
    this.setState(() => ({
      vendorlist,
      venText: value,
      venName: name,
      venSuggestions: [],
      selectedVenCode: value,
    }));
  }

  renderSuggestions() {
    const { suggestions } = this.state;
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <div className="srchList">
        <ul>
          {suggestions.map((item) => (
            <li onClick={() => this.suggestionSelected(item)}>{item}</li>
          ))}
        </ul>
      </div>
    );
  }

  renderEmpSuggestions() {
    const { empSuggestions } = this.state;
    if (empSuggestions.length === 0) {
      return null;
    }

    return (
      <div className="srchList">
        <ul>
          {empSuggestions.map((item) => (
            <li
              key={item.PLAYER_CODE}
              onClick={() =>
                this.empSuggestionsSelected(item.PLAYER_CODE, item.NAME)
              }
            >
              {item.PLAYER_CODE} [{item.NAME}]
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderVenSuggestions() {
    const { venSuggestions } = this.state;
    if (venSuggestions.length === 0) {
      return null;
    }

    return (
      <div className="srchList">
        <ul>
          {venSuggestions.map((item) => (
            <li
              key={item.VEN_CODE}
              onClick={() =>
                this.venSuggestionsSelected(item.VEN_CODE, item.NAME)
              }
            >
              {item.VEN_CODE} [{item.NAME}]
            </li>
          ))}
        </ul>
      </div>
    );
  }

  async getTicketsList(value) {
    let suggestions = [];
    let ticketsList = await getTicketsList(value);
    this.setState({ items: ticketsList.data.data.ticketNumbers });
    suggestions = this.state.items;
    this.setState(() => ({ suggestions, text: value }));
  }

  async getTicketsListNew(value) {
    let suggestions = [];
    let ticketsList = await getTicketsListNew(value);
    this.setState({ items: ticketsList.data.data.tickets });
    suggestions = this.state.items;
    this.setState(() => ({ suggestions, text: value }));
  }

  async getEmployeesList(value, name) {
    let empSuggestions = [];
    let employeesList = await getEmployeesList(value);
    this.setState({ items: employeesList.data.data?.empListbyCode });
    empSuggestions = this.state.items;
    this.setState(() => ({
      empSuggestions,
      empText: value,
      empName: name,
    }));
  }

  async getVendorsList(value, name) {
    let venSuggestions = [];
    let vendorsList = await getVendorsList(value);
    this.setState({ items: vendorsList.data.data?.venListbyCode });
    venSuggestions = this.state.items;
    this.setState(() => ({
      venSuggestions,
      venText: value,
      venName: name,
    }));
  }

  render() {
    const user = this.props.user;
    const { text } = this.state;
    const { empText, empName } = this.state;
    const { venText, venName } = this.state;
    return (
      <React.Fragment>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.props.closeModal}
        >
          <span aria-hidden="true"> Raise Expense &times;</span>
        </button>
        <div className="card">
          <div className="card-header bg-success text-white ">
            <div className="row">
              <div className="col-8"></div>
              <div className="col-4 text-right"></div>
            </div>
            <div className="row">
              <div className="col-8">{"Raise Expense"}</div>
              <div className="col-4 text-right"></div>
            </div>
          </div>

          {/* End of Header */}

          <div
            className="card-body border"
            style={{ backgroundColor: "rgb(255 255 255)" }}
          >
            <>
              <div className="card-body">
                <form id="myForm" onSubmit={this.handleSubmit}>
                  <div className="form-row ">
                    <div className="col-md-12">
                      {this.rSB(
                        "Hub *",
                        "hubId",
                        this.state.hubs,
                        { value: "hubId", label: "hub" },
                        " col-md-3  pull-right"
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6">
                      {this.rRBLD(
                        "Expense Type *",
                        "request_type",
                        this.request_type,
                        {
                          value: "request_typeId",
                          label: "request_type",
                        }
                      )}
                    </div>
                    <div className="col-md-6">
                      {this.rRBL(
                        "User Type *",
                        "player_type",
                        this.player_type,
                        {
                          value: "player_typeId",
                          label: "player_type",
                        }
                      )}
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-6">
                      {this.state.data.request_type === "ticket" &&
                        this.rTBD("Ticket No *", "refsNos", " col ")}
                    </div>
                    <div className="col-md-6">
                      {this.state.data.player_type === "employee" && (
                        <div className="col  form-group ">
                          <input
                            value={empText}
                            name="player_code"
                            onChange={this.onEmpTextChanged}
                            type="text"
                            autoComplete="off"
                          />
                          <label
                            htmlFor="player_code"
                            className="control-label"
                          >
                            Employee Code *
                          </label>
                          <i className="bar"></i>
                          <span className="badge" style={{ color: "black" }}>
                            {empName}
                          </span>
                          {this.renderEmpSuggestions()}
                        </div>
                      )}
                      {this.state.data.player_type === "vendor" && (
                        <div className="col   form-group ">
                          <input
                            value={venText}
                            name="player_code"
                            onChange={this.onVenTextChanged}
                            type="text"
                            autoComplete="off"
                          />
                          <label
                            htmlFor="player_code"
                            className="control-label"
                          >
                            Vendor Code *
                          </label>
                          <i className="bar"></i>
                          <span className="badge" style={{ color: "black" }}>
                            {venName}
                          </span>
                          {this.renderVenSuggestions()}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-row mt-3">
                    {/* {this.rTB("Expense Info", "activity_info", " col ")} */}

                    {/* {this.rSB(
                      "Expense Head *",
                      "activity",
                      this.state.activities,
                      { value: "EXPENSE_HEAD_ID", label: "EXPENSE_HEAD" },
                      " col "
                    )} */}
                    {this.rSB(
                      "Expense Head *",
                      "activity",
                      this.state.activities,
                      { value: "expenseHeadId", label: "expenseHead" },
                      " col"
                    )}
                    {/* {this.state.data.request_type === "oldTicket" && (
                      <div className="col  form-group">
                        <input
                          value={text}
                          name="ref_id"
                          onChange={(e) => this.onTextChanged(e, "old")}
                          type="text"
                          autoComplete="off"
                          className=" col "
                        />
                        <label htmlFor="ref_id" className="control-label">
                          Old RL Ticket No *
                        </label>
                        <i className="bar"></i>
                        {this.renderSuggestions()}
                      </div>
                    )} */}
                    {/* {this.state.data.request_type === "ticket" && (
                      <div className="col  form-group">
                        <input
                          value={text}
                          name="ref_id"
                          onChange={(e) => this.onTextChanged(e, "new")}
                          type="text"
                          autoComplete="off"
                          className=" col "
                        />
                        <label htmlFor="ref_id" className="control-label">
                          Ticket No *
                        </label>
                        <i className="bar"></i>
                        {this.renderSuggestions()}
                      </div>
                    )} */}
                    {this.rNB("Total Amount *", "amt", " col ")}
                    {this.rNB("Expense Amount *", "req_amt", " col ")}
                  </div>

                  {this.rTA("Expense Details *", "request_details")}

                  <div className="button-container">
                    {((this.state.data.player_type === "employee" &&
                      this.state.selectedEmpCode) ||
                      (this.state.data.player_type === "vendor" &&
                        this.state.selectedVenCode) ||
                      (this.state.data.request_type === "other" &&
                        this.state.data.player_type === "employee") ||
                      (this.state.data.request_type === "other" &&
                        this.state.data.player_type === "vendor")) &&
                      this.rBtnSP("Raise ", "Raise")}
                    <br />
                    {/* {!this.state.selectedVenCode && "Vendor Code Not Valid"}
                  {this.state.selectedVenCode && "Vendor Code Valid"}

                  <br />
                  {!this.state.selectedTicket && "Ticket Code Not Valid"}
                  {this.state.selectedTicket && "Ticket Code Valid"} */}

                    {this.state.data.player_type === "employee" &&
                      !this.state.selectedEmpCode && (
                        <span className="alert alert-info cusError">
                          Invalid Employee Code
                        </span>
                      )}

                    {this.state.data.player_type === "vendor" &&
                      !this.state.selectedVenCode && (
                        <span className="alert alert-info cusError">
                          Invalid Vendor Code
                        </span>
                      )}

                    {/* {!this.state.selectedTicket &&
                      (this.state.data.request_type === "ticket" ||
                        this.state.data.request_type === "oldTicket") && (
                        <span className="alert alert-info cusError">
                          Invalid Ticket Number
                        </span>
                      )} */}
                    {/* <div className="form-row">
                      <div className="col-md-12">
                        <button
                          className="button-container btn  btn-info  btn-sm pull-right"
                          onClick={() => this.reSetFormData()}
                        >
                          Reset
                        </button>
                      </div>
                    </div> */}
                  </div>

                  <div className="form-group col-md-12 text-center">
                    <p className="mt-5 mb-3 text-muted">&copy; Bizlog</p>
                  </div>
                </form>
              </div>
            </>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ExpenseRise;
