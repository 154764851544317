import React from "react";
import FormLib from "./../../_common/FormLib";
import PickUpVerificationProduct from "./PickUpVerificationProduct";
import PickUpVerificationTicket from "./PickUpVerificationTicket";
import PickUpVerificationInventory from "./PickUpVerificationInventory";
import PickUpVerificationBlock from "./PickUpVerificationBlock";
import { getImages } from "./../../../services/Tickets/TicketServices";
import {
  setTicketActions,
  getPhotoCapturedData,
  getPackingMaterialAndProdByJobIdAdvExc,
} from "./../../../services/Tickets/TicketProcessServices";
import { ticketListReload } from "../../../store";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { IstToUTC } from "../../../utils/DateUtils";
import Joi from "joi-browser";
import {
  setTicketActionsTicketCancel,
  getPackingMaterialAndProdByJobId,
} from "./../../../services/Tickets/TicketProcessServices";
import swal from "sweetalert";
import Table from "./../../_common/Table";
import BulkQCProcess from "./BulkQCProcess";
import FurnitureFlowProcess from "./FurnitureFlowProcess";
import BlockCreation from "./BlockCreation";
import { getOverrideConfirmPickupStatus } from "../../../services/stockManagement/inventory";
import {} from "./../../../services/Tickets/TicketProcessServices";

class PickUp extends FormLib {
  state = {
    data: {},
    errors: {},
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    curAction: "",
    setImages: [],
    pageHide: "false",
    pickStatus: "false",
    cancelStatus: "false",
    isInventoryVerified: false,
    isProductVerified: false,
    isProductVerifiedFurn: false,
    isProductNotAttempted: "false",
    mode: "",
    photoAvailable: false,
    pickupItemsVerified: false,
    override: false,
  };

  // overridePickupConfirm = [{ sortKey: "yes", sort: "overridePickupConfirm" }];

  // stsConfig = {
  //   value: "sortKey",
  //   label: "sort",
  // };

  componentDidMount() {
    try {
      //furnitureFlow
      //furnitureFlow
      this.loadPackingMaterialData();
      this.getlocalDateTime();
      this.loadInit();
      let pageHide = this.props.hideAction;
      this.setState({ pageHide });
      this.setState({ curStatus: this.props.curStatus });
      this.loadImages();
      let hoForm = localStorage.getItem("curHub");
      if (hoForm === "1lUeT6ivx3d1DXPKW74SdKyLQrM=") {
        this.setState({ hideForm: "true" });
      } else {
        this.setState({ hideForm: "false" });
      }

      this.getNoOfPhotos();
    } catch {
      console.log("Error in PickUp>>>>>>componentDidMount");
    }
  }

  async getNoOfPhotos() {
    const params = {
      teamId: this.props.ticketDetails.retailerId,
      flowId: this.props.ticketDetails.flowId,
      flowStatus: "PICK_MOB",
      deliveryType: this.props.ticketDetails.deliveryType,
    };

    // console.log(params);
    const resp = await getPhotoCapturedData(params);
    // console.log("resp=======>NoOfPhotos", resp.data.data.resultSet);
  }

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }

  includeCancelTicket = [
    { sortKey: "yes", sort: "Do you want to cancel Ticket??" },
  ];
  schema = {
    includeCancelTicket: Joi.string().optional().label("includeCancelTicket"),
  };
  loadInit() {
    try {
      // console.log(JSON.stringify(this.props.blockDetails));
      const curTicketDetails = this.props.ticketDetails;
      const curBlockDetails = this.props.blockDetails;
      const curProductDetails = this.props.ticketDetails.products;
      const curProductDetailsFurn = this.props.blockDetails.products;
      this.prodVerification(curProductDetails);
      this.prodVerificationFurniture(curProductDetailsFurn);

      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails });
      this.setState({ curProductDetailsFurn });

      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.setState({ designation: userInfo.designation });
      const parsedResultSet = JSON.parse(curBlockDetails.blockInventoryFe);
      this.setState({ jobFeInventory: parsedResultSet.jobFeInventory });
    } catch {
      console.log("Error in PickUp>>>>>>loadInit");
    }
  }

  async loadImages() {
    try {
      const processId = this.props.ticketDetails.processId;
      let res = await getImages(processId);
      if (res !== undefined) {
        if (res.data.success === true && res.data.data) {
          this.setState({ setImages: res.data.data.images });
          res.data.data.images.map((t) => {
            if (t.action === "POP") {
              this.setState({ photoAvailable: true });
            }
          });
        } else if (res.data.success === false) {
          toast.error("No Images Available for this Block");
        }
      }
    } catch {
      console.log("Error in load images");
    }
  }

  doSubmit = async () => {
    try {
      let override = { ...this.state.data };
      override["actionCode"] = "wPickupConfirm";
      override["action"] = "Confirm Pickup";
      // this.setState({ override });
      let resultSet = {
        blockId: this.state.curBlockDetails.ticketBlockId,
        processId: this.state.curBlockDetails.processId,
        actionStatus: "PICKUP_CONFIRMED",
        flowId: this.state.curTicketDetails.flowId,
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
      };
      if (
        this.state.curTicketDetails.flowId === "eWaste" ||
        this.state.curTicketDetails.flowId === "Bulk" ||
        this.state.curTicketDetails.flowId === "Furniture"
        // this.state.curTicketDetails.flowId === "DropOff" ||
        // this.state.curTicketDetails.flowId === "PickAndDropOneWay"
      ) {
        if (
          this.state.isProductVerified === true &&
          this.state.curTicketDetails.flowId !== "Furniture"
        ) {
          swal({
            icon: "error",
            title: "Kindly Check Products Status",
            dangerMode: true,
          }).then((res) => {
            return null;
          });
          return null;
        } else if (
          this.state.isProductVerifiedFurn === true &&
          this.state.curTicketDetails.flowId === "Furniture"
        ) {
          swal({
            icon: "error",
            title: "Kindly Check Products Status",
            dangerMode: true,
          }).then((res) => {
            return null;
          });
          return null;
        }
      }

      let resp = await setTicketActions(
        override,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        resultSet
      );
      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ pickStatus: "true" });
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in PickUp>>>>>>doSubmit");
    }
  };

  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };
  replaceString = (str) => {
    return str.replace(/_/g, " ");
  };
  stringReplace(data) {
    return data
      .replace(/{/g, "")
      .replace(/}/g, "")
      .replace(/]/g, "")
      .replace(/"/g, "")
      .replace(/:/g, " : ")
      .replace(/,/g, " , ");
  }
  LatLong(data) {
    let result = JSON.parse(data);
    if (result) {
      return "[ " + result["Latitude"] + " , " + result["Longitude"] + " ]";
    }
  }
  checkIloaded(t) {
    // console.log(JSON.stringify(t.actionCode));
    // if (t.actionCode === "mFrntPhoto") {
    //   alert("confirm Front Photo");
    // }
    // if (t.actionCode === "mBackPhoto") {
    //   alert("confirm Back Photo");
    // }
    // // "actionCode":"mFrntPhoto"
  }

  handleCheckBoxClick = async (e) => {
    try {
      if (e.target.checked) {
        let jobId = this.props.blockDetails.ticketBlockId;

        const res = await getOverrideConfirmPickupStatus(jobId);

        // alert(JSON.stringify(res));

        if (res.data.success === true) {
          toast.success(res.data.msg);
          this.setState({ override: true });
        } else {
          toast.toastError(res.data.msg);
        }
      }
    } catch {
      console.log("Error in handleCheckBoxClick");
    }
  };

  handleCancelTicket = async () => {
    swal({
      title: "Are you sure want to Cancel the Ticket?",
      text: "Kindly Enter the Reason for Ticket Cancellation",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: "input",
      placeholder: "Write something",
    }).then(async (handleCancelTicket) => {
      this.doCancelTicket(handleCancelTicket);
    });
  };
  doCancelTicket = async (handleCancelTicket) => {
    if (handleCancelTicket) {
      // alert(handleRTO)
      swal(
        "Ticket has been successfully Cancelled with the following remarks: " +
          handleCancelTicket,
        {
          icon: "success",
        }
      );
      this.submitCancelTicket(handleCancelTicket);
    } else {
      swal("Ticket not cancelled!", { icon: "error" });
    }
  };
  submitCancelTicket = async (handleCancelTicket) => {
    let resultSet = {
      remarks: handleCancelTicket,
      actionStatus: "OPS_CANCALLATION",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    let data = this.state.data;
    data = {
      actionCode: "wOPSCancelled",
      action: "Ticket Cancelled",
    };
    this.setState({ data });

    let resp = await setTicketActionsTicketCancel(
      this.state.data,
      this.state.curTicketDetails,
      this.state.curBlockDetails,
      resultSet
    );
    if (resp !== undefined) {
      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ cancelStatus: "true" });
          // this.setState({ rtoStatus: "false" });
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
  };

  prodVerification = async (curProductDetails) => {
    curProductDetails.map((r) => {
      if (r.prodQcStatus === "assign" || r.prodQcStatus === "open") {
        this.handleConfirmProductVerification();
      }
    });
  };
  prodVerificationFurniture = async (curProductDetails) => {
    if (curProductDetails) {
      curProductDetails.map((r) => {
        if (r.prodQcStatus === "assign" || r.prodQcStatus === "open") {
          this.handleConfirmProductVerificationFurn();
        } else if (r.prodQcStatus === "No") {
          this.setState({ curAction: "Block" });
          this.setState({ isProductVerifiedFurn: true });
        }
      });
    }
  };
  handleConfirmProductVerification = () => {
    this.setState({ isProductVerified: true });
  };
  handleConfirmProductVerificationFurn = () => {
    this.setState({ isProductVerifiedFurn: true });
  };

  handleNotAttemptProductVerification = () => {
    this.handleTicketListReload();
    this.setState({ isProductVerified: false });
    this.setState({ isProductNotAttempted: "true" });
  };
  handleBlockCreate = () => {
    this.handleTicketListReload();
  };

  onModeChange(mode) {
    this.setState({ mode: mode });
  }

  loadPackingMaterialData = async () => {
    try {
      let blockData = this.props.blockDetails;
      let ticketData = this.props.ticketDetails;
      let res = await getPackingMaterialAndProdByJobIdAdvExc(blockData, ticketData);
      if (res !== undefined) {
        if (res.data.success === true && res.data.data) {
          this.getPackingMaterialAndProdStatus(
            res.data.data.packingMaterialDetails
          );
          this.setState({ confirmData: true });
        } else {
          this.setState({ confirmData: false });
        }
      } else {
        this.setState({ confirmData: false });
      }
    } catch {
      console.log(
        "Error in loadPackingMaterialData>>>>>>>>>>>>>>>>>>>>>>>>>>PickUp"
      );
    }
  };

  isActionButtonEnabled = () => {
    const userInfo = localStorage.getItem("userInfo");
    let user = null;
    if (userInfo && typeof userInfo === "string") {
      user = JSON.parse(userInfo);
    }

    const status =
      user && user["designation"] && user["designation"] === "Operations Head";
    return status;
  };

  getPackingMaterialAndProdStatus = async (data) => {
    console.log(data.productDetails)
    // if (data.length > 0) {
    //   data.map((r) => {
    //     if (r.packMaterialId) {
    //       if (r.packMaterialItemStatus !== null) {
    //         this.setState({ pickupItemsVerified: true });
    //         // this.forceUpdate();
    //       } else {
    //         this.setState({ pickupItemsVerified: false });
    //       }
    //     } else {
    // console.log("this.state.setImages", r.productDetails.length);

    const checkItemStatusNotNull = () => {
      // Iterate through each packingMaterialDetails object
      for (const detail of data) {
        // Iterate through productDetails array in each detail object
        for (const product of detail.productDetails) {
          // Check if itemStatus is null
          if (product.itemStatus === null) {
            return false; // Return false if any itemStatus is null
          }
        }
      }
      return true; // Return true if all itemStatus are not null
    };
    const allItemStatusNotNull = checkItemStatusNotNull();



          if (allItemStatusNotNull === true) {
            this.setState({ pickupItemsVerified: true });
            // this.forceUpdate();
          } else {
            this.setState({ pickupItemsVerified: false });
          }
        // }
    //   });
    // } else {
    //   this.setState({ dropItemsAssigned: true });
    //   this.setState({ confirmData: false });
    // }
  };

  render() {
    // console.log(this.state.confirmData);
    const ticketDetails = this.state.curTicketDetails;
    return (
      <>
        {/* <div className="row  ">
          {(this.state.curAction === "Block" && this.state.curTicketDetails.flowId === "Furniture") && (
            <div className="col-12">
              <button
                onClick={() => this.onModeChange("Enable")}
                className={
                  this.state.mode === "Enable"
                    ? " btn btn-info btn-sm pull-right"
                    : "btn  btn-warning btn-sm pull-right"
                }
              // className="btn btn-light btn-sm pull-right"
              >
                Create Jobs
              </button>
            </div>
          )}
        </div> */}

        <div className="row ">
          {this.state.pageHide === "false" &&
            this.state.pickStatus === "false" &&
            this.state.hideForm === "false" &&
            this.state.cancelStatus === "false" &&
            this.state.isProductNotAttempted === "false" && (
              <>
                <div className="row">
                  {this.state.curAction === "Block" &&
                    this.state.curTicketDetails.flowId === "Furniture" && (
                      <BlockCreation
                        ticketDetails={this.state.curTicketDetails}
                        blockDetails={this.state.curBlockDetails}
                        key={this.state.curBlockDetails.ticketBlockId}
                        handleBlockCreate={this.handleBlockCreate}
                      />
                    )}
                </div>
                {this.state.setImages.length !== 0 && (
                  <div className="col-md-12">
                    <div className="card ">
                      <div className="card-body bg-light">
                        <div className="form-subHeader">
                          <b>{ticketDetails.ticketNo}</b>
                          {this.state.designation === "Operations Head" && (
                            <div
                              className="pull-right"
                              style={{ marginTop: "-1%", marginRight: "23%" }}
                            >
                              {this.rCBHL(
                                "includeCancelTicket",
                                this.includeCancelTicket,
                                {
                                  value: "sortKey",
                                  label: "sort",
                                }
                              )}
                            </div>
                          )}
                          {this.state.data.includeCancelTicket === "yes" && (
                            <div
                              className="pull-right"
                              style={{ marginTop: "1%", marginRight: "-40%" }}
                            >
                              <button
                                title="Cancel Ticket"
                                className="badge badge-danger  pull-right"
                                type="button"
                                onClick={() => this.handleCancelTicket()}
                                style={{
                                  fontSize: "82%",
                                  borderRadius: "2.25rem ",
                                  border: "none",
                                  fontWeight: "bold",
                                  marginRight: "6px",
                                }}
                              >
                                CANCEL TICKET
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="form-subHeader">
                          Before Packing Photos Verification
                        </div>

                        {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode !== "mSignature" && t.actionCode !== "mPhotoId" && t.actionCode !== "mPhotoAftrPackng" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode !== "mSignature" &&
                                  t.actionCode !== "mPhotoId" &&
                                  t.actionCode !== "mPhotoAftrPackng" && (
                                    <div className="col-md-4">
                                      <span>
                                        <a
                                          href="javascript:void(0);"
                                          data-toggle="tooltip"
                                          title={this.stringReplace(
                                            t.geoLocation
                                          )}
                                        >
                                          <img
                                            src={t.imageUrl}
                                            style={{
                                              width: "150px",
                                              height: "150px",
                                              margin: "3px",
                                            }}
                                            onLoad={this.checkIloaded(t)}
                                          />
                                        </a>
                                        <br></br>
                                        <span
                                          className="badge badge-info link-over ml-2"
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            margin: "3px",
                                          }}
                                        >
                                          {t.action}
                                        </span>
                                      </span>
                                      <br></br>
                                      {this.LatLong(t.geoLocation)}
                                      <br></br>
                                      <br></br>
                                    </div>
                                  )}
                              </>
                            );
                          })}
                        </div>

                        {ticketDetails.flowCode !== "PND2W" && (
                          <div className="form-subHeader mt-2">
                            After Packing Photos Verification
                          </div>
                        )}
                        {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode === "mPhotoAftrPackng" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}>
                                    <img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a>
                                    <span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode === "mPhotoAftrPackng" && (
                                  <div className="col-md-4">
                                    <span>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="tooltip"
                                        title={this.stringReplace(
                                          t.geoLocation
                                        )}
                                      >
                                        <img
                                          src={t.imageUrl}
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            margin: "3px",
                                          }}
                                        />
                                      </a>
                                      <br></br>
                                      <span
                                        className="badge badge-warning link-over ml-2"
                                        style={{
                                          width: "auto",
                                          height: "auto",
                                          margin: "3px",
                                        }}
                                      >
                                        {t.action}
                                      </span>
                                    </span>
                                    <br></br>
                                    {this.LatLong(t.geoLocation)}
                                    <br></br>
                                    <br></br>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                        <div className="form-subHeader mt-2">
                          Photo Id Verification
                        </div>
                        {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode === "mPhotoId" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode === "mPhotoId" && (
                                  <div className="col-md-4">
                                    <span>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="tooltip"
                                        title={this.stringReplace(
                                          t.geoLocation
                                        )}
                                      >
                                        <img
                                          src={t.imageUrl}
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            margin: "3px",
                                          }}
                                        />
                                      </a>
                                      <br></br>
                                      <span
                                        className="badge badge-danger link-over ml-2"
                                        style={{
                                          width: "auto",
                                          height: "auto",
                                          margin: "3px",
                                        }}
                                      >
                                        {t.action}
                                      </span>
                                    </span>
                                    <br></br>
                                    {this.LatLong(t.geoLocation)}
                                    <br></br>
                                    <br></br>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                        <div className="form-subHeader mt-2">
                          Signature Verification
                        </div>
                        {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode === "mSignature" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{this.replaceString(t.action)}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode === "mSignature" && (
                                  <div className="col-md-4">
                                    <span>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="tooltip"
                                        title={this.stringReplace(
                                          t.geoLocation
                                        )}
                                      >
                                        <img
                                          src={t.imageUrl}
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            margin: "3px",
                                          }}
                                        />
                                      </a>
                                      <br></br>
                                      <span
                                        className="badge badge-success link-over ml-2"
                                        style={{
                                          width: "auto",
                                          height: "auto",
                                          margin: "3px",
                                        }}
                                      >
                                        {this.replaceString(t.action)}
                                      </span>
                                    </span>
                                    <br></br>
                                    {this.LatLong(t.geoLocation)}
                                    <br></br>
                                    <br></br>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <div className="form-subHeader">
                    <b>{ticketDetails.ticketNo}</b>
                  </div>
                  <div className="card ">
                    <div className="card-body bg-light">
                      <div className="row mb-2">
                        <div className="col-3">
                          <span
                            className={"badge badge-info"}
                            style={{ fontSize: "12px" }}
                          >
                            <b>{ticketDetails.orderNo}</b>
                          </span>
                        </div>
                        <div className="col-3">
                          <span
                            className={"badge badge-info"}
                            style={{ fontSize: "12px" }}
                          >
                            <b>{ticketDetails.ticketNo}</b>
                          </span>
                        </div>
                        <div className="col-3">
                          <span
                            className={"badge badge-info"}
                            style={{ fontSize: "12px" }}
                          >
                            <b>{ticketDetails.flowName}</b>
                          </span>
                        </div>
                        <div className="col-3">
                          <span
                            className={"badge badge-info"}
                            style={{ fontSize: "12px" }}
                          >
                            <b>{ticketDetails.retailerName}</b>
                          </span>
                        </div>
                        {(this.state.curTicketDetails.flowId === "Bulk" ||
                          this.state.curTicketDetails.flowId === "eWaste") && (
                          // this.state.curTicketDetails.flowId === "DropOff"||
                          // this.state.curTicketDetails.flowId === "PickAndDropOneWay"
                          <BulkQCProcess
                            ticketDetails={this.state.curTicketDetails}
                            blockDetails={this.state.curBlockDetails}
                            productDetails={this.state.curProductDetails}
                            key={
                              "bt_" + this.state.curBlockDetails.ticketBlockId
                            }
                            handleNotAttemptProductVerification={
                              this.handleNotAttemptProductVerification
                            }
                          />
                        )}
                        {this.state.curTicketDetails.flowId === "Furniture" && (
                          <FurnitureFlowProcess
                            ticketDetails={this.state.curTicketDetails}
                            blockDetails={this.state.curBlockDetails}
                            productDetails={this.state.curBlockDetails.products}
                            key={
                              "bt_" + this.state.curBlockDetails.ticketBlockId
                            }
                            handleNotAttemptProductVerification={
                              this.handleNotAttemptProductVerification
                            }
                          />
                        )}
                      </div>
                      <form onSubmit={this.handleSubmit}>
                        <PickUpVerificationProduct
                          productDetails={this.state.curProductDetails}
                          ticketDetails={this.state.curTicketDetails}
                          blockDetails={this.state.curBlockDetails}
                          key={"p_" + this.state.curBlockDetails.ticketBlockId}
                          onHandleGetConfirmStatus={
                            this.loadPackingMaterialData
                          }
                        />
                        <PickUpVerificationBlock
                          productDetails={this.state.curProductDetails}
                          ticketDetails={this.state.curTicketDetails}
                          blockDetails={this.state.curBlockDetails}
                          key={"b_" + this.state.curBlockDetails.ticketBlockId}
                        />
                        <PickUpVerificationTicket
                          productDetails={this.state.curProductDetails}
                          ticketDetails={this.state.curTicketDetails}
                          blockDetails={this.state.curBlockDetails}
                          key={"t_" + this.state.curBlockDetails.ticketBlockId}
                        />
                        {((this.state.pickupItemsVerified === true ||
                          this.state.confirmData === false) && (this.props.ticketDetails.flowId !== "AdvanceExchange")) && (
                          <>
                            {this.rBtnSP(
                              "Confirm Pickup",
                              "Confirm",
                              "pull-right mt-2"
                            )}
                          </>
                        )}
                         {((this.state.pickupItemsVerified === true ||
                          this.state.confirmData === false) && (this.props.ticketDetails.flowId === "AdvanceExchange")) && (
                          <>
                            {this.rBtnSP(
                              "Confirm Pickup",
                              "Confirm",
                              "pull-right mt-2"
                            )}
                          </>
                        )}

                        {/* {this.rCBHL(
                          "overridePickupConfirm",
                          this.overridePickupConfirm,
                          {
                            value: "sortKey",
                            label: "sort",
                          }
                        )} */}
                        {this.isActionButtonEnabled() && (
                          <div>
                            <label> Override Pickup Confirm </label>
                            <input
                              type="checkbox"
                              name="checkBox"
                              value="checkBox"
                              title="Override Confirm Pickup"
                              onClick={(e) => this.handleCheckBoxClick(e)}
                            />

                            {this.state.override !== false && (
                              <div>
                                {this.rBtnSP(
                                  "Confirm Pickup",
                                  "Confirm",
                                  "pull-right mt-2"
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
        {this.state.pageHide === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Kindly Complete the Previous Process
            </p>
          </div>
        )}
        {this.state.pickStatus === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Ticket No: <b>{ticketDetails.ticketNo}</b> PickUp Verification
              Process Completed Successfully
            </p>
          </div>
        )}
        {this.state.isProductNotAttempted === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Ticket No: <b>{ticketDetails.ticketNo}</b> Product has been
              Successfully Updated.
            </p>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PickUp);
