import React from "react";
// import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
// import toast from "../../../services/_toastService";
import toast from "../../../../services/_toastService";
import FormLib from "../../../_common/FormLib";
import { getTeams } from "../../../../services/players/teamServices";
import { getPackingMaterials } from "../../../../services/packing/PackingMaterialService";
import { createBulkBarcode } from "../../../../services/packing/BarcodeService";
import { createBarcodesV1 } from "../../../../services/stockManagement/barcodeServices";
// import { createBulkBarcode } from "../../../services/packing/BarcodeService";
// import { getHubsAll } from "../../../services/masterLocations/hubServices";
// import { getTeams } from "../../../services/players/teamServices";
// import {
//   getPackingMaterials,
// } from "./../../../services/packing/PackingMaterialService";
import swal from "sweetalert";

class ModalBarcodeSE extends FormLib {
  state = {
    data: {
      // packMaterialId: "",
      prefix: "",
      barcodeType: "sticker",
      barcodeStart: "",
      barcodeNumbers: "",
      status: "00available",
      hubId: "5067",
      teamId: "biz1640610075280TZ17be03b3e6bd4e2f96e8fb01662b84bd",
    },
    hubs: [],
    teams: [],
    curMaterial: "",
    errors: {},
    curBarcode: {},
    status: [
      {
        statusId: "00available",
        status: "available",
      },
      // {
      //   statusId: "10issued",
      //   status: "issued",
      // },
      {
        statusId: "20used",
        status: "used",
      },
      // {
      //   statusId: "30received",
      //   status: "received",
      // },
      // {
      //   statusId: "40damaged",
      //   status: "damaged",
      // },
      // {
      //   statusId: "50scraped",
      //   status: "scraped",
      // },
    ],
  };

  schema = {
    // packMaterialId: Joi.optional().label("packMaterialId"),
    // prefix: Joi.string().optional().regex(/^[A-Za-z]*$/).label("prefix"),
    prefix: Joi.optional().label("prefix"),
    hubId: Joi.string().required().label("hubId"),
    teamId: Joi.optional().label("teamId"),
    barcodeType: Joi.string().required().label("barcodeType"),
    barcodeStart: Joi.required().label("barcodeStart"),
    barcodeNumbers: Joi.required().label("barcodeNumbers"),
    status: Joi.string().required().label("status"),
  };

  componentDidMount() {
    // this.loadPackingMaterial();
    this.loadHubs();
    this.loadTeams();
    this.setCurMaterialHub(this.props.curBarcode, this.props.curHubId);
  }
  async loadHubs() {
    let hubList = JSON.parse(localStorage.getItem("hubs"));
    this.setState({ hubList });
    let curHub = localStorage.getItem("curHub");
    if (!curHub) {
      let override = this.state.data;
      override["hubId"] = JSON.parse(JSON.stringify(hubList[0].hubId));
      this.setState({ override });
    } else {
      let override = this.state.data;
      override["hubId"] = curHub;
      this.setState({ override });
    }
  }

  async loadTeams() {
    this.setState({ loading: true });
    const res = await getTeams();
    if (res !== undefined) {
      if (res.data.success === true) {
        this.setState({ teams: res.data.data.teams });
        this.state.data.teamId = res.data.data.teams[8]["teamId"];
        this.setState({ curTeam: res.data.data.teams[8] });
        this.setState({
          data: {
            hubId: this.state.data.hubId,
            teamId: res.data.data.teams[8]["teamId"],
            status: this.state.data.status,
            // packMaterialId: this.state.data.packMaterialId,
            barcodeType: "sticker",
          },
        });
      } else {
        toast.toastError(res.data.msg);
      }
    }
    this.setState({ loading: false });
  }
  // async loadPackingMaterial() {
  //   this.setState({ loading: true });
  //   const res = await getPackingMaterials();
  //   if (res !== undefined) {
  //     if (res.data.success === true) {
  //       this.setState({ packingmaterials: res.data.data.packingMaterials });

  //       this.state.data.packMaterialId = res.data.data.packingMaterials[4]['packMaterialId'];

  //       this.setState({ curPack: res.data.data.packingMaterials[4] });

  //       this.setState({
  //         data: {
  //           packMaterialId: res.data.data.packingMaterials[4]['packMaterialId'],
  //           hubId: this.state.data.hubId,
  //           teamId: this.state.data.teamId,
  //           status: this.state.data.status,
  //           barcodeType: "prePrint",

  //         }
  //       });
  //     } else {
  //       toast.toastError("failed");
  //     }
  //   }
  //   this.setState({ loading: false });
  // }
  setCurMaterialHub = (p, h) => {
    const data = { ...this.state.data };
    // if (p.packMaterialId !== "") {
    //   data.packMaterialId = p.packMaterialId;
    // }
    if (p.hubId !== "") {
      data.hubId = p.hubId;
      data.teamId = p.teamId;
    }
    this.setState({ data });
  };
  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      // res = await createBulkBarcode(this.state.data);
      if (!this.state.data.prefix) {
        const data = this.state.data;
        data.prefix = "";
        this.setState({ data });
      }
      const params = {
        clientId: this.state.data.teamId,
        hubId: this.state.data.hubId,
        barcodeType: this.state.data.barcodeType,
        prefix: this.state.data.prefix,
        serialStartNumber: parseInt(this.state.data.barcodeStart),
        serialEndNumber: parseInt(this.state.data.barcodeNumbers),
        status: this.state.data.status,
      };
      if (this.state.data.barcodeStart > this.state.data.barcodeNumbers) {
        swal({
          icon: "error",
          title: "Start number should be less than End number",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null;
      }

      if (
        this.state.data.barcodeNumbers - this.state.data.barcodeStart >
        1000
      ) {
        swal({
          icon: "error",
          title: "Enter count below 1000 numbers",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null;
      };
      // if(this.state.data.barcodeStart)

      res = await createBarcodesV1(params);
      if (res.data.success) {
        this.resetData();
        // this.setState({teamId:"biz1640610075280TZ17be03b3e6bd4e2f96e8fb01662b84bd"})
        // this.setState({hubId:"5067"})
      }
    }
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.props.onloadBarcodes();
    } else {
      // toast.toastError(res.data.msg);
    }
  }

  resetData() {
    this.setState({
      data: {
        // packMaterialId: "",
        barcodeType: "sticker",
        barcodeStart: "",
        barcodeNumbers: "",
        status: "00available",
        hubId:"5067",
        teamId:this.state.data.teamId
      },
      // hubs: [],
      // teams: [],
    });
  }

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  barcodeType = [
    // { sortKey: "prePrint", sort: "prePrint" },
    { sortKey: "sticker", sort: "sticker" },
  ];
  barConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // console.log({hggfhf:this.state.data.teamId});
    return (
      <div className="container-fluid">
        <div className="modal fade" id="myBarcodeModalSE">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Barcode Serial Start End</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  <div className="form-row">
                    <div className="col-md-3">
                      {this.rRBL(
                        "Barcode Type",
                        "barcodeType",
                        this.barcodeType,
                        this.barConf
                      )}
                    </div>

                    <div className="col-md-3 ">
                      {this.rSB("Hub", "hubId", this.state.hubList, {
                        value: "hubId",
                        label: "hub",
                      })}
                      {/* {this.rTB("Hub", "hubId", "")} */}
                    </div>

                    <div className="col-md-3 ">
                      {this.rSB("Team", "teamId", this.state.teams, {
                        value: "teamId",
                        label: "name",
                      })}
                    </div>

                    {/* <div className="col-md-2">
                      {this.rSBD(
                        "Status *",
                        "status",
                        this.state.status,
                        { value: "statusId", label: "status" },

                      )}
                    </div> */}
                  </div>

                  <div className="form-row">
                    {/* <div className="col-md-3">
                      {this.rSB(
                        "Packing Material",
                        "packMaterialId",
                        this.state.packingmaterials,
                        {
                          value: "packMaterialId",
                          label: "name",
                        }
                      )}
                    </div> */}

                    {this.rTB("Barcode Prefix", "prefix", "col-3")}
                    {this.rNB("Barcode Start", "barcodeStart", "col-3")}
                    {this.rNB("Barcode End", "barcodeNumbers", "col-3")}
                    {/* </div> */}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {this.rBtnSP("Create", "create")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalBarcodeSE;
