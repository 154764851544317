import React from "react";
import swal from "sweetalert";
import FormLib from "../../_common/FormLib";

class CostCenterList extends FormLib {

    willDelete() {
        swal({
          title: "Are you sure want to Delete?",
          text: "Once deleted, you will not be able to recover this CostCenter",
          icon: "error",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            swal("Poof! Your CostCenter file has been deleted!", {
              icon: "success",
            });
          } else {
            swal("Your CostCenter file is safe!");
          }
        });
      }



  render() {
    return (
      <React.Fragment>

<div className="container page-todo bootstrap snippets bootdeys">
  <div className="card">
    <div className="card-body">
              <div className="task high">
                <div className="row m-1">
                  CostCenter Name
                  <span className="costcenter_cardview bg-danger"></span>
                        <div className="btn-group pull-right">
                          <button
                          type="button"
                          className="btn dropdown-icon"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          >
                          <i className="fa fa-ellipsis-v"></i>
                          </button>
                
                          <div className="dropdown-menu dropdown-menu-right">
                            <button className="dropdown-item" type="button">
                                <i className="fa fa-pencil" aria-hidden="true"></i>{" "}
                                  Edit
                            </button>
                            <button
                                onClick={this.willDelete}
                                className="dropdown-item"
                                type="button"
                            >
                            <i className="fa fa-trash" aria-hidden="true"></i>{" "}
                                Delete
                            </button>
                          </div>
                        </div>
                   
                </div>    
		          </div>
          </div>
          <div className="task-list">
            <div className="task high">
			     
                <div className="row m-1">
                  CostCenter Name
                  <span className="costcenter_cardview bg-primary"></span>

                  <div className="row pull-right">
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn dropdown-icon "
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-ellipsis-v"></i>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right">
                      <button className="dropdown-item" type="button">
                        <i className="fa fa-pencil" aria-hidden="true"></i>{" "}
                          Edit
                      </button>
                      <button
                        onClick={this.willDelete}
                        className="dropdown-item"
                        type="button"
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>{" "}
                          Delete
                      </button>
                    </div>
                  </div>
                </div>

                </div>
                
                
		        </div>
          </div>
      
          <div className="task-list">
		        <div className="task medium">
           
                <div className="row m-1">
                  CostCenter Name
                  <span className="costcenter_cardview bg-success"></span>

                  <div className="row pull-right">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn dropdown-icon "
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fa fa-ellipsis-v"></i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <button className="dropdown-item" type="button">
                          <i className="fa fa-pencil" aria-hidden="true"></i>{" "}
                            Edit
                        </button>
                        <button
                          onClick={this.willDelete}
                          className="dropdown-item"
                          type="button"
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>{" "}
                            Delete
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
                  

          	</div>
          </div>
          <div className="task-list">
            <div className="task medium">
            <div className="row m-1">
                  CostCenter Name
                  <span className="costcenter_cardview bg-warning"></span>

                  <div className="row pull-right">
                    <div className="btn-group">
                      <button
                        type="button"
                        className="btn dropdown-icon "
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="fa fa-ellipsis-v"></i>
                      </button>
                      <div className="dropdown-menu dropdown-menu-right">
                        <button className="dropdown-item" type="button">
                          <i className="fa fa-pencil" aria-hidden="true"></i>{" "}
                            Edit
                        </button>
                        <button
                          onClick={this.willDelete}
                          className="dropdown-item"
                          type="button"
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>{" "}
                            Delete
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
                 

            </div>	
	  </div>	
  </div> 
</div>
    

          
      </React.Fragment>
    );
  }
}
export default CostCenterList;
