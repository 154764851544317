import React from "react";
import ZTitle from "./../../_subhead/ZTitle";
import {
  getHubs,
  getHubsAll,
} from "./../../../services/masterLocations/hubServices";
import toast from "../../../services/_toastService";
import FormLib from "../../_common/FormLib";
import ExportXLSXReqTrans from "./../requestV1/ExcelDownload";
import {
  getRegions,
  getZones,
} from "./../../../services/masterLocations/locationServices";
import Joi from "joi-browser";
import { getMISDetails } from "../../../services/Tickets/TicketServices";
import { getMISDetailsNew } from "../../../services/Tickets/TicketServices";
import Table from "./../../_common/Table";
import { dropMenuFull } from "./../../../utils/DropMenuUtils";
import { getTeams } from "./../../../services/players/teamServices";
import { ExportXLSXMIS } from "./../requestV1/ExportXLSX";
import {
  epoch2ddmmyyyyhhmmss,
  epoch2hhmmss,
  epoch2ddmmyyyy,
  epoch2ddmmyy,
  tDate2yyyymmddhhmmss,
  epoch2convertddmmyyyyhhmmss,
  IstToUTC,
} from "../../../utils/DateUtils";

import dateFormat from "dateformat";
import { getRetailer } from "./../../../services/masterLocations/retailerServices";
import { getRetailerInfo } from "../../../services/TicketHistory/TicketHistory";
import config from "../../../config.json";
import swal from "sweetalert";

import {
  format,
  previousMonday,
  nextSunday,
  sub,
  endOfMonth,
  startOfMonth,
  isMonday,
  isSunday,
  previousSunday,
  isSaturday,
  nextSaturday,
} from "date-fns";

const apiEndpoint = config[config.build].trackTraceURL;

class MisNewReport extends FormLib {
  state = {
    data: {
      startDate: this.getDatebyDays(0),
      endDate: this.getDatebyDays(0),
      hubId: [],
      retailerId: [],
      searchString: "",
      ticketStatus: "PROCESSING",
      includeDstHub: "no",
      includeBeforeShipment: "no",
      status: "",
      ticketScope: "ticket",

      // region: "",
      // zone: "",
    },
    loaderCount: 0,
    loaderCountCur: 0,
    userCode: "",
    userInfoTeamId: "",
    errors: {},
    regions: [],
    zones: [],
    hubs: [],
    clients: [],
    report: [],
    csvReport: {
      data: [],
      headers: [],
    },
    MisTempArray: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    excelFormatted: [],
    removeExcelColumn: [],
    mapBlockStatus: {},
    fileName: "",
  };
  ticketScope = [
    { sortKey: "ticket", sort: "TicketNo." },
    { sortKey: "conComplaintNo", sort: "ConComplaintNo." },
    { sortKey: "LRNumber", sort: "LRNo." },
    { sortKey: "invoiceNo", sort: "InvoiceNo." },
    { sortKey: "orderNo", sort: "OrderNo." },
  ];
  schema = {
    startDate: Joi.string().required().label("Start Date"),
    endDate: Joi.string().required().label("End Date"),
    hubId: Joi.optional().label("Hub"),
    retailerId: Joi.optional().label("retailerId"),
    searchString: Joi.optional().label("searchString"),
    ticketStatus: Joi.string().optional().label("ticketStatus"),
    status: Joi.optional().label("status"),
    includeDstHub: Joi.optional().label("includeDstHub"),
    includeBeforeShipment: Joi.optional().label("includeBeforeShipment"),
    ticketScope: Joi.optional().label("ticketScope"),
    // region: Joi.optional().label("Region"),
    // zone: Joi.optional().label("Zone"),
  };

  ticketStatus = [
    { sortKey: "All", sort: "All (Excluding OPS Cancel)" },
    { sortKey: "CANCEL", sort: "OPS Cancelled" },
    { sortKey: "CLOSED", sort: "Closed" },
    { sortKey: "PROCESSING", sort: "In Process" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  status = [
    { sortKey: this.getDatebyDays(0), sort: "Today" },
    { sortKey: this.getDatebyWeeks(0), sort: "Past_Week" },
    { sortKey: this.getDatebyMonths(0), sort: "Past_Month" },
    { sortKey: "Custom", sort: "Custom" },
  ];

  //  = [{ label: "Includes Destination Hubs", value: "yes" }];
  includeDstHub = [{ sortKey: "yes", sort: "Include_Incoming_Tickets" }];

  includeBeforeShipment = [{ sortKey: "yes", sort: "Include_Pre_Linehaul" }];

  replaceString = (str) => {
    if (str) {
      str = str.replace(/_/g, " ");
      return str.replace("BLOCK", "JOB");
    }
  };

  // replaceBlockSatus = (str, closedStatus, prodQcStatus, flowStatus) => {

  //   try {
  //     if (!str) return;
  //     if (prodQcStatus) {
  //       if (prodQcStatus === "No") {
  //         str = "QC_FAILED";
  //         return str;
  //       } else if (prodQcStatus === "Yes") {
  //         str = "QC_PASS";
  //         return str;
  //       } else if (prodQcStatus === "DeviceNotAvailable") {
  //         str = "DEVICE_NOT_AVAILABLE";
  //         return str;
  //       } else if (prodQcStatus === "NOT ATTEMPTED") {
  //         str = "PRODUCT_NOT_ATTEMPTED";
  //         return str;
  //       } else if (prodQcStatus === "assign") {
  //         str = "PRODUCT_EVAL_PENDING";
  //         return str;
  //       }
  //     }
  //     if (
  //       str.includes("BLOCK_CLOSED") &&
  //       this.state.selectedTeamId === "6f9d70b6-f7c4-4c84-969e-0a17227beec6"
  //     ) {
  //       if (
  //         closedStatus &&
  //         (closedStatus.includes("Ticket Closed Successfully") ||
  //           closedStatus.includes("RTO DONE"))
  //       ) {
  //         str = "PRODUCT_PICKUP_HAS_BEEN_COMPLETED";
  //         return str;
  //       }
  //     }
  //     if (
  //       str.includes("IDLE_UNTIL_") &&
  //       this.state.selectedTeamId !== "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2"
  //     ) {
  //       str = "RESCHEDULED_BY_CUSTOMER";
  //       return str;
  //     } else if (
  //       str.includes("IDLE_UNTIL_") &&
  //       this.state.selectedTeamId === "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2" &&
  //       flowStatus === "PICK_WEB"
  //     ) {
  //       str = "PICKUP_PENDING";
  //       console.log("called");
  //       return str;
  //     } else if (
  //       str.includes("IDLE_UNTIL_") &&
  //       this.state.selectedTeamId === "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2" &&
  //       flowStatus === "DROP_WEB"
  //     ) {
  //       str = "DROP_PENDING";
  //       return str;
  //     } else if (
  //       str.includes("BLOCK_CLOSED") &&
  //       this.state.selectedTeamId === "24a36528-0749-4cd9-a6e9-cf8fec19d04b"
  //     ) {
  //       if (
  //         closedStatus &&
  //         (closedStatus.includes("Ticket Closed Successfully") ||
  //           closedStatus.includes("RTO DONE"))
  //       ) {
  //         str = "COMPLETED_SUCCESSFULLY";
  //         return str;
  //       }

  //       else if (closedStatus && closedStatus.includes("QC FAIL")) {
  //         str = "QC_FAILED";
  //         return str;
  //       } else {
  //         str = "CANCELLED_";
  //         return str;
  //       }
  //     } else if (
  //       str.includes("BLOCK_CLOSED") &&
  //       this.state.selectedTeamId !== "24a36528-0749-4cd9-a6e9-cf8fec19d04b"
  //     ) {
  //       if (
  //         closedStatus &&
  //         (closedStatus.includes("Ticket Closed Successfully") ||
  //           closedStatus.includes("RTO DONE")) &&
  //         this.state.selectedTeamId !== "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2"
  //       ) {
  //         str = "COMPLETED_SUCCESSFULLY";
  //         return str;
  //       } else if (
  //         closedStatus &&
  //         (closedStatus.includes("Ticket Closed Successfully") ||
  //           closedStatus.includes("RTO DONE")) &&
  //         this.state.selectedTeamId === "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2"
  //       ) {
  //         str = "CLOSED";
  //         return str;
  //       } else if (closedStatus && closedStatus.includes("Lost")) {
  //         str = "PRODUCT_LOST";
  //         return str;
  //       } else if (closedStatus && closedStatus.includes("Damaged")) {
  //         str = "PRODUCT_DAMAGED";
  //         return str;
  //       } else if (closedStatus && closedStatus.includes("QC FAIL")) {
  //         str = "QC_FAILED";
  //         return str;
  //       } else {
  //         str = "CANCELLED_";
  //         return str;
  //       }
  //     } else {
  //       return str;
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     console.log("Error in MISNewReport>>>>>> replaceBlockSatus");
  //   }
  // };

  replaceBlockSatus = (str, closedStatus, prodQcStatus, flowStatus) => {
    // console.log("calling");
    // console.log("  ............flowStatus" + flowStatus);

    try {
      if (!str) return;
      // if (prodQcStatus) {
      //   if (prodQcStatus === "No") {
      //     str = "QC_FAILED";
      //     return str;
      //   } else if (prodQcStatus === "Yes") {
      //     str = "QC_PASS";
      //     return str;
      //   } else if (prodQcStatus === "DeviceNotAvailable") {
      //     str = "DEVICE_NOT_AVAILABLE";
      //     return str;
      //   } else if (prodQcStatus === "NOT ATTEMPTED") {
      //     str = "PRODUCT_NOT_ATTEMPTED";
      //     return str;
      //   } else if (prodQcStatus === "assign") {
      //     str = "PRODUCT_EVAL_PENDING";
      //     return str;
      //   }
      // }
      if (
        str.includes("BLOCK_CLOSED") &&
        this.state.selectedTeamId === "6f9d70b6-f7c4-4c84-969e-0a17227beec6"
      ) {
        if (
          closedStatus &&
          (closedStatus.includes("Ticket Closed Successfully") ||
            closedStatus.includes("RTO DONE"))
        ) {
          str = "PRODUCT_PICKUP_HAS_BEEN_COMPLETED";
          return str;
        }
      }
      if (
        str.includes("IDLE_UNTIL_") &&
        this.state.selectedTeamId !== "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2"
      ) {
        str = "RESCHEDULED_BY_CUSTOMER";
        return str;
      } else if (
        str.includes("IDLE_UNTIL_") &&
        this.state.selectedTeamId === "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2" &&
        flowStatus === "PICK_WEB"
      ) {
        str = "PICKUP_PENDING";
        // console.log("called");
        return str;
      } else if (
        str.includes("IDLE_UNTIL_") &&
        this.state.selectedTeamId === "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2" &&
        flowStatus === "DROP_WEB"
      ) {
        str = "DROP_PENDING";
        return str;
      } else if (
        str.includes("BLOCK_CLOSED") &&
        this.state.selectedTeamId === "24a36528-0749-4cd9-a6e9-cf8fec19d04b"
      ) {
        if (
          closedStatus &&
          (closedStatus.includes("Ticket Closed Successfully") ||
            closedStatus.includes("RTO DONE"))
        ) {
          str = "COMPLETED_SUCCESSFULLY";
          return str;
        }
        // else if (closedStatus && closedStatus.includes("Lost")) {
        //     str = "PRODUCT_LOST";
        //     return str;

        // }
        // else if (closedStatus && closedStatus.includes("Damaged")) {
        //     str = "PRODUCT_DAMAGED";
        //     return str;

        // }
        else if (closedStatus && closedStatus.includes("QC FAIL")) {
          str = "QC_FAILED";
          return str;
        } else {
          str = "CANCELLED_";
          return str;
        }
      } else if (
        str.includes("BLOCK_CLOSED") &&
        this.state.selectedTeamId !== "24a36528-0749-4cd9-a6e9-cf8fec19d04b"
      ) {
        if (
          closedStatus &&
          (closedStatus.includes("Ticket Closed Successfully") ||
            closedStatus.includes("RTO DONE")) &&
          this.state.selectedTeamId !== "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2"
        ) {
          str = "COMPLETED_SUCCESSFULLY";
          return str;
        } else if (
          closedStatus &&
          (closedStatus.includes("Ticket Closed Successfully") ||
            closedStatus.includes("RTO DONE")) &&
          this.state.selectedTeamId === "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2"
        ) {
          str = "CLOSED";
          return str;
        } else if (
          (closedStatus && closedStatus.includes("Lost in Hub")) ||
          closedStatus.includes("Lost in Linehaul")
        ) {
          str = "PRODUCT_LOST";
          return str;
        } else if (closedStatus && closedStatus.includes("Damaged")) {
          str = "PRODUCT_DAMAGED";
          return str;
        } else if (closedStatus && closedStatus.includes("QC FAIL")) {
          str = "QC_FAILED";
          return str;
        } else if (
          (closedStatus && closedStatus.includes("Pickup and Drop Done")) ||
          closedStatus.includes("Installation Done") ||
          closedStatus.includes("Service Done") ||
          closedStatus.includes("QC Done")
        ) {
          str = "COMPLETED_SUCCESSFULLY";
          return str;
        } else if (
          (closedStatus &&
            closedStatus.includes("Customer Rejected At Drop Time")) ||
          closedStatus.includes("Customer Rejected At Pickup Time")
        ) {
          str = "REJECTED";
          return str;
        } else {
          str = "CANCELLED_";
          return str;
        }
      } else {
        return str;
      }
    } catch (error) {
      console.error(error);
      console.log("Error in MISNewReport>>>>>> replaceBlockSatus");
    }
  };

  mapBlockStatus = (str, closedStatus, prodQcStatus, flowStatus) => {
    try {
      let status = this.replaceBlockSatus(
        str,
        closedStatus,
        prodQcStatus,
        flowStatus
      );
      if (this.state.mapBlockStatus[status]) {
        return this.replaceString(this.state.mapBlockStatus[status]);
      }
      return this.replaceString(status);
    } catch {
      console.log("Error in MISNewReport>>>>>> mapBlockStatus");
    }
  };

  inventoryData = (data, inventoryStage) => {
    try {
      if (!data) return;
      if (inventoryStage === "blockInventory") {
        let jobInventory = [];
        let blockInventory = JSON.parse(data);
        if (JSON.stringify(blockInventory.jobInventory)) {
          blockInventory.jobInventory.map((inv) => {
            let newForm = inv.item + ":" + inv.qnt;
            let stockItem = {
              item: inv.item,
              quantity: inv.qnt,
              serial: inv.serial,
            };
            jobInventory.push(newForm);
          });
        }

        // console.log(jobInventory.toString());
        return jobInventory.toString();
      } else if (inventoryStage === "blockInventoryFe") {
        // const blockInventoryFe = JSON.parse(data);
        // return JSON.stringify(blockInventoryFe.jobFeInventory);

        let jobFeInventory = [];
        let blockInventoryFe = JSON.parse(data);
        if (JSON.stringify(blockInventoryFe.jobFeInventory)) {
          blockInventoryFe.jobFeInventory.map((inv) => {
            let newForm = inv.item + ":" + inv.qnt;
            let stockItem = {
              item: inv.item,
              quantity: inv.qnt,
              serial: inv.serial,
            };
            jobFeInventory.push(newForm);
          });
        }
        return jobFeInventory.toString();
      } else if (inventoryStage === "blockInventoryVerified") {
        // const blockInventoryVerified = JSON.parse(data);
        // return JSON.stringify(blockInventoryVerified.jobVerifiedInventory);
        let jobVerifiedInventory = [];
        let blockInventoryVerified = JSON.parse(data);
        if (JSON.stringify(blockInventoryVerified.jobVerifiedInventory)) {
          blockInventoryVerified.jobVerifiedInventory.map((inv) => {
            let newForm = inv.item + ":" + inv.verifiedQnt;
            let stockItem = {
              item: inv.item,
              quantity: inv.verifiedQnt,
              serial: inv.serial,
            };
            jobVerifiedInventory.push(newForm);
          });
        }
        return jobVerifiedInventory.toString();
      } else if (inventoryStage === "blockInventoryReturns") {
        // const blockInventoryReturns = JSON.parse(data);
        // return JSON.stringify(blockInventoryReturns.jobOldInventory);

        let jobOldInventory = [];
        let blockInventoryReturns = JSON.parse(data);
        if (JSON.stringify(blockInventoryReturns.jobOldInventory)) {
          blockInventoryReturns.jobOldInventory.map((inv) => {
            let newForm = inv.item + ":" + inv.qnt;

            let stockItem = {
              item: inv.item,
              quantity: inv.qnt,
              serial: inv.serial,
            };
            jobOldInventory.push(newForm);
          });
        }
        return jobOldInventory.toString();
      }
    } catch (error) {
      console.error(error);
      console.log("MISNewReport>>>>>> inventoryData");
    }
  };
  productCusField = {
    key: "productCusField",
    action: (r) => {
      try {
        let prod = JSON.parse(r["productCusField"]);
        if (prod && prod.customeFields) {
          let tdRows = prod.customeFields.map((i) => {
            return (
              <>
                <>
                  <td style={{ color: "green" }}>
                    <>{i["question"]}</>
                  </td>{" "}
                </>
                <td>{i["answer"]}</td>
              </>
            );
          });
          return tdRows;
        } else {
          return <b>{"No"}</b>;
        }
      } catch {
        console.log("Error in MISReport>>>>>>>>>>productCusField");
      }
    },
    icon: () => <>Basic Eval</>,
  };
  physicalEvlResult = {
    key: "physicalEvlResult",
    action: (r) => {
      try {
        if (
          this.state.selectedTeamId ===
            "epr1677250608046TZ066144d99e2648b0a764604ed0ba170a" ||
          this.state.selectedTeamId ===
            "epr1677250861052TZe901710ce0984e34bf39c5340606ccc1"
        ) {
          let prod = JSON.parse(r["physicalEvlResult"]);
          if (prod && prod.customeFields) {
            let tdRows = prod.customeFields.map((i) => {
              return (
                <>
                  <>
                    <td style={{ color: "green" }}>
                      <>{i["question"]}</>
                    </td>{" "}
                  </>
                  <td>{i["answer"]}</td>
                </>
              );
            });
            return tdRows;
          } else {
            return <b>{"No"}</b>;
          }
        } else {
          return r["physicalEvlResult"];
        }
      } catch {
        console.log("Error in MISReport>>>>>>>>>>physicalEvlResult");
      }
    },
    icon: () => <>Physical Eval</>,
  };
  technicalEvlResult = {
    key: "technicalEvlResult",
    action: (r) => {
      try {
        if (
          this.state.selectedTeamId ===
            "epr1677250608046TZ066144d99e2648b0a764604ed0ba170a" ||
          this.state.selectedTeamId ===
            "epr1677250861052TZe901710ce0984e34bf39c5340606ccc1"
        ) {
          let prod = JSON.parse(r["technicalEvlResult"]);
          if (prod && prod.customeFields) {
            let tdRows = prod.customeFields.map((i) => {
              return (
                <>
                  <>
                    <td style={{ color: "green" }}>
                      <>{i["question"]}</>
                    </td>{" "}
                  </>
                  <td>{i["answer"]}</td>
                </>
              );
            });
            return tdRows;
          } else {
            return <b>{"No"}</b>;
          }
        } else {
          return r["technicalEvlResult"];
        }
      } catch {
        console.log("Error in MISReport>>>>>>>>>>technicalEvlResult");
      }
    },
    icon: () => <>Technical Eval</>,
  };

  boxData = (data) => {
    try {
      let boxs = [];
      let boxStatus;
      let boxDatas = JSON.parse(data);
      if (boxDatas) {
        boxDatas.map((box) => {
          if (box.boxStatus === "receivedInGoodCondition") {
            boxStatus = "YES";
          } else if (box.boxStatus === "missing") {
            boxStatus = "MISSING";
          } else if (box.boxStatus === "damaged") {
            boxStatus = "DAMAGED";
          }
          let newForm = box.boxNames + ":" + boxStatus;
          let stockItem = {
            item: box.item,
            quantity: box.qnt,
            serial: box.serial,
          };
          boxs.push(newForm);
        });

        return boxs.toString();
      }
    } catch {
      console.log("MISNewReport>>>>>> boxData");
    }
  };

  srcServicability = (data) => {
    try {
      let servicabilityStatus;
      let servicabilityDatas = data;
      if (servicabilityDatas) {
        if (servicabilityDatas === "DA") {
          servicabilityStatus = "Serviceable";
        } else if (servicabilityDatas === "NDA") {
          servicabilityStatus = "NSA";
        } else if (servicabilityDatas === "ODA") {
          servicabilityStatus = "ODA";
        }

        return servicabilityStatus;
      }
    } catch {
      console.log("MISNewReport>>>>>> boxData");
    }
  };
  productCusData = (data) => {
    try {
      if (
        this.state.selectedTeamId ===
          "epr1677250608046TZ066144d99e2648b0a764604ed0ba170a" ||
        this.state.selectedTeamId ===
          "epr1677250861052TZe901710ce0984e34bf39c5340606ccc1"
      ) {
        let prods = [];
        let productCusDatas = JSON.parse(data);
        console.log(productCusDatas);
        if (productCusDatas) {
          productCusDatas.customeFields.map((prod) => {
            let newForm = prod.question + " : " + prod.answer;
            prods.push(newForm);
          });

          return prods.toString();
        }
      } else {
        return data;
      }
    } catch {
      console.log("MISNewReport>>>>>> productCusData");
    }
  };
  accessoriesData = (data) => {
    try {
      let accs = [];
      let accessoriesDatas = JSON.parse(data);
      if (accessoriesDatas) {
        accessoriesDatas.accessories.map((acc) => {
          let newForm = acc.label + " : " + acc.accessories.toString();
          accs.push(newForm);
        });

        return accs.toString();
      }
    } catch {
      console.log("MISNewReport>>>>>> productCusData");
    }
  };
  productDetailsData = (data) => {
    try {
      let pdDetails = data?.productDetails
        ? JSON.parse(data.productDetails)
        : null;
      const statusToIgnore = [
        "REJECT SERVICE FE AT PICKUP",
        "HANDED OVER PACKING MATERIALS",
        "ASSIGNED FE FOR PICKUP",
      ];
      if (
        !pdDetails ||
        Object.keys(pdDetails).length === 0 ||
        pdDetails["process_id"] === "" ||
        data["JobStatus"].includes("RESCHEDULE") ||
        statusToIgnore.includes(data["JobStatus"])
      )
        return "";

      const res = Object.keys(pdDetails).map((key) => {
        return ` ${key} : ${pdDetails[key] ? "PICKED" : "NOT_PICKED"}`;
      });
      return res.toString();
    } catch (error) {
      console.error(error);
      console.log("MISNewReport >>>>>> productDetailsData");
    }
  };

  componentDidMount() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.state.userCode = userInfo.code;
    this.state.userInfoTeamId = userInfo.teamId;

    // this.loadRegions();
    // this.loadZones();
    this.loadHubs();
    // this.loadRetailers();
    this.retailerList();
    this.getReatailerFormat(this.state.userInfoTeamId);
  }

  async loadHubs() {
    try {
      let hubList = JSON.parse(localStorage.getItem("hubs"));
      this.setState({ hubs: hubList });

      // const res = await getHubsAll();
      // if (res !== undefined) {
      //     if (res.data.success === true) {
      //         this.setState({ hubs: res.data.data.hubs });
      //     } else {
      //         toast.toastError(res.data.msg);
      //     }
      // }
    } catch {
      console.log("Error in MISNewReport>>>>>> loadHubs");
    }
  }
  //----------------------------------Customize Report Start------------------------------------------------//
  async getReatailerFormat(retailerID) {
    try {
      this.setState({ selectedTeamId: retailerID });
      const res = await getRetailer(retailerID);
      if (res !== undefined) {
        if (res.data) {
          this.state.excelFormatted = res.data.excelHeaders;
          this.state.mapBlockStatus = res.data.mapBlockStatus;
          this.formatTableHeader(res.data.columns);
          this.state.removeExcelColumn = res.data.removeColumns;
        }
      }
    } catch {
      console.log("Error in MISNewReport>>>>>> getReatailerFormat");
    }
  }

  formatTableHeader = (columns) => {
    // console.log("formatTableHeader", columns)
    try {
      let tableMeta = {
        key: "misTicketId" + 1,
        columns: [],
      };
      let mapIndex = 0;
      let filteredColumns = columns.map((c, i) => {
        if (c.path === "ticketNo") {
          return this.riderTicketNo;
        }
        if (c.path === "linehaulDispatchedAt") {
          return this.riderlinehaulDispatchedAt;
        }
        if (c.path === "pickupTime") {
          return this.riderPickupTime;
        }
        if (c.path === "dropTime") {
          return this.riderDropTime;
        }
        if (c.path === "blockStatus") {
          mapIndex = i + 1;
          return this.riderBlockStatus;
        }
        if (c.path === "prodQcStatus") {
          return this.riderProdQcStatus;
        }
        if (c.path === "updated_at") {
          return this.riderUpdatedAt;
        }
        if (c.path === "attempt1At") {
          return this.riderAttempt1;
        }
        if (c.path === "attempt2At") {
          return this.riderAttempt2;
        }
        if (c.path === "attempt3At") {
          return this.riderAttempt3;
        }
        if (c.path === "attempt4At") {
          return this.riderAttempt4;
        }
        if (c.path === "attempt5At") {
          return this.riderAttempt5;
        }
        if (c.path === "p1Start") {
          return this.riderP1Start;
        }
        if (c.path === "p1End") {
          return this.riderP1End;
        }
        if (c.path === "images") {
          return this.riderImage;
        }
        // if (c.path === "blockInventory") {
        //   return this.blockInventory;
        // }
        // if (c.path === "blockInventoryFe") {
        //   return this.blockInventoryFe;
        // }
        if (c.path === "blockInventoryVerified") {
          return this.blockInventoryVerified;
        }
        if (c.path === "prdBoxVerified") {
          return this.prdBoxVerified;
        }

        if (c.path === "productDetails") {
          return this.productDetails;
        }

        if (c.path === "accessoryDetails") {
          return this.accessoryDetails;
        }

        // if (c.path === "blockInventoryReturns") {
        //   return this.blockInventoryReturns;
        // }

        if (c.path === "quoteValue") {
          return this.quoteValue;
        }

        if (c.path === "agreedValue") {
          return this.agreedValue;
        }
        if (c.path === "created_at") {
          return this.riderCreatedDate;
        }
        if (c.path === "created_at") {
          return this.riderCreatedTime;
        }
        if (c.path === "pickupFeName") {
          return this.riderPickupFeName;
        }
        if (c.path === "pickupFeName") {
          return this.riderPickupFeCode;
        }
        if (c.path === "dropFeName") {
          return this.riderDropFeName;
        }
        if (c.path === "dropFeName") {
          return this.riderDropFeCode;
        }
        if (c.path === "productCusField") {
          return this.productCusField;
        }
        if (c.path === "physicalEvlResult") {
          return this.physicalEvlResult;
        }
        if (c.path === "technicalEvlResult") {
          return this.technicalEvlResult;
        }
        // if (c.path === "srcServicability") {
        //   return this.srcServicability;
        // }

        return c;
      });

      // console.log({mapIndex, MapriderBlockStatus: this.riderMapBlockStatus, beforeFilter: filteredColumns})
      filteredColumns.splice(mapIndex, 0, this.riderMapBlockStatus);
      tableMeta["columns"] = filteredColumns;
      this.setState({ tableMeta });
    } catch {
      console.log("Error in MISNewReport>>>>>> formatTableHeader");
    }
  };

  // blockInventory = {
  //   key: "blockInventory",
  //   action: (r) => {
  //     let inv = JSON.parse(r["blockInventory"]);
  //     if (inv && inv.jobInventory) {
  //       let tdRows = inv.jobInventory.map((i) => {
  //         return (
  //           <>
  //             <td style={{ color: "green" }}>{i["item"]}</td>{" "}
  //             <td>{i["qnt"]}</td>
  //           </>
  //         );
  //       });
  //       return tdRows;
  //     } else {
  //       return <b>{"No"}</b>;
  //     }
  //   },
  //   icon: () => <>JobInventory</>,
  // };
  // blockInventoryFe = {
  //   key: "blockInventoryFe",
  //   action: (r) => {
  //     try {

  //       let inv = JSON.parse(r["blockInventoryFe"]);
  //       if (inv && inv.jobFeInventory) {
  //         let tdRows = inv.jobFeInventory.map((i) => {
  //           return (
  //             <>
  //               <td style={{ color: "green" }}>{i["item"]}</td>{" "}
  //               <td>{i["feQnt"]}</td>
  //             </>
  //           );
  //         });
  //         return tdRows;
  //       } else {
  //         return <b>{"No"}</b>;
  //       }
  //     }
  //     catch {
  //       console.log("Error in MISReport>>>>>>>>>>blockInventoryFe");
  //     }
  //   },
  //   icon: () => <>JobInventoryFe</>,
  // };
  prdBoxVerified = {
    key: "prdBoxVerified",
    action: (r) => {
      try {
        let box = JSON.parse(r["prdBoxVerified"]);
        if (box) {
          try {
            let tdRows = box.map((i) => {
              return (
                <>
                  <tr>
                    <td style={{ color: "green" }}>{i["boxNames"]}</td>
                    <td>
                      {i["boxStatus"] && (
                        <a target="_blank" rel="no referrer">
                          {i["boxStatus"] === "assign" ? (
                            <>
                              <i class="fa fa-spinner fa-lg text-info ml-2">
                                {"   " + "InProcess"}
                              </i>
                            </>
                          ) : i["boxStatus"] === "receivedInGoodCondition" ? (
                            <i class="fa fa-check fa-lg text-success ml-2">
                              {" "}
                              {"   " + "YES"}
                            </i>
                          ) : i["boxStatus"] === "missing" ? (
                            <i class="fa fa-times-circle-o fa-lg text-danger ml-2">
                              {" "}
                              {"   " + "MISSING"}
                            </i>
                          ) : i["boxStatus"] === "damaged" ? (
                            <i class="fa fa-times-circle-o fa-lg text-danger ml-2">
                              {" "}
                              {"   " + "DAMAGED"}
                            </i>
                          ) : i["boxStatus"] === "NOT ATTEMPTED" ? (
                            <i class="fa fa-times-circle-o fa-lg text-danger ml-2">
                              {" "}
                              {"   " + "NOT ATTEMPTED"}
                            </i>
                          ) : (
                            <>
                              <i class="fa fa-clock-o fa-lg text-danger ml-2">
                                {" "}
                                {"   " + "Pending"}
                              </i>
                            </>
                          )}
                        </a>
                      )}
                    </td>
                  </tr>
                </>
              );
            });
            return tdRows;
          } catch {}
        } else {
          return <b>{"No"}</b>;
        }
      } catch (error) {
        console.error(error);
        console.log("Error in Furniture Flow >>>>>>>>>>prdBoxVerified");
      }
    },
    icon: () => <>BoxVerified</>,
  };

  productDetails = {
    key: "productDetails",
    action: (r) => {
      try {
        const blockStatusIgnored = [
          "HANDED_OVER_PACKING_MATERIALS",
          "REJECT_SERVICE_FE_AT_PICKUP",
          "PROBLEM_SOLVED_DURING_INITIAL_CALL",
          "ASSIGNED_FE_FOR_PICKUP",
          "APPOINTMENT_FIXED",
        ];
        if (
          r["blockStatus"]?.toLowerCase().includes("idle") ||
          r["blockStatus"]?.toLowerCase().includes("reschedule") ||
          blockStatusIgnored.includes(r["blockStatus"])
        ) {
          return <></>;
        }

        // console.log("r.blockStatus", r["blockStatus"]);
        const pdDetails = r["productDetails"]
          ? JSON.parse(r["productDetails"])
          : null;

        if (
          !pdDetails ||
          Object.keys(pdDetails).length === 0 ||
          pdDetails["process_id"] === ""
        )
          return <></>;

        const res = Object.keys(pdDetails).map((key) => {
          return (
            <>
              <tr>
                <td>{key}</td>
                <td>
                  {pdDetails[key] ? (
                    <span
                      style={{
                        color: "green",
                        fontWeight: "bold",
                      }}
                    >
                      PICKED
                    </span>
                  ) : (
                    <span
                      style={{
                        color: "rgb(181, 47, 47)",
                        fontWeight: "bold",
                      }}
                    >
                      NOT_PICKED
                    </span>
                  )}
                </td>
              </tr>
            </>
          );
        });
        return res;
      } catch (error) {
        console.error(error);
        console.log("Error in productDetails MIS PND1way");
      }
    },
    icon: () => <>ProductDetails</>,
  };

  blockInventoryVerified = {
    key: "blockInventoryVerified",
    action: (r) => {
      try {
        let inv = JSON.parse(r["blockInventoryVerified"]);
        if (inv && inv.jobVerifiedInventory) {
          let tdRows = inv.jobVerifiedInventory.map((i) => {
            return (
              <>
                <td style={{ color: "green" }}>{i["item"]}</td>{" "}
                <td>{i["verifiedQnt"]}</td>
              </>
            );
          });
          return tdRows;
        } else {
          return <b>{"No"}</b>;
        }
      } catch {
        console.log("Error in MISReport>>>>>>>>>>blockInventoryVerified");
      }
    },
    icon: () => <>JobInventoryVerified</>,
  };

  accessoryDetails = {
    key: "accessoryDetails",
    action: (r) => {
      try {
        let acc = JSON.parse(r["accessoryDetails"]);
        if (acc && acc.accessories) {
          let tdRows = acc.accessories.map((i) => {
            return (
              <>
                <td style={{ color: "green" }}>{i["label"]}</td>{" "}
                <td>{i["accessories"].toString()}</td>
              </>
            );
          });
          return tdRows;
        } else {
          return <b>{"No"}</b>;
        }
      } catch {
        console.log("Error in MISReport>>>>>>>>>>accessoryDetails");
      }
    },
    icon: () => <>Accessories Details</>,
  };

  // blockInventoryReturns = {
  //   key: "blockInventoryReturns",
  //   action: (r) => {
  //     let inv = JSON.parse(r["blockInventoryReturns"]);
  //     if (inv && inv.jobOldInventory) {
  //       let tdRows = inv.jobOldInventory.map((i) => {
  //         return (
  //           <>
  //             <td style={{ color: "green" }}>{i["item"]}</td>{" "}
  //             <td>{i["qnt"]}</td>
  //           </>
  //         );
  //       });
  //       return tdRows;
  //     } else {
  //       return <b>{"No"}</b>;
  //     }
  //   },
  //   icon: () => <>JobInventoryReturns</>,
  // };
  riderTicketNo = {
    key: "ticketNo",
    action: (r) => <b style={{ color: "green" }}>{r["ticketNo"]}</b>,
    icon: () => <>Ticket No</>,
  };
  riderlinehaulDispatchedAt = {
    key: "linehaulDispatchedAt",
    action: (r) => <>{epoch2convertddmmyyyyhhmmss(r.linehaulDispatchedAt)}</>,
    icon: () => <>Dispatched Date&Time</>,
  };
  riderPickupTime = {
    key: "pickupTime",
    action: (r) => <>{epoch2convertddmmyyyyhhmmss(r.pickupTime)}</>,
    icon: () => <>Pickup Date&Time</>,
  };
  riderDropTime = {
    key: "dropTime",
    action: (r) => <>{epoch2convertddmmyyyyhhmmss(r.dropTime)}</>,
    icon: () => <>drop Date&Time</>,
  };
  riderBlockStatus = {
    key: "blockStatus",
    action: (r) => (
      <b style={{ color: "#b52f2f" }}>{this.replaceString(r.blockStatus)}</b>
    ),
    cusClass: "",
    icon: () => <>RL Current Status</>,
  };
  riderProdQcStatus = {
    key: "prodQcStatus",
    action: (r) => <b style={{ color: "#b52f2f" }}>{r.prodQcStatus}</b>,
    cusClass: "",
    icon: () => <>Product Status</>,
  };

  riderMapBlockStatus = {
    key: "mapBlockStatus",
    action: (r) => (
      <b style={{ color: "#b52f2f" }}>
        {this.mapBlockStatus(
          r.blockStatus,
          r.closedStatus,
          r.prodQcStatus,
          r.flowStatus
        )}
      </b>
    ),
    cusClass: "",
    icon: () => <>Client Current Status</>,
  };
  riderUpdatedAt = {
    key: "updated_at",
    action: (r) => <>{epoch2convertddmmyyyyhhmmss(r.updated_at)}</>,
    icon: () => <>updated_at</>,
  };
  riderAttempt1 = {
    key: "attempt1At",
    action: (r) => <>{epoch2convertddmmyyyyhhmmss(r.attempt1At)}</>,
    icon: () => <>attempt1At</>,
  };
  riderAttempt2 = {
    key: "attempt2At",
    action: (r) => <>{epoch2convertddmmyyyyhhmmss(r.attempt2At)}</>,
    icon: () => <>attempt2At</>,
  };
  riderAttempt3 = {
    key: "attempt3At",
    action: (r) => <>{epoch2convertddmmyyyyhhmmss(r.attempt3At)}</>,
    icon: () => <>attempt3At</>,
  };
  riderAttempt4 = {
    key: "attempt4At",
    action: (r) => <>{epoch2convertddmmyyyyhhmmss(r.attempt4At)}</>,
    icon: () => <>attempt4At</>,
  };
  riderAttempt5 = {
    key: "attempt5At",
    action: (r) => <>{epoch2convertddmmyyyyhhmmss(r.attempt5At)}</>,
    icon: () => <>attempt5At</>,
  };
  riderP1Start = {
    key: "p1Start",
    action: (r) => <>{r.p1Start}</>,
    icon: () => <>Job Start</>,
  };
  riderP1End = {
    key: "p1End",
    action: (r) => <>{r.p1End}</>,
    icon: () => <>Job End</>,
  };

  riderImage = {
    key: "images",
    action: (r) => (
      <a
        href={apiEndpoint + "index.php?ticketNo=" + r.ticketNo}
        target="_blank"
        rel="noreferrer"
      >
        <span className="badge badge-info">Click me!</span>
      </a>
    ),
    icon: () => <>images</>,
  };

  riderCreatedDate = {
    key: "created_at",
    action: (r) => <>{epoch2ddmmyy(r.created_at)}</>,
    icon: () => <>created date</>,
  };
  riderCreatedTime = {
    key: "created_at1",
    action: (r) => <>{epoch2hhmmss(r.created_at)}</>,
    icon: () => <>created time</>,
  };
  riderPickupFeName = {
    key: "pickupFeName",
    action: (r) => <>{this.stringPickUpFEName(r.pickupFeName)}</>,
    icon: () => <>pickupFeName</>,
  };
  riderPickupFeCode = {
    key: "pickupFeName1",
    action: (r) => <>{this.stringPickUpFECode(r.pickupFeName)}</>,
    icon: () => <>pickupFeCode</>,
  };

  riderDropFeName = {
    key: "dropFeName",
    action: (r) => <>{this.stringDropFEName(r.dropFeName)}</>,
    icon: () => <>dropFeName</>,
  };
  riderDropFeCode = {
    key: "dropFeName1",
    action: (r) => <>{this.stringDropFECode(r.dropFeName)}</>,
    icon: () => <>dropFeCode</>,
  };

  //----------------------------------Customize Report End------------------------------------------------//
  stringPickUpFECode = (str) => {
    try {
      if (str) {
        let myArray = str.split(" - ");
        return myArray[1];
      }
    } catch (e) {
      console.log("Error in MISNewReport>>>>>> stringPickUpFECode....");
    }
  };
  stringPickUpFEName = (str) => {
    try {
      if (str) {
        let myArray = str.split(" - ");
        return myArray[0];
      }
    } catch (e) {
      console.log("Error in MISNewReport>>>>>> stringPickUpFEName...");
    }
  };
  stringDropFECode = (str) => {
    try {
      if (str) {
        let myArray = str.split(" - ");
        return myArray[1];
      }
    } catch (e) {
      console.log("Error in MISNewReport>>>>>> stringDropFECode....");
    }
  };
  stringDropFEName = (str) => {
    try {
      if (str) {
        let myArray = str.split(" - ");
        return myArray[0];
      }
    } catch (e) {
      console.log("Error in MISNewReport>>>>>> stringDropFEName...");
    }
  };

  retailerList = async () => {
    try {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let playerId = userInfo.playerId;
      let resp = await getRetailerInfo(playerId);
      if (resp !== undefined) {
        if (
          resp.data.success === true &&
          resp.data.data.retailersList.length >= 1
        ) {
          this.setState({ clients: resp.data.data.retailersList });
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in retailerList");
    }
  };

  async loadRetailers() {
    try {
      const res = await getTeams();
      if (res !== undefined) {
        if (res.data.success === true) {
          // this.setState({ clients: res.data.data.teams });
        } else {
          toast.toastError(res.data.msg);
        }
      }
    } catch {
      console.log("Error in MISNewReport>>>>>> loadRetailers");
    }
  }

  async doSubmit() {
    try {
      this.setState({ report: [] });
      let data = this.state.data;
      if (this.state.data.includeDstHub === "") {
        data["includeDstHub"] = "no";
        data["includeBeforeShipment"] = "no";
        this.setState({
          data,
        });
      }
      if (this.state.data.includeBeforeShipment === "") {
        data["includeBeforeShipment"] = "no";
        this.setState({
          data,
        });
      }
      if (this.state.data.hubId.length === 0) {
        toast.toastInfo("No Hubs selected");
      }

      if (this.state.userCode !== "BIZ") {
        let retailer = [];
        retailer.push(this.state.userInfoTeamId);
        data["retailerId"] = retailer;
        this.setState({
          data,
        });
      }
      let ans = Array.isArray(this.state.data.retailerId);
      if (ans === false) {
        let retailer = [];
        retailer.push(this.state.data.retailerId);
        data["retailerId"] = retailer;
        this.setState({
          data,
        });
      }
      if (this.state.data.retailerId.length === 0) {
        toast.toastInfo("No retailer selected");
      }
      this.setState({ loaderCount: 1 });
      //------------------------new start-----------------------------//
      let start = this.state.data.startDate;
      let end = this.state.data.endDate;
      let date1 = new Date(start);
      let date2 = new Date(end);

      if (date1 > date2) {
        swal({
          icon: "error",
          title:
            "Kindly Check Date Range, Start Date should be less than End Date",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null;
      }

      // To calculate the time difference of two dates
      let Difference_In_Time = date2.getTime() - date1.getTime();

      // To calculate the no. of days between two dates
      let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

      if (Difference_In_Days > 62) {
        swal({
          icon: "error",
          title: "Kindly Check Date Range, should be less than 2 months",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null;
      }

      let res = await getMISDetailsNew(
        this.state.data,
        this.state.pageLinks.pageNo
      );
      if (res.data.data.misReportTicket.length === 0) {
        toast.toastInfo("No data");
        this.setState({ loading: true });
      } else {
        this.MisTemp(this.state.data, res.data.data.pageLinks);
        this.setState({ loading: false });
      }
    } catch {
      console.log("Error in MISNewReport>>>>>> doSubmit");
    }
  }

  async MisTemp(params, pageLinks) {
    try {
      this.setState({ loaderCount: pageLinks.lastPage });

      this.state.MisTempArray = [];
      // console.log("pageLinks..." + JSON.stringify(pageLinks));
      for (var i = pageLinks.pageNo; i <= pageLinks.lastPage; i++) {
        const res = await getMISDetailsNew(params, i);
        if (res) {
          if (res.data.success === true && res.data.data.misReportTicket) {
            this.setState({ report: res.data.data.misReportTicket });
            this.state.report.map(async (r) => {
              this.state.MisTempArray.push(r);
            });

            this.setState({ loaderCountCur: i });
          } else {
            const res = await getMISDetailsNew(params, i);
            if (res.data.success === true && res.data.data.misReportTicket) {
              this.setState({ report: res.data.data.misReportTicket });
              this.state.report.map(async (r) => {
                this.state.MisTempArray.push(r);
              });

              this.setState({ loaderCountCur: i });
            }
          }
        } else {
          const res = await getMISDetailsNew(params, i);
          if (res.data.success === true && res.data.data.misReportTicket) {
            this.setState({ report: res.data.data.misReportTicket });
            this.state.report.map(async (r) => {
              this.state.MisTempArray.push(r);
            });

            this.setState({ loaderCountCur: i });
          }
        }
      }
      this.setState({ loaderCountCur: 0 });
      this.setState({ loaderCount: 0 });
      this.setState({ report: this.state.MisTempArray });
      ///Excel
      this.formatMISReport(this.state.report);

      let apiData = {};
      const { csvReport } = { ...this.state };
      apiData = this.state.report;

      csvReport.filename = "financial_report.xlsx";
      csvReport.headers = this.excelHeaders;

      let transData = [];
      transData = apiData;
      this.setState({ csvReport });

      if (apiData) {
        const promises = apiData;

        transData = await Promise.all(promises);
        csvReport.data = transData;
        this.setState({ csvReport });
      } else {
        csvReport.data = [];
        this.setState({ csvReport });
      }
    } catch (error) {
      console.log("exception in MIS data load.....", error);
    }
  }

  // formatMISReport = async (r) => {
  //   try {
  //     let formatedMIS = await Promise.all(
  //       r.filter(async (t) => {
  //         this.state.removeExcelColumn.map((s) => {
  //           delete t[s];
  //         });
  //         if (
  //           this.state.selectedTeamId === "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2"
  //         ) {
  //           t.created_date = await epoch2ddmmyyyy(t.created_at);
  //           delete t.created_at;

  //           t.pickup_date = await epoch2ddmmyyyy(t.pickupTime);
  //           delete t.pickupTime;

  //           t.drop_date = await epoch2ddmmyyyy(t.dropTime);
  //           delete t.dropTime;

  //           t.clientCurrentStatus = this.mapBlockStatus(
  //             t.blockStatus,
  //             t.closedStatus,
  //             t.prodQcStatus,
  //             t.flowStatus
  //           );
  //           t.blockStatus = this.replaceString(t.blockStatus);
  //           t.JobStatus = t.blockStatus;
  //           delete t.blockStatus;
  //           t.images = apiEndpoint + "index.php?ticketNo=" + t.ticketNo;
  //           t.attempt1At = await epoch2ddmmyyyy(t.attempt1At);
  //           t.attempt2At = await epoch2ddmmyyyy(t.attempt2At);
  //           t.attempt3At = await epoch2ddmmyyyy(t.attempt3At);
  //           t.attempt4At = await epoch2ddmmyyyy(t.attempt4At);
  //           t.attempt5At = await epoch2ddmmyyyy(t.attempt5At);
  //           t.confirmation_date = await epoch2ddmmyyyy(t.feAppointmentDateTime);
  //           delete t.feAppointmentDateTime;
  //         } else if (
  //           this.state.selectedTeamId !== "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2"
  //         ) {
  //           t.linehaulDispatchedAt = await epoch2ddmmyyyyhhmmss(
  //             t.linehaulDispatchedAt
  //           );
  //           t.pickupFe = this.stringPickUpFEName(t.pickupFeName);
  //           t.pickupFeCode = this.stringPickUpFECode(t.pickupFeName);
  //           delete t.pickupFeName;
  //           t.dropFe = this.stringDropFEName(t.dropFeName);
  //           t.dropFeCode = this.stringDropFECode(t.dropFeName);
  //           delete t.dropFeName;
  //           t.updated_at = await epoch2ddmmyyyyhhmmss(t.updated_at);

  //           t.blockInventoryVerified = this.inventoryData(
  //             t.blockInventoryVerified,
  //             "blockInventoryVerified"
  //           );
  //           t.JobInventoryVerified = t.blockInventoryVerified;
  //           delete t.blockInventoryVerified;

  //           t.JobStart = t.p1Start;
  //           delete t.p1Start;

  //           t.JobEnd = t.p1End;
  //           delete t.p1End;
  //           t.prdBoxVerified = this.boxData(t.prdBoxVerified);
  //           t.productCusField = this.productCusData(t.productCusField);
  //           t.physicalEvlResult = this.productCusData(t.physicalEvlResult);
  //           t.technicalEvlResult = this.productCusData(t.technicalEvlResult);

  //           t.productDetails = this.productDetailsData(t);
  //           t.created_date = await epoch2ddmmyyyy(t.created_at);
  //           t.created_time = await epoch2hhmmss(t.created_at);
  //           delete t.created_at;

  //           t.pickupTime = await epoch2ddmmyyyyhhmmss(t.pickupTime);
  //           t.dropTime = await epoch2ddmmyyyy(t.dropTime);

  //           t.clientCurrentStatus = this.mapBlockStatus(
  //             t.blockStatus,
  //             t.closedStatus,
  //             t.prodQcStatus,
  //             t.flowStatus
  //           );
  //           t.blockStatus = this.replaceString(t.blockStatus);
  //           t.JobStatus = t.blockStatus;
  //           delete t.blockStatus;
  //           t.images = apiEndpoint + "index.php?ticketNo=" + t.ticketNo;
  //           t.attempt1At = await epoch2ddmmyyyyhhmmss(t.attempt1At);
  //           t.attempt2At = await epoch2ddmmyyyyhhmmss(t.attempt2At);
  //           t.attempt3At = await epoch2ddmmyyyyhhmmss(t.attempt3At);
  //           t.attempt4At = await epoch2ddmmyyyyhhmmss(t.attempt4At);
  //           t.attempt5At = await epoch2ddmmyyyyhhmmss(t.attempt5At);
  //           t.confirmationDateTime = await epoch2ddmmyyyyhhmmss(
  //             t.feAppointmentDateTime
  //           );
  //           delete t.feAppointmentDateTime;
  //         }

  //         return t;
  //       })
  //     );

  //     this.setState({ formatMIS: formatedMIS });
  //   } catch {
  //     console.log("Error in MISNewReport>>>>>> formatMISReport");
  //   }
  // };

  formatMISReport = async (r) => {
    try {
      let formatedMIS = await Promise.all(
        r.filter(async (t) => {
          //t.created_at = await epoch2ddmmyyyyhhmmss(t.created_at);
          t.created_date = await epoch2ddmmyy(t.created_at);
          t.created_time = await epoch2hhmmss(t.created_at);
          delete t.created_at;
          t.pickupFe = this.stringPickUpFEName(t.pickupFeName);
          t.pickupFeCode = this.stringPickUpFECode(t.pickupFeName);
          delete t.pickupFeName;
          t.dropFe = this.stringDropFEName(t.dropFeName);
          t.dropFeCode = this.stringDropFECode(t.dropFeName);
          delete t.dropFeName;
          t.updated_at = await epoch2convertddmmyyyyhhmmss(t.updated_at);
          t.pickup_time = await epoch2hhmmss(t.pickupTime);
          t.drop_time = await epoch2hhmmss(t.dropTime);
          t.linehaulDispatchedAt = await epoch2convertddmmyyyyhhmmss(
            t.linehaulDispatchedAt
          );
          t.clientCurrentStatus = this.mapBlockStatus(
            t.blockStatus,
            t.closedStatus,
            t.prodQcStatus,
            t.flowStatus
          );
          t.blockStatus = this.replaceString(t.blockStatus);
          t.JobStatus = t.blockStatus;
          delete t.blockStatus;
          t.images = apiEndpoint + "index.php?ticketNo=" + t.ticketNo;
          t.attempt1At = await epoch2convertddmmyyyyhhmmss(t.attempt1At);
          t.attempt2At = await epoch2convertddmmyyyyhhmmss(t.attempt2At);
          t.attempt3At = await epoch2convertddmmyyyyhhmmss(t.attempt3At);
          t.attempt4At = await epoch2convertddmmyyyyhhmmss(t.attempt4At);
          t.attempt5At = await epoch2convertddmmyyyyhhmmss(t.attempt5At);
          t.confirmation_date = await epoch2ddmmyy(t.feAppointmentDateTime);
          delete t.feAppointmentDateTime;
          t.blockInventoryVerified = this.inventoryData(
            t.blockInventoryVerified,
            "blockInventoryVerified"
          );
          t.JobInventoryVerified = t.blockInventoryVerified;
          delete t.blockInventoryVerified;

          t.JobStart = t.p1Start;
          delete t.p1Start;

          t.JobEnd = t.p1End;
          delete t.p1End;
          t.prdBoxVerified = this.boxData(t.prdBoxVerified);
          t.productCusField = this.productCusData(t.productCusField);
          t.physicalEvlResult = this.productCusData(t.physicalEvlResult);
          t.technicalEvlResult = this.productCusData(t.technicalEvlResult);
          t.accessoryDetails = this.accessoriesData(t.accessoryDetails);

          t.productDetails = this.productDetailsData(t);
          t.pickup_date = await epoch2ddmmyy(t.pickupTime);
          delete t.pickupTime;

          t.drop_date = await epoch2ddmmyy(t.dropTime);
          delete t.dropTime;
          t.srcServicability = this.srcServicability(t.srcServicability);
          delete t.flowStatus;
          this.state.removeExcelColumn.map((s) => {
            delete t[s];
          });

          return t;
        })
      );

      this.setState({ formatMIS: formatedMIS });
    } catch {
      console.log("Error in MISNewReport>>>>>> formatMISReport");
    }
  };
  onChange(e) {
    let data1 = { ...this.state.data };
    if (e === "today") {
      data1["status"] = e;
      data1["startDate"] = format(new Date(), "yyyy-MM-dd");
      data1["endDate"] = format(Date.now(), "yyyy-MM-dd");
    } else if (e === "pastDay") {
      data1["status"] = e;
      data1["startDate"] = format(sub(Date.now(), { days: 1 }), "yyyy-MM-dd");
      data1["endDate"] = format(sub(Date.now(), { days: 1 }), "yyyy-MM-dd");
    } else if (e === "pastWeek") {
      data1["status"] = e;

      data1["startDate"] = format(
        !isSunday(sub(Date.now(), { weeks: 1 }))
          ? previousSunday(sub(Date.now(), { weeks: 1 }))
          : sub(Date.now(), { weeks: 1 }),
        "yyyy-MM-dd"
      );
      data1["endDate"] = format(
        !isSaturday(sub(Date.now(), { weeks: 1 }))
          ? nextSaturday(sub(Date.now(), { weeks: 1 }))
          : sub(Date.now(), { weeks: 1 }),
        "yyyy-MM-dd"
      );
    } else if (e === "pastMonth") {
      data1["status"] = e;
      data1["startDate"] = format(
        startOfMonth(sub(Date.now(), { months: 1 })),
        "yyyy-MM-dd"
      );
      data1["endDate"] = format(
        endOfMonth(sub(Date.now(), { months: 1 })),
        "yyyy-MM-dd"
      );
    } else if (e === "custom") {
      data1["status"] = e;
      data1["startDate"] = "";
      data1["endDate"] = "";
    }

    this.setState({ data: data1 });
  }
  localHandleChange = (e) => {
    try {
      if (e.target.name === "retailerId") {
        const { value } = e.target;
        let data = this.state.data;
        let retailer = this.state;

        // find the clients with the current selected value
        const clients = retailer.clients.find((c) => c.name === value);
        if (clients !== undefined && this.state.userCode === "BIZ") {
          this.getReatailerFormat(clients.teamId);
          data["retailerId"] = clients.teamId;
          this.setState({
            data,
          });
        }
        this.validateProperty(e.target.name, e.target.value);
      }

      if (e.target.name === "endDate") {
      }
    } catch {
      console.log("Error in MISNewReport>>>>>> localHandleChange");
    }
  };
  removeNull = (array) => {
    return array.filter((x) => x !== "*");
  };
  localHandleChangeMulti = (value, name) => {
    try {
      let arrayValue = [];
      const data = { ...this.state.data };
      value.map((r) => {
        arrayValue.push(r.value);
      });
      arrayValue = this.removeNull(arrayValue);
      if (name === "retailerId") {
        if (this.state.userCode === "BIZ" && arrayValue.length === 1) {
          this.getReatailerFormat(arrayValue[0]);
          let retailer = [];
          retailer.push(arrayValue[0]);
          data["retailerId"] = retailer;
          this.setState({
            data,
          });
          data["retailerId"] = arrayValue[0];
          this.setState({
            data,
          });
        } else if (this.state.userCode === "BIZ" && arrayValue.length > 1) {
          this.getReatailerFormat("000");
        }
      }
    } catch {
      console.log("Error in MISNewReport>>>>>> localHandleChangeMulti");
    }
  };

  onMISTextChanged = (e) => {
    const value = e.target.value;
    this.setState(() => ({
      fileName: value,
    }));
  };

  render() {
    const reportJSON = this.state.report;
    // console.log("tableMeta",this.state.tableMeta)
    // console.log("reportJSON",reportJSON)
    return (
      <>
        <ZTitle subHeading="Report  :: MIS with Inventory" />
        <div
          className="pageContent"
          style={{ backgroundColor: "rgb(251 112 3 / 18%)" }}
        >
          <div
            className="card"
            style={{ backgroundColor: "rgb(210 126 49 / 0%)" }}
          >
            <div
              className="card-body"
              style={{
                backgroundColor: "white",
                maxWidth: "97%",
                marginLeft: "1%",
              }}
            >
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-11">
                    <div className="form-row">
                      <div className="col-12">
                        <div className="date-selection row">
                          <label className="col-1">
                            <input
                              type="radio"
                              name="date-selection"
                              id="date_today"
                              value="today"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.onChange("today");
                                }
                              }}
                            />{" "}
                            Today
                          </label>
                          <label className="col-1">
                            <input
                              type="radio"
                              name="date-selection"
                              id="date_pastDay"
                              value="pastDay"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.onChange("pastDay");
                                }
                              }}
                            />{" "}
                            Yesterday
                          </label>

                          <label className="col-1">
                            <input
                              type="radio"
                              name="date-selection"
                              id="date_pastWeek"
                              value="pastWeek"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.onChange("pastWeek");
                                }
                              }}
                            />{" "}
                            Past Week
                          </label>

                          <label className="col-1">
                            <input
                              type="radio"
                              name="date-selection"
                              id="date_pastMonth"
                              value="pastMonth"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.onChange("pastMonth");
                                }
                              }}
                            />{" "}
                            Past Month
                          </label>
                          <label className="col-1">
                            <input
                              type="radio"
                              name="date-selection"
                              id="date_custom"
                              value="custom"
                              onChange={(e) => {
                                if (e.target.checked) {
                                  this.onChange("custom");
                                }
                              }}
                            />{" "}
                            Custom
                          </label>
                          <div className="col-6">
                            {this.rCBHL("includeDstHub", this.includeDstHub, {
                              value: "sortKey",
                              label: "sort",
                            })}
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{
                            marginTop: "-2px",
                            marginLeft: "-5px",
                            marginBottom: "-15px",
                            position: "relative",
                          }}
                        >
                          {this.rRBL(
                            "Search by :",
                            "ticketScope",
                            this.ticketScope,
                            {
                              value: "sortKey",
                              label: "sort",
                            }
                          )}
                        </div>
                      </div>

                      {/* {this.rRBL("Date Selection:", "startDate", this.status, {
                        value: "sortKey",
                        label: "sort",
                      })} */}
                    </div>
                    <div className="form-row mt-2 ">
                      {this.rDPFMIS("Start Date *", "startDate", " col-md-2 ")}
                      {this.rDPFMIS("End Date *", "endDate", " col-md-2 ")}
                      <div style={{ paddingLeft: "15px", marginTop: "-5px" }}>
                        {this.rSBMulti(
                          "Hub",
                          "hubId",
                          this.state.hubs,
                          { value: "hubId", label: "hub" },
                          " col-md-2 "
                        )}
                      </div>
                      <div
                        style={{
                          paddingLeft: "15px",
                          paddingRight: "15px",
                          marginTop: "-5px",
                        }}
                      >
                        {this.state.userCode === "BIZ" && (
                          <>
                            {this.rSBMulti(
                              "ClientMis",
                              "retailerId",
                              this.state.clients,
                              { value: "teamId", label: "clients" },
                              " col-md-2 "
                            )}
                          </>
                        )}
                      </div>
                      {this.rTB("Search .....", "searchString", "col-md-2")}
                      {this.rSB(
                        "Ticket Status",
                        "ticketStatus",
                        this.ticketStatus,
                        this.stsConf,
                        " col-md-2"
                      )}
                    </div>
                  </div>
                  <div
                    className="col-1 pull-right"
                    style={{ paddingTop: "65px", paddingLeft: "37px" }}
                  >
                    {this.rBtnSP("Submit", "Submit")}
                  </div>
                </div>
              </form>
            </div>
            <div className="row"></div>
            {this.state.loaderCount !== 0 && (
              <>
                {JSON.stringify(this.state.loaderCountCur)} /
                {JSON.stringify(this.state.loaderCount)}
                <div className="progress">
                  <div
                    className="progress-bar"
                    style={{
                      width:
                        Math.ceil(
                          (parseInt(this.state.loaderCountCur) /
                            parseInt(this.state.loaderCount)) *
                            100
                        ) + "%",
                    }}
                    role="progressbar"
                    aria-valuenow="90"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {Math.ceil(
                      (parseInt(this.state.loaderCountCur) /
                        parseInt(this.state.loaderCount)) *
                        100
                    )}
                    %
                  </div>
                </div>
              </>
            )}

            {reportJSON.length > 0 && this.state.loaderCount === 0 && (
              <div className="form-row">
                {this.props.loading && (
                  <div className="container-fluid">
                    <div> Loading Tickets...</div>
                  </div>
                )}
                {!this.props.loading && (
                  <>
                    <div
                      className="col-2 form-group mt-3"
                      style={{ position: "relative", left: "77%" }}
                    >
                      <input
                        value={this.state.fileName}
                        name="fileName"
                        onChange={this.onMISTextChanged}
                        type="text"
                        autoComplete="off"
                      />
                      <label htmlFor="fileName" className="control-label">
                        FileName *
                      </label>
                      <i className="bar"></i>
                      <ExportXLSXMIS
                        csvData={this.state.formatMIS}
                        header={this.state.excelFormatted}
                        fileName={
                          this.state.fileName + "-" + this.getDatebyDays(0)
                        }
                      />
                    </div>

                    {this.formatTableHeader}
                    {this.state.data.searchString &&
                      this.state.userCode === "BIZ" && (
                        <div className="container-fluid" id="cocard">
                          <div className="fixTableHead">
                            <Table
                              clsName="table table-striped table-bordered table-hover "
                              tableMeta={this.state.tableMeta}
                              data={reportJSON}
                              dropMenu={dropMenuFull()}
                            />
                          </div>
                        </div>
                      )}
                    {this.state.userCode !== "BIZ" && (
                      <div className="container-fluid" id="cocard">
                        <div className="fixTableHead">
                          <Table
                            clsName="table table-striped table-bordered table-hover "
                            tableMeta={this.state.tableMeta}
                            data={reportJSON}
                            dropMenu={dropMenuFull()}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        {this.state.loading == true && !this.state.report.length && (
          <div className="container-fluid jumbotron text-center">
            <h4>No Data Available...</h4>
          </div>
        )}
      </>
    );
  }
}

export default MisNewReport;
