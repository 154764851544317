import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import $ from "jquery";

import Logout from "./pages/auth/Logout";
import MaintenancePage from "./pages/auth/MaintenancePage";

import Profile from "./pages/auth/Profile";
import ChangePassword from "./pages/auth/changePassword";

//import Country from "./pages/location/Country";
//import State from "./pages/location/State";
import City from "./pages/masterLocation/City";
import PincodesMasters from "./pages/masterLocation/Pincode";

import Hub from "./pages/masterLocation/Hub";
import ItemMaster from "./pages/stockManagement/ItemMaster";
import FilterScreenOfItemMaster from "./pages/stockManagement/barcode/FilterScreenOfItemMaster";
import AssigningPmToUsers from "./pages/stockManagement/barcode/AssigningPmToUsers";
import Regions from "./pages/masterLocation/Regions";
import Zones from "./pages/masterLocation/Zones";
import States from "./pages/masterLocation/States";
import Country from "./pages/masterLocation/Country";

import Dashboards from "./pages/dashboards/Dashboards";

import LifelongTicket from "./pages/tickets/create/LifelongTicket";
import LifeLongTicketList from "./pages/tickets/create/LifeLongTicketList";

import RetailerLocations from "./pages/retailerLocations/RetailerLocations";

import RoleDivs from "./pages/appRoles/RoleDivs";
import RoleGroups from "./pages/appRoles/RoleGroups";
import AppRoles from "./pages/appRoles/AppRoles";
import AppFeatures from "./pages/appFeatures/AppFeatures";
import Menus from "./pages/menus/Menus";
import Flows from "./pages/coreItAdmin/Flows";
import PlayerTeamApiToken from "./pages/coreItAdmin/PlayerTeamApiToken";
import TeamTypes from "./pages/players/TeamTypes";
import Departments from "./pages/players/deptDesign/Departments";
import Teams from "./pages/players/Teams";
import Clients from "./pages/players/Clients";

import Players from "./pages/players/Players";
import PlayerMobKey from "./pages/players/PlayerMobKey";

import TeamContacts from "./pages/players/TeamContacts";
import Emps from "./pages/players/Emps";
import EmpBankAccs from "./pages/players/EmpBankAccs";
import TeamBankAccs from "./pages/players/TeamBankAccs";

import TableListDemo from "./pages/demo/TableListDemo";
import TableListServerSidePage from "./pages/demo/TableListServerSidePage";
import FormDemo from "./pages/demo/FormDemo";
import TasksDemo from "./pages/demo/TasksDemo";
import DynamicElements from "./pages/demo/DynamicElements";

import MasterSync from "./pages/demo/MasterSync";
import FileUpload from "./pages/demo/FileUpload";
import Charts from "./pages/demo/Charts";

import StoryBoard from "./pages/storyboard/StoryBoard";
import DbBoard from "./pages/storyboard/DbBoard";
import StoryList from "./pages/storyboard/StoryList";
import DailyReports from "./pages/storyboard/DailyReports";
import DailyPlans from "./pages/storyboard/DailyPlans";
import TasksList from "./pages/storyboard/TasksList";

import RequestRaise from "./pages/requestV1/RequestRaise";
import ApproveRequestsWrapper from "./pages/requestV1/ApproveRequestsWrapper";
import ExcelUpload from "./pages/requestV1/ExcelUpload";
import ExcelDownload from "./pages/requestV1/ExcelDownload";
import RequestReports from "./pages/requestV1/RequestReports";
import Accounts from "./pages/requestV1/Accounts";
import PaymentReports from "./pages/requestV1/PaymentReports";
import AccountsEmpUpload from "./pages/requestV1/AccountsEmpUpload";
import AccountsVenUpload from "./pages/requestV1/AccountsVenUpload";

import AccountsEmp from "./pages/requestV1/AccountsEmp";
import AccountsVen from "./pages/requestV1/AccountsVen";
import AccountsExpenseHead from "./pages/requestV1/AccountsExpenseHead";

import BDWrapper from "./pages/bluedart/BDWrapper";
import CostCenters from "./pages/TxSettings/CostCenters";
import CostCentersForm from "./pages/TxSettings/CostCentersForm";
import SupportChats from "./pages/support/SupportChats";
// import SupportGroups from "./pages/support/SupportGroups";

// import ProjectDemo from "./pages/demo/ProjectDemo";
import ProjectForm from "./pages/4Team/ProjectForm";
import GreenBoard from "./pages/4Team/GreenBoard";

import UploadBill from "./pages/misBilling/UploadBill";
import BillTickets from "./pages/misBilling/BillTickets";
import UpdateBill from "./pages/misBilling/UpdateBill";

import TicketProcessWrapper from "./pages/TicketProcess/TicketProcessWrapper";
import TicketProcessWrapperNew from "./pages/TicketProcess/TicketProcessWrapperNew";
import DragAndDrop from "./pages/TicketProcess/DragAndDrop";

import LineHaulWrapperNew from "./pages/TicketProcess/Manifest/LineHaulWrapperNew";

import CustomerPincodes from "./pages/masters/CustomerPincodes";
import RetailerProductsWrapper from "./pages/masters/RetailerProductsWrapper";
import RetailerFlows from "./pages/masters/RetailerFlows";
import TicketCreation from "./pages/TicketProcess/TicketCreation";
import MisReport from "./pages/report/MisReport";
import MisNewReport from "./pages/MisNewReport/MisNewReport";

import MisDocketUpdate from "./pages/report/MisDocketUpdate";

// import TicketHistory from "./pages/TicketHistory/TicketHistory";
import MultiTicketHistory from "./pages/TicketHistory/MultiTicketHistory";

import Ivr from "./pages/ivr/Ivr";
import IvrSearch from "./pages/ivr/IvrSearch";

import Inventory from "./pages/stockManagement/Inventory";
import InwardInventory from "./pages/stockManagement/InwardInventory";
import OutwardInventory from "./pages/stockManagement/OutwardInventory";

import StackInventory from "./pages/stockManagement/StackInventory";

import MisOldReport from "./pages/report/MisOldReport";
import Products from "./pages/products/Products";
import ProductsCategoryModels from "./pages/products/ProductsCategoryModels";

import PackingMaterial from "./pages/packing/PackingMaterial";
import PackingMaterialStock from "./pages/packing/PackingMaterialStock";
import TicketUpload from "./pages/TicketProcess/TicketUpload";
import TicketUploadNew from "./pages/TicketProcess/TicketUploadNew";

import PlayerMapper from "./pages/coreItAdmin/PlayerMapper";
import QuestionAns from "./pages/coreItAdmin/qaValidata/QuestionAns";
import PincodesMaster from "./pages/masters/PincodesMaster";
import PincodesList from "./pages/masterLocation/PincodesList";

import ResetMobileKey from "./pages/resetMobileKey/resetMobileKey";
import StoryGanttChart from "./pages/storyboard/StoryGanttChart";
import TicketActivities from "./pages/TicketActivities/TicketActivities";
import BarcodeMaster from "./pages/stockManagement/barcode/BarcodeMaster";
import RevertingBarcodesToAvailable from "./pages/stockManagement/barcode/RevertingBarcodesToAvailable";
import InventoryNewScreen from "./pages/stockManagement/InwardNewScreen/InventoryNewScreen";
import NewInventory from "./pages/stockManagement/InwardNewScreen/NewInventory";
import TicketSequencing from "./pages/TicketProcess/TicketSequencing";
import VehicleTrip from "./pages/VehicleTrip/VehicleTrip";
import MasterFields from "./pages/masters/MasterFields";
// import TicketInfo from "./pages/TicketProcess/Manifest/actions/TicketInfo";
// import TicketSequencing from "./pages/TicketProcess/TicketSequencing/TicketSequencing";
// import TicketInfo from "./pages/TicketProcess/Manifest/actions/TicketInfo";
import AssignBarcodesToUser from "./pages/stockManagement/barcode/AssignBarcodesToUser";
import ReturnBarcodesFromUser from "./pages/stockManagement/barcode/ReturnBarcodesFromUser";

// import ReactGA from "react-ga";
// const TRACKING_ID = "UA-258674917-1";

// ReactGA.initialize(TRACKING_ID);

class _Routes extends Component {
  state = {};

  // sidebarResize() {
  //   // const windowHeight = $("#page-content-wrapper").innerHeight() + 27;
  //   // var _this = this;
  //   // window.requestAnimationFrame(function (_this) {
  //   //   var node = _this.getDOMNode();
  //   //   if (node !== undefined) {
  //   //   }
  //   // });
  //   $("#sidebar-wrapper").css(
  //     "min-height",
  //     $("#page-content-wrapper").innerHeight()
  //   );
  //   console.log($("#page-content-wrapper").innerHeight());
  // }

  onNextFrame(callback) {
    $("#sidebar-wrapper").css(
      "min-height",
      $("#page-content-wrapper").innerHeight()
    );
    // console.log($("#page-content-wrapper").innerHeight());
  }
  componentDidMount() {
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }
  // componentDidUpdate() {
  //   $("#page-content-wrapper").resize(function () {
  //     // setHeight();
  //     console.log($("#page-content-wrapper").innerHeight());
  //   });
  // }

  render() {
    return (
      <React.Fragment>
        <div id="page-content-wrapper">
          <Switch>
            <Route path="/countByStatusHubswiseCharts" component={Dashboards} />

            <Route path="/v1/request/raise" component={RequestRaise} />
            <Route path="/support/chats" component={SupportChats} />
            {/* <Route path="/support/groups" component={SupportGroups} /> */}

            <Route
              path="/v1/request/approve/:pageNo?"
              component={ApproveRequestsWrapper}
            />
            <Route
              path="/v1/request/excel-download"
              component={ExcelDownload}
            />
            <Route path="/v1/request/excel-upload" component={ExcelUpload} />
            <Route path="/v1/request/reports" component={RequestReports} />
            <Route
              path="/v1/request/PaymentReports"
              component={PaymentReports}
            />
            <Route
              path="/v1/request/emp-accounts/:pageNo?"
              component={AccountsEmp}
            />
            <Route
              path="/v1/request/ven-accounts/:pageNo?"
              component={AccountsVen}
            />
            <Route
              path="/v1/request/expenseHead-accounts/:pageNo?"
              component={AccountsExpenseHead}
            />
            <Route
              path="/v1/request/emp-accounts-upload"
              component={AccountsEmpUpload}
            />

            <Route
              path="/v1/request/ven-accounts-upload"
              component={AccountsVenUpload}
            />
            <Route
              path="/packing/PackingMaterial"
              component={PackingMaterial}
            />
            <Route
              path="/packing/PackingMaterialStock"
              component={PackingMaterialStock}
            />
            <Route path="/bluedart" component={BDWrapper} />
            <Route path="/misBilling/UploadBill" component={UploadBill} />
            <Route path="/misBilling/BillTickets" component={BillTickets} />
            <Route path="/misBilling/UpdateBill" component={UpdateBill} />
            <Route path="/tx/settings/costcenters" component={CostCenters} />
            <Route
              path="/tx/settings/costCentersForm"
              component={CostCentersForm}
            />

            <Route path="/coreItAdmin/flows" component={Flows} />
            <Route
              path="/coreItAdmin/player-api-token:pageNo?"
              component={PlayerTeamApiToken}
            />
            <Route
              path="/coreItAdmin/player-assign-unassign/:pageNo?"
              component={PlayerMapper}
            />

            {/* PRODUCT */}
            <Route path="/products/product/:pageNo?" component={Products} />
            <Route
              path="/products/model/:pageNo?"
              component={ProductsCategoryModels}
            />

            <Route path="/coreItAdmin/roleDivs" component={RoleDivs} />
            {/* <Route path="/appRoles/roleDivs" component={RoleDivs} /> */}
            <Route path="/coreItAdmin/appRoles" component={AppRoles} />
            <Route path="/coreItAdmin/appFeatures" component={AppFeatures} />
            <Route path="/coreItAdmin/prvMenus" component={Menus} />

            <Route path="/players/teamTypes" component={TeamTypes} />
            <Route path="/coreItAdmin/qaValidata" component={QuestionAns} />

            <Route
              path="/masters/teams/retailer-locations"
              component={RetailerLocations}
            />
            <Route path="/players/teams/:pageNo?" component={Teams} />
            <Route path="/players/clients/:pageNo?" component={Clients} />

            <Route path="/players/players/:pageNo?" component={Players} />

            <Route path="/players/departments" component={Departments} />
            <Route
              path="/players/teamContacts/:pageNo?"
              component={TeamContacts}
            />
            <Route path="/players/emps/:pageNo?" component={Emps} />
            <Route path="/players/empBankAccs" component={EmpBankAccs} />
            <Route path="/players/teamBankAccs" component={TeamBankAccs} />
            <Route
              path="/players/playerMobKey/:pageNo?"
              component={PlayerMobKey}
            />

            {/* <Route path="/country" component={Country} />
            <Route path="/state" component={State} /> */}
            <Route path="/masterLocation/city" component={City} />
            <Route path="/masterLocation/hub" component={Hub} />
            <Route path="/stockManagement" component={ItemMaster} />
            <Route path="/stockManagementFilter" component={FilterScreenOfItemMaster} />
            <Route path="/revertingBarcodesFromUsers" component={RevertingBarcodesToAvailable} />
            <Route path="/assigningPMToUser" component={AssigningPmToUsers} />
            <Route path="/assignBarcodesToUser" component={AssignBarcodesToUser} />
            <Route path="/returnBarcodesFromUser" component={ReturnBarcodesFromUser} />
            <Route path="/inventoryNewScreen" component={InventoryNewScreen} />
            <Route path="/masterLocation/regions" component={Regions} />
            <Route path="/masterLocation/zones" component={Zones} />
            <Route path="/masterLocation/States" component={States} />
            <Route path="/masterLocation/Country" component={Country} />
            <Route path="/city" component={City} />
            <Route path="/masterLocation/Pincode" component={PincodesMasters} />

            <Route path="/ticket/create/lifelong" component={LifelongTicket} />
            <Route
              path="/ticket/lifelong/LifeLongTicketList"
              component={LifeLongTicketList}
            />

            <Route path="/ticketUpload" component={TicketUpload} />
            <Route path="/ticketUploadNew" component={TicketUploadNew} />

            <Route path="/ticketProcess" component={TicketProcessWrapper} />
            <Route
              path="/ticketProcessNew"
              component={TicketProcessWrapperNew}
            />
            <Route path="/dragAndDrop" component={DragAndDrop} />

            <Route path="/linehaul" component={LineHaulWrapperNew} />

            <Route path="/ticketCreation" component={TicketCreation} />
            {/* <Route path="/ticketSequencing" component={TicketSequencing} /> */}
            <Route path="/vehicleTrip" component={VehicleTrip} />

            <Route path="/misReport" component={MisReport} />
            <Route path="/misNewReport" component={MisNewReport} />

            <Route path="/MisDocketUpdate" component={MisDocketUpdate} />

            {/* <Route path="/TicketHistory" component={TicketHistory} /> */}
            <Route path="/MultiTicketHistory" component={MultiTicketHistory} />

            <Route path="/Inventory" component={Inventory} />
            <Route path="/inwardInventory" component={InwardInventory} />
            <Route path="/outwardInventory" component={OutwardInventory} />
            <Route path="/newInventory" component={NewInventory} />
            <Route path="/ticketSequencing" component={TicketSequencing} />


            <Route path="/stackInventory" component={StackInventory} />
            <Route path="/barcodeMaster" component={BarcodeMaster} />

            <Route path="/ivr" component={Ivr} />
            <Route path="/ivrSearch" component={IvrSearch} />
            <Route path="/resetMobileKey" component={ResetMobileKey} />

            <Route path="/masters/PincodesMaster" component={PincodesMaster} />
            <Route
              path="/masters/CustomerPincodes"
              component={CustomerPincodes}
            />
            <Route path="/masters/PincodesList" component={PincodesList} />
            <Route
              path="/masters/RetailerProducts"
              component={RetailerProductsWrapper}
            />
            <Route path="/masters/RetailerFlows" component={RetailerFlows} />
            <Route path="/masters/masterFields" component={MasterFields} />
            <Route path="/demo/form" component={FormDemo} />
            <Route path="/demo/TasksDemo" component={TasksDemo} />
            <Route path="/demo/dynamic-elements" component={DynamicElements} />
            <Route path="/demo/MasterSync" component={MasterSync} />
            <Route path="/demo/FileUpload" component={FileUpload} />
            <Route path="/demo/charts" component={Charts} />

            <Route path="/storyboard/board" component={StoryBoard} />
            <Route path="/storyboard/db-board" component={DbBoard} />
            <Route path="/storyboard/stories" component={StoryList} />
            <Route path="/storyboard/tasks" component={TasksList} />
            <Route path="/storyboard/daily-reports" component={DailyReports} />
            <Route path="/storyboard/daily-plans" component={DailyPlans} />
            {/* <Route path="/storyboard/story-gantt-chart" component={StoryGanttChart} /> */}
            <Route
              path="/demo/list/client-side-pagination/:pageNo?"
              component={TableListDemo}
            />
            <Route
              path="/demo/list/server-side-pagination/:pageNo?"
              component={TableListServerSidePage}
            />
            <Route path="/demo/list" component={TableListDemo} />
            <Route path="/demo" component={TableListDemo} />
            <Route path="/project/create" component={ProjectForm} />
            <Route path="/greenboard" component={GreenBoard} />
            <Route path="/logout" component={Logout} />
            <Route path="/MaintenancePage" component={MaintenancePage} />


            <Route path="/profile" component={Profile} />
            <Route path="/changePassword" component={ChangePassword} />
            <Route path="/ticketActivities" component={TicketActivities} />
            {/* <Route path="/ticketInfo" component={TicketInfo} /> */}


            <Route path="/" exact component={TableListDemo} />
            <Route path="/" component={FormDemo} />
          </Switch>
        </div>
      </React.Fragment>
    );
  }
}
export default _Routes;