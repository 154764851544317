import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLMasterLoc;
function api(path, subURL = "/ivr") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
  
}

export function getHubs() {
  return _http.get(api("getList"), _http.headerJWT());
}
export function saveHub(req) {
  const param = { ...req };
  const id = param.hubId;
  //delete param.hubId;
  return _http.post(api("save"), param, _http.headerJWT());
}
export function deleteHub(req) {
  const param = { ...req };
  const id = param.hubId;
  return _http.delete(api("delete/"+id), _http.headerJWT());
}
