import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLMasterLoc;
function api(path, subURL = "/cities") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getCities() {
  return _http.get(api("details/"), _http.headerJWT());
  // +"10/"+pageNo), _http.headerJWT());
}

export function getCitiesAll(pageNo) {
  return _http.get(api("details"+"/10/"+pageNo), _http.headerJWT());
  // +"10/"+pageNo), _http.headerJWT());
}

export function getActiveCities() {
  return _http.get(api("active"), _http.headerJWT());
}

export function createCity(req) {
  const param = { ...req };
  delete param.cityId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveCity(req) {
  const param = { ...req };
  const id = param.cityId;
  //delete param.cityId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteCity(req) {
  const param = { ...req };
  return _http.delete(api(param.cityId), _http.headerJWT());
}
