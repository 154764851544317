import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import {
  createLocation,
  updateLocation,
  deleteLocation,
} from "./../../../services/team/RetailerLocationServices";

class ModalLocation extends FormLib {
  state = {
    data: {
      locationId: "",
      teamId: "",
      cityId: "",
      locCode: "",
      locName: "",
      locContactPerson: "",
      locLocation: "",
      locAddress: "",
      locPhone: "",
      locAltPhone: "",
      locEmail: "",
      locPincode: "",
    },
    curLocation: [],
    cities: [],
    errors: {},
  };

  schema = {
    locationId: Joi.optional().label("locationId"),
    teamId: Joi.string().required().label("Team"),
    cityId: Joi.string().required().label("City"),
    locCode: Joi.string().required().label("Location Code"),
    locName: Joi.string().required().label("Location Name"),
    locContactPerson: Joi.string().required().label("Contact Person"),
    locLocation: Joi.string().required().label("Local Location"),
    locAddress: Joi.string().required().label("Location Address"),
    locPhone: Joi.required().label("Location Phone"),
    locAltPhone: Joi.required().label("Alt Phone"),
    locEmail: Joi.string().required().label("Location Email"),
    locPincode: Joi.string().required().label("Location Pincode"),
  };

  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createLocation(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await updateLocation(this.state.data);
    }
    if (this.state.btnValue === "delete") {
      res = await deleteLocation(this.state.data);
      this.resetData();
    }
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.props.onloadLocations();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  componentDidMount() {
    this.setCurLocation(this.props.curLocation);
    // this.loadCities();
  }
  setCurLocation = (loc) => {
    this.setState({ data: loc });
  };
  // async loadCities() {
  //   this.setState({ loading: true });
  //   const res = await getCities();
  //   if (res.data.success === true) {
  //     this.setState({ cities: res.data.data.cities });
  //   } else {
  //     toast.toastError(res.data.msg);
  //   }
  //   this.setState({ loading: false });
  // }

  resetData() {
    this.setState({
      data: {
        locationId: "",
        teamId: "",
        cityId: "",
        locCode: "",
        locName: "",
        locContactPerson: "",
        locLocation: "",
        locAddress: "",
        locPhone: "",
        locAltPhone: "",
        locEmail: "",
        locPincode: "",
      },
    });
  }

  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;
    return (
      <div className="container-fluid">
        <div className="modal fade" id="myProjectModel">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Location</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  <div className="form-row">
                    {this.rTB("Location Id", "locationId", "col-12")}
                    {this.rTB("Team / Retailer", "teamId", "col-6")}
                    {/* <div className="col-md-6">
                      {this.rSB(
                        "Team / Retailer",
                        "teamId",
                        this.state.cities,
                        {
                          value: "teamId",
                          label: "Team",
                        }
                      )}
                    </div> */}
                    {this.rTB("City", "cityId", "col-6")}
                  </div>

                  <div className="form-row">
                    {this.rTB("Location Name", "locName", "col-md-6")}
                    {this.rTB("Location Code", "locCode", "col-6")}
                  </div>
                  <div className="form-row">
                    {this.rTB("Local Location Name", "locLocation", "col-md-6")}
                    {this.rTB(
                      "Contact Person Name",
                      "locContactPerson",
                      "col-md-6"
                    )}
                  </div>
                  <div className="form-row">
                    {this.rTB("Phone", "locPhone", "col-md-6")}
                    {this.rTB("Alternate Phone", "locAltPhone", "col-md-6")}
                    {this.rTB("Email", "locEmail", "col-md-6")}
                    {this.rTB("Pincode", "locPincode", " form-group col-md-6")}
                  </div>
                  <div className="form-row">
                    {this.rTA(
                      "Address",
                      "locAddress",
                      " form-group col-md-12 "
                    )}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {this.state.data.locationId === "" &&
                  this.rBtnSP("Create", "create")}
                {this.state.data.locationId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {this.rBtnSD("Delete", "delete")}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalLocation;
