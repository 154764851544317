import React from "react";
import FormLib from "../../_common/FormLib";
import Table from "../../_common/Table";
import Pagination from "../../_common/Pagination"
import Players from "../../_subhead/Players"
import EmpCodeForm from "./EmpCodeForm";
import toast from "../../../services/_toastService";
import { getEmps,getEmpsSearch } from "../../../services/players/empsServices";
import { getTeams } from "./../../../services/players/teamServices";
class Emps extends FormLib {
  state = {
    loading: true,
    data: {
      searchStr: ""
    },
    teams: [],
    emps: [],
    errors: {},
    curEmp: {},
    curTeam: { teamId: "" },
    formMode: "create",
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };
  tableMeta = {
    key: "PlayerId",
    columns: [
      {
        key: "PLAYER_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnEmps(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      { path: "empCode", label: "Emp Code" },
      { path: "firstName", label: "First Name" },
      { path: "lastName", label: "Last Name" },
      { path: "nickName", label: "Nick Name" },

    ],
  };

  componentDidMount() {
    this.loadTeams();
  }

  loadTeams = async () => {
    this.setState({ loading: true });
    const res = await getTeams();
    if (res.data.success === true) {
      this.setState({ teams: res.data.data.teams });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }
  localHandleChange = (e) => {
    if (e.target.id === "teamId") {
      this.handleTeamChange(e);
    }
  }

  handleTeamChange = (e) => {
    this.loadCurrectEmployee(e.target.value);
  }

  loadCurrectEmployee = async (teamId, modReset = true) => {

    if (modReset === true) {
      this.setState({ emps: [] });
      this.setState({ curTeam: { teamId: "" } });
    }

    let currentTeam;
    const filter = this.state.teams.filter((r) => {
      return r.teamId === teamId ? r : null;
    });
    if (!filter[0]) {
      currentTeam = { teamId: "" };
    } else {
      currentTeam = filter[0];
    }

    this.setState({ curTeam: currentTeam });
    if (teamId !== "") {
      this.loadEmployees(teamId, this.state.pageLinks.pageNo);
    } else {
      this.setState({ players: [] });
    }
  };

  loadEmployees = async (teamId, pageNo) => {
    this.setState({ loading: true });
    this.resetData();
    const res = await getEmps(teamId, pageNo);
    if (res.data.success) {
      if (res.data.data.emps.length) {
        this.setState({ emps: res.data.data.emps });
        this.setState({ pageLinks: res.data.data.pageLinks });
      } else {
        toast.toastInfo(" No data found for selected team");
      }
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/players/emps/" + pageNo);
    this.loadEmployees(this.state.curTeam.teamId, pageNo);
  };


  handleOnEmps = (r) => {
    this.setState({ curEmp: r, formMode: "edit" });
  }

  resetData = () => {
    this.setState({data: {searchStr:""}})
  }

  search = async () => {
    let param = {
        "searchString": this.state.data.searchStr,
        "teamId": this.state.curTeam.teamId
    }
    const res = await getEmpsSearch(param, this.state.pageLinks.pageNo);
    // TODO to api response
    if (res.data.success) {
        if (res.data.data.emps.length) {
          this.setState({ emps: res.data.data.emps });
          this.setState({ pageLinks: res.data.data.pageLinks });
        } else {
          toast.toastInfo("No Data found !!!");
        }
    } else {
        toast.toastError(res.data.msg);
    }
}




  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <Players subHeading="Employees" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-8">
              <form>
                <div className="form-row form-group ml-4">
                  {this.rSB("Team", "teamId", this.state.teams, {
                    value: "teamId",
                    label: "name"
                  }, " col-8 mt-1")}
                </div>
              </form>
            </div>
            {this.state.curTeam.teamId !== "" && this.state.emps.length != 0 &&
              <>
                <div className="col-2">
                  {this.rTB("Search....", "searchStr")}
                </div>
                <div className="col-2">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-info pull-left"
                    title=" Search by name"
                    disabled={this.state.data.searchStr === ""}
                    onClick={() => this.search()}
                  >
                    <i className={"fa fa-search"} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-info pull-left"
                    onClick={() =>
                      this.loadEmployees(this.state.curTeam.teamId, this.state.pageLinks.pageNo)
                    }
                    title="Refresh"
                  >
                    <i className={"fa fa-refresh"} />
                  </button>

                </div>
              </>
            }
          </div>


          {this.state.curTeam.teamId === "" && (
            <div class="jumbotron jumbotron-fluid">
              <p class="lead text-center">Select Team  </p>
            </div>
          )}

          {this.state.curTeam.teamId !== "" && this.state.emps.length === 0 && (
            <div class="jumbotron jumbotron-fluid">
              <p class="lead text-center">No data found for selected team</p>
            </div>
          )}

          {this.state.curTeam.teamId !== "" && this.state.emps.length !== 0 && (
            <div className="row ml-3 mr-3">
              <div className="col-6">
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.emps}
                    dropMenu={[]}
                  />
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              </div>
              <div className="col-6">
             
                {this.state.formMode === "edit" &&
                  <EmpCodeForm
                    curEmp={this.state.curEmp}
                    onloadCurTeam={this.loadEmployees}
                    currentTeam={this.state.curTeam}
                    curPage={this.state.pageLinks}
                    key={this.state.curEmp.playerId}
                  />}
              </div>
            </div>
          )}

        </div>
      </React.Fragment>
    );
  }
}
export default Emps;
