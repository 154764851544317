import React from "react";
import ZTitle from "../../_subhead/ZTitle";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import {
  getHubs,
  getClients,
  getActivity,
  getFlows,
  getHubsActive,
} from "../../../services/masters/hubServices";
import _auth from "../../../services/_authService";
import {
  createRequest,
  createRequestTicketActivities,
  getTicketsList,
  getTicketsListNew,
  getEmployeesList,
  getVendorsList,
  validateTicketList,
  getExpenseHeadType,
} from "../../../services/requestV1/requestServices";
import { toast } from "react-toastify";
import swal from "@sweetalert/with-react";
import Table from "../../_common/Table";

class RequestRaise extends FormLib {
  subHeading = "User";

  state = {
    data: {
      request_type: "ticket",
      player_type: "employee",
      expense_type: "directExpense",
      player_code: "",
      hubId: "",
      // client: "",
      activity: "",
      // activity_info: "",
      // flow: "",
      ref_id: "",
      refsNos: [],
      ref_number: "",
      amt: "",
      req_amt: "",
      request_details: "",
      request_by: "",
      override: "false",
      status: "open",
    },
    errors: {},
    hubs: [],
    clients: [],
    activities: [],
    suggestions: [],
    selectedTicketList: [],
    invalidTicketList: [],
    text: "",
    empSuggestions: [],
    selectedEmpCode: "",
    empText: "",
    empName: "",
    venSuggestions: [],
    selectedVenCode: "",
    venText: "",
    venName: "",
    items: [],
    flows: [],
  };

  schema = {
    request_type: Joi.string().required().label("Request Type"),
    player_type: Joi.string().required().label("Raise Type"),
    player_code: Joi.string().required().label("Player Code"),
    hubId: Joi.string().required().label("Hub"),
    // client: Joi.string().required().label("Client"),
    activity: Joi.string().required().label("Activity"),
    expense_type: Joi.optional().label("Expense Type"),
    // flow: Joi.string().required().label("Flow"),
    refsNos: Joi.array().items(Joi.string()).required().label("Ticket Number"),
    ref_number: Joi.string().required().label("Reference number"),

    ref_id: Joi.string().optional().allow("").label("Reference Id"),
    amt: Joi.number().required().min(0).label("Total Amount"),
    req_amt: Joi.number().required().min(0).label("Request Amount"),
    request_details: Joi.string().required().label("Request Details"),
    request_by: Joi.string().required().label("Request by"),
    override: Joi.string().required().label("Override"),
    status: Joi.string().required().label("Status"),
  };

  request_type = [
    { request_typeId: "ticket", request_type: "Ticket" },
    { request_typeId: "hubExpense", request_type: "Hub Expenses" },
    // { request_typeId: "other", request_type: "Other" },
  ];
  player_type = [
    { player_typeId: "employee", player_type: "Employee" },
    { player_typeId: "vendor", player_type: "Vendor" },
  ];

  async componentDidMount() {
    this.loadHubs();
    let list = [];
    let list1 = [];

    list = await getClients();
    if (list !== undefined) {
      this.setState({ clients: list.data.data.clients });
    }

    list = await getActivity();
    if (list !== undefined) {
      this.setState({ activities: list.data.data });
    }

    list = await getFlows();
    if (list !== undefined) {
      this.setState({ flows: list.data.data.flows });
    }

    const user = _auth.getCurrentUser();
    let data = this.state.data;
    data.request_by = user.playerId;

    // data.hubId = user.locationId;

    this.setState({ data });
    list1 = await getExpenseHeadType("ticketExpense");
    // console.log({ list1 });
    if (list1 !== undefined) {
      this.setState({ expenseHeadType: list1.data.data.expenseHeadTypes });
    }
  }

  localHandleChange = async (e) => {
    if (e.target.value === "ticket") {
      const list1 = await getExpenseHeadType("ticketExpense");
      if (list1 !== undefined) {
        this.setState({ expenseHeadType: list1.data.data.expenseHeadTypes });
      }
    } else if (e.target.value === "hubExpense") {
      const list1 = await getExpenseHeadType("hubExpense");
      if (list1 !== undefined) {
        this.setState({ expenseHeadType: list1.data.data.expenseHeadTypes });
      }
      let data = this.state.data;
      data.activity = "";
      this.setState({ data });
    }
    if (e.target.name === "req_amt") {
      if (Number(this.state.data.amt) < Number(e.target.value)) {
        let errors = this.state.errors;
        errors["req_amt"] =
          `Request Amount should be less than or equal to  ` +
          this.state.data.amt;
        this.setState({
          errors,
        });
      } else {
        let errors = this.state.errors;
        delete errors["req_amt"];
        this.setState({
          errors,
        });
      }
      this.validateProperty(e.target.name, e.target.value);
    }
  };

  async doSubmit() {
    if (Number(this.state.data.req_amt) > Number(this.state.data.amt)) {
      // console.log("-------------> " + JSON.stringify(this.state.data.req_amt));
      let errors = this.state.errors;
      errors["req_amt"] =
        `Request Amount should be less than or equal to  ` +
        this.state.data.amt;
      this.setState({
        errors,
      });
      return;
    }
    let nameArr = [];
    if (this.state.data.request_type === "ticket") {
      nameArr = this.state.data.refsNos;
    } else {
      nameArr.push(this.state.data.ref_number);
    }
    let data = {
      hubId: this.state.data.hubId,
      request_type: this.state.data.request_type,
      player_type: this.state.data.player_type,
      player_code: this.state.data.player_code,
      activity: this.state.data.activity,
      ref_id: "",
      refsNos: nameArr,
      expense_type: this.state.data.expense_type,
      amt: this.state.data.amt,
      req_amt: this.state.data.req_amt,
      request_details: this.state.data.request_details,
      request_by: this.state.data.request_by,
      override: this.state.data.override,
      status: this.state.data.status,
    };
    // console.log("data......." + JSON.stringify(data));
    let requestraise = await createRequestTicketActivities(data);
    if (requestraise.data.success === true) {
      toast.success(() => <div>{requestraise.data.msg}</div>);
      document.getElementById("myForm").reset();
      this.reSetFormData();
    } else {
      // toast.error(() => <div>{requestraise.data.msg}</div>);
      this.raisedRequestModal(requestraise);
    }
  }

  raisedRequestModal(requestraise) {
    let requestRaise = requestraise.data.data;
    swal({
      title: requestraise.data.msg + "?",
      content: this.paidData(requestRaise),
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: [true],
      minWidth: "1000px",
    }).then((willDelete) => {
      if (willDelete) {
        let override = this.state.data;
        override["override"] = "true";
        this.setState({ override });
        this.requestCreate(this.state.data);
      } else {
        swal("You can edit now and retry to raise the request again");
      }
    });
  }

  async requestCreate() {
    let nameArr = [];
    if (this.state.data.request_type === "ticket") {
      nameArr = this.state.data.refsNos;
    } else {
      nameArr.push(this.state.data.ref_number);
    }
    let data = {
      hubId: this.state.data.hubId,
      request_type: this.state.data.request_type,
      recurring_type: this.state.data.recurring_type,
      frequency_type: this.state.data.frequency_type,
      dailyType: this.state.data.dailyType,
      weeklyType: this.state.data.weeklyType,
      monthlyType: this.state.data.monthlyType,
      yearlyType: this.state.data.yearlyType,
      player_type: this.state.data.player_type,
      player_code: this.state.data.player_code,
      activity: this.state.data.activity,
      ref_id: "",
      refsNos: nameArr,
      expense_type: this.state.data.expense_type,
      amt: this.state.data.amt,
      req_amt: this.state.data.req_amt,
      request_details: this.state.data.request_details,
      request_by: this.state.data.request_by,
      override: this.state.data.override,
      status: this.state.data.status,
    };
    let requestraise = await createRequestTicketActivities(data);
    if (requestraise.data.success === true) {
      toast.success(() => <div>{requestraise.data.msg}</div>);
      document.getElementById("myForm").reset();
      this.reSetFormData();
    }
  }

  tableMeta = {
    key: "REQUEST_ID",
    columns: [
      { path: "SEQ", label: "Request No." },
      // { path: "TICKET_NO", label: "Ticket No." },
      { path: "AMT", label: "Total Requested Amount" },
      { path: "AMT_APPROVED", label: "Approved Amount" },
      { path: "PAID", label: "Amount Paid" },
      { path: "STATUS", label: "STATUS" },
    ],
  };

  paidData(requestRaise) {
    return (
      <div className="row">
        <Table
          clsName="table table-striped table-bordered table-hover"
          tableMeta={this.tableMeta}
          data={requestRaise}
          dropMenu={[]}
        />
      </div>
    );
  }

  reSetFormData() {
    const user = _auth.getCurrentUser();
    let data = {
      request_type: "ticket",
      player_type: "employee",
      player_code: "",
      expense_type: "directExpense",
      hubId: "",
      // client: "",
      activity: "",
      // activity_info: "",
      // flow: "",
      // ref_id: "",
      refsNos: [],
      ref_number: "",
      amt: "",
      req_amt: "",
      request_details: "",
      request_by: "",
      override: "false",
      status: "open",
    };

    data.request_by = user.playerId;
    data.hubId = user.locationId;
    this.setState({ data });

    this.setState(() => ({ suggestions: [], text: "", selectedTicket: "" }));
    this.setState(() => ({
      empSuggestions: [],
      empText: "",
      empName: "",
      selectedEmpCode: "",
    }));
    this.setState(() => ({
      venSuggestions: [],
      venText: "",
      venName: "",
      selectedVenCode: "",
    }));
  }

  loadHubs = async () => {
    let hubList = JSON.parse(localStorage.getItem("hubs"));
    this.setState({ hubs: hubList });
    // let list = await getHubsActive();
    // if (list !== undefined) {
    //   this.setState({ hubs: list.data.data.hubs });
    // }
    let curHub = localStorage.getItem("curHub");
    const data = { ...this.state.data };
    data.hubId = curHub;
    this.setState({ data });
  };

  // onTextChanged = (e, src = "new") => {
  //   const value = e.target.value;
  //   let suggestions = [];

  //   if (value.length > 8) {
  //     if (src === "new") {
  //       this.getTicketsListNew(value);
  //     } else {
  //       this.getTicketsList(value);
  //     }
  //   }

  //   this.setState(() => ({ suggestions, text: value, selectedTicket: "" }));

  //   let data = this.state.data;
  //   data.refsNos = value;

  //   this.setState({ data });
  // };

  onEmpTextChanged = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let empSuggestions = [];

    if (value.length > 1) {
      this.getEmployeesList(value, name);
    }

    this.setState(() => ({
      empSuggestions,
      empText: value,
      empName: name,
      selectedEmpCode: "",
    }));

    let data = this.state.data;

    data.player_code = value;
    this.setState({ data });
  };

  onVenTextChanged = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let venSuggestions = [];

    if (value.length > 1) {
      this.getVendorsList(value);
    }

    this.setState(() => ({
      venSuggestions,
      venText: value,
      venName: name,
      selectedVenCode: "",
    }));

    let data = this.state.data;

    data.player_code = value;
    this.setState({ data });
  };

  suggestionSelected(value) {
    let ticketlist = this.state.data;
    ticketlist["refsNos"] = value;
    this.setState(() => ({
      ticketlist,
      text: value,
      suggestions: [],
      selectedTicket: value,
    }));
  }

  empSuggestionsSelected(value, name) {
    let employeelist = this.state.data;
    employeelist["player_code"] = value;
    this.setState(() => ({
      employeelist,
      empText: value,
      empName: name,
      empSuggestions: [],
      selectedEmpCode: value,
    }));
  }

  venSuggestionsSelected(value, name) {
    let vendorlist = this.state.data;
    vendorlist["player_code"] = value;
    this.setState(() => ({
      vendorlist,
      venText: value,
      venName: name,
      venSuggestions: [],
      selectedVenCode: value,
    }));
  }

  // renderSuggestions() {
  //   const { suggestions } = this.state;
  //   if (suggestions.length === 0) {
  //     return null;
  //   }
  //   return (
  //     <div className="srchList">
  //       <ul>
  //         {suggestions.map((item) => (
  //           <li onClick={() => this.suggestionSelected(item)}>{item}</li>
  //         ))}
  //       </ul>
  //     </div>
  //   );
  // }

  renderEmpSuggestions() {
    const { empSuggestions } = this.state;
    if (empSuggestions.length === 0) {
      return null;
    }

    return (
      <div className="srchList">
        <ul>
          {empSuggestions.map((item) => (
            <li
              key={item.PLAYER_CODE}
              onClick={() =>
                this.empSuggestionsSelected(item.PLAYER_CODE, item.NAME)
              }
            >
              {item.PLAYER_CODE} [{item.NAME}]
            </li>
          ))}
        </ul>
      </div>
    );
  }

  renderVenSuggestions() {
    const { venSuggestions } = this.state;
    if (venSuggestions.length === 0) {
      return null;
    }

    return (
      <div className="srchList">
        <ul>
          {venSuggestions.map((item) => (
            <li
              key={item.VEN_CODE}
              onClick={() =>
                this.venSuggestionsSelected(item.VEN_CODE, item.NAME)
              }
            >
              {item.VEN_CODE} [{item.NAME}]
            </li>
          ))}
        </ul>
      </div>
    );
  }

  async getTicketsList(value) {
    let suggestions = [];
    let ticketsList = await getTicketsList(value);
    this.setState({ items: ticketsList.data.data.ticketNumbers });
    suggestions = this.state.items;
    this.setState(() => ({ suggestions, text: value }));
  }

  async getTicketsListNew(value) {
    let suggestions = [];
    let ticketsList = await getTicketsListNew(value);
    this.setState({ items: ticketsList.data.data.tickets });
    suggestions = this.state.items;
    this.setState(() => ({ suggestions, text: value }));
  }

  async getEmployeesList(value, name) {
    let empSuggestions = [];
    let employeesList = await getEmployeesList(value);
    this.setState({ items: employeesList.data.data?.empListbyCode });
    empSuggestions = this.state.items;
    this.setState(() => ({
      empSuggestions,
      empText: value,
      empName: name,
    }));
  }

  async getVendorsList(value, name) {
    let venSuggestions = [];
    let vendorsList = await getVendorsList(value);
    this.setState({ items: vendorsList.data.data?.venListbyCode });
    venSuggestions = this.state.items;
    this.setState(() => ({
      venSuggestions,
      venText: value,
      venName: name,
    }));
  }

  localHandleOnBlur = async (e) => {
    if (
      e.target.name === "refsNos" &&
      this.state.data.request_type === "ticket"
    ) {
      this.setState({ selectedTicketList: [] });
      this.setState({ invalidTicketList: [] });

      let searchTickets = this.state.data.refsNos.replace(/\n/g, " ");
      let nameArr = [];
      if (searchTickets.includes(" ") || searchTickets.includes(",")) {
        nameArr = searchTickets.split(/[ ,]+/);
      } else {
        nameArr.push(searchTickets);
      }
      let ticketList = {
        tickets: nameArr,
      };
      let ticketsList = await validateTicketList(ticketList);
      if (ticketsList.data.success === true) {
        this.setState({
          selectedTicketList:
            ticketsList.data.data.validatedTicketData.validTickets,
        });

        this.setState({
          invalidTicketList:
            ticketsList.data.data.validatedTicketData.inValidTickets,
        });
        const data = { ...this.state.data };
        data["refsNos"] =
          ticketsList.data.data.validatedTicketData.validTickets;
        data["ref_number"] = "default";
        this.setState({ data });
      }
    }
  };

  render() {
    const { text } = this.state;
    const { empText, empName } = this.state;
    const { venText, venName } = this.state;
    return (
      <React.Fragment>
        <ZTitle subHeading="Expenses  :: Raise New" />
        <div className="pageContent">
          <div className="card">
            <div className="card-body">
              <form id="myForm" onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-7  border border-gray rounded pt-3 ps-3 mb-3">
                    <div className="form-row  border border-gray rounded pt-4 ps-3 mb-3">
                      <div className="col-md-12">
                        {this.rSB(
                          "Hub *",
                          "hubId",
                          this.state.hubs,
                          { value: "hubId", label: "hub" },
                          " col-md-6 "
                        )}
                      </div>
                    </div>

                    <div className="form-row mt-5  border border-gray rounded pt-4 ps-3 mb-3">
                      <div className="col-md-6">
                        {this.rRBL(
                          "User Type *",
                          "player_type",
                          this.player_type,
                          {
                            value: "player_typeId",
                            label: "player_type",
                          }
                        )}
                      </div>
                      <div className="col-md-6 ">
                        {this.state.data.player_type === "employee" && (
                          <div className="col  form-group ">
                            <input
                              value={empText}
                              name="player_code"
                              onChange={this.onEmpTextChanged}
                              type="text"
                              autoComplete="off"
                            />
                            <label
                              htmlFor="player_code"
                              className="control-label"
                            >
                              Employee Code *
                            </label>
                            <i className="bar"></i>
                            <span className="badge" style={{ color: "blue" }}>
                              {empName}
                            </span>
                            {this.renderEmpSuggestions()}
                          </div>
                        )}
                        {this.state.data.player_type === "vendor" && (
                          <div className="col   form-group ">
                            <input
                              value={venText}
                              name="player_code"
                              onChange={this.onVenTextChanged}
                              type="text"
                              autoComplete="off"
                            />
                            <label
                              htmlFor="player_code"
                              className="control-label"
                            >
                              Vendor Code *
                            </label>
                            <i className="bar"></i>
                            <span className="badge" style={{ color: "blue" }}>
                              {venName}
                            </span>
                            {this.renderVenSuggestions()}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-row mt-4  border border-gray rounded pt-4 ps-3 mb-3">
                      {this.rSB(
                        "Expense Head *",
                        "activity",
                        this.state.expenseHeadType,
                        { value: "expenseHeadId", label: "expenseHead" },
                        " col "
                      )}
                      {this.rNB("Total Amount *", "amt", " col ")}
                      {this.rNB("Expense Amount *", "req_amt", " col ")}
                    </div>
                    <div className="form-row mt-4  border border-gray rounded pt-4 ps-3 mb-3">
                      {this.rTA(
                        "Expense Details *",
                        "request_details",
                        " col "
                      )}
                    </div>
                  </div>

                  <div className="col-5 border border-gray rounded pt-4 ps-3 mb-3">
                    <div className="form-row">
                      <div className="col-md-6">
                        {this.rRBL(
                          "Expense Type *",
                          "request_type",
                          this.request_type,
                          {
                            value: "request_typeId",
                            label: "request_type",
                          }
                        )}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-7 ">
                        {/* {this.state.data.request_type === "hubExpense" &&
                          this.rTB("Reference No *", "refsNos", " col ")} */}
                        {this.state.data.request_type === "hubExpense" && (
                          // <div className="col  form-group">
                          //   {this.rTA1(
                          //     "Search Tickets number",
                          //     "refsNos",
                          //     "mt-3 border highlights mb-1"
                          //   )}

                          // </div>
                          <div className="col  form-group">
                            {this.rTB("Reference No *", "ref_number", " col ")}
                          </div>
                        )}
                        {this.state.data.request_type === "ticket" && (
                          <div className="col  form-group">
                            {this.rTA1(
                              "Ticket numbers",
                              "refsNos",
                              "border highlights mb-1"
                            )}
                            <font color="#c0bebe">
                              For multiple tickets use space commas or newlines
                              *
                            </font>
                          </div>
                        )}
                      </div>
                      <div className="col-md-5 ">
                        {this.state.invalidTicketList.length > 0 && (
                          <>
                            <text
                              style={{
                                fontWeight: "lighter",
                                color: "#ff0000",
                              }}
                            >
                              {"Invalid Ticket Numbers "}
                            </text>

                            {this.state.invalidTicketList.map((item) => (
                              <p key={item}>{item}</p>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* 
                <div className="form-row">
                  <div className="col-md-4">
                    {this.rRBL(
                      "Expense Type *",
                      "request_type",
                      this.request_type,
                      {
                        value: "request_typeId",
                        label: "request_type",
                      }
                    )}
                  </div>
                  <div className="col-md-4">
                    {this.rRBL("User Type *", "player_type", this.player_type, {
                      value: "player_typeId",
                      label: "player_type",
                    })}
                  </div>
                </div> */}

                <div className="button-container">
                  {((this.state.data.player_type === "employee" &&
                    this.state.selectedEmpCode &&
                    this.state.selectedTicketList.length > 0) ||
                    (this.state.data.player_type === "vendor" &&
                      this.state.selectedVenCode &&
                      this.state.selectedTicketList.length > 0) ||
                    (this.state.data.request_type === "hubExpense" &&
                      this.state.data.player_type === "employee" &&
                      this.state.selectedEmpCode) ||
                    (this.state.data.request_type === "hubExpense" &&
                      this.state.data.player_type === "vendor" &&
                      this.state.selectedVenCode)) &&
                    this.rBtnSP("Raise ", "Raise")}{" "}
                  <br />
                  {/* {!this.state.selectedVenCode && "Vendor Code Not Valid"}
                  {this.state.selectedVenCode && "Vendor Code Valid"}

                  <br />
                  {!this.state.selectedTicket && "Ticket Code Not Valid"}
                  {this.state.selectedTicket && "Ticket Code Valid"} */}
                  {this.state.data.player_type === "employee" &&
                    !this.state.selectedEmpCode && (
                      <span className="alert alert-info cusError">
                        Invalid Employee Code
                      </span>
                    )}
                  {this.state.data.player_type === "vendor" &&
                    !this.state.selectedVenCode && (
                      <span className="alert alert-info cusError">
                        Invalid Vendor Code
                      </span>
                    )}
                  {(this.state.selectedTicketList.length === 0 ||
                    this.state.data.refsNos.length === 0) &&
                    this.state.data.request_type === "ticket" && (
                      <span className="alert alert-info cusError">
                        Invalid Ticket Number
                      </span>
                    )}
                  <div className="form-row">
                    <div className="col-md-12">
                      <button
                        className="button-container btn  btn-info  btn-sm pull-right"
                        onClick={() => this.reSetFormData()}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-12 text-center">
                  <p className="mt-5 mb-3 text-muted">&copy; Bizlog</p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default RequestRaise;
