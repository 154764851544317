import React from "react";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import "../../assets/style.css";

const Table = ({ tableMeta, sortColumn, onSort, data, dropMenu, clsName }) => {
  return (
    // <div class="fixTableHead">

    <table className={clsName}>
      <TableHeader columns={tableMeta.columns} />
      <TableBody tableMeta={tableMeta} dropMenu={dropMenu} data={data} />
    </table>
    // </div>
  );
};

export default Table;
