import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import swal from "sweetalert";
import Table from "../../_common/Table";
import $ from "jquery";
import Pagination from "./../../_common/Pagination";
import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";
import {
  getTeamsAll,
  getTeamsByRetailer,
  createTeam,
  saveTeam,
  deleteTeam,
} from "../../../services/players/teamServices";
import { getTeamTypes } from "./../../../services/players/teamTypesService";
class Clients extends FormLib {
  state = {
    data: {
      teamId: "",
      typeId: "retailer",
      code: "",
      name: "",
      nickname: "",
      email: "",
      address1: "",
      address2: "",
      city: "",
      contact1: "",
      contact2: "",
      pincode: "",
      status: "active",
    },
    errors: {},
    formMode: "create",
    players: [],
    teams: [],
    teamTypes: [],
    curTeamTypes: { typeId: "retailer" },
    loading: true,
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };
  schema = {
    teamId: Joi.optional().label("TeamId"),
    typeId: Joi.string().required().label("TypeId*"),
    code: Joi.string().required().label("Code"),
    name: Joi.string()
      .regex(/^[a-zA-Z0-9 .()_!@#$%&*]*$/)
      .required()
      .label("Team Name")
      .error(() => {
        return {
          message: "Allow only characters.",
        };
      }),
    nickname: Joi.string().required().label("Nick Name"),
    email: Joi.string().required().label("Email").email({ minDomainAtoms: 2 }),
    address1: Joi.string().required().label("Address"),
    address2: Joi.optional().label("Address2"),
    city: Joi.string()
      .regex(/^[a-zA-Z ]*$/)
      .required()
      .label("City")
      .error(() => {
        return {
          message: "Allow only characters.",
        };
      }),
    contact1: Joi.string()
      .min(10)
      .max(11)
      .regex(/^[0-9]+$/)
      .required()
      .label("Contact 1")
      .error(() => {
        return {
          message: "Allow only 10/11 digit numbers.",
        };
      }),
    contact2: Joi.string()
      .min(10)
      .max(11)
      .regex(/^[0-9]+$/)
      .optional()
      .label("Contact 2")
      .error(() => {
        return {
          message: "Allow only 10/11 digit numbers.",
        };
      }),
    pincode: Joi.number().integer().optional().label("Pincode"),
    status: Joi.string().required().label("Satus"),
  };
  componentDidMount() {
    // this.loadTeams(this.state.pageLinks.pageNo);
    this.loadTeamType();
    this.loadCurrentTeam("retailer");
  }
  async loadTeams(typeId, pageNo) {
    this.setState({ loading: true });
    const res = await getTeamsByRetailer(typeId, pageNo);
    if (res.data.success) {
      if (res.data.data.length != 0) {
        this.setState({ teams: res.data.data.teams });
        this.setState({ pageLinks: res.data.data.pageLinks });
        toast.toastSuccess(res.data.msg);
      } else {
        this.setState({ teams: [] });
        toast.toastInfo("No data found for selected team type");
      }
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  async loadTeamType() {
    this.setState({ loading: true });
    const res = await getTeamTypes();
    if (res.data.success === true) {
      this.setState({ teamTypes: res.data.data.teamTypes });
    } else {
      toast.toastError("failed");
    }
    this.setState({ loading: false });
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/players/clients/" + pageNo);
    this.loadTeams(this.state.curTeamTypes.typeId, pageNo);
  };
  handleOnTeam(r) {
    let team = {};
    team.teamId = r.teamId;
    team.typeId = r.typeId;
    team.code = r.code;
    team.name = r.name;
    team.nickname = r.nickname;
    team.email = r.email;
    team.address1 = r.address1;
    team.address2 = r.address2;
    team.contact1 = r.contact1;
    team.contact2 = r.contact2;
    team.city = r.city;
    team.pincode = r.pincode;
    team.status = r.status;
    this.setState({ data: team, formMode: "edit" });
    $("#code").attr("disabled", "disabled");
  }
  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createTeam(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveTeam(this.state.data);
      this.resetData();
    }

    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadTeams(
        this.state.curTeamTypes.typeId,
        this.state.pageLinks.pageNo
      );
    } else {
      toast.toastError(res.data.msg);
    }
  }

  doSubmitDelete = async (handleDelete, r) => {
    if (handleDelete) {
      await deleteTeam(r);
      this.loadTeams(
        this.state.curTeamTypes.typeId,
        this.state.pageLinks.pageNo
      );

      swal("Team has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Team is safe!");
    }
  };

  handleDelete = async (r) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, r);
    });
  };

  handleView = (r) => {
    this.setState({ curTeam: r, formMode: "view" });
  };

  loadCurrentTeam = async (typeId, modReset = true) => {
    if (modReset === true) {
      this.setState({ teams: [] });
      this.setState({ curTeamTypes: { typeId: "retailer" } });
    }

    // let currentTypes;
    // const filter = this.state.teamTypes.filter((r) => {
    //   return r.typeId === typeId ? r : null;
    // });

    // if (!filter[0]) {
    //   currentTypes = { typeId: "" };
    // } else {
    //   currentTypes = filter[0];
    // }

    // this.setState({ curTeamTypes: currentTypes });
    if (typeId !== "") {
      this.loadTeams(typeId, this.state.pageLinks.pageNo);
    } else {
      this.setState({ teams: [] });
      this.setState({
        pageLinks: {
          pageNo: 1,
          perPage: 0,
          itemsCount: 0,
          lastPage: 0,
        },
      });
    }
  };

  handleTypeChange = (e) => {
    this.loadCurrentTeam(e.target.value);
  };

  localHandleChange = (e) => {
    if (e.target.id === "typeId") {
      this.handleTypeChange(e);
    }
  };

  resetData() {
    this.setState({
      data: {
        teamId: "",
        typeId: "",
        code: "",
        name: "",
        nickname: "",
        email: "",
        address1: "",
        address2: "",
        city: "",
        contact1: "",
        contact2: "",
        pincode: "",
        status: "active",
      },
      formMode: "create",
    });
    $("#code").removeAttr("disabled");
  }
  tableMeta = {
    key: "teamId",
    columns: [
      {
        key: "TEAM_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnTeam(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleView(r)}
              >
                <i className="fa fa-binoculars" aria-hidden="true"></i> View
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(r)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i> Delete
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      //   { path: "typeId", label: "Type" },
      { path: "code", label: "Code" },
      { path: "name", label: "Name" },
      { path: "nickname", label: "Nick Name" },
      { path: "city", label: "City" },
      { path: "status", label: "Status" },
    ],
  };
  // typeId = [
  //   {
  //     typeId: "trai1642488555599TZf12e16f1ccbf4f4da8ef208c9adf8505",
  //     type: "Training Process",
  //     status: "1",
  //   },
  //   {
  //     typeId: "trai1642488555599TZf12e16f1ccbf4f4da8ef208c9adf8506",
  //     type: "Retailer",
  //     status: "1",
  //   },
  // ];
  // typConf = {
  //   value: "type",
  //   label: "type",
  // };
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Clients" />
        <div className="pageContent">
          {/* <div className="row">
            <div className="col-6 mt-1">
              {this.rSB("Type", "typeId", this.state.teamTypes, {
                value: "typeId",
                label: "type",
              })}
            </div>
          </div> */}
          {this.state.curTeamTypes.typeId === "" && (
            <div class="jumbotron jumbotron-fluid">
              <p class="lead text-center">Select Team Type</p>
            </div>
          )}

          {this.state.curTeamTypes.typeId !== "" && (
            <div className="row">
              <div className="col-md-6 col-sm-12">
                {this.state.loading && (
                  <div className="container-fluid">
                    <div>Loading Teams</div>
                  </div>
                )}
                {!this.state.loading && (
                  <div className="container-fluid">
                    <Table
                      clsName="table table-striped tabele-bordered table-hover"
                      tableMeta={this.tableMeta}
                      data={this.state.teams}
                      dropMenu={[]}
                    />
                    <Pagination
                      pageNo={pageNo}
                      perPage={perPage}
                      itemsCount={itemsCount}
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-6 col-sm-12">
                {this.state.formMode === "view" && (
                  <div className="card">
                    <div className="card-body">
                      <>
                        {this.state.curTeam["name"].trim()}
                        <br />
                        <div className="pull-right">
                          <span
                            className={"badge   badge-light pull-left ml-1 "}
                          >
                            {this.state.curTeam["teamId"]}
                          </span>
                          <span
                            className={"badge   badge-success pull-left  mr-1 "}
                          >
                            {this.state.curTeam["status"]}
                          </span>
                        </div>
                      </>
                    </div>
                  </div>
                )}
                {this.state.formMode !== "view" && (
                  <div className="card pt-4">
                    <div className="card-body">
                      <form>
                        <div className="form-row">
                          {/* {this.rTBH("TeamId", "teamId", "col-6")} */}
                          <div className="col-6 mt-1">
                            {/* {this.rSB("Type", "typeId", this.state.teamTypes, {
                              value: "reatiler",
                              label: "type",
                            })} */}
                          </div>
                          <div className="col-6">
                            {this.rTB("Code", "code")}
                          </div>
                          <div className="col-6">
                            {this.rTB("Name", "name")}
                          </div>
                          <div className="col-6">
                            {this.rTB("Nick Name", "nickname")}
                          </div>
                        </div>
                        <div className="form-row mt-2">
                          {this.rTA("Address1", "address1", "col-md-6")}
                          {this.rTA("Address2", "address2", "col-md-6")}
                        </div>
                        <div className="form-row">
                          {this.rTB("City", "city", "col-6")}
                          {this.rTB("Pincode", "pincode", "col-6 ")}
                        </div>
                        <div className="form-row">
                          {this.rTB("Email", "email", "col-6")}
                          {this.rTB("Contact1", "contact1", "col-6")}
                          {this.rTB("Contact2", "contact2", "col-6")}
                          {this.rRBL(
                            "Status",
                            "status",
                            this.status,
                            this.stsConf
                          )}
                        </div>
                      </form>
                      {this.state.formMode === "create" &&
                        this.rBtnSP("Create", "create")}
                      {this.state.formMode === "edit" && (
                        <>
                          {this.rBtnSS("Edit", "edit")}
                          {this.mResetBtn(
                            () => this.resetData(),
                            " pull-right "
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default Clients;
