
import _http from "./../_httpService";
import config from "./../../config.json";

const baseURL = config[config.build].apiURLCore;
const apiCoreEndpoint = config[config.build].apiURLCore;
function api(path, subURL = "") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getPackingMaterials() {
  return _http.get(api("tracker/settings/packing-material"), _http.headerJWT());
}
export function getActivePackingMaterials() {
  return _http.get(
    api("tracker/settings/packing-material/details/active/1"),
    _http.headerJWT()
  );
}
export function createPackingMaterial(req) {
  const param = { ...req };
  delete param.packMaterialId;
  return _http.post(
    api("tracker/settings/packing-material"),
    param,
    _http.headerJWT()
  );
}
export function savePackingMaterial(req) {
  const param = { ...req };
  const id = param.packMaterialId;
  return _http.put(
    api("tracker/settings/packing-material/" + id),
    param,
    _http.headerJWT()
  );
}
export function deletePackingMaterial(req) {
  const param = { ...req };
  const id = param.packMaterialId;
  return _http.delete(
    api("tracker/settings/packing-material/" + id),
    _http.headerJWT()
  );
}
export function updateBarcodeStatus(req) {
  const param = { ...req };
  const barcode = param.barcode;
  const status = param.status;
  return _http.put(
    api("tracker/settings/barcodes/update-status/" + barcode + "/" + status),
    param,
    _http.headerJWT()
  );
}

export function detachBarcodeStatus(req) {
  const param = { ...req };
  const barcode = param.barcode;
  const status = param.status;
  return _http.put(
    api("tracker/settings/barcodes/detach-status/" + barcode + "/" + status),
    param,
    _http.headerJWT()
  );
}