import React, { component } from "react";
import FormLib from "../../_common/FormLib";
import toast from "../../../services/_toastService";
import Table from "../../_common/Table";
import { getActiveClients } from "../../../services/masterLocations/clientService";
import Joi from 'joi-browser';
import { getSearchItemsStockListByHubIdAndClientId, getTicketStockListByHubIdAndClientId, insertScannedItems } from "../../../services/stockManagement/inventory";
import Pagination from "../../_common/Pagination";
import SearchedItemList from "./InwardNewScreen/SearchedItemList";
import $ from "jquery";
import { IstToUTC, currentDate } from "../../../utils/DateUtils";
import VerifiedItemList from "./InwardNewScreen/VerifiedItemList";

class HubInwardItem extends FormLib {
    state = {
        data: {
            hubId: "",
            teamId: "",
        },
        checked: false,
        barcode: "",
        itemsList: [],
        clients: [],
        searchItemList: [],
        allClients: [],
        openStatus: "true",
        verifyStatus: "true",
        mode: "open",
        scannedDete: "",
        pageLinks: {
            pageNo: 1,
            perPage: 0,
            count: 0,
            lastPage: 0,
        },

    }

    schema = {
        hubId: Joi.string().required().label("hubId"),
        teamId: Joi.string().required().label("ClientId"),
    };

    componentDidMount() {
        this.loadItems(this.state.pageLinks.pageNo);
        this.loadClients();


    }

    componentDidUpdate(prevProps) {
        if (this.props.teamValue !== prevProps.teamValue || this.props.hubValue !== prevProps.hubValue) {

            this.loadItems(this.state.pageLinks.pageNo);
        }
        if (this.props.teamValue !== prevProps.teamValue) {
            const checkbox = document.querySelector('input[name="checkBox"]');
            if (checkbox && checkbox.checked) {
                checkbox.checked = false;
            }
        }
    }
    tableMeta = {
        key: "ticketNo",
        columns: [
            { path: "ticketNo", label: "ticketNo" },

            { path: "slNo", label: "Barcode" },
            {
                key: "slNoId",
                actionKey: "bt",
                action: (r) => (
                    <>
                        {r.scanDateTime != null && (
                            <div>
                                {((new Date(r.scanDateTime) > new Date()) && (

                                    <i className="fa fa-check fa-5x text-success"></i>
                                ))}
                            </div>
                        )}

                    </>
                ),
            }

        ]
    }

    async loadItems(pageNo) {
        const clientResp = await getActiveClients("active");
        let data = clientResp.data.data.clients.map((x) => (
            x.TEAM_ID
        ))
        this.setState({ allClients: data })
        let params = {
            hubIds: [this.props.hubValue],
            clientIds: data,
            inventoryType: "ticket"
        }
        const resp = await getTicketStockListByHubIdAndClientId(params, pageNo);
        if (resp.data.success === true) {
            this.setState({ itemsList: resp.data.data.hubInventoryData })
            this.setState({ pageLinks: resp.data.data.pageLinks });
            this.setState({ barcode: "" })


        } else {
            toast.toastError(resp.data.msg)
        }
    }
    loadItem = async () => {
        const pageNo = this.state.pageLinks.pageNo

        const clientResp = await getActiveClients("active");
        let data = clientResp.data.data.clients.map((x) => (
            x.TEAM_ID
        ))
        this.setState({ allClients: data })
        let params = {
            hubIds: [this.props.hubValue],
            clientIds: data,
            inventoryType: "ticket"

        }
        const resp = await getTicketStockListByHubIdAndClientId(params, pageNo);

        if (resp.data.success === true) {
            this.setState({ itemsList: resp.data.data.hubInventoryData })
            this.setState({ pageLinks: resp.data.data.pageLinks });
            this.setState({ barcode: "" })


        }

    }

    async loadClients() {
        this.setState({ loading: true });
        const clientResp = await getActiveClients("active");

        if (clientResp.data.success === true) {
            this.setState({ clients: clientResp.data.data.clients });
        } else {
            toast.toastError(clientResp.data.msg);
        }
        this.setState({ loading: false });
    }

    handlePageChange = (pageNo) => {
        if (this.state.checked === false) {
            this.loadItems(pageNo);
        } else {
            this.handleCheckBoxClick(pageNo)
        }
    };

    handleChange = async (e) => {
        this.setState({
            barcode: e.target.value
        })
    }

    handleChangeBarcode = async (e) => {
        try {
            if (e.key === "Enter") {
                e.preventDefault()

                const params = {
                    hubIds: [this.props.hubValue],
                    clientIds: this.state.allClients,
                    slNoId: "",
                    slNo: e.target.value,
                    itemId: "",
                    inventoryType: "ticket"
                }

                const resp = await getSearchItemsStockListByHubIdAndClientId(params)
                console.log(resp.data.data.hubInventoryData.data[0])
                const data = resp.data.data.hubInventoryData.data[0].productDetails[0].slNoId;
                const data1 = resp.data.data.hubInventoryData.data[0].hubScanDetails;
                console.log("dataaaaa", data1)
                if (resp.data.success === true && resp.data.data.hubInventoryData.data[0].packMaterialId === "") {


                    const params = {
                        packing: false,
                        slNoId: data,
                        hubId: this.props.hubValue,
                        remarks: "",
                        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
                        hubScanDetails: data1
                    }
                    console.log("jjjjjjjjjjjj", params)
                    const resp = await insertScannedItems(params)
                    if (resp.data.success === true) {
                        toast.toastSuccess(resp.data.msg)
                        this.loadItem();
                        this.setState({ barcode: "" })

                    } else {
                        toast.toastError(resp.data.msg)
                    }

                }
                else if (resp.data.success === true && resp.data.data.hubInventoryData.data[0].packMaterialId !== "") {

                    this.setState({ searchItemList: resp.data.data.hubInventoryData.data })

                    this.setState({ openStatus: "false" })


                }
                else {
                    toast.toastError(resp.data.msg)
                }
            }
        } catch (error) {

        }
    }

    // handleCheck = async (e) =>{
    //     if(e.target.checked === true){
    //         this.setState({checked:true})
    //         this.handleCheckBoxClick(this.state.pageLinks.pageNo,e)
    //     }else{
    //         this.setState({checked:false})
    //         this.handleCheckBoxClick(this.state.pageLinks.pageNo,e)

    //     }
    // }

    // handleCheckBoxClick = async (pageNo,e) => {
    //     try {
    //         if (e.target.checked === true) {
    //             const clientResp = await getActiveClients("active");
    //             let data = clientResp.data.data.clients.map((x) => (
    //                 x.TEAM_ID
    //             ))
    //             this.setState({allClients:data})
    //             let params = {
    //                 hubIds: [this.props.hubValue],
    //                 clientIds: data,
    //                 inventoryType:"ticket"

    //             }
    //             const resp = await getTicketStockListByHubIdAndClientId(params,pageNo);

    //             if (resp.data.success === true) {
    //                 this.setState({ itemsList: resp.data.data.hubInventoryData })
    //     this.setState({ pageLinks: resp.data.data.pageLinks });

    //             }
    //         } else if (e.target.checked === false) {
    //             this.setState({allClients:this.props.teamValue})
    //             const params = {
    //                 hubIds: [this.props.hubValue],
    //                 clientIds: [this.props.teamValue],
    //                 inventoryType:"ticket"

    //             }
    //             const resp = await getTicketStockListByHubIdAndClientId(params,pageNo);
    //             // console.log({resp});

    //             if (resp.data.success === true) {
    //                 toast.toastSuccess(resp.data.msg)
    //                 this.setState({ itemsList: resp.data.data.hubInventoryData })
    //     this.setState({ pageLinks: resp.data.data.pageLinks });

    //             }
    //             else {
    //                 toast.toastError(resp.data.msg)
    //             }
    //         }
    //     } catch {
    //         console.log("Error in handleCheckBoxClick");
    //     }
    // };
    handleView = () => {
        $("#myModalOpen").draggable();
    };

    closeModal = () => {
        this.setState({ openStatus: "true" });
        this.setState({ verifyStatus: "true" })
    };

    handleClickVerify = () => {
        this.setState({ verifyStatus: "false" })
    }




    render() {
        const { pageNo, perPage, count } = this.state.pageLinks;
        const currentRequest = this.state.searchItemList;


        return (
            <React.Fragment>
                <div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>
                            <h1 className='fs-4'> Hub Inward Items</h1>

                        </div>

                        <div style={{ marginTop: "0.5rem", marginLeft: "2rem" }}>
                            <input
                                type="checkbox"
                                name="checkBox"
                                value="checkBox"
                                title="get all clients stockList"
                                checked
                            />
                            <label style={{ paddingLeft: "0.7rem" }}> <h4>Select All Clients</h4> </label>
                        </div>
                        <div style={{ marginLeft: "2rem" }}>
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.handleClickVerify}
                            >
                                Verify
                            </button>
                        </div>
                    </div>
                    <div className='d-flex flex-row ' style={{ columnGap: "4rem", marginLeft: "3rem", marginTop: "1.5rem" }}>

                        <div className="p-2" style={{ marginLeft: "-1rem" }}> <text className='fs-6'>Barcode : <span style={{ color: "red" }}>*</span>  </text>

                            <input className='col-8' placeholder='Enter Barcode' type="text"
                                value={this.state.barcode}
                                onChange={(e) => this.handleChange(e)}
                                onKeyDown={(e) => {
                                    this.handleChangeBarcode(e)
                                }}
                            />
                        </div>
                    </div>
                    <div className="pageContent">
                        <div className="container-fluid">
                            <div className="card-deck">
                                {this.state.itemsList && this.state.itemsList.length > 0 && (
                                    <div className="card-body" style={{ padding: 2 }}>
                                        <Table
                                            clsName="table table-striped border table-hover w-50 h-25 mx-5"
                                            tableMeta={this.tableMeta}
                                            data={this.state.itemsList}
                                            dropMenu={[]}
                                        />
                                        <Pagination
                                            pageNo={pageNo}
                                            perPage={perPage}
                                            itemsCount={count}
                                            onPageChange={this.handlePageChange}
                                        />
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="draggable col-10 ml-5 mr-5"
                    id="myModalOpen"
                    onClick={this.handleView}
                >
                    {this.state.openStatus === "false" && (
                        <SearchedItemList
                            key={this.state.mode}
                            data={currentRequest}
                            hubId={this.props.hubValue}
                            closeModal={this.closeModal}

                            onHandleGetStatus={this.loadItem}


                        />
                    )}

                </div>
                <div
                    className="draggable col-10 ml-5 mr-5"
                    id="myModalOpen"
                    onClick={this.handleView}
                >
                    {this.state.verifyStatus === "false" && (
                        <VerifiedItemList
                            key={this.state.mode}
                            hubId={this.props.hubValue}
                            clientId={this.state.allClients}
                            closeModal={this.closeModal}

                        // onHandleGetStatus={this.loadItem}


                        />
                    )}

                </div>
            </React.Fragment>
        )
    }

}
export default HubInwardItem;