import _http from "./../_httpService";

import config from "./../../config.json";

const apiURLCorePHP = config[config.build].apiURLCorePHP + "projects.php";

function buildUrl(id) {
  return `${apiURLCorePHP}/${id}`;
}

export function getProjects() {
  return _http.get(apiURLCorePHP, _http.headerJWT());
}

export function getProject(id) {
  return _http.get(buildUrl(id), _http.headerJWT());
}
