
import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import { updateAuth } from "../../../services/players/playerServices";
import toast from "../../../services/_toastService";

class EditAuth extends FormLib {
    state = {
        data: {
            userName: "",
            passWord: "",
            comfirmPassword: ""
        },
        errors: {},
        curTeamId: ""
    }
    schema = {
        userName: Joi.string().required().label("Username"),
        passWord: Joi.string().required().label("Password"),
        comfirmPassword: Joi.any().valid(Joi.ref('passWord')).required().label("Comfirm Password ").options({ language: { any: { allowOnly: 'must match password' } } })
    };

    componentDidMount() {
        let curTeamId = this.props.curPlr.teamId;
        this.setState({ curTeamId });
    }


    doSubmit = async () => {
        let res;
        if (this.state.btnValue === "create") {
            res = await updateAuth(this.state.data, this.props.curPlr);
        }
        if (res.data.success) {
            toast.toastSuccess(res.data.msg);
            this.props.onLoadPlayer(this.state.curTeamId, this.props.onLoadPage);
            this.resetData();
            this.props.onReset();
        } else {
            toast.toastError(res.data.msg);
        }
    }
    resetData = () => {
        this.setState({
            data: {
                userName: "",
                passWord: "",
                comfirmPassword: ""
            },
        });
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="modal fade" id="myMaterialModalEditAuth">
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div class="modal-header text-center" style={{ "background-color": "Azure" }}>
                                <span class="modal-title h4 w-100">Edit Authentication</span>
                                <button type="button" class="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body mt-3">
                                <form>
                                    <div className="form-row">
                                        {this.rTB("Username", "userName", "col-12")}
                                        <br /> <br />
                                        {this.rPB("Password", "passWord", "col-12")}
                                        <br /> <br />
                                        {this.rPB("Comfirm Password", "comfirmPassword", "col-12")}
                                    </div>

                                </form>
                                {this.rBtnSP("Submit", "create")}
                                <button
                                    type="button"
                                    className="btn btn-sm btn-warning pull-right"
                                    onClick={() => this.resetData()}
                                    title="Reset"
                                >
                                    <i className={"fa fa-unlink"} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default EditAuth;