import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import swal from "sweetalert";

import Table from "../../_common/Table";
// import Pagination from "../../_common/Pagination";

import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";
import {
  getTeamBankAccs,
  createTeamBankAccs,
  saveTeamBankAccs,
  deleteTeamBankAccs,
} from "../../../services/players/teamBankAccsServices";
import { getTeams } from "./../../../services/players/teamServices";
class TeamBankAccs extends FormLib {
  state = {
    data: {
      accTeamId: "",
      teamId: "",
      accType: "",
      accNo: "",
      name: "",
      ifsc: "",
      bank: "",
      branch: "",
    },
    errors: {},
    formMode: "create",
    teamBankAccs: [],
    teams: [],
    loading: true,
    // pageLinks: {
    //   pageNo: 1,
    //   perPage: 0,
    //   itemsCount: 0,
    //   lastPage: 0,
    // },
  };
  schema = {
    accTeamId: Joi.optional().label("Account Emp Id"),

    teamId: Joi.required().label("Team Id"),
    accType: Joi.string().required().label("Account type"),
    accNo: Joi.string().required().label("Account No"),
    name: Joi.string().regex(/^[a-zA-Z ]*$/).optional().label("Name").error(() => {
      return {
        message: 'Allow only characters.',
      }
    }),
    ifsc: Joi.string().required().label("Ifsc"),
    bank: Joi.required().label("Bank"),
    branch: Joi.string().required().label("Branch"),
  };
  componentDidMount() {
    this.loadTeamBankAccs();
    this.loadTeams();
  }

  //   handlePageChange = (pageNo) => {
  //     this.props.history.push("/accounts/accounts" + pageNo);

  //     this.loadVendAccounts();
  //   };

  async loadTeamBankAccs() {
    this.setState({ loading: true });
    const res = await getTeamBankAccs();
    if (res.data.success === true) {
      // console.log(res.data.data.teamBankAccs);
      this.setState({ teamBankAccs: res.data.data.teamBankAccs });
      // console.log(this.state.teamBankAccs);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  async loadTeams() {
    this.setState({ loading: true });
    const res = await getTeams();
    if (res.data.success === true) {
      // console.log(res.data.data.teams);
      this.setState({ teams: res.data.data.teams });
      // console.log(this.state.teams);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  handleOnTeamBankAccs(t) {
    let teamBankAccs = {};
    teamBankAccs.accTeamId = t.accTeamId;
    teamBankAccs.teamId = t.teamId;
    teamBankAccs.accType = t.accType;
    teamBankAccs.accNo = t.accNo;
    teamBankAccs.name = t.name;
    teamBankAccs.ifsc = t.ifsc;
    teamBankAccs.bank = t.bank;
    teamBankAccs.branch = t.branch;
    this.setState({ data: teamBankAccs, formMode: "edit" });
  }

  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createTeamBankAccs(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveTeamBankAccs(this.state.data);

      this.resetData();
    }

    if (res.data.status === true) {
      toast.toastError(res.data.msg);
      this.loadTeamBankAccs();
    } else {
      toast.toastSuccess(res.data.msg);
      this.loadTeamBankAccs();
    }
  }

  doSubmitDelete = async (handleDelete, t) => {
    if (handleDelete) {
      await deleteTeamBankAccs(t);
      this.loadTeamBankAccs();

      swal("Team Bank Account has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Team Bank Account is safe!");
    }
  };

  handleDelete = async (t) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, t);
    });
  };

  handleView = (t) => {
    this.setState({ curTeamBankAccName: t, formMode: "view" });
  };

  resetData() {
    this.setState({
      data: {
        accTeamId: "",
        teamId: "",
        accType: "",
        accNo: "",
        name: "",
        ifsc: "",
        bank: "",
        branch: "",
      },
      formMode: "create",
    });
  }

  tableMeta = {
    key: "accTeamId",
    columns: [
      // { path: "teamId", label: "Team Id" },
      {
        key: "ACC_TEAM_ID",
        action: (t) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnTeamBankAccs(t)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleView(t)}
              >
                <i className="fa fa-binoculars" aria-hidden="true"></i> View
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(t)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i> Delete
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      { path: "name", label: "Name" },
      { path: "bank", label: "Bank" },
      { path: "branch", label: "Branch" },
      { path: "accType", label: "Account Type" },
      { path: "accNo", label: "Account No" },
      { path: "ifsc", label: "IFSC" },
    ],
  };
  actVal = [
    { sortKey: "SB Account", sort: "SB Account" },
    { sortKey: "Current", sort: "Current" },
  ];
  actConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Team Bank Accs" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Accounts</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.teamBankAccs}
                    dropMenu={[]}
                  />
                  {/* <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  /> */}
                </div>
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              {this.state.formMode === "view" && (
                <div className="card">
                  <div className="card-body">
                    <>
                      {this.state.curTeamBankAccName["name"].trim()}
                      <br />
                      <div className="pull-right">
                        <span className={"badge   badge-light pull-left ml-1 "}>
                          {this.state.curTeamBankAccName["accTeamId"]}
                        </span>
                        <span
                          className={"badge   badge-success pull-left  mr-1 "}
                        >
                          {this.state.curTeamBankAccName["status"]}
                        </span>
                      </div>
                    </>
                  </div>
                </div>
              )}
              {this.state.formMode !== "view" && (
                <div className="card">
                  <div className="card-body">
                    <form>
                      {this.rTBH("Account Team Id", "accTeamId", "col-4")}
                      {/* {this.rTB("Player Id", "PLAYER_ID", "col-3")} */}
                      {this.rSB("Team", "teamId", this.state.teams, {
                        value: "teamId",
                        label: "name",
                      })}

                      <div className="form-row">
                        {this.rTB("Name", "name", "col-6")}
                        {this.rTB("Account No", "accNo", "col-6")}
                      </div>
                      <div className="form-row ">
                        {this.rTB("Bank", "bank", "col-4")}
                        {this.rTB("Branch", "branch", "col-4")}
                        {this.rTB("IFSC", "ifsc", "col-4")}
                      </div>
                      <div className="form-row">
                        {this.rRBL("Account Type", "accType", this.actVal, {
                          value: "sortKey",
                          label: "sort",
                        })}
                      </div>
                    </form>
                    {this.state.formMode === "create" &&
                      this.rBtnSP("Create", "create")}
                    {this.state.formMode === "edit" && (
                      <>
                        {this.rBtnSS("Edit", "edit")}
                        {this.mResetBtn(() => this.resetData(), " pull-right ")}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <span>{JSON.stringify(this.state.data)}</span> */}
        </div>
      </React.Fragment>
    );
  }
}
export default TeamBankAccs;
