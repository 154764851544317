import React from "react";
import FormLib from "../../../_common/FormLib";
import Table from "../../../_common/Table";

class BarcodesListModal extends FormLib {
    state = {
        barcodeDataList: [],
    };

    tableMeta = {
        key: "slNoId",
        columns: [
            { path: "slNo", label: "Barcode" },
            { path: "item", label: "ItemName" },
            { path: "status", label: "BarcodeStatus" },
        ],
    };

    render() {
        const barcodeDataList = this.props.barcodeData;
        console.log({ barcodeDataList });

        return (
            <div className="container-fluid">
                <div className="modal fade" id="myBarcodeModalSlNo">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content" style={{ maxHeight: "100vh" }}>
                            <div className="modal-header">
                                <h4 className="modal-title">BarcodeDetails</h4>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body ">
                                <div
                                    className="fixTableHead"
                                    style={{ height: "32rem", overflow: "scroll" }}
                                >
                                    {barcodeDataList && barcodeDataList.length === 0 && (
                                        <p>No Data Found</p>  
                                    )}
                                    {barcodeDataList && barcodeDataList.length > 0 && (
                                        <Table
                                        clsName="table table-striped tabele-bordered table-hover"
                                        tableMeta={this.tableMeta}
                                        data={barcodeDataList}
                                        dropMenu={[]}
                                        />
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default BarcodesListModal;
