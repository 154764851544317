import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import {
  setTicketActions,
  getTicketClosingStatus,
  getPackingMaterialAndProdByJobId,
  getPackingMaterialAndProdByJobIdAdvExc,
} from "./../../../services/Tickets/TicketProcessServices";
import { ticketListReload } from "../../../store";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import TicketDetails from "./../TicketHistory/TicketDetails";
import RTOProcess from "./RTOProcess";
import RescheduleProcess from "./RescheduleProcess";
import BulkQCProcess from "./BulkQCProcess";

import { IstToUTC } from "../../../utils/DateUtils";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { getImages } from "./../../../services/Tickets/TicketServices";
import { setTicketActionsTicketCancel } from "./../../../services/Tickets/TicketProcessServices";
import swal from "sweetalert";
import PickUpVerificationInventory from "./PickUpVerificationInventory";

import DropConfirmItemTicket from "./DropConfirmItemTicket";
import PickUpVerificationProduct from "./PickUpVerificationProduct";

class TicketClosure extends FormLib {
  state = {
    data: {
      photosVerified: "",
      barcodesVerified: "",
      status: "",
      remarks: "",
    },
    setImages: [],
    errors: {},
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    jobFeInventory: [],
    isInventoryVerified: false,
    isProductVerified: false,
    billingStatus: {},
    closingStatus: [],
    dropItemsVerified: false,
    ticketClosingStatus:"positive",

    // status: [
    //   {
    //     status: "Ticket Closed Successfully",
    //     statusId: "Ticket Closed Successfully",
    //   },
    //   {
    //     status: "Customer Reject to Coordinator At Initial Call",
    //     statusId: "Customer Reject to Coordinator At Initial Call",
    //   },
    //   {
    //     status: "Customer Rejected At Pickup Time",
    //     statusId: "Customer Rejected At Pickup Time",
    //   },
    //   {
    //     status: "Customer Rejected At Drop Time",
    //     statusId: "Customer Rejected At Drop Time",
    //   },
    //   {
    //     status: "Ticket Cancelled By Client",
    //     statusId: "Ticket Cancelled By Client",
    //   },
    //   {
    //     status: "QC FAIL",
    //     statusId: "QC FAIL",
    //   },
    //   {
    //     status: "RTO DONE",
    //     statusId: "RTO Done",
    //   },
    //   {
    //     status: "Lost",
    //     statusId: "Lost",
    //   },
    //   {
    //     status: "Lost in Linehaul",
    //     statusId: "Lost in Linehaul",
    //   },
    //   {
    //     status: "Damaged",
    //     statusId: "Damaged",
    //   },
    //   {
    //     status: "Damaged in Linehaul",
    //     statusId: "Damaged in Linehaul",
    //   },

    //   // {
    //   //   status: "Others",
    //   //   statusId: "Others",
    //   // },
    // ],
    pageHide: "false",
    closeStatus: "false",
    cancelStatus: "false",
    isProductNotAttempted: "false",
  };
  includeCancelTicket = [
    { sortKey: "yes", sort: "Do you want to cancel Ticket??" },
  ];
  schema = {
    photosVerified: Joi.string().required().label("photosVerified"),
    barcodesVerified: Joi.string()
      .required()
      .allow(null, "")
      .label("barcodesVerified"),
    status: Joi.string().required().label("status"),
    remarks: Joi.string().required().label("remarks"),
    includeCancelTicket: Joi.string().optional().label("includeCancelTicket"),
  };

  componentDidMount() {
    try {
      this.loadPackingMaterialData();
      this.loadPackingMaterialData1();
      this.getlocalDateTime();
      this.resizeTicketList();
      this.loadInit();
      let pageHide = this.props.hideAction;
      this.setState({ pageHide });
      this.setState({ curStatus: this.props.curStatus });
      this.loadClosure(this.props.curStatus);
      this.loadImages();
    } catch {
      console.log("Error in TicketClosure>>>>>>componentDidMount");
    }
  }

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }

  loadInit() {
    try {
      const curTicketDetails = this.props.ticketDetails;
      const curBlockDetails = this.props.blockDetails;
      const curProductDetails = this.props.ticketDetails.products;
      // console.log(JSON.stringify(curBlockDetails));
      this.prodVerification(curProductDetails);

      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails });
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.setState({ designation: userInfo.designation });
      const parsedResultSet = JSON.parse(curBlockDetails.blockInventoryFe);
      this.setState({ jobFeInventory: parsedResultSet.jobFeInventory });
    } catch {
      console.log("Error in TicketClosure>>>>>>loadInit");
    }
  }

  prodVerification = async (curProductDetails) => {
    curProductDetails.map((r) => {
      if (r.prodQcStatus === "assign" || r.prodQcStatus === "open") {
        this.handleConfirmProductVerification();
      }
    });
  };
  async loadImages() {
    try {
      const processId = this.props.ticketDetails.processId;
      let res = await getImages(processId);
      if (res !== undefined) {
        if (res.data.success === true && res.data.data) {
          this.setState({ setImages: res.data.data.images });
        } else if (res.data.success === false) {
          toast.error("No Images Available for this Block");
        }
      }
    } catch {
      console.log("Error in load images");
    }
  }

  loadClosure(curStatus) {
    try {
      // OLD_PRODUCT_DAMAGED_EXCHANGE_CANCELLED
      if (curStatus === "RTO_REQUEST") {
        this.setState({ rtoStatus: "true" });
      } else if (curStatus !== "RTO_REQUEST") {
        this.setState({ rtoStatus: "false" });
      }

      if (
        curStatus === "RESCHEDULE_REQUEST_FE_AT_PICKUP" ||
        curStatus === "RESCHEDULE_REQUEST_FE_AT_DROP"
      ) {
        this.setState({ rescheduleStatus: "true" });
      } else {
        this.setState({ rescheduleStatus: "false" });
      }
    } catch {
      console.log("Error in TicketClosure>>>>>>loadClosure");
    }
  }

  photosVerification = [{ label: "All Photos are Verified", value: "yes" }];

  barcodeVerification = [{ label: "All Barcodes are Verified", value: "yes" }];

  doSubmit = async () => {
    try {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));

      let override = { ...this.state.data };
      override["actionCode"] = "wBlockClosed";
      override["action"] = "Confirm Drop";
      // this.setState({ override });

      let resultSet = {
        processId: this.state.curBlockDetails.processId,
        blockId: this.state.curBlockDetails.ticketBlockId,
        photosVerified: this.state.data.photosVerified,
        barcodesVerified: this.state.data.barcodesVerified,
        closeByType: userInfo.designation,
        closeById: userInfo.playerId,
        closeByName: userInfo.username,
        ticketClosingStatus: this.state.data.status,
        closingRemarks: this.state.data.remarks,
        actionStatus: "BLOCK_CLOSED",
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        closingStatus:this.state.ticketClosingStatus,
      };
      // Validation: to verify inventory if blockInventory or blockInventoryUnused exists
      // console.log("blockInventory", this.state.curBlockDetails?.blockInventory)
      // console.log("blockInventoryVerified", this.state.curBlockDetails?.blockInventoryVerified)
      // console.log("blockInventoryUnused in ticketClosure", this.state.curBlockDetails?.blockInventoryUnused)

      if (
        this.state.curBlockDetails?.blockInventory &&
        !(
          this.state.curBlockDetails?.blockInventoryVerified ||
          this.state.curBlockDetails?.blockInventoryUnused
        )
      ) {
        // console.log("isInventoryVerified", this.state.isInventoryVerified);
        if (this.state.isInventoryVerified === false) {
          swal({
            icon: "error",
            title: "Kindly verify the inventory ",
            dangerMode: true,
          }).then((res) => {
            return null;
          });
          return null;
        }
      }
      if (
        this.state.curBlockDetails.blockStatus === "EVALUATION_RESULT_DONE" &&
        this.state.curTicketDetails.flowId === "BulkQC"
      ) {
        // console.log("isProductVerified.....", this.state.isProductVerified);
        if (this.state.isProductVerified === true) {
          swal({
            icon: "error",
            title: "Kindly Check Products Status",
            dangerMode: true,
          }).then((res) => {
            return null;
          });
          return null;
        }
      }

      let resp = await setTicketActions(
        override,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        resultSet
      );
      if (resp !== undefined) {
        if (resp.data.success === true) {
          if (resp.data.data) {
            this.handleTicketListReload();
            this.setState({ closeStatus: "true" });
            this.setState({ rtoStatus: "false" });
            this.setState({ rescheduleStatus: "false" });
          } else {
            toast.warning(() => <div>{resp.data.msg}</div>);
          }
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      }
    } catch {
      console.log("Error in TicketClosure>>>>>>doSubmit");
    }
  };

  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };
  // setCurTicketCloseStatus = (closedStatus) => {
  //   this.setState({ closedStatusId: closedStatus.status });
  //   this.state.data.status = closedStatus.status;
  // };
  setCurTicketCloseStatus = (closedStatus) => {
    this.setState({ closedStatusId: closedStatus });
    const data = { ...this.state.data };
    data["status"] = closedStatus;
    this.setState({ data });
  };
  resizeTicketList = () => {
    $("#cocards").css("max-height", $(window).height() - 508);
  };

  replaceString = (str) => {
    return str.replace(/_/g, " ");
  };
  stringReplace(data) {
    return data
      .replace(/{/g, "")
      .replace(/}/g, "")
      .replace(/]/g, "")
      .replace(/"/g, "")
      .replace(/:/g, " : ")
      .replace(/,/g, " , ");
  }
  checkIloaded(t) {
    // console.log(JSON.stringify(t.actionCode));
    // if (t.actionCode === "mFrntPhoto") {
    //   alert("confirm Front Photo");
    // }
    // if (t.actionCode === "mBackPhoto") {
    //   alert("confirm Back Photo");
    // }
    // // "actionCode":"mFrntPhoto"
  }
  handleCancelTicket = async () => {
    swal({
      title: "Are you sure want to Cancel the Ticket?",
      text: "Kindly Enter the Reason for Ticket Cancellation",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: "input",
      placeholder: "Write something",
    }).then(async (handleCancelTicket) => {
      this.doCancelTicket(handleCancelTicket);
    });
  };
  doCancelTicket = async (handleCancelTicket) => {
    if (handleCancelTicket) {
      // alert(handleRTO)
      swal(
        "Ticket has been successfully Cancelled with the following remarks: " +
          handleCancelTicket,
        {
          icon: "success",
        }
      );
      this.submitCancelTicket(handleCancelTicket);
    } else {
      swal("Ticket not cancelled!", { icon: "error" });
    }
  };
  submitCancelTicket = async (handleCancelTicket) => {
    let resultSet = {
      remarks: handleCancelTicket,
      actionStatus: "OPS_CANCALLATION",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    let data = this.state.data;
    data = {
      actionCode: "wOPSCancelled",
      action: "Ticket Cancelled",
    };
    this.setState({ data });

    let resp = await setTicketActionsTicketCancel(
      this.state.data,
      this.state.curTicketDetails,
      this.state.curBlockDetails,
      resultSet
    );
    if (resp !== undefined) {
      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ cancelStatus: "true" });
          // this.setState({ rtoStatus: "false" });
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
  };

  handleConfirmInventoryVerification = () => {
    this.setState({ isInventoryVerified: true });
    this.handleTicketListReload();
  };
  handleConfirmProductVerification = () => {
    this.setState({ isProductVerified: true });
  };
  handleNotAttemptProductVerification = () => {
    this.handleTicketListReload();
    this.setState({ isProductVerified: false });
    this.setState({ isProductNotAttempted: "true" });
  };

  handlePostiveClick = async (e) => {
    const response = await getTicketClosingStatus(
      this.state.curTicketDetails.ticketNo
    );
    this.setState({
      closingStatus: response.data.data.closingStatus.closingStatusPositive,
    });
    this.setState({
      billingStatus: response.data.data.closingStatus.billingStatus,
      ticketClosingStatus:"positive"
    });
  };

  handleNegativeClick = async (e) => {
    const response = await getTicketClosingStatus(
      this.state.curTicketDetails.ticketNo
    );

    this.setState({
      closingStatus: response.data.data.closingStatus.closingStatusNegative,
    });
    this.setState({
      billingStatus: response.data.data.closingStatus.billingStatus,
      ticketClosingStatus:"negative"
    });
    // closingStatusNegative
  };
 loadPackingMaterialData  = async () => {
    try {
      let blockData = this.props.blockDetails;
      let ticketData = this.props.ticketDetails;
      let res = await getPackingMaterialAndProdByJobId(blockData, ticketData);
      if (res !== undefined) {
        if (res.data.success === true && res.data.data ) {
          this.getPackingMaterialAndProdStatus(
            res.data.data.packingMaterialDetails
          );
          this.setState({ confirmData: true });
        } else {
          this.setState({ confirmData: false });
        }
      } else {
        this.setState({ confirmData: false });
      }
    } catch {
      console.log(
        "Error in loadPackingMaterialData>>>>>>>>>>>>>>>>>>>>>>>>>>PickUp"
      );
    }
  };
  loadPackingMaterialData1  = async () => {
    try {
      let blockData = this.props.blockDetails;
      let ticketData = this.props.ticketDetails;
      let res = await getPackingMaterialAndProdByJobId(blockData, ticketData);
      if (res !== undefined) {
        console.log("kkkkkkkkkkkkkkk",res.data.success)
        if (res.data.success === true && res.data.data ) {
          this.getPackingMaterialAndProdStatus1(
            res.data.data.packingMaterialDetails
          );
          this.setState({ confirmData: true });
        } else {
          this.setState({ confirmData: false });
        }
      } else {
        this.setState({ confirmData: false });
      }
    } catch {
      console.log(
        "Error in loadPackingMaterialData>>>>>>>>>>>>>>>>>>>>>>>>>>PickUp"
      );
    }
  };
  getPackingMaterialAndProdStatus1 = async (data) => {
    // console.log("data======>", data)
    if (data.length > 0) {
      data.map((r) => {
        if (r.packMaterialId) {
          if (r.packMaterialItemStatus !== null) {
            this.setState({rejectItemsVerify: true });
            // this.forceUpdate();
          } else {
            this.setState({ rejectItemsVerify: false });
          }
        } else {
          if (r.productDetails[0].itemStatus === "itemsRejected") {
            this.setState({ rejectItemsVerify: true });
            // this.forceUpdate();
          } else {
            this.setState({ rejectItemsVerify: false });
          }
        }
      });
    } else {
      this.setState({ dropItemsAssigned: true });
      this.setState({ confirmData: false });
    }
  };
  getPackingMaterialAndProdStatus = async (data) => {
    if (data.length > 0) {
      data.map((r) => {
        if (r.packMaterialId) {
          if (
            r.packMaterialItemStatus === "itemsDropVerified" ||
            r.packMaterialItemStatus === "itemsRejected" ||
            ((r.packMaterialItemStatus === null ||
              r.packMaterialItemStatus === "") &&
              (this.state.curTicketDetails.flowCode === "ADVEX" ||
                this.state.curTicketDetails.flowCode === "INSV1" ||
                this.state.curBlockDetails.blockStatus.includes("REJECT_")))
          ) {
            this.setState({ dropItemsVerified: true });

            // this.forceUpdate();
          } else {
            this.setState({ dropItemsVerified: false });
          }
        } else {
          if (
            r.productDetails[0].itemStatus === "itemsDropVerified" ||
            r.productDetails[0].itemStatus === "itemsRejected" ||
            ((r.packMaterialItemStatus === null ||
              r.packMaterialItemStatus === "") &&
              (this.state.curTicketDetails.flowCode === "ADVEX" ||
                this.state.curTicketDetails.flowCode === "INSV1" ||
                this.state.curBlockDetails.blockStatus.includes("REJECT_")))
          ) {
            this.setState({ dropItemsVerified: true });
            // this.forceUpdate();
          } else {
            this.setState({ dropItemsVerified: false });
          }
        }
      });
    } else {
      this.setState({ dropItemsAssigned: true });
      this.setState({ confirmData: false });
    }
  };
  render() {
    const ticketDetails = this.state.curTicketDetails;
    
    // console.log("blockStatuss",this.state.curBlockDetails.blockStatus)
    return (
      <>
        <div className="row  ">
          {this.state.rtoStatus === "false" &&
            // this.state.rescheduleStatus === "false" &&
            this.state.pageHide === "false" &&
            this.state.closeStatus === "false" &&
            this.state.cancelStatus === "false" &&
            this.state.isProductNotAttempted === "false" && (
              <>
                {(this.state.curBlockDetails.blockStatus ===
                  "INSTALL_SERVICE_DONE" ||
                  this.state.curBlockDetails.blockStatus.includes("REJECT_") ||
                  this.state.curTicketDetails.flowCode === "DRPOF" ||
                  this.state.curTicketDetails.flowId === "BulkQC" ||
                  this.state.rescheduleStatus === "true") && (
                  <div className="col-md-12">
                    <div className="card ">
                      <div className="card-body bg-light">
                        <div className="form-subHeader">
                          <b>{ticketDetails.ticketNo}</b>
                          {this.state.designation === "Operations Head" && (
                            <div
                              className="pull-right"
                              style={{ marginTop: "-1%", marginRight: "23%" }}
                            >
                              {this.rCBHL(
                                "includeCancelTicket",
                                this.includeCancelTicket,
                                {
                                  value: "sortKey",
                                  label: "sort",
                                }
                              )}
                            </div>
                          )}
                          {this.state.data.includeCancelTicket === "yes" && (
                            <div
                              className="pull-right"
                              style={{ marginTop: "1%", marginRight: "-40%" }}
                            >
                              <button
                                title="Cancel Ticket"
                                className="badge badge-danger  pull-right"
                                type="button"
                                onClick={() => this.handleCancelTicket()}
                                style={{
                                  fontSize: "82%",
                                  borderRadius: "2.25rem ",
                                  border: "none",
                                  fontWeight: "bold",
                                  marginRight: "6px",
                                }}
                              >
                                CANCEL TICKET
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="form-subHeader">
                          Before Packing Photos Verification
                        </div>

                        {/* {
                this.state.setImages.map((t) => {
                    return <> {t.actionCode !== "mSignature" && t.actionCode !== "mPhotoId" && t.actionCode !== "mPhotoAftrPackng" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                    </span></>)}
                    </>
                })
            } */}
                        {this.state.setImages.length !== 0 && (
                          <div className="row">
                            {this.state.setImages.map((t) => {
                              return (
                                <>
                                  {t.actionCode !== "mSignature" &&
                                    t.actionCode !== "mPhotoId" &&
                                    t.actionCode !== "mPhotoAftrPackng" && (
                                      <div className="col-md-4">
                                        <span>
                                          <a
                                            href="javascript:void(0);"
                                            data-toggle="tooltip"
                                            title={this.stringReplace(
                                              t.geoLocation
                                            )}
                                          >
                                            <img
                                              src={t.imageUrl}
                                              style={{
                                                width: "150px",
                                                height: "150px",
                                                margin: "3px",
                                              }}
                                              onload={this.checkIloaded(t)}
                                            />
                                          </a>
                                          <br></br>
                                          <span
                                            className="badge badge-info link-over ml-2"
                                            style={{
                                              width: "auto",
                                              height: "auto",
                                              margin: "3px",
                                            }}
                                          >
                                            {t.action}
                                          </span>
                                        </span>
                                        <br></br>
                                        <br></br>
                                      </div>
                                    )}
                                </>
                              );
                            })}
                          </div>
                        )}
                        {ticketDetails.flowCode !== "PND2W" && (
                          <div className="form-subHeader mt-2">
                            After Packing Photos Verification
                          </div>
                        )}
                        {/* {
                this.state.setImages.map((t) => {
                    return <> {t.actionCode === "mPhotoAftrPackng" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}>
                    <img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a>
                    <span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                    </span></>)}
                    </>
                })
            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode === "mPhotoAftrPackng" && (
                                  <div className="col-md-4">
                                    <span>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="tooltip"
                                        title={this.stringReplace(
                                          t.geoLocation
                                        )}
                                      >
                                        <img
                                          src={t.imageUrl}
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            margin: "3px",
                                          }}
                                        />
                                      </a>
                                      <br></br>
                                      <span
                                        className="badge badge-warning link-over ml-2"
                                        style={{
                                          width: "auto",
                                          height: "auto",
                                          margin: "3px",
                                        }}
                                      >
                                        {t.action}
                                      </span>
                                    </span>
                                    <br></br>
                                    <br></br>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                        <div className="form-subHeader mt-2">
                          Photo Id Verification
                        </div>
                        {/* {
                this.state.setImages.map((t) => {
                    return <> {t.actionCode === "mPhotoId" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                    </span></>)}
                    </>
                })
            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode === "mPhotoId" && (
                                  <div className="col-md-4">
                                    <span>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="tooltip"
                                        title={this.stringReplace(
                                          t.geoLocation
                                        )}
                                      >
                                        <img
                                          src={t.imageUrl}
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            margin: "3px",
                                          }}
                                        />
                                      </a>
                                      <br></br>
                                      <span
                                        className="badge badge-danger link-over ml-2"
                                        style={{
                                          width: "auto",
                                          height: "auto",
                                          margin: "3px",
                                        }}
                                      >
                                        {t.action}
                                      </span>
                                    </span>
                                    <br></br>
                                    <br></br>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                        <div className="form-subHeader mt-2">
                          Signature Verification
                        </div>
                        {/* {
                this.state.setImages.map((t) => {
                    return <> {t.actionCode === "mSignature" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{this.replaceString(t.action)}</span>
                    </span></>)}
                    </>
                })
            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode === "mSignature" && (
                                  <div className="col-md-4">
                                    <span>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="tooltip"
                                        title={this.stringReplace(
                                          t.geoLocation
                                        )}
                                      >
                                        <img
                                          src={t.imageUrl}
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            margin: "3px",
                                          }}
                                        />
                                      </a>
                                      <br></br>
                                      <span
                                        className="badge badge-success link-over ml-2"
                                        style={{
                                          width: "auto",
                                          height: "auto",
                                          margin: "3px",
                                        }}
                                      >
                                        {this.replaceString(t.action)}
                                      </span>
                                    </span>
                                    <br></br>
                                    <br></br>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* {(this.state.curTicketDetails.retailerCode === "DRP") && ( */}
                <PickUpVerificationInventory
                  productDetails={this.state.curProductDetails}
                  ticketDetails={this.state.curTicketDetails}
                  blockDetails={this.state.curBlockDetails}
                  key={"bt_" + this.state.curBlockDetails.ticketBlockId}
                  handleConfirmInventoryVerification={
                    this.handleConfirmInventoryVerification
                  }
                />
                {/* )} */}

                {this.state.rescheduleStatus === "false" && (
                  <div className="col-md-12">
                    <div className="card ">
                      <div className="card-body bg-light">
                        {(this.state.curBlockDetails.blockStatus ===
                          "EVALUATION_RESULT_DONE" ) && (
                          <BulkQCProcess
                            ticketDetails={this.state.curTicketDetails}
                            blockDetails={this.state.curBlockDetails}
                            productDetails={this.state.curProductDetails}
                            key={
                              "bt_" + this.state.curBlockDetails.ticketBlockId
                            }
                            handleNotAttemptProductVerification={
                              this.handleNotAttemptProductVerification
                            }
                          />
                        )}
                        {/* {this.state.dropItemsVerified === false &&
                          (this.state.curTicketDetails.flowCode === "DRPOF" ||
                            this.state.curBlockDetails.blockStatus.includes(
                              "REJECT_"
                            )) && (
                            <div className="row">
                              <form onSubmit={this.handleSubmit}>
                                <DropConfirmItemTicket
                                  productDetails={this.state.curProductDetails}
                                  ticketDetails={this.state.curTicketDetails}
                                  blockDetails={this.state.curBlockDetails}
                                  key={
                                    "p_" +
                                    this.state.curBlockDetails.ticketBlockId
                                  }
                                  onHandleGetConfirmStatus={
                                    this.loadPackingMaterialData
                                  }
                                />
                              </form>
                            </div>
                          )} */}
                        {/* {(this.state.dropItemsVerified === true ||
                          this.state.confirmData === false) && ( */}
                          <form onSubmit={this.handleSubmit}>
                            <div className="form-subHeader">
                              <b>
                                Ticket Closure Verification -{" "}
                                {ticketDetails.ticketNo}
                              </b>
                            </div>
                            <div>
                              {this.state.curBlockDetails.blockStatus === "REJECT_SERVICE_FE_AT_PICKUP" && this.state.confirmData === true &&this.state.rejectItemsVerify === false&&(
                                  <PickUpVerificationProduct
                                  productDetails={this.state.curProductDetails}
                                  ticketDetails={this.state.curTicketDetails}
                                  blockDetails={this.state.curBlockDetails}
                                  key={
                                    "p_" +
                                    this.state.curBlockDetails.ticketBlockId
                                  }
                                  onHandleGetConfirmStatus={
                                    this.loadPackingMaterialData1
                                  }
                                />
                              )}
                               {/* {this.state.curBlockDetails.blockStatus === "REJECT_SERVICE_FE_AT_PICKUP" && this.state.curTicketDetails.flowId === "AdvanceExchange" && this.state.confirmData === true &&this.state.rejectItemsVerify === false&&(
                                  <PickUpVerificationProduct
                                  productDetails={this.state.curProductDetails}
                                  ticketDetails={this.state.curTicketDetails}
                                  blockDetails={this.state.curBlockDetails}
                                  key={
                                    "p_" +
                                    this.state.curBlockDetails.ticketBlockId
                                  }
                                  onHandleGetConfirmStatus={
                                    this.loadPackingMaterialData1
                                  }
                                />
                              )} */}
                                {this.state.curBlockDetails.blockStatus === "REJECT_SERVICE_FE_AT_DROP" && this.state.confirmData === true && (
                                  <PickUpVerificationProduct
                                  productDetails={this.state.curProductDetails}
                                  ticketDetails={this.state.curTicketDetails}
                                  blockDetails={this.state.curBlockDetails}
                                  key={
                                    "p_" +
                                    this.state.curBlockDetails.ticketBlockId
                                  }
                                  onHandleGetConfirmStatus={
                                    this.loadPackingMaterialData1
                                  }
                                />
                              )}
                            </div>
                            <div>
                            {/* {(this.state.curBlockDetails.blockStatus === "REJECT_SERVICE_FE_AT_PICKUP" || this.state.curBlockDetails.blockStatus === "REJECT_SERVICE_FE_AT_DROP" )  && this.state.rejectItemsVerify === true &&(
                               <div style={{fontSize: "15px", color:"green"}}>
                               <b>
                               &nbsp;&nbsp;Inventory Verified
                               </b>
                             </div>
                            )} */}
                            </div>
                            <div
                              className="form-row"
                              style={{ fontSize: "15px" }}
                            >
                              <div className="col-3">
                                {this.rCBHL(
                                  "photosVerified",
                                  this.photosVerification
                                )}
                              </div>
                              {this.state.curTicketDetails.flowCode !==
                                "DRPOF" && (
                                <div className="col-9">
                                  {this.rCBHL(
                                    "barcodesVerified",
                                    this.barcodeVerification
                                  )}
                                </div>
                              )}

                              <div>
                                <b>
                                  &nbsp;&nbsp;Close the Ticket with the
                                  following Status :
                                </b>
                                <div
                                  className="col-12"
                                  style={{
                                    display: "flex",
                                    columnGap: "15px",
                                    marginBottom: "20px",
                                    marginTop: "20px",
                                  }}
                                >
                                  <button
                                    style={{
                                      borderRadius: "6px",
                                      border: "none",
                                      outline: "none",
                                      backgroundColor: "#00B140",
                                      color: "#fff",
                                      padding: "5px",
                                      width: "70px",
                                      fontSize: "15px",
                                    }}
                                    onClick={(e) => this.handlePostiveClick()}
                                  >
                                    Positive
                                  </button>
                                  <button
                                    style={{
                                      borderRadius: "6px",
                                      border: "none",
                                      outline: "none",
                                      backgroundColor: "#D21404",
                                      color: "#fff",
                                      padding: "5px",
                                      width: "70px",
                                    }}
                                    onClick={(e) => this.handleNegativeClick()}
                                  >
                                    Negative
                                  </button>
                                </div>
                              </div>

                              <div id="cocards" className="col-10  ml-1 mr-4">
                                <div
                                  id="primary-ticket-search"
                                  className="row "
                                >
                                  {this.state.closingStatus.map((t) => {
                                    return (
                                      <span key={t + "spanCover1"}>
                                        <div
                                          className="card mb-2 cursorPointor "
                                          onClick={() =>
                                            this.setCurTicketCloseStatus(t)
                                          }
                                        >
                                          <div
                                            className="card-header page-todo task"
                                            style={
                                              t &&
                                              t === this.state.closedStatusId
                                                ? {
                                                    backgroundColor:
                                                      "rgb(255 225 202)",
                                                  }
                                                : { backgroundColor: "white" }
                                            }
                                          >
                                            <div className="col zoom">{t}</div>
                                            {/* <div
                                            style={{
                                              marginLeft: "40rem",
                                              marginTop: "-28px",
                                            }}
                                          >
                                            {(this.state.billingStatus[t] ===
                                              "yes" && (
                                              <span className="badge badge-success">
                                                Billable
                                              </span>
                                            )) || (
                                              <span className="badge badge-warning">
                                                Non-Billable
                                              </span>
                                            )}
                                          </div> */}
                                          </div>
                                        </div>
                                      </span>
                                    );
                                  })}
                                </div>
                              </div>

                              {/* <div id="cocards" className="col-8  ml-1 mr-4">
                              <div id="primary-ticket-search" className="row ">
                                {this.state.status.map((t) => {
                                  return (
                                    <span key={t.statusId + "spanCover1"}>
                                      <div
                                        className="card mb-2 cursorPointor "
                                        onClick={() =>
                                          this.setCurTicketCloseStatus(t)
                                        }
                                      >
                                        <div
                                          className="card-header page-todo task"
                                          style={
                                            t.status &&
                                            t.status ===
                                              this.state.closedStatusId
                                              ? {
                                                  backgroundColor:
                                                    "rgb(255 225 202)",
                                                }
                                              : { backgroundColor: "white" }
                                          }
                                        >
                                          <div className="col-6 zoom">
                                            {t.status}
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  );
                                })}
                              </div>
                            </div> */}
                              {/* <div className="col-12 mt-4">
                            {this.rSB("", "status", this.state.status, {
                              value: "ticketClosingStatus",
                              label: "status",
                            })}
                          </div> */}

                              <div className="col-12 mt-4">
                                {this.rTA("Closing Remarks *", "remarks")}
                              </div>
                            </div>
                            {(this.state.curBlockDetails.blockStatus === "REJECT_SERVICE_FE_AT_PICKUP" || this.state.curBlockDetails.blockStatus === "REJECT_SERVICE_FE_AT_DROP" )  && this.state.rejectItemsVerify === true &&(
                            <div>
                            {this.rBtnSP(
                              "Close the Ticket",
                              "Confirm",
                              "pull-right mt-2"
                            )}
                            </div>
                            )}
                             {(this.state.curBlockDetails.blockStatus === "REJECT_SERVICE_FE_AT_PICKUP" || this.state.curBlockDetails.blockStatus === "REJECT_SERVICE_FE_AT_DROP" )  && this.state.confirmData === false &&(
                            <div>
                            {this.rBtnSP(
                              "Close the Ticket",
                              "Confirm",
                              "pull-right mt-2"
                            )}
                            </div>
                            )}
                             {this.state.curBlockDetails.blockStatus !== "REJECT_SERVICE_FE_AT_PICKUP" && this.state.curBlockDetails.blockStatus !== "REJECT_SERVICE_FE_AT_DROP"  &&(
                            <div>
                            {this.rBtnSP(
                              "Close the Ticket",
                              "Confirm",
                              "pull-right mt-2"
                            )}
                            </div>
                            )}
                          </form>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
        </div>
        {this.state.pageHide === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Kindly Complete the Previous Process
            </p>
          </div>
        )}
        {this.state.closeStatus === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Ticket Number: <b>{ticketDetails.ticketNo}</b> has been
              Successfully Closed.
            </p>
          </div>
        )}
        {this.state.isProductNotAttempted === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Product has been Successfully Updated.
            </p>
          </div>
        )}

        {this.state.rtoStatus === "true" && (
          <RTOProcess
            ticketDetails={this.state.curTicketDetails}
            blockDetails={this.state.curBlockDetails}
            productDetails={this.state.curProductDetails}
          />
        )}
        {this.state.rescheduleStatus === "true" && (
          <RescheduleProcess
            ticketDetails={this.state.curTicketDetails}
            blockDetails={this.state.curBlockDetails}
            productDetails={this.state.curProductDetails}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketClosure);
