export function dropMenuFull(toLinks) {
  return  [
    {
      toLinks: toLinks + "/view",
      icon: "fa-binoculars",
      label: "View",
    },
    {
      toLinks: toLinks + "/edit",
      icon: "fa-pencil",
      label: "Edit",
    },
    {
      toLinks: toLinks + "/delete",
      icon: " fa-trash",
      label: "Delete",
    },
    {
      toLinks: toLinks + "/export",
      icon: " fa-table",
      label: "Export",
    },
    {
      toLinks: toLinks + "/print",
      icon: " fa-print",
      label: "Print",
    },
    {
      toLinks: toLinks + "/pdf",
      icon: "fa-file-pdf-o",
      label: "PDF",
    },
  ];
}


export function dropMenuBasic(toLinks) {
    return  [
      {
        toLinks: toLinks + "/view",
        icon: "fa-binoculars",
        label: "View",
      },
      {
        toLinks: toLinks + "/edit",
        icon: "fa-pencil",
        label: "Edit",
      },
      {
        toLinks: toLinks + "/delete",
        icon: " fa-trash",
        label: "Delete",
      }

    ];
  }