import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Joi from "joi-browser";
import { setPrevilage, setUserInfo } from "../../../store";

import FormLib from "../../_common/FormLib";

import _auth from "../../../services/_authServicePHP";

class LoginPHP extends FormLib {
  state = {
    data: { code: "plms", username: "", password: "" },
    errors: {},
  };
  schema = {
    code: Joi.string().required().label("Code"),
    username: Joi.string().required().label("Username"),
    password: Joi.string().required().label("Password"),
  };

  doSubmit = async () => {
    try {
      const { data } = this.state;

      const res = await _auth.login(data.code, data.username, data.password);

      await this.props.setPrevilage(res.data.features);
      await this.props.setUserInfo(res.data.primary);

      const { state } = this.props.location;
      window.location = state
        ? state.from.pathname
        : "/testAPI/react/storyboard/board";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container h-100 loginContainer ">
          <div className="row h-100">
            <div className="col-sm-10 col-md-8 col-lg-5 mx-auto d-table h-100">
              <div className="d-table-cell align-middle">
                <div className="text-center mt-4">
                  <h1 className="h2">Welcome To</h1>
                </div>
                <div className="text-center mt-4">
                  <img src="./img/bizlog.svg" alt="Bizlog" />
                </div>

                <div className="text-center mt-2">
                  <p className="lead">Sign in to your account to continue</p>
                </div>

                <div className="card">
                  <div className="card-body">
                    <form onSubmit={this.handleSubmit} className="loginForm">
                      {this.rTB("Code", "code", "col-md-12")}
                      {this.rTB("Username", "username", "col-md-12")}
                      {this.rPB("Password", "password", "col-md-12")}
                      <br />
                      {this.rBtnLP(
                        "Login",
                        "Login",
                        " btn-lg loginBtn btn-block"
                      )}

                      <div className="form-group col-md-12 text-center">
                        <NavLink to="" className="" tabIndex="-1">
                          Forgot Password / Username
                        </NavLink>
                      </div>

                      <div className="form-group col-md-12 text-center">
                        <p className="mt-5 mb-3 text-muted">&copy; Bizlog</p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.auth.userInfo.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPrevilage: (privileges) => dispatch(setPrevilage(privileges)),
    setUserInfo: (userInfo) => dispatch(setUserInfo(userInfo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPHP);
