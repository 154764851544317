import React from "react";
import $ from "jquery";
import FormLib from "../../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../../services/_toastService";

import {
  getSaveFile,
  getFilesDetails,
} from "./../../../../services/Tickets/TicketProcessServices";
class DocumentDetails extends FormLib {
  state = {
    data: {
      ticketActionId: "1",
      info: "",
      selectedFilesList: [],
    },
    errors: {},
    currentRequest: {},
    user: {},
    selectedFile: null,
    photoDetails: [],
    file: [],
  };

  schema = {
    ticketActionId: Joi.string().optional().label("Action "),
    info: Joi.string().required().label("File Info "),
    selectedFilesList: Joi.optional().label("Files"),
  };

  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  selectedFilesLists = (e) => {
    let fileObj = [];
    let fileArray = [];
    fileObj.push(e);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(fileObj[0][i]);
    }
    this.setState({ file: fileArray });

    let data = this.state.data;
    data.selectedFilesList = e;
    this.setState({ data });
  };

  componentDidMount() {
    this.loadData(this.props.data);
  }

  loadData = async (r) => {
    let res = await getFilesDetails(r.ticketActionId);
    if (res.data.success) {
      toast.toastSuccess(res.data.msg);
      this.setState({ photoDetails: res.data?.data?.ActionFiles });
    } else {
      toast.toastError(res.data.msg);
    }
  };

  doSubmit = async () => {
    let res;
    if (this.state.data.selectedFilesList == null) {
      toast.toastError("File should not be null");
    } else {
      const data = {
        ticketActionId: this.props.data.ticketActionId,
        info: this.state.data.info,
        selectedFilesList: this.state.data.selectedFilesList,
      };
      if (this.state.btnValue === "upload") {
        res = await getSaveFile(data);
      }

      if (res.data.success) {
        this.resetData();
        this.loadData(this.props.data);
        toast.toastSuccess(res.data.msg);
      } else {
        toast.toastError(res.data.msg);
      }
    }
  };

  resetData = () => {
    this.setState({
      data: {
        info: "",
        selectedFilesList: [],
      },
    });
    this.setState({ file: [] });
  };
  deleteFile(e) {
    const s = this.state.file.filter((item, index) => index !== e);
    this.setState({ file: s });
    let data = this.state.data;
    data.selectedFilesList = s;
    this.setState({ data });
  }

  render() {
    const user = this.props.user;
    const currentRequest = this.props.currentRequest;
    const fileStyle = {
      width: "130px",
      height: "130px",
      float: "left",
      cursor: "pointer",
      border: "1px solid #d3c4c4",
      "border-radius": "6px",
    };
    const imgStyle = {
      border: "1px solid #d3c4c4",
      width: "130px",
      height: "130px",
      "line-height": "130px",
      float: "left",
      cursor: "pointer",
      "font-size": "48px",
      color: "#d7caca",
      "background-color": "#f9f1f1",
      "border-radius": "6px",
    };
    return (
      <React.Fragment>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.props.closeModal}
        >
          <span aria-hidden="true">Files &times;</span>
        </button>
        <div className="card">
          <div className="card-header bg-success text-white ">
            <div className="row ">
              <div className="col-8">Files</div>
            </div>
          </div>
          <div
            className="card-body mt-4 "
            style={{ backgroundColor: "rgb(255 255 255)" }}
          >
            <div className="row">
              <div className="col-12">
                <form>
                  <div className="form-row">
                    <input
                      type="file"
                      accept=".xlsx, .docx, .txt, .pdf, .jpg, .jpeg, .png, .gif "
                      className="btn btn-primary btn-sm"
                      title="file upload"
                      key={this.state.selectedFile || ""}
                      onChange={(e) => {
                        this.selectedFilesLists(e.target.files);
                      }}
                      multiple
                    />
                  </div>
                  <div className="form-row mt-5">
                    {this.rTAR("File Info", "info", "col-12")}
                  </div>
                  <div className="form-row mt-5 ">
                    <div
                      className="col-12 page-todo overflow-auto"
                      style={{ height: "10rem" }}
                    >
                      <div className="tasks">
                        <div className="col-3">
                          <div className="form-group multi-preview">
                            {(this.state.file || []).map((url, index) => (
                              <div key={url}>
                                <li className="list-group-item" key={index}>
                                  <p>
                                    <a>{url.name}</a>
                                  </p>{" "}
                                  <img
                                    className="mt-5"
                                    src={URL.createObjectURL(url)}
                                    alt={url.name}
                                    key={index}
                                    style={{ height: "50px", width: "100px" }}
                                  />
                                  <button
                                    type="button"
                                    style={{
                                      position: "absolute",
                                      marginLeft: "30px",
                                    }}
                                    onClick={() => this.deleteFile(index)}
                                  >
                                    delete
                                  </button>
                                </li>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                {this.rBtnLP("Upload", "upload")}
              </div>
            </div>
            <hr />
            <div className="row">
              <div
                className="col-12 page-todo overflow-auto"
                style={{ height: "20rem" }}
              >
                <div className="tasks">
                  {this.state.photoDetails?.map((r) => {
                    return (
                      <div className="task" key={r.fileId}>
                        <div className="desc mb-0 pb-0">
                          <p>
                            {r.fileType == "xlsx" && (
                              <>
                                <a
                                  href={r.fileS3Path + r.filePath}
                                  target="_self"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-excel-o mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  ></i>
                                </a>
                              </>
                            )}
                            {r.fileType == "docx" && (
                              <>
                                <a
                                  href={r.fileS3Path + r.filePath}
                                  target="_self"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-word-o mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  ></i>
                                </a>
                              </>
                            )}
                            {r.fileType == "txt" && (
                              <>
                                <a
                                  href={r.fileS3Path + r.filePath}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-text mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  ></i>
                                </a>
                              </>
                            )}
                            {r.fileType == "pdf" && (
                              <>
                                <a
                                  href={r.fileS3Path + r.filePath}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-pdf-o mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  />
                                </a>
                              </>
                            )}

                            {r.fileType == "jpg" && (
                              <>
                                <a
                                  href={r.fileS3Path + r.filePath}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.fileS3Path + r.filePath}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            {r.fileType == "jpeg" && (
                              <>
                                <a
                                  href={r.fileS3Path + r.filePath}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.fileS3Path + r.filePath}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            {r.fileType == "png" && (
                              <>
                                <a
                                  href={r.fileS3Path + r.filePath}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.fileS3Path + r.filePath}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            {r.fileType == "gif" && (
                              <>
                                <a
                                  href={r.fileS3Path + r.filePath}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.fileS3Path + r.filePath}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            {/* <div>
                              <div>
                                <h5>
                                  <span> Upload By </span>
                                  <span className="ml-2">
                                    {" "}
                                    : &nbsp;{r.CREATED_BY}
                                  </span>
                                </h5>
                              </div>
                              <div>
                                <h5>
                                  <span> Info </span>
                                  <span className="ml-5">
                                    {" "}
                                    : &nbsp;{r.INFO}
                                  </span>
                                </h5>
                              </div>
                              <div>
                                <h5>
                                  <span> File Type </span>
                                  <span className="ml-3">
                                    {" "}
                                    : &nbsp;{r.fileType}
                                  </span>
                                </h5>
                              </div>
                            </div> */}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default DocumentDetails;
