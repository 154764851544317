import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import logo from "./../../assets/img/bizlog.svg";
// import $ from "jquery";

class Header extends Component {
  // scriptLoaded() {
  //   window.A.sort();
  // }

  appendExternalJS() {
    const script = document.createElement("script");
    script.src = "./main.js";
    script.async = true;
    document.body.appendChild(script);
  }
  componentDidMount() {
    this.appendExternalJS();
  }

  // componentDidMount() {
  //   function initMenu() {
  //     $("#menu ul").hide();
  //     $("#menu ul").children(".current").parent().show();
  //     //$('#menu ul:first').show();
  //     $("#menu li a").click(function () {
  //       var checkElement = $(this).next();
  //       if (checkElement.is("ul") && checkElement.is(":visible")) {
  //         return false;
  //       }
  //       if (checkElement.is("ul") && !checkElement.is(":visible")) {
  //         $("#menu ul:visible").slideUp("normal");
  //         checkElement.slideDown("normal");
  //         return false;
  //       }
  //     });
  //   }
  //   //  $(document).ready(function() {
  //   //     initMenu();
  //   //  });
  //   initMenu();

  //   $(document).ready(function () {
  //     initMenu();

  //     function setHeight() {
  //       const windowHeight = $("#page-content-wrapper").innerHeight() + 27;
  //       $("#sidebar-wrapper").css("min-height", windowHeight);
  //     }
  //     // setHeight();

  //     $(window).resize(function () {
  //       // setHeight();
  //     });

  //     // $(window).scroll(function () {
  //     //   if ($(window).scrollTop() >= 300) {
  //     //     $("nav").addClass("fixed-header");
  //     //     $("nav div").addClass("visible-title");
  //     //   } else {
  //     //     $("nav").removeClass("fixed-header");
  //     //     $("nav div").removeClass("visible-title");
  //     //   }
  //     // });
  //   });
  // }

  handleLogo() {
    window.open("https://bizlog.in", "_blank");
  }

  render() {
    const { user } = this.props;
    return (
      <nav className="navbar navbar-expand-lg  navbar-light bg-light leftNavBar">
        <span
          className="navbar-sidebar-toggler"
          data-toggle="collapse"
          id="menu-toggle-2"
          style={{ marginRight: 20 }}
        >
          <i className="fa  fa-angle-double-right fa-6" aria-hidden="true"></i>
        </span>

        <div
          id="logo-header"
          style={{
            backgroundImage: `url(${logo})`,
            backgroundPosition: "left center",
            backgroundSize: 100,
            backgroundRepeat: "no-repeat",
            width: 150,
            height: 50,
            marginLeft: 15
          }}
          className="cursorPointor"
          onClick={this.handleLogo}
        ></div>
        {/* <div className="navbar-brand" to="#">
            <img src={logo} className="d-inline-block align-top" alt="" />
        </div> */}

        {/* <span className="right-menu">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
            id="navbarNavDropdownBtn"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </span> */}
      </nav>
    );
  }
}

export default Header;
