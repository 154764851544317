import _http from "../_httpService";

import config from "../../config.json";

const apiEndpoint = config[config.build].apiURLEDashboard + "/metrics";

function buildUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getCountByStatusHub(req) {
  const param = { ...req };

  try {
    return _http.post(
      apiEndpoint + "/countByStatusHub",
      param,
      _http.headerJWT()
    );
  } catch {
    return undefined;
  }
}
