import React, { Component } from 'react';
import { toast } from 'react-toastify';
import FormLib from './../../_common/FormLib';
import Table from './../../_common/Table';
import { IstToUTC } from "../../../utils/DateUtils";

export default class DropVerificationBlock extends FormLib {
    state = {
        data: {},
        errors: {},
        curTicketDetails: [],
        curBlockDetails: [],
        curProductDetails: [],
    }

    componentDidMount() {
        this.loadInit();
    }

    loadInit() {
        const curticketDetailsArr = [];
        const curTicketDetails = [...curticketDetailsArr, this.props.ticketDetails];
        let curBlockDetails = [];
        if (this.props.blockDetails.blocks_barcode) {
            curBlockDetails = this.props.blockDetails.blocks_barcode;
            this.setState({ curBlockDetails });
        }
        const curProductDetails = this.props.productDetails;
        this.setState({ curTicketDetails });

        this.setState({ curProductDetails });
    }

    onBlockSelection = async (r) => {
        if (this.state.data["db_" + r.blockBarcode] !== r.blockBarcode) {
            toast.error("Block Barcode Does not Match Kindly enter the correct Barcode");
        } else {
            toast.success("Block Barcode Matched successfully");
        }
    };


    render() {
        const blockDetails = this.state.curBlockDetails;
        return <>
            {Array.isArray(blockDetails) &&
                (<><div className="form-subHeader">Blocks Droped Barcodes Verification</div>
                    {blockDetails.map((r) => <div className="row" key={"pu_" + r.ticketBlockId + r.blockBarcode}>
                        <form onSubmit={this.handleSubmit} className="form-row col-12">
                            <div className="col-1 ml-3">
                                <button
                                    className="badge badge-primary link-over"
                                    onClick={() =>
                                        this.onBlockSelection(r)
                                    }
                                >
                                    Confirm
                                </button>
                            </div>
                            <div className="col">
                                {this.rTB("Barcode", "db_" + r["blockBarcode"], "")}
                            </div>
                            <div className="col-3">
                                Block Barcode: <b>{r.blockBarcode}</b>
                            </div>
                            <div className="col">
                                Block Code: <b>{r.blockCode}</b>
                            </div>
                        </form>
                    </div>)}</>)}
        </>
    }
}
