import React from "react";
import FormLib from "./../../_common/FormLib";

class ProjectTasksInfo extends FormLib {
  state = {
    data: {
      username: "",
      password: "",
      pickupDate: this.getDatebyDays(1),
      pickupTime: "14:00",
    },
    errors: {},
    task: [],
  };

  renderButtonStyle(status) {
    switch (status) {
      case "wip":
        return "badge-warning";
      case "done":
        return "badge-light";
      default:
        return "badge-info";
    }
  }
  render() {
    const curUserstoryId = this.props.curUserstoryId;
    const curTask = this.props.curTask;
    return (
      <div
        id="taskInfo"
        className="col-md-7"
        style={{ border: "1px solid #ccc", padding: 0 }}
      >
        <div className="card ">
          <h5 className="card-header">
            <span className="badge badge-info  mr-1 taskType">
              {curTask.taskType}
            </span>
            <span style={{ color: "#ccc" }}> :: </span>
            {curTask.task}

            {curUserstoryId !== "" && (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-warning pull-right"
                  data-toggle="modal"
                  onClick={() => this.props.onTaskUnload(curTask)}
                >
                  <i className={"fa  fa-unlink  "}></i>
                </button>
              </>
            )}
          </h5>
          <div className="card-body">
            <div className="page-todo">
              <div className="task nil" key={curTask.taskId}>
                <div className="desc descFUll">
                  <div className="title"></div>
                  <div>
                    <span
                      className={
                        "badge   " + this.renderButtonStyle(curTask.status)
                      }
                    >
                      {curTask.status.substring(2)}
                    </span>{" "}
                    | {" " + curTask.tskOwner} | {curTask.tskPoints} pts
                  </div>
                  <br />

                  <div className="panel-group widget-request">
                    <div className="panel panel-info">
                      <label>More Info </label>
                      <div className="panel-body">{curTask.moreInfo}</div>
                    </div>
                  </div>
                  <br />

                  <div className="panel-group widget-request">
                    <div className="panel panel-info">
                      <label>specialPoints </label>
                      <div className="panel-body">{curTask.specialPoints}</div>
                    </div>
                  </div>
                  <br />

                  <div className="panel-group widget-request">
                    <div className="panel panel-info">
                      <label>Test URL </label>
                      <div className="panel-body">{curTask.testUrl}</div>
                    </div>
                  </div>
                  <br />

                  <div className="panel-group widget-request">
                    <div className="panel panel-info">
                      <label>Request1 </label>
                      <div className="panel-body  form-group ">
                        <textarea>{curTask.request}</textarea>
                      </div>
                    </div>
                  </div>
                  <br />

                  <div className="panel-group widget-request">
                    <div className="panel panel-info">
                      <label>Response </label>
                      <div className="panel-body   form-group ">
                        <textarea>{curTask.response}</textarea>
                      </div>
                    </div>
                  </div>
                  {/* <div className="desc">
                      <div className="title">
                        <span className="badge badge-info  mr-1 taskType">API</span>
                        Ledger Creation API
                      </div>
                      <div>
                        OwnerName | 8 Pts |
                        <span className="badge badge-primary  ml-1 ">Open</span>
                      </div>
                    </div> */}
                </div>
              </div>

              {/* {tasks.map((mi) => (
                <div className="task nil" key={mi.taskId}>
                  <div className="desc">
                    <div className="title">
                      <span className="badge badge-info  mr-1 taskType">
                        {mi.taskType}
                      </span>

                      {mi.task}
                    </div>
                    <div>
                      <span
                        className={
                          "badge   " + this.renderButtonStyle(mi.usStatus)
                        }
                      >
                        {mi.usStatus}
                      </span>{" "}
                      |{" " + mi.tskOwner} | {mi.tskPoints} pts
                    </div>
                    {/* <div className="desc">
                      <div className="title">
                        <span className="badge badge-info  mr-1 taskType">API</span>
                        Ledger Creation API
                      </div>
                      <div>
                        OwnerName | 8 Pts |
                        <span className="badge badge-primary  ml-1 ">Open</span>
                      </div>
                    </div> }
                  </div>
                  <div className="rightCol">
                    <div className="menu">
                      <div className="menu">
                        <DropDownMenu
                          menuItems={dropMenuBasic("/v1/request/raise")}
                          menuKey={mi.key}
                        />
                      </div>
                    </div>
                    <div className="pr-2">{mi.usDueDate}</div>
                  </div>
                </div>
              ))} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectTasksInfo;
