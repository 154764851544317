import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import $ from "jquery";
// import "bootstrap";
import {
  createPrvMenus,
  savePrvMenus,
  deletePrvMenus,
} from "./../../../services/menus/PrvMenusServices";

class ModalMenu extends FormLib {
  state = {
    data: {
      menuId: "",
      menuGroupId: "",
      menuCode: "",
      menu: "",
      status: "active",
    },
    errors: {},

    menuGroups: [],
    curGroupName: {},
  };

  schema = {
    menuId: Joi.optional().label("MenuId"),
    menuGroupId: Joi.string().required().label("MenuGroupId*"),
    menuCode: Joi.string().required().label("MenuCode*"),
    menu: Joi.string().required().label("Menu*"),
    status: Joi.string().required().label("Status*"),
  };

  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createPrvMenus(this.state.data);
      this.resetData(this.state.curGroupName);
      this.props.onLoadPrvMenus(this.state.curGroupName.menuGroupId);
    }
    if (this.state.btnValue === "edit") {
      res = await savePrvMenus(this.state.data);
      this.props.onLoadPrvMenus(this.state.curGroupName.menuGroupId, false);
    }
    if (this.state.btnValue === "delete") {
      res = await deletePrvMenus(this.state.data);
      $("#curModuleButton").click();
      this.resetData(this.state.curGroupName);
      this.props.onLoadPrvMenus(this.state.curGroupName.menuGroupId);
    }

    toast.toastMsg(res);
    // if (res.data && res.data.success && res.data.success === true) {
    //   toast.toastSuccess(res.data.msg);
    // } else {
    //   toast.toastError(
    //     (res.data && res.data.msg) || "Error Happened in getting Error Msg"
    //   );
    // }
  }

  componentDidMount() {
    this.setMenuGroups(this.props.menuGroups);
    this.setCurGroupName(this.props.curGroupName);
    this.setCurMenu(this.props.curGroupName, this.props.curMenu);
  }
  setMenuGroups(menuGroups) {
    this.setState({ menuGroups: menuGroups });
  }
  setCurGroupName(g) {
    this.setState({ curGroupName: g });
  }
  setCurMenu(g, m) {
    const mod = { ...m };
    delete mod.seq;
    if (m.menuId && m.menuId !== "") {
      this.setState({ data: mod });
    } else {
      this.resetData(g);
    }
  }

  resetData(g) {
    this.setState({
      data: {
        menuGroupId: g.menuGroupId || "",
        menuId: "",
        menuCode: "",
        menu: "",
        status: "active",
      },
    });
  }

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;

    const menuGroups = this.state.menuGroups;

    return (
      <div className="container-fluid">
        <div className="modal fade" id="myMenuModel">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Prv Menus</h4>
                <button
                  type="button"
                  className="close"
                  id="curModuleButton"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  {this.rTBH("MenuId", "menuId")}
                  {this.rSB("MenuGroupId", "menuGroupId", menuGroups, {
                    value: "menuGroupId",
                    label: "groupName",
                  })}
                  {this.rTB("MenuCode", "menuCode")}
                  {this.rTB("Menu", "menu")}
                  <br />
                  <div className="form-row">
                    {this.rRBL("Status", "status", this.status, this.stsConf, "col-12")}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {/* {"==>"+this.state.data.menuId + "<=="} */}
                {this.state.data.menuId === "" &&
                  this.rBtnSP("Create", "create")}
                {this.state.data.menuId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {this.rBtnSD("Delete", "delete")}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalMenu;
