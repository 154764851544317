import React from "react";
import ZTitle from "../../_subhead/ZTitle";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";

class FormDemo extends FormLib {
  subHeading = "User";
  state = {
    data: {
      username: "",
      password: "",
      pickupDate: this.getDatebyDays(1),
      pickupTime: "14:00",
      departsMulti: [],
    },
    errors: {},
  };
  departments = [
    { depId: "rnd", department: "RnD" },
    { depId: "finance", department: "Finance" },
    { depId: "sales", department: "Sales" },
    { depId: "it", department: "IT" },
  ];
  facilities = [
    { sortKey: "first", sort: "First" },
    { sortKey: "second", sort: "Second" },
  ];

  epics = [
    { sortKey: "book1", sort: "Ramayana" },
    { sortKey: "book2", sort: "Mahabharatha" },
  ];

  sports = [
    { sortKey: "cricket", sort: "Cricket" },
    { sortKey: "football", sort: "Football" },
  ];

  cars = [
    { sortKey: "suv", sort: "SUV" },
    { sortKey: "hatchback", sort: "hatch Back" },
  ];
  termsConditions = [
    { label: "I agree to Terms & Condition", value: "agreed" },
  ];

  gender = [
    { genderId: "male", gender: "Male" },
    { genderId: "female", gender: "Female" },
  ];

  schema = {
    username: Joi.string().label("Username"),
    password: Joi.string().required().label("Password"),
  };

  doSubmit() {
    console.log(this.state.data);
  }

  render() {
    return (
      <React.Fragment>
        <ZTitle subHeading="Demo  :: Form" />
        <div className="pageContent">
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label className="btn btn-primary active">
              <input
                type="radio"
                name="options"
                id="option1"
                autoComplete="off"
              />{" "}
              Active
            </label>
            <label className="btn btn-success">
              <input
                type="radio"
                name="options"
                id="option2"
                autoComplete="off"
              />{" "}
              Radio
            </label>
            <label className="btn btn-danger">
              <input
                type="radio"
                name="options"
                id="option3"
                autoComplete="off"
              />{" "}
              Radio
            </label>
          </div>
          <hr />
          <div className="card">
            <div className="card-body">
              <form onSubmit={this.handleSubmit}>
                <div className="form-subHead">
                  Form Examples {this.getCurrentDate()}
                </div>
                {this.rTB("Username", "username")}
                {this.rPB("Password", "password")}
                <div className="form-row">
                  {this.rTB("Emp Code", "empCode", "col-md-4")}
                  {this.rTB("First Name", "firstName", "col-md-4")}
                  {this.rPB("Last Name", "lastName", "col-md-4")}
                </div>
                {JSON.stringify(this.state.data)}
                {this.rSBM("Department", "departsMulti", this.departments, {
                  value: "depId",
                  label: "department",
                })}
                {this.rSB("Department", "name", this.departments, {
                  value: "depId",
                  label: "department",
                })}
                {this.rTB("Designation", "designation")}
                {this.rTA("Roles", "roles")}
                <div className="form-subHead">CheckBox Examples</div>
                {this.rCB("Regular", "Regular", this.epics, {
                  value: "sortKey",
                  label: "sort",
                })}
                <hr />
                {this.rCBL("Inline", "department", this.departments, {
                  value: "depId",
                  label: "department",
                })}
                <hr />
                {this.rCBHL("hl", this.termsConditions)}
                <hr />
                {this.rCBHLL("Sports", this.sports, {
                  value: "sortKey",
                  label: "sort",
                })}
                <hr />
                {this.rCBHL("Cars", this.cars, {
                  value: "sortKey",
                  label: "sort",
                })}
                <div className="form-subHead">Radio Examples</div>
                {this.rRB("Gender", "gender", this.gender, {
                  value: "genderId",
                  label: "gender",
                })}
                <hr />
                {this.rRBL("Shift", "shift", this.facilities, {
                  value: "sortKey",
                  label: "sort",
                })}
                <div className="form-subHead">Date & Time Pickers</div>
                <div className="form-row">
                  {this.rDP("Pickup Date", "pickupDate", "col-md-4")}
                  {this.rTP("Pickup Time", "pickupTime", "col-md-4")}
                  {this.rDP("Pickup Date", "dropDate", "col-md-4")}
                </div>
                <div className="form-subHead">Buttons Example</div>
                {this.rBtnSP("Login", "primary")}
                {this.rBtnSSc("Login", "secondary")}
                {this.rBtnSS("Login", "success")}
                {this.rBtnSD("Login", "danger")}
                {this.rBtnSW("Login", "warning")}
                {this.rBtnSL("Login", "info")}
                {this.rBtnSL("Login", "light")}
                {this.rBtnSDr("Login", "dark")}
                {this.rBtnS("Login", "link")}
                <br /> <br />
                <div className="form-group col-md-12 text-center">
                  <p className="mt-5 mb-3 text-muted">&copy; Bizlog</p>
                </div>
              </form>
            </div>
          </div>

          <div className="container-fluid">
            <form onSubmit={this.handleSubmit} className="loginForm">
              <div className="form-group col-md-12 text-center">
                <p className="mt-5 mb-3 text-muted">&copy; Bizlog</p>
              </div>
            </form>

            <form>
              <div className="form-group">
                <input type="date" required="required" id="dateid" />
                <label htmlFor="input" className="control-label">
                  Date
                </label>
                <i className="bar"></i>
              </div>

              <div className="form-group">
                <input type="text" required="required" id="firstName12" />
                <label htmlFor="input" className="control-label">
                  First Name
                </label>
                <i className="bar"></i>
              </div>

              <div className="form-group">
                <input type="text" required="required" id="secondName" />
                <label htmlFor="input" className="control-label">
                  Second Name
                </label>
                <i className="bar"></i>
              </div>

              <div className="form-group">
                <input type="text" required="required" id="designation232" />
                <label htmlFor="input" className="control-label">
                  Designation
                </label>
                <i className="bar"></i>
              </div>

              <div className="form-group">
                <select multiple>
                  <option>Value 1</option>
                  <option>Value 2</option>
                  <option>Value 3</option>
                  <option>Value 4</option>
                  <option>Value 5</option>
                  <option>Value 6</option>
                </select>
                <label htmlFor="select" className="control-label">
                  Selectbox
                </label>
                <i className="bar"></i>
              </div>

              <div className="form-group">
                <textarea required="required"></textarea>
                <label htmlFor="textarea" className="control-label">
                  Textarea
                </label>
                <i className="bar"></i>
              </div>

              <div className="checkbox">
                <div className="form-help"> Technologies </div>
                <label>
                  <input type="checkbox" />
                  <i className="helper"></i>ReactJS
                </label>
              </div>
              <div className="checkbox">
                <label>
                  <input type="checkbox" />
                  <i className="helper"></i>React Native
                </label>
              </div>
              <div className="form-inline form-checkbox">
                <div className="form-help"> Interface </div>
                <div className="checkbox">
                  <label>
                    <input type="checkbox" />
                    <i className="helper"></i>Web
                  </label>
                </div>
                <div className="checkbox">
                  <label>
                    <input type="checkbox" />
                    <i className="helper"></i>Mobile
                  </label>
                </div>
              </div>

              <div className="form-inline form-radio">
                <div className="form-help"> Gender</div>
                <div className="radio">
                  <label>
                    <input type="radio" name="radio" />
                    <i className="helper"></i>Male
                  </label>
                </div>

                <div className="radio">
                  <label>
                    <input type="radio" name="radio" />
                    <i className="helper"></i>Female
                  </label>
                </div>
              </div>

              <div className="form-radio">
                <div className="form-help"> Payment</div>
                <div className="radio">
                  <label>
                    <input type="radio" name="vRadio" />
                    <i className="helper"></i>Partial Payment
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input type="radio" name="vRadio" />
                    <i className="helper"></i>Full Payment
                  </label>
                </div>
              </div>

              <div className="checkbox">
                <label>
                  <input type="checkbox" />
                  <i className="helper"></i>I'm the label from a checkbox
                </label>
              </div>
            </form>
            <div className="button-container">
              <button type="button" className="button">
                <span>Submit</span>
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default FormDemo;
