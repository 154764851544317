import React from "react";
import FormLib from "../../_common/FormLib";
import LocationTitle from "../../_subhead/LocationTitle";
import Table from "../../_common/Table";
import swal from "sweetalert";
import Pagination from "./../../_common/Pagination";
import toast from "../../../services/_toastService";
import {
  getPackingMaterials,
} from "./../../../services/packing/PackingMaterialService";
import {
  searchBarcode,
  getBarcodesByPackMaterialId,
  getAvailableCountByHubIdPackMaterialId,
  getIssuedCountByHubIdPackMaterialId,
  getDamagedCountByHubIdPackMaterialId,
  getScrapedCountByHubIdPackMaterialId,
} from "./../../../services/packing/BarcodeService";
import ModalPackingMaterial from "./ModalPackingMaterial";
import ModalBarcodeSE from "./ModalBarcodeSE";
import ModalBarcodeSN from "./ModalBarcodeSN";
import ModalBarcodeN from "./ModalBarcodeN";
import ModalBarcodeTransfer from "./ModalBarcodeTransfer";
import ModalUpdateBulkBarcodeStatus from "./ModalUpdateBulkBarcodeStatus";

import { getHubsAll } from "../../../services/masterLocations/hubServices";
import { getTeams } from "../../../services/players/teamServices";
import { updateBarcodeStatus } from "../../../services/packing/PackingMaterialService";
import { detachBarcodeStatus } from "../../../services/packing/PackingMaterialService";
import { NavLink } from "react-router-dom";
import _auth from "../../../services/_authService";

class PackingMaterialStock extends FormLib {
  state = {
    data: { hubId: "", status: "00available", teamId: "", packMaterialId: "" },
    resData: {
      barcodeId: "",
      barcodeType: "",
      packMaterialId: "",
      materialType: "",
      packCode: "",
      barCode: "",
      hubId: "",
      teamId: "",
      processId: "",
      status: "",

    },
    errors: {},

    teams: [],
    packingmaterials: [],
    barcodes: [],
    curPackingMaterial: { packMaterialId: "" },
    curBarcode: { barcodeId: "" },
    curHubId: "",
    curTeamId: "",
    loading: true,
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    type: [
      {
        typeId: "Cover",
        type: "Cover",
      },
      {
        typeId: "Box",
        type: "Box",
      },
      {
        typeId: "Pouch",
        type: "Pouch",
      },
      {
        typeId: "Wrap",
        type: "Wrap",
      },
      {
        typeId: "Tape",
        type: "Tape",
      },
      {
        typeId: "Others",
        type: "Others",
      },
    ],
    status: [
      {
        statusId: "00available",
        status: "available",
      },
      {
        statusId: "10issued",
        status: "issued",
      },
      {
        statusId: "20used",
        status: "used",
      },
      {
        statusId: "40damaged",
        status: "damaged",
      },
      {
        statusId: "50scraped",
        status: "scraped",
      },
    ],
  };

  componentDidMount() {
    this.loadPackingMaterial();
    this.loadHubs();
    this.loadTeams();
    const user = _auth.getCurrentUser();
    this.setState({ user });
  }

  async loadPackingMaterial() {
    this.setState({ loading: true });
    const res = await getPackingMaterials();
    // if (res !== undefined) {
    //   if (res.data.success === true) {
    //     this.setState({ packingmaterials: res.data.data.packingMaterials });
    //     // toast.toastSuccess("Packing Material Loaded Successfully");
    //   } else {
    //     toast.toastError("failed");
    //   }
    // }
    if (res !== undefined) {
      if (res.data.success === true) {
        this.setState({ packingmaterials: res.data.data.packingMaterials });

        this.state.data.packMaterialId = res.data.data.packingMaterials[4]['packMaterialId'];

        this.setState({ curPack: res.data.data.packingMaterials[4] });

        this.setState({
          data: {
            packMaterialId: res.data.data.packingMaterials[4]['packMaterialId'],
            hubId: this.state.data.hubId,
            teamId: this.state.data.teamId,
            status: this.state.data.status
          }
        });
      } else {
        toast.toastError("failed");
      }
    }
    this.setState({ loading: false });
  }
  // async loadHubs() {
  //   this.setState({ loading: true });
  //   const res = await getHubsAll();
  //   if (res !== undefined) {
  //     if (res.data.success === true) {
  //       this.setState({ hubs: res.data.data.hubs });
  //     } else {
  //       toast.toastError(res.data.msg);
  //     }
  //   }
  //   this.setState({ loading: false });
  // }
  async loadHubs() {
    let hubList = JSON.parse(localStorage.getItem("hubs"));
    this.setState({ hubList });
    let curHub = localStorage.getItem("curHub");
    if (!curHub) {
      let override = this.state.data;
      override['hubId'] = JSON.parse(JSON.stringify(hubList[0].hubId));
      this.setState({ override })
    } else {
      let override = this.state.data;
      override['hubId'] = curHub;
      this.setState({ override })
    }
  }

  async loadTeams() {
    this.setState({ loading: true });
    const res = await getTeams();
    // if (res !== undefined) {
    //   if (res.data.success === true) {
    //     this.setState({ teams: res.data.data.teams });
    //   } else {
    //     toast.toastError(res.data.msg);
    //   }
    // }
    if (res !== undefined) {
      if (res.data.success === true) {
        this.setState({ teams: res.data.data.teams });
        this.state.data.teamId = res.data.data.teams[8]['teamId'];

        this.setState({ curTeam: res.data.data.teams[8] });

        this.setState({
          data: {
            hubId: this.state.data.hubId,
            teamId: res.data.data.teams[8]['teamId'],
            status: this.state.data.status,
            packMaterialId: this.state.data.packMaterialId
          }
        });

      } else {
        toast.toastError(res.data.msg);
      }
    }
    this.setState({ loading: false });

  }

  localHandleChange = (e) => {
    if (e.target.id === "prvDivs") {
      this.handleDivisionChange(e);
    }
  };


  loadBarcodesByPackMaterialId = async (packMaterialId, pageNo) => {
    let curPackingMaterial;
    const filter = this.state.packingmaterials.filter((r) => {
      return r.packMaterialId === packMaterialId ? r : null;
    });
    if (!filter[0]) {
      curPackingMaterial = { packMaterialId: "" };
    } else {
      curPackingMaterial = filter[0];
    }
    this.setState({ curPackingMaterial: curPackingMaterial });
    this.setState({ loading: true });
    const res = await getBarcodesByPackMaterialId(curPackingMaterial.packMaterialId, pageNo);
    if (res !== undefined) {
      if (res.data.data.length != 0) {
        this.setState({ barcodes: res.data.data.barcodes });
        this.setState({ pageLinks: res.data.data.pageLinks });
        toast.toastSuccess(res.data.msg);
      }
      else {
        toast.toastInfo("No Barcode found for this Packing Material");
        this.setState({ barcodes: [] });
        this.setState({
          pageLinks: {
            pageNo: 1,
            perPage: 0,
            itemsCount: 0,
            lastPage: 0,
          },
        });
      }
    }
    this.setState({ loading: false });
  };

  loadBarcodes = async (searchLoad) => {
    // alert(JSON.stringify(searchLoad));
    // let params = {
    //   hubId: this.state.data.hubId,
    //   teamId: this.state.data.teamId,
    //   status: this.state.data.status,
    //   packMaterialId: this.state.data.packMaterialId
    // }
    try {

      this.setState({ loading: true });
      this.availableCount(searchLoad);
      const res = await searchBarcode(
        searchLoad,
        this.state.pageLinks.pageNo
      );
      if (res.data.data.barcodes.length !== 0) {
        this.setState({ barcodes: res.data.data.barcodes });
        this.setState({ pageLinks: res.data.data.pageLinks });
        toast.toastSuccess(res.data.msg);
      } else {
        toast.toastInfo("No Barcodes data");
        this.setState({ barcodes: [] });
        this.setState({
          pageLinks: {
            pageNo: 1,
            perPage: 0,
            itemsCount: 0,
            lastPage: 0,
          }
        });
      }
      this.setState({ loading: false });
    }
    catch {
      console.log("Error in loadBarcodes");
    }
  };

  availableCount = async (searchLoad) => {
    try {

      const avail = await getAvailableCountByHubIdPackMaterialId(searchLoad.hubId, searchLoad.packMaterialId);
      const iss = await getIssuedCountByHubIdPackMaterialId(searchLoad.hubId, searchLoad.packMaterialId);
      const dam = await getDamagedCountByHubIdPackMaterialId(searchLoad.hubId, searchLoad.packMaterialId);
      const scrp = await getScrapedCountByHubIdPackMaterialId(searchLoad.hubId, searchLoad.packMaterialId);
      // console.log(JSON.stringify(avail.data));
      // console.log(JSON.stringify(iss.data));
      if (avail.data.data) {
        this.setState({ available: avail.data.data.available });
      }
      if (iss.data.data) {
        this.setState({ issued: iss.data.data.issued });
      }
      if (dam.data.data) {
        this.setState({ damaged: dam.data.data.damaged });
      }
      if (scrp.data.data) {
        this.setState({ scraped: scrp.data.data.scraped });
      }
    }
    catch {
      console.log("Error in availableCount");
    }

  }

  loadBarcodesPage = async (searchLoad, pageNo) => {
    this.availableCount(searchLoad);
    this.setState({ loading: true });
    const res = await searchBarcode(
      searchLoad,
      pageNo
    );
    if (res.data.data.barcodes.length !== 0) {
      this.setState({ barcodes: res.data.data.barcodes });
      this.setState({ pageLinks: res.data.data.pageLinks });
      toast.toastSuccess(res.data.msg);
    } else {
      toast.toastInfo("No Barcodes data");
      this.setState({ barcodes: [] });
      this.setState({
        pageLinks: {
          pageNo: 1,
          perPage: 0,
          itemsCount: 0,
          lastPage: 0,
        }
      });
    }
    this.setState({ loading: false });
  };
  handlePackingMaterialChange(e) {
    this.loadBarcodesByPackMaterialId(
      e.target.value,

      this.state.pageLinks.pageNo,
    );

  }

  handlePackMaterialChange = (pageNo) => {
    this.props.history.push("/packing/packingmaterialStock/" + pageNo);
    this.loadBarcodesPage(
      this.state.data,
      pageNo
    );
  };
  handleHubChange = (e) => {
    // alert(e.target.value);

    this.setState({ curHubId: e.target.value });
  }

  localHandleChange = (e) => {
    // alert(e.target.id);
    if (e.target.id === "hubId") {
      this.handleHubChange(e);
    }
    if (e.target.id === "packMaterialId") {
      // this.loadBarcodesByPackMaterialId(
      //   e.target.value,
      //   this.state.pageLinks.pageNo
      // );
    }


  };

  handleDamage = async (r, status) => {
    swal({
      title: "Are you sure want to set Damaged?",
      text: "Once updated, you will not be able to revert the status",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleUpdate) => {
      this.updateStatus(handleUpdate, r, status);
    });
  };

  handleScrap = async (r, status) => {
    swal({
      title: "Are you sure want to set Scraped?",
      text: "Once updated, you will not be able to revert the status",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDetach) => {
      this.scrapStatus(handleDetach, r, status);
    });
  };

  handleDetach = async (r, status) => {
    swal({
      title: "Are you sure want to set detach barcode?",
      text: "Once updated, you will not be able to revert the status",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDetach) => {
      this.detachStatus(handleDetach, r, status);
    });
  };

  async updateStatus(handleUpdate, r, status) {

    let param = {
      "barcode": r.barCode,
      "status": status
    }
    const res = await updateBarcodeStatus(param);
    if (res.data.statusCode === 200) {
      toast.toastSuccess(res.data.msg);
      this.loadBarcodesPage(
        this.state.data,
        this.state.pageLinks.pageNo
      );
    } else {
      toast.toastInfo("status not updated");
    }


  }
  async detachStatus(handleDetach, r, status) {

    let param = {
      "barcode": r.barCode,
      "status": status
    }
    const res = await detachBarcodeStatus(param);
    if (res.data.statusCode === 200) {
      toast.toastSuccess(res.data.msg);
      this.loadBarcodesPage(
        this.state.data,
        this.state.pageLinks.pageNo
      );
    } else {
      toast.toastInfo("status not updated");
    }


  }

  async scrapStatus(handleDetach, r, status) {

    let param = {
      "barcode": r.barCode,
      "status": status
    }
    const res = await updateBarcodeStatus(param);
    if (res.data.statusCode === 200) {
      toast.toastSuccess(res.data.msg);
      this.loadBarcodesPage(
        this.state.data,
        this.state.pageLinks.pageNo
      );
    } else {
      toast.toastInfo("status not updated");
    }


  }

  searStatus = [
    { sortKey: "all", sort: "All" },
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  searHubId;
  searBarcodeId;

  tableMeta = {
    key: "packMaterialId",
    columns: [
      {
        key: "PACK_MATERIAL_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              {/* <button
                className="dropdown-item"
                type="button"
                onClick={() => this.loadBarcodes(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button> */}
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDamage(r, "40damaged")}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Damaged
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleScrap(r, "50scraped")}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Scraped
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDetach(r, "00available")}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Detach
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },


      {
        key: "status",
        action: (r) => (
          <>
            <span className="badge badge-success pull-right mr-1 badge-normal">
              {r.status.replace(/[0-9]/g, '').toUpperCase()}
            </span>
          </>
        ),
        icon: () => <>Status</>,
        label: "Status",
      },
      { path: "hub", label: "Hub" },
      { path: "teamName", label: "Team" },
      { path: "barCode", label: "Barcode" },
      { path: "barcodeType", label: "Barcode Type" },
      { path: "packName", label: "Pack Name" },
      { path: "packType", label: "Packing Type" },
    ],
  };
  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    const user = this.state.user;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Packing Material Stock::List" />
        <div className="pageContent">


          <div className="row">
            <div className="col-12">


              <div className="row " style={{ marginLeft: "auto" }} >
                <div className="col-sm-2 mt-1">
                  {this.rSBA("Hub", "hubId", this.state.hubList, {
                    value: "hubId",
                    label: "hub",
                  }

                  )}
                </div>
                <div className="col-sm-2 mt-1">
                  {this.rSBA(
                    "Team",
                    "teamId",
                    this.state.teams,
                    { value: "teamId", label: "name" },
                  )}
                </div>
                {/* <div className="col-sm-1 mt-1">
                  {this.rSB(
                    "Type *",
                    "type",
                    this.state.type,
                    { value: "typeId", label: "type" },
                  )}
                </div> */}
                <div className="col-sm-2 mt-1">
                  {this.rSB(
                    "PackingMaterial",
                    "packMaterialId",
                    this.state.packingmaterials,
                    {
                      value: "packMaterialId",
                      label: "name",
                    },
                  )}
                </div>

                <div className="col-md-1 mt-1">
                  {this.rSB(
                    "Status *",
                    "status",
                    this.state.status,
                    { value: "statusId", label: "status" },

                  )}
                </div>


                {this.rTB("Barcode", "searchString", "col-1")}
                <div className="menu col-md-2 " style={{ marginRight: "-7%" }}>
                  {/* {this.state.curPackingMaterial["packMaterialId"] === "" && (
                    <button
                      type="button"
                      title="Packing material"
                      className="btn btn-sm btn-info pull-left"
                      data-toggle="modal"
                      data-target="#myMaterialModal"
                    >
                      <i className={"fa  fa-plus  "}></i>
                    </button>
                  )} */}
                  {this.state.curPackingMaterial["packMaterialId"] !== "" && (
                    <button
                      type="button"
                      className="btn btn-sm btn-success pull-left"
                      data-toggle="modal"
                      data-target="#myMaterialModal"
                    >
                      <i className={"fa  fa-pencil"}></i>
                    </button>
                  )}
                  {/* <button
                    type="button"
                    className="btn btn-sm btn-secondary pull-left"
                    onClick={() => this.loadPackingMaterial()}
                  >
                    <i className={"fa  fa-refresh  "}></i>
                  </button> */}
                  {/* {this.state.curPackingMaterial["packMaterialId"] !== "" && ( */}
                  <button
                    type="button"
                    className="btn btn-sm btn-success pull-left"
                    data-toggle="modal"
                    data-target="#"
                    onClick={() => this.loadBarcodes(this.state.data)}
                  >
                    <i className={"fa  fa-search "} ></i>
                  </button>
                  {/* )} */}
                </div>
                <div className=" col-md-2" style={{ marginRight: "-11%" }}>
                  <div class="dropdown">
                    <button class="btn btn-sm btn-info dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      Add
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li>
                        {this.state.curBarcode.barcodeId === "" && (
                          <a class="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#myBarcodeModalSE"
                            style={{ marginRight: "3px" }}
                          >
                            Barcode Start End
                          </a>
                        )}

                      </li>
                      <li>
                        {this.state.curBarcode.barcodeId === "" && (
                          <a class="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#myBarcodeModalSN"
                            style={{ marginRight: "2px" }}>
                            Barcode Start number
                          </a>
                        )}
                      </li>
                      <li>
                        {this.state.curBarcode.barcodeId === "" && (
                          <a class="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#myBarcodeModalN"
                          >
                            Barcode Numbers
                          </a>
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className=" col-md-2">
                  <div class="dropdown">
                    <button class="btn btn-sm btn-warning dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li>
                        {this.state.curPackingMaterial["packMaterialId"] === "" && (

                          <a class="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#myMaterialModal"
                          >
                            Create Packing Material
                          </a>
                        )}
                      </li>
                      <li>
                        {this.state.curBarcode.barcodeId === "" && (
                          <a class="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#myBulkBarcodeUpdate"
                          >
                            Bulk Barcodes Status
                          </a>
                        )}
                      </li>
                      <li>
                        {this.state.curBarcode.barcodeId === "" && (
                          <a
                            class="dropdown-item"
                            type="button"
                            title="barcode transfer"
                            data-toggle="modal"
                            data-target="#myBarcodeModalTransfer"
                          >
                            Bulk Barcodes Transfer
                          </a>
                        )}
                      </li>
                    </ul>
                  </div>

                </div>
              </div>

            </div>

          </div>


          {/* {this.state.barcodes.length === 0 && (
            <div className="jumbotron jumbotron-fluid">
              <p className="lead text-center">Select Packing Material</p>
            </div>
          )} */}

          {!this.state.loading && this.state.barcodes.length === 0 && (
            <div className="jumbotron jumbotron-fluid">
              <p className="lead text-center">Packing Material</p>
            </div>
          )}

          <div className="row">
            <div className="col-md-9">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Barcode...</div>
                </div>
              )}
              {!this.state.loading && this.state.barcodes.length !== 0 && (
                <div className="container-fluid">
                  <div className="card" style={{ marginRight: "-121px" }}>
                    <div className="card-body">
                      <Table
                        clsName="table table-striped tabele-bordered table-hover "
                        tableMeta={this.tableMeta}
                        data={this.state.barcodes}
                        dropMenu={[]}
                      />
                      <Pagination
                        pageNo={pageNo}
                        perPage={perPage}
                        itemsCount={itemsCount}
                        onPageChange={this.handlePackMaterialChange}
                      />
                    </div>
                  </div>
                </div>

              )}
            </div>
            {!this.state.loading && this.state.barcodes.length !== 0 && (
              <div className="col-md-3 mt-3">
                <div className="container-fluid">
                  <div className="card ">
                    <div className="card-body " style={{ backgroundColor: "rgb(82 82 86 / 17%)" }}>
                      <h1
                        style={{
                          backgroundColor: "rgb(241, 169, 114)",
                          textAlign: "center",
                          marginTop: "-20px",
                          marginLeft: "-20px",
                          marginRight: "-20px",
                          padding: "5px",
                        }}
                      >
                        At Location
                      </h1>
                      <p>
                        <b>Available : </b>
                        {this.state.available &&
                          <span className="badge badge-success badge-normal" style={{ fontSize: "100%" }}>
                            {this.state.available}
                          </span>
                        }
                      </p>
                      <p>
                        <b>Issued : </b>
                        {this.state.issued &&
                          <span className="badge badge-success badge-normal" style={{ fontSize: "100%" }}>
                            {this.state.issued}
                          </span>
                        }
                      </p>
                      <p>
                        <b>Damaged : </b>
                        {this.state.damaged &&
                          <span className="badge badge-success  badge-normal" style={{ fontSize: "100%" }}>
                            {this.state.damaged}
                          </span>
                        }
                      </p>
                      <p>
                        <b>Scrapped : </b>
                        {this.state.scraped &&
                          <span className="badge badge-success badge-normal" style={{ fontSize: "100%" }}>
                            {this.state.scraped}
                          </span>
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <span>{JSON.stringify(this.state.data)}</span> */}
        </div>
        <ModalPackingMaterial
          curPackingMaterial={this.state.curPackingMaterial}
          key={this.state.curPackingMaterial["packMaterialId"]}
        />
        <ModalBarcodeSE
          curBarcode={this.state.data}
          curHubId={this.state.curHubId}
          curPackingMaterial={this.state.curPackingMaterial}
          packingmaterials={this.state.packingmaterials}
          key={this.state.curPackingMaterial["packMaterialId"] + this.state.curHubId + 1}
        />
        <ModalBarcodeSN
          curBarcode={this.state.data}
          curHubId={this.state.curHubId}
          curPackingMaterial={this.state.curPackingMaterial}
          packingmaterials={this.state.packingmaterials}
          key={this.state.curPackingMaterial["packMaterialId"] + this.state.curHubId + 2}
        />
        <ModalBarcodeN
          curBarcode={this.state.data}
          curHubId={this.state.curHubId}
          curPackingMaterial={this.state.curPackingMaterial}
          packingmaterials={this.state.packingmaterials}
          key={this.state.curPackingMaterial["packMaterialId"] + this.state.curHubId + 3}
        />
        <ModalBarcodeTransfer
          curBarcode={this.state.data}
          srcHubId={this.state.srcHubId}
          dstHubId={this.state.dstHubId}
        // curPackingMaterial={this.state.curPackingMaterial}
        // packingmaterials={this.state.packingmaterials}
        // key={this.state.curPackingMaterial["packMaterialId"] + this.state.curHubId + 3}
        />
        <ModalUpdateBulkBarcodeStatus
        />
      </React.Fragment>
    );
  }
}
export default PackingMaterialStock;
