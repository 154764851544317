import React from "react";
import FormLib from "./../../_common/FormLib";

class ProjectUserStorires extends FormLib {
  state = {
    moreInfo: false,
  };

  toggleMoreInfo = () => {
    if (this.state.moreInfo) {
      this.setState({ moreInfo: false });
    } else {
      this.setState({ moreInfo: true });
    }
  };

  renderButtonStyle(status) {
    switch (status) {
      case "wip":
        return "badge-warning";
      case "done":
        return "badge-light";
      default:
        return "badge-info";
    }
  }
  isCur(id, cur) {
    if (id === cur) {
      return " curTasks ";
    }
    return " ";
  }

  render() {
    const userstories = this.props.userstories;
    const curModule = this.props.curModule;
    const curUserstory = this.props.curUserstory;
    return (
      <div className="card  " style={{ margin: 0, padding: 2 }}>
        <h5 className="card-header">
          Userstories
          <button
            type="button"
            className="btn btn-sm btn-warning pull-right"
            onClick={() => this.toggleMoreInfo()}
          >
            {this.state.moreInfo}
          </button>
          {curModule.moduleId && !curUserstory.userstoryId && (
            <button
              type="button"
              className="btn btn-sm btn-primary pull-right"
              data-toggle="modal"
              data-target="#myUserstoryModel"
            >
              <i className={"fa  fa-plus  "}></i>
            </button>
          )}
          {curUserstory.userstoryId && curUserstory.userstoryId !== "" && (
            <>
              <button
                type="button"
                className="btn btn-sm btn-warning pull-right"
                onClick={() => this.props.onUserstoryUnload()}
              >
                <i className={"fa  fa-unlink  "}></i>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-success pull-right"
                data-toggle="modal"
                data-target="#myUserstoryModel"
              >
                <i className={"fa  fa-pencil  "}></i>
              </button>
            </>
          )}
        </h5>

        <div className="card-body" style={{ padding: 2 }}>
          <div className="page-todo">
            <div className=" tasks">
              {userstories.map((mi) => (
                <div
                  className={
                    "task nil " +
                    this.isCur(mi.userstoryId, curUserstory.userstoryId)
                  }
                  key={mi.userstoryId}
                >
                  <div className="desc" style={{ width: "100%" }}>
                    <span
                      className="span-click"
                      onClick={() => this.props.onUserstoryChange(mi)}
                    >
                      <div className="title">
                        {mi.usTitle}
                        <span
                          className={
                            "badge   badge-success pull-right " +
                            "badge-" +
                            mi.status
                          }
                        >
                          {mi.sort + " : " + mi.status.substring(2)}
                        </span>
                      </div>
                    </span>

                    <div>
                      {this.state.moreInfo && (
                        <>
                          <span
                            className={
                              "badge   badge-success pull-left mr-1 " +
                              "badge-" +
                              mi.usPriority
                            }
                          >
                            {mi.usPriority}
                          </span>
                          <span
                            className={
                              "badge   badge-success pull-left  mr-1 " +
                              "badge-" +
                              mi.usComplexity
                            }
                          >
                            {mi.usComplexity}
                          </span>
                          |{" " + mi.usOwner} | {mi.usPoints} pts
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectUserStorires;
