import React from "react";
import FormLib from "../../../_common/FormLib";
import swal from "sweetalert";
import Joi from "joi-browser";

import Table from "../../../_common/Table";
import Pagination from "./../../../_common/Pagination";
import LifelongDocket from "./LifelongDocket";
import LocationTitle from "../../../_subhead/LocationTitle";
import { getLifelongTickets } from "./../../../../services/Tickets/create/LifelongService";

import toast from "../../../../services/_toastService";
class LifeLongTicketList extends FormLib {
  state = {
    data: {
      retailerId: "LIFELONG",
      fromDate: this.getDatebyDays(-1),
      toDate: this.getDatebyDays(1),
      cusComplaintNo: "",
      status: "all",
      ticketNo: "",
    },
    errors: {},
    loading: true,
    formMode: "create",
    misTickets: [],
    cusMisTicket: {},
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };
  schema = {
    retailerId: Joi.string().required().label("retailerId"),
    fromDate: Joi.string().required().label("From Date"),
    toDate: Joi.string().required().label("To Date"),
    cusComplaintNo: Joi.optional().label("Order No"),
    ticketNo: Joi.optional().label("ticketNo"),
    status: Joi.optional().label("Status"),
  };
  componentDidMount() {
    //  this.loadCountry();
  }
  async doSubmit() {
    this.loadTickets();
  }
  loadTickets = async () => {
    let res;
    this.setState({ loading: true });
    res = await getLifelongTickets(this.state.data);
    // this.resetData();

    if (res.data.status === 1) {
      if (res.data.data) {
        toast.toastSuccess(res.data.msg);
        this.setState({ misTickets: res.data.data });
      } else {
        toast.toastWarn("No Data");
      }
      // this.loadCountry();
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  handleOnDocket(r) {
    this.setState({ cusMisTicket: r });
  }

  handleView = (r) => {
    this.setState({ curCountry: r, formMode: "view" });
  };

  resetData() {
    this.setState({
      data: {
        countryId: "",
        country: "",
        status: "active",
      },
      formMode: "create",
    });
  }
  status = [
    { sortKey: "all", sort: "All" },
    { sortKey: "RepairPartIsuue", sort: "Part Pending" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  ticketStatusMapp = {
    APPOINTMENT_HAS_FIXED: "Appointment Taken",
    ASSIGNED_TO_FE_FOR_INSTALLATION: "Allotted",
    INSTALLATION_DONE: "Resolved",
    "PENDING TECHNICAL ASSITANCE": "Pending Spares",
    "CUSTOMER PENDING": "Customer Pending",
    "PENDING INTRANSIT": "Spares Dispatched",
    CUSTOMER_REJECT_TO_CORDINATOR_AT_INITAILCALL: "Cancelled",
    INSTALLATION_REJECTED_TO_FE_BY_CUSTOMER: "Cancelled",
    CUSTOMER_RESCHEDULE_TO_COORDINATOR: "Rescheduled",
    INSTALLATION_RESCHEDULE_TO_FE_BY_CUSTOMER: "Rescheduled",
  };
  tableMeta = {
    key: "countryId",
    columns: [
      {
        key: "COUNTRY_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnDocket(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Update
                Docket
              </button>
              <a
                className="dropdown-item"
                type="button"
                target="_blank"
                href={
                  "https://test.bizlog.in/#/task/" + r.PROCESS_ID_ + "/view"
                }
              >
                <i className="fa fa-binoculars" aria-hidden="true"></i> More
                Details
              </a>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      // { path: "countryId", label: "Country Id" },
      { path: "CONSUMER_COMPLAINT_NUMBER_", label: "Comp No" },
      { path: "RL_TICKET_NO_", label: "Tickt NO" },
      { path: "CONSUMER_NAME_", label: "Customer" },
      { path: "TEXT_", label: "Part" },
      { path: "DOCKET_NO_", label: "Docket No" },
      // { path: "CURRENT_STATUS_", label: "Status" },

      {
        key: "tokenExpiryDate",
        action: (
          r,
          ticketStatusMapp = {
            TICKET_CREATED: "Opened",
            ALLOTTED: "Allotted",
            APPOINTMENT_HAS_FIXED: "Appointment Taken",
            APPOINTMENT_FIXED: "Appointment Taken",
            ASSIGNED_TO_FE_FOR_INSTALLATION: "Allotted",
            INSTALLATION_DONE: "Resolved",
            "PENDING TECHNICAL ASSITANCE": "Pending Spares",
            "CUSTOMER PENDING": "Customer Pending",
            "PENDING INTRANSIT": "Spares Dispatched",
            CUSTOMER_REJECT_TO_CORDINATOR_AT_INITAILCALL: "Cancelled",
            INSTALLATION_REJECTED_TO_FE_BY_CUSTOMER: "Cancelled",
            CUSTOMER_RESCHEDULE_TO_COORDINATOR: "Rescheduled",
            INSTALLATION_RESCHEDULE_TO_FE_BY_CUSTOMER: "Rescheduled",
            CLOSED: "Closed",
            OPENED: "Opened",
          }
        ) => (
          <>
            {ticketStatusMapp[r.CURRENT_STATUS_]
              ? ticketStatusMapp[r.CURRENT_STATUS_]
              : "=>" + r.CURRENT_STATUS_}
          </>
        ),
        cusClass: " ",
        icon: () => <>Status</>,
      },
    ],
  };
  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Tickets" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-9">
              <div className="card">
                <div className="card-body">
                  <form id="myForm" onSubmit={this.handleSubmit}>
                    <div className="form-row">
                      {this.rTBH("retailerId", "retailerId", "col-md-3")}
                      {/* {this.rDP("From Date *", "fromDate", "col-md-3")}
                      {this.rDP("To Date *", "toDate", "col-md-3")} */}

                      {this.rSB(
                        "Status",
                        "status",
                        this.status,
                        this.stsConf,
                        " col-md-2 "
                      )}
                      {this.rTB("Order No", "cusComplaintNo", "col-md-3")}
                      {this.rTB("Ticket No", "ticketNo", "col-md-3")}
                      <span>
                        {this.rBtnS(
                          "Search",
                          "Search",
                          "btn btn-primary  btn-sm"
                        )}
                      </span>
                    </div>
                  </form>
                </div>
              </div>
              {this.state.loading && (
                <div>
                  <div>Loading Tickets</div>
                </div>
              )}
              {!this.state.loading && (
                <>
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.misTickets}
                    dropMenu={[]}
                  />
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                </>
              )}
            </div>
            <div className="col-md-3">
              {this.state.cusMisTicket["PROCESS_ID_"] && (
                <LifelongDocket
                  curMisTicket={this.state.cusMisTicket}
                  key={this.state.cusMisTicket["PROCESS_ID_"]}
                  handleLoadTickets={this.loadTickets}
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LifeLongTicketList;
