import _http from "../_httpService";
import config from "../../config.json";
import { axios } from "axios";
const baseURL = config[config.build].apiURLMis;
const baseURLInventory = config[config.build].apiURLInventory;


function api(path, subURL = "/shipment") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}
function apiV1(path, subURL = "/v3/shipment") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

function apiBlock(path, subURL = "/linehaul") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function createManifestsAsOld(req) {
  const params = { ...req };
  // const url = "http://192.168.3.78:9092/shipment/create";

  return _http.post(
    api("create"),
    // url,
    params, _http.headerJWT());
}

export function createManifests(data) {
  // console.log("createManifests.........." + JSON.stringify(req));
  // const params = { ...req };
  // const url = "http://192.168.3.78:9092/shipment/create";

  try {
    const formData = new FormData();

    if (data.selectedFilesList.length > 0) {


      const files = data.selectedFilesList;

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i].name)
      }
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }
    else {
      const files = data.selectedFilesList;
      formData.append("files", null);
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }


    return _http.post(
      apiV1("create"),
      // url,
      formData, _http.headerJWT());
    // let url = "http://192.168.3.78:9092/attachment/pod/upload"
    // let apiMisEndpoints = apiMisEndpoint + "/attachment/pod/upload";
    // return _http.post(
    //   apiMisEndpoints,
    //   // url,
    //   formData,
    //   _http.headerJWT()
    // );

  }
  catch {
    return undefined;
  }

 
}

export function getLinehaulReqCount(srcId) {
  return _http.get(api("count/" + srcId), _http.headerJWT());
}

export function getLinehaulBySrcDst(req) {
  const params = { ...req };
  return _http.post(api("get-list"), params, _http.headerJWT());
}

export function getIncoming(req, pageNo, pageSize = 10) {
  // const params = { ...req };
  // const url = "http://192.168.3.78:9092/shipment/incoming/" + pageSize + "/" + pageNo
  return _http.post(
    api("incoming/" + pageSize + "/" + pageNo),
    // url,
    req,
    _http.headerJWT()
  );
}

export function blockStatus(req) {
  // console.log("blockStatus.." + JSON.stringify(req));
  const params = { ...req };
  return _http.put(apiBlock("received/block"), params, _http.headerJWT());
}

export function getOutgoing(req, pageNo, pageSize = 10) {
  // const params = { ...req };
  // const url = "http://192.168.3.78:9092/shipment/outgoing/" + pageSize + "/" + pageNo

  return _http.post(
    api("outgoing/" + pageSize + "/" + pageNo),
    // url,
    req,
    _http.headerJWT()
  );
}
export function getCompleted(req, pageNo, pageSize = 10) {
  const params = { ...req };
  return _http.post(
    api("completed/" + pageSize + "/" + pageNo),
    params,
    _http.headerJWT()
  );
}

export function updateShipmentNo(ticketLineHaulId, shipmentNo) {
  const path = `${ticketLineHaulId}/${shipmentNo}`;
  const params = "";
  return _http.put(api(path, ""), params, _http.headerJWT());
}

export function getOutgoingReview(req, pageNo, pageSize = 10) {
  const params = { ...req };
  // const url = "http://192.168.3.78:9092/preview/shipment/outgoing/" + pageSize + "/" + pageNo
  return _http.post(
    `${baseURL}/preview/shipment/outgoing/` + pageSize + "/" + pageNo,
    // url,
    params,
    _http.headerJWT()
  );
}

export function getOutgoingReviewExport(req, pageNo, pageSize = 10) {
  const params = { ...req };
  // const url = "http://192.168.3.78:9092/preview/shipment/outgoing/export/" + pageSize + "/" + pageNo
  return _http.post(
    `${baseURL}/preview/shipment/outgoing/export/` + pageSize + "/" + pageNo,
    // url,
    params,
    _http.headerJWT()
  );
}

export function getLinehaulTicktes(lineSeqId) {
  const url = `${baseURL}/linehaulSeq/getTickets/` + lineSeqId
  // const url = "http://192.168.3.78:9092/linehaulSeq/getTickets/" + lineSeqId
  return _http.get(url, _http.headerJWT());
}


export function getReversed(req, pageNo, pageSize = 10) {
  const params = { ...req };
  // const url = "http://192.168.3.78:9092/shipment/reversed/" + pageSize + "/" + pageNo

  return _http.post(
    api("reversed/" + pageSize + "/" + pageNo),
    params,
    _http.headerJWT()
  );
}

export function createBox(req) {

  try {
    const params = { ...req };
    const url = `${baseURLInventory}/createLineHaulBoxesV1`;
    return _http.post(url, params, _http.headerJWT())
  } catch (e) {
    console.log(e)
  }
}
export function getClusteredHubsDetails(hubId) {

  try {

    const url = `${baseURLInventory}/getClusteredHubsDetails/${hubId}`;
    return _http.get(url, _http.headerJWT())
  } catch (e) {
    console.log(e)
  }
}

// https://test-api.bizlog.in:1337/inventory/getLineHaulBoxesByHubIds
export function getLineHaulBoxesByHubIds(req) {

  try {

    const url = `${baseURLInventory}/getLineHaulBoxesByHubIds`;
    const params = { ...req }

    return _http.put(url, params, _http.headerJWT())
  } catch (e) {
    console.log(e)
  }
}
// export function getLogisticsProbviderData() {
//   try {
//     const url = `https://test-api.bizlog.in:1337/core/logisticsProvider/active`;
//     return _http.get(url, _http.headerJWT())
//   } catch (e) {
//     console.log(e)
//   }
// }


export function updateBarcodeStatus(params) {
  return _http.put(api("boxVerification"), params, _http.headerJWT());
}

export function getLinehaulProducts(lineSeqId) {
  const url = `${baseURL}/shipment/ticketDetails/` + lineSeqId
  // const url = "http://192.168.3.78:9092/linehaulSeq/getTickets/" + lineSeqId
  return _http.get(url, _http.headerJWT());
}

  export function getLinehaulProductsV1(lineSeqId, pageNo, pageSize = 50) {
  const url = `${baseURL}/shipment/ticketDetails/` + lineSeqId + "/" + pageSize + "/" + pageNo 
  // const url = "http://192.168.3.78:9092/linehaulSeq/getTickets/" + lineSeqId
  return _http.get(url, _http.headerJWT());
}
  export function updateAwbNO(params) {
    const url = `${baseURL}/v1/shipment/manifestation`
  return _http.put(url, params, _http.headerJWT());
  }

  export function updateBarcodeStatusv1(params) {
    return _http.put(api("verifyBox"), params, _http.headerJWT());
  }

  export function blockStatusv1(req) {
    // console.log("blockStatus.." + JSON.stringify(req));
    const params = { ...req };
    return _http.put(api("boxReceived"), params, _http.headerJWT());
  }

  export function verifyProduct(params) {
    return _http.put(api("verifyProduct"), params, _http.headerJWT());
  }

  export function updateReceiveProdStatus(params) {
    return _http.put(api("receivedProduct"), params, _http.headerJWT());
  }
  export function blockStatusOutGoing(params) {
    const url = `${baseURL}/v1/linehaul/received/block`
    return _http.put(url, params, _http.headerJWT());
  }
  export function boxMissing(req) {
    // console.log("blockStatus.." + JSON.stringify(req));
    const params = { ...req };
    return _http.put(api("boxMissing"), params, _http.headerJWT());
  }

  export function boxDamage(req) {
    // console.log("blockStatus.." + JSON.stringify(req));
    const params = { ...req };
    return _http.put(api("boxDamaged"), params, _http.headerJWT());
  }



  export function missingBoxReceive(req) {
    // console.log("blockStatus.." + JSON.stringify(req));
    const params = { ...req };
    return _http.put(api("receivingMissedBox"), params, _http.headerJWT());
  }
  
  export function missingBoxLost(req) {
    // console.log("blockStatus.." + JSON.stringify(req));
    const params = { ...req };
    return _http.put(api("boxCompletelyLost"), params, _http.headerJWT());
  }
  
  export function getBoxDetails(blockId) {
  
    try {
  
      return _http.get(api(`boxDetails/${blockId}`), _http.headerJWT())
    } catch (e) {
      console.log(e)
    }
  }