import React, { Fragment, Component } from "react";
import { toast } from "react-toastify";
import FormLib from "./../../_common/FormLib";
import Table from "./../../_common/Table";
import Products from "./../products/Products";
import { IoBarcodeOutline, IoCopyOutline } from "react-icons/io5";
import BarCodePrintSVG from "./BarCodePrintSVG";
import { pickUpConfirm } from "./pickupConfirm";
// import TableHeader from "../../_common/TableHeader";
// import TableBody from "../../_common/TableBody";

import Tables from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHeader from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { packingtListReload } from "../../../store";
import {
  getPackingMaterialAndProdByJobId,
  verifyingPackingMaterialAndProduct,
  updatePackingMaterialAndProductLost,
  getPackingMaterialAndProdByJobIdAdvExc,
} from "./../../../services/Tickets/TicketProcessServices";
import { async } from "rxjs";
import swal from "sweetalert";
import { IstToUTC } from "../../../utils/DateUtils";

export default class DropAssignItemTicket extends FormLib {
  state = {
    data: {},
    errors: {},
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    printBarcode: "",
    pickUpConfirmData: [],
    confirmData: false,
    count: 0,
    isOverride: false,
  };

  componentDidMount() {
    const curTicketDetails = this.props.ticketDetails;
    this.setState({ curTicketDetails });
    this.loadPackingMaterialData();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({ designation: userInfo.designation });
  }

  loadPackingMaterialData = async () => {
    try {
      let blockData = this.props.blockDetails;
      let ticketData = this.props.ticketDetails;
      let res = await getPackingMaterialAndProdByJobIdAdvExc(blockData, ticketData);
      if (res !== undefined) {
        if (res.data.success === true && res.data.data) {
          this.setState({
            pickUpConfirmData: res.data.data.packingMaterialDetails,
          });
          // this.getPackingMaterialAndProdStatus(
          //   res.data.data.packingMaterialDetails
          // );
          this.setState({ confirmData: true });
        } else {
          this.setState({ confirmData: false });
        }
      } else {
        this.setState({ confirmData: false });
      }
    } catch {
      console.log(
        "Error in loadPackingMaterialData>>>>>>>>>>>>>>>>>>>>>>>>>>DropAssignItemTicket"
      );
    }
  };
  // getPackingMaterialAndProdStatus = async (data) => {
  //   data.map((r) => {
  //     if (r.packMaterialId) {
  //       if (r.packMaterialItemStatus !== null) {
  //         this.props.onHandleGetConfirmStatus();
  //       }
  //     } else {
  //       if (r.productDetails[0].itemStatus !== null) {
  //         this.props.onHandleGetConfirmStatus();
  //       }
  //     }
  //   });
  // };

  onCellSelection = async (r) => {
    try {
      let curHub = localStorage.getItem("curHub");

      if (r.packMaterialId) {
        if (this.state.data["pb_" + r.packMaterialSl] !== r.packMaterialSl) {
          swal({
            icon: "error",
            title:
              "Packing Barcode Does not Match Kindly enter the correct Barcode",
            dangerMode: true,
          }).then((res) => {
            return null;
          });
          return null;
        } else {
          let packingParams = {
            packing: true,
            slNoId: r.packMaterialId,
            hubId: curHub,
            status: "AssignedDropItems",
            isOverride: this.state.isOverride,
            processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          };

          let res = await verifyingPackingMaterialAndProduct(packingParams);
          if (res.data.success === true && res.data.data) {
            toast.success(() => <div>{res.data.msg}</div>);
            this.props.onHandleGetConfirmStatus();
          } else {
            toast.error(() => <div>{res.data.msg}</div>);
            this.raisedDropModal(packingParams);
          }
        }
      } else {
        if (
          JSON.parse(this.state.data["pb_" + r.productDetails[0].ticketProductId]) !==
          r.productDetails[0].slNo
        ) {
          swal({
            icon: "error",
            title:
              "Product Barcode Does not Match Kindly enter the correct Barcode",
            dangerMode: true,
          }).then((res) => {
            return null;
          });
          return null;
        } else {
          let productParams = {
            packing: false,
            slNoId: r.productDetails[0].slNoId,
            hubId: curHub,
            status: "AssignedDropItems",
            isOverride: this.state.isOverride,
            processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          };

          let res = await verifyingPackingMaterialAndProduct(productParams);
          if (res.data.success === true && res.data.data) {
            toast.success(() => <div>{res.data.msg}</div>);
            this.props.onHandleGetConfirmStatus();
          } else {
            toast.error(() => <div>{res.data.msg}</div>);
            this.raisedDropModal(productParams);
          }
        }
      }
    } catch {
      console.log("Error in DropAssignItemTicket>>>>>>onCellSelection");
    }
  };

  raisedDropModal(data) {
    swal({
      title:
        "PickUp is Not Confirmed for Item, Are you still wants to proceed for drop?",
      icon: "warning",
      buttons: ["Cancel", true],
      dangerMode: [true],
    }).then((willDelete) => {
      if (willDelete) {
        this.setState({ isOverride: true });
        this.requestDrop(data);
      } else {
        swal("You can edit now and retry to assign the item for drop");
      }
    });
  }

  async requestDrop(data) {
    let params = {
      packing: data.packing,
      slNoId: data.slNoId,
      hubId: data.hubId,
      status: "AssignedDropItems",
      isOverride: true,
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    let res = await verifyingPackingMaterialAndProduct(params);
    if (res.data.success === true) {
      toast.success(() => <div>{res.data.msg}</div>);
      this.props.onHandleGetConfirmStatus();

      document.getElementById("myForm").reset();
    }
  }

  handleProductLost = async (data) => {
    swal({
      title: "Are you sure want to update Product Status as Lost ?",
      text: "Kindly Enter the Reason for Product Lost",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: "input",
      placeholder: "Write something",
    }).then(async (handleProductLost) => {
      this.doProductLost(handleProductLost, data);
    });
  };

  doProductLost = async (handleProductLost, data) => {
    if (handleProductLost) {
      swal(
        "Product Lost status upadted successfully with the following remarks: " +
          handleProductLost,
        {
          icon: "success",
        }
      );
      this.onProductLost(handleProductLost, data);
    } else {
      swal("Product status not updated..!", { icon: "error" });
    }
  };
  onProductLost = async (data, r) => {
    try {
      let curHub = localStorage.getItem("curHub");

      if (r.packMaterialId) {
        let packingParams = {
          packing: true,
          slNoId: r.packMaterialId,
          status: "itemLost",
          remark: data,
        };

        let res = await updatePackingMaterialAndProductLost(packingParams);
        if (res.data.success === true && res.data.data) {
          toast.success(() => <div>{res.data.msg}</div>);
          this.props.onHandleGetConfirmStatus();
        }
      } else {
        let productParams = {
          packing: false,
          slNoId: r.productDetails[0].slNoId,
          status: "itemLost",
          remark: data,
        };

        let res = await updatePackingMaterialAndProductLost(productParams);
        if (res.data.success === true && res.data.data) {
          toast.success(() => <div>{res.data.msg}</div>);
          this.props.onHandleGetConfirmStatus();
        }
      }
    } catch {
      console.log("Error in DropAssignItemTicket>>>>>>onProductLost");
    }
  };

  handlePackingListReload = () => {
    this.props.packingListReload(
      this.props.packingListReloadState.barcodeVerification.packingListReload
    );
  };

  render() {
    const productDetails = this.state.curProductDetails;
    let ticketDetails = this.state.curTicketDetails;
    return (
      <>
        {" "}
        <div className="form-subHeader">Drop Products Items Assign</div>
        <div className="row">
          {this.props.loading && (
            <div className="container-fluid">
              <div> Loading Tickets...</div>
            </div>
          )}
          {!this.props.loading && (
            <>
              <div className="container-fluid">
                {this.state.confirmData === true && (
                  <>
                    <Tables className="table table table-bordered table-hover">
                      <TableHeader>
                        <TableRow>
                          <TableCell></TableCell>
                          {/* {ticketDetails.flowId === "Bulk" && (
                            <>
                              <TableCell>Scan Packing Barcode</TableCell>
                            </>
                          )} */}
                          {(ticketDetails.flowId != "Bulk" ||
                            ticketDetails.flowId != "DropOff") && (
                            <>
                              <TableCell>Scan Product Barcode</TableCell>
                            </>
                          )}
                          <TableCell>Scan Product Barcode</TableCell>
                          {/* {ticketDetails.flowId === "Bulk" && (
                            <>
                              <TableCell>Packing Barcode</TableCell>
                              <TableCell>Packing Name</TableCell>
                            </>
                          )} */}
                          <TableCell>Barcode</TableCell>
                          <TableCell>Product</TableCell>
                          <TableCell>Product Code</TableCell>
                          {ticketDetails.flowId === "AdvanceExchange" && (
                            <TableCell>Product Type</TableCell>
                          )}
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {this.state.pickUpConfirmData.map((item) => (
                          <Fragment>
                            <>
                              <TableRow>
                                <TableCell
                                  style={{ textAlign: "center" }}
                                  rowSpan={item.productDetails.length + 1}
                                >
                                  {/* <span> */}
                                  <div className="row">
                                    <div className="col-3">
                                      <span
                                        className="badge badge-primary link-over"
                                        onClick={() =>
                                          this.onCellSelection(item)
                                        }
                                      >
                                        Confirm
                                      </span>{" "}
                                    </div>
                                  </div>
                                </TableCell>
                                {/* {ticketDetails.flowId === "Bulk" && (
                                  <>
                                    <TableCell
                                      style={{ textAlign: "center" }}
                                      rowSpan={item.productDetails.length + 1}
                                    >
                                      <div className="row">
                                        {item.packMaterialId !== "" && (
                                          <div className="col-9">
                                            {this.rTB(
                                              "Barcode",
                                              "pb_" + item["packMaterialSl"],
                                              ""
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </TableCell>
                                  
                                  </>
                                )} */}
                              </TableRow>

                              {item.productDetails.map((detail) => (
                                <TableRow>
                                  <TableCell>
                                    {item.packMaterialId === "" && (
                                      <div className="col-9">
                                        {this.rTB(
                                          "Barcode",
                                          "pb_" + detail["ticketProductId"],
                                          ""
                                        )}
                                      </div>
                                    )}
                                  </TableCell>
                                  {/* {ticketDetails.flowId === "Bulk" && (
                                    <>
                                      <TableCell
                                        style={{
                                          color: "transparent",
                                          textShadow: "0 0 8px #000",
                                        }}
                                      >
                                        {item?.packMaterialSl}
                                      </TableCell>
                                      <TableCell>
                                        {item?.packMaterialName}
                                      </TableCell>
                                    </>
                                  )} */}
                                  <TableCell
                                    style={{
                                      color: "transparent",
                                      textShadow: "0 0 8px #000",
                                    }}
                                  >
                                    {detail?.slNo}
                                  </TableCell>
                                  <TableCell>{detail?.productName}</TableCell>
                                  <TableCell>{detail?.productCode}</TableCell>
                                  {ticketDetails.flowId ===
                                    "AdvanceExchange" && (
                                    <TableCell>{detail?.productType}</TableCell>
                                  )}
                                </TableRow>
                              ))}
                            </>
                          </Fragment>
                        ))}
                      </TableBody>
                    </Tables>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
