import React from "react";
import FormLib from "../../_common/FormLib";
import ZTitle from "../../_subhead/ZTitle";

import Joi from "joi-browser";
import { getDailyReports } from "./../../../services/plms/reportServices";
import * as _plms from "./../../../services/plms/_plmsServices";

import { toast } from "react-toastify";
import Table from "../../_common/Table";

import Pagination from "../../_common/Pagination";
import dateFormat from "dateformat";

export default class DailyReports extends FormLib {
  state = {
    data: {
      reportFrom: this.getDatebyDays(0),
      reportTo: this.getDatebyDays(0),
      reportBy: "",
    },
    errors: {},
    loading: false,
    reports: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      count: 0,
      lastPage: 0,
    },
  };

  schema = {
    reportFrom: Joi.string().required().label("Start Date"),
    reportTo: Joi.string().required().label("End Date"),
    reportBy: Joi.optional().label("Employee Code"),
  };
  tableMeta = {
    key: "reportId",
    columns: [
      { path: "project", label: "Project" },
      { path: "prModule", label: "Module" },
      { path: "usTitle", label: "US" },
      { path: "task", label: "Task" },

      {
        key: "DateTime",
        action: (r) => <>{dateFormat(r.reportFrom, "dd-mm-yyyy")}</>,
        label: "fromDateTime",
        icon: () => "fromDateTime",
      },
      {
        key: "taskStatusId",
        action: (r) => (
          <>
            <div className="pull-right">
              <span
                className={
                  "badge   badge-success pull-right ml-1 " +
                  "badge-" +
                  r["repStatus"]
                }
              >
                {r["repStatus"].substring(2)}
              </span>
            </div>
          </>
        ),
        label: "Tasks",
        cusClass: " ",
        icon: () => "Status",
      },
      {
        key: "taskId",
        action: (r) => (
          <>
            {r["remarks"]}
            {/* <br /> */}
            {/* {dateFormat(r.reportTo, "dd-mm-yyyy")} */}
            <div className="pull-right">
              <span
                className={
                  "badge   badge-success pull-right ml-1 " +
                  "badge-" +
                  r["repStatus"]
                }
              ></span>
            </div>
          </>
        ),
        label: "Tasks",
        cusClass: " ",
        icon: () => "Remarks",
      },
      {
        key: "reportBy",
        action: (r) => <>{_plms.getUserByCode(r.reportBy)}</>,
        label: "reportBy",
        icon: () => "reportBy",
      },
    ],
  };

  getUserByCode;

  async componentDidMount() {
    this.setOwners();
  }
  setOwners = () => {
    this.setState({ owners: _plms.users.owners });
    this.setState({ ownerConf: _plms.users.ownerConf });
  };
  async doSubmit() {
    await this.getReports(this.state.data, this.state.pageLinks.pageNo);
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/storyboard/daily-reports/" + pageNo);
    this.getReports(this.state.data, pageNo);
  };

  async getReports(params, pageNo) {
    this.setState({ loading: true });
    this.setState({ reports: [] });
    let resp = await getDailyReports(params, pageNo);

    if (resp.data.success === true) {
      resp.data.data.reports &&
        this.setState({ reports: resp.data.data.reports });
      resp.data.data.pageLinks &&
        this.setState({ pageLinks: resp.data.data.pageLinks });
      resp.data.data.reports && toast.success(() => <div>{resp.data.msg}</div>);
      !resp.data.data.reports && toast.warning(() => <div>No Reports</div>);
    } else {
      toast.error(() => <div>{resp.data.msg}</div>);
    }
    this.setState({ loading: false });
  }

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;

    const reports = this.state.reports;
    return (
      <React.Fragment>
        <ZTitle subHeading="Daily Report " />
        <div className="pageContent">
          <div className="card">
            <div className="card-body">
              <form id="myForm" onSubmit={this.handleSubmit}>
                <div className="form-row">
                  {this.rDP("Start Date *", "reportFrom", "col-md-3")}
                  {this.rDP("End Date *", "reportTo", "col-md-3")}
                  {this.rSB(
                    "Owner*",
                    "reportBy",
                    this.state.owners,
                    this.state.ownerConf,
                    " col-md-3 "
                  )}
                  <div className=" form-group ml-1 ">
                    {this.rBtnS("Submit", "Submit", "btn btn-primary  btn-sm")}
                  </div>
                </div>
              </form>
              <br />
              <div className="row">
                {this.state.loading && (
                  <div className="container-fluid">
                    <div> Loading Reports...</div>
                  </div>
                )}
                {!this.state.loading && itemsCount > 0 && (
                  <div className="container-fluid">
                    <Table
                      clsName="table table-striped table-bordered table-hover"
                      tableMeta={this.tableMeta}
                      data={reports}
                      dropMenu={[]}
                    />
                    <Pagination
                      pageNo={pageNo}
                      perPage={perPage}
                      itemsCount={itemsCount}
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                )}
                {!this.state.loading && itemsCount === 0 && (
                  <div className="alert alert-info col-12" role="alert">
                    <div className="text-center"> No Records Found! </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
