import _http from "../_httpService";
import config from '../../config.json';

const baseURL = config[config.build].apiClientReport;
// const apiPrevilageEndPoint = config[config.build].apiURLPrevilages;

function api(path) {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${path}`;
}
// function apiPrivilage(path) {
//     path = path !== "" ? "/" + path : "";
//     return `${apiPrevilageEndPoint}${path}`;
//   }

export function getAllFields() {
  const path = 'getMisFieldData'
  // console.log(api(path))
  return _http.get(api(path), _http.headerJWT());
}
export function getMaMisTeamsFields() {
  const path = 'getMaMisTeamsFields'
  // console.log(api(path))
  return _http.get(api(path), _http.headerJWT());
}

export function getAllDesignation() {
  const path = `getDesignation`;
  // console.log(path);
  return _http.get(api(path), _http.headerJWT())
}
export function getFieldsByDesignation(designationId) {
  const path = `getFieldsByDesignation/${designationId}`
  // console.log(path)
  return _http.get(api(path), _http.headerJWT())
}

export function MisTeamsFieldData(params) {
  const path = 'insertMaMisTeamsFields';
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}

export function getMisDesignationFieldDataByData(params) {
  const path = 'getMisDesignationFieldDataByData';
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}

export function getplayersByDesignationId(designationId) {
  const path = `getplayersByDesignationId/${designationId}`;
  // console.log(path);
  return _http.get(api(path), _http.headerJWT())
}

export function misDesignationFieldData(params) {
  const path = 'misDesignationFieldData';
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}
export function getMaMisTeamsFieldsByData(params) {
  const path = 'getMaMisTeamsFieldsByData';
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}
export function insertMaMisPlayersField(params) {
  const path = 'insertMaMisPlayersField';
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}
export function misFieldData(params) {
  const path = 'misFieldData';
  // console.log(path);
  return _http.post(api(path), params, _http.headerJWT())
}

export function getStatusOfmaMisTeamsData(params) {
  const path = 'getStatusOfmaMisTeamsData'
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}
export function getStatusOfmaMisPlayersData(params) {
  const path = 'getStatusOfmaMisPlayersData'
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}
export function getStatusOfmaMisDesignationData(params) {
  const path = 'getStatusOfmaMisDesignationData';
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}
export function updateMisDesignationFieldData(params) {
  const path = 'updateMisDesignationFieldData';
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}
export function updateMaMisPlayersField(params) {
  const path = 'updateMaMisPlayersField';
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}
export function updateMaMisTeamsFieldsByData(params) {
  const path = 'updateMaMisTeamsFieldsByData';
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}
export function updateMisDesignationFieldStatus(params) {
  const path = 'updateMisDesignationFieldStatus';
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}
export function updateMaMisTeamsFieldsStatus(params) {
  const path = 'updateMaMisTeamsFieldsStatus';
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}
export function updateMaMisPlayersFieldStatus(params) {
  const path = 'updateMaMisPlayersFieldStatus';
  // console.log(path);
  return _http.put(api(path), params, _http.headerJWT())
}



// http://localhost:1337/clientReport/updateMaMisTeamsFieldsByData

// http://localhost:1337/clientReport/updateMaMisPlayersField