import React from "react";
import $ from "jquery";
import FormLib from "../../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../../services/_toastService";
import {
  getDocuments,
  getSaveFile,
} from "../../../../services/requestV1/requestServices";

class DocumentDetails extends FormLib {
  state = {
    data: {
      description: "",
    },
    errors: {},
    currentRequest: {},
    user: {},
    selectedFile: null,
    photoDetails: [],
  };

  schema = {
    description: Joi.string().required().label("Description "),
  };

  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  componentDidMount() {
    this.loadData(this.props.data);
  }

  loadData = async (r) => {
    let data = [
      {
        fileId: "5252c11hhg",
        desc: "asdfggsd",
        url: "https://cdn.pixabay.com/photo/2015/12/01/20/28/road-1072821__340.jpg",
        uploadBy: "jshdjkhsk",
      },
      {
        fileId: "52s5211hhg",
        desc: "asdfggsd",
        url: "https://iso.500px.com/wp-content/uploads/2016/03/stock-photo-142984111-1500x1000.jpg",
        uploadBy: "jshdjkhsk",
      },
      {
        fileId: "52521f1hhg",
        desc: "asdfggsd",
        url: "https://cdn.pixabay.com/photo/2014/02/27/16/10/tree-276014__340.jpg",
        uploadBy: "jshdjkhsk",
      },
      {
        fileId: "525211hwhg",
        desc: "asdfggsd",
        url: "https://thumbs.dreamstime.com/b/beautiful-rain-forest-ang-ka-nature-trail-doi-inthanon-national-park-thailand-36703721.jpg",
        uploadBy: "jshdjkhsk",
      },
      {
        fileId: "5252g11hhg",
        url: "https://thumbs.dreamstime.com/b/environment-earth-day-hands-trees-growing-seedlings-bokeh-green-background-female-hand-holding-tree-nature-field-gra-130247647.jpg",
        desc: "asdfggsd",
        uploadBy: "jshdjkhsk",
      },
      {
        fileId: "52524111hhg",
        url: "https://media.cntraveler.com/photos/60596b398f4452dac88c59f8/16:9/w_3999,h_2249,c_limit/MtFuji-GettyImages-959111140.jpg",
        desc: "asdfggsd",
        uploadBy: "jshdjkhsk",
      },
      {
        fileId: "52528911hhg",
        url: "https://image.shutterstock.com/image-photo/large-drop-water-reflects-environment-260nw-1917029711.jpg",
        desc: "asdfggsd",
        uploadBy: "jshdjkhsk",
      },
    ];
    this.setState({ currentRequest: this.props.data });

    let res;
    if (r["REQUEST_ID"]) {
      res = await getDocuments(r["REQUEST_ID"]);
    }
    this.setState({ photoDetails: res.data?.data?.filesList });

    // console.log(JSON.stringify(res.data.data));
  };

  doSubmit = async () => {
    let res;
    if (this.state.selectedFile == null) {
      toast.toastError("File should not be null");
    } else {
      if (this.state.btnValue === "upload") {
        let dataParam = {
          REQUEST_ID: this.state.currentRequest.REQUEST_ID,
          INFO: this.state.data.description,
        };
        res = await getSaveFile(dataParam, this.state.selectedFile);
      }
      if (res.data.success) {
        this.resetData();
        this.loadData(this.state.currentRequest);
        toast.toastSuccess(res.data.msg);
      } else {
        toast.toastError(res.data.msg);
      }
    }
  };

  resetData = () => {
    this.setState({ data: { description: "" } });
    this.setState({ selectedFile: null });
  };

  render() {
    const user = this.props.user;
    const currentRequest = this.props.currentRequest;
    const fileStyle = {
      width: "130px",
      height: "130px",
      float: "left",
      cursor: "pointer",
      border: "1px solid #d3c4c4",
      "border-radius": "6px",
    };
    const imgStyle = {
      border: "1px solid #d3c4c4",
      width: "130px",
      height: "130px",
      "line-height": "130px",
      float: "left",
      cursor: "pointer",
      "font-size": "48px",
      color: "#d7caca",
      "background-color": "#f9f1f1",
      "border-radius": "6px",
    };
    let ticket = this.props.data.MULTI_REF_IDS;
    let ticketData = ticket.split(",");
    // console.log({ ticketData });

    const listTicketModified = ticketData.map((ticket) => (
      <p key={ticket}>
        <span className="bg-success text-white">{ticket}</span>
      </p>
    ));

    // // let result = ""
    // for (let i = 0; i < ticketData.length; i++) {
    //   //splitting the data here
    //     let split = ticketData[i].split(",");
    //     console.log({split})
    // }

    // console.log({ticket});
    return (
      <React.Fragment>
        <div className="card w-50">
          <div className="card-header bg-success text-white">
            <div className="row">
              <div className="col">
                Ticket Details
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                  style={{
                    display: "inline",
                    float: "right",
                    marginTop: "-0.4rem",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-body mt-0 ">
            <div className="row">
              <div className="col">
                <div className="container">
                  {ticketData.map((ticket) => (
                    <p key={ticket} className="p-0 m-0">
                      <span
                        className="bg-success text-white px-1 rounded"
                        style={{ fontSize: "0.6rem" }}
                      >{` ${ticket} `}</span>
                    </p>
                  ))}
                </div>
                <form className="mt-5">
                  <div className="form-row">
                    {this.rTA("Description", "description", "col-12")}
                    <input
                      type="file"
                      accept=".xlsx, .docx, .txt, .pdf, .jpg, .jpeg, .png, .gif "
                      className="btn btn-primary btn-sm"
                      title="file upload"
                      onChange={this.onFileChange}
                      // key={this.state.selectedFile || ""}
                    />
                  </div>
                </form>
                {this.rBtnSP("Upload", "upload")}
                {currentRequest}
              </div>
            </div>
            <hr />
            <div className="row">
              <div
                className="col-12 page-todo overflow-auto"
                style={{ height: "20rem" }}
              >
                <div className="tasks">
                  {this.state.photoDetails.map((r) => {
                    return (
                      <div className="task" key={r.S3_FILE_ID}>
                        <div className="desc mb-0 pb-0">
                          <p>
                            {r.FILE_TYPE == "xlsx" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_self"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-excel-o mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  ></i>
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "docx" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_self"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-word-o mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  ></i>
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "txt" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-text mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  ></i>
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "pdf" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-pdf-o mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  />
                                </a>
                              </>
                            )}

                            {r.FILE_TYPE == "jpg" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.FILE_PATH}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "jpeg" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.FILE_PATH}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "png" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.FILE_PATH}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "gif" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.FILE_PATH}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            <div>
                              <div>
                                <h5>
                                  <span> Upload By </span>
                                  <text>{r["MULTI_REF_IDS"]}</text>
                                  <span className="ml-2">
                                    {" "}
                                    : &nbsp;{r.CREATED_BY}
                                  </span>
                                </h5>
                              </div>
                              <div>
                                <h5>
                                  <span> Info </span>
                                  <span className="ml-5">
                                    {" "}
                                    : &nbsp;{r.INFO}
                                  </span>
                                </h5>
                              </div>
                              <div>
                                <h5>
                                  <span> File Type </span>
                                  <span className="ml-3">
                                    {" "}
                                    : &nbsp;{r.FILE_TYPE}
                                  </span>
                                </h5>
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default DocumentDetails;
