import React, { useState } from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import { getFlows } from './../../../services/coreItAdmin/flowsService';
import {
    getBrandsInfo,
    getMasterData,
    getRetailersInfo,
    uploadExcelTicketData,
} from "../../../services/Tickets/TicketServices";
import {
    getProductsInfo,
    getModelsInfo,
} from "./../../../services/Tickets/TicketServices";
import { connect } from 'react-redux';
import { getServiceCenters } from './../../../services/coreItAdmin/serviceCenters'

class TicketUploadNewModal extends FormLib {
    state = {
        data: {
            teamId: "",
            ticketPriority: "",
            conComplaintNo: "",
            flowId: "PickAndDropOneWay",
            retailerCode: "REL",
            productCode: "",
            modelCode: "",
            modelName: "",
            brandCode: "",
            brandName: "",
            isAppointmentReq: "",
            isInstallaationReq: "",
            isPhysicalEval: "",
            isTechEval: "",
            isPackingReq: "",
            retailerNo: "",
            invoiceNo: "",
            orderNo: "",
            ticketDetails: "",
            specialInstructions: "",
            dateOfPurchase: "",
            identificationNo: "",
            productDesc: "",
            problemDesc: "",
            productValue: "",
            cost: "",
            isUnderWarranty: "",
            srcContactPerson: "",
            srcAdd1: "",
            srcAdd2: "",
            srcLocation: "",
            srcContact1: "",
            srcEmailId: "",
            srcCity: "",
            srcState: "",
            srcPincode: "",
            srcType: "",
            dstContactPerson: "",
            dstAdd1: "",
            dstAdd2: "",
            dstLocation: "",
            dstContact1: "",
            dstCity: "",
            dstState: "",
            dstPincode: "",
            dstEmailId: "",
            dstType: "",
            retailerId: "",
            retailerName: "",
            productId: "",
            productName: "",
            brandId: "",
            modelId: "",
            productId2: "",
            productCode2: "",
            productName2: "",
            brandId2: "",
            brandCode2: "",
            brandName2: "",
            modelId2: "",
            modelCode2: "",
            modelName2: ""
        },
        excelData: [],
        curTempExcelData: [],
        errors: {},
        serviceCenterData: "false",
        serviceCenters: []
    };


    schema = {
        teamId: Joi.optional().label("teamId"),
        parentTicket: Joi.optional().label("parentTicket"),
        flowId: Joi.string().required().label("flowId"),
        ticketPriority: Joi.string().required().label("ticketPriority"),
        conComplaintNo: Joi.string().required().label("conComplaintNo"),
        retailerCode: Joi.string().required().label("retailerCode"),
        retailerNo: Joi.optional().label("retailerNo"),
        invoiceNo: Joi.optional().label("invoiceNo"),
        orderNo: Joi.string().required().label("orderNo"),
        ticketDetails: Joi.optional().label("ticketDetails"),
        specialInstructions: Joi.optional().label("specialInstructions"),
        isAppointmentReq: Joi.optional().label("isAppointmentReq"),
        isInstallaationReq: Joi.optional().label("isInstallaationReq"),
        isPhysicalEval: Joi.string().required().label("isPhysicalEval"),
        isTechEval: Joi.string().required().label("isTechEval"),
        isPackingReq: Joi.optional().label("isPackingReq"),
        productCode: Joi.string().required().label("productCode"),
        modelCode: Joi.optional().label("modelCode"),
        modelName: Joi.string().required().label("modelName"),
        brandCode: Joi.optional().label("brandCode"),
        brandName: Joi.string().required().label("brandName"),
        identificationNo: Joi.string().required().label("identificationNo"),
        dateOfPurchase: Joi.string().optional().label("dateOfPurchase"),
        productDesc: Joi.optional().label("productDesc"),
        problemDesc: Joi.optional().label("problemDesc"),
        productValue: Joi.optional().label("productValue"),
        cost: Joi.optional().label("cost"),
        isUnderWarranty: Joi.optional().label("isUnderWarranty"),
        srcContactPerson: Joi.string().required().label("srcContactPerson"),
        srcAdd1: Joi.string().required().label("srcAdd1"),
        srcAdd2: Joi.optional().label("srcAdd2"),
        srcLocation: Joi.optional().label("srcLocation"),
        // srcContact1: Joi.string().required().label("srcContact1"),
        // srcContact2: Joi.optional().label("srcContact2"),
        srcContact1: Joi.string().min(10).max(11).regex(/^[0-9]+$/).required().label("Source Contact 1").error(() => {
            return {
                message: 'Allow only 10/11 digit numbers.',
            }
        }),
        srcContact2: Joi.string().min(10).max(11).regex(/^[0-9]+$/).optional().label("Source Contact 2").error(() => {
            return {
                message: 'Allow only 10/11 digit numbers.',
            }
        }),
        srcCity: Joi.string().required().label("srcCity"),
        srcState: Joi.optional().label("srcState"),
        // srcPincode: Joi.string().required().label("srcPincode"),

        srcPincode: Joi.string().regex(/^[0-9]{6}$/).required().label("srcPincode").error(() => {
            return {
                message: 'Allow only 6 digit pincode',
            }
        }),
        srcLandmark: Joi.optional().label("srcLandmark"),
        srcEmailId: Joi.optional().label("srcEmailId"),
        srcEmailIdAlt: Joi.optional().label("srcEmailIdAlt"),
        srcType: Joi.optional().label("srcType"),
        dstContactPerson: Joi.string().required().label("dstContactPerson"),
        dstAdd1: Joi.string().required().label("dstAdd1"),
        dstAdd2: Joi.optional().label("dstAdd2"),
        dstLocation: Joi.optional().label("dstLocation"),
        dstContact1: Joi.string().required().label("dstContact1"),
        dstContact2: Joi.optional().label("dstContact2"),

        // dstContact1: Joi.string().regex(/^[0-9]{10}$/).required().label("destination Contact 1").error(() => {
        //     return {
        //         message: 'Allow only 10 digit numbers.',
        //     }
        // }),

        // dstContact2: Joi.string().regex(/^[0-9]{10}$/).optional().label("destination Contact 2").error(() => {
        //     return {
        //         message: 'Allow only 10 digit numbers.',
        //     }
        // }),
        dstCity: Joi.string().required().label("dstCity"),
        dstState: Joi.optional().label("dstState"),
        // dstPincode: Joi.string().required().label("dstPincode"),

        dstPincode: Joi.string().regex(/^[0-9]{6}$/).required().label("dstPincode").error(() => {
            return {
                message: 'Allow only 6 digit pincode',
            }
        }),
        dstLandmark: Joi.optional().label("dstLandmark"),
        dstEmailId: Joi.optional().label("dstEmailId"),
        dstEmailIdAlt: Joi.optional().label("dstEmailIdAlt"),
        dstType: Joi.optional().label("dstType"),
        retailerId: Joi.optional().label("retailerId"),
        retailerName: Joi.optional().label("retailerName"),
        productId: Joi.optional().label("productId"),
        productName: Joi.optional().label("productName"),
        brandId: Joi.optional().label("brandId"),
        modelId: Joi.optional().label("modelId"),
        productId2: Joi.optional().label("productId2"),
        productCode2: Joi.optional().label("productCode2"),
        productName2: Joi.optional().label("productName2"),
        brandId2: Joi.optional().label("brandId2"),
        brandCode2: Joi.optional().label("brandCode2"),
        brandName2: Joi.optional().label("brandName2"),
        modelId2: Joi.optional().label("modelId2"),
        modelCode2: Joi.optional().label("modelCode2"),
        modelName2: Joi.optional().label("modelName2"),
        ticketQc: Joi.optional().label("ticketQc"),
        QC__brand: Joi.optional().label("QC__brand"),
        QC__color: Joi.optional().label("QC__color"),
        QC__description: Joi.optional().label("QC__description"),
        QC__images: Joi.optional().label("QC__images"),
        QC__price_tag: Joi.optional().label("QC__price_tag"),
        QC__product_not_damaged: Joi.optional().label("QC__product_not_damaged"),
        QC__product_not_used: Joi.optional().label("QC__product_not_used"),
        QC__size: Joi.optional().label("QC__size"),

    };

    componentDidMount() {
        this.resetData();
        this.setCurData(this.props.curData);
        this.loadFlows();
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.state.userCode = userInfo.code;
        this.state.userInfoTeamId = userInfo.teamId;
        this.loadServiceCenters(this.state.userInfoTeamId);

    }

    setCurData(p) {
        if (p.conComplaintNo !== "") {
            this.setState({ data: p });
        } else {
            this.resetData();
        }
    }



    async loadFlows() {
        try {
            const res = await getFlows();
            if (res !== undefined) {
                if (res.data.success === true) {
                    this.setState({ flows: res.data.data.flows });
                } else {
                    toast.toastError(res.data.msg);
                }
            }
            this.setState({ loading: false });
        }
        catch {
            console.log("Error in TicketUploadNewModal>>>>>>loadFlows");
        }
    }
    async loadServiceCenters(userInfoTeamId) {
        try {
            const res = await getServiceCenters(userInfoTeamId);
            if (res !== undefined) {
                if (res.data.success === true && res.data.data.serviceCenters !== 0) {
                    this.setState({ serviceCenters: res.data.data.serviceCenters });
                } else {
                    toast.toastError(res.data.msg);
                }
            }
            this.setState({ loading: false });
        }
        catch {
            console.log("Error in TicketUploadNewModal>>>>>>loadServiceCenters");
        }
    }


    ticketPriority = [
        { priorityId: "high", ticketPriority: "high" },
        { priorityId: "medium", ticketPriority: "medium" },
        { priorityId: "low", ticketPriority: "low" },
    ];

    isAppointmentReq = [
        { appointmentId: "yes", isAppointmentReq: "yes" },
        { appointmentId: "no", isAppointmentReq: "no" },
    ]
    isInstallaationReq = [
        { installationId: "yes", isInstallaationReq: "yes" },
        { installationId: "no", isInstallaationReq: "no" },
    ]
    isPhysicalEval = [
        { physicalEvalId: "yes", isPhysicalEval: "yes" },
        { physicalEvalId: "no", isPhysicalEval: "no" },
    ]
    isTechEval = [
        { techEvalId: "yes", isTechEval: "yes" },
        { techEvalId: "no", isTechEval: "no" },
    ]
    isPackingReq = [
        { packingReqId: "yes", isPackingReq: "yes" },
        { packingReqId: "no", isPackingReq: "no" },
    ]
    isUnderWarranty = [
        { underWarrantyId: "yes", isUnderWarranty: "yes" },
        { underWarrantyId: "no", isUnderWarranty: "no" },
    ]

    localHandleChange = (e) => {
        try {
            if (e.target.name === "teamId") {
                const { value } = e.target;
                let data = this.state.data;
                let retailer = this.state;

                // find the serviceCenters with the current selected value
                const serviceCenters = retailer.serviceCenters.find((c) => c.name === value);
                if (serviceCenters !== undefined) {
                    data["dstContactPerson"] = serviceCenters.name;
                    data["dstAdd1"] = serviceCenters.address1;
                    data["dstAdd2"] = serviceCenters.address2;
                    data["dstLocation"] = serviceCenters.city;
                    data["dstContact1"] = serviceCenters.contact1;
                    data["dstCity"] = serviceCenters.city;
                    data["dstPincode"] = serviceCenters.pincode;
                    data["dstEmailId"] = serviceCenters.email;

                    this.setState({
                        data,
                    });
                    this.setState({
                        serviceCenterData: "true"
                    });

                }
                this.validateProperty(e.target.name, e.target.value);
            }
        }
        catch {
            console.log("Error in TicketUploadNewModal>>>>>>localHandleChange");
        }
    };
    async doSubmit() {
        try {
            let data = [];
            data.push(this.state.data);
            this.setState({
                excelData: [],
            });
            let tempExcelData = [];

            for (let r in data) {
                let retailerCode = data[r]["retailerCode"];
                let productCode = data[r]["productCode"];
                let productName = data[r]["productName"];

                let brandCode = data[r]["brandCode"];
                let modelCode = data[r]["modelCode"];
                let modelName = data[r]["modelName"];
                let brandName = data[r]["brandName"];

                let retailerInfo = JSON.parse(localStorage.getItem("retailersInfo"));
                let productInfo = JSON.parse(localStorage.getItem("productsInfo"));
                let brandInfo = JSON.parse(localStorage.getItem("brandsInfo"));
                let modelInfo = JSON.parse(localStorage.getItem("modelsInfo"));

                ////logic IN service
                let curRetailer = await getRetailersInfo(retailerInfo, retailerCode);
                let curProduct = await getProductsInfo(productInfo, productCode);
                let curBrand = await getBrandsInfo(brandInfo, brandCode);
                let curModel = await getModelsInfo(modelInfo, modelCode);

                // console.log("R value====>" + curProduct);

                if (
                    curRetailer !== "" &&
                    curProduct !== "" &&
                    curBrand !== "" &&
                    curModel !== ""
                ) {
                    if (Array.isArray(curRetailer) && curRetailer.length > 0) {
                        data[r]["retailerId"] = curRetailer[0].teamId;
                        data[r]["retailerName"] = curRetailer[0].name;
                    } else {
                        data[r]["retailerId"] = "";
                        data[r]["retailerCode"] = "";
                        data[r]["retailerName"] = "";
                    }

                    if (Array.isArray(curProduct) && curProduct.length > 0) {
                        data[r]["productId"] = curProduct[0].productId;
                        data[r]["productName"] = curProduct[0].product;
                    } else {
                        data[r]["productId"] = "";
                        data[r]["productCode"] = productCode;
                        data[r]["productName"] = productName;
                    }

                    if (Array.isArray(curBrand) && curBrand.length > 0) {
                        data[r]["brandId"] = curBrand[0].prodBrandId;
                        data[r]["brandName"] = brandName;
                    } else {
                        data[r]["brandId"] = brandCode;
                        data[r]["brandCode"] = brandCode;
                        data[r]["brandName"] = brandName;
                    }

                    if (Array.isArray(curModel) && curModel.length > 0) {
                        data[r]["modelId"] = curModel[0].prodModelId;
                        data[r]["modelName"] = modelName;
                    } else {
                        data[r]["modelId"] = modelCode;
                        data[r]["modelCode"] = modelCode;
                        data[r]["modelName"] = modelName;
                    }

                    let productCode2 = data[r]["productCode2"];
                    let brandCode2 = data[r]["brandCode2"];
                    let modelCode2 = data[r]["modelCode2"];

                    let curProduct2 = await getProductsInfo(productInfo, productCode2);
                    let curBrand2 = await getBrandsInfo(brandInfo, brandCode2);
                    let curModel2 = await getModelsInfo(modelInfo, modelCode2);


                    if (Array.isArray(curProduct2) && curProduct2.length > 0) {
                        data[r]["productId2"] = curProduct2[0].productId;
                        data[r]["productName2"] = curProduct2[0].product;
                    } else {
                        data[r]["productId2"] = "";
                        data[r]["productCode2"] = "";
                        data[r]["productName2"] = "";
                    }

                    if (Array.isArray(curBrand2) && curBrand2.length > 0) {
                        data[r]["brandId2"] = curBrand2[0].prodBrandId;
                        data[r]["brandName2"] = curBrand2[0].brand;
                        data[r]["brandCode"] = brandCode;
                        data[r]["brandName"] = brandName;
                    } else {
                        data[r]["brandId2"] = "";
                        data[r]["brandCode2"] = "";
                        data[r]["brandName2"] = "";
                        data[r]["brandCode"] = brandCode;
                        data[r]["brandName"] = brandName;
                    }

                    if (Array.isArray(curModel2) && curModel2.length > 0) {
                        data[r]["modelId2"] = curModel2[0].prodModelId;
                        data[r]["modelName2"] = curModel2[0].model;
                        data[r]["modelCode"] = modelCode;
                        data[r]["modelName"] = modelName;
                    } else {
                        data[r]["modelId2"] = "";
                        data[r]["modelCode2"] = "";
                        data[r]["modelName2"] = "";
                        data[r]["modelCode"] = modelCode;
                        data[r]["modelName"] = modelName;
                    }

                }
                tempExcelData.push(data[r]);
            }
            this.setState({
                excelData: tempExcelData,
            });
            if (tempExcelData.length > 0 && this.props.curData.conComplaintNo === "") {
                toast.toastSuccess("Fetched Data For Ticket Creation ");
                this.resetData();

            }
            else if (tempExcelData.length > 0 && this.props.curData.conComplaintNo !== "") {
                toast.toastSuccess("Fetched Data For Ticket Updation ");
            }
            this.sendStateDatatoparent(tempExcelData);

            this.setState({
                serviceCenterData: "false",
            });

        }
        catch {
            console.log("Error in TicketUploadNewModal>>>>>>doSubmit");
        }

    }


    resetData() {
        this.setState({
            data: {
                teamId: "",
                ticketPriority: "",
                conComplaintNo: "",
                flowId: "PickAndDropOneWay",
                retailerCode: "REL",
                productCode: "",
                modelCode: "",
                modelName: "",
                brandCode: "",
                brandName: "",
                isAppointmentReq: "",
                isInstallaationReq: "",
                isPhysicalEval: "",
                isTechEval: "",
                isPackingReq: "",
                retailerNo: "",
                invoiceNo: "",
                orderNo: "",
                ticketDetails: "",
                specialInstructions: "",
                dateOfPurchase: "",
                identificationNo: "",
                productDesc: "",
                problemDesc: "",
                productValue: "",
                cost: "",
                isUnderWarranty: "",
                srcContactPerson: "",
                srcAdd1: "",
                srcAdd2: "",
                srcLocation: "",
                srcContact1: "",
                srcEmailId: "",
                srcCity: "",
                srcState: "",
                srcPincode: "",
                srcType: "",
                dstContactPerson: "",
                dstAdd1: "",
                dstAdd2: "",
                dstLocation: "",
                dstContact1: "",
                dstCity: "",
                dstState: "",
                dstPincode: "",
                dstEmailId: "",
                dstType: "",
                retailerId: "",
                retailerName: "",
                productId: "",
                productName: "",
                brandId: "",
                modelId: "",
                productId2: "",
                productCode2: "",
                productName2: "",
                brandId2: "",
                brandCode2: "",
                brandName2: "",
                modelId2: "",
                modelCode2: "",
                modelName2: ""
            },
        });

        toast.toastWarn("Ticket Form Refreshed ");
    }

    sendStateDatatoparent = (data) => {
        this.props.onHandleGetFormData(data);
    }
    render() {
        // const tasks = this.props.tasks;
        // const curUserstoryId = this.props.curUserstoryId;
        return (

            <div className="container-fluid  ">
                <div className="modal fade " id="myTicketModal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content ScrollStyleModal">
                            <div className="modal-header" style={{ backgroundColor: "#e7d9d9", borderBottom: "1px solid #a7a8a9", borderTop: "1px solid #a7a8a9", borderLeft: "1px solid rgb(167, 168, 169)", borderRight: "1px solid rgb(167, 168, 169)" }}>
                                <h4 className="modal-title">Ticket Creation</h4>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body " style={{ backgroundColor: "white", borderBottom: "1px solid #a7a8a9", borderTop: "1px solid #a7a8a9", borderLeft: "1px solid rgb(167, 168, 169)", borderRight: "1px solid rgb(167, 168, 169)" }}>
                                <form>
                                    <div className="form-row" style={{ marginTop: "12px" }}>
                                        {/* {this.rSB(
                                            "Flow *",
                                            "flowId",
                                            this.state.flows,
                                            { value: "flowId", label: "flowName" },
                                            " col-3 "
                                        )} */}
                                        {this.rTBD("Flow. *", "flowId", " col-3")}
                                        {this.rRBL(
                                            "Ticket Priority *",
                                            "ticketPriority",
                                            this.ticketPriority,
                                            {
                                                value: "priorityId",
                                                label: "ticketPriority",
                                            },
                                            "col-md-4"
                                        )}


                                    </div>
                                    <div className="form-subHeader" style={{ color: "#0c0c0c", backgroundColor: "rgb(204 213 255)" }}>Primary Details</div>
                                    <div className='form-row'>
                                        {this.rTB("Consumer Complaint No. *", "conComplaintNo", " col-3")}
                                        {this.rTB("Reatiler Code *", "retailerCode", " col-3")}
                                        {this.rTB("Reatiler Number ", "retailerNo", " col-3")}
                                        {this.rTB("Invoice Number ", "invoiceNo", " col-3")}
                                        {this.rTB("Order Number *", "orderNo", " col-3")}
                                        {this.rTB("Ticket Details ", "ticketDetails", " col-3")}
                                        {this.rTB("Special Instructions ", "specialInstructions", " col-3")}

                                        {this.rRBL(
                                            "Appointment Req.",
                                            "isAppointmentReq",
                                            this.isAppointmentReq,
                                            {
                                                value: "appointmentId",
                                                label: "isAppointmentReq",
                                            },
                                            "col-md-3"
                                        )}
                                        {this.rRBL(
                                            "Installation Req.",
                                            "isInstallaationReq",
                                            this.isInstallaationReq,
                                            {
                                                value: "installationId",
                                                label: "isInstallaationReq",
                                            },
                                            "col-md-3"
                                        )}
                                        {this.rRBL(
                                            "PhysicalEval Req.*",
                                            "isPhysicalEval",
                                            this.isPhysicalEval,
                                            {
                                                value: "physicalEvalId",
                                                label: "isPhysicalEval",
                                            },
                                            "col-md-3"
                                        )}
                                        {this.rRBL(
                                            "TechEval Req.*",
                                            "isTechEval",
                                            this.isTechEval,
                                            {
                                                value: "techEvalId",
                                                label: "isTechEval",
                                            },
                                            "col-md-3"
                                        )}
                                        {this.rRBL(
                                            "Packing Req.",
                                            "isPackingReq",
                                            this.isPackingReq,
                                            {
                                                value: "packingReqId",
                                                label: "isPackingReq",
                                            },
                                            "col-md-3"
                                        )}

                                    </div>

                                    <div className="form-subHeader" style={{ color: "#131314", backgroundColor: "rgb(254 226 211)" }}>Product Details</div>
                                    <div className='form-row'>
                                        {this.rTB("Product Code *", "productCode", " col-3")}
                                        {this.rTB("Model Code ", "modelCode", " col-3")}
                                        {this.rTB("Model Name *", "modelName", " col-3")}
                                        {this.rTB("Brand Code ", "brandCode", " col-3")}
                                        {this.rTB("Brand Name *", "brandName", " col-3")}
                                        {this.rTB("Identification Number *", "identificationNo", " col-3")}
                                        {this.rDP("Date of purchase *", "dateOfPurchase", "col-md-3")}
                                        {this.rTB("Product Description ", "productDesc", " col-3")}
                                        {this.rTB("Problem Description ", "problemDesc", " col-3")}
                                        {this.rTB("Product Value ", "productValue", " col-3")}
                                        {this.rTB("Cost ", "cost", " col-3")}
                                        {this.rRBL(
                                            "isUnderWarranty.",
                                            "isUnderWarranty",
                                            this.isUnderWarranty,
                                            {
                                                value: "underWarrantyId",
                                                label: "isUnderWarranty",
                                            },
                                            "col-md-3"
                                        )}

                                    </div>

                                    <div className="form-subHeader" style={{ color: "#050505", backgroundColor: "#ddc4e3" }}>Source Details</div>
                                    <div className='form-row'>
                                        {this.rTB("Contact person *", "srcContactPerson", " col-3")}
                                        {this.rTB("Address1 *", "srcAdd1", " col-3")}
                                        {this.rTB("Address2 ", "srcAdd2", " col-3")}
                                        {this.rTB("Location ", "srcLocation", " col-3")}
                                        {this.rTB("Contact1 *", "srcContact1", " col-3")}
                                        {this.rTB("Contact2 ", "srcContact2", " col-3")}
                                        {this.rTB("City *", "srcCity", " col-3")}
                                        {this.rTB("State ", "srcState", " col-3")}
                                        {this.rTB("Pincode*", "srcPincode", " col-3")}
                                        {this.rTB("Landmark", "srcLandmark", " col-3")}
                                        {this.rTB("EmailId ", "srcEmailId", " col-3")}
                                        {this.rTB("EmailId Alternative ", "srcEmailIdAlt", " col-3")}
                                        {this.rTB("Source Type ", "srcType", " col-3")}

                                    </div>


                                    <div className="form-subHeader" style={{ color: "#080808", backgroundColor: "#ffd3d3" }}>Destination Details</div>
                                    <div className='form-row'>

                                        {this.rSBF(
                                            "Service Centers",
                                            "teamId",
                                            this.state.serviceCenters,
                                            { value: "teamId", label: "name" },
                                            " col-md-3 "
                                        )}
                                    </div>

                                    <div className='form-row'>
                                        {this.rTB("Contact person *", "dstContactPerson", " col-3")}
                                        {this.rTB("Address1 *", "dstAdd1", " col-3")}
                                        {this.rTB("Address2 ", "dstAdd2", " col-3")}
                                        {this.rTB("Location ", "dstLocation", " col-3")}
                                        {this.rTB("Contact1 *", "dstContact1", " col-3")}
                                        {this.rTB("Contact2 ", "dstContact2", " col-3")}
                                        {this.rTB("City *", "dstCity", " col-3")}
                                        {this.rTB("State ", "dstState", " col-3")}
                                        {this.rTB("Pincode*", "dstPincode", " col-3")}
                                        {this.rTB("Landmark", "dstLandmark", " col-3")}
                                        {this.rTB("EmailId ", "dstEmailId", " col-3")}
                                        {this.rTB("EmailId Alternative ", "dstEmailIdAlt", " col-3")}
                                        {this.rTB("Destination Type ", "dstType", " col-3")}

                                    </div>
                                </form>
                            </div>

                            <div className="modal-footer">
                                {/* {this.rBtnSP("Create", "create")} */}
                                {this.props.curData.conComplaintNo === "" &&
                                    this.rBtnSP("Create", "create")}
                                {this.props.curData.conComplaintNo !== "" && (
                                    <>
                                        {this.rBtnSS("Update", "update")}
                                    </>
                                )}
                                {/* {this.props.curData.conComplaintNo !== "" && ( */}
                                <button
                                    className="btn  btn-info  btn-sm pull-right"
                                    onClick={() => this.resetData()}
                                >
                                    Reset Form
                                </button>
                                {/* )} */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

export default TicketUploadNewModal;
