import React from "react";
import FormLib from "./../../../_common/FormLib";
import Joi from "joi-browser";
import toast from "./../../../../services/_toastService";
import $ from "jquery";
// import "bootstrap";
import {
  createDesignations,
  saveDesignations,
  deleteDesignations,
} from "./../../../../services/players/designationsServices";

class ModalDesignation extends FormLib {
  state = {
    data: {
      designationId: "",
      departmentId: "",
      designation: "",
      status: "active",
    },
    errors: {},

    departments: [],
    curDepartment: {},
  };

  schema = {
    designationId: Joi.optional().label("DesignationId"),
    departmentId: Joi.string().required().label("DepartmentId*"),
    designation: Joi.string().required().label("Designation*"),
    status: Joi.string().required().label("Status*"),
  };

  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createDesignations(this.state.data);
      this.resetData(this.state.curDepartment);
      this.props.onLoadDesignations(this.state.curDepartment.departmentId);
    }
    if (this.state.btnValue === "edit") {
      res = await saveDesignations(this.state.data);
      this.props.onLoadDesignations(
        this.state.curDepartment.departmentId,
        false
      );
    }
    if (this.state.btnValue === "delete") {
      res = await deleteDesignations(this.state.data);
      $("#curDesignationButton").click();
      this.resetData(this.state.curDepartment);
      this.props.onLoadDesignations(this.state.curDepartment.departmentId);
    }

    toast.toastMsg(res);
    // if (res.data && res.data.success && res.data.success === true) {
    //   toast.toastSuccess(res.data.msg);
    // } else {
    //   toast.toastError(
    //     (res.data && res.data.msg) || "Error Happened in getting Error Msg"
    //   );
    // }
  }

  componentDidMount() {
    this.setDepartments(this.props.departments);
    this.setCurDepartment(this.props.curDepartment);
    this.setCurDesignation(this.props.curDepartment, this.props.curDesignation);
  }
  setDepartments(departments) {
    this.setState({ departments: departments });
  }
  setCurDepartment(d) {
    this.setState({ curDepartment: d });
  }
  setCurDesignation(d, m) {
    const mod = { ...m };
    delete mod.seq;
    if (m.designationId && m.designationId !== "") {
      this.setState({ data: mod });
    } else {
      this.resetData(d);
    }
  }

  resetData(d) {
    this.setState({
      data: {
        departmentId: d.departmentId || "",
        designationId: "",
        designation: "",
        status: "active",
      },
    });
  }

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;

    const departments = this.state.departments;

    return (
      <div className="container-fluid">
        <div className="modal fade" id="myDesignationModel">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Designations</h4>
                <button
                  type="button"
                  className="close"
                  id="curDesignationButton"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  {this.rTBH("DesignationId", "designationId")}
                  {this.rSB("DepartmentId", "departmentId", departments, {
                    value: "departmentId",
                    label: "department",
                  })}
                  {this.rTB("Designation", "designation")}
                  <br />
                  <div className="form-row">
                    {this.rRBL(
                      "Status",
                      "status",
                      this.status,
                      this.stsConf,
                      "col-12"
                    )}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {/* {"==>"+this.state.data.menuId + "<=="} */}
                {this.state.data.designationId === "" &&
                  this.rBtnSP("Create", "create")}
                {this.state.data.designationId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {this.rBtnSD("Delete", "delete")}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalDesignation;
