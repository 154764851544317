// import React, { Component } from "react";
// import { getHubsAll } from "../../../services/masterLocations/hubServices";
// import FormLib from "./../../_common/FormLib";
// import { toast } from "react-toastify";
// import HubAssignment from "./HubAssignment";
// import Joi from "joi-browser";
// import { updateSrcHub } from "../../../services/Tickets/TicketProcessServices";
// import { setTicketActionsTicketCancel } from "./../../../services/Tickets/TicketProcessServices";
// import swal from "sweetalert";
// import { IstToUTC } from "../../../utils/DateUtils";
// import { connect } from "react-redux";
// import { ticketListReload } from "./../../../store";

// class HoProcess extends FormLib {
//   state = {
//     data: { hubId: "" },
//     errors: {},
//     curticketDetails: {},
//     curblockDetails: {},
//     curProductDetails: {},
//     hubs: [],
//     cancelStatus: "false",
//     hideForm: "false",
//   };
//   includeCancelTicket = [
//     { sortKey: "yes", sort: "Do you want to cancel Ticket??" },
//   ];
//   schema = {
//     hubId: Joi.string().required().label("Hub"),
//     includeCancelTicket: Joi.string().optional().label("includeCancelTicket"),
//   };

//   componentDidMount() {
//     this.loadHubs();
//     this.loadInit();
//     this.getlocalDateTime();
//   }

//   async getlocalDateTime() {
//     fetch("https://worldtimeapi.org/api/ip")
//       .then((response) => response.json())
//       .then((data) => {
//         this.setState({ localDateTime: data });
//       });
//   }

//   async loadHubs() {
//     try {
//       const res = await getHubsAll();
//       if (res !== undefined) {
//         if (res.data.success === true) {
//           this.setState({ hubs: res.data.data.hubs });
//         } else {
//           toast.error(res.data.msg);
//         }
//       }
//     } catch {
//       console.log("Error in HoProcess>>>>>>loadHubs");
//     }
//   }

//   loadInit() {
//     try {
//       const curticketDetails = this.props.curticketDetails;
//       const curblockDetails = this.props.curblockDetails;
//       const curProductDetails = this.props.curProductDetails[0];
//       const hideForm = this.props.hideForm;
//       this.setState({ curticketDetails });
//       this.setState({ curblockDetails });
//       this.setState({ curProductDetails });
//       this.setState({ hideForm });
//       let userInfo = JSON.parse(localStorage.getItem("userInfo"));
//       this.setState({ designation: userInfo.designation });
//     } catch {
//       console.log("Error in AptScheduler>>>>>>HOPROCESS");
//     }
//   }

//   async doSubmit() {
//     try {
//       const curHub = this.state.hubs.filter((h) => {
//         return h.hubId === this.state.data.hubId ? h.hub : "";
//       });

//       let params = {
//         ticketBlockId: this.state.curblockDetails.ticketBlockId,
//         hubId: this.state.data.hubId,
//         hubName: curHub[0].hub,
//         oldHubId: this.state.curblockDetails.srcHubId,
//         oldHubName: this.state.curblockDetails.srcHub,
//         processId: this.state.curblockDetails.processId,
//       };
//       // console.log("SOURCEEEEEEEEEEE---------> " + JSON.stringify(params));

//       let resp = await updateSrcHub(params);

//       if (resp.data.success === true) {
//         resp.data && toast.success(() => <div>{resp.data.msg}</div>);
//         !resp.data && toast.warning(() => <div>{resp.data.msg}</div>);
//       } else {
//         toast.error(() => <div>{resp.data.msg}</div>);
//       }
//     } catch {
//       console.log("Error in HoProcess>>>>>>doSubmit");
//     }
//   }
//   handleCancelTicket = async () => {
//     swal({
//       title: "Are you sure want to Cancel the Ticket?",
//       text: "Kindly Enter the Reason for Ticket Cancellation",
//       icon: "warning",
//       buttons: true,
//       dangerMode: true,
//       closeOnClickOutside: false,
//       closeOnEsc: false,
//       content: "input",
//       placeholder: "Write something",
//     }).then(async (handleCancelTicket) => {
//       this.doCancelTicket(handleCancelTicket);
//     });
//   };
//   doCancelTicket = async (handleCancelTicket) => {
//     if (handleCancelTicket) {
//       // alert(handleRTO)
//       swal(
//         "Ticket has been successfully Cancelled with the following remarks: " +
//           handleCancelTicket,
//         {
//           icon: "success",
//         }
//       );
//       this.submitCancelTicket(handleCancelTicket);
//     } else {
//       swal("Ticket not cancelled!", { icon: "error" });
//     }
//   };

//   submitCancelTicket = async (handleCancelTicket) => {
//     let resultSet = {
//       remarks: handleCancelTicket,
//       actionStatus: "OPS_CANCALLATION",
//       processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
//     };
//     let data = this.state.data;
//     data = {
//       actionCode: "wOPSCancelled",
//       action: "Ticket Cancelled",
//     };
//     this.setState({ data });

//     let resp = await setTicketActionsTicketCancel(
//       this.state.data,
//       this.state.curticketDetails,
//       this.state.curblockDetails,
//       resultSet
//     );
//     if (resp !== undefined) {
//       if (resp.data.success === true) {
//         if (resp.data.data) {
//           this.handleTicketListReload();
//           this.setState({ cancelStatus: "true" });
//           // this.setState({ rtoStatus: "false" });
//         } else {
//           toast.warning(() => <div>{resp.data.msg}</div>);
//         }
//       } else {
//         toast.error(() => <div>{resp.data.msg}</div>);
//       }
//     }
//   };
//   handleTicketListReload = () => {
//     this.props.ticketListReload(
//       this.props.ticketListReloadState.ticketProcess.ticketListReload
//     );
//   };

//   render() {
//     const ticketDetails = this.state.curticketDetails;

//     return (
//       <>
//         {this.state.cancelStatus === "false" && (
//           <div id="coAction" className="col-md-12">
//             <div className="form-subHeader">
//               <b>{ticketDetails.ticketNo}</b>
//               {this.state.designation === "Operations Head" && (
//                 <div
//                   className="pull-right"
//                   style={{ marginTop: "-1%", marginRight: "23%" }}
//                 >
//                   {this.rCBHL("includeCancelTicket", this.includeCancelTicket, {
//                     value: "sortKey",
//                     label: "sort",
//                   })}
//                 </div>
//               )}
//               {this.state.data.includeCancelTicket === "yes" && (
//                 <div
//                   className="pull-right"
//                   style={{ marginTop: "1%", marginRight: "-40%" }}
//                 >
//                   <button
//                     title="Cancel Ticket"
//                     className="badge badge-danger  pull-right"
//                     type="button"
//                     onClick={() => this.handleCancelTicket()}
//                     style={{
//                       fontSize: "82%",
//                       borderRadius: "2.25rem ",
//                       border: "none",
//                       fontWeight: "bold",
//                       marginRight: "6px",
//                     }}
//                   >
//                     CANCEL TICKET
//                   </button>
//                 </div>
//               )}
//             </div>
//             <div className="card ">
//               <div className="card-body  bg-light">
//                 <div className="form-row">
//                   {this.state.curblockDetails.flowStatus === "PICK_WEB" && (
//                     <div className="col-md-6">
//                       <span
//                         className="badge badge-success block pull-right"
//                         style={{ fontSize: "90%" }}
//                       >
//                         <b>
//                           {this.state.curblockDetails.srcHub} - (
//                           {this.state.curProductDetails.srcPincode})
//                         </b>
//                       </span>
//                       <br />
//                       <form onSubmit={this.handleSubmit}>
//                         {this.rSB(
//                           "Src Hub",
//                           "hubId",
//                           this.state.hubs,
//                           { value: "hubId", label: "hub" },
//                           ""
//                         )}
//                         {this.rBtnLP(
//                           "Save",
//                           "Submit",
//                           "btn btn-primary btn-sm pull-right"
//                         )}
//                       </form>
//                     </div>
//                   )}
//                   {(this.state.curblockDetails.flowStatus === "PICK_WEB" ||
//                     this.state.curblockDetails.flowStatus === "DROP_WEB") && (
//                     <div className="col-md-6">
//                       <HubAssignment
//                         curblockDetails={this.state.curblockDetails}
//                         curProductDetails={this.state.curProductDetails}
//                       />
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>

//             {/* <div className="card ">
//                         <div className="card-body  bg-light">
//                             <div className="form-row">
//                                 <div className="col-md-6">
//                                     <HubAssignment
//                                         curblockDetails={this.state.curblockDetails}
//                                         curProductDetails={this.state.curProductDetails} />
//                                 </div>

//                             </div>
//                         </div>
//                     </div> */}
//             {this.state.hideForm === "true" &&
//               this.state.cancelStatus === "false" && (
//                 <button
//                   className="btn  btn-primary  btn-sm pull-right"
//                   onClick={() => this.handleTicketListReload()}
//                 >
//                   Move to Coordinator
//                 </button>
//               )}
//           </div>
//         )}
//       </>
//     );
//   }
// }
// const mapStateToProps = (state) => {
//   return {
//     ticketListReloadState: state.ticketProcess,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
//   };
// };
// export default connect(mapStateToProps, mapDispatchToProps)(HoProcess);


/*
import React, { Component } from "react";
import { getHubsAll } from "../../../services/masterLocations/hubServices";
import FormLib from "./../../_common/FormLib";
import { toast } from "react-toastify";
import HubAssignment from "./HubAssignment";
import Joi from "joi-browser";
import { updateSrcHub } from "../../../services/Tickets/TicketProcessServices";
import { setTicketActionsTicketCancel } from "./../../../services/Tickets/TicketProcessServices";
import swal from "sweetalert";
import { IstToUTC } from "../../../utils/DateUtils";
import { connect } from "react-redux";
import { ticketListReload } from "./../../../store";

*/


import React, { Component } from "react";
import { getHubsAll } from "../../../services/masterLocations/hubServices";
import FormLib from "./../../_common/FormLib";
import { toast } from "react-toastify";
import HubAssignment from "./HubAssignment";
import Joi from "joi-browser";
import { updateSrcHub } from "../../../services/Tickets/TicketProcessServices";
import { setTicketActionsTicketCancel } from "./../../../services/Tickets/TicketProcessServices";
import swal from "sweetalert";
import { IstToUTC } from "../../../utils/DateUtils";
import { connect } from "react-redux";
import { ticketListReload } from "./../../../store";
import { getBoxDetails, missingBoxLost, missingBoxReceive } from "../../../services/linehaul/linehaulServices";

class HoProcess extends FormLib {
  state = {
    data: { hubId: "" },
    errors: {},
    curticketDetails: {},
    curblockDetails: {},
    curProductDetails: {},
    curBoxDetails:{},
    hubs: [],
    cancelStatus: "false",
    hideForm: "false",
  };
  includeCancelTicket = [
    { sortKey: "yes", sort: "Do you want to cancel Ticket??" },
  ];
  schema = {
    hubId: Joi.string().required().label("Hub"),
    includeCancelTicket: Joi.string().optional().label("includeCancelTicket"),
  };

  componentDidMount() {
    this.loadHubs();
    this.loadInit();
    this.getlocalDateTime();
  }

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }

  async loadHubs() {
    try {
      const res = await getHubsAll();
      if (res !== undefined) {
        if (res.data.success === true) {
          this.setState({ hubs: res.data.data.hubs });
        } else {
          toast.error(res.data.msg);
        }
      }
    } catch {
      console.log("Error in HoProcess>>>>>>loadHubs");
    }
  }

  loadInit() {
    try {
      const curticketDetails = this.props.curticketDetails;
      console.log("curTicketDetails",this.props.curticketDetails)
      const curblockDetails = this.props.curblockDetails;
      console.log("curBlockDetails",this.props.curblockDetails)
      const curProductDetails = this.props.curProductDetails[0];
      console.log("curProductDetails",this.props.curProductDetails[0])
      const hideForm = this.props.hideForm;
      this.setState({ curticketDetails });
      this.setState({ curblockDetails });
      this.setState({ curProductDetails });
      this.setState({ hideForm });
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.setState({ designation: userInfo.designation });
      this.loadBoxData()
    } catch {
      console.log("Error in AptScheduler>>>>>>HOPROCESS");
    }
  }

  loadBoxData = async () =>{
    try {
      let blockId = this.props.curblockDetails.ticketBlockId;

      const resp = await getBoxDetails(blockId);
      if(resp.data.success === true){
        this.setState({curBoxDetails:resp.data.data})
      }else{
        console.log("error")
      }
    } catch (error) {
      
    }
  }

  async doSubmit() {
    try {
      const curHub = this.state.hubs.filter((h) => {
        return h.hubId === this.state.data.hubId ? h.hub : "";
      });

      let params = {
        ticketBlockId: this.state.curblockDetails.ticketBlockId,
        hubId: this.state.data.hubId,
        hubName: curHub[0].hub,
        oldHubId: this.state.curblockDetails.srcHubId,
        oldHubName: this.state.curblockDetails.srcHub,
        processId: this.state.curblockDetails.processId,
      };
      // console.log("SOURCEEEEEEEEEEE---------> " + JSON.stringify(params));

      let resp = await updateSrcHub(params);

      if (resp.data.success === true) {
        resp.data && toast.success(() => <div>{resp.data.msg}</div>);
        !resp.data && toast.warning(() => <div>{resp.data.msg}</div>);
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in HoProcess>>>>>>doSubmit");
    }
  }
  handleCancelTicket = async () => {
    swal({
      title: "Are you sure want to Cancel the Ticket?",
      text: "Kindly Enter the Reason for Ticket Cancellation",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: "input",
      placeholder: "Write something",
    }).then(async (handleCancelTicket) => {
      this.doCancelTicket(handleCancelTicket);
    });
  };
  doCancelTicket = async (handleCancelTicket) => {
    if (handleCancelTicket) {
      // alert(handleRTO)
      swal(
        "Ticket has been successfully Cancelled with the following remarks: " +
          handleCancelTicket,
        {
          icon: "success",
        }
      );
      this.submitCancelTicket(handleCancelTicket);
    } else {
      swal("Ticket not cancelled!", { icon: "error" });
    }
  };

  submitCancelTicket = async (handleCancelTicket) => {
    let resultSet = {
      remarks: handleCancelTicket,
      actionStatus: "OPS_CANCALLATION",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    let data = this.state.data;
    data = {
      actionCode: "wOPSCancelled",
      action: "Ticket Cancelled",
    };
    this.setState({ data });

    let resp = await setTicketActionsTicketCancel(
      this.state.data,
      this.state.curticketDetails,
      this.state.curblockDetails,
      resultSet
    );
    if (resp !== undefined) {
      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ cancelStatus: "true" });
          // this.setState({ rtoStatus: "false" });
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
  };
  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };

  handleMissingBoxReceive = async () =>{
    try {
      let params = {
        boxId:this.state.curBoxDetails.boxId,
        boxBarcode:this.state.curBoxDetails.boxBarcode,
        boxStatus:"lineHaulBoxReceived",
        lineHaulId:this.state.curBoxDetails.ticketLinehaulId,
        missingConfirmation:false,
        srcHubId:this.state.curBoxDetails.srcHubId,
        dstHubId:this.state.curBoxDetails.dstHubId,
        direction:this.state.curblockDetails.direction,
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        dropDate:IstToUTC(new Date()).slice(0, 10),
        dropTime:IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        status:"05RECEIVED"
      }
      console.log("params",params)
      const resp = await missingBoxReceive(params)
      if(resp.data.statusCode === 428){
        swal({
          title: resp.data.msg,
          text: "Once deleted, you will not be able to recover",
          icon: "error",
          buttons: true,
          dangerMode: true,
        }).then(async (handleDelete) => {
          this.doSubmitDeleteV1(handleDelete);
        });
      }
      
    } catch (error) {
      
    }
  }

  doSubmitDeleteV1 = async (handleDelete) => {
    if (handleDelete) {
      let params = {
        boxId:this.state.curBoxDetails.boxId,
        boxBarcode:this.state.curBoxDetails.boxBarcode,
        boxStatus:"lineHaulBoxReceived",
        lineHaulId:this.state.curBoxDetails.ticketLinehaulId,
        missingConfirmation:true,
        srcHubId:this.state.curBoxDetails.srcHubId,
        dstHubId:this.state.curBoxDetails.dstHubId,
        direction:this.state.curblockDetails.direction,
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        dropDate:IstToUTC(new Date()).slice(0, 10),
        dropTime:IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        status:"05RECEIVED"
      }
      const resp = await missingBoxReceive(params)
      if(resp.data.success === true){
        this.handleTicketListReload();
        toast.success(()=>{<div>{resp.data.msg}</div>})
      }
    }
  }

  handleMissingBoxLost = async () =>{
  try {
    let params = {
      boxId:this.state.curBoxDetails.boxId,
      boxBarcode:this.state.curBoxDetails.boxBarcode,
      boxStatus:"lineHaulBoxMissing",
      lineHaulId:this.state.curBoxDetails.ticketLinehaulId,
      lostConfirmation:false,
      srcHubId:this.state.curBoxDetails.srcHubId,
      dstHubId:this.state.curBoxDetails.dstHubId,
      direction:this.state.curblockDetails.direction,
      processAt:IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
      dropDate:IstToUTC(new Date()).slice(0, 10),
      dropTime:IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
      status:"17LOST",
      kamRemarks:""
    }
    const resp = await missingBoxLost(params)
    if(resp.data.statusCode === 428){
      swal({
        title: resp.data.msg,
        text: "Kindly Enter the Reason for Box Lost",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: "input",
        placeholder: "Write something",
      }).then(async (handleCancelBox) => {
        this.doCancelBox(handleCancelBox);
      });
    }
  } catch (error) {
    
  }
  }

  doCancelBox = async (handleCancelBox) => {
    if (handleCancelBox) {
      // alert(handleRTO)
      swal(
        "Box has been lost with the following remarks: " +
        handleCancelBox,
        {
          icon: "success",
        }
      );
      this.submitLostBox(handleCancelBox);
    } else {
      swal("Ticket not cancelled!", { icon: "error" });
    }
  };

  submitLostBox = async (handleCancelBox) =>{
    let params = {
      boxId:this.state.curBoxDetails.boxId,
      boxBarcode:this.state.curBoxDetails.boxBarcode,
      boxStatus:"lineHaulBoxMissing",
      lineHaulId:this.state.curBoxDetails.ticketLinehaulId,
      lostConfirmation:true,
      srcHubId:this.state.curBoxDetails.srcHubId,
      dstHubId:this.state.curBoxDetails.dstHubId,
      direction:this.state.curblockDetails.direction,
      processAt:IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
      dropDate:IstToUTC(new Date()).slice(0, 10),
      dropTime:IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
      status:"17LOST",
      kamRemarks:handleCancelBox
    }

    const resp = await missingBoxLost(params)

    if(resp.data.success === true){
      this.handleTicketListReload();

    }
  }

  render() {
    const ticketDetails = this.state.curticketDetails;
    console.log("responce111111111111",this.state.curBoxDetails)


    return (
      <>
        {this.state.cancelStatus === "false" && (
          <div id="coAction" className="col-md-12">
            <div className="form-subHeader">
              <b>{ticketDetails.ticketNo}</b>
              {this.state.designation === "Operations Head" && (
                <div
                  className="pull-right"
                  style={{ marginTop: "-1%", marginRight: "23%" }}
                >
                  {this.rCBHL("includeCancelTicket", this.includeCancelTicket, {
                    value: "sortKey",
                    label: "sort",
                  })}
                </div>
              )}
              {this.state.data.includeCancelTicket === "yes" && (
                <div
                  className="pull-right"
                  style={{ marginTop: "1%", marginRight: "-40%" }}
                >
                  <button
                    title="Cancel Ticket"
                    className="badge badge-danger  pull-right"
                    type="button"
                    onClick={() => this.handleCancelTicket()}
                    style={{
                      fontSize: "82%",
                      borderRadius: "2.25rem ",
                      border: "none",
                      fontWeight: "bold",
                      marginRight: "6px",
                    }}
                  >
                    CANCEL TICKET
                  </button>
                </div>
              )}
            </div>
            <div>
            {((this.state.curblockDetails.flowStatus === "PICK_WEB" ||
                    this.state.curblockDetails.flowStatus === "DROP_WEB") && this.state.curblockDetails.actionStatus === "15MISSING") &&(
                      <div className="form-row ">
                         <button
                  className="btn  btn-primary  btn-sm"
                  onClick={() => this.handleMissingBoxReceive()}
                  style={{
                    marginLeft:200,
                    fontSize:15
                  }}
                >
                 Missing Box Receive
                </button>
                <button
                  className="btn btn btn-danger  btn-sm"
                  onClick={() => this.handleMissingBoxLost()}
                  style={{
                    marginLeft:200,
                    fontSize:15
                  }}
                >
                 Missing Box Lost
                </button>
                      </div>
                    )}
            </div>
            <div className="card" style={{marginTop:20}}>
              <div className="card-body  bg-light">
                <div className="form-row">
                  {this.state.curblockDetails.flowStatus === "PICK_WEB" && (
                   
                    <div className="col-md-6">
                      <span
                        className="badge badge-success block pull-right"
                        style={{ fontSize: "90%" }}
                      >
                        <b>
                          {this.state.curblockDetails.srcHub} - (
                          {this.state.curProductDetails.srcPincode})
                        </b>
                      </span>
                      <br />
                      <form onSubmit={this.handleSubmit}>
                        {this.rSB(
                          "Src Hub",
                          "hubId",
                          this.state.hubs,
                          { value: "hubId", label: "hub" },
                          ""
                        )}
                        {this.rBtnLP(
                          "Save",
                          "Submit",
                          "btn btn-primary btn-sm pull-right"
                        )}
                      </form>
                    </div>
                  )}
                 
                  {((this.state.curblockDetails.flowStatus === "PICK_WEB" ||
                    this.state.curblockDetails.flowStatus === "DROP_WEB")&& this.state.curblockDetails.actionStatus !== "15MISSING") && (
                      
                       
                    <div className="col-md-6">
                      <HubAssignment
                        curblockDetails={this.state.curblockDetails}
                        curProductDetails={this.state.curProductDetails}
                      />
                    </div>
                   
                  )}
                </div>
              </div>
            </div>
            

            {/* <div className="card ">
                        <div className="card-body  bg-light">
                            <div className="form-row">
                                <div className="col-md-6">
                                    <HubAssignment
                                        curblockDetails={this.state.curblockDetails}
                                        curProductDetails={this.state.curProductDetails} />
                                </div>

                            </div>
                        </div>
                    </div> */}
            {this.state.hideForm === "true" &&
              this.state.cancelStatus === "false" && this.state.curblockDetails.actionStatus !== "15MISSING" &&  (
                <button
                  className="btn  btn-primary  btn-sm pull-right"
                  onClick={() => this.handleTicketListReload()}
                >
                  Move to Coordinator
                </button>
              )}

          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HoProcess);






































