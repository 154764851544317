import React from "react";
import FormLib from "./../../../_common/FormLib";
import Joi from "joi-browser";
import toast from "./../../../../services/_toastService";
import { updateDocket } from "./../../../../services/Tickets/create/LifelongService";

class LifelongDocket extends FormLib {
  state = {
    data: {
      processId: "",
      docketNo: "",
    },
    errors: {},
  };

  schema = {
    processId: Joi.string().required().label("Project*"),
    docketNo: Joi.string().required().label("Project Code*"),
  };

  async doSubmit() {
    let res;

    res = await updateDocket(this.state.data);

    if (res.data.status === 1) {
      toast.toastSuccess(res.data.msg);
      this.props.handleLoadTickets();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  componentDidMount() {
    this.setCurMisTicket(this.props.curMisTicket);
  }

  setCurMisTicket(p) {
    const data = {};
    data.processId = p.PROCESS_ID_;
    data.docketNo = p.DOCKET_NO_;
    this.setState({
      data,
    });
  }

  resetData() {
    this.setState({
      data: {
        processId: "",
        docketNo: "",
      },
    });
  }

  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <form>
              <div className="form-row">
                <span>{this.props.curMisTicket.ORDER_NUMBER_}</span> &nbsp; ||
                &nbsp;
                <span>{this.props.curMisTicket.RL_TICKET_NO_}</span>
                <br />
                <br />
                <br />
                {this.rTBH("ProcessId", "processId", "col-2")}
                {this.rTB("DocketNo", "docketNo", "col-8")}
              </div>
            </form>
            {this.rBtnSS("Edit", "edit")}
          </div>
        </div>
      </div>
    );
  }
}
export default LifelongDocket;
