import React from "react";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import FormLib from "../../../_common/FormLib";
import Joi from "joi-browser";
import { requestCall } from "../../../../services/requestV1/requestServices";
import { getActivity } from "./../../../../services/masters/hubServices";
import { toast } from "react-toastify";
import dateFormat from "dateformat";

class RequestApprove extends FormLib {
  state = {
    data: {},
    errors: {},
    approvalJSON: {},
  };

  schema = {
    amt_approved: Joi.number().min(0).label("Approved Total Amount"),
    approv_expense_head: Joi.optional().label("Approv Expense Head"),
    approv_amt: Joi.number()
      .required()
      .min(0)
      .label("Approved Required Amount"),
    approv_remark: Joi.string().required().label("Remarks"),
  };

  async componentDidMount() {
    this.setCurReq();

    let list = await getActivity();
    this.setState({ activities: list.data.data });
  }

  setCurReq() {
    this.setState({ approvalJSON: this.props.data });
    const data = { ...this.state.data };
    data.approv_expense_head = this.props.data["ACTIVITY"];
    this.setState({ data });
  }

  async doSubmit() {
    // console.log(
    //   "--------------------------> " + JSON.stringify(this.state.data)
    // );
    if (
      Number(this.state.data.amt_approved) > Number(this.state.approvalJSON.AMT)
    ) {
      let errors = this.state.errors;
      errors["amt_approved"] =
        `Approved Total Amount should be less than or equal to ` +
        this.state.approvalJSON.AMT;
      this.setState({
        errors,
      });
      return;
    }

    if (
      Number(this.state.data.approv_amt) >
        Number(this.state.approvalJSON.REQ_AMT) ||
      Number(this.state.data.approv_amt) > Number(this.state.data.amt_approved)
    ) {
      let errors = this.state.errors;
      errors["approv_amt"] =
        `Approved Required Amount should be less than or equal to ` +
        this.state.approvalJSON.REQ_AMT +
        ` & ` +
        this.state.data.amt_approved;
      this.setState({
        errors,
      });
      return;
    }

    const params = {
      request_id: this.state.approvalJSON.REQUEST_ID,
      approve_id: this.state.approvalJSON.APPROVE_ID,
      ...this.state.data,
      status: this.state.btnValue,
    };
    // console.log(params, this.state.btnValue);
    let requestRaised = await requestCall(params, this.state.btnValue);
    if (requestRaised.data.success === true) {
      toast.success(() => <div>{requestRaised.data.msg}</div>);
      document.getElementById("myForm").reset();
      this.setState({ data: "" });
    } else {
      toast.error(() => <div>{requestRaised.data.msg}</div>);
    }
    this.props.requestStatusChange();
  }

  localHandleChange = (e) => {
    if (e.target.name === "amt_approved") {
      if (Number(this.state.approvalJSON.AMT) < Number(e.target.value)) {
        let errors = this.state.errors;
        errors["amt_approved"] =
          `Approved Total Amount should be less than or equal to ` +
          this.state.approvalJSON.AMT;
        this.setState({
          errors,
        });
      } else {
        let errors = this.state.errors;
        delete errors["amt_approved"];
        this.setState({
          errors,
        });
      }
      this.validateProperty(e.target.name, e.target.value);
    }
    if (e.target.name === "approv_amt") {
      if (
        Number(this.state.approvalJSON.REQ_AMT) < Number(e.target.value) ||
        Number(this.state.data.amt_approved) < Number(e.target.value)
      ) {
        let errors = this.state.errors;
        errors["approv_amt"] =
          `Approved Required Amount should be less than or equal to ` +
          this.state.approvalJSON.REQ_AMT +
          ` & ` +
          this.state.data.amt_approved;
        this.setState({
          errors,
        });
      } else {
        let errors = this.state.errors;
        delete errors["approv_amt"];
        this.setState({
          errors,
        });
      }
      this.validateProperty(e.target.name, e.target.value);
    }
  };
  handleView = () => {
    $(".modal-content").resizable({
      //alsoResize: ".modal-dialog",
      minHeight: 300,
      minWidth: 300,
    });
    $("#myModal").draggable();

    $("#myModal").on("show.bs.modal", function () {
      $(this).find(".modal-body").css({
        "max-height": "100%",
      });
    });
  };

  checkRM = (user, hub) => {
    if (user["roleCode"] === "coordinator_ho" || user["username"] === "admin") {
      return true;
    }
    if (
      user["username"] === "BLR0374" || //Vadhiraj
      user["username"] === "CHE001" || //Balaji
      user["username"] === "BLR0252" || //Avinash
      user["username"] === "BLR0196" || //Prasanna
      user["username"] === "DEL0093" || //Jagbir
      user["username"] === "KOL0025" || //Suvojith
      user["username"] === "BLR0359" // sangram
    ) {
      return true;
    }

    return false;
  };
  render() {
    const user = this.props.user;
    return (
      <React.Fragment>
        <div style={{ marginTop: "-4rem", position: "fixed" }}>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.props.closeModal}
          >
            <h6 aria-hidden="true"> {"Open->Approve"} &times;</h6>
          </button>
          <div className="card">
            <div className="card-header bg-success text-white ">
              <div className="row">
                <div className="col-8">{this.state.approvalJSON.M_CLIENT}</div>
                <div className="col-4 text-right">
                  # {this.state.approvalJSON.RE_PREFIX}
                  {this.state.approvalJSON.SEQ + " "}
                </div>
              </div>
              <div className="row">
                <div className="col-8">
                  {this.state.approvalJSON.REF_ID} [
                  {this.state.approvalJSON.M_HUB}]
                </div>
                <div className="col-4 text-right">
                  {dateFormat(this.state.approvalJSON.CREATED_AT, "dd-mm-yyyy")}
                </div>
              </div>
            </div>

            {/* End of Header */}

            <div className="card-body border">
              <div className="row">
                <div className="col-4">
                  <span className="req-label">Request Type </span>
                  <span className="req-value">
                    {this.state.approvalJSON.REQUEST_TYPE}
                  </span>
                </div>
                {this.state.approvalJSON.PLAYER_TYPE === "employee" && (
                  <div className="col-4">
                    <span className="req-label">Employee</span>
                    <span className="req-value">
                      {this.state.approvalJSON.E_NAME} [
                      {this.state.approvalJSON.PLAYER_CODE}]
                    </span>
                  </div>
                )}
                {this.state.approvalJSON.PLAYER_TYPE === "vendor" && (
                  <div className="col-4">
                    <span className="req-label">Vendor</span>
                    <span className="req-value">
                      {this.state.approvalJSON.V_NAME} [
                      {this.state.approvalJSON.PLAYER_CODE}]
                    </span>
                  </div>
                )}
                <div className="col-4">
                  <span className="req-label">Flow </span>
                  <span className="req-value">
                    {this.state.approvalJSON.M_FLOW}
                  </span>
                </div>
              </div>

              <div className="panel-group widget-request">
                <div className="panel panel-info">
                  <label>Activity Details</label>
                  <div className="panel-body">
                    {this.state.approvalJSON.M_ACTIVITY}
                  </div>
                </div>
              </div>
              <div className="panel-group widget-request">
                <div className="panel panel-info">
                  <label>Request Details</label>
                  <div className="panel-body">
                    {this.state.approvalJSON.REQUEST_DETAILS}
                  </div>
                </div>
              </div>
              <br />
              {this.state.approvalJSON.REVERT_REMARKS &&
                this.state.approvalJSON.REVERT_REMARKS !== null && (
                  <div className="panel-group widget-request">
                    <div className="panel panel-info bg-warning">
                      <label className="bg-warning text-white">
                        Revert Remarks
                      </label>
                      <div className="panel-body bg-warning">
                        {this.state.approvalJSON.REVERT_REMARKS}
                      </div>
                    </div>
                  </div>
                )}
              {this.state.approvalJSON.AMT_APPROVED === 0 && (
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <span className="req-label">Total Amount </span>
                      <span className="req-value">
                        {this.state.approvalJSON.AMT}
                      </span>
                    </div>
                    <div className="col-6 text-right">
                      <span className="req-label">Requested Amount </span>
                      <span className="req-value">
                        {this.state.approvalJSON.REQ_AMT}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {this.state.approvalJSON.AMT_APPROVED !== 0 && (
                <div className="card-body">
                  <div className="row">
                    <div className="col-6">
                      <span className="req-label"> Amount </span>
                      <span className="req-value">
                        {this.state.approvalJSON.PAID} /&nbsp;
                        {this.state.approvalJSON.AMT_APPROVED}
                      </span>
                    </div>
                    <div className="col-6 text-right">
                      <span className="req-label">Requested Amount </span>
                      <span className="req-value">
                        {this.state.approvalJSON.REQ_AMT}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {user && this.checkRM(user, this.state.approvalJSON.M_HUB) && (
                <>
                  <div className="card-body">
                    <form id="myForm" onSubmit={this.handleSubmit}>
                      {this.state.approvalJSON.AMT_APPROVED === 0 && (
                        <div className="row">
                          {this.rNB(
                            "Apprv. Total Amt.",
                            "amt_approved",
                            "col-md-6"
                          )}
                          {this.rNB(
                            "Approved Required Amount",
                            "approv_amt",
                            "col-md-6"
                          )}
                        </div>
                      )}
                      {this.state.approvalJSON.AMT_APPROVED !== 0 && (
                        <div className="row">
                          {this.rNB(
                            "Approved Amount",
                            "approv_amt",
                            "col-md-6"
                          )}
                        </div>
                      )}
                      {/* {this.rTB("Approve Expense Head", "approv_expense_head")} */}
                      {this.rSB(
                        "Approve Expense Head *",
                        "approv_expense_head",
                        this.state.activities,
                        { value: "EXPENSE_HEAD_ID", label: "EXPENSE_HEAD" },
                        " col "
                      )}
                      {this.rTA("Remarks", "approv_remark")}
                    </form>
                  </div>

                  <div className="row p-1">
                    {this.state.approvalJSON.AMT_APPROVED !== "0" && (
                      <div className="col-6">
                        {this.rBtnSD(
                          "Reject",
                          "reject",
                          "btn btn-danger btn-sm btn-block"
                        )}
                      </div>
                    )}
                    {this.state.approvalJSON.AMT_APPROVED === "0" && (
                      <div className="col-6">
                        {this.rBtnSD(
                          "Reject Close",
                          "rejectBase",
                          "btn btn-danger btn-sm btn-block"
                        )}
                      </div>
                    )}
                    <div className="col-6">
                      {this.rBtnS(
                        "Approve",
                        "approved",
                        "btn btn-primary btn-sm btn-block"
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default RequestApprove;
