import React from "react";
import FormLib from "../../_common/FormLib";
import swal from "sweetalert";
import Joi from "joi-browser";
import Table from "../../_common/Table";
//import Pagination from "../../_common/Pagination";

import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";
import {
  getRegions,
  createRegions,
  saveRegions,
  deleteRegions,
} from "../../../services/masterLocations/regionsServices";
class Regions extends FormLib {
  state = {
    data: {
      regionId: "",
      region: "",
      status: "active",
    },
    errors: {},
    formMode: "create",
    regions: [],
    loading: true,
    // pageLinks: {
    //   pageNo: 1,
    //   perPage: 0,
    //   itemsCount: 0,
    //   lastPage: 0,
    // },
  };
  schema = {
    regionId: Joi.optional().label("Region Id"),
    region: Joi.string().required().label("Region"),
    status: Joi.string().required().label("Status"),
  };
  componentDidMount() {
    this.loadRegions();
  }
  async loadRegions() {
    this.setState({ loading: true });
    const res = await getRegions();
    if (res.data.success === true) {
      this.setState({ regions: res.data.data.regions });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  tableMeta = {
    key: "regionId",
    columns: [
      {
        key: "REGION_ID",
        action: (r) => (
          <div className="btn-group pull-right dropdown dropright">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnRegions(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true" /> Edit
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleView(r)}
              >
                <i className="fa fa-binoculars" aria-hidden="true" />View
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(r)}
              >
                <i className="fa fa-trash" aria-hidden="true" /> Delete
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"/>,
      },
      // { path: "regionId", label: "Region Id" },
      { path: "region", label: "Region" },
      { path: "status", label: "Status" },
    ],
  };
  handleOnRegions(r) {
    console.log("inside handle on regions");
    let regions = {};
    regions.regionId = r.regionId;
    regions.region = r.region;
    regions.status = r.status;
    this.setState({ data: regions, formMode: "edit" });
  }
  //   handlePageChange = (pageNo) => {
  //     this.props.history.push("/location/city" + pageNo);

  //     this.loadCities();
  //   };
  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createRegions(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveRegions(this.state.data);
      this.resetData();
    }

    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadRegions();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  doSubmitDelete = async (handleDelete, r) => {
    if (handleDelete) {
      await deleteRegions(r);
      this.loadRegions();

      swal("Region has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Region is safe!");
    }
  };

  handleDelete = async (r) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, r);
    });
  };

  handleView = (r) => {
    this.setState({ curRegion: r, formMode: "view" });
  };

  resetData() {
    this.setState({
      data: {
        regionId: "",
        region: "",
        status: "active",
      },
      formMode: "create",
    });
  }
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    //const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Regions" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-6">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Regions</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.regions}
                    dropMenu={[]}
                  />
                  {/* <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  /> */}
                </div>
              )}
            </div>

            <div className="col-md-6">
              {this.state.formMode === "view" && (
                <div className="card">
                  <div className="card-body">
                    <>
                      {this.state.curRegion["region"].trim()}
                      <br />
                      <div className="pull-right">
                        <span className={"badge   badge-light pull-left ml-1 "}>
                          {/* {this.state.curRegion["regionId"]} */}
                        </span>
                        <span
                          className={"badge   badge-success pull-left  mr-1 "}
                        >
                          {this.state.curRegion["status"]}
                        </span>
                      </div>
                    </>
                  </div>
                </div>
              )}
              {this.state.formMode !== "view" && (
                <div className="card pt-2">
                  <div className="card-body">
                    <form>
                      <div className="form-row">
                        {this.rTBH("RegionId", "regionId", "col-4")}
                        {this.rTB("Region", "region", "col-12")}
                        {this.rRBL(
                          "Status",
                          "status",
                          this.status,
                          this.stsConf
                        )}
                      </div>
                    </form>
                    {this.state.formMode === "create" &&
                      this.rBtnSP("Create", "create")}
                    {this.state.formMode === "edit" && (
                      <>
                        {this.rBtnSS("Edit", "edit")}
                      </>
                    )}
                    {this.mResetBtn(() => this.resetData(), " pull-right ","Reset")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Regions;
