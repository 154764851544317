import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import { getRetailers } from "./../../../services/masters/retailerServices";
import {
  getLocationById,
  getLocations,
  createLocation,
  updateLocation,
  deleteLocation,
} from "./../../../services/team/RetailerLocationServices";
import ModalLocation from "./ModalLocation";
class RetailerLocations extends FormLib {
  state = {
    data: { client: "", flow: "" },
    retailers: [],
    locations: [],
    curLocation: {},
    errors: {},
  };

  schema = {
    client: Joi.string().required().label("client"),
  };

  async componentDidMount() {
    let list = await getRetailers();
    this.setState({ retailers: list.data.data.teams });
  }
  async getLocations(id) {
    let loc = await getLocationById(id);
    this.setState({ locations: loc.data.data.locations });
  }

  setCurLocation = (r) => {
    this.setState({ curLocation: r });
  };

  localHandleChange = (e) => {
    if (e.target.name === "client") {
      this.handleClient(e);
    }
    if (e.target.name === "flow") {
      const { value } = e.target;

      let data = this.state.data;
      data["flow"] = value;
      this.setState({
        data,
      });
      this.handleCheckBox(e);
      this.validateProperty(e.target.name, e.target.value);
    }
  };

  handleClient(e) {
    const { value } = e.target;
    let data = this.state.data;
    let retailer = this.state;

    // find the clients with the current selected value
    const clients = retailer.clients.find((c) => c.name === value);

    if (clients !== undefined) {
      // console.log("------>bhanthu " + JSON.stringify(clients.teamId));
      data["client"] = clients.teamId;
      this.setState({
        data,
      });
    }
    this.validateProperty(e.target.name, e.target.value);
  }

  handleCheckBox(e) {
    let params = {
      flowId: this.state.data.flow,
      teamId: this.state.data.client,
    };
    if (e.target.checked) {
      this.assignAPI(params);
      // console.log("Checkbox is checked.." + JSON.stringify(params));
    } else {
      console.log("Checkbox is not checked.." + JSON.stringify(params));
    }
  }

  //   async assignAPI(params) {
  //     let res = await assignRetailerflow(params);
  //     console.log("Checkbox " + JSON.stringify(res));
  //   }

  render() {
    return (
      <React.Fragment>
        <div className="col-12 col-md-12">
          <br />
          <div className="row">
            <div id="cocard" className="col-4   ml-1 mr-4">
              <div id="primary-ticket-search" className="row">
                <div className="col-7 ml-2">
                  {this.rTB("Retailer Name / Code", "search")}
                </div>
                <div className="col-2">
                  <button className="btn btn-info btn-sm" name="recordinput">
                    Search
                  </button>
                </div>
              </div>
              <div>
                {this.state.retailers.map((e) => {
                  return (
                    <div className="card mb-2 ">
                      <div className="card-header  bg-light">
                        <div className="row">
                          <div className="col-10">
                            <p>&nbsp;&nbsp;{e.name}</p>
                          </div>
                          <div className="col-2 text-right">
                            <span
                              onClick={() => this.getLocations(e.teamId)}
                              className={"badge badge-info"}
                              id="btn1"
                              data-backdrop="false"
                              data-toggle="modal"
                              aria-hidden="true"
                            >
                              {e.code}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div id="locard" className="col-5  ml-1 mr-4">
              <br />
              <div id="location-dis" className="row">
                <div className="col-12">
                  <div className="card-head bg-white mt-1 mb-1">
                    <h4 className="ml-4 pt-2 pb-2">
                      Locations
                      <button
                        type="button"
                        className="btn btn-sm btn-info pull-right"
                        data-toggle="modal"
                        data-target="#myProjectModel"
                      >
                        <i className={"fa  fa-plus  "}></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-sm btn-info pull-right"
                        data-toggle="modal"
                        data-target="#myProjectModel"
                      >
                        <i className={"fa  fa-plus  "}></i>
                      </button>
                    </h4>
                  </div>
                  <span>{JSON.stringify(this.state.curLocation)}</span>
                </div>
              </div>
              <div>
                {this.state.locations.map((r) => {
                  return (
                    <div className="card mt-2  ">
                      <div className="card-header  bg-light">
                        <div className="row">
                          <div className="col-4">
                            <b>{r.locName}</b> | {r.locLocation}
                          </div>

                          <div className="col-2 text-right ">
                            <span
                              className="badge badge-success pull-right"
                              onClick={() => this.setCurLocation(r)}
                            >
                              {r.locCode}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-5">{r.locContactPerson}</div>
                          <div className="col-12">{r.locAddress}</div>
                        </div>
                        <div className="row">
                          <div className="col-5">
                            {r.locPhone} | {r.locEmail}
                          </div>
                          <div className="col-5"></div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <ModalLocation
            curLocation={this.state.curLocation}
            key={this.state.curLocation["locationId"]}
          />
        </div>
        {/* **************************** */}
      </React.Fragment>
    );
  }
}

export default RetailerLocations;
