import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import { updateBulkBarcodeStatus } from "../../../services/packing/BarcodeService";

class ModalUpdateBulkBarcodeStatus extends FormLib {
  state = {
    data: {
      barcodeStart: "",
      barcodeEnd: "",
      status: "",
    },
    errors: {},
    status: [
      { statusId: "40damaged", status: "Damaged" },
      { statusId: "50scraped", status: "Scraped" },
    ]
  };

  // stsConf = {
  //     value: "sortKey",
  //     label: "sort",
  // };

  schema = {
    barcodeStart: Joi.required().label("barcodeStart"),
    barcodeEnd: Joi.required().label("barcodeEnd"),
    status: Joi.string().required().label("status"),
  };


  doSubmit = async () => {
    let res;
    // console.log("REQ JSON--------------->" + this.state.data);
    if (this.state.btnValue === "update") {
      res = await updateBulkBarcodeStatus(this.state.data);
      this.resetData();
    }
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
    } else {
      toast.toastError(res.data.msg);
    }
  };

  resetData() {
    this.setState({
      data: {
        barcodeStart: "",
        barcodeEnd: "",
        status: "",
      },
    });
  }
  render() {
    return (
      <div className="container-fluid">
        <div className="modal fade" id="myBulkBarcodeUpdate">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Update Bulk Barcode Status</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  <div className="form-row">
                    <div className="col-md-3 ">
                      <div>
                        {this.rSB(
                          "Status *",
                          "status",
                          this.state.status,
                          { value: "statusId", label: "status" },

                        )}
                      </div>
                    </div>
                    {this.rNB("Barcode Start", "barcodeStart", "col-4")}
                    {this.rNB("Barcode End", "barcodeEnd", "col-4")}
                    {/* </div> */}

                  </div>
                </form>
              </div>


              <div className="modal-footer">
                <div className="row">
                  <div className="col-3">
                    {this.rBtnSP("Update", "update")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalUpdateBulkBarcodeStatus;
