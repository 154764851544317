import React from "react";
import FormLib from "./../../_common/FormLib";
import LocationTitle from "../../_subhead/LocationTitle";

import toast from "../../../services/_toastService";
import {
  getImages,
  getAdditionalImages,
} from "./../../../services/TicketHistory/TicketHistory";

class TicketFiles extends FormLib {
  state = {
    data: {},
    errors: {},
    curProductDetails: [],
    curTicketDetails: [],
    setImages: [],
    setAdditionalImages: [],
    wentWrong: "",
  };

  componentDidMount() {
    this.loadImages();
  }

  async loadImages() {
    try {
      this.setState({ loading: true });

      const processId = this.props.curProcessId;

      let res = await getImages(processId);
      if (res !== undefined) {
        if (
          res.data.success === true &&
          res.data.data !== [] &&
          res.data.data !== undefined
        ) {
          this.setState({ setImages: res.data.data.TicketFilesImage });
        } else if (res.data.success === false) {
          toast.error("No Images Available for this Block");
        }
      } else {
        this.setState({ wentWrong: undefined });
      }
      this.setState({ loading: false });

      const list1 = await getAdditionalImages(processId);
      if (list1 !== undefined) {
        this.setState({ setAdditionalImages: list1.data.data.AdditionalFiles });
      }
    } catch {
      console.log("Error in TicketFiles>>>>>> loadImages");
    }
  }

  stringReplace(data) {
    return data
      .replace(/{/g, "")
      .replace(/}/g, "")
      .replace(/]/g, "")
      .replace(/"/g, "")
      .replace(/:/g, " : ")
      .replace(/,/g, " , ");
  }

  LatLong(data) {
    let result = JSON.parse(data);
    if (result) {
      return "[ " + result["Latitude"] + " , " + result["Longitude"] + " ]";
    }
  }

  replaceString = (str) => {
    return str.replace(/_/g, " ");
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading && (
          <div className="container-fluid">
            <div> Loading Files...</div>
          </div>
        )}
        {!this.state.loading && this.state.setImages.length !== 0 && (
          <div className="row mt-4 ">
            <div className="col-md-12">
              <div className="card ">
                <div className="card-body bg-custom-6">
                  <div
                    className="form-subHeader text-center"
                    style={{
                      color: "#e6e8f1",
                      backgroundColor: "#8b9566",
                    }}
                  >
                    Before Packing Photos
                  </div>
                  <div className="row">
                    {this.state.setImages.map((t) => {
                      return (
                        <>
                          {t.actionCode !== "mSignature" &&
                            t.actionCode !== "mPhotoId" &&
                            t.actionCode !== "mPhotoAftrPackng" &&
                            t.actionCode !== "wAdditionalFiles" && (
                              <div className="col-md-4">
                                <span>
                                  <a
                                    href="javascript:void(0);"
                                    data-toggle="tooltip"
                                    title={this.stringReplace(t.geoLocation)}
                                  >
                                    <img
                                      src={t.imageUrl}
                                      style={{
                                        width: "150px",
                                        height: "150px",
                                        margin: "3px",
                                      }}
                                    />
                                  </a>
                                  <br></br>
                                  <span
                                    className="badge badge-info link-over ml-2"
                                    style={{
                                      width: "auto",
                                      height: "auto",
                                      margin: "3px",
                                    }}
                                  >
                                    {t.action}
                                  </span>
                                </span>
                                <br></br>
                                {this.LatLong(t.geoLocation)}
                                <br></br>
                                <br></br>
                              </div>
                            )}
                        </>
                      );
                    })}
                  </div>
                  <div
                    className="form-subHeader text-center"
                    style={{ color: "#3d4154", backgroundColor: "#89c0b1" }}
                  >
                    After Packing Photos
                  </div>
                  <div className="row">
                    {this.state.setImages.map((t) => {
                      return (
                        <>
                          {t.actionCode === "mPhotoAftrPackng" && (
                            <div className="col-md-4">
                              <span>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="tooltip"
                                  title={this.stringReplace(t.geoLocation)}
                                >
                                  <img
                                    src={t.imageUrl}
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      margin: "3px",
                                    }}
                                  />
                                </a>
                                <br></br>
                                <span
                                  className="badge badge-warning link-over ml-2"
                                  style={{
                                    width: "auto",
                                    height: "auto",
                                    margin: "3px",
                                  }}
                                >
                                  {t.action}
                                </span>
                              </span>
                              <br></br>
                              {this.LatLong(t.geoLocation)}
                              <br></br>
                              <br></br>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                  <div
                    className="form-subHeader text-center"
                    style={{ color: "#3d4154", backgroundColor: "#8e9e6d" }}
                  >
                    Id Photos
                  </div>
                  <div className="row">
                    {this.state.setImages.map((t) => {
                      return (
                        <>
                          {t.actionCode === "mPhotoId" && (
                            <div className="col-md-4">
                              <span>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="tooltip"
                                  title={this.stringReplace(t.geoLocation)}
                                >
                                  <img
                                    src={t.imageUrl}
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      margin: "3px",
                                    }}
                                  />
                                </a>
                                <br></br>
                                <span
                                  className="badge badge-danger link-over ml-2"
                                  style={{
                                    width: "auto",
                                    height: "auto",
                                    margin: "3px",
                                  }}
                                >
                                  {t.action}
                                </span>
                              </span>
                              <br></br>
                              {this.LatLong(t.geoLocation)}
                              <br></br>
                              <br></br>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                  <div
                    className="form-subHeader text-center"
                    style={{ color: "#3d4154", backgroundColor: "#8cbd6d" }}
                  >
                    Signature
                  </div>
                  <div className="row">
                    {this.state.setImages.map((t) => {
                      return (
                        <>
                          {t.actionCode === "mSignature" && (
                            <div className="col-md-4">
                              <span>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="tooltip"
                                  title={this.stringReplace(t.geoLocation)}
                                >
                                  <img
                                    src={t.imageUrl}
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      margin: "3px",
                                    }}
                                  />
                                </a>
                                <br></br>
                                <span
                                  className="badge badge-success link-over ml-2"
                                  style={{
                                    width: "auto",
                                    height: "auto",
                                    margin: "3px",
                                  }}
                                >
                                  {this.replaceString(t.action)}
                                </span>
                              </span>
                              <br></br>
                              {this.LatLong(t.geoLocation)}
                              <br></br>
                              <br></br>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                  <div
                    className="form-subHeader text-center"
                    style={{ color: "#3d4154", backgroundColor: "#8cbd6d" }}
                  >
                    Additional Files
                  </div>
                  <div className="row">
                    {this.state.setAdditionalImages.map((t) => {
                      return (
                        <>
                          {t.actionCode === "wAdditionalFiles" && (
                            <div className="col-md-4">
                              <span>
                                <a
                                  href="javascript:void(0);"
                                  data-toggle="tooltip"
                                  title={t.fileName}
                                >
                                  <img
                                    src={t.imageUrl}
                                    style={{
                                      width: "150px",
                                      height: "150px",
                                      margin: "3px",
                                    }}
                                  />
                                </a>
                                <br></br>
                                <span
                                  className="badge badge-success link-over ml-2"
                                  style={{
                                    width: "auto",
                                    height: "auto",
                                    margin: "3px",
                                  }}
                                >
                                  {this.replaceString(t.action)}
                                </span>
                              </span>
                              <br></br>
                              {/* {this.LatLong(t.geoLocation)} */}
                              <br></br>
                              <br></br>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!this.state.loading &&
          this.state.setImages.length === 0 &&
          this.state.wentWrong !== undefined && (
            <div className="container-fluid jumbotron text-center">
              <h4>No Images Available for this ticket...</h4>
            </div>
          )}
        {!this.state.loading && this.state.wentWrong === undefined && (
          <div className="container-fluid jumbotron text-center">
            <h4>Something went wrong please try again...</h4>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default TicketFiles;
