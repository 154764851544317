import _http from "./../_httpNoRejectService";
import config from "./../../config.json";

const apiMisEndpoint = config[config.build].apiURLMis;
const apiCoreEndpoint = config[config.build].apiURLCore;
const apiCoreEndpointE = config[config.build].apiURLECore;
const apiInvoice = config[config.build].apiInvoice;
const baseURLInventory = config[config.build].apiURLInventory;


export function setTicketActions(stateData, ticketData, blockData, resultSetData, ticketProductId = "") {
  // console.log("resultSetData........" + JSON.stringify(resultSetData))

  try {
    let params = {
      syncId: "",
      processId: blockData.processId,
      ticketBlockId: blockData.ticketBlockId,
      ticketProductId: ticketProductId,
      ticketNo: ticketData.ticketNo,
      retailerId: ticketData.retailerId,
      retailerCode: ticketData.retailerCode,
      actionCode: stateData.actionCode,
      action: stateData.action,
      flowStatus: blockData.flowStatus,
      flowId: ticketData.flowId,
      resultSet: resultSetData,
      direction: blockData.direction,
      isHistoryRecord: "",
      isHistoryShown: "",
      isStrictLevel: "",
      syncStatus: "SYNC",
    };

    // console.log("sssss......." + JSON.stringify(params));
    //192.168.137.228
    // const apiMisEndpoint = "http://192.168.3.93:9092"
    return _http.post(
      apiMisEndpoint + "/ticket/mis/update",
      params,
      _http.headerJWT()
    );
  }
  catch {
    return undefined;
  }
}

export function setTicketActionsNew(stateData, ticketData, blockData, resultSetData, ticketProductId = "") {

  try {
    let params = {
      syncId: "",
      processId: blockData.processId,
      ticketBlockId: blockData.ticketBlockId,
      ticketProductId: ticketProductId,
      ticketNo: ticketData.ticketNo,
      retailerId: ticketData.retailerId,
      retailerCode: ticketData.retailerCode,
      actionCode: stateData.actionCode,
      action: stateData.action,
      flowStatus: blockData.flowStatus,
      flowId: ticketData.flowId,
      resultSet: resultSetData,
      direction: blockData.direction,
      isHistoryRecord: "",
      isHistoryShown: "",
      isStrictLevel: "",
      syncStatus: "SYNC",
    };
    // console.log("dgggg......." + JSON.stringify(params));
    return _http.post(
      apiMisEndpoint + "/new/ticket/mis/update",
      params,
      _http.headerJWT()
    );
  }
  catch {
    return undefined;
  }
}
export function setTicketActionsOutForProcess(stateData, ticketData, blockData, resultSetData, ticketProductId = "") {

  try {
    let params = {
      syncId: "",
      processId: blockData.processId,
      ticketBlockId: blockData.ticketBlockId,
      ticketProductId: ticketProductId,
      ticketNo: ticketData.ticketNo,
      retailerId: ticketData.retailerId,
      retailerCode: ticketData.retailerCode,
      actionCode: stateData.actionCode,
      action: stateData.action,
      flowStatus: blockData.flowStatus,
      resultSet: resultSetData,
      direction: blockData.direction,
      isHistoryRecord: "",
      isHistoryShown: "",
      isStrictLevel: "",
      syncStatus: "SYNC",
    };
    // console.log(JSON.stringify(params));
    return _http.post(
      apiMisEndpoint + "/new/ticket/mis/update",
      params,
      _http.headerJWT()
    );
  }
  catch {
    return undefined;
  }
}


export function setTicketActionsRTO(stateData, ticketData, blockData, resultSetData, ticketProductId = "") {
  try {

    let params = {
      syncId: "",
      processId: blockData.processId,
      ticketBlockId: blockData.ticketBlockId,
      ticketProductId: ticketProductId,
      ticketNo: ticketData.ticketNo,
      retailerId: ticketData.retailerId,
      retailerCode: ticketData.retailerCode,
      actionCode: stateData.actionCode,
      action: stateData.action,
      flowStatus: blockData.flowStatus,
      direction: blockData.direction,
      resultSet: resultSetData,
      isHistoryRecord: "",
      isHistoryShown: "",
      isStrictLevel: "",
      syncStatus: "SYNC",
    };
    // console.log(JSON.stringify(params));
    return _http.post(
      apiMisEndpoint + "/ticket/mis/update",
      params,
      _http.headerJWT()
    );
  }
  catch {
    return undefined;
  }
}
export function setTicketActionsTicketCancel(stateData, ticketData, blockData, resultSetData, ticketProductId = "") {
  try {
    let params = {
      syncId: "",
      processId: blockData.processId,
      ticketBlockId: blockData.ticketBlockId,
      // ticketProductId: ticketProductId,
      ticketNo: ticketData.ticketNo,
      // retailerId: ticketData.retailerId,
      // retailerCode: ticketData.retailerCode,
      actionCode: stateData.actionCode,
      action: stateData.action,
      // flowStatus: blockData.flowStatus,
      direction: blockData.direction,
      resultSet: resultSetData,
      isHistoryRecord: "",
      isHistoryShown: "",
      isStrictLevel: "",
      syncStatus: "SYNC",
    };
    // console.log(JSON.stringify(params));

    return _http.post(
      apiMisEndpoint + "/ticket/mis/update",
      params,
      _http.headerJWT()
    );
  }
  catch {
    return undefined;
  }
}


export function setTicketActionsReschedule(stateData, ticketData, blockData, resultSetData, ticketProductId = "") {
  try {

    let params = {
      syncId: "",
      processId: blockData.processId,
      ticketBlockId: blockData.ticketBlockId,
      ticketProductId: ticketProductId,
      ticketNo: ticketData.ticketNo,
      retailerId: ticketData.retailerId,
      retailerCode: ticketData.retailerCode,
      actionCode: stateData.actionCode,
      action: stateData.action,
      flowStatus: blockData.flowStatus,
      flowId: ticketData.flowId,
      resultSet: resultSetData,
      direction: blockData.direction,
      isHistoryRecord: "",
      isHistoryShown: "",
      isStrictLevel: "",
      syncStatus: "SYNC",
    };
    return _http.post(
      apiMisEndpoint + "/ticket/mis/update",
      params,
      _http.headerJWT()

    );
  }
  catch {
    return undefined;
  }
}

export function setTicketActionsProdStatus(remarks, ticketData, blockData, productData, status) {
  try {

    let params = {
      remark: remarks,
      ticketBlockId: blockData.ticketBlockId,
      ticketProductId: productData.ticketProductId,
      status: status,
    };
    return _http.put(
      apiMisEndpoint + "/new/ticket/update/prodQcStatus",
      params,
      _http.headerJWT()

    );
  }
  catch {
    return undefined;
  }
}


export function createBlock(params) {
  return _http.post(
    apiMisEndpoint + "/ticket/createblock",
    params,
    _http.headerJWT()
  );
}

export function assignPrdToBlock(params) {
  return _http.post(
    apiMisEndpoint + "/ticket/updateBlockByTicketProdId",
    params,
    _http.headerJWT()
  );
}
export function loadTicketJobs(ticketNo) {
  let apiCoreEndpoint1 = apiCoreEndpoint + "/ticketDetails/details/" + ticketNo;
  return _http.get(apiCoreEndpoint1, _http.headerJWT());
}

export function getBarcodesList(params, curPage) {
  return _http.put(
    apiCoreEndpoint +
    "/tracker/settings/barcodes/getbarcodes/" +
    "8" +
    "/" +
    curPage,
    params,
    _http.headerJWT()
  );
}


export function phoneCall(params) {
  return _http.post(apiCoreEndpoint + "/call/customer", params, _http.headerJWT());
}

export function phoneCallInfo(params) {
  // console.log(JSON.stringify(apiCoreEndpointE));
  return _http.post("https://test-api.bizlog.in:1337/core/call/reqContact", params, _http.headerJWT());
}

export function updateSrcHub(params) {
  return _http.put(apiMisEndpoint + "/ticket/update/srcHub", params, _http.headerJWT());
}

export function updateDstHub(params) {
  return _http.put(apiMisEndpoint + "/ticket/update/dstHub", params, _http.headerJWT());
}
export function resheduleFENull(params) {
  return _http.put(apiMisEndpoint + "/new/ticket/update/old", params, _http.headerJWT());
}

export function updateCurHub(ticketBlockId, hubId) {
  try {
    let apiMisEndpointHub = apiMisEndpoint + "/ticket/changeHub/cur/" + ticketBlockId + "/" + hubId;
    return _http.get(apiMisEndpointHub, _http.headerJWT());
  }
  catch {
    return undefined;
  }
}


export function getActionCategory() {
  let apiCoreEndpoint1 = apiCoreEndpoint + "/getActionCategory";
  return _http.get(apiCoreEndpoint1, _http.headerJWT());
}

export function setTicketActionsVehicle(data) {

  try {
    const formData = new FormData();

    if (data.selectedFilesList.length > 0) {


      const files = data.selectedFilesList;

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i].name)
      }
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }
    else {
      const files = data.selectedFilesList;
      formData.append("files", null);
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }


    let apiMisEndpoints = apiMisEndpoint + "/attachment/actions/upload";
    // let apiMisEndpoints = "http://192.168.3.78:9092/attachment/actions/upload";
    return _http.post(
      apiMisEndpoints,
      formData,
      _http.headerJWT()
    );
  }
  catch {
    return undefined;
  }
}

export function getTicketActionsDetails(ticketActionId) {
  try {
    let apiMisEndpoints = apiMisEndpoint + "/ticket/details/byAction/" + ticketActionId;

    return _http.get(apiMisEndpoints, _http.headerJWT());
  }
  catch {
    return undefined;
  }


}


export function getTicketActionDetails(params, pageNo) {
  let payload = { ...params };
  payload["startDate"] = params["startDate"] + " 00:00:00";
  payload["endDate"] = params["endDate"] + " 23:59:59";

  const apiEndpoint =
    apiMisEndpoint + "/ticket/actions/report/" + "10/" + pageNo;
  return _http.put(apiEndpoint, payload, _http.headerJWT());
}

export function getSaveFile(data) {
  const formData = new FormData();
  const files = data.selectedFilesList;
  for (let i = 0; i < files.length; i++) {
    formData.append("files", files[i], files[i].name)
  }
  const fieldsData = { ...data };
  delete fieldsData.selectedFilesList;
  const fields = JSON.stringify(fieldsData);
  formData.append("fields", fields);

  let apiMisEndpoints = apiMisEndpoint + "/attachment/ticketActions/upload";
  return _http.post(
    apiMisEndpoints,
    formData,
    _http.headerJWT()
  );
}


export function getFilesDetails(ticketActionId) {
  try {
    let apiMisEndpoints = apiMisEndpoint + "/ticket/action/files/" + ticketActionId;

    return _http.get(apiMisEndpoints, _http.headerJWT());
  }
  catch {
    return undefined;
  }


}

export function getQuestions(actionId) {
  try {
    let apiMisEndpoints = apiMisEndpoint + "/ticket/action/fields/" + actionId;

    return _http.get(apiMisEndpoints, _http.headerJWT());
  } catch {
    return undefined;
  }
}

export function getTicketClosingStatus(ticketNo) {

  const path = `${apiInvoice}/report/ticketClosingStatus/${ticketNo}`
  return _http.get(path, _http.headerJWT());
}

export function setTicketStatus(stateData, ticketData, processAt, ticketProductId = "") {

  try {
    let params = {
      syncId: "",
      processId: ticketData.processId,
      ticketBlockId: ticketData.ticketBlockId,
      ticketProductId: ticketProductId,
      actionCode: "wChangeTicketStatus",
      action: "Change Ticket Status",
      flowStatus: stateData.flowStatus,
      hubId: ticketData.hubId,
      srcHubId: ticketData.srcHubId,
      dstHubId: ticketData.dstHubId,
      direction: ticketData.direction,
      blockStatus: stateData.status,
      prevStatus: ticketData.blockStatus,
      processAt: processAt,
      isHistoryRecord: "",
      isHistoryShown: "No",
      isStrictLevel: "",
      syncStatus: "SYNC",
    };

    // console.log("params......." + JSON.stringify(params));
    // let apiMisEndpoints = "http://192.168.3.78:9092/mis-report/ticket/change/jobStatus";
    return _http.put(
      apiMisEndpoint + "/ticket/change/jobStatus",
      // apiMisEndpoints,
      params,
      _http.headerJWT()
    );

  }
  catch {
    return undefined;
  }
}
export function setTicketVehicleInfo(stateData, ticketData, blockData, resultSetData, ticketProductId = "") {

  try {
    let params = {
      syncId: "",
      processId: blockData.processId,
      ticketBlockId: blockData.ticketBlockId,
      ticketProductId: blockData.products[0].ticketProductId,
      ticketNo: ticketData.ticketNo,
      retailerId: ticketData.retailerId,
      retailerCode: ticketData.retailerCode,
      actionCode: stateData.actionCode,
      action: stateData.action,
      flowStatus: blockData.flowStatus,
      flowId: ticketData.flowId,
      resultSet: resultSetData,
      direction: blockData.direction,
      isHistoryRecord: "",
      isHistoryShown: "",
      isStrictLevel: "",
      syncStatus: "SYNC",
    };
    // console.log("sssss......." + JSON.stringify(blockData));

    // console.log("params......." + JSON.stringify(params));
    // const url = "http://192.168.3.78:9092/ticket/mis/update";
    return _http.post(
      apiMisEndpoint + "/ticket/mis/update",
      // url,
      params,
      _http.headerJWT()
    );
  }
  catch {
    return undefined;
  }
}
export function setTicketVehicleInfoUpload(data) {

  try {
    const formData = new FormData();

    if (data.selectedFilesList.length > 0) {


      const files = data.selectedFilesList;

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i].name)
      }
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }
    else {
      const files = data.selectedFilesList;
      formData.append("files", null);
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }


    let apiMisEndpoints = apiCoreEndpointE + "/createTrip";
    // // let apiMisEndpoints = "http://192.168.3.78:9092/attachment/actions/upload";
    return _http.post(
      apiMisEndpoints,
      formData,
      _http.headerJWT()
    );
  }catch{
    return undefined;
  }
}


export function getPackingMaterialAndProdByJobId(blockData, ticketData) {

  try {
    let params = {

      jobId: blockData.ticketBlockId,
      // flowId: ticketData.flowId

    };

    const url = `${baseURLInventory}/getPackingMaterialAndProdByJobId`;

    return _http.put(
      url,
      params,
      _http.headerJWT()
    );

  }
  catch {
    return undefined;
  }
}
export function getPackingMaterialAndProdByJobIdAdvExc(blockData, ticketData) {
  // alert("calling")
  try {
    let params = {

      jobId: blockData.ticketBlockId,
      flowId: ticketData.flowId

    };

    const url = `${baseURLInventory}/getPackingMaterialAndProdByJobId`;

    return _http.put(
      url,
      params,
      _http.headerJWT()
    );

  }
  catch {
    return undefined;
  }
}

export function setTicketVehicleInfoUpdate(data) {


  // console.log("data", data)

  try {
    const formData = new FormData();

    if (data.selectedFilesList.length > 0) {


      const files = data.selectedFilesList;

      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i], files[i].name)
      }
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }
    else {
      const files = data.selectedFilesList;
      formData.append("file", null);
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }


    let apiMisEndpoints = apiCoreEndpointE + "/updateTrip";
    return _http.put(
      apiMisEndpoints,
      formData,
      _http.headerJWT()
    );
  }
  catch {
    return undefined;
  }
}


// export function getVehicleDetails(params, pageNo) {
//   try {

//     let payload = { ...params };
//     payload["startDate"] = params["startDate"] + " 00:00:00";
//     payload["endDate"] = params["endDate"] + " 23:59:59";

//     const apiEndpoint = apiMisEndpoint + "/ticket/vehicle/trips/report/" + "10/" + pageNo;

//     // const apiEndpoint = "http://192.168.3.78:9092/ticket/vehicle/trips/report/" + "10/" + pageNo;

//     return _http.put(apiEndpoint, payload, _http.headerJWT());

//   }
//   catch {
//     return undefined;
//   }
// }

export function getVehicleDetails(params, pageNo) {
  // console.log(pageNo)
  try {

    let payload = { ...params };
    payload["startDate"] = params["startDate"] + " 00:00:00";
    payload["endDate"] = params["endDate"] + " 23:59:59";

    const apiEndpoint = apiCoreEndpointE + "/getVehicleTripReports/"+"10/"+ pageNo;
    // console.log("hhhhhhhhhhhhhhhhhhhh",apiEndpoint)

    // const apiEndpoint = "http://192.168.3.78:9092/ticket/vehicle/trips/report/" + "10/" + pageNo;

    return _http.put(apiEndpoint, payload, _http.headerJWT());
  }
  catch{
    return undefined;
  }
}


export function verifyingPackingMaterialAndProduct(params) {

  try {
    // alert("calling API Services--------" + JSON.stringify(params));

    // const url = "http://192.168.3.60:1337/inventory/verifyingPackingMaterialAndProduct"
    const url = `${baseURLInventory}/verifyingPackingMaterialAndProduct`;
    return _http.put(
      url,
      params,
      _http.headerJWT()
    );

  }
  catch {
    return undefined;
  }
}




export function setTicketVehicleInfoUpdateStart(data) {


  // console.log("data", data)

  try {
    const formData = new FormData();

    if (data.selectedFilesList.length > 0) {

      const files = data.selectedFilesList;

      for (let i = 0; i < files.length; i++) {
        // console.log("file", files[i].name)
        formData.append("file", files[i], files[i].name)
      }
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }
    else {
      const files = data.selectedFilesList;
      formData.append("file", null);
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }


    let apiMisEndpoints = apiCoreEndpointE + "/updateTripStart";
    return _http.put(
      apiMisEndpoints,
      formData,
      _http.headerJWT()
    );
  }
  catch{
    return undefined}
  }
export function inwardInventoryForNegativeCases(params) {

  try {
    // alert("calling API Services--------" + JSON.stringify(params));

    // const url = "http://192.168.3.60:1337/inventory/verifyingPackingMaterialAndProduct"
    const url = `${baseURLInventory}/inwardInventoryForNegativeCases`;
    return _http.put(
      url,
      params,
      _http.headerJWT()
    );

  }
  catch {
    return undefined;
  }
}
export function deattachingBarcodeFromItem(params) {

  try {
    // alert("calling API Services--------" + JSON.stringify(params));

    // const url = "http://192.168.3.60:1337/inventory/verifyingPackingMaterialAndProduct"
    const url = `${baseURLInventory}/deattachingBarcodeFromItem`;
    return _http.put(
      url,
      params,
      _http.headerJWT()
    );

  }
  catch {
    return undefined;
  }
}



export function deattachingSlNoInwardingItem(params) {

  try {
    // alert("calling API Services--------" + JSON.stringify(params));

    // const url = "http://192.168.3.60:1337/inventory/verifyingPackingMaterialAndProduct"
    const url = `${baseURLInventory}/deattachingSlNoInwardingItem`;
    return _http.put(
      url,
      params,
      _http.headerJWT()
    );

  }
  catch {
    return undefined;
  }
}
export function updatePackingMaterialAndProductLost(params) {

  try {
    // alert("calling API Services--------" + JSON.stringify(params));

    // const url = "http://192.168.3.60:1337/inventory/verifyingPackingMaterialAndProduct"
    const url = `${baseURLInventory}/updateItemLostStatus`;
    return _http.put(
      url,
      params,
      _http.headerJWT()
    );

  }
  catch {
    return undefined;
  }
}

export function getTripDetails(tripId) {
  try {
    let apiMisEndpointHub = `${apiCoreEndpointE}/getTicketDetailsByTripId/` + tripId;
    // console.log({apiMisEndpointHub});
    return _http.get(apiMisEndpointHub, _http.headerJWT());
  }
  catch {
    return undefined;
  }
}
export function updateTripSequence(params) {
  try {
    let apiMisEndpointHub = `${apiCoreEndpointE}/updateTripSequence`;
    return _http.put(apiMisEndpointHub, params, _http.headerJWT());
  }
  catch {
    return undefined;
  }
}

export function updateTripClosure(data) {


  // console.log("data", data)

  try {
    const formData = new FormData();

    if (data.selectedFilesList.length > 0) {
      const file = data.selectedFilesList;

      for (let i = 0; i < file.length; i++) {
        // console.log("file", file[i].name)
        formData.append("file", file[i], file[i].name)
      }
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }
    else {
      const file = data.selectedFilesList;
      formData.append("file", null);
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }
    let apiMisEndpoints = `${apiCoreEndpointE}/updateTripClose`;
    return _http.put(
      apiMisEndpoints,
      formData,
      _http.headerJWT()
    );
  }
  catch {
    return undefined
  }
}
export function getPhotoCapturedData(params) {

  try {
    // alert("calling API Services--------" + JSON.stringify(params));

    // const url = "http://192.168.3.60:1337/inventory/verifyingPackingMaterialAndProduct"
    const url = `${baseURLInventory}/getPhotoCapturedData`;
    return _http.put(
      url,
      params,
      _http.headerJWT()
    );

  }
  catch {
    return undefined;
  }
}

export function loadTickets(playerId) {
  let apiCoreEndpoint1 = apiCoreEndpoint + "/ticketDetails/get-tickets/by-player/" + playerId;
  return _http.get(apiCoreEndpoint1, _http.headerJWT());
}


// const url = `${baseURLInventory}/inventory/getPackingMaterialAndProdByJobId`;

// return _http.post(url, formData, _http.headerJWT());
