import React from "react";
import $ from "jquery";
import FormLib from "../../../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../../../services/_toastService";
import {
  getDocuments,
  getSaveFile,
} from "../../../../../services/requestV1/requestServices";
import { getLinehaulTicktes } from "../../../../../services/linehaul/linehaulServices";
import TicketAdditionalFiles from "./TicketAdditionalFiles";

class DocumentDetails extends FormLib {
  state = {
    data: {
      description: "",
    },
    errors: {},
    currentRequest: {},
    user: {},
    selectedFile: null,
    ticketDetails: [],
  };

  schema = {
    description: Joi.string().required().label("Description "),
  };

  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  componentDidMount() {
    // this.loadData(this.props.data);
  }

  // loadData = async (r) => {
  //   try {
  //     this.setState({ currentRequest: this.props.data });

  //     let res;
  //     if (r["linehaulSeq"]) {
  //       res = await getLinehaulTicktes(r["linehaulSeq"]);
  //     }
  //     this.setState({ ticketDetails: res.data?.data });

  //     // console.log(JSON.stringify(res.data.data));
  //   } catch {
  //     console.log("DocumentsDetails>>>>>>>>>>>>loadData");
  //   }
  // };

  doSubmit = async () => {
    try {
      let res;
      if (this.state.selectedFile == null) {
        toast.toastError("File should not be null");
      } else {
        if (this.state.btnValue === "upload") {
          let dataParam = {
            REQUEST_ID: this.state.currentRequest.REQUEST_ID,
            INFO: this.state.data.description,
          };
          res = await getSaveFile(dataParam, this.state.selectedFile);
        }
        if (res.data.success) {
          this.resetData();
          this.loadData(this.state.currentRequest);
          toast.toastSuccess(res.data.msg);
        } else {
          toast.toastError(res.data.msg);
        }
      }
    } catch {
      console.log("Linehaul Actions >>>>>>>>>>>> doSubmit");
    }
  };

  resetData = () => {
    this.setState({ data: { description: "" } });
    this.setState({ selectedFile: null });
  };

  render() {
    const user = this.props.user;
    const currentRequest = this.props.currentRequest;
    const fileStyle = {
      width: "130px",
      height: "130px",
      float: "left",
      cursor: "pointer",
      border: "1px solid #d3c4c4",
      "border-radius": "6px",
    };
    const imgStyle = {
      border: "1px solid #d3c4c4",
      width: "130px",
      height: "130px",
      "line-height": "130px",
      float: "left",
      cursor: "pointer",
      "font-size": "48px",
      color: "#d7caca",
      "background-color": "#f9f1f1",
      "border-radius": "6px",
    };
    let ticket = this.props.data.multiTicketIds;
    let ticketData = ticket.split(",");
    // this.setState({ ticketData });

    const listTicketModified = ticketData.map((ticket) => (
      <p key={ticket}>
        <span className="bg-success text-white">{ticket}</span>
      </p>
    ));

    return (
      <React.Fragment>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.props.closeModal}
        >
          <span aria-hidden="true"> Ticket Details &times;</span>
        </button>
        <div className="card">
          <div className="card-header bg-success text-white ">
            <div className="row">
              <div className="col-8"></div>
              <div className="col-4 text-right"></div>
            </div>
            <div className="row">
              <div className="col-8">{"Ticket Details"}</div>
              <div className="col-4 text-right"></div>
            </div>
          </div>

          <div className="card-body mt-0 ">
            <div className="row">
              <div className="col">
                <div
                  className="container   overflow-auto"
                  style={{ height: "5rem" }}
                >
                  {ticketData?.map((ticket) => (
                    <p key={ticket} className="p-0 m-0">
                      <span
                        className="bg-success text-white px-1 rounded"
                        style={{ fontSize: "0.6rem" }}
                      >{` ${ticket} `}</span>
                    </p>
                  ))}
                </div>

                {/* <form className="mt-5">
                  <div className="form-row">
                    {this.rTA("Description", "description", "col-12")}
                    <input
                      type="file"
                      accept=".xlsx, .docx, .txt, .pdf, .jpg, .jpeg, .png, .gif "
                      className="btn btn-primary btn-sm"
                      title="file upload"
                      onChange={this.onFileChange}
                      // key={this.state.selectedFile || ""}
                    />
                  </div>
                </form>
                {this.rBtnSP("Upload", "upload")}
                {currentRequest} */}
              </div>
            </div>
            <hr />
            <div className="col-md-12">
              <TicketAdditionalFiles
                data={this.props.data}
                curTicket={this.state.currentRequest.ticketNo}
                curProcessId={this.state.currentRequest.processId}
                key={this.state.currentRequest.ticketNo + 1}
              />
            </div>
            {/* <div className="row">
              <div
                className="col-12 page-todo overflow-auto"
                style={{ height: "20rem" }}
              >
                <div className="tasks">
                  {this.state.photoDetails.map((r) => {
                    return (
                      <div className="task" key={r.S3_FILE_ID}>
                        <div className="desc mb-0 pb-0">
                          <p>
                            {r.FILE_TYPE == "xlsx" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_self"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-excel-o mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  ></i>
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "docx" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_self"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-word-o mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  ></i>
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "txt" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-text mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  ></i>
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "pdf" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <i
                                    className="fa fa-file-pdf-o mr-3 mb-1 text-center"
                                    style={imgStyle}
                                  />
                                </a>
                              </>
                            )}

                            {r.FILE_TYPE == "jpg" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.FILE_PATH}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "jpeg" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.FILE_PATH}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "png" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.FILE_PATH}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            {r.FILE_TYPE == "gif" && (
                              <>
                                <a
                                  href={r.FILE_PATH}
                                  target="_blank"
                                  title="Click to View"
                                >
                                  <img
                                    src={r.FILE_PATH}
                                    alt="Pineapple"
                                    className="mr-3 mb-1"
                                    style={fileStyle}
                                  />
                                </a>
                              </>
                            )}
                            <div>
                              <div>
                                <h5>
                                  <span> Upload By </span>
                                  <text>{r["MULTI_REF_IDS"]}</text>
                                  <span className="ml-2">
                                    {" "}
                                    : &nbsp;{r.CREATED_BY}
                                  </span>
                                </h5>
                              </div>
                              <div>
                                <h5>
                                  <span> Info </span>
                                  <span className="ml-5">
                                    {" "}
                                    : &nbsp;{r.INFO}
                                  </span>
                                </h5>
                              </div>
                              <div>
                                <h5>
                                  <span> File Type </span>
                                  <span className="ml-3">
                                    {" "}
                                    : &nbsp;{r.FILE_TYPE}
                                  </span>
                                </h5>
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default DocumentDetails;
