import React from "react";
import FormLib from "../../../_common/FormLib";
import Table from "../../../_common/Table";
import { toast } from "react-toastify";
import { loadTickets } from "../../../../services/Tickets/TicketProcessServices";

class FeTicketDetails extends FormLib {
  state = {
    data: {
      description: "",
    },
    errors: {},
    ticketDetails: [],
  };

  componentDidMount() {
    this.loadTicketDetails();
  }

  async loadTicketDetails() {
    try {
      let resp = await loadTickets(this.props.FeDetails)
      if(resp.data.success === true){
          this.setState({ticketDetails:resp.data.data.tickets})
      }
    } catch (error) {}
  }

  render() {
    console.log(this.state.ticketDetails);
    return (
      <React.Fragment>
        <div className="card w-150">
          <div className="card-header bg-success text-white">
            <div className="row">
              <div className="col">
                FE Ticket List [{this.props.userName}]
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                  style={{
                    display: "inline",
                    float: "right",
                    marginTop: "-0.4rem",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>

          {this.state.ticketDetails.map((i, index) => (
            <div className="card mb-2 mt-2 cursorPointor ">
              <div
                className="card-header page-todo task"
                style={{ backgroundColor: "white" }}
              >
                <div>{i.ticketNo}</div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default FeTicketDetails;
