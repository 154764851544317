import _http from "../_httpService";

import config from "../../config.json";

const baseURL = config[config.build].apiURLCore;
function api(path, subURL = "") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getQueAns(type,prod,flow) {
  return _http.get(api(type+"/"+prod+"/"+flow), _http.headerJWT());
}


const qa = {
    "success": true,
    "statusCode": 200,
    "msg": "Successfully Rtrieved",
    "data": {
      "faq": [
        {
          "questionId": "01",
          "question": "Is Mobile working?",
          "elementType": "Options",
          "flowId": "active",
          "productId": "active",
          "options": "A=>Yes, B=>No",
          "status": "active",
          "respPositive": "active",
          "respNagative": "active",
          "respNatural": "active",
          "sync": "active",
          "remarks": "active"
        },
        {
          "questionId": "02",
          "question": "Does the device power on ?",
          "elementType": "Options",
          "flowId": "active",
          "productId": "active",
          "options": "A=>Yes, B=>No",
          "status": "active",
          "respPositive": "active",
          "respNagative": "active",
          "respNatural": "active",
          "sync": "active",
          "remarks": "active"
        },
        {
          "questionId": "03",
          "question": "IMEI",
          "elementType": "Options",
          "flowId": "active",
          "productId": "active",
          "options": "active",
          "status": "active",
          "respPositive": "active",
          "respNagative": "active",
          "respNatural": "active",
          "sync": "active",
          "remarks": "active"
        },
        {
          "questionId": "04",
          "question": "Check display glass",
          "elementType": "Options",
          "flowId": "active",
          "productId": "active",
          "options": "A=>Glass OK, B=>Glass Cracked",
          "status": "active",
          "respPositive": "active",
          "respNagative": "active",
          "respNatural": "active",
          "sync": "active",
          "remarks": "active"
        },
        {
          "questionId": "05",
          "question": "Front Camera Condition",
          "elementType": "Options",
          "flowId": "active",
          "productId": "active",
          "options": "A=>Yes, B=>No",
          "status": "active",
          "respPositive": "active",
          "respNagative": "active",
          "respNatural": "active",
          "sync": "active",
          "remarks": "active"
        },
        {
          "questionId": "06",
          "question": "Back Camera working",
          "elementType": "Options",
          "flowId": "active",
          "productId": "active",
          "options": "A=>Working, B=>Not Working, C=>Blurred, D=>NA",
          "status": "active",
          "respPositive": "active",
          "respNagative": "active",
          "respNatural": "active",
          "sync": "active",
          "remarks": "active"
        },
        {
          "questionId": "07",
          "question": "Device Physical Condition",
          "elementType": "Options",
          "flowId": "active",
          "productId": "active",
          "options": "A=>Yes, B=>No",
          "status": "active",
          "respPositive": "active",
          "respNagative": "active",
          "respNatural": "active",
          "sync": "active",
          "remarks": "active"
        },
        {
          "questionId": "08",
          "question": "Original earphone available",
          "elementType": "Options",
          "flowId": "active",
          "productId": "active",
          "options": "A=>Yes, B=>No",
          "status": "active",
          "respPositive": "active",
          "respNagative": "active",
          "respNatural": "active",
          "sync": "active",
          "remarks": "active"
        },
        {
          "questionId": "09",
          "question": "Phone reset done?",
          "elementType": "Options",
          "flowId": "active",
          "productId": "active",
          "options": "A=>Yes, B=>No",
          "status": "active",
          "respPositive": "active",
          "respNagative": "active",
          "respNatural": "active",
          "sync": "active",
          "remarks": "active"
        },
        {
          "questionId": "10",
          "question": "Check Battery working?",
          "elementType": "Options",
          "flowId": "active",
          "productId": "active",
          "options": "A=>Present, B=>Not Present",
          "status": "active",
          "respPositive": "active",
          "respNagative": "active",
          "respNatural": "active",
          "sync": "active",
          "remarks": "active"
        },
        {
          "questionId": "12",
          "question": "Check fingerprint sensor?",
          "elementType": "Options",
          "flowId": "active",
          "productId": "active",
          "options": " A=>Working, B=>Not Working",
          "status": "active",
          "respPositive": "active",
          "respNagative": "active",
          "respNatural": "active",
          "sync": "active",
          "remarks": "active"
        }
      ],
      "totalRecord": 12
    }
  };


export function getQAList() {
    // return _http.get(api(""),_http.headerJWT());
    return {data:qa};
}



const open = {
  "success": true,
  "statusCode": 200,
  "msg": "Successfully Retrieved",
  "data": {
    "open": [
      {
        "questionId": "01",
        "question": "Operation Team",
        "elementType": "Ticket No: OP001",
        "flowId": "active",
        "productId": "active",
        "options": "Active",
        "status": "active",
        "respPositive": "active",
        "respNagative": "active",
        "respNatural": "active",
        "sync": "active",
        "remarks": "active"
      },
      {
        "questionId": "02",
        "question": "IT Team",
        "elementType": "Ticket No: IT001",
        "flowId": "active",
        "productId": "active",
        "options": "Active",
        "status": "active",
        "respPositive": "active",
        "respNagative": "active",
        "respNatural": "active",
        "sync": "active",
        "remarks": "active"
      },
      {
        "questionId": "03",
        "question": "Business Team",
        "elementType": "Ticket No: BT001",
        "flowId": "active",
        "productId": "active",
        "options": "Active",
        "status": "active",
        "respPositive": "active",
        "respNagative": "active",
        "respNatural": "active",
        "sync": "active",
        "remarks": "active"
      },
      {
        "questionId": "04",
        "question": "Operation Team",
        "elementType": "Ticket No: OP004",
        "flowId": "active",
        "productId": "active",
        "options": "Active",
        "status": "active",
        "respPositive": "active",
        "respNagative": "active",
        "respNatural": "active",
        "sync": "active",
        "remarks": "active"
      },
      {
        "questionId": "05",
        "question": "Operation Team",
        "elementType": "Ticket No: OP004",
        "flowId": "active",
        "productId": "active",
        "options": "Active",
        "status": "active",
        "respPositive": "active",
        "respNagative": "active",
        "respNatural": "active",
        "sync": "active",
        "remarks": "active"
      },
    ],
    "totalRecord": 5
  }
};


const closed = {
  "success": true,
  "statusCode": 200,
  "msg": "Successfully Retrieved",
  "data": {
    "closed": [
      {
        "questionId": "01",
        "question": "Accounts Team",
        "elementType": "Ticket No: AT001",
        "flowId": "active",
        "productId": "active",
        "options": "Active",
        "status": "active",
        "respPositive": "active",
        "respNagative": "active",
        "respNatural": "active",
        "sync": "active",
        "remarks": "active"
      },
      {
        "questionId": "02",
        "question": "HR Team",
        "elementType": "Ticket No: HR002",
        "flowId": "active",
        "productId": "active",
        "options": "Active",
        "status": "active",
        "respPositive": "active",
        "respNagative": "active",
        "respNatural": "active",
        "sync": "active",
        "remarks": "active"
      },
      {
        "questionId": "03",
        "question": "IT Team",
        "elementType": "Ticket No: IT003",
        "flowId": "active",
        "productId": "active",
        "options": "Active",
        "status": "active",
        "respPositive": "active",
        "respNagative": "active",
        "respNatural": "active",
        "sync": "active",
        "remarks": "active"
      },
      {
        "questionId": "04",
        "question": "Project Team",
        "elementType": "Ticket No: PT004",
        "flowId": "active",
        "productId": "active",
        "options": "Active",
        "status": "active",
        "respPositive": "active",
        "respNagative": "active",
        "respNatural": "active",
        "sync": "active",
        "remarks": "active"
      },
      {
        "questionId": "05",
        "question": "Operation Team",
        "elementType": "Ticket No: OP004",
        "flowId": "active",
        "productId": "active",
        "options": "Active",
        "status": "active",
        "respPositive": "active",
        "respNagative": "active",
        "respNatural": "active",
        "sync": "active",
        "remarks": "active"
      },
    ],
    "totalRecord": 5
  }
};



export function getOpenList() {
  // return _http.get(api(""),_http.headerJWT());
  return {data:open};
}


export function getClosedList() {
// return _http.get(api(""),_http.headerJWT());
return {data:closed};
}

















//QA-service

