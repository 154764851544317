import React from "react";
import FormLib from "../../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../../../src/services/_toastService";
import Table from "../../../_common/Table"; 
import { getLinehaulProducts,  getLinehaulProductsV1,  updateReceiveProdStatus, verifyProduct } from "../../../../services/linehaul/linehaulServices";
import Pagination from "../../../_common/Pagination";
import { epoch2ddmmyyyyhhmmss } from "../../../../utils/DateUtils";
import { IstToUTC } from "../../../../utils/DateUtils";

class LinehaulProdDetails extends FormLib{
  state = {
      errors: {},
      currentRequest: {},
      responce:[],
      currentShipment: {},
      barcodeValue:"",
      pageLinks: {
        pageNo: 1,
        perPage: 0,
        itemsCount: 0,
        lastPage: 0,
      },
    };


    componentDidMount(){
     this.loadProductData(this.state.pageLinks.pageNo);
    }

    loadProductData = async(pageNo = this.state.pageLinks.pageNo)=>{
     const lineSeqId = this.props.data
    //  console.log("lineseqId",this.props.data)
       try {
        const resp = await getLinehaulProductsV1(lineSeqId, pageNo)
        if(resp.data.success === true){
          this.setState({responce:resp.data.data.linehaulTicketDetails})
          resp.data.data.pageLinks &&
          this.setState({ pageLinks: resp.data.data.pageLinks });
          this.props.requestStatusChange()
        
          // if( resp.data.data.linehaulTicketDetails.some((v) => v === "lineHaulProdCreated") === false){
          //   this.props.requestStatusChange();
          //   this.props.closeModal();
          // }     
          
          if(resp.data.data.linehaulTicketDetails){
            const result = resp.data.data.linehaulTicketDetails.every(
              (ticket) => ticket.linehaulProductStatus === 'lineHaulProdReceived'
            );
            if(result){
              this.props.closeModal();
            }
          }
        }else{
          toast.toastError(resp.data.msg);
        }
       } catch (error) {
        
       }
    }

//     handleSaveProdBarcodeNo = async(e) =>{
//       // alert(e.key)
//       if (e.key === "Enter") {
//         e.preventDefault()
//         const ProdBarcodeNo = e.target.value;
//       console.log("ProdBarcodeNo", ProdBarcodeNo);
//       console.log("curShipment",this.state.responce);
//       const result = this.state.responce.filter(item => item.prdBarcode === ProdBarcodeNo);

//       console.log("found",result)
// //         try {
// //           const productBarcode = this.state.currentShipment.prdBarcode;
// if(e.target.value === result[0].prdBarcode){
// this.setState({barcodeValue:""})
// if(this.props.boxBarcode !== null){

//         let params = {
//           boxBarcodeId:result[0].boxBarcodeId,
//           boxBarcode:this.props.boxBarcode,
//           productBarcodeId:result[0].prdBarcodeId,
//           productBarcode:result[0].prdBarcode,
//           boxId:result[0].boxId,
//           lineHaulId:result[0].ticketLinehaulId,
//           boxStatus:result[0].boxStatus,
//           productStatus:"lineHaulProdVerified",
//           ticketBlockId:result[0].ticketBlockId,
//           productId:result[0].ticketProductId,
//           srcHubId:result[0].srcHubId,
//           dstHubId:result[0].dstHubId,
//           processAt:IstToUTC(new Date()).slice(0, 19).replace(" ", "T")
//         }
//         console.log("params", params)

//         const resp = await verifyProduct(params)
//         if ( resp.data.success === true) {
//           this.onBlockSelection(result)
//           toast.toastSuccess(() => <div>{resp.data.msg}</div>);
//         }else{        
//           toast.toastError(() => <div>{resp.data.msg}</div>);
//           return false;
//         }
//       }else{
        
//         let params = {
//           boxBarcodeId:result[0].boxBarcodeId,
//           boxBarcode:"",
//           productBarcodeId:result[0].prdBarcodeId,
//           productBarcode:result[0].prdBarcode,
//           boxId:result[0].boxId,
//           lineHaulId:result[0].ticketLinehaulId,
//           boxStatus:result[0].boxStatus,
//           productStatus:"lineHaulProdVerified",
//           ticketBlockId:result[0].ticketBlockId,
//           productId:result[0].ticketProductId,
//           srcHubId:result[0].srcHubId,
//           dstHubId:result[0].dstHubId,
//           processAt:IstToUTC(new Date()).slice(0, 19).replace(" ", "T")
//         }
//         console.log("params", params)

//         const resp = await verifyProduct(params)
//         if ( resp.data.success === true) {
//           toast.toastSuccess(() => <div>{resp.data.msg}</div>);
//           this.onBlockSelection(result)
//         }else{        
//           toast.toastError(() => <div>{resp.data.msg}</div>);
//           return false;
//         }
//       }
//       }else{
// //         // alert("call")

//       toast.toastError("Barcode not matching");
//        }
        
// //         } catch (error) {
        
// //         }
//       }
//     }


handleSaveProdBarcodeNo = async(e) =>{
  // alert(e.key)
  if (e.key === "Enter") {
    e.preventDefault()
    const ProdBarcodeNo = e.target.value;
  const result = this.state.responce.filter(item => item.prdBarcode === ProdBarcodeNo);

  // console.log("found",result.length)
//         try {
//           const productBarcode = this.state.currentShipment.prdBarcode;
if(result.length === 0){
toast.toastError("Barcode not matching");
}else if(e.target.value === result[0].prdBarcode){
this.setState({barcodeValue:""})
if(this.props.boxBarcode !== null){

    let params = {
      boxBarcodeId:result[0].boxBarcodeId,
      boxBarcode:this.props.boxBarcode,
      productBarcodeId:result[0].prdBarcodeId,
      productBarcode:result[0].prdBarcode,
      boxId:result[0].boxId,
      lineHaulId:result[0].ticketLinehaulId,
      boxStatus:result[0].boxStatus,
      productStatus:"lineHaulProdVerified",
      ticketBlockId:result[0].ticketBlockId,
      productId:result[0].ticketProductId,
      srcHubId:result[0].srcHubId,
      dstHubId:result[0].dstHubId,
      processAt:IstToUTC(new Date()).slice(0, 19).replace(" ", "T")
    }
    // console.log("params", params)

    const resp = await verifyProduct(params)
    if ( resp.data.success === true) {
      this.onBlockSelection(result)
      toast.toastSuccess(() => <div>{resp.data.msg}</div>);
    }else{        
      toast.toastError(() => <div>{resp.data.msg}</div>);
      return false;
    }
  }else{
    
    let params = {
      boxBarcodeId:result[0].boxBarcodeId,
      boxBarcode:"",
      productBarcodeId:result[0].prdBarcodeId,
      productBarcode:result[0].prdBarcode,
      boxId:result[0].boxId,
      lineHaulId:result[0].ticketLinehaulId,
      boxStatus:result[0].boxStatus,
      productStatus:"lineHaulProdVerified",
      ticketBlockId:result[0].ticketBlockId,
      productId:result[0].ticketProductId,
      srcHubId:result[0].srcHubId,
      dstHubId:result[0].dstHubId,
      processAt:IstToUTC(new Date()).slice(0, 19).replace(" ", "T")
    }
    // console.log("params", params)

    const resp = await verifyProduct(params)
    if ( resp.data.success === true) {
      toast.toastSuccess(() => <div>{resp.data.msg}</div>);
      this.onBlockSelection(result)
    }else{        
      toast.toastError(() => <div>{resp.data.msg}</div>);
      return false;
    }
  }
  }else{
  // alert("call")

  toast.toastError("Barcode not matching");
   }
    
//         } catch (error) {
    
//         }
  }
}

    handlePageChange = (pageNo) => {
      // this.props.history.push("/ticketProcess/"+ pageNo);
      this.getLinehaulList(pageNo);
    };

    tableMeta = {
      key: "shipmentNo",
      columns: [
        { path: "ticketNo", label: "ticket No" },
       
        { path: "prdName", label: "Produc tName" },
        { path: "prdCode", label: "Product Code"},
        { path: "srcLocation", label: "Source Location" },
      
        { path: "dstType", label: "Destination Type" },
        {
          key: "shipmentNo",
          actionKey: "sh",
          action: (r) => (
            <>
            {/* {(r.prdBarcode && r.linehaulProductStatus === "lineHaulProdManifested") ?(
                <>
                  <input
                    type="text"

                    placeholder="Scan Barcode"
                    className="shipmentNoInputManifest"
                    onFocus={() => {
                      // console.log({focusOn: r.linehaulSeq})
                      this.setState({ currentShipment: r });
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        if (!e.target.value) return;
                        this.handleSaveProdBarcodeNo(e.target.value);
                      }
                    }}
                  />
                </>
                ):(
                  <div>
                    {r.prdBarcode && r.linehaulProductStatus === "lineHaulProdVerified" &&(
                       <>
                       <button
                         className="btn  btn-success  btn-sm link-over"
                         onClick={() =>
                           this.onBlockSelection(r, "05RECEIVED")
                         }
                       >
                         Received
                       </button>
                       <button
                         className="btn  btn-danger  btn-sm link-over"
                         onClick={() => this.onBlockSelection(r, "10DAMAGE")}
                       >
                         Damaged
                       </button>
                       <button
                         className="btn  btn-warning  btn-sm link-over"
                         onClick={() =>
                           this.onBlockSelection(r, "15MISSING")
                         }
                       >
                         Missing
                       </button>
                     </>
                    )}
                  </div>
                )} */}
                {r.linehaulProductStatus === "lineHaulProdReceived" &&(
                  <div style={{color:"green", fontSize:15, fontWeight:"bold"}}>Received</div>
                )}
              
                  
  
                
            </>
          ),
          cusClass: " ",
          icon: () => <i className="fa fa-cogs" aria-hidden="true"></i>,
        },
      ],
    };

    async onBlockSelection(r){
        let params = {
          productBarcodeId:r[0].prdBarcodeId,
          productBarcode:r[0].prdBarcode,
          boxId:r[0].boxId,
          lineHaulId:r[0].ticketLinehaulId,
          boxStatus:r[0].boxStatus,
          productStatus:"lineHaulProdReceived",
          lineHaulStatus:"05RECEIVED",
          ticketBlockId:r[0].ticketBlockId,
          productId:r[0].ticketProductId,
          srcHubId:r[0].srcHubId,
          dstHubId:r[0].dstHubId,
          processAt:IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          direction:this.props.direction,
          dropTime:IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          dropDate:IstToUTC(new Date()).slice(0, 10)
        }

        // console.log("params",params)

        const resp = await updateReceiveProdStatus(params)
        if(resp.data.success){
          toast.toastSuccess(resp.data.msg)
          this.loadProductData(this.state.pageLinks.pageNo)
        }else{
          toast.toastError(resp.data.msg)
        }
    }

    handleChange = async (e) => {
      this.setState({
        barcodeValue: e.target.value
      })
  }

    render(){
  const { pageNo, perPage, itemsCount } = this.state.pageLinks;
  // console.log("length",this.props.direction)
        
      return(
          <React.Fragment>
            <div style={{alignItems:"center", marginTop:190, marginRight:19,width:"100%"}}>
              <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={this.props.closeModal}
      >
        <span aria-hidden="true"> Product Details &times;</span>
      </button>
      <div className="card">
      <div className="card-header bg-success text-white ">
          <div className="row">
            <div className="col-8"></div>
            <div className="col-4 text-right"></div>
          </div>
          <div className="row">
            <div className="col-8">{"Product Details"}</div>
            <div className="col-4 text-right"></div>
            
          </div>
          
        </div>
        <div style={{marginTop:20,marginLeft:20}}>
                  <input
                    type="text"

                    placeholder="Scan Barcode"
                    className="shipmentNoInputManifest"
                    value={this.state.barcodeValue}
                    onChange={(e) =>this.handleChange(e)}
                    // onFocus={() => {
                    //   // console.log({focusOn: r.linehaulSeq})
                    //   this.setState({ currentShipment: r });
                    // }}
                    onKeyDown={(e) => {
                     
                        this.handleSaveProdBarcodeNo(e);
                      
                    }}
                  />
                </div>

        <div className="card-body mt-0">
        <div className="row" style={{height:150, overflow:"scroll"}}>
        <Table
              clsName="table table-striped table-bordered table-hover"
              tableMeta={this.tableMeta}
              data={this.state.responce}
              dropMenu={[]}
            />
             {/* {this.state.responce.length !== 0 && (
              <Pagination
                pageNo={pageNo}
                perPage={perPage}
                itemsCount={itemsCount}
                onPageChange={this.handlePageChange}
              />
            )} */}
            </div>
            </div>
      </div>
      </div>

          </React.Fragment>
      )
    }
  

}


export default LinehaulProdDetails;