import React from "react";
import FormLib from "./../../_common/FormLib";

class PrvGroups extends FormLib {
  isCur(id, cur) {
    if (id === cur) {
      return " curTasks ";
    }
    return " ";
  }

  render() {
    const prvGroups = this.props.prvGroups;
    const curModule = this.props.curModule;
    const curGroup = this.props.curGroup;
    return (
      <div className="card  ">
        <h5 className="card-header">
          Prv Groups
          {/* {JSON.stringify(curGroup)} */}
          {curModule.moduleId && !curGroup.prvGroupId && (
            <button
              type="button"
              className="btn btn-sm btn-primary pull-right"
              data-toggle="modal"
              data-target="#myGroupModel"
            >
              <i className={"fa  fa-plus  "}></i>
            </button>
          )}
          {curGroup.prvGroupId && curGroup.prvGroupId !== "" && (
            <>
              <button
                type="button"
                className="btn btn-sm btn-warning pull-right"
                onClick={() => this.props.onGroupUnload()}
              >
                <i className={"fa  fa-unlink  "}></i>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-success pull-right"
                data-toggle="modal"
                data-target="#myGroupModel"
              >
                <i className={"fa  fa-pencil  "}></i>
              </button>
            </>
          )}
        </h5>

        <div className="card-body">
          <div className="page-todo">
            <div className=" tasks">
              {prvGroups.map((pg) => (
                <div
                  className={
                    "task nil " +
                    this.isCur(pg.prvGroupId, curGroup.prvGroupId)
                  }
                  key={pg.prvGroupId}
                >
                  <div className="desc">
                    <span
                      className="span-click"
                      onClick={() => this.props.onGroupChange(pg)}
                    >
                      <div className="title">{pg.groupName}</div>
                    </span>
                    
                  </div>
                  <div className="pull-right">
                    <div className="menu">
                      <span
                        className={
                          "badge   badge-success pull-left " +
                          "badge-" +
                          pg.status
                        }
                      >
                        {pg.sort + " : " + pg.status}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrvGroups;
