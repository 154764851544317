import _http from "../_httpService";

import config from "../../config.json";


const baseURL = config[config.build].apiURLCore;
function api(path, subURL = "/tracker/settings/products-category-sub") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

function apiGet(path, subURL = "/tracker/settings/products") {
    path = path !== "" ? "/" + path : "";
    return `${baseURL}${subURL}${path}`;
}

export function getAllSubProducts(prodSubCatId,pageNo) {
    let URL = "all/"+prodSubCatId+"/10/"+pageNo;
    return _http.get(apiGet(URL),_http.headerJWT());
}

export function getProductsSub(prodCatId,pageNo) {
    let URL = "all/"+prodCatId+"/10/"+pageNo;
    return _http.get(api(URL), _http.headerJWT());
}


export function createProductSubCat(req) {
    const param = { ...req }
    delete param.prodSubCatId;
    return _http.post(api(""), param, _http.headerJWT());
}

export function updateProductSubCat(req) {
    const param = { ...req }
    return _http.put(api(""+req.prodSubCatId), param, _http.headerJWT());
}

export function deleteProductSubCat(req) {

    return _http.delete(api(""+req.prodSubCatId), _http.headerJWT());
}

