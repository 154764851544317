import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import { updateDocket } from "../../../services/Tickets/TicketServices";
import { IstToUTC } from "../../../utils/DateUtils";

class MISDocketList extends FormLib {
  state = {
    data: {
      syncId: "",
      ticketNo: "",
      processId: "",
      ticketBlockId: "",
      docketNo: "",
      partAdd1: "",
      partAdd2: "",
      actionCode: "wPartReceived",
      action: "Missing Part Received",
      isHistoryRecord: "",
      isHistoryShown: "",
      isStrictLevel: "",
      syncStatus: "SYNC",
      // MIS_STATUS: "",
      resultSet: {
        partAdd2: "",
        docketNo: "",
        actionStatus: "PART_RECEIVED",
      },
    },
    output: {},

    errors: {},
  };

  schema = {
    syncId: Joi.string().optional().allow("").label("syncId*"),
    ticketNo: Joi.string().optional().label("ticketNo*"),
    processId: Joi.string().required().label("Project*"),
    ticketBlockId: Joi.string().optional().label("ticketBlockId*"),
    partAdd1: Joi.string().required().label("partAdd1*"),
    partAdd2: Joi.string().optional().label("partAdd2*"),
    docketNo: Joi.string().required().label("docketNo*"),
    actionCode: Joi.string().optional().label("actionCode*"),
    action: Joi.string().optional().label("action*"),
    isHistoryRecord: Joi.string()
      .optional()
      .allow("")
      .label("isHistoryRecord*"),
    isHistoryShown: Joi.string().optional().allow("").label("isHistoryShown*"),
    isStrictLevel: Joi.string().optional().allow("").label("isStrictLevel*"),
    syncStatus: Joi.string().optional().allow("").label("syncStatus*"),
    // MIS_STATUS: Joi.string().optional().allow('').label("MIS_STATUS*"),
    resultSet: Joi.optional().label("resultSet*"),
  };

  async doSubmit() {
    let res;
    // console.log("set the data........"+this.state.data.docketNo);
    this.state.data.resultSet = {
      partAdd2: this.state.data.partAdd1,
      docketNo: this.state.data.docketNo,
      actionStatus: "PART_RECEIVED",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    delete this.state.data.docketNo;
    delete this.state.data.partAdd1;
    // console.log("dosubmit..........."+JSON.stringify(this.state.data));

    res = await updateDocket(this.state.data);

    if (res.data.success) {
      toast.toastSuccess(res.data.msg);
      this.props.handleLoadTickets();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  componentDidMount() {
    this.setCurMisTicket(this.props.curMisTicket);
    this.getlocalDateTime();
  }

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }

  setCurMisTicket(p) {
    const data = {};
    const output = {};
    data.syncId = "";
    data.ticketNo = p.ticketNo;
    data.processId = p.processId;
    data.ticketBlockId = p.ticketBlockId;
    data.docketNo = p.docketNo;
    data.actionCode = "wPartReceived";
    data.action = "Missing Part Received";
    data.isHistoryRecord = "";
    data.isHistoryShown = "";
    data.isStrictLevel = "";
    data.syncStatus = "SYNC";
    // data.MIS_STATUS= "";
    data.resultSet = {
      partAdd2: this.state.data.partAdd1,
      docketNo: p.docketNo,
      actionStatus: "PART_RECEIVED",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    this.setState({
      data,
    });
  }

  resetData() {
    this.setState({
      data: {
        processId: "",
        docketNo: "",
        partAdd1: "",
      },
    });
  }

  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;
    return (
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <form>
              <div className="form-row">
                <span>{this.props.curMisTicket.orderNo}</span> &nbsp; || &nbsp;
                <span>{this.props.curMisTicket.ticketNo}</span>
                <br />
                <br />
                <br />
                {this.rTBH("ProcessId", "processId", "col-2")}
                {this.rTB("PartAdd1", "partAdd1", "col-8")}
                {this.rTB("DocketNo", "docketNo", "col-8")}
              </div>
            </form>
            {this.rBtnSS("Edit", "edit")}
          </div>
        </div>
      </div>
    );
  }
}
export default MISDocketList;
