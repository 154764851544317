export function getBuyBackJSON(data, ticketScope) {
    try {
        const d = new Date().toISOString().slice(0, 10);
        let indentificationNumber = data.identificationNo.toString().replace(/(?:\r\n|\r|\n|\t)/g, '');
        const params = {
            primary: {
                ticketPriority: data.ticketPriority,
                flowId: data.flowId,
                retailerId: data.retailerId,
                retailerNo: data.retailerNo?.toString() || "",
                conComplaintNo: data.conComplaintNo?.toString() || "",
                LRNumber: data.LRNumber?.toString() || "",
                orderNo: data.orderNo?.toString() || "",
                invoiceNo: data.invoiceNo?.toString() || "",
                ticketDetails: data.ticketDetails,
                specialInstructions: data.specialInstructions,
                buyerContactNumber: "9854745862",
                isAppointmentReq: data.isAppointmentReq,
                isPhysicalEval: data.isPhysicalEval,
                isTechEval: data.isTechEval,
                isPackingReq: data.isPackingReq,
                paymentMode: data.paymentMode,
                waitForNotification: data.waitForNotification,
                inFlowBy: "EXCEL",
                ticketScope: ticketScope,
                deliveryType: data.deliveryType,
                carrier: data.carrier


            },
            primary_details: {
                medical_equp: "",
                someFiled2: "",
            },
            products: [
                {
                    primary: {
                        productId: data.productId,
                        productCode: data.productCode,
                        brandId: data.brandId,
                        modelId: data.modelId,
                        brandName: data.brandName,
                        modelName: data.modelName,
                        brandCode: data.brandCode,
                        modelCode: data.modelCode,
                        productName: data.productName,
                        dateOfPurchase: data.dateOfPurchase,
                        identificationNo: indentificationNumber.trim(),
                        productDesc: data.productDesc,
                        problemDesc: data.problemDesc,
                        productValue: data.productValue?.toString() || "",
                        cost: data.cost?.toString() || "",
                        isUnderWarranty: data.isUnderWarranty,
                        pickupSellerCode: data.pickupSellerCode,
                        returnSellerCode: data.returnSellerCode,
                        maxValueToBeOffered: data.maxValueToBeOffered,
                        estimatedProdValue: data.estimatedProdValue,
                        productDimensions: {
                            productUnit: data.productDimensionUnit,
                            productLength: data.productLength,
                            productBreadth: data.productBreadth,
                            productHeight: data.productHeight
                        },
                        productWeight: {
                            productUnit: data.productWeightUnit,
                            productActualWeight: data.productActualWeight,
                            productVolumeWeight: data.productVolumeWeight
                        }
                    },
                    product_details: {
                        boxPickUp: data.boxPickUp,
                        batteryPickUp: data.batteryPickUp,
                        headPhonePickUp: data.headPhonePickUp,
                        chargerPickUp: data.chargerPickUp,
                        othersPickUp: data.othersPickUp,
                        productBoxes: [
                            {
                                boxName: data.boxName,
                                boxSlNo: data.boxSlNo
                            }
                        ]
                    },
                    src_add: {
                        srcContactPerson: data.srcContactPerson,
                        srcOrg: data.srcOrg,
                        srcAdd1: data.srcAdd1,
                        srcAdd2: data.srcAdd2,
                        srcLocation: data.srcLocation,
                        srcContact1: data.srcContact1?.toString() || "",
                        srcContact2: data.srcContact2?.toString() || "",
                        srcCity: data.srcCity,
                        srcState: data.srcState,
                        srcPincode: data.srcPincode?.toString() || "",
                        srcLandmark: data.srcLandmark,
                        srcEmailId: data.srcEmailId,
                        srcEmailIdAlt: data.srcEmailIdAlt,
                        srcType: data.srcType,
                        srcLatitude: data?.srcLatitude,
                        srcLongitude: data?.srcLongitude,
                    },
                    dst_add: {
                        dstContactPerson: "",
                        dstOrg: "",
                        dstAdd1: "",
                        dstAdd2: "",
                        dstLocation: "",
                        dstContact1: "",
                        dstContact2: "",
                        dstCity: "",
                        dstState: "",
                        dstPincode: "",
                        dstLandmark: "",
                        dstEmailId: "",
                        dstEmailIdAlt: "",
                        dstType: "",
                        dstLatitude: "",
                        dstLongitude: ""
                    },
                },
            ],
        };
        return params;
    }
    catch {
        console.log("Error in ticket creation");
    }
}