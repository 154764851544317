import React from "react";
import FormLib from "../../_common/FormLib";
import "./../../../assets/support-chats.css";
import { getOpenList,getClosedList } from "../../../services/coreItAdmin/QA-service";
import toast from "../../../services/_toastService";
import $ from 'jquery';
class SupportChats extends FormLib {
  state = {
    loading: true,
    mode: "Open",
    data: {},
    pageLinks: {
        pageNo: 1,
        perPage: 0,
        itemsCount: 0,
        lastPage: 0,
    },
    errors: {},
    curFlow: { flowId: "" },
    curTeams: { teamId: "" },
    curProducts: { productId: "" },
    flows: [],
    teams: [],
    products: [],
    OpenList: [],
    ClosedList: [],



}

  onModeChange(mode) {
    this.setState({ mode: mode });
    // console.log(1);
  }

componentDidMount(){
  this.handleView();
  this.loadOpenList();
  this.loadClosedList();


}
  handleView = () => {
    // document.getElementById("defaultOpen").click();
  };

  loadOpenList = async () => {
    this.setState({ loading: true });
    const res = await getOpenList();
    // console.log(JSON.stringify(res));
    if (res.data.success === true) {
        this.setState({ OpenList: res.data.data.open });
    } else {
        toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
}

loadClosedList = async () => {
  this.setState({ loading: true });
  const res = await getClosedList();
  // console.log(JSON.stringify(res));
  if (res.data.success === true) {
      this.setState({ ClosedList: res.data.data.closed });
  } else {
      toast.toastError(res.data.msg);
  }
  this.setState({ loading: false });
}

handleView(){
  $('select[multiple]').multiselect();
  $('#langOptgroup').multiselect({
    columns: 4,
    placeholder: 'Select Languages',
    search: true,
    selectAll: true
});


}



  render() {
    const openlist = this.state.OpenList;
    const closedlist = this.state.ClosedList;


    return (
      <React.Fragment>
        <div className="pageContent">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card m-0">
              
                  <div className="row">
                    <div className="col-md-4">
                    <button
              type="button"
              title="Create Tickets"
              className="btn btn-sm btn-info pull-left mt-4"
              data-toggle="modal"
              data-target="#mySupportModal"

            >
              <i className={"fa fa-plus"}></i>
            </button>
                      <div className="users-container">

{/* ---------------------------------------------------tab role----------------------------------------------------------- */}
                <div role="tabpanel">
                        <div className="inbox-categories">
                  <button
                      onClick={() => this.onModeChange("Open")}
                      className={
                        this.state.mode === "Open"
                          ? " btn btn-success btn-sm "
                          : "btn  btn-default btn-sm "
                      }
                      
                    >
                      Open
                    </button>
                    <button
                      onClick={() => this.onModeChange("Closed")}
                      className={
                        this.state.mode === "Closed"
                          ? " btn btn-info btn-sm "
                          : "btn  btn-default btn-sm "
                      }
                    >
                      Closed
                    </button>
                    </div>

                    <div  className="tab-content">
                       {this.state.mode === "Open" &&
                       <>
                      <div className="chat-search-box ">
                        <div className="input-group pt-2">
                          {/* <input
                            className="form-control"
                            placeholder="Search"
                          />
                          <div className="input-group-btn">
                            <button type="button" className="btn btn-info">
                              <i className="fa fa-search"></i>
                            </button>
                          </div> */}
                        </div>
                      </div>
                          <div className="row">

                            <div className="col-10">
                                {openlist.map((r) => {
                                    return (
                                        <div className="card mb-1" key={r.questionId} style={{ "background-color": "AliceBlue" }}>
                                            <div className="row ">
                                                {/* <div className="col-1 pl-3"> */}
                                                    {/* <span className="m-2"> */}
                                                        {/* {this.rCBHL("11212", [{ label: "", value: "selectedQA" }])} */}
                                                    {/* </span> */}
                                                {/* </div> */}
                                                <div className="col-12 border-left curTasks cursorPointor m-2" onClick={() => this.handleCardClick(r)}>
                                                    <div className="card-body col-18">
                                                        <span className="h6">{r.question}</span> <br />
                                                        {/* <span className="h6">{r.question}</span> <br /> */}
                                                        <span className="h6">{r.options}</span> <br /> 
                                                         <span className="h6 pull-right">{r.elementType}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                      </div>

                      </>
                    }
                    {this.state.mode === "Closed" &&
                       <>
                    <div className="chat-search-box ">
                            <div className="input-group pt-2">
                            </div>
                          </div>

                          {/* <div className="row"> */}

                  <div className="col-10">
                  {closedlist.map((r) => {
                      return (
                          <div className="card mb-1" key={r.questionId} style={{ "background-color": "AliceBlue" }}>
                              <div className="row ">
                                  <div className="col-12 border-left curTasks cursorPointor m-2" onClick={() => this.handleCardClick(r)}>
                                      <div className="card-body col-18 " style={{color: "red"}}>
                                          <span className="h6">{r.question}</span> <br />
                                          <span className="h6">{r.options}</span> <br /> 
                                            <span className="h6 pull-right">{r.elementType}</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      )
                  })}
                  </div>

                       </>
                    }
                    </div>
                      </div>
                    </div>

                    </div>



{/* ---------------------------------------------------tab role----------------------------------------------------------- */}
<div className="modal fade" id="mySupportModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content" style={{"background-color": "#fdfbe7"}}>
              <div className="modal-header">
                <h4 className="modal-title">Create Support Token</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body mt-2 ">
                <form>
                  <div className="form-row">
                  <div className="form-group col-md-10">
                  <textarea placeholder=" Write Subject Here....." name="styled-textarea" id="styled" onfocus="this.value=''; setbg('#e5fff3');" onblur="setbg('white')"></textarea>
                    <label htmlFor="textarea" className="control-label">
                     <b style={{color:" black"}}>Subject</b> 
                    </label>
                    <i className="box"></i>
                  </div>
                  </div>
                  <div className="form-row">
                  <div className="form-group col-md-10 ">
                  <textarea placeholder=" Write Messages Here....." name="styled-textarea" id="styled" onfocus="this.value=''; setbg('#e5fff3');" onblur="setbg('white')"></textarea>
                    <label htmlFor="textarea" className="control-label">
                     <b style={{color:" black"}}>Message</b> 
                    </label>
                    <i className="box"></i>
                  </div>
                  </div>
                  <div className="form-row">

                    <div className="col-6 ">
                    <select name="langOpt[]" multiple id="langOpt">
                          <option value="C++">C++</option>
                          <option value="C#">C#</option>
                          <option value="Java">Java</option>
                          <option value="Objective-C">Objective-C</option>
                          <option value="JavaScript">JavaScript</option>
                          <option value="Perl">Perl</option>
                          <option value="PHP">PHP</option>
                          <option value="Ruby on Rails">Ruby on Rails</option>
                          <option value="Android">Android</option>
                          <option value="iOS">iOS</option>
                          <option value="HTML">HTML</option>
                          <option value="XML">XML</option>
                      </select>
                    <span className="m-2">
                          {/* {this.rCBHL("11212", [{ label: "", value: "IT team" }])}
                          {this.rCBHL("11212", [{ label: "", value: "IT team" }])} 
                          {this.rCBHL("11212", [{ label: "", value: "IT team" }])} 
                          {this.rCBHL("11212", [{ label: "", value: "IT team" }])}  */}


                      </span> 
                  </div>
                </div>

                </form>
              </div>

              <div className="modal-footer">
                {this.rBtnSP("Create", "create")}
                {/* {this.state.data.packMaterialId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {this.rBtnSD("Delete", "delete")}
                  </>
                )} */}
              </div>
            </div>
          </div>
        </div>

                    <div className="col-md-8">
                      <div className="selected-user">
                        <span>
                          To: <span className="name">Emily Russell</span>
                        </span>
                      </div>
                      <div className="chat-container">
                        <ul className="chat-box chatContainerScroll">
                          <li className="chat-left">
                            <div className="chat-avatar">
                              <img
                                src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
                                alt="Retail Admin"
                              />
                              <div className="chat-name">Russell</div>
                            </div>
                            <div className="chat-text">
                              Hello, I'm Russell.
                              <br />
                              How can I help you today?
                            </div>
                            <div className="chat-hour">
                              08:55 <span className="fa fa-check-circle"></span>
                            </div>
                          </li>
                          <li className="chat-right">
                            <div className="chat-hour">
                              08:56 <span className="fa fa-check-circle"></span>
                            </div>
                            <div className="chat-text">
                              Hi, Russell
                              <br /> I need more information about Developer
                              Plan.
                            </div>
                            <div className="chat-avatar">
                              <img
                                src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
                                alt="Retail Admin"
                              />
                              <div className="chat-name">Sam</div>
                            </div>
                          </li>
                          <li className="chat-left">
                            <div className="chat-avatar">
                              <img
                                src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
                                alt="Retail Admin"
                              />
                              <div className="chat-name">Russell</div>
                            </div>
                            <div className="chat-text">
                              Are we meeting today?
                              <br />
                              Project has been already finished and I have
                              results to show you.
                            </div>
                            <div className="chat-hour">
                              08:57 <span className="fa fa-check-circle"></span>
                            </div>
                          </li>
                          <li className="chat-right">
                            <div className="chat-hour">
                              08:59 <span className="fa fa-check-circle"></span>
                            </div>
                            <div className="chat-text">
                              Well I am not sure.
                              <br />I have results to show you.
                            </div>
                            <div className="chat-avatar">
                              <img
                                src="https://www.bootdey.com/img/Content/avatar/avatar5.png"
                                alt="Retail Admin"
                              />
                              <div className="chat-name">Joyse</div>
                            </div>
                          </li>
                          <li className="chat-left">
                            <div className="chat-avatar">
                              <img
                                src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
                                alt="Retail Admin"
                              />
                              <div className="chat-name">Russell</div>
                            </div>
                            <div className="chat-text">
                              The rest of the team is not here yet.
                              <br />
                              Maybe in an hour or so?
                            </div>
                            <div className="chat-hour">
                              08:57 <span className="fa fa-check-circle"></span>
                            </div>
                          </li>
                          <li className="chat-right">
                            <div className="chat-hour">
                              08:59 <span className="fa fa-check-circle"></span>
                            </div>
                            <div className="chat-text">
                              Have you faced any problems at the last phase of
                              the project?
                            </div>
                            <div className="chat-avatar">
                              <img
                                src="https://www.bootdey.com/img/Content/avatar/avatar4.png"
                                alt="Retail Admin"
                              />
                              <div className="chat-name">Jin</div>
                            </div>
                          </li>
                          <li className="chat-left">
                            <div className="chat-avatar">
                              <img
                                src="https://www.bootdey.com/img/Content/avatar/avatar3.png"
                                alt="Retail Admin"
                              />
                              <div className="chat-name">Russell</div>
                            </div>
                            <div className="chat-text">
                              Actually everything was fine.
                              <br />
                              I'm very excited to show this to our team.
                            </div>
                            <div className="chat-hour">
                              07:00 <span className="fa fa-check-circle"></span>
                            </div>
                          </li>
                        </ul>

                        {/* <div className="form-group">
                          <textarea
                            className="form-control"
                            rows="3"
                            placeholder="Type your message here..."
                          ></textarea>
                        </div> */}
                        <div className="chat-footer ">
                          <textarea className="send-message-text"></textarea>
                          <label className="upload-file">
                            <input type="file" required="" />
                            <i className="fa fa-paperclip"></i>
                          </label>
                          <button
                            type="button"
                            className="send-message-button btn-info"
                          >
                            {" "}
                            <i className="fa fa-send"></i>{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SupportChats;
