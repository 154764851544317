import React from "react";
import { NavLink } from "react-router-dom";

function Nlk({ label, to, faIcon, clsName = "" }) {
  return (

      <NavLink to={ to } className="">
        <span className="fa-stack  fa-lg pull-left">
          <i className={"fa " + faIcon + " fa-stack-1x "}></i>
          
        </span>
        {label}
        <span className="arrow-icon fa-stack pull-right" >
        <i className={"fa fa-chevron-right fa-stack-1x pull-right"}></i>
        </span>
      </NavLink>
  
  );
}

export default Nlk;
