import React from "react";
import FormLib from "../../_common/FormLib";
import Table from "../../_common/Table";
import { setTicketActions } from "./../../../services/Tickets/TicketProcessServices";
// import toast from "../../../services/_toastService";
import { toast } from "react-toastify";
import { IstToUTC } from "../../../utils/DateUtils";
import $ from "jquery";
import Joi from "joi-browser";
import {
  assignBarcodeToProducts,
  getInventoryBarcodeDetails,
  getInwardedInventoryBarcodeDetails,
  assignBarcodeToTicket,
} from "./../../../services/stockManagement/inwardInventoryServices";
import BarcodeProductModal from "./actions/BarcodeProductModal";
import { getPackingMaterialAndProdByJobId } from "./../../../services/Tickets/TicketProcessServices";
class BarcodeProduct extends FormLib {
  state = {
    data: { scanCode: "" , bcScan: ""},
    // type:"",
    errors: {},
    currentRequest: {},
    openStatus: "true",
    mode: "open",
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    barcodeData: [],
    currentBlock: "",
    currentTicket: "",
    slNo: "",
    transformedData: [],
    slNoId: "",
    barcodeNo:"",
  };

  schema = {
    scancode: Joi.string().required().label("scancode "),
  };
  // type = [
  //   { sortKey: "barcode", sort: "With Barcode" },
  //   { sortKey: "quantity", sort: "Without Barcode" },
  // ];

  componentDidMount() {
    this.loadInit();
    this.getlocalDateTime();
    this.loadPackingData();
  }

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }
  localHandleChange = (e) => {
    this.setState({slNo:e.target.value})
  };
  loadPackingData = async () => {
    // alert("call")
    try {
      let blockData = this.props.blockDetails;
      // let ticketData = this.props.ticketDetails;
      let res = await getPackingMaterialAndProdByJobId(blockData);
      // console.log("jjjj", res.data.data.packingMaterialDetails);

      if (res !== undefined) {
        if (res.data.success === true && res.data.data) {
          // console.log("jjjj22222",res.data.data.packingMaterialDetails.length)
          const transformedData = res.data.data.packingMaterialDetails
            .map((item) =>
              item.productDetails.map((product) => ({
                ticketProdId: product.ticketProductId,
                slNo: product.slNo,

              }))
            )
            .flat();
          this.setState({ slNoId: res.data.data.packingMaterialDetails[0].productDetails[0].slNoId })

          this.setState({ transformedData });
          this.setState({ statuses: res.data.data.packingMaterialDetails });

          this.getPackingMaterialAndProdStatus(
            res.data.data.packingMaterialDetails
          );
          this.setState({ confirmData: true });
        } else {
          this.setState({ confirmData: false });
        }
      } else {
        this.setState({ confirmData: false });
      }
    } catch {
      console.log(
        "Error in loadPackingMaterialData>>>>>>>>>>>>>>>>>>>>>>>>>>FeAssignment"
      );
    }
  };

  loadInit() {
    const curTicketDetails = this.props.ticketDetails;
    this.setState({ currentTicket: curTicketDetails.retailerId });
    const curBlockDetails = this.props.blockDetails;
    this.setState({ currentBlock: curBlockDetails.hubId });
    const curProductDetails = this.props.productDetails;
    if (curTicketDetails.flowId === "AdvanceExchange") {
      let exchangeData = [];
      curProductDetails.map((m) => {
        if (m.productType === "exchange") {
          exchangeData.push(m);
        }
      });
      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails: exchangeData });
    } else {
      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails });
    }
  }

  onData = (r) => {
    this.setState({ openStatus: "false" });
    this.setState({ currentRequest: r });
  };

  // handleBarcodeChange = (e) => {
  //     this.setState({ barcodeNo: e.target.value });    
  // };

  tableMetaQnt = {
    key: "ticketProductId",
    columns: [
      {
        key: "ticketProductId",
        actionKey: "bt",
        action: (r) => (
          <>
            <span
              className="badge badge-primary link-over"
              onClick={() => this.onBarcodeAttach(r)}
            >
              Save
            </span>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      {
        key: "ticketProductId",
        actionKey: "sb",
        action: (r) => (
          <>
            <>
              {this.state.transformedData.map((i) => (
                <>
                  {i.ticketProdId === r["ticketProductId"] && (
                    <span className="badge badge-info mr-1 ml-1">{i.slNo}</span>
                  )}
                </>
              ))}
            </>

            {/* <>
              {this.rRBL("", "type" + r["ticketProductId"], this.type, {
                value: "sortKey",
                label: "sort",
              })}
            </> */}

            {/* {this.state.data["type" + r["ticketProductId"]] === "barcode" &&
             ( */}
            <>
            {this.rTB("Scan Barcode", "bc_" + r["ticketProductId"], "")}
            {/* <input
            key={r["ticketProductId"]}
          type="text"
          value={this.state.barcodeNo}
          onChange={this.handleBarcodeChange}
          placeholder="Enter Barcode"
          style={{
            border: 'none', 
            borderBottom: '1px solid black', 
            outline: 'none', 
            backgroundColor: 'transparent',
          }}
        /> */}
            </>
            {/* )} */}
            {/* {this.state.data["type" + r["ticketProductId"]] === "quantity" && (
              <span
                className="badge badge-success link-over"
                onClick={() => this.onData(r)}
              >
                Quantity
              </span>
            )} */}
          </>
        ),
        label: "Barcode",
        icon: () => <>Attach Inventory</>,
        cusClass: " ",
      },
      { path: "productName", label: "Product" },
      { path: "identificationNo", label: "Ident. No" },
    ],
  };

  tableMetaBarcode = {
    key: "ticketProductId",
    columns: [
      {
        key: "ticketProductId",
        actionKey: "bt",
        action: (r) => (
          <>
            <span
              className="badge badge-primary link-over"
              onClick={() => this.onBarcodeAttach(r)}
            >
              Save
            </span>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      {
        key: "ticketProductId",
        actionKey: "sb",
        action: (r) => (
          <>
            <>{this.rTB("Scan Barcode", "bc_" + r["ticketProductId"], "")}</>
        {/* <input
          key={r["ticketProductId"]}
          type="text"
          value={this.state.barcodeNo}
          id= {`bc_${r["ticketProductId"]}`}
          onChange={(e) => this.handleBarcodeChange(e, r)}
          placeholder="Enter Barcode"
          style={{
            border: 'none', 
            borderBottom: '1px solid black', 
            outline: 'none', 
            backgroundColor: 'transparent',
          }}
        /> */}
          </>
        ),
        label: "Barcode",
        icon: () => <>Barcode</>,
        cusClass: " ",
      },
      { path: "productName", label: "Product" },
      { path: "identificationNo", label: "Ident. No" },
    ],
  };

  tableMeta = {
    key: "ticketProductId",
    columns: [
      // {
      //   key: "ticketProductId",
      //   actionKey: "bt",
      //   action: (r) => (
      //     <>
      //       <span
      //         className="badge badge-primary link-over"
      //         onClick={() => this.onBlockSelection(r)}
      //       >
      //         Save
      //       </span>
      //     </>
      //   ),
      //   icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      // },
      // {
      //   key: "ticketProductId",
      //   actionKey: "sb",
      //   action: (r) => (
      //     <>{this.rTB("Scan Barcode", "bc_" + r["ticketProductId"], "")}</>
      //   ),
      //   label: "Barcode",
      //   icon: () => <>Barcode</>,
      //   cusClass: " ",
      // },
      { path: "productName", label: "Product" },
      { path: "identificationNo", label: "Ident. No" },
    ],
  };

  onBlockSelection = async (r) => {
    let override = { ...this.state.data };
    override["actionCode"] = "wBarcodeProdAssign";
    override["action"] = "Assign Barcode to Product";
    // this.setState({ override });
    let resultSet = {
      barcode: this.state.data["bc_" + r.ticketProductId].trim(),
      ticketProductId: r.ticketProductId,
      actionStatus: "PRODUCT_BARCODE_ASSIGNED",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };

    // console.log("cameeeeeeeeeeee " + JSON.stringify(resultSet));
    // console.log("Block Details " + JSON.stringify(this.state.curBlockDetails));

    let resp = await setTicketActions(
      override,
      this.state.curTicketDetails,
      this.state.curBlockDetails,
      resultSet,
      r.ticketProductId
    );
    if (resp.data.success === true) {
      resp.data.data &&
        resp.data.data &&
        toast.success(() => <div>Product Barcode Assigned Successfully</div>);
    } else {
      toast.error(() => <div>{resp.data.msg}</div>);
    }
  };
  closeModal = () => {
    this.setState({ openStatus: "true" });
  };

  handleView = () => {
    $("#myModalOpen").draggable();
  };

  onBarcodeAttach = async (r) => {
    if (this.state.slNoId) {
      if (
        this.state.curTicketDetails.flowId === "DropOff" ||
        this.state.curTicketDetails.flowId === "AdvanceExchange"
      ) {
        // if(this.state.barcodeNo.toString().length === 7){
        let respBarcode = await getInwardedInventoryBarcodeDetails(
          this.state.data["bc_" + r.ticketProductId]?.trim()
        );
        this.setState({ slNo: respBarcode.data.data.slNo });
        console.log(respBarcode.data.data.slNo[0].slNoId, "responcessssssssssssssssss")
        this.props.onHandleGetConfirmStatus();
        this.loadPackingData();
        if (respBarcode.data.success === true) {
          if (
            respBarcode.data.success === true &&
            respBarcode.data.data.slNo.length === 1
          ) {
            this.attachInwardedBarcode(
              respBarcode.data.data.slNo[0].slNoId,
              r,
              respBarcode.data.data.slNo[0]
            );
            // this.props.onHandleGetConfirmStatus();
          } else if (
            this.state.barcodeId &&
            respBarcode.data.data.slNo.length >= 1
          ) {
            this.attachInwardedBarcode(
              this.state.barcodeId,
              r,
              this.state.barcodeIdSelected
            );
            let data = {
              barcode: "",
            };
            this.setState({ data });
            // this.props.onHandleGetConfirmStatus();

            // toast.error(() => <div>{respBarcode.data.msg}</div>);
          }
        } else if (this.state.slNoId !== respBarcode.data.data.slNo[0].slNoId) {
          toast.warning(() => <div>{"Barcode not matched"}</div>)
        }
        else {
          this.setState({ barcodeData: respBarcode.data.data.slNo });
          // toast.error(() => <div>{respBarcode.data.msg}</div>);
          this.props.onHandleGetConfirmStatus();
        }
      // }else{
      //   toast.error(() => <div>Barcode number should be exactly 7 digits long</div>);
      // }
      } else {
        if(this.state.slNo.length === 7){
        let respBarcode = await getInventoryBarcodeDetails(
          this.state.data["bc_" + r.ticketProductId]?.trim()
          // this.state.barcodeNo
        );
        this.setState({slNo:respBarcode.data.data.slNo[0].slNo})
        // this.props.onHandleGetConfirmStatus();
        this.loadPackingData();

        if (
          respBarcode.data.success === true &&
          respBarcode.data.data.slNo.length === 1
        ) {
          // this.props.onHandleGetConfirmStatus();

          this.attachBarcode(respBarcode.data.data.slNo[0].slNoId, r);
        } else if (
          this.state.barcodeId &&
          respBarcode.data.data.slNo.length >= 1
        ) {
          this.attachBarcode(this.state.barcodeId, r);
          let data = {
            barcode: "",
          };
          this.setState({ data });
          // this.props.onHandleGetConfirmStatus();

          // toast.error(() => <div>{respBarcode.data.msg}</div>);
        } else {
          this.setState({ barcodeData: respBarcode.data.data.slNo });
          // toast.error(() => <div>{respBarcode.data.msg}</div>);
        }
        }else{
          toast.error(() => <div>Barcode number should be exactly 7 digits long</div>);
        }
      }
    } else {
      if (
        this.state.curTicketDetails.flowId === "DropOff" ||
        this.state.curTicketDetails.flowId === "AdvanceExchange"
      ) {
        // if(this.state.barcodeNo.toString().length === 7){
        let respBarcode = await getInwardedInventoryBarcodeDetails(
          this.state.data["bc_" + r.ticketProductId]?.trim()
        );
        this.setState({ slNo: respBarcode.data.data.slNo });
        // console.log("fffffffffffffffffffffffffffffff",respBarcode)
        this.props.onHandleGetConfirmStatus();
        this.loadPackingData();

        if (
          respBarcode.data.success === true &&
          respBarcode.data.data.slNo.length === 1
        ) {
          this.attachInwardedBarcode(
            respBarcode.data.data.slNo[0].slNoId,
            r,
            respBarcode.data.data.slNo[0]
          );
          // this.props.onHandleGetConfirmStatus();
        } else if (
          this.state.barcodeId &&
          respBarcode.data.data.slNo.length >= 1
        ) {
          this.attachInwardedBarcode(
            this.state.barcodeId,
            r,
            this.state.barcodeIdSelected
          );
          let data = {
            barcode: "",
          };
          this.setState({ data });
          // this.props.onHandleGetConfirmStatus();

          // toast.error(() => <div>{respBarcode.data.msg}</div>);
        } else {
          this.setState({ barcodeData: respBarcode.data.data.slNo });
          // toast.error(() => <div>{respBarcode.data.msg}</div>);
          // this.props.onHandleGetConfirmStatus();
        }
      // }else{
      //   toast.error(() => <div>Barcode number should be exactly 7 digits long</div>);
      // }
      } else {
        if(this.state.slNo.length === 7){
        let respBarcode = await getInventoryBarcodeDetails(
          this.state.data["bc_" + r.ticketProductId]?.trim()
          // this.state.barcodeNo
        );
        this.setState({slNo:respBarcode.data.data.slNo[0].slNo})
        // this.props.onHandleGetConfirmStatus();
        this.loadPackingData();

        if (
          respBarcode.data.success === true &&
          respBarcode.data.data.slNo.length === 1
        ) {
          // this.props.onHandleGetConfirmStatus();

          this.attachBarcode(respBarcode.data.data.slNo[0].slNoId, r);
        } else if (
          this.state.barcodeId &&
          respBarcode.data.data.slNo.length >= 1
        ) {
          this.attachBarcode(this.state.barcodeId, r);
          let data = {
            barcode: "",
          };
          this.setState({ data });
          // this.props.onHandleGetConfirmStatus();

          // toast.error(() => <div>{respBarcode.data.msg}</div>);
        } else {
          this.setState({ barcodeData: respBarcode.data.data.slNo });
          // toast.error(() => <div>{respBarcode.data.msg}</div>);
        }
      }else{
        toast.error(() => <div>Barcode number should be exactly 7 digits long</div>);
      }
      }
    }
  };

  attachBarcode = async (slNoId, data) => {
    let params = {
      slNoId: slNoId,
      modelId: data.modelId,
      clientId: this.state.curTicketDetails.retailerId,
      prodCode: data.productCode,
      processId: data.processId,
      ticketBlockId: data.ticketBlockId,
      ticketProductId: data.ticketProductId,
      productLength: "",
      productBreadth: "",
      productHeight: "",
      productVolWeight: "",
      productUOM: "",
      actualWeight: "",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };

    let resp1 = await assignBarcodeToProducts(params);
    if (resp1.data.success === true) {
      resp1.data.data &&
        resp1.data.data &&
        toast.success(() => <div>Barcode Assigned Successfully</div>);
      this.props.onHandleGetConfirmStatus();
      this.loadPackingData();
      this.setState({ barcodeData: [] });
      let data = {
        barcode: "",
      };
      this.setState({ data });
    } else {
      // this.setState({ barcodeData: resp1.data.data.barcodeData });
      this.setState({ ticketActionResponse: false });
      toast.error(() => <div>{resp1.data.msg}</div>);
    }
  };

  attachInwardedBarcode = async (slNoId, data, slNoData) => {
    let params = {
      slNo: slNoData.slNo,
      slNoId: slNoId,
      processId: data.processId,
      ticketBlockId: data.ticketBlockId,
      ticketProductId: data.ticketProductId,
      hubId: slNoData.hubId,
      itemId: slNoData.itemId,
      ticketNo: data.ticketNo,
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };

    
    let resp1 = await assignBarcodeToTicket(params);
    if (resp1.data.success === true) {
      resp1.data.data &&
        resp1.data.data &&
        toast.success(() => <div>Barcode Assigned Successfully</div>);
      this.props.onHandleGetConfirmStatus();
      this.loadPackingData();

      this.setState({ barcodeData: [] });
      let data = {
        barcode: "",
      };
      this.setState({ data });
      this.props.onHandleConfirmStatus();
    } else {
      // this.setState({ barcodeData: resp1.data.data.barcodeData });
      this.setState({ ticketActionResponse: false });
      toast.error(() => <div>{resp1.data.msg}</div>);
    }
  };

  setCurSerialNumber = async (barcodeData) => {
    this.setState({ barcodeId: barcodeData.slNoId });
    this.setState({ barcodeIdSelected: barcodeData });

    this.setState({ barcodeOverRide: true });
    this.setState({ ticketActionResponse: true });
  };
  handlePacking = async () => {
    try {
      // console.log("call" + JSON.stringify(this.state.data.scanCode));
    } catch {
      console.log(
        "Exception in >>>>>>>>>>> BarcodeProduct >>>>>>>>>>> handlePacking "
      );
    }
  };
  render() {
    const productDetails = this.state.curProductDetails;

    const currentRequest = this.state.currentRequest;

    const currentBlock = this.state.currentBlock;

    // console.log('rarararararara',this.props)

    return (
      <div className="col-12">
        {/* <div className="form-subHeader">Products Barcodes</div> */}
        {/* <form id="myForm" onSubmit={this.handleSubmit}>
          <div className="form-row mt-2">
            <div className="col-8">
              <div className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3">
                {this.rTB("Packing code", "scanCode", "col-9")}
                <div className="col-3 m-0 p-0 ">
                  <button
                    type="button"
                    className="btn btn-sm btn-primary pull-right mt-2"
                    onClick={this.handlePacking}
                  >
                    Assign
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form> */}
        <div>
          {this.props.loading && (
            <div className="container-fluid">
              <div> Loading Tickets...</div>
            </div>
          )}

          {!this.props.loading && (
            <>
              {(this.state.curTicketDetails.flowId === "DropOff" ||
                this.state.curTicketDetails.flowId === "AdvanceExchange") && (
                  <div className="container-fluid">
                    <Table
                      clsName="table table-striped table-bordered table-hover"
                      tableMeta={this.tableMetaQnt}
                      data={productDetails}
                      dropMenu={[]}
                    />
                  </div>
                )}
              {(this.state.curTicketDetails.flowId === "Bulk" ||
                this.state.curTicketDetails.flowId === "PickAndDropOneWay" ||
                this.state.curTicketDetails.flowId === "BuyBack" ||
                this.state.curTicketDetails.flowId === "PickAndDropTwoWay") && (
                  <div className="container-fluid">
                    <Table
                      clsName="table table-striped table -bordered table-hover"
                      tableMeta={this.tableMetaBarcode}
                      data={productDetails}
                      dropMenu={[]}
                    />
                  </div>
                )}
              {/* {(this.state.curTicketDetails.flowId != "Bulk" ||
                this.state.curTicketDetails.flowId != "DropOff") && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped table-bordered table-hover"
                    // tableMeta={this.tableMeta}
                    data={productDetails}
                    dropMenu={[]}
                  />
                </div>
              )} */}
            </>
          )}
        </div>
        <div>
          {this.state.barcodeData &&
            this.state.barcodeData.map((r) => {
              return (
                <span
                  className="badge taskType"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <div
                    className="card mb-2 cursorPointor     "
                    onClick={() => this.setCurSerialNumber(r)}
                    title={r.NAME}
                  >
                    <div
                      className="card-header page-todo task"
                      style={
                        this.state.barcodeData &&
                          this.state.barcodeId === r.slNoId
                          ? { backgroundColor: "#e3e5ee" }
                          : { backgroundColor: "white" }
                      }
                    >
                      <div className="row  mt-2">
                        <div className="col-3 ">{r.slNo}</div>
                        <div className="col-9">{r?.itemName} </div>
                      </div>

                      <div className="row mt-2">
                        <div className="col-3 ">{r.NAME}</div>
                      </div>
                    </div>
                  </div>
                </span>
              );
            })}
        </div>
        <br />
        <div
          className="draggable col-10 ml-5 mr-5"
          id="myModalOpen"
          onClick={this.handleView}
        >
          {this.state.currentRequest.ticketProductId &&
            this.state.openStatus === "false" && (
              <BarcodeProductModal
                key={this.state.mode + currentRequest.ticketProductId}
                data={currentRequest}
                // requestStatusChange={this.handleRequestStatusChange}
                hubId={this.state.currentBlock}
                retailerId={this.state.currentTicket}
                closeModal={this.closeModal}
                onHandleGetStatus={this.loadPackingData}
                onHandleGetConfirmStatus={this.props.onHandleGetConfirmStatus}
              />
            )}
        </div>
      </div>
    );
  }
}
export default BarcodeProduct;