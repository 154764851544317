import _http from "../_httpService";
import config from "../../config.json";

const baseURL = config[config.build].apiURLPrevilages;

function api(path, subURL = "/roles") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getRoles(roleGroupId) {
  return _http.get(api("get-roles/" + roleGroupId), _http.headerJWT());
}
export function createRoles(req) {
  const param = { ...req };
  delete param.roleId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveRoles(req) {
  const param = { ...req };
  const id = param.roleId;
  //delete param.cityId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteRoles(req) {
  const param = { ...req };
  return _http.delete(api(param.roleId), _http.headerJWT());
}
