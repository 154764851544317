import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";

import Table from "../../_common/Table";
import Pagination from "../../_common/Pagination";
import { paginate } from "../../../utils/paginate";

import LocationTitle from "../../_subhead/LocationTitle";
import AccountsExpenseHeadForm from "./AccountsExpenseHeadForm";
import { getExpenseHead } from "../../../services/requestV1/ExpenseHeadService";

class AccountsExpenseHead extends FormLib {
  state = {
    // data: {
    //   searchString: "",
    // },
    errors: {},
    curExpenseHeadAccounts: {},
    loading: true,
    pageLinks: {
      pageNo: 1,
      perPage: 10,
      itemsCount: 10,
      lastPage: 0,
    },
  };
  // schema = {
  //   searchString: Joi.optional().label("Search String"),
  // };
  componentDidMount() {
    const pageNo = this.props.match.params.pageNo
      ? parseInt(this.props.match.params.pageNo)
      : 1;
    this.loadExpenseHeadAccounts(pageNo);
  }
  async loadExpenseHeadAccounts(pageNo) {
    this.setState({ loading: true });
    this.setState({ ExpenseHeadAccounts: [] });
    this.setState({
      pageLinks: {
        pageNo: 1,
        perPage: 10,
        itemsCount: 10,
        lastPage: 0,
      },
    });

    const res = await getExpenseHead(pageNo, this.state.data);

    if (res.data.status === 1) {
      this.setState({ ExpenseHeadAccounts: res.data.data });
      let pageLinks = res.data.pageLinks;
      pageLinks["itemsCount"] = pageLinks.count;
      this.setState({ pageLinks: pageLinks });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }
  async doSubmit() {
    this.loadExpenseHeadAccounts(1);
  }

  handleOnExpenseHeadAccount(r) {
    this.setState({ curExpenseHeadAccounts: r });
  }

  handlePageChange = (pageNo) => {
    //console.log("====>" + pageNo);
    this.props.history.push("/v1/request/ExpenseHead-accounts/" + pageNo);
    this.loadExpenseHeadAccounts(pageNo);
  };

  handleDataChange = () => {
    this.handlePageChange(this.state.pageLinks.pageNo);
    this.resetCurExpenseHeadAccounts();
  };

  resetCurExpenseHeadAccounts = () => {
    this.setState({ curExpenseHeadAccounts: {} });
  };

  tableMeta = {
    key: "EXPENSE_HEAD_ID",
    columns: [
      { path: "EXPENSE_HEAD", label: "Expense Head" },
      { path: "EXPENSE_HEAD_CODE", label: "Expense Head Code" },
      {
        key: "EXPENSE_HEAD_ID",
        action: (r) => (
          
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-right">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnExpenseHeadAccount(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
            </div>
          </div>
        ),
        label: "Action",
      },
    ],
  };
  actVal = [
    { sortKey: "SB", sort: "SB Account" },
    { sortKey: "Current", sort: "Current" },
  ];
  actConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="ExpenseHead::List" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Accounts</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  {/* <div className="form-row">
                    {this.rTB(
                      "Expense Head / Expense Code",
                      "searchString",
                      "col-6"
                    )}

                    <div className="col-1">
                      {this.rBtnSP("Search", "search")}
                    </div>
                  </div> */}
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.ExpenseHeadAccounts}
                    dropMenu={[]}
                  />
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              )}
            </div>
            <div className="col-12 col-sm-6 col-md-6">
              <button
                className="button-container btn  btn-info  btn-sm pull-right"
                onClick={() => this.resetCurExpenseHeadAccounts()}
              >
                Reset
              </button>
              <br /> <br /> <br />
              <AccountsExpenseHeadForm
                curExpenseHeadAccounts={this.state.curExpenseHeadAccounts}
                onDataChange={this.handleDataChange}
                key={this.state.curExpenseHeadAccounts.EXPENSE_HEAD_ID}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AccountsExpenseHead;
