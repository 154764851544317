import _http from "../_httpService";
import config from "../../config.json";

const baseURL = config[config.build].apiURLPrevilages;

function api(path, subURL = "/plrTeamApiToken") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getPlayerTeamApiToken(pageNo) {
 return _http.get(api("10/"+pageNo),_http.headerJWT());
}

export function createPlayerTeamApiToken(req) {
    const param = { ...req }
    return _http.post(api(""),param,_http.headerJWT());
}

export function updatePlayerTeamApiToken(req) {
    const param = { ...req }
    return _http.put(api(req.teamId),param,_http.headerJWT());
}

export function deletePlayerTeamApiToken(req) {
    return _http.delete(api(req.teamId),_http.headerJWT());
}

