import React from 'react';
import { toast } from 'react-toastify';
import FormLib from './../../_common/FormLib';
import Table from './../../_common/Table';
import { IoBarcodeOutline, IoCopyOutline } from 'react-icons/io5';
import BarCodePrintSVG from './BarCodePrintSVG';

export default class PickUpVerificationBlock extends FormLib {

    state = {
        data: {},
        errors: {},
        curTicketDetails: [],
        curBlockDetails: [],
        curProductDetails: [],
    }

    componentDidMount() {
        this.loadInit();
    }

    loadInit() {
        try {

            const curticketDetailsArr = [];
            const curTicketDetails = [...curticketDetailsArr, this.props.ticketDetails];
            let curBlockDetails = [];
            if (this.props.blockDetails.blocks_barcode) {
                curBlockDetails = this.props.blockDetails.blocks_barcode;
                this.setState({ curBlockDetails });
            }
            const curProductDetails = this.props.productDetails;
            this.setState({ curTicketDetails });

            this.setState({ curProductDetails });
        }
        catch {
            console.log("Error in PickUpVerificationBlock>>>>>>loadInit");

        }
    }

    onBlockSelection = async (r) => {
        try {
            if (this.state.data["bb_" + r.blockBarcode] !== r.blockBarcode) {
                toast.error("Block Barcode Does not Match Kindly enter the correct Barcode");
            } else {
                toast.success("Block Barcode Matched successfully");
            }

        }
        catch {
            console.log("Error in PickUpVerificationBlock>>>>>>onBlockSelection");
        }
    };


    render() {
        const blockDetails = this.state.curBlockDetails;
        return <>
            {Array.isArray(blockDetails) &&
                (<><div className="form-subHeader">Jobs PickedUp Barcodes Verification</div>
                    {blockDetails.map((r) => <div className="row" key={"pu_" + r.ticketBlockId + r.blockBarcode}>
                        <form onSubmit={this.handleSubmit} className="form-row col-12">
                            <div className="col-1 ml-3">
                                <button
                                    className="badge badge-primary link-over"
                                    onClick={() =>
                                        this.onBlockSelection(r)
                                    }
                                >
                                    Confirm
                                </button>
                            </div>
                            <div className="col">
                                {this.rTB("Barcode", "bb_" + r["blockBarcode"], "")}
                            </div>
                            <div className="col-2">
                                Job Barcode: <b>{r.blockBarcode}</b>
                            </div>
                            <div className="col">
                                Job Code: <b>{r.blockCode}</b>
                            </div>
                        </form>
                    </div>)}</>)}
        </>
    }
}
