import React from 'react'
import { getFlows, getRetailer } from '../../../services/masters/hubServices';
import FormLib from './../../_common/FormLib';
import Joi from 'joi-browser';
import { toast } from 'react-toastify';
export default class TicketCreation extends FormLib {
    state = {
        data: { client: "" },
        clients: [],
        flows: [],
        errors: {},
    }

    schema = {
        client: Joi.string().required().label("client")
    };

    async componentDidMount() {
        this.loadRetailers();
        this.loadFlows();
    }

    async loadRetailers() {
        const res = await getRetailer();
        if (res.data.success === true) {
            this.setState({ clients: res.data.data.teams });
        } else {
            toast.error(res.data.msg);
        }
    }

    async loadFlows() {
        const res = await getFlows();
        if (res.data.success === true) {
            this.setState({ flows: res.data.data.flows });
        } else {
            toast.error(res.data.msg);
        }
    }

    localHandleChange = (e) => {
        if (e.target.name === "client") {
            const { value } = e.target;
            let data = this.state.data;
            let retailer = this.state;

            // find the clients with the current selected value
            const clients = retailer.clients.find((c) => c.name === value);

            if (clients !== undefined) {
                data["client"] = clients.teamId;
                this.setState({
                    data,
                });
            }
            this.validateProperty(e.target.name, e.target.value);
        }
    };

    priority = [
        { priorityId: "high", priority: "High" },
        { priorityId: "medium", priority: "Medium" },
        { priorityId: "low", priority: "Low" },
    ];


    render() {
        return (
            <>
                <div className='card'>
                    <div className='card-body'>
                        <form id="myform" onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                {this.rSBF(
                                    "Clients *",
                                    "client",
                                    this.state.clients,
                                    { value: "teamId", label: "name" },
                                    " col-md-4 "
                                )}
                                {this.rSBF(
                                    "Flows *",
                                    "flow",
                                    this.state.flows,
                                    { value: "flowId", label: "flow" },
                                    " col-md-4 "
                                )}
                                {this.rRBL(
                                    "Ticket Priority *",
                                    "priority",
                                    this.priority,
                                    {
                                        value: "priorityId",
                                        label: "priority",
                                    },
                                    "col-md-4"
                                )}
                            </div>
                            <br />
                            <div className="form-subHeader">Primary Details</div>
                            {/* <div className='form-row'>
                            {this.rTB("Consumer Name *", "ship_no", " col-3")}
                            {this.rTB("Consumer Complaint Number *", "vehicle_no", " col-3")}
                        </div> */}
                            <div className="form-subHeader">Product Details</div>
                        </form>
                    </div>
                </div>
            </>
        )
    }
}
