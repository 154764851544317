import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";

import {
  createRoleGroups,
  saveRoleGroups,
  deleteRoleGroups,
} from "../../../services/appRoles/roleGroupsServices";

class ModalGroup extends FormLib {
  state = {
    data: {
      roleGroupId: "",
      divisionId: "",
      groupName: "",
      status: "",
      sort: "",
    },
    errors: {},
    roleDivs: [],
    curDivision: {},
    curGroup: {},
  };

  schema = {
    roleGroupId: Joi.optional().label("Role Group Id"),
    divisionId: Joi.optional().label("Division Id"),
    groupName: Joi.string().required().label("Group Name"),
    status: Joi.string().required().label("Status"),
    sort: Joi.string().required().label("Sort"),
  };

  async doSubmit() {
    // console.log(JSON.stringify(this.props.curDivision.divisionId));
    let res;
    if (this.state.btnValue === "create") {
      res = await createRoleGroups(this.state.data);
    }
    if (this.state.btnValue === "edit") {
      res = await saveRoleGroups(this.state.data);
    }
    if (this.state.btnValue === "delete") {
      res = await deleteRoleGroups(this.state.data);
    }
    if (res.data.success) {
      toast.toastSuccess(res.data.msg);
      this.resetData(this.state.curGroup);
      this.props.onLoadGroups(this.props.curDivision.divisionId);
    } else {
      toast.toastError(res.data.msg);
    }
  }
  componentDidMount() {
    this.setRoleDivs(this.props.roleDivs);
    this.setCurDivision(this.props.curDivision);
    this.setCurGroup(this.props.curGroup, this.props.curDivision);
  }
  setRoleDivs(roleDivs) {
    this.setState({ roleDivs: roleDivs });
  }
  setCurDivision(d) {
    this.setState({ curDivision: d });
  }

  setCurGroup(g, d) {
    if (g.roleGroupId !== "") {
      g.status = "active";
      this.setState({ data: g });
    } else {
      this.resetData(d);
    }
  }

  resetData(d) {
    this.setState({
      data: {
        roleGroupId: "",
        divisionId: d.divisionId || "",
        groupName: "",
        status: "active",
        sort: "",
      },
    });
  }

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  sort = [
    { sortKey: "asc", sort: "ASC" },
    { sortKey: "dsc", sort: "DSC" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;
    const roleDivs = this.state.roleDivs;
    return (
      <div className="container-fluid">
        <div className="modal fade" id="myGroupModel">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Role Groups</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  <br />

                  {this.rTBH("RoleGroupId", "roleGroupId")}
                  {this.rSB("RoleDivs", "divisionId", roleDivs, {
                    value: "divisionId",
                    label: "division",
                  })}
                  {/* {this.rTB("DivisionId", "divisionId", "col-4")} */}
                  {this.rTB("GroupName", "groupName")}
                  <div className="form-row">
                    {this.rRBL("Status", "status", this.status, this.stsConf, "col-6")}
                    {this.rRBL("Sort", "sort", this.sort, this.stsConf, "col-6")}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {/* {"==>"+this.state.data.roleGroupId + "<=="} */}
                {this.state.data.roleGroupId === "" &&
                  this.rBtnSP("Create", "create")}
                {this.state.data.roleGroupId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {this.rBtnSD("Delete", "delete")}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalGroup;
