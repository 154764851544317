import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import { changePassword } from "../../../services/players/playerServices";



class PasswordChange extends FormLib {
    state = {
        data: {
            newPassword: "",
            comfirmPassword: ""
        },
        curPlayer: {},
        errors: {},
    }
    schema = {
        newPassword: Joi.string().required().label("Password "),
        comfirmPassword: Joi.any().valid(Joi.ref('newPassword')).required().label("Comfirm Password ").options({ language: { any: { allowOnly: 'must match password' } } })
    };

    componentDidMount() {
        this.loadCurPlayer(this.props.curPlr);
    }

    loadCurPlayer = (r) => {
        this.setState({ curPlayer: r });
        
    }


    doSubmit = async () => {
        let res;
        if (this.state.btnValue === "create") {
            res = await changePassword(this.state.data, this.props.curPlr);
        }
        if (res.data.success) {
            toast.toastSuccess(res.data.msg);
            this.props.onLoadPlayer(this.props.curPlr.teamId, this.props.onLoadPage);
            this.resetData();
            this.props.onReset();
        } else {
            toast.toastError(res.data.msg);
        }
    };

    resetData = () => {
        this.setState({
            data: {
                newPassword: "",
                comfirmPassword: ""
            },
        });
    }


    render() {
        const { firstname, lastname } = this.props.curPlr;

        return (
            <div className="container-fluid">
                <div className="modal fade" id="myMaterialModal">
                    <div className="modal-dialog modal-md modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header text-center" style={{ "background-color": "Azure" }}>
                                <span className="modal-title h4 w-100">Change Password</span>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body ">
                                <form >
                                    <b  className="mb-6"> {firstname}  {lastname} </b>
                                    <div className="form-row mt-3">
                                        {this.rPB("Password", "newPassword", "col-12")}
                                        <br /> <br />
                                        {this.rPB("Comfirm Password", "comfirmPassword", "col-12")}
                                    </div>
                                </form>
                                <br />
                                {this.rBtnSP("Submit", "create")}
                                <button
                                    type="button"
                                    className="btn btn-sm btn-warning pull-right"
                                    onClick={() => this.resetData()}
                                    title="Reset"
                                >
                                    <i className={"fa fa-unlink"} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PasswordChange;