import _http from "../_httpNoRejectService";
import config from "../../config.json";

const apiDevEndpoint = config[config.build].apiURLPrevilages;

export function resetMobileKey(params) {
  return _http.get(apiDevEndpoint + "/mobile-key-reset/by-username/" + params.userName, _http.headerJWT());
}

export function resetAllMobileKey() {
  return _http.delete(apiDevEndpoint + "/mobile-key-reset/all", _http.headerJWT());
}
