import React from "react";
import FormLib from "../../_common/FormLib";
import swal from "sweetalert";
import Joi from "joi-browser";
import Table from "../../_common/Table";
// import Pagination from "../../_common/Pagination";

import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";

import { getActiveCountry } from "../../../services/masterLocations/countryServices";

import {
  getStates,
  createStates,
  saveStates,
  deleteStates,
} from "../../../services/masterLocations/statesServices";

class States extends FormLib {
  state = {
    data: {
      stateId: "",
      countryId: "",
      state: "",
      status: "active",
    },
    errors: {},
    formMode: "create",
    loading: true,
    curCountry :{countryId:""}
    // pageLinks: {
    //   pageNo: 1,
    //   perPage: 0,
    //   itemsCount: 0,
    //   lastPage: 0,
    // },
  };
  schema = {
    stateId: Joi.optional().label("State Id"),
    countryId: Joi.optional().label("Country Id"),
    state: Joi.string()
      .regex(/^[a-zA-Z ]*$/)
      .required()
      .label("State")
      .error(() => {
        return {
          message: "Allow only characters.",
        };
      }),
    status: Joi.string().required().label("Status"),
  };

  loadActiveCountry = async () => {
    let list = await getActiveCountry();
    this.setState({ countries: list.data.data.countries });
    this.state.data.countryId = list.data.data.countries[0]['countryId'];

    this.setState({curCountry : list.data.data.countries[0]});

    this.setState({ data:  {
      stateId: "",
      countryId: list.data.data.countries[0]['countryId'],
      state: "",
      status: "active",
    } });

 
  };

  componentDidMount() {
    this.loadActiveCountry();
    this.loadStates();
  }
  async loadStates() {
    this.setState({ loading: true });
    const res = await getStates();
    if (res.data.success === true) {
      this.setState({ states: res.data.data.states });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  tableMeta = {
    key: "stateId",
    columns: [
      {
        key: "STATE_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnStates(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleView(r)}
              >
                <i className="fa fa-binoculars" aria-hidden="true"></i> View
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(r)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i> Delete
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      // { path: "stateId", label: "State Id" },
      { path: "country", label: "Country" },
      { path: "state", label: "State" },
      { path: "status", label: "Status" },
    ],
  };
  handleOnStates(r) {
  
    let states = {};
    states.stateId = r.stateId;
    states.countryId = r.countryId;
    states.state = r.state;
    states.status = r.status;
    this.setState({ data: states, formMode: "edit" });
  }
  //   handlePageChange = (pageNo) => {
  //     this.props.history.push("/location/city" + pageNo);

  //     this.loadCities();
  //   };
  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createStates(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveStates(this.state.data);
      this.resetData();
    }

    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadStates();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  doSubmitDelete = async (handleDelete, r) => {
    if (handleDelete) {
      await deleteStates(r);
      this.loadStates();

      swal("State has been deleted!", {
        icon: "success",
      });
    } else {
      swal("State is safe!");
    }
  };

  handleDelete = async (r) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, r);
    });
  };

  handleView = (r) => {
    this.setState({ curState: r, formMode: "view" });
  };

  resetData() {
    this.setState({
      data: {
        stateId: "",
        countryId: this.state.curCountry.countryId,
        state: "",
        status: "active",
      },
      formMode: "create",
    });
  }
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

 


  render() {
    // const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="States" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-6">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading States</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.states}
                    dropMenu={[]}
                  />
                  {/* <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  /> */}
                </div>
              )}
            </div>
            <div className="col-md-6">
              {this.state.formMode === "view" && (
                <div className="card">
                  <div className="card-body">
                    <>
                      {this.state.curState["state"].trim()}
                      <div className="pull-right">
                        <span className={"badge badge-light pull-left ml-1 "}>
                          {/* {this.state.curState["stateId"]} */}
                        </span>
                        { this.state.curState["status"] ==='active' && 
                          <span className={"badge badge-success pull-left mr-1 "}>
                            {this.state.curState["status"]}
                          </span>
                        }
                        { this.state.curState["status"] ==='inactive' && 
                          <span className={"badge badge-danger pull-left mr-1 "}>
                            {this.state.curState["status"]}
                          </span>
                        }
                      </div>
                    </>
                  </div>
                </div>
              )}
              {this.state.formMode !== "view" && (
                <div className="card pt-3">
                  <div className="card-body">
                    <form>
                      <div className="form-row">
                        {this.rTBH("StateId", "stateId", "col-6")}
                        {this.rSB(
                          "Country *",
                          "countryId",
                          this.state.countries,
                          { value: "countryId", label: "country" },
                          " col-md-6 mt-1"
                        )}
                        {/* {this.rTB("CountryId", "countryId", "col-4")} */}
                        {this.rTB("State", "state", "col-6")}
                      </div>
                      <div className="form-row">
                        {this.rRBL(
                          "Status",
                          "status",
                          this.status,
                          this.stsConf
                        )}
                      </div>
                    </form>
                    {this.state.formMode === "create" &&
                      this.rBtnSP("Create", "create")}
                    {this.state.formMode === "edit" && (
                      <>
                        {this.rBtnSS("Edit", "edit")}
                      </>
                    )}
                    {this.mResetBtn(() => this.resetData(), " pull-right ","Reset")}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <span>{JSON.stringify(this.state.data)}</span> */}
        </div>
      </React.Fragment>
    );
  }
}

export default States;
