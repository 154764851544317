import { combineReducers } from "redux";
import entitiesReducer from "./entitiesReducer";
import ticketProcessReducer from "./ticketProcessReducer";
import authReducer from "./authReducer";
import appReducer from "./appReducer/appReducer";
import uiReducer from "./ui/uiReducer";

const rootReducer = combineReducers({
  entities: entitiesReducer,
  auth: authReducer,
  ticketProcess: ticketProcessReducer,
  app: appReducer,
  ui: uiReducer,
});

export default rootReducer;
