import React from "react";
import FormLib from "../../_common/FormLib";
import ZTitle from "../../_subhead/ZTitle";
import Joi from "joi-browser";
import { getHubs } from "../../../services/masters/hubServices";
import { getCities } from "../../../services/masterLocations/locationServices";
import { uploadPincodes } from "../../../services/masterLocations/pincodeServices";
import { getHubsAll } from "../../../services/masterLocations/hubServices";

class PincodesMaster extends FormLib {
  state = {
    data: {
      hub: "5067",
      city: "1111",
      type: "DA",
      pincode: [],
    },
    processState: [],
    errors: {},
    hubs: [],
    cities: [],
    tableData: [],
    error: null,
  };

  schema = {
    city: Joi.string().required().label("City"),
    hub: Joi.string().required().label("Hub"),
    type: Joi.string().required().label("Service Type"),
    pincode: Joi.string().required().label("Pincodes"),
  };

  type = [
    { service_typeId: "DA", type: "DA" },
    { service_typeId: "ODA", type: "ODA" },
    { service_typeId: "NDA", type: "NDA" },
  ];

  async componentDidMount() {
    let list = await getHubsAll();
    this.setState({ hubs: list.data.data.hubs });

    list = await getCities();

    this.setState({ cities: list.data.data.cities });
  }

  // localHandleChange = (e) => {
  //   if (e.target.name === "pincode") {
  //     if (e.target.value.length >= 7) {

  //       let errors = this.state.errors;
  //       errors["pincode"] = `Pincode must be a 6 digits Pincode`;
  //       this.setState({
  //         errors,
  //       });
  //     } else {
  //       let errors = this.state.errors;
  //       delete errors["pincode"];
  //       this.setState({
  //         errors,
  //       });
  //     }

  //     this.validateProperty(e.target.name, e.target.value);
  //   }
  // };

  doSubmit() {
    this.setState({ processState: [] });
    let pinArry = this.state.data.pincode.split(",");

    let t = pinArry.map(c => c.split(', ')
      .reduce((p, n) => p && !!n.match(/^\d{6}$/), true));
    for (let i = 0; i < pinArry.length; i++) {
      // console.log('case:', pinArry[i], t[i] ? 'pass' : 'fail');

      if (pinArry[i], t[i] === "fail") {
        let errors = this.state.errors;
        errors["pincode"] = `Pincode must be a 6 digits Pincode`;
        this.setState({
          errors,
        });
      }
    }
    const tableData = pinArry.map((p) => {
      return {
        pincode: p,
        city: this.state.data.city,
        hub: this.state.data.hub,
        type: this.state.data.type,
      };
    });
    this.setState({ tableData });
  }

  async handleAPIHit() {
    let processState;
    let res;
    this.setState({ processState: [] });

    let params = {
      type: this.state.data.type,
      cityId: this.state.data.city,
      hubId: this.state.data.hub,
    };

    let i = 0;
    for (let r in this.state.tableData) {
      processState = this.state.processState;
      processState[i] = "Processing";
      this.setState({ processState });
      res = await uploadPincodes(this.state.tableData[r].pincode, params);
      processState[i] = res.data.msg;
      i++;
      this.setState({ processState });
    }
  }

  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  render() {
    return (
      <React.Fragment>
        <ZTitle subHeading="Pincodes  :: Create Pincodes" />
        <div className="pageContent">
          <div className="card">
            <div className="card-body">
              <form id="myform" onSubmit={this.handleSubmit}>
                <div className="form-row">
                  {this.rSB(
                    "City *",
                    "city",
                    this.state.cities,
                    { value: "cityId", label: "city" },
                    " col-md-3 "
                  )}
                  {this.rSB(
                    "Hub *",
                    "hub",
                    this.state.hubs,
                    { value: "hubId", label: "hub" },
                    " col-md-3 "
                  )}
                </div>
                <div className="form-row">
                  {this.rRBL("Service Type *", "type", this.type, {
                    value: "service_typeId",
                    label: "type",
                  })}
                </div>

                <div className="form-row">
                  {this.rTA("Pincodes *", "pincode", "col-12")}
                </div>
                <div className="row pull-right">
                  {this.rBtnSP("Stage", "Stage", "btn-primary btn-sm")}
                </div>
              </form>
            </div>

            <table className="col-12 table table-responsive">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Pincodes</th>
                  <th>Service Type</th>
                </tr>
              </thead>
              <tbody>
                {this.state.tableData.map((tableData, i) => (
                  <tr key={tableData.pincode}>
                    <td>{this.state.processState[i]}</td>
                    <td>{tableData.pincode}</td>
                    <td>{tableData.type}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />
          <button
            className="btn  btn-info  btn-sm pull-right"
            onClick={() => this.handleAPIHit()}
          >
            Submit
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default PincodesMaster;
