import _http from "../_httpService";


import config from "../../config.json";
const baseURL = config[config.build].apiURLCore;
function api(path, subURL = "/tracker/settings/products-cat-brands") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getBrands() {
    return _http.get(api(""), _http.headerJWT());
}

export function createBrand(req) {
    const param = { ...req }
    delete param.prodBrandId;
    return _http.post(api(""), param, _http.headerJWT());
}

export function updateBrand(req) {
    const param = { ...req }
    return _http.put(api(""+req.prodBrandId), param, _http.headerJWT());
}

export function deleteBrand(req) {
    return _http.delete(api(""+req.prodBrandId), _http.headerJWT());
}



