import React from "react";
import FormLib from "./../../_common/FormLib";
import toast from "../../../services/_toastService";
import { assign, unassign } from '../../../services/coreItAdmin/PlayerHubsServices';


class PlayerHubMapper extends FormLib {

    state = {
        data: {},
        errors: {},
        curPlayer: {},
        hubs: []
    };

    componentDidMount() {

        this.setState({ curPlayer: this.props.curPlr });
    }

    localHandleChange = (e) => {
        this.asignUnAsignPlayersHubs(e);
    }

    asignUnAsignPlayersHubs = (e) => {
        let paramData = {};
        paramData.playerId = this.state.curPlayer.playerId;
        if (e.target.checked) {
            paramData.hubId = e.target.value;
            paramData.isPrimary = "true";
            this.assignUnassign('assign', paramData);
        } else {
            paramData.hubId = e.target.value;
            paramData.isPrimary = "false";
            this.assignUnassign('unAssign', paramData);
        }
    }

    assignUnassign = async (type, paramData) => {
        if (type === 'assign') {
            const res = await assign(paramData);
            if (res.data.success) {
                toast.toastSuccess(res.data.msg);
            } else {
                toast.toastError(res.data.msg);
            }
            // console.log(res.data);
        } else {
            const res = await unassign(paramData);
            // console.log(res.data);
            if (res.data.success) {
                toast.toastSuccess(res.data.msg);
            } else {
                toast.toastError(res.data.msg);
            }
        }
        this.props.onLoadHubs(this.state.curPlayer.playerId);
    }

    chkStatus = false;
    render() {
        const hubs = this.props.hubs;
        return (
            <div className="card overflow-auto" style={{ height: '30rem' }}>
                <div className="card-body ">
                    <div className="page-todo">
                        <div className="tasks " >
                            {hubs.map((r) => {
                                this.chkStatus = r.playerId === null ? false : true;
                                return <div className="task nil" key={r.hubId}>
                                    <div className="desc">
                                        <span>
                                            <div className="title">
                                                {/* {this.rCBHL("hl", [{ label: r.hub, value: r.hubId }])} */}

                                                {this.rCBHLLS(
                                                    "cb_" + r.hubId,
                                                    [{ label: r.hub, value: r.hubId }],
                                                    { value: "value", label: "label" },
                                                    this.chkStatus,
                                                    "m-0"
                                                )}
                                            </div>
                                        </span>
                                        <div>
                                            <span className={"badge badge-success pull-left mr-1 " + "badge-" +
                                                r.hubCode}>{r.hubCode}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default PlayerHubMapper;