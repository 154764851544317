export function getQuestionFormat(reason, data) {

    switch (reason) {
        case "A part of the product is missing":
            delete data['QC__description'];
            delete data['QC__size'];
            delete data['QC__color'];
            delete data['QC__product_not_used'];
            delete data['QC__product_not_damaged'];
            return data;

        case "Accessory received is defective":
            delete data['QC__brand'];
            delete data['QC__size'];
            delete data['QC__color'];
            delete data['QC__product_not_used'];
            delete data['QC__product_not_damaged'];
            return data;

        case "color issue":
            delete data['QC__description'];
            delete data['QC__size'];
            delete data['QC__color'];
            return data;

        case "Damaged":
            delete data['QC__description'];
            delete data['QC__size'];
            delete data['QC__product_not_damaged'];
            return data;

        case "Dead on arrival":
            delete data['QC__description'];
            delete data['QC__size'];
            delete data['QC__color'];
            return data;

        case "Default":
            delete data['QC__size'];
            return data;

        case "Defective":
            delete data['QC__description'];
            delete data['QC__price_tag'];
            delete data['QC__size'];
            delete data['QC__color'];
            delete data['QC__product_not_used'];
            delete data['QC__product_not_damaged'];
            return data;

        case "Found a better price elsewhere":
            delete data['QC__price_tag'];
            delete data['QC__size'];
            return data;

        case "I changed my mind":
            delete data['QC__size'];
            delete data['QC__description'];
            return data;

        case "I found the product cheaper on another website":
            delete data['QC__size'];
            delete data['QC__description'];
            return data;

        case "Incorrect Product":
            delete data['QC__price_tag'];
            delete data['QC__size'];
            delete data['QC__color'];
            delete data['QC__description'];
            return data;

        case "It doesn't look good on me":
            delete data['QC__description'];
            return data;

        case "Look & feel differs from what I saw on Tata CLiQ":
            delete data['QC__price_tag'];
            delete data['QC__size'];
            delete data['QC__description'];
            delete data['QC__product_not_damaged'];
            return data;

        case "Ordered wrong product by mistake":
            delete data['QC__size'];
            delete data['QC__description'];
            return data;

        case "Part of the order is missing":
            delete data['QC__brand'];
            delete data['QC__size'];
            delete data['QC__color'];
            return data;

        case "Product I have received looks used":
            delete data['QC__color'];
            delete data['QC__product_not_used'];
            delete data['QC__product_not_damaged'];
            return data;

        case "Quality Unsatisfactory":
            delete data['QC__size'];
            delete data['QC__description'];
            delete data['QC__product_not_used'];
            delete data['QC__product_not_damaged'];
            return data;

        case "Size Issue":
            delete data['QC__description'];
            return data;

        case "The product doesn't fit me well":
            delete data['QC__description'];
            return data;

        case "This product does not suit me":
            delete data['QC__description'];
            return data;

        default:
            return data;
    }

}