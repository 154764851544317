import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import * as _plms from "./../../../services/plms/_plmsServices";

import $ from "jquery";

import {
  createUserstory,
  saveUserstory,
  deleteUserstory,
} from "./../../../services/plms/userstoriesServices";

class ModalUserstory extends FormLib {
  state = {
    data: {
      moduleId: "",
      userstoryId: "",
      usTitle: "",
      usDesc: "",
      usEdgeCase: "",
      usAcceptCriteria: "",
      remarks: "",

      usRoles: "",
      usOwner: "BLR0000",
      usPriority: "normal",
      usComplexity: "normal",
      usPoints: "",
      usColumn: "noSprint",
      sort: "",
      startDate: "",
      dueDate: "",
      endDate: "",
      status: "99pipe",
      docs: "",
    },
    errors: {},

    modules: [],
    curUserstory: {},
  };

  schema = {
    userstoryId: Joi.optional().label("userstoryId"),
    moduleId: Joi.string().required().label("Module"),
    usTitle: Joi.string().required().label("Title"),
    usDesc: Joi.optional().label("Desc"),
    usEdgeCase: Joi.optional().label("Edge Case"),
    usAcceptCriteria: Joi.optional().label("Accept Criteria"),
    remarks: Joi.optional().label("Remarks"),
    usRoles: Joi.optional().label("Roles"),
    usOwner: Joi.optional().label("Owner"),
    usPriority: Joi.string().required().label("Priority"),
    usComplexity: Joi.string().required().label("Complexity"),
    usPoints: Joi.optional().label("Points"),
    usColumn: Joi.optional().label("Column"),
    sort: Joi.optional().label("Sort"),
    startDate: Joi.optional().label("start Date"),
    dueDate: Joi.optional().label("Due Date"),
    endDate: Joi.optional().label("End Date"),

    status: Joi.string().required().label("status"),
    docs: Joi.optional().label("Docs"),
  };
  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res = {};
    if (this.state.btnValue === "create") {
      res = await createUserstory(this.state.data);
      // this.resetData(this.props.curModule);
      this.props.onLoadUserstories(this.props.curModule, "downwards");
    }
    if (this.state.btnValue === "edit") {
      res = await saveUserstory(this.state.data);
      this.props.onLoadUserstories(this.props.curModule, "false");
    }
    if (this.state.btnValue === "delete") {
      res = await deleteUserstory(this.state.data);
      $("#curUserstoryButton").click();
      this.resetData(this.props.curModule);
      this.props.onLoadUserstories(this.props.curModule, "downwards");
    }
    toast.toastMsg(res);
  }
  componentDidMount() {
    this.setModules(this.props.modules);
    this.setCurModule(this.props.curModule);
    this.setCurUserstory(this.props.curModule, this.props.curUserstory);
    this.setOwners();
  }

  setModules(modules) {
    this.setState({ modules: modules });
  }
  setCurModule(m) {
    this.setState({ curModule: m });
  }

  setCurUserstory(m, u) {
    if (u.userstoryId && m.userstoryId !== "") {
      this.setState({ data: u });
    } else {
      this.resetData(m);
    }
  }
  setOwners = () => {
    this.setState({ owners: _plms.users.owners });
    this.setState({ ownerConf: _plms.users.ownerConf });
  };

  resetData(m) {
    this.setState({
      data: {
        moduleId: m.moduleId || "",
        userstoryId: "",
        usTitle: "",
        usDesc: "",
        usEdgeCase: "",
        usAcceptCriteria: "",
        usRoles: "",
        usOwner: "BLR0000",
        usPriority: "normal",
        usComplexity: "normal",
        usPoints: "",
        usColumn: "noSprint",
        sort: "",
        startDate: this.getDatebyDays(500),
        dueDate: "",
        endDate: "",
        remarks: "",
        status: "99pipe",
        docs: "",
      },
    });
  }

  status = [
    { sortKey: "00open", sort: "Open" },
    { sortKey: "05assign", sort: "Assign" },
    { sortKey: "08plan", sort: "Plan" },

    { sortKey: "10wip", sort: "WIP" },
    { sortKey: "15hold", sort: "Hold" },
    { sortKey: "20dev", sort: "Dev" },
    { sortKey: "25test", sort: "Test" },
    { sortKey: "30done", sort: "Done" },
    { sortKey: "99pipe", sort: "Pipe" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  priority = [
    { sortKey: "critical", sort: "Critical" },
    { sortKey: "high", sort: "High" },
    { sortKey: "normal", sort: "Normal" },
    { sortKey: "low", sort: "Low" },
  ];
  prtyConf = {
    value: "sortKey",
    label: "sort",
  };

  Complexity = [
    { sortKey: "bottleNeck", sort: "Bottle Neck" },
    { sortKey: "high", sort: "High" },
    { sortKey: "normal", sort: "Normal" },
    { sortKey: "low", sort: "Low" },
  ];
  cmplConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;

    return (
      <div className="container-fluid">
        <div className="modal fade" id="myUserstoryModel">
          <div
            className="modal-dialog modal-lg"
            style={{ marginLeft: 0, maxWidth: "670px" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Userstories</h4>
                <button
                  type="button"
                  id="curUserstoryButton"
                  className="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  <div className="form-row">
                    {this.rTBH("UserstoryId", "userstoryId", "col-4")}
                    {this.rSB(
                      "Modules*",
                      "moduleId",
                      this.state.modules,
                      {
                        value: "moduleId",
                        label: "prModule",
                      },
                      " col-6 "
                    )}

                    {this.rSB(
                      "Owner*",
                      "usOwner",
                      this.state.owners,
                      this.state.ownerConf
                    )}
                    {this.rNB("Sort", "sort", "col-2")}
                    {this.rTB("Column", "usColumn", "col-2")}
                  </div>

                  <div class="collapse" id="collapseExample">
                    <div className="form-row">
                      {/* {this.rTB("Owner", "usOwner", "col-3")} */}
                      {this.rTB("Roles", "usRoles", "col-5")}

                      {this.rNB("Points", "usPoints", "col-2")}
                    </div>
                    <div className="form-row">
                      {this.rTA("Desc*", "usDesc", "col-md-6")}
                      {this.rTA("Edge Case", "usEdgeCase", "col-md-6")}

                      {this.rTA("Criteria", "usAcceptCriteria", "col-md-6")}
                      {this.rTA("Remarks*", "remarks", " form-group col-md-6")}
                    </div>
                    {this.rQuill("docs")}
                    <div className="form-row mt-3">
                      {this.rRBL(
                        "Priority",
                        "usPriority",
                        this.priority,
                        this.prtyConf
                      )}
                      {this.rRBL(
                        "Complexity",
                        "usComplexity",
                        this.Complexity,
                        this.cmplConf
                      )}
                    </div>
                    <div className="form-row">
                      {this.rDP("Start Date*", "startDate", "col-md-4")}
                      {this.rDP("Due Date", "dueDate", "col-md-3")}
                      {this.rDP("End Date", "endDate", "col-md-3")}
                    </div>
                  </div>

                  <div className="form-row">
                    {this.rTB("Title*", "usTitle", "col-12")}
                    {this.rRBL(
                      "Status*",
                      "status",
                      this.status,
                      this.stsConf,
                      " col-11 "
                    )}
                    <div className="form-group col-1">
                      <span
                        class="badge   badge-success pull-right mr-1 badge-normal"
                        data-toggle="collapse"
                        data-target="#collapseExample"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <i
                          className="fa  fa-list-alt fa-12"
                          aria-hidden="true"
                        ></i>
                        <i class="fa-solid fa-layer-group"></i>
                      </span>
                    </div>
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {this.state.data.userstoryId === "" &&
                  this.rBtnSP("Create", "create")}
                {this.state.data.userstoryId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {/* {this.rBtnSD("Delete", "delete")} */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalUserstory;
