import React from "react";
import FormLib from "./../../_common/FormLib";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { getPackingMaterialAndProdByJobId, getPackingMaterialAndProdByJobIdAdvExc, setTicketActionsRTO } from "./../../../services/Tickets/TicketProcessServices";
import { ticketListReload } from "../../../store";
import { connect } from "react-redux";
import { IstToUTC } from "../../../utils/DateUtils";
import Joi from "joi-browser";
import PickUpVerificationProduct from "./PickUpVerificationProduct";

class RTOProcess extends FormLib {
  state = {
    data: {
      actionCode: "wRtoInitiate",
      action: "RTO_INITIATE",
      status: "",
    },
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    closeStatus: "false",
    pageHide: "false",
    rtoItemsVerify:false,
    status: [
      {
        status: "ORDER CANCELLED BY CUSTOMER ",
        statusId: "ORDER_CANCELLED_BY_CUSTOMER",
      },
      {
        status: "ORDER CANCELLED BY CLIENT ",
        statusId: "ORDER_CANCELLED_BY_CLIENT",
      },
      {
        status: "PRODUCT MISMATCH",
        statusId: "PRODUCT_MISMATCH",
      },
      {
        status: "DAMAGED",
        statusId: "DAMAGED",
      },

      {
        status: "DST ADDRESS WRONG",
        statusId: "DST_ADDRESS_WRONG",
      },
      {
        status: "FAILED DELIVERY",
        statusId: "FAILED_DELIVERY",
      },
      {
        status: "LOST",
        statusId: "LOST",
      },
      {
        status: "NON SERVICEABLE",
        statusId: "NON_SERVICEABLE",
      },
      {
        status: "RE ATTEMPTS FAILED",
        statusId: "RE_ATTEMPTS_FAILED",
      },
    ],
  };

  schema = {
    status: Joi.string().required().label("status"),
  };
  componentDidMount() {
    this.loadInit();
    this.getlocalDateTime();
    this.loadPackingMaterialData();
  }
  loadPackingMaterialData  = async () => {
    try {
      let blockData = this.props.blockDetails;
      let ticketData = this.props.ticketDetails;
      let res = await getPackingMaterialAndProdByJobIdAdvExc(blockData, ticketData);
      if (res !== undefined) {
        if (res.data.success === true && res.data.data ) {
          this.getPackingMaterialAndProdStatus(
            res.data.data.packingMaterialDetails
          );
          this.setState({ confirmData: true });
        } else {
          this.setState({ confirmData: false });
        }
      } else {
        this.setState({ confirmData: false });
      }
    } catch {
      console.log(
        "Error in loadPackingMaterialData>>>>>>>>>>>>>>>>>>>>>>>>>>PickUp"
      );
    }
  };

  getPackingMaterialAndProdStatus = async (data) => {
    // console.log("data======>", data)
    if (data.length > 0) {
      data.map((r) => {
        if (r.packMaterialId) {
          if (r.packMaterialItemStatus !== null) {
            this.setState({rtoItemsVerify: true });
            // this.forceUpdate();
          } else {
            this.setState({ rtoItemsVerify: false });
          }
        } else {
          if (r.productDetails[0].itemStatus === "itemsRequestedForRTO") {
            this.setState({ rtoItemsVerify: true });
            // this.forceUpdate();
          } else {
            this.setState({ rtoItemsVerify: false });
          }
        }
      });
    } else {
      this.setState({ dropItemsAssigned: true });
      this.setState({ confirmData: false });
    }
  };

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }

  loadInit() {
    try {
      const curTicketDetails = this.props.ticketDetails;
      const curBlockDetails = this.props.blockDetails;
      const curProductDetails = this.props.productDetails;
      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails });
    } catch {
      console.log("Error in RTOProcess>>>>>>loadInit");
    }
  }

  handleRTO = async () => {
    try {
      swal({
        title: "Are you sure want to Initiate RTO?",
        text: "Kindly Enter the Reason for RTO",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: "input",
        placeholder: "Write something",
      }).then(async (handleRTO) => {
        this.doRTO(handleRTO);
      });
    } catch {
      console.log("Error in RTOProcess>>>>>>handleRTO");
    }
  };

  doRTO = async (handleRTO) => {
    try {
      if (handleRTO) {
        // alert(handleRTO)
        swal(
          "RTO has been successfully Initiated with the following remarks: " +
            handleRTO,
          {
            icon: "success",
          }
        );
        this.submitRTO(handleRTO);
      } else {
        swal("RTO not initiated!", { icon: "error" });
      }
    } catch {
      console.log("Error in RTOProcess>>>>>>doRTO");
    }
  };

  submitRTO = async (handleRTO) => {
    try {
      let resultSet = {
        comments: handleRTO,
        actionStatus: "RTO_INITIATE",
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        rtoStatus: this.state.data.status,
      };
      let data = this.state.data;
      data = {
        actionCode: "wRTOInitiate",
        action: "RTO Initiated",
      };
      this.state.curBlockDetails.direction = "RTO";
      this.setState({ data });

      let resp = await setTicketActionsRTO(
        this.state.data,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        resultSet
      );
      if (resp !== undefined) {
        if (resp.data.success === true) {
          if (resp.data.data) {
            this.handleTicketListReload();
            this.setState({ pageHide: "true" });
            this.setState({ closeStatus: "true" });
            this.setState({ rtoStatus: "RTO INITIATED" });
            // this.setState({ rtoStatus: "false" });
          } else {
            toast.warning(() => <div>{resp.data.msg}</div>);
          }
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      }
    } catch {
      console.log("Error in RTOProcess>>>>>>submitRTO");
    }
  };

  handleRejectRTO = async () => {
    try {
      swal({
        title: "Are you sure want to Reject RTO?",
        text: "Kindly Enter the Reason for Reject RTO",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: "input",
        placeholder: "Write something",
      }).then(async (handleRejectRTO) => {
        this.doRejectRTO(handleRejectRTO);
      });
    } catch {
      console.log("Error in RTOProcess>>>>>>handleRejectRTO");
    }
  };

  doRejectRTO = async (handleRejectRTO) => {
    try {
      if (handleRejectRTO) {
        // alert(handleRejectRTO)
        swal(
          "RTO has been successfully Rejected with the following remarks: " +
            handleRejectRTO,
          {
            icon: "success",
          }
        );
        this.submitRejectRTO(handleRejectRTO);
      } else {
        swal("RTO not Rejected!", { icon: "error" });
      }
    } catch {
      console.log("Error in RTOProcess>>>>>>doRejectRTO");
    }
  };

  submitRejectRTO = async (handleRejectRTO) => {
    try {
      let resultSet = {
        comments: handleRejectRTO,
        actionStatus: "RTO_REJECT",
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        // rtoStatus: this.state.data.status
      };
      let data = this.state.data;
      data = {
        actionCode: "wRTOReject",
        action: "RTO Rejected",
      };
      this.state.curBlockDetails.direction = "DTD";
      this.setState({ data });
      let resp = await setTicketActionsRTO(
        this.state.data,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        resultSet
      );
      if (resp !== undefined) {
        if (resp.data.success === true) {
          if (resp.data.data) {
            this.handleTicketListReload();
            this.setState({ pageHide: "true" });
            this.setState({ closeStatus: "true" });
            this.setState({ rtoStatus: "RTO REJECTED" });
            // this.setState({ rtoStatus: "false" });
          } else {
            toast.warning(() => <div>{resp.data.msg}</div>);
          }
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      }
    } catch {
      console.log("Error in RTOProcess>>>>>>submitRejectRTO");
    }
  };

  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };

  setCurTicketCloseStatus = (rtoStatus) => {
    this.setState({ rtoStatusId: rtoStatus.status });
    this.state.data.status = rtoStatus.statusId;
  };

  render() {
    const ticketDetails = this.state.curTicketDetails;
    return (
      <>
        {this.state.pageHide === "false" && (
          <div className="row mt-4 ">
            <div className="col-md-12">
              <div className="card ">
                <div className="card-body bg-light">
                  <div className="form-subHeader">
                    <b>Ticket RTO Verification - {ticketDetails.ticketNo}</b>
                  </div>
                  <b>&nbsp;&nbsp;RTO with the following Status :</b>
                  {this.state.confirmData === true&&(
                                  <PickUpVerificationProduct
                                  productDetails={this.state.curProductDetails}
                                  ticketDetails={this.state.curTicketDetails}
                                  blockDetails={this.state.curBlockDetails}
                                  key={
                                    "p_" +
                                    this.state.curBlockDetails.ticketBlockId
                                  }
                                  onHandleGetConfirmStatus={
                                    this.loadPackingMaterialData
                                  }
                                />
                              )}
                  {this.state.rtoItemsVerify === true &&( 
                    <div>          
                  <div id="cocards" className="col-8  ml-1 mr-4">
                    <div id="primary-ticket-search" className="row ">
                      {this.state.status.map((t) => {
                        return (
                          <span key={t.statusId + "spanCover1"}>
                           
                            <div
                              className="card mb-2 cursorPointor "
                              onClick={() => this.setCurTicketCloseStatus(t)}
                            >
                              <div
                                className="card-header page-todo task"
                                style={
                                  t.status &&
                                  t.status === this.state.rtoStatusId
                                    ? { backgroundColor: "rgb(255 225 202)" }
                                    : { backgroundColor: "white" }
                                }
                              >
                                <div className="col-6 zoom">{t.status}</div>
                              </div>
                            </div>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  
                  <div className="row">
                    <div className="col-md-4">
                      {this.state.data.status === "" && (
                        <button
                          className="btn btn-success btn-lg btn-block"
                          type="button"
                          onClick={() => this.handleRTO()}
                          disabled="true"
                        >
                          RTO INITIATE
                        </button>
                      )}
                      {this.state.data.status !== "" && (
                        <button
                          className="btn btn-success btn-lg btn-block"
                          type="button"
                          onClick={() => this.handleRTO()}
                        >
                          RTO INITIATE
                        </button>
                      )}
                    </div>

                    <div className="col-md-4">
                      <button
                        className="btn btn-warning btn-lg btn-block"
                        type="button"
                        onClick={() => this.handleRejectRTO()}
                      >
                        RTO REJECT
                      </button>
                    </div>
                  </div>
                  
                </div>
                )}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* {this.state.pageHide === "true" && (<div className="jumbotron jumbotron-fluid">
                    <p className="lead text-center">Kindly Complete the Previous Process</p>
                </div>)
                } */}
        {this.state.closeStatus === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Ticket Number: <b>{ticketDetails.ticketNo}</b> has{" "}
              <b>{this.state.rtoStatus}</b> Successfully.
            </p>
          </div> 
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RTOProcess);
