import React from "react";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
// import ZTitle from "../../_subhead/ZTitle";
import FormLib from "../../../_common/FormLib";
import Joi from "joi-browser";
import {
  getHubs,
  getClients,
  getActivity,
  getFlows,
  getHubsActive,
} from "../../../../services/masters/hubServices";
import _auth from "../../../../services/_authService";
import {
  createRequest,
  getTicketsList,
  getTicketsListNew,
  getEmployeesList,
  getVendorsList,
} from "../../../../services/requestV1/requestServices";
import { toast } from "react-toastify";
import swal from "@sweetalert/with-react";
import Table from "../../../_common/Table";

class TicketDetailsList extends FormLib {
  subHeading = "User";

  state = {
    ticketJSON: [],
  };

  tableMeta = {
    key: "ticketNo",
    columns: [
      { path: "actionNo", label: "ActionNo." },
      { path: "clientName", label: "Client" },
      { path: "ticketNo", label: "Ticket Number" },
      { path: "flowId", label: "Activity" },
      {
        key: "flowStatus",
        action: (r) => (
          <b>
            {(r.flowStatus === "PICK_WEB" || r.flowStatus === "PICK_MOB") &&
              r.blockStatus !== "LINEHAUL" &&
              r.blockStatus !== "LINEHAUL_IN_TRANSIT" &&
              "First Mile"}{" "}
            {(r.blockStatus === "LINEHAUL" ||
              r.blockStatus === "LINEHAUL_IN_TRANSIT") &&
              "Middle Mile"}{" "}
            {(r.flowStatus === "DROP_WEB" || r.flowStatus === "DROP_MOB") &&
              r.blockStatus !== "LINEHAUL" &&
              r.blockStatus !== "LINEHAUL_IN_TRANSIT" &&
              "Last Mile"}
          </b>
        ),
        cusClass: "",
        icon: () => <>Flow</>,
      },

      {
        key: "blockStatus",
        action: (r) => <b>{this.replaceString(r.blockStatus)}</b>,
        cusClass: "",
        icon: () => <>Job Status</>,
      },
    ],
  };

  replaceString = (str) => {
    if (str) {
      str = str.replace(/_/g, " ");
      return str.replace("BLOCK", "JOB");
    }
  };
  async componentDidMount() {
    this.setCurTicketDetailsList();
  }

  setCurTicketDetailsList = async () => {
    // alert(JSON.stringify(this.props.ticketDetails));
    this.setState({ ticketJSON: this.props.ticketDetails });
  };

  render() {
    return (
      <React.Fragment>
        <div className="row pull-right">
          <div className="col-12">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.props.closeModal}
            >
              <span aria-hidden="true"> Ticket Details &times;</span>
            </button>
            <div className="card">
              <div className="card-header bg-success text-white ">
                <div className="row">
                  <div className="col-4"></div>
                  <div className="col-4 text-right"></div>
                </div>
                <div className="row">
                  <div className="col-4">{"Ticket Details"}</div>
                  <div className="col-4 text-right"></div>
                </div>
              </div>

              {/* End of Header */}

              <div
                className="card-body border"
                style={{ backgroundColor: "rgb(255 255 255)" }}
              >
                <div className="row">
                  <div className="col-4"></div>
                  <div className="container-fluid">
                    <div style={{ overflow: "auto" }}>
                      {/* {this.state.ticketJSON > 0 && ( */}
                      <Table
                        clsName="table table-striped table-bordered table-hover"
                        tableMeta={this.tableMeta}
                        data={this.state.ticketJSON}
                        dropMenu={[]}
                      />
                      {/* )} */}
                    </div>
                  </div>
                </div>

                {/* <>
              <div className="card-body">
                <div className="form-group col-md-12 text-center">
                  <p className="mt-5 mb-3 text-muted">&copy; Bizlog</p>
                </div>
              </div>
            </> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default TicketDetailsList;
