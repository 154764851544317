import React from "react";
import FormLib from "./../../_common/FormLib";
import $ from "jquery";

class DbFields extends FormLib {
  state = {
    data: {},
    errors: {},
  };

  mouseOver(f) {
    console.log("show");
    $("#fi_" + f.fieldId).show();
  }
  mouseOut(f) {
    $("#fi_" + f.fieldId).hide();
  }

  render() {
    return (
      <>
        <div className="card-body ">
          <ul className="list-group list-group-flush">
            {this.props.fields.map((f) => {
              return (
                <li
                  className="list-group-item"
                  onMouseOver={() => this.mouseOver(f)}
                  onMouseOut={() => this.mouseOut(f)}
                >
                  {f.field}
                  <span className="badge  badge-light pull-right">
                    {f.sort}
                  </span>
                  <div
                    class="panel-group widget-request col-md-12 fieldsInfo"
                    id={"fi_" + f.fieldId}
                  >
                    <div class="panel panel-info">
                      {f.key === "yes" && (
                        <span className="badge  badge-normal mr-1 pull-left">
                          PK
                        </span>
                      )}
                      {f.type}
                      {f.isNull === "yes" && (
                        <span className="badge  badge-normal mr-1 pull-right">
                          NULL
                        </span>
                      )}
                      {f.isNull !== "yes" && (
                        <span className="badge  badge-normal mr-1 pull-right">
                          Not NULL
                        </span>
                      )}
                      <br />
                      {f.pField && (
                        <>
                          <span className="badge  badge-high mr-1 pull-right text-white">
                            FK
                          </span>
                          <span className="badge  badge-high mr-1 pull-right text-white">
                            {f.pField}
                          </span>
                          <span className="badge  badge-high  pull-right text-white">
                            {f.pTable}
                          </span>
                        </>
                      )}
                      <br />
                      {f.extra && (
                        <div class=" alert-light">
                          {f.extra}
                          <hr />
                        </div>
                      )}
                      {f.extra && <div class=" alert-light">{f.details}</div>}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  }
}

export default DbFields;
