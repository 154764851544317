import _http from "../_httpService";

import config from "../../config.json";

const baseURL = config[config.build].apiURLPrevilages;
function api(path, subURL = "/players") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getPlayers() {
  return _http.get(api(""), _http.headerJWT());
}
export function createPlayer(req) {
  const param = { ...req };
  delete param.playerId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function savePlayer(req) {
  const param = { ...req };
  return _http.put(api("update"), param, _http.headerJWT());
}
export function deletePlayer(req) {
  const param = { ...req };
  return _http.delete(api(param.playerId), _http.headerJWT());
}
export function getPlayersByTeam(teamId, pageNo) {
  return _http.get(api(teamId) + "/" + 200 + "/" + pageNo, _http.headerJWT());
}

export function getPlayersSearch(data, pageNo) {
  return _http.put(api("searchPlayer/200/" + pageNo), data, _http.headerJWT());
}

export function changePassword(req, data) {
  let param = {
    password: req.newPassword,
    playerId: data.playerId,
  };
  return _http.put(api("update/password"), param, _http.headerJWT());
}
export function changePasswords(req, data) {
  let param = {
    password: req.newPassword,
    playerId: data.playerId,
  };
  // console.log(JSON.stringify(param));
  return _http.put(api("update/password"), param, _http.headerJWT());
}

export function updateAuth(req, data) {
  let param = {
    username: req.userName,
    password: req.passWord,
    playerId: data.playerId,
  };
  return _http.put(api("update/usernamepassword"), param, _http.headerJWT());
}
