import React from "react";
import ZTitle from "../../_subhead/ZTitle";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import Table from "../../_common/Table";
import { dropMenuFull } from "./../../../utils/DropMenuUtils";

import { getProjects } from "./../../../services/demo/demoServices";

class DynamicElements extends FormLib {
  state = {
    data: { eleRows: 5 },
    errors: {},
    projects: [],
  };
  schema = {
    eleRows: Joi.string().label("Username"),
  };

  localHandleChange = (input) => {
    // console.log(input.value);
    // console.log(this.state.data);
  };

  async componentDidMount() {
    //const projects = await getProjects();
    //this.setState({ projects: projects.data.projects });
  }

  handleGetProjects = async () => {
    try {
      const projects = await getProjects();
      this.setState({ projects: projects.data.projects });
    } catch (ex) {}
  };

  tableMeta = {
    key: "projectId",
    columns: [
      { path: "projectId", label: "projectId" },
      { path: "project", label: "project" },
      { path: "prOwner", label: "Owner" },
      { path: "dueDate", label: "DueDate" },
    ],
  };

  render() {
    // const n = 8;
    return (
      <React.Fragment>
        <ZTitle subHeading="Dynamic Elements" />

        {/* 
             { [...Array(n)].map((elementInArray, i) => ( 
    <div className="" key={i}> Whatever needs to be rendered repeatedly 
    {this.state.data.eleRows} </div> 
    ) 
)}

{ [...Array(this.state.data.eleRows)].map((elementInArray, i) => ( 
    <div className="" key={i}> Whatever needs to be rendered repeatedly </div> 
    ) 
)} */}
        <button
          className="page-link ml-5 mt-2"
          onClick={() => this.handleGetProjects()}
        >
          Projects
        </button>

        <Table
          clsName="table table-striped table-bordered table-hover"
          tableMeta={this.tableMeta}
          data={this.state.projects}
          dropMenu={dropMenuFull()}
        />

        <form onSubmit={this.handleSubmit}>
          {this.rTB("No of Rows", "eleRows", "col-md-4")}
          <div className="form-row">
            {this.rTB("L", "length", "col-3")}
            {this.rTB("B", "width", "col-3")}
            {this.rTB("H", "height", "col-3")}
          </div>
        </form>
      </React.Fragment>
    );
  }
}

export default DynamicElements;
