import _http from "../_httpService";

import config from "../../config.json";

const baseURL = config[config.build].apiURLCore;
function api(path, subURL = "/tracker/settings/products") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getAllProducts() {
    return _http.get(api(""),_http.headerJWT());
}
export function getAllProductsList() {
    return _http.get(api("all"),_http.headerJWT());
}

export function getProducts(prodCatId,pageNo) {
    let URL = "all/"+prodCatId+"/10/"+pageNo;
    return _http.get(api(URL), _http.headerJWT());
}

export function getProductsBySearch(data,pageNo) {
    let URL = "all/searchProduct/10/"+pageNo;
    return _http.put(api(URL),data, _http.headerJWT());
}

export function createProduct(req) {
    const param = { ...req }
    delete param.productId;
    return _http.post(api(""), param, _http.headerJWT());
}

export function updateProduct(req) {
    const param = { ...req }
    return _http.put(api(""+req.productId), param, _http.headerJWT());
}

export function deleteProduct(req) {
    return _http.delete(api(""+req.productId), _http.headerJWT());
}