import { getHubs, getClients } from "./../masters/hubServices";

function onGetNetStatus() {
  return 1;
}
export function getHistoryRowCount() {
  return 1;
}
export function getHistoryRowRunningCount() {
  return 0;
}
export function getSyncActionTime() {
  return "2021-01-05 13:00:00";
}
export function getExpiryTime(minusMin) {
  return "2021-01-05 13:00:00";
}

export function setSyncActionTime(minusMin) {
  return "2021-01-05 13:00:00";
}

export function upSyncActionStart() {
  console.log("================= Pre-Works  ===================");

  if (onGetNetStatus() !== 1) {
    return "noInternet";
  }

  //Get Count from History Table
  //Return if count = 0
  if (getHistoryRowCount() <= 0) {
    return "noRows";
  }

  //Get Count from History Table where status = open
  //Return if count > 1
  if (getHistoryRowRunningCount() > 0) {
    return "running";
  }

  if (getExpiryTime > getSyncActionTime) {
    return "runNotRequired";
  }

  //Call Sync Method
  upSyncActions();
}

async function upSyncActions() {
  console.log("================= Start Upsync  ===================");
  let list = await getHubs();
  let hubs = list.data.data.hubs;
  let nextAction = true;

  hubs.map(async (r) => {
    if (nextAction === true) {
      await getClients();
      if (r.hubId === "5067") {
        nextAction = false;
      }
      // console.log(r);
    }
  });
}
