import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import swal from "sweetalert";
import Table from "../../_common/Table";
import $ from "jquery";
import Pagination from "./../../_common/Pagination";

import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";
import {
  getPlayers,
  createPlayer,
  savePlayer,
  deletePlayer,
} from "../../../services/players/playerServices";
import { getPlayersByTeam } from "../../../services/players/playerServices";
import { getTeams } from "./../../../services/players/teamServices";
import PasswordChange from "./PasswordChange";
import EditAuth from "./EditAuth";

class Players extends FormLib {
  state = {
    data: {
      playerId: "",
      teamId: "",
      firstname: "",
      lastname: "",
      nickname: "",
      phone: "",
      phoneAlt: "",
      email: "",
      emailAlt: "",
      status: "active",
    },
    errors: {},
    formMode: "create",
    teams: [],
    players: [],
    loading: true,
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    curTeam: { teamId: "" },
    curPlayer: { playerId: "" },
  };
  schema = {
    playerId: Joi.optional().label("PlayerId"),
    teamId: Joi.string().required().label("TeamId"),
    firstname: Joi.string()
      .regex(/^[a-zA-Z ]*$/)
      .required()
      .label("FirstName")
      .error(() => {
        return {
          message: "Allow only characters.",
        };
      }),
    lastname: Joi.string()
      .regex(/^[a-zA-Z ]*$/)
      .required()
      .label("LastName")
      .error(() => {
        return {
          message: "Allow only characters.",
        };
      }),
    nickname: Joi.string().required().label("NickName"),
    phone: Joi.string()
      .regex(/^[0-9]{10}$/)
      .required()
      .label("Phone")
      .error(() => {
        return {
          message: "Allow only 10 digit numbers.",
        };
      }),
    phoneAlt: Joi.any().optional().label("Phone Alt"),
    email: Joi.string().required().label("Email").email({ minDomainAtoms: 2 }),
    emailAlt: Joi.any().optional().label("Email Alt"),
    status: Joi.string().required().label("Satus"),
  };
  componentDidMount() {
    this.loadTeams();
  }

  loadTeams = async () => {
    this.setState({ loading: true });
    const res = await getTeams();
    if (res.data.success === true) {
      this.setState({ teams: res.data.data.teams });
      // console.log(this.state.teams);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  handlePageChange = (pageNo) => {
    this.props.history.push("/players/players/" + pageNo);
    this.loadPlayers(this.state.curTeam.teamId, pageNo);
  };

  handleOnPlayer = (r) => {
    let players = {};
    players.playerId = r.playerId;
    players.teamId = this.state.curTeam.teamId;
    players.firstname = r.firstname;
    players.lastname = r.lastname;
    players.nickname = r.nickname;
    players.phone = r.phone;
    players.phoneAlt = r.phoneAlt;
    players.email = r.email;
    players.emailAlt = r.emailAlt;
    players.status = r.status;

    this.setState({
      data: players,
      formMode: "edit",
      curPlayer: r,
    });
    $("#password").attr("disabled", "disabled");
  };

  doSubmit = async () => {
    let res;
    let param = this.state.data;
    param.username = "";
    param.password = "";

    if (this.state.btnValue === "create") {
      res = await createPlayer(param);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      delete param.username;
      delete param.password;
      res = await savePlayer(param);
      this.resetData();
    }

    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadPlayers(this.state.curTeam.teamId, this.state.pageLinks.pageNo);
    } else {
      toast.toastError(res.data.msg);
    }
  };

  doSubmitDelete = async (handleDelete, r) => {
    if (handleDelete) {
      let res = await deletePlayer(r);
      if (res.data.success) {
        this.loadPlayers(
          this.state.curTeam.teamId,
          this.state.pageLinks.pageNo
        );
        swal("Player has been deleted!", {
          icon: "success",
        });
        this.resetData();
      }
    } else {
      swal("Player is safe!");
    }
  };

  handleDelete = async (r) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, r);
    });
  };

  handleView = (r) => {
    this.setState({ curPlayer: r, formMode: "view" });
  };

  loadPlayers = async (teamId, pageNo) => {
    this.setState({ loading: true });
    const res = await getPlayersByTeam(teamId, pageNo);
    if (res.data.success === true) {
      if (res.data.data.length != 0) {
        this.setState({ players: res.data.data.players });
        this.setState({ pageLinks: res.data.data.pageLinks });
        toast.toastSuccess(res.data.msg);
      } else {
        this.setState({ players: [] });
        toast.toastInfo("No data found for selected team");
      }
    } else {
      this.setState({ players: [] });
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  loadCurrectPlayer = async (teamId, modReset = true) => {
    if (modReset === true) {
      this.setState({ players: [] });
      this.setState({ curTeam: { teamId: "" } });
    }

    let currentTeam;
    const filter = this.state.teams.filter((r) => {
      return r.teamId === teamId ? r : null;
    });
    if (!filter[0]) {
      currentTeam = { teamId: "" };
    } else {
      currentTeam = filter[0];
    }

    this.setState({ curTeam: currentTeam });
    if (teamId !== "") {
      this.loadPlayers(teamId, this.state.pageLinks.pageNo);
    } else {
      this.setState({ players: [] });
      this.setState({
        pageLinks: {
          pageNo: 1,
          perPage: 0,
          itemsCount: 0,
          lastPage: 0,
        },
      });
    }
  };

  handleTeamChange = (e) => {
    this.loadCurrectPlayer(e.target.value);
  };

  localHandleChange = (e) => {
    if (e.target.id === "teamId") {
      this.handleTeamChange(e);
    }
  };

  resetData() {
    this.setState({
      data: {
        playerId: "",
        teamId: this.state.curTeam.teamId,
        firstname: "",
        lastname: "",
        nickname: "",
        phone: "",
        phoneAlt: "",
        email: "",
        emailAlt: "",
        status: "active",
      },
      formMode: "create",
    });
    $("#password").removeAttr("disabled");
  }

  cleareField = () => {
    this.setState({
      data: {
        firstname: "",
        lastname: "",
        nickname: "",
        phone: "",
        phoneAlt: "",
        email: "",
        emailAlt: "",
        status: "active",
      },
      formMode: "create",
    });
  };

  tableMeta = {
    key: "playerId",
    columns: [
      // { path: "teamId", label: "Team Id" },
      {
        key: "PLAYER_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnPlayer(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true" />
                Edit
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleView(r)}
              >
                <i className="fa fa-binoculars" aria-hidden="true" /> View
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(r)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i> Delete
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      { path: "firstname", label: "First Name" },
      { path: "lastname", label: "Last Name" },
      { path: "nickname", label: "Nick Name" },
      { path: "username", label: "Username" },
      { path: "phone", label: "Phone No" },
      { path: "email", label: "Email Id" },
      { path: "status", label: "status" },
    ],
  };
  typConf = {
    value: "sortKey",
    label: "sort",
  };
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Players" />
        <div className="pageContent">
          <div className="row">
            <div className="col-6 ml-3">
              {this.rSB("Team", "teamId", this.state.teams, {
                value: "teamId",
                label: "name",
              })}
            </div>
            <div className="col-5">
              {this.state.curTeam.teamId !== "" &&
                this.state.formMode === "edit" &&
                this.state.curPlayer.username === "" && (
                  <span
                    className="btn btn-sm btn-outline-success pull-right"
                    data-toggle="modal"
                    data-target="#myMaterialModalEditAuth"
                  >
                    <i className="fa fa-pencil mr-2" aria-hidden="true" />
                    Edit Auth
                  </span>
                )}
              {this.state.curTeam.teamId !== "" &&
                this.state.formMode === "edit" &&
                this.state.curPlayer.username.trim() !== "" && (
                  <span
                    className="btn btn-sm btn-outline-success pull-right"
                    data-toggle="modal"
                    data-target="#myMaterialModal"
                  >
                    <i className="fa fa-lock mr-2" aria-hidden="true" />
                    Change Password
                  </span>
                )}
            </div>
          </div>

          {this.state.curTeam.teamId === "" && (
            <div class="jumbotron jumbotron-fluid">
              <p class="lead text-center">Select Team</p>
            </div>
          )}

          {this.state.curTeam.teamId !== "" && (
            <div className="row">
              <div className="col-md-8 col-sm-12">
                {this.state.loading && (
                  <div className="container-fluid">
                    <div>Loading Players</div>
                  </div>
                )}
                {!this.state.loading && (
                  <div className="container-fluid">
                    <Table
                      clsName="table table-striped tabele-bordered table-hover"
                      tableMeta={this.tableMeta}
                      data={this.state.players}
                      dropMenu={[]}
                    />
                    <Pagination
                      pageNo={pageNo}
                      perPage={perPage}
                      itemsCount={itemsCount}
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-4 col-sm-12">
                {this.state.formMode === "view" && (
                  <div className="card">
                    <div className="card-body">
                      <>
                        {this.state.curPlayer["firstname"].trim()}
                        <br />
                        <div className="pull-right">
                          <span
                            className={"badge   badge-light pull-left ml-1 "}
                          >
                            {/* {this.state.curPlayer["playerId"]} */}
                          </span>
                          <span
                            className={"badge   badge-success pull-left  mr-1 "}
                          >
                            {this.state.curPlayer["status"]}
                          </span>
                        </div>
                      </>
                    </div>
                  </div>
                )}
                {this.state.formMode !== "view" && (
                  <div className="card pt-3">
                    <div className="card-body">
                      <form>
                        {this.rSB("Team *", "teamId", this.state.teams, {
                          value: "teamId",
                          label: "name",
                        })}
                        <div className="form-row">
                          {this.rTBH("PlayerId", "playerId")}
                          {/* {this.rTB("TeamId", "teamId", "col-4")} */}

                          {this.rTB("FirstName *", "firstname", "col-12")}
                          {this.rTB("LastName *", "lastname", "col-12")}
                          {this.rTB("NickName *", "nickname", "col-12")}
                          {this.rTB("Phone Number *", "phone", "col-12")}
                          {this.rTB("Phone Number Alt", "phoneAlt", "col-12")}
                          {this.rTB("Email Id *", "email", "col-12")}
                          {this.rTB("EmailId Alt", "emailAlt", "col-12")}
                          {this.rRBL(
                            "Status*",
                            "status",
                            this.status,
                            this.stsConf
                          )}
                        </div>
                      </form>
                      {/* <div className="form-row">
                          {this.rTB("Username", "username", "col-4")}
                          {this.rTB("Password", "password", "col-4")}
                          {this.rRBL("Status", "status", this.status, this.stsConf)}
                        </div> */}
                      {this.state.formMode === "create" &&
                        this.rBtnSP("Create", "create")}
                      {this.state.formMode === "edit" && (
                        <>{this.rBtnSS("Edit", "edit")}</>
                      )}
                      <button
                        type="button"
                        className="btn btn-sm btn-warning pull-right"
                        onClick={() => this.resetData()}
                        title="Reset"
                      >
                        <i className={"fa fa-unlink"} />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {/* <span>{JSON.stringify(this.state.data)}</span> */}
        </div>
        <PasswordChange
          key={this.state.curPlayer["playerId"]}
          curPlr={this.state.curPlayer}
          onLoadPlayer={this.loadPlayers}
          onLoadPage={this.state.pageLinks.pageNo}
          onReset={this.resetData}
        />
        <EditAuth
          key={this.state.curPlayer["playerId"] + 1}
          curPlr={this.state.data}
          onLoadPlayer={this.loadPlayers}
          onLoadPage={this.state.pageLinks.pageNo}
          onReset={this.resetData}
        />
      </React.Fragment>
    );
  }
}
export default Players;
