import _http from "./_httpService";

import config from "./../config.json";

const apiURLCorePHP = config[config.build].apiURLCorePHP + "users";

function buildUrl(id) {
  return `${apiURLCorePHP}/${id}`;
}

export function getUsers() {
  return _http.get(apiURLCorePHP, _http.headerJWT());
}

export function getUser(key) {
  return _http.get(buildUrl(key), _http.headerJWT);
}

export function saveUser(item) {
  if (item._id) {
    const body = { ...item };
    delete body._id;
    return _http.put(buildUrl(item._id), body);
  }

  return _http.post(apiURLCorePHP, item);
}

export function deleteUser(key) {
  return _http.delete(buildUrl(key));
}

export function fakeGetUser(pageNo) {
  return {
    status: 1,
    msg: "Users List",
    data: {
      users: [
        {
          reqcode: pageNo + "01",
          hub: "Shanti Nagar",
          customer: "Dore",
          activity: "One Way",
        },
        {
          reqcode: pageNo + "02",
          hub: "Basavanagudi",
          customer: "Akshay",
          activity: "Two Way",
        },
        {
          reqcode: pageNo + "03",
          hub: "Jayanagar",
          customer: "Ramachandra",
          activity: "BuyBack",
        },
        {
          reqcode: pageNo + "04",
          hub: "JP Nagar",
          customer: "Manmohan",
          activity: "Adv Exchange",
        },
        {
          reqcode: pageNo + "05",
          hub: "Ullal",
          customer: "Yaj",
          activity: "Furniture",
        },
        {
          reqcode: pageNo + "06",
          hub: "Basavanagudi",
          customer: "Akshay",
          activity: "BulkQC",
        },
        {
          reqcode: pageNo + "07",
          hub: "JP Nager",
          customer: "Manmohan",
          activity: "BulkDropOff",
        },
        {
          reqcode: pageNo + "08",
          hub: "Jayanagar",
          customer: "Ramachandra",
          activity: "BuyBack",
        },
      ],
      pageLinks: {
        pageNo: pageNo,
        perPage: 8,
        itemsCount: 80,
        lastPage: 10,
      },
    },
  };
}

export function fakeGetUserAll() {
  return {
    status: 1,
    msg: "Users List",
    data: {
      users: [
        {
          reqcode: "001",
          hub: "Shanti Nagar",
          customer: "Dore",
          activity: "One Way",
        },
        {
          reqcode: "002",
          hub: "Basavanagudi",
          customer: "Akshay",
          activity: "Two Way",
        },
        {
          reqcode: "003",
          hub: "Jayanagar",
          customer: "Ramachandra",
          activity: "BuyBack",
        },
        {
          reqcode: "004",
          hub: "JP Nagar",
          customer: "Manmohan",
          activity: "Adv Exchange",
        },
        {
          reqcode: "005",
          hub: "Ullal",
          customer: "Yaj",
          activity: "Furniture",
        },
        {
          reqcode: "006",
          hub: "Basavanagudi",
          customer: "Akshay",
          activity: "BulkQC",
        },
        {
          reqcode: "007",
          hub: "JP Nager",
          customer: "Manmohan",
          activity: "BulkDropOff",
        },
        {
          reqcode: "008",
          hub: "Jayanagar",
          customer: "Ramachandra",
          activity: "BuyBack",
        },
      ],
    },
  };
}
