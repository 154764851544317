import * as act from "./appReducerTypes";

const getInitState = () => {
  return {
    loading: false,
    error: "",
  };
};

const initialState = {
  user: getInitState(),
  countries: getInitState(),
  states: getInitState(),
  cities: getInitState(),
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case act.SET_LOADING:
      return {
        ...state,
        [action.payload.comp]: {
          ...state[action.payload.comp],
          loading: true,
        },
      };
    case act.SET_SUCCESS:
      return {
        ...state,
        [action.payload.comp]: {
          ...state[action.payload.comp],
          loading: false,
          error: "",
        },
      };
    case act.SET_ERROR:
      return {
        ...state,
        [action.payload.comp]: {
          ...state[action.payload.comp],
          loading: false,
          error: action.payload.error,
        },
      };
    case act.UNSET_LOADING:
      return {
        ...state,
        [action.payload.comp]: {
          ...state[action.payload.comp],
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default appReducer;
