import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import $ from "jquery";

import {
  createTask,
  saveTask,
  deleteTask,
} from "./../../../services/plms/taskServices";

class ModalTask extends FormLib {
  state = {
    data: {
      taskId: "",
      task: "",
      userstoryId: "",
      taskType: "API",
      tskOwner: "",
      tskPoints: "",
      sort: "",
      specialPoints: "",
      moreInfo: "",
      estimatedTime: "00:00",
      actualTime: "00:00",
      startDate: this.getDatebyDays(500),
      dueDate: "",
      endDate: "",
      testUrl: "",
      request: "",
      response: "",
      status: "99pipe",
    },
    errors: {},
    userstories: [],
    curUserstory: {},
    curTask: {},
  };

  schema = {
    taskId: Joi.optional().label("taskId"),
    userstoryId: Joi.string().required().label("userstoryId"),
    task: Joi.string().required().label("task"),
    taskType: Joi.string().required().label("taskType"),
    tskOwner: Joi.optional().label("tskOwner"),
    tskPoints: Joi.optional().label("tskPoints"),
    sort: Joi.optional().label("sort"),
    specialPoints: Joi.optional().label("specialPoints"),
    moreInfo: Joi.optional().label("moreInfo"),
    estimatedTime: Joi.optional().label("estimatedTime"),
    actualTime: Joi.optional().label("actualTime"),
    startDate: Joi.string().required().label("startDate"),
    dueDate: Joi.optional().label("dueDate"),
    endDate: Joi.optional().label("endDate"),
    testUrl: Joi.optional().label("testUrl"),
    request: Joi.optional().label("request"),
    response: Joi.optional().label("response"),
    status: Joi.string().required().label("status"),
  };
  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createTask(this.state.data);
      this.props.onLoadTasks(this.props.curUserstory);
      this.resetData(this.state.data);
    }
    if (this.state.btnValue === "edit") {
      res = await saveTask(this.state.data);
      this.props.onLoadTasks(this.props.curUserstory, false);
    }
    if (this.state.btnValue === "delete") {
      res = await deleteTask(this.state.data);
      $("#curTaskButton").click();

      this.resetData(this.props.curUserstory);
      this.props.onLoadTasks(this.props.curUserstory);
    }
    toast.toastMsg(res);
  }
  componentDidMount() {
    this.setUserstories(this.props.userstories);
    this.setCurUserstory(this.props.curUserstory);
    this.setCurTask(this.props.curUserstory, this.props.curTask);
  }
  setUserstories(userstories) {
    this.setState({ userstories: userstories });
  }
  setCurUserstory(u) {
    this.setState({ curUserstory: u });
    this.resetData(u);
  }
  setCurTask(u, t) {
    if (t.taskId && t.taskId !== "") {
      this.setState({ data: t });
    } else {
      this.resetData(u);
    }
  }

  owners = [
    { sortKey: "BLR0000", sort: "No Owner" },
    { sortKey: "BLR0278", sort: "Asha" },
    { sortKey: "BLR0197", sort: "Doreswamy" },
    { sortKey: "BLR0203", sort: "Manoj" },
    { sortKey: "INT0006", sort: "Meenakshi" },
    { sortKey: "INT0011", sort: "Mohan" },
    { sortKey: "BLR0196", sort: "Prasanna" },
    { sortKey: "BLR0315", sort: "Rakesh" },
    { sortKey: "BLR0261", sort: "Sateesh" },
  ];
  ownerConf = {
    value: "sortKey",
    label: "sort",
  };

  resetData(u) {
    this.setState({
      data: {
        taskId: "",
        task: "",
        userstoryId: u.userstoryId || "",
        taskType: u.taskType || "",
        tskOwner: u.tskOwner || "BLR0000",
        tskPoints: "",
        sort: "",
        specialPoints: "",
        moreInfo: "",
        estimatedTime: "00:00",
        actualTime: "00:00",
        startDate: this.getDatebyDays(1),
        dueDate: "",
        endDate: "",
        testUrl: "",
        request: "",
        response: "",
        status: u.status || "99pipe",
      },
    });
  }
  status = [
    { sortKey: "00open", sort: "Open" },
    { sortKey: "05assign", sort: "Assign" },
    { sortKey: "08plan", sort: "Plan" },

    { sortKey: "10wip", sort: "WIP" },
    { sortKey: "15hold", sort: "Hold" },
    { sortKey: "20dev", sort: "Dev" },
    { sortKey: "25test", sort: "Test" },
    { sortKey: "30done", sort: "Done" },
    { sortKey: "99pipe", sort: "Pipe" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  taskType = [
    { sortKey: "API", sort: "API" },
    { sortKey: "UI", sort: "UI" },
    { sortKey: "DB", sort: "DB" },
    { sortKey: "Cloud", sort: "Cloud" },
    { sortKey: "PM", sort: "Project" },
  ];
  tskConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;

    return (
      <div className="container-fluid">
        <div className="modal fade" id="myTaskModel">
          <div
            className="modal-dialog modal-lg"
            style={{ marginLeft: "290px" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Tasks</h4>
                <button
                  type="button"
                  className="close"
                  id="curTaskButton"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  {this.rTBH("taskId", "taskId", "col-4")}
                  <div className="form-row">
                    {this.rSB(
                      "Userstory*",
                      "userstoryId",
                      this.props.userstories,
                      {
                        value: "userstoryId",
                        label: "usTitle",
                      },
                      " col-md-4 "
                    )}
                    {this.rSB(
                      "Owner*",
                      "tskOwner",
                      this.owners,
                      this.ownerConf,
                      " col-md-3 "
                    )}
                    {this.rDP("Start Date*", "startDate", "col-md-3")}
                    {this.rNB("Sort", "sort", "col-md-1")}
                    {this.rNB("Points", "tskPoints", "col-md-1")}
                  </div>
                  <div className="form-row">
                    {this.rRBL(
                      "Task Type*",
                      "taskType",
                      this.taskType,
                      this.tskConf
                    )}
                    {this.rRBL("Status*", "status", this.status, this.stsConf)}
                  </div>

                  <div class="collapse" id="collapseExample">
                    <div className="form-row">
                      {this.rTA("Details", "moreInfo", "col-6")}
                      {this.rTA("Special Points", "specialPoints", "col-6")}
                    </div>

                    <div className="form-row">
                      {this.rTB("Test URL", "testUrl", "col-md-12")}

                      {this.rTA8("request", "request", " col-md-6 ")}
                      {this.rTA8("response", "response", "col-md-6 ")}
                    </div>
                  </div>
                  <div class="collapse" id="CollseTimmers">
                    <div className="form-row">
                      {this.rDP("Due Date", "dueDate", "col-md-3")}
                      {this.rDP("End Date", "endDate", "col-md-2")}
                      {this.rTP("estimatedTime", "estimatedTime", "col-2")}
                      {this.rTP("actualTime", "actualTime", "col-2")}
                    </div>
                  </div>

                  <div className="form-row">
                    {this.rTB("Task*", "task", "col-10")}
                    <div className="form-group col-1">
                      <span
                        class="badge   badge-success pull-right mr-1 badge-normal"
                        data-toggle="collapse"
                        data-target="#CollseTimmers"
                        aria-expanded="false"
                        aria-controls="CollseTimmers"
                      >
                        <i
                          className="fa  fa-clock-o fa-12"
                          aria-hidden="true"
                        ></i>
                        <i class="fa-solid fa-layer-group"></i>
                      </span>
                    </div>
                    <div className="form-group col-1">
                      <span
                        class="badge   badge-success pull-right mr-1 badge-normal"
                        data-toggle="collapse"
                        data-target="#collapseExample"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        <i
                          className="fa  fa-list-alt fa-12"
                          aria-hidden="true"
                        ></i>
                        <i class="fa-solid fa-layer-group"></i>
                      </span>
                    </div>
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {this.state.data.taskId === "" &&
                  this.rBtnSP("Create", "create")}
                {this.state.data.taskId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {/* {this.rBtnSD("Delete", "delete")} */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalTask;
