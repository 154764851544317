import React from "react";
import FormLib from "./../../_common/FormLib";
import toast from "../../../services/_toastService";
import { epoch2ddmmyyyyhhmmss,epoch2ddmmyy } from "./../../../utils/DateUtils";
import { getTicketInfo } from "./../../../services/TicketHistory/TicketHistory";
import { IoBarcodeOutline } from "react-icons/io5";
import EwayBillPrintSVG from "../TicketProcess/EwayBillPrintSVG";

class TicketDetails extends FormLib {
  state = {
    data: {},
    errors: {},
    curTicket: "",
    curProductDetails: [],
    curTicketDetails: [],
    TicketProductDetails: [],
    TicketProductDetails1: [],
    TicketPrimarytDetails: [],
    wentWrong: "",
    loadPrintData: false,
    ticketProductLength:""
  };

  componentDidMount() {
    // this.loadTicketDetails();
    this.getTicketInfo();
  }

  async loadTicketDetails() {
    this.setState({ loading: true });

    this.setState({ curTicket: this.props.curTicket });
    if (this.props.ticketProductDetails && this.props.ticketPrimarytDetails) {
      this.setState({
        TicketPrimarytDetails: this.props.ticketPrimarytDetails,
      });
      this.setState({ TicketProductDetails: this.props.ticketProductDetails });
    } else {
      toast.error("No Details Available for this Ticket");
    }
    this.setState({ loading: false });
  }

  async getTicketInfo() {
    try {
      this.setState({ loading: true });

      const ticketNo = this.props.curTicket;
      this.setState({ curTicket: this.props.curTicket });
      this.state.TicketProductDetails = [];
      this.state.TicketProductDetails1 = [];

      this.state.TicketPrimarytDetails = [];
      const respDst = await getTicketInfo(ticketNo);
      if (respDst !== undefined) {
        if (respDst) {
          if (respDst.data.success === true && respDst.data.data) {
            this.setState({
              TicketPrimarytDetails:
                respDst.data.data.TicketDetails.primaryDetails,
            });
            this.setState({
              TicketProductDetails:
                respDst.data.data.TicketDetails.productDetails[0],
            });
            this.setState({
              TicketProductDetails1:
                respDst.data.data.TicketDetails.productDetails[1],
            });
            this.setState({
              ticketProductLength:respDst.data.data.TicketDetails.productDetails.length
            })
          } else if (respDst.data.success === false) {
            toast.error("No Details Available for this Ticket");
          }
        }
      } else {
        this.setState({ wentWrong: undefined });
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in TicketDetails>>>>>> getTicketInfo");
    }
  }
  replaceString = (str) => {
    if (str) {
      str = str.replace(/_/g, " ");
      return str.replace("BLOCK", "JOB");
    }
  };

  onloadBarcodeDetails = () =>{
    this.setState({loadPrintData:true});
  }

  closeModal = () => {
    this.setState({loadPrintData: false});
  }
  render() {
    return (
      <React.Fragment>
        {this.state.loading && (
          <div className="container-fluid">
            <div> Loading Details...</div>
          </div>
        )}
        <div className="pageContent ScrollStyle">
          {!this.state.loading &&
            this.state.TicketProductDetails &&
            this.state.TicketPrimarytDetails &&
            this.state.wentWrong !== undefined && (
              <div className="row">
                <div className="col-md-4">
                  <div className="card ">
                    <div className="card-body bg-custom-4">
                      <h1
                        style={{
                          backgroundColor: "#e3b68e",
                          textAlign: "center",
                          marginTop: "-20px",
                          marginLeft: "-20px",
                          marginRight: "-20px",
                          padding: "5px",
                        }}
                      >
                        Ticket Details
                      </h1>
                      <p>
                        <b>Ticket No : </b>
                        {this.state.curTicket}
                      </p>

                      <p>
                        <b>Created / Closing Date&Time : </b>
                        {epoch2ddmmyyyyhhmmss(
                          this.state.TicketPrimarytDetails.createdAt
                        )}{" "}
                        /
                        {epoch2ddmmyyyyhhmmss(
                          this.state.TicketProductDetails.closedTime
                        )}
                      </p>

                      <p>
                        <b>Status :</b>{" "}
                        {this.replaceString(
                          this.state.TicketProductDetails.blockStatus
                        )}
                      </p>
                      <p>
                        <b>Appointment Date&Time :</b>{" "}
                        {epoch2ddmmyyyyhhmmss(
                          this.state.TicketProductDetails.feAppointmentDateTime
                        )}
                      </p>
                      <p>
                        <b>Linehaul At:</b>{" "}
                        {this.state.TicketProductDetails.linehaulAwb} |{" "}
                        {epoch2ddmmyyyyhhmmss(
                          this.state.TicketProductDetails.linehaulDispatchedAt
                        )}
                      </p>
                      {this.state.TicketPrimarytDetails?.carrier && (
                        <p>
                          <b>Carrier:</b>{" "}
                          {this.state.TicketPrimarytDetails?.carrier}
                        </p>
                        
                      )}
                      <p>
                        <b>Print Ticket:</b>{""}
                        <span
              // className="badge badge-success link-over"

              title="Click to Print Barcode"
            >
              {/* {r.linehaulSeq} */}
              <button
              className="border-0 fs-5 ms-3 bg-transparent"
              title="Print Barcode"
              onClick={() => this.onloadBarcodeDetails()}
            >
              {<IoBarcodeOutline />}
            </button> 
              {/* <i
                className="fa-sharp fa-solid fa-barcode"
                style={{ fontSize: "30px", marginLeft: "50px" }}
                onClick={() => this.onloadBarcodeDetails(r)}
                title="Print Barcode"
              ></i> */}
            </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card ">
                    <div className="card-body bg-custom-2">
                      <h1
                        style={{
                          backgroundColor: "#a0cbbf",
                          textAlign: "center",
                          marginTop: "-20px",
                          marginLeft: "-20px",
                          marginRight: "-20px",
                          padding: "5px",
                        }}
                      >
                        Request Details
                      </h1>
                      <p>
                        <b>Consumer Request Number :</b>{" "}
                        {this.state.TicketPrimarytDetails?.conComplaintNo}
                      </p>
                      <p>
                        <b>Order No. :</b>{" "}
                        {this.state.TicketPrimarytDetails?.orderNo}
                      </p>
                      <p>
                        <b>Invoice No. :</b>{" "}
                        {this.state.TicketPrimarytDetails?.invoiceNo}
                      </p>
                      <p>
                        <b>LR No. :</b> {this.state.TicketPrimarytDetails?.lrNo}
                      </p>

                      <p>
                        <b>Request Date :</b>{" "}
                        {epoch2ddmmyy(this.state.TicketPrimarytDetails.dateOfTicket)}
                      </p>
                      <p>
                        <b>Request Type :</b>{" "}
                        {this.state.TicketPrimarytDetails.flowId}
                      </p>
                      {/* <p>
                        <b>Description :</b> ~~
                      </p> */}
                    </div>
                  </div>
                </div>
                {this.state.TicketPrimarytDetails.flowId !==
                  "AdvanceExchange" && (
                  <>
                    <div className="col-md-4">
                      <div className="card ">
                        <div className="card-body bg-custom-3">
                          <h1
                            style={{
                              backgroundColor: "#ccd284",
                              textAlign: "center",
                              marginTop: "-20px",
                              marginLeft: "-20px",
                              marginRight: "-20px",
                              padding: "5px",
                            }}
                          >
                            Order Details
                          </h1>
                          <p>
                            <b>Product Category : </b>
                            {this.state.TicketProductDetails.productName}
                          </p>
                          <p>
                            <b>Model : </b>
                            {this.state.TicketProductDetails.model}{" "}
                            <b>Brand : </b>
                            {this.state.TicketProductDetails.brand}{" "}
                          </p>
                          <p>
                            <b>Date Of Purchase : </b>
                            {
                              this.state.TicketProductDetails.dateOfPurchase
                            }{" "}
                          </p>
                          <p>
                            <b>Identification No :</b>
                            {this.state.TicketProductDetails.identificationNo}
                          </p>
                          <p>
                            <b>Product Desc :</b>
                            {this.state.TicketProductDetails.productDesc}
                          </p>
                          <p>
                            <b>Barcode :</b>
                            {this.state.TicketProductDetails?.prdBarcode}
                          </p>
                          {/* <p><b>Accessories :</b> ~~</p> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card ">
                        <div className="card-body bg-custom-1">
                          <h1
                            style={{
                              backgroundColor: "#e2cdcd",
                              textAlign: "center",
                              marginTop: "-20px",
                              marginLeft: "-20px",
                              marginRight: "-20px",
                              padding: "5px",
                            }}
                          >
                            PickUp Details
                          </h1>
                          <p>
                            <b>Consumer Name :</b>{" "}
                            {this.state.TicketProductDetails.srcContactPerson}
                          </p>
                          <p>
                            <b>Address :</b>{" "}
                            {this.state.TicketProductDetails.srcAdd1},
                            {this.state.TicketProductDetails.srcLandmark},
                            {this.state.TicketProductDetails.srcCity}-
                            {this.state.TicketProductDetails.srcState}-
                            {this.state.TicketProductDetails.srcPincode}
                          </p>
                          <p>
                            <b>Email :</b>{" "}
                            {this.state.TicketProductDetails.srcEmailId}
                          </p>
                          {/* <p>
                            <b>Phone :</b>{" "}
                            {this.state.TicketProductDetails.srcContact1},
                            {this.state.TicketProductDetails.srcContact2}
                          </p> */}
                          <p>
                            <b>FE Info :</b>{" "}
                            {this.state.TicketProductDetails.pickUpFeName} |{" "}
                            {epoch2ddmmyyyyhhmmss(
                              this.state.TicketProductDetails.pickUpTime
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card ">
                        <div className="card-body bg-custom-5">
                          <h1
                            style={{
                              backgroundColor: "#ddb2c8",
                              textAlign: "center",
                              marginTop: "-20px",
                              marginLeft: "-20px",
                              marginRight: "-20px",
                              padding: "5px",
                            }}
                          >
                            Drop Location
                          </h1>
                          <p>
                            <b>Contact Person : </b>
                            {this.state.TicketProductDetails.dstContactPerson}
                          </p>
                          <p>
                            <b>Address : </b>
                            {this.state.TicketProductDetails.dstAdd1},
                            {this.state.TicketProductDetails.dstLandmark},
                            {this.state.TicketProductDetails.dstLocation},
                            {this.state.TicketProductDetails.dstCity},
                            {this.state.TicketProductDetails.dstState}-
                            {this.state.TicketProductDetails.dstPincode}
                          </p>
                          {/* <p>
                        <b>Email : </b>
                        {this.state.TicketPrimarytDetails.dstEmailId}{" "}
                      </p> */}
                          {/* <p>
                            <b>Phone :</b>
                            {this.state.TicketProductDetails.dstContact1},
                            {this.state.TicketProductDetails.dstContact2}
                          </p> */}
                          <p>
                            <b>FE Info :</b>{" "}
                            {this.state.TicketProductDetails.dropFeName} |{" "}
                            {epoch2ddmmyyyyhhmmss(
                              this.state.TicketProductDetails.dropTime
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {this.state.TicketPrimarytDetails.flowId ===
                  "AdvanceExchange" && (
                  <>
                    <div className="col-md-4">
                      <div className="card ">
                        <div className="card-body bg-custom-3">
                          <h1
                            style={{
                              backgroundColor: "#ccd284",
                              textAlign: "center",
                              marginTop: "-20px",
                              marginLeft: "-20px",
                              marginRight: "-20px",
                              padding: "5px",
                            }}
                          >
                            Order Details
                          </h1>
                          <p>
                            <b>Product Category : </b>
                            {this.state.TicketProductDetails1.productName}
                          </p>
                          <p>
                            <b>Model : </b>
                            {this.state.TicketProductDetails1.model}{" "}
                            <b>Brand : </b>
                            {this.state.TicketProductDetails1.brand}{" "}
                          </p>
                          <p>
                            <b>Date Of Purchase : </b>
                            {
                              this.state.TicketProductDetails1.dateOfPurchase
                            }{" "}
                          </p>
                          <p>
                            <b>Identification No :</b>
                            {this.state.TicketProductDetails1.identificationNo}
                          </p>
                          <p>
                            <b>Product Desc :</b>
                            {this.state.TicketProductDetails1.productDesc}
                          </p>
                          <p>
                            <b>Product Length :</b>
                            {this.state.TicketProductDetails.productLength}
                          </p>
                          <p>
                            <b>Product Breadth :</b>
                            {this.state.TicketProductDetails.productBreadth}
                          </p>
                          <p>
                            <b>Product Height :</b>
                            {this.state.TicketProductDetails.productHeight}
                          </p>
                          <p>
                            <b>Barcode :</b>
                            {this.state.TicketProductDetails?.prdBarcode}
                          </p>
                          {/* <p><b>Accessories :</b> ~~</p> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card ">
                        <div className="card-body bg-custom-1">
                          <h1
                            style={{
                              backgroundColor: "#e2cdcd",
                              textAlign: "center",
                              marginTop: "-20px",
                              marginLeft: "-20px",
                              marginRight: "-20px",
                              padding: "5px",
                            }}
                          >
                            PickUp Details
                          </h1>
                          <p>
                            <b>Consumer Name :</b>{" "}
                            {this.state.TicketProductDetails1.srcContactPerson}
                          </p>
                          <p>
                            <b>Address :</b>{" "}
                            {this.state.TicketProductDetails1.srcAdd1},
                            {this.state.TicketProductDetails1.srcLandmark},
                            {this.state.TicketProductDetails1.srcCity}-
                            {this.state.TicketProductDetails1.srcState}-
                            {this.state.TicketProductDetails1.srcPincode}
                          </p>
                          <p>
                            <b>Email :</b>{" "}
                            {this.state.TicketProductDetails1.srcEmailId}
                          </p>
                          <p>
                            <b>Phone :</b>{" "}
                            {this.state.TicketProductDetails1.srcContact1},
                            {this.state.TicketProductDetails1.srcContact2}
                          </p>
                          <p>
                            <b>FE Info :</b>{" "}
                            {this.state.TicketProductDetails1.pickUpFeName} |{" "}
                            {epoch2ddmmyyyyhhmmss(
                              this.state.TicketProductDetails1.pickUpTime
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card ">
                        <div className="card-body bg-custom-5">
                          <h1
                            style={{
                              backgroundColor: "#ddb2c8",
                              textAlign: "center",
                              marginTop: "-20px",
                              marginLeft: "-20px",
                              marginRight: "-20px",
                              padding: "5px",
                            }}
                          >
                            Drop Location
                          </h1>
                          <p>
                            <b>Contact Person : </b>
                            {this.state.TicketProductDetails1.dstContactPerson}
                          </p>
                          <p>
                            <b>Address : </b>
                            {this.state.TicketProductDetails1.dstAdd1},
                            {this.state.TicketProductDetails1.dstLandmark},
                            {this.state.TicketProductDetails1.dstLocation},
                            {this.state.TicketProductDetails1.dstCity},
                            {this.state.TicketProductDetails1.dstState}-
                            {this.state.TicketProductDetails1.dstPincode}
                          </p>
                          {/* <p>
                        <b>Email : </b>
                        {this.state.TicketPrimarytDetails.dstEmailId}{" "}
                      </p> */}
                          <p>
                            <b>Phone :</b>
                            {this.state.TicketProductDetails1.dstContact1},
                            {this.state.TicketProductDetails1.dstContact2}
                          </p>
                          <p>
                            <b>FE Info :</b>{" "}
                            {this.state.TicketProductDetails1.dropFeName} |{" "}
                            {epoch2ddmmyyyyhhmmss(
                              this.state.TicketProductDetails1.dropTime
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {/* <div className="col-md-3">
                    <div className="card ">
                        <div className="card-body bg-custom-6">

                        <h3>Card 6</h3>
                          <p>Some text</p>
                          <p>Some text</p>
                        
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-3">
                    <div className="card ">
                        <div className="card-body bg-custom-7">

                        <h3>Card 7</h3>
                          <p>Some text</p>
                          <p>Some text</p>
                        
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-3">
                    <div className="card ">
                        <div className="card-body bg-custom-8">

                        <h3>Card 8</h3>
                          <p>Some text</p>
                          <p>Some text</p>
                        
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-3">
                    <div className="card ">
                        <div className="card-body bg-custom-9">

                        <h3>Card 9</h3>
                          <p>Some text</p>
                          <p>Some text</p>
                        
                    </div>
                  </div>
                </div> */}
              </div>
            )}
          {!this.state.loading &&
            (!this.state.TicketProductDetails ||
              !this.state.TicketPrimarytDetails) &&
            this.state.wentWrong !== undefined && (
              <div className="container-fluid jumbotron text-center">
                <h4>No Details Available for this Ticket...</h4>
              </div>
            )}
          {!this.state.loading && this.state.wentWrong === undefined && (
            <div className="container-fluid jumbotron text-center">
              <h4>Something went wrong please try again...</h4>
            </div>
          )}
        </div>
        <div  className="draggable col-11 ml-4 mr-5">
        {this.state.loadPrintData === true &&(
           <EwayBillPrintSVG
           // BarCodePrintSVGLinehaul
           ticketProductDetails={this.state.TicketProductDetails}
           ticketPrimarytDetails={this.state.TicketPrimarytDetails}
           curTicket = {this.state.curTicket}
           closeModal={this.closeModal}
           productlength={this.state.ticketProductLength}
         />
        )}
        </div>
      </React.Fragment>
    );
  }
}

export default TicketDetails;
