import _http from "../_httpService";
import config from "../../config.json";

const baseURL = config[config.build].apiURLMis;
const baseURLCore = config[config.build].apiURLCore;
const baseURLCoreExp = config[config.build].apiURLCoreExp;
const apiClientReport = config[config.build].apiClientReport;
const apiMisEndpoint = config[config.build].apiURLMis;

function api(path, subURL = "/ticket") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getImages(processId) {
  try {
    const apiEndpoint = "files/" + processId;
    return _http.get(api(apiEndpoint), _http.headerJWT());

  }
  catch {
    return undefined;
  }
}
export function getAdditionalImages(processId) {
  try {
    const apiEndpoint = "additionalFiles/wAdditionalFiles/" + processId;
    return _http.get(api(apiEndpoint), _http.headerJWT());

  }
  catch {
    return undefined;
  }
}


export function getAdditionalFiles(processId, actionCode) {
  try {
    const apiEndpoint = "additionalFiles/" + actionCode + "/" + processId;
    return _http.get(api(apiEndpoint), _http.headerJWT());

  }
  catch {
    return undefined;
  }
}


export function getProductImages(ticketProductId) {
  try {
    const apiEndpoint = "files/byProduct/" + ticketProductId;
    return _http.get(api(apiEndpoint), _http.headerJWT());

  }
  catch {
    return undefined;
  }
}


export function getTicketDetails(ticketNumber) {
  try {
    const apiEndpoint = "ticketDetails/" + ticketNumber;
    return _http.get(api(apiEndpoint), _http.headerJWT());

  }
  catch {
    return undefined;
  }
}

export function getTicketProcess(processId) {
  try {

    const apiEndpoint = "history/" + processId;
    return _http.get(api(apiEndpoint), _http.headerJWT());
  }
  catch {
    return undefined;
  }
}

export function getTicketMisHistory(ticketNo) {
  try {
    const apiEndpoint = baseURL + "/ticket/track/history/" + ticketNo + "?result=all";
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
  // return _http.get(apiCoreEndpoint + "/getTickets/" + query);
}

export function getTicketInfo(ticketNo) {
  try {
    const apiEndpoint = baseURL + "/ticket/track/ticket/" + ticketNo + "?result=all";
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
  // return _http.get(apiCoreEndpoint + "/getTickets/" + query);
}
export function getTicketRemarks(ticketNo) {
  try {
    const apiEndpoint = baseURLCore + "/ticket/remarks/" + ticketNo;
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
  // return _http.get(apiCoreEndpoint + "/getTickets/" + query);
}



export function multiTicketSearch(data, ticketScope, retailerIds, curPage) {
  try {
    const params = {
      ticketNos: data,
      ticketScope: ticketScope,
      retailerId: retailerIds

    };
    const apiEndpoint =
      baseURLCore + "/ticketDetails/multiTicketDetails/" +
      "7" +
      "/" +
      curPage;

    // const apiEndpoint = "http://192.168.3.133:9090/new/ticketDetails/get-tickets/by-hub/new/pincode-filter/10/" + curPage
    return _http.put(apiEndpoint, params, _http.headerJWT());
  } catch {
    return undefined;
  }
}

export function getTicketHistoryDetails(data, ticketScope, retailerIds, curPage) {
  try {
    const params = {
      ids: data,
      type: ticketScope,
      retailerId: retailerIds

    };
    // "ids":["",""],
    // "retailerId":"",
    // "type":"conComplaintNo/LRNumber/invoiceNo/orderNo/ticketNo"
    // ticketMisData/:perPage/:pageNo' \
    const apiEndpoint =
      apiClientReport + "/ticketMisData/" +
      "10" +
      "/" +
      curPage;

    return _http.put(apiEndpoint, params, _http.headerJWT());
  } catch {
    return undefined;
  }
}

export function uploadAdditionalFiles(data) {
  try {
    const formData = new FormData();

    if (data.selectedFilesList.length > 0) {


      const files = data.selectedFilesList;

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i].name)
      }
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }
    else {
      const files = data.selectedFilesList;
      formData.append("files", null);
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }


    let apiMisEndpoints = apiMisEndpoint + "/attachment/additionalFiles/upload";
    return _http.post(
      apiMisEndpoints,
      formData,
      _http.headerJWT()
    );

  }
  catch {
    return undefined;
  }
}

export function getTicketJobStatus(blockId, flowStatus) {
  try {
    // let apiMisEndpoints = "http://192.168.3.78:9092" + "/ticket/jobStatuses/" + blockId + "/" + flowStatus;
    let apiMisEndpoints = apiMisEndpoint + "/ticket/jobStatuses/" + blockId + "/" + flowStatus;
    return _http.get(apiMisEndpoints, _http.headerJWT());
  } catch {
    return undefined;
  }
  // return _http.get(apiCoreEndpoint + "/getTickets/" + query);
}



export function uploadLinehaulAdditionalFiles(data) {
  try {
    const formData = new FormData();

    if (data.selectedFilesList.length > 0) {


      const files = data.selectedFilesList;

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i], files[i].name)
      }
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }
    else {
      const files = data.selectedFilesList;
      formData.append("files", null);
      const fieldsData = { ...data };
      delete fieldsData.selectedFilesList;
      const fields = JSON.stringify(fieldsData);
      formData.append("fields", fields);
    }

    // let url = "http://192.168.3.78:9092/attachment/pod/upload"
    let apiMisEndpoints = apiMisEndpoint + "/attachment/pod/upload";
    return _http.post(
      apiMisEndpoints,
      // url,
      formData,
      _http.headerJWT()
    );

  }
  catch {
    return undefined;
  }
}


export function getLineHaulAdditionalFiles(ticketLineHaulId) {
  try {
    const apiEndpoint = apiMisEndpoint + "/shipment/files/" + ticketLineHaulId;
    // let apiMisEndpoints = apiMisEndpoint + "/attachment/pod/upload";

    // const url = "http://192.168.3.78:9092/shipment/files/" + ticketLineHaulId;

    return _http.get(
      apiEndpoint,
      // url,
      _http.headerJWT());

  }
  catch {
    return undefined;
  }
}

// Get retailerInfo by playerId
export function getRetailerInfo(playerId) {
  try {
    const apiEndpoint = baseURLCoreExp + "/getRetailerForPlayers/" + playerId;
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
  // return _http.get(apiCoreEndpoint + "/getTickets/" + query);
}