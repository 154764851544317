import React from "react";
import FormLib from "./../../_common/FormLib";

import PrvModules from "./PrvModules";
import PrvGroups from "./PrvGroups";
import PrvFeatures from "./PrvFeatures";


import ModalDivision from "./ModalDivision";
import ModalModule from "./ModalModule";
import ModalGroup from "./ModalGroup";
import ModalFeature from "./ModalFeature";
import PrevilagesTitle from "../../_subhead/PrevilagesTitle";


import toast from "../../../services/_toastService";

import { getPrvDivs } from "./../../../services/appFeatures/prvDivsServices";
import { getPrvModules } from "./../../../services/appFeatures/prvModulesServices";
import { getPrvGroups } from "./../../../services/appFeatures/prvGroupsServices";
import { getPrvFeatures } from "./../../../services/appFeatures/prvFeaturesServices";

class AppFeatures extends FormLib {
  state = {
    data: {},
    errors: {},
    prvDivs: [],
    prvModules: [],
    prvGroups: [],
    prvFeatures: [],
    curDivision: {divisionId: ""},
    curModule: {},
    curGroup: {},
    curTitle: {},
  };
  schema = {};

  componentDidMount() {
    this.loadPrvDivs();
  }

  loadPrvDivs = async (m) => {
    const res = await getPrvDivs();
    if (res.data.success === true) {
      this.setState({ prvDivs: res.data.data.prvDivs });
      toast.toastSuccess("PrvDivs Loaded Successfully");
    } else {
      toast.toastError("failed");
    }
  };

  loadPrvModules = async (divisionId, modReset = true) => {
    if (modReset === true) {
      this.setState({ prvGroups: [], prvFeatures: [] });
      this.setState({ curGroup: {}, curTitle: {} });
      this.setState({ curModule: {} });
    }
    let curDivision;
    const filter = this.state.prvDivs.filter((r) => {
      return r.divisionId === divisionId ? r : null;
    });

    if (!filter[0]) {
      curDivision = { divisionId: "" };
    } else {
      curDivision = filter[0];
    }

    this.setState({ curDivision: curDivision });

    if (divisionId !== "") {
      const res = await getPrvModules(divisionId);
      this.setState({ prvModules: res.data.data.prvModules });
    } else {
      this.setState({ prvModules: [] });
    }
  };

  loadPrvGroups = async (m, modReset = true) => {
    if (modReset === true) {
      this.setState({ prvFeatures: [] });
      this.setState({ curModule: m });
      this.setState({ curGroup: {} });
    }
    if (m["moduleId"] !== "") {
      const res = await getPrvGroups(m["moduleId"]);
      this.setState({ prvGroups: res.data.data.prvGroups });
    } else {
      this.setState({ prvGroups: [] });
    }
  };

  loadPrvFeatures = async (f, modReset = true) => {
    if (modReset === true) {
      this.setState({ curGroup: f });
      this.setState({ curTitle: {} });
    }

    if (f["prvGroupId"] !== "") {
      const res = await getPrvFeatures(f["prvGroupId"]);
      this.setState({ prvFeatures: res.data.data.prvFeatures });
    } else {
      this.setState({ prvFeatures: [] });
    }
  };

  handleDivisionChange(e) {
    this.loadPrvModules(e.target.value);
  }

  handleModuleChange = (m) => {
    this.loadPrvGroups(m);
  };
  handleGroupChange = (f) => {
    this.loadPrvFeatures(f);
  };
  handleFeatureChange = (f) => {
    
    this.setState({ curTitle: f });
  };

  handleModuleUnload = () => {
    this.setState({ prvGroups: [], prvFeatures: [] });
    this.setState({ curModule: {}, curGroup: {}, curTitle: {} });
  };
  handleGroupUnload = () => {
    this.setState({ prvFeatures: [] });
    this.setState({ curGroup: {}, curTitle: {} });
  };
  handleFeatureUnload = () => {
    
    this.setState({ prvFeatures: [] });
  };

  localHandleChange = (e) => {
    if (e.target.id === "prvDivs") {
      this.handleDivisionChange(e);
    }
  };

  render() {
    return (
      <React.Fragment>
        <PrevilagesTitle subHeading="App Features" />
        <div className="pageContent">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10">
                {this.rSB("Prv Divs", "prvDivs", this.state.prvDivs, {
                  value: "divisionId",
                  label: "division",
                })}
              </div>
                {/* <pre>{JSON.stringify(this.state.curDivision)}</pre>  */}
              <div className="menu col-md-2 ">
                {this.state.curDivision.divisionId === "" && (
                  <button
                    type="button"
                    className="btn btn-sm btn-info pull-left"
                    data-toggle="modal"
                    data-target="#myDivisionModel"
                  >
                    <i className={"fa  fa-plus  "}></i>
                  </button>
                )}
                {this.state.curDivision.divisionId !== "" && (
                  <button
                    type="button"
                    className="btn btn-sm btn-success pull-left in-line"
                    data-toggle="modal"
                    data-target="#myDivisionModel"
                  >
                    <i className={"fa  fa-pencil  "}></i>
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-sm btn-secondary pull-left"
                  onClick={() => this.loadPrvDivs()}
                >
                  <i className={"fa  fa-refresh  "}></i>
                </button>
              </div>
            </div>

            <div className="card-deck">
              <PrvModules
                prvModules={this.state.prvModules}
                curDivision={this.state.curDivision}
                curModule={this.state.curModule}
                onModuleChange={this.handleModuleChange}
                onModuleUnload={this.handleModuleUnload}
              />
              <PrvGroups
                prvGroups={this.state.prvGroups}
                curModule={this.state.curModule}
                curGroup={this.state.curGroup}
                onGroupChange={this.handleGroupChange}
                onGroupUnload={this.handleGroupUnload}
              />
              <PrvFeatures
                prvFeatures={this.state.prvFeatures}
                curGroup={this.state.curGroup}
                curTitle={this.state.curTitle}
                onFeatureChange={this.handleFeatureChange}
                onFeatureUnload={this.handleFeatureUnload}
              />
            </div>
          </div>
        </div>
        <>
          <ModalDivision
            curDivision={this.state.curDivision}
            onloadPrvDivs={this.loadPrvDivs}
            key={this.state.curDivision["divisionId"]}
          />
          <ModalModule
            prvDivs={this.state.prvDivs}
            curDivision={this.state.curDivision}
            curModule={this.state.curModule}
            onLoadPrvModules={this.loadPrvModules}
            key={
              this.state.curModule["moduleId"] ||
              "m_" + this.state.curDivision["divisionId"]
            }
          />
          <ModalGroup
            prvModules={this.state.prvModules}
            curModule={this.state.curModule}
            curGroup={this.state.curGroup}
            onLoadPrvGroups={this.loadPrvGroups}
            key={
              this.state.curGroup["prvGroupId"] ||
              "g_" + this.state.curModule["moduleId"]
            }
          />
          <ModalFeature
            prvGroups={this.state.prvGroups}
            curGroup={this.state.curGroup}
            curTitle={this.state.curTitle}
            onLoadPrvFeatures={this.loadPrvFeatures}
            key={
              "f_" +
              (this.state.curTitle["previlagesId"] ||
                this.state.curGroup["prvGroupId"])
            }
          />
        </>
      </React.Fragment>
    );
  }
}
export default AppFeatures;
