import { Component } from "react";
import _auth from "../../../services/_authService";

class Logout extends Component {
  componentDidMount() {
    _auth.logout();

    window.location = "/";
  }

  render() {
    return null;
  }
}

export default Logout;
