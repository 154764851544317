import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";

import {createProductCat,updateProductCat,deleteProductCat} from "../../../services/products/productCategoryServices";

class ModelProductsCategory extends FormLib {

    state = {
        data: {
            prodCatId: "",
            category: "",
            status: "active"
        },
        errors: {},
    };
    status = [
        { sortKey: "active", sort: "Active" },
        { sortKey: "inactive", sort: "Inactive" },
    ];
    stsConf = {
        value: "sortKey",
        label: "sort",
    };
    schema = {
        prodCatId: Joi.optional().label("prodCatId"),
        category: Joi.string().required().label("Category Name "),
        status: Joi.string().required().label("Category Status"),
    };


    componentDidMount() {
        this.setCurCategory(this.props.curProductsCategory);
     }

     setCurCategory(p) {
        if (p.prodCatId !== "") {
          this.setState({ data: p });
        } else {
          this.resetData();
        }
    }

    resetData(){
        this.setState({
            data: {
                prodCatId: "",
                category: "",
                status: "active"
            }
        });
    }
    async doSubmit() {
        let res;
        if (this.state.btnValue === "create") {
            res = await createProductCat(this.state.data);
            this.resetData();
        }
        if (this.state.btnValue === "edit") {
            res = await updateProductCat(this.state.data);
            // this.resetData();
        }
        if (this.state.btnValue === "delete") {
            res = await deleteProductCat(this.state.data);
            this.resetData();
        }
        if (res.data.success === true) {
            toast.toastSuccess(res.data.msg);
            // this.props.onLoadProductCat();
        } else {
            toast.toastError(res.data.msg);
        }
    }



    render() {
        return (
            <div className="container-fluid">
                <div className="modal fade" id="myMaterialModal">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Product Category</h4>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times; 
                                </button>
                            </div>

                            <div className="modal-body ">
                                <form>
                                    <div className="form-row">
                                        {this.rTB("Product Category Name", "category", "col-12")}
                                        <br/> <br/>
                                        {this.rRBL("Status", "status", this.status, this.stsConf,"col-12")}
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                {this.state.data.prodCatId === "" &&
                                    this.rBtnSP("Create", "create")
                                }
                                {this.state.data.prodCatId !== "" && ( 
                                    <>
                                        {this.rBtnSS("Edit", "edit")}
                                        {this.rBtnSD("Delete", "delete")}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default ModelProductsCategory;