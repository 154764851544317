import React, { Component } from "react";
import axios from "axios";

class FileUpload extends Component {
  state = {
    selectedFile: "",
    apiStatus: "init",
  };
  componentDidMount() {
    // this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange = (event) => {
    this.setState({
      selectedFile: event.target.files[0],
    });
  };

  submit() {
    const formData = new FormData();
    formData.append("file", this.state.selectedFile);
    formData.append(
      "fields",
      '{"fileId": "cat2166v4233395p0TZ2056097e693704e6fab608f","processId": 1254214,"ticketProductId": "ticketProdId","actionCode": "pickUp_photo","action": "pickUp","fileType": "jpg","geoLocation": "Bangelore","fileName": "xyz.png","syncStatus": "synced","syncAttempt": 1}'
    );

    console.warn(this.state.selectedFile);
    let url = "https://devmumbai.bizlog.in:5000/file-upload/attachment/upload";

    axios
      .post(url, formData, {
        // receive two parameter endpoint url ,form data
      })
      .then((res) => {
        // then print response status
        console.warn(res);
      });
  }
  render() {
    return (
      <div>
        hello
        <div className="row">
          <div className="col-md-6 offset-md-3">
            {this.state.apiStatus}
            <br />
            <br />

            <h3>React File Upload Example - Tutsmake.com</h3>
            <br />
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Select File :</label>
                <input
                  type="file"
                  className="form-control"
                  name="upload_file"
                  onChange={this.handleInputChange}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-6">
                <button
                  type="submit"
                  className="btn btn-dark"
                  onClick={() => this.submit()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FileUpload;
