import React from "react";
import FormLib from "../../_common/FormLib";
import swal from "sweetalert";
import Joi from "joi-browser";
import Table from "../../_common/Table";
// import Pagination from "../../_common/Pagination";

import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";

import {
  getZones,
  createZones,
  saveZones,
  deleteZones,
} from "../../../services/masterLocations/zonesServices";

import { getActiveRegions } from "../../../services/masterLocations/regionsServices";

class Zones extends FormLib {
  state = {
    data: {
      zoneId: "",
      regionId: "",
      zone: "",
      status: "active",
    },
    errors: {},
    formMode: "create",
    loading: true,
    zones: [],
  };
  schema = {
    zoneId: Joi.optional().label("Zone Id"),
    regionId: Joi.optional().label("Region Id"),
    zone: Joi.string().regex(/^[a-zA-Z ]*$/).required().label("Zone").error(() => {
      return {
        message: 'Allow only characters.',
      }
    }),
    status: Joi.string().required().label("Status"),
  };

  componentDidMount() {
    this.loadZones();
    this.loadActiveRegions();
  }
  async loadZones() {
    this.setState({ loading: true });
    const res = await getZones();
    if (res.data.success === true && res.data.data.zones) {
      this.setState({ zones: res.data.data.zones });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  async loadActiveRegions() {
    try {

      this.setState({ loading: true });
      const res = await getActiveRegions();
      if (res.data.success === true) {
        this.setState({ regions: res.data.data.regions });
      } else {
        toast.toastError(res.data.msg);
      }
      this.setState({ loading: false });
    }
    catch {
      console.log("Error in Zones>>>>loadActiveRegions");
    }
  }

  tableMeta = {
    key: "zoneId",
    columns: [
      {
        key: "ZONE_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnZones(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleView(r)}
              >
                <i className="fa fa-binoculars" aria-hidden="true"></i> View
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(r)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i> Delete
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      // { path: "zoneId", label: "Zone Id" },
      // { path: "regionId", label: "Region Id" },
      { path: "region", label: "Region" },
      { path: "zone", label: "Zone" },
      { path: "status", label: "Status" },
    ],
  };
  handleOnZones(r) {
    console.log("inside handle on zones");
    let zones = {};
    zones.zoneId = r.zoneId;
    zones.regionId = r.regionId;
    zones.zone = r.zone;
    zones.status = r.status;
    this.setState({ data: zones, formMode: "edit" });
  }
  //   handlePageChange = (pageNo) => {
  //     this.props.history.push("/location/city" + pageNo);

  //     this.loadCities();
  //   };
  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createZones(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveZones(this.state.data);
      this.resetData();
    }

    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadZones();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  doSubmitDelete = async (handleDelete, r) => {
    if (handleDelete) {
      await deleteZones(r);
      this.loadZones();

      swal("Zone has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Zone is safe!");
    }
  };

  handleDelete = async (r) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, r);
    });
  };

  handleView = (r) => {
    this.setState({ curZone: r, formMode: "view" });
  };

  resetData() {
    this.setState({
      data: {
        zoneId: "",
        regionId: "",
        zone: "",
        status: "active",
      },
      formMode: "create",
    });
  }
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Zones" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-6">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading zones</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.zones}
                    dropMenu={[]}
                  />
                  {/* <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  /> */}
                </div>
              )}
            </div>
            <div className="col-md-6">
              {this.state.formMode === "view" && (
                <div className="card">
                  <div className="card-body">
                    <>
                      {this.state.curZone["zone"].trim()}
                      <br />
                      <div className="pull-right">
                        <span className={"badge   badge-light pull-left ml-1 "}>
                          {/* {this.state.curZone["zoneId"]} */}
                        </span>
                        <span
                          className={"badge   badge-success pull-left  mr-1 "}
                        >
                          {this.state.curZone["status"]}
                        </span>
                      </div>
                    </>
                  </div>
                </div>
              )}
              {this.state.formMode !== "view" && (
                <div className="card pt-2">
                  <div className="card-body">
                    <form>
                      <div className="form-row">
                        {this.rSB(
                          "Regions *",
                          "regionId",
                          this.state.regions,
                          { value: "regionId", label: "region" },
                          " col-md-6 mt-1"
                        )}
                        {this.rTB("Zone", "zone", "col-6")}
                      </div>
                      <div className="form-row">
                        {this.rTBH("ZoneId", "zoneId", "col-4")}
                      </div>
                      <div className="form-row">
                        {this.rRBL(
                          "Status",
                          "status",
                          this.status,
                          this.stsConf
                        )}
                      </div>
                    </form>
                    {this.state.formMode === "create" &&
                      this.rBtnSP("Create", "create")}
                    {this.state.formMode === "edit" && (
                      <>
                        {this.rBtnSS("Edit", "edit")}
                      </>
                    )}
                    {this.mResetBtn(() => this.resetData(), " pull-right ", "Reset")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Zones;
