import React from 'react'
import ReportCreate from './ReportCreate';


class ReportModal extends React.Component {
    render() {
        return (
            <div className="task-modal">
                <button type="button" className="btn btn-sm btn-primary" data-toggle="modal" data-target="#myModal6">
                    Re
                </button>

                <div className="modal fade" id="myModal6">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Report</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-body p-0">
                            <ReportCreate/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-success" data-dismiss="modal">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ReportModal;

