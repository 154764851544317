import React from "react";
import FormLib from "./../../_common/FormLib";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import {
  setTicketActionsVehicle,
  getActionCategory,
  getTicketActionsDetails,
} from "./../../../services/Tickets/TicketProcessServices";
import {
  getVendorsList,
  getExpenseHeadType,
} from "../../../services/requestV1/requestServices";
import { toast } from "react-toastify";
import { IstToUTC, IstToUTCDate } from "../../../utils/DateUtils";
import Joi from "joi-browser";
import LocationTitle from "../../_subhead/LocationTitle";
import { connect } from "react-redux";
import { ticketListReload } from "./../../../store";
import ExpenseRise from "./../TicketActivities/actions/ExpenseRise";
import { blockStatus } from "./../../../services/linehaul/linehaulServices";
import swal from "sweetalert";
import { getQuestions } from "./../../../services/Tickets/TicketProcessServices";

class TicketActionsInfo extends FormLib {
  state = {
    data: {
      // actionCatId: "",
      activity: "",
      vehicleNo: "",
      logisticsProvider: "",
      vehicleLoadEmpty: "",
      vehicleLoadWt: "",
      apprxVehicleLoad: "",
      logistics: "shortHaul",
      vehicleType: "twoWheeler",
      vehicleLoad: "PTL",
      billable: "Yes",
      vendor: "Yes",
      moreDetails: "",
      approxEstimation: "",
      fileInfo: "",
      selectedFilesList: [],
    },
    users: [],
    errors: {},
    curticketDetails: {},
    curblockDetails: {},
    curAction: "",
    pageHide: "false",
    feStatus: "false",
    curStatus: "",
    cancelStatus: "false",
    curMultiFETicketDetails: [],
    curMultiblockDetails: {},
    curMultiticketDetails: {},
    venText: "",
    venName: "",
    venSuggestions: [],
    selectedVenCode: "",
    file: [],
    successData: false,
    openStatus: true,
    documentDetails: false,
    ticketActionId: "",
    ticketActions: [],
    ProductQuestions: [],
    QuestionArray: {},
    expenseHeadType: [],
  };
  billable = [
    { billable: "Yes", billableByLabel: "Yes" },
    { billable: "No", billableByLabel: "No" },
  ];
  vendor = [
    { vendor: "Yes", vendorByLabel: "Yes" },
    { vendor: "No", vendorByLabel: "No" },
  ];
  logistics = [
    {
      logisticsByDistance: "shortHaul",
      logisticsByLabel: "IntraCity ( Upto 150km )",
    },
    { logisticsByDistance: "longHaul", logisticsByLabel: "InterCity" },
  ];
  vehicleType = [
    { vehicleTypeByLoad: "twoWheeler", vehicleTypeByLabel: "Two Wheeler" },
    { vehicleTypeByLoad: "threeWheeler", vehicleTypeByLabel: "Three Wheeler" },
    { vehicleTypeByLoad: "fourWheeler", vehicleTypeByLabel: "Four Wheeler" },
  ];
  vehicleLoad = [
    { vehicleByLoad: "PTL", vehicleByLabel: "PTL" },
    { vehicleByLoad: "FTL", vehicleByLabel: "FTL" },
  ];
  stsConfig = {
    value: "sortKey",
    label: "sort",
  };
  includeVehicleInfo = [
    { sortKey: "yes", sort: "Needs to Capture only files??" },
  ];

  schema = {
    // actionCatId: Joi.string().required().label("actionCatId"),
    activity: Joi.string().required().label("activity"),
    vehicleNo: Joi.optional().label("vehicleNo"),
    logisticsProvider: Joi.optional().label("logisticsProvider"),
    vehicleLoadEmpty: Joi.string()
      .allow("")
      .regex(/^[0-9]+$/)
      .optional()
      .label("vehicleLoadEmpty")
      .error(() => {
        return {
          message: "Allow only numbers",
        };
      }),
    vehicleLoadWt: Joi.string()
      .allow("")
      .regex(/^[0-9]+$/)
      .optional()
      .label("vehicleLoadWt")
      .error(() => {
        return {
          message: "Allow only numbers",
        };
      }),
    apprxVehicleLoad: Joi.string()
      .allow("")
      .regex(/^[0-9]+$/)
      .optional()
      .label("apprxVehicleLoad")
      .error(() => {
        return {
          message: "Allow only numbers",
        };
      }),
    logistics: Joi.optional().label("logistics"),
    vehicleType: Joi.optional().label("vehicleType"),
    vehicleLoad: Joi.optional().label("vehicleLoad"),
    includeVehicleInfo: Joi.optional().label("includeVehicleInfo"),
    billable: Joi.string().required().label("billable"),
    vendor: Joi.string().required().label("vendor"),
    moreDetails: Joi.optional().label("moreDetails"),
    approxEstimation: Joi.optional().label("approxEstimation"),
    fileInfo: Joi.optional().label("fileInfo"),
    selectedFilesList: Joi.optional().label("selectedFilesList"),
    vendor_code: Joi.optional().label("vendor_code"),
  };

  //   handleView = () => {
  //     $(".modal-content").resizable({
  //       //alsoResize: ".modal-dialog",
  //       minHeight: 300,
  //       minWidth: 300,
  //     });
  //     $("#myModal").draggable();

  //     $("#myModal").on("show.bs.modal", function () {
  //       $(this).find(".modal-body").css({
  //         "max-height": "100%",
  //       });
  //     });
  //   };
  componentDidMount() {
    this.getActionCategory();
    this.loadInit();
  }
  loadInit() {
    const curticketDetails = this.props.ticketDetails;
    const curblockDetails = this.props.blockDetails;
    const curAction = this.props.curAction;
    const curMultiticketDetails = this.props.ticketMultiDetails;
    const curMultiblockDetails = this.props.blockMultiDetails;
    const bulkAssign = this.props.bulkAssign;
    this.setState({ curticketDetails });
    this.setState({ curblockDetails });
    this.setState({ curAction });
    this.setState({ bulkAssign });
    this.setState({ curMultiticketDetails });
    this.setState({ curMultiblockDetails });
    let hoForm = localStorage.getItem("curHub");
    if (hoForm === "1lUeT6ivx3d1DXPKW74SdKyLQrM=") {
      this.setState({ hideForm: "true" });
    } else {
      this.setState({ hideForm: "false" });
    }
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({ designation: userInfo.designation });
  }

  getActionCategory = async () => {
    try {
      let actionsList = [];
      const list1 = await getExpenseHeadType("ticketExpense");
      // console.log({ list1 });
      if (list1 !== undefined) {
        this.setState({ expenseHeadType: list1.data.data.expenseHeadTypes });
      }
      let resp = await getActionCategory();
      if (resp.data.success === true) {
        if (resp.data.data) {
          let result = resp.data.data.ticketActionsCatList;
          this.setState({ actionsList: result });
          toast.success(() => <div>{resp.data.msg}</div>);
        } else {
          toast.warning(() => <div>{"Data not available"}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in getActionCategory");
    }
  };

  async doSubmit() {
    let curHub = localStorage.getItem("curHub");
    let date = IstToUTCDate(new Date());
    let now = new Date().valueOf(); // Unix timestamp in milliseconds
    let tripId = curHub + "-" + date + "-" + now;
    let override = { ...this.state.data };
    override["actionCode"] = "wUpdateTicketActions";
    override["action"] = "TICKET ACTIONS";
    this.setState({ override });
    let newTicketPriority = "";
    let blocks = [];

    if (this.state.bulkAssign === "bulk") {
      this.state.curMultiblockDetails.map((r) => {
        let blockData = {
          ticketBlockId: r.ticketBlockId,
          flowStatus: r.flowStatus,
          blockStatus: r.blockStatus,
        };
        blocks.push(blockData);
      });
    } else {
      let blockData = {
        ticketBlockId: this.state.curblockDetails.ticketBlockId,
        flowStatus: this.state.curblockDetails.flowStatus,
        blockStatus: this.state.curblockDetails.blockStatus,
      };
      blocks.push(blockData);
    }

    let ticketAction = {
      // actionCatId: this.state.data.actionCatId,
      actionCatId: this.state.data.activity,
      actionPrefix: "",
      status: "active",
      isBillable: this.state.data.billable,
      hubId: localStorage.getItem("curHub"),
    };
    let ticketActionInfo = {
      isVendor: this.state.data.vendor,
      isMarketVendor: "",
      vendor: this.state.venName,
      venCode: this.state.data.vendor_code,
      approxEstimation: this.state.data.approxEstimation,
      moreDetails: this.state.data.moreDetails,
    };
    let vehicle = {
      transporter: this.state.data.logistics,
      vehicleNo: this.state.data.vehicleNo,
      vehicleType: this.state.data.vehicleType,
      vehicleLoadEmpty: this.state.data.vehicleLoadEmpty,
      vehicleLoadWt: this.state.data.vehicleLoadWt,
      apprxVehicleLoad: this.state.data.apprxVehicleLoad,
    };

    let resultSet = {
      blocks: blocks,
      actionStatus: "TICKET_ACTIONS",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };

    if (this.state.data.activity === "a047") {
      const data = {
        actionCode: override.actionCode,
        action: override.action,
        ticketAction: ticketAction,
        ticketActionInfo: ticketActionInfo,
        selectedFilesList: this.state.data.selectedFilesList,
        info: this.state.data.fileInfo,
        vehicle: this.state.QuestionArray,
        resultSet: resultSet,
      };

      let resp = await setTicketActionsVehicle(data);

      if (resp.data.success === true) {
        if (resp.data) {
          toast.success("Ticket Action Fetched Successfully");
          this.setState({ successData: true });
          this.setState({ ticketActionId: resp.data.data.ticketActionId });
          // this.reSetFormData();
          // this.handleTicketListReload();
        } else {
          !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } else {
      const data = {
        actionCode: override.actionCode,
        action: override.action,
        ticketAction: ticketAction,
        ticketActionInfo: ticketActionInfo,
        selectedFilesList: this.state.data.selectedFilesList,
        info: this.state.data.fileInfo,
        actions: this.state.QuestionArray,
        resultSet: resultSet,
      };
      let resp = await setTicketActionsVehicle(data);

      if (resp.data.success === true) {
        if (resp.data) {
          toast.success("Ticket Action Fetched Successfully");
          this.setState({ successData: true });
          this.setState({ ticketActionId: resp.data.data.ticketActionId });
          // this.reSetFormData();
          // this.handleTicketListReload();
        } else {
          !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
  }
  reSetFormData() {
    let data = { ...this.state.data };
    data["activity"] = "";
    data["vendor_code"] = "";
    data["moreDetails"] = "";
    data["approxEstimation"] = "";
    data["vehicleNo"] = "";
    data["logisticsProvider"] = "";
    data["apprxVehicleLoad"] = "";
    data["vehicleLoadWt"] = "";
    data["vehicleLoadEmpty"] = "";
    data["fileInfo"] = "";
    data["selectedFilesList"] = [];

    this.setState({ data });
    this.setState({ file: [] });
  }
  selectedFilesLists = (e) => {
    let fileObj = [];
    let fileArray = [];
    fileObj.push(e);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(fileObj[0][i]);
    }
    this.setState({ file: fileArray });

    let data = this.state.data;
    data.selectedFilesList = e;
    this.setState({ data });
  };

  localHandleChange = async (e) => {
    let actionId = e.target.value;
    if (e.target.value) {
      try {
        let res = await getQuestions(actionId);
        // console.log("Question Arrays" + JSON.stringify(res));
        if (res.data.success === true) {
          const data = res.data.data.fields;
          this.setState({
            ProductQuestions: data,
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  onVenTextChanged = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    let venSuggestions = [];

    if (value.length > 1) {
      this.getVendorsList(value);
    }

    this.setState(() => ({
      venSuggestions,
      venText: value,
      venName: name,
      selectedVenCode: "",
    }));

    let data = this.state.data;

    data.vendor_code = value;

    this.setState({ data });
  };

  renderVenSuggestions() {
    const { venSuggestions } = this.state;
    if (venSuggestions.length === 0) {
      return null;
    }

    return (
      <div className="srchList">
        <ul>
          {venSuggestions.map((item) => (
            <li
              key={item.VEN_CODE}
              onClick={() =>
                this.venSuggestionsSelected(item.VEN_CODE, item.NAME)
              }
            >
              {item.VEN_CODE} [{item.NAME}]
            </li>
          ))}
        </ul>
      </div>
    );
  }

  async getVendorsList(value, name) {
    let venSuggestions = [];
    let vendorsList = await getVendorsList(value);
    this.setState({ items: vendorsList.data.data?.venListbyCode });
    venSuggestions = this.state.items;
    this.setState(() => ({
      venSuggestions,
      venText: value,
      venName: name,
    }));
  }
  venSuggestionsSelected(value, name) {
    let vendorlist = this.state.data;
    vendorlist["vendor_code"] = value;
    this.setState(() => ({
      vendorlist,
      venText: value,
      venName: name,
      venSuggestions: [],
      selectedVenCode: value,
    }));
  }
  // uploadFiles() {
  //   let override = { ...this.state.data };
  //   console.log(override.selectedFilesList);
  // }
  deleteFile(e) {
    const s = this.state.file.filter((item, index) => index !== e);
    this.setState({ file: s });
    let data = this.state.data;
    data.selectedFilesList = s;
    this.setState({ data });
  }
  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };
  closeModal = () => {
    this.setState({ openStatus: "false" });
    this.setState({ documentDetails: "false" });
  };

  handleExpenseRise = async () => {
    let resp = await getTicketActionsDetails(this.state.ticketActionId);
    if (resp.data.success === true) {
      if (resp.data.data) {
        // toast.success("Ticket Action Fetched Successfully");
        this.setState({ ticketActions: resp.data.data.actions });
        this.setState({ ticketDetails: resp.data.data.tickets });
      } else {
        !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
      }
    } else {
      toast.error(() => <div>{resp.data.msg}</div>);
    }
    this.setState({ documentDetails: "false" });
    this.setState({ openStatus: "true" });
    // this.setState({ currentRequest: ticketActions });
  };

  handleExpenseLater = () => {
    try {
      swal({
        title: "Are you sure want to Expense Rise later?",
        text: "Kindly Enter the Reason ",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: "input",
        placeholder: "Write something",
      }).then(async (handleExpenseLater) => {
        this.doExpenseLater(handleExpenseLater);
      });
    } catch {
      console.log("Error in TicketActivities>>>>>>handleExpenseLater");
    }
  };

  doExpenseLater = async (handleExpenseLater) => {
    try {
      if (handleExpenseLater) {
        swal(
          "Expense Rise later Initiated with the following remarks: " +
            handleExpenseLater,
          {
            icon: "success",
          }
        );
        this.reSetFormData();
      } else {
        swal("Expense Rise later Initiation cancelled!", { icon: "error" });
      }
    } catch {
      console.log("Error in TicketActivities>>>>>>doExpenseLater");
    }
  };

  handleRadio = (e) => {
    // console.log(e.target.value);
    this.setState({
      QuestionArray: {
        ...this.state.QuestionArray,
        [e.target.name]: e.target.value,
      },
    });
  };
  handleText = (e) => {
    // console.log(e.target.value);
    this.setState({
      QuestionArray: {
        ...this.state.QuestionArray,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleData = (e) => {
    // console.log(e.target.value);
    this.setState({
      QuestionArray: {
        ...this.state.QuestionArray,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    const { venText, venName } = this.state;
    const file = this.state.data.selectedFilesList;
    const currentRequest = this.state.ticketActions;
    const ticketRequest = this.state.ticketDetails;

    return (
      <>
        <LocationTitle subHeading="Ticket Actions" />
        <div className="row mt-5 ">
          <div className="col-10">
            <div
              className="card-body  bg-light"
              style={{
                minHeight: "1250px",
                maxHeight: "1250px",
                marginLeft: "-18px",
                marginTop: "-36px",
                backgroundColor: "#f5f0f0",
              }}
            >
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-6">
                    <b className="mt-5">&nbsp;&nbsp;Expenses :</b>

                    <div className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3 ">
                      {/* {this.rSB(
                        "Expenses Head :",
                        "actionCatId",
                        this.state.actionsList,
                        { value: "actionCatId", label: "actionCat" },
                        " col-12"
                      )} */}
                      {this.rSB(
                        "Expense Head *",
                        "activity",
                        this.state.expenseHeadType,
                        { value: "expenseHeadId", label: "expenseHead" },
                        " col-12"
                      )}
                    </div>
                  </div>
                  <div className="col-6">
                    <b className="mt-5">&nbsp;&nbsp;Billable Info :</b>

                    <div className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3">
                      {this.rRBL(
                        "Is Billable ?",
                        "billable",
                        this.billable,
                        {
                          value: "billable",
                          label: "billableByLabel",
                        },
                        "col-12 mt-2 mb-1"
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <b className="mt-5">&nbsp;&nbsp;Vendor Info :</b>

                    <div className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3">
                      {this.rRBL(
                        "Is Vendor ?",
                        "vendor",
                        this.vendor,
                        {
                          value: "vendor",
                          label: "vendorByLabel",
                        },
                        "col-12 mt-2 mb-1"
                      )}
                      {this.state.data.vendor === "Yes" && (
                        <div className="col form-group mb-2">
                          <input
                            value={venText}
                            name="vendor_code"
                            onChange={this.onVenTextChanged}
                            type="text"
                            autoComplete="off"
                          />

                          <label htmlFor="venName" className="control-label">
                            Vendor List
                          </label>
                          <i className="bar"></i>
                          <span className="badge" style={{ color: "black" }}>
                            {venName}
                          </span>
                          {this.renderVenSuggestions()}
                          {this.state.data.vendor === "Yes" &&
                            !this.state.selectedVenCode && (
                              <span className="alert alert-info cusError ">
                                Invalid Vendor Code
                              </span>
                            )}
                        </div>
                      )}

                      {this.rTB(
                        "More Details ",
                        "moreDetails",
                        " col-12 mt-1 mb-3"
                      )}
                      {this.rNB(
                        "Approx. Estimation ",
                        "approxEstimation",
                        " col-12 mt-1 mb-3"
                      )}
                    </div>
                    <b className="mt-5">&nbsp;&nbsp;File Upload :</b>

                    <div className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3">
                      <div className="m-0 p-0 col-9 mt-2">
                        <label
                          htmlFor="refDocFiles"
                          className="form-label font-monospace"
                        >
                          Upload Files
                        </label>
                        <input
                          className="form-control form-control-sm "
                          multiple
                          id="refDocFiles"
                          type="file"
                          style={{ cursor: "pointer" }}
                          onChange={(e) => {
                            this.selectedFilesLists(e.target.files);
                          }}
                        />
                      </div>
                      {this.rTB("File Info ", "fileInfo", " col-12 mt-3 mb-3")}

                      <div
                        className="col-12 page-todo overflow-auto"
                        style={{ height: "20rem" }}
                      >
                        <div className="tasks">
                          <div className="form-group multi-preview">
                            {(this.state.file || []).map((url, index) => (
                              <div key={url}>
                                <li className="list-group-item" key={index}>
                                  <p>
                                    <a>{url.name}</a>
                                  </p>{" "}
                                  <img
                                    className="mt-5"
                                    src={URL.createObjectURL(url)}
                                    alt={url.name}
                                    key={index}
                                    style={{ height: "50px", width: "100px" }}
                                  />
                                  <button
                                    type="button"
                                    style={{
                                      position: "absolute",
                                      marginLeft: "30px",
                                    }}
                                    onClick={() => this.deleteFile(index)}
                                  >
                                    delete
                                  </button>
                                </li>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      {/* <div className="form-group preview">
                        {file.length > 0 &&
                          file.map((item, index) => {
                            return (
                              <div key={item}>
                                <img src={item} alt="" />
                                <button
                                  type="button"
                                  onClick={() => this.deleteFile(index)}
                                >
                                  delete
                                </button>
                              </div>
                            );
                          })}
                      </div> */}
                    </div>
                  </div>
                  {/* {this.state.data.actionCatId === "253" && (
                    <div className="col-6">
                      <b className="mt-5">&nbsp;&nbsp;Vehicle Info :</b>

                      <div className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3">
                        {this.rRBL(
                          "Travel :",
                          "logistics",
                          this.logistics,
                          {
                            value: "logisticsByDistance",
                            label: "logisticsByLabel",
                          },
                          "col-12 mt-2 mb-1"
                        )}
                        {this.rRBL(
                          "Vehicle Type :",
                          "vehicleType",
                          this.vehicleType,
                          {
                            value: "vehicleTypeByLoad",
                            label: "vehicleTypeByLabel",
                          },
                          "col-12 mt-2 mb-1"
                        )}
                        {this.rRBL(
                          "Vehicle Load :",
                          "vehicleLoad",
                          this.vehicleLoad,
                          {
                            value: "vehicleByLoad",
                            label: "vehicleByLabel",
                          },
                          "col-12 mt-1 mb-1"
                        )}
                        <div className="row">
                          {this.rTB(
                            "Vehicle No ",
                            "vehicleNo",
                            " col-6 mt-1 mb-3"
                          )}
                          {this.rTB(
                            "Logistics Provider ",
                            "logisticsProvider",
                            " col-6 mt-1 mb-3"
                          )}
                        </div>
                        <div className="row">
                          {this.rNB(
                            "Vehicle Loaded (in Kgs) ",
                            "vehicleLoadWt",
                            " col-6 mt-1 mb-4"
                          )}
                          {this.rNB(
                            "Vehicle Empty Load (in Kgs) ",
                            "vehicleLoadEmpty",
                            " col-6 mt-1 mb-4"
                          )}
                        </div>
                        {this.rNB(
                          "Approximate Vehicle Load (in Kgs) ",
                          "apprxVehicleLoad",
                          " col-12 mt-1 mb-5"
                        )}
                      </div>
                    </div>
                  )} */}
                  <div className="col-6">
                    <b className="mt-5">&nbsp;&nbsp;Actions :</b>
                    {this.state.ProductQuestions.map((t) => {
                      return (
                        <div className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3">
                          {t.fieldType === "radio" && (
                            <div>
                              <h5>{t.question}</h5>

                              {t.options.map((op) => (
                                <div className="radio">
                                  <label>
                                    <input
                                      type="radio"
                                      value={op.value}
                                      name={t.question}
                                      id={op.id}
                                      onChange={this.handleRadio}
                                    />
                                    {op.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          )}

                          {/* <div className="row"> */}
                          <div className="col-12">
                            {t.fieldType === "textbox" && (
                              <div>
                                <h5 className="ml-2">{t.fieldHolder}</h5>
                                <input
                                  type="text"
                                  id={t.field}
                                  name={t.label}
                                  autoComplete="off"
                                  className="form-control col-8 mb-2"
                                  onChange={this.handleText}
                                />
                              </div>
                            )}
                          </div>
                          {/* </div> */}
                          <div>
                            {t.fieldType === "text" && (
                              <div className="flex-container">
                                <h5 className="ml-2">{t.fieldHolder}</h5>
                                <input
                                  type="number"
                                  id={t.field}
                                  name={t.label}
                                  min={1}
                                  autoComplete="off"
                                  className=" form-control mb-2"
                                  onChange={this.handleData}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {this.state.successData === false &&
                  this.state.data.vendor === "Yes" &&
                  this.state.selectedVenCode && (
                    <div>
                      {this.rBtnLP(
                        "Save Data",
                        "Submit",
                        "btn btn-primary btn-sm pull-right "
                      )}
                    </div>
                  )}
                {this.state.successData === false &&
                  this.state.data.vendor !== "Yes" && (
                    <div>
                      {this.rBtnLP(
                        "Save Data",
                        "Submit",
                        "btn btn-primary btn-sm pull-right "
                      )}
                    </div>
                  )}
                {this.state.data.vendor === "Yes" &&
                  !this.state.selectedVenCode && (
                    <span className="alert alert-info cusError pull-right">
                      Invalid Vendor Code
                    </span>
                  )}
                <div>
                  {this.state.successData === true && (
                    <div className="pull-right">
                      <button
                        className="btn  btn-success  btn-sm"
                        onClick={() => this.handleExpenseRise()}
                      >
                        Raise Now
                      </button>

                      <button
                        className="btn  btn-warning  btn-sm"
                        onClick={() => this.handleExpenseLater()}
                      >
                        Later
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
          {/* <div className="col-3">
            {this.rCBHL("includeVehicleInfo", this.includeVehicleInfo, {
              value: "sortKey",
              label: "sort",
            })}
            {this.state.data.includeVehicleInfo === "yes" && (
              <>
                <form>
                  <b className="mt-0">&nbsp;&nbsp;File Upload :</b>
                  <div
                    className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3 "
                    style={{ backgroundColor: "#f5f0f0" }}
                  >
                    <div className="m-0 p-0 col-10 mt-2 ml-4">
                      <label
                        htmlFor="refDocFiles"
                        className="form-label font-monospace"
                      >
                        Upload Files
                      </label>
                      <input
                        className="form-control form-control-sm"
                        multiple
                        id="refDocFiles"
                        type="file"
                        onChange={(e) => {
                          this.selectedFilesLists(e.target.files);
                        }}
                      />
                    </div>
                    {this.rTB("File Info ", "fileInfo", " col-12 mt-3 mb-4")}
                  </div>
                  <div className="col m-0 mt-1 p-0">
                    <button
                      type="button"
                      className="btn btn-primary btn-sm pull-right  "
                      onClick={(e) => {
                        this.uploadFiles(e);
                      }}
                    >
                      Submit Files
                    </button>
                  </div>
                </form>
              </>
            )}
          </div> */}

          <div
            className="draggable  col-sm-12 col-md-8 col-lg-8"
            id="myModalApprove"
            onClick={this.handleView}
          >
            {this.state.openStatus === "true" && (
              <ExpenseRise
                key={currentRequest.ticketNo}
                data={currentRequest}
                type="rise"
                ticketDetails={ticketRequest}
                ticketActionId={this.state.ticketActionId}
                // requestStatusChange={this.handleRequestStatusChange}
                // user={this.state.user}
                closeModal={this.closeModal}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(TicketActionsInfo);

export default TicketActionsInfo;
