import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLCore;
function api(path, subURL = "/flows") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getFlows() {
  return _http.get(api("all"), _http.headerJWT());
}
export function getAllFlows() {
  return _http.get(api("all/active"), _http.headerJWT());
}
export function createFlows(req) {
  const param = { ...req };
  return _http.post(api("create"), param, _http.headerJWT());
}
export function saveFlows(req) {
  const param = { ...req };
  const id = param.flowId;
  return _http.put(api("update"), param, _http.headerJWT());
}
export function deleteFlows(req) {
  const param = { ...req };
  const id = param.flowId;
  return _http.delete(api("delete/" + id), _http.headerJWT());
}
