import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLMasterLoc;
function api(path, subURL = "/country") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getCountry() {
  return _http.get(api(""), _http.headerJWT());
}

export function getActiveCountry() {
  return _http.get(api("active"), _http.headerJWT());
}

export function createCountry(req) {
  const param = { ...req };
  delete param.countryId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveCountry(req) {
  const param = { ...req };
  const id = param.countryId;
  //delete param.cityId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteCountry(req) {
  const param = { ...req };
  return _http.delete(api(param.countryId), _http.headerJWT());
}
