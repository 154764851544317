import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import {createProductSubCat,updateProductSubCat,deleteProductSubCat} from "../../../services/products/productSubCategoryServices";


class ModelProductsSubCategory extends FormLib {
    state = {
        data: {
            prodCatId: "",
            prodSubCatId:"",
            subCat: "",
            status: "active"
        },
        errors: {},
        btnValue:'create'
    };
    status = [
        { sortKey: "active", sort: "Active" },
        { sortKey: "inactive", sort: "Inactive" },
    ];
    stsConf = {
        value: "sortKey",
        label: "sort",
    };
    schema = {
        prodCatId: Joi.optional().label("prodCatId"),
        prodSubCatId: Joi.optional().label("prodSubCatId"),
        subCat: Joi.string().required().label("Sub Category Name "),
        status: Joi.string().required().label("Category Status"),
    };

    componentDidMount() {
        this.setCurCategory(this.props.curProductsCategory);
        this.setCurSubCategory(this.props.curProductsSubCategory,this.props.curProductsCategory);
         
     }
     setCurCategory(s) {
        if (s.prodCatId !== "") {
            let data1 = { ...this.state.data };
            data1["prodCatId"] = s.prodCatId;
            this.setState({data:data1 });
        } else {
          this.resetData();
        }
    }
    setCurSubCategory(p,s) {
         if (p.prodSubCatId !== "") {
            let data1 = { ...this.state.data };
            data1["prodCatId"] =s.prodCatId;
            data1["prodSubCatId"] =p.prodSubCatId;
            data1["subCat"] =p.subCat;
          this.setState({data: data1 });
        } else {
        //   this.resetData();
        }
    }

    resetData(){
        this.setState({
            data: {
                prodCatId: this.props.curProductsCategory.prodCatId,
                prodSubCatId: "",
                subCat: "",
                status: "active"
            },
        });
    }

    async doSubmit() {
        let res;
        if (this.state.btnValue === "create") {
            res = await createProductSubCat(this.state.data);
            this.resetData();
        }
        if (this.state.btnValue === "edit") {
            res = await updateProductSubCat(this.state.data);
            // this.resetData();
        }
        if (this.state.btnValue === "delete") {
            res = await deleteProductSubCat(this.state.data);
            this.resetData();
        }
        if (res.data.success === true) {
            toast.toastSuccess(res.data.msg);
            // this.props.onLoadProductCat();
        } else {
            toast.toastError(res.data.msg);
        }
    }

    render() {
        return (
            
            <div className="container-fluid">
                <div className="modal fade" id="myMaterialSubModal">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Product Sub Category</h4>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times; 
                                </button>
                            </div>

                            <div className="modal-body ">
                                <form>
                                    <div className="form-row">
                                        {this.rTB("Product Sub Category Name", "subCat", "col-12")}
                                        <br/> <br/>
                                        {this.rRBL("Status", "status", this.status, this.stsConf,"col-12")}
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                {this.state.data.prodSubCatId === "" &&
                                    this.rBtnSP("Create", "create")
                                }
                                {this.state.data.prodSubCatId !== "" && ( 
                                    <>
                                        {this.rBtnSS("Edit", "edit")}
                                        {this.rBtnSD("Delete", "delete")}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


}

export default ModelProductsSubCategory;




