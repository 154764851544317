import React, { Component } from "react";

import DropDownMenu from "./../../_common/DropDownMenu";
import {dropMenuFull,dropMenuBasic} from "./../../../utils/DropMenuUtils";


class TasksDemo extends Component {

  tasksList = [
    {
      key: "key1",
      title: "Ledger Creation API",
      OwnerName: "OwnerName",
      points: "8",
      status: "open",
      type: "api",
    },
    {
      key: "key2",
      title: "Ledger Creation API",
      OwnerName: "OwnerName",
      points: "8",
      status: "open",
      type: "web",
    },
    {
      key: "key3",
      title: "Ledger Creation Web",
      OwnerName: "OwnerName",
      points: "8",
      status: "open",
      type: "db",
    },
    {
      key: "key4",
      title: "Ledger Creation API",
      OwnerName: "OwnerName",
      points: "8",
      status: "open",
      type: "mob",
    },
    {
      key: "key5",
      title: "Ledger Creation API",
      OwnerName: "OwnerName",
      points: "8",
      status: "open",
      type: "api",
    },
    {
      key: "key6",
      title: "Ledger Creation API",
      OwnerName: "OwnerName",
      points: "8",
      status: "open",
      type: "web",
    },
  ];

  render() {
    return (
      <React.Fragment>
        <div className="pageContent">
          <div className="container-fluid">
            <div className="page-todo">
              <div className="col-md-6 tasks">
                <div className="task-list">
                  <h1>Tasks</h1>
                  <div className="priority high">
                    <span>high priority</span>
                  </div>
                  <div className="task high">
                    <div className="desc">
                      <div className="title">Lorem Ipsum</div>
                      <div>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit
                      </div>
                    </div>
                    <div className="time">
                      <div className="date">Jun 1, 2012</div>
                      <div> 1 day</div>
                    </div>
                  </div>
                  <div className="task high">
                    <div className="desc">
                      <div className="title">Lorem Ipsum</div>
                      <div>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit
                      </div>
                    </div>
                    <div className="time">
                      <div className="date">Jun 1, 2012</div>
                      <div>1 day</div>
                    </div>
                  </div>
                  <div className="task high">
                    <div className="desc">
                      <div className="title">Lorem Ipsum</div>
                      <div>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit
                      </div>
                    </div>
                    <div className="time">
                      <div className="date">Jun 1, 2012</div>
                      <div> 1 day</div>
                    </div>
                  </div>
                  <div className="task high last">
                    <div className="desc">
                      <div className="title">Lorem Ipsum</div>
                      <div>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit
                      </div>
                    </div>
                    <div className="time">
                      <div className="date">Jun 1, 2012</div>
                      <div>1 day</div>
                    </div>
                  </div>
                  <div className="priority medium">
                    <span>medium priority</span>
                  </div>
                  <div className="task medium">
                    <div className="desc">
                      <div className="title">Lorem Ipsum</div>
                      <div>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit
                      </div>
                    </div>
                    <div className="time">
                      <div className="date">Jun 1, 2012</div>
                      <div> 1 day</div>
                    </div>
                  </div>
                  <div className="task medium last">
                    <div className="desc">
                      <div className="title">Lorem Ipsum</div>
                      <div>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit
                      </div>
                    </div>
                    <div className="time">
                      <div className="date">Jun 1, 2012</div>
                      <div> 1 day</div>
                    </div>
                  </div>
                  <div className="priority low">
                    <span>low priority</span>
                  </div>
                  <div className="task low">
                    <div className="desc">
                      <div className="title">Lorem Ipsum</div>
                      <div>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit
                      </div>
                    </div>
                    <div className="time">
                      <div className="date">Jun 1, 2012</div>
                      <div> 1 day</div>
                    </div>
                  </div>
                  <div className="task low">
                    <div className="desc">
                      <div className="title">Lorem Ipsum</div>
                      <div>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit
                      </div>
                    </div>
                    <div className="time">
                      <div className="date">Jun 1, 2012</div>
                      <div> 1 day</div>
                    </div>
                  </div>
                  <div className="task low">
                    <div className="desc">
                      <div className="title">Lorem Ipsum</div>
                      <div>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit
                      </div>
                    </div>
                    <div className="time">
                      <div className="date">Jun 1, 2012</div>
                      <div> 1 day</div>
                    </div>
                  </div>
                  <div className="task low">
                    <div className="desc">
                      <div className="title">Lorem Ipsum</div>
                      <div>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit
                      </div>
                    </div>
                    <div className="time">
                      <div className="date">Jun 1, 2012</div>
                      <div> 1 day</div>
                    </div>
                  </div>
                  <div className="task low">
                    <div className="desc">
                      <div className="title">Lorem Ipsum</div>
                      <div>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit
                      </div>
                    </div>
                    <div className="time">
                      <div className="date">Jun 1, 2012</div>
                      <div> 1 day</div>
                    </div>
                  </div>
                  <div className="task low">
                    <div className="desc">
                      <div className="title">Lorem Ipsum</div>
                      <div>
                        Neque porro quisquam est qui dolorem ipsum quia dolor
                        sit amet, consectetur, adipisci velit
                      </div>
                    </div>
                    <div className="time">
                      <div className="date">Jun 1, 2012</div>
                      <div> 1 day</div>
                    </div>
                  </div>
                  <div className="priority high">
                    <span>Module List</span>
                  </div>
                  <div className="task nil">
                    <div className="desc">
                      <div className="title">Finance Module</div>
                      <div>Team Member Name</div>
                    </div>
                    <div className="rightCol">
                      <div className="menu">
                        <DropDownMenu menuItems={dropMenuFull("/v1/request/raise")} menuKey="" />
                      </div>
                      <div>trx-001</div>
                    </div>
                  </div>
                  <div className="priority high">
                    <span>Userstory List</span>
                  </div>
                  <div className="task nil">
                    <div className="desc">
                      <div className="title">Ledger Creation</div>
                      <div>
                        OwnerName | 8 Pts |
                        <span className="badge badge-primary  ml-1 ">Open</span>
                      </div>
                    </div>
                    <div className="rightCol">
                      <div className="menu">
                        <DropDownMenu menuItems={dropMenuFull("/v1/request/raise")} menuKey="" />
                      </div>
                      <div>trx-001</div>
                    </div>
                  </div>
                  <div className="priority high">
                    <span>Tasks List</span>
                  </div>
                  <div className="task nil">
                    <div className="desc">
                      <div className="title">
                        <span className="badge badge-info  mr-1 taskType">API</span>
                        Ledger Creation API
                      </div>
                      <div>
                        OwnerName | 8 Pts |
                        <span className="badge badge-primary  ml-1 ">Open</span>
                      </div>
                    </div>
                    <div className="rightCol">
                      <div className="menu">
                        <DropDownMenu menuItems={dropMenuFull("/v1/request/raise")} menuKey="" />
                      </div>
                      <div>trx-001</div>
                    </div>
                  </div>
                  <div className="task nil">
                    <div className="desc">
                      <div className="title">
                        <span className="badge badge-info  mr-1 taskType">Web</span>
                        Ledger Creation API
                      </div>
                      <div>
                        OwnerName | 8 Pts |
                        <span className="badge badge-primary ml-1 ">Open</span>
                      </div>
                    </div>
                    <div className="rightCol">
                      <div className="menu">
                        <DropDownMenu menuItems={dropMenuFull("/v1/request/raise")} menuKey="" />
                      </div>
                      <div>trx-001</div>
                    </div>
                  </div>
                  <div className="task nil">
                    <div className="desc">
                      <div className="title">
                        <span className="badge badge-info  mr-1 taskType">Mob</span>
                        Ledger Creation API
                      </div>
                      <div>
                        OwnerName | 8 Pts |
                        <span className="badge badge-success  ml-1 ">Close</span>
                      </div>
                    </div>
                    <div className="rightCol">
                      <div className="menu">
                        <DropDownMenu menuItems={dropMenuFull("/v1/request/raise")}  menuKey="" />
                      </div>
                      <div>trx-001</div>
                    </div>
                  </div>
                  <div className="task nil">
                    <div className="desc">
                      <div className="title">
                        <span className="badge badge-info   mr-1  taskType">DB</span>
                        Ledger Creation API
                      </div>
                      <div>
                        OwnerName | 8 Pts |
                        <span className="badge badge-primary ml-1">Open</span>
                      </div>
                    </div>
                    <div className="rightCol">
                      <div className="menu">
                        <DropDownMenu menuItems={dropMenuFull("/v1/request/raise")} menuKey="" />
                      </div>
                      <div>trx-001</div>
                    </div>
                  </div>
                  <div className="task nil">
                    <div className="desc">
                      <div className="title">
                        <span className="badge badge-info  mr-1 taskType">API</span>
                        Ledger Creation API
                      </div>
                      <div>
                        OwnerName | 8 Pts |
                        <span className="badge badge-primary ml-1">Open</span>
                      </div>
                    </div>
                    <div className="rightCol">
                      <div className="menu">
                        <DropDownMenu menuItems={dropMenuFull("/v1/request/raise")} menuKey="" />
                      </div>
                      <div>trx-001</div>
                    </div>
                  </div>
                  <div className="priority high">
                    <span>Dynamic Menu</span>
                  </div>
                  
                  {this.tasksList.map((mi) => (
                    <div className="task nil"  key={mi.key}>
                      <div className="desc">
                        <div className="title">
                          <span className="badge badge-info  mr-1 taskType">{mi.type}</span>
                          {mi.title}
                        </div>
                        <div>
                        {mi.OwnerName} | {mi.points} Pts | 
                          <span className="badge badge-primary ml-1">{mi.status}</span>
                        </div>
                      </div>
                      <div className="rightCol">
                        <div className="menu">
                          <DropDownMenu menuItems={dropMenuBasic("/v1/request/raise")} menuKey={mi.key} />
                        </div>
                        <div>trx-001</div>
                      </div>
                    </div>
                  ))}
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TasksDemo;
