import _http from "./../_httpService";
import config from "./../../config.json";

const apiURLValidata = config[config.build].apiURLValidata;

export function uploadBill(file) {
  const formData = new FormData();
  formData.append("file", file, file.name);

  const apiEndpoint = apiURLValidata + "mis-billing/upload";
  // return _http.post(apiEndpoint, param, _http.headerJWT());
  return _http.post(apiEndpoint, formData);
}

export function updateBill(file) {
  const formData = new FormData();
  formData.append("file", file, file.name);

  const apiEndpoint = apiURLValidata + "mis-billing/update";
  // return _http.post(apiEndpoint, param, _http.headerJWT());
  return _http.put(apiEndpoint, formData);
}

export function getBillTickets(params, pageNo) {
  return _http.get(
    apiURLValidata +
    "mis-billing/all/" +
    pageNo +
    "?startDate=" +
    params.startDate +
    "&endDate=" +
    params.endDate +
    "&rlTicketNo=" +
    params.rlTicketNo +
    "&retailer=" +
    params.retailer
  );
}

export function downloadBill(params, billStatus) {
  return _http.get(
    apiURLValidata +
    "mis-billing/download?rlTicketNo=" +
    params.rlTicketNo +
    "&startDate=" +
    params.startDate +
    "&endDate=" +
    params.endDate +
    "&billStatus=" +
    billStatus
  );
}
