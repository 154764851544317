import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLPrevilages;
function api(path, subURL = "/role-division") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getRoleDivs() {
  return _http.get(api(""), _http.headerJWT());
}
export function createRoleDivs(req) {
  const param = { ...req };
  delete param.divisionId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveRoleDivs(req) {
  const param = { ...req };
  const id = param.divisionId;
  //delete param.cityId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteRoleDivs(req) {
  const param = { ...req };
  return _http.delete(api(param.divisionId), _http.headerJWT());
}
