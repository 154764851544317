import React from "react";
import FormLib from "./../../../_common/FormLib";
import Joi from "joi-browser";
import toast from "./../../../../services/_toastService";
import $ from "jquery";

import {
  createDepartments,
  saveDepartments,
  deleteDepartments,
} from "./../../../../services/players/departmentsServices";

class ModalDepartment extends FormLib {
  state = {
    data: {
      departmentId: "",
      typeId: "",
      department: "",
      status: "active",
    },
    errors: {},
    teamTypes: [],
    curTeamType: {},
    //  curDepartment: {},
  };

  schema = {
    departmentId: Joi.optional().label("DepartmentId"),
    typeId: Joi.string().required().label("TypeId"),
    department: Joi.string().required().label("Department"),
    status: Joi.string().required().label("Status*"),
  };

  componentDidMount() {
    this.setTeamTypes(this.props.teamTypes);
    this.setCurTeamType(this.props.curTeamType);
    this.setCurDepartment(this.props.curTeamType, this.props.curDept);

  }

  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createDepartments(this.state.data);
      this.resetData(this.state.curType);
      this.props.onloadDepartments(this.state.curTeamType.typeId);
    }
    if (this.state.btnValue === "edit") {
      res = await saveDepartments(this.state.data);
      this.props.onloadDepartments(this.state.curTeamType.typeId, false);
    }
    if (this.state.btnValue === "delete") {
      res = await deleteDepartments(this.state.data);
      $("#curDepartmentButton").click();
      this.resetData(this.state.curType);
      this.props.onLoadDepartments(this.state.curTeamType.typeId);
    }
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.props.onloadDepartments();
    } else {
      toast.toastError(res.data.msg);
    }
  }


  setTeamTypes = (teamTypes) => {

    this.setState({ teamTypes: teamTypes });
  }
  setCurTeamType(t) {
    this.setState({ setCurTeamType: t });
  }
  setCurDepartment(t, m) {
    const mod = { ...m };
    delete mod.seq;
    if (m.departmentId && m.departmentId !== "") {
      this.setState({ data: mod });
    } else {
      this.resetData(t);
    }
  }

  resetData(t) {
    this.setState({
      data: {
        typeId: t.typeId || "",
        departmentId: "",
        department: "",
        status: "active",
      },
    });
  }

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;
    // {"==>"+this.state.data.roleGroupId + "<=="}


    const curDepartment = this.props.curDept;
    const teamTypes = this.props.teamTypes;
    return (
      <div className="container-fluid">
        <div className="modal fade" id="myDepartmentModel">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Departments</h4>
                <button
                  type="button"
                  className="close"
                  id="curDepartmentButton"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>

              <div className="modal-body pt-4">
                <form>
                  {this.rTBH("DepartmentId", "departmentId")}
                  {this.rSB("TypeId", "typeId", teamTypes, {
                    value: "typeId",
                    label: "type",
                  })}
                  {this.rTB("Department", "department")}
                  <div className="form-row">
                    {this.rRBL(
                      "Status",
                      "status",
                      this.status,
                      this.stsConf,
                      "col-12"
                    )}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {this.state.data.departmentId === "" &&
                  this.rBtnSP("Create", "create")}

                {this.state.data.departmentId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {this.rBtnSD("Delete", "delete")}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalDepartment;
