import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import Table from "../../_common/Table";

import dateFormat from "dateformat";
import {
  createPlan,
  getDailyPlans,
} from "./../../../services/plms/dayPlanServices";
import _auth from "./../../../services/_authService";

class ModalDayPlan extends FormLib {
  state = {
    data: {
      taskId: "",
      planDate: this.getDatebyDays(1),
      planBy: "",
      remarks: "",
      status: "open",
    },
    errors: {},
    loading: false,
    dayPlans: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };

  schema = {
    taskId: Joi.string().required().label("taskId"),
    planDate: Joi.string().required().label("planDate"),
    planBy: Joi.string().required().label("planBy"),
    remarks: Joi.string().required().label("remarks"),
  };
  componentDidMount() {
    this.resetData(this.props.curTask, _auth.getCurrentUser());
    this.getReports();
  }
  async getReports() {
    const user = _auth.getCurrentUser();
    this.setState({ loading: true });
    let resp = await getDailyPlans(
      {
        planDate: this.getDatebyDays(1),
        planBy: user.username || "",
      },
      1
    );
    if (resp !== undefined) {

      if (resp.data.success === true) {
        resp.data.data.dayPlans &&
          this.setState({ dayPlans: resp.data.data.dayPlans });
        resp.data.data.pageLinks &&
          this.setState({ pageLinks: resp.data.data.pageLinks });
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
    this.setState({ loading: false });
  }
  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createPlan(this.state.data);
      this.resetData(this.props.curTask, _auth.getCurrentUser());
      this.getReports();
    }

    toast.toastMsg(res);
  }

  resetData(t, user) {
    this.setState({
      data: {
        taskId: t.taskId || "",
        planDate: this.getDatebyDays(1),
        planBy: user.username || "",
        remarks: "",
      },
    });
  }

  tableMeta = {
    key: "planId",
    columns: [
      { path: "project", label: "Project" },
      { path: "prModule", label: "Module" },
      { path: "usTitle", label: "US" },
      { path: "taskType", label: "taskType" },
      { path: "task", label: "Task" },

      {
        key: "planDate",
        action: (r) => <>{dateFormat(r.planDate, "dd-mm-yyyy")}</>,
        label: "planDate",
      },

      { path: "planBy", label: "planBy" },
      { path: "plnRemarks", label: "remarks" },
    ],
  };
  render() {
    const { itemsCount } = this.state.pageLinks;

    return (
      <div className="container-fluid">
        <div className="modal fade" id="myDayPlanModel">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  <span style={{ color: "#ccc" }}>Reporting ::</span>
                  {this.props.curTask.task}
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  {this.rTBH("taskId", "taskId", "col-4")}
                  <div className="form-row">
                    {this.rTB("planBy", "planBy", "col-md-3")}

                    {this.rDP("planDate", "planDate", "col-md-3")}
                  </div>

                  <div className="form-row">
                    {this.rTA8("Report", "remarks", " col-md-12 ")}
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                {this.state.data.taskId !== "" &&
                  this.rBtnSP("Create", "create")}
              </div>
              <div className="row">
                {this.state.loading && (
                  <div className="container-fluid">
                    <div> Loading Reports...</div>
                  </div>
                )}
                {!this.state.loading && itemsCount > 0 && (
                  <div className="container-fluid">
                    <Table
                      clsName="table table-striped table-bordered table-hover"
                      tableMeta={this.tableMeta}
                      data={this.state.dayPlans}
                      dropMenu={[]}
                    />
                  </div>
                )}
                {!this.state.loading && itemsCount === 0 && (
                  <div className="alert alert-info col-12" role="alert">
                    <div className="text-center"> No Records Found! </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalDayPlan;
