import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";

import Table from "../../_common/Table";
import Pagination from "../../_common/Pagination";
import { paginate } from "../../../utils/paginate";

import LocationTitle from "../../_subhead/LocationTitle";
import AccountsEmpForm from "./AccountsEmpForm";
import { getEmpAccounts } from "../../../services/requestV1/AccountServices";
import { ExportXLSXReqTrans } from "./ExportXLSX";

class AccountsEmp extends FormLib {
  state = {
    data: {
      searchString: "",
    },
    errors: {},
    curEmpAccounts: {},
    loading: true,
    pageLinks: {
      pageNo: 1,
      perPage: 10,
      itemsCount: 10,
      lastPage: 0,
    },
    csvReport: {
      data: [],
      headers: [],
    },
    fileName: "",
  };
  schema = {
    searchString: Joi.optional().label("Search String"),
  };
  componentDidMount() {
    const pageNo = this.props.match.params.pageNo
      ? parseInt(this.props.match.params.pageNo)
      : 1;
    this.loadEmpAccounts(pageNo);
  }
  excelHeaders = [
    "PLAYER_CODE",
    "ACCOUNT_TYPE",
    "ACCOUNT_NO",
    "NAME",
    "IFSC",
    "BANK",
    "BRANCH",
  ];
  async loadEmpAccounts(pageNo) {
    this.setState({ loading: true });
    this.setState({ empAccounts: [] });
    this.setState({
      pageLinks: {
        pageNo: 1,
        perPage: 10,
        itemsCount: 0,
        lastPage: 0,
      },
    });

    const res = await getEmpAccounts(pageNo, this.state.data);

    if (res.data.status === 1) {
      this.setState({ empAccounts: res.data.data });

      const values = res.data.data.map((item) => ({ ...item }));
      this.getXlsxData(values);

      let pageLinks = res.data.pageLinks;
      pageLinks["itemsCount"] = pageLinks.count;
      this.setState({ pageLinks: pageLinks });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }
  async doSubmit() {
    this.loadEmpAccounts(1);
  }
  async getXlsxData(data) {
    let apiData = {};
    const { csvReport } = { ...this.state };
    apiData = data;
    this.setState({ csvReport });
    if (apiData.length > 0) {
      csvReport.data = apiData;
      this.formatHeaders(csvReport.data);
      csvReport.filename = "Emps Account";
      csvReport.data.headers = this.excelHeaders;
      this.setState({ csvReport });
    } else {
      csvReport.data = [];
      this.setState({ csvReport });
    }
  }
  formatHeaders = async (r) => {
    let formatedHeaderData = await Promise.all(
      r.filter(async (t) => {
        delete t.ACCOUNT_ID;
        delete t.CREATED_AT;
        delete t.CREATED_BY;
        delete t.UPDATED_AT;
        delete t.UPDATED_BY;
        return t;
      })
    );
    this.setState({ formatedXlsxData: formatedHeaderData });
  };
  handleOnEmpAccount(r) {
    this.setState({ curEmpAccounts: r });
  }

  handlePageChange = (pageNo) => {
    // console.log("====>" + pageNo);
    this.props.history.push("/v1/request/emp-accounts/" + pageNo);
    this.loadEmpAccounts(pageNo);
  };

  handleDataChange = () => {
    this.handlePageChange(this.state.pageLinks.pageNo);
    this.resetCurEmpAccounts();
  };

  resetCurEmpAccounts = () => {
    this.setState({ curEmpAccounts: {} });
  };

  tableMeta = {
    key: "ACCOUNT_ID",
    columns: [
      { path: "PLAYER_CODE", label: "Emp Code" },
      { path: "NAME", label: "Name" },
      { path: "ACCOUNT_TYPE", label: "Account Type" },
      { path: "ACCOUNT_NO", label: "Account No" },
      { path: "IFSC", label: "IFSC" },

      {
        key: "ACCOUNT_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-right">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnEmpAccount(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
            </div>
          </div>
        ),
        label: "Action",
      },
    ],
  };
  actVal = [
    { sortKey: "SB", sort: "SB Account" },
    { sortKey: "Current", sort: "Current" },
  ];
  actConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    //if (itemsCount === 0) return <p>No Data Found</p>;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Account::List" />
        <div className="pageContent">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-6">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Accounts</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <div className="form-row">
                    {this.rTB(
                      "Emp Code / Name / Account No / IFSC",
                      "searchString",
                      "col-6"
                    )}

                    <div className="col-1">
                      {this.rBtnSP("Search", "search")}
                    </div>
                    <div className="col-4 " style={{ marginLeft: 40 }}>
                      <ExportXLSXReqTrans
                        csvData={this.state.formatedXlsxData}
                        header={this.excelHeaders}
                        fileName={
                          this.state.csvReport.filename +
                          "-" +
                          this.getDatebyDays(0)
                        }
                      />
                    </div>
                  </div>
                  <Table
                    clsName="table table-striped tabele-bordered"
                    tableMeta={this.tableMeta}
                    data={this.state.empAccounts}
                    dropMenu={[]}
                  />
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              )}
            </div>
            <div className="col-12 col-sm-6 col-md-6">
              <button
                className="button-container btn  btn-info  btn-sm pull-right"
                onClick={() => this.resetCurEmpAccounts()}
              >
                Reset
              </button>
              <br /> <br /> <br />
              <AccountsEmpForm
                curEmpAccounts={this.state.curEmpAccounts}
                onDataChange={this.handleDataChange}
                key={this.state.curEmpAccounts.ACCOUNT_ID}
              />
            </div>
            <br />
            {this.state.csvReport.data.length === 0 && (
              <div className="alert alert-info" role="alert">
                <div className="text-center"> No Records Found! </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AccountsEmp;
