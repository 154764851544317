import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
// import logger from "redux-logger";
import thunk from "redux-thunk";

import rootReducer from "./rootReducer";

const initialState = {
  ticketListReload: 1,
  entities: {
    users: {
      users: [],
    },
  },
  auth: {
    previlages: {
      previlages: localStorage.getItem("previlages")
        ? JSON.parse(localStorage.getItem("previlages"))
        : null,
    },
    userInfo: {
      userInfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
    },
  },
  // cart: {
  //   cart: {
  //     cartItems: localStorage.getItem("cartItems")
  //       ? JSON.parse(localStorage.getItem("cartItems"))
  //       : [],
  //   },
  // },
};

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
