import React, { Component } from "react";
import _auth from "./../../services/_authService";

import Footer from "./Footer";

import AppMain from "./AppMain";

class AppWrapper extends Component {
  state = {};
  componentDidMount() {
    const user = _auth.getCurrentUser();
    if (!user) {
      this.isUserValid();
    }

    this.setState({ user });
  }
  isUserValid = () => {
    _auth.logout();
    window.location = "/";
  };
  render() {
    const { user } = this.state;

    return (
      <React.Fragment>
        <AppMain user={user} />
        <Footer />
      </React.Fragment>
    );
  }
}
export default AppWrapper;
