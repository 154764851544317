import React, { Component } from 'react'
import FormLib from './../../_common/FormLib';

class TicketEvaluation extends FormLib {

    componentDidMount() {
        this.loadEvaluation();
    }

    replaceString = (str) => {
        return str.replace(/=/g, " : ");
    };


    loadEvaluation() {
        let MIS = this.props.curMisHistory;
        const curMis = MIS.filter((m) => {
            return m.actionCode === "mPhysicEval" ? m : "";
        });

        // console.log("MISSSSS->>>>> " + JSON.stringify(curMis))
        if (curMis) {
            const curEvaluation = this.replaceString(curMis[0].resultSet);

            this.setState({ curEvaluation: JSON.stringify(curEvaluation) })
        }
    }

    render() {
        return (
            <div>TicketEvaluation  - {JSON.stringify(this.state.curEvaluation)}</div>
        )
    }
}


export default TicketEvaluation;