import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLPHP;
function api(path, subURL = "/requestV1") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getExpenseHead(pageNo, req) {
  let url =
    "request-expense-head-list.php?pageNo=" + pageNo + "&perPage=" + 200;
  // console.log(req);
  if (req && req["searchString"] && req["searchString"] !== "") {
    url = url + "&search=" + req.searchString;
  }
  return _http.get(api(url), _http.headerJWT());
}

export function createExpenseHead(req) {
  const param = {};
  param.expense_head = req.EXPENSE_HEAD;
  param.expense_head_code = req.EXPENSE_HEAD_CODE;
  return _http.post(
    api("/request-expense-head-create.php"),
    param,
    _http.headerJWT()
  );
}

export function saveExpenseHead(req) {
  const param = {};
  param.expense_head_id = req.EXPENSE_HEAD_ID;
  param.expense_head_code = req.EXPENSE_HEAD_CODE;
  param.expense_head = req.EXPENSE_HEAD;
  const id = param.expense_head_id;
  return _http.put(
    api("/request-expense-head-update.php?expense_head_id=" + id),
    param,
    _http.headerJWT()
  );
}

export function deleteExpenseHead(req) {
  const param = { ...req };
  const id = param.EXPENSE_HEAD_ID;
  return _http.delete(
    api("request-expense-head-delete.php?expense_head_id=" + id),
    _http.headerJWT()
  );
}
