import React from "react";
import FormLib from "../../_common/FormLib";
import swal from "sweetalert";

import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";
import Joi from "joi-browser";
import { resetMobileKey, resetAllMobileKey } from "../../../services/resetMobileKey/resetMobileKeyService";
class ResetMobileKey extends FormLib {

    state = {
        data: {
            userName: "",
        },
        sampleValue: 0,
        errors: {},
        loading: true,
        username: "",
        responses: {}
    };

    schema = {
        userName: Joi.string().required().label("userName"),
    };

    componentDidMount() {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.setState({ username: userInfo.username });
        // this.state.username = userInfo.username;
        // alert("userCode......" + JSON.stringify(this.state.username));
    }

    handleReset = async (id) => {
        if (this.state.data.userName) {
            swal({
                title: "Are you sure want to Reset?",
                text: "Once restted, you will not be able to recover",
                icon: "error",
                buttons: true,
                dangerMode: true,
            }).then(async (handleReset) => {
                this.resetKey(handleReset, id);
            });
        }
        else {
            toast.toastError("Kindly enter userName");
        }
    };

    handleResetAll = async (id) => {

        swal({
            title: "Are you sure want to ResetALl?",
            text: "Once restted, you will not be able to recover",
            icon: "error",
            buttons: true,
            dangerMode: true,
        }).then(async (handleResetAll) => {
            this.resetKeyAll(handleResetAll, id);
        });


    };

    resetKey = async (handleReset, id) => {
        this.state.sampleValue = id;
        this.setState({ loading: true });

        if (handleReset) {
            let params = {
                "userName": this.state.data.userName,
            }
            const res = await resetMobileKey(params);
            this.setState({ responses: res.data });
            if (res.data.success === true) {
                swal("Player has been resetted!", {
                    icon: "success",
                });
                this.resetData();
            } else {
                this.resetData();
                toast.toastInfo(res.data.msg);
            }
        }
        else {
            swal("Player is safe!");
        }

        this.setState({ loading: false });
    };

    resetKeyAll = async (handleResetAll, id) => {
        this.state.sampleValue = id;
        this.setState({ loading: true });

        if (handleResetAll) {
            const res = await resetAllMobileKey();
            this.setState({ responses: res.data });
            if (res.data.success === true) {
                swal("All Player's has been Resetted Successfully", {
                    icon: "success",
                });
            } else {
                toast.toastInfo(res.data.msg);
            }
        }
        else {
            swal("Players are safe!");
        }

        this.setState({ loading: false });
    };

    resetData = () => {
        this.setState({ data: { userName: "" } })
    }
    render() {
        return (
            <React.Fragment>
                <LocationTitle subHeading="ResetMobileKey" />
                <div className="pageContent">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-row">
                                    {this.rTB("UserName", "userName", "col-md-3")}
                                    <div className="col-1">
                                        <button
                                            className="btn  btn-info"
                                            onClick={() => this.handleReset(this.state.sampleValue = 1)}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                    {this.state.username == "admin" && (
                                        <div className="col-1">
                                            <button
                                                className="btn  btn-info"
                                                onClick={() => this.handleResetAll(this.state.sampleValue = 1)}
                                            >
                                                Reset All
                                            </button>
                                        </div>
                                    )}
                                </div>


                            </form>
                        </div>

                    </div>
                    {this.state.sampleValue === 1 && (
                        <div className="card">
                            <div className="card-body col-5" style={{ "background-color": "#ebdacd" }}>
                                <span style={{ color: 'Green', fontSize: '2rem', paddingBottom: "15%" }}>Responses ::</span>
                                <h1>Success: {JSON.stringify(this.state.responses.success)}</h1>
                                <h1>statusCode: {JSON.stringify(this.state.responses.statusCode)}</h1>
                                <h1>message: {JSON.stringify(this.state.responses.msg)}</h1>

                            </div>
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    }
}
export default ResetMobileKey;