import * as app from "../appReducer/appReducerActions";
import * as act from "./userTypes";
import { getUsers } from "./../../services/userService";

const COMP = "user";

export const setFailed = (error) => {
  return async (dispatch) => {
    dispatch(app.setError(COMP, error));
    dispatch({
      type: act.FETCH_USERS,
      payload: { users: [] },
    });
  };
};
export const fetchUsers = () => {
  return async (dispatch) => {
    dispatch(app.setLoading(COMP));
    try {
      const response = await getUsers();
      const users = response.data;
      dispatch({
        type: act.FETCH_USERS,
        payload: { users },
      });
      dispatch(app.setSuccess("user"));
    } catch (error) {
      dispatch(setFailed(error.message));
    }
  };
};
