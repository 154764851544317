import React from "react";
import FormLib from "../../../_common/FormLib";
import toast from "../../../../services/_toastService";
import { IstToUTC, epoch2yyyymmdd } from "../../../../utils/DateUtils";
import { getHubInventoryCount, getHubInventoryCountData } from "../../../../services/stockManagement/inventory";
import Table from "../../../_common/Table";
class VerifiedItemList extends FormLib {
    state = {
        itemCount: [],
        itemCountData: []
    }

    tableMeta = {
        key: "ticketNo",
        columns: [
            { path: "ticketNo", label: "ticketNo" },

            { path: "slNo", label: "Barcode" },

        ]
    }

    componentDidMount() {
        this.loadCount();
        this.loadCountData();
    }

    async loadCount() {
        try {
            const params = {
                date: epoch2yyyymmdd(new Date()),
                clientId: this.props.clientId,
                hubId: [this.props.hubId]
            }
            const resp = await getHubInventoryCount(params)
            if (resp.data.success === true) {
                this.setState({ itemCount: resp.data.data.slawReportCounts })
            }

        } catch (error) {

        }
    }

    async loadCountData() {
        try {
            const params = {
                date: epoch2yyyymmdd(new Date()),
                clientId: this.props.clientId,
                hubId: [this.props.hubId]
            }
            const resp = await getHubInventoryCountData(params)
            if (resp.data.success === true) {
                this.setState({ itemCountData: resp.data.data.slawReportCounts.totalTicketHubInventoryNotScanned })
            }
        } catch (error) {

        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="row col-md-6 pull-right ">
                    <div className="col-12">
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={this.props.closeModal}
                        >
                            <span aria-hidden="true"> Item List &times;</span>
                        </button>
                    </div>
                    <div className="card">
                        <div className="card-header bg-success text-white ">
                            <div className="row">
                                <div className="col-4"></div>
                                <div className="col-4 text-right"></div>
                            </div>
                            <div className="row">
                                <div className="col-4">{"Ticket Details"}</div>
                                <div className="col-4 text-right"></div>
                            </div>
                        </div>

                        {/* End of Header */}
                        <div>
                            <div style={{ margin: 10, fontSize: 18, fontWeight: "bold" }}>Total Ticket Hub Invetory : {this.state.itemCount.totalTicketHubInventory}</div>
                            <div style={{ margin: 10, fontSize: 18, fontWeight: "bold" }}>Total Ticket Hub Invetory Scanned : {this.state.itemCount.totalTicketHubInventoryScanned}</div>
                            <div style={{ margin: 10, fontSize: 18, fontWeight: "bold" }}>Total Ticket Hub Invetory Not Scanned : {this.state.itemCount.totalTicketHubInventoryNotScanned}</div>

                        </div>

                        <div
                            className="card-body border"
                            style={{ backgroundColor: "rgb(255 255 255)" }}
                        >
                            <div style={{ margin: 10, fontSize: 18, fontWeight: "bold", color: "red" }}>Not Scanned Data:</div>

                            <div className="row">
                                <div className="col-8"></div>
                                <div className="container-fluid">
                                    <div style={{ height: "35rem", overflow: "scroll" }}>
                                        <Table
                                            clsName="table table-striped table-bordered table-hover"
                                            tableMeta={this.tableMeta}
                                            data={this.state.itemCountData}
                                            dropMenu={[]}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default VerifiedItemList;