import React from "react";

import CountByStatusHub from "./CountByStatusHub";

const Dashboards = () => {
  return (
    <div className="row">
      <div className="col-3" style={{ height: 300 }}>
        <CountByStatusHub hubs={["202514"]} />
      </div>
      <div className="col-3" style={{ height: 300 }}>
        <CountByStatusHub hubs={["564828"]} />
      </div>
      <div className="col-3" style={{ height: 300 }}>
        <CountByStatusHub hubs={["934742"]} />
      </div>
      <div className="col-3" style={{ height: 300 }}>
        <CountByStatusHub hubs={["564800"]} />
      </div>
      <div className="col-3" style={{ height: 300 }}>
        <CountByStatusHub hubs={["3486014"]} />
      </div>
      {/* <div className="col-3" style={{ height: 300 }}>
        <CountByStatusHub
          hubs={["bhiw1653314900933TZ986eb68f946e4a75b6614536b3dce3a9"]}
        />
      </div> */}
      <div className="col-3" style={{ height: 300 }}>
        <CountByStatusHub hubs={["3486275"]} />
      </div>
      <div className="col-3" style={{ height: 300 }}>
        <CountByStatusHub hubs={["5067"]} />
      </div>
      <div className="col-3" style={{ height: 300 }}>
        <CountByStatusHub
          hubs={["blr1658330070905TZe2f734719dd24bf4af7decc2cdf86552"]}
        />
      </div>
      <div className="col-3" style={{ height: 300 }}>
        <CountByStatusHub hubs={["7524"]} />
      </div>
      <div className="col-3" style={{ height: 300 }}>
        <CountByStatusHub hubs={["185584"]} />
      </div>
      <div className="col-3" style={{ height: 300 }}>
        <CountByStatusHub hubs={["66750808"]} />
      </div>
      <div className="col-3" style={{ height: 300 }}>
        <CountByStatusHub hubs={["565001"]} />
      </div>
    </div>
  );
};

export default Dashboards;
