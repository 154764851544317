import React from "react";
import "jquery-ui-dist/jquery-ui";
import CreateManifest from "./CreateManifest";
import IncomingManifest from "./IncomingManifest";
import FormLib from "./../../../_common/FormLib";
import OutgoingManifest from "./OutgoingManifest";
import CompletedManifest from "./CompletedManifest";
import ReversedManifest from "./ReversedManifest";

import LocationTitle from "../../../_subhead/LocationTitle";
import ReviewWrapper from "./ReviewWrapper";

export default class LineHaulWrapperNew extends FormLib {
  state = {
    mode: "Create",
    data: { hub: "" },
    errors: {},
    loading: false,
  };

  onModeChange(mode) {
    this.setState({ mode: mode });
  }
  componentDidMount() {
    this.loadHubs();
    this.getHubList();
  }
  async loadHubs() {
    let hubList = JSON.parse(localStorage.getItem("hubs"));
    this.setState({ hubList });

    let curHub = localStorage.getItem("curHub");

    if (!curHub) {
      localStorage.setItem(
        "curHub",
        JSON.parse(JSON.stringify(hubList[0].hubId))
      );
      let override = this.state.data;
      override["hub"] = JSON.parse(JSON.stringify(hubList[0].hubId));
      this.setState({ override });
    } else {
      let override = this.state.data;
      override["hub"] = curHub;
      this.setState({ override });
    }
  }

  getHubList = () => {
    let hubList = JSON.parse(localStorage.getItem("hubs"));
    let selectedHub = localStorage.getItem("curHub");
    const curHubCode = hubList.filter((h) => {
      return h.hubId === selectedHub ? h.hubCode : "";
    });

    if (curHubCode !== "") {
      this.setState({ curHubCode: curHubCode[0].hubCode });
      this.setState({ curHubName: curHubCode[0].hub });
    } else {
      this.setState({ curHubName: curHubCode[0].hub });
      this.setState({ curHubCode: hubList[0].hubCode });
      localStorage.setItem("curHub", hubList[0].hubId);
      selectedHub = hubList[0].hubId;
      let override = this.state.data;
      override["hub"] = selectedHub;
      this.setState({ override });
    }
  };
  stsConf = {
    value: "hubId",
    label: "hub",
  };

  localHandleChange = (e) => {
    try {
      if (e.target.name === "hub") {
        localStorage.setItem("curHub", e.target.value);
        this.setState({ data: { hub: e.target.value } });
        this.getHubList();
        if (this.state.refreshCallback) {
          // console.log("refreshCallback is called", this.state.refreshCallback)
          this.state.refreshCallback();
        }
      }
    } catch (error) {
      console.log("Error in LineHaulWrapperNew ---> localHandleChange");
      console.error(error);
    }
  };

  refreshListCallback = (callback) => {
    this.setState({ refreshCallback: callback });
  };

  render() {
    // console.log("LinehaulWrapper", {state: this.state})
    return (
      <React.Fragment>
        <LocationTitle subHeading="Process :: Linehaul" />
        <div className="pageContent">
          <a>
            <i
              id="btn1"
              data-target="#myModalProduct"
              data-backdrop="false"
              data-toggle="modal"
              className="badge badge-warning  cursorPointor pr-1 zoom"
              aria-hidden="true"
              title={"Select Hub"}
              style={{ marginLeft: "-1px" }}
            >
              <b>{this.state.curHubName}</b>
            </i>
          </a>
          <div role="tabpanel">
            <div className="text-center">
              <button
                onClick={() => this.onModeChange("Create")}
                className={
                  this.state.mode === "Create"
                    ? " btn btn-success btn-sm "
                    : "btn  btn-default btn-sm "
                }
              >
                Create
              </button>
              <button
                onClick={() => this.onModeChange("Incoming")}
                className={
                  this.state.mode === "Incoming"
                    ? " btn btn-success btn-sm "
                    : "btn  btn-default btn-sm "
                }
              >
                Incoming
              </button>
              <button
                onClick={() => this.onModeChange("Review")}
                className={
                  this.state.mode === "Review"
                    ? " btn btn-success btn-sm "
                    : "btn  btn-default btn-sm "
                }
              >
                Manifested
              </button>
              <button
                onClick={() => this.onModeChange("Outgoing")}
                className={
                  this.state.mode === "Outgoing"
                    ? " btn btn-success btn-sm "
                    : "btn  btn-default btn-sm "
                }
              >
                Outgoing
              </button>
              <button
                onClick={() => this.onModeChange("Completed")}
                className={
                  this.state.mode === "Completed"
                    ? " btn btn-success btn-sm "
                    : "btn  btn-default btn-sm "
                }
              >
                Completed
              </button>
              <button
                onClick={() => this.onModeChange("Reversed")}
                className={
                  this.state.mode === "Reversed"
                    ? " btn btn-success btn-sm "
                    : "btn  btn-default btn-sm "
                }
              >
                Reversed
              </button>
            </div>
            <hr />
            <div className="tab-content">
              {this.state.mode === "Create" && (
                <CreateManifest
                  refreshListCallback={this.refreshListCallback}
                />
              )}
              {this.state.mode === "Incoming" && (
                <IncomingManifest
                  refreshListCallback={this.refreshListCallback}
                />
              )}
              {this.state.mode === "Review" && (
                <ReviewWrapper refreshListCallback={this.refreshListCallback} />
              )}
              {this.state.mode === "Outgoing" && (
                <OutgoingManifest
                  refreshListCallback={this.refreshListCallback}
                />
              )}
              {this.state.mode === "Completed" && (
                <CompletedManifest
                  refreshListCallback={this.refreshListCallback}
                />
              )}
              {this.state.mode === "Reversed" && (
                <ReversedManifest
                  refreshListCallback={this.refreshListCallback}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="myModalProduct"
          role="dialog"
          aria-labelledby="myModalProduct"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  Select Hub
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {this.state.hubList?.length > 0 && (
                <div className="modal-body">
                  <form>
                    {this.rRBL("", "hub", this.state.hubList, this.stsConf)}
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
