import _http from "./../_httpService";
import config from "./../../config.json";

const baseURL = config[config.build].apiURLPunchInOut;

function api(path, subURL = "") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getPunchedInFEsByHubId(hubId) {
  //   const baseURL = "http://localhost:1337/punchInOut";
  const url = `${baseURL}/punchedInFEs/${hubId}`;
  return _http.get(url, _http.headerJWT());
}

export function getPunchedInFEsByHubIdSSE(hubId) {
  const url = `${baseURL}/punches/${hubId}`;

  return new EventSource(url);
}
