import React from "react";
import FormLib from "../../_common/FormLib";
import ZTitle from "../../_subhead/ZTitle";

import Joi from "joi-browser";
import { getDailyPlansReport } from "./../../../services/plms/dayPlanServices";
import * as _plms from "./../../../services/plms/_plmsServices";

import { toast } from "react-toastify";
import Table from "../../_common/Table";

import Pagination from "../../_common/Pagination";
import dateFormat from "dateformat";

export default class DailyPlans extends FormLib {
  state = {
    data: {
      planDateFrom: this.getDatebyDays(0),
      planDateTo: this.getDatebyDays(0),
      planBy: "",
    },
    errors: {},
    loading: false,
    dayPlans: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      count: 0,
      lastPage: 0,
    },
  };

  schema = {
    planDateFrom: Joi.string().required().label("Start Date"),
    planDateTo: Joi.string().required().label("End Date"),
    planBy: Joi.optional().label("Employee Code"),
  };
  tableMeta = {
    key: "reportId",
    columns: [
      { path: "project", label: "Project" },
      { path: "prModule", label: "Module" },
      { path: "usTitle", label: "US" },
      { path: "taskType", label: "taskType" },
      { path: "task", label: "Task" },

      {
        key: "DateTime",
        action: (r) => <>{r.taskType}</>,
        label: "fromDateTime",
      },
      {
        key: "DateTime",
        action: (r) => <>{dateFormat(r.planDateFrom, "dd-mm-yyyy")}</>,
        label: "fromDateTime",
      },
      {
        key: "planDateTo",
        action: (r) => <>{dateFormat(r.planDateTo, "dd-mm-yyyy")}</>,
        label: "toDateTime",
      },

      { path: "repStatus", label: "status" },
      { path: "planBy", label: "planBy" },
      { path: "plnRemarks", label: "remarks" },
    ],
  };

  async componentDidMount() {
    this.setOwners();
  }
  setOwners = () => {
    this.setState({ owners: _plms.users.owners });
    this.setState({ ownerConf: _plms.users.ownerConf });
  };

  async doSubmit() {
    await this.getdayPlans(this.state.data, this.state.pageLinks.pageNo);
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/storyboard/daily-dayPlans/" + pageNo);
    this.getdayPlans(this.state.data, pageNo);
  };

  async getdayPlans(params, pageNo) {
    this.setState({ loading: true });
    this.setState({ dayPlans: [] });
    let resp = await getDailyPlansReport(params, pageNo);

    if (resp.data.success === true) {
      resp.data.data.dayPlans &&
        this.setState({ dayPlans: resp.data.data.dayPlans });
      resp.data.data.pageLinks &&
        this.setState({ pageLinks: resp.data.data.pageLinks });
      resp.data.data.dayPlans &&
        toast.success(() => <div>{resp.data.msg}</div>);
      !resp.data.data.dayPlans && toast.warning(() => <div>No dayPlans</div>);
    } else {
      toast.error(() => <div>{resp.data.msg}</div>);
    }
    this.setState({ loading: false });
  }

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;

    const dayPlans = this.state.dayPlans;
    return (
      <React.Fragment>
        <ZTitle subHeading="Daily Plans " />
        <div className="pageContent">
          <div className="card">
            <div className="card-body">
              <form id="myForm" onSubmit={this.handleSubmit}>
                <div className="form-row">
                  {this.rDP("Start Date *", "planDateFrom", "col-md-3")}
                  {this.rDP("End Date *", "planDateTo", "col-md-3")}
                  {this.rSB(
                    "Owner*",
                    "planBy",
                    this.state.owners,
                    this.state.ownerConf,
                    " col-md-3 "
                  )}
                </div>
                <div className="col-9">
                  {this.rBtnS("Submit", "Submit", "btn btn-primary  btn-sm")}
                </div>
              </form>
              <br />
              <div className="row">
                {this.state.loading && (
                  <div className="container-fluid">
                    <div> Loading dayPlans...</div>
                  </div>
                )}
                {!this.state.loading && itemsCount > 0 && (
                  <div className="container-fluid">
                    <Table
                      clsName="table table-striped table-bordered table-hover"
                      tableMeta={this.tableMeta}
                      data={dayPlans}
                      dropMenu={[]}
                    />
                    <Pagination
                      pageNo={pageNo}
                      perPage={perPage}
                      itemsCount={itemsCount}
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                )}
                {!this.state.loading && itemsCount === 0 && (
                  <div className="alert alert-info col-12" role="alert">
                    <div className="text-center"> No Records Found! </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
