// import _http from "./../../_httpService";
import _http from "./../_httpService";
import config from "./../../config.json";

const baseURL = config[config.build].apiURLEDashboard;
const baseURLInventory = config[config.build].apiURLInventory;

function api(path, subURL = "") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function postInwardInventory(req) {
  // const url = api("api/ticket/create/InstallationV1")
  const formData = new FormData();
  const files = req.selectedFileList;
  for (let i = 0; i < files.length; i++) {
    formData.append("file", files[i], files[i].name);
  }

  const fieldsData = { ...req };
  delete fieldsData.selectedFileList;

  const fields = JSON.stringify(fieldsData);
  formData.append("fields", fields);
  // console.log({ fields });
  const url = `${baseURLInventory}/inventory/inward-at-storeV2`;

  return _http.post(url, formData, _http.headerJWT());
}

export function getStoresByHub(hubId) {
  const url = `${baseURL}/metrics/getStores/${hubId}`;

  // console.log({url});
  // console.log("get stores by hubId", url);
  return _http.get(url, _http.headerJWT());
}
export function getItemStockListByHubId(hubId) {
  const url = `${baseURL}/metrics/getItemStockListByHubId/${hubId}`;
  // console.log("get stores by hubId", url);
  return _http.get(url, _http.headerJWT());
}

export function getInvTxnsByStoreId(storeid) {
  const url = `${baseURL}/metrics/inwardByStoreId/${storeid}`;
  // console.log("get invTxns by StoreId", url);
  return _http.get(url, _http.headerJWT());
}

export function getItemsListByInvTxnId(invTxnId) {
  const url = `${baseURL}/metrics/itemByInvTrxId/${invTxnId}`;
  // console.log("get items list by inv txn id", url);
  return _http.get(url, _http.headerJWT());
}

export function getInvItemTxnByItemId(invTrxItemsId) {
  const url = `${baseURL}/metrics/TrxByInvTrxItemId/${invTrxItemsId}`;
  // console.log("get itemlist by inv txn item", url);
  return _http.get(url, _http.headerJWT());
}

export function postInvItemsComp(req) {
  const url = `${baseURL}/metrics/inventory/inward/`;
  // console.log("post items in compartment", url);
  return _http.post(url, req, _http.headerJWT());
}

export function getBoxesByStoreId(storeId) {
  const url = `${baseURL}/metrics/boxesByStoreId/${storeId}`;
  // console.log("get itemlist by inv txn item", url);
  return _http.get(url, _http.headerJWT());
}

export function getItemListByStoreId(storeId) {
  const url = `${baseURL}/metrics/getItemStockList/${storeId}`;
  // console.log("get item list by storeId", url);
  return _http.get(url, _http.headerJWT());
}

export function getItemList() {
  const url = `${baseURL}/metrics/getItemList`;
  // console.log("get item list by storeId", url);
  return _http.get(url, _http.headerJWT());
}
export function getItemListByTeamId(retailerId) {
  const url = `${baseURL}/metrics/getItemListByTeamId/${retailerId}`;
  // console.log("get stores by hubId", url);
  return _http.get(url, _http.headerJWT());
}

export function getItemsByStore(storeId) {
  const url = `${baseURL}/metrics/getItemStockList/${storeId}`;
  // console.log("get stores by hubId", url);
  return _http.get(url, _http.headerJWT());
}
export function getItemListByStoreBays(retailerId, storeBaysId) {
  const url = `${baseURL}/metrics/getItemListByStoreBaysId/${retailerId}/${storeBaysId}`;
  // console.log("get stores by hubId", url);
  return _http.get(url, _http.headerJWT());
}
export function getStockByItem(storeBaysId, itemId) {
  const url = `${baseURL}/metrics/getItemsDetails/${storeBaysId}/${itemId}`;
  // console.log("get stores by hubId", url);
  return _http.get(url, _http.headerJWT());
}

export function assignBarcodeToProducts(params) {
  const apiEndpoint = `${baseURLInventory}/assignBarcodeToProducts`;
  return _http.put(apiEndpoint, params, _http.headerJWT());
}
export function assignBarcodeToTicket(params) {
  const apiEndpoint = `${baseURLInventory}/attachItemToTicket`;
  return _http.put(apiEndpoint, params, _http.headerJWT());
}
export function getInventoryBarcodeDetails(barcode) {
  // console.log("barcode......" + JSON.stringify(barcode));
  let status = "00available";
  const url = `${baseURLInventory}/getInvBarcodeDetailsBySlNoAndStatus/${barcode}/${status}`;
  return _http.get(url, _http.headerJWT());
}

export function getInwardedInventoryBarcodeDetails(barcode) {
  // console.log("barcode......" + JSON.stringify(barcode));
  let status = "00available";
  const url = `${baseURLInventory}/getBarcodeDetailsByslNov1/${barcode}/${status}`;
  return _http.get(url, _http.headerJWT());
}

export function updateItemSkuCode(params) {
  const apiEndpoint = `${baseURLInventory}/mapGivenSkuToTheItem`;
  // const apiEndpoint = `${baseURLInventory}/verifyBarcodeMappedToItem`;
  return _http.put(apiEndpoint, params, _http.headerJWT());
}

export function getItemBySkuBarcode(itemCode, retailer) {
  const apiEndpoint = `${baseURLInventory}/getItemDetailsBySkuBarcode/${retailer}/${itemCode}`;
  return _http.get(apiEndpoint, _http.headerJWT());
}
export function getItemBySku(retailer, itemCode) {
  const apiEndpoint = `${baseURLInventory}/getItemDetailsBySku/${retailer}/${itemCode}`;
  return _http.get(apiEndpoint, _http.headerJWT());
}
export function getItemsWithNoSku(retailer) {
  const apiEndpoint = `${baseURLInventory}/getItemDetailsBySkuBarcode/${retailer}`;
  return _http.get(apiEndpoint, _http.headerJWT());
}

export function postOutwardInventory(req) {
  // const url = api("api/ticket/create/InstallationV1")
  const formData = new FormData();
  const files = req.filesSelected;
  for (let i = 0; i < files.length; i++) {
    formData.append("file", files[i], files[i].name);
  }

  const fieldsData = { ...req };
  delete fieldsData.selectedFileList;

  const fields = JSON.stringify(fieldsData);
  formData.append("fields", fields);
  const url = `${baseURLInventory}/updateOutwardProductData`;

  return _http.post(url, formData, _http.headerJWT());
}

export function getOutwardProdDetails(itemSku, teamId, hubId) {
  // console.log("barcode......" + JSON.stringify(barcode));
  const url = `${baseURLInventory}/getOutwardProdDetails/${itemSku}/${teamId}/${hubId}`;
  return _http.get(url, _http.headerJWT());
}
export function getOutwardProdDetailsBySlNo(slNo, teamId, hubId) {
  // console.log("barcode......" + JSON.stringify(barcode));
  const url = `${baseURLInventory}/getOutwardProdDetailsV1/${slNo}/${teamId}/${hubId}`;
  return _http.get(url, _http.headerJWT());
}
// https:/test-api.bizlog.in:1337/inventory/getOutwardProdDetailsV1/:slNo/:teamId/:hubId

export function getItemDetails(retailerId, hubId) {
  // console.log("barcode......" + JSON.stringify(barcode));
  const url = `${baseURLInventory}/getItemQntDetails/${hubId}/${retailerId}`;
  return _http.get(url, _http.headerJWT());
}

export function assignBarcodeToItemQnt(params) {
  const apiEndpoint = `${baseURLInventory}/assignBarcodeToItemQnt`;
  // const apiEndpoint = `${baseURLInventory}/verifyBarcodeMappedToItem`;
  return _http.put(apiEndpoint, params, _http.headerJWT());
}
export function getBarcodeDetailsByHubTeamAndSlNo(params) {
  const apiEndpoint = `${baseURLInventory}/getBarcodeDetailsByHubTeamAndSlNo`;
  // const apiEndpoint = `${baseURLInventory}/verifyBarcodeMappedToItem`;
  return _http.put(apiEndpoint, params, _http.headerJWT());
}
