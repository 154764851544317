import React from 'react'
import ModuleCreate from './ModuleCreate';

class ProjectModal extends React.Component {

 

    render() {
        return (
            <div className="container-fluid col-md-3 pull-left">
                <button type="button"   className="btn btn-sm btn-primary" data-toggle="modal" data-target="#myModal">
                    M
                </button>
   {/* <div className="modal   fade" id="myModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Module</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-body">
                            <ModuleCreate/>
                            </div>
                            <div className="modal-footer pull-left">
                                <button type="button" className="btn btn-success" data-dismiss="modal">Save</button>
                            </div>
                            
                        </div>
                        
                    </div>
                </div> */}





<div class="container-fluid">
 

 
  <div class="modal fade" id="myModal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
      
       
        <div class="modal-header">
          <h4 class="modal-title">Module</h4>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        
        
        <div class="modal-body p-0">
        <ModuleCreate/>
        </div>
        
       
        <div class="modal-footer">
          <button type="button" class="btn btn-success" data-dismiss="modal">Save</button>
        </div>
        
      </div>
    </div>
  </div>
  
</div>









             </div>

          


        );
    }
}

export default ProjectModal;

