import * as act from "./previlageTypes";

const initialState = {
  error: "",
  loading: false,
  previlages: [],
};

const previlageReducer = (state = initialState, action) => {
  switch (action.type) {
    case act.SET_PREVILAGE:
      return {
        loading: false,
        previlages: action.payload,
        error: "",
      };
    case act.SET_PREVILAGE_FAILURE:
      return {
        loading: false,
        previlages: [],
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default previlageReducer;
