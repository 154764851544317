import React from "react";
import FormLib from "../../_common/FormLib";

import Joi from "joi-browser";
import { getTasksByProjStatus } from "./../../../services/plms/taskServices";
import { toast } from "react-toastify";
import Table from "../../_common/Table";
import ModalReport from "./ModalReport";
import ModalDayPlan from "./ModalDayPlan";
import Pagination from "../../_common/Pagination";
import * as _plms from "./../../../services/plms/_plmsServices";
import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption,
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";

export default class TasksList extends FormLib {
  state = {
    data: {
      status: "active",
      typeList: "tasks",
      typeDisplay: "table",
    },
    errors: {},
    loading: false,
    tasks: [],
    tasksFilter: [],
    filter: null,
    filterFlag: "On",
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    owners: [],
    curFilter: {},
    curTask: {},
    sprintList: [],
    filterSprints: [],
    filterTskStatus: [],
    filterTskOwner: [],
    curListType: "",
  };

  schema = {
    status: Joi.string().required().label("Status"),
    typeList: Joi.string().required().label("Type"),
    typeDisplay: Joi.string().required().label("Display"),
  };
  async componentDidMount() {
    this.setOwners();
    this.setSprints();

    await this.getTasksByProjStatus(
      this.state.data,
      this.state.pageLinks.pageNo
    );
    this.addFilterByProject(null);
  }

  setOwners = () => {
    this.setState({ owners: _plms.users.owners });
    this.setState({ ownerConf: _plms.users.ownerConf });
  };
  setSprints = () => {
    this.setState({ sprintList: _plms.sprintList });
  };
  taskStatusFilter = [
    { tskStatus: "00open" },
    { tskStatus: "05assign" },
    { tskStatus: "08plan" },
    { tskStatus: "10wip" },
    { tskStatus: "15hold" },
    { tskStatus: "20dev" },
    { tskStatus: "25test" },
    { tskStatus: "30done" },
    { tskStatus: "99pipe" },
  ];
  setFilterFlag() {
    this.state.filterFlag === "On"
      ? this.setState({ filterFlag: "Off" })
      : this.setState({ filterFlag: "On" });
  }

  addFilterByProject = (r) => {
    if (r === null) {
      this.setState({ tasksFilter: this.state.tasks });
      this.setState({ filter: null });
      this.setState({ curFilter: {} });
      this.filterTasks({});
    } else if (r["projectId"]) {
      let curFilter = { ...this.state.curFilter };
      curFilter["projectId"] = r["projectId"];
      this.setState({ curFilter });
      this.filterTasks(curFilter);

      // const tasksFilter = this.state.tasks.filter((u) => {
      //   return r["projectId"] === u["projectId"] ? u : null;
      // });

      // this.setState({ tasksFilter });
      // this.setState({ filter: r });
    }
  };
  addFilterByModule = (r) => {
    let curFilter = { ...this.state.curFilter };
    curFilter["moduleId"] = r["moduleId"];
    this.setState({ curFilter });
    this.filterTasks(curFilter);

    // const tasksFilter = this.state.tasks.filter((u) => {
    //   return r["moduleId"] === u["moduleId"] ? u : null;
    // });
    // this.setState({ tasksFilter });
    // this.setState({ filter: r });
    // this.setState({ curFilter });
  };
  addFilterByStory = (r) => {
    let curFilter = { ...this.state.curFilter };
    curFilter["userstoryId"] = r["userstoryId"];
    this.setState({ curFilter });
    this.filterTasks(curFilter);
    // const tasksFilter = this.state.tasks.filter((u) => {
    //   return r["userstoryId"] === u["userstoryId"] ? u : null;
    // });
    // this.setState({ tasksFilter });
    // this.setState({ filter: r });
    // this.setState({ curFilter });
  };

  addFilterByTaskStatus = (r) => {
    let curFilter = { ...this.state.curFilter };
    curFilter["tskStatus"] = r["tskStatus"];
    this.setState({ curFilter });
    let filterTskStatus = [...this.state.filterTskStatus];

    let index = filterTskStatus.indexOf(r["tskStatus"]);

    if (index >= 0) {
      filterTskStatus.splice(index, 1);
    } else {
      filterTskStatus.push(r["tskStatus"]);
    }
    curFilter["filterTskStatus"] = filterTskStatus;
    this.setState({ filterTskStatus });
    this.filterTasks(curFilter);
  };

  addFilterByUser = (r) => {
    let curFilter = { ...this.state.curFilter };
    curFilter["tskOwner"] = r["sortKey"];
    this.setState({ curFilter });

    let filterTskOwner = [...this.state.filterTskOwner];

    let index = filterTskOwner.indexOf(r["sortKey"]);

    if (index >= 0) {
      filterTskOwner.splice(index, 1);
    } else {
      filterTskOwner.push(r["sortKey"]);
    }
    curFilter["filterTskOwner"] = filterTskOwner;
    this.setState({ filterTskOwner });

    this.filterTasks(curFilter);
  };

  addFilterBySprint = (r) => {
    let curFilter = { ...this.state.curFilter };

    curFilter["sprint"] = r;
    this.setState({ curFilter });

    let filterSprints = [...this.state.filterSprints];

    let index = filterSprints.indexOf(r);

    if (index >= 0) {
      filterSprints.splice(index, 1);
    } else {
      filterSprints.push(r);
    }
    curFilter["filterSprints"] = filterSprints;
    this.setState({ filterSprints });
    this.filterTasks(curFilter);
  };

  filterTasks = async (f) => {
    const tasksFilter = await this.state.tasks.filter((tasks) => {
      if (
        f["projectId"] &&
        f["projectId"] !== null &&
        f["projectId"] !== tasks["projectId"]
      ) {
        return null;
      }
      if (
        f["moduleId"] &&
        f["moduleId"] !== null &&
        f["moduleId"] !== tasks["moduleId"]
      ) {
        return null;
      }
      if (
        f["userstoryId"] &&
        f["userstoryId"] !== null &&
        f["userstoryId"] !== tasks["userstoryId"]
      ) {
        return null;
      }
      //Sprint
      if (
        f["filterSprints"] &&
        f["filterSprints"].length > 0 &&
        f["filterSprints"].indexOf(tasks["usColumn"]) < 0
      ) {
        return null;
      }
      //Owner
      if (
        f["filterTskOwner"] &&
        f["filterTskOwner"].length > 0 &&
        ((this.state.data.typeList === "tasks" &&
          f["filterTskOwner"].indexOf(tasks["tskOwner"]) < 0) ||
          (this.state.data.typeList !== "tasks" &&
            f["filterTskOwner"].indexOf(tasks["usOwner"]) < 0))
      ) {
        return null;
      }
      //Status
      if (
        f["filterTskStatus"] &&
        f["filterTskStatus"].length > 0 &&
        ((this.state.data.typeList === "tasks" &&
          f["filterTskStatus"].indexOf(tasks["tskStatus"]) < 0) ||
          (this.state.data.typeList !== "tasks" &&
            f["filterTskStatus"].indexOf(tasks["usStatus"]) < 0))
      ) {
        return null;
      }

      tasks["name"] = tasks.usTitle ? tasks.usTitle : "Undefined";

      tasks["progress"] = 0;
      tasks["type"] = "userstory";
      tasks["hideChildren"] = false;
      if (this.state.curListType === "userstory") {
        tasks["id"] = tasks.userstoryId ? tasks.userstoryId : Math.random();
      } else {
        tasks["id"] = tasks.taskId ? tasks.taskId : Math.random();
      }

      tasks["start"] = tasks?.startDate
        ? new Date(tasks.startDate)
        : new Date();
      tasks["end"] = tasks?.dueDate ? new Date(tasks.dueDate) : new Date();
      if (tasks["start"] > tasks["end"]) {
        tasks["end"] = tasks["start"];
      }
      // tasks["end"].addDays(5);
      return tasks;
    });
    this.setState({ tasksFilter });
  };

  randomNumber = (min = 1, max = 10) => {
    return Math.random() * (max - min) + min;
  };

  handleTaskChange = (t, e) => {
    this.setState({ curTask: t });
  };

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  typeDisplay = [
    { sortKey: "table", sort: "Table" },
    { sortKey: "chart", sort: "Chart" },
  ];
  typeDisplayConf = {
    value: "sortKey",
    label: "sort",
  };

  typeList = [
    { sortKey: "userstory", sort: "Userstory" },
    { sortKey: "tasks", sort: "Tasks" },
  ];
  typeConf = {
    value: "sortKey",
    label: "sort",
  };
  userstory;

  tableMetaTasks = {
    key: "taskId",
    columns: [
      {
        key: "moduleId",
        action: (r) => (
          <>
            <span onClick={() => this.addFilterByModule(r)}>
              {r["prModule"]?.trim()}
            </span>
            <br />
            <span
              className={"badge   badge-success pull-left mr-3  badge-00open"}
              onClick={() => this.addFilterByProject(r)}
            >
              {r["project"]?.trim()}
            </span>
            <span
              className={"badge   badge-success pull-right mr-3  badge-00open"}
              onClick={() => this.addFilterByProject(r)}
            >
              {r["modStatus"]?.substring(2)}
            </span>
          </>
        ),
        label: "Module",
      },

      {
        key: "userstoryId",
        action: (r) => (
          <>
            <span onClick={() => this.addFilterByStory(r)}>
              {r["usTitle"]?.trim()}
            </span>
            <br />
            <div className="pull-left">
              <span
                className={
                  "badge   badge-success pull-left  mr-1 " +
                  "badge-" +
                  r.usComplexity
                }
              >
                {r.usColumn}
              </span>
              <span className={"badge   badge-light pull-left ml-1 "}>
                {_plms.getUserByCode(r["usOwner"])}
              </span>
              <span
                className={
                  "badge   badge-success pull-left mr-1 " +
                  "badge-" +
                  r.usPriority
                }
              >
                {r.usPriority}
              </span>

              <span className={"badge   badge-light pull-left ml-1 "}>
                {r["usPoints"] + " pts"}
              </span>

              <span
                className={
                  "badge   badge-success pull-right ml-1 " +
                  "badge-" +
                  r["usStatus"]
                }
              >
                {r["usStatus"].substring(2)}
              </span>
            </div>
          </>
        ),
        label: "Stories",
      },
      {
        key: "taskId",
        action: (r) => (
          <>
            <span
              className={
                "badge badge-success pull-left mr-1  badge-" + r["taskType"]
              }
            >
              {r["taskType"]}
            </span>
            <span
              onClick={(e) => this.handleTaskChange(r, e)}
              style={{ cursor: "pointer" }}
            >
              {r["task"]?.trim()}
            </span>
            <br />
            <div className="pull-right">
              <span className={"badge   badge-light pull-left ml-1 "}>
                {_plms.getUserByCode(r["tskOwner"])}
              </span>
              <span className={"badge   badge-light pull-left ml-1 "}>
                {r["tskPoints"] + " pts"}
              </span>
              <span
                className={
                  "badge   badge-success pull-right ml-1 " +
                  "badge-" +
                  r["tskStatus"]
                }
                onClick={() => this.addFilterByTaskStatus(r)}
              >
                {r["tskStatus"]?.substring(2)}
              </span>
            </div>
          </>
        ),
        label: "Tasks",
      },
    ],
  };
  tableMetaUserstories = {
    key: "userstoryId",
    columns: [
      {
        key: "moduleId",
        action: (r) => (
          <>
            <span onClick={() => this.addFilterByModule(r)}>
              {r["prModule"]?.trim()}
            </span>
            <br />
            <span
              className={"badge   badge-success pull-left mr-3  badge-00open"}
              onClick={() => this.addFilterByProject(r)}
            >
              {r["project"]?.trim()}
            </span>
            <span
              className={"badge   badge-success pull-right mr-3  badge-00open"}
              onClick={() => this.addFilterByProject(r)}
            >
              {r["modStatus"].substring(2)}
            </span>
          </>
        ),
        label: "Module",
      },

      {
        key: "userstoryId",
        action: (r) => (
          <>
            <span onClick={() => this.addFilterByStory(r)}>
              {r["usTitle"]?.trim()}
            </span>
            <br />
            <div className="pull-left">
              <span
                className={
                  "badge   badge-success pull-left  mr-1 " +
                  "badge-" +
                  r.usComplexity
                }
              >
                {r.usColumn}
              </span>
              <span className={"badge   badge-light pull-left ml-1 "}>
                {_plms.getUserByCode(r["usOwner"])}
              </span>
              <span
                className={
                  "badge   badge-success pull-left mr-1 " +
                  "badge-" +
                  r.usPriority
                }
              >
                {r.usPriority}
              </span>

              <span className={"badge   badge-light pull-left ml-1 "}>
                {r["usPoints"] + " pts"}
              </span>

              <span
                className={
                  "badge   badge-success pull-right ml-1 " +
                  "badge-" +
                  r["usStatus"]
                }
              >
                {r["usStatus"].substring(2)}
              </span>
            </div>
          </>
        ),
        label: "Stories",
      },
    ],
  };
  tableMeta = this.tableMetaUserstories;

  async doSubmit() {
    await this.getTasksByProjStatus(
      this.state.data,
      this.state.pageLinks.pageNo
    );
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/storyboard/tasks/" + pageNo);
    this.getTasksByProjStatus(this.state.data, pageNo);
  };

  async getTasksByProjStatus(params, pageNo) {
    if (params.typeList && params.typeList === "tasks") {
      this.tableMeta = this.tableMetaTasks;
    } else {
      this.tableMeta = this.tableMetaUserstories;
    }
    this.setState({ loading: true });
    let resp = await getTasksByProjStatus(params, pageNo);

    // tasks userstories
    // console.log(resp);
    if (resp && resp.data && resp.data.success === true) {
      // console.log(resp.data.data);
      resp.data.data.tasks && this.setState({ tasks: resp.data.data.tasks });
      resp.data.data.userstories &&
        this.setState({ tasks: resp.data.data.userstories });

      if (this.state.filterFlag === "On") {
        this.addFilterByProject(this.state.curFilter);
      } else {
        this.addFilterByProject(null);
      }

      resp.data.data.pageLinks &&
        this.setState({ pageLinks: resp.data.data.pageLinks });
      resp.data.data.tasks && toast.success(() => <div>{resp.data.msg}</div>);
      !resp.data.data.tasks && toast.warning(() => <div>No Tasks</div>);
    } else {
      toast.error(() => <div>{resp?.data?.msg}</div>);
    }
    this.setState({ loading: false });
    this.setState({ curListType: this.state.data.typeList });
  }

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;

    const tasks = this.state.tasksFilter;

    const chartTasks = this.state.tasksFilter;
    return (
      <React.Fragment>
        {/* <ZTitle subHeading="Tasks  " /> */}
        <div className="pageContent">
          <div
            className="card "
            style={{
              display: "block",
              position: "fixed",
              top: 0,
              zIndex: 9,
              paddingTop: 4,
            }}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                  <form id="myForm" onSubmit={this.handleSubmit}>
                    <div className="form-row">
                      {this.rRBL("Status", "status", this.status, this.stsConf)}
                      {this.rRBL(
                        "Type",
                        "typeList",
                        this.typeList,
                        this.typeConf
                      )}
                      {this.rRBL(
                        "Display",
                        "typeDisplay",
                        this.typeDisplay,
                        this.typeDisplayConf
                      )}

                      <div className="col-md-2">
                        {this.rBtnS("Go", "Submit", "btn btn-primary  btn-sm")}
                      </div>
                    </div>
                  </form>
                </div>
                <div className="col-md-7">
                  {this.state.owners.map((u) => {
                    if (this.state.filterTskOwner.indexOf(u["sortKey"]) < 0) {
                      return (
                        <span
                          className={
                            "badge   badge-info pull-right ml-1 " +
                            "badge-" +
                            u["sortKey"]
                          }
                          onClick={() => this.addFilterByUser(u)}
                        >
                          {u["sort"]}
                        </span>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <br />
                  {this.state.filterTskOwner.map((u) => {
                    return (
                      <span
                        className={
                          "badge   badge-info pull-right ml-1 " + "badge-" + u
                        }
                        onClick={() =>
                          this.addFilterByUser({
                            sortKey: u,
                            sort: _plms.getUserByCode(u),
                          })
                        }
                      >
                        {_plms.getUserByCode(u)}
                      </span>
                    );
                  })}

                  {this.taskStatusFilter.map((s, index) => {
                    if (
                      this.state.filterTskStatus.indexOf(s["tskStatus"]) < 0
                    ) {
                      return (
                        <span
                          className={
                            "badge   badge-success pull-left ml-1 " +
                            "badge-" +
                            s["tskStatus"]
                          }
                          onClick={() => this.addFilterByTaskStatus(s)}
                          key={s["taskId"]}
                        >
                          {s["tskStatus"].substring(2)}
                        </span>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <br />
                  {this.state.filterTskStatus.map((s, index) => {
                    return (
                      <span
                        className={
                          "badge   badge-success pull-left ml-1 " + "badge-" + s
                        }
                        onClick={() =>
                          this.addFilterByTaskStatus({ tskStatus: s })
                        }
                        key={s}
                      >
                        {s.substring(2)}
                      </span>
                    );
                  })}
                  <br />
                  {this.state.sprintList.map((sp) => {
                    if (this.state.filterSprints.indexOf(sp) < 0) {
                      return (
                        <span
                          className={
                            "badge  badge-success pull-left ml-1 badge-" +
                            _plms.getSprintClass(sp)
                          }
                          onClick={() => this.addFilterBySprint(sp)}
                        >
                          {sp}
                        </span>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <br></br>
                  {this.state.filterSprints.map((sp) => {
                    return (
                      <span
                        className={
                          "badge  badge-success pull-left ml-1 badge-" +
                          _plms.getSprintClass(sp)
                        }
                        onClick={() => this.addFilterBySprint(sp)}
                      >
                        {sp}
                      </span>
                    );
                  })}

                  {JSON.stringify(this.state.curFilter)}
                  {/* {JSON.stringify(this.state.curListType)} */}

                  {/* {JSON.stringify(this.state.filterSprints)} */}

                  <button
                    type="button"
                    className="btn btn-sm btn-primary pull-right mr-0"
                    data-toggle="modal"
                    data-target="#myDayPlanModel"
                  >
                    <i className={"fa fa-flag-checkered"} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-dark pull-right"
                    data-toggle="modal"
                    data-target="#myReportModel"
                  >
                    <i className={"fa fa-hospital-o"} />
                  </button>
                  <span
                    className="badge   badge-primary pull-right ml-1 badge-10wip"
                    onClick={() => this.setFilterFlag()}
                  >
                    {this.state.filterFlag}
                  </span>
                  <span
                    className="badge   badge-success pull-right ml-1 badge-10wip"
                    onClick={() => this.addFilterByProject(null)}
                  >
                    Reset
                  </span>

                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="card " style={{ marginTop: 120 }}>
            <div className="card-body">
              <div className="row">
                {JSON.stringify(this.filter)}
                {this.state.loading && (
                  <div className="container-fluid">
                    <div> Loading Tasks...</div>
                  </div>
                )}
                {!this.state.loading &&
                  this.state.data.typeDisplay === "table" && (
                    <div className="container-fluid">
                      <Table
                        clsName="table table-striped table-bordered table-hover"
                        tableMeta={this.tableMeta}
                        data={tasks}
                        dropMenu={[]}
                      />
                      <Pagination
                        pageNo={pageNo}
                        perPage={perPage}
                        itemsCount={itemsCount}
                        onPageChange={this.handlePageChange}
                      />
                    </div>
                  )}
                {!this.state.loading &&
                  this.state.data.typeDisplay !== "table" && (
                    <div className="container-fluid">
                      <>
                        {chartTasks.length > 0 ? (
                          <Gantt
                            tasks={chartTasks}
                            viewMode={ViewMode.Day}
                            ganttHeight={600}
                          />
                        ) : (
                          <div
                            style={{
                              height: "50%",
                              textAlign: "center",
                            }}
                          >
                            Nothing to show!
                          </div>
                        )}
                      </>
                    </div>
                  )}
                {!this.state.loading && tasks.length === 0 && (
                  <div className="alert alert-info col-12" role="alert">
                    <div className="text-center"> No Records Found! </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <ModalReport
          userstories={this.state.userstories}
          curUserstory={this.state.curUserstory}
          curTask={this.state.curTask}
          key={"mr_" + this.state.curTask["taskId"]}
        />
        <ModalDayPlan
          userstories={this.state.userstories}
          curUserstory={this.state.curUserstory}
          curTask={this.state.curTask}
          key={"mdp_" + this.state.curTask["taskId"]}
        />
      </React.Fragment>
    );
  }
}
