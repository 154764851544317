import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

// import options from "./data";

function MultiSelectAllHubs({
    name,
    label,
    error,
    options,
    value,
    inline = "",
    ctrls,
    handleChange,
    classAppend = "",
    ...rest
}) {
    // const MultiSelectAllHubs = () => {

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [allOps, setAllOps] = useState([]);

    // useEffect(() => {
    //     setAllOps([{ label: "All", value: "*" }, ...options]);
    // }, []);

    useEffect(() => {

        setSelectedOptions([{ label: "All", value: "*" }, { label: "HO", value: "ho" }, { label: name, value: name }, ...options]);
    });

    useEffect(() => {
        handleChange(selectedOptions, name);
    }, [selectedOptions]);


    function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
        if (value && value.some((o) => o.value === "*")) {
            return `${placeholderButtonLabel}: All`;
        } else {
            return `${placeholderButtonLabel}: ${value.length} selected`;
        }
    }

    function onChange(value, event) {
        if (event.action === "select-option" && event.option.value === "*") {
            this.setState(this.options);
        }
        else if (event.action === "deselect-option" && event.option.value === "*") {
            this.setState([]);
        }
        else if (event.action === "deselect-option") {
            this.setState(value.filter((o) => o.value !== "*"));
        }
        else if (value.length === this.options.length - 1) {
            this.setState(this.options);
        }
        else {
            this.setState(value);
        }
    }
    if (name === "hubId") {
        return (
            <ReactMultiSelectCheckboxes
                options={[{ label: "All", value: "*" }, { label: "HO", value: "ho" }, ...options]}
                placeholderButtonLabel="Hubs"
                getDropdownButtonLabel={getDropdownButtonLabel}
                value={selectedOptions}
                onChange={onChange}
                setState={setSelectedOptions}

            />
        );
    }
    else if (name === "retailerId") {
        return (
            <ReactMultiSelectCheckboxes
                options={[{ label: "All", value: "*" }, ...options]}
                placeholderButtonLabel="Clients"
                getDropdownButtonLabel={getDropdownButtonLabel}
                value={selectedOptions}
                onChange={onChange}
                setState={setSelectedOptions}

            />
        );
    }


};
export default MultiSelectAllHubs;
