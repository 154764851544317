// import React from "react";
// import FormLib from "./../../../_common/FormLib";
// import Table from "./../../../_common/Table";
// import { toast } from "react-toastify";
// import Pagination from "./../../../_common/Pagination";

// import {
//   getOutgoing,
//   updateShipmentNo,
//   getLinehaulTicktes,
//   blockStatus,
//   updateBarcodeStatus,
// } from "../../../../services/linehaul/linehaulServices";
// import dateFormat from "dateformat";
// // import { blockStatus } from "./../../../../services/linehaul/linehaulServices";
// import Joi from "joi-browser";
// import UpdateShipmentNoModal from "./UpdateShipmentNoModal";
// import { IstToUTC } from "../../../../utils/DateUtils";
// import $ from "jquery";
// import "jquery-ui-dist/jquery-ui";
// import DocumentDetails from "./actions/DocumentDetails";
// import ExpenseRise from "./actions/ExpenseRise";
// import { epoch2ddmmyyyyhhmmss } from "../../../../utils/DateUtils";
// import { ClickAwayListener } from "@mui/material";
// import { getBoxDetailedInformation } from "../../../../services/stockManagement/inventory";
// import BarCodePrintSVGLinehaul from "../BarcodePrintSVGLinehaul";
// import { IoBarcodeOutline } from "react-icons/io5";
// // import TicketInfo from "./actions/TicketInfo";

// export default class OutgoingManifest extends FormLib {
//   state = {
//     outgoing: [],
//     errors: {},
//     data: { search: "" },
//     pageLinks: {
//       pageNo: 1,
//       perPage: 0,
//       itemsCount: 0,
//       lastPage: 0,
//     },
//     currentShipment: {},
//     showUpdateBtn: true,
//     documentDetails: false,
//     // ticketInfo:false,
//     openStatus: "true",
//     currentRequest: {},
//     tiicketDetails: {},
//     showUpdateBarcodeBtn: false,

//     // incomingBoxDetails:false,
//   };
//   schema = {
//     search: Joi.optional().label("search"),
//   };
//   componentDidMount() {
//     this.getLinehaulList(this.state.pageLinks.pageNo);
//     if (this.props?.refreshListCallback) {
//       this.props.refreshListCallback(this.getLinehaulList);
//     }

//     const userJSON = localStorage.getItem("userInfo");
//     let userInfo = {};
//     if (userJSON) {
//       userInfo = JSON.parse(userJSON);
//     }

//     if (
//       userInfo?.username === "admin" ||
//       userInfo?.designation === "Key Accounts Manager"
//     ) {
//       this.setState({ showUpdateBtn: true });
//     }
//   }

//   getLinehaulList = async (pageNo = this.state.pageLinks.pageNo) => {
//     try {
//       this.setState({ loading: true });
//       let selectedHub = localStorage.getItem("curHub");
//       let params = {
//         srcHubId: selectedHub,
//         searchString: this.state.data.search,
//       };

//       const res = await getOutgoing(params, pageNo);
//       if (res.data.success === true) {
//         if (res.data.data.outgoingShipment !== 0) {
//           this.setState({ outgoing: res.data.data.outgoingShipment });
//           res.data.data.pageLinks &&
//             this.setState({ pageLinks: res.data.data.pageLinks });
//         } else {
//           toast.info(() => <div>{res.data.msg}</div>);
//           this.state.outgoing = [];
//         }
//       } else {
//         toast.error(res.data.msg);
//       }
//       this.setState({ loading: false });
//     } catch {
//       console.log("Error in OutgoingManifest>>>>>> getLinehaulList");
//     }
//   };
//   handleSearch = () => {
//     let pageLinks = this.state.pageLinks;
//     pageLinks["pageNo"] = "1";

//     this.setState({ pageLinks });

//     this.getLinehaulList(this.state.pageLinks.pageNo);
//   };

//   handlePageChange = (pageNo) => {
//     // this.props.history.push("/ticketProcess/"+ pageNo);
//     this.getLinehaulList(pageNo);
//   };

//   replaceString = (str) => {
//     return str.replace(/_/g, " ");
//   };

//   tableMeta = {
//     key: "shipmentNo",
//     columns: [
//       // { path: "linehaulSeq", label: "Linehaul Ref No" },
//       {
//         key: "linehaulRefNo",
//         action: (r) => (
//           <>
//             <span
//               // className="badge badge-success link-over"

//               title="Click to Print Barcode"
//             >
//               {r.linehaulSeq}
//               <button
//                 className="border-0 fs-5 ms-3 bg-transparent"
//                 title="Print Barcode"
//                 onClick={() => this.onloadBarcodeDetails(r)}
//               >
//                 {r.boxBarcode && <IoBarcodeOutline />}
//               </button>
//               {/* <i
//                 className="fa-sharp fa-solid fa-barcode"
//                 style={{ fontSize: "30px", marginLeft: "50px" }}
//                 onClick={() => this.onloadBarcodeDetails(r)}
//                 title="Print Barcode"
//               ></i> */}
//             </span>
//           </>
//         ),
//         icon: () => <>Linehaul Ref No</>,
//         label: "#",
//       },
//       // {
//       //   key:"Box",
//       //   action: (r)=> (
//       //         <>
//       //         <span
//       //         className="badge badge-primary link-over"

//       //         onClick={() => this.getBoxDetails(r)}
//       //         title="Click To Get Boxdetails"
//       //       >
//       //         <span>boxDetails</span>
//       //       </span>
//       //         </>
//       //   ),
//       //   icon: () => <><i class="fa fa-th-large" aria-hidden="true"></i><span>BoxDetails</span></>,
//       //   // icon: () => <i className="fa fa-cogs" aria-hidden="true"></i>
//       // },
//       // {
//       //   key: "",
//       //   action: () => (
//       //     <>
//       //       <div className="d-flex justify-content-center">
//       //         <>
//       //           <i
//       //             className="fa fa-info-circle cursorPointor = "
//       //             style={{ fontSize:"25px", color:"black" }}
//       //             onClick={() => this.getTicketInfo()}
//       //             title="Get Item Info"
//       //           ></i>
//       //         </>
//       //       </div>
//       //     </>
//       //   ),
//       //   icon: () => (
//       //     <div className="d-flex justify-content-center">Info</div>
//       //   ),
//       // },
//       // { path: "ticketNo", label: "Ticket Number" },
//       {
//         // ***************************************************dfdfdfd**********************************************************

//         // path: "REF_ID",  label: "Ref No."

//         key: "multiTicketIds",
//         action: (r) => (
//           <>
//             <span
//               className="badge badge-success link-over"
//               onClick={() => this.onloadDocumentDetails(r)}
//               title="Click to View/Upload files"
//             >
//               {/* {r["ticketNo"] && r["ticketNo"].substring(0, 30)}{" "} */}
//               {r["multiTicketIds"]
//                 ? r["multiTicketIds"].substring(0, 30)
//                 : r["multiTicketIds"]}
//             </span>
//             <div class="icon-badge-container ">
//               <div
//                 class="icon-badge cursorPointor"
//                 style={{
//                   marginLeft: "96%",
//                   marginTop: "-14%",
//                   position: "relative",
//                   backgroundColor: "#ff6c00",
//                 }}
//                 title="Number of Tickets"
//               >
//                 {r["multiTicketIds"] && r["multiTicketIds"].split(",").length}
//               </div>
//             </div>
//           </>
//         ),
//         icon: () => <>Ticket Number</>,
//         label: "#",
//       },
//       // { path: "shipmentNo", label: "Shipment No." },
//       {
//         key: "ticketCreationDate",
//         action: (r) => <>{epoch2ddmmyyyyhhmmss(r.ticketCreationDate)}</>,
//         cusClass: " ",
//         icon: () => <>Ticket Creation Date</>,
//       },
//       {
//         key: "shipmentTrackingURL",
//         action: (r) => {
//           if (r.shipmentNo && r.logisticsProvider === "Delhivery") {
//             return (
//               <div className="clearfix shipmentNo">
//                 <span className="pull-left">
//                   <a
//                     href={`https://www.delhivery.com/track/lr/${r.shipmentNo}`}
//                     target="_blank"
//                     rel="noreferrer"
//                   >
//                     {r.shipmentNo}
//                   </a>
//                 </span>

//                 <i
//                   className="fa fa-pencil fa-2x pull-right fs-5 edit-shipmentNo"
//                   data-bs-toggle="modal"
//                   data-bs-target="#staticBackdrop"
//                   aria-hidden="true"
//                   title="Edit shipmentNo"
//                   onClick={() => this.openShipmentNoModal(r)}
//                 />
//               </div>
//             );
//           }
//           return (
//             <div className="clearfix shipmentNo">
//               {r.shipmentNo}
//               {r.shipmentNo ? (
//                 <i
//                   className="fa fa-pencil fa-2x pull-right fs-5 edit-shipmentNo"
//                   data-bs-toggle="modal"
//                   data-bs-target="#staticBackdrop"
//                   aria-hidden="true"
//                   title="Edit shipmentNo"
//                   onClick={() => this.openShipmentNoModal(r)}
//                 />
//               ) : null}
//             </div>
//           );
//         },
//         icon: () => <>LH docket</>,
//       },

//       {
//         key: "createdAt",
//         action: (r) => <>{epoch2ddmmyyyyhhmmss(r.createdAt)}</>,
//         cusClass: " ",
//         icon: () => <>Manifest Creation Date</>,
//       },
//       { path: "transMode", label: "Delivery Type" },

//       // { path: "srcLocation", label: "Source Location" },

//       { path: "dstLocation", label: "Drop Location" },
//       // { path: "logisticsProvider", label: "Logistics Provider" },
//       {
//         key: "logisticsProvider",
//         action: (r) => <>{this.replaceString(r.logisticsProvider)}</>,
//         cusClass: " ",
//         icon: () => <>Logistics Provider</>,
//       },
//       // {
//       //   key: "shipmentNo",
//       //   actionKey: "sh",
//       //   action: (r) => (
//       //     <>
//       //       {r.boxBarcode &&
//       //       r.destination === "customer" &&
//       //       r.boxStatus !== "linehaulDelivered" ? (
//       //         <>
//       //           <input
//       //             type="text"
//       //             placeholder="Scan Box Barcode"
//       //             name={`sh_${r.linehaulSeq}`}
//       //             id={`sh_${r.linehaulSeq}`}
//       //             className="shipmentNoInputManifest"
//       //             onFocus={() => {
//       //               // console.log({focusOn: r.linehaulSeq})
//       //               this.setState({ currentShipment: r });
//       //             }}
//       //             onKeyDown={(e) => {
//       //               if (e.key === "Enter") {
//       //                 if (!e.target.value) return;
//       //                 this.handleSaveBarcodeNo(e.target.value);
//       //               }
//       //             }}
//       //           />
//       //         </>
//       //       ) : (
//       //         <div>
//       //           {(!r.boxBarcode || r.boxStatus === "linehaulDelivered") && (
//       //             <>
//       //               {r.shipmentNo ? (
//       //                 r.destination === "customer" && (
//       //                   <button
//       //                     className="btn  btn-success  btn-sm link-over"
//       //                     onClick={() =>
//       //                       this.onBlockSelection(r, "20DIRECT_DELIVERY")
//       //                     }
//       //                   >
//       //                     Delivered
//       //                   </button>
//       //                 )
//       //               ) : (
//       //                 <div>
//       //                   {this.state.showUpdateBtn ? (
//       //                     <></>
//       //                   ) : (
//       //                     // <button
//       //                     //   className="btn  btn-outline-secondary  btn-sm link-over"
//       //                     //   type="button"
//       //                     //   data-bs-toggle="modal"
//       //                     //   data-bs-target="#staticBackdrop"
//       //                     //   onClick={() => this.openShipmentNoModal(r)}
//       //                     // >
//       //                     //   Update AWB/LR no
//       //                     // </button>

//       //                     <>Shipment No NOT yet updated</>
//       //                   )}
//       //                 </div>
//       //               )}
//       //             </>
//       //           )}
//       //         </div>
//       //       )}
//       //     </>
//       //   ),
//       //   cusClass: " ",
//       //   icon: () => <i className="fa fa-cogs" aria-hidden="true"></i>,
//       // },
//       {
//         key: "shipmentNo",
//         actionKey: "sh",
//         action: (r) => (
//           <>
//             {r.shipmentNo ? (
//               r.destination === "customer" && (
//                 <button
//                   className="btn  btn-success  btn-sm link-over"
//                   onClick={() => this.onBlockSelection(r, "20DIRECT_DELIVERY")}
//                 >
//                   Delivered
//                 </button>
//               )
//             ) : (
//               <div>
//                 {this.state.showUpdateBtn ? (
//                   <></>
//                 ) : (
//                   // <button
//                   //   className="btn  btn-outline-secondary  btn-sm link-over"
//                   //   type="button"
//                   //   data-bs-toggle="modal"
//                   //   data-bs-target="#staticBackdrop"
//                   //   onClick={() => this.openShipmentNoModal(r)}
//                   // >
//                   //   Update AWB/LR no
//                   // </button>

//                   <>Shipment No NOT yet updated</>
//                 )}
//               </div>
//             )}
//           </>
//         ),
//         cusClass: " ",
//         icon: () => <i className="fa fa-cogs" aria-hidden="true"></i>,
//       },
//       { path: "destination", label: "Destination Type" },
//       {
//         key: "Expense",
//         action: (r) => (
//           <>
//             <div className="d-flex justify-content-center">
//               <>
//                 <i
//                   className="fa fa-telegram cursorPointor ="
//                   style={{ fontSize: "30px", color: "green" }}
//                   onClick={() => this.handleExpenseRise(r)}
//                   title="Raise Expense"
//                 ></i>
//               </>
//             </div>
//           </>
//         ),
//         icon: () => (
//           <div className="d-flex justify-content-center">Raise Expense</div>
//         ),
//       },
//     ],
//   };

//   //  async getTicketInfo () {
//   //   const res = await getBoxDetailedInformation();
//   //   if(res.data.success === true){
//   //     this.setState({tiicketDetails:res.data.boxDetails})
//   //     // console.log({ddddd:res.data.boxDetails});

//   //   }
//   //   console.log({res});

//   //   }

//   // onloadDocumentDetails = (r) => {
//   //   this.setState({ documentDetails: "true" });
//   //   this.setState({ openStatus: "false" });
//   //   this.setState({ currentRequest: r });
//   // };

//   // closeModal = () => {
//   //   this.setState({ openStatus: "false" });
//   //   this.setState({ documentDetails: "false" });
//   // };

//   onloadDocumentDetails = (r) => {
//     this.setState({ documentDetails: "true" });
//     this.setState({ openStatus: "false" });
//     this.setState({ barcodeDetails: "false" });
//     this.setState({ currentRequest: r });
//   };

//   onloadBarcodeDetails = (r) => {
//     // alert(JSON.stringify(r));
//     this.setState({ barcodeDetails: "true" });
//     this.setState({ documentDetails: "false" });
//     this.setState({ openStatus: "false" });
//     this.setState({ barcodeRequest: r });
//   };

//   closeModal = () => {
//     this.setState({ openStatus: "false" });
//     this.setState({ documentDetails: "false" });
//     this.setState({ barcodeDetails: "false" });
//   };

//   // handleView = () => {
//   //   $("#myDocumentDetails").draggable();
//   // };

//   handleExpenseRise = async (r) => {
//     try {
//       let resp = await getLinehaulTicktes(r.linehaulSeq);
//       if (resp.data.success === true) {
//         if (resp.data.data) {
//           toast.success("Ticket Action Fetched Successfully");
//           this.setState({ ticketDetails: resp.data.data });
//         } else {
//           !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
//         }
//       } else {
//         toast.error(() => <div>{resp.data.msg}</div>);
//       }
//       this.setState({ documentDetails: "false" });
//       this.setState({ openStatus: "true" });
//       this.setState({ ticketStatus: "false" });

//       this.setState({ currentRequest: r });
//     } catch {
//       console.log("OutgoingManifest ----------->>> handleExpenseRise");
//     }
//   };

//   async onBlockSelection(r, status) {
//     try {
//       this.setState({ loading: true });
//       let params = {
//         ticketLinehaulBlockId: r.ticketLinehaulBlockId,
//         ticketBlockId: r.ticketBlockId,
//         status: status,
//         direction: r.direction,
//         dropTime: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
//         dropDate: IstToUTC(new Date()).slice(0, 10),
//         ticketLineHaulId: r.ticketLineHaulId,
//         srcHubId: r.srcHubId,
//         dstHubId: r.dstHubId,
//         boxId: r.boxId,
//         multiTicketIds: r.multiTicketIds,
//         processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
//       };
//       let resp = await blockStatus(params);
//       if (resp.data.success === true) {
//         toast.success(() => <div>{resp.data.msg}</div>);
//         this.getLinehaulList(this.state.pageLinks.pageNo);
//       } else {
//         toast.error(() => <div>{resp.data.msg}</div>);
//       }
//       this.setState({ loading: false });
//     } catch {
//       console.log("Error in OutgoingManifest>>>>>> onBlockSelection");
//     }
//   }

//   async openShipmentNoModal(r) {
//     //*call to open shipment modal
//     try {
//       // console.log("rowDetails", r)
//       this.setState({ currentShipment: r });
//       const shipmentInput = document.getElementById("inputShipmentNo");
//       const modalShipmentNoUpdate = document.getElementById("staticBackdrop");

//       modalShipmentNoUpdate.addEventListener("shown.bs.modal", () => {
//         if (shipmentInput) {
//           shipmentInput.focus();
//         }
//       });
//     } catch (error) {
//       console.error(error);
//     }
//   }

//   handleSaveShipmentNo = async (shipmentNumber) => {
//     // console.log({shipmentDetails: this.state.currentShipment, shipmentNumber})
//     // this.getLinehaulList(this.state.pageLinks.pageNo);

//     const ticketLineHaulId = this.state.currentShipment.ticketLineHaulId;
//     try {
//       const resp = await updateShipmentNo(ticketLineHaulId, shipmentNumber);

//       if (resp.data.success) {
//         const updatedOutgoingShipment = this.state.outgoing.map((s) => ({
//           ...s,
//           shipmentNo:
//             s.ticketLineHaulId === this.state.currentShipment.ticketLineHaulId
//               ? shipmentNumber
//               : s.shipmentNo,
//         }));
//         this.setState({ outgoing: updatedOutgoingShipment });

//         toast.success(() => <div>{resp.data.msg}</div>);

//         return true;
//       }
//       toast.error(() => <div>{resp.data.msg}</div>);
//       return false;
//     } catch (error) {
//       console.log("ERROR in handleSaveShipmentNo");
//       console.error(error);
//     }
//   };

//   // handleSaveBarcodeNo = async (barcodeNumber) => {
//   //   // console.log(this.state.currentShipment.boxBarcode);
//   //   const boxBarcode = this.state.currentShipment.boxBarcode;
//   //   try {
//   //     if (barcodeNumber === boxBarcode) {
//   //       const updatedOutgoingShipment = this.state.outgoing.map((s) => ({
//   //         ...s,
//   //         barcodeNumber:
//   //           s.ticketLineHaulId === this.state.currentShipment.ticketLineHaulId
//   //             ? barcodeNumber
//   //             : s.barcodeNumber,
//   //       }));
//   //       this.setState({ outgoing: updatedOutgoingShipment });
//   //       this.setState({ showUpdateBarcodeBtn: true });

//   //       return true;
//   //     }
//   //     return false;
//   //   } catch (error) {
//   //     console.log("ERROR in handleSaveShipmentNo");
//   //     console.error(error);
//   //   }
//   // };

//   handleSaveBarcodeNo = async (barcodeNumber) => {
//     try {
//       const boxBarcode = this.state.currentShipment.boxBarcode;
//       const boxId = this.state.currentShipment.boxId;
//       const params = {
//         boxId: boxId,
//         boxBarcode: boxBarcode,
//         boxStatus: "linehaulDelivered",
//       };
//       const resp = await updateBarcodeStatus(params);

//       if (barcodeNumber === boxBarcode && resp.data.success) {
//         const updatedOutgoingShipment = this.state.outgoing.map((s) => ({
//           ...s,
//           boxStatus:
//             s.ticketLineHaulId === this.state.currentShipment.ticketLineHaulId
//               ? "linehaulDelivered"
//               : s.boxStatus,
//         }));
//         this.setState({ outgoing: updatedOutgoingShipment });
//         this.setState({ showUpdateBarcodeBtn: true });
//         toast.success(() => <div>{resp.data.msg}</div>);
//         return true;
//       }
//       toast.error(() => <div>{resp.data.msg}</div>);
//       return false;
//     } catch (error) {
//       console.log("ERROR in handleSaveBarcodeNo");
//       console.error(error);
//     }
//   };

//   render() {
//     const { pageNo, perPage, itemsCount } = this.state.pageLinks;
//     const outgoingJSON = this.state.outgoing;
//     // console.log({outgoingJSON});
//     const currentRequest = this.state.currentRequest;
//     const ticketRequest = this.state.ticketDetails;
//     // console.log({data:outgoingJSON});
//     // console.log({ticket:this.state.tiicketDetails});

//     // console.log({ outgoingJSON });
//     return (
//       <>
//         <div>
//           <div className="form-subHeader">Outgoing Manifest</div>
//           <div className="form-row col-12">
//             <div className="col-4">
//               {this.rTB("Search Ticket No.", "search")}
//             </div>
//             <div className="col-4">
//               <button
//                 className="btn  btn-primary  btn-sm"
//                 onClick={() => this.handleSearch(this.state.data.search)}
//               >
//                 Search
//               </button>
//             </div>
//           </div>
//           <div className="form-row col-12">
//             {this.props.loading && (
//               <div className="container-fluid pull-right">
//                 <div> Loading users...</div>
//               </div>
//             )}
//             {!this.props.loading && (
//               <>
//                 <Table
//                   clsName="table table-striped table-bordered table-hover"
//                   tableMeta={this.tableMeta}
//                   data={outgoingJSON}
//                   dropMenu={[]}
//                 />
//                 {this.state.outgoing.length !== 0 && (
//                   <Pagination
//                     pageNo={pageNo}
//                     perPage={perPage}
//                     itemsCount={itemsCount}
//                     onPageChange={this.handlePageChange}
//                   />
//                 )}
//               </>
//             )}
//           </div>
//         </div>

//         {this.state.outgoing.length === 0 && (
//           <div className="jumbotron jumbotron-fluid">
//             <p className="lead text-center">No Tickets Found</p>
//           </div>
//         )}

//         <div
//           className="draggable col-10 ml-5 mr-5"
//           id="myDocumentDetails"
//           onClick={this.handleView}
//         >
//           {this.state.openStatus === "false" &&
//             this.state.documentDetails === "true" && (
//               <DocumentDetails
//                 key={currentRequest.ticketNo}
//                 data={currentRequest}
//                 // requestStatusChange={this.handleRequestStatusChange}
//                 // user={this.state.user}
//                 closeModal={this.closeModal}
//               />
//             )}
//         </div>
//         <div
//           className="draggable  col-sm-12 col-md-8 col-lg-8 "
//           id="myModalApprove"
//           onClick={this.handleView}
//         >
//           {
//             // this.state.TicketActionsDatas &&
//             this.state.openStatus === "true" &&
//               this.state.documentDetails === "false" &&
//               this.state.ticketStatus === "false" && (
//                 <ExpenseRise
//                   key={currentRequest.ticketNo}
//                   data={currentRequest}
//                   type="report"
//                   ticketDetails={ticketRequest}
//                   // ticketActionId={currentRequest.ticketActionId}
//                   // requestChange={this.handleRequestChange}
//                   // user={this.state.user}
//                   closeModal={this.closeModal}
//                 />
//               )
//           }
//         </div>

//         <div
//           className="draggable col-10 ml-5 mr-5"
//           id="myDocumentDetails"
//           onClick={this.handleView}
//         >
//           {this.state.openStatus === "false" &&
//             this.state.documentDetails === "false" &&
//             this.state.barcodeDetails === "true" && (
//               <BarCodePrintSVGLinehaul
//                 key={outgoingJSON.ticketNo}
//                 data={this.state.barcodeRequest}
//                 barcodeDetails={this.state.barcodeRequest}
//                 closeModal={this.closeModal}
//               />
//             )}
//         </div>
//         <iframe
//           id="ifmcontentstoprint"
//           title="toPrint"
//           style={{
//             height: "0px",
//             width: "0px",
//             position: "absolute",
//           }}
//         ></iframe>
//         <UpdateShipmentNoModal
//           shipmentDetails={this.state.currentShipment}
//           handleSave={this.handleSaveShipmentNo}
//           shipmentInputId="inputShipmentNo"
//           modalId="staticBackdrop"
//         />
//       </>
//     );
//   }
// }



import React from "react";
import FormLib from "./../../../_common/FormLib";
import Table from "./../../../_common/Table";
import { toast } from "react-toastify";
import Pagination from "./../../../_common/Pagination";

import {
  getOutgoing,
  updateShipmentNo,
  getLinehaulTicktes,
  blockStatus,
  updateBarcodeStatus,
  blockStatusOutGoing
} from "../../../../services/linehaul/linehaulServices";
import dateFormat from "dateformat";
// import { blockStatus } from "./../../../../services/linehaul/linehaulServices";
import Joi from "joi-browser";
import UpdateShipmentNoModal from "./UpdateShipmentNoModal";
import { IstToUTC } from "../../../../utils/DateUtils";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import DocumentDetails from "./actions/DocumentDetails";
import ExpenseRise from "./actions/ExpenseRise";
import { epoch2ddmmyyyyhhmmss } from "../../../../utils/DateUtils";
import { ClickAwayListener } from "@mui/material";
import { getBoxDetailedInformation } from "../../../../services/stockManagement/inventory";
import BarCodePrintSVGLinehaul from "../BarcodePrintSVGLinehaul";
import { IoBarcodeOutline } from "react-icons/io5";
// import TicketInfo from "./actions/TicketInfo";

export default class OutgoingManifest extends FormLib {
  state = {
    outgoing: [],
    errors: {},
    data: { search: "" },
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    currentShipment: {},
    showUpdateBtn: true,
    documentDetails: false,
    // ticketInfo:false,
    openStatus: "true",
    currentRequest: {},
    tiicketDetails: {},
    showUpdateBarcodeBtn: false,

    // incomingBoxDetails:false,
  };
  schema = {
    search: Joi.optional().label("search"),
  };
  componentDidMount() {
    this.getLinehaulList(this.state.pageLinks.pageNo);
    if (this.props?.refreshListCallback) {
      this.props.refreshListCallback(this.getLinehaulList);
    }

    const userJSON = localStorage.getItem("userInfo");
    let userInfo = {};
    if (userJSON) {
      userInfo = JSON.parse(userJSON);
    }

    if (
      userInfo?.username === "admin" ||
      userInfo?.designation === "Key Accounts Manager"
    ) {
      this.setState({ showUpdateBtn: true });
    }
  }

  getLinehaulList = async (pageNo = this.state.pageLinks.pageNo) => {
    try {
      this.setState({ loading: true });
      let selectedHub = localStorage.getItem("curHub");
      let params = {
        srcHubId: selectedHub,
        searchString: this.state.data.search,
      };

      const res = await getOutgoing(params, pageNo);
      if (res.data.success === true) {
        if (res.data.data.outgoingShipment !== 0) {
          this.setState({ outgoing: res.data.data.outgoingShipment });
          res.data.data.pageLinks &&
            this.setState({ pageLinks: res.data.data.pageLinks });
        } else {
          toast.info(() => <div>{res.data.msg}</div>);
          this.state.outgoing = [];
        }
      } else {
        toast.error(res.data.msg);
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in OutgoingManifest>>>>>> getLinehaulList");
    }
  };
  handleSearch = () => {
    let pageLinks = this.state.pageLinks;
    pageLinks["pageNo"] = "1";

    this.setState({ pageLinks });

    this.getLinehaulList(this.state.pageLinks.pageNo);
  };

  handlePageChange = (pageNo) => {
    // this.props.history.push("/ticketProcess/"+ pageNo);
    this.getLinehaulList(pageNo);
  };

  replaceString = (str) => {
    return str.replace(/_/g, " ");
  };

  tableMeta = {
    key: "shipmentNo",
    columns: [
      // { path: "linehaulSeq", label: "Linehaul Ref No" },
      {
        key: "linehaulRefNo",
        action: (r) => (
          <>
            <span
              // className="badge badge-success link-over"

              title="Click to Print Barcode"
            >
              {r.linehaulSeq}
              <button
                className="border-0 fs-5 ms-3 bg-transparent"
                title="Print Barcode"
                onClick={() => this.onloadBarcodeDetails(r)}
              >
                {r.boxBarcode && <IoBarcodeOutline />}
              </button>
              {/* <i
                className="fa-sharp fa-solid fa-barcode"
                style={{ fontSize: "30px", marginLeft: "50px" }}
                onClick={() => this.onloadBarcodeDetails(r)}
                title="Print Barcode"
              ></i> */}
            </span>
          </>
        ),
        icon: () => <>Linehaul Ref No</>,
        label: "#",
      },
      // {
      //   key:"Box",
      //   action: (r)=> (
      //         <>
      //         <span
      //         className="badge badge-primary link-over"

      //         onClick={() => this.getBoxDetails(r)}
      //         title="Click To Get Boxdetails"
      //       >
      //         <span>boxDetails</span>
      //       </span>
      //         </>
      //   ),
      //   icon: () => <><i class="fa fa-th-large" aria-hidden="true"></i><span>BoxDetails</span></>,
      //   // icon: () => <i className="fa fa-cogs" aria-hidden="true"></i>
      // },
      // {
      //   key: "",
      //   action: () => (
      //     <>
      //       <div className="d-flex justify-content-center">
      //         <>
      //           <i
      //             className="fa fa-info-circle cursorPointor = "
      //             style={{ fontSize:"25px", color:"black" }}
      //             onClick={() => this.getTicketInfo()}
      //             title="Get Item Info"
      //           ></i>
      //         </>
      //       </div>
      //     </>
      //   ),
      //   icon: () => (
      //     <div className="d-flex justify-content-center">Info</div>
      //   ),
      // },
      // { path: "ticketNo", label: "Ticket Number" },
      {
        // ***************************************************dfdfdfd**********************************************************

        // path: "REF_ID",  label: "Ref No."

        key: "multiTicketIds",
        action: (r) => (
          <>
            <span
              className="badge badge-success link-over"
              onClick={() => this.onloadDocumentDetails(r)}
              title="Click to View/Upload files"
            >
              {/* {r["ticketNo"] && r["ticketNo"].substring(0, 30)}{" "} */}
              {r["multiTicketIds"]
                ? r["multiTicketIds"].substring(0, 30)
                : r["multiTicketIds"]}
            </span>
            <div class="icon-badge-container ">
              <div
                class="icon-badge cursorPointor"
                style={{
                  marginLeft: "96%",
                  marginTop: "-14%",
                  position: "relative",
                  backgroundColor: "#ff6c00",
                }}
                title="Number of Tickets"
              >
                {r["multiTicketIds"] && (r["multiTicketIds"].split(",").length)}
              </div>
            </div>
          </>
        ),
        icon: () => <>Ticket Number</>,
        label: "#",
      },
      // { path: "shipmentNo", label: "Shipment No." },
      {
        key: "ticketCreationDate",
        action: (r) => <>{epoch2ddmmyyyyhhmmss(r.ticketCreationDate)}</>,
        cusClass: " ",
        icon: () => <>Ticket Creation Date</>,
      },
      {
        key: "shipmentTrackingURL",
        action: (r) => {
          if (r.shipmentNo && r.logisticsProvider === "Delhivery") {
            return (
              <div className="clearfix shipmentNo">
                <span className="pull-left">
                  <a
                    href={`https://www.delhivery.com/track/lr/${r.shipmentNo}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {r.shipmentNo}
                  </a>
                </span>

                <i
                  className="fa fa-pencil fa-2x pull-right fs-5 edit-shipmentNo"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  aria-hidden="true"
                  title="Edit shipmentNo"
                  onClick={() => this.openShipmentNoModal(r)}
                />
              </div>
            );
          }
          return (
            <div className="clearfix shipmentNo">
              {r.shipmentNo}
              {r.shipmentNo ? (
                <i
                  className="fa fa-pencil fa-2x pull-right fs-5 edit-shipmentNo"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  aria-hidden="true"
                  title="Edit shipmentNo"
                  onClick={() => this.openShipmentNoModal(r)}
                />
              ) : null}
            </div>
          );
        },
        icon: () => <>LH docket</>,
      },
      
      {
        key: "createdAt",
        action: (r) => <>{epoch2ddmmyyyyhhmmss(r.createdAt)}</>,
        cusClass: " ",
        icon: () => <>Manifest Creation Date</>,
      },
      { path: "transMode", label: "Delivery Type" },

      // { path: "srcLocation", label: "Source Location" },

      { path: "dstLocation", label: "Drop Location" },
      // { path: "logisticsProvider", label: "Logistics Provider" },
      {
        key: "logisticsProvider",
        action: (r) => <>{this.replaceString(r.logisticsProvider)}</>,
        cusClass: " ",
        icon: () => <>Logistics Provider</>,
      },
      {
        key: "shipmentNo",
        actionKey: "sh",
        action: (r) => (
          <>
            {/* {r.boxBarcode &&
            r.destination === "customer" &&
            r.boxStatus !== "linehaulDelivered" ? (
              <>
                <input
                  type="text"
                  placeholder="Scan Box Barcode"
                  name={`sh_${r.linehaulSeq}`}
                  id={`sh_${r.linehaulSeq}`}
                  className="shipmentNoInputManifest"
                  onFocus={() => {
                    // console.log({focusOn: r.linehaulSeq})
                    this.setState({ currentShipment: r });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (!e.target.value) return;
                      this.handleSaveBarcodeNo(e.target.value);
                    }
                  }}
                />
              </>
            ) : ( */}
              <div>
                {/* {(!r.boxBarcode || r.boxStatus === "linehaulDelivered") && ( */}
                  <>
                    {r.shipmentNo ? (
                      r.destination === "customer" && (
                        <button
                          className="btn  btn-success  btn-sm link-over"
                          onClick={() =>
                            this.onBlockSelection(r, "20DIRECT_DELIVERY")
                          }
                        >
                          Delivered
                        </button>
                      )
                    ) : (
                      <div>
                        {this.state.showUpdateBtn ? (
                          <></>
                        ) : (
                          // <button
                          //   className="btn  btn-outline-secondary  btn-sm link-over"
                          //   type="button"
                          //   data-bs-toggle="modal"
                          //   data-bs-target="#staticBackdrop"
                          //   onClick={() => this.openShipmentNoModal(r)}
                          // >
                          //   Update AWB/LR no
                          // </button>

                          <>Shipment No NOT yet updated</>
                        )}
                      </div>
                    )}
                  </>
                {/* )} */}
              </div>
            {/* )} */}
          </>
        ),
        cusClass: " ",
        icon: () => <i className="fa fa-cogs" aria-hidden="true"></i>,
      },
      { path: "destination", label: "Destination Type" },
      {
        key: "Expense",
        action: (r) => (
          <>
            <div className="d-flex justify-content-center">
              <>
                <i
                  className="fa fa-telegram cursorPointor ="
                  style={{ fontSize: "30px", color: "green" }}
                  onClick={() => this.handleExpenseRise(r)}
                  title="Raise Expense"
                ></i>
              </>
            </div>
          </>
        ),
        icon: () => (
          <div className="d-flex justify-content-center">Raise Expense</div>
        ),
      },
    ],
  };

  //  async getTicketInfo () {
  //   const res = await getBoxDetailedInformation();
  //   if(res.data.success === true){
  //     this.setState({tiicketDetails:res.data.boxDetails})
  //     // console.log({ddddd:res.data.boxDetails});

  //   }
  //   console.log({res});

  //   }

  // onloadDocumentDetails = (r) => {
  //   this.setState({ documentDetails: "true" });
  //   this.setState({ openStatus: "false" });
  //   this.setState({ currentRequest: r });
  // };

  // closeModal = () => {
  //   this.setState({ openStatus: "false" });
  //   this.setState({ documentDetails: "false" });
  // };

  onloadDocumentDetails = (r) => {
    this.setState({ documentDetails: "true" });
    this.setState({ openStatus: "false" });
    this.setState({ barcodeDetails: "false" });
    this.setState({ currentRequest: r });
  };

  onloadBarcodeDetails = (r) => {
    // alert(JSON.stringify(r));
    this.setState({ barcodeDetails: "true" });
    this.setState({ documentDetails: "false" });
    this.setState({ openStatus: "false" });
    this.setState({ barcodeRequest: r });
  };

  closeModal = () => {
    this.setState({ openStatus: "false" });
    this.setState({ documentDetails: "false" });
    this.setState({ barcodeDetails: "false" });
  };

  // handleView = () => {
  //   $("#myDocumentDetails").draggable();
  // };

  handleExpenseRise = async (r) => {
    try {
      let resp = await getLinehaulTicktes(r.linehaulSeq);
      if (resp.data.success === true) {
        if (resp.data.data) {
          toast.success("Ticket Action Fetched Successfully");
          this.setState({ ticketDetails: resp.data.data });
        } else {
          !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
      this.setState({ documentDetails: "false" });
      this.setState({ openStatus: "true" });
      this.setState({ ticketStatus: "false" });

      this.setState({ currentRequest: r });
    } catch {
      console.log("OutgoingManifest ----------->>> handleExpenseRise");
    }
  };

  async onBlockSelection(r, status) {
    try {
      this.setState({ loading: true });
      let params = {
        syncId:"",
        ticketBlockId: r.ticketBlockId,
        actionCode:"wLinehaulVerification",
        action:"Shipment Recevied Status update",
        productStatus:"lineHaulProdDirectDelivered",
        boxStatus:"lineHaulBoxDirectDelivered",
        ticketLineHaulId:r.ticketLineHaulId,
        
        actionStatus:status,
        status:status,
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        dropDate: IstToUTC(new Date()).slice(0, 10),
        dropTime: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
       
        isHistoryRecord:"",
        isHistoryShown:"",
        isStrictLevel:"",
        direction: r.direction,
        syncStatus:"SYNC",
        processId:r.processId
      };
      let resp = await blockStatusOutGoing(params);
      if (resp.data.success === true) {
        toast.success(() => <div>{resp.data.msg}</div>);
        this.getLinehaulList(this.state.pageLinks.pageNo);
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in OutgoingManifest>>>>>> onBlockSelection");
    }
  }

  async openShipmentNoModal(r) {
    //*call to open shipment modal
    try {
      // console.log("rowDetails", r)
      this.setState({ currentShipment: r });
      const shipmentInput = document.getElementById("inputShipmentNo");
      const modalShipmentNoUpdate = document.getElementById("staticBackdrop");

      modalShipmentNoUpdate.addEventListener("shown.bs.modal", () => {
        if (shipmentInput) {
          shipmentInput.focus();
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleSaveShipmentNo = async (shipmentNumber) => {
    // console.log({shipmentDetails: this.state.currentShipment, shipmentNumber})
    // this.getLinehaulList(this.state.pageLinks.pageNo);

    const ticketLineHaulId = this.state.currentShipment.ticketLineHaulId;
    try {
      const resp = await updateShipmentNo(ticketLineHaulId, shipmentNumber);

      if (resp.data.success) {
        const updatedOutgoingShipment = this.state.outgoing.map((s) => ({
          ...s,
          shipmentNo:
            s.ticketLineHaulId === this.state.currentShipment.ticketLineHaulId
              ? shipmentNumber
              : s.shipmentNo,
        }));
        this.setState({ outgoing: updatedOutgoingShipment });

        toast.success(() => <div>{resp.data.msg}</div>);

        return true;
      }
      toast.error(() => <div>{resp.data.msg}</div>);
      return false;
    } catch (error) {
      console.log("ERROR in handleSaveShipmentNo");
      console.error(error);
    }
  };

  // handleSaveBarcodeNo = async (barcodeNumber) => {
  //   // console.log(this.state.currentShipment.boxBarcode);
  //   const boxBarcode = this.state.currentShipment.boxBarcode;
  //   try {
  //     if (barcodeNumber === boxBarcode) {
  //       const updatedOutgoingShipment = this.state.outgoing.map((s) => ({
  //         ...s,
  //         barcodeNumber:
  //           s.ticketLineHaulId === this.state.currentShipment.ticketLineHaulId
  //             ? barcodeNumber
  //             : s.barcodeNumber,
  //       }));
  //       this.setState({ outgoing: updatedOutgoingShipment });
  //       this.setState({ showUpdateBarcodeBtn: true });

  //       return true;
  //     }
  //     return false;
  //   } catch (error) {
  //     console.log("ERROR in handleSaveShipmentNo");
  //     console.error(error);
  //   }
  // };

  handleSaveBarcodeNo = async (barcodeNumber) => {
    try {
      const boxBarcode = this.state.currentShipment.boxBarcode;
      const boxId = this.state.currentShipment.boxId;
      const params = {
        boxId: boxId,
        boxBarcode: boxBarcode,
        boxStatus: "linehaulDelivered",
      };
      const resp = await updateBarcodeStatus(params);

      if (barcodeNumber === boxBarcode && resp.data.success) {
        const updatedOutgoingShipment = this.state.outgoing.map((s) => ({
          ...s,
          boxStatus:
            s.ticketLineHaulId === this.state.currentShipment.ticketLineHaulId
              ? "linehaulDelivered"
              : s.boxStatus,
        }));
        this.setState({ outgoing: updatedOutgoingShipment });
        this.setState({ showUpdateBarcodeBtn: true });
        toast.success(() => <div>{resp.data.msg}</div>);
        return true;
      }
      toast.error(() => <div>{resp.data.msg}</div>);
      return false;
    } catch (error) {
      console.log("ERROR in handleSaveBarcodeNo");
      console.error(error);
    }
  };

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    const outgoingJSON = this.state.outgoing;
    // console.log({outgoingJSON});
    const currentRequest = this.state.currentRequest;
    const ticketRequest = this.state.ticketDetails;
    // console.log({data:outgoingJSON});
    // console.log({ticket:this.state.tiicketDetails});

    // console.log({ outgoingJSON });
    return (
      <>
        <div>
          <div className="form-subHeader">Outgoing Manifest</div>
          <div className="form-row col-12">
            <div className="col-4">
              {this.rTB("Search Ticket No.", "search")}
            </div>
            <div className="col-4">
              <button
                className="btn  btn-primary  btn-sm"
                onClick={() => this.handleSearch(this.state.data.search)}
              >
                Search
              </button>
            </div>
          </div>
          <div className="form-row col-12">
            {this.props.loading && (
              <div className="container-fluid pull-right">
                <div> Loading users...</div>
              </div>
            )}
            {!this.props.loading && (
              <>
                <Table
                  clsName="table table-striped table-bordered table-hover"
                  tableMeta={this.tableMeta}
                  data={outgoingJSON}
                  dropMenu={[]}
                />
                {this.state.outgoing.length !== 0 && (
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                )}
              </>
            )}
          </div>
        </div>

        {this.state.outgoing.length === 0 && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">No Tickets Found</p>
          </div>
        )}

        <div
          className="draggable col-10 ml-5 mr-5"
          id="myDocumentDetails"
          onClick={this.handleView}
        >
          {this.state.openStatus === "false" &&
            this.state.documentDetails === "true" && (
              <DocumentDetails
                key={currentRequest.ticketNo}
                data={currentRequest}
                // requestStatusChange={this.handleRequestStatusChange}
                // user={this.state.user}
                closeModal={this.closeModal}
              />
            )}
        </div>
        <div
          className="draggable  col-sm-12 col-md-8 col-lg-8 "
          id="myModalApprove"
          onClick={this.handleView}
        >
          {
            // this.state.TicketActionsDatas &&
            this.state.openStatus === "true" &&
              this.state.documentDetails === "false" &&
              this.state.ticketStatus === "false" && (
                <ExpenseRise
                  key={currentRequest.ticketNo}
                  data={currentRequest}
                  type="report"
                  ticketDetails={ticketRequest}
                  // ticketActionId={currentRequest.ticketActionId}
                  // requestChange={this.handleRequestChange}
                  // user={this.state.user}
                  closeModal={this.closeModal}
                />
              )
          }
        </div>

        <div
          className="draggable col-10 ml-5 mr-5"
          id="myDocumentDetails"
          onClick={this.handleView}
        >
          {this.state.openStatus === "false" &&
            this.state.documentDetails === "false" &&
            this.state.barcodeDetails === "true" && (
              <BarCodePrintSVGLinehaul
                key={outgoingJSON.ticketNo}
                data={this.state.barcodeRequest}
                barcodeDetails={this.state.barcodeRequest}
                closeModal={this.closeModal}
              />
            )}
        </div>
        <iframe
          id="ifmcontentstoprint"
          title="toPrint"
          style={{
            height: "0px",
            width: "0px",
            position: "absolute",
          }}
        ></iframe>
        <UpdateShipmentNoModal
          shipmentDetails={this.state.currentShipment}
          handleSave={this.handleSaveShipmentNo}
          shipmentInputId="inputShipmentNo"
          modalId="staticBackdrop"
        />
      </>
    );
  }
}
