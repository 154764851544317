import React from "react";
import FormLib from "../../_common/FormLib";
import toast from "./../../../services/_toastService";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import {
  epoch2ddmmyyyyhhmmss,
  epoch2ddmmyyyy,
  epoch2ddmmyy,
  tDate2yyyymmdd,
} from "./../../../utils/DateUtils";

import Table from "../../_common/Table";
import ZTitle from "../../_subhead/ZTitle";

import Pagination from "./../../_common/Pagination";
import _auth from "./../../../services/_authService";

import RequestApprove from "./actions/RequestApprove";
import RequestReady2Approve from "./actions/RequestReady2Approve";
import RequestRunning from "./actions/RequestRunning";
import RequestUTR from "./actions/RequestUTR";
import RequestPaid from "./actions/RequestPaid";
import RequestRejected from "./actions/RequestRejected";
import DocumentDetails from "./actions/DocumentDetails";

import RequestReady from "./actions/RequestReady";

import {
  getFilteredExpenseReport,
  getRequests,
  requestCall,
} from "../../../services/requestV1/requestServices";
import {
  BsArrowLeft,
  BsArrowRight,
  BsFillArrowRightCircleFill,
} from "react-icons/bs";
import { IoIosArrowDropdown, IoMdArrowDropdown } from "react-icons/io";
import {
  getActivity,
  getApprover,
  getClients,
  getFlows,
} from "../../../services/masters/hubServices";
import { getRetailer } from "../../../services/masterLocations/retailerServices";
import { getTeams } from "../../../services/players/teamServices";

class ApproveRequestsWrapper extends FormLib {
  state = {
    data: {
      hubIds: [],
      clientIds: [],
      activityIds: [],
      approverIds: [],
      searchRefId: "",
      searchExpenseNumber: "",
      expenseStatus: "open",
    },
    errors: {},
    currentRequest: {},
    mode: "open",
    actionBtn: "view",
    openStatus: "true",
    showDropdown: false,
    // showExpenseStates: false,
    clients: [],
    activities: [],
    approvers: [],
    flows: [],
    hubs: [],
    // status:"",
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      count: 0,
      lastPage: 0,
    },
    requests: [],
    user: [],
    documentDetails: false,
    stateTableMeta: {},
    stateSelectedReqList: [],
  };

  expenseStatus = [
    { value: "open", label: "Open" },
    { value: "approved", label: "Approved" },
    { value: "ready", label: "Ready" },
    { value: "down", label: "Downloaded" },
    { value: "paid", label: "Paid" },
    { value: "running", label: "Running" },
    { value: "closed", label: "Closed" },
    { value: "reject", label: "Rejected" },
    { value: "all", label: "All" },
  ];

  // : "",
  // : "",

  async componentDidMount() {
    this.loadHubs();
    this.loadRetailers();
    this.loadApprover();
    this.loadActivity();

    this.setState({ stateTableMeta: this.tableMetaMaster });
    const pageNo = this.props.match.params.pageNo
      ? parseInt(this.props.match.params.pageNo)
      : 1;
    this.onModeChange("open", pageNo);

    this.setCurUser();

    let list1 = await getActivity();
    this.setState({ activities: list1.data.data });
  }

  async loadHubs() {
    try {
      let hubList = JSON.parse(localStorage.getItem("hubs"));
      this.setState({ hubs: hubList });
    } catch {
      console.log("Error >>>>>> loadHubs");
    }
  }

  async loadRetailers() {
    try {
      const res = await getTeams();
      if (res !== undefined) {
        if (res.data.success === true) {
          this.setState({ clients: res.data.data.teams });
        } else {
          toast.toastError(res.data.msg);
        }
      }
    } catch {
      console.log("Error >>>>>> loadRetailers");
    }
  }
  async loadActivity() {
    try {
      const res = await getActivity();
      if (res !== undefined) {
        if (res.data.success === true) {
          this.setState({ activity: res.data.data });
        } else {
          toast.toastError(res.data.msg);
        }
      }
    } catch {
      console.log("Error >>>>>> loadActivity");
    }
  }
  async loadApprover() {
    try {
      const res = await getApprover();
      if (res !== undefined) {
        if (res.data.success === true) {
          this.setState({ approvers: res.data.data.approversList });
        } else {
          toast.toastError(res.data.msg);
        }
      }
    } catch {
      console.log("Error >>>>>> loadApprovers");
    }
  }

  setCurUser() {
    const user = _auth.getCurrentUser();
    this.setState({ user });
  }

  modes = {
    open: "Open",
    approved: "Approved",
    ready: "Ready",
    down: "Downloaded",
    paid: "Paid",
    running: "Running",
    closed: "Closed",
    reject: "Rejected",
  };

  isActionButtonEnabled = () => {
    const userInfo = localStorage.getItem("userInfo");
    let user = null;
    if (userInfo && typeof userInfo === "string") {
      user = JSON.parse(userInfo);
    }

    const status =
      user &&
      (user["roleCode"] === "finance_head" ||
        (user["designation"] && user["designation"] === "Finance Head") ||
        user["designation"] === "Finance Executive" ||
        user["designation"] === "Finance Manager" ||
        user["playerId"] === "player1");
    return status;
  };
  async doSubmit(r) {
    try {
      // alert({ r });
      const params = {
        request_id: r.REQUEST_ID,
        approve_id: r.APPROVE_ID,
        approv_amt: r.APPROV_AMT,
        status: "ready",
        approv_remark:
          r.PLAYER_TYPE === "employee"
            ? `Paid to ${r.PLAYER_TYPE} ${r.E_NAME}`
            : r.PLAYER_TYPE === "vendor"
            ? `Paid to ${r.PLAYER_TYPE} ${r.V_NAME}`
            : null,
        pymt_mode: "IMPS",
        bank: "ICICI",
        ready_expense_head: r.APPROV_EXPENSE_HEAD,
      };

      const requestRaised = await requestCall(params, "ready");
      if (requestRaised.data.success === true) {
        toast.toastSuccess(requestRaised.data.msg);
      } else {
        toast.toastError(requestRaised.data.msg);
      }
      this.handleRequestStatusChange();
    } catch (error) {
      console.log("Error in doSubmit");
      console.error(error);
      return;
    }
  }
  async doClick(r) {
    try {
      const params = {
        request_id: r.REQUEST_ID,
        approve_id: r.APPROVE_ID,
        approv_amt: r.APPROV_AMT,
        status: "revertApproved",
      };

      let requestRaised = await requestCall(params, "revertApproved");
      if (requestRaised.data.success === true) {
        toast.toastSuccess(requestRaised.data.msg);
      } else {
        toast.toastError(requestRaised.data.msg);
      }
      this.handleRequestStatusChange();
    } catch (error) {
      console.log("Error in doClick");
      console.error(error);
      return;
    }
  }

  async handleSubmitFilter() {
    try {
      let data = this.state.data;
      this.setState({ loaderCount: 1 });

      const { expenseStatus, ...restData } = data;

      if (
        this.state.mode === "open" ||
        this.state.mode === "running" ||
        this.state.mode === "closed"
      ) {
        let payload1 = restData;

        payload1.approverIds = [];

        this.setState({ data: payload1 });
        let res = await getFilteredExpenseReport(payload1, this.state.mode);
        toast.toastSuccess(res.data.msg);

        if (res.data.data.requestList.length === 0) {
          toast.toastInfo("No data");
          this.setState({ loading: true });
        }

        this.setState({ requests: res.data.data?.requestList });
        this.setState({ pageLinks: res.data.data.pageLinks });
      } else {
        const payload1 = restData;
        let res = await getFilteredExpenseReport(payload1, this.state.mode);
        toast.toastSuccess(res.data.msg);

        if (res.data.data.requestList.length === 0) {
          toast.toastInfo("No data");
          this.setState({ loading: true });
        }

        this.setState({ requests: res.data.data?.requestList });
        this.setState({ pageLinks: res.data.data.pageLinks });
      }
    } catch {
      console.log("Error in >>>>>> fetching ");
    }
  }
  localHandleChange = (e) => {
    if (e.target.id === "expenseStatus") {
      if (!e.target.value) return;
      this.onModeChange(e.target.value);
    }
  };

  onModeChange(mode, pageNo = 1) {
    this.tableMeta.key = "APPROVE_ID";
    this.setState({ documentDetails: false });
    this.tableMeta.columns = [
      {
        key: "Seq",
        action: (r) => (
          <>
            <span
              className="badge badge-success link-over"
              onClick={() => this.onRequest(r)}
            >
              {(r["RE_PREFIX"].trim() + r["SEQ"]).trim()}{" "}
            </span>
          </>
        ),
        icon: () => <>Seq. </>,
      },

      {
        key: "hub",
        action: (r) => <>{r["M_HUB"] + " / " + r["REQUEST_BY_FIRST"]}</>,

        icon: () => <>HUB /REQ BY </>,
      },

      {
        // ***************************************************dfdfdfd**********************************************************

        // path: "REF_ID",  label: "Ref No."

        key: "MULTI_REF_IDS",
        action: (r) => (
          <>
            <span
              className="badge badge-success link-over"
              onClick={() => this.onloadDocumentDetails(r)}
            >
              {/* {r["REF_ID"] && r["REF_ID"].trim().trim()}{" "} */}
              {r["MULTI_REF_IDS"]
                ? r["MULTI_REF_IDS"].substring(0, 30)
                : r["REF_ID"]}
            </span>
          </>
        ),
        icon: () => <>Ticket Number/RefNo </>,
        label: "#",
      },

      {
        key: "M_ACTIVITY",
        action: (r) => <>{r["M_ACTIVITY"]}</>,

        icon: () => <>Expense Head</>,
        label: "Expense Head",
      },
    ];

    this.setState({ mode: mode });
    this.setState({ openStatus: "false" });
    this.getRequests(mode, pageNo);

    switch (mode) {
      case "open":
        this.tableMeta.columns.push(
          { path: "AMT", label: "Total Req" },
          { path: "REQ_AMT", label: "Req Amt" },
          {
            key: "CREATED_AT",
            action: (r) => epoch2ddmmyy(r.CREATED_AT),

            icon: () => <>Req Date</>,
          },
          {
            key: "ACC_NAME",
            action: (r) => (
              <>
                {r["E_NAME"] ? r["E_NAME"] : ""}
                {r["V_NAME"] ? r["V_NAME"] : ""}
              </>
            ),

            icon: () => <>A/C Name</>,
          }
        );
        return this.setState({ actionBtn: "View" });

      case "approved":
        this.tableMeta.columns.push(
          { path: "AMT", label: "Total Req" },
          { path: "REQ_AMT", label: "Req Amt" },
          {
            key: "CREATED_AT",
            action: (r) => epoch2ddmmyy(r["CREATED_AT"]),

            icon: () => <div style={{ width: "5rem" }}>Req Date</div>,
          },
          {
            key: "APPROVER_NAME",
            action: (r) => <>{r["APPROVER_NAME"]}</>,
            icon: () => <>Approver</>,
          },

          {
            key: "APPROVE_DATE",
            action: (r) => epoch2ddmmyy(r["APPROVE_DATE"]),

            icon: () => <>Approv Date</>,
          },

          { path: "AMT_APPROVED", label: "Total Apprv .Amt" },
          { path: "APPROV_AMT", label: "Req Apprv Amt" },
          {
            key: "ACC_NAME",
            action: (r) => (
              <>
                {r["E_NAME"] ? r["E_NAME"] : ""}
                {r["V_NAME"] ? r["V_NAME"] : ""}
              </>
            ),

            icon: () => <>A/C Name</>,
          },
          {
            key: "Seq1",
            action: (r) =>
              this.isActionButtonEnabled() && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    style={{ border: "none", width: "2rem", height: "2rem" }}
                    onClick={() => this.doSubmit(r)}
                  >
                    <BsArrowRight />
                  </button>
                </div>
              ),
            icon: () => <>Make Ready</>,
          }
        );

        return this.setState({ actionBtn: "View" });
      case "ready":
        this.tableMeta.columns.push(
          { path: "AMT", label: "Total Req" },
          { path: "REQ_AMT", label: "Req Amt" },

          {
            key: "APPROVER_NAME",
            action: (r) => <>{r["APPROVER_NAME"]}</>,
            icon: () => <>Approver</>,
          },
          {
            key: "APPROVE_DATE",
            action: (r) => epoch2ddmmyy(r["APPROVE_DATE"]),
            icon: () => <>Approv Date</>,
            label: "Approv Date",
          },
          { path: "AMT_APPROVED", label: "Total Apprv .Amt" },
          { path: "APPROV_AMT", label: "Req Apprv Amt" },
          {
            key: "ACC_NAME",
            action: (r) => (
              <>
                {r["E_NAME"] ? r["E_NAME"] : ""}
                {r["V_NAME"] ? r["V_NAME"] : ""}
              </>
            ),

            icon: () => <>A/C Name</>,
          },
          {
            key: "Seq",
            action: (r) =>
              this.isActionButtonEnabled() && (
                <div className="d-flex justify-content-center">
                  <button
                    type="button"
                    style={{ border: "none", width: "2rem", height: "2rem" }}
                    onClick={() => this.doClick(r)}
                  >
                    <BsArrowLeft />
                  </button>
                </div>
              ),
            icon: () => <>Revert To Approved</>,
          }
        );
        return this.setState({ actionBtn: "View" });
      case "down":
        this.tableMeta.columns.push(
          { path: "AMT", label: "Total Req" },
          { path: "REQ_AMT", label: "Req Amt" },

          {
            key: "APPROVER_NAME",
            action: (r) => <>{r["APPROVER_NAME"]}</>,
            icon: () => <>Approver</>,
          },
          {
            key: "APPROVE_DATE",
            action: (r) => epoch2ddmmyy(r["APPROVE_DATE"]),

            icon: () => <>Approv Date</>,
          },
          { path: "AMT_APPROVED", label: "Total Apprv .Amt" },
          { path: "APPROV_AMT", label: "Req Apprv Amt" },
          {
            key: "ACC_NAME",
            action: (r) => (
              <>
                {r["E_NAME"] ? r["E_NAME"] : ""}
                {r["V_NAME"] ? r["V_NAME"] : ""}
              </>
            ),

            icon: () => <>A/C Name</>,
          }
        );
        return this.setState({ actionBtn: "View" });
      case "running":
        this.tableMeta.columns.push(
          { path: "AMT", label: "Total Req" },
          { path: "AMT_APPROVED", label: "Total Apprv .Amt" },
          { path: "PAID", label: "Paid" }
        );
        return this.setState({ actionBtn: "View" });
      case "paid":
        this.tableMeta.columns.push(
          { path: "AMT", label: "Total Req" },
          { path: "REQ_AMT", label: "Req Amt" },
          {
            key: "CREATED_AT",
            action: (r) => epoch2ddmmyy(r["CREATED_AT"]),

            icon: () => <div style={{ width: "5rem" }}>Req Date</div>,
          },
          // { path: "APPROVER_NAME", label: "Approver" },
          {
            key: "APPROVER_NAME",
            action: (r) => <>{r["APPROVER_NAME"]}</>,
            icon: () => (
              <>
                Approver
                {/* <input
                  type="text"
                  className="form-control"
                  style={{ width: "8rem", height: "1.5rem" }}
                  name="searchTicket"
                  id="searchTicket"
                  value={this.state.searchApprover}
                  onChange={(e) => {
                    this.setState({ searchApprover: e.target.value });
                    const searchApproverList = this.state.requests?.filter(
                      (a) => {
                        const approver = a.APPROVER_NAME;
                        // console.log({ client: c.M_CLIENT});
                        // console.log(Client, "ClientDetails");
                        return approver
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase());
                      }
                    );
                    this.setState({ searchApproverList });
                    console.log(searchApproverList, "searchApprover");

                    console.log(e.target.value, "searchhh");
                  }}
                /> */}
              </>
            ),
            // path: "APPROVER_NAME", label: "Approver"
          },
          {
            key: "APPROVE_DATE",
            action: (r) => epoch2ddmmyy(r["APPROVE_DATE"]),

            icon: () => <div style={{ width: "4rem" }}>Approv Date</div>,
          },
          { path: "AMT_APPROVED", label: "Total Apprv .Amt" },
          { path: "APPROV_AMT", label: "Req Apprv Amt" },
          { path: "PAY_UTR_NO", label: "UTR" },
          {
            key: "PAY_ACC_DEBIT_DATE",
            action: (r) => epoch2ddmmyy(r["PAY_ACC_DEBIT_DATE"]),

            icon: () => <>Debit Date</>,
          },
          {
            key: "ACC_NAME",
            action: (r) => (
              <>
                {r["E_NAME"] ? r["E_NAME"] : ""}
                {r["V_NAME"] ? r["V_NAME"] : ""}
              </>
            ),

            icon: () => <>A/C Name</>,
          }
        );
        return this.setState({ actionBtn: "View" });
      case "closed":
        this.tableMeta.key = "REQUEST_ID";

        this.tableMeta.columns.push(
          { path: "AMT", label: "Total Req" },
          { path: "AMT_APPROVED", label: "Total Apprv .Amt" },
          { path: "PAID", label: "Paid" }
        );
        return this.setState({ actionBtn: "View" });
      case "reject":
        this.tableMeta.columns.push(
          { path: "AMT", label: "Total Req" },
          { path: "APPROVER_NAME", label: "Rejecter" },
          {
            key: "PAY_ACC_DEBIT_DATE",
            action: (r) => epoch2ddmmyy(r["APPROVE_DATE"]),

            icon: () => <>Approv Date</>,
          },
          { path: "AMT_APPROVED", label: "Total Apprv .Amt" },
          { path: "PAID", label: "Paid" }
        );
        return this.setState({ actionBtn: "View" });
      default:
        return "foo";
    }
  }

  async getRequests(mode, pageNo) {
    mode === "running"
      ? (this.tableMeta.key = "REQUEST_ID")
      : (this.tableMeta.key = "APPROVE_ID");
    this.setState({
      requests: [],
      pageLinks: {
        pageNo: 1,
        perPage: 0,

        count: 0,
        lastPage: 0,
      },
    });

    const resp = await getRequests(
      mode,
      this.state.data["search"],
      pageNo,
      100
    );

    if (resp.data && resp.data.success && resp.data.success === true) {
      toast.toastSuccess(
        resp.data.data?.pageLinks.count + " : " + resp.data.msg
      );
      this.setState({ requests: resp.data.data?.requestList });
      // this.setState({ searchExpenseNumberList: resp.data.data?.requestList });
      // this.setState({ searchHubList: resp.data.data?.requestList });
      // this.setState({ searchTicketNumberList: resp.data.data?.requestList });
      // this.setState({ searchCustomerList: resp.data.data?.requestList });
      // this.setState({ searchExpenseHeadList: resp.data.data?.requestList });
      // this.setState({ searchApproverList: resp.data.data?.requestList });
      this.setState({ pageLinks: resp.data.data?.pageLinks });
    } else {
      toast.toastError(resp.data?.msg || "Failed, Kindly check API's");
    }
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/v1/request/approve/" + pageNo);

    this.getRequests(this.state.mode, pageNo);
  };

  handleRequestStatusChange = () => {
    this.getRequests(this.state.mode, this.state.pageLinks.pageNo);
    this.setState({ currentRequest: {} });
  };

  onRequest = (r) => {
    this.setState({ documentDetails: "false" });
    this.setState({ openStatus: "true" });
    this.setState({ currentRequest: r });
  };

  onloadDocumentDetails = (r) => {
    this.setState({ documentDetails: "true" });
    this.setState({ openStatus: "false" });
    this.setState({ currentRequest: r });
  };

  // tableMeta = { columns: [] };
  tableMeta = {
    key: "APPROVE_ID",
    columns: [
      {
        key: "action",
        action: (r) => (
          <i
            data-target="#myModalOpen"
            data-backdrop="false"
            data-toggle="modal"
            aria-hidden="true"
          >
            <span
              className="badge badge-light link-over "
              onClick={() => this.onRequest(r)}
            >
              {this.state.actionBtn}
            </span>
          </i>
        ),
        cusClass: " ",
        icon: () => <>Act</>,
      },
      {
        key: "Seq",
        action: (r) => (
          <>
            <span
              className="badge badge-success link-over"
              onClick={() => this.onRequest(r)}
            >
              {(r["RE_PREFIX"].trim() + r["SEQ"].trim()).trim()}{" "}
            </span>
          </>
        ),
        cusClass: " ",
        icon: () => <>#</>,
      },
      { path: "REF_ID", label: "Ref No." },
      { path: "M_HUB", label: "Hub" },
      { path: "M_CLIENT", label: "Customer" },
      { path: "M_ACTIVITY", label: "Activity" },
    ],
  };

  tableColumns = [
    {
      key: "action",
      action: (r) => (
        <i
          data-target="#myModalOpen"
          data-backdrop="false"
          data-toggle="modal"
          aria-hidden="true"
        >
          <span
            className="badge badge-light link-over"
            onClick={() => this.onRequest(r)}
          >
            {this.state.actionBtn}
          </span>
        </i>
      ),
      cusClass: " ",
      icon: () => <>Act</>,
    },
    {
      key: "Seq",
      action: (r) => (
        <>
          <span
            className="badge badge-success link-over"
            onClick={() => this.onRequest(r)}
          >
            {" "}
            {(r["RE_PREFIX"].trim() + r["SEQ"].trim()).trim()}{" "}
          </span>{" "}
        </>
      ),
      cusClass: " ",
      icon: () => <>#</>,
    },
    { path: "REF_ID", label: "Ref No." },
    { path: "M_HUB", label: "Hub" },
    { path: "M_CLIENT", label: "Customer" },
    { path: "M_ACTIVITY", label: "Activity" },
  ];
  closeModal = () => {
    this.setState({ openStatus: "false" });
    this.setState({ documentDetails: "false" });
  };
  handleView = () => {
    $("#myModalOpen").draggable();
    $("#myModalApprove").draggable();
    $("#myModalReady").draggable();
    $("#myModalReady2Approve").draggable();
    $("#myModalUTR").draggable();
    $("#myModalRunning").draggable();
    $("#myModalPaid").draggable();
    $("#myModalRejected").draggable();
    $("#myDocumentDetails").draggable();
  };

  dropdown = () => {
    this.setState({ showDropdown: !this.state.showDropdown });
  };

  // expenseDropdown = () => {
  //   this.setState({ showExpenseStates: !this.state.showExpenseStates });
  // };

  render() {
    const { pageNo, perPage, count } = this.state.pageLinks;
    // const { showDropdown } = this.state;
    // const { showExpenseStates } = this.state;
    // if (count === 0) return <p>No Data Found</p>;

    const requestsJSON = this.state.requests;
    // const requestsJSON = this.state.searchExpenseNumberList;
    // const requestsJSON = this.state.searchHubList;
    // const requestsJSON = this.state.searchTicketNumberList;
    // const requestsJSON = this.state.searchCustomerList;
    // const requestsJSON = this.state.searchExpenseHeadList;
    // const requestsJSON = this.state.searchApproverList;
    const currentRequest = this.state.currentRequest;

    // console.log({ stateData: this.state });
    // console.log({ loadingData: this.state.loading });
    // console.log({count:count});
    // console.log({stttttttaaattteee:this.state.data});

    return (
      <React.Fragment>
        {/* <ZTitle/> */}

        <div className="pageContent">
          <div className="container-fluid">
            <div className="columns">
              <div>
                <div className="pb-2">
                  <div
                    className="pageContent"
                    style={{ backgroundColor: "rgb(251 112 3 / 28%)" }}
                  >
                    <div
                      className="card"
                      style={{ backgroundColor: "rgb(210 126 49 / 0%)" }}
                    >
                      <div
                        className="card-body"
                        style={{
                          backgroundColor: "white",
                          height: "4rem",
                          maxWidth: "100%",
                        }}
                      >
                        <form>
                          <div
                            className="form-row d-flex"
                            style={{ columnGap: "1.5rem" }}
                          >
                            <div
                              style={{
                                paddingTop: "0.75rem",
                                width: "9rem",
                                height: "2.5rem",
                              }}
                            >
                              {this.rSB(
                                "Expense Status",
                                "expenseStatus",
                                this.expenseStatus,
                                { value: "value", label: "label" }
                              )}
                            </div>
                            <div>
                              {this.rSBMultiAE(
                                "Activities",
                                "activityIds",
                                this.state.activities,
                                {
                                  value: "EXPENSE_HEAD_ID",
                                  label: "EXPENSE_HEAD",
                                },
                                " col-md-2 "
                              )}
                            </div>
                            <div>
                              {this.rSBMulti(
                                "Hub",
                                "hubIds",
                                this.state.hubs,
                                { value: "hubId", label: "hub" },
                                " col-md-2 "
                              )}
                            </div>
                            <div>
                              {this.state.mode !== "open" &&
                                this.rSBMultiAE(
                                  "Approver",
                                  "approverIds",
                                  this.state.approvers,
                                  {
                                    value: "APPROVER_ID",
                                    label: "APPROVER_NAME",
                                  },
                                  " col-md-2 "
                                )}
                            </div>
                            <div style={{ width: "8rem" }}>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Ticket No"
                                value={this.state.searchRefId}
                                onChange={(e) => {
                                  this.setState({
                                    ...this.state,
                                    searchRefId: e.target.value,
                                    data: {
                                      ...this.state.data,
                                      searchRefId: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>

                            <div style={{ width: "8rem" }}>
                              <input
                                type="text"
                                className="form-control"
                                name="searchNumber"
                                id="searchNumber"
                                placeholder="ExpenseNo"
                                value={this.state.searchExpenseNumber}
                                onChange={(e) => {
                                  this.setState({
                                    ...this.state,
                                    searchExpenseNumber: e.target.value,
                                    data: {
                                      ...this.state.data,
                                      searchExpenseNumber: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <div className="col-1 pull-right">
                              <button
                                className="btn-sm btn-primary"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.handleSubmitFilter();
                                }}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                {this.props.loading && (
                  <div className="container-fluid">
                    <div> Loading users...</div>
                  </div>
                )}

                {!this.props.loading && this.state.pageLinks.count > 0 && (
                  <>
                    <div className="tableWrapper container-fluid">
                      <div
                        className="fixTableHead"
                        style={{ height: "35rem", overflow: "scroll" }}
                      >
                        <Table
                          clsName="table table-striped table-bordered table-hover"
                          tableMeta={this.tableMeta}
                          data={requestsJSON}
                          dropMenu={[]}
                        />
                      </div>
                    </div>
                    <Pagination
                      pageNo={pageNo}
                      perPage={perPage}
                      itemsCount={count}
                      onPageChange={this.handlePageChange}
                    />
                  </>
                )}

                {!this.props.loading && count === 0 && (
                  <div className="alert alert-info" role="alert">
                    <div>Loading......</div>
                  </div>
                )}
              </div>
            </div>

            <div
              className="draggable  col-sm-12 col-md-8 col-lg-4"
              id="myModalApprove"
              onClick={this.handleView}
            >
              {this.state.mode === "open" &&
                this.state.currentRequest.APPROVE_ID &&
                this.state.openStatus === "true" && (
                  <RequestApprove
                    key={this.state.mode + currentRequest.APPROVE_ID}
                    data={currentRequest}
                    requestStatusChange={this.handleRequestStatusChange}
                    user={this.state.user}
                    closeModal={this.closeModal}
                  />
                )}
            </div>

            <div
              className="draggable  col-sm-12 col-md-8 col-lg-4"
              id="myModalReady"
              onClick={this.handleView}
            >
              {this.state.mode === "approved" &&
                this.state.currentRequest.APPROVE_ID &&
                this.state.documentDetails === "false" &&
                this.state.openStatus === "true" && (
                  <RequestReady
                    key={this.state.mode + currentRequest.APPROVE_ID}
                    data={currentRequest}
                    requestStatusChange={this.handleRequestStatusChange}
                    user={this.state.user}
                    closeModal={this.closeModal}
                  />
                )}
            </div>

            {/* ******************************************** */}
            <div
              className="draggable col-10 ml-5 mr-5"
              id="myDocumentDetails"
              onClick={this.handleView}
            >
              {this.state.currentRequest.REQUEST_ID &&
                this.state.openStatus === "false" &&
                this.state.documentDetails === "true" && (
                  <DocumentDetails
                    key={this.state.mode + currentRequest.REQUEST_ID}
                    data={currentRequest}
                    // requestStatusChange={this.handleRequestStatusChange}
                    // user={this.state.user}
                    closeModal={this.closeModal}
                  />
                )}
            </div>

            {/* ******************************************** */}

            <div
              className="draggable  col-sm-12 col-md-8 col-lg-4"
              id="myModalReady2Approve"
              onClick={this.handleView}
            >
              {this.state.mode === "ready" &&
                this.state.currentRequest.APPROVE_ID &&
                this.state.openStatus === "true" && (
                  <RequestReady2Approve
                    key={this.state.mode + currentRequest.APPROVE_ID}
                    data={currentRequest}
                    requestStatusChange={this.handleRequestStatusChange}
                    user={this.state.user}
                    closeModal={this.closeModal}
                  />
                )}
            </div>

            <div
              className="draggable  col-sm-12 col-md-8 col-lg-4"
              id="myModalUTR"
              onClick={this.handleView}
            >
              {this.state.mode === "down" &&
                this.state.currentRequest.APPROVE_ID &&
                this.state.openStatus === "true" && (
                  <RequestUTR
                    key={this.state.mode + currentRequest.APPROVE_ID}
                    data={currentRequest}
                    requestStatusChange={this.handleRequestStatusChange}
                    user={this.state.user}
                    closeModal={this.closeModal}
                  />
                )}
            </div>

            <div
              className="draggable  col-sm-12 col-md-8 col-lg-4"
              id="myModalRunning"
              onClick={this.handleView}
            >
              {this.state.mode === "running" &&
                this.state.currentRequest.REQUEST_ID &&
                this.state.openStatus === "true" && (
                  <RequestRunning
                    key={this.state.mode + currentRequest.REQUEST_ID}
                    data={currentRequest}
                    requestStatusChange={this.handleRequestStatusChange}
                    user={this.state.user}
                    closeModal={this.closeModal}
                  />
                )}
            </div>
            <div
              className="draggable  col-sm-12 col-md-8 col-lg-4"
              id="myModalPaid"
              onClick={this.handleView}
            >
              {this.state.mode === "paid" &&
                this.state.currentRequest.APPROVE_ID &&
                this.state.openStatus === "true" && (
                  <RequestPaid
                    key={this.state.mode + currentRequest.APPROVE_ID}
                    data={currentRequest}
                    requestStatusChange={this.handleRequestStatusChange}
                    user={this.state.user}
                    closeModal={this.closeModal}
                  />
                )}
            </div>

            <div
              className="draggable  col-sm-12 col-md-8 col-lg-4"
              id="myModalRejected"
              onClick={this.handleView}
            >
              {this.state.mode === "reject" &&
                this.state.currentRequest.APPROVE_ID &&
                this.state.openStatus === "true" && (
                  <RequestRejected
                    key={this.state.mode + currentRequest.APPROVE_ID}
                    data={currentRequest}
                    requestStatusChange={this.handleRequestStatusChange}
                    user={this.state.user}
                    closeModal={this.closeModal}
                  />
                )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ApproveRequestsWrapper;
