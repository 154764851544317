import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
// import ProjectDemo from './ProjectDemo';

import ZTitle from "../../_subhead/ZTitle";
import ProjectModuleform from "./ProjectModuleform";

class ProjectForm extends FormLib {
  state = {
    data: {
      username: "",
      password: "",
      pickupDate: this.getDatebyDays(1),
      pickupTime: "14:00",

      ticket1: "",
      ticket2: "",
      clientName: "",
      area: "",
      add1: "",
      add2: "",
      add3: "",
      pincode: "",
      mobileNo: "",
      phoneNo: "",
      consigneeName: "",
      length: "",
      width: "",
      height: "",
    },
    errors: {},
  };
  gender = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
  ];
  schema = {
    ticket1: Joi.string().label("Ticket 1"),
    ticket2: Joi.string().label("Ticket 2"),
  };

  render() {
    //const [selectedDate, setSelectedDate] = useState(null);

    return (
      <React.Fragment>
        <ZTitle subHeading="Project::Form" />
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-10">
              <div className="card">
                {/* <div className="col-10"> */}
                <ProjectModuleform />
                {/* </div> */}

                <div className="card-body border-top">
                  <div className="form-row">
                    {this.rTB("Module", "module", "col-10")}
                  </div>
                  <div className="form-row">
                    {this.rTB("Owner", "add1", "col-10")}
                  </div>
                </div>
                <div className="p-2">
                  {this.rBtnSS("Save", "save", "pull-right")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ProjectForm;
