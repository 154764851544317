import React from "react";
import LocationTitle from "../../_subhead/LocationTitle";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import Table from "../../_common/Table";
import toast from "../../../services/_toastService";
import Pagination from "./../../_common/Pagination";
import { getTeams } from "../../../services/players/teamServices";
import dateFormat from 'dateformat';
import {
  getPlayerTeamApiToken,
  createPlayerTeamApiToken,
  updatePlayerTeamApiToken,
  deletePlayerTeamApiToken,
} from "../../../services/coreItAdmin/PlayerTeamApiTokenServices";

class PlayerTeamApiToken extends FormLib {
  state = {
    loading: true,
    data: {
      teamId: "",
      apiUsername: "",
      apiPassword: "",
      apiToken: "",
      tokenExpiryDate: this.getDatebyDays(0),
    },
    errors: {},
    playerTeamApiToken: [],
    teams: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    isEdit: false
  };

  schema = {
    teamId: Joi.string().required().label("Team"),
    apiUsername: Joi.string().required().label("Username"),
    apiPassword: Joi.string().required().label("Password"),
    apiToken: Joi.string().required().label("API token"),
    tokenExpiryDate: Joi.string().required().label("Token Expiry Date"),
  };

  tableMeta = {
    key: "teamId",
    columns: [
      {
        key: "TEAM_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnAction(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
            </div>
          </div>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      { path: "apiUsername", label: "User Name" },
      { path: "apiToken", label: "API Token" },
      {
        key: "tokenExpiryDate",
        action: (r) => <>{dateFormat(r.tokenExpiryDate, "dd-mm-yyyy")}</>,
        cusClass: " ",
        icon: () => <>Expiry Date</>
      },
    ],
  };

  handleOnAction = (r) => {
    let onEditParam = {};
    onEditParam.teamId = r.teamId;
    onEditParam.apiUsername = r.apiUsername;
    onEditParam.apiPassword = r.apiPassword;
    onEditParam.apiToken = r.apiToken;
    onEditParam.tokenExpiryDate = r.tokenExpiryDate;
    this.setState({ data: onEditParam });
    this.setState({ isEdit: true });

  };

  componentDidMount() {
    this.loadPlayerTeamApiToken(this.state.pageLinks.pageNo);
    this.loadTeams();
  }

  loadPlayerTeamApiToken = async (pageNo) => {
    this.setState({ loading: true });
    const res = await getPlayerTeamApiToken(pageNo);

    // console.log(JSON.stringify(res.data));

    if (res.data.success === true) {
      this.setState({ playerTeamApiToken: res.data.data.plrteamapitoken });
      this.setState({ pageLinks: res.data.data.pageLinks });
      toast.toastSuccess(res.data.msg);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  loadTeams = async () => {
    this.setState({ loading: true });
    const res = await getTeams();
    if (res.data.success === true) {
      this.setState({ teams: res.data.data.teams });
    } else {
      console.log("Errer while loading teams");
    }
  };

  handlePageChange = (pageNo) => {
    this.props.history.push("/coreItAdmin/player-api-token/" + pageNo);
    this.loadPlayerTeamApiToken(pageNo);
  };

  resetData() {
    this.setState({
      data: {
        teamId: "",
        apiUsername: "",
        apiPassword: "",
        apiToken: "",
        tokenExpiryDate: this.getDatebyDays(0),
      },
      isEdit: false
    });
  }

  doSubmit = async () => {
    let res;
    if (this.state.btnValue === "create") {
      res = await createPlayerTeamApiToken(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await updatePlayerTeamApiToken(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "delete") {
      res = await deletePlayerTeamApiToken(this.state.data);
      this.resetData();
    }
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadPlayerTeamApiToken(this.state.pageLinks.pageNo);
    } else {
      toast.toastError(res.data.msg);
    }
  }

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    const isEditRecord = this.state.isEdit;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Team Api Token" />
        <div className="pageContent">
          <div className="row">
            <div className="col-6">
              {this.state.loading && (
                <div className="container-fluid">
                  <h1>Player Team Api Token Loading...</h1>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.playerTeamApiToken}
                    dropMenu={[]}
                  />
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              )}
            </div>
            <div className="col-6 ">
              <div className="card p-4 pt-5">
                <form >
                  <div className="form-row">
                    {this.rSB(
                      "Team",
                      "teamId",
                      this.state.teams,
                      { value: "teamId", label: "name" },
                      " col-12"
                    )}
                    {this.rTB("User Name", "apiUsername", "col-6")}
                    {this.rPB("Password", "apiPassword", "col-6")}
                    {this.rTB("API Token", "apiToken", "col-6")}
                    {this.rDP("Token Expiry Date", "tokenExpiryDate", "col-6")}

                  </div>
                  {!isEditRecord && this.rBtnSP("Create", "create")}
                  {isEditRecord && (
                    <>
                      {this.rBtnSS("Edit", "edit")}
                      {this.rBtnSD("Delete", "delete",)}
                    </>
                  )}
                  <button type="button" className="btn btn-sm btn-warning pull-right"
                    onClick={() => this.resetData()}
                    title="Reset">
                    <i className={"fa fa-unlink"} />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default PlayerTeamApiToken;
