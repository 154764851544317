import _http from "./../_httpService";
import config from "./../../config.json";
import { yyyymmdd } from "./../../utils/DateUtils";
import _auth from "./../../services/_authService";

const apiURLPHP = config[config.build].apiURLPHP;
const apiURLValidata = config[config.build].apiURLValidata;
const apiURLValidataOld = config[config.build].apiURLValidataOld;

const apiURLEExpenseV1 = config[config.build].apiURLEExpenseV1;
const apiCoreEndpointE = config[config.build].apiURLECore;

const apiURLCore = config[config.build].apiURLCore;

const subURL = "/requestV1";
// const subURL = "";

function buildUrl(id) {
  const apiEndpoint = apiURLPHP;
  return `${apiEndpoint}/${id}`;
}

export function createRequest(param) {
  return _http.post(
    `${apiURLEExpenseV1}/raiseRequest`,
    param,
    _http.headerJWT()
  );
}

export async function getRequests(status, search, pageNo = 1, perPage = 100) {
  let hubIds = await _auth.getUserHubsIds();
  let param = { hubIds, search };
  const apiEndpoint = `${apiURLEExpenseV1}/requestListV2/${status}/${perPage}/${pageNo}`;
  return _http.put(apiEndpoint, param, _http.headerJWT());
}

export function additionalRequest(param) {
  const apiEndpoint = apiURLPHP + subURL + "/request-raise-additional.php";
  return _http.post(apiEndpoint, param, _http.headerJWT());
}

export function getFilteredExpenseReport(
  params,
  mode,
  pageNo = 1,
  perPage = 100
) {
  let payload = { ...params };
  const apiEndpoint = `${apiURLEExpenseV1}/requestListV2/${mode}/${perPage}/${pageNo}`;

  return _http.put(apiEndpoint, payload, _http.headerJWT());
}
export function requestCall(item, action) {
  var payload;
  switch (action) {
    case "approved":
      payload = item;
      break;
    case "ready":
      payload = item;
      break;
    case "revertApproved":
      payload = item;
      break;
    case "revertOpen":
      payload = item;
      break;
    case "paid":
      payload = item;
      break;
    case "reject":
      payload = { ...item };
      delete payload["amt_approved"];
      delete payload["approv_amt"];
      break;
    case "rejectBase":
      payload = { ...item };
      delete payload["amt_approved"];
      delete payload["approv_amt"];
      break;

    default:
      break;
  }
  payload["action"] = action;

  return _http.post(
    `${apiURLEExpenseV1}/request-approve-create`,
    payload,
    _http.headerJWT()
  );
}

export function getPaymentsList(requestId) {
  return _http.get(
    buildUrl(subURL + "/request-payments-list.php?request_id=" + requestId),
    _http.headerJWT()
  );
}

export function getTicketsList(query) {
  return _http.get(apiURLValidataOld + "/misReport/getTicketList/" + query);
}

export function getTicketsListNew(query) {
  return _http.get(apiURLCore + "/getTickets/" + query, _http.headerJWT());
}

export function getEmployeesList(query, perPage = 100, pageNo = 1) {
  return _http.get(
    `${apiURLEExpenseV1}/request-emp-account/${query}/${perPage}/${pageNo}`,
    _http.headerJWT()
  );
}

export function getVendorsList(query, perPage = 100, pageNo = 1) {
  return _http.get(
    `${apiURLEExpenseV1}/request-ven-account/${query}/${perPage}/${pageNo}`,
    _http.headerJWT()
  );
}

export function getExcelPayments(payload) {
  return _http.post(
    `${apiURLEExpenseV1}/request-excel-download`,
    payload,
    _http.headerJWT()
  );
}

export function uploadExcelData(r) {
  let procData = {
    approve_id: r.Reference_no,
    approv_amt: r.Amount,
    utr_no: r["UTR NO"],
    acc_debt_date: yyyymmdd(r.Acct_Debit_date),
    status: "paid",
    action: "paidExcel",
  };

  return _http.post(
    `${apiURLEExpenseV1}/request-approve-create`,
    procData,
    _http.headerJWT()
  );
}
export function paymentsDownloded(items) {
  const approve_ids = items.map((r) => {
    return r["REF_NO"];
  });

  const payload = {
    approve_ids,
  };

  return _http.post(
    `${apiURLEExpenseV1}/request-excel-downloaded`,
    payload,
    _http.headerJWT()
  );
}

export function getPaymentReports(param, pageNo, perPage = 2000) {
  const apiEndpoint = `${apiURLEExpenseV1}/request-reports-payments/${perPage}/${pageNo}`;
  return _http.put(apiEndpoint, param, _http.headerJWT());
}

export function getRequestReports(param, pageNo, perPage = 100) {
  const apiEndpoint = `${apiURLEExpenseV1}/request-reports-requests/${perPage}/${pageNo}`;

  return _http.put(apiEndpoint, param, _http.headerJWT());
}

// -----------------------------------------------------------------------------------------------

export function getDocuments(param) {
  const apiEndpoint = `${apiURLEExpenseV1}/raiseRequest/file-upload-s3-list/${param}`;
  return _http.get(apiEndpoint, _http.headerJWT());
}

export function getSaveFile(param, file) {
  const apiEndpoint = `${apiURLEExpenseV1}/raiseRequest/file-upload-s3`;

  const formData = new FormData();
  formData.append("REQUEST_ID", param.REQUEST_ID);
  formData.append("INFO", param.INFO);
  formData.append("FILE_ATTACH", file);
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return _http.post(apiEndpoint, formData, _http.headerJWT());
}

export function createRequestTicketActivities(param) {
  return _http.post(
    `${apiURLEExpenseV1}/raiseRequestV2`,
    param,
    _http.headerJWT()
  );
}

export function validateTicketList(param) {
  const apiEndpoint = `${apiCoreEndpointE}/ticketValidation`;
  return _http.put(apiEndpoint, param, _http.headerJWT());
}

export function getExpenseHeadType(expenseHeadType) {
  return _http.get(
    `${apiURLEExpenseV1}/expenseHead/${expenseHeadType}`,
    _http.headerJWT()
  );
}
