import React from 'react';
import FormLib from './../../_common/FormLib';
import Joi from 'joi-browser';
import { getFlows, getRetailer } from '../../../services/masters/hubServices';
import { assignRetailerflow, unassignRetailerflow, getRetailerAssignedFLow } from './../../../services/masters/retailerServices';
import { toast } from 'react-toastify';

class RetailerFlows extends FormLib {
    state = {
        data: { client: "", adva1641451442542TZd15eb5e733e54030835c7c548cce43ec: "adva1641451442542TZd15eb5e733e54030835c7c548cce43ec" },
        clients: [],
        flows: [],
        errors: {},
    }

    schema = {
        client: Joi.string().required().label("client")
    };

    async componentDidMount() {
        let list = await getRetailer();
        if (list.data.data !== undefined) {
            this.setState({ clients: list.data.data.teams });
        }

        list = await getFlows();
        if (list.data.data !== undefined) {
            this.setState({ flows: list.data.data.flows });
        }
    }

    handleflow = (flowID) => {
        console.log(flowID);
    }

    localHandleChange = (e) => {
        if (e.target.name === "client") {
            this.handleClient(e);
        }
        // console.log(e.target.type);
        if (e.target.type === 'checkbox') {
            const { value } = e.target;
            this.handleCheckBox(e);
            this.validateProperty(e.target.name, e.target.value);
        }
    };

    handleClient(e) {
        const { value } = e.target;
        let data = this.state.data;
        let retailer = this.state;

        // find the clients with the current selected value
        const clients = retailer.clients.find((c) => c.name === value);

        if (clients !== undefined) {
            // console.log("------>bhanthu " + JSON.stringify(clients.teamId));
            data["client"] = clients.teamId;
            this.setState({
                data,
            });
            this.handleAssignedFlow();
        }
        this.validateProperty(e.target.name, e.target.value);
    }

    async handleAssignedFlow() {
        // console.log("---> " + this.state.data['client']);
        let retailerId = this.state.data['client'];

        let res = await getRetailerAssignedFLow(retailerId);

        // console.log("flows---> " + JSON.stringify(res))

        let Flows = this.state.flows;
        let data = { ...this.state.data };
        let assignedFlows = res.data.data;

        for (let i = 0; i <= assignedFlows.length; i++) {
            const clients = Flows.find((c) => c.flowId === assignedFlows[i]);
            if (clients !== undefined) {
                // console.log("------>bhanthu " + JSON.stringify(clients.flowId));
                data = clients.flowId;
                this.setState({
                    data,
                });

            }
            // console.log("------>result " + JSON.stringify(this.state.data));
        }
    }


    handleCheckBox(e) {
        let params = {
            "flowId": e.target.value,
            "teamId": this.state.data.client
        }
        if (e.target.checked) {
            this.assignAPI(params);
            // console.log("Checkbox is checked.." + JSON.stringify(params));
        } else {
            this.unassignAPI(params);
            // console.log("Checkbox is not checked.." + JSON.stringify(params));
        }
        console.log("Stateeeeeee");
        // console.log(this.state.data);

    }

    async assignAPI(params) {
        let res = await assignRetailerflow(params);
        // console.log("Checkbox " + JSON.stringify(res));
        if (res.data.success === true && res.data.statusCode === 201) {
            toast.success(() => <div>{res.data.msg}</div>);
            // document.getElementById("myform").reset();
            // this.reSetFormData();
        } else if (res.data.success === false && res.data.statusCode === 200) {
            toast.warning(() => <div>{res.data.msg}</div>);
        } else {
            toast.error(() => <div>{res.data.msg}</div>);
        }
    }

    async unassignAPI(params) {
        let res = await unassignRetailerflow(params);
        // console.log("Checkbox " + JSON.stringify(res));
        if (res.data.success === true && res.data.statusCode === 201) {
            toast.success(() => <div>{res.data.msg}</div>);
            // document.getElementById("myform").reset();
            // this.reSetFormData();
        } else if (res.data.success === false && res.data.statusCode === 200) {
            toast.warning(() => <div>{res.data.msg}</div>);
        } else {
            toast.error(() => <div>{res.data.msg}</div>);
        }
    }

    reSetFormData() {
        let data = {
            client: "", flow: ""
        };
        this.setState({ data });
    }

    render() {
        return (
            <div className='col-4'>
                <div className='card'>
                    <div className='card-body'>
                        <form id="myform" onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                {this.rSBF(
                                    "Clients *",
                                    "client",
                                    this.state.clients,
                                    { value: "teamId", label: "name" },
                                    " col-md-12 "
                                )}
                            </div>
                            <div className="form-subHead mt-2 text-center">Flows</div>
                            {this.rCB(
                                "Flows *",
                                "flow",
                                this.state.flows,
                                { value: "flowId", label: "flowName" },
                                "col-md-12"
                            )}
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default RetailerFlows;