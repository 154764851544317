import React from "react";
import FormLib from "./../../../_common/FormLib";
import Joi from "joi-browser";
import Table from "./../../../_common/Table";
import $ from "jquery";

// import Pagination from "../../_common/Pagination";
import { createLifelongTicket } from "../../../../services/Tickets/create/LifelongService";
import LocationTitle from "./../../../_subhead/LocationTitle";
import toast from "./../../../../services/_toastService";

class LifelongTicket extends FormLib {
  state = {
    data: {
      consumerName: "",
      consumerComplaintNumber: "",
      addressLine1: "",
      addressLine2: "",
      emailId: "",
      city: "",
      landmark: "",
      pincode: "",
      telephoneNumber: "",
      natureOfComplaint: "installation",
      isUnderWarranty: "yes",
      invoiceNo: "",
      brand: "LifeLong",
      // productCategory: "Fitness",
      productName: "",
      model: "",
      productDescription: "",
      problemDescription: "probDesc",
      physicalEvaluation: "No",
      levelOfIrritation: "medium",
      // productCode: "",
      orderNumber: "",
      dateOfPurchase: this.getDatebyDays(0),
      // dateOfComplaint: this.getDatebyDays(0),
      identificationNo: "",
    },
    errors: {},
    tickets: [],
    createdTicket: "",
    models: [{ sortKey: "LLF18", sort: "LLF18" }],
  };

  /*
data: {
      consumerName: "",
      consumerComplaintNumber: "",
      addressLine1: "",
      addressLine2: "",
      emailId: "",
      city: "",
      landmark: "",
      pincode: "",
      telephoneNumber: "",
      natureOfComplaint: "",
      isUnderWarranty: "",
      invoiceNo: "",
      brand: "LifeLong",
      productCategory: "Fitness",
      productName: "",
      model: "",
      productDescription: "",
      problemDescription: "",
      physicalEvaluation: "No",
      levelOfIrritation: "medium",
      productCode: "",
      orderNumber: "na",
      dateOfPurchase: "",
      dateOfComplaint: this.getDatebyDays(0),
      identificationNo: "",
    },
  */

  schema = {
    physicalEvaluation: Joi.optional().label("physicalEvaluation"),
    telephoneNumber: Joi.string().required().label("Phone"),

    consumerName: Joi.string().required().label("Customer Name"),
    consumerComplaintNumber: Joi.string().required().label("Complaint Number"),
    addressLine1: Joi.string().required().label("Address-1"),
    addressLine2: Joi.optional().label("Address-2"),
    city: Joi.string().required().label("City"),
    pincode: Joi.string().required().label("Pincode"),
    landmark: Joi.optional().label("Landmark"),

    alternateTelephoneNumber: Joi.string().optional().label("Alt Phone"),
    // emailId: Joi.string().optional().label("Email"),
    // emailID: Joi.string().optional().label("Email"),
    emailId: Joi.optional().label("Email Id"),

    natureOfComplaint: Joi.string().required().label("Nature of Complaint"),
    isUnderWarranty: Joi.string().required().label("Under Warrenty"),
    brand: Joi.string().required().label("Brand"),
    productName: Joi.string().required().label("Product Name"),
    model: Joi.string().required().label("Model"),
    productDescription: Joi.optional().label("Product Description"),
    problemDescription: Joi.string().required().label("Problem Description"),
    dateOfPurchase: Joi.required().label("Date of Purchase"),
    // dateOfComplaint: Joi.required().label("Date of Complaint"),
    levelOfIrritation: Joi.required().label("Level of Escalation"),
    // productCategory: Joi.string().required().label("Product Category"),
    // productCode: Joi.string().required().label("Product Code"),
    orderNumber: Joi.optional().label("Order Number"),
    invoiceNo: Joi.required().label("Invoice Number"),
    identificationNo: Joi.string().required().label("Serial Number"),
  };
  componentDidMount() {
    // $("#dateOfComplaint").attr("disabled", "disabled");
  }

  async doSubmit() {
    let res;
    this.setState({ createdTicket: "" });

    if (this.state.btnValue === "create") {
      res = await createLifelongTicket(
        this.state.data,

      );
    }
    if (res.data.success === true) {
      this.resetData();
      toast.toastSuccess(res.data.msg);
      if (res.data.data.ticketNo) {
        this.setState({ createdTicket: res.data.data.ticketNo });
      }
    } else {
      toast.toastError(res.data.msg);
    }
  }
  resetData() {
    this.setState({
      data: {
        consumerName: "",
        consumerComplaintNumber: "",
        addressLine1: "",
        addressLine2: "",
        emailId: "",
        city: "",
        landmark: "",
        pincode: "",
        telephoneNumber: "",
        natureOfComplaint: "installation",
        isUnderWarranty: "yes",
        invoiceNo: "",
        brand: "LifeLong",
        // productCategory: "Fitness",
        productName: "",
        model: "",
        productDescription: "",
        problemDescription: "",
        physicalEvaluation: "No",
        levelOfIrritation: "medium",
        // productCode: "",
        orderNumber: "",
        dateOfPurchase: "",
        // dateOfComplaint: this.getDatebyDays(0),
        identificationNo: "",
      },
    });
  }

  localHandleChange = (e) => {
    if (e.target.id === "productName") {
      this.onProductNameChange(e.target.value);
    }
    // if (e.target.id === "productCategory") {
    //   this.onProductCatNameChange(e.target.value);
    // }
  };

  onProductCatNameChange = (val) => {
    let product = [];
    if (val === "Fitness") {
      product = [
        { sortKey: "Cycles", sort: " Cycles " },
        { sortKey: "ExerciseBike", sort: "	ExerciseBike	" },
        { sortKey: "TreadMill", sort: "	TreadMill	" },

      ];
    }
    if (val === "HomeAppliances") {
      product = [
        { sortKey: "GasStove", sort: "	GasStove	" },
        { sortKey: "MixerGrinder", sort: "	MixerGrinder	" },
      ];
    }
    this.setState({ product });
  };

  onProductNameChange = (val) => {
    let models = [];

    if (val === "Cycles") {
      models = [
        { sortKey: "CZBC2601", sort: "CZBC2601" },
        { sortKey: "CZBC2602", sort: "CZBC2602" },
        { sortKey: "CZBC2701", sort: "CZBC2701" },
        { sortKey: "CZBC2702", sort: "CZBC2702" },
        { sortKey: "CZBC2703", sort: "CZBC2703" },
        { sortKey: "FHBC1401", sort: "FHBC1401" },
        { sortKey: "FHBC1601", sort: "FHBC1601" },
        { sortKey: "FHBC2001", sort: "FHBC2001" },
        { sortKey: "FHBC2601", sort: "FHBC2601" },
        { sortKey: "FHBC2602", sort: "FHBC2602" },
        { sortKey: "FHBC2701", sort: "FHBC2701" },
        { sortKey: "FHBC2702", sort: "FHBC2702" },
        { sortKey: "LLBC1401", sort: "LLBC1401" },
        { sortKey: "LLBC1601", sort: "LLBC1601" },
        { sortKey: "LLBC1602", sort: "LLBC1602" },
        { sortKey: "LLBC2001", sort: "LLBC2001" },
        { sortKey: "LLBC2002", sort: "LLBC2002" },
        { sortKey: "LLBC2601", sort: "LLBC2601" },
        { sortKey: "LLBC2602", sort: "LLBC2602" },
        { sortKey: "LLBC2603", sort: "LLBC2603" },
        { sortKey: "LLBC2701", sort: "LLBC2701" },
        { sortKey: "LLBC2702", sort: "LLBC2702" },
      ];
    }

    if (val === "ExerciseBike") {
      models = [
        { sortKey: "LLEB101", sort: "LLEB101" },
        { sortKey: "LLEB102", sort: "LLEB102" },
        { sortKey: "LLEB103", sort: "LLEB103" },
        { sortKey: "LLEB104", sort: "LLEB104" },
        { sortKey: "LLF09", sort: "LLF09" },
        { sortKey: "LLF108", sort: "LLF108" },
        { sortKey: "LLF126", sort: "LLF126" },
        { sortKey: "LLF135", sort: "LLF135" },
        { sortKey: "LLF18", sort: "LLF18" },
        { sortKey: "LLF27", sort: "LLF27" },
        { sortKey: "LLF36", sort: "LLF36" },
        { sortKey: "LLF45", sort: "LLF45" },
        { sortKey: "LLF54", sort: "LLF54" },
        { sortKey: "LLF63", sort: "LLF63" },
        { sortKey: "LLFCN09", sort: "LLFCN09" },
        { sortKey: "LLFCN18", sort: "LLFCN18" },
        { sortKey: "LLFCN27", sort: "LLFCN27" },
        { sortKey: "LLFCN36", sort: "LLFCN36" },
      ];
    }

    if (val === "Treadmill") {
      models = [
        { sortKey: "LLTLM126", sort: "LLTLM126" },
        { sortKey: "LLTM09", sort: "LLTM09" },
        { sortKey: "LLTM108", sort: "LLTM108" },
        { sortKey: "LLTM117", sort: "LLTM117" },
        { sortKey: "LLTM135", sort: "LLTM135" },
        { sortKey: "LLTM144", sort: "LLTM144" },
        { sortKey: "LLTM153", sort: "LLTM153" },
        { sortKey: "LLTM162", sort: "LLTM162" },
        { sortKey: "LLTM18", sort: "LLTM18" },
        { sortKey: "LLTM207", sort: "LLTM207" },
        { sortKey: "LLTM27", sort: "LLTM27" },
        { sortKey: "LLTM36", sort: "LLTM36" },
        { sortKey: "LLTM45", sort: "LLTM45" },
        { sortKey: "LLTM54", sort: "LLTM54" },
        { sortKey: "LLTM63", sort: "LLTM63" },
        { sortKey: "LLTM72", sort: "LLTM72" },
        { sortKey: "LLTM81", sort: "LLTM81" },
        { sortKey: "LLTM90", sort: "LLTM90" },
        { sortKey: "LLTM99", sort: "LLTM99" },
      ];
    }

    if (val === "MixerGrinder") {
      models = [
        { sortKey: "LLMG45", sort: "	LLMG45	" },
        { sortKey: "LLCMB02", sort: "	LLCMB02	" },
        { sortKey: "LLMG20", sort: "	LLMG20	" },
        { sortKey: "LLMG95", sort: "	LLMG95	" },
        { sortKey: "LLCMB03", sort: "	LLCMB03	" },
        { sortKey: "LLMG93A", sort: "	LLMG93A	" },
        { sortKey: "LLMG02", sort: "	LLMG02	" },
        { sortKey: "LLMG21", sort: "	LLMG21	" },
        { sortKey: "LLMG750", sort: "	LLMG750	" },
        { sortKey: "LLMG92", sort: "	LLMG92	" },
        { sortKey: "LLMG74", sort: "	LLMG74	" },
        { sortKey: "LLWG01", sort: "	LLWG01	" },
        { sortKey: "LLMG93", sort: "	LLMG93	" },
        { sortKey: "LLMG753", sort: "	LLMG753	" },
        { sortKey: "LLJMG01", sort: "	LLJMG01	" },
        { sortKey: "LLMG755", sort: "	LLMG755	" },
        { sortKey: "POWER PRO", sort: "	POWER PRO	" },
        { sortKey: "LLMG78", sort: "	LLMG78	" },
        { sortKey: "LLMG70", sort: "	LLMG70	" },
        { sortKey: "LLMG66", sort: "	LLMG66	" },
        { sortKey: "LLMG66", sort: "	LLMG66	" },
        { sortKey: "LLMG01", sort: "	LLMG01	" },
        { sortKey: "LLMG109", sort: "	LLMG109	" },
        { sortKey: "LLMG79", sort: "	LLMG79	" },
        { sortKey: "LLMG10", sort: "	LLMG10	" },
        { sortKey: "NG01-P", sort: "	NG01-P	" },
        { sortKey: "POWER PRO- CR", sort: "	POWER PRO- CR	" },
        { sortKey: "LLMG320", sort: "	LLMG320	" },
        { sortKey: "LLMG310", sort: "	LLMG310	" },
        { sortKey: "LLMG300", sort: "	LLMG300	" },
        { sortKey: "LLMG220", sort: "	LLMG220	" },
        { sortKey: "LLMG210", sort: "	LLMG210	" },
        { sortKey: "LLMG200", sort: "	LLMG200	" },
        { sortKey: "LLMG91", sort: "	LLMG91	" },
        { sortKey: "LLMG100", sort: "	LLMG100	" },
        { sortKey: "LLMG90", sort: "	LLMG90	" },
        { sortKey: "LLMG72", sort: "	LLMG72	" },
        { sortKey: "LLMG63", sort: "	LLMG63	" },
        { sortKey: "LLMG111", sort: "	LLMG111	" },
        { sortKey: "LLMG12", sort: "	LLMG12	" },
        { sortKey: "LLJMG02", sort: "	LLJMG02	" },
        { sortKey: "LLMG108", sort: "	LLMG108	" },
        { sortKey: "LLMG94", sort: "	LLMG94	" },
        { sortKey: "LLMG759", sort: "	LLMG759	" },
        { sortKey: "LLEC921", sort: "	LLEC921	" },
        { sortKey: "LLMG23", sort: "	LLMG23	" },
        // { sortKey: "LLCMB02", sort: "	LLCMB02	" },
        { sortKey: "LLMG19", sort: "	LLMG19	" },
        { sortKey: "LLMG89", sort: "	LLMG89	" },
        { sortKey: "LX350", sort: "	LX350	" },
      ];
    }
    if (val === "GasStove") {
      models = [
        { sortKey: "LLGS83", sort: "	LLGS83	" },
        { sortKey: "LLGS82", sort: "	LLGS82	" },
        { sortKey: "LLGS18", sort: "	LLGS18	" },
        { sortKey: "LLGS27", sort: "	LLGS27	" },
        { sortKey: "LLGS23", sort: "	LLGS23	" },
        { sortKey: "LLGS303", sort: "	LLGS303	" },
        { sortKey: "LLGS118", sort: "	LLGS118	" },
        { sortKey: "LLGS902", sort: "	LLGS902	" },
        { sortKey: "LLGS912", sort: "	LLGS912	" },
        { sortKey: "LLGS311", sort: "	LLGS311	" },
        { sortKey: "LLGS10", sort: "	LLGS10	" },
        { sortKey: "LLGS299", sort: "	LLGS299	" },
        { sortKey: "LLGS931", sort: "	LLGS931	" },
        { sortKey: "GSTGADNH3ZT2YNBP", sort: "	GSTGADNH3ZT2YNBP	" },
        { sortKey: "LLGS93", sort: "	LLGS93	" },
        { sortKey: "LLGS900", sort: "	LLGS900	" },
        { sortKey: "LLGS50", sort: "	LLGS50	" },
        { sortKey: "LLGS699", sort: "	LLGS699	" },
        { sortKey: "LLGS1000", sort: "	LLGS1000	" },
        { sortKey: "LLGS930", sort: "	LLGS930	" },
        { sortKey: "LLGS399", sort: "	LLGS399	" },
        { sortKey: "LLGS909", sort: "	LLGS909	" },
        { sortKey: "LLGS24", sort: "	LLGS24	" },
        { sortKey: "LLCMBGS01", sort: "	LLCMBGS01	" },
        { sortKey: "LLGS60", sort: "	LLGS60	" },
        { sortKey: "LLGS92", sort: "	LLGS92	" },
        { sortKey: "LLGS108", sort: "	LLGS108	" },
        { sortKey: "LLGS45", sort: "	LLGS45	" },
        { sortKey: "LLGS919", sort: "	LLGS919	" },
        { sortKey: "LLGS301", sort: "	LLGS301	" },
        { sortKey: "LLGS944", sort: "	LLGS944	" },
        { sortKey: "LLGS411", sort: "	LLGS411	" },
        { sortKey: "LLGS44", sort: "	LLGS44	" },
        { sortKey: "LLGS99", sort: "	LLGS99	" },
        { sortKey: "LLGS211", sort: "	LLGS211	" },
        { sortKey: "LLGS09", sort: "	LLGS09	" },
        { sortKey: "LLGS199", sort: "	LLGS199	" },
        { sortKey: "LLGS201", sort: "	LLGS201	" },
        { sortKey: "LLGS30", sort: "	LLGS30	" },
      ];
    }

    this.setState({ models });
  };
  // productCategory = [
  //   { sortKey: "Fitness", sort: "Fitness" },
  //   { sortKey: "HomeAppliances", sort: "Home Appliances" },
  // ];
  // productCategoryConf = {
  //   value: "sortKey",
  //   label: "sort",
  // };
  productName = [
    { sortKey: "Cycles", sort: "Cycles" },
    { sortKey: "ExerciseBike", sort: "ExerciseBike" },
    { sortKey: "Treadmill", sort: "Treadmill" },
    { sortKey: "GasStove", sort: "	GasStove	" },
    { sortKey: "MixerGrinder", sort: "	MixerGrinder	" },
  ];
  // productCode = [
  //   { sortKey: "MIX", sort: "MIX" },
  //   { sortKey: "FIT", sort: "FIT" },
  //   { sortKey: "GST", sort: "GST" },
  //   { sortKey: "CYC", sort: "	CYC	" },
  // ];




  productNameConf = {
    value: "sortKey",
    label: "sort",
  };
  warranty = [
    { sortKey: "yes", sort: "Yes" },
    { sortKey: "no", sort: "No" },
  ];
  warrantyConf = {
    value: "sortKey",
    label: "sort",
  };
  natureOfComplaint = [
    { sortKey: "installation", sort: "Installation" },
    { sortKey: "RepairFlow", sort: "Service" },
  ];
  natureOfComplaintConf = {
    value: "sortKey",
    label: "sort",
  };

  escal = [
    { sortKey: "low", sort: "Low" },
    { sortKey: "medium", sort: "Medium" },
    { sortKey: "high", sort: "High" },
    // { sortKey: "critical", sort: "Critical" },
  ];
  escalConf = {
    value: "sortKey",
    label: "sort",
  };

  render() {
    // const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Lifelong Ticket" />
        <div className="pageContent">
          <form>
            <div className="row">
              <br />

              <div className="card col-md-7">
                <br />
                <div className="form-row">
                  {this.rRBL(
                    "Nature Of Complaint",
                    "natureOfComplaint",
                    this.natureOfComplaint,
                    this.natureOfComplaintConf,
                    "col-4"
                  )}
                  <div className="col-2">
                    {this.rSB(
                      "Level of Escalation",
                      "levelOfIrritation",
                      this.escal,
                      {
                        value: "sortKey",
                        label: "sort",
                      }
                    )}
                  </div>


                  {this.rTB(
                    "Complaint Number",
                    "consumerComplaintNumber",
                    "col-3"
                  )}

                  {/* {this.rTBH("Date Of Complaint", "dateOfComplaint", "col-3")} */}
                  {/* {this.rTBH("Product Code", "productCode", "col-3")} */}
                  {/* {this.rTB("Product Code", "productCode", "col-3")} */}
                  {/* <div className="col-3">
                    {this.rSB("Product Code", "productCode", this.productCode, {
                      value: "sortKey",
                      label: "sort",
                    })}
                  </div> */}
                  {this.rTBH("Order Number", "orderNumber", "col-2")}
                  {this.rTBD("Brand", "brand", "col-3")}

                  {/* <div className="col-3">
                    {this.rSB(
                      "Product Category",
                      "productCategory",
                      this.productCategory,
                      {
                        value: "sortKey",
                        label: "sort",
                      }
                    )}
                  </div> */}

                  <div className="col-3">
                    {this.rSB("Product Name", "productName", this.productName, {
                      value: "sortKey",
                      label: "sort",
                    })}
                  </div>

                  {/* {this.rTB("Model", "model", "col-3")} */}
                  <div className="col-3">
                    {this.rSB("Model", "model", this.state.models, {
                      value: "sortKey",
                      label: "sort",
                    })}
                  </div>

                  {this.rTB("Invoice No", "invoiceNo", "col-2")}
                  {this.rDP("Date of purchase", "dateOfPurchase", "col-3")}
                  {this.rTB("Serial No", "identificationNo", "col-3")}
                  {this.rRBL(
                    "Is Under Warranty",
                    "isUnderWarranty",
                    this.warranty,
                    this.warrantyConf,
                    "col-2"
                  )}
                </div>
                <div className="form-row">
                  {this.rTBH(
                    "Product Description",
                    "productDescription",
                    "col-6"
                  )}
                  {this.rTA(
                    "Problem Description",
                    "problemDescription",
                    "col-6"
                  )}
                </div>
              </div>

              <div className="col-md-5">
                <div className="form-row">
                  {this.rTB("Customer Name", "consumerName", "col-12")}

                  {this.rTB("Phone", "telephoneNumber", "col-3")}
                  {this.rTB("Alt Phone", "alternateTelephoneNumber", "col-3")}
                  {this.rTB("Email Id", "emailId", "col-6")}
                </div>
                <div className="form-row">
                  {this.rTA("Address-1", "addressLine1", "col-6")}
                  {this.rTA("Address-2", "addressLine2", "col-6")}

                  {this.rTB("Landmark", "landmark", "col-4")}
                  {this.rTB("City", "city", "col-4")}
                  {this.rTB("Pincode", "pincode", "col-2")}
                </div>
              </div>
            </div>

            <hr />
          </form>
          {this.rBtnSP("Create", "create")}
          {this.state.createdTicket && (
            <span class="badge badge-secondary">
              New Ticket No.: {this.state.createdTicket}
            </span>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default LifelongTicket;
