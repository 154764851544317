import _http from "./../_httpService";

import config from "./../../config.json";

const baseURL = config[config.build].apiURLValidata;

function api(path, subURL = "/4team/modules") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function createModule(req) {
  const param = { ...req };
  delete param.moduleId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveModule(req) {
  const param = { ...req };
  const id = param.moduleId;
  delete param.moduleId;
  param.seq = 1;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteModule(param) {
  return _http.delete(api(param.moduleId), _http.headerJWT());
}

export function getModules(projectId) {
  return _http.get(api("by-project/" + projectId), _http.headerJWT());
  // return _http.get(api(""), _http.headerJWT());
}
