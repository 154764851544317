import React from "react";
import ZTitle from "../../_subhead/ZTitle";
import FormLib from "../../_common/FormLib";
import Table from "../../_common/Table";
import dateFormat from "dateformat";

import Joi from "joi-browser";
import {
  getExcelPayments,
  paymentsDownloded,
} from "./../../../services/requestV1/requestServices";
import { ExportXLSX } from "./ExportXLSX";

class ExcelDownload extends FormLib {
  subHeading = "User";
  state = {
    data: {
      fromDate: dateFormat(this.getDatebyDays(0), "yyyy-mm-dd"),
      toDate: dateFormat(this.getDatebyDays(0), "yyyy-mm-dd"),
    },
    errors: {},
    csvReport: {
      data: [],
      headers: [],
    },
    fileName: "",
  };
  schema = {
    fromDate: Joi.string().required().label("From Date"),
    toDate: Joi.string().required().label("To Date"),
  };

  localHandleChange = (e) => {
    const csvReportDefault = {
      data: [],
      headers: [],
      filename: "financial_report.xlsx",
    };

    this.setState({ csvReport: csvReportDefault });
  };
  handleDownloadClicked = async (items) => {
    console.log("submit export");
    let resp;
    resp = await paymentsDownloded(items);
    // console.log(resp);
  };

  async doSubmit() {
    let apiData = {};
    const { csvReport } = { ...this.state };
    apiData = await getExcelPayments(this.state.data);

    csvReport.filename = "financial_report.xlsx";
    csvReport.headers = this.excelHeaders;

    let transData = [];
    // transData = apiData.data.data.transactions;
    this.setState({ csvReport });

    if (apiData.data["data"]) {
      const promises = apiData.data.data.transactions.filter(async (t) => {
        t.DEBIT_NARR = t.DEBIT_NARR.substring(16, 33).trim();
        t.CREDIT_NARR = t.CREDIT_NARR.substring(16, 33).trim();
        if (t.BENE_IFSC !== null && t.BENE_IFSC.substring(0, 4) === "ICIC") {
          t.BENE_IFSC = "ICIC0000011";
        }

        return t;
      });

      transData = await Promise.all(promises);
      csvReport.data = transData;
      this.setState({ csvReport });
    } else {
      csvReport.data = [];
      this.setState({ csvReport });
    }
  }

  excelHeaders = [
    { label: "PYMT_PROD_TYPE_CODE", key: "PYMT_PROD_TYPE_CODE" },
    { label: "PYMT_MODE", key: "PYMT_MODE" },
    { label: "DEBIT_ACC_NO", key: "DEBIT_ACC_NO" },
    { label: "BNF_NAME", key: "BNF_NAME" },
    { label: "BENE_ACC_NO", key: "BENE_ACC_NO" },
    { label: "BENE_IFSC", key: "BENE_IFSC" },
    { label: "AMOUNT", key: "AMOUNT" },
    { label: "DEBIT_NARR", key: "DEBIT_NARR" },
    { label: "CREDIT_NARR", key: "CREDIT_NARR" },
    { label: "MOBILE_NUM", key: "MOBILE_NUM" },
    { label: "EMAIL_ID", key: "EMAIL_ID" },
    { label: "REMARK", key: "REMARK" },
    { label: "PYMT_DATE", key: "PYMT_DATE" },
    { label: "REF_NO", key: "REF_NO" },
    { label: "ADDL_INFO1", key: "ADDL_INFO1" },
    { label: "ADDL_INFO2", key: "ADDL_INFO2" },
    { label: "ADDL_INFO3", key: "ADDL_INFO3" },
    { label: "ADDL_INFO4", key: "ADDL_INFO4" },
    { label: "ADDL INFO5", key: "ADDL_INFO5" },
  ];

  tableMeta = {
    key: "REF_NO",
    columns: [
      { path: "PYMT_PROD_TYPE_CODE", label: "PYMT_PROD_TYPE_CODE" },
      { path: "PYMT_MODE", label: "PYMT_MODE" },
      { path: "DEBIT_ACC_NO", label: "DEBIT_ACC_NO" },
      { path: "BNF_NAME", label: "BNF_NAME" },
      { path: "BENE_ACC_NO", label: "BENE_ACC_NO" },
      { path: "BENE_IFSC", label: "BENE_IFSC" },
      { path: "AMOUNT", label: "AMOUNT" },
      {
        key: "DEBIT_NARR",
        action: (r) => r["DEBIT_NARR"],
        label: "DEBIT_NARR",
        icon: () => <>DEBIT_NARR </>,
      },
      {
        key: "CREDIT_NARR",
        action: (r) => r["CREDIT_NARR"],
        label: "CREDIT_NARR",
        icon: () => <>CREDIT_NARR </>,
      },

      { path: "MOBILE_NUM", label: "MOBILE_NUM" },
      { path: "EMAIL_ID", label: "EMAIL_ID" },
      { path: "REMARK", label: "REMARK" },
      { path: "PYMT_DATE", label: "PYMT_DATE" },
      { path: "REF_NO", label: "REF_NO" },
      { path: "ADDL_INFO1", label: "ADDL_INFO1" },
      { path: "ADDL_INFO2", label: "ADDL_INFO2" },
      { path: "ADDL_INFO3", label: "ADDL_INFO3" },
      { path: "ADDL_INFO4", label: "ADDL_INFO4" },
      { path: "ADDL_INFO5", label: "ADDL_INFO5" },
    ],
  };

  onEmpTextChanged = (e) => {
    const value = e.target.value;
    this.setState(() => ({
      fileName: value,
    }));
  };

  render() {
    return (
      <React.Fragment>
        <ZTitle subHeading="Expenses :: Excel Download" />
        <div className="pageContent">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-row">
                    {this.rDP("From Date", "fromDate", "col-md-3")}
                    {this.rDP("To Date", "toDate", "col-md-3")}
                    <div className="col-md-4">
                      {this.rBtnS(
                        "Submit",
                        "submit",
                        "btn  btn-primary  btn-sm"
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {this.state.csvReport.data.length > 0 && (
              <div>
                <div style={{ overflow: "auto" }}>
                  <Table
                    clsName="table table-striped table-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.csvReport.data}
                    dropMenu={[]}
                  ></Table>
                </div>
                <br />
                <div className="col-3 form-group">
                  <input
                    value={this.state.fileName}
                    name="fileName"
                    onChange={this.onEmpTextChanged}
                    type="text"
                    autoComplete="off"
                  />
                  <label htmlFor="fileName" className="control-label">
                    FileName *
                  </label>
                  <i className="bar"></i>
                </div>
                <ExportXLSX
                  csvData={this.state.csvReport.data}
                  fileName={this.state.fileName + "-" + this.getDatebyDays(0)}
                />
              </div>
            )}
            <br />
            {this.state.csvReport.data.length === 0 && (
              <div className="alert alert-info" role="alert">
                <div className="text-center"> No Records Found! </div>
              </div>
            )}
            {/* {this.state.csvReport.data} */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ExcelDownload;
