import * as app from "../appReducer/appReducerActions";
import * as act from "./ticketProcessTypes";

const TICKET_LIST_RELOAD_CNT = "TICKET_LIST_RELOAD";

export const ticketListReload = (cnt) => {
  return async (dispatch) => {
    dispatch({
      type: act.TICKET_LIST_RELOAD,
      payload: { ticketListReloadCnt: cnt },
    });
  };
};
