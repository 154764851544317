import React, { Component } from "react";
import FormLib from "../../../_common/FormLib";
import LocationTitle from "../../../_subhead/LocationTitle";
import Joi from "joi-browser";
import Table from "../../../_common/Table";
import {
  getActiveClients,
  getAssignedProduct,
  getAssignedProductV1,
  getUsers,
  revertAssignedProduct,
} from "../../../../services/masterLocations/clientService";
import { toast } from "react-toastify";
import Pagination from "../../../_common/Pagination";

class RevertingBarcodesToAvailable extends FormLib {
  state = {
    data: {
      hubId: "5067",
      userId: "",
      clientId: "",
    },
    hubs: [],
    usersList: [],
    clients: [],
    errors: {},
    mode: "",
    userAssignedBarcode: [],
    slNoId: "",
    searchText: "",
    filteredBarcodeData: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };

  componentDidMount() {
    this.loadHubs();
    this.loadClients();
    this.loadUsers();
  }

  schema = {
    hubId: Joi.string().required().label("Hubs *"),
    clientId: Joi.string().required().label("Clients *"),
    userId: Joi.string().required().label("User *"),
  };

  async loadClients() {
    try {
      this.setState({ loading: true });
      const res = await getActiveClients("active");

      if (res.data.success === true) {
        this.setState({ clients: res.data.data.clients });
      } else {
        toast.toastError(res.data.msg);
      }
    } catch (error) {
      return error.msg;
    }
  }

  async loadHubs() {
    try {
      this.setState({ loading: true });
      let hubList = JSON.parse(localStorage.getItem("hubs"));

      if (hubList.length > 0) {
        this.setState({ hubs: hubList });
      } else {
        return "No Hubs Found";
      }
    } catch {
      console.log("error in loading hubs");
    }
    this.setState({ loading: false });
  }

  async loadUsers() {
    try {
      let hubId = this.state.data.hubId;
      const res = await getUsers(hubId);

      if (res.data.success === true) {
        this.setState({ usersList: res.data.data.playersHubs });
      } else {
        toast.toastError(res.data.msg);
      }
    } catch (error) {
      return "failed to fetch users data";
    }
  }

  localHandleChange = (e) => {
    if (e.target.id === "hubId") {
      this.setState({ hubId: e.target.value }, () => {
        this.loadUsers();
      });
    }
  };

  async doSubmit() {
    const userAssignedProduct = await getAssignedProductV1(
      this.state.data,
      this.state.pageLinks.pageNo
    );
    if (userAssignedProduct.data.success === true) {
      this.setState({
        userAssignedBarcode:
          userAssignedProduct.data.data.assignedProductDetails,
      });

      this.setState({
        filteredBarcodeData:
          userAssignedProduct.data.data.assignedProductDetails,
      });

      this.setState({ mode: "submit" });
      toast.success(() => <div>{userAssignedProduct.data.msg}</div>);
    } else {
      this.setState({ loading: false });
      this.setState({ filteredBarcodeData: [] });
      this.setState({
        pageLinks: {
          pageNo: 1,
          perPage: 0,
          itemsCount: 0,
          lastPage: 0,
        },
      });
      // toast.error(userAssignedProduct.data.msg);
    }
    this.setState({ loading: true });
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/revertingBarcodesFromUsers/" + pageNo);
    let pageLinks = this.state.pageLinks;
    pageLinks["pageNo"] = pageNo;
    this.setState({ pageLinks });
    this.doSubmit();
  };
  tableMeta = {
    key: "itemId",
    columns: [
      {
        key: "itemId",
        action: (r) => <div className="btn-group pull-right"></div>,
        icon: () => <i className="fa fa-table " aria-hidden="true" />,
      },
      {
        key: "sl_No2",
        action: (r) => (
          <>
            <span>{r.slNo}</span>
          </>
        ),
        cusClass: " ",
        icon: () => (
          <div>
            <p>Barcode</p>
            <input
              type="text"
              name="searchTicket"
              id="serachTicket"
              placeholder="search"
              value={this.state.searchText}
              onChange={(e) => {
                this.setState({
                  searchText: e.target.value,
                });

                const filteredBarcodeData =
                  this.state.userAssignedBarcode?.filter((item) => {
                    const slNo = item.slNo;
                    return slNo.includes(e.target.value);
                  });
                this.setState({ filteredBarcodeData });
              }}
            />
          </div>
        ),
      },
      { path: "item", label: "Item" },
      { path: "FIRSTNAME", label: "User" },
      {
        key: "playerId",
        action: (r) => (
          <>
            <button
              className="btn btn-success btn-sm"
              type="button"
              onClick={() => this.revertBarcode(r)}
            >
              Revert
            </button>
          </>
        ),
        icon: () => <>Revert</>,
      },
    ],
  };

  revertBarcode = async (data) => {
    // console.log({ data });
    try {
      let params = {
        clientId: this.state.data.clientId,
        hubId: this.state.data.hubId,
        userId: this.state.data.userId,
        slNoId: data.slNoId,
      };
      const res = await revertAssignedProduct(params);
      // console.log({ res });

      if (res.data.success === true) {
        toast.success(() => <div>{res.data.msg}</div>);
        this.doSubmit();
      } else {
        toast.error(() => <div>{res.data.msg}</div>);
      }
    } catch (error) {
      return "failed to assign users data";
    }
  };

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    const returnData = this.state.filteredBarcodeData;

    return (
      <React.Fragment>
        <LocationTitle subHeading="Return barcode Items :" />
        <form onSubmit={this.handleSubmit}>
          <div className="pageContent">
            <div className="form-row">
              <div className="col-3">
                {this.rSB("Clients *", "clientId", this.state.clients, {
                  value: "TEAM_ID",
                  label: "NAME",
                })}
              </div>
              <div className="col-3">
                {this.rSB("Hubs *", "hubId", this.state.hubs, {
                  value: "hubId",
                  label: "hub",
                })}
              </div>

              <div className="col-3">
                {this.rSB("Users *", "userId", this.state.usersList, {
                  value: "playerId",
                  label: "firstName",
                })}
              </div>
              <div style={{ paddingLeft: "1rem" }}>
                {this.rBtnSP("Submit", "submit")}
              </div>
            </div>
          </div>
        </form>

        <div style={{ maxWidth: "50rem", paddingLeft: "2rem" }}>
          <div>
            {this.state.mode === "submit" &&
              this.state.userAssignedBarcode.length !== 0 && (
                <div>
                  {" "}
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={returnData}
                    dropMenu={[]}
                  />
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              )}
          </div>
          <div>
            {this.state.filteredBarcodeData.length === 0 &&
              this.state.mode === "submit" && (
                <div className="jumbotron jumbotron-fluid">
                  <p className="lead text-center">No barcodes Found</p>
                </div>
              )}
              </div>
        </div>
      </React.Fragment>
    );
  }
}
export default RevertingBarcodesToAvailable;
