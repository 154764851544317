import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLMasterLoc;
function api(path, subURL = "/states") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getStates() {
  return _http.get(api("details"), _http.headerJWT());
}

export function getActiveStates() {
  return _http.get(api("active"), _http.headerJWT());
}

export function createStates(req) {
  const param = { ...req };
  delete param.stateId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveStates(req) {
  const param = { ...req };
  const id = param.stateId;
  //delete param.cityId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteStates(req) {
  const param = { ...req };
  return _http.delete(api(param.stateId), _http.headerJWT());
}
