import React from "react";
import FormLib from "./../../_common/FormLib";
import BarcodeProduct from "./BarcodeProduct";
import Table from "./../../_common/Table";
import BarcodeTicket from "./BarcodeTicket";
import BarcodeBlock from "./BarcodeBlock";
import Joi from "joi-browser";
import {
  getBarcodesList,
  setTicketActions,
} from "./../../../services/Tickets/TicketProcessServices";
import Pagination from "./../../_common/Pagination";
import { toast } from "react-toastify";
import { getPackingMaterials } from "./../../../services/packing/PackingMaterialService";
import { ticketListReload } from "./../../../store";
import { connect } from "react-redux";
import { getHubsAll } from "../../../services/masterLocations/hubServices";
import { IstToUTC } from "../../../utils/DateUtils";
import { IoBarcodeOutline, IoCopyOutline } from "react-icons/io5";
import BarCodePrintSVG from "./BarCodePrintSVG";
import { setTicketActionsTicketCancel } from "./../../../services/Tickets/TicketProcessServices";
import swal from "sweetalert";
import {
  getItemListByStoreBays,
  getItemStockListByHubId,
  getStockByItem,
} from "./../../../services/stockManagement/inwardInventoryServices";
import InventoryBlock from "./InventoryBlock";
// import { bayData } from "./data"
class PackingAssignment extends FormLib {
  state = {
    data: { type: "all", materialType: "all", status: "00available" },
    errors: {},
    type: [
      {
        typeId: "Cover",
        type: "Cover",
      },
      {
        typeId: "Box",
        type: "Box",
      },
      {
        typeId: "Pouch",
        type: "Pouch",
      },
      {
        typeId: "Wrap",
        type: "Wrap",
      },
      {
        typeId: "Tape",
        type: "Tape",
      },
      {
        typeId: "Others",
        type: "Others",
      },
    ],
    status: [
      {
        statusId: "00available",
        status: "Available",
      },
      {
        statusId: "10issued",
        status: "Issued",
      },
      {
        statusId: "20used",
        status: "Used",
      },
      {
        statusId: "30received",
        status: "Received",
      },
      {
        statusId: "40damaged",
        status: "Damaged",
      },
      {
        statusId: "50scraped",
        status: "Scraped",
      },
    ],
    packingType: [],
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    barcodeDetails: [],
    pageLinks: {
      pageNo: 1,
      perPage: 8,
      itemsCount: 0,
      lastPage: 0,
    },
    pageHide: "false",
    packStatus: "false",
    // pageCurrent: 1,
    // pageSize: 10,
    printBarcode: "",
    cancelStatus: "false",
    storeBays: [],
    itemsStockList: [],
    itemsStockListInfo: [],
    storeBaysId: "",
    qnt: "",
    selectedStockItems: [],
  };
  includeCancelTicket = [
    { sortKey: "yes", sort: "Do you want to cancel Ticket??" },
  ];
  includeInventory = [{ sortKey: "yes", sort: "Need Inventory??" }];
  schema = {
    type: Joi.string().required().label("Type"),
    materialType: Joi.string().required().label("Packing Material Type"),
    status: Joi.string().required().label("Status"),
    includeCancelTicket: Joi.string().optional().label("includeCancelTicket"),
    includeInventory: Joi.string().optional().label("includeInventory"),
  };

  componentDidMount() {
    try {
      this.getlocalDateTime();
      this.loadHubs();
      this.loadPackType();
      this.loadstoreBays();
      this.loadInit();
      let pageHide = this.props.hideAction;
      this.setState({ pageHide });
      this.setState({ curStatus: this.props.curStatus });
      let hoForm = localStorage.getItem("curHub");
      if (hoForm === "1lUeT6ivx3d1DXPKW74SdKyLQrM=") {
        this.setState({ hideForm: "true" });
      } else {
        this.setState({ hideForm: "false" });
      }
    } catch {
      console.log("Error in PackingAssignment>>>>>>componentDidMount");
    }
    // this.setState({ selectedStockItems: [] })
  }

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }

  handleCancelTicket = async () => {
    swal({
      title: "Are you sure want to Cancel the Ticket?",
      text: "Kindly Enter the Reason for Ticket Cancellation",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: "input",
      placeholder: "Write something",
    }).then(async (handleCancelTicket) => {
      this.doCancelTicket(handleCancelTicket);
    });
  };

  doCancelTicket = async (handleCancelTicket) => {
    if (handleCancelTicket) {
      // alert(handleRTO)
      swal(
        "Ticket has been successfully Cancelled with the following remarks: " +
          handleCancelTicket,
        {
          icon: "success",
        }
      );
      this.submitCancelTicket(handleCancelTicket);
    } else {
      swal("Ticket not cancelled!", { icon: "error" });
    }
  };

  submitCancelTicket = async (handleCancelTicket) => {
    let resultSet = {
      remarks: handleCancelTicket,
      actionStatus: "OPS_CANCALLATION",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    let data = this.state.data;
    data = {
      actionCode: "wOPSCancelled",
      action: "Ticket Cancelled",
    };
    this.setState({ data });

    let resp = await setTicketActionsTicketCancel(
      this.state.data,
      this.state.curTicketDetails,
      this.state.curBlockDetails,
      resultSet
    );
    if (resp !== undefined) {
      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ cancelStatus: "true" });
          // this.setState({ rtoStatus: "false" });
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
  };

  loadHubs = async () => {
    try {
      let list = await getHubsAll();
      if (list !== undefined) {
        this.setState({ hubs: list.data.data.hubs });
      }
    } catch {
      console.log("Error in PackingAssignment>>>>>>loadHubs");
    }
  };

  loadPackType = async () => {
    try {
      let res = await getPackingMaterials();
      if (res.data.data) {
        this.setState({ packingType: res.data.data.packingMaterials });
      }
    } catch {
      console.log("Error in PacckingAssignment>>>>>>>loadPackType");
    }
  };
  loadstoreBays = async () => {
    try {
      let curHub = localStorage.getItem("curHub");
      let res = await getItemStockListByHubId(curHub);
      if (res.data.data) {
        this.setState({ storeBays: res.data.data.storeBaysId });
      }
    } catch {
      console.log("Error in PacckingAssignment>>>>>>>loadstoreBays");
    }
  };

  loadItems = async (storeBaysId) => {
    try {
      let curRetailer = this.props.ticketDetails.retailerId;
      let res = await getItemListByStoreBays(curRetailer, storeBaysId);
      if (res.data.data) {
        this.setState({ itemsStockList: res.data.data.itemsList });
      }
    } catch {
      console.log("Error in PacckingAssignment>>>>>>>loadStores");
    }
  };

  loadItemsStock = async (storeBaysId, itemId) => {
    try {
      let res = await getStockByItem(storeBaysId, itemId);
      if (res.data.data) {
        this.setState({ itemsStockListInfo: res.data.data.itemsStockList });
      }
    } catch {
      console.log("Error in PacckingAssignment>>>>>>>loadStores");
    }
  };

  loadInit() {
    try {
      const curTicketDetails = this.props.ticketDetails;
      const curBlockDetails = this.props.blockDetails;
      const curProductDetails = this.props.ticketDetails.products;
      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails });
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.setState({ designation: userInfo.designation });
    } catch {
      console.log("Error in PackingAssignment>>>>>>loadInit");
    }
  }
  onBarcodeChange(text) {
    try {
      // if (navigator.clipboard) {

      navigator["clipboard"].writeText(text);
      // }
      // else {
      //   toast.warn("Barcode not copied");
      // }
    } catch {
      console.log("Error in PackingAssignment>>>>>>onBarcodeChange");
    }
  }
  onPrintBarcode(barcode) {
    try {
      this.setState({ printBarcode: barcode });
    } catch {
      console.log("Error in PackingAssignment>>>>>>onPrintBarcode");
    }
  }

  tableMeta = {
    key: "barCode",
    columns: [
      {
        key: "barCode",
        action: (r) => (
          <>
            <button
              type="button"
              className="btn btn-sm btn-white ml-5"
              onClick={() => this.onBarcodeChange(r.barCode)}
              title="Copy Barcode"
            >
              <i
                className={"fa fa-clone"}
                style={{ fontSize: "15px", color: "black", fontWeight: "bold" }}
              ></i>
            </button>

            <a>{r.barCode}</a>
            <button
              className="border-0 fs-5 ms-3 bg-transparent"
              title="Print Barcode"
              onClick={() => this.onPrintBarcode(r.barCode)}
            >
              <IoBarcodeOutline />
            </button>
          </>
        ),
        icon: () => (
          <>
            <div className="form-row">
              {this.rSB(
                "",
                "materialType",
                this.state.packingType,
                { value: "packMaterialId", label: "name" },
                " col-12 "
              )}
            </div>
          </>
        ),
        label: "Action",
      },
    ],
  };

  tableMetaInventory = {
    key: "itemId",
    columns: [
      {
        key: "itemId",
        actionKey: "bt",
        action: (r) => (
          <>
            <span
              className="badge badge-primary link-over"
              onClick={() => this.onBlockSelection(r)}
            >
              Add
            </span>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },

      {
        key: "itemId",
        actionKey: "sb",
        action: (r) => (
          <>
            {r.isSerial === "no" && (
              <>{this.rNBm("Quantity ", "qnt_" + r["itemId"], "col-5")}</>
            )}
            {r.isSerial === "yes" && (
              <>
                <>{this.rTB("Serial", "serial_" + r["itemId"], "col-6")} </>
              </>
            )}
          </>
        ),
        label: "Quantity ",
        icon: () => <>Quantity / Serial</>,
        cusClass: " ",
      },
      { path: "stock", label: "Stock" },
      { path: "storeBaysId", label: "storeBaysId" },
    ],
  };

  onBlockSelection = async (r) => {
    if (!this.state.data["qnt_" + r["itemId"]]) {
      this.state.data["qnt_" + r["itemId"]] = "1";
    }
    if (r.isSerial === "yes" && this.state.data["serial_" + r["itemId"]]) {
      this.state.data["qnt_" + r["itemId"]] = "1";
      const filteredInvList = this.state.selectedStockItems.filter((inv) => {
        return (
          inv.itemId === r.itemId &&
          inv.storeBaysId === r.storeBaysId &&
          inv.serial === this.state.data["serial_" + r["itemId"]]
        );
      });
      if (filteredInvList.length === 1) {
        const invListNew = this.state.selectedStockItems.map((inv) => {
          return inv.itemId === r.itemId &&
            inv.storeBaysId === r.storeBaysId &&
            inv.serial === this.state.data["serial_" + r["itemId"]]
            ? {
                itemId: r.itemId,
                item: r.item,
                storeBaysId: r.storeBaysId,
                qnt: this.state.data["qnt_" + r["itemId"]],
                serial: this.state.data["serial_" + r["itemId"]],
                validation: {
                  isReq: "yes",
                },
              }
            : inv;
        });
        this.setState({ selectedStockItems: invListNew });
      } else {
        let stockItem = {
          itemId: r.itemId,
          item: r.item,
          storeBaysId: r.storeBaysId,
          qnt: this.state.data["qnt_" + r["itemId"]],
          serial: this.state.data["serial_" + r["itemId"]],
          validation: {
            isReq: "yes",
          },
        };
        this.state.selectedStockItems.push(stockItem);
        this.setState({ selectedStockItems: this.state.selectedStockItems });
      }
    } else if (r.isSerial === "no") {
      this.state.data["serial_" + r["itemId"]] = "";
      const filteredInvList = this.state.selectedStockItems.filter((inv) => {
        return (
          inv.itemId === r.itemId &&
          inv.storeBaysId === r.storeBaysId &&
          inv.serial === this.state.data["serial_" + r["itemId"]]
        );
      });
      if (filteredInvList.length === 1) {
        const invListNew = this.state.selectedStockItems.map((inv) => {
          return inv.itemId === r.itemId &&
            inv.storeBaysId === r.storeBaysId &&
            inv.serial === this.state.data["serial_" + r["itemId"]]
            ? {
                itemId: r.itemId,
                item: r.item,
                storeBaysId: r.storeBaysId,
                qnt: this.state.data["qnt_" + r["itemId"]],
                serial: this.state.data["serial_" + r["itemId"]],
                validation: {
                  isReq: "yes",
                },
              }
            : inv;
        });
        this.setState({ selectedStockItems: invListNew });
      } else {
        let stockItem = {
          itemId: r.itemId,
          item: r.item,
          storeBaysId: r.storeBaysId,
          qnt: this.state.data["qnt_" + r["itemId"]],
          serial: this.state.data["serial_" + r["itemId"]],
          validation: {
            isReq: "yes",
          },
        };
        this.state.selectedStockItems.push(stockItem);
        this.setState({ selectedStockItems: this.state.selectedStockItems });
      }
    } else {
      toast.error("Enter proper Data");
    }
  };
  handleRemoveItem = (item) => {
    let selectedStockItems = this.state.selectedStockItems.filter(
      (inv) =>
        inv.itemId + inv.storeBaysId + inv.serial !==
        item.itemId + item.storeBaysId + item.serial
    );
    this.setState({ selectedStockItems: selectedStockItems });
  };
  handleInventorySubmit = async () => {
    let override = { ...this.state.data };
    override["actionCode"] = "wInventoryBlockAssign";
    override["action"] = "Assign Inventory to Block";
    // this.setState({ override });
    let resultSet = {
      jobInventory: this.state.selectedStockItems,
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
      ticketBlockId: this.state.curBlockDetails.ticketBlockId,
      actionStatus: "BLOCK_INVENTORY_ASSIGNED",
    };

    let resp = await setTicketActions(
      override,
      this.state.curTicketDetails,
      this.state.curBlockDetails,
      resultSet,
      this.state.curTicketDetails.ticketProductId
    );
    if (resp.data.success === true) {
      resp.data.data &&
        resp.data.data &&
        toast.success(() => <div>Block Inventory Assigned Successfully</div>);
    } else {
      toast.error(() => <div>{resp.data.msg}</div>);
    }
  };

  localHandleChange = async (e) => {
    try {
      if (e.target.name === "materialType") {
        if (e.target.value) {
          let materialType = e.target.value;
          let res = { ...this.state.data };
          res["hubId"] = localStorage.getItem("curHub");
          res["materialType"] = materialType;
          await this.getBarcode(res, this.state.pageLinks.pageNo);
        } else {
          let res = { ...this.state.data };
          res["hubId"] = localStorage.getItem("curHub");
          res["materialType"] = "all";
          await this.getBarcode(res, this.state.pageLinks.pageNo);
        }
      }
      if (e.target.name === "baysType") {
        let storeBaysId = e.target.value;
        this.loadItems(storeBaysId);
        this.setState({ storeBaysId });
      }
      if (e.target.name === "itemType") {
        let itemId = e.target.value;
        this.loadItemsStock(this.state.storeBaysId, itemId);
      }
    } catch {
      console.log("Error in PackingAssignment>>>>>>localHandleChange");
    }
  };

  async doSubmit() {
    try {
      let res = { ...this.state.data };
      res["hubId"] = localStorage.getItem("curHub");
      await this.getBarcode(res, this.state.pageLinks.pageNo);
    } catch {
      console.log("Error in PackingAssignment>>>>>>doSubmit");
    }
  }

  async getBarcode(params, pageNo) {
    try {
      this.setState({ loading: true });
      let resp = await getBarcodesList(params, pageNo);

      if (resp.data.success === true) {
        resp.data.data.barcodes &&
          this.setState({ barcodeDetails: resp.data.data.barcodes });
        resp.data.data.pageLinks &&
          this.setState({ pageLinks: resp.data.data.pageLinks });
        resp.data.data.barcodes.length > 0 &&
          toast.success(() => <div>{resp.data.msg}</div>);
        resp.data.data.barcodes.length == 0 &&
          toast.warning(() => <div>No Barcodes Available</div>);
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in PackingAssignment>>>>>>getBarcode");
    }
  }

  handlePageChange = (pageNo) => {
    try {
      // console.log("page clicked:" + pageNo);
      let res = { ...this.state.data };
      res["hubId"] = localStorage.getItem("curHub");
      this.getBarcode(res, pageNo);
    } catch {
      console.log("Error in PackingAssignment>>>>>>handlePageChange");
    }
  };

  handlePackingCompleted = async () => {
    try {
      let override = { ...this.state.data };
      override["actionCode"] = "wPackingMaterial";
      override["action"] = "HANDED OVER PACKING MATERIALS";
      // this.setState({ override });
      let resultSet = {
        actionStatus: "HANDED_OVER_PACKING_MATERIALS",
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
      };

      // console.log("cameeeeeeeeeeee " + JSON.stringify(this.state.data));
      // console.log("Block Details " + JSON.stringify(this.state.curBlockDetails));

      let resp = await setTicketActions(
        override,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        resultSet
      );

      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ packStatus: "true" });
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in PackingAssignment>>>>>>handlePackingCompleted");
    }
  };

  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    const ticketDetails = this.state.curTicketDetails;

    return (
      <div className="row ">
        {this.state.pageHide === "false" &&
          this.state.packStatus === "false" &&
          this.state.hideForm === "false" &&
          this.state.cancelStatus === "false" && (
            <div className="col-md-12">
              <div className="form-subHeader">
                <b>
                  {this.state.curTicketDetails.ticketNo +
                    "|| Packing Barcode ::"}
                </b>
                {this.state.designation === "Operations Head" && (
                  <div
                    className="pull-right"
                    style={{ marginTop: "-1%", marginRight: "16%" }}
                  >
                    {this.rCBHL(
                      "includeCancelTicket",
                      this.includeCancelTicket,
                      {
                        value: "sortKey",
                        label: "sort",
                      }
                    )}
                  </div>
                )}
                {this.state.data.includeCancelTicket === "yes" && (
                  <div
                    className="pull-right"
                    style={{ marginTop: "1%", marginRight: "-37%" }}
                  >
                    <button
                      title="Cancel Ticket"
                      className="badge badge-danger  pull-right"
                      type="button"
                      onClick={() => this.handleCancelTicket()}
                      style={{
                        fontSize: "82%",
                        borderRadius: "2.25rem ",
                        border: "none",
                        fontWeight: "bold",
                        marginRight: "6px",
                      }}
                    >
                      CANCEL TICKET
                    </button>
                  </div>
                )}
              </div>
              <div className="card ">
                <div className="form-row">
                  <div className="col-9">
                    {this.state.curTicketDetails.flowId &&
                      this.state.curTicketDetails.flowId !== "eWaste" && (
                        <BarcodeProduct
                          productDetails={this.state.curProductDetails}
                          ticketDetails={this.state.curTicketDetails}
                          blockDetails={this.state.curBlockDetails}
                          key={"p_" + this.state.curBlockDetails.ticketBlockId}
                        />
                      )}
                    {/* <BarcodeBlock
                      productDetails={this.state.curProductDetails}
                      ticketDetails={this.state.curTicketDetails}
                      blockDetails={this.state.curBlockDetails}
                      key={"b_" + this.state.curBlockDetails.ticketBlockId}
                    /> */}
                    {/* {(this.state.curTicketDetails.flowId !== "Bulk" ) && (
                      <BarcodeTicket
                        productDetails={this.state.curProductDetails}
                        ticketDetails={this.state.curTicketDetails}
                        blockDetails={this.state.curBlockDetails}
                        key={"t_" + this.state.curBlockDetails.ticketBlockId}
                      />
                    )} */}
                    {this.state.printBarcode && (
                      <BarCodePrintSVG
                        printBarcode={this.state.printBarcode}
                        productDetails={this.state.curProductDetails}
                        ticketDetails={this.state.curTicketDetails}
                        blockDetails={this.state.curBlockDetails}
                        key={
                          "t_" +
                          this.state.curBlockDetails.ticketBlockId +
                          this.state.printBarcode
                        }
                      />
                    )}
                    <iframe
                      id="ifmcontentstoprint"
                      title="toPrint"
                      style={{
                        height: "0px",
                        width: "0px",
                        position: "absolute",
                      }}
                    ></iframe>
                    {/* {this.state.curTicketDetails.retailerCode === "DRP" && (
                    <InventoryBlock
                      productDetails={this.state.curProductDetails}
                      ticketDetails={this.state.curTicketDetails}
                      blockDetails={this.state.curBlockDetails}
                      key={"iB_" + this.state.curBlockDetails.ticketBlockId}
                    />
                  )} */}
                  </div>
                  {/* <div className="col-3">
                    {this.state.loading && (
                      <div className="container-fluid">
                        <div> Loading Barcodes...</div>
                      </div>
                    )}
                    {!this.state.loading && itemsCount >= 0 && (
                      <>
                        <div className="container-fluid">
                          <Table
                            clsName="table table-striped table-bordered table-hover"
                            tableMeta={this.tableMeta}
                            data={this.state.barcodeDetails}
                            dropMenu={[]}
                          />
                          <Pagination
                            pageNo={pageNo}
                            perPage={perPage}
                            itemsCount={itemsCount}
                            onPageChange={(pageNo) =>
                              this.handlePageChange(pageNo)
                            }
                          />
                          {!this.state.loading && itemsCount === 0 && (
                            <div className="alert alert-info" role="alert">
                              <div> No Records Found! </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div> */}
                  {/* <AssignPMAddProduct
                    productDetails={this.state.curProductDetails}
                    ticketDetails={this.state.curTicketDetails}
                    blockDetails={this.state.curBlockDetails}
                    key={"iB_" + this.state.curBlockDetails.ticketBlockId}
                  /> */}

                  {/* {this.state.curTicketDetails.retailerCode === "DRP" && ( */}
                  <InventoryBlock
                    productDetails={this.state.curProductDetails}
                    ticketDetails={this.state.curTicketDetails}
                    blockDetails={this.state.curBlockDetails}
                    key={"iB_" + this.state.curBlockDetails.ticketBlockId}
                  />
                  {/* )} */}
                </div>

                {this.state.curTicketDetails.retailerCode === "DRP" && (
                  <>
                    {/* {this.rCBHL("includeInventory", this.includeInventory, {
                    value: "sortKey",
                    label: "sort",
                  })} */}
                    {/* {this.state.data.includeInventory === "yes" && (
                    <>
                      <div className="form-subHeader">
                        <b>{"Block Inventory ::"}</b>
                      </div>
                      <div className="form-row">
                        {this.rSB(
                          "Store Bay's :",
                          "baysType",
                          this.state.storeBays,
                          { value: "storeBaysId", label: "baysName" },
                          " col-3 mt-2"
                        )}
                        {this.rSB(
                          "Items Stock List :",
                          "itemType",
                          this.state.itemsStockList,
                          { value: "itemId", label: "item" },
                          " col-3 mt-2"
                        )}
                        <div className="col-8 mt-2">
                          {this.state.itemsStockListInfo &&
                            <Table
                              clsName="table table-striped table-bordered table-hover"
                              tableMeta={this.tableMetaInventory}
                              data={this.state.itemsStockListInfo}
                              dropMenu={[]}
                            />
                          }
                        </div>

                        <div className="col-4 mt-2">
                          <table className="table  table-striped m-1 p-0 border">
                            <tbody>
                              {this.state.selectedStockItems.map((inv) => {
                                return (
                                  <tr key={inv.itemId + inv.storeBaysId + inv.serial} className="m-0 p-0">
                                    <td>{inv?.item}</td>
                                    <td>{inv?.storeBaysId}</td>
                                    <td>{inv?.serial}</td>
                                    <td>
                                      {inv?.qnt}{" "}
                                      <span
                                        class="badge badge-danger ml-4 mr-1 mt-2 "
                                        onClick={() => this.handleRemoveItem(inv)}
                                      >
                                        X
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          {this.state.selectedStockItems.length > 0 &&
                            <button
                              type="button"
                              className="btn btn-sm btn-success pull-right mt-2"
                              onClick={this.handleInventorySubmit}
                            >
                              Submit Inventory
                            </button>
                          }
                        </div>
                      </div>
                    </>

                  )} */}
                  </>
                )}
              </div>
              {/* {this.state.curTicketDetails.retailerCode === "DRP" && ( */}
              <button
                type="button"
                className="btn btn-sm btn-primary pull-right mt-2"
                onClick={this.handlePackingCompleted}
              >
                Handed Over Packing / Inventory
              </button>
              {/* )}
            {this.state.curTicketDetails.retailerCode !== "DRP" && (
              <button
                type="button"
                className="btn btn-sm btn-primary pull-right mt-2"
                onClick={this.handlePackingCompleted}
              >
                Handed Over Packing Materials
              </button>
            )} */}
            </div>
          )}
        {/* {this.state.pageHide === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Packing Assignment
            </p>
          </div>
        )} */}

        {this.state.packStatus === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Ticket No: <b>{ticketDetails.ticketNo}</b> Packing Process
              Completed Successfully
            </p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackingAssignment);
