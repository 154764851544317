import React from 'react';
import FormLib from './../../_common/FormLib';
import { getImages } from './../../../services/Tickets/TicketServices';
import { toast } from 'react-toastify';
import TicketDetails from './../TicketHistory/TicketDetails';

export default class FilesView extends FormLib {
    state = {
        data: {},
        errors: {},
        curProductDetails: [],
        curTicketDetails: [],
        setImages: []
    };

    componentDidMount() {
        this.loadInit();
        this.loadImages();
    }

    loadInit() {
        const curProductDetails = this.props.productDetails;
        const curTicketDetails = this.props.ticketDetails;
        this.setState({ curProductDetails });
        this.setState({ curTicketDetails });
    }

    async loadImages() {
        try {
            const processId = this.props.ticketDetails.processId;
            let res = await getImages(processId);
            if (res !== undefined) {
                if (res.data.success === true && res.data.data !== []) {
                    this.setState({ setImages: res.data.data.images })
                } else if (res.data.success === false) {
                    toast.error("No Images Available for this Block")
                }
            }

        }
        catch {
            console.log("Error in FilesView>>loadImages");
        }


    }

    replaceString = (str) => {
        return str.replace(/_/g, " ");
    };

    stringReplace(data) {
        return data.replace(/{/g, "").replace(/}/g, "").replace(/]/g, "").replace(/"/g, "").replace(/:/g, " : ").replace(/,/g, ' , ');
    }

    render() {
        const ticketDetails = this.state.curTicketDetails;
        return <>
            {this.state.setImages.length !== 0 && (
                <div className="row mt-4 ">
                    <div className="col-md-12">
                        <div className="card ">
                            <div className="card-body bg-light">
                                <div className="form-subHeader"><b>{ticketDetails.ticketNo}</b></div>
                                <div className="form-subHeader">Before Packing Photos Verification</div>

                                {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode !== "mSignature" && t.actionCode !== "mPhotoId" && t.actionCode !== "mPhotoAftrPackng" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}

                                <div className="row">
                                    {this.state.setImages.map((t) => {
                                        return (
                                            <>
                                                {t.actionCode !== "mSignature" &&
                                                    t.actionCode !== "mPhotoId" &&
                                                    t.actionCode !== "mPhotoAftrPackng" && (
                                                        <div className="col-md-4">
                                                            <span>
                                                                <a
                                                                    href="javascript:void(0);"
                                                                    data-toggle="tooltip"
                                                                    title={this.stringReplace(t.geoLocation)}
                                                                >
                                                                    <img
                                                                        src={t.imageUrl}
                                                                        style={{
                                                                            width: "150px",
                                                                            height: "150px",
                                                                            margin: "3px",
                                                                        }}
                                                                    />
                                                                </a>
                                                                <br></br>
                                                                <span
                                                                    className="badge badge-info link-over ml-2"
                                                                    style={{
                                                                        width: "auto",
                                                                        height: "auto",
                                                                        margin: "3px",
                                                                    }}
                                                                >
                                                                    {t.action}
                                                                </span>
                                                            </span>
                                                            <br></br>
                                                            <br></br>
                                                        </div>
                                                    )}
                                            </>
                                        );
                                    })}
                                </div>

                                {ticketDetails.flowCode !== "PND2W" && <div className="form-subHeader mt-2">After Packing Photos Verification</div>}
                                {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode === "mPhotoAftrPackng" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}>
                                    <img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a>
                                    <span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                                <div className="row">
                                    {this.state.setImages.map((t) => {
                                        return (
                                            <>
                                                {t.actionCode === "mPhotoAftrPackng" && (
                                                    <div className="col-md-4">
                                                        <span>
                                                            <a
                                                                href="javascript:void(0);"
                                                                data-toggle="tooltip"
                                                                title={this.stringReplace(t.geoLocation)}
                                                            >
                                                                <img
                                                                    src={t.imageUrl}
                                                                    style={{
                                                                        width: "150px",
                                                                        height: "150px",
                                                                        margin: "3px",
                                                                    }}
                                                                />
                                                            </a>
                                                            <br></br>
                                                            <span
                                                                className="badge badge-warning link-over ml-2"
                                                                style={{
                                                                    width: "auto",
                                                                    height: "auto",
                                                                    margin: "3px",
                                                                }}
                                                            >
                                                                {t.action}
                                                            </span>
                                                        </span>
                                                        <br></br>
                                                        <br></br>
                                                    </div>
                                                )}
                                            </>
                                        );
                                    })}
                                </div>
                                <div className="form-subHeader mt-2">Photo Id Verification</div>
                                {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode === "mPhotoId" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                                <div className="row">
                                    {this.state.setImages.map((t) => {
                                        return (
                                            <>
                                                {t.actionCode === "mPhotoId" && (
                                                    <div className="col-md-4">
                                                        <span>
                                                            <a
                                                                href="javascript:void(0);"
                                                                data-toggle="tooltip"
                                                                title={this.stringReplace(t.geoLocation)}
                                                            >
                                                                <img
                                                                    src={t.imageUrl}
                                                                    style={{
                                                                        width: "150px",
                                                                        height: "150px",
                                                                        margin: "3px",
                                                                    }}
                                                                />
                                                            </a>
                                                            <br></br>
                                                            <span
                                                                className="badge badge-danger link-over ml-2"
                                                                style={{
                                                                    width: "auto",
                                                                    height: "auto",
                                                                    margin: "3px",
                                                                }}
                                                            >
                                                                {t.action}
                                                            </span>
                                                        </span>
                                                        <br></br>
                                                        <br></br>
                                                    </div>
                                                )}
                                            </>
                                        );
                                    })}
                                </div>
                                <div className="form-subHeader mt-2">Signature Verification</div>
                                {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode === "mSignature" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{this.replaceString(t.action)}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                                <div className="row">
                                    {this.state.setImages.map((t) => {
                                        return (
                                            <>
                                                {t.actionCode === "mSignature" && (
                                                    <div className="col-md-4">
                                                        <span>
                                                            <a
                                                                href="javascript:void(0);"
                                                                data-toggle="tooltip"
                                                                title={this.stringReplace(t.geoLocation)}
                                                            >
                                                                <img
                                                                    src={t.imageUrl}
                                                                    style={{
                                                                        width: "150px",
                                                                        height: "150px",
                                                                        margin: "3px",
                                                                    }}
                                                                />
                                                            </a>
                                                            <br></br>
                                                            <span
                                                                className="badge badge-success link-over ml-2"
                                                                style={{
                                                                    width: "auto",
                                                                    height: "auto",
                                                                    margin: "3px",
                                                                }}
                                                            >
                                                                {this.replaceString(t.action)}
                                                            </span>
                                                        </span>
                                                        <br></br>
                                                        <br></br>
                                                    </div>
                                                )}
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    }
}
