import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import { transferBulkBarcode } from "../../../services/packing/BarcodeService";
import { getHubsAll } from "../../../services/masterLocations/hubServices";

class ModalBarcodeTransfer extends FormLib {
  state = {
    data: {
      barcodeStart: "",
      barcodeEnd: "",
      srcHubId: "",
      dstHubId: "",
    },
    hubs: [],
    errors: {},
    curBarcode: {},
  };

  schema = {
    srcHubId: Joi.required().label("srcHubId"),
    dstHubId: Joi.required().label("dstHubId"),
    barcodeStart: Joi.required().label("barcodeStart"),
    barcodeEnd: Joi.required().label("barcodeEnd"),
  };

  componentDidMount() {
    this.loadHubs();
    //   this.setCurMaterialHub(this.props.curBarcode, this.props.curHubId);
  }
  async loadHubs() {
    this.setState({ loading: true });
    const res = await getHubsAll();
    if (res != undefined) {
      if (res.data.success === true) {
        this.setState({ hubs: res.data.data.hubs });
      } else {
        toast.toastError(res.data.msg);
      }
    }
    this.setState({ loading: false });
  }

  // setCurMaterialHub = (p, h) => {
  //   const data = { ...this.state.data };
  //   if (p.packMaterialId !== "") {
  //     data.packMaterialId = p.packMaterialId;
  //   }
  //   data.hubId = h;
  //   this.setState({ data });

  // }
  // async doSubmit() {
  //   let res;
  //   if (this.state.btnValue === "transfer") {
  //     res = await transferBulkBarcode(this.state.data);
  //     if (res.success) {
  //       this.resetData();
  //     }
  //   }
  //   if (res.data.success === true) {
  //     toast.toastSuccess(res.data.msg);
  //     this.props.onloadBarcodes();
  //   } else {
  //     toast.toastError(res.data.msg);
  //   }
  // }

  doSubmit = async () => {
    let res;
    // console.log("REQ JSON--------------->" + this.state.data);
    if (this.state.btnValue === "transfer") {
      res = await transferBulkBarcode(this.state.data);
      this.resetData();
    }
    if (res.data.success === true) {
      this.setState({ failureBarcode: res.data.data.failuredBarcodes });
      toast.toastSuccess(res.data.msg);
      this.loadProducts();
      this.props.onloadBrand();
    } else {
      toast.toastError(res.data.msg);
    }
  };

  resetData() {
    this.setState({
      data: {
        barcodeStart: "",
        barcodeEnd: "",
      },
      hubs: [],
    });
  }

  // status = [
  //   { sortKey: "active", sort: "Active" },
  //   { sortKey: "inactive", sort: "Inactive" },
  // ];
  // stsConf = {
  //   value: "sortKey",
  //   label: "sort",
  // };
  // barcodeType = [
  //   { sortKey: "sticker", sort: "sticker" },
  //   { sortKey: "prePrint", sort: "prePrint" },
  // ];
  // barConf = {
  //   value: "sortKey",
  //   label: "sort",
  // };
  render() {
    return (
      <div className="container-fluid">
        <div className="modal fade" id="myBarcodeModalTransfer">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Transfer Barcodes</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  <div className="form-row">
                    <div className="col-md-3 ">
                      {this.rSB("From Hub", "srcHubId", this.state.hubs, {
                        value: "hubId",
                        label: "hub",
                      })}
                    </div>

                    <div className="form-row">
                      <div className="col-md-3 ">
                        {this.rSB("To Hub", "dstHubId", this.state.hubs, {
                          value: "hubId",
                          label: "hub",
                        })}
                      </div>


                      {this.rNB("Barcode Start", "barcodeStart", "col-4")}
                      {this.rNB("Barcode End", "barcodeEnd", "col-4")}
                      {/* </div> */}

                    </div>
                  </div>
                </form>
              </div>


              <div className="modal-footer">
                <div className="row">
                  {/* <div className="col-3">
                  {"failureBarcode : "+JSON.stringify(this.state.failureBarcode)}
                  </div> */}
                  <div className="col-3">
                    {this.rBtnSP("Transfer", "transfer")}
                  </div>
                </div>
                {this.state.failureBarcode &&
                  <div className="card" style={{ "background": "#efeded" }} >
                    <div className="card-body">
                      {"failureBarcodes : " + JSON.stringify(this.state.failureBarcode)}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalBarcodeTransfer;
