import React, { Component } from "react";
import FormLib from "./../../_common/FormLib";
import {
  getPlayersbyRole,
  getPlayersbyRoleCount,
} from "./../../../services/Tickets/TicketServices";
import {
  setTicketActions,
  setTicketActionsNew,
} from "./../../../services/Tickets/TicketProcessServices";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { ticketListReload } from "../../../store";
import { connect } from "react-redux";
import { IstToUTC } from "../../../utils/DateUtils";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { epoch2ddmmyyyy, epoch2ddmmyy } from "./../../../utils/DateUtils";
import ReactTooltip from "react-tooltip";
class FeAssignmentNew extends FormLib {
  state = {
    data: {
      player: "",
      tkPriorityChange: "",
    },
    users: [],
    errors: {},
    curticketDetails: {},
    curblockDetails: {},
    curMultiblockDetails: {},
    curMultiticketDetails: {},
    curAction: "",
    pageHide: "false",
    feStatus: "false",
    curStatus: "",
    curMultiFETicketDetails: [],
  };

  schema = {
    player: Joi.string().required().label("FE Assignment"),
    tkPriorityChange: Joi.optional().label("tkPriorityChange"),
  };

  componentDidMount() {
    this.resizeTicketList();
    this.getPlayers();
    this.loadInit();
    let pageHide = this.props.hidePage;
    this.setState({ pageHide });
    this.setState({ curStatus: this.props.curStatus });
    this.getlocalDateTime();
  }

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }

  loadInit() {
    const curticketDetails = this.props.ticketDetails;
    const curblockDetails = this.props.blockDetails;
    const curAction = this.props.curAction;
    const curMultiticketDetails = this.props.ticketMultiDetails;
    const curMultiblockDetails = this.props.blockMultiDetails;
    const bulkAssign = this.props.bulkAssign;
    this.setState({ curticketDetails });
    this.setState({ curblockDetails });
    this.setState({ curAction });
    this.setState({ bulkAssign });
    this.setState({ curMultiticketDetails });
    this.setState({ curMultiblockDetails });
  }
  async getPlayers() {
    try {
      let hub = localStorage.getItem("curHub");
      let resp = await getPlayersbyRole(hub);
      // let resp = await getPlayersbyRoleCount(hub);

      if (resp.data.data) {
        let users = resp.data.data.playersHubs;

        const filter = resp.data.data.playersHubs.filter((r) => {
          if (!r.rlastName) {
            r.rlastName = "";
          }
          r["display"] = r.firstName + " " + r.rlastName + " / " + r.userName; //+ " / " + r.count;
          return r;
        });

        if (resp.data.success === true) {
          resp.data.data.playersHubs && this.setState({ users: filter });
          !resp.data.data.playersHubs &&
            toast.warning(() => <div>{resp.data.msg}</div>);
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      }
    } catch {
      console.log("Error in getPlayers");
    }
  }

  async doSubmit() {
    try {
      let override = { ...this.state.data };
      override["actionCode"] = "wFeAssign";
      override["action"] = "Assign FE to Block";
      this.setState({ override });
      let feAction = "";
      let newTicketPriority = "";
      if (this.state.bulkAssign === "bulk") {
        for (var i = 0; i <= this.state.curMultiblockDetails.length; i++) {
          if (
            this.state.curMultiblockDetails[i].blockStatus === "BLOCK_CREATED"
          ) {
            feAction = "ASSIGNED_FE_FOR_PICKUP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus === "RESCHEDULE_FE"
          ) {
            feAction = "CHOOSE_RE_APPOINTMENT_DATE";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
              "CHOOSE_FE_FOR_DROP" &&
            this.state.curMultiticketDetails[i].flowId !== "PickAndDropTwoWay"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
              "CHOOSE_FE_FOR_DROP_ASC" &&
            this.state.curMultiticketDetails[i].flowId === "PickAndDropTwoWay"
          ) {
            feAction = "ASSIGNED_FE_FOR_SERVICE_DROP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
              "DROP_DONE_ASC" &&
            this.state.curMultiticketDetails[i].flowId === "PickAndDropTwoWay"
          ) {
            feAction = "ASSIGNED_FE_FOR_SERVICE_PICKUP";
            newTicketPriority = this.state.data.tkPriorityChange;
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
              "PICKUP_DONE_ASC" &&
            this.state.curMultiticketDetails[i].flowId === "PickAndDropTwoWay"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
              "APPOINTMENT_FIXED" &&
            this.state.curMultiblockDetails[i].flowStatus === "PICK_WEB" &&
            this.state.curMultiticketDetails[i].flowCode !== "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_PICKUP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
              "APPOINTMENT_FIXED" &&
            this.state.curMultiblockDetails[i].flowStatus === "DROP_WEB"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
              "APPOINTMENT_FIXED" &&
            this.state.curMultiticketDetails[i].flowCode === "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "RESCHEDULE_FE_AT_DROP"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "RESCHEDULE_FE_AT_PICKUP"
          ) {
            feAction = "ASSIGNED_FE_FOR_PICKUP";
          }

          const feFilter = this.state.users.filter((r) => {
            return r.playerId === this.state.data.player ? r : null;
          });

          this.setState({
            curFeName: feFilter[0].firstName + " " + feFilter[0].lastName,
          });

          // console.log("FE DETAILS----------> " + JSON.stringify(feFilter));

          let resultSet = {
            fes: [
              {
                feId: this.state.data.player,
                feType: "fe",
                feName:
                  feFilter[0].firstName +
                  " " +
                  feFilter[0].lastName +
                  " - " +
                  feFilter[0].userName,
                fePhone: feFilter[0].phone,
              },
            ],
            // feId: this.state.data.player,
            // feType: "fe",
            // feName: feFilter[0].firstName + " " + feFilter[0].lastName + " - " + feFilter[0].userName,
            // fePhone: feFilter[0].phone,
            flowId: this.state.curMultiticketDetails[i].flowId,
            newTicketPriority: newTicketPriority,
            actionStatus: feAction,
            processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          };
          let resp = await setTicketActionsNew(
            override,
            this.state.curMultiticketDetails[i],
            this.state.curMultiblockDetails[i],
            resultSet
          );
          if (resp.data.success === true) {
            if (resp.data.data) {
              this.handleTicketListReload();
              this.setState({ feStatus: "true" });
              toast.success("FE Assigned Successfully");
            } else {
              !resp.data.data &&
                toast.warning(() => <div>{resp.data.msg}</div>);
            }
          } else {
            toast.error(() => <div>{resp.data.msg}</div>);
          }
        }
      } else {
        let multiFes = [];
        if (
          this.state.curticketDetails.flowId === "BulkQC" ||
          this.state.curticketDetails.flowId === "Bulk" ||
          this.state.curticketDetails.flowId === "DropOff"
        ) {
          let override = { ...this.state.data };
          override["actionCode"] = "wFeAssign";
          override["action"] = "Assign Bulk FE to Block";
          this.setState({ override });
          let feAction = "ASSIGNED_FE_FOR_PICKUP";
          let newTicketPriority = "";

          const feFilter = this.state.users.filter((r) => {
            return r.playerId === this.state.data.player ? r : null;
          });

          this.setState({
            curFeName: feFilter[0].firstName + " " + feFilter[0].lastName,
          });

          this.state.curMultiFETicketDetails.map((r) => {
            let FEs = {
              feId: r.playerId,
              feType: "fe",
              feName: r.firstName + " " + r.lastName + " - " + r.userName,
              fePhone: r.phone,
            };
            multiFes.push(FEs);
          });
          let resultSet = {
            fes: multiFes,
            flowId: this.state.curticketDetails.flowId,
            newTicketPriority: newTicketPriority,
            actionStatus: feAction,
            processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          };

          let resp = await setTicketActionsNew(
            override,
            this.state.curticketDetails,
            this.state.curblockDetails,
            resultSet
          );
          if (resp.data.success === true) {
            if (resp.data.data) {
              this.handleTicketListReload();
              this.setState({ feStatus: "true" });
              toast.success("FE Assigned Successfully");
            } else {
              !resp.data.data &&
                toast.warning(() => <div>{resp.data.msg}</div>);
            }
          } else {
            toast.error(() => <div>{resp.data.msg}</div>);
          }
        } else {
          if (this.state.curblockDetails.blockStatus === "BLOCK_CREATED") {
            feAction = "ASSIGNED_FE_FOR_PICKUP";
          } else if (
            this.state.curblockDetails.blockStatus ===
            "HANDED_OVER_PACKING_MATERIALS"
          ) {
            feAction = "ASSIGNED_FE_FOR_PICKUP";
          } else if (
            this.state.curblockDetails.blockStatus === "RESCHEDULE_FE"
          ) {
            feAction = "CHOOSE_RE_APPOINTMENT_DATE";
          } else if (
            this.state.curblockDetails.blockStatus === "CHOOSE_FE_FOR_DROP" &&
            this.state.curticketDetails.flowId !== "PickAndDropTwoWay"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus ===
              "CHOOSE_FE_FOR_DROP_ASC" &&
            this.state.curticketDetails.flowId === "PickAndDropTwoWay"
          ) {
            feAction = "ASSIGNED_FE_FOR_SERVICE_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "DROP_DONE_ASC" &&
            this.state.curticketDetails.flowId === "PickAndDropTwoWay"
          ) {
            feAction = "ASSIGNED_FE_FOR_SERVICE_PICKUP";
            newTicketPriority = this.state.data.tkPriorityChange;
          } else if (
            this.state.curblockDetails.blockStatus === "PICKUP_DONE_ASC" &&
            this.state.curticketDetails.flowId === "PickAndDropTwoWay"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "APPOINTMENT_FIXED" &&
            this.state.curblockDetails.flowStatus === "PICK_WEB" &&
            this.state.curticketDetails.flowCode !== "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_PICKUP";
          } else if (
            this.state.curblockDetails.blockStatus === "APPOINTMENT_FIXED" &&
            this.state.curblockDetails.flowStatus === "DROP_WEB"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "APPOINTMENT_FIXED" &&
            this.state.curticketDetails.flowCode === "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "RESCHEDULE_FE_AT_DROP"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "RESCHEDULE_FE_AT_PICKUP"
          ) {
            feAction = "ASSIGNED_FE_FOR_PICKUP";
          }

          const feFilter = this.state.users.filter((r) => {
            return r.playerId === this.state.data.player ? r : null;
          });

          this.setState({
            curFeName: feFilter[0].firstName + " " + feFilter[0].lastName,
          });

          // console.log("FE DETAILS----------> " + JSON.stringify(feFilter));

          let resultSet = {
            fes: [
              {
                feId: this.state.data.player,
                feType: "fe",
                feName:
                  feFilter[0].firstName +
                  " " +
                  feFilter[0].lastName +
                  " - " +
                  feFilter[0].userName,
                fePhone: feFilter[0].phone,
              },
            ],
            // feId: this.state.data.player,
            // feType: "fe",
            // feName: feFilter[0].firstName + " " + feFilter[0].lastName,
            // fePhone: feFilter[0].phone,
            flowId: this.state.curticketDetails.flowId,
            newTicketPriority: newTicketPriority,
            actionStatus: feAction,
            processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          };

          // console.log(
          //   "resultSet " + JSON.stringify(this.state.curblockDetails.flowStatus)
          // );

          let resp = await setTicketActionsNew(
            override,
            this.state.curticketDetails,
            this.state.curblockDetails,
            resultSet
          );
          if (resp.data.success === true) {
            if (resp.data.data) {
              this.handleTicketListReload();
              this.setState({ feStatus: "true" });
              toast.success("FE Assigned Successfully");
            } else {
              !resp.data.data &&
                toast.warning(() => <div>{resp.data.msg}</div>);
            }
          } else {
            toast.error(() => <div>{resp.data.msg}</div>);
          }
        }
      }
    } catch {
      console.log("Error in FE assignment doSubmit");
    }
  }

  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };
  resizeTicketList = () => {
    $("#cocardsFe").css("max-height", $(window).height() - 262);
  };
  curFeAssignment = (feAssign) => {
    // let ticketCount = 0;
    // if (this.state.bulkAssign === "bulk") {
    //     if (this.state.curMultiticketDetails.length === undefined) {
    //         this.ticketCount = feAssign.count + 0;
    //     }
    //     else {
    //         this.ticketCount = feAssign.count + this.state.curMultiticketDetails.length;
    //     }
    // }
    // else {
    //     this.ticketCount = feAssign.count + 1;
    // }
    // this.setState({ selectedTicketCount: this.ticketCount })
    if (
      (this.state.curticketDetails.flowId === "BulkQC" ||
        this.state.curticketDetails.flowId === "Bulk" ||
        this.state.curticketDetails.flowId === "DropOff") &&
      this.state.curMultiFETicketDetails.length > 1
    ) {
      this.setState({
        playersId: this.state.curMultiFETicketDetails[0].playerId,
      });
      this.state.data.player = this.state.curMultiFETicketDetails[0].playerId;
    } else if (this.state.curMultiFETicketDetails.length < 1) {
      this.state.curMultiFETicketDetails = [];
      this.state.curMultiFETicketDetails.push(feAssign);
      this.setState({
        playersId: this.state.curMultiFETicketDetails[0].playerId,
      });
      this.state.data.player = this.state.curMultiFETicketDetails[0].playerId;
    } else {
      this.setState({ playersId: feAssign.playerId });
      this.state.data.player = feAssign.playerId;
    }
  };
  tkPriorityChange = [{ label: "Update Service Center Status", value: "high" }];
  replaceString = (str) => {
    return str.replace(/_/g, " ");
  };
  handleCheckBoxClick = (e, t) => {
    try {
      if (e.target.checked) {
        const index = this.state.curMultiFETicketDetails.findIndex(
          (object) => object.playerId === t.playerId
        );
        if (index === -1) {
          this.state.curMultiFETicketDetails.push(t);
        }
      } else {
        let index1 = this.state.curMultiFETicketDetails.indexOf(t);
        if (index1 > -1) {
          this.state.curMultiFETicketDetails.splice(index1, 1);
        }
      }
      if (
        this.state.curticketDetails.flowId === "BulkQC" ||
        this.state.curticketDetails.flowId === "Bulk" ||
        this.state.curticketDetails.flowId === "DropOff"
      ) {
        this.setState({
          playersId: this.state.curMultiFETicketDetails[0].playerId,
        });
        this.state.data.player = this.state.curMultiFETicketDetails[0].playerId;
      }
    } catch {
      console.log("Error in handleCheckBoxClick");
    }
  };
  render() {
    const ticketDetails = this.state.curticketDetails;
    return (
      <>
        <div className="row  ">
          {this.state.pageHide === "false" &&
            this.state.feStatus === "false" &&
            this.state.bulkAssign === "notBulk" && (
              <>
                <div id="coAction" className="col-md-12">
                  <div className="form-subHeader">
                    <b>{ticketDetails.ticketNo}</b>
                  </div>
                  <div className="card ">
                    <div className="card-body  bg-light">
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row mt-5">
                          <b>&nbsp;&nbsp;FE Assignment :</b>
                          <div id="cocardsFe" className="col-6  ml-1 mr-4">
                            <div id="primary-ticket-search" className="row ">
                              {this.state.users.map((t) => {
                                return (
                                  <span key={t.playerId + "spanCover1"}>
                                    {(this.state.curticketDetails.flowId ===
                                      "BulkQC" ||
                                      this.state.curticketDetails.flowId ===
                                        "Bulk" ||
                                      this.state.curticketDetails.flowId ===
                                        "DropOff") && (
                                      <input
                                        type="checkbox"
                                        name={t.ticketNo}
                                        value={t.ticketNo}
                                        title="Bulk FE Selection"
                                        onClick={(e) =>
                                          this.handleCheckBoxClick(e, t)
                                        }
                                        className="multiInput ml-3"
                                      />
                                    )}
                                    <div
                                      className="card mb-2 cursorPointor "
                                      onClick={() => this.curFeAssignment(t)}
                                    >
                                      <div
                                        className="card-header page-todo task"
                                        style={
                                          t.playerId &&
                                          t.playerId === this.state.playersId
                                            ? {
                                                backgroundColor:
                                                  "rgb(255 225 202)",
                                              }
                                            : { backgroundColor: "white" }
                                        }
                                      >
                                        <div className="col-6 zoom">
                                          {t.display}
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                );
                              })}
                            </div>
                          </div>

                          {this.state.curblockDetails.blockStatus ===
                            "DROP_DONE_ASC" &&
                            this.state.curticketDetails.flowId ===
                              "PickAndDropTwoWay" &&
                            this.rCBHL(
                              "tkPriorityChange",
                              this.tkPriorityChange
                            )}
                        </div>
                        {this.rBtnLP(
                          "Submit",
                          "Submit",
                          "btn btn-primary btn-sm pull-right"
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}

          {this.state.pageHide === "false" &&
            this.state.feStatus === "false" &&
            this.state.bulkAssign === "bulk" && (
              <>
                {/* <div className="col-6 ">
                                {this.state.curMultiticketDetails.map((t) => {
                                    return (
                                        <span key={t.processId + "spanCover1"}>
                                            {t.blocks.map((b, index) => {
                                                return (
                                                    <span key={b.ticketBlockId + "spanCover1" + index}>
                                                        <div
                                                            className="card mb-2 cursorPointor"
                                                            key={t.processId + b.ticketBlockId + index}
                                                        >
                                                            <div
                                                                className="card-header page-todo task"
                                                            >
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <a>
                                                                            <i
                                                                                id="btn1"
                                                                            >
                                                                                <b style={{ color: "#737CA1" }}>{t.ticketNo}</b>
                                                                            </i>
                                                                        </a>{" "}
                                                                        | <span title="orderNumber">{t.orderNo}</span>
                                                                        {b.blockStatus && (
                                                                            <span
                                                                                className={
                                                                                    "badge badge-success badge-normal pull-right"
                                                                                }
                                                                            >
                                                                                {b.blockCode} -{" "}
                                                                                {this.replaceString(b.blockStatus)}
                                                                            </span>
                                                                        )}

                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <span
                                                                            className={
                                                                                t.ticketPriority === "high"
                                                                                    ? "badge badge-danger"
                                                                                    : "badge badge-primary badge-05assign"
                                                                            }
                                                                        >
                                                                            {t.ticketPriority}
                                                                        </span>
                                                                        <span className="badge badge-info mr-1 ml-1">
                                                                            {t.flowCode}
                                                                        </span>
                                                                        {t.flowId === "InstallationV1" && (
                                                                            <span className="badge badge-warning mr-1 ml-1">
                                                                                {t.primaryDetails.ticketType}
                                                                            </span>
                                                                        )}

                                                                        {t.flowId === "InstallationV1" &&
                                                                            t.retailerCode === "DRP" &&
                                                                            t.primaryDetails &&
                                                                            t.primaryDetails.serviceLevel && (
                                                                                <span className="badge badge-success badge-normal mr-1 ml-1">
                                                                                    {t.primaryDetails.serviceLevel}
                                                                                </span>
                                                                            )}

                                                                        <span>{epoch2ddmmyy(t.dateOfTicket)}</span>{" "}
                                                                        {b.flowStatus === "PICK_WEB" && (
                                                                            <span className="badge badge-secondary mr-1 ml-1" data-tip={t.products[0].srcAdd1 + " : " + t.products[0].srcAdd2} >
                                                                                {"Pickup-" + t.products[0].srcPincode}
                                                                                <ReactTooltip
                                                                                />
                                                                            </span>

                                                                        )}
                                                                        {b.flowStatus === "DROP_WEB" && (

                                                                            <span className="badge badge-secondary  mr-1 ml-1" data-tip={t.products[0].dstAdd1 + " : " + t.products[0].dstAdd2}>
                                                                                {"Drop-" + t.products[0].dstPincode}
                                                                                <ReactTooltip
                                                                                    multiline={true}
                                                                                />
                                                                            </span>
                                                                        )}

                                                                        <span className="pull-right">
                                                                            {t.retailerNickName}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </span>
                                                );
                                            })}
                                        </span>
                                    );
                                })}

                            </div> */}
                <div id="coAction" className="col-md-12">
                  <div className="form-subHeader">
                    <b>{ticketDetails.ticketNo}</b>
                  </div>
                  <div className="card ">
                    <div className="card-body  bg-light">
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row mt-5">
                          <b>&nbsp;&nbsp;FE Assignment :</b>
                          <div id="cocardsFe" className="col-6  ml-1 mr-4">
                            <div id="primary-ticket-search" className="row ">
                              {this.state.users.map((t) => {
                                return (
                                  <span key={t.playerId + "spanCover1"}>
                                    <div
                                      className="card mb-2 cursorPointor "
                                      onClick={() => this.curFeAssignment(t)}
                                    >
                                      <div
                                        className="card-header page-todo task"
                                        style={
                                          t.playerId &&
                                          t.playerId === this.state.playersId
                                            ? {
                                                backgroundColor:
                                                  "rgb(255 225 202)",
                                              }
                                            : { backgroundColor: "white" }
                                        }
                                      >
                                        <div className="col-6 zoom">
                                          {t.display}
                                        </div>
                                      </div>
                                    </div>
                                  </span>
                                );
                              })}
                            </div>
                          </div>

                          {this.state.curblockDetails.blockStatus ===
                            "DROP_DONE_ASC" &&
                            this.state.curticketDetails.flowId ===
                              "PickAndDropTwoWay" &&
                            this.rCBHL(
                              "tkPriorityChange",
                              this.tkPriorityChange
                            )}
                        </div>
                        {this.rBtnLP(
                          "Submit",
                          "Submit",
                          "btn btn-primary btn-sm pull-right"
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}

          {this.state.feStatus === "true" &&
            this.state.bulkAssign === "notBulk" && (
              <div className="jumbotron jumbotron-fluid">
                <p className="lead text-center">
                  Ticket No: <b>{ticketDetails.ticketNo}</b> has been Assigned
                  for <b>{this.state.curFeName}</b> Successfully
                </p>
              </div>
            )}
          {this.state.feStatus === "true" &&
            this.state.bulkAssign === "bulk" && (
              <div className="jumbotron jumbotron-fluid">
                <p className="lead text-center">
                  Ticket No: <b>{ticketDetails.ticketNo}</b> has been Assigned
                  for <b>{this.state.curFeName}</b> Successfully
                </p>
              </div>
            )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeAssignmentNew);
