import _http from "../_httpService";
import config from "../../config.json";

const baseURL = config[config.build].apiURLPrevilages;

export function getPlayersByTeam(teamId, pageNo) {
    return _http.get(baseURL+"/players-mob/"+teamId+"/200/"+pageNo, _http.headerJWT());
  }
  
export function deletePlayer(playerId){
  return _http.delete(baseURL+"/players-mob-web/"+playerId,_http.headerJWT());
}