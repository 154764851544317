import React from "react";
import FormLib from "../../_common/FormLib";
import ZTitle from "./../../_subhead/ZTitle";
import { getFlows } from "./../../../services/masters/hubServices";
import {
  getHubs,
  getClients,
  getActivity,
} from "./../../../services/masters/hubServices";
import Joi from "joi-browser";
import {
  getPaymentReports,
  getExcelPayments,
} from "./../../../services/requestV1/requestServices";
import { toast } from "react-toastify";
import Table from "./../../_common/Table";

import Pagination from "./../../_common/Pagination";
import dateFormat from "dateformat";
import {
  epoch2ddmmyyyy,
  epoch2ddmmyy,
  convertyyyymmddToddmmyyyy,
} from "./../../../utils/DateUtils";
import { ExportXLSXReqTrans, ExportXLSXTally } from "./ExportXLSX";

export default class PaymentReports extends FormLib {
  state = {
    data: {
      start_date: "",
      end_date: "",
      emp_code: "",
      ref_id: "",
      hub: "",
      client: "",
      flow: "",
      activity: "",
      request_status: "",
      payment_status: "",
    },
    errors: {},
    hubs: [],
    clients: [],
    activities: [],
    flows: [],
    paymentReports: [],
    counts: 1,
    debitValue: [],
    debitValue1: [],
    creditValue: [],
    myArr: [],
    excelData: [],
    csvReport: {
      headers: [],
      headers1: [],
      paymentReports: [],
    },
    fileName: "",
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      count: 0,
      lastPage: 0,
    },
  };

  schema = {
    start_date: Joi.string().required().label("Start Date"),
    end_date: Joi.string().required().label("End Date"),
    emp_code: Joi.any().optional().label("Employee Code"),
    ref_id: Joi.any().optional().label("Reference No."),
    hub: Joi.any().optional().label("Hub"),
    client: Joi.any().optional().label("Client"),
    flow: Joi.any().optional().label("Flow"),
    activity: Joi.any().optional().label("Activity"),
    request_status: Joi.any().optional().label("Activity"),
    payment_status: Joi.any().optional().label("Activity"),
  };

  excelHeaders = [
    "SEQ",
    "MULTI_REF_IDS",
    "ACTIVITY_INFO",
    "REQUEST_DETAILS",
    "AMT_APPROVED",
    "PAID",
    "STATUS",
    "CREATED_AT",
    "M_HUB",
    "M_ACTIVITY",
    "REQ_REMARKS",
    "PLAYER_TYPE",
    "PLAYER_CODE",
    "APPROVE_DATE",
    "APPROV_EXPENSE_HEAD",
    "APPROV_REMARK",
    "BANK",
    "PYMT_MODE",
    "STATUS",
    "APPROVER_NAME",
    "PAID_NAME",
    "ACCOUNT_NO",
    "NAME",
    "IFSC",
    "BANK_E_V",
    "PAY_UTR_NO",
    "PAY_ACC_DEBIT_DATE",
  ];

  excelHeadersTally = [
    "VOUCHER_TYPE",
    "VOUCHER_DATE",
    "VOUCHER_NO",
    "LEDGER",
    "DrCr",
    "MULTI_REF_IDS",
    "PAID",
    "NAME",
    "TRANSACTION_TYPE",
    "PAY_UTR_NO",
    "UTR_DATE",
    "CROSS_USING",
    "HUB",
    "NARRATION",
  ];

  tableMeta = {
    key: "REQUEST_ID",
    columns: [
      {
        key: "Seq",
        action: (r) => (
          <> {(r["RE_PREFIX"].trim() + String(r["SEQ"]).trim()).trim()} </>
        ),

        icon: () => <>#</>,
      },
      {
        key: "hub",
        action: (r) => <>{r["M_HUB"] + " / " + r["REQUEST_BY_FIRST"]}</>,

        icon: () => <>HUB /REQ BY</>,
      },
      // { path: "M_CLIENT", label: "Client" },
      {
        key: "CREATED_AT",
        action: (r) => <>{dateFormat(r.CREATED_AT, "dd-mm-yyyy")}</>,

        icon: () => <>Request Date</>,
      },
      {
        key: "REF_ID",
        action: (r) => (
          <>
            {r.MULTI_REF_IDS === "" ||
              r.MULTI_REF_IDS === "null" ||
              (r.MULTI_REF_IDS === null && r.REF_ID)}{" "}
            {(r.MULTI_REF_IDS !== "" ||
              r.MULTI_REF_IDS !== "null" ||
              r.MULTI_REF_IDS !== null) &&
              r.MULTI_REF_IDS}{" "}
          </>
        ),
        cusClass: "",
        icon: () => <>Reference No.</>,
      },
      { path: "REQUEST_TYPE", label: "Request Type" },
      // { path: "M_FLOW", label: "Flow" },
      { path: "M_ACTIVITY", label: "Activity" },
      { path: "APPROVER_NAME", label: "Approver" },
      {
        key: "APPROVE_DATE",
        action: (r) => epoch2ddmmyy(r["APPROVE_DATE"]),

        icon: () => <>Approv Date</>,
      },
      { path: "REQ_AMT", label: "Amount Paid" },
      { path: "PAID_NAME", label: "Paid By" },
      {
        key: "PAY_ACC_DEBIT_DATE",
        action: (r) => <>{dateFormat(r.PAID_AT, "dd-mm-yyyy")}</>,

        icon: () => <>Paid Date</>,
      },
      { path: "PAY_PAID_ID", label: "PAID NO" },
      { path: "PAY_UTR_NO", label: "UTR" },

      {
        key: "ACC_NAME",
        action: (r) => (
          <>
            {r["E_NAME"] ? r["E_NAME"] : ""}
            {r["V_NAME"] ? r["V_NAME"] : ""}
          </>
        ),

        icon: () => <>A/C Name</>,
      },
      { path: "ACTIVITY_INFO", label: "Activity Info" },
      { path: "REQUEST_DETAILS", label: "Req Narr" },
    ],
  };

  async componentDidMount() {
    let list = await getHubs();
    this.setState({ hubs: list.data.data.hubs });

    list = await getClients();
    this.setState({ clients: list.data.data.clients });

    list = await getActivity();
    this.setState({ activities: list.data.data });

    list = await getFlows();
    this.setState({ flows: list.data.data.flows });
    this.props.history.push("/v1/request/PaymentReports/1");
  }

  async doSubmit() {
    this.getPayments(this.state.data, 1);
  }

  async getPayments(params, pageNo) {
    this.setState({ loading: true });
    const { csvReport } = { ...this.state };
    const apiData = await getPaymentReports(this.state.data, pageNo);
    this.setState({ loading: false });
    csvReport.paymentReports = [];
    this.setState({ csvReport });

    if (apiData.data.success === true) {
      const { csvReport } = { ...this.state };
      csvReport.headers = this.excelHeaders;
      csvReport.headers1 = this.excelHeadersTally;
      csvReport.paymentReports = apiData.data.data.paymentReports;
      this.setState({ csvReport });
      let x = JSON.parse(JSON.stringify(apiData.data.data.paymentReports));
      let y = JSON.parse(JSON.stringify(apiData.data.data.paymentReports));
      this.formatTransactions(x);
      let debitValue = await this.formatTallyDebit(y);
      this.setState({ debitValue });
      const debitValue1 = debitValue.map((item) => ({ ...item }));

      let creditValue = await this.formatTallyCredit(debitValue);
      this.setState({ creditValue });

      this.arrayFun(debitValue1, creditValue);

      this.setState({ pageLinks: apiData.data.data.pageLinks });
      toast.success(() => <div>{apiData.data.msg}</div>);
    } else {
      toast.error(() => <div>{apiData.data.msg}</div>);
    }
  }
  formatTransactions = async (r) => {
    let formatedTrnx = await Promise.all(
      r.filter(async (t) => {
        // let t = { ...trx };
        delete t.REQUEST_ID;
        // delete t.RE_PREFIX;
        // delete t.SEQ;
        delete t.REQUEST_TYPE;
        delete t.HUB;
        delete t.CLIENT;
        delete t.FLOW;
        delete t.ACTIVITY;
        delete t.REQUEST_BY;
        delete t.AMT;
        delete t.CREATED_BY;
        delete t.UPDATED_AT;
        delete t.UPDATED_BY;
        delete t.APPROVE_ID;
        delete t.REQUEST_ID;
        delete t.REQ_AMT;
        delete t.APPROVER_ID;
        delete t.APPROV_AMT;
        delete t.PAID_AT;
        delete t.PAID_BY;
        delete t.PAID_REMARKS;
        delete t.REVERT_REMARKS;
        delete t.READY_DATE;
        delete t.LINE_TYPE;
        delete t.CREATED_BY;
        delete t.UPDATED_AT;
        delete t.UPDATED_BY;
        delete t.REQ_CREATED_AT;
        delete t.REQUEST_BY_FIRST;
        delete t.REQUEST_BY_LAST;
        delete t.REQUEST_BY_PHONE;
        delete t.REQUEST_BY_EMAIL;
        delete t.E_ACCOUNT_TYPE;
        delete t.E_BRANCH;
        delete t.V_ACCOUNT_TYPE;
        delete t.V_BRANCH;
        delete t.approv_status;
        delete t.PAY_PAID_ID;
        delete t.PAY_APPROVE_ID;
        delete t.READY_EXPENSE_HEAD;

        t.SEQ = t.RE_PREFIX + "" + t.SEQ;

        if (t.E_ACCOUNT_NO === null || t.E_ACCOUNT_NO === "") {
          t.ACCOUNT_NO = t.V_ACCOUNT_NO;
          t.NAME = t.V_NAME;
          t.IFSC = t.V_IFSC;
          t.BANK = t.V_BANK;
        } else {
          t.ACCOUNT_NO = t.E_ACCOUNT_NO;
          t.NAME = t.E_NAME;
          t.IFSC = t.E_IFSC;
          t.BANK = t.E_BANK;
        }
        if (
          t.MULTI_REF_IDS === null ||
          t.MULTI_REF_IDS === "" ||
          t.MULTI_REF_IDS === "null"
        ) {
          t.MULTI_REF_IDS = t.REF_ID;
        } else {
          t.MULTI_REF_IDS = t.MULTI_REF_IDS;
        }
        delete t.E_ACCOUNT_NO;
        delete t.V_ACCOUNT_NO;
        delete t.E_NAME;
        delete t.V_NAME;
        delete t.E_IFSC;
        delete t.V_IFSC;
        delete t.E_BANK;
        delete t.V_BANK;
        delete t.RE_PREFIX;
        delete t.M_CLIENT;
        delete t.M_FLOW;
        delete t.REF_ID;

        t.PAY_ACC_DEBIT_DATE = await convertyyyymmddToddmmyyyy(
          t.PAY_ACC_DEBIT_DATE
        );
        t.APPROVE_DATE = await convertyyyymmddToddmmyyyy(t.APPROVE_DATE);
        t.CREATED_AT = await convertyyyymmddToddmmyyyy(t.CREATED_AT);

        //

        return t;
      })
    );

    this.setState({ formatTrx: formatedTrnx });
  };

  formatTallyDebit = async (r) => {
    let formatedTally = await Promise.all(
      r.filter(async (t) => {
        delete t.REQUEST_ID;
        delete t.RE_PREFIX;
        delete t.SEQ;
        delete t.REQUEST_TYPE;
        delete t.HUB;
        delete t.CLIENT;
        delete t.FLOW;
        delete t.ACTIVITY;
        delete t.REQUEST_BY;
        delete t.AMT;
        delete t.CREATED_BY;
        delete t.UPDATED_AT;
        delete t.UPDATED_BY;
        delete t.APPROVE_ID;
        delete t.REQUEST_ID;
        delete t.REQ_AMT;
        delete t.APPROVER_ID;
        delete t.APPROV_AMT;
        delete t.PAID_AT;
        delete t.PAID_BY;
        delete t.REVERT_REMARKS;
        delete t.READY_DATE;
        delete t.LINE_TYPE;
        delete t.CREATED_BY;
        delete t.UPDATED_AT;
        delete t.UPDATED_BY;
        delete t.REQ_CREATED_AT;
        delete t.REQUEST_BY_FIRST;
        delete t.REQUEST_BY_LAST;
        delete t.REQUEST_BY_PHONE;
        delete t.REQUEST_BY_EMAIL;
        delete t.E_ACCOUNT_TYPE;
        delete t.APPROVER_ID;
        delete t.ACTIVITY;
        delete t.REQUEST_DETAILS;
        delete t.AMT_APPROVED;
        delete t.M_CLIENT;
        delete t.CREATED_AT;
        delete t.M_FLOW;
        delete t.M_ACTIVITY;
        delete t.REQ_REMARKS;
        delete t.PLAYER_TYPE;
        delete t.PLAYER_CODE;
        delete t.APPROVE_DATE;
        delete t.APPROV_EXPENSE_HEAD;
        delete t.APPROV_REMARK;
        delete t.PAID_REMARKS;
        delete t.BANK;
        delete t.PYMT_MODE;
        delete t.APPROVER_NAME;
        delete t.PAID_NAME;
        delete t.E_IFSC;
        delete t.E_IFSC;
        delete t.V_IFSC;
        delete t.E_BANK;
        delete t.V_BANK;
        delete t.E_BRANCH;
        delete t.V_ACCOUNT_TYPE;
        delete t.V_BRANCH;
        delete t.approv_status;
        delete t.STATUS;
        delete t.PAY_PAID_ID;
        delete t.PAY_APPROVE_ID;
        delete t.READY_EXPENSE_HEAD;

        t.VOUCHER_TYPE = "Bank Payment";
        t.VOUCHER_NO = this.state.counts++;
        t.DrCr = "Dr";
        t.TRANSACTION_TYPE = "";
        t.CROSS_USING = "";
        t.NARRATION =
          t.MULTI_REF_IDS +
          " | " +
          t.ACTIVITY_INFO +
          " | " +
          t.M_HUB +
          " | " +
          t.REQ_REMARKS +
          " | " +
          t.PLAYER_CODE;

        if (
          t.E_ACCOUNT_NO === null ||
          t.E_ACCOUNT_NO === "" ||
          t.PAY_ACC_DEBIT_DATE ||
          t.M_ACTIVITY ||
          t.M_HUB
        ) {
          t.NAME = t.V_NAME;
          t.LEDGER = t.M_ACTIVITY;
          t.VOUCHER_DATE = await convertyyyymmddToddmmyyyy(
            t.PAY_ACC_DEBIT_DATE
          );
          t.HUB = t.M_HUB;
        } else {
          t.NAME = t.E_NAME;
        }
        if (
          t.MULTI_REF_IDS === null ||
          t.MULTI_REF_IDS === "" ||
          t.MULTI_REF_IDS === "null"
        ) {
          t.MULTI_REF_IDS = t.REF_ID;
        } else {
          t.MULTI_REF_IDS = t.MULTI_REF_IDS;
        }
        delete t.M_HUB;
        delete t.ACTIVITY_INFO;
        delete t.E_ACCOUNT_NO;
        delete t.V_ACCOUNT_NO;
        delete t.E_NAME;
        delete t.V_NAME;
        delete t.PAY_ACC_DEBIT_DATE;
        delete t.REF_ID;
        delete t.M_CLIENT;
        delete t.M_FLOW;

        return t;
      })
    );
    this.setState({ formatTallyDb: formatedTally });
    return this.state.formatTallyDb;
  };

  formatTallyCredit = async (r) => {
    let formatedTallyCr = await Promise.all(
      r.filter(async (t) => {
        if (t.DrCr == "Dr") {
          t.DrCr = "Cr";
          t.MULTI_REF_IDS = "";
          t.NAME = "";
          t.TRANSACTION_TYPE = "E-fund transfer";
          t.UTR_DATE = t.VOUCHER_DATE;
          t.CROSS_USING = "AC/PAYEE";
        }
        return t;
      })
    );
    this.setState({ formatTallyCr: formatedTallyCr });
    return this.state.formatTallyCr;
  };

  arrayFun = async (arrDr, arrCr) => {
    this.myArr = [...arrDr, ...arrCr];
  };

  handlePageChange = (pageNo) => {
    this.props.history.push("/v1/request/PaymentReports/" + pageNo);
    this.getPayments(this.state.data, pageNo);
  };

  onFileTextChanged = (e) => {
    const value = e.target.value;
    this.setState(() => ({
      fileName: value,
    }));
  };

  request_status = [
    { value: "all", label: "All" },
    { value: "open", label: "open" },
    { value: "closed", label: "closed" },
  ];

  payment_status = [
    { value: "all", label: "All" },
    { value: "open", label: "open" },
    { value: "approved", label: "approved" },
    { value: "down", label: "down" },
    { value: "paid", label: "paid" },
    { value: "ready", label: "ready" },
    { value: "reject", label: "reject" },
  ];

  render() {
    const { pageNo, perPage, count } = this.state.pageLinks;
    //const reports = this.state.csvReport.paymentReports;
    return (
      <React.Fragment>
        <ZTitle subHeading="Expenses :: Payment Reports" />
        <div className="pageContent">
          <div className="card">
            <div className="card-body">
              <form id="myForm" onSubmit={this.handleSubmit}>
                <div className="form-row">
                  {this.rDP("Start Date *", "start_date", "col-md-3")}
                  {this.rDP("End Date *", "end_date", "col-md-3")}
                  {this.rTB("Employee Code", "emp_code", "col-md-3")}
                  {this.rTB("Reference No", "ref_id", "col-md-3")}
                  {this.rSB(
                    "Hub",
                    "hub",
                    this.state.hubs,
                    { value: "hubId", label: "hub" },
                    " col-md-3 "
                  )}
                  {/* {this.rSB(
                    "Client",
                    "client",
                    this.state.clients,
                    { value: "clientId", label: "client" },
                    " col-md-3 "
                  )} */}

                  {/* {this.rSB(
                    "Flow",
                    "flow",
                    this.state.flows,
                    { value: "flowId", label: "flow" },
                    " col "
                  )} */}
                  {this.rSB(
                    "Activity",
                    "activity",
                    this.state.activities,
                    { value: "EXPENSE_HEAD_ID", label: "EXPENSE_HEAD" },
                    " col "
                  )}

                  {this.rSB(
                    "Request Status",
                    "request_status",
                    this.request_status,
                    { value: "value", label: "label" },
                    " col "
                  )}

                  {this.rSB(
                    "Payment Status",
                    "payment_status",
                    this.payment_status,
                    { value: "value", label: "label" },
                    " col "
                  )}
                </div>
                <div className="col-9">
                  {this.rBtnS("Submit", "Submit", "btn btn-primary  btn-sm")}
                </div>
              </form>
              <br />

              <div className="row">
                {this.state.loading && (
                  <div className="container-fluid">
                    <div> Loading Report...</div>
                  </div>
                )}
                {!this.state.loading && count > 0 && (
                  <div className="container-fluid">
                    <div
                      className="col-3 form-group "
                      style={{ "margin-left": "auto" }}
                    >
                      <input
                        value={this.state.fileName}
                        name="fileName"
                        onChange={this.onFileTextChanged}
                        type="text"
                        autoComplete="off"
                      />
                      <label htmlFor="fileName" className="control-label">
                        FileName *
                      </label>
                      <i className="bar"></i>
                      <br />
                      <ExportXLSXReqTrans
                        style={{ "margin-left": "auto" }}
                        csvData={this.state.formatTrx}
                        header={this.excelHeaders}
                        fileName={
                          this.state.fileName + "-" + this.getDatebyDays(0)
                        }
                      />
                      <ExportXLSXTally
                        csvData={this.myArr}
                        header={this.excelHeadersTally}
                        fileName={
                          this.state.fileName + "-" + this.getDatebyDays(0)
                        }
                      />
                    </div>

                    <div style={{ overflow: "auto" }}>
                      <div className="fixTableHead">
                        <Table
                          clsName="table table-striped table-bordered table-hover"
                          tableMeta={this.tableMeta}
                          data={this.state.csvReport.paymentReports}
                        />
                      </div>

                      <Pagination
                        pageNo={pageNo}
                        perPage={perPage}
                        itemsCount={count}
                        onPageChange={this.handlePageChange}
                      />
                    </div>
                    <br />
                  </div>
                )}
                <br />
                {!this.props.loading && count === 0 && (
                  <div className="alert alert-info col-12" role="alert">
                    <div className="text-center"> No Records Found! </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
