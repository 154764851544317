import React, { Component } from "react";
import { getSlNoByItemAndBaysId } from "../../../../services/stockManagement/inventory";
import FormLib from "../../../_common/FormLib";
import toast from "../../../../services/_toastService";
import StroresBaysItems from "../StoresBaysItems";
import Table from "../../../_common/Table";
import { getItemStockListByHubId } from "../../../../services/stockManagement/inwardInventoryServices";

class InventorySlNo extends FormLib {
  state = {
    data: {
      itemId: "",
      hubId: "",
      storeBaysId: "",
      slNoId: "",
    },
    storeBays: [],
    barcodeDataList: [],
  };

  
  tableMeta = {
    ket: "slNoId",
    columns: [
      { path: "slNo", label: "Barcode" },
      { path: "item", label: "ItemName" },
      { path: "status", label: "BarcodeStatus" },
      // { path: "baysName", label: "bay" },
      // { path: "storeBaysId", label: "bayId" },
    ],
  };

  render() {
    const barcodeDataList = this.props.barcodeSlNo;
    // console.log({barcodeDataList});

    return (
      <div className="container-fluid">
        <div className="modal fade" id="myBarcodeModalSlNo">
          <div className="modal-dialog modal-md">
            <div className="modal-content" style={{ maxHeight: "100vh" }}>
              <div className="modal-header">
                <h4 className="modal-title">BarcodeDetails</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body ">
                <div
                  className="fixTableHead"
                  style={{ height: "32rem", overflow: "scroll" }}
                >

                {barcodeDataList&& barcodeDataList.length ===0 && (
                  <p>No Data Found</p>
                )}
                  {barcodeDataList && barcodeDataList.length > 0 && (
                    <Table
                      clsName="table table-striped tabele-bordered table-hover"
                      tableMeta={this.tableMeta}
                      data={barcodeDataList}
                      dropMenu={[]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default InventorySlNo;
