import React from "react";
import FormLib from "./../../_common/FormLib";

class HubStores extends FormLib {
  state = {
    data: {
      storeId: "",
      hubId: "",
    },
    errors: {},
    curHub: {},
  };

  isCur(id, cur) {
    if (id === cur) {
      return " curTasks ";
    }
    return " ";
  }

  componentDidMount() {
    this.setCurHub(this.props.curHub);
  }
  setCurHub(p) {
    this.setState({ curHub: p });
  }
  setCurStore(m) {
    if (m.storeId !== "") {
      this.setState({ data: m });
    } else {
      this.resetData();
    }
    // console.log(JSON.stringify(this.state.data));
  }

  resetData() {
    this.setState({
      data: {
        hubId: "",
        storeId: "",
      },
    });
  }
  render() {
    const stores = this.props.stores;
    const curHub = this.props.curHub;
    const curStore = this.props.curStore;

    return (
      <div className="card  col-2" style={{ margin: 0, padding: 2 }}>
        <h5 className="card-header">Stores</h5>

        {stores && (
          <div className="card-body" style={{ padding: 2 }}>
            <div className="page-todo">
              <div className="tasks">
                {stores.map((mi) => (
                  <div className={"task nil "} key={mi.storeId}>
                    {/* +                      this.isCur(mi["storeId"], curStore["storeId"]) */}
                    <div className="desc">
                      <span
                        className="span-click"
                        onClick={() => this.props.onStoreChange(mi)}
                      >
                        <div className="title" style={{ fontSize: "13px" }}>
                          {mi.store}
                        </div>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default HubStores;
