import React from "react";
import { NavLink } from "react-router-dom";
import config from "../../config.json"

function Li({ label, to, faIcon, clsName = "" }) {
  return (
    <li>
      <NavLink to={to} className="" activeClassName="active-now">
        <span className="fa-stack  fa-lg pull-left">
          <i className={"fa " + faIcon + " fa-stack-1x "}></i>
        </span>
        {label}
      </NavLink>
    </li>
  );
}


function Link({ label, to, faIcon, clsName = "", app="JS" }) {
  
  if (app === "TS"){
    return (
  <li>
      <a href= {config[config.build]["apiDirect"]+to} className="">
        <span className="fa-stack  fa-lg pull-left">
          <i className={"fa " + faIcon + " fa-stack-1x "}></i>  
        </span>
        {label}
      </a>
  </li>
  );
  }
  
  
  return (
      <li>
      <NavLink to={to} className="" activeClassName="active-now">
        <span className="fa-stack  fa-lg pull-left">
          <i className={"fa " + faIcon + " fa-stack-1x "}></i>
        </span>
        {label}
      </NavLink>
    </li>
  
  );
}



export default Link;
