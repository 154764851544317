import React, { forwardRef } from 'react'
import Barcode from 'react-barcode';
import dateFormat from "dateformat";
import html2canvas from 'html2canvas';
import FormLib from "./../../_common/FormLib";

// const { packingNo } = props;
// const now = new Date();
// const localTimeStamp = now.toLocaleString()
// console.log(now.toLocaleString())
// const fDate = dateFormat(now.toLocaleString(), "isoDateTime")
// console.log(fDate);


export default class BarCodePrintSVG extends FormLib {
    state = {
        data: {},
        errors: {},
        curProductDetails: [],
        curTicketDetails: [],
        curBlockDetails: [],


    };

    onclick = (event) => {
        try {

            var content = document.getElementById("barcode-content");
            var pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(content.innerHTML);
            pri.document.close();
            pri.focus();
            pri.print();
            pri.close();
        }
        catch {
            console.log("Error in BarcodePrintSVG>>>>>>>>onClick");
        }
    }

    onClickPrintLabel = (event) => {
        try {
            const content = document.getElementById("labelContentToPrint");
            const pri = document.getElementById("ifmcontentstoprint").contentWindow;
            pri.document.open();
            pri.document.write(content.innerHTML);
            pri.document.close();
            pri.focus();
            pri.print();
            pri.close();
        }
        catch {
            console.log("Error in BarcodePrintSVG>>>>>>>>onClickPrintLabel");
        }

    }


    componentDidMount() {
        this.loadInit();
    }

    loadInit() {
        try {

            const printBarcode = this.props.printBarcode;
            const curProductDetails = this.props.productDetails;
            const curTicketDetails = this.props.ticketDetails;
            const curBlockDetails = this.props.blockDetails
            this.setState({ curProductDetails });
            this.setState({ curTicketDetails });
            this.setState({ curBlockDetails });
            this.setState({ printBarcode });
            const now = new Date();
            const localTimeStamp = now.toLocaleString();
            const fDate = dateFormat(now.toLocaleString(), "isoDateTime");
        }
        catch {
            console.log("Error in BarcodePrintSVG>>>>>>>>loadInit");
        }

    }

    render() {
        // console.log({curTicketDetails:  this.props.ticketDetails})
        // console.log({curBlockDetails:  this.props.blockDetails})
        // console.log({curProductDetails:  this.props.productDetails})
        const productDetails = this.state.curProductDetails;
        return (
            <>
                <div className="container mt-4" id='barcode-content'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            width: 'auto',
                            margin: '0',
                            padding: '0',
                            transform: 'scale(0.9)'
                        }}

                    >

                        <Barcode
                            fontSize={8}
                            margin={0}
                            marginTop={1}
                            marginBottom={3}
                            width={1}
                            height={15}

                            value={'' + this.state.printBarcode} />


                        <span
                            style={{
                                fontFamily: 'monospace',
                                fontSize: '8px'
                            }}
                        >
                            {this.state.curTicketDetails.ticketNo}
                        </span>

                        <Barcode
                            fontSize={8}
                            margin={0}
                            marginTop={3}
                            marginBottom={0}
                            height={20}
                            width={1.1}

                            value={this.state.curTicketDetails.conComplaintNo} />

                    </div>

                </div>
                <button
                    type='button'
                    className='mt-5 btn btn-outline-secondary'
                    onClick={this.onclick}
                    style={{ marginLeft: "43%" }}
                    title="Print Barcode"
                >Print Barcode</button>
                

            <div className='border border-2 col-7 mt-3'>
                <div id='labelContentToPrint'>
                    <div
                    style={{
                        width: "350px",
                        height:  "495px",
                        padding: "10px",
                    }}
                    >
                        <div
                        style={{
                            marginBottom: "1rem",
                            paddingInline: "1.5rem",
                            display: "flex",
                            justifyContent: "center",
                            marginTop:"0.5rem"
                        }}>


                     <Barcode
                           
                            margin={0}
                            marginTop={3}
                            marginBottom={0}
                            height={30}
                            displayValue= {false}
                            background="transparent"
                            value={this.state.curTicketDetails.conComplaintNo} />
                        </div>

                        <div 
                        className='d-flex justify-content-between mb-1 row'
                        style={{
                            fontWeight: "bold",
                            fontSize: "12px",
                            marginBottom: "10px",
                           
                        }}>
                            <div className='d-flex flex-column col-8'>

                            <p>ORDER NO: {this.state.curTicketDetails.conComplaintNo}</p>
                            <p>
                            <span style={{
                                fontWeight: "bold",
                                fontSize: "12px"
                            }}>BP CODE NO: </span>
                            <span
                            style={{
                                fontSize: "1.1rem",
                                fontWeight: "normal",
                            }}
                            >
                                {this.state.curTicketDetails.ticketNo}
                            </span>
                            </p>
                            </div>

                            <div
                            className='col-3'
                            style={{
                                border: "2px solid black",
                                width: "100px",
                                heigh: "100px",
                                maxwidth: "60%",
                                padding: "1rem",
                                fontWeight: "bold",
                                textAlign: 'justify',
                                alignSelf: "center",
                               
                            }}>
                                <span>BOX NO</span>
                                <br />
                                <span>{`${this.state.curTicketDetails.conComplaintNo}/1`}</span>
                            </div>
                        </div>
                        <div>
                            
                        </div>
                        <div>
                            <p style={{
                                fontWeight: "bold",
                                fontSize: "12px",
                                marginBottom: "0"
                            }}>SHIP TO</p>
                            <p>NAME: {
                            this.state.curProductDetails[0]?.dstContactPerson ? this.state.curProductDetails[0]?.dstContactPerson : ""
                            }</p>   
                        </div>

                        <div>
                            <p
                            className='mb-1'
                            ><span style={{
                                 fontWeight: "bold",
                                fontSize: "12px",
                                marginBottom: "0",
                            }}>ADDRESS: </span>
                            {this.state.curProductDetails[0]?.dstAdd1
                                    ? this.state.curProductDetails[0]?.dstAdd1
                            : ""}
                            </p>
                        
                            <p
                             className='mb-1'
                            
                            ><span style={{
                                 fontWeight: "bold",
                                fontSize: "12px",
                                marginBottom: "0"
                            }}>CITY: </span>
                            {this.state.curProductDetails[0]?.dstCity
                                    ? this.state.curProductDetails[0]?.dstCity
                            : ""}
                            </p>
                       
                            <p>
                                <span style={{
                                 fontWeight: "bold",
                                fontSize: "12px",
                               
                            }}>PIN CODE: </span>
                            {this.state.curProductDetails[0]?.dstPincode
                                    ? this.state.curProductDetails[0]?.dstPincode
                            : ""}
                            </p>
                        </div>
                       
                       <div>
                        <p
                         className='mb-1'
                        >
                            <span style={{
                                 fontWeight: "bold",
                                fontSize: "12px"
                            }}>
                               {" ITEM CODE: "}
                            </span>
                            {this.state.curProductDetails[0]?.modelId
                                    ? this.state.curProductDetails[0]?.modelId
                            : ""}
                        </p>

                        <p
                         className='mb-1'
                        >
                            <span style={{
                                 fontWeight: "bold",
                                fontSize: "12px"
                            }}>
                                {"DESCRIPTION: "}
                            </span>
                            {this.state.curProductDetails[0]?.productDesc
                                    ? this.state.curProductDetails[0]?.productDesc
                                    : ""}
                        </p>

                        <p>
                            <span style={{
                                 fontWeight: "bold",
                                fontSize: "12px"
                            }}>
                               {" PRODUCT NAME: "}
                            </span>
                            {this.state.curProductDetails[0]?.productName
                                    ? this.state.curProductDetails[0]?.productName
                                    : ""}
                        </p>

                       </div>
                       <div>
                        <p>
                            <span style={{
                                 fontWeight: "bold",
                                fontSize: "12px"
                            }}>
                               { "PICKUP LOCATION: "}
                            </span>
                            {this.state.curProductDetails[0]?.dstLocation

                                    ? this.state.curProductDetails[0]?.dstLocation

                                    : ""}
                        </p>
                       </div>

                    </div>
                              
                </div>
                </div>
                <button
                    type='button'
                    className='mt-5 btn btn-outline-secondary'
                    onClick={this.onClickPrintLabel}
                    style={{ marginLeft: "43%" }}
                    title="Print Label"
                >Print Label</button>  
            </>
        );
    }
}
