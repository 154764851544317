import React from "react";
// import swal from "sweetalert";
import FormLib from "../../../_common/FormLib";
import LocationTitle from "../../../_subhead/LocationTitle";
import _auth from "../../../../services/_authService";
import toast from "../../../../services/_toastService";
// import { detachBarcodeStatus, getPackingMaterials, updateBarcodeStatus } from "../../../../services/packing/PackingMaterialService";
import { getTeams } from "../../../../services/players/teamServices";
// import { getAvailableCountByHubIdPackMaterialId, getBarcodesByPackMaterialId, getDamagedCountByHubIdPackMaterialId, getIssuedCountByHubIdPackMaterialId, getScrapedCountByHubIdPackMaterialId, searchBarcode } from "../../../../services/packing/BarcodeService";
import { searchBarcode } from "../../../../services/packing/BarcodeService";
import Table from "../../../_common/Table";
import Pagination from "../../../_common/Pagination";
// import ModalPackingMaterial from "./ModalPackingMaterial";
import ModalBarcodeSE from "./ModalBarcodeSE";
import ModalBarcodeSN from "./ModalBarcodeSN";
import ModalBarcodeN from "./ModalBarcodeN";
import { getBarcodesReport } from "../../../../services/stockManagement/barcodeServices";
import ModalBarcodeStatus from "./ModalBarcodeStatus";
import { getPlayersbyRole } from "../../../../services/Tickets/TicketServices";
import swal from "sweetalert";
import { getRetailerInfo } from "../../../../services/TicketHistory/TicketHistory";
// import ModalBarcodeTransfer from "./ModalBarcodeTransfer";
// import ModalUpdateBulkBarcodeStatus from "../../packing/ModalUpdateBulkBarcodeStatus";

class BarcodeMaster extends FormLib {
  state = {
    data: {
      hubId: "",
      status: "00available",
      teamId: "",
      barcodeType: "sticker",
      searchString: "",
      fromScan: "",
      toScan: ""
      //  packMaterialId: ""
    },
    resData: {
      barcodeId: "",
      barcodeType: "",
      // packMaterialId: "",
      // materialType: "",
      // packCode: "",
      searchString: "",
      barCode: "",
      hubId: "",
      teamId: "",
      processId: "",
      status: "",
    },
    errors: {},

    teams: [],
    packingmaterials: [],
    barcodes: [],
    // curPackingMaterial: { packMaterialId: "" },
    curBarcode: { barcodeId: "" },
    curHubId: "",
    curTeamId: "",
    loading: true,
    barcodeDetails: {},
    playersList: [],
    updateBarcodeStatus: false,
    bulkDamageStatus: false,
    bulkProps: false,
    selectAll: false,
    checked: false,
    checkedArr: [],
    barcodeArr: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      count: 0,
      lastPage: 0,
    },
    // type: [
    //   {
    //     typeId: "Cover",
    //     type: "Cover",
    //   },
    //   {
    //     typeId: "Box",
    //     type: "Box",
    //   },
    //   {
    //     typeId: "Pouch",
    //     type: "Pouch",
    //   },
    //   {
    //     typeId: "Wrap",
    //     type: "Wrap",
    //   },
    //   {
    //     typeId: "Tape",
    //     type: "Tape",
    //   },
    //   {
    //     typeId: "Others",
    //     type: "Others",
    //   },
    // ],

    status: [
      {
        statusId: "00available",
        status: "available",
      },
      // {
      //   statusId: "10issued",
      //   status: "issued",
      // },
      {
        statusId: "05used",
        status: "used",
      },
      {
        statusId: "04damaged",
        status: "damaged",
      },
      // {
      //   statusId: "50scraped",
      //   status: "scraped",
      // },
    ],
  };

  componentDidMount() {
    // this.loadPackingMaterial();
    this.loadHubs();
    this.loadTeams();
    const user = _auth.getCurrentUser();
    this.setState({ user });
  }

  // async loadPackingMaterial() {
  //   this.setState({ loading: true });
  //   const res = await getPackingMaterials();
  //   // if (res !== undefined) {
  //   //   if (res.data.success === true) {
  //   //     this.setState({ packingmaterials: res.data.data.packingMaterials });
  //   //     // toast.toastSuccess("Packing Material Loaded Successfully");
  //   //   } else {
  //   //     toast.toastError("failed");
  //   //   }
  //   // }
  //   if (res !== undefined) {
  //     if (res.data.success === true) {
  //       this.setState({ packingmaterials: res.data.data.packingMaterials });

  //       this.state.data.packMaterialId = res.data.data.packingMaterials[4]['packMaterialId'];

  //       this.setState({ curPack: res.data.data.packingMaterials[4] });

  //       this.setState({
  //         data: {
  //           packMaterialId: res.data.data.packingMaterials[4]['packMaterialId'],
  //           hubId: this.state.data.hubId,
  //           teamId: this.state.data.teamId,
  //           status: this.state.data.status
  //         }
  //       });
  //     } else {
  //       toast.toastError("failed");
  //     }
  //   }
  //   this.setState({ loading: false });
  // }
  // async loadHubs() {
  //   this.setState({ loading: true });
  //   const res = await getHubsAll();
  //   if (res !== undefined) {
  //     if (res.data.success === true) {
  //       this.setState({ hubs: res.data.data.hubs });
  //     } else {
  //       toast.toastError(res.data.msg);
  //     }
  //   }
  //   this.setState({ loading: false });
  // }
  async loadHubs() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({ designation: userInfo.designation });

    let hubList = JSON.parse(localStorage.getItem("hubs"));
    this.setState({ hubList });
    let curHub = localStorage.getItem("curHub");
    if (!curHub) {
      let override = this.state.data;
      override["hubId"] = JSON.parse(JSON.stringify(hubList[0].hubId));
      this.setState({ override });
    } else {
      let override = this.state.data;
      override["hubId"] = curHub;
      this.setState({ override });
    }
  }

  async loadTeams() {
    this.setState({ loading: true });
    // const res = await getTeams();
    // // if (res !== undefined) {
    // //   if (res.data.success === true) {
    // //     this.setState({ teams: res.data.data.teams });
    // //   } else {
    // //     toast.toastError(res.data.msg);
    // //   }
    // // }
    // if (res !== undefined) {
    //   if (res.data.success === true) {
    //     this.setState({ teams: res.data.data.teams });
    //     this.state.data.teamId = res.data.data.teams[8]["teamId"];

    //     this.setState({ curTeam: res.data.data.teams[8] });

    //     this.setState({
    //       data: {
    //         ...this.state.data,
    //         // hubId: this.state.data.hubId,
    //         teamId: res.data.data.teams[8]["teamId"],
    //         // status: this.state.data.status,
    //         // packMaterialId: this.state.data.packMaterialId
    //       },
    //     });
    //   } else {
    //     toast.toastError(res.data.msg);
    //   }
    // }

    try {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let playerId = userInfo.playerId;
      let resp = await getRetailerInfo(playerId);
      if (resp !== undefined) {
        if (
          resp.data.success === true &&
          resp.data.data.retailersList.length >= 1
        ) {
          this.setState({ teams: resp.data.data.retailersList });
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in retailerList");
    }
    this.setState({ loading: false });
  }


  localHandleChange = (e) => {
    if (e.target.id === "prvDivs") {
      this.handleDivisionChange(e);
    }
  };

  // loadBarcodesByPackMaterialId = async (packMaterialId, pageNo) => {
  //   let curPackingMaterial;
  //   const filter = this.state.packingmaterials.filter((r) => {
  //     return r.packMaterialId === packMaterialId ? r : null;
  //   });
  //   if (!filter[0]) {
  //     curPackingMaterial = { packMaterialId: "" };
  //   } else {
  //     curPackingMaterial = filter[0];
  //   }
  //   this.setState({ curPackingMaterial: curPackingMaterial });
  //   this.setState({ loading: true });
  //   const res = await getBarcodesByPackMaterialId(curPackingMaterial.packMaterialId, pageNo);
  //   if (res !== undefined) {
  //     if (res.data.data.length != 0) {
  //       this.setState({ barcodes: res.data.data.barcodes });
  //       this.setState({ pageLinks: res.data.data.pageLinks });
  //       toast.toastSuccess(res.data.msg);
  //     }
  //     else {
  //       toast.toastInfo("No Barcode found for this Packing Material");
  //       this.setState({ barcodes: [] });
  //       this.setState({
  //         pageLinks: {
  //           pageNo: 1,
  //           perPage: 0,
  //           itemsCount: 0,
  //           lastPage: 0,
  //         },
  //       });
  //     }
  //   }
  //   this.setState({ loading: false });
  // };

  loadBarcodes = async (searchLoad) => {
    try {
      this.setState({ selectAll: false });
      this.setState({ loading: true });
      const params = {
        clientId: this.state.data.teamId,
        hubId: this.state.data.hubId,
        status: this.state.data.status,
        barcodeType: this.state.data.barcodeType,
        fromScan: this.state.data.fromScan,
        toScan: this.state.data.toScan,
        searchString: this.state.data.searchString,
      };

      if (params.fromScan > params.toScan && this.state.data.toScan !== "") {
        swal("From Scan should be greater than To Scan", { icon: "error" });
      }
      else if ((params.toScan - params.fromScan) > 49) {
        console.log(params.toScan - params.fromScan)
        swal("Length Should be less than 50", { icon: "error" })
      }

      else if (params.toScan === "") {
        params.toScan = params.fromScan;
        const pageLinks = this.state.pageLinks;
        pageLinks.pageNo = 1;
        this.setState({ pageLinks });
        if (this.state.data.searchString === "") {
          const resp = await getBarcodesReport(
            params,
            this.state.pageLinks.pageNo
          );
          if (resp.data.success) {
            this.setState({ barcodes: resp.data.data.barcodeSerialReports });
            this.setState({ pageLinks: resp.data.data.pageLinks });
            // alert(JSON.stringify(resp.data.data.pageLinks));
            toast.toastSuccess(resp.data.msg);
          } else {
            toast.toastInfo("No Barcodes data");
            this.setState({ barcodes: [] });
            this.setState({
              pageLinks: {
                pageNo: 1,
                perPage: 0,
                count: 0,
                lastPage: 0,
              },
            });
          }
        } else {
          const resp = await getBarcodesReport(params, 1);
          if (resp.data.success) {
            this.setState({ barcodes: resp.data.data.barcodeSerialReports });
            this.setState({ pageLinks: resp.data.data.pageLinks });
            // alert(JSON.stringify(resp.data.data.pageLinks));
            toast.toastSuccess(resp.data.msg);
          } else {
            toast.toastInfo("No Barcodes data");
            this.setState({ barcodes: [] });
            this.setState({
              pageLinks: {
                pageNo: 1,
                perPage: 0,
                count: 0,
                lastPage: 0,
              },
            });
          }
        }

        this.setState({ loading: false });
        return;
      }

      else {
        const pageLinks = this.state.pageLinks;
        pageLinks.pageNo = 1;
        this.setState({ pageLinks });
        if (this.state.data.searchString === "" && (params.fromScan === "" && params.toScan === "")) {
          this.setState({ fromScan: "" });
          this.setState({ toScan: "" });

          const resp = await getBarcodesReport(
            params,
            this.state.pageLinks.pageNo
          );
          if (resp.data.success) {
            this.setState({ barcodes: resp.data.data.barcodeSerialReports });
            this.setState({ pageLinks: resp.data.data.pageLinks });
            // alert(JSON.stringify(resp.data.data.pageLinks));
            toast.toastSuccess(resp.data.msg);
          } else {
            toast.toastInfo("No Barcodes data");
            this.setState({ barcodes: [] });
            this.setState({
              pageLinks: {
                pageNo: 1,
                perPage: 0,
                count: 0,
                lastPage: 0,
              },
            });
          }
        }
        else if (this.state.data.searchString === "" && params.fromScan !== "" && params.toScan !== "") {
          // alert('call')
          this.setState({ selectAll: true })
          const resp = await getBarcodesReport(params, 1);
          if (resp.data.success) {
            this.setState({ barcodes: resp.data.data.barcodeSerialReports });
            this.setState({ pageLinks: resp.data.data.pageLinks });
            // alert(JSON.stringify(resp.data.data.pageLinks));
            toast.toastSuccess(resp.data.msg);
          } else {
            toast.toastInfo("No Barcodes data");
            this.setState({ barcodes: [] });
            this.setState({
              pageLinks: {
                pageNo: 1,
                perPage: 0,
                count: 0,
                lastPage: 0,
              },
            });
          }
        }
        else {
          const resp = await getBarcodesReport(params, 1);
          if (resp.data.success) {
            this.setState({ barcodes: resp.data.data.barcodeSerialReports });
            this.setState({ pageLinks: resp.data.data.pageLinks });
            // alert(JSON.stringify(resp.data.data.pageLinks));
            toast.toastSuccess(resp.data.msg);
          } else {
            toast.toastInfo("No Barcodes data");
            this.setState({ barcodes: [] });
            this.setState({
              pageLinks: {
                pageNo: 1,
                perPage: 0,
                count: 0,
                lastPage: 0,
              },
            });
          }
        }

        this.setState({ loading: false });
        return;
      }
    } catch {
      console.log("Error in loadBarcodes");
      this.setState({ loading: false });
    }
  };

  loadBarcodesPage = async (searchLoad) => {
    try {
      this.setState({ loading: true });
      const params = {
        clientId: this.state.data.teamId,
        hubId: this.state.data.hubId,
        status: this.state.data.status,
        barcodeType: this.state.data.barcodeType,
        fromScan: this.state.data.fromScan,
        toScan: this.state.data.toScan,
        searchString: this.state.data.searchString,
      };

      if (params.fromScan > params.toScan && this.state.data.toScan !== "") {
        swal("From Scan should be greater than To Scan", { icon: "error" });
      }
      if (this.state.data.searchString === "") {
        const resp = await getBarcodesReport(
          params,
          this.state.pageLinks.pageNo
        );
        if (resp.data.success) {
          this.setState({ barcodes: resp.data.data.barcodeSerialReports });
          this.setState({ pageLinks: resp.data.data.pageLinks });
          // alert(JSON.stringify(resp.data.data.pageLinks));
          toast.toastSuccess(resp.data.msg);
        } else {
          toast.toastInfo("No Barcodes data");
          this.setState({ barcodes: [] });
          this.setState({
            pageLinks: {
              pageNo: 1,
              perPage: 0,
              count: 0,
              lastPage: 0,
            },
          });
        }
      } else {
        const resp = await getBarcodesReport(params, 1);
        if (resp.data.success) {
          this.setState({ barcodes: resp.data.data.barcodeSerialReports });
          this.setState({ pageLinks: resp.data.data.pageLinks });
          // alert(JSON.stringify(resp.data.data.pageLinks));
          toast.toastSuccess(resp.data.msg);
        } else {
          toast.toastInfo("No Barcodes data");
          this.setState({ barcodes: [] });
          this.setState({
            pageLinks: {
              pageNo: 1,
              perPage: 0,
              count: 0,
              lastPage: 0,
            },
          });
        }
      }

      this.setState({ loading: false });
      return;
    } catch {
      console.log("Error in loadBarcodes");
      this.setState({ loading: false });
    }
  };
  // availableCount = async (searchLoad) => {
  //   try {

  //     const avail = await getAvailableCountByHubIdPackMaterialId(searchLoad.hubId, searchLoad.packMaterialId);
  //     const iss = await getIssuedCountByHubIdPackMaterialId(searchLoad.hubId, searchLoad.packMaterialId);
  //     const dam = await getDamagedCountByHubIdPackMaterialId(searchLoad.hubId, searchLoad.packMaterialId);
  //     const scrp = await getScrapedCountByHubIdPackMaterialId(searchLoad.hubId, searchLoad.packMaterialId);
  //     // console.log(JSON.stringify(avail.data));
  //     // console.log(JSON.stringify(iss.data));
  //     if (avail.data.data) {
  //       this.setState({ available: avail.data.data.available });
  //     }
  //     if (iss.data.data) {
  //       this.setState({ issued: iss.data.data.issued });
  //     }
  //     if (dam.data.data) {
  //       this.setState({ damaged: dam.data.data.damaged });
  //     }
  //     if (scrp.data.data) {
  //       this.setState({ scraped: scrp.data.data.scraped });
  //     }
  //   }
  //   catch {
  //     console.log("Error in availableCount");
  //   }

  // }


  // handlePackingMaterialChange(e) {
  //   this.loadBarcodesByPackMaterialId(
  //     e.target.value,

  //     this.state.pageLinks.pageNo,
  //   );

  // }

  handlePackMaterialChange = (pageNo) => {
    this.setState({ barcodeArr: [] })
    this.setState({ bulkDamageStatus: false })
    this.props.history.push("/barcodeMaster/" + pageNo);
    const pageLinks = this.state.pageLinks;
    pageLinks.pageNo = pageNo;
    this.setState({ pageLinks });
    this.loadBarcodesPage();
  };
  // handleHubChange = (e) => {
  //   // alert(e.target.value);

  //   this.setState({ curHubId: e.target.value });
  // }

  // localHandleChange = (e) => {
  //   // alert(e.target.id);
  //   if (e.target.id === "hubId") {
  //     this.handleHubChange(e);
  //   }
  //   if (e.target.id === "packMaterialId") {
  //     // this.loadBarcodesByPackMaterialId(
  //     //   e.target.value,
  //     //   this.state.pageLinks.pageNo
  //     // );
  //   }

  // };

  // handleDamage = async (r, status) => {
  //   swal({
  //     title: "Are you sure want to set Damaged?",
  //     text: "Once updated, you will not be able to revert the status",
  //     icon: "error",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then(async (handleUpdate) => {
  //     this.updateStatus(handleUpdate, r, status);
  //   });
  // };

  // handleScrap = async (r, status) => {
  //   swal({
  //     title: "Are you sure want to set Scraped?",
  //     text: "Once updated, you will not be able to revert the status",
  //     icon: "error",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then(async (handleDetach) => {
  //     this.scrapStatus(handleDetach, r, status);
  //   });
  // };

  // handleDetach = async (r, status) => {
  //   swal({
  //     title: "Are you sure want to set detach barcode?",
  //     text: "Once updated, you will not be able to revert the status",
  //     icon: "error",
  //     buttons: true,
  //     dangerMode: true,
  //   }).then(async (handleDetach) => {
  //     this.detachStatus(handleDetach, r, status);
  //   });
  // };

  // async updateStatus(handleUpdate, r, status) {

  //   let param = {
  //     "barcode": r.barCode,
  //     "status": status
  //   }
  //   const res = await updateBarcodeStatus(param);
  //   if (res.data.statusCode === 200) {
  //     toast.toastSuccess(res.data.msg);
  //     this.loadBarcodesPage(
  //       this.state.data,
  //       this.state.pageLinks.pageNo
  //     );
  //   } else {
  //     toast.toastInfo("status not updated");
  //   }

  // }
  // async detachStatus(handleDetach, r, status) {

  //   let param = {
  //     "barcode": r.barCode,
  //     "status": status
  //   }
  //   const res = await detachBarcodeStatus(param);
  //   if (res.data.statusCode === 200) {
  //     toast.toastSuccess(res.data.msg);
  //     this.loadBarcodesPage(
  //       this.state.data,
  //       this.state.pageLinks.pageNo
  //     );
  //   } else {
  //     toast.toastInfo("status not updated");
  //   }

  // }

  // async scrapStatus(handleDetach, r, status) {

  //   let param = {
  //     "barcode": r.barCode,
  //     "status": status
  //   }
  //   const res = await updateBarcodeStatus(param);
  //   if (res.data.statusCode === 200) {
  //     toast.toastSuccess(res.data.msg);
  //     this.loadBarcodesPage(
  //       this.state.data,
  //       this.state.pageLinks.pageNo
  //     );
  //   } else {
  //     toast.toastInfo("status not updated");
  //   }

  // }

  // searStatus = [
  //   { sortKey: "all", sort: "All" },
  //   { sortKey: "active", sort: "Active" },
  //   { sortKey: "inactive", sort: "Inactive" },
  // ];
  // stsConf = {
  //   value: "sortKey",
  //   label: "sort",
  // };
  // searHubId;
  // searBarcodeId;

  onUpdate = async (r) => {
    console.log("r===> data", r);
    const playerList = await getPlayersbyRole(r.hubId);
    if (playerList.data.data.playersHubs.length > 0) {
      this.setState({ playersList: playerList.data.data.playersHubs })
    }
    this.setState({ updateBarcodeStatus: true })
    this.setState({ bulkProps: false })
    this.setState({ barcodeDetails: r })

    const obj = {
      clientId: r.clientId,
      slNoId: r.slNoId,
      barcodeHubId: r.hubId
    }

    this.state.barcodeArr.push(obj);
    // this.setState({bulkDamageStatus: true})
    this.handleStatus()
  }

  closeModal = () => {
    this.setState({ updateBarcodeStatus: false });
    this.setState({ bulkDamageStatus: false });
    this.setState({ barcodeArr: [] });
    this.handleStatus()
  }



  handleStatus = () => {
    if (this.state.barcodeArr.length > 0) {
      console.log(this.state.barcodeArr.length);
      this.setState({ bulkDamageStatus: true })
    }
    else {
      this.setState({ bulkDamageStatus: false })
    }
  }

  handleBulkDamage = () => {
    this.setState({ updateBarcodeStatus: true })
    this.setState({ bulkProps: true })
  }

  selectAllBarcodes = (e) => {
    // console.log("this.state.barcodes", this.state.barcodes);
    if (e.target.checked) {
      const selectAllArr = this.state.barcodes.map((s) => {
        return {
          clientId: s.clientId,
          slNoId: s.slNoId,
          barcodeHubId: s.hubId
        }
      })
      if (selectAllArr.length > 0) {
        this.setState({ checkedArr: this.state.barcodes })
        this.setState({ checked: true })
        this.setState({ barcodeArr: selectAllArr })
        this.setState({ bulkDamageStatus: true })
      }
    } else {
      this.setState({ checkedArr: [] })
      this.setState({ barcodeArr: [] })
      this.setState({ checked: false })
      this.setState({ bulkDamageStatus: false })
    }
  }

  handleBarcodeUpdate = (e, data) => {
    // const barcodeArr = [];
    console.log(this.state.barcodeArr);

    const obj = {
      clientId: data.clientId,
      slNoId: data.slNoId,
      barcodeHubId: data.hubId
    }
    if (e.target.checked) {

      this.state.barcodeArr.push(obj);
      // this.setState({bulkDamageStatus: true})
      this.handleStatus()
    }
    else {
      this.state.barcodeArr.pop(obj);
      this.handleStatus()
    }
  }

  handleCheckBarcode = (e, data) => {
    const isSelected = this.state.checkedArr.includes(data);

    const obj = {
      clientId: data.clientId,
      slNoId: data.slNoId,
      barcodeHubId: data.hubId
    }

    if (e.target.checked) {
      if (this.state.checkedArr.length === 0) {
        this.state.checkedArr.push(data)
        alert(obj.slNoId + 'check')
      }
      else {
        this.state.checkedArr.push(data)
        alert(obj.slNoId + 'unCheck')
      }
    }
    // if(e.target.checked && this.state.selectAll === false){
    //   this.state.checkedArr.push(data)
    //   this.state.barcodeArr.push(obj);
    // }else{
    //   this.state.checkedArr.pop(data)
    //   this.state.barcodeArr.pop(obj);
    // }
    // if(!e.target.checked && this.state.selectAll === true){
    //   this.state.checkedArr.pop(data)
    //   this.state.barcodeArr.pop(obj);
    // }
    // else{
    //   this.state.checkedArr.pop(data)
    //   this.state.barcodeArr.pop(obj);
    // }
    //  if(isSelected){
    //   this.setState({checkedArr: this.state.barcodeArr.filter((i) => i !== data)})

    //  }
    //  else{
    //   // this.setState({
    //   //   barcodeArr: [...this.state.barcodeArr, data],
    //   // });
    //   this.state.barcodeArr.pop(data)
    //  }
  }


  tableMeta = {
    key: "slNo",
    columns: [
      {
        key: "status",
        action: (r) => (
          <>
            {r.status === "00available" && this.state.selectAll === true && (
              <div>
                <input type="checkbox"
                  //  onChange={(e) => this.handleCheckBarcode(e,r)} 
                  checked={this.state.checkedArr.includes(r)}
                  key={r.slNoId} />
              </div>
            )}
            {r.status === "00available" && this.state.selectAll === false && (
              <div>
                <input type="checkbox" onChange={(e) => this.handleBarcodeUpdate(e, r)}
                  key={r.slNoId} />
              </div>
            )}
          </>
        ),
        icon: () => (
          <>
            {this.state.selectAll === true && this.state.data.status === "00available" && (
              <>
                <p>Select All</p>
                <input type="checkbox" onChange={(e) => this.selectAllBarcodes(e)} />
              </>
            )}
          </>
        ),
        label: "Status",
      },
      {
        key: "status",
        action: (r) => (
          <>
            {r.status === "00available" ? (
              <span className="badge badge-success mr-1 ">
                {r.status.replace(/[0-9]/g, "").toUpperCase()}
              </span>
            ) : (
              <span className="badge badge-warning mr-1 ">
                {r.status.replace(/[0-9]/g, "").toUpperCase()}
              </span>
            )}
          </>
        ),
        icon: () => <>Status</>,
        label: "Status",
      },
      // bi bi-check
      { path: "hub", label: "Hub" },
      { path: "client", label: "client" },
      { path: "slNo", label: "Barcode" },
      { path: "barcodeType", label: "Barcode Type" },
      {
        key: "status",
        action: (r) => (
          <>
            {(r.status === "05used") && (
              // <span className="badge badge-success mr-1 ">
              //   {r.status.replace(/[0-9]/g, "").toUpperCase()}
              // </span>
              <button
                className="btn  btn-primary  btn-sm link-over"
                key={r.slNoId}
                onClick={() =>
                  this.onUpdate(r)
                }
              >
                Damage
              </button>
            )}
          </>
        ),
        // icon: () => <i className="fa fa-cogs" aria-hidden="true"> If Damaged</i>,
        label: "Status",
      },
      // { path: "packName", label: "Pack Name" },
      // { path: "packType", label: "Packing Type" },
    ],
  };

  barcodeType = [
    {
      label: "sticker",
      value: "sticker",
    },
    {
      label: "pre-printed",
      value: "prePrinted",
    },
    {
      label: "label",
      value: "label",
    },
  ];
  render() {
    const { pageNo, perPage, count } = this.state.pageLinks;
    // const user = this.state.user;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Barcode Serial Stock::List" />
        <div className="pageContent">
          <div className="row">
            <div className="col-12">
              <div className="row " style={{ marginLeft: "auto" }}>
                {/* <div className="col-sm-2 mt-1">
                  {this.rSBA("Hub", "hubId", this.state.hubList, {
                    value: "hubId",
                    label: "hub",
                  }

                  )}
                </div> */}
                <div className="form-row ">
                  <div>
                    {this.rSBMulti(
                      "Hub",
                      "hubId",
                      this.state.hubList,
                      {
                        value: "hubId",
                        label: "hub",
                      },
                      "col-md-3"
                    )}
                  </div>
                  <div style={{ marginLeft: "1%" }}>
                    {this.rSBMulti(
                      "ClientMis",
                      "teamId",
                      this.state.teams,
                      { value: "teamId", label: "clients" },
                      "col-md-3"
                    )}
                  </div>
                  {/* <div className="col-sm-2 mt-1">
                  {this.rSBA(
                    "Client",
                    "teamId",
                    this.state.teams,
                    { value: "teamId", label: "name" },
                  )}
                </div> */}
                  {/* <div className="col-sm-1 mt-1">
                  {this.rSB(
                    "Type *",
                    "type",
                    this.state.type,
                    { value: "typeId", label: "type" },
                  )}
                </div> */}
                  {/* <div className="col-sm-2 mt-1">
                  {this.rSB(
                    "PackingMaterial",
                    "packMaterialId",
                    this.state.packingmaterials,
                    {
                      value: "packMaterialId",
                      label: "name",
                    },
                  )}
                </div> */}

                  <div className="col-md-1 mt-2" style={{ marginLeft: "1%" }}>
                    {this.rSB("Status *", "status", this.state.status, {
                      value: "statusId",
                      label: "status",
                    })}
                  </div>
                  {/* <div className="col-md-1 mt-1">
                        {this.rSBMulti(
                           "Status *",
                          "status",
                          this.state.status,
                          { value: "statusId", label: "status" },
                         
                        )}
                      </div> */}

                  <div className="col-md-1 mt-2">
                    {this.rSB(
                      "Barcode Type *",
                      "barcodeType",
                      this.barcodeType,
                      {
                        value: "value",
                        label: "label",
                      }
                    )}
                  </div>
                  {this.rTB("From Scan", "fromScan", "col-1 mt-1")}
                  {this.rTB("To Scan", "toScan", "col-1 mt-1")}
                  {this.rTB("Barcode", "searchString", "col-1 mt-1")}
                  <div
                    className="menu col-md-2 mt-1 "
                    style={{ marginRight: "-7%" }}
                  >
                    {/* {this.state.curPackingMaterial["packMaterialId"] === "" && (
                    <button
                      type="button"
                      title="Packing material"
                      className="btn btn-sm btn-info pull-left"
                      data-toggle="modal"
                      data-target="#myMaterialModal"
                    >
                      <i className={"fa  fa-plus  "}></i>
                    </button>
                  )} */}
                    {/* {this.state.curPackingMaterial["packMaterialId"] !== "" && (
                    <button
                      type="button"
                      className="btn btn-sm btn-success pull-left"
                      data-toggle="modal"
                      data-target="#myMaterialModal"
                    >
                      <i className={"fa  fa-pencil"}></i>
                    </button>
                  )} */}
                    {/* <button
                    type="button"
                    className="btn btn-sm btn-secondary pull-left"
                    onClick={() => this.loadPackingMaterial()}
                  >
                    <i className={"fa  fa-refresh  "}></i>
                  </button> */}
                    {/* {this.state.curPackingMaterial["packMaterialId"] !== "" && ( */}
                    <button
                      type="button"
                      className="btn btn-sm btn-success pull-left"
                      data-toggle="modal"
                      data-target="#"
                      onClick={() => this.loadBarcodes(this.state.data)}
                    >
                      <i className={"fa  fa-search "}></i>
                    </button>
                    {/* )} */}
                  </div>
                  {(this.state.designation === "Operations Head" || this.state.designation === "Key Accounts Manager") && (
                    <div
                      className=" col-md-2 mt-1"
                      style={{ marginRight: "-5%" }}
                    >
                      <button
                        className="btn btn-sm btn-info"
                        data-toggle="modal"
                        data-target="#myBarcodeModalSE"
                      >
                        Allocate Barcodes
                      </button>
                    </div>
                  )}
                  {/* <div className="dropdown">
                    <button className="btn btn-sm btn-info dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      Add
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li>
                        {this.state.curBarcode.barcodeId === "" && (
                          <a className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#myBarcodeModalSE"
                            style={{ marginRight: "3px" }}
                          >
                            Barcode Start End
                          </a>
                        )}

                      </li>
                      <li>
                        {this.state.curBarcode.barcodeId === "" && (
                          <a className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#myBarcodeModalSN"
                            style={{ marginRight: "2px" }}>
                            Barcode Start number
                          </a>
                        )}
                      </li>
                      <li>
                        {this.state.curBarcode.barcodeId === "" && (
                          <a className="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#myBarcodeModalN"
                          >
                            Barcode Numbers
                          </a>
                        )}
                      </li>
                    </ul>
                  </div> */}
                </div>
                {/* <div className=" col-md-2">
                  <div class="dropdown">
                    <button class="btn btn-sm btn-warning dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      Actions
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li>
                        {this.state.curPackingMaterial["packMaterialId"] === "" && (

                          <a class="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#myMaterialModal"
                          >
                            Create Packing Material
                          </a>
                        )}
                      </li>
                      <li>
                        {this.state.curBarcode.barcodeId === "" && (
                          <a class="dropdown-item"
                            type="button"
                            data-toggle="modal"
                            data-target="#myBulkBarcodeUpdate"
                          >
                            Bulk Barcodes Status
                          </a>
                        )}
                      </li>
                      <li>
                        {this.state.curBarcode.barcodeId === "" && (
                          <a
                            class="dropdown-item"
                            type="button"
                            title="barcode transfer"
                            data-toggle="modal"
                            data-target="#myBarcodeModalTransfer"
                          >
                            Bulk Barcodes Transfer
                          </a>
                        )}
                      </li>
                    </ul>
                  </div>

                </div> */}
              </div>
            </div>
          </div>

          {/* {this.state.barcodes.length === 0 && (
            <div className="jumbotron jumbotron-fluid">
              <p className="lead text-center">Select Packing Material</p>
            </div>
          )} */}

          {!this.state.loading && this.state.barcodes.length === 0 && (
            <div className="jumbotron jumbotron-fluid">
              <p className="lead text-center">No Barcodes Found</p>
            </div>
          )}

          <div className="row">
            <div className="col-md-9">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Barcode...</div>
                </div>
              )}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {!this.state.loading && this.state.barcodes.length !== 0 && (
                  <div className="container-fluid">
                    <div className="card" style={{ marginRight: "-121px" }}>
                      <div className="card-body">
                        <Table
                          clsName="table table-striped tabele-bordered table-hover "
                          tableMeta={this.tableMeta}
                          data={this.state.barcodes}
                          dropMenu={[]}
                        />

                        <Pagination
                          pageNo={pageNo}
                          perPage={perPage}
                          itemsCount={count}
                          onPageChange={this.handlePackMaterialChange}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{ marginTop: "20px" }}
                >
                  {this.state.bulkDamageStatus === true && this.state.data.status === "00available" && (
                    <button className="btn btn-primary" onClick={() => this.handleBulkDamage()}>Damage</button>
                  )}
                </div>
              </div>
            </div>
            {this.state.updateBarcodeStatus === true && (
              <ModalBarcodeStatus
                closeModal={this.closeModal}
                barcodeDetails={this.state.barcodeDetails}
                playersList={this.state.playersList}
                hubList={this.state.hubList}
                loadDetails={this.loadBarcodes}
                // bulkDamage={this.state.bulkProps}
                bulkDamageArr={this.state.barcodeArr}
              />
              //     <div style={{ height: "30rem", width: "30rem", backgroundColor: "#fff", position: "absolute", marginLeft: "20rem", borderRadius: "8px" }}>
              //     <div className="bg-success mt-1 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              //         <h3 className="text-dark mt-2 ml-2">Update Barcode Status</h3>
              //         <button onClick={this.closeModal} className="btn btn-danger">X</button>
              //     </div>
              //     <div style={{ display: "flex", flexDirection: "row" }}>
              //         <div className="mt-3">
              //             {/* {hubList.length > 0 && (this.rSB("Hubs", "hubs", hubList, {
              //                 value: "hubId",
              //                 label: "hub",
              //             }))} */}
              //             {this.state.playersList.length > 0 && (this.rSB("Players", "players", this.state.playersList, {
              //                 value: "playerId",
              //                 label: "firstName",
              //             }
              //             ))}
              //         </div>
              //         <label className="mt-3"><p className="ml-3 mt-3">Damaged Remarks:</p>
              //             {this.rTB("Remarks", "remarks", "col mb-2")}
              //         </label>

              //     </div>


              // </div>
            )}
            {/* {!this.state.loading && this.state.barcodes.length !== 0 && (
              <div className="col-md-3 mt-3">
                <div className="container-fluid">
                  <div className="card ">
                    <div className="card-body " style={{ backgroundColor: "rgb(82 82 86 / 17%)" }}>
                      <h1
                        style={{
                          backgroundColor: "rgb(241, 169, 114)",
                          textAlign: "center",
                          marginTop: "-20px",
                          marginLeft: "-20px",
                          marginRight: "-20px",
                          padding: "5px",
                        }}
                      >
                        At Location
                      </h1>
                      <p>
                        <b>Available : </b>
                        {this.state.available &&
                          <span className="badge badge-success badge-normal" style={{ fontSize: "100%" }}>
                            {this.state.available}
                          </span>
                        }
                      </p>
                      <p>
                        <b>Issued : </b>
                        {this.state.issued &&
                          <span className="badge badge-success badge-normal" style={{ fontSize: "100%" }}>
                            {this.state.issued}
                          </span>
                        }
                      </p>
                      <p>
                        <b>Damaged : </b>
                        {this.state.damaged &&
                          <span className="badge badge-success  badge-normal" style={{ fontSize: "100%" }}>
                            {this.state.damaged}
                          </span>
                        }
                      </p>
                      <p>
                        <b>Scrapped : </b>
                        {this.state.scraped &&
                          <span className="badge badge-success badge-normal" style={{ fontSize: "100%" }}>
                            {this.state.scraped}
                          </span>
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )} */}
          </div>
          {/* <span>{JSON.stringify(this.state.data)}</span> */}
        </div>
        {/* <ModalPackingMaterial
          curPackingMaterial={this.state.curPackingMaterial}
          key={this.state.curPackingMaterial["packMaterialId"]}
        /> */}
        <ModalBarcodeSE
          curBarcode={this.state.data}
          curHubId={this.state.curHubId}
          // curPackingMaterial={this.state.curPackingMaterial}
          packingmaterials={this.state.packingmaterials}
          key={
            // this.state.curPackingMaterial["packMaterialId"] +
            this.state.curHubId + 1
          }
        />
        <ModalBarcodeSN
          curBarcode={this.state.data}
          curHubId={this.state.curHubId}
          // curPackingMaterial={this.state.curPackingMaterial}
          packingmaterials={this.state.packingmaterials}
          key={
            // this.state.curPackingMaterial["packMaterialId"] +
            this.state.curHubId + 2
          }
        />
        <ModalBarcodeN
          curBarcode={this.state.data}
          curHubId={this.state.curHubId}
          // curPackingMaterial={this.state.curPackingMaterial}
          packingmaterials={this.state.packingmaterials}
          key={
            // this.state.curPackingMaterial["packMaterialId"] +
            this.state.curHubId + 3
          }
        />
        {/* <ModalBarcodeTransfer
          curBarcode={this.state.data}
          srcHubId={this.state.srcHubId}
          dstHubId={this.state.dstHubId}
        // curPackingMaterial={this.state.curPackingMaterial}
        // packingmaterials={this.state.packingmaterials}
        // key={this.state.curPackingMaterial["packMaterialId"] + this.state.curHubId + 3}
        /> */}
        {/* <ModalUpdateBulkBarcodeStatus
        /> */}
      </React.Fragment>
    );
  }
}
export default BarcodeMaster;
