import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
class UserStoriesCreate extends FormLib {
  state = {
    data: {
      username: "",
      password: "",
      pickupDate: this.getDatebyDays(1),
      pickupTime: "14:00",

      userStory: "",
      moduleId: "",
      title: "",
      desc: "",
      acceptCriteria: "",
      roles: "",
      owner: "",
      priority: "",
      complexity: "",
      points: "",
      column: "",
      sort: "",
      startDate: "",
      dueDate: "",
      endDate: "",
      remark: "",
      status: "",
    },
    errors: {},
  };

  schema = {
    ticket1: Joi.string().label("Ticket 1"),
    ticket2: Joi.string().label("Ticket 2"),
  };
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="">
                <div className="card-body"></div>
                <div className="form-row">
                  {this.rTB("Title", "title", "col-12")}
                </div>
                <div className="form-row">
                  {this.rTB("Roles", "roles", "col-6")}
                  {this.rTB("Owner", "owner", "col-6")}
                  {this.rTB("Sort", "sort", "col-4")}
                </div>
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <textarea required="required"></textarea>
                    <label htmlFor="textarea" className="control-label">
                      Description
                    </label>
                    <i className="bar"></i>
                  </div>
                  <div className="form-group col-md-6">
                    <textarea required="required"></textarea>
                    <label htmlFor="textarea" className="control-label">
                      Edge Case
                    </label>
                    <i className="bar"></i>
                  </div>
                  <div className="form-group col-md-6">
                    <textarea required="required"></textarea>
                    <label htmlFor="textarea" className="control-label">
                      Acceptance Criteria
                    </label>
                    <i className="bar"></i>
                  </div>
                  {/* <div className="form-group col-md-6">
                <textarea required="required"></textarea>
                <label htmlFor="textarea" className="control-label">
                  Remark
                </label>
                <i className="bar"></i>
                </div>           */}
                </div>
                <div className="form-row">
                  {this.rTB("Points", "points", "col-4")}
                  {this.rTB("Complexity", "complexity", "col-4")}
                </div>

                <div className="form-row">
                  {this.rDP("Start Date", "startpDate", "col-md-4")}
                  {this.rDP("Due Date", "dueDate", "col-md-4")}
                  {this.rDP("End Date", "endDate", "col-md-4")}
                </div>

                {/* <div className="form-row">
                    {this.rDP("Start Date", "pickupDate", "col-md-4")}
                    {this.rDP("Due Date", "pickupDate", "col-md-4")}
                    {this.rDP("End Date", "pickupDate", "col-md-4")}
                    </div> */}

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <textarea required="required"></textarea>
                    <label htmlFor="textarea" className="control-label">
                      Remark
                    </label>
                    <i className="bar"></i>
                  </div>
                  {this.rRBL("Status", "status", this.status, {
                    value: "sortKey",
                    label: "sort",
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </React.Fragment>
    );
  }
}

export default UserStoriesCreate;
