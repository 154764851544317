import React from "react";

import AreaChart from "../../_charts/AreaChart";
import BarChart from "../../_charts/BarChart";
import HBarChart from "../../_charts/HBarChart";
import LineChart from "../../_charts/LineChart";

import PieChart from "../../_charts/PieChart";
import PolarAreaChart from "../../_charts/PolarAreaChart";

const Charts = () => {
  return (
    <div className="row">
      {/* <div className="col-12" style={{ height: 300 }}>
        <BarChart />
      </div> */}
      <div className="col-4" style={{ height: 300 }}>
        <AreaChart />
      </div>
      <div className="col-4" style={{ height: 300 }}>
        <LineChart />
      </div>

      <div className="col-4" style={{ height: 300 }}>
        <PieChart />
      </div>
      <div className="col-4" style={{ height: 300 }}>
        <HBarChart />
      </div>
      <div className="col-4" style={{ height: 300 }}>
        <PolarAreaChart />
      </div>
    </div>
  );
};

export default Charts;
