import React from "react";
import ZTitle from "./../../_subhead/ZTitle";
import toast from "../../../services/_toastService";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import { getIVRCallReport } from "../../../services/ivr/IvrSearchService";
import Table from "./../../_common/Table";
import Pagination from "../../_common/Pagination";


class IvrSearch extends FormLib {
  state = {
    data: {
      startDate: this.getDatebyDays(0),
      endDate: this.getDatebyDays(0),
      hubId: [],
      searchString: "",
    },
    errors: {},
    loading: false,
    ivrData: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      count: 0,
      lastPage: 0,
    },
  };

  schema = {
    startDate: Joi.string().required().label("Start Date"),
    endDate: Joi.string().required().label("End Date"),
    hubId: Joi.optional().label("Hub"),
    searchString: Joi.optional().label("searchString"),
  };

  componentDidMount() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.state.userCode = userInfo.code;
    this.state.userInfoTeamId = userInfo.teamId;
    this.loadHubs();
  }
  async loadHubs() {
    let hubList = JSON.parse(localStorage.getItem("hubs"));
    this.setState({ hubs: hubList });
  }
  tableMeta = {
    key: "ivrId",
    columns: [
      { path: "startTime", label: "startTime" },
      { path: "fromNo", label: "From No" },
      { path: "toNo", label: "To No" },
      { path: "ticketNo", label: "Ticket No" },
      {
        key: "blockStatus",
        action: (r) => (
          <b>{this.replaceString(r.blockStatus)}</b>
        ),
        cusClass: "",
        icon: () => <>Status</>
      },
      // { path: "blockStatus", label: "Status" },
      { path: "status", label: "Status" },
      {
        key: "ivrId",
        action: (r) => (
          <>
            {r.recordingUrl !== "" && (
              <a href={r.recordingUrl} target="_blank" rel="no referrer">
                {r.recordingUrl !== null ? (
                  <i class="fa fa-microphone fa-lg text-info "></i>
                ) : r.recordingUrl === "" ? (
                  <i class="fa fa-microphone-slash fa-lg text-danger"></i>
                ) : (
                  <i class="fa fa-microphone-slash fa-lg text-danger"></i>
                )}
              </a>
            )}
            {r.recordingUrl === "" && (
              <a target="_blank" rel="no referrer">
                {r.recordingUrl !== "" ? (
                  <i class="fa fa-microphone fa-lg text-danger "></i>
                ) : r.recordingUrl === "" ? (
                  <i class="fa fa-microphone-slash fa-lg text-danger"></i>
                ) : (
                  <i class="fa fa-microphone-slash fa-lg text-danger"></i>
                )}
              </a>
            )}
          </>
        ),
        icon: () => <>Audio</>,
      },
    ],
  };
  replaceString = (str) => {
    if (str) {
      str = str.replace(/_/g, " ");
      return str.replace("BLOCK", "JOB");
    }
  };
  async doSubmit() {
    //console.log("inside doSubmit");
    this.getIVRCall(this.state.data, this.state.pageLinks.pageNo);
  }
  handlePageChange = (pageNo) => {
    this.props.history.push("/ivrSearch/" + pageNo);
    this.getIVRCall(this.state.data, pageNo);
  };

  async getIVRCall(params, pageNo) {
    this.setState({ loading: true });
    let resp = await getIVRCallReport(params, pageNo);
    // console.log(JSON.stringify(resp.data.data));
    if (resp.data.success === true) {
      resp.data.data.IvrCallDetails &&
        this.setState({ IvrCallDetails: resp.data.data.IvrCallDetails });
      resp.data.data.pageLinks &&
        this.setState({ pageLinks: resp.data.data.pageLinks });
      resp.data.data.IvrCallDetails && toast.toastSuccess(resp.data.msg);
      !resp.data.data.IvrCallDetails && toast.toastInfo("No IvrCall");
    } else {
      toast.toastError(resp.data.msg);
    }
    this.setState({ loading: false });
  }

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    const ivrData = this.state.IvrCallDetails;
    return (
      <>
        <ZTitle subHeading="Report  :: IVR" />
        <div
          className="pageContent"
          style={{ backgroundColor: "rgb(251 112 3 / 18%)" }}
        >
          <div
            className="card"
            style={{ backgroundColor: "rgb(210 126 49 / 0%)" }}
          >
            <div
              className="card-body"
              style={{
                backgroundColor: "white",
                maxWidth: "94%",
                marginLeft: "1%",
              }}
            >
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-11">
                    <div className="form-row mt-2 ">
                      {this.rDPF("Start Date *", "startDate", " col-md-3 ml-1")}
                      {this.rDPF("End Date *", "endDate", " col-md-3")}
                      <div style={{ paddingLeft: "30px", marginTop: "-5px" }}>
                        {this.rSBMulti(
                          "Hub",
                          "hubId",
                          this.state.hubs,
                          { value: "hubId", label: "hub" },
                          " col-md-3 "
                        )}
                      </div>
                      {this.rTB("Ticket No", "searchString", "col-md-3")}
                    </div>
                  </div>
                  <div
                    className="col-1 pull-right"
                    style={{ paddingTop: "15px", paddingLeft: "5px" }}
                  >
                    {this.rBtnSP("Submit", "Submit")}
                  </div>
                </div>
              </form>

              <div className="row" style={{ marginTop: "50px" }}>
                {this.state.loading && (
                  <div className="container-fluid">
                    <div> Loading IVR Report...</div>
                  </div>
                )}
                {!this.state.loading && itemsCount > 0 && (
                  <div className="container-fluid">
                    <Table
                      clsName="table table-striped table-bordered table-hover"
                      tableMeta={this.tableMeta}
                      data={ivrData}
                      dropMenu={[]}
                    />
                    <Pagination
                      pageNo={pageNo}
                      perPage={perPage}
                      itemsCount={itemsCount}
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                )}
                {!this.state.loading && itemsCount === 0 && (
                  <div className="alert alert-info col-12" role="alert">
                    <div className="text-center"> No Records Found! </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.loading == true && !this.state.ivrData.length && (
          <div className="container-fluid jumbotron text-center">
            <h4>No Data Available...</h4>
          </div>
        )}
      </>
    );
  }
}

export default IvrSearch;
