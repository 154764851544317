import _http from "../_httpService";

import config from "../../config.json";
import { tDate2yyyymmddhhmmss } from "../../utils/DateUtils";
const baseURL = config[config.build].apiURLCore;
function api(path, subURL = "/call/ivrReport/by-hubId") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getIVRCallReport(req, pageNo) {
  const param = { ...req };
  param.startDate = tDate2yyyymmddhhmmss(param.startDate + "T00:00");
  param.endDate = tDate2yyyymmddhhmmss(param.endDate + "T23:59:59");
  return _http.put(api(pageNo + "/10"), param, _http.headerJWT());
}

export function getIVRCallReportByTicketNo(ticketNo) {
  const url = api(ticketNo, "/call/ivrCallDetails")

  // const url = `http://192.168.3.170:9090/call/ivrCallDetails/${ticketNo}'
  // const url = `https://test-api.bizlog.in:1337/call/ivrCallDetails/AMS-866-101-7872-54403553`
  // console.log("inside getIVRCallReportByTicketNo", url)

  return _http.get(url,  _http.headerJWT())
}

