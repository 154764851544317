import Joi from 'joi-browser';
import React from 'react';
import { createBlock } from '../../../services/Tickets/TicketProcessServices';
import FormLib from './../../_common/FormLib';
import BlockAssignment from './BlockAssignment';
import { toast } from "react-toastify";


export default class BlockCreation extends FormLib {
    state = {
        data: { blockCode: "" },
        errors: {},
        curTicketDetails: [],
        curBlockDetails: [],
        curProductDetails: []
    }

    schema = {
        blockCode: Joi.string().required().label("Block Name")
    }

    componentDidMount() {
        this.loadInit();
    }

    loadInit() {
        const curTicketDetails = this.props.ticketDetails;
        const curBlockDetails = this.props.blockDetails;
        const curProductDetails = this.props.ticketDetails.products;

        this.setState({ curTicketDetails });
        this.setState({ curBlockDetails });
        this.setState({ curProductDetails });
    }

    async doSubmit() {
        // console.log("--------> " + JSON.stringify(this.state.data));
        // console.log("--------> " + JSON.stringify(this.state.curTicketDetails));

        let params = {
            "processId": this.state.curTicketDetails.processId,
            "blockCode": this.state.data.blockCode
        }

        let res = await createBlock(params);
        if (res.data.success === true) {
            if (res.data.data) {
                this.props.handleBlockCreate();
                toast.success(() => <div>{res.data.msg}</div>);
            } else {
            }
        } else {
            toast.error(() => <div>{res.data.msg}</div>);
        }

        // this.props.refreshList();
    }
    handleBlockAssignment = () => {
        this.props.handleBlockCreate();
    }
    render() {
        return (
            <>
                <div className="row mt-4 ">
                    <div className="col-md-12">
                        <div className="card ">
                            <div className="card-body bg-light">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-row">
                                        <div className='col-10'>
                                            {this.rTB("Job Name", "blockCode")}
                                        </div>
                                        <div className='col-2'>
                                            {this.rBtnSP("Add Job", "New", "pull-right")}
                                        </div>
                                    </div>
                                </form>
                                <BlockAssignment
                                    productDetails={this.state.curProductDetails}
                                    ticketDetails={this.state.curTicketDetails}
                                    key={this.state.curTicketDetails.processId}
                                    handleBlockAssignment={this.handleBlockAssignment}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>);
    }
}
