import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import $ from "jquery";

import {
  createPrvGroups,
  savePrvGroups,
  deletePrvGroups,
} from "./../../../services/appFeatures/prvGroupsServices";

class ModalGroup extends FormLib {
  state = {
    data: {
      prvGroupId: "",
      moduleId: "",
      groupCode: "",
      groupName: "",
      status: "active",
      sort: "",
    },
    errors: {},

    prvModules: [],
    curGroup: {},
  };

  schema = {
    prvGroupId: Joi.optional().label("PrvGroupId"),
    moduleId: Joi.string().required().label("Module"),
    groupCode: Joi.string().required().label("GroupCode"),
    groupName: Joi.string().required().label("GroupName"),
    status: Joi.string().required().label("Status"),
    sort: Joi.optional().label("Sort"),
  };
  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res = {};
    if (this.state.btnValue === "create") {
      res = await createPrvGroups(this.state.data);
      this.resetData(this.props.curModule);
      this.props.onLoadPrvGroups(this.props.curModule);
    }
    if (this.state.btnValue === "edit") {
      res = await savePrvGroups(this.state.data);
      this.props.onLoadPrvGroups(this.props.curModule, false);
    }
    if (this.state.btnValue === "delete") {
      res = await deletePrvGroups(this.state.data);
      $("#curGroupButton").click();
      this.resetData(this.props.curModule);
      this.props.onLoadPrvGroups(this.props.curModule);
    }
    toast.toastMsg(res);
  }
  componentDidMount() {
    this.setModules(this.props.prvModules);
    this.setCurModule(this.props.curModule);
    this.setCurGroup(this.props.curModule, this.props.curGroup);
  }

  setModules(prvModules) {
    this.setState({ prvModules: prvModules });
  }
  setCurModule(m) {
    this.setState({ curModule: m });
  }

  setCurGroup(m, g) {
    if (g.prvGroupId && g.prvGroupId !== "") {
      this.setState({ data: g });
    } else {
      this.resetData(m);
    }
  }

  resetData(m) {
    this.setState({
      data: {
        moduleId: m.moduleId || "",
        prvGroupId: "",
        groupCode: "",
        groupName: "",
        status: "active",
        sort: "",
      },
    });
  }

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  sort = [
    { sortKey: "asc", sort: "ASC" },
    { sortKey: "dsc", sort: "DSC" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;

    return (
      <div className="container-fluid">
        <div className="modal fade" id="myGroupModel">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Prv Groups</h4>
                <button
                  type="button"
                  id="curGroupButton"
                  className="close"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  {this.rTBH("PrvGroupId", "prvGroupId")}
                  {this.rSB("Modules*", "moduleId", this.state.prvModules, {
                    value: "moduleId",
                    label: "module",
                  })}
                  {this.rTB("GroupCode", "groupCode")}
                  {this.rTB("GroupName", "groupName")}


                  <div className="form-row">
                    {this.rRBL("Status*", "status", this.status, this.stsConf, "col-12")}
                    {this.rRBL("Sort", "sort", this.sort, this.stsConf, "col-12")}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {/* {"==>"+this.state.data.prvGroupId + "<=="} */}
                {this.state.data.prvGroupId === "" &&
                  this.rBtnSP("Create", "create")}
                {this.state.data.prvGroupId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {this.rBtnSD("Delete", "delete")}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalGroup;
