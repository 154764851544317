import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import DbTables from "./DbTables";

class DbBoard extends FormLib {
  state = {
    data: { databaseId: "" },
    errors: {},
    databases: [
      {
        databaseId: "1",

        database: "new_Rlog",

        details: "new",

        status: "active",
      },

      {
        databaseId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7dfc",

        database: "privileges",

        details: "microservice",

        status: "active",
      },

      {
        databaseId: "2",

        database: "Location",

        details: "MasterLocation",

        status: "InActive",
      },
    ],
    tables: [
      {
        tableId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7001",
        table: "Table 001",
        databaseId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7dfc",
        details: "details",
        status: "active",
      },
      {
        tableId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7002",
        table: "Table 002",
        databaseId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7dfc",
        details: "details",
        status: "active",
      },
      {
        tableId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7003",
        table: "Table 003",
        databaseId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7dfc",
        details: "details",
        status: "active",
      },
      {
        tableId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7004",
        table: "Table 004",
        databaseId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7dfc",
        details: "details",
        status: "active",
      },
    ],
  };
  schema = { databaseId: Joi.optional().label("databaseId") };
  componentDidMount() {
    this.handleSetDrag();
  }
  handleSetDrag = () => {
    $(".myTableDrag").draggable();
  };
  render() {
    return (
      <React.Fragment>
        <div className="pageContent">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10">
                {this.rSB("Databases", "databaseId", this.state.databases, {
                  value: "databaseId",
                  label: "database",
                })}
              </div>
            </div>
            {this.state.tables.map((t, index) => {
              return <DbTables key={t.tableId} tables={t} top={index} />;
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default DbBoard;
