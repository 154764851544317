import React from 'react'
import FormLib from '../../_common/FormLib';
import { getTeams } from '../../../services/players/teamServices';
import toast from "../../../services/_toastService";
import { getPlayersbyRole } from '../../../services/Tickets/TicketServices';
import Checkbox from '../../_common/formComps/Checkbox';
import swal from "sweetalert";
import {
    MisTeamsFieldData,
    getAllDesignation,
    getAllFields,
    getFieldsByDesignation,
    getMaMisTeamsFields,
    getMaMisTeamsFieldsByData,
    getMisDesignationFieldDataByData,
    getStatusOfmaMisDesignationData,
    getStatusOfmaMisPlayersData,
    getStatusOfmaMisTeamsData,
    getplayersByDesignationId,
    insertMaMisPlayersField,
    misDesignationFieldData,
    misFieldData,
    updateMaMisPlayersField,
    updateMaMisPlayersFieldStatus,
    updateMaMisTeamsFieldsByData,
    updateMaMisTeamsFieldsStatus,
    updateMisDesignationFieldData,
    updateMisDesignationFieldStatus
} from '../../../services/clientReports/clientReports';
import { Joi } from 'joi-browser';

class MasterFields extends FormLib {
    state = {
        data: {
            clientId: "",
            designationId: "",
            status: "clients",
            bizlogTeamId: "",
            fields: [],
            playerId: "",
            name: "",
            nameInMaMisField: "",
            descriptionInMaMisField: "",
            designationIds: ""
        },
        clients: [],
        curTeam: { teamId: "" },
        errors: {},
        players: [],
        fieldData: [],
        fieldDataClients: [],
        fieldDataPlayers: [],
        fieldArr: [],
        responseFieldArr: [],
        designations: [],
        name: "",
        description: "",
        alteredName: "",
        alteredDesc: "",
        isChecked: false,
        fieldsData: [],
        retailers: {},
        btnValue: "",
        btnStatus: false,
        teamsFieldData: []
    }

    componentDidMount() {
        this.loadRetailers()
        this.getPlayerList()
        // this.loadFields()
        this.loadFieldsBiz()
        this.loadDesignations()
        this.loadTeamsFields()
    }


    loadTeamsFields = async () => {
        const resp = await getMaMisTeamsFields();
        this.setState({ teamsFieldData: resp.data.data.misDesignationFieldData })
        // console.log("loadTeamsFields",resp)
    }

    loadFieldsBiz = async () => {
        const resp = await getAllFields();
        // console.log("resp========><===============resp", resp)
        this.setState({ fieldsData: resp.data.data.misFieldData })
    }

    loadDesignations = async () => {
        const designation = await getAllDesignation();
        // console.log(designation);
        this.setState({ designations: designation.data.data.getDesignation })
    }

    fieldOption = [
        { sort: "Fields For Users", sortKey: "clients" },
        { sort: "Fields For Designation", sortKey: "bizlog" },
        { sort: "Insert New Fields", sortKey: "players" },
    ]
    bizlogTeamOption = [
        { sort: "KAM", sortKey: "kAM00" },
        { sort: "CoOrdinator", sortKey: "CoOrdinator00" },
        { sort: "Accounts", sortKey: "Accounts00" },
    ]

    getPlayerList = async () => {
        const hub = localStorage.getItem("curHub");
        const rep = await getPlayersbyRole(hub)
        // console.log("hub-=-=-=-=-=-=-=-=>hub",rep)
        this.setState({ players: rep.data.data.playersHubs })

    }


    isCheck(data) {
        // this.setState({isChecked: (n) => {

        // }})
        // console.log("resp============================><<<<<<<<<<<<<<<<<<<<===========resp",resp)

        this.setState({ isChecked: data })

    }

    handleCheckboxChange = async (e, data) => {
        // console.log("e.target.value, data", e.target.value, data);
        // const playerId = JSON.parse(localStorage.getItem("userInfo"));
        // console.log("playerId", playerId)

        if (this.state.data.status === 'clients' && this.state.data.designationId === 'reta1669212860575TZ8f391e738dd64fa99c58c586a7d609d2') {


            if (e.target.checked) {
                const playerId = JSON.parse(localStorage.getItem("userInfo"));
                let name;
                let description;

                if (this.state.alteredName === '') {
                    if (data.maMisTeamsName === '') {
                        name = data.maMisDesigName
                    }
                    else {
                        name = data.maMisTeamsName
                    }
                }
                else {
                    name = this.state.alteredName
                }
                if (this.state.alteredDesc === '') {
                    if (data.maMisTeamsDescription === '') {
                        description = data.maMisDesigDescription
                    }
                    else {
                        description = data.maMisTeamsDescription
                    }
                }
                else {
                    description = this.state.alteredDesc
                }

                const paramsDesig = {
                    fieldId: data.fieldId,
                    designationId: this.state.data.designationId
                }
                // const maMisDesigData = await getMisDesignationFieldDataByData(paramsDesig)
                // console.log("maMisDesigData", maMisDesigData.data.data.geMaDesigIdData[0].maMisDesigId)
                const params = {
                    name: name,
                    description: description,
                    maMisDesigId: data.maMisDesigId,
                    teamId: this.state.data.clientId,
                    updatedBy: playerId.playerId
                }

                // console.log("params=>params", params)

                const resp = await MisTeamsFieldData(params);
                if (resp.data.success === true) {
                    toast.toastSuccess(resp.data.msg);
                    this.setState({ alteredName: '' })
                    this.setState({ alteredDesc: '' })
                    const refreshParams = {
                        designationId: this.state.data.designationId,
                        teamId: this.state.data.clientId
                    };

                    // console.log(refreshParams)

                    const respField = await getStatusOfmaMisTeamsData(refreshParams)

                    // console.log("resp====>resp<====resp", respField.data.data.getDesignation)
                    this.setState({ fieldDataClients: respField.data.data.getDesignation });
                }
                else {
                    toast.toastError(resp.data.msg);

                }

                // console.log("resp====><==========resp", resp);
                // if(resp.data.success === true){
                //     toast.toastSuccess(resp.data.msg);
                // }
                // else{
                //     toast.toastSuccess(resp.data.msg);
                // }

                // this.pushData(data)





            }
            else {
                const playerId = JSON.parse(localStorage.getItem("userInfo"));
                let name;
                let description;

                // console.log(playerId.playerId)
                const paramsDesig = {
                    fieldId: data.fieldId,
                    designationId: this.state.data.designationId
                }
                // const maMisDesigData = await getMisDesignationFieldDataByData(paramsDesig)
                // console.log("maMisDesigData", maMisDesigData.data.data.geMaDesigIdData[0].maMisDesigId)

                if (this.state.alteredName === '') {
                    name = data.maMisName
                }
                else {
                    name = this.state.alteredName
                }
                if (this.state.alteredDesc === '') {
                    description = data.maMisDesigDescription
                }
                else {
                    description = this.state.alteredDesc
                }

                const params = {
                    maMisDesigId: data.maMisDesigId,
                    teamId: this.state.data.clientId,
                    updatedBy: playerId.playerId
                }

                // console.log("params=>params", params)
                const resp = await updateMaMisTeamsFieldsStatus(params);

                // console.log("resp====><==========resp", resp);
                if (resp.data.success === true) {
                    toast.toastSuccess(resp.data.msg);
                    const refreshParams = {
                        designationId: this.state.data.designationId,
                        teamId: this.state.data.clientId
                    };

                    // console.log(refreshParams)

                    const respField = await getStatusOfmaMisTeamsData(refreshParams)

                    // console.log("resp====>resp<====resp", respField.data.data.getDesignation)
                    this.setState({ fieldDataClients: respField.data.data.getDesignation });
                }
                else {
                    toast.toastError(resp.data.msg);
                }

            }
        }


        if (this.state.data.status === 'clients' && this.state.data.designationId !== 'reta1669212860575TZ8f391e738dd64fa99c58c586a7d609d2') {
            if (e.target.checked) {
                const playerId = JSON.parse(localStorage.getItem("userInfo"));
                let name;
                let description;

                if (this.state.alteredName === '') {
                    if (data.maMisPlayersName === '') {
                        name = data.maMisDesigName
                    }
                    else {
                        name = data.maMisPlayersName
                    }
                }
                else {
                    name = this.state.alteredName
                }
                if (this.state.alteredDesc === '') {
                    if (data.maMisPlayersDescription === '') {
                        description = data.maMisDesigDescription
                    }
                    else {
                        description = data.maMisPlayersDescription
                    }
                }
                else {
                    description = this.state.alteredDesc
                }
                // alert('acll')

                // const paramsDesig = {
                //     fieldId: data.fieldId,
                //     designationId: this.state.data.designationId
                // }
                // const maMisDesigData = await getMisDesignationFieldDataByData(paramsDesig)
                // console.log("maMisDesigData", maMisDesigData.data.data.geMaDesigIdData[0].maMisDesigId)
                const params = {
                    name: name,
                    description: description,
                    maMisDesigId: data.maMisDesigId,
                    playerId: this.state.data.clientId,
                    updatedBy: playerId.playerId
                }
                // console.log("params=>params", params)
                const resp = await insertMaMisPlayersField(params);

                // console.log("resp====><==========resp", resp);
                if (resp.data.success === true) {
                    toast.toastSuccess(resp.data.msg);

                    const params = {
                        designationId: this.state.data.designationId,
                        playerId: this.state.data.clientId
                    };

                    // console.log("params=====><======params", params);

                    const resp1 = await getStatusOfmaMisPlayersData(params);
                    this.setState({ fieldDataPlayers: resp1.data.data.getPlayersStatus });
                    this.setState({ alteredName: '' })
                    this.setState({ alteredDesc: '' })
                }
                else {
                    toast.toastError(resp.data.msg);
                }

                // this.pushData(data)
            }
            else {
                const playerId = JSON.parse(localStorage.getItem("userInfo"));
                // let name;
                // let description;

                // if (this.state.alteredName === '') {
                //     name = data.maMisDesigName
                // }
                // else {
                //     name = this.state.alteredName
                // }
                // if (this.state.alteredDesc === '') {
                //     description = data.maMisDesigDescription
                // }
                // else {
                //     description = this.state.alteredDesc
                // }
                // alert('acll')

                // const paramsDesig = {
                //     fieldId: data.fieldId,
                //     designationId: this.state.data.designationId
                // }
                // const maMisDesigData = await getMisDesignationFieldDataByData(paramsDesig)
                // console.log("maMisDesigData", maMisDesigData.data.data.geMaDesigIdData[0].maMisDesigId)
                const params = {
                    maMisDesigId: data.maMisDesigId,
                    playerId: this.state.data.clientId,
                    updatedBy: playerId.playerId
                }
                // console.log("params=>params", params)
                const resp = await updateMaMisPlayersFieldStatus(params);

                // console.log("resp====><==========resp", resp);
                if (resp.data.success === true) {
                    toast.toastSuccess(resp.data.msg);
                    const params = {
                        designationId: this.state.data.designationId,
                        playerId: this.state.data.clientId
                    };

                    // console.log("params=====><======params", params);

                    const resp1 = await getStatusOfmaMisPlayersData(params);
                    this.setState({ fieldDataPlayers: resp1.data.data.getPlayersStatus });
                }
                else {
                    toast.toastError(resp.data.msg);
                }
            }
            // insertMaMisPlayersField
        }


        if (this.state.data.status === 'bizlog') {
            if (e.target.checked) {
                // alert(e.target.value)
                const playerId = JSON.parse(localStorage.getItem("userInfo"));
                let name;
                let description;

                if (this.state.alteredName === '') {
                    if (data.maMisDesigName === '') {
                        name = data.maMisFieldName
                    }
                    else {
                        name = data.maMisDesigName
                    }
                }
                else {
                    name = this.state.alteredName
                }
                if (this.state.alteredDesc === '') {
                    if (data.maMisDesigDescription === '') {
                        description = data.maMisFieldDescription
                    }
                    else{
                        description = data.maMisDesigDescription
                    }
                }
                else {
                    description = this.state.alteredDesc
                }

                const params = {
                    name: name,
                    description: description,
                    fieldId: JSON.stringify(data.fieldId),
                    designationId: this.state.data.designationIds,
                    updatedBy: playerId.playerId
                }
                // console.log({ params })

                const resp = await misDesignationFieldData(params);
                // console.log({ resp })
                if (resp.data.success === true) {
                    toast.toastSuccess(resp.data.msg);
                    this.setState({ alteredName: '' })
                    this.setState({ alteredDesc: '' })
                    this.loadMaMisFields(this.state.data.designationIds)
                }
                else {
                    toast.toastError(resp.data.msg);
                }


            }
            else {
                const playerId = JSON.parse(localStorage.getItem("userInfo"));
                // let name;
                // let description;

                // if (this.state.alteredName === '') {
                //     name = data.maMisFieldName
                // }
                // else {
                //     name = this.state.alteredName
                // }
                // if (this.state.alteredDesc === '') {
                //     description = data.maMisFieldDescription

                // }
                // else {
                //     description = this.state.alteredDesc
                // }

                const params = {
                    fieldId: JSON.stringify(data.fieldId),
                    designationId: this.state.data.designationIds,
                    updatedBy: playerId.playerId
                }

                // console.log("params", params)
                const resp = await updateMisDesignationFieldStatus(params);
                if (resp.data.success === true) {
                    toast.toastSuccess(resp.data.msg);
                    this.loadMaMisFields(this.state.data.designationIds)
                }
                else {
                    toast.toastError(resp.data.msg);
                }
                // console.log({ resp })
            }
        }
    }

    pushData(data) {
        // console.log("datadataxatadatadtasad", data)
        this.state.fieldArr.push(data)
        this.setState({ responseFieldArr: this.state.fieldArr })
    }
    popData(data) {
        this.state.responseFieldArr.pop(data)
        // console.log(this.state.responseFieldArr)
    }

    async loadFields(designationId) {
        try {
            const resp = await getFieldsByDesignation(designationId);
            // console.log("response of the fields", resp);
            // this.setState({ fieldData: resp.data.data.getFieldByDesignation });




            // this.isCheck(resp.data.data.getFieldByDesignation)

        } catch (e) {
            console.log(e)
        }
    }

    async loadRetailers() {
        try {
            const res = await getTeams();
            if (res !== undefined) {
                if (res.data.success === true) {
                    this.setState({ retailers: res.data.data.teams });
                } else {
                    toast.toastError(res.data.msg);
                }
            }
        } catch {
            console.log("Error in MISNewReport>>>>>> loadRetailers");
        }
    }


    handleNameChange(e, name) {

        if (name != e.target.value) {
            this.nameChange(e.target.value)
        }
    }

    nameChange(name) {
        this.setState({ alteredName: name })
    }



    handleDescChange(e, desc) {

        if (desc != e.target.value) {
            this.setState({ alteredDesc: e.target.value })
        }
        else {
            this.setState({ alteredDesc: this.state.description })
        }
    }

    loadRetailersField = async (teamId) => {
        const params = {
            designationId: this.state.data.designationId,
            teamId: teamId,
        };

        const resp = await getStatusOfmaMisTeamsData(params)
        if (resp.data.success === true) {
            this.setState({ fieldDataClients: resp.data.data.getDesignation });
            // console.log("resp====>resp<====resp", resp.data.data.getDesignation)
        }
    }


    loadPlayersFields = async (playerId) => {
        const params = {
            designationId: this.state.data.designationId,
            playerId: playerId
        };

        // console.log("params=====><======params", params);

        const resp = await getStatusOfmaMisPlayersData(params);
        if (resp.data.success === true) {
            this.setState({ fieldDataPlayers: resp.data.data.getPlayersStatus });
            // console.log("resp=====<getStatusOfmaMisPlayersData>======", resp.data.data.getPlayersStatus)
        }
    }

    loadMaMisFields = async (designationId) => {
        const params = {
            designationId: designationId
        }

        // console.log(params);


        const resp = await getStatusOfmaMisDesignationData(params);

        // console.log("resp==>resp<==resp", resp.data.data.getDesignationStatus)
        this.setState({ fieldData: resp.data.data.getDesignationStatus });
    }


    localHandleChange = async (e) => {
        try {
            if (e.target.name === 'designationId' && this.state.data.status === 'clients') {
                // alert(e.target.value)
                this.loadFields(e.target.value)
                // getplayersByDesignationId
                // this.setState({ clients: res.data.data.teams });

                const resp = await getplayersByDesignationId(e.target.value);
                // console.log({ resp: resp.data.data.playerList })
                this.setState({ clients: resp.data.data.playerList });
            }
            if (e.target.name === 'clientId' && this.state.data.status === 'clients' && this.state.data.designationId === 'reta1669212860575TZ8f391e738dd64fa99c58c586a7d609d2') {

                try {
                    this.setState({fieldDataClients: []})

                    this.loadRetailersField(e.target.value)

                } catch (e) {
                    console.log(e)
                }
            }

            if (e.target.name === 'clientId' && this.state.data.status === 'clients' && this.state.data.designationId !== 'reta1669212860575TZ8f391e738dd64fa99c58c586a7d609d2') {
                try {
                    this.setState({fieldDataPlayers: []})
                    this.loadPlayersFields(e.target.value)

                } catch (e) {
                    console.log(e)
                }
            }

            if (e.target.name === 'designationIds' && this.state.data.status === 'bizlog') {
                try {
                    this.setState({fieldData: []})
                    this.loadMaMisFields(e.target.value)

                } catch (e) {
                    console.log(e)
                }
            }


        } catch (e) {
            console.log(e)
        }
    }




    handleClick = async () => {
        try {

            if (this.state.data.nameInMaMisField != '' && this.state.data.descriptionInMaMisField != '') {
                const params = {
                    name: this.state.data.nameInMaMisField,
                    description: this.state.data.descriptionInMaMisField
                }
                // console.log(params)
                const resp = await misFieldData(params);
                // console.log(resp);
                if (resp.data.success === true) {
                    toast.toastSuccess(resp.data.msg);
                    this.setState({
                        data: {
                            nameInMaMisField: "",
                        }
                    })
                    this.setState({
                        data: {
                            descriptionInMaMisField: ""
                        }
                    })
                    this.setState({
                        data: {
                            status: "players"
                        }
                    })
                }
                else {
                    toast.toastError(resp.data.msg)
                }
            }
            else {
                if (this.state.data.nameInMaMisField === '' && this.state.data.descriptionInMaMisField != '') {

                    swal("Name is Mandatory", {
                        icon: "error",
                    });
                }
                if (this.state.data.nameInMaMisField != '' && this.state.data.descriptionInMaMisField === '') {

                    swal("Description is Mandatory", {
                        icon: "error",
                    });
                }
                if (this.state.data.nameInMaMisField === '' && this.state.data.descriptionInMaMisField === '') {

                    swal("Name And Description are Mandatory", {
                        icon: "error",
                    });
                }
            }


        } catch (error) {
            console.log(error)
        }
    }


    handleUpdateClick = async (data, designationId) => {
        // console.log(data, designationId);

        if(this.state.data.status === 'bizlog'){

            const playerId = JSON.parse(localStorage.getItem("userInfo"));
            let name;
            let description;
    
            if (this.state.alteredName === '') {
                name = data.maMisDesigName
            }
            else {
                name = this.state.alteredName
            }
            if (this.state.alteredDesc === '') {
                description = data.maMisDesigDescription
            }
            else {
                description = this.state.alteredDesc
            }
    
    
            const params = {
                designationId: designationId,
                fieldId: JSON.stringify(data.fieldId),
                name: name,
                description: description,
                updatedBy: playerId.playerId,
            }
    
            // console.log("params=><=", params)
            const resp = await updateMisDesignationFieldData(params);
            // console.log("resp",resp)
            if(resp.data.success === true){
                toast.toastSuccess(resp.data.msg);
                this.setState({alteredName: ''})
                this.setState({alteredDesc: ''})
                this.loadMaMisFields(designationId)
            }
            else{
                toast.toastError(resp.data.msg);
                this.setState({alteredName: ''})
                this.setState({alteredDesc: ''})
            }
        }



       if(this.state.data.status === 'clients' && this.state.data.designationId === 'reta1669212860575TZ8f391e738dd64fa99c58c586a7d609d2'){
        const playerId = JSON.parse(localStorage.getItem("userInfo"));
                let name;
                let description;

                if (this.state.alteredName === '') {
                    name = data.maMisTeamsName
                }
                else {
                    name = this.state.alteredName
                }
                if (this.state.alteredDesc === '') {
                    description = data.maMisTeamsDescription
                }
                else {
                    description = this.state.alteredDesc
                }

    
    
            const params = {
                maMisDesigId: data.maMisDesigId,
                teamId: this.state.data.clientId,
                name: name,
                description: description,
                updatedBy: playerId.playerId,
            }
    
            // console.log("params=><=", params)
            const resp = await updateMaMisTeamsFieldsByData(params);
            // console.log("resp",resp)
            if(resp.data.success === true){
                toast.toastSuccess(resp.data.msg);
                this.setState({alteredName: ''})
                this.setState({alteredDesc: ''})
                this.loadRetailersField(this.state.data.clientId)
            }
            else{
                toast.toastError(resp.data.msg);
                this.setState({alteredName: ''})
                this.setState({alteredDesc: ''})
            }
       }

       if(this.state.data.status === 'clients' && this.state.data.designationId !== 'reta1669212860575TZ8f391e738dd64fa99c58c586a7d609d2'){
        const playerId = JSON.parse(localStorage.getItem("userInfo"));
        let name;
        let description;

        if (this.state.alteredName === '') {
            name = data.maMisPlayersName
        }
        else {
            name = this.state.alteredName
        }
        if (this.state.alteredDesc === '') {
            description = data.maMisPlayersDescription
        }
        else {
            description = this.state.alteredDesc
        }
        const params = {
         maMisDesigId: data.maMisDesigId,
         playerId: this.state.data.clientId,
         name: name,
         description: description,
         updatedBy: playerId.playerId,
        }   
        // console.log("params:", params)

        const resp = await updateMaMisPlayersField(params);
            // console.log("resp",resp)
            if(resp.data.success === true){
                toast.toastSuccess(resp.data.msg);
                this.setState({alteredName: ''})
                this.setState({alteredDesc: ''})
                this.loadPlayersFields(this.state.data.clientId)
            }
            else{
                toast.toastError(resp.data.msg);
                this.setState({alteredName: ''})
                this.setState({alteredDesc: ''})
            }

       }
    }

    render() {

        return (
            <div>
                <div className="form-subHeader">
                    <h3 className='text-dark'>Client Report Configs : </h3>
                </div>
                <div className='col-12'>
                    {this.rRBL("", "status", this.fieldOption, {
                        label: "sort",
                        value: "sortKey"
                    },
                        "col-4"
                    )}
                </div>
                <div className='col-10' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                    {this.state.data.status === 'clients' && (

                        <div className='col-10' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" ,  }}>
                            <div className='col-6' style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>

                                {this.rSB("Designation", "designationId", this.state.designations, {
                                    value: "designationId",
                                    label: "designation"
                                },
                                    "col-3 ms-3 me-5"
                                )}
                                {/* {this.state.clients != [] && (
                                    <div className='col-3 w-50'> */}
                                {this.state.data.designationId === 'reta1669212860575TZ8f391e738dd64fa99c58c586a7d609d2' && (
                                    < >

                                        {this.rSB("Clients", "clientId", this.state.retailers, {
                                            value: "teamId",
                                            label: "name"
                                        },
                                            "col-3 ms-3 me-1 w-100"
                                        )}
                                    </>
                                )}
                                {this.state.data.designationId != 'reta1669212860575TZ8f391e738dd64fa99c58c586a7d609d2' && (
                                    < >

                                        {this.rSB("Recepents", "clientId", this.state.clients, {
                                            value: "playerId",
                                            label: "firstName"
                                        },
                                            "col-3 ms-3 me-1 w-100"
                                        )}
                                    </>
                                )}
                                {/* </div>
                                )} */}
                            </div>
                            {/* {this.rSBMulti("Fields","fields",this.fields,{
                        label: "label",
                        value: "value"
                    },
                    "col-1"
                    )} */}
                    {/*  && this.state.fieldData.length > 0 */}
                            { this.state.data.status === 'clients' && this.state.data.clientId != '' && (

                                <div className='col-4' style={{ display: "flex", flexDirection: "column", marginTop: -20, }}>

                                    <p style={{ fontSize: "14px", fontFamily: "sans-serif" }}>Fields:</p>
                                    { this.state.data.status === 'clients'&& this.state.fieldDataClients.length > 0 && this.state.data.designationId === 'reta1669212860575TZ8f391e738dd64fa99c58c586a7d609d2' && (

                                        <div style={{ height: "20rem", width: "40rem", 
                                        backgroundColor: "#fff",
                                        borderRadius: "2px", 
                                        marginTop: -15,
                                        overflowY: 'scroll',
                                        border: "1px solid black",
                                        padding: "12px",                                       
                                        paddingTop: "5px"
                                        }}>
                                            <div
                                                style={{ display: "flex", flexDirection: "column", }}>
                                                {this.state.fieldDataClients.map((m) => (
                                                    <div
                                                        key={m.fieldId}
                                                        style={{
                                                            display: "flex", flexDirection: "row", justifyContent: "space-evenly", backgroundColor: "#e3e5ee",
                                                            marginBottom: "5px", marginTop: "5px"
                                                        }}>

                                                        <label style={{ marginTop: "16px" }}>
                                                            {m.maMisDesigName}
                                                        </label>
                                                        {m.maMisTeamsName !== "" && (

                                                            <input type='text' id={m.fieldId} defaultValue={m.maMisTeamsName} title='Name' maxLength={50}
                                                                style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }}
                                                                onChange={(e) => {
                                                                    this.setState({ name: m.maMisTeamsName })
                                                                    this.handleNameChange(e, m.maMisTeamsName)
                                                                }}
                                                            />
                                                        )}
                                                        {m.maMisTeamsName === "" && (

                                                            <input type='text' id={m.fieldId} defaultValue={m.maMisDesigName} title='Name' maxLength={50}
                                                                style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }}
                                                                onChange={(e) => {
                                                                    this.setState({ name: m.maMisDesigName })
                                                                    this.handleNameChange(e, m.maMisDesigName)
                                                                }}
                                                            />
                                                        )}
                                                        {m.maMisTeamsDescription !== "" && (
                                                            <input type='text' id={m.fieldId} defaultValue={m.maMisTeamsDescription} title='Description' maxLength={50}
                                                                style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }}
                                                                onChange={(e) => {
                                                                    this.setState({ description: m.maMisTeamsDescription })
                                                                    this.handleDescChange(e, m.maMisTeamsDescription)
                                                                }}
                                                            />
                                                        )}
                                                        {m.maMisTeamsDescription === "" && (
                                                            <input type='text' id={m.fieldId} defaultValue={m.maMisDesigDescription} title='Description' maxLength={50}
                                                                style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }}
                                                                onChange={(e) => {
                                                                    this.setState({ description: m.maMisDesigDescription })
                                                                    this.handleDescChange(e, m.maMisDesigDescription)
                                                                }}
                                                            />
                                                        )}
                                                        <input type='checkbox' id={m.fieldId} value={m.fieldId} style={{ marginTop: "0" }}
                                                            checked={m.status}
                                                            onChange={(e) => {
                                                                this.handleCheckboxChange(e, m)
                                                            }
                                                            }
                                                        />
                                                        {m.status === true && (
                                                    <div>
                                                        <button
                                                            key={m.fieldId} className='btn-primary'
                                                            style={{ marginTop: 10, border: "none", outline: "none" }}
                                                            onClick={() => this.handleUpdateClick(m, this.state.data.designationId)}
                                                        >update</button>
                                                    </div>
                                                    )} 
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {this.state.data.status === 'clients' && this.state.fieldDataPlayers.length > 0 && this.state.data.designationId !== 'reta1669212860575TZ8f391e738dd64fa99c58c586a7d609d2' && (

                                        <div style={{ height: "20rem",
                                         width: "40rem", 
                                         backgroundColor: "#fff",
                                          borderRadius: "2px",
                                           marginTop: -15, 
                                           overflowY: 'scroll',
                                           border: "1px solid black",
                                        padding: "12px", 
                                        paddingTop: "5px"
                                         }}>
                                            <div
                                                style={{ display: "flex", flexDirection: "column", }}>
                                                {this.state.fieldDataPlayers.map((m) => (
                                                    <div
                                                        key={m.fieldId}
                                                        style={{
                                                            display: "flex", flexDirection: "row", justifyContent: "space-evenly", backgroundColor: "#e3e5ee",
                                                            marginBottom: "5px", marginTop: "5px"
                                                        }}>

                                                        <label style={{ marginTop: "16px" }}>
                                                            {m.maMisDesigName}
                                                        </label>
                                                        {m.maMisPlayersName !== "" && (

                                                            <input type='text' id={m.fieldId} defaultValue={m.maMisPlayersName} title='Name' maxLength={50}
                                                                style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }}
                                                                onChange={(e) => {
                                                                    this.setState({ name: m.maMisPlayersName })
                                                                    this.handleNameChange(e, m.maMisPlayersName)
                                                                }}
                                                            />
                                                        )}
                                                        {m.maMisPlayersName === "" && (

                                                            <input type='text' id={m.fieldId} defaultValue={m.maMisDesigName} title='Name' maxLength={50}
                                                                style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }}
                                                                onChange={(e) => {
                                                                    this.setState({ name: m.maMisDesigName })
                                                                    this.handleNameChange(e, m.maMisDesigName)
                                                                }}
                                                            />
                                                        )}
                                                        {m.maMisPlayersDescription !== "" && (
                                                            <input type='text' id={m.fieldId} defaultValue={m.maMisPlayersDescription} title='Description' maxLength={50}
                                                                style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }}
                                                                onChange={(e) => {
                                                                    this.setState({ description: m.maMisPlayersDescription })
                                                                    this.handleDescChange(e, m.maMisPlayersDescription)
                                                                }}
                                                            />
                                                        )}
                                                        {m.maMisPlayersDescription === "" && (
                                                            <input type='text' id={m.fieldId} defaultValue={m.maMisDesigDescription} title='Description' maxLength={50}
                                                                style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }}
                                                                onChange={(e) => {
                                                                    this.setState({ description: m.maMisDesigDescription })
                                                                    this.handleDescChange(e, m.maMisDesigDescription)
                                                                }}
                                                            />
                                                        )}
                                                        <input type='checkbox' id={m.fieldId} value={m.fieldId} style={{ marginTop: "0" }}
                                                            checked={m.status}
                                                            onChange={(e) => {
                                                                this.handleCheckboxChange(e, m)
                                                            }
                                                            }
                                                        />
                                                        {m.status === true && (
                                                    <div>
                                                        <button
                                                            key={m.fieldId} className='btn-primary'
                                                            style={{ marginTop: 10, border: "none", outline: "none" }}
                                                            onClick={() => this.handleUpdateClick(m, this.state.data.designationId)}
                                                        >update</button>
                                                    </div>
                                                    )} 
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                        </div>
                    )}
                    {this.state.data.status === 'bizlog' && (

                        <div className='col-10' style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                            <div className='col-5' >

                                {this.rSB("Designation", "designationIds", this.state.designations, {
                                    value: "designationId",
                                    label: "designation"
                                },
                                    "col-3 ms-3 "
                                )}
                            </div>
                            {/* {this.rSBMulti("Fields","fields",this.fields,{
                        label: "label",
                        value: "value"
                    },
                    "col-1"
                    )} */}
                            {/* <div className='col-5' style={{ display: "flex", flexDirection: "column", marginTop: -20 }}>

                                <p style={{ fontSize: "14px", fontFamily: "sans-serif" }}>Fields:</p>

                                <div style={{ height: "20rem", width: "40rem", backgroundColor: "#fff", borderRadius: "2px", marginTop: -15, overflowY: 'scroll' }}>
                                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-evenly" }}>
                                        {this.fields.map((m) => (
                                            <label>

                                                <input type='checkbox' value={m.value} onChange={(e) => this.handleCheckboxChange(e)} />
                                                {m.label}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div> */}

                            { this.state.data.status === 'bizlog' && this.state.data.designationIds && (

                                <div className='col-4' style={{ display: "flex", flexDirection: "column", marginTop: -20, marginRight: -20 }}>

                                    <p style={{ fontSize: "14px", fontFamily: "sans-serif" }}>Fields:</p>

                                    <div style={{ height: "20rem",
                                     width: "40rem", 
                                     backgroundColor: "#fff", 
                                     borderRadius: "2px",
                                      marginTop: -15, 
                                      overflowY: 'scroll',
                                      border: "1px solid black",
                                        padding: "12px" ,
                                        paddingTop: "5px"
                                        }}>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            {this.state.fieldData.map((m) => (
                                                <div key={m.fieldId}
                                                    style={{
                                                        display: "flex", flexDirection: "row", justifyContent: "space-evenly", backgroundColor: "#e3e5ee",
                                                        marginBottom: "5px", marginTop: "5px"
                                                    }}>
                                                    <label style={{ marginTop: "16px" }}>
                                                        {m.maMisFieldName}
                                                    </label>
                                                    {m.maMisDesigName === "" && (
                                                        <input type='text' id={m.fieldId} defaultValue={m.maMisFieldName} title='Name'
                                                            style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }}
                                                            onChange={(e) => {
                                                                this.setState({ name: m.maMisFieldName })
                                                                this.handleNameChange(e, m.maMisFieldName)
                                                            }} />
                                                    )}
                                                    {m.maMisDesigName !== "" && (
                                                        <input type='text' id={m.fieldId} defaultValue={m.maMisDesigName} title='Name'
                                                            style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }}
                                                            onChange={(e) => {
                                                                this.setState({ name: m.maMisDesigName })
                                                                this.handleNameChange(e, m.maMisDesigName)
                                                            }} />
                                                    )}
                                                    {m.maMisDesigDescription === "" && (

                                                    <input type='text' id={m.fieldId} defaultValue={m.maMisFieldDescription} title='Description'
                                                        style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }}
                                                        onChange={(e) => {
                                                            this.setState({ description: m.maMisFieldDescription})
                                                            this.handleDescChange(e, m.maMisFieldDescription)
                                                        }}
                                                    />
                                                    )}
                                                    {m.maMisDesigDescription !== "" && (

                                                    <input type='text' id={m.fieldId} defaultValue={m.maMisDesigDescription} title='Description'
                                                        style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }}
                                                        onChange={(e) => {
                                                            this.setState({ description: m.maMisDesigDescription})
                                                            this.handleDescChange(e, m.maMisDesigDescription)
                                                        }}
                                                    />
                                                    )}
                                                    <input type='checkbox' id={m.fieldId} value={m.fieldId} style={{ marginTop: "0" }}
                                                    checked = {m.status}
                                                        onChange={(e) => {
                                                            this.handleCheckboxChange(e, m)
                                                        }}
                                                    />
                                                    {m.status === true && (
                                                    <div>
                                                        <button
                                                            key={m.fieldId} className='btn-primary'
                                                            style={{ marginTop: 10, border: "none", outline: "none" }}
                                                            onClick={() => this.handleUpdateClick(m, this.state.data.designationIds)}
                                                        >update</button>
                                                    </div>
                                                    )} 
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    {this.state.data.status === 'players' && (

                        <div className='col-10' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div className='col-12' style={{ display: "flex", flexDirection: "row" }}>

                                {/* {this.rSB("Players", "playerId", this.state.players, {
                                    value: "playerId",
                                    label: "userName"
                                },
                                    // " col-3 ms-2 me-3"
                                )} */}

                                {this.rTB('Name', 'nameInMaMisField', 'col-4')}
                                {this.rTB('description', 'descriptionInMaMisField', 'col-4')}
                                <div className='col-4' style={{ paddingLeft: 15, paddingRight: 15 }}>

                                    {/* {this.rBtnSP("Create", "create")} */}

                                    <button className='bg-primary text-white'
                                        style={{
                                            border: "none", outline: "none",
                                            padding: " 5px 10px 5px 10px", borderRadius: 3
                                        }}
                                        onClick={() => this.handleClick()}
                                    >Create</button>


                                </div>


                            </div>
                            {/* {this.rSBMulti("Fields", "fields", this.fields, {
                                label: "label",
                                value: "value"
                            },
                                "col-1"
                            )} */}
                            {/* <div className='col-5' style={{ display: "flex", flexDirection: "column", marginTop: -20 }}>

                                <p style={{ fontSize: "14px", fontFamily: "sans-serif" }}>Fields:</p>

                                <div style={{ height: "20rem", width: "40rem", backgroundColor: "#fff", borderRadius: "2px", marginTop: -15, overflowY: 'scroll' }}>
                                    <div style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", justifyContent: "space-evenly" }}>
                                        {this.fields.map((m) => (
                                            <label>

                                                <input type='checkbox' value={m.value} onChange={(e) => this.handleCheckboxChange(e)} />
                                                {m.label}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div> */}
                            {/* {this.state.fieldData.length > 0 && (

                                <div className='col-5' style={{ display: "flex", flexDirection: "column", marginTop: -20 }}>

                                    <p style={{ fontSize: "14px", fontFamily: "sans-serif" }}>Fields:</p>

                                    <div style={{ height: "20rem", width: "40rem", backgroundColor: "#fff", borderRadius: "2px", marginTop: -15, overflowY: 'scroll' }}>
                                        <div style={{ display: "flex", flexDirection: "column", }}>
                                            {this.state.fieldData.map((m) => (
                                                <div
                                                    style={{
                                                        display: "flex", flexDirection: "row", justifyContent: "space-evenly", backgroundColor: "#e3e5ee",
                                                        marginBottom: "5px", marginTop: "5px"
                                                    }}>
                                                    <input type='checkbox' id={m.fieldId} value={m.fieldId} style={{ marginTop: "0" }}
                                                        onChange={(e) => this.handleCheckboxChange(e, m)} />
                                                    <label style={{ marginTop: "16px" }}>
                                                        {m.name}
                                                    </label>
                                                    <input type='text' id={m.fieldId} defaultValue={m.name} title='Name'
                                                        style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }}
                                                        onChange={(e) => this.handleNameChange(e, m.fieldId)} />
                                                    <input type='text' id={m.fieldId} defaultValue={m.description} title='Description'
                                                        style={{ border: "none", outline: "none", borderBottom: "1px solid black", backgroundColor: "#e3e5ee" }} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )} */}
                        </div>
                    )}
                </div>
                {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                    {this.rBtnSP("Submit", "Submit")}
                </div> */}
            </div>
        );
    }
}

export default MasterFields;