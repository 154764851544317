import React, { Component } from "react";
import CostTitle from "../../_subhead/CostTitle";



import CostCentersForm from "./CostCentersForm";
import CostCenterList from "./CostCenterList";

class CostCenters extends Component {
 
  render() {
    return (
      <React.Fragment>
        <CostTitle subHeading="Cost Center :: List" />
        <div className="pageContent">
          <div className="container-fluid">
            <div className="row">
              <div className="col-6">
                <CostCenterList />
              </div>
              <div className="col-6">
                <CostCentersForm />
              </div>
            </div>
          </div>

        </div>
      </React.Fragment>
    );
  }
}
export default CostCenters;
