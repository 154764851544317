import React from "react";
import ZTitle from "../../_subhead/ZTitle";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import { getBillTickets } from "../../../services/misBilling/billServices";
import { toast } from "react-toastify";
import Table from "./../../_common/Table";
import { dropMenuFull } from "./../../../utils/DropMenuUtils";
import Pagination from "./../../_common/Pagination";
import { getClients } from "./../../../services/masters/hubServices";

class BillTickets extends FormLib {
  state = {
    data: { startDate: "", endDate: "", rlTicketNo: "", retailer: "" },
    errors: {},
    retailers: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      count: 0,
      lastPage: 0,
    },
    bills: [],
  };

  schema = {
    startDate: Joi.string().required().label("Start Date"),
    endDate: Joi.string().required().label("End Date"),
    rlTicketNo: Joi.any().optional().label("Ticket No"),
    retailer: Joi.any().optional().label("Retailer"),
  };

  tableMeta = {
    key: "billingId",
    columns: [
      { path: "retailer", label: "Retailer" },
      { path: "misDate", label: "MIS Date" },
      { path: "rlTicketNo", label: "Ticket No." },
      { path: "billStatus", label: "Bill Status" },
    ],
  };

  async componentDidMount() {
    let list = await getClients();
    this.setState({ retailers: list.data.data.clients });
  }

  termsConditions = [{ label: "With Billed Details", value: "true" }];

  async doSubmit() {
    // console.log("Submitted" + JSON.stringify(this.state.data));
    let billTickets = await getBillTickets(
      this.state.data,
      this.state.pageLinks.pageNo
    );

    if (billTickets.data.success === true) {
      this.setState({ bills: billTickets.data.data.MisBillingModel });
      this.setState({ pageLinks: billTickets.data.data.pageLinks });
      toast.success(() => <div>{billTickets.data.msg}</div>);
    } else {
      toast.error(() => <div>{billTickets.data.msg}</div>);
    }
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/misBilling/BillTickets/" + pageNo);

    this.getTickets(this.state.data, pageNo);
  };

  async getTickets(data, pageNo) {
    let billTickets = await getBillTickets(data, pageNo);
    // console.log(
    //   "Submitted" + JSON.stringify(billTickets.data.data.MisBillingModel)
    // );
    if (billTickets.data.success === true) {
      this.setState({ bills: billTickets.data.data.MisBillingModel });
      this.setState({ pageLinks: billTickets.data.data.pageLinks });
    } else {
      toast.error(() => <div>{billTickets.data.msg}</div>);
    }
  }

  async downloadBill() {
    console.log("-----------------> " + this.termsConditions[0].value);
    // let billDownload = await downloadBill(
    //   this.state.data,
    //   this.termsConditions[0].value
    // );
  }

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    // if (itemsCount === 0) return <p>No Data Found</p>;

    const billJSON = this.state.bills;
    // console.log("DATA-------------> " + JSON.stringify(billJSON));
    return (
      <React.Fragment>
        <ZTitle subHeading="Bill :: Tickets" />
        <div className="pageContent">
          <div className="card">
            <div className="card-body">
              <form id="myForm" onSubmit={this.handleSubmit}>
                <div className="form-row">
                  {this.rDP("Start Date", "startDate", "col-md-3")}
                  {this.rDP("End Date", "endDate", "col-md-3")}
                  {this.rTB("Ticket No", "rlTicketNo", "col-md-3")}
                  {this.rSB(
                    "Retailer",
                    "retailer",
                    this.state.retailers,
                    { value: "client", label: "client" },
                    " col "
                  )}
                </div>
              </form>
              <div className="row">
                <div className="col-9">
                  {this.rBtnS("Submit", "Submit", "btn  btn-primary  btn-sm")}
                </div>
                <div className="col-2 pull-right">
                  {this.rCBHL(this.termsConditions)}
                </div>
                <div className="col-1">
                  <button
                    className="btn btn-success btn-sm"
                    onClick={() => this.downloadBill()}
                  >
                    Download
                  </button>
                </div>
              </div>

              <div className="row">
                {this.props.loading && (
                  <div className="container-fluid">
                    <div> Loading Tickets...</div>
                  </div>
                )}
                {!this.props.loading && itemsCount && (
                  <div className="container-fluid">
                    <Table
                      clsName="table table-striped table-bordered table-hover"
                      tableMeta={this.tableMeta}
                      data={billJSON}
                      dropMenu={dropMenuFull()}
                    />
                    <Pagination
                      pageNo={pageNo}
                      perPage={perPage}
                      itemsCount={itemsCount}
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default BillTickets;
