import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLPHP;
function api(path, subURL = "/requestV1") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}
export function createEmpAccount(req) {
  const param = {};
  // param.account_id = req.ACCOUNT_ID;
  param.account_type = req.ACCOUNT_TYPE;
  param.player_code = req.PLAYER_CODE;
  param.account_no = req.ACCOUNT_NO;
  param.name = req.NAME;
  param.ifsc = req.IFSC;
  param.bank = req.BANK;
  param.branch = req.BRANCH;
  return _http.post(
    api("/request-acc-emp-create.php"),
    param,
    _http.headerJWT()
  );
}
export function uploadExcelEmpAccount(req) {
  const param = {};
  // param.account_id = req.ACCOUNT_ID;
  param.account_type = req.ACCOUNT_TYPE;
  param.player_code = req.PLAYER_CODE;
  param.account_no = req.ACCOUNT_NO;
  param.name = req.NAME;
  param.ifsc = req.IFSC;
  param.bank = req.BANK;
  param.branch = req.BRANCH;
  return _http.post(
    api("/request-acc-emp-create.php?edit=true"),
    param,
    _http.headerJWT()
  );

  //   account_id: "4a217cb7273e2c7e0cddc133e894c4f2"
  // account_no: "123456789999"
  // account_type: "SB Account"
  // bank: "asdfgh"
  // branch: "dfgh"
  // ifsc: "ASDFGH"
  // name: "nameee"
  // player_code: "123852"
}
export function saveEmpAccount(req) {
  const param = {};

  param.account_id = req.ACCOUNT_ID;
  param.account_type = req.ACCOUNT_TYPE;
  param.player_code = req.PLAYER_CODE;
  param.account_no = req.ACCOUNT_NO;
  param.name = req.NAME;
  param.ifsc = req.IFSC;
  param.bank = req.BANK;
  param.branch = req.BRANCH;
  const id = param.account_id;
  //delete param.account_id;
  return _http.put(
    api("/request-acc-emp-update.php?account_id=" + id),
    param,
    _http.headerJWT()
  );
}

export function deleteEmpAccount(req) {
  const param = { ...req };
  const id = param.ACCOUNT_ID;
  return _http.delete(
    api("request-acc-emp-delete.php?account_id=" + id),
    _http.headerJWT()
  );
}

export function getEmpAccounts(pageNo, req) {
  let url = "request-acc-emp-list.php?pageNo=" + pageNo + "&perPage=" + 500;
  // console.log(req);

  if (req && req["searchString"] && req["searchString"] !== "") {
    url = url + "&search=" + req.searchString;
  }

  return _http.get(api(url), _http.headerJWT());
}

export function createVenAccount(req) {
  const param = {};
  // param.account_id = req.ACCOUNT_ID;
  param.account_type = req.ACCOUNT_TYPE;
  param.vendor_code = req.VEN_CODE;
  param.account_no = req.ACCOUNT_NO;
  param.name = req.NAME;
  param.ifsc = req.IFSC;
  param.bank = req.BANK;
  param.branch = req.BRANCH;
  return _http.post(
    api("/request-acc-ven-create.php"),
    param,
    _http.headerJWT()
  );
}

export function uploadExcelVenAccount(req) {
  const param = {};
  // param.account_id = req.ACCOUNT_ID;
  param.account_type = req.ACCOUNT_TYPE;
  param.vendor_code = req.VEN_CODE;
  param.account_no = req.ACCOUNT_NO;
  param.name = req.NAME;
  param.ifsc = req.IFSC;
  param.bank = req.BANK;
  param.branch = req.BRANCH;
  return _http.post(
    api("/request-acc-ven-create.php?edit=true"),
    param,
    _http.headerJWT()
  );
}

export function saveVenAccount(req) {
  const param = {};

  param.account_id = req.ACCOUNT_ID;
  param.account_type = req.ACCOUNT_TYPE;
  param.vendor_code = req.VEN_CODE;
  param.account_no = req.ACCOUNT_NO;
  param.name = req.NAME;
  param.ifsc = req.IFSC;
  param.bank = req.BANK;
  param.branch = req.BRANCH;
  const id = param.account_id;
  //delete param.account_id;
  return _http.put(
    api("/request-acc-ven-update.php?account_id=" + id),
    param,
    _http.headerJWT()
  );
}

export function deleteVenAccount(req) {
  const param = { ...req };
  const id = param.ACCOUNT_ID;
  return _http.delete(
    api("request-acc-ven-delete.php?account_id=" + id),
    _http.headerJWT()
  );
}
export function getVenAccounts(pageNo, req) {
  let url = "request-acc-ven-list.php?pageNo=" + pageNo + "&perPage=" + 500;
  // console.log(req);
  if (req && req["searchString"] && req["searchString"] !== "") {
    url = url + "&search=" + req.searchString;
  }
  return _http.get(api(url), _http.headerJWT());
}
