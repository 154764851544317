import React from "react";
import FormLib from "../../../../_common/FormLib";
// import LocationTitle from "../../_subhead/LocationTitle";

import toast from "../../../../../services/_toastService";
import {
  getLineHaulAdditionalFiles,
  getTicketInfo,
  uploadLinehaulAdditionalFiles,
} from "../../../../../services/TicketHistory/TicketHistory";
import {
  IstToUTC,
  epoch2ddmmyyyyhhmmss,
} from "./../../../../../utils/DateUtils";
import Joi from "joi-browser";

class TicketAdditionalFiles extends FormLib {
  state = {
    data: {
      selectedFilesList: [],
      fileInfo: "",
    },
    errors: {},
    curTicket: "",
    curProductDetails: [],
    curTicketDetails: [],
    TicketProductDetails: [],
    TicketProductDetails1: [],
    TicketPrimarytDetails: [],
    setImages: [],
    wentWrong: "",
  };

  componentDidMount() {
    this.loadImages();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({ designation: userInfo.designation });
  }
  schema = {
    fileInfo: Joi.string().required().label("fileInfo "),
  };
  loadImages = async () => {
    try {
      this.setState({ loading: true });

      const ticketLineHaulId = this.props.data.ticketLineHaulId;
      // const processId = "22_03_04_4rr11646398873898TZ4ba175b39eb6484b9e8813f83bae7f09";
      let res = await getLineHaulAdditionalFiles(ticketLineHaulId);
      if (res !== undefined) {
        if (
          res.data.success === true &&
          res.data.data !== [] &&
          res.data.data !== undefined
        ) {
          this.setState({ setImages: res.data.data.ShipmentFiles });
        } else if (res.data.success === false) {
          toast.error("No Images Available for this Block");
        }
      } else {
        this.setState({ wentWrong: undefined });
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in TicketFiles>>>>>> loadImages");
    }
  };

  async getTicketInfo() {
    try {
      this.setState({ loading: true });

      const ticketNo = this.props.curTicket;
      this.setState({ curTicket: this.props.curTicket });
      this.state.TicketProductDetails = [];
      this.state.TicketProductDetails1 = [];

      this.state.TicketPrimarytDetails = [];
      const respDst = await getTicketInfo(ticketNo);
      if (respDst !== undefined) {
        if (respDst) {
          if (respDst.data.success === true && respDst.data.data !== []) {
            this.setState({
              TicketPrimarytDetails:
                respDst.data.data.TicketDetails.primaryDetails,
            });
            this.setState({
              TicketProductDetails:
                respDst.data.data.TicketDetails.productDetails[0],
            });
            this.setState({
              TicketProductDetails1:
                respDst.data.data.TicketDetails.productDetails[1],
            });
          } else if (respDst.data.success === false) {
            toast.error("No Details Available for this Ticket");
          }
        }
      } else {
        this.setState({ wentWrong: undefined });
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in TicketDetails>>>>>> getTicketInfo");
    }
  }

  stringReplace(data) {
    return data
      .replace(/{/g, "")
      .replace(/}/g, "")
      .replace(/]/g, "")
      .replace(/"/g, "")
      .replace(/:/g, " : ")
      .replace(/,/g, " , ");
  }

  LatLong(data) {
    let result = JSON.parse(data);
    if (result) {
      return "[ " + result["Latitude"] + " , " + result["Longitude"] + " ]";
    }
  }

  replaceString = (str) => {
    return str.replace(/_/g, " ");
  };

  selectedFilesLists = (e) => {
    let fileObj = [];
    let fileArray = [];
    fileObj.push(e);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(fileObj[0][i]);
    }
    this.setState({ file: fileArray });

    let data = this.state.data;
    data.selectedFilesList = e;
    this.setState({ data });
  };

  deleteFile(e) {
    const s = this.state.file.filter((item, index) => index !== e);
    this.setState({ file: s });
    let data = this.state.data;
    data.selectedFilesList = s;
    this.setState({ data });
  }

  uploadFiles = async (e) => {
    try {
      let override = { ...this.state.data };

      const data = {
        ticketLinehaulId: this.props.data.ticketLineHaulId,
        info: this.state.data.fileInfo,
        selectedFilesList: this.state.data.selectedFilesList,
      };
      let resp = await uploadLinehaulAdditionalFiles(data);
      if (resp.data.statusCode === 200) {
        toast.toastSuccess(resp.data.msg);
        this.reSetFormData();
        this.loadImages();
      } else {
        toast.toastWarn("Kindly choose file to upload");
      }
    } catch {
      console.log("TicketAdditionalFiles >>>>>>>>>>>>>>> uploadFiles");
    }
  };

  reSetFormData() {
    let data = { ...this.state.data };
    data["selectedFilesList"] = [];
    data["fileInfo"] = "";
    this.setState({ data });
    this.setState({ file: [] });
  }

  render() {
    const imgStyle = {
      border: "1px solid #d3c4c4",
      width: "130px",
      height: "130px",
      "line-height": "130px",
      float: "left",
      cursor: "pointer",
      "font-size": "48px",
      color: "#d7caca",
      "background-color": "#f9f1f1",
      "border-radius": "6px",
    };
    return (
      <React.Fragment>
        {this.state.loading && (
          <div className="container-fluid">
            <div> Loading Files...</div>
          </div>
        )}
        <div className="row">
          {/* {this.state.designation === "Key Accounts Manager" && ( */}
          <div className="col-6">
            <>
              <form>
                <b className="mt-0">&nbsp;&nbsp; Additional File Upload :</b>
                <div
                  className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3 "
                  style={{ backgroundColor: "#f5f0f0" }}
                >
                  <div className="m-4 p-1 col-10 mt-2 ml-4">
                    <label
                      htmlFor="refDocFiles"
                      className="form-label font-monospace"
                    >
                      Upload Files *
                    </label>
                    <input
                      className="form-control form-control-sm"
                      multiple
                      id="refDocFiles"
                      type="file"
                      onChange={(e) => {
                        this.selectedFilesLists(e.target.files);
                      }}
                    />
                  </div>
                  {this.rTB("File Info ", "fileInfo", " col-12 mt-3 mb-4")}
                  <div
                    className="col-12 page-todo overflow-auto"
                    style={{ height: "15rem" }}
                  >
                    <div className="tasks">
                      <div className="form-group multi-preview">
                        {(this.state.file || []).map((url, index) => (
                          <div key={url}>
                            <li className="list-group-item" key={index}>
                              <p>
                                <a>{url.name}</a>
                              </p>{" "}
                              <img
                                className="mt-5"
                                src={URL.createObjectURL(url)}
                                alt={url.name}
                                key={index}
                                style={{ height: "50px", width: "100px" }}
                              />
                              <button
                                type="button"
                                style={{
                                  position: "absolute",
                                  marginLeft: "30px",
                                }}
                                onClick={() => this.deleteFile(index)}
                              >
                                delete
                              </button>
                            </li>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col m-0 mt-1 p-0">
                  <button
                    type="button"
                    className="btn btn-primary btn-sm pull-right  "
                    onClick={(e) => {
                      this.uploadFiles(e);
                    }}
                  >
                    Submit Files
                  </button>
                </div>
              </form>
            </>
          </div>
          {/* )} */}
          <div className="col-6">
            {!this.state.loading && this.state.setImages?.length !== 0 && (
              <div className="row ">
                <div className="col-md-12">
                  <div className="card ">
                    <div className="card-body bg-custom-6">
                      <div
                        className="form-subHeader text-center"
                        style={{
                          color: "#e6e8f1",
                          backgroundColor: "#8b9566",
                        }}
                      >
                        Shipment Files
                      </div>
                      <div
                        className="row overflow-auto"
                        style={{ height: "25rem" }}
                      >
                        {this.state.setImages.map((t) => {
                          return (
                            <>
                              <div className="col-4 ">
                                <div className="col-md-4">
                                  {t.fileType == "jpg" && (
                                    <>
                                      <span>
                                        <a
                                          href={t.imageUrl}
                                          data-toggle="tooltip"
                                          title={""}
                                        >
                                          <img
                                            src={t.imageUrl}
                                            style={{
                                              width: "130px",
                                              height: "130px",
                                              margin: "3px",
                                            }}
                                          />
                                        </a>
                                        <br></br>
                                        <span
                                          className="badge badge-info link-over ml-2"
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            margin: "3px",
                                          }}
                                        >
                                          {t.action}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                  {t.fileType == "jpeg" && (
                                    <>
                                      <span>
                                        <a
                                          href={t.imageUrl}
                                          data-toggle="tooltip"
                                          title={""}
                                        >
                                          <img
                                            src={t.imageUrl}
                                            style={{
                                              width: "130px",
                                              height: "130px",
                                              margin: "3px",
                                            }}
                                          />
                                        </a>
                                        <br></br>
                                        <span
                                          className="badge badge-info link-over ml-2"
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            margin: "3px",
                                          }}
                                        >
                                          {t.action}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                  {t.fileType == "png" && (
                                    <>
                                      <span>
                                        <a
                                          href={t.imageUrl}
                                          data-toggle="tooltip"
                                          title={""}
                                        >
                                          <img
                                            src={t.imageUrl}
                                            style={{
                                              width: "130px",
                                              height: "130px",
                                              margin: "3px",
                                            }}
                                          />
                                        </a>
                                        <br></br>
                                        <span
                                          className="badge badge-info link-over ml-2"
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            margin: "3px",
                                          }}
                                        >
                                          {t.action}
                                        </span>
                                      </span>
                                    </>
                                  )}

                                  {t.fileType == "gif" && (
                                    <>
                                      <span>
                                        <a
                                          href={t.imageUrl}
                                          data-toggle="tooltip"
                                          title={""}
                                        >
                                          <img
                                            src={t.imageUrl}
                                            style={{
                                              width: "130px",
                                              height: "130px",
                                              margin: "3px",
                                            }}
                                          />
                                        </a>
                                        <br></br>
                                        <span
                                          className="badge badge-info link-over ml-2"
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            margin: "3px",
                                          }}
                                        >
                                          {t.action}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                  {t.fileType == "xlsx" && (
                                    <>
                                      <span>
                                        <a
                                          href={t.imageUrl}
                                          target="_self"
                                          title="Click to download"
                                        >
                                          <i
                                            className="fa fa-file-excel-o text-center"
                                            style={imgStyle}
                                          ></i>
                                        </a>
                                        <br></br>
                                        <span
                                          className="badge badge-info link-over "
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            margin: "3px",
                                          }}
                                        >
                                          {t.action}
                                        </span>
                                      </span>
                                    </>
                                  )}

                                  {t.fileType == "txt" && (
                                    <>
                                      <span>
                                        <a
                                          href={t.imageUrl}
                                          target="_self"
                                          title="Click to download"
                                        >
                                          <i
                                            className="fa fa-file-text mb-1 text-center"
                                            style={imgStyle}
                                          ></i>
                                        </a>
                                        <br></br>
                                        <span
                                          className="badge badge-info link-over "
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            margin: "3px",
                                          }}
                                        >
                                          {t.action}
                                        </span>
                                      </span>
                                    </>
                                  )}

                                  {t.fileType == "pdf" && (
                                    <>
                                      <span>
                                        <a
                                          href={t.imageUrl}
                                          target="_self"
                                          title="Click to download"
                                        >
                                          <i
                                            className="fa fa-file-pdf-o mb-1 text-center"
                                            style={imgStyle}
                                          ></i>
                                        </a>
                                        <br></br>
                                        <span
                                          className="badge badge-info link-over "
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            margin: "3px",
                                          }}
                                        >
                                          {t.action}
                                        </span>
                                      </span>
                                    </>
                                  )}

                                  {t.fileType == "docx" && (
                                    <>
                                      <span>
                                        <a
                                          href={t.imageUrl}
                                          target="_self"
                                          title="Click to download"
                                        >
                                          <i
                                            className="fa fa-file-word-o mb-1 text-center"
                                            style={imgStyle}
                                          ></i>
                                        </a>
                                        <br></br>
                                        <span
                                          className="badge badge-info link-over "
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            margin: "3px",
                                          }}
                                        >
                                          {t.action}
                                        </span>
                                      </span>
                                    </>
                                  )}

                                  {t.fileType == "bmp" && (
                                    <>
                                      <span>
                                        <a
                                          href={t.imageUrl}
                                          target="_self"
                                          title="Click to download"
                                        >
                                          <i
                                            className="fa fa-file-word-o mb-1 text-center"
                                            style={imgStyle}
                                          ></i>
                                        </a>
                                        <br></br>
                                        <span
                                          className="badge badge-info link-over "
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            margin: "3px",
                                          }}
                                        >
                                          {t.action}
                                        </span>
                                      </span>
                                    </>
                                  )}

                                  <br></br>
                                  {/* {this.LatLong(t.geoLocation)} */}
                                  <br></br>
                                  <br></br>
                                </div>
                              </div>
                              <div className="col-8">
                                <div>
                                  <h5>
                                    <span> Upload By </span>
                                    <span className="ml-2">
                                      {" "}
                                      : &nbsp;{t.createdBy}
                                    </span>
                                  </h5>
                                </div>
                                <div>
                                  <h5>
                                    <span> Upload At </span>
                                    <span className="ml-2">
                                      {" "}
                                      : &nbsp;
                                      {epoch2ddmmyyyyhhmmss(t.createdAt)}
                                    </span>
                                  </h5>
                                </div>
                                <div>
                                  <h5>
                                    <span> Info </span>
                                    <span className="ml-5">
                                      {" "}
                                      : &nbsp;{t.info}
                                    </span>
                                  </h5>
                                </div>
                                <div>
                                  <h5>
                                    <span> File Type </span>
                                    <span className="ml-3">
                                      {" "}
                                      : &nbsp;{t.fileType}
                                    </span>
                                  </h5>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* {!this.state.loading &&
          this.state.setImages.length === 0 &&
          this.state.wentWrong !== undefined && (
            <div className="container-fluid jumbotron text-center">
              <h4>No Images Available for this ticket...</h4>
            </div>
          )}
        {!this.state.loading && this.state.wentWrong === undefined && (
          <div className="container-fluid jumbotron text-center">
            <h4>Something went wrong please try again...</h4>
          </div>
        )}  */}
      </React.Fragment>
    );
  }
}

export default TicketAdditionalFiles;
