import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import {
  getExpenseHead,
  createExpenseHead,
  saveExpenseHead,
  deleteExpenseHead,
} from "../../../services/requestV1/ExpenseHeadService";
class AccountsExpenseHeadForm extends FormLib {
  state = {
    data: {
      EXPENSE_HEAD_ID: "",
      EXPENSE_HEAD_CODE: "",
      EXPENSE_HEAD: "",
     
    },
    errors: {},
  };

  schema = {
    EXPENSE_HEAD_ID: Joi.optional().label("EXPENSE_HEAD_ID"),
    EXPENSE_HEAD: Joi.string().required().label("Expense Head"),
    EXPENSE_HEAD_CODE: Joi.required().label("Expense Code"),
  };
  componentDidMount() {
    // console.log(JSON.stringify(this.props.curExpenseHeadAccounts));
    this.loadCurAccount(this.props.curExpenseHeadAccounts);
  }

  loadCurAccount = (a) => {
    if (a.EXPENSE_HEAD_ID && a.EXPENSE_HEAD_ID !== "") {
      delete a["CREATED_AT"];
      delete a["CREATED_BY"];
      delete a["UPDATED_AT"];
      delete a["UPDATED_BY"];
      this.setState({ data: a });
    } else {
      this.resetData();
    }
  };

  async loadExpenseHeadAccounts(pageNo) {
    this.setState({ loading: true });
    const res = await getExpenseHead(pageNo);

    if (res.data.status === 1) {
      this.setState({ ExpenseHeadAccounts: res.data.data });
      let pageLinks = res.data.pageLinks;
      pageLinks["itemsCount"] = pageLinks.count;
      this.setState({ pageLinks: pageLinks });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createExpenseHead(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveExpenseHead(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "delete") {
      res = await deleteExpenseHead(this.state.data);
      this.resetData();
    }
    if (res.data.status === 1) {
      toast.toastSuccess(res.data.msg);
      this.props.onDataChange();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  resetData() {
    this.setState({
      data: {
        EXPENSE_HEAD_ID: "",
        EXPENSE_HEAD: "",
        EXPENSE_HEAD_CODE: "",
      },
    });
  }

  render() {
    return (
      <React.Fragment>
        <form>
          <div className="form-row">
            {this.rTBH("Expense Head Id", "EXPENSE_HEAD_ID", "col-1")}
            {this.rTB("Expense Head", "EXPENSE_HEAD", "col-6")}
            {this.rTB("Expense Head Code", "EXPENSE_HEAD_CODE", "col-6")}
          </div>
        </form>
        {this.state.data.EXPENSE_HEAD_ID === "" && this.rBtnSP("Create", "create")}
        {this.state.data.EXPENSE_HEAD_ID !== "" && (
          <>
            {this.rBtnSS("Edit", "edit")}
            {this.rBtnSD("Delete", "delete")}
          </>
        )}
      </React.Fragment>
    );
  }
}
export default AccountsExpenseHeadForm;
