import React from "react";
import FormLib from "./../../_common/FormLib";
import swal from "sweetalert";
import { toast } from "react-toastify";
import {
  getPackingMaterialAndProdByJobId,
  getPackingMaterialAndProdByJobIdAdvExc,
  setTicketActionsReschedule,
} from "./../../../services/Tickets/TicketProcessServices";
import { getTicketMisHistory } from "./../../../services/Tickets/TicketServices";
import { ticketListReload } from "../../../store";
import { connect } from "react-redux";
import {
  IstToUTC,
  epoch2ddmmyyyyhhmmss,
  epoch2yyyymmddhhmmss,
} from "../../../utils/DateUtils";
import Joi from "joi-browser";
import {
  setTicketActions,
  phoneCall,
} from "./../../../services/Tickets/TicketProcessServices";
import BulkQCProcess from "./BulkQCProcess";
import PickUpVerificationProduct from "./PickUpVerificationProduct";
import DropVerificationProduct from "./DropVerificationProduct";

class RescheduleProcess extends FormLib {
  countDownInterval;
  state = {
    data: {
      actionCode: "wRescheduleInitiate",
      action: "RESCHEDULE_INITIATE",
      appointmentDate: "",
    },
    errors: {},
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    closeStatus: "false",
    pageHide: "false",
    countDown: 30,
    rescheduleBy: "",
    confirmData: false,
    // confirmData1: false,
    rescheduleItemsVerified: false,
    rescheduleItemsVerify: false,
  };

  componentDidMount() {
    this.loadInit();
    this.getlocalDateTime();
    this.loadPackingMaterialData();
    this.loadPackingMaterialData1();
  }

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }

  loadInit() {
    try {
      const curTicketDetails = this.props.ticketDetails;
      const curBlockDetails = this.props.blockDetails;
      const curProductDetails = this.props.productDetails;
      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails });
      // IstToUTC(new Date()).slice(0, 16).replace(" ", "T")
      this.setState({
        data: {
          actionCode: "wRescheduleInitiate",
          action: "RESCHEDULE_INITIATE",
          appointmentDate: IstToUTC(curBlockDetails.feAppointmentDateTime)
            .slice(0, 16)
            .replace(" ", "T"),
        },
      });
    } catch {
      console.log("Error in RescheduleProcess>>>>>>loadInit");
    }
  }
  async getTicketMisHistory(ticketNo) {
    try {
      this.state.curMisReportHistory = [];
      const respDst = await getTicketMisHistory(ticketNo);
      if (respDst !== undefined) {
        if (respDst.data.success === true && respDst.data.data !== []) {
          let result = respDst.data.data.MisReportHistory[0].resultSet;
          this.result = JSON.parse(result);
          this.setState({
            rescheduleRemarks: JSON.stringify(this.result.resultSet.remark),
          });
          this.setState({
            rescheduleTime: JSON.stringify(
              this.result.resultSet.reScheduleDateTime
            ),
          });
          this.setState({
            curMisReportHistory: respDst.data.data.MisReportHistory,
          });
        } else if (respDst.data.success === false) {
          toast.error("No Details Available for this Ticket");
        }
      }
    } catch {
      console.log("Eror in Reschedule process");
    }
  }

  handleReschedule = async () => {
    try {
      if (this.state.rescheduleBy === "") {
        swal({
          icon: "error",
          title: "Please select Reschedule by option",
          dangerMode: true,
        }).then((res) => {
          const resByCustRadio = document.getElementById("byCustomer");
          resByCustRadio.focus();
        });
        return null;
      }
      swal({
        title: "Are you sure want to Initiate Reschedule?",
        text: "Kindly Enter the Reason for Reschedule",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: "input",
        placeholder: "Write something",
      }).then(async (handleReschedule) => {
        this.doReschedule(handleReschedule);
      });
    } catch {
      console.log("Error in RescheduleProcess>>>>>>handleReschedule");
    }
  };

  doReschedule = async (handleReschedule) => {
    try {
      if (handleReschedule) {
        swal(
          "Reschedule has been successfully Initiated with the following remarks: " +
            handleReschedule,
          {
            icon: "success",
          }
        );
        this.submitReschedule(handleReschedule);
      } else {
        swal("Reschedule not initiated!", { icon: "error" });
      }
    } catch {
      console.log("Error in RescheduleProcess>>>>>>doReschedule");
    }
  };

  submitReschedule = async (handleReschedule) => {
    try {
      let resultSet = {};
      if (this.state.curBlockDetails.flowStatus === "PICK_WEB") {
        this.resultSet = {
          comments: "by " + this.state.rescheduleBy + ":: " + handleReschedule,
          actionStatus: "RESCHEDULE_FE_AT_PICKUP",
          appointmentDate: this.state.data.appointmentDate,
          processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        };
      } else if (this.state.curBlockDetails.flowStatus === "DROP_WEB") {
        this.resultSet = {
          comments: "by " + this.state.rescheduleBy + ":: " + handleReschedule,
          actionStatus: "RESCHEDULE_FE_AT_DROP",
          appointmentDate: this.state.data.appointmentDate,
          processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        };
      }

      let data = this.state.data;
      data = {
        actionCode: "wRescheduleInitiate",
        action: "Reschedule Initiated",
      };
      this.setState({ data });
      let resp = await setTicketActionsReschedule(
        this.state.data,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        this.resultSet
      );
      if (resp !== undefined) {
        if (resp.data.success === true) {
          if (resp.data.data) {
            this.handleTicketListReload();
            this.setState({ pageHide: "true" });
            this.setState({ closeStatus: "true" });
            this.setState({ RescheduleStatus: "RESCHEDULE INITIATED" });
            // this.setState({ RescheduleStatus: "false" });
          } else {
            toast.warning(() => <div>{resp.data.msg}</div>);
          }
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      }
    } catch {
      console.log("Error in RescheduleProcess>>>>>>submitReschedule");
    }
  };

  handleRejectReschedule = async () => {
    try {
      swal({
        title: "Are you sure want to Reject Reschedule?",
        text: "Kindly Enter the Reason for Reject Reschedule",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: "input",
        placeholder: "Write something",
      }).then(async (handleRejectReschedule) => {
        this.doRejectReschedule(handleRejectReschedule);
      });
    } catch {
      console.log("Error in RescheduleProcess>>>>>>handleRejectReschedule");
    }
  };

  doRejectReschedule = async (handleRejectReschedule) => {
    try {
      if (handleRejectReschedule) {
        swal(
          "Reschedule has been successfully Rejected with the following remarks: " +
            handleRejectReschedule,
          {
            icon: "success",
          }
        );
        this.submitRejectReschedule(handleRejectReschedule);
      } else {
        swal("Reschedule not Rejected!", { icon: "error" });
      }
    } catch {
      console.log("Error in RescheduleProcess>>>>>>doRejectReschedule");
    }
  };

  submitRejectReschedule = async (handleRejectReschedule) => {
    try {
      let resultSet = {};
      if (this.state.curBlockDetails.flowStatus === "PICK_WEB") {
        this.resultSet = {
          comments: handleRejectReschedule,
          actionStatus: "ASSIGNED_FE_FOR_PICKUP",
          appointmentDate: IstToUTC(
            this.state.curBlockDetails.feAppointmentDateTime
          )
            .slice(0, 16)
            .replace(" ", "T"),
          processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        };
      } else if (this.state.curBlockDetails.flowStatus === "DROP_WEB") {
        this.resultSet = {
          comments: handleRejectReschedule,
          actionStatus: "ASSIGNED_FE_FOR_DROP",
          appointmentDate: IstToUTC(
            this.state.curBlockDetails.feAppointmentDateTime
          )
            .slice(0, 16)
            .replace(" ", "T"),
          processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        };
      }

      let data = this.state.data;
      data = {
        actionCode: "wRescheduleReject",
        action: "Reschedule Rejected",
      };
      this.setState({ data });
      let resp = await setTicketActionsReschedule(
        this.state.data,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        this.resultSet
      );
      if (resp !== undefined) {
        if (resp.data.success === true) {
          if (resp.data.data) {
            this.handleTicketListReload();
            this.setState({ pageHide: "true" });
            this.setState({ closeStatus: "true" });
            this.setState({ RescheduleStatus: "RESCHEDULE REJECTED" });
            // this.setState({ RescheduleStatus: "false" });
          } else {
            toast.warning(() => <div>{resp.data.msg}</div>);
          }
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      }
    } catch {
      console.log("Error in RescheduleProcess>>>>>>submitRejectReschedule");
    }
  };
  handleCall = async () => {
    try {
      this.countDownInterval = setInterval(() => {
        if (this.state.countDown <= 0) {
          clearInterval(this.countDownInterval);
        } else {
          this.setState((prevState) => {
            return { ...prevState, countDown: prevState.countDown - 1 };
          });
        }
      }, 1000);

      let selectedHub = localStorage.getItem("curHub");
      let toContactPersonNo = "";
      let fromContactPerson = "";

      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      fromContactPerson = userInfo.phoneNo;

      if (
        (this.state.curBlockDetails.flowStatus === "DROP_WEB" &&
          this.state.curBlockDetails.direction === "DTD") ||
        (this.state.curBlockDetails.flowStatus === "PICK_WEB" &&
          this.state.curBlockDetails.direction === "RTO")
      ) {
        toContactPersonNo = this.state.curProductDetails[0].dstContact1;
      } else if (
        (this.state.curBlockDetails.flowStatus === "PICK_WEB" &&
          this.state.curBlockDetails.direction === "DTD") ||
        (this.state.curBlockDetails.flowStatus === "DROP_WEB" &&
          this.state.curBlockDetails.direction === "RTO")
      ) {
        toContactPersonNo = this.state.curProductDetails[0].srcContact1;
      }

      let params = {
        fromNO: fromContactPerson,
        toNo: toContactPersonNo,
        hubId: selectedHub,
        ticketNo: this.state.curTicketDetails.ticketNo,
        blockStatus: this.state.curBlockDetails.blockStatus,
        processId: this.state.curBlockDetails.processId,
        blockId: this.state.curBlockDetails.ticketBlockId,
      };

      let resp = await phoneCall(params);

      if (resp.data.success === true) {
        resp.data && toast.success(() => <div>{resp.data.msg}</div>);
        !resp.data && toast.warning(() => <div>{resp.data.msg}</div>);
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in RescheduleProcess>>>>>>handleCall");
    }
  };
  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };
  handleAlternateCall = async () => {
    try {
      this.countDownInterval = setInterval(() => {
        if (this.state.countDown <= 0) {
          clearInterval(this.countDownInterval);
        } else {
          this.setState((prevState) => {
            return { ...prevState, countDown: prevState.countDown - 1 };
          });
        }
      }, 1000);
      let selectedHub = localStorage.getItem("curHub");
      let toContactPersonNo = "";
      let fromContactPerson = "";

      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      fromContactPerson = userInfo.phoneNo;

      if (fromContactPerson === null) {
        toast.error("Alternate Phone Number of Coordinator is not Available");
      }

      if (
        (this.state.curBlockDetails.flowStatus === "DROP_WEB" &&
          this.state.curBlockDetails.direction === "DTD") ||
        (this.state.curBlockDetails.flowStatus === "PICK_WEB" &&
          this.state.curBlockDetails.direction === "RTO")
      ) {
        toContactPersonNo = this.state.curProductDetails[0].dstContact2;
      } else if (
        (this.state.curBlockDetails.flowStatus === "PICK_WEB" &&
          this.state.curBlockDetails.direction === "DTD") ||
        (this.state.curBlockDetails.flowStatus === "DROP_WEB" &&
          this.state.curBlockDetails.direction === "RTO")
      ) {
        toContactPersonNo = this.state.curProductDetails[0].srcContact2;
      }

      if (toContactPersonNo === null) {
        toast.error(
          "Alternate Phone Number of Destination Contact Person is not Available"
        );
      }

      if (fromContactPerson !== null && toContactPersonNo !== null) {
        let params = {
          fromNO: fromContactPerson,
          toNo: toContactPersonNo,
          hubId: selectedHub,
          ticketNo: this.state.curTicketDetails.ticketNo,
          blockStatus: this.state.curBlockDetails.blockStatus,
          processId: this.state.curBlockDetails.processId,
          blockId: this.state.curBlockDetails.ticketBlockId,
        };

        let resp = await phoneCall(params);

        if (resp.data.success === true) {
          resp.data && toast.success(() => <div>{resp.data.msg}</div>);
          !resp.data && toast.warning(() => <div>{resp.data.msg}</div>);
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      }
    } catch {
      console.log("Error in RescheduleProcess>>>>>>handleAlternateCall");
    }
  };

  loadPackingMaterialData = async () => {
    try {
      //   if(this.props.blockDetails.flowStatus === "DROP_WEB"){
      //   let blockData = this.props.blockDetails;
      //   let ticketData = this.props.ticketDetails
      //   let res = await getPackingMaterialAndProdByJobIdAdvExc(blockData,ticketData);
      //   if (res !== undefined) {
      //     if (res.data.success === true && res.data.data) {
      //       this.getPackingMaterialAndProdStatus(
      //         res.data.data.packingMaterialDetails
      //       );
      //       this.setState({ confirmData: true });
      //     } else {
      //       this.setState({ confirmData: false });
      //     }
      //   } else {
      //     this.setState({ confirmData: false });
      //   }
      // }else{
      let blockData = this.props.blockDetails;
      let ticketData = this.props.ticketDetails;
      let res = await getPackingMaterialAndProdByJobId(blockData, ticketData);
      if (res !== undefined) {
        if (res.data.success === true && res.data.data) {
          this.getPackingMaterialAndProdStatus(
            res.data.data.packingMaterialDetails
          );
          this.setState({ confirmData: true });
        } else {
          this.setState({ confirmData: false });
        }
      } else {
        this.setState({ confirmData: false });
      }
      // }
    } catch {
      console.log(
        "Error in loadPackingMaterialData>>>>>>>>>>>>>>>>>>>>>>>>>>PickUp"
      );
    }
  };
  // getPackingMaterialAndProdStatus = async (data) => {
  //   if (data.length > 0) {

  //   data.map((r) => {
  //     if (r.packMaterialId) {
  //       if (r.packMaterialItemStatus === "itemsDropVerified") {
  //         this.setState({ dropItemsVerified: true });
  //         // this.forceUpdate();
  //       }
  //       else{
  //         this.setState({ dropItemsVerified: false });
  //       }
  //     } else {
  //       if (r.productDetails[0].itemStatus  === "itemsDropVerified") {
  //         this.setState({ dropItemsVerified: true });
  //         // this.forceUpdate();
  //       }
  //       else{
  //         this.setState({ dropItemsVerified: false });
  //       }
  //     }
  //   });
  // } else {
  //   this.setState({ dropItemsAssigned: true });
  //   this.setState({ confirmData: false });
  // }
  // };

  getPackingMaterialAndProdStatus = async (data) => {
    // console.log("data======>", data)
    if (data.length > 0) {
      data.map((r) => {
        if (r.packMaterialId) {
          if (r.packMaterialItemStatus !== null) {
            this.setState({ rescheduleItemsVerified: true });
            // this.forceUpdate();
          } else {
            this.setState({ rescheduleItemsVerified: false });
          }
        } else {
          if (r.productDetails[0].itemStatus !== null) {
            this.setState({ rescheduleItemsVerified: true });
            // this.forceUpdate();
          } else {
            this.setState({ rescheduleItemsVerified: false });
          }
        }
      });
    } else {
      this.setState({ dropItemsAssigned: true });
      this.setState({ confirmData: false });
    }
  };
  getPackingMaterialAndProdStatus1 = async (data) => {
    //   console.log("ffff",localStorage.getItem("inventoryInward"))
    // let  curData = localStorage.getItem("inventoryInward")
    // console.log("data======>", data)
    if (data.length > 0) {
      data.map((r) => {
        if (r.packMaterialId) {
          if (r.packMaterialItemStatus !== null) {
            this.setState({ rescheduleItemsVerify: true });
            // this.forceUpdate();
          } else {
            this.setState({ rescheduleItemsVerify: false });
          }
        } else {
          // console.log("yyyyyyyyyyyyyyyyyyy",r.productDetails[0].itemStatus)

          if (r.productDetails[0].itemStatus === "itemsRescheduled") {
            this.setState({ rescheduleItemsVerify: true });
            // this.forceUpdate();
          } else {
            this.setState({ rescheduleItemsVerify: false });
          }
        }
      });
    } else {
      this.setState({ dropItemsAssigned: true });
      this.setState({ confirmData: false });
    }
  };

  loadPackingMaterialData1 = async () => {
    try {
      //       if(this.props.blockDetails.flowStatus === "DROP_WEB"){
      //       let blockData = this.props.blockDetails;
      //       let ticketData = this.props.ticketDetails
      //       let res = await getPackingMaterialAndProdByJobIdAdvExc(blockData,ticketData);
      //       if (res !== undefined) {
      //         if (res.data.success === true && res.data.data !== []) {
      //           this.getPackingMaterialAndProdStatus1(
      //             res.data.data.packingMaterialDetails
      //           );
      //           this.setState({itemStatus: res.data.data.packingMaterialDetails[0].productDetails
      // [0].itemStatus })
      //           this.setState({ confirmData: true });
      //         } else {
      //           this.setState({ confirmData: false });
      //         }
      //       } else {
      //         this.setState({ confirmData: false });
      //       }
      //     }else{
      let blockData = this.props.blockDetails;
      let ticketData = this.props.ticketDetails;
      let res = await getPackingMaterialAndProdByJobId(blockData, ticketData);
      if (res !== undefined) {
        if (res.data.success === true && res.data.data !== []) {
          this.getPackingMaterialAndProdStatus1(
            res.data.data.packingMaterialDetails
          );
          this.setState({
            itemStatus:
              res.data.data.packingMaterialDetails[0].productDetails[0]
                .itemStatus,
          });
          this.setState({ confirmData: true });
        } else {
          this.setState({ confirmData: false });
        }
      } else {
        this.setState({ confirmData: false });
      }
      // }
    } catch {
      console.log(
        "Error in loadPackingMaterialData>>>>>>>>>>>>>>>>>>>>>>>>>>PickUp"
      );
    }
  };

  componentWillUnmount() {
    clearInterval(this.countDownInterval);
  }

  render() {
    const ticketDetails = this.state.curTicketDetails;
    // console.log("this.state.itemStatus", this.state.rescheduleItemsVerified )
    // console.log("this.state.itemStatus22", this.state.rescheduleItemsVerify)
    // console.log("this.state.itemStatus1", this.state.confirmData)

    return (
      <>
        {this.state.pageHide === "false" && (
          <div className="row mt-4 ">
            <div className="col-md-12">
              <div className="card ">
                <div className="card-body bg-light">
                  {this.state.curTicketDetails.flowId === "Furniture" && (
                    <BulkQCProcess
                      ticketDetails={this.state.curTicketDetails}
                      blockDetails={this.state.curBlockDetails}
                      productDetails={this.state.curProductDetails}
                      key={"bt_" + this.state.curBlockDetails.ticketBlockId}
                      handleNotAttemptProductVerification={
                        this.handleNotAttemptProductVerification
                      }
                    />
                  )}
                  <div className="form-subHeader">
                    <b>
                      Ticket Reschedule Verification - {ticketDetails.ticketNo}
                    </b>
                  </div>
                  {this.state.confirmData === true &&
                    this.state.curBlockDetails.flowStatus === "PICK_WEB" && (
                      <PickUpVerificationProduct
                        productDetails={this.state.curProductDetails}
                        ticketDetails={this.state.curTicketDetails}
                        blockDetails={this.state.curBlockDetails}
                        key={"p_" + this.state.curBlockDetails.ticketBlockId}
                        onHandleGetConfirmStatus={this.loadPackingMaterialData}
                        onHandleGetConfirmStatuses={
                          this.loadPackingMaterialData1
                        }
                      />
                    )}
                  {this.state.confirmData === true &&
                    this.state.curBlockDetails.flowStatus === "DROP_WEB" && (
                      <PickUpVerificationProduct
                        productDetails={this.state.curProductDetails}
                        ticketDetails={this.state.curTicketDetails}
                        blockDetails={this.state.curBlockDetails}
                        key={"p_" + this.state.curBlockDetails.ticketBlockId}
                        onHandleGetConfirmStatus={this.loadPackingMaterialData}
                        onHandleGetConfirmStatuses={
                          this.loadPackingMaterialData1
                        }
                      />
                    )}
                  {this.state.rescheduleItemsVerified === true &&
                    this.state.rescheduleItemsVerify === true && (
                      <div className="row mt-4 ">
                        <>
                          <div id="coAction" className="col-md-12">
                            <div className="card ">
                              <div className="card-body  bg-light">
                                <div className="col-md-12 ">
                                  <span
                                    className="fa-stack fa-lg pull-left"
                                    title="Primary Number"
                                  >
                                    <i
                                      className="fa fa-phone fa-stack-2x cursorPointor"
                                      style={{ color: "blue" }}
                                      onClick={this.handleCall}
                                    ></i>
                                  </span>
                                  {(this.state.curBlockDetails.flowStatus ===
                                    "DROP_WEB" &&
                                    this.state.curProductDetails[0]
                                      .dstContact2 !== "") ||
                                    (this.state.curBlockDetails.flowStatus ===
                                      "PICK_WEB" &&
                                      this.state.curProductDetails[0]
                                        .srcContact2 !== "" && (
                                        <>
                                          <span
                                            className="fa-stack fa-lg pull-left"
                                            title="Alternate Number"
                                          >
                                            <i
                                              className="fa fa-phone fa-stack-2x cursorPointor primary"
                                              style={{ color: "green" }}
                                              onClick={this.handleAlternateCall}
                                            ></i>
                                          </span>
                                        </>
                                      ))}
                                </div>
                                <form onSubmit={this.handleSubmit}>
                                  <div
                                    className="form-row pt-2"
                                    style={{ marginLeft: "14%" }}
                                  >
                                    {this.rDTF(
                                      "Reschedule Date&Time :",
                                      "appointmentDate",
                                      "col-md-3"
                                    )}

                                    {/* {"Note:If you want to change the FE reschedule date&Time use Date&Time picker"} */}
                                  </div>
                                </form>
                              </div>
                              <p>
                                <a style={{ color: "#AAA" }}>
                                  {
                                    "Note* : Use Date&Time picker to Change/Alter FE reschedule date&Time. "
                                  }
                                </a>
                              </p>
                            </div>
                          </div>

                          <div
                            className="col-md-8 m-0 p-0 ml-3 mb-3"
                            onChange={(e) =>
                              this.setState({ rescheduleBy: e.target.value })
                            }
                          >
                            <p className="d-inline-block m-0 p-0 mr-3">
                              Reschedule by:
                            </p>
                            <div class="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                name="rescheduleBy"
                                type="radio"
                                id="byCustomer"
                                checked={this.state.rescheduleBy === "Customer"}
                                value="Customer"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="byCustomer"
                              >
                                Customer
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                name="rescheduleBy"
                                type="radio"
                                id="byBizlog"
                                checked={this.state.rescheduleBy === "Bizlog"}
                                value="Bizlog"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="byBizlog"
                              >
                                Bizlog
                              </label>
                            </div>
                            <div class="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                name="rescheduleBy"
                                type="radio"
                                id="byClient"
                                checked={this.state.rescheduleBy === "Client"}
                                value="Client"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="byClient"
                              >
                                Client
                              </label>
                            </div>
                          </div>
                        </>
                      </div>
                    )}
                  {this.state.confirmData === false && (
                    <div className="row mt-4 ">
                      <>
                        <div id="coAction" className="col-md-12">
                          <div className="card ">
                            <div className="card-body  bg-light">
                              <div className="col-md-12 ">
                                <span
                                  className="fa-stack fa-lg pull-left"
                                  title="Primary Number"
                                >
                                  <i
                                    className="fa fa-phone fa-stack-2x cursorPointor"
                                    style={{ color: "blue" }}
                                    onClick={this.handleCall}
                                  ></i>
                                </span>
                                {(this.state.curBlockDetails.flowStatus ===
                                  "DROP_WEB" &&
                                  this.state.curProductDetails[0]
                                    .dstContact2 !== "") ||
                                  (this.state.curBlockDetails.flowStatus ===
                                    "PICK_WEB" &&
                                    this.state.curProductDetails[0]
                                      .srcContact2 !== "" && (
                                      <>
                                        <span
                                          className="fa-stack fa-lg pull-left"
                                          title="Alternate Number"
                                        >
                                          <i
                                            className="fa fa-phone fa-stack-2x cursorPointor primary"
                                            style={{ color: "green" }}
                                            onClick={this.handleAlternateCall}
                                          ></i>
                                        </span>
                                      </>
                                    ))}
                              </div>
                              <form onSubmit={this.handleSubmit}>
                                <div
                                  className="form-row pt-2"
                                  style={{ marginLeft: "14%" }}
                                >
                                  {this.rDTF(
                                    "Reschedule Date&Time :",
                                    "appointmentDate",
                                    "col-md-3"
                                  )}

                                  {/* {"Note:If you want to change the FE reschedule date&Time use Date&Time picker"} */}
                                </div>
                              </form>
                            </div>
                            <p>
                              <a style={{ color: "#AAA" }}>
                                {
                                  "Note* : Use Date&Time picker to Change/Alter FE reschedule date&Time. "
                                }
                              </a>
                            </p>
                          </div>
                        </div>

                        <div
                          className="col-md-8 m-0 p-0 ml-3 mb-3"
                          onChange={(e) =>
                            this.setState({ rescheduleBy: e.target.value })
                          }
                        >
                          <p className="d-inline-block m-0 p-0 mr-3">
                            Reschedule by:
                          </p>
                          <div class="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              name="rescheduleBy"
                              type="radio"
                              id="byCustomer"
                              checked={this.state.rescheduleBy === "Customer"}
                              value="Customer"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="byCustomer"
                            >
                              Customer
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              name="rescheduleBy"
                              type="radio"
                              id="byBizlog"
                              checked={this.state.rescheduleBy === "Bizlog"}
                              value="Bizlog"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="byBizlog"
                            >
                              Bizlog
                            </label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              name="rescheduleBy"
                              type="radio"
                              id="byClient"
                              checked={this.state.rescheduleBy === "Client"}
                              value="Client"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="byClient"
                            >
                              Client
                            </label>
                          </div>
                        </div>
                      </>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-md-4">
                      <button
                        className="btn btn-success btn-lg btn-block"
                        type="button"
                        onClick={() => this.handleReschedule()}
                        disabled={this.state.countDown <= 0 ? false : true}
                      >
                        {this.state.countDown
                          ? `RESCHEDULE IN ${this.state.countDown} sec`
                          : "RESCHEDULE INITIATE"}
                      </button>
                    </div>

                    <div className="col-md-4">
                      <button
                        className="btn btn-warning btn-lg btn-block"
                        type="button"
                        onClick={() => this.handleRejectReschedule()}
                      >
                        RESCHEDULE REJECT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* {this.state.pageHide === "true" && (<div className="jumbotron jumbotron-fluid">
                    <p className="lead text-center">Kindly Complete the Previous Process</p>
                </div>)
                } */}
        {this.state.closeStatus === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Ticket Number: <b>{ticketDetails.ticketNo}</b> has{" "}
              <b>{this.state.RescheduleStatus}</b> Successfully.
            </p>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RescheduleProcess);
