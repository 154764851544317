import * as act from "./userInfoTypes";

export const setUserInfo = (userInfo) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: act.SET_USERINFO,
        payload: userInfo,
      });
    } catch (error) {
      dispatch({
        type: act.SET_USERINFO_FAILURE,
        payload: { error: error.message },
      });
    }
  };
};
