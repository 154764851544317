export const homeMenu = [
  { path: "/v1/request/raise", label: "Request Raise", icon: "fa-plus" },
  { path: "/v1/request/approve", label: "Approve Requests", icon: "fa-table" },
  { path: "/v1/request/reports", label: "Request Reports", icon: "fa-plus" },
  { path: "/z/print", label: "Z Print", icon: "fa-print" },
];

export function getHomeMenu() {
  return homeMenu.filter((g) => g);
}

//   fakeGenreService.js
