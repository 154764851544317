import React from "react";
import FormLib from "./../../../_common/FormLib";

class Designations extends FormLib {
  state = {
    data: {
      designationId: "",
      departmentId: "",
      designation: "",
      status: "active",
    },
    errors: {},
    designations: [],
    curDepartment: {},
    curDesignation: {},
    // prvModules: [],
  };

  isCur(id, cur) {
    if (id === cur) {
      return " curTasks ";
    }
    return " ";
  }

  componentDidMount() {
    this.setCurDepartment(this.props.curDepartment);
    this.setCurDesignation(this.props.curDesignation);
  }
  setCurDepartment(d) {
    this.setState({ curDepartment: d });
  }
  setCurDesignation(m) {
    if (m.designationId !== "") {
      m.status = 1;
      this.setState({ data: m });
    } else {
      this.resetData();
    }
  }

  resetData() {
    this.setState({
      data: {
        designationId: "",
        departmentId: "",
        designation: "",
        status: "active",
      },
    });
  }
  render() {
    const designations = this.props.designations;
    const curDepartment = this.props.curDept;
    const curDesignation = this.props.curDesignation;

    return (
      <div className="card  ">
        <h5 className="card-header">
          Designations
          {/* <pre>{JSON.stringify(this.state.curMenu)}</pre> */}
          {curDepartment.departmentId !== "" && !curDesignation.designationId && (
            <button
              type="button"
              className="btn btn-sm btn-primary pull-right"
              data-toggle="modal"
              data-target="#myDesignationModel"
            >
              <i className={"fa  fa-plus"}/>
            </button>
          )}
          {curDesignation.designationId && curDesignation.designationId !== "" && (
            <>
              <button
                type="button"
                className="btn btn-sm btn-warning pull-right"
                onClick={() => this.props.onDesignationUnload()}
              >
                <i className={"fa  fa-unlink"}/>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-success pull-right"
                data-toggle="modal"
                data-target="#myDesignationModel"
              >
                <i className={"fa  fa-pencil"}/>
              </button>
            </>
          )}
        </h5>

        <div className="card-body">
          <div className="page-todo">
            <div className=" tasks">
              {designations.map((d) => (
                <div
                  className={
                    "task nil " +
                    this.isCur(d.designationId, curDesignation.designationId)
                  }
                  key={d.designationId}
                >
                  <div className="desc">
                    <span
                      className="span-click"
                      onClick={() => this.props.onDesignationChange(d)}
                    >
                      <div className="title">{d.designation}</div>
                    </span>
                  </div>

                  <div className="pull-right">
                    <div className="menu">
                    {d.status === 'inactive' && (
                      <span  
                        className={
                          "badge badge-danger pull-left " +
                          "badge-" +
                          d.status
                        }
                      >
                        {d.status}
                      </span>
                    )}
                      {d.status === 'active' && ( <span  
                        className={
                          "badge badge-success pull-left " +
                          "badge-" +
                          d.status
                        }
                      >
                        {d.status}
                      </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Designations;
