export function getBulkQCJSON(data, ticketScope) {
  try {
    const d = new Date().toISOString().slice(0, 10);
    let bulkProducts = [];
    data.map((r) => {
      let products = {
        primary: {
          productId: r.productId,
          productCode: r.productCode,
          brandId: r.brandId,
          modelId: r.modelId,
          brandName: r.brandName,
          modelName: r.modelName,
          brandCode: r.brandCode,
          modelCode: r.modelCode,
          productName: r.productName,
          dateOfPurchase: r.dateOfPurchase,
          identificationNo: r.identificationNo.toString().trim(),
          productDesc: r.productDesc,
          problemDesc: r.problemDesc,
          productValue: r.productValue?.toString() || "",
          cost: r.cost?.toString() || "",
          isUnderWarranty: r.isUnderWarranty,
          accessories: "",
          pickupType: "",
          productDimensions: {
            productUnit: r.productDimensionUnit,
            productLength: r.productLength,
            productBreadth: r.productBreadth,
            productHeight: r.productHeight
          },
          productWeight: {
            productUnit: r.productWeightUnit,
            productActualWeight: r.productActualWeight,
            productVolumeWeight: r.productVolumeWeight
          }
        },
        product_details: {
          process_id: "",
          ticketQc: "",
          productBoxes: [
            {
              boxName: r.boxName,
              boxSlNo: r.boxSlNo
            }
          ]
        },
        src_add: {
          srcContactPerson: r.srcContactPerson,
          srcOrg: "org",
          srcAdd1: r.srcAdd1,
          srcAdd2: r.srcAdd2,
          srcLocation: r.srcLocation,
          srcContact1: r.srcContact1?.toString() || "",
          srcContact2: r.srcContact2?.toString() || "",
          srcCity: r.srcCity,
          srcState: r.srcState,
          srcPincode: r.srcPincode?.toString() || "",
          srcLandmark: r.srcLandmark,
          srcEmailId: r.srcEmailId,
          srcEmailIdAlt: r.srcEmailIdAlt,
          srcType: r.srcType,
          srcLatitude: r?.srcLatitude,
          srcLongitude: r?.srcLongitude,
        },
        dst_add: {
          dstContactPerson: "",
          dstOrg: "",
          dstAdd1: "",
          dstAdd2: "",
          dstLocation: "",
          dstContact1: "",
          dstContact2: "",
          dstCity: "",
          dstState: "",
          dstPincode: "",
          dstLandmark: "",
          dstEmailId: "",
          dstEmailIdAlt: "",
          dstType: "",
          dstLatitude: "",
          dstLongitude: ""
        },
      };
      bulkProducts.push(products);
    });
    const params = {
      primary: {
        ticketPriority: data[0].ticketPriority,
        flowId: data[0].flowId,
        retailerId: data[0].retailerId,
        retailerNo: data[0].retailerNo?.toString() || "",
        conComplaintNo: data[0].conComplaintNo?.toString() || "",
        LRNumber: data[0].LRNumber?.toString() || "",
        orderNo: data[0].orderNo?.toString() || "",
        invoiceNo: data[0].invoiceNo?.toString() || "",
        parentTicket: "",
        ticketDetails: data[0].ticketDetails,
        specialInstructions: data[0].specialInstructions,
        buyerContactNumber: "",
        dateOfTicket: d?.toString() || "",
        isAppointmentReq: data[0].isAppointmentReq,
        isInstallationReq: data[0].isInstallationReq,
        isPhysicalEval: data[0].isPhysicalEval,
        isTechEval: data[0].isTechEval,
        isPackingReq: data[0].isPackingReq,
        paymentMode: "cash",
        ticketScope: ticketScope,
        deliveryType: data[0].deliveryType,
        carrier: data[0].carrier

      },
      primary_details: {
        cus_field1: "",
        cus_field2: "",
      },
      products: bulkProducts,
    };
    return params;
  } catch {
    console.log("Error in ticket creation>>>>>>>>>>getBulkQCJSON");
  }
}

export function getBulkJSON(data, ticketScope) {
  try {
    const d = new Date().toISOString().slice(0, 10);
    let bulkProducts = [];
    data.map((r) => {
      if(r.productQuantity){
        let products = {
          primary: {
          productQuantity:r.productQuantity.toString(),
            productId: r.productId,
            productCode: r.productCode,
            brandId: r.brandId,
            modelId: r.modelId,
            brandName: r.brandName,
            modelName: r.modelName,
            brandCode: r.brandCode,
            modelCode: r.modelCode,
            productName: r.productName,
            dateOfPurchase: r.dateOfPurchase,
            identificationNo: r.identificationNo.toString().trim(),
            productDesc: r.productDesc,
            problemDesc: r.problemDesc,
            productValue: r.productValue?.toString() || "",
            cost: r.cost?.toString() || "",
            isUnderWarranty: r.isUnderWarranty,
            accessories: "",
            pickupType: "",
            productDimensions: {
              productUnit: r.productDimensionUnit,
              productLength: r.productLength,
              productBreadth: r.productBreadth,
              productHeight: r.productHeight
            },
            productWeight: {
              productUnit: r.productWeightUnit,
              productActualWeight: r.productActualWeight,
              productVolumeWeight: r.productVolumeWeight
            }
          },
          product_details: {
            process_id: "",
            ticketQc: "",
            productBoxes: [
              {
                boxName: r.boxName,
                boxSlNo: r.boxSlNo
              }
            ]
          },
          src_add: {
            srcContactPerson: r.srcContactPerson,
            srcOrg: "org",
            srcAdd1: r.srcAdd1,
            srcAdd2: r.srcAdd2,
            srcLocation: r.srcLocation,
            srcContact1: r.srcContact1?.toString() || "",
            srcContact2: r.srcContact2?.toString() || "",
            srcCity: r.srcCity,
            srcState: r.srcState,
            srcPincode: r.srcPincode?.toString() || "",
            srcLandmark: r.srcLandmark,
            srcEmailId: r.srcEmailId,
            srcEmailIdAlt: r.srcEmailIdAlt,
            srcType: r.srcType,
            srcLatitude: r?.srcLatitude,
            srcLongitude: r?.srcLongitude,
          },
          dst_add: {
            dstContactPerson: r.dstContactPerson,
            dstOrg: "org",
            dstAdd1: r.dstAdd1,
            dstAdd2: r.dstAdd2,
            dstLocation: r.dstLocation,
            dstContact1: r.dstContact1?.toString() || "",
            dstContact2: r.dstContact2?.toString() || "",
            dstCity: r.dstCity,
            dstState: r.dstState,
            dstPincode: r.dstPincode?.toString() || "",
            dstLandmark: r.dstLandmark,
            dstEmailId: r.dstEmailId,
            dstEmailIdAlt: r.dstEmailIdAlt,
            dstType: r.dstType,
            dstLatitude: r?.dstLatitude,
            dstLongitude: r?.dstLongitude
          },
        };
        bulkProducts.push(products);
      }else{
        let products = {
          primary: {
            productId: r.productId,
            productCode: r.productCode,
            brandId: r.brandId,
            modelId: r.modelId,
            brandName: r.brandName,
            modelName: r.modelName,
            brandCode: r.brandCode,
            modelCode: r.modelCode,
            productName: r.productName,
            dateOfPurchase: r.dateOfPurchase,
            identificationNo: r.identificationNo.toString().trim(),
            productDesc: r.productDesc,
            problemDesc: r.problemDesc,
            productValue: r.productValue?.toString() || "",
            cost: r.cost?.toString() || "",
            isUnderWarranty: r.isUnderWarranty,
            accessories: "",
            pickupType: "",
            productDimensions: {
              productUnit: r.productDimensionUnit,
              productLength: r.productLength,
              productBreadth: r.productBreadth,
              productHeight: r.productHeight
            },
            productWeight: {
              productUnit: r.productWeightUnit,
              productActualWeight: r.productActualWeight,
              productVolumeWeight: r.productVolumeWeight
            }
          },
          product_details: {
            process_id: "",
            ticketQc: "",
            productBoxes: [
              {
                boxName: r.boxName,
                boxSlNo: r.boxSlNo
              }
            ]
          },
          src_add: {
            srcContactPerson: r.srcContactPerson,
            srcOrg: "org",
            srcAdd1: r.srcAdd1,
            srcAdd2: r.srcAdd2,
            srcLocation: r.srcLocation,
            srcContact1: r.srcContact1?.toString() || "",
            srcContact2: r.srcContact2?.toString() || "",
            srcCity: r.srcCity,
            srcState: r.srcState,
            srcPincode: r.srcPincode?.toString() || "",
            srcLandmark: r.srcLandmark,
            srcEmailId: r.srcEmailId,
            srcEmailIdAlt: r.srcEmailIdAlt,
            srcType: r.srcType,
            srcLatitude: r?.srcLatitude,
            srcLongitude: r?.srcLongitude,
          },
          dst_add: {
            dstContactPerson: r.dstContactPerson,
            dstOrg: "org",
            dstAdd1: r.dstAdd1,
            dstAdd2: r.dstAdd2,
            dstLocation: r.dstLocation,
            dstContact1: r.dstContact1?.toString() || "",
            dstContact2: r.dstContact2?.toString() || "",
            dstCity: r.dstCity,
            dstState: r.dstState,
            dstPincode: r.dstPincode?.toString() || "",
            dstLandmark: r.dstLandmark,
            dstEmailId: r.dstEmailId,
            dstEmailIdAlt: r.dstEmailIdAlt,
            dstType: r.dstType,
            dstLatitude: r?.dstLatitude,
            dstLongitude: r?.dstLongitude
          },
        };
        bulkProducts.push(products);
      }
    });
    const params = {
      primary: {
        ticketPriority: data[0].ticketPriority,
        flowId: data[0].flowId,
        retailerId: data[0].retailerId,
        retailerNo: data[0].retailerNo?.toString() || "",
        conComplaintNo: data[0].conComplaintNo?.toString() || "",
        LRNumber: data[0].LRNumber?.toString() || "",

        orderNo: data[0].orderNo?.toString() || "",
        invoiceNo: data[0].invoiceNo?.toString() || "",
        parentTicket: "",
        ticketDetails: data[0].ticketDetails,
        specialInstructions: data[0].specialInstructions,
        buyerContactNumber: "",
        dateOfTicket: d?.toString() || "",
        isAppointmentReq: data[0].isAppointmentReq,
        isInstallationReq: data[0].isInstallationReq,
        isPhysicalEval: data[0].isPhysicalEval,
        isTechEval: data[0].isTechEval,
        isPackingReq: data[0].isPackingReq,
        paymentMode: "cash",
        ticketScope: ticketScope,
        deliveryType: data[0].deliveryType,
        carrier: data[0].carrier


      },
      primary_details: {
        cus_field1: "",
        cus_field2: "",
      },
      products: bulkProducts,
    };
    return params;
  } catch {
    console.log("Error in ticket creation >>>>>getBulkJSON");
  }
}

export function getEwasteJSON(data, ticketScope) {
  try {
    const d = new Date().toISOString().slice(0, 10);
    let bulkProducts = [];
    data.map((r) => {
      let products = {
        primary: {
          productId: r.productId || "",
          productCode: r.productCode,
          brandId: r.brandId || "",
          modelId: r.modelId || "",
          brandName: r.brandName || "",
          modelName: r.modelName,
          brandCode: r.brandCode,
          modelCode: r.modelCode,
          productName: r.productName,
          dateOfPurchase: r.dateOfPurchase,
          identificationNo: r.identificationNo?.toString().trim() || "",
          productDesc: r.productDesc,
          problemDesc: r.problemDesc,
          productValue: r.productValue?.toString() || "",
          cost: r.cost?.toString() || "",
          isUnderWarranty: r.isUnderWarranty,
          accessories: "",
          pickupType: "",
          productDimensions: {
            productUnit: r.productDimensionUnit,
            productLength: r.productLength,
            productBreadth: r.productBreadth,
            productHeight: r.productHeight
          },
          productWeight: {
            productUnit: r.productWeightUnit,
            productActualWeight: r.productActualWeight,
            productVolumeWeight: r.productVolumeWeight
          }
        },
        product_details: {
          process_id: "",
          ticketQc: "",
          productBoxes: [
            {
              boxName: r.boxName,
              boxSlNo: r.boxSlNo
            }
          ]
        },
        src_add: {
          srcContactPerson: r.srcContactPerson,
          srcOrg: "org",
          srcAdd1: r.srcAdd1,
          srcAdd2: r.srcAdd2,
          srcLocation: r.srcLocation,
          srcContact1: r.srcContact1?.toString() || "",
          srcContact2: r.srcContact2?.toString() || "",
          srcCity: r.srcCity,
          srcState: r.srcState,
          srcPincode: r.srcPincode?.toString() || "",
          srcLandmark: r.srcLandmark,
          srcEmailId: r.srcEmailId,
          srcEmailIdAlt: r.srcEmailIdAlt,
          srcType: r.srcType,
          srcLatitude: r?.srcLatitude,
          srcLongitude: r?.srcLongitude,
        },
        dst_add: {
          dstContactPerson: r.dstContactPerson,
          dstOrg: "org",
          dstAdd1: r.dstAdd1,
          dstAdd2: r.dstAdd2,
          dstLocation: r.dstLocation,
          dstContact1: r.dstContact1?.toString() || "",
          dstContact2: r.dstContact2?.toString() || "",
          dstCity: r.dstCity,
          dstState: r.dstState,
          dstPincode: r.dstPincode?.toString() || "",
          dstLandmark: r.dstLandmark,
          dstEmailId: r.dstEmailId,
          dstEmailIdAlt: r.dstEmailIdAlt,
          dstType: r.dstType,
          dstLatitude: r?.dstLatitude,
          dstLongitude: r?.dstLongitude
        },
      };
      bulkProducts.push(products);
    });
    const params = {
      primary: {
        ticketPriority: data[0].ticketPriority,
        flowId: data[0].flowId,
        retailerId: data[0].retailerId,
        retailerNo: data[0].retailerNo?.toString() || "",
        conComplaintNo: data[0].conComplaintNo?.toString() || "",
        LRNumber: data[0].LRNumber?.toString() || "",

        orderNo: data[0].orderNo?.toString() || "",
        invoiceNo: data[0].invoiceNo?.toString() || "",
        parentTicket: "",
        ticketDetails: data[0].ticketDetails,
        specialInstructions: data[0].specialInstructions,
        buyerContactNumber: "",
        dateOfTicket: d?.toString() || "",
        isAppointmentReq: data[0].isAppointmentReq,
        isInstallationReq: data[0].isInstallationReq,
        isPhysicalEval: data[0].isPhysicalEval,
        isTechEval: data[0].isTechEval,
        isPackingReq: data[0].isPackingReq,
        isDocumentReq: data[0].isDocumentReq,
        isGradingReq: data[0].isGradingReq,
        isSegregationReq: data[0].isSegregationReq,
        isSortingReq: data[0].isSortingReq,
        isAuditReq: data[0].isAuditReq,
        isWeighmentReq: data[0].isWeighmentReq,
        paymentMode: data[0].paymentMode,
        inFlowBy: "EXCEL",
        ticketScope: ticketScope,
        deliveryType: data[0].deliveryType,
        carrier: data[0].carrier





      },
      primary_details: {
        cus_field1: "",
        cus_field2: "",
      },
      products: bulkProducts,
    };
    // console.log(JSON.stringify(params));
    return params;
  } catch (e) {
    console.log("Error in ticket creation >>>>>getEwasteJSON");
    console.log(e);
  }
}
