import React from "react";
import FormLib from "../../_common/FormLib";
import swal from "sweetalert";
import Joi from "joi-browser";

import Table from "../../_common/Table";
import Pagination from "../../_common/Pagination";
import $ from "jquery";
import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";
import { getRegions } from "../../../services/masterLocations/regionsServices";
import {
  getHubs,
  createHub,
  saveHub,
  deleteHub,
  getSearchHubs,
} from "../../../services/masterLocations/hubServices";
import { getCities } from "../../../services/masterLocations/cityServices";
class Hub extends FormLib {
  state = {
    data: {
      hubId: "",
      regionId: "",
      hub: "",
      hubCode: "",
      add1: "",
      add2: "",
      // add3: "",
      city: "",
      contactPerson: "",
      emailId: "",
      contact1: "",
      location: "",
      landmark: "",
      state: "",
      status: "active",
      pincode: "",
      hubSearch: ""
    },
    errors: {},
    formMode: "create",
    hubs: [],
    regions: [],
    cities: [],
    loading: true,
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };
  componentDidMount() {
    this.loadHubs(this.state.pageLinks.pageNo);
    this.loadCities();
    this.loadRegions();
  }
  schema = {
    hubId: Joi.optional().label("HubId"),
    regionId: Joi.string().required().label("Region Id"),
    hub: Joi.string().regex(/^[a-zA-Z ]*$/).optional().label("Hub").error(() => {
      return {
        message: 'Allow only characters.',
      }
    }),
    hubCode: Joi.string().required().label("Hub Code"),
    add1: Joi.string().required().label("Add1 *"),
    add2: Joi.string().optional().label("Add2"),
    //  add3: Joi.string().optional().label("Add3"),
    city: Joi.string().regex(/^[a-zA-Z ]*$/).optional().label("City").error(() => {
      return {
        message: 'Allow only characters.',
      }
    }),
    contactPerson: Joi.string().required().label("Contact Person"),
    emailId: Joi.string().required().label("Email Id").email({ minDomainAtoms: 2 }),
    contact1: Joi.string().min(10).max(11).regex(/^[0-9]+$/).required().label("Contact Number").error(() => {
      return {
        message: 'Allow only 10/11 digit numbers.',
      }
    }),
    location: Joi.string().required().label("Location"),
    landmark: Joi.string().required().label("Landmark"),
    state: Joi.string().required().label("State"),
    pincode: Joi.number().integer().optional().label("Pincode"),
    status: Joi.string().required().label("Status"),
    hubSearch: Joi.optional().label("hubSearch"),
  };
  tableMeta = {
    key: "hubId",
    columns: [
      {
        key: "HUB_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnHub(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleView(r)}
              >
                <i className="fa fa-binoculars" aria-hidden="true" /> View
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(r)}
              >
                <i className="fa fa-trash" aria-hidden="true" /> Delete
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true" />,
      },
      { path: "hubCode", label: "Code" },
      { path: "hub", label: "Hub" },
      { path: "city", label: "City" },
      { path: "status", label: "Status" },
    ],
  };

  searchHub = async () => {
    let param = {
      "hub": this.state.data.hubSearch,
    }
    this.setState({ loading: true });
    const res = await getSearchHubs(param, this.state.pageLinks.pageNo);
    if (res.data.success && res.data.data.hubs.length != 0) {
      this.setState({ hubs: res.data.data.hubs });
      this.setState({ pageLinks: res.data.data.pageLinks });
    } else {
      toast.toastInfo("No data found!!!");
      this.setState({ hubs: [] });
      this.setState({
        pageLinks: {
          "pageNo": 1,
          "perPage": 0,
          "itemsCount": 0,
          "lastPage": 0
        }
      });
    }
    this.setState({ loading: false });


  }
  handleOnHub(r) {
    let hubs = {};
    hubs.regionId = r.regionId;
    hubs.hubId = r.hubId;
    hubs.hub = r.hub;
    hubs.hubCode = r.hubCode;
    hubs.add1 = r.add1;
    hubs.add2 = r.add2;
    // hubs.add3 = r.add3;
    hubs.city = r.city;
    hubs.contactPerson = r.contactPerson;
    hubs.emailId = r.emailId;
    hubs.contact1 = r.contact1;
    hubs.location = r.location;
    hubs.landmark = r.landmark;
    hubs.state = r.state;
    hubs.pincode = r.pincode;
    hubs.status = r.status;
    this.setState({ data: hubs, formMode: "edit" });
    $("#hubCode").attr("disabled", "disabled");
  }
  handlePageChange = (pageNo) => {
    this.props.history.push("/masterLocation/hub/" + pageNo);
    this.loadHubs(pageNo);
  };
  async loadRegions() {
    this.setState({ loading: true });
    const res = await getRegions();
    if (res.data.success === true) {
      this.setState({ regions: res.data.data.regions });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }
  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createHub(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveHub(this.state.data);
      this.resetData();
    }

    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadHubs(this.state.pageLinks.pageNo);
    } else {
      toast.toastError(res.data.msg);
    }
  }

  doSubmitDelete = async (handleDelete, r) => {
    if (handleDelete) {
      await deleteHub(r);
      this.loadHubs(this.state.pageLinks.pageNo);

      swal("Hub has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Hub is safe!");
    }
  };

  handleDelete = async (r) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, r);
    });
  };

  handleView = (r) => {
    this.setState({ curHub: r, formMode: "view" });
  };


  async loadHubs(pageNo) {
    this.resetData();
    this.setState({ loading: true });
    const res = await getHubs(pageNo);
    if (res.data.success === true && res.data.data.hubs) {
      this.setState({ hubs: res.data.data.hubs });
      this.setState({ pageLinks: res.data.data.pageLinks });
    } else {
      toast.toastError(res.data.msg);
      this.setState({ hubs: [] });
      this.setState({
        pageLinks: {
          "pageNo": 1,
          "perPage": 0,
          "itemsCount": 0,
          "lastPage": 0
        }
      });
    }
    this.setState({ loading: false });
  }
  async loadCities() {
    this.setState({ loading: true });
    const res = await getCities();
    if (res !== undefined) {
      if (res.data.success === true) {
        this.setState({ cities: res.data.data.cities });
      } else {
        toast.toastError(res.data.msg);
      }
    }
    this.setState({ loading: false });
  }
  resetData() {
    this.setState({
      data: {
        hubId: "",
        hub: "",
        hubCode: "",
        add1: "",
        add2: "",
        // add3: "",
        city: "",
        pincode: "",
        contactPerson: "",
        regionId: "",
        emailId: "",
        contact1: "",
        location: "",
        landmark: "",
        state: "",
        status: "active",
        hubSearch: ""
      },
      formMode: "create",
    });
  }

  typConf = {
    value: "sortKey",
    label: "sort",
  };
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Hubs" />
        <div className="pageContent">
          <div className="row">
            {!this.state.loading && (
              <>
                <div className="col-3 ml-3">
                  {this.rTB("Search hub...", "hubSearch")}
                </div>
                <div className="menu col-2">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-info pull-left"
                    title=" Search by hub name "
                    disabled={this.state.data.hubSearch === ""}
                    onClick={() => this.searchHub()}
                  >
                    <i className={"fa fa-search"} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-info pull-left"
                    onClick={() =>
                      this.loadHubs(
                        this.state.pageLinks.pageNo
                      )
                    } >
                    <i className={"fa fa-refresh"} />
                  </button>
                </div>
              </>
            )}
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Hubs</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.hubs}
                    dropMenu={[]}
                  />
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              {this.state.formMode === "view" && (
                <div className="card">
                  <div className="card-body">
                    <>
                      {this.state.curHub["hub"].trim()}
                      <br />
                      <div className="pull-right">
                        <span className={"badge   badge-light pull-left ml-1 "}>
                          {/* {this.state.curHub["hubId"]} */}
                        </span>
                        <span
                          className={"badge   badge-success pull-left  mr-1 "}
                        >
                          {this.state.curHub["status"]}
                        </span>
                      </div>
                    </>
                  </div>
                </div>
              )}
              {this.state.formMode !== "view" && (
                <div className="card pt-2">
                  <div className="card-body">
                    <form>
                      <div className="form-row">
                        {this.rTBH("HubId", "hubId", "col-2")}
                        {this.rSB(
                          "Regions *",
                          "regionId",
                          this.state.regions,
                          { value: "regionId", label: "region" },
                          " col-md-6"
                        )}
                        {/* {this.rTB("Region Id", "regionId", " col-md-6")} */}
                        {this.rTB("HubCode", "hubCode", "col-6")}
                      </div>
                      <div className="form-row">
                        {this.rTB("Hub", "hub", "col-6")}
                        {this.rTB("Contact Person", "contactPerson", " col-md-6")}
                      </div>
                      <div className="form-row">
                        {this.rTB("Location", "location", " col-md-6")}
                        {this.rTB("Address1", "add1", " col-md-6")}
                        {/* {this.rTB("Address3", "add3", " col-md-12")} */}
                      </div>
                      <div className="form-row">
                        {this.rTB("Address2", "add2", " col-md-6")}
                        {this.rTB("Landmark", "landmark", " col-md-6")}
                      </div>
                      <div className="form-row">
                        {this.rTB("Email Id", "emailId", " col-md-6")}
                        {this.rTB("Contact Number", "contact1", " col-md-6 ")}
                      </div>
                      <div className="form-row">
                        {this.rTB("City", "city", " col-md-4")}
                        {this.rTB("State", "state", " col-md-4")}
                        {this.rTB("Pincode", "pincode", " col-md-4")}
                      </div>
                      <div className="form-row mt-2">
                        {this.rRBL(
                          "Status",
                          "status",
                          this.status,
                          this.stsConf
                        )}
                      </div>
                    </form>
                    {this.state.formMode === "create" &&
                      this.rBtnSP("Create", "create")}
                    {this.state.formMode === "edit" && (
                      <>
                        {this.rBtnSS("Edit", "edit")}
                      </>
                    )}
                    {this.mResetBtn(() => this.resetData(), " pull-right ", "Reset")}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Hub;
