import React from "react";
import FormLib from "./../../_common/FormLib";

import _auth from "../../../services/_authService";

class AngLogin extends FormLib {
  state = {
    msg: "Logging in, Please wait",
  };

  componentDidMount() {
    this.doSubmit(this.props.match.params.jwt);
  }
  doSubmit = async (jwt) => {
    try {
      await _auth.loginWithJwt(jwt);
      const user = _auth.getCurrentUser();

      let url = "/testAPI/react/about-us";

      if (user !== null) {
        localStorage.setItem("userInfo", JSON.stringify(user));
        const { state } = this.props.location;

        let url = "/testAPI/react/ticket/create/lifelong";

        if (user["id"].trim() !== "lifelongretailer@yopmail.com") {
          url = "/testAPI/react/v1/request/approve";
        }
        window.location = state ? state.from.pathname : url;
      } else {
        this.setState({ msg: "Invalid Login, Please try again" });
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="pageContent">
          <div className="container-fluid">
            Angular Login <br />
            {this.state.msg}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AngLogin;
