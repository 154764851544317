import React from "react";
import FormLib from "../../_common/FormLib";
import * as XLSX from "xlsx";
import Joi from "joi-browser";
import { ExportXLSXReqTrans } from "./ExportXLSX";
import {
  getBrandsInfo,
  getMasterData,
  getRetailersInfo,
  uploadExcelTicketData,
} from "../../../services/Tickets/TicketServices";
import { uploadExcelVenAccount } from "../../../services/requestV1/AccountServices";
import {
  getProductsInfo,
  getModelsInfo,
} from "../../../services/Tickets/TicketServices";
import { toast } from "react-toastify";

// require('./boo.css');

export default class AccountsVenUpload extends FormLib {
  state = {
    data: { fileUpload: "" },
    errors: {},
    excelFile: null,
    excelData: [],
    excelFileError: null,
    failedData: { data: [] },
    csvReport: {
      data: [],
      headers: [],
    },
  };
  schema = {
    fileUpload: Joi.string().required().label("fileUpload"),
  };

  excelHeaders = [
    { key: "VEN_CODE", label: "VEN_CODE" },
    { key: "ACCOUNT_TYPE", label: "ACCOUNT_TYPE" },
    { key: "ACCOUNT_NO", label: "ACCOUNT_NO" },
    { key: "NAME", label: "NAME" },
    { key: "IFSC", label: "IFSC" },
    { key: "BANK", label: "BANK" },
    { key: "BRANCH", label: "BRANCH" },
  ];

  localHandleChange = (e) => {
    this.handleValidation(e);

    this.setState({
      excelFile: null,
      excelFileError: null,
      excelData: [],
      processState: [],
      processStateCls: [],
      ticketState: [],
      ticketStateCls: [],
    });

    if (e.target.name === "fileUpload") {
      console.log("change in file upload");
      this.handleFile(e);
    }
  };

  fileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  handleFile = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && this.fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          this.setState({ excelFileError: null });
          this.setState({ excelFile: e.target.result });
          // console.log(e.target.result);
        };
      } else {
        // setExcelFileError('Please select only excel file types');
        // setExcelFile(null);
        this.setState({
          excelFileError: "Please select only excel file types",
        });
        this.setState({ excelFile: null });
      }
    } else {
      console.log("plz select your file");
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (this.state.excelFile !== null) {
      const workbook = XLSX.read(this.state.excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      //   setExcelData(data);
      // this.setState({ excelData: data });
      this.setState({
        excelData: [],
      });
      let tempExcelData = [];

      // console.log("1---------> " + JSON.stringify(data));

      for (let r in data) {
        let vendorCode = data[r]["VEN_CODE"];
        let accountType = data[r]["ACCOUNT_TYPE"];
        let accountNo = data[r]["ACCOUNT_NO"];
        let name = data[r]["NAME"];
        let ifsc = data[r]["IFSC"];
        let bank = data[r]["BANK"];
        let branch = data[r]["BRANCH"];

        tempExcelData.push(data[r]);
      }
      this.setState({
        excelData: tempExcelData,
      });
    } else {
      //   setExcelData(null);
      this.setState({ excelData: [] });
      console.log("no data");
    }
  };

  async handleAPIHit() {
    let processState;
    let processStateCls;
    let ticketState;
    let ticketStateCls;
    let res;
    this.setState({ processState: [] });
    this.setState({ ticketState: [] });

    let i = 0;
    for (let r in this.state.excelData) {
      processState = this.state.processState;
      processStateCls = this.state.processStateCls;
      ticketState = this.state.ticketState;
      ticketStateCls = this.state.ticketStateCls;

      processStateCls[i] = "info";
      processState[i] = "Processing";
      this.setState({ processState });

      ticketStateCls[i] = "info";
      ticketState[i] = "Processing";
      this.setState({ ticketState });

      // console.log("AKSHAY-----------> " + JSON.stringify(this.state.excelData[r]));
      if (
        this.state.excelData[r]["VEN_CODE"] &&
        this.state.excelData[r]["VEN_CODE"] !== ""
      ) {
        res = await uploadExcelVenAccount(this.state.excelData[r]);
        processState[i] = res.data.msg;
        processStateCls[i] = "info";
        ticketState[i] = res.data.data.ticketNo;
        ticketStateCls[i] = "success";
      } else {
        // console.log("Failed1 " + JSON.stringify(this.state.excelData[r]));
        processState[i] = "No Vendor Code";
        processStateCls[i] = "danger";

        let failed = this.state.failedData;

        failed["data"].push(this.state.excelData[r]);

        this.setState({
          failed,
        });

        // console.log("Failed2 " + JSON.stringify(this.state.failedData));

        this.failedExcelData();
      }
      i++;
      this.setState({ processState });
      this.setState({ ticketState });
    }
  }
  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  async failedExcelData() {
    ///Excel
    let apiData = {};
    const { csvReport } = { ...this.state };
    apiData = this.state.failedData;

    csvReport.filename = "accountCreate.xlsx";
    csvReport.headers = this.excelHeaders;

    let transData = [];
    transData = apiData;
    this.setState({ csvReport });

    if (apiData["data"]) {
      const promises = apiData["data"];

      transData = await Promise.all(promises);
      // transData = promises;
      csvReport.data = transData;
      this.setState({ csvReport });
    } else {
      csvReport.data = [];
      this.setState({ csvReport });
    }
    ///Excel
  }

  render() {
    return (
      <>
        <div className="card">
          <div className="card-body">
            <form id="myform" onSubmit={this.handleSubmit}>
              <div className="form-row">
                {this.rFU(
                  "Upload File",
                  "fileUpload",
                  "col-4 btn btn-primary btn-sm"
                )}
                {/* <input className="form-control form-control-sm" id="formFileSm" type="file" /> */}
                <div className="col-md-4">
                  {this.rBtnS("Upload", "upload", "col-md-4")}
                </div>
              </div>
            </form>
          </div>

          <div className="viewer">
            {this.state.excelData.length > 0 && (
              <>
                <button
                  className="btn  btn-info  btn-sm"
                  onClick={() => this.handleAPIHit()}
                >
                  Submit
                </button>

                <div className="table-responsive">
                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>VEN_CODE</th>
                        <th>ACCOUNT_TYPE</th>
                        <th>ACCOUNT_NO</th>
                        <th>NAME</th>
                        <th>IFSC</th>
                        <th>BANK</th>
                        <th>BRANCH</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.excelData.map((individualExcelData, i) => (
                        <tr key={individualExcelData.conComplaintNo}>
                          <td>
                            <span
                              className={
                                "badge badge-" + this.state.processStateCls[i]
                              }
                            >
                              {this.state.processState[i]}
                            </span>
                          </td>

                          <td>{individualExcelData.VEN_CODE}</td>
                          <td>{individualExcelData.ACCOUNT_TYPE}</td>
                          <td>{individualExcelData.ACCOUNT_NO}</td>
                          <td>{individualExcelData.NAME}</td>
                          <td>{individualExcelData.IFSC}</td>
                          <td>{individualExcelData.BANK}</td>
                          <td>{individualExcelData.BRANCH}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <ExportXLSXReqTrans
                  csvData={this.state.csvReport.data}
                  fileName={"Failed Data"}
                />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
