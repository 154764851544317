import React from 'react'
import TaskCreate from './TaskCreate';


class TaskModal extends React.Component {
   
    render() {
        return (
            <div className="task-modal">
                <button type="button" className="btn btn-sm btn-primary" data-toggle="modal" data-target="#myModal1">
                    T
                </button>

              

                <div className="modal fade" id="myModal1">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Task</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-body p-0">
                            <TaskCreate/>
                           </div>

                            <div className="modal-footer pull-left">
                                <button type="button" className="btn btn-success" data-dismiss="modal">Save</button>
                              {/* <span><Alert/></span> */}
                            </div>
                            {/* <ReportCreate/> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TaskModal;

