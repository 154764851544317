import _http from "../../_httpService";
import config from "../../../config.json";
import axios from "axios";



const apiURLPHP = config[config.build].apiURLPHP;
const baseURL = "https://rl.bizlog.in:8443/rlogistics-execution/rlservice";

function api(path, subURL = "") {
    path = path !== "" ? "/" + path : "";
    return `${baseURL}${subURL}${path}`;
  }


  export function getMisOldTickets(payload, pageNo = 1, perpage = 50) {

    return _http.put(
      apiURLPHP +
        "/requestV1/ticket-reports-mis.php?pageNo=" +
        pageNo +
        "&perPage=" +
        perpage,
      payload,
      _http.headerJWT()
    );
  }


  