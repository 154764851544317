import React, { forwardRef } from "react";
import Barcode from "react-barcode";
import dateFormat from "dateformat";
import html2canvas from "html2canvas";
import FormLib from "../../_common/FormLib";
import { epoch2ddmmyyyyhhmmss } from "../../../utils/DateUtils";
import logo from '../../../assets/img/bizlog.svg';
import Table from "../../_common/Table";
import "./EwayBillPrintSVGStyle.css";
import ReactToPrint from 'react-to-print';
import { getTicketInfo } from "../../../services/TicketHistory/TicketHistory";
import { toast } from "react-toastify";
import { epoch2ddmmyyyy,epoch2hhmmss } from "../../../utils/DateUtils";




export default class EwayBillPrintSVG extends FormLib {
  state = {
    data: {},
    errors: {},
    curTicket: "",
    curProductDetails: [],
    curTicketDetails: [],
    TicketProductDetails: [],
    TicketProductDetails1: [],
    TicketPrimarytDetails: [],
  };


  async getTicketInfo() {
    try {
      this.setState({ loading: true });
      // console.log("Ticket Number",this.props.curTicket)

      const ticketNo = this.props.curTicket;
      this.setState({ curTicket: this.props.curTicket });
      this.state.TicketProductDetails = [];
      this.state.TicketProductDetails1 = [];

      this.state.TicketPrimarytDetails = [];
      const respDst = await getTicketInfo(ticketNo);
      if (respDst !== undefined) {
        if (respDst) {
          if (respDst.data.success === true && respDst.data.data) {
            this.setState({
              TicketPrimarytDetails:
                respDst.data.data.TicketDetails.primaryDetails,
            });
            this.setState({
              TicketProductDetails:
                respDst.data.data.TicketDetails.productDetails[0],
            });
            this.setState({
              TicketProductDetails1:
                respDst.data.data.TicketDetails.productDetails[1],
            });
          } else if (respDst.data.success === false) {
            toast.error("No Details Available for this Ticket");
          }
        }
      } else {
        this.setState({ wentWrong: undefined });
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in TicketDetails>>>>>> getTicketInfo");
    }
  }

  onclick = (event) => {
    try {
      // alert('call')
      // window.print()
      const content = document.getElementById("barcode-content");
      const pri = document.getElementById("ifmcontentstoprint").contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
      pri.close();
    } catch {
      console.log("Error in BarcodePrintSVG>>>>>>>>onClick");
    }
  };

  onClickPrintLabel = (event) => {
    try {
      const content = document.getElementById("labelContentToPrint");
      const pri = document.getElementById("ifmcontentstoprint").contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
      pri.close();

      // generate CSS links
      var bootstrap = document.createElement('link');
      bootstrap.href = './EwayBillPrintSVGStyle.css';
      bootstrap.rel = 'stylesheet';
      pri.document.head.appendChild(bootstrap);

      var app = document.createElement('link');
      app.href = '/static/css/bq_print.css';
      app.rel = 'stylesheet';
      pri.document.head.appendChild(app);
    } catch {
      console.log("Error in BarcodePrintSVG>>>>>>>>onClickPrintLabel");
    }
  };

  componentDidMount() {
    this.loadInit();
    this.getTicketInfo();
  }

  loadInit() {
    try {
      //   const barcodeDetails = this.props.barcodeDetails;
      // const printBarcode = this.props.printBarcode;
      // const curProductDetails = this.props.productDetails;
      // const curTicketDetails = this.props.ticketDetails;
      // const curBlockDetails = this.props.blockDetails
      // this.setState({ curProductDetails });
      // this.setState({ curTicketDetails });
      // this.setState({ curBlockDetails });
      // this.setState({ printBarcode });
      // const now = new Date();
      // const localTimeStamp = now.toLocaleString();
      // const fDate = dateFormat(now.toLocaleString(), "isoDateTime");
    } catch {
      console.log("Error in BarcodePrintSVG>>>>>>>>loadInit");
    }
  }
  outgoingJSON = [
    {
      productDescritpion: "Description",
      actualWeight: "100",
      volWt: "63",
      dimension: "6*6",
      invoiceNo: "54vd5csd47c",
      ewayBillNo: "5456"
    },
    {
      productDescritpion: "Description",
      actualWeight: "100",
      volWt: "63",
      dimension: "6*6",
      invoiceNo: "54vd5csd47c",
      ewayBillNo: "5456"
    },
    // {
    //   productDescritpion: "Description",
    //   actualWeight: "100",
    //   volWt: "63",
    //   dimension: "6*6",
    //   invoiceNo: "54vd5csd47c",
    //   ewayBillNo: "5456"
    // },

  ]

  tableMeta = {
    key: "ewayBillNo",
    columns: [
      { path: "productDescritpion", label: "Product Descritpion" },
      { path: "actualWeight", label: "Actual Weight" },
      { path: "volWt", label: "Vol Wt" },
      { path: "dimension", label: "Dimension" },
      { path: "invoiceNo", label: "Invoice No" },
      { path: "ewayBillNo", label: "e-way Bill No" },
    ]
  }

  insuranceDetails = [
    { value: "onwersRisk", label: "Onwer's Risk" },
    { value: "carriersRisk", label: "Carrier's Risk" },
  ]

  render() {
// console.log("TicketProductLength",this.state.TicketPrimarytDetails)
    return (
      <div className="" style={{height:"100vh",  overflow: "scroll",  }}>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={this.props.closeModal}
        >
          <span aria-hidden="true">Print Barcode &times;</span>
        </button>
        <div className="card">
          <div className="card-header bg-success text-white ">
            <div className="row">
              <div className="col-12"></div>
              <div className="col-4 text-right"></div>
            </div>
            <div className="row">
              <div className="col-8">{"Print Barcode"}</div>
              <div className="col-4 text-right"></div>
            </div>
          </div>
          {/* <div className="container mt-4" id="barcode-content" ref={el => (this.componentRef=el)}>
            <form id="myForm">
              <div className="col-12 border border-dark rounded ps-3 mb-3" id="labelContentToPrint">
                <div className="form-row  ps-3 col-12 border-bottom border-dark">
                  <div className="text-center mt-4 col-4 md-4">
                    <img src={logo} alt="Bizlog" className="w-50" />
                  </div>
                  <div className="text-center mt-4 col-4 md-4">
                    <h2>Way Bill</h2>
                  </div>
                  <div
                    style={{
                      marginBottom: "1rem",
                      paddingInline: "1.5rem",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0.5rem",
                    }}
                    className="col-4 md-4"
                  >
                    <Barcode
                      margin={0}
                      marginTop={3}
                      marginBottom={0}
                      marginLeft={120}
                      height={50}
                      width={2}
                      displayValue={true}
                      background="transparent"
                      value={this.props.barcodeDetails.boxBarcode}
                    />
                  </div>
                </div>
                <div className="form-row ps-3 mb-3 col-12 border-bottom border-dark">
                  <div className="row col-12">
                    <h3>e-way Bill Details :</h3>
                    <div className="d-flex justify-content-between ">
                      <h6 className="">Docket No: 156854500</h6>
                      <h6 className="">E-way Bill No: 156854500</h6>
                      <h6 className="">Date: 24/05/2024</h6>
                    </div>
                  </div>
                </div>
                <div className="form-row ps-3 mb-3 col-12 border-bottom border-dark">
                  <div className="row col-12">
                    <h3>Address Details:</h3>
                    <div className="row  col-12 mb-2 d-flex justify-content-evenly">
                      <div className="border rounded border-dark col-5 w-100 h-100">
                        <h3>From:</h3>
                        <span className="d-flex justify-content-start">
                          <p>Shipper Code: <b>6466</b>,</p>
                          <p>Shipper Name: <b>Bizlog</b></p>
                        </span>
                        <p className="">
                          Address: #68, 3rd cross
                          Basavanagudi near Karanji hanuman Temple,Bengaluru-560004,India
                        </p>
                        <span className="d-flex justify-content-start">
                          <p>Phone: <b>7022579858</b>,</p>
                          <p>Email Id: <b>bizlog@gmail.com</b></p>
                        </span>
                        <span className="d-flex justify-content-start">
                          <p>Photo ID: <b>31d6s1313</b>,</p>
                        <p>Product: <b>Phone</b></p>
                        </span>
                      </div>
                      <div className="border rounded border-dark col-5 w-100 h-100">
                        <h3>To:</h3>
                        <span className="d-flex justify-content-start">
                        <p>Consignee Code: <b>64656</b>,</p>
                        <p>Consignee Name: <b>One Assist</b></p>
                        </span>
                        <p className="">Address: #68, 3rd cross Banashankari near hanuman Temple,Bengaluru-560050,India</p>
                        <span className="d-flex justify-content-start">
                        <p>Phone: <b>9008440985</b>,</p>
                        <p>Email Id: <b>oneAssist@gmail.com</b></p>
                        </span>
                        <p>Photo ID: <b>31d6s1313</b></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row ps-3 mb-3 col-12 border-bottom border-dark ">
                  <h3>Product Details:</h3>
                  <Table
                    clsName="table table-striped table-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.outgoingJSON}
                    dropMenu={[]}
                  />
                </div>
                <div className="form-row ps-3 mb-3 col-12 border-bottom border-dark ">
                  <div className="row">

                    <h3>Service Details (Bizlog Specials)</h3>
                    <div >
                      <p>Service Type: <b>Delivery</b></p>
                      <p>COD: <b>5000</b></p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div> */}
          <div style={{margin: 5, }} ref={el => (this.componentRef = el)}>

          <div style={{margin: 5, }}>

            <div style={{ display: "flex", flexDirection: "row", width: "100%" }} >
              <div style={{ border: "1px solid", width: 500, borderRight: "none" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    margin: 10,
                    // borderBottom:"1px solid", 
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={logo} alt="Bizlog" style={{ width: 190, }} />
                  </div>
                  <b
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    DELIVERY ONTIME LOGISTICS PVT LTD
                  </b>
                  <p style={{ textAlign: "center", fontFamily: "sans-serif", marginBottom: -6, fontSize: 12 }}>
                    31, 2nd floor, Vidya Bhavan, Bizlog Value Chain,  <br />
                    Deliveryontime Logistics Pvt Ltd, W Anjaneya Temple St, opp.<br />
                    Karanji Anjaneya Temple, Bengaluru, Karnataka 560004 <br />
                  </p>
                </div>
                <div style={{ borderTop: "1px solid", borderBottom: "1px solid" }}>
                  <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                    {/* <h4 id="consignor"
                      style={{
                        writingMode: "horizontal-rl",
                        textOrientation: "upright",
                        // transform:"rotate(180deg)",
                        whiteSpace: "nowrap",
                        fontSize: "8px",
                        backgroundColor: "white",
                        color: "#000",
                        padding: 2,
                        fontFamily: "sans-serif"
                      }}><b>CONSIGNOR</b></h4> */}
                    <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: 0, fontSize: 12 }}><p>CONSIGNOR:</p></h5>
                    <div style={{ maxWidth: 200, marginLeft: 20, marginTop: -5 }}>
                      <p style={{ textAlign: "center", fontFamily: "sans-serif" }}>
                      {this.state.TicketProductDetails.srcAdd1},
                            {this.state.TicketProductDetails.srcLandmark},
                            {this.state.TicketProductDetails.srcCity}-
                            {this.state.TicketProductDetails.srcState}-
                            {this.state.TicketProductDetails.srcPincode}
                      </p>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: 5, marginBottom: -12 }}>
                    <p style={{ fontFamily: "sans-serif" }}>CODE:</p>
                    <p style={{ fontFamily: "sans-serif" }}>GSTIN:</p>
                    <p style={{ fontFamily: "sans-serif",marginRight: 55 }}>PIN:  {this.state.TicketProductDetails.srcPincode}</p>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: -15 }}>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, }}>Mobile No: {this.state.TicketProductDetails.srcContact1}</p>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5,marginRight: 25 }}>Alt Mobile No: {this.state.TicketProductDetails.srcContact2}</p>
                </div>
                <div style={{ borderBottom: "1px solid", borderTop: "1px solid", height: 30 }}>
                  {/* <b style={{ display: "flex", justifyContent: "center", fontSize: "15px", fontFamily: "sans-serif" }}>AT OWNER'S RISK/CARRIER'S RISK</b>
                  <p style={{ display: "flex", justifyContent: "center", fontFamily: "sans-serif", marginBottom: -2 }}>If Insured Details of Insurance Policy</p> */}
                  {/* <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: -5 }}>Insurance Company:</p>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: -1 }}>Insurance value:</p> */}
                  {/* <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: 0 }}><p>Insurance Details:</p></h5> */}
                  <div style={{ display: "flex", flexDirection: "row", justifyContent:"space-between" }}>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: -5 }}>Insurance Type:</p>
                    <p style={{ fontFamily: "sans-serif", marginBottom: -2, }}>Service Type: {this.state.TicketPrimarytDetails.flowId}</p>
                    {/* {this.insuranceDetails.map((i) => (
                      <div style={{ display: "flex", flexDirection: "row", marginLeft: 5 }}>
                        <input value={i.value} type="checkbox" style={{ marginTop: -15 }} />
                        <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>{i.label}</p>
                      </div>

                    ))} */}
                  </div>

                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", 
                // marginBottom: -10 
                }}>
                  <div style={{
                    width: 206,
                    // border: "1px solid",
                    // borderleft: 'none',
                    // borderTop: 'none',
                    // borderBottom: 'none',
                    // marginBottom: -10
                  }}>
                    {/* <div style={{
                      width: 200,
                      border: "1px solid",
                      margin: 2
                    }}>
                      <b style={{ display: "flex", justifyContent: "center", fontSize: "15px", fontFamily: "sans-serif" }}>BIZLOG Specials</b>
                    </div> */}
                      {/* <p style={{fontFamily:"sans-serif", marginBottom: -2,width:100, }}>Bizlog AM/PM surface Delivery:</p> */}
                      {/*
                       <p style={{fontFamily:"sans-serif", marginBottom: -2,}}>Bizlog Reverse:</p>
                       <p style={{fontFamily:"sans-serif", marginBottom: -1,}}>Bizlog Air:</p> 
                      */}
                      {/* <div style={{ marginLeft: 5 }}>
                        <p style={{ fontFamily: "sans-serif", marginBottom: -2, }}>Service Type: Delivery</p>
                    </div> */}
                  </div>
                  <div>
                    <div style={{ marginLeft: -130 }}>
                      {/* <h4 style={{ fontFamily: "sans-serif", textAlign: "center"}}>Dimension</h4> */}
                    </div>
                    {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginLeft: 10, }}>
                      <p style={{ fontFamily: "sans-serif", }}>L.....x</p>
                      <p style={{ fontFamily: "sans-serif", }}>B.....x</p>
                      <p style={{ fontFamily: "sans-serif", }}>H.....</p>
                      <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>(inch)</p>
                      <div style={{ marginLeft: 10, marginTop: 0 }}>
                        <p style={{ fontFamily: "sans-serif", }}>Volume in cft:</p>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div style={{ width: 500, borderBottom: "1px solid", }}>
                  <p style={{ fontSize: "7px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>We hereby agree to the terms & conditions set out in the reverse of this Consignor's
                    copy & declare that contents on this waybill are true and comect, The TO-PAY Frieght has mylour consent and will be paid by the
                    consignee along with Service Charges as applicable at the time of Delivery in the event if the Consignee fails to pay take delivery
                    of the consigament, the frieght charges shall be paid by the Consigner.
                  </p>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: -10 }}>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>Name: </p>
                    <p style={{ fontFamily: "sans-serif", marginRight: 5 }}>Signature of the Consignor</p>
                  </div>
                </div>
                <div style={{ width: 500, borderBottom: "1px solid" }}>
                  <p style={{ fontFamily: "sans-serif", marginBottom: -1, marginLeft: 5 }}>Agreed to terms & Conditions of transportation on Reverse Consignor's Signature</p>
                </div>
                <div style={{ width: 500 }}>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>Received the Consignment in good condition<br />
                    Receiver's Name, Signature & Seal
                  </p>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>Date: </p>
                    <p style={{ fontFamily: "sans-serif", marginRight: 5 }}>Time: </p>
                  </div>
                </div>
              </div>
              <div style={{ border: "1px solid", width: 500 }}>
                <div style={{}}>
                  <div style={{ borderBottom: "1px solid", flexDirection:"row",display:"flex" }}>
                    {/* <p style={{ fontFamily: "sans-serif", display: "flex", justifyContent: "center", margin: 9, }}>NON NEGOTIABLE</p> */}
                    <h5 style={{ marginLeft: -4, fontFamily: "sans-serif", marginBottom: 0, padding: "10px" }}>Docket No: <b>{this.state.curTicket}</b></h5>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 150,marginTop:8 }}><b>Shipper Copy</b></p>
                 
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid", height: 30 }}>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>Booking Date: {epoch2ddmmyyyy(this.state.TicketPrimarytDetails.createdAt)}</p>
                    <p style={{ fontFamily: "sans-serif", marginRight: 130 }}>Time: {epoch2hhmmss(this.state.TicketPrimarytDetails.createdAt)}</p>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid" }}>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>From: {this.state.TicketProductDetails.srcLocation}</p>
                    {/* <p style={{ fontFamily: "sans-serif", marginRight: 180 }}>Code: </p> */}
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between",height:35 }}>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom:5 }}>To: {this.state.TicketProductDetails.dstLocation}</p>
                    {/* <p style={{ fontFamily: "sans-serif", marginRight: 180 }}>Code: </p> */}
                  </div>
                </div>
                <div style={{ borderTop: "1px solid", borderBottom: "1px solid",height: 86 }}>
                  <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                    {/* <h4 id="consignor"
                      style={{
                        writingMode: "vertical-rl",
                        textOrientation: "upright",
                        // transform:"rotate(180deg)",
                        whiteSpace: "nowrap",
                        fontSize: "8px",
                        backgroundColor: "white",
                        color: "#000",
                        padding: 2,
                        fontFamily: "sans-serif"
                      }}><b>CONSIGNEE</b></h4> */}
                    <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: 1, fontSize: 12 }}><p>CONSIGNEE:</p></h5>
                    <div style={{ maxWidth: 250, marginLeft: 20, marginTop: -5 }}>
                      <p style={{ textAlign: "center", fontFamily: "sans-serif" }}> 
                            {this.state.TicketProductDetails.dstAdd1},
                            {this.state.TicketProductDetails.dstLocation},
                            {this.state.TicketProductDetails.dstCity},
                            {this.state.TicketProductDetails.dstState}-
                            {this.state.TicketProductDetails.dstPincode}</p>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: 5, marginBottom: -20 , marginTop:-11}}>
                    <p style={{ fontFamily: "sans-serif" }}>CODE: </p>
                    <p style={{ fontFamily: "sans-serif",marginLeft:100 }}>GSTIN:</p>
                    <p style={{ fontFamily: "sans-serif", marginRight: 30}}>PIN: {this.state.TicketProductDetails.dstPincode}</p>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: -15 }}>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, }}>Mobile No: {this.state.TicketProductDetails.dstContact1}</p>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, }}>Alt Phone No: {this.state.TicketProductDetails.dstContact2}</p>
                </div>
                <div style={{ width: 500, borderTop: "1px solid", marginBottom: -16 }}>
                  <p style={{ fontFamily: "sans-serif", marginBottom: 25, marginLeft: 5 }}>Description Of Goods: {this.state.TicketProductDetails.productName}</p>
                </div>
                <div style={{
                  border: "1px solid", borderRight: "none", borderLeft: "none",
                  display: "flex", 
                  flexDirection: "column",
                  //  justifyContent: "space-between",
                    // height: 27
                }}>
                      <div style={{ display: "flex", flexDirection: "row", marginLeft: 5,}}>
                   
                    <p style={{ width: 80, marginRight: 150,fontFamily: "sans-serif"}}><b>Act wt:</b>{this.state.TicketProductDetails.productActualWeight}</p>
                 
                      {/* <div style={{ marginLeft: 10, marginTop: 0 }}> */}
                        <p style={{ fontFamily: "sans-serif",marginLeft: 105, }}><b>Volume Weight:</b>{this.state.TicketProductDetails.productVolumeWeight}</p>

                      {/* </div> */}

                    </div>
                  <div
                   style={{ display: "flex", flexDirection: "row", justifyContent: "space-between",marginTop:0 }}
                   >
                    <div>
                  <p style={{ width: 80, marginLeft: 5,fontFamily: "sans-serif", }}><b>No. of Pkgs</b></p>
                  <p style={{ width: 80, marginLeft: 25,fontFamily: "sans-serif",marginTop:-10 }}>{this.props.productlength}</p>

                  </div>
                  <div>
                  <p style={{ fontFamily: "sans-serif",fontSize:"bold" }}><b>Length</b></p>
                  <p style={{ width: 80, marginLeft: 15,fontFamily: "sans-serif",marginTop:-10 }}>{this.state.TicketProductDetails.productLength}</p>

                  </div>
                  <div>
                  <p style={{ fontFamily: "sans-serif", }}><b>Breadth</b> </p>
                  <p style={{ width: 80, marginLeft: 15,fontFamily: "sans-serif",marginTop:-10 }}>{this.state.TicketProductDetails.productBreadth}</p>

                  </div>
                  <div>
                  <p style={{ fontFamily: "sans-serif", }}><b>Height </b></p>
                  <p style={{ width: 80, marginLeft: 15,fontFamily: "sans-serif",marginTop:-10 }}>{this.state.TicketProductDetails.productHeight}</p>

                  </div>
                    {/* <p style={{ width: 80, marginRight: 130,fontFamily: "sans-serif", }}>Act wt: {this.state.TicketProductDetails.productActualWeight}</p> */}
                    {/* <p style={{ width: 80, marginTop: -20 }}>Child wt:</p> */}
                  </div>
                

                </div>
                <div style={{ borderBottom: "1px solid", height: 20,marginBottom: 1  }}>
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <h5 style={{  marginLeft: 5, fontFamily: "sans-serif", }}><p>Payment Mode: {this.state.TicketPrimarytDetails.paymentMode}</p></h5>
                  <p style={{  marginRight: 130,fontFamily: "sans-serif",  }}>Amount: {this.state.TicketProductDetails?.cost}</p>
                  </div>
                </div>
                  {/* <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: -11 }}><p>Payment Mode:</p></h5> */}
                  {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
                    {/* <div style={{
                       width: 250, marginLeft: 5,
                        }}>
                      <p style={{ fontFamily: "sans-serif", marginBottom: -4 }}>Amount:</p> */}
                      {/* <p style={{ fontFamily: "sans-serif" }}>Ch.No.:</p> */}
                    {/* </div>
                    <div style={{ width: 250 }}> */}
                      {/* <p style={{ fontFamily: "sans-serif", marginBottom: -4 }}>Bank:</p>
                      <p style={{ fontFamily: "sans-serif" }}>Amt:</p> */}
                    {/* </div>
                  </div> */}
                {/* <div style={{ borderBottom: "1px solid", height: 50 }}>
                  <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", }}><b>Billing Details:</b></h5>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: 0 }}>Billing Type:  Cash</p>
                </div> */}
                <div style={{ borderBottom: "1px solid", display: "flex", flexDirection: "row", justifyContent: "space-between", height: 19 }}>
                  {/* <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: 0 }}>Eway Bill No :</p>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: 0 }}>Vehicle Regn No :</p> */}
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: 0 }}>Invoice No : {this.state.TicketProductDetails.invoiceNo}</p>
                  <p style={{
                    fontFamily: "sans-serif", marginLeft: 5, marginBottom: 0,
                    marginRight: 135
                  }}>Value : {this.state.TicketProductDetails?.productValue}</p>
                </div>
                <div>
                  {/* <h4 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: 0 }}><b>Docket No:</b></h4> */}
                  <div
                    // style={{
                    //   marginBottom: "1rem",
                    //   paddingInline: "1.5rem",
                    //   display: "flex",
                    //   justifyContent: "center",
                    //   marginTop: "0.5rem",
                    // }}
                    // className="col-4 md-4"
                  >
                    {/* {!this.state.TicketProductDetails.prdBarcode &&( */}
                    <Barcode
          margin={0}
          marginTop={20}
          marginBottom={10}
          marginLeft={20}
          height={30}
          width={2}
          displayValue={true}
          background="transparent"
          value={this.state.curTicket}
        />
                  {/* )} */}
                  </div>
                </div>
              </div>
            </div>



            <div style={{ marginTop: 150, }}>
              <h2 style={{ display: "flex", justifyContent: "center", width: 1000 }}>TERMS & CONDITIONS</h2>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ border: "1px solid", width: 500, borderRight: "none", }}>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: 10 }}>1. This docket is to be regarded as a contract between owners or d is patchers of the goods (hereinafter reffered to as Consignor)hereof on the one hand and BIZLOG a service
                    offered by Delivery ontime Logistics Pvt. Ltd. (herein after reffered to as the Company) on the other hand, for the transportation of goods of the owners from the booking station
                    station to the destination station as specified on Docket and agreed to the terms and conditions said out hereinafter. This docket is NON NEGOTIABLE. No employee of the company may, in
                    any manner alter and modify these terms and conditions.The owner at his own cost can insure the goods/items covered under this docket.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>2. The company hereby declares that the contents, nature, quality, conditions and value of the subject docket are unknown tothem and that they have accepted the same in good faith for
                    transportation as indicated on the face hereof (as packed and declared by the owner). The company shall not in anyway, be responsible or liable to the consignor and consignee for any loss
                    or damage or non delivery of the subject consignment attributable to or caused by any incorrect or false, declaration and/or defective packing, makingor addressing of the subject docket by
                    them or by theirservants or agents, but the consignor shall be responsible and liable to the company for all the consequences of their action as above. That the consignor/ consignee will
                    save, defend, Idemnify and hold harmless the company against all clearing penalties or fines, other expenses and against legal actions resulting thereof. The consignor shall also be responsible
                    on account of losses caused due to dangerous shipments loaded in to the vehicle on account of false declaration and due to improper 11, documentation resulting in delay of the other shipments.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>3.The consignor here by declares that the subject docket does not include any CONTRABAND or PROHIBITED GOODS and that the consignment has been correctly valued and properly packed, markedand
                    addressed so as to ensure safe transportation delivery. The consignor acknowledges that the company is not a common carrier and that the company at all times shall have the right to refuse any
                    goods for transportation without assigning any reason whatsoever.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>4. The consignor/consignee shall without any limitations be solely liable to the company for and expenses which would include OCTROI/OTHER TAXES/IMPORTLEVEIES related to consignment carried
                    under the docket and the cost of warehousing and return of the docket to the consignor/ consignee if desired by them and agreed by the company.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>5. In the event of security detention of the vehicle carrying the subject Consignment while in transit by the control or local government authorities or any other lawenforcing agency on the
                    ground that the docket comprises or included contraband or prohibited goods or otherwise, the consignor/ consignee shall be liable to the company for all detention charges and other related expenses
                    incurred by them in securing the releases of the vehicle and the goods carried therein.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>6.Demurrage will be charged @Rs. 1/- kg or 1% on the declared value per day, which ever higher, after72 hours of the arrival ofcargo.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>7.Freight  charges to he paid immediately or as per the agreed payment terms entered into with the 67.8. Customer from the date of submission of bills. In case of non payment of billamount within stipullated
                    time parties shall be liable for payment of interest of the rate of 2% per month. Bill will be raised on the delivery even of POD is not submitted.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>8.All claims relating to freight refunds shall be made in writing on the assured of delivery itself by9 the parties contray this Bizlog is not llable.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>9.LIEN OF CONSIGNMENT:
                    The company shall have general llen on the Consignment in case the consignor or consignee fails to pay the transportation charges and otherexpenses incurred by the company in respect of docket due to local
                    state rules or for any other reason whatsoever.</p>
                </div>
                <div style={{ border: "1px solid", width: 500, }}>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: 10 }}>
                    10.LIMITATION OF COMPANY LIABILITY/LIABILITY NOT ASSUMED BY THE COMPANY While company will be
                    using its best endeavors to affect delivery of the subject Consignment the company will not
                    at any circumstances assume any responsibility or liability for any loss
                    (direct or consequential Irrespective of amount involved alleged to have been sustained by the
                    consignor/consignee accord by delay in the pickup/delivery of the subject docket due to:</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -10 }}>
                    a. Act of God, acts of enemies of the states, force majeure, natural calamities or floods or convulsions of nature
                    cause accident to the carrying vehicle of traffic congestion and blockage or by the action of terrorists militants
                    practivists by the person acting from a political motive or caused by resulting from strike, lockout, labour disturbances,
                    riot or civil commissions etc.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -15 }}>
                    b. Any ad, default omission on the part of the consignor consignée or any other interested third party whomsoever arising</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -15 }}>
                    c. Inherentvoice or nature or any special characteristics of the subject Consignment and</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -15 }}>
                    d. Electric or magnetic injury ensure for other such damage to photographic or electronic pictures image or recording in any form.
                  </p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -15 }}>
                    e. BizLog will make every reasonable effort to deliver the Shipment according to our regular delivery schedules, but these are not
                    guaranteed and do not form part of the contract. Bizlog, is not liable for any damages or loss caused by delays
                  </p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    11. CLAIM FOR LOSS OR/DAMAGE TO THE SUBJEC CONSIGNMENT.
                    Any claim as above must be lodged by the consignor/consignee and delivered in writing to the booking office of the company within 10 days after termination of transit
                    of the Consignment subject to remarks on proof, of delivery, thereof shall not be entertained by the Company.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    11a. Any claim or loss pertaining to the Docket shall be restricted to an maximum of the value of the freight paid.
                    In case of dockets where value is not declared, the maximum claim is restricted to a Rs. 100/-(rupees hundred only).</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    12. This contract shall not insure to the benefit of any of the general insurance operating in India.
                  </p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    13. Except under very special circumstance and/or for very good reasons and after payment to the company by the consignor/consignee of all dues,
                    etc. both in respect of the original outward transit and the return transit and safety
                    at the discretion of the company, the subject consignment shall bere-booked by the company from the destination station or to any other station.
                  </p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    14. UNDELIVERED OR UNCLAIMED: Where company is unable, for any reason to deliver docket goods to the consignee or as he may order,
                    it may sell the goods and payment or tenders or the proceeds after deduction of propera charges/expenses in relation thereto
                    and of all outstanding charges in relation to the carriage and storage after issuing Notice or Intimation.
                  </p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    15.  No suit shall be entertained against the company in respect of or in relation to the subject docket/consignment after the expiry of
                    one month from the date of booking. Any disputes pertaining to the Docket shall be referred to Sole Arbitrator appointed by Bizlog
                    governed under the Arbitration and Conciliation Act 1996 as are in force and any amendment thereto from time to time The venue shall
                    be Bengaluru and the award of the arbitrator shall be final and binding.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    16. The consignor declares that he is fully aware of the terms and conditions and set his hands as on obligation to perform.
                  </p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    17. JURISDICTION.
                    The competent court in the city of Bengaluru alone shall have jurisdiction in respect of all claims
                    and/or matters pertaining the subject docket or arising out or in connection with this contract.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div style={{margin: 5, marginTop:250 }}>

            <div style={{ display: "flex", flexDirection: "row", width: "100%" }} >
              <div style={{ border: "1px solid", width: 500, borderRight: "none" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    margin: 10,
                    // borderBottom:"1px solid", 
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={logo} alt="Bizlog" style={{ width: 190, }} />
                  </div>
                  <b
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    DELIVERY ONTIME LOGISTICS PVT LTD
                  </b>
                  <p style={{ textAlign: "center", fontFamily: "sans-serif", marginBottom: -6, fontSize: 12 }}>
                    31, 2nd floor, Vidya Bhavan, Bizlog Value Chain,  <br />
                    Deliveryontime Logistics Pvt Ltd, W Anjaneya Temple St, opp.<br />
                    Karanji Anjaneya Temple, Bengaluru, Karnataka 560004 <br />
                  </p>
                </div>
                <div style={{ borderTop: "1px solid", borderBottom: "1px solid" }}>
                  <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                    {/* <h4 id="consignor"
                      style={{
                        writingMode: "horizontal-rl",
                        textOrientation: "upright",
                        // transform:"rotate(180deg)",
                        whiteSpace: "nowrap",
                        fontSize: "8px",
                        backgroundColor: "white",
                        color: "#000",
                        padding: 2,
                        fontFamily: "sans-serif"
                      }}><b>CONSIGNOR</b></h4> */}
                    <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: 0, fontSize: 12 }}><p>CONSIGNOR:</p></h5>
                    <div style={{ maxWidth: 200, marginLeft: 20, marginTop: -5 }}>
                      <p style={{ textAlign: "center", fontFamily: "sans-serif" }}>
                      {this.state.TicketProductDetails.srcAdd1},
                            {this.state.TicketProductDetails.srcLandmark},
                            {this.state.TicketProductDetails.srcCity}-
                            {this.state.TicketProductDetails.srcState}-
                            {this.state.TicketProductDetails.srcPincode}
                      </p>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: 5, marginBottom: -12 }}>
                    <p style={{ fontFamily: "sans-serif" }}>CODE:</p>
                    <p style={{ fontFamily: "sans-serif" }}>GSTIN:</p>
                    <p style={{ fontFamily: "sans-serif",marginRight: 55 }}>PIN:  {this.state.TicketProductDetails.srcPincode}</p>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: -15 }}>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, }}>Mobile No: {this.state.TicketProductDetails.srcContact1}</p>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5,marginRight: 25 }}>Alt Mobile No: {this.state.TicketProductDetails.srcContact2}</p>
                </div>
                <div style={{ borderBottom: "1px solid", borderTop: "1px solid", height: 30 }}>
                  {/* <b style={{ display: "flex", justifyContent: "center", fontSize: "15px", fontFamily: "sans-serif" }}>AT OWNER'S RISK/CARRIER'S RISK</b>
                  <p style={{ display: "flex", justifyContent: "center", fontFamily: "sans-serif", marginBottom: -2 }}>If Insured Details of Insurance Policy</p> */}
                  {/* <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: -5 }}>Insurance Company:</p>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: -1 }}>Insurance value:</p> */}
                  {/* <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: 0 }}><p>Insurance Details:</p></h5> */}
                  <div style={{ display: "flex", flexDirection: "row", justifyContent:"space-between" }}>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: -5 }}>Insurance Type:</p>
                    <p style={{ fontFamily: "sans-serif", marginBottom: -2, }}>Service Type: {this.state.TicketPrimarytDetails.flowId}</p>
                    {/* {this.insuranceDetails.map((i) => (
                      <div style={{ display: "flex", flexDirection: "row", marginLeft: 5 }}>
                        <input value={i.value} type="checkbox" style={{ marginTop: -15 }} />
                        <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>{i.label}</p>
                      </div>

                    ))} */}
                  </div>

                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", 
                // marginBottom: -10 
                }}>
                  <div style={{
                    width: 206,
                    // border: "1px solid",
                    // borderleft: 'none',
                    // borderTop: 'none',
                    // borderBottom: 'none',
                    // marginBottom: -10
                  }}>
                    {/* <div style={{
                      width: 200,
                      border: "1px solid",
                      margin: 2
                    }}>
                      <b style={{ display: "flex", justifyContent: "center", fontSize: "15px", fontFamily: "sans-serif" }}>BIZLOG Specials</b>
                    </div> */}
                      {/* <p style={{fontFamily:"sans-serif", marginBottom: -2,width:100, }}>Bizlog AM/PM surface Delivery:</p> */}
                      {/*
                       <p style={{fontFamily:"sans-serif", marginBottom: -2,}}>Bizlog Reverse:</p>
                       <p style={{fontFamily:"sans-serif", marginBottom: -1,}}>Bizlog Air:</p> 
                      */}
                      {/* <div style={{ marginLeft: 5 }}>
                        <p style={{ fontFamily: "sans-serif", marginBottom: -2, }}>Service Type: Delivery</p>
                    </div> */}
                  </div>
                  <div>
                    <div style={{ marginLeft: -130 }}>
                      {/* <h4 style={{ fontFamily: "sans-serif", textAlign: "center"}}>Dimension</h4> */}
                    </div>
                    {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginLeft: 10, }}>
                      <p style={{ fontFamily: "sans-serif", }}>L.....x</p>
                      <p style={{ fontFamily: "sans-serif", }}>B.....x</p>
                      <p style={{ fontFamily: "sans-serif", }}>H.....</p>
                      <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>(inch)</p>
                      <div style={{ marginLeft: 10, marginTop: 0 }}>
                        <p style={{ fontFamily: "sans-serif", }}>Volume in cft:</p>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div style={{ width: 500, borderBottom: "1px solid", }}>
                  <p style={{ fontSize: "7px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>We hereby agree to the terms & conditions set out in the reverse of this Consignor's
                    copy & declare that contents on this waybill are true and comect, The TO-PAY Frieght has mylour consent and will be paid by the
                    consignee along with Service Charges as applicable at the time of Delivery in the event if the Consignee fails to pay take delivery
                    of the consigament, the frieght charges shall be paid by the Consigner.
                  </p>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: -10 }}>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>Name: </p>
                    <p style={{ fontFamily: "sans-serif", marginRight: 5 }}>Signature of the Consignor</p>
                  </div>
                </div>
                <div style={{ width: 500, borderBottom: "1px solid" }}>
                  <p style={{ fontFamily: "sans-serif", marginBottom: -1, marginLeft: 5 }}>Agreed to terms & Conditions of transportation on Reverse Consignor's Signature</p>
                </div>
                <div style={{ width: 500 }}>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>Received the Consignment in good condition<br />
                    Receiver's Name, Signature & Seal
                  </p>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>Date: </p>
                    <p style={{ fontFamily: "sans-serif", marginRight: 5 }}>Time: </p>
                  </div>
                </div>
              </div>
              <div style={{ border: "1px solid", width: 500 }}>
                <div style={{}}>
                  <div style={{ borderBottom: "1px solid", flexDirection:"row",display:"flex" }}>
                    {/* <p style={{ fontFamily: "sans-serif", display: "flex", justifyContent: "center", margin: 9, }}>NON NEGOTIABLE</p> */}
                    <h5 style={{ marginLeft: -4, fontFamily: "sans-serif", marginBottom: 0, padding: "10px" }}>Docket No: <b>{this.state.curTicket}</b></h5>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 150,marginTop:8 }}><b>Fiance Copy</b></p>
                 
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid", height: 30 }}>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>Booking Date: {epoch2ddmmyyyy(this.state.TicketPrimarytDetails.createdAt)}</p>
                    <p style={{ fontFamily: "sans-serif", marginRight: 130 }}>Time: {epoch2hhmmss(this.state.TicketPrimarytDetails.createdAt)}</p>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid" }}>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>From: {this.state.TicketProductDetails.srcLocation}</p>
                    {/* <p style={{ fontFamily: "sans-serif", marginRight: 180 }}>Code: </p> */}
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between",height:35 }}>
                    <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom:5 }}>To: {this.state.TicketProductDetails.dstLocation}</p>
                    {/* <p style={{ fontFamily: "sans-serif", marginRight: 180 }}>Code: </p> */}
                  </div>
                </div>
                <div style={{ borderTop: "1px solid", borderBottom: "1px solid",height: 86 }}>
                  <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
                    {/* <h4 id="consignor"
                      style={{
                        writingMode: "vertical-rl",
                        textOrientation: "upright",
                        // transform:"rotate(180deg)",
                        whiteSpace: "nowrap",
                        fontSize: "8px",
                        backgroundColor: "white",
                        color: "#000",
                        padding: 2,
                        fontFamily: "sans-serif"
                      }}><b>CONSIGNEE</b></h4> */}
                    <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: 1, fontSize: 12 }}><p>CONSIGNEE:</p></h5>
                    <div style={{ maxWidth: 250, marginLeft: 20, marginTop: -5 }}>
                      <p style={{ textAlign: "center", fontFamily: "sans-serif" }}> 
                            {this.state.TicketProductDetails.dstAdd1},
                            {this.state.TicketProductDetails.dstLocation},
                            {this.state.TicketProductDetails.dstCity},
                            {this.state.TicketProductDetails.dstState}-
                            {this.state.TicketProductDetails.dstPincode}</p>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: 5, marginBottom: -20 , marginTop:-11}}>
                    <p style={{ fontFamily: "sans-serif" }}>CODE: </p>
                    <p style={{ fontFamily: "sans-serif",marginLeft:100 }}>GSTIN:</p>
                    <p style={{ fontFamily: "sans-serif", marginRight: 30}}>PIN: {this.state.TicketProductDetails.dstPincode}</p>
                  </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: -15 }}>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, }}>Mobile No: {this.state.TicketProductDetails.dstContact1}</p>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, }}>Alt Phone No: {this.state.TicketProductDetails.dstContact2}</p>
                </div>
                <div style={{ width: 500, borderTop: "1px solid", marginBottom: -16 }}>
                  <p style={{ fontFamily: "sans-serif", marginBottom: 25, marginLeft: 5 }}>Description Of Goods: {this.state.TicketProductDetails.productName}</p>
                </div>
                <div style={{
                  border: "1px solid", borderRight: "none", borderLeft: "none",
                  display: "flex", 
                  flexDirection: "column",
                  //  justifyContent: "space-between",
                    // height: 27
                }}>
                     <div style={{ display: "flex", flexDirection: "row", marginLeft: 5,}}>
                    <p style={{ width: 80, marginRight: 130,fontFamily: "sans-serif"}}><b>Act wt:</b>{this.state.TicketProductDetails.productActualWeight}</p>
                      {/* <div style={{ marginLeft: 10, marginTop: 0 }}> */}
                        <p style={{ fontFamily: "sans-serif",marginLeft: 105, }}><b>Volume Weight:</b>{this.state.TicketProductDetails.productVolumeWeight}</p>
                    </div>
                  <div
                   style={{ display: "flex", flexDirection: "row", justifyContent: "space-between",marginTop:0 }}
                   >
                    <div>
                  <p style={{ width: 80, marginLeft: 5,fontFamily: "sans-serif", }}><b>No. of Pkgs</b></p>
                  <p style={{ width: 80, marginLeft: 25,fontFamily: "sans-serif",marginTop:-10 }}>{this.props.productlength}</p>

                  </div>
                  <div>
                  <p style={{ fontFamily: "sans-serif",fontSize:"bold" }}><b>Length</b></p>
                  <p style={{ width: 80, marginLeft: 15,fontFamily: "sans-serif",marginTop:-10 }}>{this.state.TicketProductDetails.productLength}</p>

                  </div>
                  <div>
                  <p style={{ fontFamily: "sans-serif", }}><b>Breadth</b> </p>
                  <p style={{ width: 80, marginLeft: 15,fontFamily: "sans-serif",marginTop:-10 }}>{this.state.TicketProductDetails.productBreadth}</p>

                  </div>
                  <div>
                  <p style={{ fontFamily: "sans-serif", }}><b>Height </b></p>
                  <p style={{ width: 80, marginLeft: 15,fontFamily: "sans-serif",marginTop:-10 }}>{this.state.TicketProductDetails.productHeight}</p>

                  </div>
                    {/* <p style={{ width: 80, marginRight: 130,fontFamily: "sans-serif", }}>Act wt: {this.state.TicketProductDetails.productActualWeight}</p> */}
                    {/* <p style={{ width: 80, marginTop: -20 }}>Child wt:</p> */}
                  </div>
                 

                </div>
                <div style={{ borderBottom: "1px solid", height: 20,marginBottom: 1  }}>
                  <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <h5 style={{  marginLeft: 5, fontFamily: "sans-serif", }}><p>Payment Mode: {this.state.TicketPrimarytDetails.paymentMode}</p></h5>
                  <p style={{  marginRight: 130,fontFamily: "sans-serif",  }}>Amount: {this.state.TicketProductDetails?.cost}</p>
                  </div>
                </div>
                  {/* <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: -11 }}><p>Payment Mode:</p></h5> */}
                  {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
                    {/* <div style={{
                       width: 250, marginLeft: 5,
                        }}>
                      <p style={{ fontFamily: "sans-serif", marginBottom: -4 }}>Amount:</p> */}
                      {/* <p style={{ fontFamily: "sans-serif" }}>Ch.No.:</p> */}
                    {/* </div>
                    <div style={{ width: 250 }}> */}
                      {/* <p style={{ fontFamily: "sans-serif", marginBottom: -4 }}>Bank:</p>
                      <p style={{ fontFamily: "sans-serif" }}>Amt:</p> */}
                    {/* </div>
                  </div> */}
                {/* <div style={{ borderBottom: "1px solid", height: 50 }}>
                  <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", }}><b>Billing Details:</b></h5>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: 0 }}>Billing Type:  Cash</p>
                </div> */}
                <div style={{ borderBottom: "1px solid", display: "flex", flexDirection: "row", justifyContent: "space-between", height: 19 }}>
                  {/* <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: 0 }}>Eway Bill No :</p>
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: 0 }}>Vehicle Regn No :</p> */}
                  <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: 0 }}>Invoice No : {this.state.TicketProductDetails.invoiceNo}</p>
                  <p style={{
                    fontFamily: "sans-serif", marginLeft: 5, marginBottom: 0,
                    marginRight: 135
                  }}>Value : {this.state.TicketProductDetails?.productValue}</p>
                </div>
                <div>
                  {/* <h4 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: 0 }}><b>Docket No:</b></h4> */}
                  <div
                    // style={{
                    //   marginBottom: "1rem",
                    //   paddingInline: "1.5rem",
                    //   display: "flex",
                    //   justifyContent: "center",
                    //   marginTop: "0.5rem",
                    // }}
                    // className="col-4 md-4"
                  >
                    {/* {!this.state.TicketProductDetails.prdBarcode &&( */}
                    <Barcode
          margin={0}
          marginTop={20}
          marginBottom={10}
          marginLeft={20}
          height={30}
          width={2}
          displayValue={true}
          background="transparent"
          value={this.state.curTicket}
        />
                  {/* )} */}
                  </div>
                </div>
              </div>
            </div>



            <div style={{ marginTop: 150, }}>
              <h2 style={{ display: "flex", justifyContent: "center", width: 1000 }}>TERMS & CONDITIONS</h2>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ border: "1px solid", width: 500, borderRight: "none", }}>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: 10 }}>1. This docket is to be regarded as a contract between owners or d is patchers of the goods (hereinafter reffered to as Consignor)hereof on the one hand and BIZLOG a service
                    offered by Delivery ontime Logistics Pvt. Ltd. (herein after reffered to as the Company) on the other hand, for the transportation of goods of the owners from the booking station
                    station to the destination station as specified on Docket and agreed to the terms and conditions said out hereinafter. This docket is NON NEGOTIABLE. No employee of the company may, in
                    any manner alter and modify these terms and conditions.The owner at his own cost can insure the goods/items covered under this docket.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>2. The company hereby declares that the contents, nature, quality, conditions and value of the subject docket are unknown tothem and that they have accepted the same in good faith for
                    transportation as indicated on the face hereof (as packed and declared by the owner). The company shall not in anyway, be responsible or liable to the consignor and consignee for any loss
                    or damage or non delivery of the subject consignment attributable to or caused by any incorrect or false, declaration and/or defective packing, makingor addressing of the subject docket by
                    them or by theirservants or agents, but the consignor shall be responsible and liable to the company for all the consequences of their action as above. That the consignor/ consignee will
                    save, defend, Idemnify and hold harmless the company against all clearing penalties or fines, other expenses and against legal actions resulting thereof. The consignor shall also be responsible
                    on account of losses caused due to dangerous shipments loaded in to the vehicle on account of false declaration and due to improper 11, documentation resulting in delay of the other shipments.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>3.The consignor here by declares that the subject docket does not include any CONTRABAND or PROHIBITED GOODS and that the consignment has been correctly valued and properly packed, markedand
                    addressed so as to ensure safe transportation delivery. The consignor acknowledges that the company is not a common carrier and that the company at all times shall have the right to refuse any
                    goods for transportation without assigning any reason whatsoever.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>4. The consignor/consignee shall without any limitations be solely liable to the company for and expenses which would include OCTROI/OTHER TAXES/IMPORTLEVEIES related to consignment carried
                    under the docket and the cost of warehousing and return of the docket to the consignor/ consignee if desired by them and agreed by the company.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>5. In the event of security detention of the vehicle carrying the subject Consignment while in transit by the control or local government authorities or any other lawenforcing agency on the
                    ground that the docket comprises or included contraband or prohibited goods or otherwise, the consignor/ consignee shall be liable to the company for all detention charges and other related expenses
                    incurred by them in securing the releases of the vehicle and the goods carried therein.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>6.Demurrage will be charged @Rs. 1/- kg or 1% on the declared value per day, which ever higher, after72 hours of the arrival ofcargo.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>7.Freight  charges to he paid immediately or as per the agreed payment terms entered into with the 67.8. Customer from the date of submission of bills. In case of non payment of billamount within stipullated
                    time parties shall be liable for payment of interest of the rate of 2% per month. Bill will be raised on the delivery even of POD is not submitted.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>8.All claims relating to freight refunds shall be made in writing on the assured of delivery itself by9 the parties contray this Bizlog is not llable.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>9.LIEN OF CONSIGNMENT:
                    The company shall have general llen on the Consignment in case the consignor or consignee fails to pay the transportation charges and otherexpenses incurred by the company in respect of docket due to local
                    state rules or for any other reason whatsoever.</p>
                </div>
                <div style={{ border: "1px solid", width: 500, }}>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: 10 }}>
                    10.LIMITATION OF COMPANY LIABILITY/LIABILITY NOT ASSUMED BY THE COMPANY While company will be
                    using its best endeavors to affect delivery of the subject Consignment the company will not
                    at any circumstances assume any responsibility or liability for any loss
                    (direct or consequential Irrespective of amount involved alleged to have been sustained by the
                    consignor/consignee accord by delay in the pickup/delivery of the subject docket due to:</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -10 }}>
                    a. Act of God, acts of enemies of the states, force majeure, natural calamities or floods or convulsions of nature
                    cause accident to the carrying vehicle of traffic congestion and blockage or by the action of terrorists militants
                    practivists by the person acting from a political motive or caused by resulting from strike, lockout, labour disturbances,
                    riot or civil commissions etc.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -15 }}>
                    b. Any ad, default omission on the part of the consignor consignée or any other interested third party whomsoever arising</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -15 }}>
                    c. Inherentvoice or nature or any special characteristics of the subject Consignment and</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -15 }}>
                    d. Electric or magnetic injury ensure for other such damage to photographic or electronic pictures image or recording in any form.
                  </p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -15 }}>
                    e. BizLog will make every reasonable effort to deliver the Shipment according to our regular delivery schedules, but these are not
                    guaranteed and do not form part of the contract. Bizlog, is not liable for any damages or loss caused by delays
                  </p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    11. CLAIM FOR LOSS OR/DAMAGE TO THE SUBJEC CONSIGNMENT.
                    Any claim as above must be lodged by the consignor/consignee and delivered in writing to the booking office of the company within 10 days after termination of transit
                    of the Consignment subject to remarks on proof, of delivery, thereof shall not be entertained by the Company.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    11a. Any claim or loss pertaining to the Docket shall be restricted to an maximum of the value of the freight paid.
                    In case of dockets where value is not declared, the maximum claim is restricted to a Rs. 100/-(rupees hundred only).</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    12. This contract shall not insure to the benefit of any of the general insurance operating in India.
                  </p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    13. Except under very special circumstance and/or for very good reasons and after payment to the company by the consignor/consignee of all dues,
                    etc. both in respect of the original outward transit and the return transit and safety
                    at the discretion of the company, the subject consignment shall bere-booked by the company from the destination station or to any other station.
                  </p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    14. UNDELIVERED OR UNCLAIMED: Where company is unable, for any reason to deliver docket goods to the consignee or as he may order,
                    it may sell the goods and payment or tenders or the proceeds after deduction of propera charges/expenses in relation thereto
                    and of all outstanding charges in relation to the carriage and storage after issuing Notice or Intimation.
                  </p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    15.  No suit shall be entertained against the company in respect of or in relation to the subject docket/consignment after the expiry of
                    one month from the date of booking. Any disputes pertaining to the Docket shall be referred to Sole Arbitrator appointed by Bizlog
                    governed under the Arbitration and Conciliation Act 1996 as are in force and any amendment thereto from time to time The venue shall
                    be Bengaluru and the award of the arbitrator shall be final and binding.</p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    16. The consignor declares that he is fully aware of the terms and conditions and set his hands as on obligation to perform.
                  </p>
                  <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
                    17. JURISDICTION.
                    The competent court in the city of Bengaluru alone shall have jurisdiction in respect of all claims
                    and/or matters pertaining the subject docket or arising out or in connection with this contract.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div style={{margin: 5, marginTop:250 }}>

<div style={{ display: "flex", flexDirection: "row", width: "100%" }} >
  <div style={{ border: "1px solid", width: 500, borderRight: "none" }}>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: 10,
        // borderBottom:"1px solid", 
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={logo} alt="Bizlog" style={{ width: 190, }} />
      </div>
      <b
        style={{ display: "flex", justifyContent: "center" }}
      >
        DELIVERY ONTIME LOGISTICS PVT LTD
      </b>
      <p style={{ textAlign: "center", fontFamily: "sans-serif", marginBottom: -6, fontSize: 12 }}>
        31, 2nd floor, Vidya Bhavan, Bizlog Value Chain,  <br />
        Deliveryontime Logistics Pvt Ltd, W Anjaneya Temple St, opp.<br />
        Karanji Anjaneya Temple, Bengaluru, Karnataka 560004 <br />
      </p>
    </div>
    <div style={{ borderTop: "1px solid", borderBottom: "1px solid" }}>
      <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
        {/* <h4 id="consignor"
          style={{
            writingMode: "horizontal-rl",
            textOrientation: "upright",
            // transform:"rotate(180deg)",
            whiteSpace: "nowrap",
            fontSize: "8px",
            backgroundColor: "white",
            color: "#000",
            padding: 2,
            fontFamily: "sans-serif"
          }}><b>CONSIGNOR</b></h4> */}
        <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: 0, fontSize: 12 }}><p>CONSIGNOR:</p></h5>
        <div style={{ maxWidth: 200, marginLeft: 20, marginTop: -5 }}>
          <p style={{ textAlign: "center", fontFamily: "sans-serif" }}>
          {this.state.TicketProductDetails.srcAdd1},
                {this.state.TicketProductDetails.srcLandmark},
                {this.state.TicketProductDetails.srcCity}-
                {this.state.TicketProductDetails.srcState}-
                {this.state.TicketProductDetails.srcPincode}
          </p>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: 5, marginBottom: -12 }}>
        <p style={{ fontFamily: "sans-serif" }}>CODE:</p>
        <p style={{ fontFamily: "sans-serif" }}>GSTIN:</p>
        <p style={{ fontFamily: "sans-serif",marginRight: 55 }}>PIN:  {this.state.TicketProductDetails.srcPincode}</p>
      </div>
    </div>
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: -15 }}>
      <p style={{ fontFamily: "sans-serif", marginLeft: 5, }}>Mobile No: {this.state.TicketProductDetails.srcContact1}</p>
      <p style={{ fontFamily: "sans-serif", marginLeft: 5,marginRight: 25 }}>Alt Mobile No: {this.state.TicketProductDetails.srcContact2}</p>
    </div>
    <div style={{ borderBottom: "1px solid", borderTop: "1px solid", height: 30 }}>
      {/* <b style={{ display: "flex", justifyContent: "center", fontSize: "15px", fontFamily: "sans-serif" }}>AT OWNER'S RISK/CARRIER'S RISK</b>
      <p style={{ display: "flex", justifyContent: "center", fontFamily: "sans-serif", marginBottom: -2 }}>If Insured Details of Insurance Policy</p> */}
      {/* <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: -5 }}>Insurance Company:</p>
      <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: -1 }}>Insurance value:</p> */}
      {/* <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: 0 }}><p>Insurance Details:</p></h5> */}
      <div style={{ display: "flex", flexDirection: "row", justifyContent:"space-between" }}>
        <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: -5 }}>Insurance Type:</p>
        <p style={{ fontFamily: "sans-serif", marginBottom: -2, }}>Service Type: {this.state.TicketPrimarytDetails.flowId}</p>
        {/* {this.insuranceDetails.map((i) => (
          <div style={{ display: "flex", flexDirection: "row", marginLeft: 5 }}>
            <input value={i.value} type="checkbox" style={{ marginTop: -15 }} />
            <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>{i.label}</p>
          </div>

        ))} */}
      </div>

    </div>
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", 
    // marginBottom: -10 
    }}>
      <div style={{
        width: 206,
        // border: "1px solid",
        // borderleft: 'none',
        // borderTop: 'none',
        // borderBottom: 'none',
        // marginBottom: -10
      }}>
        {/* <div style={{
          width: 200,
          border: "1px solid",
          margin: 2
        }}>
          <b style={{ display: "flex", justifyContent: "center", fontSize: "15px", fontFamily: "sans-serif" }}>BIZLOG Specials</b>
        </div> */}
          {/* <p style={{fontFamily:"sans-serif", marginBottom: -2,width:100, }}>Bizlog AM/PM surface Delivery:</p> */}
          {/*
           <p style={{fontFamily:"sans-serif", marginBottom: -2,}}>Bizlog Reverse:</p>
           <p style={{fontFamily:"sans-serif", marginBottom: -1,}}>Bizlog Air:</p> 
          */}
          {/* <div style={{ marginLeft: 5 }}>
            <p style={{ fontFamily: "sans-serif", marginBottom: -2, }}>Service Type: Delivery</p>
        </div> */}
      </div>
      <div>
        <div style={{ marginLeft: -130 }}>
          {/* <h4 style={{ fontFamily: "sans-serif", textAlign: "center"}}>Dimension</h4> */}
        </div>
        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginLeft: 10, }}>
          <p style={{ fontFamily: "sans-serif", }}>L.....x</p>
          <p style={{ fontFamily: "sans-serif", }}>B.....x</p>
          <p style={{ fontFamily: "sans-serif", }}>H.....</p>
          <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>(inch)</p>
          <div style={{ marginLeft: 10, marginTop: 0 }}>
            <p style={{ fontFamily: "sans-serif", }}>Volume in cft:</p>
          </div>
        </div> */}
      </div>
    </div>
    <div style={{ width: 500, borderBottom: "1px solid", }}>
      <p style={{ fontSize: "7px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>We hereby agree to the terms & conditions set out in the reverse of this Consignor's
        copy & declare that contents on this waybill are true and comect, The TO-PAY Frieght has mylour consent and will be paid by the
        consignee along with Service Charges as applicable at the time of Delivery in the event if the Consignee fails to pay take delivery
        of the consigament, the frieght charges shall be paid by the Consigner.
      </p>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: -10 }}>
        <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>Name: </p>
        <p style={{ fontFamily: "sans-serif", marginRight: 5 }}>Signature of the Consignor</p>
      </div>
    </div>
    <div style={{ width: 500, borderBottom: "1px solid" }}>
      <p style={{ fontFamily: "sans-serif", marginBottom: -1, marginLeft: 5 }}>Agreed to terms & Conditions of transportation on Reverse Consignor's Signature</p>
    </div>
    <div style={{ width: 500 }}>
      <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>Received the Consignment in good condition<br />
        Receiver's Name, Signature & Seal
      </p>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", }}>
        <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>Date: </p>
        <p style={{ fontFamily: "sans-serif", marginRight: 5 }}>Time: </p>
      </div>
    </div>
  </div>
  <div style={{ border: "1px solid", width: 500 }}>
    <div style={{}}>
      <div style={{ borderBottom: "1px solid", flexDirection:"row",display:"flex" }}>
        {/* <p style={{ fontFamily: "sans-serif", display: "flex", justifyContent: "center", margin: 9, }}>NON NEGOTIABLE</p> */}
        <h5 style={{ marginLeft: -4, fontFamily: "sans-serif", marginBottom: 0, padding: "10px" }}>Docket No: <b>{this.state.curTicket}</b></h5>
        <p style={{ fontFamily: "sans-serif", marginLeft: 150,marginTop:8 }}><b>POD Copy</b></p>
     
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid", height: 30 }}>
        <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>Booking Date: {epoch2ddmmyyyy(this.state.TicketPrimarytDetails.createdAt)}</p>
        <p style={{ fontFamily: "sans-serif", marginRight: 130 }}>Time: {epoch2hhmmss(this.state.TicketPrimarytDetails.createdAt)}</p>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid" }}>
        <p style={{ fontFamily: "sans-serif", marginLeft: 5 }}>From: {this.state.TicketProductDetails.srcLocation}</p>
        {/* <p style={{ fontFamily: "sans-serif", marginRight: 180 }}>Code: </p> */}
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between",height:35 }}>
        <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom:5 }}>To: {this.state.TicketProductDetails.dstLocation}</p>
        {/* <p style={{ fontFamily: "sans-serif", marginRight: 180 }}>Code: </p> */}
      </div>
    </div>
    <div style={{ borderTop: "1px solid", borderBottom: "1px solid",height: 86 }}>
      <div style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}>
        {/* <h4 id="consignor"
          style={{
            writingMode: "vertical-rl",
            textOrientation: "upright",
            // transform:"rotate(180deg)",
            whiteSpace: "nowrap",
            fontSize: "8px",
            backgroundColor: "white",
            color: "#000",
            padding: 2,
            fontFamily: "sans-serif"
          }}><b>CONSIGNEE</b></h4> */}
        <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: 1, fontSize: 12 }}><p>CONSIGNEE:</p></h5>
        <div style={{ maxWidth: 250, marginLeft: 20, marginTop: -5 }}>
          <p style={{ textAlign: "center", fontFamily: "sans-serif" }}> 
                {this.state.TicketProductDetails.dstAdd1},
                {this.state.TicketProductDetails.dstLocation},
                {this.state.TicketProductDetails.dstCity},
                {this.state.TicketProductDetails.dstState}-
                {this.state.TicketProductDetails.dstPincode}</p>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginLeft: 5, marginBottom: -20 , marginTop:-11}}>
        <p style={{ fontFamily: "sans-serif" }}>CODE: </p>
        <p style={{ fontFamily: "sans-serif",marginLeft:100 }}>GSTIN:</p>
        <p style={{ fontFamily: "sans-serif", marginRight: 30}}>PIN: {this.state.TicketProductDetails.dstPincode}</p>
      </div>
    </div>
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: -15 }}>
      <p style={{ fontFamily: "sans-serif", marginLeft: 5, }}>Mobile No: {this.state.TicketProductDetails.dstContact1}</p>
      <p style={{ fontFamily: "sans-serif", marginLeft: 5, }}>Alt Phone No: {this.state.TicketProductDetails.dstContact2}</p>
    </div>
    <div style={{ width: 500, borderTop: "1px solid", marginBottom: -16 }}>
      <p style={{ fontFamily: "sans-serif", marginBottom: 25, marginLeft: 5 }}>Description Of Goods: {this.state.TicketProductDetails.productName}</p>
    </div>
    <div style={{
      border: "1px solid", borderRight: "none", borderLeft: "none",
      display: "flex", 
      flexDirection: "column",
      //  justifyContent: "space-between",
        // height: 27
    }}>
         <div style={{ display: "flex", flexDirection: "row", marginLeft: 5,}}>
                    <p style={{ width: 80, marginRight: 130,fontFamily: "sans-serif"}}><b>Act wt:</b>{this.state.TicketProductDetails.productActualWeight}</p>
                      {/* <div style={{ marginLeft: 10, marginTop: 0 }}> */}
                        <p style={{ fontFamily: "sans-serif",marginLeft: 105, }}><b>Volume Weight:</b>{this.state.TicketProductDetails.productVolumeWeight}</p>
                    </div>
      <div
       style={{ display: "flex", flexDirection: "row", justifyContent: "space-between",marginTop:0 }}
       >
        <div>
      <p style={{ width: 80, marginLeft: 5,fontFamily: "sans-serif", }}><b>No. of Pkgs</b></p>
      <p style={{ width: 80, marginLeft: 25,fontFamily: "sans-serif",marginTop:-10 }}>{this.props.productlength}</p>

      </div>
      <div>
      <p style={{ fontFamily: "sans-serif",fontSize:"bold" }}><b>Length</b></p>
      <p style={{ width: 80, marginLeft: 15,fontFamily: "sans-serif",marginTop:-10 }}>{this.state.TicketProductDetails.productLength}</p>

      </div>
      <div>
      <p style={{ fontFamily: "sans-serif", }}><b>Breadth</b> </p>
      <p style={{ width: 80, marginLeft: 15,fontFamily: "sans-serif",marginTop:-10 }}>{this.state.TicketProductDetails.productBreadth}</p>

      </div>
      <div>
      <p style={{ fontFamily: "sans-serif", }}><b>Height </b></p>
      <p style={{ width: 80, marginLeft: 15,fontFamily: "sans-serif",marginTop:-10 }}>{this.state.TicketProductDetails.productHeight}</p>

      </div>
        {/* <p style={{ width: 80, marginRight: 130,fontFamily: "sans-serif", }}>Act wt: {this.state.TicketProductDetails.productActualWeight}</p> */}
        {/* <p style={{ width: 80, marginTop: -20 }}>Child wt:</p> */}
      </div>

    </div>
    <div style={{ borderBottom: "1px solid", height: 20,marginBottom: 1  }}>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
      <h5 style={{  marginLeft: 5, fontFamily: "sans-serif", }}><p>Payment Mode: {this.state.TicketPrimarytDetails.paymentMode}</p></h5>
      <p style={{  marginRight: 130,fontFamily: "sans-serif",  }}>Amount: {this.state.TicketProductDetails?.cost}</p>
      </div>
    </div>
      {/* <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: -11 }}><p>Payment Mode:</p></h5> */}
      {/* <div style={{ display: "flex", flexDirection: "row" }}> */}
        {/* <div style={{
           width: 250, marginLeft: 5,
            }}>
          <p style={{ fontFamily: "sans-serif", marginBottom: -4 }}>Amount:</p> */}
          {/* <p style={{ fontFamily: "sans-serif" }}>Ch.No.:</p> */}
        {/* </div>
        <div style={{ width: 250 }}> */}
          {/* <p style={{ fontFamily: "sans-serif", marginBottom: -4 }}>Bank:</p>
          <p style={{ fontFamily: "sans-serif" }}>Amt:</p> */}
        {/* </div>
      </div> */}
    {/* <div style={{ borderBottom: "1px solid", height: 50 }}>
      <h5 style={{ marginLeft: 5, fontFamily: "sans-serif", }}><b>Billing Details:</b></h5>
      <p style={{ fontFamily: "sans-serif", marginLeft: 5, marginBottom: 0 }}>Billing Type:  Cash</p>
    </div> */}
      <div style={{ borderBottom: "1px solid", height: 20,marginBottom: 1  }}>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
      <h5 style={{  marginLeft: 5, fontFamily: "sans-serif", }}><p>Invoice No : {this.state.TicketProductDetails.invoiceNo}</p></h5>
      <p style={{  marginRight: 130,fontFamily: "sans-serif",  }}>Value : {this.state.TicketProductDetails?.productValue}</p>
      </div>
    </div>
   
    <div>
      {/* <h4 style={{ marginLeft: 5, fontFamily: "sans-serif", marginBottom: 0 }}><b>Docket No:</b></h4> */}
      <div
        // style={{
        //   marginBottom: "1rem",
        //   paddingInline: "1.5rem",
        //   display: "flex",
        //   justifyContent: "center",
        //   marginTop: "0.5rem",
        // }}
        // className="col-4 md-4"
      >
        {/* {!this.state.TicketProductDetails.prdBarcode &&( */}
        <Barcode
          margin={0}
          marginTop={20}
          marginBottom={10}
          marginLeft={20}
          height={30}
          width={2}
          displayValue={true}
          background="transparent"
          value={this.state.curTicket}
        />
      {/* )} */}
      </div>
    </div>
  </div>
</div>



<div style={{ marginTop: 150, }}>
  <h2 style={{ display: "flex", justifyContent: "center", width: 1000 }}>TERMS & CONDITIONS</h2>
  <div style={{ display: "flex", flexDirection: "row" }}>
    <div style={{ border: "1px solid", width: 500, borderRight: "none", }}>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: 10 }}>1. This docket is to be regarded as a contract between owners or d is patchers of the goods (hereinafter reffered to as Consignor)hereof on the one hand and BIZLOG a service
        offered by Delivery ontime Logistics Pvt. Ltd. (herein after reffered to as the Company) on the other hand, for the transportation of goods of the owners from the booking station
        station to the destination station as specified on Docket and agreed to the terms and conditions said out hereinafter. This docket is NON NEGOTIABLE. No employee of the company may, in
        any manner alter and modify these terms and conditions.The owner at his own cost can insure the goods/items covered under this docket.</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>2. The company hereby declares that the contents, nature, quality, conditions and value of the subject docket are unknown tothem and that they have accepted the same in good faith for
        transportation as indicated on the face hereof (as packed and declared by the owner). The company shall not in anyway, be responsible or liable to the consignor and consignee for any loss
        or damage or non delivery of the subject consignment attributable to or caused by any incorrect or false, declaration and/or defective packing, makingor addressing of the subject docket by
        them or by theirservants or agents, but the consignor shall be responsible and liable to the company for all the consequences of their action as above. That the consignor/ consignee will
        save, defend, Idemnify and hold harmless the company against all clearing penalties or fines, other expenses and against legal actions resulting thereof. The consignor shall also be responsible
        on account of losses caused due to dangerous shipments loaded in to the vehicle on account of false declaration and due to improper 11, documentation resulting in delay of the other shipments.</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>3.The consignor here by declares that the subject docket does not include any CONTRABAND or PROHIBITED GOODS and that the consignment has been correctly valued and properly packed, markedand
        addressed so as to ensure safe transportation delivery. The consignor acknowledges that the company is not a common carrier and that the company at all times shall have the right to refuse any
        goods for transportation without assigning any reason whatsoever.</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>4. The consignor/consignee shall without any limitations be solely liable to the company for and expenses which would include OCTROI/OTHER TAXES/IMPORTLEVEIES related to consignment carried
        under the docket and the cost of warehousing and return of the docket to the consignor/ consignee if desired by them and agreed by the company.</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>5. In the event of security detention of the vehicle carrying the subject Consignment while in transit by the control or local government authorities or any other lawenforcing agency on the
        ground that the docket comprises or included contraband or prohibited goods or otherwise, the consignor/ consignee shall be liable to the company for all detention charges and other related expenses
        incurred by them in securing the releases of the vehicle and the goods carried therein.</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>6.Demurrage will be charged @Rs. 1/- kg or 1% on the declared value per day, which ever higher, after72 hours of the arrival ofcargo.</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>7.Freight  charges to he paid immediately or as per the agreed payment terms entered into with the 67.8. Customer from the date of submission of bills. In case of non payment of billamount within stipullated
        time parties shall be liable for payment of interest of the rate of 2% per month. Bill will be raised on the delivery even of POD is not submitted.</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>8.All claims relating to freight refunds shall be made in writing on the assured of delivery itself by9 the parties contray this Bizlog is not llable.</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>9.LIEN OF CONSIGNMENT:
        The company shall have general llen on the Consignment in case the consignor or consignee fails to pay the transportation charges and otherexpenses incurred by the company in respect of docket due to local
        state rules or for any other reason whatsoever.</p>
    </div>
    <div style={{ border: "1px solid", width: 500, }}>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: 10 }}>
        10.LIMITATION OF COMPANY LIABILITY/LIABILITY NOT ASSUMED BY THE COMPANY While company will be
        using its best endeavors to affect delivery of the subject Consignment the company will not
        at any circumstances assume any responsibility or liability for any loss
        (direct or consequential Irrespective of amount involved alleged to have been sustained by the
        consignor/consignee accord by delay in the pickup/delivery of the subject docket due to:</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -10 }}>
        a. Act of God, acts of enemies of the states, force majeure, natural calamities or floods or convulsions of nature
        cause accident to the carrying vehicle of traffic congestion and blockage or by the action of terrorists militants
        practivists by the person acting from a political motive or caused by resulting from strike, lockout, labour disturbances,
        riot or civil commissions etc.</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -15 }}>
        b. Any ad, default omission on the part of the consignor consignée or any other interested third party whomsoever arising</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -15 }}>
        c. Inherentvoice or nature or any special characteristics of the subject Consignment and</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -15 }}>
        d. Electric or magnetic injury ensure for other such damage to photographic or electronic pictures image or recording in any form.
      </p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif", marginTop: -15 }}>
        e. BizLog will make every reasonable effort to deliver the Shipment according to our regular delivery schedules, but these are not
        guaranteed and do not form part of the contract. Bizlog, is not liable for any damages or loss caused by delays
      </p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
        11. CLAIM FOR LOSS OR/DAMAGE TO THE SUBJEC CONSIGNMENT.
        Any claim as above must be lodged by the consignor/consignee and delivered in writing to the booking office of the company within 10 days after termination of transit
        of the Consignment subject to remarks on proof, of delivery, thereof shall not be entertained by the Company.</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
        11a. Any claim or loss pertaining to the Docket shall be restricted to an maximum of the value of the freight paid.
        In case of dockets where value is not declared, the maximum claim is restricted to a Rs. 100/-(rupees hundred only).</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
        12. This contract shall not insure to the benefit of any of the general insurance operating in India.
      </p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
        13. Except under very special circumstance and/or for very good reasons and after payment to the company by the consignor/consignee of all dues,
        etc. both in respect of the original outward transit and the return transit and safety
        at the discretion of the company, the subject consignment shall bere-booked by the company from the destination station or to any other station.
      </p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
        14. UNDELIVERED OR UNCLAIMED: Where company is unable, for any reason to deliver docket goods to the consignee or as he may order,
        it may sell the goods and payment or tenders or the proceeds after deduction of propera charges/expenses in relation thereto
        and of all outstanding charges in relation to the carriage and storage after issuing Notice or Intimation.
      </p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
        15.  No suit shall be entertained against the company in respect of or in relation to the subject docket/consignment after the expiry of
        one month from the date of booking. Any disputes pertaining to the Docket shall be referred to Sole Arbitrator appointed by Bizlog
        governed under the Arbitration and Conciliation Act 1996 as are in force and any amendment thereto from time to time The venue shall
        be Bengaluru and the award of the arbitrator shall be final and binding.</p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
        16. The consignor declares that he is fully aware of the terms and conditions and set his hands as on obligation to perform.
      </p>
      <p style={{ fontSize: "8px", textAlign: "left", marginLeft: 5, fontFamily: "sans-serif" }}>
        17. JURISDICTION.
        The competent court in the city of Bengaluru alone shall have jurisdiction in respect of all claims
        and/or matters pertaining the subject docket or arising out or in connection with this contract.
      </p>
    </div>
  </div>
</div>
</div>
</div>


          <ReactToPrint
            trigger={() => {
              return <button
                type="button"
                className="mt-5 btn btn-outline-secondary"
                // onClick={this.onClickPrintLabel}
                style={{ marginLeft: "43%", width: "150px", marginBottom: "30px" }}
                title="Print Label"

              >
                Print Label
              </button>
            }}
            content={() => this.componentRef}
            documentTitle="Airway Bill"
            pageStyle="print"
          />

          {/* <button
            type="button"
            className="mt-5 btn btn-outline-secondary"
            onClick={this.onClickPrintLabel}
            style={{ marginLeft: "43%", width: "150px", marginBottom: "30px" }}
            title="Print Label"
          >
            Print Label
          </button> */}
        </div>
      </div>
    );
  }
}