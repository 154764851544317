import * as act from "./previlageTypes";

export const setPrevilage = (previlages) => {
  console.log("auth");
  // const previlages = { isOp: 1, isEmp: 1 };
  return async (dispatch) => {
    // dispatch({ type: act.FETCH_USERS_REQUEST });
    try {
      dispatch({
        type: act.SET_PREVILAGE,
        payload: previlages,
      });
    } catch (error) {
      dispatch({
        type: act.SET_PREVILAGE_FAILURE,
        payload: { error: error.message },
      });
    }
  };
};

// export const unsetPrevilage = () => {};
