import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLPrevilages;
function api(path, subURL = "/menus") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getPrvMenus(menuGroupId) {
  return _http.get(api("get-menus/" + menuGroupId), _http.headerJWT());
}
export function createPrvMenus(req) {
  const param = { ...req };
  delete param.menuId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function savePrvMenus(req) {
  const param = { ...req };
  const id = param.menuId;
  //delete param.cityId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deletePrvMenus(req) {
  const param = { ...req };
  const id = param.menuId;
  return _http.delete(api(id), _http.headerJWT());
}
