import React from "react";

import FormLib from "../../_common/FormLib";

import Table from "./../../_common/Table";



class IvrRecordsCard extends FormLib {
  state = {
    ivrData: [],
  };

  tableMeta = {
    key: "ivrId",
    columns: [
      { path: "startTime", label: "startTime" },
      { path: "exotelNo", label: "From No" },
      { path: "toNo", label: "To No" },
      {
        key: "blockStatus",
        action: (r) => (
          <b>{this.replaceString(r.blockStatus)}</b>
        ),
        cusClass: "",
        icon: () => <>Status</>
      },
      // { path: "blockStatus", label: "Status" },
      { path: "status", label: "Status" },
      {
        key: "ivrId",
        action: (r) => (
          <>
            {r.recordingUrl !== "" && (
              <a href={r.recordingUrl} target="_blank" rel="no referrer">
                {r.recordingUrl !== null ? (
                  <i class="fa fa-microphone fa-lg text-info "></i>
                ) : r.recordingUrl === "" ? (
                  <i class="fa fa-microphone-slash fa-lg text-danger"></i>
                ) : (
                  <i class="fa fa-microphone-slash fa-lg text-danger"></i>
                )}
              </a>
            )}
            {r.recordingUrl === "" && (
              <a target="_blank" rel="no referrer">
                {r.recordingUrl !== "" ? (
                  <i class="fa fa-microphone fa-lg text-danger "></i>
                ) : r.recordingUrl === "" ? (
                  <i class="fa fa-microphone-slash fa-lg text-danger"></i>
                ) : (
                  <i class="fa fa-microphone-slash fa-lg text-danger"></i>
                )}
              </a>
            )}
          </>
        ),
        icon: () => <>Audio</>,
      },
    ],
  };


  replaceString = (str) => {
    if (str) {
      str = str.replace(/_/g, " ");
      return str.replace("BLOCK", "JOB");
    }
  };

  render() {
    // const ivrData = this.props.ivrRecords;
    // console.log("ivrData", ivrData)
    return (
      <>

        <div
          className="card"
          style={{ backgroundColor: "transparent", marginTop: "-25px", marginLeft: "19.4%" }}
        >
          <div
            className="card-body "
            style={{
              backgroundColor: "transparent",
              width: "66%",
              marginTop: "1rem",
              paddingTop: "0px"
            }}
          >


            <div className="row" style={{ fontSize: "10px", padding: "0", margin: "0" }}>
              {this.props.ivrRecords?.length > 0 ? (
                <div className="container-fluid ps-0">
                  <p className="fs-6 mb-0 p-0" style={{ backgroundColor: "transparent", fontWeight: "500", marginBottom: '2px' }}>Ivr call records</p>
                  <Table
                    clsName="table table-striped table-bordered table-hover p-0 m-0"
                    tableMeta={this.tableMeta}
                    data={this.props.ivrRecords}
                    dropMenu={[]}
                  />
                </div>
              )
                : <p>Call records not found!</p>

              }

            </div>
          </div>
        </div>


      </>
    );
  }
}

export default IvrRecordsCard;
