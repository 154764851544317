import React, { Component } from "react";
import _ from "lodash";
// columns: array
// sortColumn: object
// onSort: function

class TableHeader extends Component {
  renderCell = (column) => {
    if (column.action) return column.action();

    return _.get(column.path);
  };
  render() {
    return (
      <thead>
        <tr key={"thr_"}>
          {this.props.columns.map((c) => {
            if (c.action) {
              return (
                <th
                  key={"th_" + (c.actionKey || "") + (c.path || c.key)}
                  className={c.cusClass ? c.cusClass : "actions"}
                >
                  {c.icon && c.icon()}
                </th>
              );
            } else {
              return (
                <th key={"th_" + (c.actionKey || "") + (c.path || c.key)}>
                  {c.label}
                </th>
              );
            }
          })}
        </tr>
      </thead>
    );
  }
}

export default TableHeader;
