import React from "react";
import FormLib from "./../../_common/FormLib";
import toast from "../../../services/_toastService";
import { assign, unassign } from '../../../services/coreItAdmin/PlayerZonesServices';

class PlayerZonesMapper extends FormLib {

    state = {
        data: {},
        errors: {},
        curPlayer: {},
    };

    componentDidMount() {
        this.setState({ curPlayer: this.props.curPlr });
    }

    localHandleChange = (e) => {
        this.asignUnAsignPlayersZones(e);
    }

    asignUnAsignPlayersZones = (e) => {
        let paramData = {};
        paramData.playerId = this.state.curPlayer.playerId;
        if (e.target.checked) {
            paramData.zoneId = e.target.value;
            paramData.isPrimary = "true";
            this.assignUnassign('assign', paramData);
        } else {
            paramData.zoneId = e.target.value;
            paramData.isPrimary = "false";
            this.assignUnassign('unAssign', paramData);
        }
    }

    assignUnassign = async (type, paramData) => {
        if (type === 'assign') {
            const res = await assign(paramData);
            if (res.data.success) {
                toast.toastSuccess(res.data.msg);
            } else {
                toast.toastError(res.data.msg);
            }
            // console.log(res.data);
        } else {
            const res = await unassign(paramData);
            // console.log(res.data);
            if (res.data.success) {
                toast.toastSuccess(res.data.msg);
            } else {
                toast.toastError(res.data.msg);
            }
        }
        this.props.onLoadZone(this.state.curPlayer.playerId);
    }

    chkStatus = false;
    render() {
        const zones = this.props.zones;
        return (
            <div className="card overflow-auto" style={{ height: '30rem' }}>
                <div className="card-body">
                    <div className="page-todo">
                        <div className="tasks">
                            {zones.map((r) => {
                                this.chkStatus = r.playerId === null ? false : true;
                                return <div className="task nil" key={r.zoneId}>
                                    <div className="desc">
                                        <span>
                                            <div className="title">
                                                {/* {this.rCBHL("hl", [{ label: r.zone, value: r.zoneId }])} */}

                                                {this.rCBHLLS(
                                                    "cb_" + r.zoneId,
                                                    [{ label: r.zone, value: r.zoneId }],
                                                    { value: "value", label: "label" },
                                                    this.chkStatus,
                                                    "m-0"
                                                )}
                                            </div>
                                        </span>
                                        <div>
                                            <span className={"badge badge-success pull-left mr-1 " + "badge-" +
                                                r.region}>{r.region}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default PlayerZonesMapper;