import React, { Component } from "react";
import { interval } from "rxjs";
// import { StyleSheet, Text, View, LogBox } from "react-native";
import { upSyncActionStart } from "../../../services/demo/syncActionService";

class MasterSync extends Component {
  //emit value in sequence every 1 second
  // const source = interval(1000);
  //output: 0,1,2,3,4,5....

  // const [count, setCount] = useState(0);
  state = {
    count: 0,
  };

  // isSyncFieldRunning = false;
  // isSyncCloudRunning = false;

  subscribeSyncField = "";
  subscribeSyncCloud;
  subscribeSyncMaster;

  isRunningUpSyncAction = false;
  isRunningUpSyncFile = false;
  isRunningUpSyncLog = false;

  componentDidMount() {
    // LogBox.ignoreLogs(["Setting a timer for a long period of time"]);
    console.log("============== Component did Mount ==============");

    this.upSyncObservable();
    upSyncActionStart();
  }

  upSyncObservable() {
    // LogBox.ignoreLogs(["Setting a timer"]);
    console.log("================= upsync Observable  ===================");
    this.subscribeSyncMaster = interval(60000).subscribe((val) => {
      upSyncActionStart();
    });
  }

  onNetChange() {
    console.log("Change in Net Status");
  }

  // fieldSyncStart() {
  //   console.log("Starting");

  //   if (this.subscribeSyncField != "") {
  //     if (!this.subscribeSyncField.closed) {
  //       this.subscribeSyncField.unsubscribe();
  //     }
  //   }

  //   try {
  //     this.subscribeSyncField.unsubscribe();
  //     console.log(this.subscribeSyncField.closed);
  //   } catch (e) {
  //     console.log("unsubscribe errors");
  //   }

  //   this.subscribeSyncField = interval(1000).subscribe((val) => {
  //     this.syncField(val);
  //   });
  //   // this.isSyncFieldRunning = true;
  // }

  // fieldSyncStop2() {
  //   console.log("Stopping");
  //   try {
  //     this.subscribeSyncField.unsubscribe();
  //     console.log(this.subscribeSyncField.closed);
  //   } catch (e) {
  //     console.log("unsubscribe errors");
  //   }
  //   this.isSyncFieldRunning = false;
  // }

  // masterSync() {
  //   subscribeSyncMaster = interval(60000).subscribe((val) => {
  //     this.masterSyncStart();
  //   });
  // }

  // //   onNetChange() {
  // //     masterSyncStart();
  // //   }

  // masterSyncStart() {
  //   // console.log(subscribeSyncField.closed);
  //   // this.isSyncFieldRunning === false ? this.fieldSyncStart() : null;
  //   this.fieldSyncStart();
  //   console.log("mater sync start");
  // }

  // //   // fieldSyncStart();

  // syncField(val) {
  //   // console.log(val);
  //   //   setCount(val);
  //   let sysnResp = fieldSync(val);
  //   sysnResp !== 1
  //     ? this.fieldSyncStop2()
  //     : console.log("asdfghjkl--> " + sysnResp + " <---");
  // }

  render() {
    return <div>Master Sync</div>;
  }
}
export default MasterSync;
