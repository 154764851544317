import _http from "./../_httpService";

import config from "./../../config.json";

const baseURL = config[config.build].apiURLPrevilages;

function api(path, subURL = "") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}
export function getLocationById(id) {
  return _http.get(
    api("players-features/locations/all/" + id),
    _http.headerJWT()
  );
}
export function getLocations() {
  return _http.get(api("locations"), _http.headerJWT());
}
export function createLocation(req) {
  const param = { ...req };
  return _http.post(
    api("players-features/locations"),
    param,
    _http.headerJWT()
  );
}
export function updateLocation(req) {
  const param = { ...req };
  const id = param.locationId;
  return _http.put(api("locations/" + id), param, _http.headerJWT());
}
export function deleteLocation(req) {
  const param = { ...req };
  const id = param.locationId;
  return _http.delete(api("locations/" + id), _http.headerJWT());
}
