import React from "react";
import FormLib from "../../_common/FormLib";
import ZTitle from "../../_subhead/ZTitle";
import Joi from "joi-browser";
import Table from "../../_common/Table";
import Pagination from "../../_common/Pagination";
import { getHubsAll } from "../../../services/masterLocations/hubServices";
// import { getTeams } from "../../../services/player/teamService";
import toast from "../../../services/_toastService";
import { getPincodesList } from '../../../services/masterLocations/pincodeServices';

class PincodesList extends FormLib {
  state = {
    data: {
      hub: "",
      hudId: "",
      retailerId: "",
      searchString: "",
    },
    errors: {},
    hubs: [],
    teams: [],
    pincodes: [],
    cities: [],
    error: null,
    loading: true,
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    curHub: { hubId: "" }


  };

  schema = {
    hub: Joi.string().required().label("Hub"),
  };

  componentDidMount() {
    this.loadHubs();
    // this.loadTeams();
  }

  loadHubs = async () => {
    this.setState({ loading: true });
    const res = await getHubsAll();
    if (res.data.success === true) {
      this.setState({ hubs: res.data.data.hubs });
      console.log(this.state.hubs);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  // loadTeams = async () => {
  //   this.setState({ loading: true });
  //   const res = await getTeams();
  //   if (res.data.success === true) {
  //     this.setState({ teams: res.data.data.teams });
  //     console.log(this.state.teams);
  //   } else {
  //     toast.toastError(res.data.msg);
  //   }
  //   this.setState({ loading: false });
  // };

  handlePageChange = (pageNo) => {
    this.props.history.push("/masters/PincodesList/" + pageNo);
    this.loadHub(pageNo);
  };


  loadHub = async (pageNo = 1) => {
    this.setState({ loading: true });
    let params = {
      "hubId": this.state.data.hub,
      "retailerId": this.state.data.retailerId,
      "searchString": this.state.data.searchString,
    }
    const res = await getPincodesList(params, pageNo)
    if (res.data.success === true) {
      if (res.data.data.length != 0) {
        this.setState({ pincodes: res.data.data.pincodes });
        this.setState({ pageLinks: res.data.data.pageLinks });
        toast.toastSuccess(res.data.msg);
      } else {
        this.setState({ pincodes: [] });
        toast.toastInfo("No data found for selected hub");
      }
    } else {
      this.setState({ pincodes: [] });
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };


  tableMeta = {
    key: "hubId",
    columns: [
      {
        key: "hubId",
        action: () => (
          <>
            <span className="badge badge-success pull-right mr-1 badge-normal">
            </span>
          </>
        ),
      },
      { path: "pincode", label: "Pincode" },
      { path: "serviceType", label: "Service Type" },
      { path: "status", label: "Status" },
      { path: "hub", label: "Hub" },
      { path: "hubId", label: "Hub Id" },
    ],
  };
  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    const pincodesJSON = this.state.pincodes;

    return (
      <React.Fragment>
        <ZTitle subHeading="PincodesList" />
        <div className="pageContent">
          <div className="card">
            <div className="card-body">
              <form onSubmit={this.handleSubmit}>
                <div className="form-row">
                  {this.rSB(
                    "Hub",
                    "hub",
                    this.state.hubs,
                    { value: "hubId", label: "hub" },
                    " col-md-3 "
                  )}
                  {/* {this.rSB(
                    "Retailer",
                    "retailerId",
                    this.state.teams,
                    { value: "retailerId", label: "name" },
                    " col-md-3 "
                  )} */}
                  {this.rTB("Search", "searchString", "col-md-3")}
                </div>
                <div className="col-9">
                  <button
                    className="btn  btn-info"
                    onClick={() => this.loadHub()}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>

            {pincodesJSON.length > 0 && (<div className="row col-md-12">
              <div className="container-fluid">

                <Table
                  clsName="table table-striped tabele-bordered table-hover"
                  tableMeta={this.tableMeta}
                  data={this.state.pincodes}
                  dropMenu={[]}
                />
                <Pagination
                  pageNo={pageNo}
                  perPage={perPage}
                  itemsCount={itemsCount}
                  onPageChange={this.handlePageChange}
                />
              </div>

            </div>)}
          </div>
        </div>


      </React.Fragment>
    );
  }
}

export default PincodesList;