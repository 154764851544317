import React from "react";

import FormLib from "../../../_common/FormLib";
import Joi from "joi-browser";
import { requestCall } from "../../../../services/requestV1/requestServices";
import { toast } from "react-toastify";

class RequestApprove2Open extends FormLib {
  state = {
    data: {
      revert_remarks: "",
    },
    errors: {},
    approvedRequest: {},
  };

  schema = {
    revert_remarks: Joi.string().required().label("Revert Remarks"),
  };

  componentDidMount() {
    this.setPayment();
  }

  setPayment() {
    this.setState({ approvedRequest: this.props.data });

    // console.log("88888888888---> " + JSON.stringify(this.props.data))
  }

  async doSubmit() {
    const params = {
      request_id: this.state.approvedRequest.REQUEST_ID,
      approve_id: this.state.approvedRequest.APPROVE_ID,
      line_type: this.state.approvedRequest.LINE_TYPE,
      ...this.state.data,
      status: this.state.btnValue,
    };
    // console.log(params, this.state.btnValue);
    let requestRaised = await requestCall(params, this.state.btnValue);
    if (requestRaised.data.success === true) {
      toast.success(() => <div>{requestRaised.data.msg}</div>);
      document.getElementById("myForm").reset();
      this.setState({ data: "" });
    } else {
      toast.error(() => <div>{requestRaised.data.msg}</div>);
    }
    this.props.requestStatusChange();
  }

  render() {
    return (
      <React.Fragment>
        <form id="myForm" onSubmit={this.handleSubmit}>
          <div className="form-row">
            <div className="col-12">
              {this.rTA("Revert Remarks", "revert_remarks")}
            </div>
          </div>
        </form>
        <div className="p-1">
          {this.rBtnSW(
            "Revert to Open",
            "revertOpen",
            "btn btn-warning btn-sm btn-block"
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default RequestApprove2Open;
