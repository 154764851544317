import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getHubs } from "../../../services/masters/hubServices";
import { getRefDocFilesUploadedCount, postInwardRefFileUpload } from "../../../services/stockManagement/inventory";
import {
  getBoxesByStoreId,
  getInvItemTxnByItemId,
  getInvTxnsByStoreId,
  getItemsListByInvTxnId,
  getStoresByHub,
  postInvItemsComp,
} from "../../../services/stockManagement/inwardInventoryServices";
// import { set } from "lodash";

const StackInventory = () => {
  const [hubsList, setHubsList] = useState([]);
  const [hubId, setHubId] = useState({});
  const [storesList, setStoresList] = useState([]);
  const [storeId, setStoreId] = useState({});
  const [invTxnList, setInvTxnList] = useState([]);
  const [invTxn, setInvTxn] = useState({});
  const [itemList, setItemList] = useState([]);
  const [item, setItem] = useState({});
  const [boxList, setBoxList] = useState([]);
  const [box, setBox] = useState("");
  const [qty, setQty] = useState("");
  const [invTxnItemList, setInvTxnItemList] = useState([]);
  const [stateSelectedFilesList, setSelectedFilesList] = useState([]);
  const [stateInwardTxn, setInwardTxn] = useState({});
  const [stateFilesUploadedCount, setFilesUploadedCount] = useState(0)

  console.log({
    hubsList,
    hubId,
    storesList,
    storeId,
    invTxnList,
    invTxn,
    itemList,
    item,
    boxList,
    box,
    qty,
    invTxnItemList,
    stateSelectedFilesList,
    stateInwardTxn,
    stateFilesUploadedCount
  });

  // fetch hub list on component initial loading
  useEffect(() => {
    getHubs()
      .then((response) => {
        const hubs = response.data.data.hubs;
        setHubsList(hubs);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // on selecting a hub, get stores list by hubId
  const handleChangeHub = (e) => {
    const hubSelected = e.target.value;
    // console.log({ hubSelected });
    // console.log('hubSelected', hubSelected)
    // once the hub is set, reset every dependent fields
    if (hubId === hubSelected) return null;
    setHubId(hubSelected);
    setStoreId("");
    setItem({});
    setInvTxn({});
    setInwardTxn({});
    setItemList([]);
    setInvTxnList([]);
    setBox("");
    setBoxList([]);
    setQty("");

    // Logic to fetch store based on hub selected

    const getStores = async (hubId) => {
      const response = await getStoresByHub(hubId);
      return response.data;
    };

    getStores(hubSelected).then(({ data }) => {
      setStoresList(data.stores);
    });
  };

  // on selecting a single store, display its invTxnList if list items are used
  const handleChangeStore = (e, store) => {
    const storeSelected = e.target.value;
    if (storeId === storeSelected) return null;
    setStoreId(storeSelected);
    setItem({});
    setInvTxn({});
    setInwardTxn({});
    setItemList([]);
    setQty("");
    setBox("");

    // rest bay list and get bays ist by StoreId
    const getBoxes = async (storeId) => {
      const response = await getBoxesByStoreId(storeId);
      return response.data;
    };

    getBoxes(storeSelected).then(({ data }) => {
      // console.log("getBoxesByStoreId", data);
      setBoxList(data.baysList);
      // setItem({})
    });

    // Logic to fetch invTxns based on store selected
    // get inwards by store id (inv_trx_inwards | status=open)
    const getInvTxns = async (storeId) => {
      const response = await getInvTxnsByStoreId(storeId);
      return response.data;
    };

    getInvTxns(storeSelected).then(({ data }) => {
      // console.log("getInvTxnsByStoreId", data);
      setInvTxnList(data.stores);
    });
  };

  // on selecting the hubId and storeId, get invTxnList. boxList by StoreId - selectBox
  // const handleClickSelect = (e) => {
  //   // Logic to fetch invTxns based on store selected
  //   // get inwards by store id (inv_trx_inwards | status=open)
  //   const getInvTxns = async (storeId) => {
  //     const response = await getInvTxnsByStoreId(storeId);
  //     return response.data;
  //   };

  //   getInvTxns(storeId).then(({ data }) => {
  //     // console.log("getInvTxnsByStoreId", data);
  //     setInvTxnList(data.stores);
  //   });

  //   // get Boxes List by StoreId
  //   const getBoxes = async (storeId) => {
  //     const response = await getBoxesByStoreId(storeId);
  //     return response.data;
  //   };

  //   getBoxes(storeId).then(({ data }) => {
  //     // console.log("getBoxesByStoreId", data);
  //     setBoxList(data.baysList);
  //   });
  // };

  // on selecting single invTxn, display its item list and reset - item inv trxn list, boxList, quantity
  const handleClickInvTxn = async (inv) => {
    const invId = inv?.invTrxId ? inv.invTrxId : ""

    if(!invId) return null

    if (invTxn === invId) return null;
    setInvTxn(invId);
    setInwardTxn(inv);
    //logic to fetch files uploaded count
try{
const resp = await getRefDocFilesUploadedCount(invId);
      if (resp.data.success){
        setFilesUploadedCount(resp.data.data?.inwardFileStatus?.fileCount)
      }

} catch(error){
  console.log(error)
}

    // logic to fetch items list by inward txn id
    const getItemsList = async (invTxnId) => {
      const response = await getItemsListByInvTxnId(invTxnId);
      return response.data;
    };
    getItemsList(invId).then(({ data }) => {
      setItemList(data.InvTrxbyItems);
      setItem({});
      setQty("");
      setBox("");
    });
  };

  // upon selecting item, display previous successful item trxn list
  const handleClickItemList = (i) => {
    if (i.itemId === item.itemId) return null;
    setItem(i);
    setQty("");
    setBox("");
    // logic to fetch getInvItemTxn list
    const getInvItemTxn = async (invTrxItemsId) => {
      const response = await getInvItemTxnByItemId(invTrxItemsId);

      return response.data;
    };
    getInvItemTxn(i.invTrxItemsId).then(({ data }) => {
      // console.log("getInvItemTxn", data);
      setInvTxnItemList(data.stores);
    });
  };

  // Select bay and upon selecting bay, reset qty
  const handleChangeBox = (e, bId) => {
    const boxId = e.target.value;
    if (boxId === box) return null;
    setBox(boxId);
    setQty("");
  };

  // post invItemTxn and display successful item trxn list
  const handleSubmitItemTxn = (e) => {
    // post json
    if (qty <= 0 || qty === "") {
      alert("Invalid quantity");
      setQty("");
      return null;
    }
    if (box === "") {
      alert("Select Bay");
      return null;
    }
    const data = {
      invTrxItemsId: item.invTrxItemsId,
      itemId: item.itemId,
      storeBaysId: box,
      trxType: "stacking",
      qnt: qty,
    };
    // console.log("postData", data);

    const getInvItemTxn = async (invTrxItemsId) => {
      const response = await getInvItemTxnByItemId(invTrxItemsId);
      return response.data;
    };

    //upon successful post operation, clear quantity
    const postItemTxn = async (data) => {
      const response = await postInvItemsComp(data);
      return response.data;
    };

    postItemTxn(data).then((res) => {
      // console.log("postItemTxn", res);
      setQty("");
      setBox("");

      getInvItemTxn(item.invTrxItemsId)
        .then(({ data }) => {
          // console.log("getInvItemTxnAtHandle", data);
          setInvTxnItemList(data.stores);
        })
        .catch((error) => console.error(error));
    });

    // console.log("item.invTrxItemsId", item.invTrxItemsId);
  };

  const handleUploadFiles = async () => {
    console.log("handleUploadFiles")
    if(stateSelectedFilesList.length === 0){
      alert("Please select files")
      return;
    }
    const req = {
      
      selectedFileList: stateSelectedFilesList,
      invTrxId: invTxn
    }
    try{
    const resp = await postInwardRefFileUpload(req)
    if(resp.data.success){
      setSelectedFilesList("")
      toast.success(resp.data.msg)
     try{
      console.log("Fetching ref doc files upload")
      const resp = await getRefDocFilesUploadedCount(invTxn);
      if (resp.data.success){
        setFilesUploadedCount(resp.data.data?.inwardFileStatus?.fileCount)
      }

} catch(error){
  console.log(error)
}
    }

    }catch(error){
      console.log(error)
    }
   

  }

  return (
    <>
      <div className="container-fluid">
        <p className="fs-4">Sorting & Storing</p>
        <div className="row">
          {/* Select hub */}
          <div className="col-1 p-0 m-1">
            <p className="font-monospace mb-1 p-0">Hubs</p>
            <div className="list-group">
              {hubsList?.length !== 0 &&
                hubsList.map((hub) => (
                  <button
                    value={hub.hubId}
                    onClick={(e) => handleChangeHub(e)}
                    key={hub.hubId}
                    type="button"
                    className={`list-group-item list-group-item-action shadow-none p-1 m-0 ${
                      hub.hubId === hubId ? "active" : null
                    }`}
                  >
                    {hub.hub}
                  </button>
                ))}
            </div>
          </div>

          {/* select store */}
          <div className="col-1 m-0 p-1">
            <p className="font-monospace mb-1 p-0">Stores</p>
            <div className="list-group">
              {storesList?.length !== 0 &&
                storesList.map((store) => (
                  <button
                    key={store.storeId}
                    value={store.storeId}
                    onClick={(e) => handleChangeStore(e, store)}
                    type="button"
                    className={`list-group-item list-group-item-action shadow-none p-1 m-0 ${
                      store.storeId === storeId ? "active" : null
                    }`}
                  >
                    {store.store}
                  </button>
                ))}
            </div>
          </div>

          {/* Select inward transaction */}
          <div className="col-2 m-0 p-1">
            {invTxnList?.length !== 0 && (
              <>
                <p className="font-monospace mb-1 p-0">Inwards</p>
                <ul className="list-group">
                  {invTxnList?.map((inv) => {
                    return (
                      <button
                        type="button"
                        key={inv.invTrxId}
                        className={
                          "list-group-item list-group-item-action shadow-none p-1 m-0" +
                          ` ${invTxn === inv.invTrxId ? "active" : null}`
                        }
                        onClick={(e) => handleClickInvTxn(inv)}
                      >
                        <p className="p-0 m-0 lh-sm text-dark" style={{fontSize: "1.0rem"}}><span>{inv.invDesc}</span></p>
                       <p className="p-0 m-0 clearfix lh-sm">
                        <span className={invTxn === inv.invTrxId ? "text-light font-monospace":"text-muted font-monospace"}>{inv.invDateTime.slice(0,10)}</span>
                        <span className={invTxn === inv.invTrxId ? "fw-bold text-white font-monospace px-1 pull-right": "fw-bold text-info font-monospace px-1 pull-right" }>{inv?.retailerCode ? inv.retailerCode: ""}</span>
                       </p>
                      </button>
                    );
                  })}
                </ul>
              </>
            )}
          </div>

          {/* Select items */}
          <div className="col-3 m-0 p-1">
            {itemList?.length !== 0 && (
              <>
                <p className="font-monospace mb-1 p-0">Inward items</p>
                <ul className="list-group">
                  {itemList.map((i) => (
                    <li
                      key={i.itemId}
                      className={
                        "list-group-item d-flex justify-content-between m-0  p-1" +
                        ` ${item.itemId === i.itemId ? "active" : null}`
                      }
                      onClick={(e) => handleClickItemList(i)}
                    >
                      <span className="m-0 p-0">{i?.item || i?.itemId}</span>{" "}
                      <span className="m-0 p-0">{i.qnt}</span>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>

          {/* Stacking item form and item transaction table display */}
          <div className="col-4 m-0 p-1">
             {/* Ref Doc upload button, if no ref doc; stacking NOT allowed */}
             {invTxn && stateInwardTxn?.invTrxId &&<>
              <div className="col-12 m-0 p-1">

                <p className="font-monospace m-0 mb-2 ">
                Ref Document Details
                </p>
                <p className="col-8 clearfix m-0 p-0"><span>Ref Doc No</span><span className="pull-right">{stateInwardTxn?.refDocNumber? stateInwardTxn.refDocNumber : "NA"}</span></p>
                <p className="col-8 clearfix m-0 p-0"><span>Ref Doc Type</span><span className="pull-right">{stateInwardTxn?.refDocType ? stateInwardTxn.refDocType: "NA"}</span></p>
                {/* <p className="col-8 clearfix m-0 p-0 mb-2"><span>Ref. doc uploaded files count</span><span className="pull-right">{"6"}</span></p> */}
                
                {stateFilesUploadedCount === 0 &&<p className="text-danger m-0 px-0 mt-3 col-8 ">No DC copy or invoice files found. Please upload the files to enable storing items</p>}

                <div className="ms-1 p-0 col-10 mt-2">
                  <label htmlFor="refDocFiles" className="form-label font-monospace">Upload DC copy or Invoice Files</label>
                <div className="row m-0 p-0 ms-2 mb-3">
                    <input className="form-control form-control-sm col-9 ps-2" multiple id="refDocFiles" type="file"
                    onChange={(e)=>{
                      setSelectedFilesList(e.target.files);              
                    }}/>
                <div className="col-2">
                    <button type="button" className="btn btn-outline-secondary btn-sm" onClick={handleUploadFiles}> Upload</button>

                </div>
                </div>
                </div>

              </div>
              </>}
            {/* stacking item form  */}
            {item?.itemId && (
              <>
             
                {/* Bay selectbox, qty input and submit button */}
                <div className="row flex-column g-0">
                  {/* bay select box */}
                  <div className="col ">
                    {item?.itemId && (
                      <>
                        <p className="font-monospace m-0 p-0">Store items into Bays</p>
                        <select
                          className="form-select"
                          aria-label="selectBox"
                          value={box}
                          disabled={!boxList?.length ? true : false}
                          onChange={(e) => handleChangeBox(e)}
                        >
                          <option key="0" value="">
                            Select Bay
                          </option>
                          {boxList?.length &&
                            boxList.map((b) => (
                              <option key={b.storeBaysId} value={b.storeBaysId}>
                                {b.baysName}
                              </option>
                            ))}
                        </select>
                      </>
                    )}
                  </div>

                  {/* Quantity input textbox */}
                  <div className="col mt-1">
                    <input
                      className="form-control"
                      type="number"
                      min={0}
                      placeholder="Quantity"
                      aria-label="quantity"
                      onChange={(e) => {
                        
                          setQty(e.target.value);
                        
                      }}
                      value={qty}
                    />
                  </div>

                  {/* Submit button  */}
                  <div className="col mt-2">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={handleSubmitItemTxn}
                      disabled={stateFilesUploadedCount===0}
                    >
                      Submit
                    </button>
                  </div>
                </div>

                {/* item inventory transaction list */}
                {item?.itemId && (
                  <div className="row flex-column ms-1 mt-3 me-1">
                    <p className="font-monospace">
                      Item inventory transaction list
                    </p>
                    {invTxnItemList?.length !== 0 && (
                      <>
                        {invTxnItemList.map((i) => (
                          <div
                            key={i.storingId}
                            className="col card border mb-1"
                          >
                            <p className="p-1 mb-1 font-monospace">
                              {/* <span className="font-monospace">Bay:</span>{" "} */}
                              {
                                (boxList?.filter(
                                  (box) => box?.storeBaysId === i.storeBaysId
                                ))[0]?.baysName
                              }
                            </p>
                            <p className="d-flex justify-content-between m-0 p-1">
                              {" "}
                              <span>
                                {
                                  itemList.filter(
                                    (item) => item.itemId === i.itemId
                                  )[0]?.item
                                }
                              </span>{" "}
                              <span> {i.qnt}</span>
                            </p>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StackInventory;
