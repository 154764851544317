import _http from "../_httpService";

import config from "../../config.json";

const baseURL = config[config.build].apiURLInventory;
const baseURLInventory = config[config.build].apiURLInventory;


function api(path, subURL) {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getDetailsBySlNo(slNo) {
  try {
    const subURL = "getBarcodeDetailsByslNo";
    return _http.get(
      `${baseURLInventory}/getBarcodeDetailsByslNo/${slNo}`,
      _http.headerJWT()
    );
  } catch (e) {
    console.error(e);
  }
}
export function getClusteredHubsDetails(hubId) {
  try {
    const subURL = "getBarcodeDetailsByslNo";
    return _http.get(
      `${baseURLInventory}/getClusteredHubsDetails/${hubId}`,
      _http.headerJWT()
    );
  } catch (e) {
    console.error(e);
  }
}

export function getDetailsBySlNoStatus(slNo) {
  try {
    const status = "00available";
    return _http.get(
      `${baseURLInventory}/getInvBarcodeDetailsBySlNoAndStatus/${slNo}/${status}`,
      _http.headerJWT()
    );
  } catch (e) {
    console.error(e);
  }
}
