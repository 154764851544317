import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import Table from "../../_common/Table";
// import Pagination from "../../_common/Pagination";

import PrevilagesTitle from "../../_subhead/PrevilagesTitle";
import toast from "../../../services/_toastService";
import {
  getRoleDivs,
  createRoleDivs,
  saveRoleDivs,
  deleteRoleDivs,
} from "../../../services/appRoles/roleDivsServices";
class RoleDivs extends FormLib {
  state = {
    data: {
      divisionId: "",
      division: "",
      status: "",
      sort: "",
    },
    errors: {},
    roleDivs: [],
    loading: true,
    // pageLinks: {
    //   pageNo: 1,
    //   perPage: 0,
    //   itemsCount: 0,
    //   lastPage: 0,
    // },
  };
  schema = {
    divisionId: Joi.optional().label("Division Id"),
    division: Joi.string().required().label("Division"),
    status: Joi.string().required().label("Status"),
    sort: Joi.string().required().label("Sort"),
  };
  componentDidMount() {
    this.loadRoleDivs();
  }
  async loadRoleDivs() {
    this.setState({ loading: true });
    const res = await getRoleDivs();
    if (res.data.success === true) {
      this.setState({ roleDivs: res.data.data.roleDivs });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  tableMeta = {
    key: "divisionId",
    columns: [
      {
        key: "DIVISION_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnRoleDivs(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      // { path: "divisionId", label: "Division Id" },
      { path: "division", label: "Division" },
      { path: "status", label: "Status" },
      { path: "sort", label: "Sort" },
      {
        key: "edit",
        action: (r) => (
          <>
            <span
              onClick={() => this.handleOnRoleDivs(r)}
              className="badge  badge-success mr-1 p-1 pull-right"
            >
              <i className={"fa  fa-edit"}></i>
            </span>
          </>
        ),
        label: "Action",
      },
    ],
  };
  handleOnRoleDivs(r) {
    // console.log("inside handle on RoleDivs");
    let roleDivs = {};
    roleDivs.divisionId = r.divisionId;
    roleDivs.division = r.division;
    roleDivs.status = r.status;
    roleDivs.sort = r.sort;
    this.setState({ data: roleDivs });
  }
  //   handlePageChange = (pageNo) => {
  //     this.props.history.push("/location/city" + pageNo);

  //     this.loadCities();
  //   };
  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createRoleDivs(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveRoleDivs(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "delete") {
      res = await deleteRoleDivs(this.state.data);
      this.resetData();
    }
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadRoleDivs();
    } else {
      toast.toastError(res.data.msg);
    }
  }
  resetData() {
    this.setState({
      data: {
        divisionId: "",
        division: "",
        status: "active",
        sort: "",
      },
    });
  }
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  sort = [
    { sortKey: "asc", sort: "ASC" },
    { sortKey: "dsc", sort: "DSC" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <PrevilagesTitle subHeading="RoleDivs" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-6">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Role Divs</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.roleDivs}
                    dropMenu={[]}
                  />
                  {/* <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  /> */}
                </div>
              )}
            </div>
            <div className="col-md-6">
              <form>
                <div className="form-row">
                  {this.rTBH("DivisionId", "divisionId", "col-4")}
                  {this.rTB("Division", "division", "col-4")}

                  {this.rRBL("Status", "status", this.status, this.stsConf)}
                </div>
              </form>
              {this.rRBL("Sort", "sort", this.sort, this.stsConf)}
              {this.state.data.divisionId === "" && this.rBtnSP("Create", "create")}
              {this.state.data.divisionId !== "" && (
                <>
                  {this.rBtnSS("Edit", "edit")}
                  {this.rBtnSD("Delete", "delete")}
                </>
              )}
            </div>
          </div>
          {/* <span>{JSON.stringify(this.state.data)}</span> */}
        </div>
      </React.Fragment>
    );
  }
}

export default RoleDivs;
