export const users = {
  owners: [
    { sortKey: "BLR0000", sort: "No Owner" },
    { sortKey: "BLR0197", sort: "Doreswamy" },
    { sortKey: "INT0006", sort: "Meenakshi" },
    { sortKey: "INT0015", sort: "Manoj" },
    { sortKey: "INT0011", sort: "Mohan" },
    { sortKey: "INT0012", sort: "Nikhil" },
    { sortKey: "INT0013", sort: "Phanindra" },
    { sortKey: "BLR0196", sort: "Prasanna" },
    { sortKey: "BLR0315", sort: "Rakesh" },
    { sortKey: "INT0016", sort: "Sanjay" },
    { sortKey: "BLR0261", sort: "Sateesh" },
    { sortKey: "INT0014", sort: "Tejas" },
  ],
  ownerConf: {
    value: "sortKey",
    label: "sort",
  },
};

export const userList = {
  BLR0000: "No Owner",
  BLR0197: "Doreswamy",
  INT0006: "Meenakshi",
  INT0015: "Manoj",
  INT0011: "Mohan",

  INT0012: "Nikhil",
  INT0013: "Phanindra",

  BLR0196: "Prasanna",
  BLR0315: "Rakesh",
  BLR0261: "Sateesh",
  INT0016: "Sanjay",

  BLR9999: "Not Defined",
  INT0014: "Tejas",
};

export const sprintList = [
  "noSprint",
  "Jan26-Feb01-demo",
  "Jan26-Feb01",
  "Feb02-Feb08",
  "Feb09-Feb15",
  "Feb16-Feb22",
  "Feb23-Feb01",
];

export const sprintListClass = {
  noSprint: "00open",
  "Jan26-Feb01-demo": "25test",
  "Jan26-Feb01": "05assign",
  "Feb02-Feb08": "08plan",
  "Feb09-Feb15": "10wip",
  "Feb16-Feb22": "15hold",
  "Feb23-Mar01": "30done",
  "Feb01-Mar07": "99pipe",
};

export const getUserByCode = (c) => {
  if (userList[c]) {
    return userList[c];
  }

  return userList["BLR9999"];
};

export const getSprintClass = (c) => {
  if (sprintListClass[c]) {
    return sprintListClass[c];
  }

  return sprintListClass["noSprint"];
};
