import React from "react";
import FormLib from "./../../../_common/FormLib";

import Designations from "./Designations";
import PrevilagesTitle from "../../../_subhead/PrevilagesTitle";

import ModalDepartment from "./ModalDepartment";
import ModalDesignation from "./ModalDesignation";
import DesignationsRoles from "./DesignationsRoles";

import toast from "./../../../../services/_toastService";

import { getDepartments } from "./../../../../services/players/departmentsServices";
import { getDesignations } from "../../../../services/players/designationsServices";
import { getTeamTypes } from "../../../../services/players/teamTypeServices";
import { getRoles } from "../../../../services/players/DesignationsRolesServices";

class Departments extends FormLib {
  state = {
    data: {},
    errors: {},
    teamTypes: [],
    departments: [],
    designations: [],
    designationRoles: [],
    curTeamType: {typeId:""},
    curDepartment: {departmentId: ""},
    curDesignation: {designationId: ""},
  };
  schema = {};

  componentDidMount() {
    this.loadDepartments();
    this.loadTeamTypes();
  }

  loadTeamTypes = async () => {
    this.setState({ loading: true });
    const res = await getTeamTypes();
    if (res.data.success) {



      this.setState({ teamTypes: res.data.data.teamTypes });
    } else {
      toast.toastError("failed");
    }
    this.setState({ loading: false });
  }

  loadDepartments = async (d) => {
    const res = await getDepartments();
    if (res.data.success === true) {
      this.setState({ departments: res.data.data.departments });
      toast.toastSuccess("Departments Loaded Successfully");
    } else {
      toast.toastError("failed");
    }
  };

  loadDesignations = async (departmentId, modReset = true) => {
    if (modReset === true) {
      this.setState({ designations: [] });
      this.setState({ curDesignation: { designationId: "" } });
    }

    let curDept;
    const filter = this.state.departments.filter((r) => {
      return r.departmentId === departmentId ? r : null;
    });

    if (!filter[0]) {
      curDept = { departmentId: "" };
    } else {
      curDept = filter[0];
    }

    this.setState({ curDepartment: curDept });

    if (departmentId !== "") {
      const res = await getDesignations(departmentId);
      let data = res.data.data.designations ;
      this.setState({ designations: data });
    } else {
      this.setState({ designations: [] });
    }
  };

  // -------------------------------------
  loadDesignationsRoles = async (r, modReset = true) => {
    if (modReset === true) {
      this.setState({ curDesignation: r });
      this.setState({ curRole: {} });
    }
    if (r["designationId"] !== "") {
      const res = await getRoles(r["designationId"]);
      if (res.data.success) {
        if (res.data.data.designationsRoles !=0) {
          let data = res.data.data.designationsRoles ;
          
          this.setState({ designationRoles: data });
          toast.toastSuccess(res.data.msg);
        }else{
          this.setState({ designationRoles: [] });
          toast.toastInfo("No data available !!!");
        }
      }else{
        toast.toastError(res.data.msg);
      }
    } else {
      this.setState({ designationRoles: [] });
    }
  }
// ----------------------------------------------

  handleDepartmentChange(e) {
    this.loadDesignations(e.target.value);
    this.setState({ designationRoles: [] });
  }

  handleDesignationChange = (d) => {
    this.setState({ curDesignation: d });
    this.loadDesignationsRoles(d);
  };

  handleDesignationUnload = () => {
    this.setState({ designations: [] });
    this.setState({ designationRoles: [] });
  };

  localHandleChange = (e) => {
    if (e.target.id === "departments") {
      this.handleDepartmentChange(e);
    }
  };

  render() {
    return (
      <React.Fragment>
        <PrevilagesTitle subHeading="Departments and Designations" />
        <div className="pageContent">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10">
                {this.rSB(
                  "Departments",
                  "departments",
                  this.state.departments,
                  {
                    value: "departmentId",
                    label: "department",
                  }
                )}
              </div>
              
              <div className="menu col-md-2 ">
                {this.state.curDepartment.departmentId === "" && (
                  <button
                    type="button"
                    className="btn btn-sm btn-info pull-left"
                    data-toggle="modal"
                    data-target="#myDepartmentModel"
                  >
                    <i className={"fa fa-plus"}/>
                  </button>
                )}
                {this.state.curDepartment.departmentId !== "" && (
                  <button
                    type="button"
                    className="btn btn-sm btn-success pull-left in-line"
                    data-toggle="modal"
                    data-target="#myDepartmentModel"
                  >
                    <i className={"fa fa-pencil"}/>
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-sm btn-secondary pull-left"
                  onClick={() => this.loadDepartments()}>
                  <i className={"fa fa-refresh"}/>
                </button>
              </div>
            </div>

            <div className="card-deck">
              <Designations
                designations={this.state.designations}
                curDept={this.state.curDepartment}
                curDesignation={this.state.curDesignation}
                onDesignationChange={this.handleDesignationChange}
                onDesignationUnload={this.handleDesignationUnload}
              />
              <DesignationsRoles
               loadDesignationRoles = {this.state.designationRoles}
               curDesignation={this.state.curDesignation}
               onDesignationChange={this.handleDesignationChange}
               key={this.state.curDesignation["designationId"]}
              />
            </div>
          </div>
        </div>
        <>
          <ModalDepartment
            teamTypes={this.state.teamTypes}
            curTeamType={this.state.curTeamType}
            curDept={this.state.curDepartment}
            onloadDepartments={this.loadDepartments}
            key={
              // this.state.curDepartment["departmentId"]
              "m_1250" +this.state.curDepartment["departmentId"] 
             
          }
          />
          <ModalDesignation
            departments={this.state.departments}
            curDepartment={this.state.curDepartment}
            curDesignation={this.state.curDesignation}
            onLoadDesignations={this.loadDesignations}
            key={
              this.state.curDesignation["designationId"] ||
              "m_" + this.state.curDepartment["departmentId"]
            }
          />
        </>
      </React.Fragment>
    );
  }
}
export default Departments;
