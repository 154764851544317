import React, { Component } from 'react'
import FormLib from '../../../_common/FormLib';
import Joi from 'joi-browser';
import { getBarcodesListAndStatus, getItemsStockListByHubIdAndClientId, getItemsStockListByHubIdAndClientIdForAllClients } from '../../../../services/stockManagement/inventory';
import toast from "../../../../services/_toastService"
import Table from '../../../_common/Table';
import { getActiveClients } from '../../../../services/masterLocations/clientService';
import BarcodesListModal from './BarcodesListModal';

class NewInventory extends FormLib {

    state = {
        data: {
            hubId: "",
            teamId: "",
        },
        itemsList: [],
        clients: [],
        barcodeList: [],

    }

    schema = {
        hubId: Joi.string().required().label("hubId"),
        teamId: Joi.string().required().label("ClientId"),
    };


    stsConfig = {
        value: "sortKey",
        label: "sort",
    };

    componentDidMount() {
        this.loadItems(this.props.hubValue, this.props.teamValue);
        this.loadClients();
    }

    componentDidUpdate(prevProps) {
        if (this.props.teamValue !== prevProps.teamValue || this.props.hubValue !== prevProps.hubValue) {

            this.loadItems(this.props.hubValue, this.props.teamValue);
        }
        if (this.props.teamValue !== prevProps.teamValue) {
            const checkbox = document.querySelector('input[name="checkBox"]');
            if (checkbox && checkbox.checked) {
                checkbox.checked = false;
            }
        }
    }

    async loadItems() {
        const params = {
            hubId: this.props.hubValue,
            clientId: this.props.teamValue,
        }
        // console.log({ params });
        const resp = await getItemsStockListByHubIdAndClientId(params);

        if (resp.data.success === true) {
            toast.toastSuccess(resp.data.msg)
            this.setState({ itemsList: resp.data.data.itemsStockList })
        }
        else {
            toast.toastError(resp.data.msg)
        }
    }

    async loadClients() {
        this.setState({ loading: true });
        const clientResp = await getActiveClients("active");

        if (clientResp.data.success === true) {
            this.setState({ clients: clientResp.data.data.clients });
        } else {
            toast.toastError(clientResp.data.msg);
        }
        this.setState({ loading: false });
    }

    async displayBarcodesList(r) {

        let itemId1 = JSON.stringify(r.itemId)
        let hubId1 = JSON.stringify(r.hubId)
        let itemId = itemId1.replace(/"/g, '')
        let hubId = hubId1.replace(/"/g, '')
        const barcodesList = await getBarcodesListAndStatus(itemId,hubId);

        if (barcodesList.data.success === true && barcodesList.data.data.barcodesList.length === 0) {
            this.setState({ barcodeList: [] })
        }

        else if (barcodesList.data.success === true && barcodesList.data.data.barcodesList.length > 0) {
            this.setState({ barcodeList: barcodesList.data.data.barcodesList })
        }
    }

    handleCheckBoxClick = async (e) => {
        try {
            if (e.target.checked) {
                const clientResp = await getActiveClients("active");
                let data = clientResp.data.data.clients.map((x) => (
                    x.TEAM_ID
                ))
                let params = {
                    hubId: this.props.hubValue,
                    clientId: data
                }
                const resp = await getItemsStockListByHubIdAndClientIdForAllClients(params);

                if (resp.data.success === true) {
                    this.setState({ itemsList: resp.data.data.itemsStockList })
                }
            } else if (!e.target.checked) {
                const params = {
                    hubId: this.props.hubValue,
                    clientId: this.props.teamValue,
                }
                const resp = await getItemsStockListByHubIdAndClientId(params);
                // console.log({resp});

                if (resp.data.success === true) {
                    toast.toastSuccess(resp.data.msg)
                    this.setState({ itemsList: resp.data.data.itemsStockList })
                }
                else {
                    toast.toastError(resp.data.msg)
                }
            }
        } catch {
            console.log("Error in handleCheckBoxClick");
        }
    };

    tableMeta = {
        key: "itemId",
        columns: [
            {
                key: "reatailerName",
                action: (r) => <>{<span>{r?.retailerCode}</span>}</>,
                label: "Client",
                icon: () => "Client",
            },

            {
                key: "item",
                action: (r) => (
                    <>
                        <i
                            data-target="#myBarcodeModalSlNo"
                            data-backdrop="false"
                            data-toggle="modal"
                            aria-hidden="true"
                        >
                            <span
                                // className="badge badge-light link-over "
                                style={{ cursor: "pointer" }}
                                onClick={() => this.displayBarcodesList(r)}
                            >
                                {r.item}
                            </span>
                        </i>
                    </>
                ),
                label: "item",
                icon: () => "item",
            },

            { path: "itemCode", label: "itemCode" },

            { path: "stock", label: "Stock" },
            //   { path: "bays", label: "Bays" },
        ],
    };

    render() {
        // const idArray = this.state.clients.map(item => item.TEAM_ID);
        // console.log({ itemId: this.state.itemId });
        return (
            <React.Fragment>
                <div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div>
                            <h1 className='fs-4'> Inventory</h1>
                        </div>
                        <div style={{ marginTop: "0.5rem", marginLeft: "2rem" }}>
                            <input
                                type="checkbox"
                                name="checkBox"
                                value="checkBox"
                                title="get all clients stockList"
                                onClick={(e) => this.handleCheckBoxClick(e)}
                            />
                            <label style={{ paddingLeft: "0.7rem" }}> <h4>Select All Clients</h4> </label>
                        </div>
                    </div>
                    <div className="pageContent">
                        <div className="container-fluid">

                            <div className="card-deck">
                                {this.state.itemsList && this.state.itemsList.length > 0 && (
                                    <div className="card-body" style={{ padding: 2 }}>
                                        <Table
                                            clsName="table table-striped border table-hover w-50 h-25 mx-5"
                                            tableMeta={this.tableMeta}
                                            data={this.state.itemsList}
                                            dropMenu={[]}
                                        />
                                    </div>
                                )}

                            </div>
                            <BarcodesListModal
                                barcodeData={this.state.barcodeList}
                                key={this.state.barcodeList.slNoId} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default NewInventory;
