import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLPrevilages;
function api(path, subURL = "/players/emps") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function saveEmps(req, data) {
  const param = { ...req };
  param.playerId = data;
  return _http.post(api(""), param, _http.headerJWT());
}

export function getEmps(teamId,pageNo) {
  return _http.get(api(teamId+"/10/"+pageNo), _http.headerJWT());
}

export function getEmpsSearch(data,pageNo) {
  return _http.put(api("searchEmp/2/"+pageNo),data, _http.headerJWT());
}