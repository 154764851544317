import React from "react";
import FormLib from "../../_common/FormLib";
import Table from "../../_common/Table";
import Pagination from "../../_common/Pagination";
import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";
import { getPlayersByTeam } from "../../../services/players/PlayerMobKeyService";
import { getTeams } from "../../../services/players/teamServices";
import { deletePlayer } from "../../../services/players/PlayerMobKeyService";


import swal from "sweetalert";


class PlayerMobKey extends FormLib {
  state = {
    data: {
      playerId: "",
      teamId: "",
      firstname: "",
      lastname: "",
      nickname: "",
      username: "",
      password: "",
      status: "active",
    },
    errors: {},
    teams: [],
    players: [],
    loading: true,
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    curTeam: { teamId: "" },
    curPlayer: { playerId: "" },
  };

  componentDidMount() {
    this.loadTeams();
  }

  loadTeams = async () => {
    this.setState({ loading: true });
    const res = await getTeams();
    if (res.data.success === true) {
      this.setState({ teams: res.data.data.teams });
      // console.log(this.state.teams);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  handlePageChange = (pageNo) => {
    this.props.history.push("/players/playerMobKey/" + pageNo);
    this.loadPlayers(this.state.curTeam.teamId, pageNo);
  };

  loadPlayers = async (teamId, pageNo = 1) => {
    this.setState({ loading: true });
    const res = await getPlayersByTeam(teamId, pageNo);
    if (res.data.success === true) {
      if (res.data.data.length != 0) {
        this.setState({ players: res.data.data.players });
        this.setState({ pageLinks: res.data.data.pageLinks });
        toast.toastSuccess(res.data.msg);
      } else {
        this.setState({ players: [] });
        toast.toastInfo("No data found for selected team");
      }
    } else {
      this.setState({ players: [] });
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  loadCurrectPlayer = async (teamId, modReset = true) => {
    if (modReset === true) {
      this.setState({ players: [] });
      this.setState({ curTeam: { teamId: "" } });
    }

    let currentTeam;
    const filter = this.state.teams.filter((r) => {
      return r.teamId === teamId ? r : null;
    });
    if (!filter[0]) {
      currentTeam = { teamId: "" };
    } else {
      currentTeam = filter[0];
    }

    this.setState({ curTeam: currentTeam });
    if (teamId !== "") {
      this.loadPlayers(teamId, this.state.pageLinks.pageNo);
    } else {
      this.setState({ players: [] });
    }
  };

  doDelete = async (handleDelete, r) => {
    if (handleDelete) {
      await deletePlayer(r.playerId);
      this.loadPlayers(this.state.curTeam.teamId, this.state.pageLinks.pageNo);

      swal("Player has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Player is safe!");
    }
  };

  handleDelete = async (r) => {

    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      // alert(JSON.stringify(r));
      this.doDelete(handleDelete, r);
    });
  };

  handleTeamChange = (e) => {
    this.loadCurrectPlayer(e.target.value);
  };

  localHandleChange = (e) => {
    if (e.target.id === "teamId") {
      this.handleTeamChange(e);
    }
  };

  tableMeta = {
    key: "playerId",
    columns: [
      {
        key: "playerId",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            {r.mobKey && (
              <div className="dropdown-menu dropdown-menu-left">
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => this.handleDelete(r)}
                >
                  <i className="fa fa-trash" aria-hidden="true"></i> Delete
                </button>
              </div>
            )}
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      { path: "mobKey", label: "MobileKey" },
      { path: "username", label: "Username" },
      { path: "firstname", label: "First Name" },
      { path: "lastname", label: "Last Name" },
      { path: "nickname", label: "Nickname" },
      { path: "phone", label: "PhoneNumber" },
      { path: "email", label: "Email Id" },

    ],
  };
  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="PlayersMobKey" />
        <div className="pageContent">
          <div className="row">
            <div className="col-6">
              {this.rSB("Team", "teamId", this.state.teams, {
                value: "teamId",
                label: "name",
              })}
            </div>
          </div>

          {this.state.curTeam.teamId === "" && (
            <div class="jumbotron jumbotron-fluid">
              <p class="lead text-center">Select Team</p>
            </div>
          )}

          {this.state.curTeam.teamId !== "" && this.state.players.length == 0 && (
            <div class="jumbotron jumbotron-fluid">
              <p class="lead text-center">No data found for selected team</p>
            </div>
          )}
          {this.state.curTeam.teamId !== "" && this.state.players.length != 0 && (
            <div className="row">
              <div className="col-md-12">
                {this.state.loading && (
                  <div className="container-fluid">
                    <div>Loading Players</div>
                  </div>
                )}
                {!this.state.loading && (
                  <div className="container-fluid">
                    <Table
                      clsName="table table-striped tabele-bordered table-hover"
                      tableMeta={this.tableMeta}
                      data={this.state.players}
                      dropMenu={[]}
                    />
                    <Pagination
                      pageNo={pageNo}
                      perPage={perPage}
                      itemsCount={itemsCount}
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                )}
              </div>
            </div>

          )}
        </div>
      </React.Fragment>
    );
  }
}
export default PlayerMobKey;
