import React from "react";
import FormLib from "./../../_common/FormLib";
import * as XLSX from "xlsx";
import Joi from "joi-browser";
import { ExportXLSXReqTrans } from "./../requestV1/ExportXLSX";
import {
  getBrandsInfo,
  getMasterData,
  getRetailersInfo,
  uploadExcelTicketData,
  uploadExcelTicketDataBulk,
} from "../../../services/Tickets/TicketServices";
import {
  getProductsInfo,
  getModelsInfo,
} from "./../../../services/Tickets/TicketServices";
import { toast } from "react-toastify";
import { getFlows } from "./../../../services/coreItAdmin/flowsService";
import ZTitle from "./../../_subhead/ZTitle";
import swal from "sweetalert";
import Products from "./../products/Products";

// require('./boo.css');

export default class TicketUpload extends FormLib {
  state = {
    data: { fileUpload: "", flow: "" },
    errors: {},
    excelFile: null,
    excelData: [],
    excelFileError: null,
    failedData: { data: [] },
    csvReport: {
      data: [],
      headers: [],
    },
    JSONData: [],
    exportFailedData: false,
    successData: false,
    processState: [],
    processStateCls: [],
    ticketState: [],
    ticketStateCls: [],
    products: [
      { sortKey: "singleProduct", sort: "Single Product Upload" },
      { sortKey: "multiProducts", sort: "Bulk Products Upload" },
    ],
  };
  ticketScope = [
    { sortKey: "conComplaintNo", sort: "ConComplaintNo." },
    { sortKey: "LRNumber", sort: "LRNo." },
    { sortKey: "invoiceNo", sort: "InvoiceNo." },
    { sortKey: "orderNo", sort: "OrderNo." },
  ];
  schema = {
    fileUpload: Joi.string().required().label("fileUpload"),
    flow: Joi.string().required().label("flow"),
    ticketScope: Joi.optional().label("ticketScope"),
    products: Joi.optional().label("products"),
  };

  excelHeaders = [
    { key: "ticketPriority", label: "ticketPriority" },
    { key: "conComplaintNo", label: "conComplaintNo" },
    { key: "LRNumber", label: "LRNumber" },
    { key: "flowId", label: "flowId" },
    { key: "retailerCode", label: "retailerCode" },
    { key: "retailerId", label: "retailerId" },
    { key: "retailerName", label: "retailerName" },
    { key: "productCode", label: "productCode" },
    { key: "productId", label: "productId" },
    { key: "productName", label: "productName" },
    { key: "brandCode", label: "brandCode" },
    { key: "brandId", label: "brandId" },
    { key: "brandName", label: "brandName" },
    { key: "modelCode", label: "modelCode" },
    { key: "modelId", label: "modelId" },
    { key: "modelName", label: "modelName" },
    { key: "deliveryType", label: "deliveryType" },
    { key: "carrier", label: "carrier" },

    { key: "isAppointmentReq", label: "isAppointmentReq" },
    { key: "isInstallationReq", label: "isInstallationReq" },
    { key: "isPhysicalEval", label: "isPhysicalEval" },
    { key: "isTechEval", label: "isTechEval" },
    { key: "isPackingReq", label: "isPackingReq" },
    { key: "retailerNo", label: "retailerNo" },

    { key: "ticketDetails", label: "ticketDetails" },
    { key: "specialInstructions", label: "specialInstructions" },
    { key: "dateOfPurchase", label: "dateOfPurchase" },
    { key: "identificationNo", label: "identificationNo" },
    { key: "productDesc", label: "productDesc" },
    { key: "problemDesc", label: "problemDesc" },
    { key: "productValue", label: "productValue" },
    { key: "cost", label: "cost" },
    { key: "isUnderWarranty", label: "isUnderWarranty" },
    { key: "srcContactPerson", label: "srcContactPerson" },
    { key: "srcAdd1", label: "srcAdd1" },
    { key: "srcAdd2", label: "srcAdd2" },
    { key: "srcLocation", label: "srcLocation" },
    { key: "srcContact1", label: "srcContact1" },
    { key: "srcContact2", label: "srcContact2" },
    { key: "srcCity", label: "srcCity" },
    { key: "srcState", label: "srcState" },
    { key: "srcPincode", label: "srcPincode" },
    { key: "srcLandmark", label: "srcLandmark" },
    { key: "srcEmailId", label: "srcEmailId" },
    { key: "srcEmailIdAlt", label: "srcEmailIdAlt" },
    { key: "srcType", label: "srcType" },
    { key: "srcLatitude", label: "srcLatitude" },
    { key: "srcLongitude", label: "srcLongitude" },
    { key: "dstContactPerson", label: "dstContactPerson" },
    { key: "dstAdd1", label: "dstAdd1" },
    { key: "dstAdd2", label: "dstAdd2" },
    { key: "dstLocation", label: "dstLocation" },
    { key: "dstContact1", label: "dstContact1" },
    { key: "dstContact2", label: "dstContact2" },
    { key: "dstCity", label: "dstCity" },
    { key: "dstState", label: "dstState" },
    { key: "dstPincode", label: "dstPincode" },
    { key: "dstLandmark", label: "dstLandmark" },
    { key: "dstEmailId", label: "dstEmailId" },
    { key: "dstEmailIdAlt", label: "dstEmailIdAlt" },
    { key: "dstType", label: "dstType" },
    { key: "dstLatitude", label: "dstLatitude" },
    { key: "dstLongitude", label: "dstLongitude" },
  ];

  componentDidMount() {
    this.loadMasterData();
    this.loadFlows();
  }

  async loadMasterData() {
    try {
      let result = await getMasterData();
      if (result === "failure") {
        toast.error("kindly refresh the page again");
      }
    } catch {
      console.log("Error in TicketUpload>>>>>>loadMasterData");
    }
  }

  async loadFlows() {
    try {
      const res = await getFlows();
      if (res !== undefined) {
        if (res.data.success === true) {
          this.setState({ flows: res.data.data.flows });
        } else {
          toast.toastError(res.data.msg);
        }
      }
      this.setState({ loading: false });
    } catch {
      console.log("error in load flows");
    }
  }

  localHandleChange = (e) => {
    try {
      this.setState({ successData: false });
      this.handleValidation(e);
      this.setState({
        // excelFile: null,
        // excelFileError: null,
        // excelData: [],
        processState: [],
        processStateCls: [],
        ticketState: [],
        ticketStateCls: [],
      });

      if (e.target.name === "fileUpload") {
        console.log("change in file upload");
        this.handleFile(e);
      }
    } catch {
      console.log("Error in TicketUpload>>>>>>localHandleChange");
    }
  };

  fileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  handleFile = (e) => {
    try {
      let selectedFile = e.target.files[0];
      if (selectedFile) {
        if (selectedFile && this.fileType.includes(selectedFile.type)) {
          let reader = new FileReader();
          reader.readAsArrayBuffer(selectedFile);
          reader.onload = (e) => {
            this.setState({ excelFileError: null });
            this.setState({ excelFile: e.target.result });
            // console.log(e.target.result);
          };
        } else {
          // setExcelFileError('Please select only excel file types');
          // setExcelFile(null);
          this.setState({
            excelFileError: "Please select only excel file types",
          });
          this.setState({ excelFile: null });
        }
      } else {
        console.log("plz select your file");
      }
    } catch {
      console.log("Error in TicketUpload>>>>>>handleFile");
    }
  };

  handleSubmit = async (e) => {
    try {
      e.preventDefault();

      if (
        (!this.state.data.products || this.state.data.products === undefined) &&
        (this.state.data.flow === "PND1W" || this.state.data.flow === "DRPOF")
      ) {
        swal({
          icon: "error",
          title: "Kindly select the products label ",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null;
      }
      if (this.state.excelFile !== null) {
        const workbook = XLSX.read(this.state.excelFile, { type: "buffer" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);

        const worksheetName1 = workbook.SheetNames[1];
        const worksheet1 = workbook.Sheets[worksheetName1];
        const data1 = XLSX.utils.sheet_to_json(worksheet1);
        this.setState({
          excelData: [],
        });
        this.setState({ exportFailedData: false });
        let tempExcelData = [];
        for (let r in data) {
          let retailerCode = data[r]["retailerCode"];
          let productCode = data[r]["productCode"];
          let productName = data[r]["productName"];

          let brandCode = data[r]["brandCode"];
          let modelCode = data[r]["modelCode"];
          let modelName = data[r]["modelName"];
          let brandName = data[r]["brandName"];

          let retailerInfo = JSON.parse(localStorage.getItem("retailersInfo"));
          let productInfo = JSON.parse(localStorage.getItem("productsInfo"));
          let brandInfo = JSON.parse(localStorage.getItem("brandsInfo"));
          let modelInfo = JSON.parse(localStorage.getItem("modelsInfo"));

          ////logic IN service
          let curRetailer = await getRetailersInfo(retailerInfo, retailerCode);
          let curProduct = await getProductsInfo(productInfo, productCode);
          let curBrand = await getBrandsInfo(brandInfo, brandCode);
          let curModel = await getModelsInfo(modelInfo, modelCode);
          if (
            curRetailer !== "" &&
            curProduct !== "" &&
            curBrand !== "" &&
            curModel !== ""
          ) {
            if (Array.isArray(curRetailer) && curRetailer.length > 0) {
              data[r]["retailerId"] = curRetailer[0].teamId;
              data[r]["retailerName"] = curRetailer[0].name;
            } else {
              data[r]["retailerId"] = "";
              data[r]["retailerCode"] = "";
              data[r]["retailerName"] = "";
            }
            if (retailerCode !== "SPC") {
              if (Array.isArray(curProduct) && curProduct.length > 0) {
                data[r]["productId"] = curProduct[0].productId;
                data[r]["productName"] = curProduct[0].product;
              } else {
                data[r]["productId"] = "";
                data[r]["productCode"] = productCode;
                data[r]["productName"] = productName;
              }
            } else {
              data[r]["productId"] = "";
              data[r]["productCode"] = productCode;
              data[r]["productName"] = productName;
            }

            if (Array.isArray(curBrand) && curBrand.length > 0) {
              data[r]["brandId"] = curBrand[0].prodBrandId;
              data[r]["brandName"] = brandName;
            } else {
              data[r]["brandId"] = brandCode;
              data[r]["brandCode"] = brandCode;
              data[r]["brandName"] = brandName;
            }

            if (Array.isArray(curModel) && curModel.length > 0) {
              data[r]["modelId"] = curModel[0].prodModelId;
              data[r]["modelName"] = modelName;
            } else {
              data[r]["modelId"] = modelCode;
              data[r]["modelCode"] = modelCode;
              data[r]["modelName"] = modelName;
            }

            let productCode2 = data[r]["productCode2"];
            let brandCode2 = data[r]["brandCode2"];
            let modelCode2 = data[r]["modelCode2"];

            let curProduct2 = await getProductsInfo(productInfo, productCode2);
            let curBrand2 = await getBrandsInfo(brandInfo, brandCode2);
            let curModel2 = await getModelsInfo(modelInfo, modelCode2);

            if (retailerCode !== "SPC") {
              if (Array.isArray(curProduct2) && curProduct2.length > 0) {
                data[r]["productId2"] = curProduct2[0].productId;
                data[r]["productName2"] = curProduct2[0].product;
              } else {
                data[r]["productId2"] = "";
                data[r]["productCode2"] = "";
                data[r]["productName2"] = "";
              }
            } else {
              data[r]["productId2"] = "";
              data[r]["productCode2"] = "";
              data[r]["productName2"] = "";
            }

            if (Array.isArray(curBrand2) && curBrand2.length > 0) {
              data[r]["brandId2"] = curBrand2[0].prodBrandId;
              data[r]["brandName2"] = curBrand2[0].brand;
              data[r]["brandCode"] = brandCode;
              data[r]["brandName"] = brandName;
            } else {
              data[r]["brandId2"] = "";
              data[r]["brandCode2"] = "";
              data[r]["brandName2"] = "";
              data[r]["brandCode"] = brandCode;
              data[r]["brandName"] = brandName;
            }

            if (Array.isArray(curModel2) && curModel2.length > 0) {
              data[r]["modelId2"] = curModel2[0].prodModelId;
              data[r]["modelName2"] = curModel2[0].model;
              data[r]["modelCode"] = modelCode;
              data[r]["modelName"] = modelName;
            } else {
              data[r]["modelId2"] = "";
              data[r]["modelCode2"] = "";
              data[r]["modelName2"] = "";
              data[r]["modelCode"] = modelCode;
              data[r]["modelName"] = modelName;
            }
          }
          tempExcelData.push(data[r]);
        }
        this.setState({
          excelData: tempExcelData,
        });
      } else {
        this.setState({ excelData: [] });
        toast.error("Kindly Check the file");
        console.log("no data");
      }
    } catch {
      console.log("Error in excel upload");
    }
  };

  async handleAPIHit() {
    let ticketScope = this.state.data.ticketScope;
    try {
      let processState;
      let processStateCls;
      let ticketState;
      let ticketStateCls;
      let res;
      this.setState({ processState: [] });
      this.setState({ ticketState: [] });

      let i = 0;
      // console.log(JSON.stringify(this.state.excelData));
      // console.log(JSON.stringify(this.state.excelData[0].flowId));
      if (
        this.state.excelData[0].flowId === "BulkQC" ||
        this.state.excelData[0].flowId === "Bulk" ||
        this.state.excelData[0].flowId === "Furniture" ||
        this.state.excelData[0].flowId === "eWaste" ||
        ((this.state.excelData[0].flowId === "DropOff" ||
          this.state.excelData[0].flowId === "PickAndDropOneWay") &&
          this.state.data.products === "multiProducts")
      ) {
        if (!this.state.excelData[0][ticketScope]) {
          swal({
            icon: "error",
            title:
              "Kindly Check data : " +
              this.state.data.ticketScope +
              " should not be empty",
            dangerMode: true,
          }).then((res) => {
            return null;
          });
          return null;
        }
        processState = this.state.processState;
        processStateCls = this.state.processStateCls;
        ticketState = this.state.ticketState;
        ticketStateCls = this.state.ticketStateCls;

        processStateCls[i] = "info";
        processState[i] = "Processing";
        this.setState({ processState });

        ticketStateCls[i] = "info";
        ticketState[i] = "Processing";
        this.setState({ ticketState });

        res = await uploadExcelTicketDataBulk(
          this.state.excelData,
          this.state.data.ticketScope
        );
        // console.log("resulted data " + JSON.stringify(res));
        if (res.data.success === true) {
          this.setState({ successData: true });
          processState[i] = res.data.msg;
          processStateCls[i] = "info";
          ticketState[i] = res.data.data.ticketNo;
          ticketStateCls[i] = "success";
        } else if (res.data.success === false) {
          processState[i] = this.stringReplace(JSON.stringify(res.data.data));
          processStateCls[i] = "danger";
          ticketState[i] = res.data.msg;
          ticketStateCls[i] = "danger";

          let failed = this.state.failedData;
          if (this.state.failedData) {
            this.setState({ exportFailedData: true });
          }
          failed["data"].push(this.state.excelData);
          this.setState({
            failed,
          });

          this.failedExcelData();
        }
        i++;
        this.setState({ processState });
        this.setState({ ticketState });
      } else {
        for (let r in this.state.excelData) {
          if (!this.state.excelData[r][ticketScope]) {
            swal({
              icon: "error",
              title:
                "Kindly Check data : " +
                this.state.data.ticketScope +
                " should not be empty",
              dangerMode: true,
            }).then((res) => {
              return null;
            });
            return null;
          }
          processState = this.state.processState;
          processStateCls = this.state.processStateCls;
          ticketState = this.state.ticketState;
          ticketStateCls = this.state.ticketStateCls;

          processStateCls[i] = "info";
          processState[i] = "Processing";
          this.setState({});

          ticketStateCls[i] = "info";
          ticketState[i] = "Processing";
          this.setState({ ticketState });

          res = await uploadExcelTicketData(
            this.state.excelData[r],
            this.state.data.ticketScope
          );
          // console.log("resulted data " + JSON.stringify(res));
          if (res.data.success === true) {
            // this.setState({ exportFailedData: true });
            this.setState({ successData: true });

            processState[i] = res.data.msg;
            processStateCls[i] = "info";
            ticketState[i] = res.data.data.ticketNo;
            ticketStateCls[i] = "success";
          } else if (res.data.success === false) {
            processState[i] = this.stringReplace(JSON.stringify(res.data.data));
            processStateCls[i] = "danger";
            ticketState[i] = res.data.msg;
            ticketStateCls[i] = "danger";

            let failed = this.state.failedData;
            if (this.state.failedData) {
              this.setState({ exportFailedData: true });
            }
            failed["data"].push(this.state.excelData[r]);

            this.setState({
              failed,
            });

            // console.log("Failed2 " + JSON.stringify(this.state.failedData));

            this.failedExcelData();
          }
          i++;
          this.setState({ processState });
          this.setState({ ticketState });
        }
      }
    } catch {
      console.log("Error in TicketUpload>>>>>>handleAPIHit");
    }
  }
  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  stringReplace(data) {
    if (data) {
      return data
        .replace(/{/g, "")
        .replace(/}/g, "")
        .replace(/]/g, "")
        .replace(/"/g, "")
        .replace("productErrors:[", "")
        .replace("primaryErrors:[", "")
        .replace("productErrors:", "")
        .replace("primaryErrors:", "")
        .replace(/:/g, " : ")
        .replace(/,/g, " , ");
    }
  }

  async failedExcelData() {
    try {
      ///Excel
      let apiData = {};
      const { csvReport } = { ...this.state };
      apiData = this.state.failedData;

      // console.log("EXCELLLLLLL " + JSON.stringify(apiData));

      csvReport.filename = "financial_report.xlsx";
      csvReport.headers = this.excelHeaders;

      let transData = [];
      transData = apiData;
      this.setState({ csvReport });

      if (apiData["data"]) {
        const promises = apiData["data"];

        transData = await Promise.all(promises);
        // transData = promises;
        csvReport.data = transData;
        this.setState({ csvReport });
      } else {
        csvReport.data = [];
        this.setState({ csvReport });
      }
      ///Excel
    } catch {
      console.log("catch while excel upload");
    }
  }

  reSetFormData() {
    try {
      let data = {
        fileUpload: "",
        flow: "",
      };
      this.setState({ data });
      this.setState({ excelData: [] });
      this.setState({ exportFailedData: false });
      this.setState({ successData: false });
    } catch {
      console.log("Error in TicketUpload>>>>>>reSetFormData");
    }
  }

  render() {
    return (
      <>
        <ZTitle subHeading="Ticket Upload" />

        <div className="card">
          <div className="card-body">
            <form id="myform" onSubmit={this.handleSubmit}>
              <div className="form-row">
                {this.rSB(
                  "Flow *",
                  "flow",
                  this.state.flows,
                  { value: "flowCode", label: "flowName" },
                  " col-3 "
                )}
                {(this.state.data.flow === "PND1W" ||
                  this.state.data.flow === "DRPOF") && (
                  <>
                    {this.rSB(
                      "Select Products label *",
                      "products",
                      this.state.products,
                      { value: "sortKey", label: "sort" },
                      " col-3 "
                    )}
                  </>
                )}
                {this.rFU(
                  "Upload File",
                  "fileUpload",
                  "col-4 btn btn-primary btn-sm"
                )}
                <div className="col-4">
                  {this.rBtnS("Upload", "upload", "col-2")}
                </div>
              </div>
            </form>
            <div className="col-3 pull-right">
              <button
                className="btn  btn-info  btn-sm pull-right"
                onClick={() => this.reSetFormData()}
              >
                Reset
              </button>
            </div>
          </div>

          <div className="viewer">
            {this.state.excelData.length > 0 && (
              <>
                {this.state.exportFailedData === false &&
                  this.state.successData === false && (
                    <>
                      {this.rRBL(
                        "Ticket Scope :",
                        "ticketScope",
                        this.ticketScope,
                        {
                          value: "sortKey",
                          label: "sort",
                        }
                      )}
                    </>
                  )}
                {this.state.data.flow === "PND1W" &&
                  this.state.excelData[0].flowId === "PickAndDropOneWay" &&
                  this.state.exportFailedData === false &&
                  this.state.successData === false &&
                  this.state.data.ticketScope && (
                    <button
                      className="btn  btn-info  btn-sm"
                      onClick={() => this.handleAPIHit()}
                    >
                      Submit
                    </button>
                  )}
                {this.state.data.flow === "PND2W" &&
                  this.state.excelData[0].flowId === "PickAndDropTwoWay" &&
                  this.state.exportFailedData === false &&
                  this.state.successData === false &&
                  this.state.data.ticketScope && (
                    <button
                      className="btn  btn-info  btn-sm"
                      onClick={() => this.handleAPIHit()}
                    >
                      Submit
                    </button>
                  )}
                {this.state.data.flow === "ADV" &&
                  this.state.excelData[0].flowId === "AdvanceExchange" &&
                  this.state.exportFailedData === false &&
                  this.state.successData === false &&
                  this.state.data.ticketScope && (
                    <button
                      className="btn  btn-info  btn-sm"
                      onClick={() => this.handleAPIHit()}
                    >
                      Submit
                    </button>
                  )}
                {this.state.data.flow === "BUYBACK" &&
                  this.state.excelData[0].flowId === "BuyBack" &&
                  this.state.exportFailedData === false &&
                  this.state.successData === false &&
                  this.state.data.ticketScope && (
                    <button
                      className="btn  btn-info  btn-sm"
                      onClick={() => this.handleAPIHit()}
                    >
                      Submit
                    </button>
                  )}
                {this.state.data.flow === "INSV1" &&
                  this.state.excelData[0].flowId === "InstallationV1" &&
                  this.state.exportFailedData === false &&
                  this.state.successData === false &&
                  this.state.data.ticketScope && (
                    <button
                      className="btn  btn-info  btn-sm"
                      onClick={() => this.handleAPIHit()}
                    >
                      Submit
                    </button>
                  )}
                {this.state.data.flow === "DRPOF" &&
                  this.state.excelData[0].flowId === "DropOff" &&
                  this.state.exportFailedData === false &&
                  this.state.successData === false &&
                  this.state.data.ticketScope && (
                    <button
                      className="btn  btn-info  btn-sm"
                      onClick={() => this.handleAPIHit()}
                    >
                      Submit
                    </button>
                  )}
                {/* BulkQC */}
                {this.state.data.flow === "BULKQC" &&
                  this.state.excelData[0].flowId === "BulkQC" &&
                  this.state.exportFailedData === false &&
                  this.state.successData === false &&
                  this.state.data.ticketScope && (
                    <button
                      className="btn  btn-info  btn-sm"
                      onClick={() => this.handleAPIHit()}
                    >
                      Submit
                    </button>
                  )}
                {this.state.data.flow === "BLK" &&
                  this.state.excelData[0].flowId === "Bulk" &&
                  this.state.exportFailedData === false &&
                  this.state.successData === false &&
                  this.state.data.ticketScope && (
                    <button
                      className="btn  btn-info  btn-sm"
                      onClick={() => this.handleAPIHit()}
                    >
                      Submit
                    </button>
                  )}
                {this.state.data.flow === "EWASTE" &&
                  this.state.excelData[0].flowId === "eWaste" &&
                  this.state.exportFailedData === false &&
                  this.state.data.ticketScope && (
                    <button
                      className="btn  btn-info  btn-sm"
                      onClick={() => this.handleAPIHit()}
                    >
                      Submit
                    </button>
                  )}
                {this.state.data.flow === "FURN" &&
                  this.state.excelData[0].flowId === "Furniture" &&
                  this.state.exportFailedData === false &&
                  this.state.successData === false &&
                  this.state.data.ticketScope && (
                    <button
                      className="btn  btn-info  btn-sm"
                      onClick={() => this.handleAPIHit()}
                    >
                      Submit
                    </button>
                  )}
                {this.state.data.flow === "PND1W" &&
                  this.state.excelData[0].flowId === "PickAndDropOneWay" && (
                    <>
                      <div className="table-responsive">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Ticket No.</th>
                              <th>Ticket Priority</th>
                              <th>Consumer Complaint Number</th>
                              <th>LR Number</th>
                              <th>invoiceNo</th>
                              <th>orderNo</th>
                              <th>Flow Id</th>
                              <th>Retailer Code</th>
                              {/* <th>Retailer Id</th> */}
                              <th>Retailer Name</th>
                              <th>Product Code</th>
                              {/* <th>Product Id</th> */}
                              <th>Product Name</th>
                              <th>Brand Code</th>
                              {/* <th>Brand Id</th> */}
                              <th>Brand Name</th>
                              <th>Model Code</th>
                              {/* <th>Model Id</th> */}
                              <th>Model Name </th>
                              <th>Delivery Type</th>
                              <th>Carrier</th>
                              <th>isAppointment Required</th>
                              <th>isInstallation Required</th>
                              <th>isPhysical Evaluation Required</th>
                              <th>isTechnical Evaluation Required</th>
                              <th>isPacking Required</th>
                              <th>retailerNo</th>

                              <th>ticketDetails</th>
                              <th>specialInstructions</th>
                              <th>dateOfPurchase</th>
                              <th>identificationNo</th>
                              <th>productDesc</th>
                              <th>problemDesc</th>
                              <th>productValue</th>
                              <th>cost</th>
                              <th>isUnderWarranty</th>
                              <th>productDimensionUnit</th>
                              <th>productLength</th>
                              <th>productBreadth</th>
                              <th>productHeight</th>
                              <th>productWeightUnit</th>
                              <th>productActualWeight</th>
                              <th>productVolumeWeight</th>
                              <th>boxName</th>
                              <th>boxSlNo</th>
                              <th>srcContactPerson</th>
                              <th>srcAdd1</th>
                              <th>srcAdd2</th>
                              <th>srcLocation</th>
                              <th>srcContact1</th>
                              <th>srcContact2</th>
                              <th>srcCity</th>
                              <th>srcState</th>
                              <th>srcPincode</th>
                              <th>srcLandmark</th>
                              <th>srcEmailId</th>
                              <th>srcEmailIdAlt</th>
                              <th>srcType</th>
                              <th>srcOrg</th>
                              <th>srcLatitude</th>
                              <th>srcLongitude</th>
                              <th>dstContactPerson</th>
                              <th>dstAdd1</th>
                              <th>dstAdd2</th>
                              <th>dstLocation</th>
                              <th>dstContact1</th>
                              <th>dstContact2</th>
                              <th>dstCity</th>
                              <th>dstState</th>
                              <th>dstPincode</th>
                              <th>dstLandmark</th>
                              <th>dstEmailId</th>
                              <th>dstEmailIdAlt</th>
                              <th>dstType</th>
                              <th>dstOrg</th>
                              <th>dstLatitude</th>
                              <th>dstLongitude</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.excelData.map(
                              (individualExcelData, i) => (
                                <tr key={individualExcelData.conComplaintNo}>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.processStateCls[i]
                                      }
                                    >
                                      {this.state.processState[i]}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.ticketStateCls[i]
                                      }
                                    >
                                      {this.state.ticketState[i]}
                                    </span>
                                  </td>
                                  <td>{individualExcelData.ticketPriority}</td>
                                  <td>{individualExcelData.conComplaintNo}</td>
                                  <td>{individualExcelData.LRNumber}</td>
                                  <td>{individualExcelData.invoiceNo}</td>
                                  <td>{individualExcelData.orderNo}</td>
                                  <td>{individualExcelData.flowId}</td>
                                  <td>{individualExcelData.retailerCode}</td>
                                  {/* <td>{individualExcelData.retailerId}</td> */}
                                  <td>{individualExcelData.retailerName}</td>
                                  <td>{individualExcelData.productCode}</td>
                                  {/* <td>{individualExcelData.productId}</td> */}
                                  <td>{individualExcelData.productName}</td>
                                  <td>{individualExcelData.brandCode}</td>
                                  {/* <td>{individualExcelData.brandId}</td> */}
                                  <td>{individualExcelData.brandName}</td>
                                  <td>{individualExcelData.modelCode}</td>
                                  {/* <td>{individualExcelData.modelId}</td> */}
                                  <td>{individualExcelData.modelName}</td>
                                  <td>{individualExcelData.deliveryType}</td>
                                  <td>{individualExcelData.carrier}</td>
                                  <td>
                                    {individualExcelData.isAppointmentReq}
                                  </td>
                                  <td>
                                    {individualExcelData.isInstallationReq}
                                  </td>
                                  <td>{individualExcelData.isPhysicalEval}</td>
                                  <td>{individualExcelData.isTechEval}</td>
                                  <td>{individualExcelData.isPackingReq}</td>
                                  <td>{individualExcelData.retailerNo}</td>

                                  <td>{individualExcelData.ticketDetails}</td>
                                  <td>
                                    {individualExcelData.specialInstructions}
                                  </td>
                                  <td>{individualExcelData.dateOfPurchase}</td>
                                  <td>
                                    {individualExcelData.identificationNo}
                                  </td>
                                  <td>{individualExcelData.productDesc}</td>
                                  <td>{individualExcelData.problemDesc}</td>
                                  <td>{individualExcelData.productValue}</td>
                                  <td>{individualExcelData.cost}</td>
                                  <td>{individualExcelData.isUnderWarranty}</td>
                                  <td>
                                    {individualExcelData.productDimensionUnit}
                                  </td>
                                  <td>{individualExcelData.productLength}</td>
                                  <td>{individualExcelData.productBreadth}</td>
                                  <td>{individualExcelData.productHeight}</td>
                                  <td>
                                    {individualExcelData.productWeightUnit}
                                  </td>
                                  <td>
                                    {individualExcelData.productActualWeight}
                                  </td>
                                  <td>
                                    {individualExcelData.productVolumeWeight}
                                  </td>
                                  <td>{individualExcelData.boxName}</td>
                                  <td>{individualExcelData.boxSlNo}</td>
                                  <td>
                                    {individualExcelData.srcContactPerson}
                                  </td>
                                  <td>{individualExcelData.srcAdd1}</td>
                                  <td>{individualExcelData.srcAdd2}</td>
                                  <td>{individualExcelData.srcLocation}</td>
                                  <td>{individualExcelData.srcContact1}</td>
                                  <td>{individualExcelData.srcContact2}</td>
                                  <td>{individualExcelData.srcCity}</td>
                                  <td>{individualExcelData.srcState}</td>
                                  <td>{individualExcelData.srcPincode}</td>
                                  <td>{individualExcelData.srcLandmark}</td>
                                  <td>{individualExcelData.srcEmailId}</td>
                                  <td>{individualExcelData.srcEmailIdAlt}</td>
                                  <td>{individualExcelData.srcType}</td>
                                  <td>{individualExcelData.srcOrg}</td>
                                  <td>{individualExcelData.srcLatitude}</td>
                                  <td>{individualExcelData.srcLongitude}</td>

                                  <td>
                                    {individualExcelData.dstContactPerson}
                                  </td>
                                  <td>{individualExcelData.dstAdd1}</td>
                                  <td>{individualExcelData.dstAdd2}</td>
                                  <td>{individualExcelData.dstLocation}</td>
                                  <td>{individualExcelData.dstContact1}</td>
                                  <td>{individualExcelData.dstContact2}</td>
                                  <td>{individualExcelData.dstCity}</td>
                                  <td>{individualExcelData.dstState}</td>
                                  <td>{individualExcelData.dstPincode}</td>
                                  <td>{individualExcelData.dstLandmark}</td>
                                  <td>{individualExcelData.dstEmailId}</td>
                                  <td>{individualExcelData.dstEmailIdAlt}</td>
                                  <td>{individualExcelData.dstType}</td>
                                  <td>{individualExcelData.dstOrg}</td>
                                  <td>{individualExcelData.dstLatitude}</td>
                                  <td>{individualExcelData.dstLongitude}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                {this.state.data.flow === "PND2W" &&
                  this.state.excelData[0].flowId === "PickAndDropTwoWay" && (
                    <>
                      <div className="table-responsive">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Ticket No.</th>
                              <th>Ticket Priority</th>
                              <th>Consumer Complaint Number</th>
                              <th>LR Number</th>
                              <th>invoiceNo</th>
                              <th>orderNo</th>
                              <th>Flow Id</th>
                              <th>Retailer Code</th>
                              {/* <th>Retailer Id</th> */}
                              <th>Retailer Name</th>
                              <th>Product Code</th>
                              {/* <th>Product Id</th> */}
                              <th>Product Name</th>
                              <th>Brand Code</th>
                              {/* <th>Brand Id</th> */}
                              <th>Brand Name</th>
                              <th>Model Code</th>
                              {/* <th>Model Id</th> */}
                              <th>Model Name</th>
                              <th>Delivery Type</th>
                              <th>Carrier</th>
                              <th>isAppointment Required</th>
                              <th>isInstallation Required</th>
                              <th>isPhysical Evaluation Required</th>
                              <th>isTechnical Evaluation Required</th>
                              <th>isPacking Required</th>
                              <th>retailerNo</th>

                              <th>ticketDetails</th>
                              <th>specialInstructions</th>
                              <th>dateOfPurchase</th>
                              <th>identificationNo</th>
                              <th>productDesc</th>
                              <th>problemDesc</th>
                              <th>productValue</th>
                              <th>cost</th>
                              <th>isUnderWarranty</th>
                              <th>productDimensionUnit</th>
                              <th>productLength</th>
                              <th>productBreadth</th>
                              <th>productHeight</th>
                              <th>productWeightUnit</th>
                              <th>productActualWeight</th>
                              <th>productVolumeWeight</th>
                              <th>boxName</th>
                              <th>boxSlNo</th>
                              <th>srcContactPerson</th>
                              <th>srcAdd1</th>
                              <th>srcAdd2</th>
                              <th>srcLocation</th>
                              <th>srcContact1</th>
                              <th>srcContact2</th>
                              <th>srcCity</th>
                              <th>srcState</th>
                              <th>srcPincode</th>
                              <th>srcLandmark</th>
                              <th>srcEmailId</th>
                              <th>srcEmailIdAlt</th>
                              <th>srcType</th>
                              <th>srcLatitude</th>
                              <th>srcLongitude</th>
                              <th>dstContactPerson</th>
                              <th>dstAdd1</th>
                              <th>dstAdd2</th>
                              <th>dstLocation</th>
                              <th>dstContact1</th>
                              <th>dstContact2</th>
                              <th>dstCity</th>
                              <th>dstState</th>
                              <th>dstPincode</th>
                              <th>dstLandmark</th>
                              <th>dstEmailId</th>
                              <th>dstEmailIdAlt</th>
                              <th>dstType</th>
                              <th>dstLatitude</th>
                              <th>dstLongitude</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.excelData.map(
                              (individualExcelData, i) => (
                                <tr key={individualExcelData.conComplaintNo}>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.processStateCls[i]
                                      }
                                    >
                                      {this.state.processState[i]}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.ticketStateCls[i]
                                      }
                                    >
                                      {this.state.ticketState[i]}
                                    </span>
                                  </td>
                                  <td>{individualExcelData.ticketPriority}</td>
                                  <td>{individualExcelData.conComplaintNo}</td>
                                  <td>{individualExcelData.LRNumber}</td>
                                  <td>{individualExcelData.invoiceNo}</td>
                                  <td>{individualExcelData.orderNo}</td>
                                  <td>{individualExcelData.flowId}</td>
                                  <td>{individualExcelData.retailerCode}</td>
                                  {/* <td>{individualExcelData.retailerId}</td> */}
                                  <td>{individualExcelData.retailerName}</td>
                                  <td>{individualExcelData.productCode}</td>
                                  {/* <td>{individualExcelData.productId}</td> */}
                                  <td>{individualExcelData.productName}</td>
                                  <td>{individualExcelData.brandCode}</td>
                                  {/* <td>{individualExcelData.brandId}</td> */}
                                  <td>{individualExcelData.brandName}</td>
                                  <td>{individualExcelData.modelCode}</td>
                                  {/* <td>{individualExcelData.modelId}</td> */}
                                  <td>{individualExcelData.modelName}</td>
                                  <td>{individualExcelData.deliveryType}</td>
                                  <td>{individualExcelData.carrier}</td>
                                  <td>
                                    {individualExcelData.isAppointmentReq}
                                  </td>
                                  <td>
                                    {individualExcelData.isInstallationReq}
                                  </td>
                                  <td>{individualExcelData.isPhysicalEval}</td>
                                  <td>{individualExcelData.isTechEval}</td>
                                  <td>{individualExcelData.isPackingReq}</td>
                                  <td>{individualExcelData.retailerNo}</td>
                                  <td>{individualExcelData.ticketDetails}</td>
                                  <td>
                                    {individualExcelData.specialInstructions}
                                  </td>
                                  <td>{individualExcelData.dateOfPurchase}</td>
                                  <td>
                                    {individualExcelData.identificationNo}
                                  </td>
                                  <td>{individualExcelData.productDesc}</td>
                                  <td>{individualExcelData.problemDesc}</td>
                                  <td>{individualExcelData.productValue}</td>
                                  <td>{individualExcelData.cost}</td>
                                  <td>{individualExcelData.isUnderWarranty}</td>
                                  <td>
                                    {individualExcelData.productDimensionUnit}
                                  </td>
                                  <td>{individualExcelData.productLength}</td>
                                  <td>{individualExcelData.productBreadth}</td>
                                  <td>{individualExcelData.productHeight}</td>
                                  <td>
                                    {individualExcelData.productWeightUnit}
                                  </td>
                                  <td>
                                    {individualExcelData.productActualWeight}
                                  </td>
                                  <td>
                                    {individualExcelData.productVolumeWeight}
                                  </td>
                                  <td>{individualExcelData.boxName}</td>
                                  <td>{individualExcelData.boxSlNo}</td>
                                  <td>
                                    {individualExcelData.srcContactPerson}
                                  </td>
                                  <td>{individualExcelData.srcAdd1}</td>
                                  <td>{individualExcelData.srcAdd2}</td>
                                  <td>{individualExcelData.srcLocation}</td>
                                  <td>{individualExcelData.srcContact1}</td>
                                  <td>{individualExcelData.srcContact2}</td>
                                  <td>{individualExcelData.srcCity}</td>
                                  <td>{individualExcelData.srcState}</td>
                                  <td>{individualExcelData.srcPincode}</td>
                                  <td>{individualExcelData.srcLandmark}</td>
                                  <td>{individualExcelData.srcEmailId}</td>
                                  <td>{individualExcelData.srcEmailIdAlt}</td>
                                  <td>{individualExcelData.srcType}</td>
                                  <td>{individualExcelData.srcLatitude}</td>
                                  <td>{individualExcelData.srcLongitude}</td>

                                  <td>
                                    {individualExcelData.dstContactPerson}
                                  </td>
                                  <td>{individualExcelData.dstAdd1}</td>
                                  <td>{individualExcelData.dstAdd2}</td>
                                  <td>{individualExcelData.dstLocation}</td>
                                  <td>{individualExcelData.dstContact1}</td>
                                  <td>{individualExcelData.dstContact2}</td>
                                  <td>{individualExcelData.dstCity}</td>
                                  <td>{individualExcelData.dstState}</td>
                                  <td>{individualExcelData.dstPincode}</td>
                                  <td>{individualExcelData.dstLandmark}</td>
                                  <td>{individualExcelData.dstEmailId}</td>
                                  <td>{individualExcelData.dstEmailIdAlt}</td>
                                  <td>{individualExcelData.dstType}</td>
                                  <td>{individualExcelData.dstLatitude}</td>
                                  <td>{individualExcelData.dstLongitude}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                {this.state.data.flow === "ADV" &&
                  this.state.excelData[0].flowId === "AdvanceExchange" && (
                    <>
                      <div className="table-responsive">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Ticket No.</th>
                              <th>Ticket Priority</th>
                              <th>Consumer Complaint Number</th>
                              <th>LR Number</th>
                              <th>invoiceNo</th>
                              <th>orderNo</th>
                              <th>Flow Id</th>
                              <th>Retailer Code</th>
                              {/* <th>Retailer Id</th> */}
                              <th>Retailer Name</th>
                              <th>Product Code</th>
                              {/* <th>Product Id</th> */}
                              <th>Product Name</th>
                              <th>Brand Code</th>
                              {/* <th>Brand Id</th> */}
                              <th>Brand Name</th>
                              <th>Model Code</th>
                              {/* <th>Model Id</th> */}
                              <th>Model Name</th>
                              <th>Delivery Type</th>
                              <th>Carrier</th>
                              <th>isAppointment Required</th>
                              <th>isInstallation Required</th>
                              <th>isPhysical Evaluation Required</th>
                              <th>isTechnical Evaluation Required</th>
                              <th>isPacking Required</th>
                              <th>isBasicEval Required</th>
                              <th>retailerNo</th>

                              <th>ticketDetails</th>
                              <th>specialInstructions</th>
                              <th>dateOfPurchase</th>
                              <th>identificationNo</th>
                              <th>productDesc</th>
                              <th>problemDesc</th>
                              <th>productValue</th>
                              <th>cost</th>
                              <th>isUnderWarranty</th>
                              <th>isDoa</th>
                              <th>Accessories</th>
                              <th>srcContactPerson</th>
                              <th>srcAdd1</th>
                              <th>srcAdd2</th>
                              <th>srcLocation</th>
                              <th>srcContact1</th>
                              <th>srcContact2</th>
                              <th>srcCity</th>
                              <th>srcState</th>
                              <th>srcPincode</th>
                              <th>srcLandmark</th>
                              <th>srcEmailId</th>
                              <th>srcEmailIdAlt</th>
                              <th>srcType</th>
                              <th>pickupSellerCode</th>
                              <th>productId2</th>
                              <th>productCode2</th>
                              <th>productName2</th>
                              <th>brandId2</th>
                              <th>brandCode2</th>
                              <th>brandName2</th>
                              <th>modelId2</th>
                              <th>modelCode2</th>
                              <th>modelName2</th>
                              <th>returnSellerCode</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.excelData.map(
                              (individualExcelData, i) => (
                                <tr key={individualExcelData.conComplaintNo}>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.processStateCls[i]
                                      }
                                    >
                                      {this.state.processState[i]}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.ticketStateCls[i]
                                      }
                                    >
                                      {this.state.ticketState[i]}
                                    </span>
                                  </td>
                                  <td>{individualExcelData.ticketPriority}</td>
                                  <td>{individualExcelData.conComplaintNo}</td>
                                  <td>{individualExcelData.LRNumber}</td>
                                  <td>{individualExcelData.invoiceNo}</td>
                                  <td>{individualExcelData.orderNo}</td>
                                  <td>{individualExcelData.flowId}</td>
                                  <td>{individualExcelData.retailerCode}</td>
                                  {/* <td>{individualExcelData.retailerId}</td> */}
                                  <td>{individualExcelData.retailerName}</td>
                                  <td>{individualExcelData.productCode}</td>
                                  {/* <td>{individualExcelData.productId}</td> */}
                                  <td>{individualExcelData.productName}</td>
                                  <td>{individualExcelData.brandCode}</td>
                                  {/* <td>{individualExcelData.brandId}</td> */}
                                  <td>{individualExcelData.brandName}</td>
                                  <td>{individualExcelData.modelCode}</td>
                                  {/* <td>{individualExcelData.modelId}</td> */}
                                  <td>{individualExcelData.modelName}</td>
                                  <td>{individualExcelData.deliveryType}</td>
                                  <td>{individualExcelData.carrier}</td>
                                  <td>
                                    {individualExcelData.isAppointmentReq}
                                  </td>
                                  <td>
                                    {individualExcelData.isInstallationReq}
                                  </td>
                                  <td>{individualExcelData.isPhysicalEval}</td>
                                  <td>{individualExcelData.isTechEval}</td>
                                  <td>{individualExcelData.isPackingReq}</td>
                                  <td>{individualExcelData.isBasicEval}</td>
                                  <td>{individualExcelData.retailerNo}</td>

                                  <td>{individualExcelData.ticketDetails}</td>
                                  <td>
                                    {individualExcelData.specialInstructions}
                                  </td>
                                  <td>{individualExcelData.dateOfPurchase}</td>
                                  <td>
                                    {individualExcelData.identificationNo}
                                  </td>
                                  <td>{individualExcelData.productDesc}</td>
                                  <td>{individualExcelData.problemDesc}</td>
                                  <td>{individualExcelData.productValue}</td>
                                  <td>{individualExcelData.cost}</td>
                                  <td>{individualExcelData.isUnderWarranty}</td>
                                  <td>{individualExcelData.isDoa}</td>
                                  <td>{individualExcelData.accessories}</td>
                                  <td>
                                    {individualExcelData.srcContactPerson}
                                  </td>
                                  <td>{individualExcelData.srcAdd1}</td>
                                  <td>{individualExcelData.srcAdd2}</td>
                                  <td>{individualExcelData.srcLocation}</td>
                                  <td>{individualExcelData.srcContact1}</td>
                                  <td>{individualExcelData.srcContact2}</td>
                                  <td>{individualExcelData.srcCity}</td>
                                  <td>{individualExcelData.srcState}</td>
                                  <td>{individualExcelData.srcPincode}</td>
                                  <td>{individualExcelData.srcLandmark}</td>
                                  <td>{individualExcelData.srcEmailId}</td>
                                  <td>{individualExcelData.srcEmailIdAlt}</td>
                                  <td>{individualExcelData.srcType}</td>

                                  <td>
                                    {individualExcelData.pickupSellerCode}
                                  </td>
                                  <td>{individualExcelData.productId2}</td>
                                  <td>{individualExcelData.productCode2}</td>
                                  <td>{individualExcelData.productName2}</td>
                                  <td>{individualExcelData.brandId2}</td>
                                  <td>{individualExcelData.brandCode2}</td>
                                  <td>{individualExcelData.brandName2}</td>
                                  <td>{individualExcelData.modelId2}</td>
                                  <td>{individualExcelData.modelCode2}</td>
                                  <td>{individualExcelData.modelName2}</td>
                                  <td>
                                    {individualExcelData.returnSellerCode}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                {this.state.data.flow === "BUYBACK" &&
                  this.state.excelData[0].flowId === "BuyBack" && (
                    <>
                      <div className="table-responsive">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Ticket No.</th>
                              <th>Ticket Priority</th>
                              <th>Consumer Complaint Number</th>
                              <th>LR Number</th>
                              <th>invoiceNo</th>
                              <th>orderNo</th>
                              <th>Flow Id</th>
                              <th>Retailer Code</th>
                              {/* <th>Retailer Id</th> */}
                              <th>Retailer Name</th>
                              <th>Product Code</th>
                              {/* <th>Product Id</th> */}
                              <th>Product Name</th>
                              <th>Brand Code</th>
                              {/* <th>Brand Id</th> */}
                              <th>Brand Name</th>
                              <th>Model Code</th>
                              {/* <th>Model Id</th> */}
                              <th>Model Name</th>
                              <th>Delivery Type</th>
                              <th>Carrier</th>
                              <th>isAppointment Required</th>
                              <th>isPhysical Evaluation Required</th>
                              <th>isTechnical Evaluation Required</th>
                              <th>isPacking Required</th>
                              <th>retailerNo</th>

                              <th>ticketDetails</th>
                              <th>specialInstructions</th>
                              <th>dateOfPurchase</th>
                              <th>identificationNo</th>
                              <th>productDesc</th>
                              <th>problemDesc</th>
                              <th>productValue</th>
                              <th>cost</th>
                              <th>isUnderWarranty</th>
                              <th>productDimensionUnit</th>
                              <th>productLength</th>
                              <th>productBreadth</th>
                              <th>productHeight</th>
                              <th>productWeightUnit</th>
                              <th>productActualWeight</th>
                              <th>productVolumeWeight</th>
                              <th>boxName</th>
                              <th>boxSlNo</th>
                              <th>srcContactPerson</th>
                              <th>srcAdd1</th>
                              <th>srcAdd2</th>
                              <th>srcLocation</th>
                              <th>srcContact1</th>
                              <th>srcContact2</th>
                              <th>srcCity</th>
                              <th>srcState</th>
                              <th>srcPincode</th>
                              <th>srcLandmark</th>
                              <th>srcEmailId</th>
                              <th>srcEmailIdAlt</th>
                              <th>srcType</th>
                              <th>srcLatitude</th>
                              <th>srcLongitude</th>
                              <th>buyerContactNumber</th>
                              <th>paymentMode</th>
                              <th>waitForNotification</th>
                              <th>pickupSellerCode</th>
                              <th>returnSellerCode</th>
                              <th>maxValueToBeOffered</th>
                              <th>estimatedProdValue</th>
                              <th>boxPickUp</th>
                              <th>batteryPickUp</th>
                              <th>headPhonePickUp</th>
                              <th>chargerPickUp</th>
                              <th>othersPickUp</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.excelData.map(
                              (individualExcelData, i) => (
                                <tr key={individualExcelData.conComplaintNo}>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.processStateCls[i]
                                      }
                                    >
                                      {this.state.processState[i]}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.ticketStateCls[i]
                                      }
                                    >
                                      {this.state.ticketState[i]}
                                    </span>
                                  </td>
                                  <td>{individualExcelData.ticketPriority}</td>
                                  <td>{individualExcelData.conComplaintNo}</td>
                                  <td>{individualExcelData.LRNumber}</td>
                                  <td>{individualExcelData.invoiceNo}</td>
                                  <td>{individualExcelData.orderNo}</td>
                                  <td>{individualExcelData.flowId}</td>
                                  <td>{individualExcelData.retailerCode}</td>
                                  {/* <td>{individualExcelData.retailerId}</td> */}
                                  <td>{individualExcelData.retailerName}</td>
                                  <td>{individualExcelData.productCode}</td>
                                  {/* <td>{individualExcelData.productId}</td> */}
                                  <td>{individualExcelData.productName}</td>
                                  <td>{individualExcelData.brandCode}</td>
                                  {/* <td>{individualExcelData.brandId}</td> */}
                                  <td>{individualExcelData.brandName}</td>
                                  <td>{individualExcelData.modelCode}</td>
                                  {/* <td>{individualExcelData.modelId}</td> */}
                                  <td>{individualExcelData.modelName}</td>
                                  <td>{individualExcelData.deliveryType}</td>
                                  <td>{individualExcelData.carrier}</td>
                                  <td>
                                    {individualExcelData.isAppointmentReq}
                                  </td>
                                  <td>{individualExcelData.isPhysicalEval}</td>
                                  <td>{individualExcelData.isTechEval}</td>
                                  <td>{individualExcelData.isPackingReq}</td>
                                  <td>{individualExcelData.retailerNo}</td>

                                  <td>{individualExcelData.ticketDetails}</td>
                                  <td>
                                    {individualExcelData.specialInstructions}
                                  </td>
                                  <td>{individualExcelData.dateOfPurchase}</td>
                                  <td>
                                    {individualExcelData.identificationNo}
                                  </td>
                                  <td>{individualExcelData.productDesc}</td>
                                  <td>{individualExcelData.problemDesc}</td>
                                  <td>{individualExcelData.productValue}</td>
                                  <td>{individualExcelData.cost}</td>
                                  <td>{individualExcelData.isUnderWarranty}</td>
                                  <td>
                                    {individualExcelData.productDimensionUnit}
                                  </td>
                                  <td>{individualExcelData.productLength}</td>
                                  <td>{individualExcelData.productBreadth}</td>
                                  <td>{individualExcelData.productHeight}</td>
                                  <td>
                                    {individualExcelData.productWeightUnit}
                                  </td>
                                  <td>
                                    {individualExcelData.productActualWeight}
                                  </td>
                                  <td>
                                    {individualExcelData.productVolumeWeight}
                                  </td>
                                  <td>{individualExcelData.boxName}</td>
                                  <td>{individualExcelData.boxSlNo}</td>
                                  <td>
                                    {individualExcelData.srcContactPerson}
                                  </td>
                                  <td>{individualExcelData.srcAdd1}</td>
                                  <td>{individualExcelData.srcAdd2}</td>
                                  <td>{individualExcelData.srcLocation}</td>
                                  <td>{individualExcelData.srcContact1}</td>
                                  <td>{individualExcelData.srcContact2}</td>
                                  <td>{individualExcelData.srcCity}</td>
                                  <td>{individualExcelData.srcState}</td>
                                  <td>{individualExcelData.srcPincode}</td>
                                  <td>{individualExcelData.srcLandmark}</td>
                                  <td>{individualExcelData.srcEmailId}</td>
                                  <td>{individualExcelData.srcEmailIdAlt}</td>
                                  <td>{individualExcelData.srcType}</td>
                                  <td>{individualExcelData.srcLatitude}</td>
                                  <td>{individualExcelData.srcLongitude}</td>

                                  <td>
                                    {individualExcelData.buyerContactNumber}
                                  </td>
                                  <td>{individualExcelData.paymentMode}</td>
                                  <td>
                                    {individualExcelData.waitForNotification}
                                  </td>
                                  <td>
                                    {individualExcelData.pickupSellerCode}
                                  </td>
                                  <td>
                                    {individualExcelData.returnSellerCode}
                                  </td>
                                  <td>
                                    {individualExcelData.maxValueToBeOffered}
                                  </td>
                                  <td>
                                    {individualExcelData.estimatedProdValue}
                                  </td>
                                  <td>{individualExcelData.boxPickUp}</td>
                                  <td>{individualExcelData.batteryPickUp}</td>
                                  <td>{individualExcelData.headPhonePickUp}</td>
                                  <td>{individualExcelData.chargerPickUp}</td>
                                  <td>{individualExcelData.othersPickUp}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                {this.state.data.flow === "INSV1" &&
                  this.state.excelData[0].flowId === "InstallationV1" && (
                    <>
                      <div className="table-responsive">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Ticket No.</th>
                              <th>Ticket Priority</th>
                              <th>Consumer Complaint Number</th>
                              <th>LR Number</th>
                              <th>invoiceNo</th>
                              <th>orderNo</th>
                              <th>Flow Id</th>
                              <th>Retailer Code</th>
                              {/* <th>Retailer Id</th> */}
                              <th>Retailer Name</th>
                              <th>Product Code</th>
                              {/* <th>Product Id</th> */}
                              <th>Product Name</th>
                              <th>Brand Code</th>
                              {/* <th>Brand Id</th> */}
                              <th>Brand Name</th>
                              <th>Model Code</th>
                              {/* <th>Model Id</th> */}
                              <th>Model Name</th>
                              <th>Delivery Type</th>
                              <th>Carrier</th>
                              <th>Ticket Type</th>
                              {/* <th>Source GeoCodes</th>
                    <th>Dst GeoCodes</th> */}
                              <th>isAppointment Required</th>
                              <th>isPhysical Evaluation Required</th>
                              <th>isTechnical Evaluation Required</th>
                              <th>isPacking Required</th>
                              <th>retailerNo</th>

                              <th>ticketDetails</th>
                              <th>specialInstructions</th>
                              <th>dateOfPurchase</th>
                              <th>identificationNo</th>
                              <th>productDesc</th>
                              <th>problemDesc</th>
                              <th>productValue</th>
                              <th>cost</th>
                              <th>isUnderWarranty</th>
                              <th>productDimensionUnit</th>
                              <th>productLength</th>
                              <th>productBreadth</th>
                              <th>productHeight</th>
                              <th>productWeightUnit</th>
                              <th>productActualWeight</th>
                              <th>productVolumeWeight</th>
                              <th>boxName</th>
                              <th>boxSlNo</th>
                              <th>srcContactPerson</th>
                              <th>srcAdd1</th>
                              <th>srcAdd2</th>
                              <th>srcLocation</th>
                              <th>srcContact1</th>
                              <th>srcContact2</th>
                              <th>srcCity</th>
                              <th>srcState</th>
                              <th>srcPincode</th>
                              <th>srcLandmark</th>
                              <th>srcEmailId</th>
                              <th>srcEmailIdAlt</th>
                              <th>srcType</th>
                              <th>srcLatitude</th>
                              <th>srcLongitude</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.excelData.map(
                              (individualExcelData, i) => (
                                <tr key={individualExcelData.conComplaintNo}>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.processStateCls[i]
                                      }
                                    >
                                      {this.state.processState[i]}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.ticketStateCls[i]
                                      }
                                    >
                                      {this.state.ticketState[i]}
                                    </span>
                                  </td>
                                  <td>{individualExcelData.ticketPriority}</td>
                                  <td>{individualExcelData.conComplaintNo}</td>
                                  <td>{individualExcelData.LRNumber}</td>
                                  <td>{individualExcelData.invoiceNo}</td>
                                  <td>{individualExcelData.orderNo}</td>
                                  <td>{individualExcelData.flowId}</td>
                                  <td>{individualExcelData.retailerCode}</td>
                                  {/* <td>{individualExcelData.retailerId}</td> */}
                                  <td>{individualExcelData.retailerName}</td>
                                  <td>{individualExcelData.productCode}</td>
                                  {/* <td>{individualExcelData.productId}</td> */}
                                  <td>{individualExcelData.productName}</td>
                                  <td>{individualExcelData.brandCode}</td>
                                  {/* <td>{individualExcelData.brandId}</td> */}
                                  <td>{individualExcelData.brandName}</td>
                                  <td>{individualExcelData.modelCode}</td>
                                  {/* <td>{individualExcelData.modelId}</td> */}
                                  <td>{individualExcelData.modelName}</td>
                                  <td>{individualExcelData.deliveryType}</td>
                                  <td>{individualExcelData.carrier}</td>
                                  <td>{individualExcelData.ticketType}</td>
                                  {/* <td>{individualExcelData.srcGeoCodes}</td>
                      <td>{individualExcelData.dstGeoCodes}</td> */}
                                  <td>
                                    {individualExcelData.isAppointmentReq}
                                  </td>
                                  <td>{individualExcelData.isPhysicalEval}</td>
                                  <td>{individualExcelData.isTechEval}</td>
                                  <td>{individualExcelData.isPackingReq}</td>
                                  <td>{individualExcelData.retailerNo}</td>

                                  <td>{individualExcelData.ticketDetails}</td>
                                  <td>
                                    {individualExcelData.specialInstructions}
                                  </td>
                                  <td>{individualExcelData.dateOfPurchase}</td>
                                  <td>
                                    {individualExcelData.identificationNo}
                                  </td>
                                  <td>{individualExcelData.productDesc}</td>
                                  <td>{individualExcelData.problemDesc}</td>
                                  <td>{individualExcelData.productValue}</td>
                                  <td>{individualExcelData.cost}</td>
                                  <td>{individualExcelData.isUnderWarranty}</td>
                                  <td>
                                    {individualExcelData.productDimensionUnit}
                                  </td>
                                  <td>{individualExcelData.productLength}</td>
                                  <td>{individualExcelData.productBreadth}</td>
                                  <td>{individualExcelData.productHeight}</td>
                                  <td>
                                    {individualExcelData.productWeightUnit}
                                  </td>
                                  <td>
                                    {individualExcelData.productActualWeight}
                                  </td>
                                  <td>
                                    {individualExcelData.productVolumeWeight}
                                  </td>
                                  <td>{individualExcelData.boxName}</td>
                                  <td>{individualExcelData.boxSlNo}</td>
                                  <td>
                                    {individualExcelData.srcContactPerson}
                                  </td>
                                  <td>{individualExcelData.srcAdd1}</td>
                                  <td>{individualExcelData.srcAdd2}</td>
                                  <td>{individualExcelData.srcLocation}</td>
                                  <td>{individualExcelData.srcContact1}</td>
                                  <td>{individualExcelData.srcContact2}</td>
                                  <td>{individualExcelData.srcCity}</td>
                                  <td>{individualExcelData.srcState}</td>
                                  <td>{individualExcelData.srcPincode}</td>
                                  <td>{individualExcelData.srcLandmark}</td>
                                  <td>{individualExcelData.srcEmailId}</td>
                                  <td>{individualExcelData.srcEmailIdAlt}</td>
                                  <td>{individualExcelData.srcType}</td>
                                  <td>{individualExcelData.srcLatitude}</td>
                                  <td>{individualExcelData.srcLongitude}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                {this.state.data.flow === "DRPOF" &&
                  this.state.excelData[0].flowId === "DropOff" && (
                    <>
                      <div className="table-responsive">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Ticket No.</th>
                              <th>Ticket Priority</th>
                              <th>Consumer Complaint Number</th>
                              <th>LR Number</th>
                              <th>invoiceNo</th>
                              <th>orderNo</th>
                              <th>Flow Id</th>
                              <th>Retailer Code</th>
                              {/* <th>Retailer Id</th> */}
                              <th>Retailer Name</th>
                              <th>Product Code</th>
                              {/* <th>Product Id</th> */}
                              <th>Product Name</th>
                              <th>Brand Code</th>
                              {/* <th>Brand Id</th> */}
                              <th>Brand Name</th>
                              <th>Model Code</th>
                              {/* <th>Model Id</th> */}
                              <th>Model Name</th>
                              <th>Delivery Type</th>
                              <th>Carrier</th>
                              <th>isAppointment Required</th>
                              <th>isInstallation Required</th>
                              <th>isPhysical Evaluation Required</th>
                              <th>isTechnical Evaluation Required</th>
                              <th>isPacking Required</th>
                              <th>retailerNo</th>

                              <th>ticketDetails</th>
                              <th>specialInstructions</th>
                              <th>dateOfPurchase</th>
                              <th>identificationNo</th>
                              <th>productDesc</th>
                              <th>problemDesc</th>
                              <th>productValue</th>
                              <th>cost</th>
                              <th>isUnderWarranty</th>
                              <th>productDimensionUnit</th>
                              <th>productLength</th>
                              <th>productBreadth</th>
                              <th>productHeight</th>
                              <th>productWeightUnit</th>
                              <th>productActualWeight</th>
                              <th>productVolumeWeight</th>
                              <th>boxName</th>
                              <th>boxSlNo</th>
                              <th>srcContactPerson</th>
                              <th>srcAdd1</th>
                              <th>srcAdd2</th>
                              <th>srcLocation</th>
                              <th>srcContact1</th>
                              <th>srcContact2</th>
                              <th>srcCity</th>
                              <th>srcState</th>
                              <th>srcPincode</th>
                              <th>srcLandmark</th>
                              <th>srcEmailId</th>
                              <th>srcEmailIdAlt</th>
                              <th>srcType</th>
                              <th>srcLatitude</th>
                              <th>srcLongitude</th>
                              <th>dstContactPerson</th>
                              <th>dstAdd1</th>
                              <th>dstAdd2</th>
                              <th>dstLocation</th>
                              <th>dstContact1</th>
                              <th>dstContact2</th>
                              <th>dstCity</th>
                              <th>dstState</th>
                              <th>dstPincode</th>
                              <th>dstLandmark</th>
                              <th>dstEmailId</th>
                              <th>dstEmailIdAlt</th>
                              <th>dstType</th>
                              <th>dstLatitude</th>
                              <th>dstLongitude</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.excelData.map(
                              (individualExcelData, i) => (
                                <tr key={individualExcelData.conComplaintNo}>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.processStateCls[i]
                                      }
                                    >
                                      {this.state.processState[i]}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.ticketStateCls[i]
                                      }
                                    >
                                      {this.state.ticketState[i]}
                                    </span>
                                  </td>
                                  <td>{individualExcelData.ticketPriority}</td>
                                  <td>{individualExcelData.conComplaintNo}</td>
                                  <td>{individualExcelData.LRNumber}</td>
                                  <td>{individualExcelData.invoiceNo}</td>
                                  <td>{individualExcelData.orderNo}</td>
                                  <td>{individualExcelData.flowId}</td>
                                  <td>{individualExcelData.retailerCode}</td>
                                  {/* <td>{individualExcelData.retailerId}</td> */}
                                  <td>{individualExcelData.retailerName}</td>
                                  <td>{individualExcelData.productCode}</td>
                                  {/* <td>{individualExcelData.productId}</td> */}
                                  <td>{individualExcelData.productName}</td>
                                  <td>{individualExcelData.brandCode}</td>
                                  {/* <td>{individualExcelData.brandId}</td> */}
                                  <td>{individualExcelData.brandName}</td>
                                  <td>{individualExcelData.modelCode}</td>
                                  {/* <td>{individualExcelData.modelId}</td> */}
                                  <td>{individualExcelData.modelName}</td>
                                  <td>{individualExcelData.deliveryType}</td>
                                  <td>{individualExcelData.carrier}</td>
                                  <td>
                                    {individualExcelData.isAppointmentReq}
                                  </td>
                                  <td>
                                    {individualExcelData.isInstallationReq}
                                  </td>
                                  <td>{individualExcelData.isPhysicalEval}</td>
                                  <td>{individualExcelData.isTechEval}</td>
                                  <td>{individualExcelData.isPackingReq}</td>
                                  <td>{individualExcelData.retailerNo}</td>

                                  <td>{individualExcelData.ticketDetails}</td>
                                  <td>
                                    {individualExcelData.specialInstructions}
                                  </td>
                                  <td>{individualExcelData.dateOfPurchase}</td>
                                  <td>
                                    {individualExcelData.identificationNo}
                                  </td>
                                  <td>{individualExcelData.productDesc}</td>
                                  <td>{individualExcelData.problemDesc}</td>
                                  <td>{individualExcelData.productValue}</td>
                                  <td>{individualExcelData.cost}</td>
                                  <td>{individualExcelData.isUnderWarranty}</td>
                                  <td>
                                    {individualExcelData.productDimensionUnit}
                                  </td>
                                  <td>{individualExcelData.productLength}</td>
                                  <td>{individualExcelData.productBreadth}</td>
                                  <td>{individualExcelData.productHeight}</td>
                                  <td>
                                    {individualExcelData.productWeightUnit}
                                  </td>
                                  <td>
                                    {individualExcelData.productActualWeight}
                                  </td>
                                  <td>
                                    {individualExcelData.productVolumeWeight}
                                  </td>
                                  <td>{individualExcelData.boxName}</td>
                                  <td>{individualExcelData.boxSlNo}</td>
                                  <td>
                                    {individualExcelData.srcContactPerson}
                                  </td>
                                  <td>{individualExcelData.srcAdd1}</td>
                                  <td>{individualExcelData.srcAdd2}</td>
                                  <td>{individualExcelData.srcLocation}</td>
                                  <td>{individualExcelData.srcContact1}</td>
                                  <td>{individualExcelData.srcContact2}</td>
                                  <td>{individualExcelData.srcCity}</td>
                                  <td>{individualExcelData.srcState}</td>
                                  <td>{individualExcelData.srcPincode}</td>
                                  <td>{individualExcelData.srcLandmark}</td>
                                  <td>{individualExcelData.srcEmailId}</td>
                                  <td>{individualExcelData.srcEmailIdAlt}</td>
                                  <td>{individualExcelData.srcType}</td>
                                  <td>{individualExcelData.srcLatitude}</td>
                                  <td>{individualExcelData.srcLongitude}</td>

                                  <td>
                                    {individualExcelData.dstContactPerson}
                                  </td>
                                  <td>{individualExcelData.dstAdd1}</td>
                                  <td>{individualExcelData.dstAdd2}</td>
                                  <td>{individualExcelData.dstLocation}</td>
                                  <td>{individualExcelData.dstContact1}</td>
                                  <td>{individualExcelData.dstContact2}</td>
                                  <td>{individualExcelData.dstCity}</td>
                                  <td>{individualExcelData.dstState}</td>
                                  <td>{individualExcelData.dstPincode}</td>
                                  <td>{individualExcelData.dstLandmark}</td>
                                  <td>{individualExcelData.dstEmailId}</td>
                                  <td>{individualExcelData.dstEmailIdAlt}</td>
                                  <td>{individualExcelData.dstType}</td>
                                  <td>{individualExcelData.dstLatitude}</td>
                                  <td>{individualExcelData.dstLongitude}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                {this.state.data.flow === "BULKQC" &&
                  this.state.excelData[0].flowId === "BulkQC" && (
                    <>
                      <div className="table-responsive">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Ticket No.</th>
                              <th>Ticket Priority</th>
                              <th>Consumer Complaint Number</th>
                              <th>LR Number</th>
                              <th>invoiceNo</th>
                              <th>orderNo</th>
                              <th>Flow Id</th>
                              <th>Retailer Code</th>
                              {/* <th>Retailer Id</th> */}
                              <th>Retailer Name</th>
                              <th>Product Code</th>
                              {/* <th>Product Id</th> */}
                              <th>Product Name</th>
                              <th>Brand Code</th>
                              {/* <th>Brand Id</th> */}
                              <th>Brand Name</th>
                              <th>Model Code</th>
                              {/* <th>Model Id</th> */}
                              <th>Model Name</th>
                              <th>Delivery Type</th>
                              <th>Carrier</th>
                              <th>isAppointment Required</th>
                              <th>isInstallation Required</th>
                              <th>isPhysical Evaluation Required</th>
                              <th>isTechnical Evaluation Required</th>
                              <th>isPacking Required</th>
                              <th>retailerNo</th>

                              <th>ticketDetails</th>
                              <th>specialInstructions</th>
                              <th>dateOfPurchase</th>
                              <th>identificationNo</th>
                              <th>productDesc</th>
                              <th>problemDesc</th>
                              <th>productValue</th>
                              <th>cost</th>
                              <th>isUnderWarranty</th>
                              <th>productDimensionUnit</th>
                              <th>productLength</th>
                              <th>productBreadth</th>
                              <th>productHeight</th>
                              <th>productWeightUnit</th>
                              <th>productActualWeight</th>
                              <th>productVolumeWeight</th>
                              <th>boxName</th>
                              <th>boxSlNo</th>
                              <th>srcContactPerson</th>
                              <th>srcAdd1</th>
                              <th>srcAdd2</th>
                              <th>srcLocation</th>
                              <th>srcContact1</th>
                              <th>srcContact2</th>
                              <th>srcCity</th>
                              <th>srcState</th>
                              <th>srcPincode</th>
                              <th>srcLandmark</th>
                              <th>srcEmailId</th>
                              <th>srcEmailIdAlt</th>
                              <th>srcType</th>
                              <th>srcLatitude</th>
                              <th>srcLongitude</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.excelData.map(
                              (individualExcelData, i) => (
                                <tr key={individualExcelData.conComplaintNo}>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.processStateCls[i]
                                      }
                                    >
                                      {this.state.processState[i]}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.ticketStateCls[i]
                                      }
                                    >
                                      {this.state.ticketState[i]}
                                    </span>
                                  </td>
                                  <td>{individualExcelData.ticketPriority}</td>
                                  <td>{individualExcelData.conComplaintNo}</td>
                                  <td>{individualExcelData.LRNumber}</td>
                                  <td>{individualExcelData.invoiceNo}</td>
                                  <td>{individualExcelData.orderNo}</td>
                                  <td>{individualExcelData.flowId}</td>
                                  <td>{individualExcelData.retailerCode}</td>
                                  {/* <td>{individualExcelData.retailerId}</td> */}
                                  <td>{individualExcelData.retailerName}</td>
                                  <td>{individualExcelData.productCode}</td>
                                  {/* <td>{individualExcelData.productId}</td> */}
                                  <td>{individualExcelData.productName}</td>
                                  <td>{individualExcelData.brandCode}</td>
                                  {/* <td>{individualExcelData.brandId}</td> */}
                                  <td>{individualExcelData.brandName}</td>
                                  <td>{individualExcelData.modelCode}</td>
                                  {/* <td>{individualExcelData.modelId}</td> */}
                                  <td>{individualExcelData.modelName}</td>
                                  <td>{individualExcelData.deliveryType}</td>
                                  <td>{individualExcelData.carrier}</td>
                                  <td>
                                    {individualExcelData.isAppointmentReq}
                                  </td>
                                  <td>
                                    {individualExcelData.isInstallationReq}
                                  </td>
                                  <td>{individualExcelData.isPhysicalEval}</td>
                                  <td>{individualExcelData.isTechEval}</td>
                                  <td>{individualExcelData.isPackingReq}</td>
                                  <td>{individualExcelData.retailerNo}</td>

                                  <td>{individualExcelData.ticketDetails}</td>
                                  <td>
                                    {individualExcelData.specialInstructions}
                                  </td>
                                  <td>{individualExcelData.dateOfPurchase}</td>
                                  <td>
                                    {individualExcelData.identificationNo}
                                  </td>
                                  <td>{individualExcelData.productDesc}</td>
                                  <td>{individualExcelData.problemDesc}</td>
                                  <td>{individualExcelData.productValue}</td>
                                  <td>{individualExcelData.cost}</td>
                                  <td>{individualExcelData.isUnderWarranty}</td>
                                  <td>
                                    {individualExcelData.productDimensionUnit}
                                  </td>
                                  <td>{individualExcelData.productLength}</td>
                                  <td>{individualExcelData.productBreadth}</td>
                                  <td>{individualExcelData.productHeight}</td>
                                  <td>
                                    {individualExcelData.productWeightUnit}
                                  </td>
                                  <td>
                                    {individualExcelData.productActualWeight}
                                  </td>
                                  <td>
                                    {individualExcelData.productVolumeWeight}
                                  </td>
                                  <td>{individualExcelData.boxName}</td>
                                  <td>{individualExcelData.boxSlNo}</td>
                                  <td>
                                    {individualExcelData.srcContactPerson}
                                  </td>
                                  <td>{individualExcelData.srcAdd1}</td>
                                  <td>{individualExcelData.srcAdd2}</td>
                                  <td>{individualExcelData.srcLocation}</td>
                                  <td>{individualExcelData.srcContact1}</td>
                                  <td>{individualExcelData.srcContact2}</td>
                                  <td>{individualExcelData.srcCity}</td>
                                  <td>{individualExcelData.srcState}</td>
                                  <td>{individualExcelData.srcPincode}</td>
                                  <td>{individualExcelData.srcLandmark}</td>
                                  <td>{individualExcelData.srcEmailId}</td>
                                  <td>{individualExcelData.srcEmailIdAlt}</td>
                                  <td>{individualExcelData.srcType}</td>
                                  <td>{individualExcelData.srcLatitude}</td>
                                  <td>{individualExcelData.srcLongitude}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                {this.state.data.flow === "BLK" &&
                  this.state.excelData[0].flowId === "Bulk" &&
                  -(
                    <>
                      <div className="table-responsive">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Ticket No.</th>
                              <th>Ticket Priority</th>
                              <th>Consumer Complaint Number</th>
                              <th>LR Number</th>
                              <th>invoiceNo</th>
                              <th>orderNo</th>
                              <th>Flow Id</th>
                              <th>Retailer Code</th>
                              {/* <th>Retailer Id</th> */}
                              <th>Retailer Name</th>
                              <th>Product Code</th>
                              {/* <th>Product Id</th> */}
                              <th>Product Name</th>
                              <th>Brand Code</th>
                              {/* <th>Brand Id</th> */}
                              <th>Brand Name</th>
                              <th>Model Code</th>
                              {/* <th>Model Id</th> */}
                              <th>Model Name</th>
                              <th>Delivery Type</th>
                              <th>Carrier</th>
                              <th>isAppointment Required</th>
                              <th>isInstallation Required</th>
                              <th>isPhysical Evaluation Required</th>
                              <th>isTechnical Evaluation Required</th>
                              <th>isPacking Required</th>
                              <th>retailerNo</th>

                              <th>ticketDetails</th>
                              <th>specialInstructions</th>
                              <th>dateOfPurchase</th>
                              <th>identificationNo</th>
                              <th>productDesc</th>
                              <th>problemDesc</th>
                              <th>productValue</th>
                              <th>cost</th>
                              <th>isUnderWarranty</th>
                              <th>productDimensionUnit</th>
                              <th>productLength</th>
                              <th>productBreadth</th>
                              <th>productHeight</th>
                              <th>productWeightUnit</th>
                              <th>productActualWeight</th>
                              <th>productVolumeWeight</th>
                              <th>boxName</th>
                              <th>boxSlNo</th>
                              <th>srcContactPerson</th>
                              <th>srcAdd1</th>
                              <th>srcAdd2</th>
                              <th>srcLocation</th>
                              <th>srcContact1</th>
                              <th>srcContact2</th>
                              <th>srcCity</th>
                              <th>srcState</th>
                              <th>srcPincode</th>
                              <th>srcLandmark</th>
                              <th>srcEmailId</th>
                              <th>srcEmailIdAlt</th>
                              <th>srcType</th>
                              <th>srcLatitude</th>
                              <th>srcLongitude</th>
                              <th>dstContactPerson</th>
                              <th>dstAdd1</th>
                              <th>dstAdd2</th>
                              <th>dstLocation</th>
                              <th>dstContact1</th>
                              <th>dstContact2</th>
                              <th>dstCity</th>
                              <th>dstState</th>
                              <th>dstPincode</th>
                              <th>dstLandmark</th>
                              <th>dstEmailId</th>
                              <th>dstEmailIdAlt</th>
                              <th>dstType</th>
                              <th>dstLatitude</th>
                              <th>dstLongitude</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.excelData.map(
                              (individualExcelData, i) => (
                                <tr key={individualExcelData.conComplaintNo}>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.processStateCls[i]
                                      }
                                    >
                                      {this.state.processState[i]}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.ticketStateCls[i]
                                      }
                                    >
                                      {this.state.ticketState[i]}
                                    </span>
                                  </td>
                                  <td>{individualExcelData.ticketPriority}</td>
                                  <td>{individualExcelData.conComplaintNo}</td>
                                  <td>{individualExcelData.LRNumber}</td>
                                  <td>{individualExcelData.invoiceNo}</td>
                                  <td>{individualExcelData.orderNo}</td>
                                  <td>{individualExcelData.flowId}</td>
                                  <td>{individualExcelData.retailerCode}</td>
                                  {/* <td>{individualExcelData.retailerId}</td> */}
                                  <td>{individualExcelData.retailerName}</td>
                                  <td>{individualExcelData.productCode}</td>
                                  {/* <td>{individualExcelData.productId}</td> */}
                                  <td>{individualExcelData.productName}</td>
                                  <td>{individualExcelData.brandCode}</td>
                                  {/* <td>{individualExcelData.brandId}</td> */}
                                  <td>{individualExcelData.brandName}</td>
                                  <td>{individualExcelData.modelCode}</td>
                                  {/* <td>{individualExcelData.modelId}</td> */}
                                  <td>{individualExcelData.modelName}</td>
                                  <td>{individualExcelData.deliveryType}</td>
                                  <td>{individualExcelData.carrier}</td>
                                  <td>
                                    {individualExcelData.isAppointmentReq}
                                  </td>
                                  <td>
                                    {individualExcelData.isInstallationReq}
                                  </td>
                                  <td>{individualExcelData.isPhysicalEval}</td>
                                  <td>{individualExcelData.isTechEval}</td>
                                  <td>{individualExcelData.isPackingReq}</td>
                                  <td>{individualExcelData.retailerNo}</td>

                                  <td>{individualExcelData.ticketDetails}</td>
                                  <td>
                                    {individualExcelData.specialInstructions}
                                  </td>
                                  <td>{individualExcelData.dateOfPurchase}</td>
                                  <td>
                                    {individualExcelData.identificationNo}
                                  </td>
                                  <td>{individualExcelData.productDesc}</td>
                                  <td>{individualExcelData.problemDesc}</td>
                                  <td>{individualExcelData.productValue}</td>
                                  <td>{individualExcelData.cost}</td>
                                  <td>{individualExcelData.isUnderWarranty}</td>
                                  <td>
                                    {individualExcelData.productDimensionUnit}
                                  </td>
                                  <td>{individualExcelData.productLength}</td>
                                  <td>{individualExcelData.productBreadth}</td>
                                  <td>{individualExcelData.productHeight}</td>
                                  <td>
                                    {individualExcelData.productWeightUnit}
                                  </td>
                                  <td>
                                    {individualExcelData.productActualWeight}
                                  </td>
                                  <td>
                                    {individualExcelData.productVolumeWeight}
                                  </td>
                                  <td>{individualExcelData.boxName}</td>
                                  <td>{individualExcelData.boxSlNo}</td>
                                  <td>
                                    {individualExcelData.srcContactPerson}
                                  </td>
                                  <td>{individualExcelData.srcAdd1}</td>
                                  <td>{individualExcelData.srcAdd2}</td>
                                  <td>{individualExcelData.srcLocation}</td>
                                  <td>{individualExcelData.srcContact1}</td>
                                  <td>{individualExcelData.srcContact2}</td>
                                  <td>{individualExcelData.srcCity}</td>
                                  <td>{individualExcelData.srcState}</td>
                                  <td>{individualExcelData.srcPincode}</td>
                                  <td>{individualExcelData.srcLandmark}</td>
                                  <td>{individualExcelData.srcEmailId}</td>
                                  <td>{individualExcelData.srcEmailIdAlt}</td>
                                  <td>{individualExcelData.srcType}</td>
                                  <td>{individualExcelData.srcLatitude}</td>
                                  <td>{individualExcelData.srcLongitude}</td>

                                  <td>
                                    {individualExcelData.dstContactPerson}
                                  </td>
                                  <td>{individualExcelData.dstAdd1}</td>
                                  <td>{individualExcelData.dstAdd2}</td>
                                  <td>{individualExcelData.dstLocation}</td>
                                  <td>{individualExcelData.dstContact1}</td>
                                  <td>{individualExcelData.dstContact2}</td>
                                  <td>{individualExcelData.dstCity}</td>
                                  <td>{individualExcelData.dstState}</td>
                                  <td>{individualExcelData.dstPincode}</td>
                                  <td>{individualExcelData.dstLandmark}</td>
                                  <td>{individualExcelData.dstEmailId}</td>
                                  <td>{individualExcelData.dstEmailIdAlt}</td>
                                  <td>{individualExcelData.dstType}</td>
                                  <td>{individualExcelData.dstLatitude}</td>
                                  <td>{individualExcelData.dstLongitude}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                {this.state.data.flow === "EWASTE" &&
                  this.state.excelData[0].flowId === "eWaste" && (
                    <>
                      <div className="table-responsive">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Ticket No.</th>
                              <th>Ticket Priority</th>
                              <th>Consumer Complaint Number</th>
                              <th>LR Number</th>
                              <th>invoiceNo</th>
                              <th>orderNo</th>
                              <th>Flow Id</th>
                              <th>Retailer Code</th>
                              {/* <th>Retailer Id</th> */}
                              <th>Retailer Name</th>
                              <th>Product Code</th>
                              {/* <th>Product Id</th> */}
                              <th>Product Name</th>
                              <th>Brand Code</th>
                              {/* <th>Brand Id</th> */}
                              <th>Brand Name</th>
                              <th>Model Code</th>
                              {/* <th>Model Id</th> */}
                              <th>Model Name</th>
                              <th>Delivery Type</th>
                              <th>Carrier</th>
                              <th>isAppointment Required</th>
                              <th>isInstallation Required</th>
                              <th>isPhysical Evaluation Required</th>
                              <th>isTechnical Evaluation Required</th>
                              <th>isPacking Required</th>
                              <th>retailerNo</th>

                              <th>ticketDetails</th>
                              <th>specialInstructions</th>
                              <th>dateOfPurchase</th>
                              <th>identificationNo</th>
                              <th>productDesc</th>
                              <th>problemDesc</th>
                              <th>productValue</th>
                              <th>cost</th>
                              <th>isUnderWarranty</th>
                              <th>productDimensionUnit</th>
                              <th>productLength</th>
                              <th>productBreadth</th>
                              <th>productHeight</th>
                              <th>productWeightUnit</th>
                              <th>productActualWeight</th>
                              <th>productVolumeWeight</th>
                              <th>boxName</th>
                              <th>boxSlNo</th>
                              <th>srcContactPerson</th>
                              <th>srcAdd1</th>
                              <th>srcAdd2</th>
                              <th>srcLocation</th>
                              <th>srcContact1</th>
                              <th>srcContact2</th>
                              <th>srcCity</th>
                              <th>srcState</th>
                              <th>srcPincode</th>
                              <th>srcLandmark</th>
                              <th>srcEmailId</th>
                              <th>srcEmailIdAlt</th>
                              <th>srcType</th>
                              <th>srcLatitude</th>
                              <th>srcLongitude</th>
                              <th>dstContactPerson</th>
                              <th>dstAdd1</th>
                              <th>dstAdd2</th>
                              <th>dstLocation</th>
                              <th>dstContact1</th>
                              <th>dstContact2</th>
                              <th>dstCity</th>
                              <th>dstState</th>
                              <th>dstPincode</th>
                              <th>dstLandmark</th>
                              <th>dstEmailId</th>
                              <th>dstEmailIdAlt</th>
                              <th>dstType</th>
                              <th>dstLatitude</th>
                              <th>dstLongitude</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.excelData.map(
                              (individualExcelData, i) => (
                                <tr key={individualExcelData.conComplaintNo}>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.processStateCls[i]
                                      }
                                    >
                                      {this.state.processState[i]}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.ticketStateCls[i]
                                      }
                                    >
                                      {this.state.ticketState[i]}
                                    </span>
                                  </td>
                                  <td>{individualExcelData.ticketPriority}</td>
                                  <td>{individualExcelData.conComplaintNo}</td>
                                  <td>{individualExcelData.LRNumber}</td>
                                  <td>{individualExcelData.invoiceNo}</td>
                                  <td>{individualExcelData.orderNo}</td>
                                  <td>{individualExcelData.flowId}</td>
                                  <td>{individualExcelData.retailerCode}</td>
                                  {/* <td>{individualExcelData.retailerId}</td> */}
                                  <td>{individualExcelData.retailerName}</td>
                                  <td>{individualExcelData.productCode}</td>
                                  {/* <td>{individualExcelData.productId}</td> */}
                                  <td>{individualExcelData.productName}</td>
                                  <td>{individualExcelData.brandCode}</td>
                                  {/* <td>{individualExcelData.brandId}</td> */}
                                  <td>{individualExcelData.brandName}</td>
                                  <td>{individualExcelData.modelCode}</td>
                                  {/* <td>{individualExcelData.modelId}</td> */}
                                  <td>{individualExcelData.modelName}</td>
                                  <td>{individualExcelData.deliveryType}</td>
                                  <td>{individualExcelData.carrier}</td>
                                  <td>
                                    {individualExcelData.isAppointmentReq}
                                  </td>
                                  <td>
                                    {individualExcelData.isInstallationReq}
                                  </td>
                                  <td>{individualExcelData.isPhysicalEval}</td>
                                  <td>{individualExcelData.isTechEval}</td>
                                  <td>{individualExcelData.isPackingReq}</td>
                                  <td>{individualExcelData.retailerNo}</td>

                                  <td>{individualExcelData.ticketDetails}</td>
                                  <td>
                                    {individualExcelData.specialInstructions}
                                  </td>
                                  <td>{individualExcelData.dateOfPurchase}</td>
                                  <td>
                                    {individualExcelData.identificationNo}
                                  </td>
                                  <td>{individualExcelData.productDesc}</td>
                                  <td>{individualExcelData.problemDesc}</td>
                                  <td>{individualExcelData.productValue}</td>
                                  <td>{individualExcelData.cost}</td>
                                  <td>{individualExcelData.isUnderWarranty}</td>
                                  <td>
                                    {individualExcelData.productDimensionUnit}
                                  </td>
                                  <td>{individualExcelData.productLength}</td>
                                  <td>{individualExcelData.productBreadth}</td>
                                  <td>{individualExcelData.productHeight}</td>
                                  <td>
                                    {individualExcelData.productWeightUnit}
                                  </td>
                                  <td>
                                    {individualExcelData.productActualWeight}
                                  </td>
                                  <td>
                                    {individualExcelData.productVolumeWeight}
                                  </td>
                                  <td>{individualExcelData.boxName}</td>
                                  <td>{individualExcelData.boxSlNo}</td>
                                  <td>
                                    {individualExcelData.srcContactPerson}
                                  </td>
                                  <td>{individualExcelData.srcAdd1}</td>
                                  <td>{individualExcelData.srcAdd2}</td>
                                  <td>{individualExcelData.srcLocation}</td>
                                  <td>{individualExcelData.srcContact1}</td>
                                  <td>{individualExcelData.srcContact2}</td>
                                  <td>{individualExcelData.srcCity}</td>
                                  <td>{individualExcelData.srcState}</td>
                                  <td>{individualExcelData.srcPincode}</td>
                                  <td>{individualExcelData.srcLandmark}</td>
                                  <td>{individualExcelData.srcEmailId}</td>
                                  <td>{individualExcelData.srcEmailIdAlt}</td>
                                  <td>{individualExcelData.srcType}</td>
                                  <td>{individualExcelData.srcLatitude}</td>
                                  <td>{individualExcelData.srcLongitude}</td>

                                  <td>
                                    {individualExcelData.dstContactPerson}
                                  </td>
                                  <td>{individualExcelData.dstAdd1}</td>
                                  <td>{individualExcelData.dstAdd2}</td>
                                  <td>{individualExcelData.dstLocation}</td>
                                  <td>{individualExcelData.dstContact1}</td>
                                  <td>{individualExcelData.dstContact2}</td>
                                  <td>{individualExcelData.dstCity}</td>
                                  <td>{individualExcelData.dstState}</td>
                                  <td>{individualExcelData.dstPincode}</td>
                                  <td>{individualExcelData.dstLandmark}</td>
                                  <td>{individualExcelData.dstEmailId}</td>
                                  <td>{individualExcelData.dstEmailIdAlt}</td>
                                  <td>{individualExcelData.dstType}</td>
                                  <td>{individualExcelData.dstLatitude}</td>
                                  <td>{individualExcelData.dstLongitude}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                {this.state.data.flow === "FURN" &&
                  this.state.excelData[0].flowId === "Furniture" && (
                    <>
                      <div className="table-responsive">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Ticket No.</th>
                              <th>Ticket Priority</th>
                              <th>Consumer Complaint Number</th>
                              <th>LR Number</th>
                              <th>invoiceNo</th>
                              <th>orderNo</th>
                              <th>Flow Id</th>
                              <th>Retailer Code</th>
                              {/* <th>Retailer Id</th> */}
                              <th>Retailer Name</th>
                              <th>Product Code</th>
                              {/* <th>Product Id</th> */}
                              <th>Product Name</th>
                              <th>Brand Code</th>
                              {/* <th>Brand Id</th> */}
                              <th>Brand Name</th>
                              <th>Model Code</th>
                              {/* <th>Model Id</th> */}
                              <th>Model Name</th>
                              <th>Delivery Type</th>
                              <th>Carrier</th>
                              <th>isAppointment Required</th>
                              <th>isInstallation Required</th>
                              <th>isPhysical Evaluation Required</th>
                              <th>isTechnical Evaluation Required</th>
                              <th>isPacking Required</th>
                              <th>retailerNo</th>

                              <th>ticketDetails</th>
                              <th>specialInstructions</th>
                              <th>dateOfPurchase</th>
                              <th>identificationNo</th>
                              <th>productDesc</th>
                              <th>problemDesc</th>
                              <th>productValue</th>
                              <th>cost</th>
                              <th>isUnderWarranty</th>
                              <th>productDimensionUnit</th>
                              <th>productLength</th>
                              <th>productBreadth</th>
                              <th>productHeight</th>
                              <th>productWeightUnit</th>
                              <th>productActualWeight</th>
                              <th>productVolumeWeight</th>
                              <th>boxName</th>
                              <th>boxSlNo</th>
                              <th>srcContactPerson</th>
                              <th>srcAdd1</th>
                              <th>srcAdd2</th>
                              <th>srcLocation</th>
                              <th>srcContact1</th>
                              <th>srcContact2</th>
                              <th>srcCity</th>
                              <th>srcState</th>
                              <th>srcPincode</th>
                              <th>srcLandmark</th>
                              <th>srcEmailId</th>
                              <th>srcEmailIdAlt</th>
                              <th>srcType</th>
                              <th>srcLatitude</th>
                              <th>srcLongitude</th>
                              <th>dstContactPerson</th>
                              <th>dstAdd1</th>
                              <th>dstAdd2</th>
                              <th>dstLocation</th>
                              <th>dstContact1</th>
                              <th>dstContact2</th>
                              <th>dstCity</th>
                              <th>dstState</th>
                              <th>dstPincode</th>
                              <th>dstLandmark</th>
                              <th>dstEmailId</th>
                              <th>dstEmailIdAlt</th>
                              <th>dstType</th>
                              <th>dstLatitude</th>
                              <th>dstLongitude</th>
                              <th>noOfBoxes</th>
                              <th>boxesNames</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.excelData.map(
                              (individualExcelData, i) => (
                                <tr key={individualExcelData.conComplaintNo}>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.processStateCls[i]
                                      }
                                    >
                                      {this.state.processState[i]}
                                    </span>
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        "badge badge-" +
                                        this.state.ticketStateCls[i]
                                      }
                                    >
                                      {this.state.ticketState[i]}
                                    </span>
                                  </td>
                                  <td>{individualExcelData.ticketPriority}</td>
                                  <td>{individualExcelData.conComplaintNo}</td>
                                  <td>{individualExcelData.LRNumber}</td>
                                  <td>{individualExcelData.invoiceNo}</td>
                                  <td>{individualExcelData.orderNo}</td>
                                  <td>{individualExcelData.flowId}</td>
                                  <td>{individualExcelData.retailerCode}</td>
                                  {/* <td>{individualExcelData.retailerId}</td> */}
                                  <td>{individualExcelData.retailerName}</td>
                                  <td>{individualExcelData.productCode}</td>
                                  {/* <td>{individualExcelData.productId}</td> */}
                                  <td>{individualExcelData.productName}</td>
                                  <td>{individualExcelData.brandCode}</td>
                                  {/* <td>{individualExcelData.brandId}</td> */}
                                  <td>{individualExcelData.brandName}</td>
                                  <td>{individualExcelData.modelCode}</td>
                                  {/* <td>{individualExcelData.modelId}</td> */}
                                  <td>{individualExcelData.modelName}</td>
                                  <td>{individualExcelData.deliveryType}</td>
                                  <td>{individualExcelData.carrier}</td>
                                  <td>
                                    {individualExcelData.isAppointmentReq}
                                  </td>
                                  <td>
                                    {individualExcelData.isInstallationReq}
                                  </td>
                                  <td>{individualExcelData.isPhysicalEval}</td>
                                  <td>{individualExcelData.isTechEval}</td>
                                  <td>{individualExcelData.isPackingReq}</td>
                                  <td>{individualExcelData.retailerNo}</td>

                                  <td>{individualExcelData.ticketDetails}</td>
                                  <td>
                                    {individualExcelData.specialInstructions}
                                  </td>
                                  <td>{individualExcelData.dateOfPurchase}</td>
                                  <td>
                                    {individualExcelData.identificationNo}
                                  </td>
                                  <td>{individualExcelData.productDesc}</td>
                                  <td>{individualExcelData.problemDesc}</td>
                                  <td>{individualExcelData.productValue}</td>
                                  <td>{individualExcelData.cost}</td>
                                  <td>{individualExcelData.isUnderWarranty}</td>
                                  <td>
                                    {individualExcelData.productDimensionUnit}
                                  </td>
                                  <td>{individualExcelData.productLength}</td>
                                  <td>{individualExcelData.productBreadth}</td>
                                  <td>{individualExcelData.productHeight}</td>
                                  <td>
                                    {individualExcelData.productWeightUnit}
                                  </td>
                                  <td>
                                    {individualExcelData.productActualWeight}
                                  </td>
                                  <td>
                                    {individualExcelData.productVolumeWeight}
                                  </td>
                                  <td>{individualExcelData.boxName}</td>
                                  <td>{individualExcelData.boxSlNo}</td>
                                  <td>
                                    {individualExcelData.srcContactPerson}
                                  </td>
                                  <td>{individualExcelData.srcAdd1}</td>
                                  <td>{individualExcelData.srcAdd2}</td>
                                  <td>{individualExcelData.srcLocation}</td>
                                  <td>{individualExcelData.srcContact1}</td>
                                  <td>{individualExcelData.srcContact2}</td>
                                  <td>{individualExcelData.srcCity}</td>
                                  <td>{individualExcelData.srcState}</td>
                                  <td>{individualExcelData.srcPincode}</td>
                                  <td>{individualExcelData.srcLandmark}</td>
                                  <td>{individualExcelData.srcEmailId}</td>
                                  <td>{individualExcelData.srcEmailIdAlt}</td>
                                  <td>{individualExcelData.srcType}</td>
                                  <td>{individualExcelData.srcLatitude}</td>
                                  <td>{individualExcelData.srcLongitude}</td>

                                  <td>
                                    {individualExcelData.dstContactPerson}
                                  </td>
                                  <td>{individualExcelData.dstAdd1}</td>
                                  <td>{individualExcelData.dstAdd2}</td>
                                  <td>{individualExcelData.dstLocation}</td>
                                  <td>{individualExcelData.dstContact1}</td>
                                  <td>{individualExcelData.dstContact2}</td>
                                  <td>{individualExcelData.dstCity}</td>
                                  <td>{individualExcelData.dstState}</td>
                                  <td>{individualExcelData.dstPincode}</td>
                                  <td>{individualExcelData.dstLandmark}</td>
                                  <td>{individualExcelData.dstEmailId}</td>
                                  <td>{individualExcelData.dstEmailIdAlt}</td>
                                  <td>{individualExcelData.dstType}</td>
                                  <td>{individualExcelData.dstLatitude}</td>
                                  <td>{individualExcelData.dstLongitude}</td>
                                  <td>{individualExcelData.noOfBoxes}</td>
                                  <td>{individualExcelData.boxesNames}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                {this.state.data.flow === "PND1W" &&
                  this.state.excelData[0].flowId !== "PickAndDropOneWay" && (
                    <div class="jumbotron jumbotron-fluid">
                      <p class="lead text-center">
                        Uploaded Excel Not Matched With The Selected Flow Kindly
                        Check And Re-Upload
                      </p>
                    </div>
                  )}
                {/* {this.state.data.flow === "PND1W" && !this.state.products && (
                  <div class="jumbotron jumbotron-fluid">
                    <p class="lead text-center">
                      Kindly Select products label to upload the tickets
                    </p>
                  </div>
                )} */}
                {this.state.data.flow === "PND2W" &&
                  this.state.excelData[0].flowId !== "PickAndDropTwoWay" && (
                    <div class="jumbotron jumbotron-fluid">
                      <p class="lead text-center">
                        Uploaded Excel Not Matched With The Selected Flow Kindly
                        Check And Re-Upload
                      </p>
                    </div>
                  )}
                {this.state.data.flow === "ADV" &&
                  this.state.excelData[0].flowId !== "AdvanceExchange" && (
                    <div class="jumbotron jumbotron-fluid">
                      <p class="lead text-center">
                        Uploaded Excel Not Matched With The Selected Flow Kindly
                        Check And Re-Upload
                      </p>
                    </div>
                  )}
                {this.state.data.flow === "BUYBACK" &&
                  this.state.excelData[0].flowId !== "BuyBack" && (
                    <div class="jumbotron jumbotron-fluid">
                      <p class="lead text-center">
                        Uploaded Excel Not Matched With The Selected Flow Kindly
                        Check And Re-Upload
                      </p>
                    </div>
                  )}
                {this.state.data.flow === "INSV1" &&
                  this.state.excelData[0].flowId !== "InstallationV1" && (
                    <div class="jumbotron jumbotron-fluid">
                      <p class="lead text-center">
                        Uploaded Excel Not Matched With The Selected Flow Kindly
                        Check And Re-Upload
                      </p>
                    </div>
                  )}
                {this.state.data.flow === "DRPOF" &&
                  this.state.excelData[0].flowId !== "DropOff" && (
                    <div class="jumbotron jumbotron-fluid">
                      <p class="lead text-center">
                        Uploaded Excel Not Matched With The Selected Flow Kindly
                        Check And Re-Upload
                      </p>
                    </div>
                  )}
                {this.state.data.flow === "BULKQC" &&
                  this.state.excelData[0].flowId !== "BulkQC" && (
                    <div class="jumbotron jumbotron-fluid">
                      <p class="lead text-center">
                        Uploaded Excel Not Matched With The Selected Flow Kindly
                        Check And Re-Upload
                      </p>
                    </div>
                  )}
                {this.state.data.flow === "BLK" &&
                  this.state.excelData[0].flowId !== "Bulk" && (
                    <div class="jumbotron jumbotron-fluid">
                      <p class="lead text-center">
                        Uploaded Excel Not Matched With The Selected Flow Kindly
                        Check And Re-Upload
                      </p>
                    </div>
                  )}
                {this.state.data.flow === "EWASTE" &&
                  this.state.excelData[0].flowId !== "eWaste" && (
                    <div class="jumbotron jumbotron-fluid">
                      <p class="lead text-center">
                        Uploaded Excel Not Matched With The Selected Flow Kindly
                        Check And Re-Upload
                      </p>
                    </div>
                  )}
                {this.state.data.flow === "FURN" &&
                  this.state.excelData[0].flowId !== "Furniture" && (
                    <div class="jumbotron jumbotron-fluid">
                      <p class="lead text-center">
                        Uploaded Excel Not Matched With The Selected Flow Kindly
                        Check And Re-Upload
                      </p>
                    </div>
                  )}
                {this.state.exportFailedData === true && (
                  <ExportXLSXReqTrans
                    csvData={this.state.csvReport.data}
                    fileName={"Failed Data"}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
