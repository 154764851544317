import React from "react";
import FormLib from "./../../_common/FormLib";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { getTicketDetails, getTicketDetailsNew } from "./../../../services/Tickets/TicketServices";
import { epoch2ddmmyyyy,epoch2ddmmyy } from "./../../../utils/DateUtils";
import { toast } from "react-toastify";
import Pagination from "./../../_common/Pagination";
import Joi from 'joi-browser';
import ReactTooltip from "react-tooltip";
import {
    epoch2ddmmyyyyhhmmss, IstToUTC

} from "../../../utils/DateUtils";

import { getTicketInfo, getTicketMisHistory, getTicketsCountsNew, getPincodesList } from "../../../services/Tickets/TicketServices";
import LineHaulWrapper from "./Manifest/LineHaulWrapper";
import { getTicketRemarks } from "../../../services/TicketHistory/TicketHistory";

class TicketsListNew extends FormLib {
    state = {
        btnValue: "unassigned",
        data: { search: "", hub: "", ticketStatus: "all", flow: "all", order: "lifo", pincodeList: "all", appointment: "yes", packing: "yes" },
        // blockStatus
        errors: {},
        ticketDetails: [],
        curBlockDetails: [],
        curTicketDetails: [],
        curMultiBlockDetails: [],
        curMultiTickets: [],

        curMultiTicketDetails: [],
        TicketProductDetails: [],
        TicketPrimarytDetails: [],
        TicketRemarks: [],
        curMisReportHistory: [],
        pageLinks: {
            pageNo: 1,
            perPage: 8,
            itemsCount: 0,
            lastPage: 0,
        },
        curHubCode: [],
        mode: "",
        blockSelected: false,

    };
    onModeChange(mode) {
        this.setState({ mode: mode });

    }
    flow = [
        { sortKey: "all", sort: "All" },
        { sortKey: "pick", sort: "PickUp" },
        { sortKey: "drop", sort: "Drop" },
    ];
    order = [
        { sortKey: "lifo", sort: "LIFO" },
        { sortKey: "fifo", sort: "FIFO" },
    ];
    appointment = [
        { sortKey: "yes", sort: "YES" },
        { sortKey: "no", sort: "NO" },
    ];
    packing = [
        { sortKey: "yes", sort: "YES" },
        { sortKey: "no", sort: "NO" },
    ];

    schema = {
        search: Joi.optional().label("search")
    }

    handlebtn(e) {
        localStorage.setItem("blockStatus", "all");
        localStorage.setItem("pincode", "all");

        let selectedBlockStatus = localStorage.getItem("blockStatus");
        let pincode = localStorage.getItem("pincode");

        if (e === "unassigned") {
            this.setState({
                data: {
                    search: this.state.data.search,
                    hub: this.state.data.hub,
                    ticketStatus: selectedBlockStatus,
                    flow: "all",
                    order: "lifo",
                    pincodeList: "all"
                },
            })
            let selectedHub = localStorage.getItem("curHub");
            this.setState({ btnValue: e });
            localStorage.setItem("actionBtn", e);
            localStorage.setItem("flow", "all");
            localStorage.setItem("order", "lifo");
            localStorage.setItem("appointment", "yes");
            localStorage.setItem("packing", "yes");
            // localStorage.setItem("pincode", "all");
            let flowStatus = localStorage.getItem("flow");
            let order = localStorage.getItem("order");
            let appointment = localStorage.getItem("appointment");
            let packing = localStorage.getItem("packing");
            this.getTicketlist(selectedHub, selectedBlockStatus, e, "1", order, flowStatus, pincode, appointment, packing);
        }
        else {
            this.setState({
                data: {
                    search: this.state.data.search,
                    hub: this.state.data.hub,
                    ticketStatus: selectedBlockStatus,
                    flow: "all",
                    order: "lifo",
                    pincodeList: "all"
                },
            })
            let selectedHub = localStorage.getItem("curHub");
            this.setState({ btnValue: e });
            localStorage.setItem("actionBtn", e);
            localStorage.setItem("flow", "all");
            localStorage.setItem("order", "lifo");
            // localStorage.setItem("pincode", "all");
            let flowStatus = localStorage.getItem("flow");
            let order = localStorage.getItem("order");
            let appointment = localStorage.getItem("appointment");
            let packing = localStorage.getItem("packing");
            this.getTicketlist(selectedHub, selectedBlockStatus, e, "1", order, flowStatus, pincode, appointment, packing);
        }

    }

    componentDidMount() {
        this.resizeTicketList();
        this.loadHubs();
        let curHub = localStorage.getItem("curHub");
        this.getTicketsCount(curHub);
        this.getPincodesListByHub(curHub);

    }
    async getTicketsCount(curHub) {
        try {
            let param = {
                "hubId": curHub
            }
            let resp = await getTicketsCountsNew(param);
            if (resp !== undefined) {
                if (resp.data.data) {
                    this.setState({ unAssigned: resp.data.data.unAssigned });
                    this.setState({ assigned: resp.data.data.assigned });
                    this.setState({ rescheduled: resp.data.data.rescheduled });
                    this.setState({ cancelled: resp.data.data.cancelled });
                    this.setState({ completed: resp.data.data.completed });
                    this.setState({ field: resp.data.data.field });

                }
            }
        }
        catch {
            console.log("Error in TicketListNew>>>>>>getTicketsCount");
        }
    }
    async getPincodesListByHub(curHub) {
        try {

            let pincodeList = []
            let resp = await getPincodesList(curHub);
            if (resp !== undefined) {
                if (resp.data.data) {
                    let result = resp.data.data.filter(e => e);
                    result.forEach((element, index) => {
                        if (element !== "null") {
                            pincodeList.push({ sortKey: element, sort: element })
                        }
                    });
                    pincodeList.unshift({ sortKey: "all", sort: "All" });
                    this.setState({ pincodeList })

                }
            }
        }
        catch {
            console.log("Error in getPincodesListByHub");
        }
    }

    async loadHubs() {
        try {
            let hubList = JSON.parse(localStorage.getItem("hubs"));
            this.setState({ hubList });

            let curHub = localStorage.getItem("curHub");

            if (!curHub) {
                localStorage.setItem("curHub", JSON.parse(JSON.stringify(hubList[0].hubId)));
                let override = this.state.data;
                override['hub'] = JSON.parse(JSON.stringify(hubList[0].hubId));
                this.setState({ override })
            } else {
                let override = this.state.data;
                override['hub'] = curHub;
                this.setState({ override })
            }
            this.loadTickets();
        }
        catch {
            console.log("Error in TicketListNew>>>>>>loadHubs");
        }
    }
    async loadTickets() {
        try {
            let actionBtn = localStorage.getItem("actionBtn");
            if (actionBtn === "unassigned") {
                localStorage.setItem("flow", "all");
            } else {
                localStorage.setItem("flow", "all");

            }
            let selectedHub = localStorage.getItem("curHub");
            localStorage.setItem("order", "lifo");
            localStorage.setItem("pincode", "all");
            localStorage.setItem("appointment", "yes");
            localStorage.setItem("packing", "yes");
            localStorage.setItem("blockStatus", "all");

            let flowStatus = localStorage.getItem("flow");
            let order = localStorage.getItem("order");
            let pincode = localStorage.getItem("pincode");
            let appointment = localStorage.getItem("appointment");
            let packing = localStorage.getItem("packing");
            let selectedBlockStatus = localStorage.getItem("blockStatus");

            if (actionBtn) {
                this.getTicketlist(selectedHub, selectedBlockStatus, actionBtn, this.state.pageLinks.pageNo, order, flowStatus, pincode, appointment, packing);
                this.setState({ btnValue: actionBtn });
            } else {
                this.getTicketlist(selectedHub, selectedBlockStatus, this.state.btnValue, this.state.pageLinks.pageNo, order, flowStatus, pincode, appointment, packing);
            }
        }
        catch {
            console.log("Error in TicketListNew>>>>>>loadTickets");
        }
    }
    // let selectedStatus = localStorage.getItem("blockStatus");
    localHandleChange = (e) => {
        try {
            if (e.target.name === "hub") {
                let selectedBlockStatus = localStorage.getItem("blockStatus");
                let flowStatus = localStorage.getItem("flow");
                let order = localStorage.getItem("order");
                let pincode = localStorage.getItem("pincode");
                localStorage.setItem("curHub", e.target.value);
                let appointment = localStorage.getItem("appointment");
                let packing = localStorage.getItem("packing");
                this.getTicketlist(e.target.value, selectedBlockStatus, this.state.btnValue, this.state.pageLinks.pageNo, order, flowStatus, pincode, appointment, packing);
                this.getTicketsCount(e.target.value);
                this.getPincodesListByHub(e.target.value);
            }
            else if (e.target.name === "ticketStatus") {
                let blockStatusValue = e.target.value;
                localStorage.setItem("blockStatus", blockStatusValue);
                let selectedHub = localStorage.getItem("curHub");
                let flowStatus = localStorage.getItem("flow");
                let order = localStorage.getItem("order");
                let pincode = localStorage.getItem("pincode");
                let appointment = localStorage.getItem("appointment");
                let packing = localStorage.getItem("packing");
                this.getTicketlist(selectedHub, blockStatusValue, this.state.btnValue, this.state.pageLinks.pageNo, order, flowStatus, pincode, appointment, packing);
            }
            else if (e.target.name === "flow") {
                let flowStatus = e.target.value;
                localStorage.setItem("flow", flowStatus);
                let selectedBlockStatus = localStorage.getItem("blockStatus");
                let selectedHub = localStorage.getItem("curHub");
                let order = localStorage.getItem("order");
                let pincode = localStorage.getItem("pincode");
                let appointment = localStorage.getItem("appointment");
                let packing = localStorage.getItem("packing");
                this.getTicketlist(selectedHub, selectedBlockStatus, this.state.btnValue, this.state.pageLinks.pageNo, order, flowStatus, pincode, appointment, packing);
            }
            else if (e.target.name === "order") {
                let order = e.target.value;
                localStorage.setItem("order", order);
                let selectedBlockStatus = localStorage.getItem("blockStatus");
                let selectedHub = localStorage.getItem("curHub");
                let flowStatus = localStorage.getItem("flow");
                let pincode = localStorage.getItem("pincode");
                let appointment = localStorage.getItem("appointment");
                let packing = localStorage.getItem("packing");
                this.getTicketlist(selectedHub, selectedBlockStatus, this.state.btnValue, this.state.pageLinks.pageNo, order, flowStatus, pincode, appointment, packing);

            }
            else if (e.target.name === "pincodeList") {
                let pincode = e.target.value;
                localStorage.setItem("pincode", pincode);
                let selectedBlockStatus = localStorage.getItem("blockStatus");
                let selectedHub = localStorage.getItem("curHub");
                let flowStatus = localStorage.getItem("flow");
                let order = localStorage.getItem("order");
                let appointment = localStorage.getItem("appointment");
                let packing = localStorage.getItem("packing");
                this.getTicketlist(selectedHub, selectedBlockStatus, this.state.btnValue, this.state.pageLinks.pageNo, order, flowStatus, pincode, appointment, packing);
            }
            else if (e.target.name === "appointment") {
                let appointment = e.target.value;
                localStorage.setItem("appointment", appointment);
                let selectedBlockStatus = localStorage.getItem("blockStatus");
                let selectedHub = localStorage.getItem("curHub");
                let flowStatus = localStorage.getItem("flow");
                let order = localStorage.getItem("order");
                let pincode = localStorage.getItem("pincode");
                let packing = localStorage.getItem("packing");
                this.getTicketlist(selectedHub, selectedBlockStatus, this.state.btnValue, this.state.pageLinks.pageNo, order, flowStatus, pincode, appointment, packing);
            }
            else if (e.target.name === "packing") {
                let packing = e.target.value;
                localStorage.setItem("packing", packing);
                let selectedBlockStatus = localStorage.getItem("blockStatus");
                let selectedHub = localStorage.getItem("curHub");
                let flowStatus = localStorage.getItem("flow");
                let order = localStorage.getItem("order");
                let pincode = localStorage.getItem("pincode");
                let appointment = localStorage.getItem("appointment");
                this.getTicketlist(selectedHub, selectedBlockStatus, this.state.btnValue, this.state.pageLinks.pageNo, order, flowStatus, pincode, appointment, packing);
            }
        }
        catch {
            console.log("Error in TicketListNew>>>>>>localHandleChange");
        }
    }

    // let selectedBlockStatus = localStorage.getItem("blockStatus");
    async getTicketlist(selectedHub, selectedBlockStatus, e, pageNo, order, flowStatus, pincode, appointment, packing) {
        try {
            this.setState({ loading: true });

            let hubList = JSON.parse(localStorage.getItem("hubs"));

            const curHubCode = hubList.filter((h) => {
                return h.hubId == selectedHub ? h.hubCode : "";
            });

            if (curHubCode != "") {
                this.setState({ curHubCode: curHubCode[0].hubCode });
                this.setState({ curHubName: curHubCode[0].hub });
            } else {
                this.setState({ curHubName: curHubCode[0].hub });
                this.setState({ curHubCode: hubList[0].hubCode })
                localStorage.setItem("curHub", hubList[0].hubId);
                selectedHub = hubList[0].hubId;
                let override = this.state.data;
                override['hub'] = selectedHub;
                this.setState({ override })
            }

            // let hub = "5067";
            // let hub = "1111";
            let hub = selectedHub;

            let params = {
                hubId: hub.toString(),
                action: e,
                searchString: this.state.data.search,
                blockStatus: selectedBlockStatus,
                order: order,
                flowStatus: flowStatus,
                pincode: pincode,
                appointment: appointment,
                packing: packing
            };
            // console.log(JSON.stringify(params));
            let resp = await getTicketDetailsNew(params, pageNo);
            // let resp = await getTicketDetails(params, pageNo);
            if (resp !== undefined) {

                if (resp.data.success === true && resp.data.data) {
                    resp.data.data.tickets &&
                        this.setState({ ticketDetails: resp.data.data.tickets });

                    resp.data.data.pageLinks &&
                        this.setState({ pageLinks: resp.data.data.pageLinks });
                    // resp.data.data.tickets && toast.success(() => <div>{resp.data.msg}</div>);
                    !resp.data.data.tickets && toast.warning(() => <div>No Tickets</div>);
                } else {
                    toast.error(() => <div>{resp.data.msg}</div>);
                }
            }
            this.setState({ loading: false });

        }
        catch {
            console.log("Error in TicketListNew>>>>>>getTicketlist");
        }
    }

    handlePageChange = (pageNo) => {
        try {
            let selectedHub = localStorage.getItem("curHub");
            let selectedBlockStatus = localStorage.getItem("blockStatus");
            let flowStatus = localStorage.getItem("flow");
            let order = localStorage.getItem("order");
            let pincode = localStorage.getItem("pincode");
            let appointment = localStorage.getItem("appointment");
            let packing = localStorage.getItem("packing");
            this.getTicketlist(selectedHub, selectedBlockStatus, this.state.btnValue, pageNo, order, flowStatus, pincode, appointment, packing);
        }
        catch {
            console.log("Error in TicketListNew>>>>>>handlePageChange");
        }
    };


    resizeTicketList = () => {
        $("#cocard").css("max-height", $(window).height() - 68);
        $("#cocardHistory").css("max-height", $(window).height() - 15);


    };

    handleView = () => {
        $(".modal-content").resizable({
            //alsoResize: ".modal-dialog",
            minHeight: 300,
            minWidth: 300,
        });
        $("#myModal").draggable();
        $("#myModalProduct").draggable();

        $("#myModal").on("show.bs.modal", function () {
            $(this).find(".modal-body").css({
                "max-height": "100%",
                "max-width": "100%",
            });
        });
        $("#myModalProduct").on("show.bs.modal", function () {
            $(this).find(".modal-body").css({
                "max-height": "100%",
            });
        });
    };

    async setCurBlockTicket(b, t) {
        try {
            if (!t.isAppointmentReq || t.isAppointmentReq === null || t.isAppointmentReq === undefined || t.isAppointmentReq === "null" || t.isAppointmentReq === "") {
                t.isAppointmentReq = "no";
            }
            else if (!t.isPackingReq || t.isPackingReq === null || t.isPackingReq === undefined || t.isPackingReq === "null" || t.isPackingReq === "") {
                t.isPackingReq = "no";
            }
            this.setState({ curBlockDetails: b });
            this.setState({ curTicketDetails: t });
            this.props.onTicketChange(b, t, "notBulk");
            this.getTicketInfo(t.ticketNo);
            this.getTicketMisHistory(t.ticketNo);
        }
        catch {
            console.log("Error in TicketListNew>>>>>>setCurBlockTicket");
        }
    };




    async getTicketInfo(ticketNo) {
        try {

            this.state.TicketProductDetails = [];
            this.state.TicketPrimarytDetails = [];
            const respDst = await getTicketInfo(ticketNo);

            if (respDst !== undefined) {
                if (respDst.data.success === true && respDst.data.data != []) {
                    this.setState({ TicketPrimarytDetails: respDst.data.data.TicketDetails.primaryDetails });
                    this.setState({ TicketProductDetails: respDst.data.data.TicketDetails.productDetails[0] });

                } else if (respDst.data.success === false) {
                    toast.error("No Details Available for this Ticket");
                }
            }
        }
        catch {
            console.log("Error in getTicketInfo");
        }

    }

    async getTicketMisHistory(ticketNo) {
        try {
            this.state.curMisReportHistory = [];
            this.state.TicketRemarks = [];

            const respDst = await getTicketMisHistory(ticketNo);
            const resRemarks = await getTicketRemarks(ticketNo);
            if (resRemarks.data.success === true && resRemarks.data.data != []) {
                this.setState({ TicketRemarks: resRemarks.data.data.ticketRemarks });
            }
            if (respDst !== undefined) {
                if (respDst.data.success === true && respDst.data.data != []) {
                    this.setState({ curMisReportHistory: respDst.data.data.MisReportHistory });
                } else if (respDst.data.success === false) {
                    toast.error("No Details Available for this Ticket");
                }
            }
        }
        catch {
            console.log("Error in TicketListNew>>>>>>getTicketMisHistory");
        }
    }

    replaceString = (str) => {
        if (str) {
            str = str.replace(/_/g, " ");
            return str.replace("BLOCK", "JOB");
        }
    };

    async doSubmit() {
        try {
            let selectedHub = localStorage.getItem("curHub");
            let selectedBlockStatus = localStorage.getItem("blockStatus");
            let flowStatus = localStorage.getItem("flow");
            let order = localStorage.getItem("order");
            let pincode = localStorage.getItem("pincode");
            let appointment = localStorage.getItem("appointment");
            let packing = localStorage.getItem("packing");
            this.getTicketlist(selectedHub, selectedBlockStatus, this.state.btnValue, this.state.pageLinks.pageNo, order, flowStatus, pincode, appointment, packing);
        }
        catch {
            console.log("Error in TicketListNew>>>>>>doSubmit");
        }
    }
    handleSearch = () => {
        try {
            let selectedHub = localStorage.getItem("curHub");
            let pageLinks = this.state.pageLinks;
            pageLinks['pageNo'] = "1";

            this.setState({ pageLinks })
            let selectedBlockStatus = localStorage.getItem("blockStatus");
            let flowStatus = localStorage.getItem("flow");
            let order = localStorage.getItem("order");
            let pincode = localStorage.getItem("pincode");
            let appointment = localStorage.getItem("appointment");
            let packing = localStorage.getItem("packing");
            this.getTicketlist(selectedHub, selectedBlockStatus, this.state.btnValue, this.state.pageLinks.pageNo, order, flowStatus, pincode, appointment, packing);
            this.getTicketsCount(selectedHub);
            this.getPincodesListByHub(selectedHub);
        }
        catch {
            console.log("Error in TicketListNew>>>>>>handleSearch");
        }
    };


    handleHubModalShow = () => {
        window.$("#myModalProduct").show();
    };

    stsConf = {
        value: "hubId",
        label: "hub",
    };
    ticketStatus = [
        { sortKey: "all", sort: "All" },
        { sortKey: "ASSIGN_TO_HO", sort: "ASSIGN TO HO" },
        { sortKey: "APPOINTMENT_FIXED", sort: "APPOINTMENT FIXED" },
        { sortKey: "ASSIGNED_FE_FOR_PICKUP", sort: "ASSIGNED FE FOR PICKUP" },
        { sortKey: "ASSIGNED_FE_FOR_DROP", sort: "ASSIGNED FE FOR DROP" },
        { sortKey: "BLOCK_CREATED", sort: "BLOCK CREATED" },
        { sortKey: "CHOOSE_FE_FOR_DROP", sort: "CHOOSE FE FOR DROP" },
        { sortKey: "CHOOSE_FE_FOR_DROP_ASC", sort: "CHOOSE FE FOR DROP ASC" },
        { sortKey: "DROP_DONE", sort: "DROP DONE" },
        { sortKey: "DROP_DONE_ASC", sort: "DROP DONE ASC" },
        { sortKey: "DROP_CONFIRMED", sort: "DROP CONFIRMED" },
        { sortKey: "HANDED_OVER_PACKING_MATERIALS", sort: "HANDED OVER PACKING MATERIALS" },
        // { sortKey: "IDLE_FOR_15MIN", sort: "IDLE FOR 15MIN" },
        { sortKey: "INSTALL_SERVICE_DONE", sort: "INSTALL SERVICE DONE" },
        { sortKey: "LINEHAUL", sort: "LINEHAUL" },
        { sortKey: "LINEHAUL_IN_TRANSIT", sort: "LINEHAUL IN TRANSIT" },
        { sortKey: "OLD_PRODUCT_DAMAGED_EXCHANGE_CANCELLED", sort: "OLD PRODUCT DAMAGED EXCHANGE CANCELLED" },
        { sortKey: "PICK_UP_DONE_EXCHANGE_SUCCESSFULLY", sort: "PICK UP DONE EXCHANGE SUCCESSFULLY" },
        { sortKey: "PICKUP_DONE", sort: "PICKUP DONE" },
        { sortKey: "PICKUP_DONE_ASC", sort: "PICKUP DONE ASC" },
        { sortKey: "PROBLEM_SOLVED_DURING_INITIAL_CALL", sort: "PROBLEM SOLVED DURING INITIAL CALL" },
        { sortKey: "PART_RECEIVED", sort: "PART RECEIVED" },
        { sortKey: "PART_MISSING", sort: "PART MISSING" },
        { sortKey: "RESCHEDULE_REQUEST_FE_AT_PICKUP", sort: "RESCHEDULE REQUEST FE AT PICKUP" },
        { sortKey: "RESCHEDULE_REQUEST_FE_AT_DROP", sort: "RESCHEDULE REQUEST FE AT DROP" },
        { sortKey: "RTO_INITIATE", sort: "RTO INITIATE" },
        { sortKey: "RTO_REQUEST", sort: "RTO REQUEST" },
        { sortKey: "RTO_REJECT", sort: "RTO REJECT" },
        { sortKey: "REJECT_FE", sort: "REJECT FE" },
        { sortKey: "REJECT_SERVICE_FE_AT_PICKUP", sort: "REJECT SERVICE FE AT PICKUP" },
        { sortKey: "REJECT_SERVICE_FE_AT_DROP", sort: "REJECT SERVICE FE AT DROP" },
    ];
    stsConfig = {
        value: "sortKey",
        label: "sort",
    };

    handleCheckBoxClick = (e, b, t) => {
        try {
            if (e.target.checked) {
                this.state.curMultiBlockDetails.push(b);
                this.state.curMultiTicketDetails.push(t);
                // t["blocks"].push(b)
                // this.state.curMultiTickets.push(t);
                this.setState({ blockSelected: true });
                // console.log("b..........." + JSON.stringify(b));
                // console.log("t..........." + JSON.stringify(t));

            }
            else {
                let index = this.state.curMultiBlockDetails.indexOf(b);
                if (index > -1) {
                    this.state.curMultiBlockDetails.splice(index, 1);
                }
                let index1 = this.state.curMultiTicketDetails.indexOf(t);
                if (index1 > -1) {
                    this.state.curMultiTicketDetails.splice(index1, 1);
                }
            }
            this.props.onTicketChange(this.state.curMultiBlockDetails, this.state.curMultiTicketDetails, "bulk");
        }
        catch {
            console.log("Error in handleCheckBoxClick");
        }
    };

    bulkAssign = [
        { sortKey: "yes", sort: "Bulk" },
    ];


    render() {
        const { pageNo, perPage, itemsCount } = this.state.pageLinks;

        return (
            <React.Fragment>
                <div id="cocard" className="col-12  ml-1 mr-4" >

                    <div id="primary-ticket-search" className="row">
                        <div className="form-row " style={{ marginTop: "4px", marginLeft: "12px" }}>
                            <div >

                                <button
                                    onClick={() => this.handlebtn("unassigned")}
                                    className={
                                        this.state.btnValue === "unassigned"
                                            ? " badge badge-warning  "
                                            : "badge badge-info  "
                                    }
                                    style={{ fontSize: "82%", borderRadius: "2.25rem ", border: "none", fontWeight: "normal", marginRight: "2px" }}
                                    title="UnAssigned"
                                >
                                    UnAssigned<b>({this.state.unAssigned})</b>
                                </button>
                            </div>
                            <div >

                                <button
                                    onClick={() => this.handlebtn("assigned")}
                                    className={
                                        this.state.btnValue === "assigned"
                                            ? " badge badge-warning "
                                            : "badge badge-info "
                                    }
                                    title="Assigned"
                                    style={{ fontSize: "82%", borderRadius: "2.25rem ", border: "none", fontWeight: "normal", marginRight: "2px" }}
                                >
                                    Assigned<b>({this.state.assigned})</b>
                                </button>
                            </div>
                            <div >
                                <button
                                    onClick={() => this.handlebtn("rescheduled")}
                                    className={
                                        this.state.btnValue === "rescheduled"
                                            ? " badge badge-warning"
                                            : "badge badge-info  "
                                    }
                                    title="Rescheduled"
                                    style={{ fontSize: "82%", borderRadius: "2.25rem ", border: "none", fontWeight: "normal", marginRight: "2px" }}
                                >
                                    Rescheduled<b>({this.state.rescheduled})</b>
                                </button>
                            </div>
                            <div >
                                <button
                                    onClick={() => this.handlebtn("field")}
                                    className={
                                        this.state.btnValue === "field"
                                            ? " badge badge-warning "
                                            : "badge badge-info "

                                    }
                                    title="FE Tickets"
                                    style={{ fontSize: "82%", borderRadius: "2.25rem ", border: "none", fontWeight: "normal", marginRight: "6px" }}
                                >
                                    FE Tickets<b>({this.state.field})</b>

                                </button>
                            </div>
                            <div class="dropdown">
                                <button class="badge badge-info dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" style={{ fontSize: "82%", borderRadius: "2.25rem ", border: "none", fontWeight: "normal", marginRight: "2px" }}>
                                    More Actions
                                </button>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            type="button"
                                            onClick={() => this.handlebtn("cancelled")}
                                            style={{ marginRight: "3px" }}
                                        >
                                            Cancelled <b>({this.state.cancelled})</b>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            type="button"
                                            onClick={() => this.handlebtn("completed")}
                                            style={{ marginRight: "2px" }}>
                                            Completed <b>({this.state.completed})</b>
                                        </a>

                                    </li>
                                    <li>
                                        <a
                                            class="dropdown-item"
                                            type="button"
                                            data-target="#myModals"
                                            data-backdrop="false"
                                            data-toggle="modal"
                                            onClick={() => this.onModeChange("LineHaul")}
                                        >
                                            LineHaul Process
                                        </a>

                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-4 mt-4 " style={{ marginLeft: "-10%" }}>
                            {(this.state.btnValue === "unassigned" || this.state.btnValue === "assigned") && (
                                <div style={{ marginLeft: "24%" }}>
                                    {this.rSB(
                                        "Ticket Status",
                                        "ticketStatus",
                                        this.ticketStatus,
                                        this.stsConfig,
                                        " col-12   "
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="col-4 pull-right" style={{ marginLeft: "-2%" }}>
                            <form onSubmit={this.handleSubmit}>
                                {this.rTB("Search Ticket", "search", "mt-3")}
                            </form>
                        </div>
                        <div className="col-5 pull-right mt-3">
                            <i className="fa fa-search cursorPointor fa-lg pr-1" style={{ marginLeft: "-1px" }} onClick={this.handleSearch}></i>

                            <a>
                                <i
                                    id="btn1"
                                    data-target="#myModalProduct"
                                    data-backdrop="false"
                                    data-toggle="modal"
                                    className="badge badge-warning  cursorPointor pr-1 zoom"
                                    aria-hidden="true"
                                    title={"Select Hub"}
                                    style={{ marginLeft: "-1px" }}
                                >
                                    <b>{this.state.curHubName}</b>
                                </i>
                            </a>
                            <i className="fa fa-refresh cursorPointor fa-lg  pr-2 " title="Refresh" onClick={this.handleSearch} />
                            {/* {this.rCBHL("bulkAssign", this.bulkAssign, {
                                value: "sortKey",
                                label: "sort",
                            })} */}

                        </div>

                        <div className="form-row " style={{ marginTop: "4px", marginLeft: "1%", marginBottom: "-24px" }}>
                            {(this.state.btnValue === "assigned" || this.state.btnValue === "unassigned") && (
                                <>
                                    {this.rSB(
                                        "Pincodes :",
                                        "pincodeList",
                                        this.state.pincodeList,
                                        this.stsConfig,
                                        " col-2"
                                    )}
                                </>
                            )}
                            {(this.state.btnValue === "unassigned" || this.state.btnValue === "assigned" || this.state.btnValue === "rescheduled") && (
                                <div className="col-4" style={{ marginLeft: "14px", marginRight: "-24px", marginBottom: "-24px" }}>
                                    {this.rRBL("Order :", "order", this.order, {
                                        value: "sortKey",
                                        label: "sort",
                                    })}
                                </div>
                            )}


                            {(this.state.btnValue === "assigned" || this.state.btnValue === "rescheduled" || this.state.btnValue === "unassigned") && (
                                <div className="col-6">
                                    {this.rRBL("Flow :", "flow", this.flow, {
                                        value: "sortKey",
                                        label: "sort",
                                    })}
                                </div>
                            )}


                        </div>
                        {/* <div className="form-row " style={{ marginTop: "28px", marginLeft: "-3%", marginBottom: "2px" }}>
                            {(this.state.btnValue === "unassigned") && (
                                <div className="col-4" style={{ marginLeft: "14px", marginRight: "-24px", marginBottom: "-24px" }}>
                                    {this.rRBL("AppointmentReq :", "appointment", this.appointment, {
                                        value: "sortKey",
                                        label: "sort",
                                    })}
                                </div>
                            )}
                            {(this.state.btnValue === "unassigned") && (
                                <div className="col-4" style={{ marginLeft: "14px", marginRight: "-24px", marginBottom: "-24px" }}>
                                    {this.rRBL("PackingReq :", "packing", this.packing, {
                                        value: "sortKey",
                                        label: "sort",
                                    })}
                                </div>
                            )}
                        </div> */}
                        <div className="col-12">
                            {this.state.loading && (
                                <div className="container-fluid">
                                    <div> Loading Tickets...</div>
                                </div>
                            )}
                            {!this.state.loading && itemsCount >= 0 && (
                                <>
                                    <div className="container-fluid pull-right">
                                        <Pagination
                                            pageNo={pageNo}
                                            perPage={perPage}
                                            itemsCount={itemsCount}
                                            NameOfComp="Tickets"
                                            onPageChange={(pageNo) => this.handlePageChange(pageNo)}
                                        />
                                        {!this.state.loading && itemsCount === 0 && (
                                            <div className="alert alert-info" role="alert">
                                                <div> No Records Found! </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        {/*  */}
                    </div>
                    <div >
                        {this.state.ticketDetails.map((t) => {
                            return (
                                <span key={t.processId + "spanCover1"}>

                                    {t.blocks.map((b, index) => {

                                        return (
                                            <span key={b.ticketBlockId + "spanCover1" + index}>
                                                {(this.state.btnValue === "unassigned" && (b.blockStatus === "BLOCK_CREATED" || b.blockStatus === "CHOOSE_FE_FOR_DROP" || b.blockStatus === "CHOOSE_FE_FOR_DROP_ASC")) &&
                                                    t.flowId !== "BulkQC" && t.flowId !== "Bulk" && (
                                                        <input
                                                            type="checkbox"
                                                            name={t.ticketNo}
                                                            value={t.ticketNo}
                                                            title="Bulk Ticket Selection"
                                                            onClick={(e) => this.handleCheckBoxClick(e, b, t)}
                                                            className="multiInput"
                                                        />
                                                    )}
                                                <div
                                                    className="card mb-2 cursorPointor"
                                                    onClick={() => this.setCurBlockTicket(b, t)}
                                                    key={t.processId + b.ticketBlockId + index}
                                                >
                                                    <div
                                                        className="card-header page-todo task"
                                                        style={
                                                            this.state.curBlockDetails.ticketBlockId &&
                                                                this.state.curBlockDetails.ticketBlockId ===
                                                                b.ticketBlockId
                                                                ? { backgroundColor: "#cfedda" }
                                                                : { backgroundColor: "white" }
                                                        }
                                                    >
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <a>
                                                                    <i
                                                                        id="btn1"
                                                                        data-target="#myModal"
                                                                        data-backdrop="false"
                                                                        data-toggle="modal"
                                                                        className="cursorPointor"
                                                                        aria-hidden="true"
                                                                        title="Ticket Details"
                                                                    >
                                                                        <b style={{ color: "#737CA1" }}>{t.ticketNo}</b>
                                                                    </i>
                                                                </a>{" "}
                                                                | <span title="orderNumber">{t.orderNo}</span>
                                                                {b.blockStatus && (
                                                                    <span
                                                                        className={
                                                                            "badge badge-success badge-normal pull-right"
                                                                        }
                                                                    >
                                                                        {b.blockCode} -{" "}
                                                                        {this.replaceString(b.blockStatus)}
                                                                    </span>
                                                                )}

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12">
                                                                <span
                                                                    className={
                                                                        t.ticketPriority === "high"
                                                                            ? "badge badge-danger"
                                                                            : "badge badge-primary badge-05assign"
                                                                    }
                                                                >
                                                                    {t.ticketPriority}
                                                                </span>
                                                                <span className="badge badge-info mr-1 ml-1">
                                                                    {t.flowCode}
                                                                </span>
                                                                {t.flowId === "InstallationV1" && (
                                                                    <span className="badge badge-warning mr-1 ml-1">
                                                                        {t.primaryDetails.ticketType}
                                                                    </span>
                                                                )}

                                                                {t.flowId === "InstallationV1" &&
                                                                    t.retailerCode === "DRP" &&
                                                                    t.primaryDetails &&
                                                                    t.primaryDetails.serviceLevel && (
                                                                        <span className="badge badge-success badge-normal mr-1 ml-1">
                                                                            {t.primaryDetails.serviceLevel}
                                                                        </span>
                                                                    )}

                                                                <span>{epoch2ddmmyy(t.dateOfTicket)}</span>{" "}
                                                                {b.flowStatus === "PICK_WEB" && (
                                                                    <span className="badge badge-secondary mr-1 ml-1" data-tip={t.products[0].srcAdd1 + " : " + t.products[0].srcAdd2} >
                                                                        {"Pickup-" + t.products[0].srcPincode}
                                                                        <ReactTooltip
                                                                        />
                                                                    </span>

                                                                )}
                                                                {b.flowStatus === "DROP_WEB" && (

                                                                    <span className="badge badge-secondary  mr-1 ml-1" data-tip={t.products[0].dstAdd1 + " : " + t.products[0].dstAdd2}>
                                                                        {"Drop-" + t.products[0].dstPincode}
                                                                        <ReactTooltip
                                                                            multiline={true}
                                                                        />
                                                                    </span>
                                                                )}

                                                                <span className="pull-right">
                                                                    {t.retailerNickName}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* </input> */}
                                            </span>
                                        );
                                    })}
                                </span>
                            );
                        })}
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="myModal"
                    role="dialog"
                    aria-labelledby="myModal"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog momodel modal-fluid modal-xl " style={{ marginLeft: "35%", marginTop: "0%" }} role="document">
                        <div className="modal-content-full-width modal-content" style={{ backgroundColor: " #e8e8e8" }}>
                            <div className="modal-header" >
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    <h5><b title="TicketNumber"> {this.state.curTicketDetails.ticketNo} | <span title="conComplaintNumber">{this.state.curTicketDetails.conComplaintNo}</span> | <span className="badge badge-info mr-1 ml-1">
                                        {this.state.curTicketDetails.flowId}
                                    </span></b></h5>
                                </h5>

                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">

                                <div class="container-fluid">
                                    <h1 class="h3 mb-2"></h1>
                                    <div class="row">
                                        <div class="col-lg-5" style={{ marginTop: "-16px" }}>
                                            <div className="card " style={{ marginBottom: "1rem" }}>
                                                <div className="card-body ">
                                                    <p
                                                        style={{
                                                            marginBottom: "1rem"
                                                        }}
                                                    >
                                                        <b>Status :</b> <span class="text-success"><b>{this.state.curBlockDetails.blockStatus}</b></span>
                                                    </p>
                                                    <p>
                                                        <b>Product Category:</b>{" "}
                                                        {this.state.TicketProductDetails.productCode}{" "} | {this.state.TicketProductDetails.productName}{" "}
                                                    </p>
                                                    <p>
                                                        <b>Model & Brand:</b>{" "}
                                                        {this.state.TicketProductDetails.model}{" "} , {this.state.TicketProductDetails.brand}{" "}
                                                    </p>
                                                    <p>
                                                        <b>Created At : </b>
                                                        {epoch2ddmmyyyyhhmmss(this.state.TicketPrimarytDetails.createdAt)}
                                                    </p>
                                                    <p>
                                                        <b>Appointment At:</b>{" "}
                                                        {epoch2ddmmyyyyhhmmss(this.state.curBlockDetails.feAppointmentDateTime)}
                                                    </p>
                                                    <p>
                                                        <b>Linehaul At:</b>{" "}
                                                        {this.state.TicketProductDetails.linehaulAwb} | {epoch2ddmmyyyyhhmmss(this.state.TicketProductDetails.linehaulDispatchedAt)}
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="card " style={{ marginBottom: "1rem" }}>
                                                <div className="card-body ">
                                                    <h1
                                                        style={{
                                                            // backgroundColor: "#e2cdcd",
                                                            textAlign: "center",
                                                            marginTop: "-20px",
                                                            marginLeft: "-20px",
                                                            marginRight: "-20px",
                                                            padding: "5px",
                                                            marginBottom: "1rem"
                                                        }}
                                                    >
                                                        PickUp Location
                                                    </h1>
                                                    <p>
                                                        <b>Consumer Name :</b>{" "}
                                                        {this.state.TicketProductDetails.srcContactPerson}
                                                    </p>
                                                    <p>
                                                        <b>Address :</b>{" "}
                                                        {this.state.TicketProductDetails.srcAdd1},
                                                        {this.state.TicketProductDetails.srcLandmark},
                                                        {this.state.TicketProductDetails.srcCity}-
                                                        {this.state.TicketProductDetails.srcState}-
                                                        {this.state.TicketProductDetails.srcPincode}
                                                    </p>
                                                    <p>
                                                        <b>Email :</b>{" "}
                                                        {this.state.TicketProductDetails.srcEmailId}
                                                    </p>
                                                    <p>
                                                        <b>Phone :</b>{" "}
                                                        {this.state.TicketProductDetails.srcContact1},
                                                        {this.state.TicketProductDetails.srcContact2}
                                                    </p>
                                                    <p>
                                                        <b>FE Info :</b>{" "}
                                                        {this.state.TicketProductDetails.pickUpFeName} | {epoch2ddmmyyyyhhmmss(this.state.TicketProductDetails.pickUpTime)}
                                                    </p>


                                                </div>
                                            </div>
                                            <div className="card ">
                                                <div className="card-body ">
                                                    <h1
                                                        style={{
                                                            // backgroundColor: "#ddb2c8",
                                                            textAlign: "center",
                                                            marginTop: "-20px",
                                                            marginLeft: "-20px",
                                                            marginRight: "-20px",
                                                            padding: "5px",
                                                            marginBottom: "1rem"
                                                        }}
                                                    >
                                                        Drop Location
                                                    </h1>
                                                    <p>
                                                        <b>Contact Person : </b>
                                                        {this.state.TicketProductDetails.dstContactPerson}
                                                    </p>
                                                    <p>
                                                        <b>Address : </b>
                                                        {this.state.TicketProductDetails.dstAdd1},
                                                        {this.state.TicketProductDetails.dstLandmark},
                                                        {this.state.TicketProductDetails.dstLocation},
                                                        {this.state.TicketProductDetails.dstCity},
                                                        {this.state.TicketProductDetails.dstState}-
                                                        {this.state.TicketProductDetails.dstPincode}
                                                    </p>
                                                    <p>
                                                        <b>Phone :</b>
                                                        {this.state.TicketProductDetails.dstContact1},
                                                        {this.state.TicketProductDetails.dstContact2}
                                                    </p>
                                                    <p>
                                                        <b>FE Info :</b>{" "}
                                                        {this.state.TicketProductDetails.dropFeName} | {epoch2ddmmyyyyhhmmss(this.state.TicketProductDetails.dropTime)}
                                                    </p>
                                                </div>
                                            </div>

                                        </div>



                                        <div id="cocardHistory" class="col-lg-7" style={{ marginTop: "-16px" }}>
                                            <div class="card" style={{ width: "101%" }}>
                                                <div class="p-2 bg-light bg-opacity-10">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <ul class="cbp_tmtimeline">
                                                                {this.state.curMisReportHistory &&
                                                                    <>
                                                                        {this.state.curMisReportHistory.map((t) => {
                                                                            return (
                                                                                <>
                                                                                    {t.blockStatus && (
                                                                                        <li>
                                                                                            {t.processedAt && (
                                                                                                <time
                                                                                                    className="cbp_tmtime"
                                                                                                    dateTime="2022-04-04T03:45"
                                                                                                >
                                                                                                    <span style={{ fontSize: "10px" }}>{IstToUTC(t.processedAt)}</span>{" "}
                                                                                                </time>
                                                                                            )}
                                                                                            {!t.processedAt && (
                                                                                                <time
                                                                                                    className="cbp_tmtime"
                                                                                                    dateTime="2022-04-04T03:45"
                                                                                                >
                                                                                                    <span>{"--"}</span>{" "}
                                                                                                </time>
                                                                                            )}

                                                                                            <div className="cbp_tmicon bg-info">
                                                                                                <i className="zmdi zmdi-label"></i>
                                                                                            </div>
                                                                                            {t.blockStatus !== "null" && (
                                                                                                <div className="cbp_tmlabel ">
                                                                                                    <h2>
                                                                                                        <a style={{ color: "#75bb06" }}>
                                                                                                            <b style={{ fontSize: "11px" }}>{this.replaceString(t.blockStatus)}</b>{" "}
                                                                                                        </a>
                                                                                                    </h2>
                                                                                                    <time
                                                                                                        className="cbp_tmtime "
                                                                                                        dateTime="2022-04-04T03:45"
                                                                                                    >
                                                                                                        <span className="pull-right" style={{ marginRight: "-330px", color: "#c6bcbc", fontSize: "10px", marginTop: "-44px" }}>{IstToUTC(t.createdAt)}</span>{" "}
                                                                                                        <span className="pull-right" style={{ marginRight: "-330px", color: "#c6bcbc", fontSize: "10px", marginTop: "-10px" }}>{t.firstName} {t.lastName} | {t.userName} | {t.phone}</span>{" "}

                                                                                                    </time>
                                                                                                </div>
                                                                                            )}
                                                                                        </li>
                                                                                    )}

                                                                                </>
                                                                            );
                                                                        })}
                                                                    </>
                                                                }

                                                            </ul>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        {this.state.TicketRemarks.length > 0 &&
                                                            <div className="col-md-12">
                                                                <div className="card " >
                                                                    <div className="card-body bg-custom-6">
                                                                        <h1
                                                                            style={{
                                                                                backgroundColor: "#ced4da",
                                                                                textAlign: "center",
                                                                                marginTop: "-20px",
                                                                                marginLeft: "-20px",
                                                                                marginRight: "-20px",
                                                                                padding: "5px",
                                                                            }}
                                                                        >
                                                                            Remarks
                                                                        </h1>
                                                                        {this.state.TicketRemarks.map((t) => {
                                                                            return (
                                                                                <>
                                                                                    <p>
                                                                                        {">> " + t.remark} | {epoch2ddmmyyyyhhmmss(t.createdAt)}
                                                                                    </p>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="modal fade"
                    id="myModalProduct"
                    role="dialog"
                    aria-labelledby="myModalProduct"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">
                                    Select Hub
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    {this.rRBL(
                                        "",
                                        "hub",
                                        this.state.hubList,
                                        this.stsConf
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.mode === "LineHaul" && (
                        <LineHaulWrapper

                            data-target="#myModals"
                            data-backdrop="false"
                            data-toggle="modal"
                        />
                    )
                }
            </React.Fragment >
        );
    }
}

export default TicketsListNew;
