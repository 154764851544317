import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import $ from "jquery";
// import "bootstrap";
import {
  createModule,
  saveModule,
  deleteModule,
} from "./../../../services/plms/moduleServices";
import * as _plms from "./../../../services/plms/_plmsServices";

class ModalModule extends FormLib {
  state = {
    data: {
      moduleId: "",
      projectId: "",
      prModule: "",
      podOwner: "",
      sort: "",
      status: "99pipe",
    },
    errors: {},

    projects: [],
    curProject: {},
  };

  schema = {
    moduleId: Joi.optional().label("moduleId"),
    projectId: Joi.string().required().label("Project*"),
    prModule: Joi.string().required().label("Module*"),
    podOwner: Joi.string().required().label("Owner*"),
    status: Joi.string().required().label("Status*"),
    sort: Joi.number().required().label("Sort*"),
  };

  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createModule(this.state.data);
      this.resetData(this.state.curProject);
      this.props.onLoadModules(this.state.curProject.projectId);
    }
    if (this.state.btnValue === "edit") {
      res = await saveModule(this.state.data);
      this.props.onLoadModules(this.state.curProject.projectId, false);
    }
    if (this.state.btnValue === "delete") {
      res = await deleteModule(this.state.data);
      $("#curModuleButton").click();
      this.resetData(this.state.curProject);
      this.props.onLoadModules(this.state.curProject.projectId);
    }

    toast.toastMsg(res);
    // if (res.data && res.data.success && res.data.success === true) {
    //   toast.toastSuccess(res.data.msg);
    // } else {
    //   toast.toastError(
    //     (res.data && res.data.msg) || "Error Happened in getting Error Msg"
    //   );
    // }
  }

  componentDidMount() {
    this.setProjects(this.props.projects);
    this.setCurProject(this.props.curProject);
    this.setCurModule(this.props.curProject, this.props.curModule);
    this.setOwners();
  }
  setOwners = () => {
    this.setState({ owners: _plms.users.owners });
    this.setState({ ownerConf: _plms.users.ownerConf });
  };
  setProjects(projects) {
    this.setState({ projects: projects });
  }
  setCurProject(p) {
    this.setState({ curProject: p });
  }
  setCurModule(p, m) {
    const mod = { ...m };
    delete mod.seq;
    if (m.moduleId && m.moduleId !== "") {
      this.setState({ data: mod });
    } else {
      this.resetData(p);
    }
  }

  resetData(p) {
    this.setState({
      data: {
        projectId: p.projectId || "",
        moduleId: "",
        prModule: "",
        podOwner: "BLR0000",
        status: "99pipe",
      },
    });
  }
  status = [
    { sortKey: "00open", sort: "Open" },
    { sortKey: "05assign", sort: "Assign" },
    { sortKey: "08plan", sort: "Plan" },

    { sortKey: "10wip", sort: "WIP" },
    { sortKey: "15hold", sort: "Hold" },
    { sortKey: "20dev", sort: "Dev" },
    { sortKey: "25test", sort: "Test" },
    { sortKey: "30done", sort: "Done" },
    { sortKey: "99pipe", sort: "Pipe" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;

    const projects = this.state.projects;

    return (
      <div className="container-fluid">
        <div className="modal fade" id="myModuleModel">
          <div
            className="modal-dialog modal-lg"
            style={{ marginRight: "70px" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Modules</h4>
                <button
                  type="button"
                  className="close"
                  id="curModuleButton"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  <br />
                  {this.rTBH("ModuleId", "moduleId")}
                  {this.rSB("Projects", "projectId", projects, {
                    value: "projectId",
                    label: "project",
                  })}
                  {this.rTB("Module", "prModule")}
                  {this.rSB(
                    "Owner*",
                    "podOwner",
                    this.state.owners,
                    this.state.ownerConf
                  )}
                  {this.rNB("Sort", "sort")}
                  <div className="form-row">
                    {this.rRBL("Status", "status", this.status, this.stsConf)}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {this.state.data.moduleId === "" &&
                  this.rBtnSP("Create", "create")}
                {this.state.data.moduleId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {/* {this.rBtnSD("Delete", "delete")} */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalModule;
