import React from "react";

import FormLib from "../../../_common/FormLib";
import Joi from "joi-browser";
import { requestCall } from "../../../../services/requestV1/requestServices";
import { getActivity } from "./../../../../services/masters/hubServices";

import { toast } from "react-toastify";
import dateFormat from "dateformat";
import RequestApprove2Open from "./RequestApprove2Open";
import { IoIosArrowDropdown, IoMdArrowDropdown } from "react-icons/io";

class RequestReady extends FormLib {
  state = {
    data: {
      pymt_mode: "IMPS",
      bank: "ICICI",
    },
    showDropdown: false,
    errors: {},
    transactionRequest: {},
  };

  schema = {
    bank: Joi.string().required().label("Bank"),
    pymt_mode: Joi.string().required().label("Payment Mode"),
    approv_remark: Joi.string().required().label("Payment Remarks"),
    ready_expense_head: Joi.string().required().label("Ready Expense Head"),
  };

  pymt_mode = [
    { pymt_mode: "IMPS", pymt_modeLabel: "IMPS" },
    { pymt_mode: "RTGS", pymt_modeLabel: "RTGS" },
    { pymt_mode: "NEFT", pymt_modeLabel: "NEFT" },
    { pymt_mode: "FT", pymt_modeLabel: "FT" },
  ];

  bank = [{ bank: "ICICI", bankName: "ICICI" }];

  async componentDidMount() {
    this.setPayment();

    let list = await getActivity();
    this.setState({ activities: list.data.data });
  }

  setPayment() {
    this.setState({ transactionRequest: this.props.data });
    let data = this.state.data;
    data["ready_expense_head"] = this.props.data["APPROV_EXPENSE_HEAD"];
    if (this.props.data.PLAYER_TYPE === "employee") {
      data["approv_remark"] =
        `Paid to ` + this.props.data.PLAYER_TYPE + " " + this.props.data.E_NAME;
      this.setState({
        data,
      });
      // console.log("-----------> " + JSON.stringify(this.state.data));
    } else if (this.props.data.PLAYER_TYPE === "vendor") {
      data["approv_remark"] =
        `Paid to  ` +
        this.props.data.PLAYER_TYPE +
        " " +
        this.props.data.V_NAME;
      this.setState({
        data,
      });
    }
    // if (this.props.data.E_BANK.substring(3) === "ICIC") {
    // }

    if (
      this.props.data.V_BANK !== null &&
      this.props.data.V_BANK.substring(0, 4) === "ICIC"
    ) {
      data.pymt_mode = "FT";
      this.setState({ data });
    }

    if (
      this.props.data.E_BANK !== null &&
      this.props.data.E_BANK.substring(0, 4) === "ICIC"
    ) {
      data.pymt_mode = "FT";
      this.setState({ data });
    }
  }
  dropdown = () => {
    // console.log("drodDown clicked");
    this.setState({ showDropdown: !this.state.showDropdown });
  };
  async doSubmit() {
    // console.log({ doSubmitState: this.state });
    const params = {
      request_id: this.state.transactionRequest.REQUEST_ID,
      approve_id: this.state.transactionRequest.APPROVE_ID,
      approv_amt: this.state.transactionRequest.APPROV_AMT,
      ...this.state.data,
      status: this.state.btnValue,
    };
    // console.log({ params, btnValue: this.state.btnValue });
    let requestRaised = await requestCall(params, this.state.btnValue);
    if (requestRaised.data.success === true) {
      toast.success(() => <div>{requestRaised.data.msg}</div>);
      document.getElementById("myForm").reset();
      this.setState({ data: "" });
    } else {
      toast.error(() => <div>{requestRaised.data.msg}</div>);
    }
    this.props.requestStatusChange();
  }

  render() {
    const user = this.props.user;
    // console.log({ RequestReadyState: this.state });
    const { showDropdown } = this.state;
    return (
      <div>
        <React.Fragment>
          <div
            style={{
              marginTop: "-5rem",
              position: "fixed",
              maxHeight: "95%",
              // overflow: "scroll",
            }}
          >
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.props.closeModal}
            >
              <span aria-hidden="true"><h6> Approv2Ready &times;</h6></span>
            </button>
            <div className="card">
              <div className="card-header bg-warning text-white">
                <div className="row">
                  <div className="col-6">
                    {this.state.transactionRequest.M_CLIENT}
                  </div>
                  <div className="col-6 text-right">
                    {dateFormat(
                      this.state.transactionRequest.CREATED_AT,
                      "dd-mm-yyyy"
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-8">
                    {this.state.transactionRequest.REF_ID} [
                    {this.state.transactionRequest.M_HUB}]
                  </div>
                  <div className="col-4 text-right">
                    #{this.state.transactionRequest.RE_PREFIX}
                    {this.state.transactionRequest.SEQ}
                  </div>
                </div>
              </div>
              <div className="card-body border-top">
                <div className="row">
                  <div className="col-4">
                    <span className="req-label">Request Type </span>
                    <span className="req-value">
                      {this.state.transactionRequest.REQUEST_TYPE}
                    </span>
                  </div>
                  {this.state.transactionRequest.PLAYER_TYPE === "employee" && (
                    <div className="col-8">
                      <span className="req-label">Employee</span>
                      <span className="req-value">
                        {this.state.transactionRequest.E_NAME} [
                        {this.state.transactionRequest.PLAYER_CODE}]
                      </span>
                    </div>
                  )}
                  {this.state.transactionRequest.PLAYER_TYPE === "vendor" && (
                    <div className="col-4">
                      <span className="req-label">Vendor</span>
                      <span className="req-value">
                        {this.state.transactionRequest.V_NAME} [
                        {this.state.transactionRequest.PLAYER_CODE}]
                      </span>
                    </div>
                  )}
                  {/* <div className="col-4">
                <span className="req-label">Flow </span>
                <span className="req-value">
                  {this.state.transactionRequest.M_FLOW}
                </span>
              </div> */}
                </div>
              </div>

              <div className="card-body row">
                <div className="col-6">
                  <span className="req-label">Total Amt Approved </span>
                  <span className="req-value">
                    {this.state.transactionRequest.AMT_APPROVED}
                  </span>
                </div>
                <div className="col-6 text-right">
                  <span className="req-label">Requested Amount </span>
                  <span className="req-value">
                    {this.state.transactionRequest.REQ_AMT}
                  </span>
                </div>
                <div className="col-6 mt-1">
                  <span className="req-label">Approved Amount </span>
                  <span className="req-value">
                    {this.state.transactionRequest.APPROV_AMT}
                  </span>
                </div>
                <div className="col-6 mt-1 text-right">
                  <span className="req-label">APPROVER NAME </span>
                  <span className="req-value">
                    {this.state.transactionRequest.APPROVER_NAME}
                  </span>
                </div>
              </div>

              {user &&
                (user["roleCode"] === "finance_head" ||
                  (user["designation"] &&
                    user["designation"] === "Finance Head") ||
                  user["designation"] === "Finance Executive" ||
                  user["designation"] === "Finance Manager" ||
                  user["playerId"] === "player1") && (
                  <div
                    className="card-body border-top"
                    style={{ maxheight: "8rem", marginTop:"-1.3rem" }}
                  >
                    Additional Details
                    <button
                      onClick={this.dropdown}
                      style={{
                        border: "none",

                      }}
                    >
                      <IoMdArrowDropdown
                        style={{
                          width: "3.5rem",
                          height: "1rem",
                          color: "blue",
                        }}
                      />
                      <form
                        id="myForm"
                        onSubmit={this.handleSubmit}
                        style={{
                          display: showDropdown ? "block" : "none",
                          width: "25rem",
                        }}
                      >
                        <div >
                          <div className="panel-group widget-request" >
                            <div className="panel panel-info">
                              <label>Activity Details</label>
                              <div className="panel-body">
                                {this.state.transactionRequest.M_ACTIVITY}
                              </div>
                            </div>
                            <div className="panel panel-info">
                              <label>Request Details</label>
                              <div className="panel-body">
                                {this.state.transactionRequest.REQUEST_DETAILS}
                              </div>
                            </div>

                            <div className="panel panel-info">
                              <label className="label-approve">
                                Approve Remarks
                              </label>
                              <div className="panel-body">
                                {this.state.transactionRequest.APPROV_REMARK}
                              </div>
                            </div>
                          </div>
                          <div>
                          <div className="form-row row col-2 pt-2">
                            {this.rRBL("Bank", "bank", this.bank, {
                              value: "bank",
                              label: "bankName",
                            })}
                          </div>
                          <div className="form-row row">
                            {this.rRBL(
                              "Payment Mode",
                              "pymt_mode",
                              this.pymt_mode,
                              {
                                value: "pymt_mode",
                                label: "pymt_modeLabel",
                              },
                              "pull-left"
                              )
                            }
                          </div>
                        </div>
                        </div>
                      </form>
                    </button>
                    <div style={{ marginTop: "2rem" }}>
                      {this.rSB(
                        "Ready Expense Head *",
                        "ready_expense_head",
                        this.state.activities,
                        { value: "EXPENSE_HEAD_ID", label: "EXPENSE_HEAD" },
                        " col "
                      )}

                      {this.rTA("Payment Remarks", "approv_remark")}
                    </div>
                  </div>
                )}
              {user &&
                (user["roleCode"] === "finance_head" ||
                  (user["designation"] &&
                    user["designation"] === "Finance Head") ||
                  user["designation"] === "Finance Executive" ||
                  user["designation"] === "Finance Manager" ||
                  user["roleCode"] === "coordinator_ho" ||
                  user["playerId"] === "player1") && (
                  <div className="row p-1">
                    {(user["roleCode"] === "finance_head" ||
                      (user["designation"] &&
                        user["designation"] === "Finance Head") ||
                      user["designation"] === "Finance Executive" ||
                      user["designation"] === "Finance Manager" ||
                      user["playerId"] === "player1") && (
                      <div className="col-12">
                        {this.rBtnS(
                          "Make Ready",
                          "ready",
                          "btn btn-success btn-sm btn-block"
                        )}
                      </div>
                    )}
                    <div className="col-12">
                      <div className="card-body border-top">
                        <div
                          className="form-subHead"
                          data-toggle="collapse"
                          href="#Revert"
                          aria-expanded="false"
                          aria-controls="Revert"
                        >
                          Revert to Open
                          <i
                            className="fa fa-angle-down fa-1x text-danger ml-1 pull-right"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <div className="collapse" id="Revert">
                          <RequestApprove2Open
                            key={this.state.transactionRequest.APPROVE_ID}
                            data={this.state.transactionRequest}
                            requestStatusChange={this.props.requestStatusChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </React.Fragment>
      </div>
    );
  }
}
export default RequestReady;
