import _http from "./../_httpService";
import Joi from "joi-browser";

import config from "./../../config.json";
import { dateByDays } from "./../../utils/DateUtils";

const apiURLValidata = config[config.build].apiURLValidata + "shipments/";

export function initData() {
  return {
    bdShipmentsId: "",
    noTickets: "1",
    ticket0: "",

    shArea: "",
    shClientName: "",
    shPincode: "",
    shAdd1: "",
    shAdd2: "",
    shAdd3: "",
    shMob: "",
    shPhone: "",
    shEmail: "",

    cnConsiName: "",
    cnPincode: "",
    cnAdd1: "",
    cnAdd2: "",
    cnAdd3: "",
    cnMob: "",
    cnPhone: "",
    cnEmail: "",

    pickDate: dateByDays(1),
    pickTime: "14:00",
    prPieces: "",
    prWeight: "",

    length0: "",
    breadth0: "",
    height0: "",

    status: "",

    prCode: "A",
    prSubProd: "P",
    pdfLb: "A4 Single Copy",
    delNet: "yes",
    pickUp: "yes",
    proType: "dox",
    forcePu: "no",
    isRvp: "yes",
    wayBill: "req",
  };
}
export function setData(mi, stateData) {
  // console.log(mi.dimensions);

  const data = stateData;
  data.bdShipmentsId = mi.bdShipmentsId;
  data.noTickets = mi.noTickets;
  data.shClientName = mi.shClientName;
  data.shArea = mi.shArea;
  data.shPincode = mi.shPincode;
  data.shAdd1 = mi.shAdd1;
  data.shAdd2 = mi.shAdd2;
  data.shAdd3 = mi.shAdd3;
  data.shMob = mi.shMob;
  data.shPhone = mi.shPhone;
  data.shEmail = mi.shEmail;
  data.cnConsiName = mi.cnConsiName;
  data.cnPincode = mi.cnPincode;
  data.cnAdd1 = mi.cnAdd1;
  data.cnAdd2 = mi.cnAdd2;
  data.cnAdd3 = mi.cnAdd3;
  data.cnMob = mi.cnMob;
  data.cnPhone = mi.cnPhone;
  data.cnEmail = mi.cnEmail;
  data.prPieces = mi.prPieces;
  data.prWeight = mi.prWeight;
  data.prCode = mi.prCode;
  data.prSubProd = mi.prSubProd;
  data.status = mi.status;
  data.delNet = mi.delNet;
  data.pdfLb = mi.pdfLb;
  data.forcePu = mi.forcePu;
  data.pickUp = mi.pickUp;
  data.proType = mi.proType;
  data.isRvp = mi.isRvp;
  data.wayBill = mi.wayBill;

  mi.tickets.split("|").map((ticket, i) => {
    data["ticket" + i] = ticket;
    return null;
  });

  JSON.parse(mi.dimensions).map((r, i) => {
    data["length" + i] = r.length;
    data["breadth" + i] = r.breadth;
    data["height" + i] = r.height;
    return null;
  });

  const set = JSON.parse(mi.settings);

  data.delNet = set.delNet;
  data.pdfLb = set.pdfLb;
  data.forcePu = set.forcePu;
  data.pickUp = set.pickUp;
  data.proType = set.proType;
  data.isRvp = set.isRvp;
  data.wayBill = set.wayBill;

  return data;
}
export function getSchema() {
  return {
    noTickets: Joi.any().optional().label("No of Ticket"),
    id: Joi.any().optional().label("ID"),
    ticket0: Joi.any().optional().label("ticket 0"),
    ticket1: Joi.any().optional().label("ticket 1"),
    ticket2: Joi.any().optional().label("ticket 2"),
    ticket3: Joi.any().optional().label("ticket 3"),

    shClientName: Joi.any().optional().label("Client Name"),
    shArea: Joi.any().optional().label("Area Code"),
    shPincode: Joi.any().optional().label("Pincode"),
    shAdd1: Joi.any().optional().label("Address 1"),
    shAdd2: Joi.any().optional().label("Address 2"),
    shAdd3: Joi.any().optional().label("Address 3"),
    shMob: Joi.any().optional().label("Mobile"),
    shPhone: Joi.any().optional().label("Phone"),
    shEmail: Joi.any().optional().label("Email"),
    cnConsiName: Joi.any().optional().label("Consignee Name"),
    cnPincode: Joi.any().optional().label("Pincode"),
    cnAdd1: Joi.any().optional().label("Address 1"),
    cnAdd2: Joi.any().optional().label("Address 2"),
    cnAdd3: Joi.any().optional().label("Address 3"),
    cnMob: Joi.any().optional().label("Mobile"),
    cnPhone: Joi.any().optional().label("Phone"),
    cnEmail: Joi.any().optional().label("Email"),
    prPieces: Joi.any().optional().label("Packed Pieces"),
    prWeight: Joi.any().optional().label("Weight"),
    pickDate: Joi.any().optional().label("Pickup Date"),
    pickTime: Joi.any().optional().label("Pickup Time"),

    length0: Joi.any().optional().label("length1"),
    breadth0: Joi.any().optional().label("breadth1"),
    height0: Joi.any().optional().label("height1"),

    length1: Joi.any().optional().label("length2"),
    breadth1: Joi.any().optional().label("breadth2"),
    height1: Joi.any().optional().label("height2"),

    length2: Joi.any().optional().label("length3"),
    breadth2: Joi.any().optional().label("breadth3"),
    height2: Joi.any().optional().label("height3"),

    length3: Joi.any().optional().label("length4"),
    breadth3: Joi.any().optional().label("breadth4"),
    height3: Joi.any().optional().label("height4"),

    bdShipmentsId: Joi.any().optional().label("bdShipmentsId"),
    status: Joi.any().optional().label("status"),

    prCode: Joi.any().optional().label("prCode"),
    prSubProd: Joi.any().optional().label("prSubProd"),
    pdfLb: Joi.any().optional().label("pdfLb"),
    delNet: Joi.any().optional().label("delNet"),
    forcePu: Joi.any().optional().label("forcePu"),
    pickUp: Joi.any().optional().label("pickUp"),
    proType: Joi.any().optional().label("proType"),
    isRvp: Joi.any().optional().label("isRvp"),
    wayBill: Joi.any().optional().label("wayBill"),
  };
}
export function stdSettings() {
  return {
    hubList: [
      { value: "Bangaloru", label: "Bangalore" },
      { value: "Mysore", label: "Mysore" },
    ],
    hubType: [
      { label: "Shipping", value: "shipping" },
      { label: "Consignee", value: "consignee" },
      { label: "Both", value: "both" },
    ],
    copy: [
      { value: "A4 Single Copy", label: "A4 Single Copy" },
      { value: "A4 Three Copies", label: "A4 Three Copies" },
      { value: "55*30 Label", label: "55*30 Label" },
      { value: "89*60 Label", label: "89*60 Label" },
    ],
    wayBill: [
      { label: "Required", value: "req" },
      { label: "Not Required", value: "notReq" },
    ],
    delNet: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    pickUp: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    proType: [
      { label: "DOX", value: "dox" },
      { label: "NDOX", value: "ndox" },
    ],
    forcePu: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
    isRvp: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" },
    ],
  };
}

export function getHubs() {
  return [
    {
      hubId: "blr",
      hub: "Bengaluru",
      contactName: "Brl HO",
      areaCode: "blr",
      add1: "add1",
      add2: "add2",
      add3: "add3",
      mobile: "9876543211",
      phone: "9874561233",
      email: "blr@bizlog.in",
      pincode: "560050",
    },
    {
      hubId: "mys",
      hub: "Mysore",
      contactName: "Mysore HO",
      areaCode: "mys",
      add1: "madd1",
      add2: "madd2",
      add3: "madd3",
      mobile: "8876543211",
      phone: "8874561233",
      email: "mys@bizlog.in",
      pincode: "560100",
    },
  ];
}
export function getHubLists() {
  return [
    {
      hubId: "blr",
      hub: "Bengaluru",
    },
    {
      hubId: "mys",
      hub: "Mysore",
    },
    {
      hubId: "clt",
      hub: "Calicut",
    },
  ];
}

export function getShipmentsByStatus(mode, pageNo = 1) {
  var processEndpoint;
  switch (mode) {
    case "open":
      processEndpoint = apiURLValidata + "byStatus/open/" + pageNo;
      break;
    case "intransit":
      processEndpoint = apiURLValidata + "byStatus/transit/" + pageNo;
      break;
    case "closed":
      processEndpoint = apiURLValidata + "byStatus/closed/" + pageNo;
      break;
    case "rejected":
      processEndpoint = apiURLValidata + "byStatus/reject/" + pageNo;
      break;
    default:
      processEndpoint = apiURLValidata + "byStatus/open/" + pageNo;
  }

  return _http.get(processEndpoint, _http.headerJWT());
}

export function processShipment(item, action) {
  const payload = buildData(item, action);

  switch (action) {
    case "save":
      return saveShipment(payload);
    case "closed":
      return saveShipmentStatus(payload, action);
    case "rejected":
      return saveShipmentStatus(payload, action);
    case "book":
      return bookAWB(item);
    default:
      return null;
  }
}

function saveShipment(payload) {
  var processEndpoint;
  processEndpoint = apiURLValidata;
  if (payload["bdShipmentsId"]) {
    console.log("logic to update");
    processEndpoint = apiURLValidata + "/update/" + payload["bdShipmentsId"];
    delete payload["bdShipmentsId"];
  }
  // console.log(JSON.stringify(payload));
  return _http.post(processEndpoint, payload, _http.headerJWT());
}

function saveShipmentStatus(payload, status) {
  var processEndpoint =
    apiURLValidata + "update/status/" + payload["bdShipmentsId"] + "/" + status;
  return _http.post(processEndpoint, payload, _http.headerJWT());
}

function bookAWB(payload) {
  // console.log(JSON.stringify(payload));
  return _http.post(
    "http://localhost/works/rnd/bluedart/pickUpRegister.php",
    payload,
    _http.headerJWT()
  );
}

export function buildData(item, action) {
  const data = { ...item };

  //for Tickets
  data["tickets"] = "";
  [...Array(parseInt(data["noTickets"]))].map((elementInArray, i) => {
    if (i !== 0) data["tickets"] += "|";
    data["tickets"] += data["ticket" + i];
    delete data["ticket" + i];
    return null;
  });

  const dimensions = [];
  //for Dimensions
  [...Array(parseInt(data["prPieces"]))].map((elementInArray, i) => {
    dimensions.push({
      length: data["length" + i],
      breadth: data["breadth" + i],
      height: data["height" + i],
    });
    delete data["length" + i];
    delete data["breadth" + i];
    delete data["height" + i];
    return null;
  });
  data.dimensions = JSON.stringify(dimensions);

  const settings = {
    prCode: data["prCode"],
    prSubProd: data["prSubProd"],
    pdfLb: data["pdfLb"],
    delNet: data["delNet"],
    pickUp: data["pickUp"],
    proType: data["proType"],
    forcePu: data["forcePu"],
    isRvp: data["isRvp"],
    wayBill: data["wayBill"],
  };
  delete data["prCode"];
  delete data["prSubProd"];
  delete data["pdfLb"];
  delete data["delNet"];
  delete data["pickUp"];
  delete data["proType"];
  delete data["forcePu"];
  delete data["isRvp"];
  delete data["wayBill"];

  data["hubLoc"] = "100064";
  data["createdBy"] = "hari";
  data["updatedBy"] = "";

  // data["noTickets"] = 1;

  data.settings = JSON.stringify(settings);
  // console.log(action);
  if (action === "save") {
    delete data["bdShipmentsId"];
    delete data["docketNo"];
    delete data["crediRefNo"];
    data["status"] = "open";
  }

  // console.log(JSON.parse(data.dimensions));

  // console.log(JSON.stringify(data));

  data.pickTime = data.pickTime + ":00";
  return data;
}
/*
export function barcodeService() {
  const { inputRef } = useBarcode({
    value: "12345 8908",
    options: {
      background: "#ccffff"
    }
  });

  return (
    <div className="App">
      <h1>react-barcodes</h1>
      <svg ref={inputRef} />;
    </div>
  );
}
*/
