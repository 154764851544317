import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLCore;
function api(path, subURL = "/flows") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function assignRetailerflow(params) {
  return _http.put("http://192.168.3.73:9091/retailer_flow/asign", params);
}

export function unassignRetailerflow(params) {
  return _http.put("http://192.168.3.73:9091/retailer_flow/unasign", params);
}

export function getRetailerAssignedFLow(params) {
  // console.log("Service---> " + params);
  return _http.get(
    "http://192.168.3.73:9091/retailer_flow/asigned_flow/" + params
  );
}

export function getRetailers() {
  return _http.get(api("teams"), _http.headerJWT());
}
