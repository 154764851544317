import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import swal from "sweetalert";
import Table from "../../_common/Table";
import Pagination from "../../_common/Pagination";

import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";
import {
  getTeamContactsAll,
  createTeamContacts,
  saveTeamContacts,
  deleteTeamContacts,
} from "../../../services/players/teamContactsServices";
import { getTeams } from "./../../../services/players/teamServices";
class TeamContacts extends FormLib {
  state = {
    data: {
      contactId: "",
      teamId: "",
      name: "",
      ph1: "",
      ph2: "",
      mob1: "",
      mob2: "",
      email: "",
      escLevel: "",
    },
    errors: {},
    formMode: "create",
    teamContacts: [],
    teams: [],
    loading: true,
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };
  schema = {
    contactId: Joi.optional().label("ContactId"),
    teamId: Joi.string().required().label("TeamId*"),
    name: Joi.string().regex(/^[a-zA-Z ]*$/).required().label("Name").error(() => {
      return {
        message: 'Allow only characters.',
      }
    }),
    ph1: Joi.number().integer().required().label("PH_1").error(() => {
      return {
        message: 'must be number.',
      }
    }),
    ph2: Joi.number().integer().optional().label("PH_2").error(() => {
      return {
        message: 'must be number.',
      }
    }),

    mob1: Joi.string().regex(/^[0-9]{10}$/).required().label("MOB_1").error(() => {
      return {
        message: 'Allow only 10 digit numbers.',
      }
    }),

    mob2: Joi.string().regex(/^[0-9]{10}$/).optional().label("MOB_2").error(() => {
      return {
        message: 'Allow only 10 digit numbers.',
      }
    }),

    email: Joi.string().required().label("Email").email({ minDomainAtoms: 2 }),
    escLevel: Joi.optional().label("EscLevel"),
  };

  componentDidMount() {
    this.loadTeamContacts(this.state.pageLinks.pageNo);
    this.loadTeams();

  }
  async loadTeams() {
    this.setState({ loading: true });
    const res = await getTeams();
    if (res.data.success === true) {
      this.setState({ teams: res.data.data.teams });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  async loadTeamContacts(pageNo) {
    this.setState({ loading: true });
    const res = await getTeamContactsAll(pageNo);
    if (res !== undefined && res.data.data.length !== 0) {
      if (res.data.success) {
        this.setState({ teamContacts: res.data.data.teamcontacts });
        this.setState({ pageLinks: res.data.data.pageLinks });
      } else {
        toast.toastError("failed");
      }
    }
    this.setState({ loading: false });
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/players/teamContacts/" + pageNo);
    this.loadTeamContacts(pageNo);
  };

  handleOnTeamContacts(r) {
    let teamContacts = {};
    teamContacts.contactId = r.contactId;
    teamContacts.teamId = r.teamId;
    teamContacts.name = r.name;
    teamContacts.ph1 = r.ph1;
    teamContacts.ph2 = r.ph2;
    teamContacts.mob1 = r.mob1;
    teamContacts.mob2 = r.mob2;
    teamContacts.email = r.email;
    teamContacts.escLevel = r.escLevel;
    this.setState({ data: teamContacts, formMode: "edit" });
  }
  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createTeamContacts(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveTeamContacts(this.state.data);
      this.resetData();
    }

    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadTeamContacts(this.state.pageLinks.pageNo);
    } else {
      toast.toastError(res.data.msg);
    }
  }

  doSubmitDelete = async (handleDelete, r) => {
    if (handleDelete) {
      await deleteTeamContacts(r);
      this.loadTeamContacts(this.state.pageLinks.pageNo);

      swal("Team Contact has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Team Contact is safe!");
    }
  };

  handleDelete = async (r) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, r);
    });
  };

  handleView = (r) => {
    this.setState({ curTeamContact: r, formMode: "view" });
  };


  resetData() {
    this.setState({
      data: {
        contactId: "",
        teamId: "",
        name: "",
        ph1: "",
        ph2: "",
        mob1: "",
        mob2: "",
        email: "",
        escLevel: "",
      },
      formMode: "create",
    });
  }
  tableMeta = {
    key: "contactId",
    columns: [
      {
        key: "CONTACT_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnTeamContacts(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleView(r)}
              >
                <i className="fa fa-binoculars" aria-hidden="true"></i> View
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(r)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i> Delete
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      // { path: "teamId", label: "Team Id" },
      { path: "name", label: "Name" },
      //   { path: "ph1", label: "PH_1" },
      //   { path: "ph2", label: "PH_2" },
      //   { path: "mob1", label: "MOB_1" },
      //   { path: "mob2", label: "MOB_2" },
      { path: "email", label: "Email" },
      { path: "escLevel", label: "Esc Level" },

    ],
  };
  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="TeamContacts" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading TeamContacts</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.teamContacts}
                    dropMenu={[]}
                  />
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              {this.state.formMode === "view" && (
                <div className="card ">
                  <div className="card-body">
                    <>
                      {this.state.curTeamContact["name"].trim()}
                      <br />
                      <div className="pull-right">
                        <span className={"badge   badge-light pull-left ml-1 "}>
                          {this.state.curTeamContact["contactId"]}
                        </span>
                        <span
                          className={"badge   badge-success pull-left  mr-1 "}
                        >
                          {this.state.curTeamContact["status"]}
                        </span>
                      </div>
                    </>
                  </div>
                </div>
              )}
              {this.state.formMode !== "view" && (
                <div className="card pt-3">
                  <div className="card-body">
                    <form>
                      {this.rSB("Team", "teamId", this.state.teams, {
                        value: "teamId",
                        label: "name",
                      })}
                      <div className="form-row">
                        {this.rTBH("ContactId", "contactId", "col-2")}
                        {/* {this.rRBL(
                    "Type",
                    "type",
                    this.state.plrTeamTypes,
                    this.typConf
                  )} */}

                      </div>
                      <div className="form-row">
                        {this.rTB("Contact_1", "ph1", "col-4")}
                        {this.rTB("Contact_2", "ph2", "col-4")}
                        {this.rTB("Email", "email", "col-4")}
                      </div>
                      <div className="form-row">
                        {this.rTB("Mobile_1", "mob1", " form-group col-md-6")}
                        {this.rTB("Mobile_2", "mob2", " form-group col-md-6")}
                      </div>
                      <div className="form-row">
                        {this.rTB("Name", "name", " form-group col-md-6")}
                        {this.rTB("EscLevel", "escLevel", " form-group col-md-6")}
                      </div>
                    </form>
                    {this.state.formMode === "create" &&
                      this.rBtnSP("Create", "create")}
                    {this.state.formMode === "edit" && (
                      <>
                        {this.rBtnSS("Edit", "edit")}
                        {this.mResetBtn(() => this.resetData(), " pull-right ")}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <span>{JSON.stringify(this.state.data)}</span> */}
        </div>
      </React.Fragment>
    );
  }
}
export default TeamContacts;
