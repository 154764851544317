import React, { Component } from "react";

import Table from "../../_common/Table";
import Pagination from "./../../_common/Pagination";

import { dropMenuFull } from "./../../../utils/DropMenuUtils";

import { fakeGetUser } from "./../../../services/userService";

class TableListServerSidePage extends Component {
  subHeading = "User";

  state = {
    users: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };

  componentDidMount() {
    const pageNo = this.props.match.params.pageNo
      ? parseInt(this.props.match.params.pageNo)
      : 1;
    this.loadUsers(pageNo);
  }

  async loadUsers(pageNo) {
    const resp = await fakeGetUser(pageNo);
    this.setState({ users: resp.data.users });
    this.setState({ pageLinks: resp.data.pageLinks });
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/demo/list/server-side-pagination/" + pageNo);
    this.loadUsers(pageNo);
  };

  handleTableCBChange = (r, e) => {
    // console.log(JSON.stringify(r));
    if (e.target.checked) {
      console.log("checked");
    } else {
      console.log("unchecked");
    }
  };

  tableMeta = {
    key: "reqcode",
    columns: [
      {
        key: "cb_reqcode",
        action: (r) => (
          <>
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  name={r["reqcode"]}
                  value={r["reqcode"]}
                  onClick={(e) => this.handleTableCBChange(r, e)}
                />
                <i className="helper"></i>
              </label>
            </div>
          </>
        ),
        label: "User",
      },
      { path: "reqcode", label: "ReqCode" },
      { path: "hub", label: "Hub" },
      { path: "customer", label: "Customer" },
      { path: "activity", label: "Activity" },
    ],
  };

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    if (itemsCount === 0) return <p>No Data Found</p>;

    const users = this.state.users;

    return (
      <React.Fragment>
        <div className="pageContent">
          {this.props.loading && (
            <div className="container-fluid">
              <div> Loading users...</div>
            </div>
          )}

          {!this.props.loading && itemsCount && (
            <div className="container-fluid">
              <Table
                clsName="table table-striped table-bordered table-hover"
                tableMeta={this.tableMeta}
                data={users}
                dropMenu={dropMenuFull()}
              />
              <Pagination
                pageNo={pageNo}
                perPage={perPage}
                itemsCount={itemsCount}
                onPageChange={this.handlePageChange}
              />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default TableListServerSidePage;
