import React, { Component } from "react";
import FormLib from "./../../_common/FormLib";
import {
  getPlayersbyRole,
  getPlayersbyRoleCount,
} from "./../../../services/Tickets/TicketServices";
import {
  setTicketActions,
  setTicketActionsNew,
  setTicketActionsTicketCancel,
  getPackingMaterialAndProdByJobId,
  getPackingMaterialAndProdByJobIdAdvExc,
} from "./../../../services/Tickets/TicketProcessServices";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { ticketListReload } from "../../../store";
import { connect } from "react-redux";
import { IstToUTC, IstToUTCDate } from "../../../utils/DateUtils";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import swal from "sweetalert";
import TicketActionsInfo from "./TicketActionsInfo";
import TicketVehicleInfo from "./TicketVehicleInfo";
import DropAssignItemTicket from "./DropAssignItemTicket";
import BarcodeProduct from "./BarcodeProduct";


import {
  getPunchedInFEsByHubId,
  getPunchedInFEsByHubIdSSE,
} from "../../../services/punchInAndOut/punchInOut";
import SelectedTickets from "./Manifest/actions/SelectedTickets";
import FeTicketDetails from "./actions/FeTicketDetails";
class FeAssignment extends FormLib {
  state = {
    data: {
      player: "",
      tkPriorityChange: "",
    },
    users: [],
    errors: {},
    curticketDetails: {},
    curblockDetails: {},
    curAction: "",
    pageHide: "false",
    feStatus: "false",
    curStatus: "",
    cancelStatus: "false",
    curMultiFETicketDetails: [],
    curMultiblockDetails: {},
    curMultiticketDetails: {},
    filteredPunchedInFEs: [],
    dropItemsAssigned: false,
    pickUpConfirmData: [],
    confirmData: false,
    curProductDetailsProp: [],
    statuses: "",
    productType: "",
    productLength: "",
    currentStatus: false,
    openStatus: "false",
    playerDetails: {},
    userName: {},
    itemStatus: ""
  };

  includeCancelTicket = [
    { sortKey: "yes", sort: "Do you want to cancel Ticket??" },
  ];
  // includeVehicleInfo = [
  //   { sortKey: "yes", sort: "View Ticket Sequence And Create A Trip" },
  // ];

  stsConfig = {
    value: "sortKey",
    label: "sort",
  };
  schema = {
    player: Joi.string().required().label("FE Assignment"),
    tkPriorityChange: Joi.optional().label("tkPriorityChange"),
    includeCancelTicket: Joi.string().optional().label("includeCancelTicket"),
    includeVehicleInfo: Joi.optional().label("includeVehicleInfo"),
    includeInventory: Joi.string().optional().label("includeInventory"),
  };

  componentDidMount() {
    this.loadPackingMaterialData();

    this.resizeTicketList();
    this.getPlayers();
    this.loadInit();
    let pageHide = this.props.hidePage;
    this.setState({ pageHide });
    this.setState({ curStatus: this.props.curStatus });
    this.getlocalDateTime();
  }

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }
  handleCancelTicket = async () => {
    swal({
      title: "Are you sure want to Cancel the Ticket?",
      text: "Kindly Enter the Reason for Ticket Cancellation",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: "input",
      placeholder: "Write something",
    }).then(async (handleCancelTicket) => {
      this.doCancelTicket(handleCancelTicket);
    });
  };

  doCancelTicket = async (handleCancelTicket) => {
    if (handleCancelTicket) {
      // alert(handleRTO)
      swal(
        "Ticket has been successfully Cancelled with the following remarks: " +
          handleCancelTicket,
        {
          icon: "success",
        }
      );
      this.submitCancelTicket(handleCancelTicket);
    } else {
      swal("Ticket not cancelled!", { icon: "error" });
    }
  };

  submitCancelTicket = async (handleCancelTicket) => {
    let resultSet = {
      remarks: handleCancelTicket,
      actionStatus: "OPS_CANCALLATION",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    let data = this.state.data;
    data = {
      actionCode: "wOPSCancelled",
      action: "Ticket Cancelled",
    };
    this.setState({ data });

    let resp = await setTicketActionsTicketCancel(
      this.state.data,
      this.state.curticketDetails,
      this.state.curblockDetails,
      resultSet
    );
    if (resp !== undefined) {
      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ cancelStatus: "true" });
          // this.setState({ rtoStatus: "false" });
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
  };

  loadInit() {
    const curticketDetails = this.props.ticketDetails;
    const curblockDetails = this.props.blockDetails;
    const curAction = this.props.curAction;
    const curMultiticketDetails = this.props.ticketMultiDetails;
    const curMultiblockDetails = this.props.blockMultiDetails;
    const bulkAssign = this.props.bulkAssign;
    const curProductDetailsProp = this.props.ticketDetails?.products;
    if (curProductDetailsProp != undefined) {
      curProductDetailsProp.map((i) => {
        this.setState({ productType: i.productType });
      });
      this.setState({ productLength: curProductDetailsProp.length });
    }
    this.setState({ curticketDetails });
    this.setState({ curblockDetails });
    this.setState({curProductDetailsProp});
    this.setState({ curAction });
    this.setState({ bulkAssign });
    this.setState({ curMultiticketDetails });
    this.setState({ curMultiblockDetails });
    let hoForm = localStorage.getItem("curHub");
    if (hoForm === "1lUeT6ivx3d1DXPKW74SdKyLQrM=") {
      this.setState({ hideForm: "true" });
    } else {
      this.setState({ hideForm: "false" });
    }
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({ designation: userInfo.designation });
  }

  async getPlayers() {
    try {
      let hub = localStorage.getItem("curHub");
      // let resp = await getPlayersbyRole(hub);
      let resp = await getPlayersbyRoleCount(hub);

      if (resp.data.data) {
        let users = resp.data.data.playersHubs;

        const filter = resp.data.data.playersHubs.filter((r) => {
          if (!r.rlastName) {
            r.rlastName = "";
          }
          r["display"] = r.firstName + " " + r.rlastName + " / " + r.userName +
            " - " +
            r.feTicketCount;

          return r;
        });

        if (resp.data.success === true) {
          resp.data.data.playersHubs && this.setState({ users: filter });

          !resp.data.data.playersHubs &&
            toast.warning(() => <div>{resp.data.msg}</div>);

          // if (resp.data.data.playersHubs !== []) {
          //   try {
          //     const punchedInFEsResp = await getPunchedInFEsByHubId(hub);
          //     if (punchedInFEsResp && punchedInFEsResp.data.success) {
          //       const punchedInUsers = punchedInFEsResp.data.data.punches;
          //       // console.log({punchedInUsers, filter})
          //       if (!punchedInUsers.length) {
          //         this.setState({ filteredPunchedInFEs: [] });
          //         return;
          //       }
          //       const punchedInFEsIds = punchedInUsers.map((p) => p.userId);
          //       const filteredPunchedInFEs = filter.filter((r) =>
          //         punchedInFEsIds.includes(r.playerId)
          //       );
          //       // console.log({filteredPunchedInFEs})
          //       this.setState({ filteredPunchedInFEs });
          //     } else {
          //       this.setState({ filteredPunchedInFEs: filter });
          //     }
          //   } catch (error) {
          //     //  this.setState({ filteredPunchedInFEs: filter })
          //     return;
          //   }
          // }
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      }
    } catch {
      console.log("Error in getPlayers");
    }
  }

  async doSubmit() {
    try {
      let override = { ...this.state.data };
      override["actionCode"] = "wFeAssign";
      override["action"] = "Assign FE to Block";
      this.setState({ override });
      let feAction = "";
      let newTicketPriority = "";
      if (this.state.bulkAssign === "bulk") {
        for (var i = 0; i <= this.state.curMultiblockDetails.length; i++) {
          if (
            ((this.state.curMultiblockDetails[i].blockStatus ===
              "HANDED_OVER_PACKING_MATERIALS" &&
              this.state.curMultiticketDetails[i].flowCode !== "DRPOF") ||
              (this.state.curMultiblockDetails[i].blockStatus ===
                "APPOINTMENT_FIXED" &&
                this.state.curMultiticketDetails[i].flowCode !== "DRPOF")) &&
            this.state.curMultiblockDetails[i].flowStatus === "PICK_WEB"
          ) {
            feAction = "ASSIGNED_FE_FOR_PICKUP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
            "CHOOSE_FE_FOR_DROP"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
            "APPOINTMENT_FIXED" &&
            this.state.curMultiblockDetails[i].flowStatus === "DROP_WEB"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
              "CHOOSE_FE_FOR_DROP_ASC" &&
            (this.state.curMultiticketDetails[i].flowId ===
              "PickAndDropTwoWay" ||
              this.state.curMultiblockDetails[i].flowId === "PickAndDropTwoWay")
          ) {
            feAction = "ASSIGNED_FE_FOR_SERVICE_DROP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
              "DROP_DONE_ASC" &&
            (this.state.curMultiticketDetails[i].flowId ===
              "PickAndDropTwoWay" ||
              this.state.curMultiblockDetails[i].flowId === "PickAndDropTwoWay")
          ) {
            feAction = "ASSIGNED_FE_FOR_SERVICE_PICKUP";
            newTicketPriority = this.state.data.tkPriorityChange;
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
              "PICKUP_DONE_ASC" &&
            (this.state.curMultiticketDetails[i].flowId ===
              "PickAndDropTwoWay" ||
              this.state.curMultiblockDetails[i].flowId === "PickAndDropTwoWay")
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
            "APPOINTMENT_FIXED" &&
            this.state.curMultiblockDetails[i].flowStatus === "PICK_WEB" &&
            this.state.curMultiticketDetails[i].flowCode !== "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_PICKUP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
            "APPOINTMENT_FIXED" &&
            this.state.curMultiblockDetails[i].flowStatus === "DROP_WEB"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
              "APPOINTMENT_FIXED" &&
            this.state.curMultiticketDetails[i].flowCode === "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
              "HANDED_OVER_PACKING_MATERIALS" &&
            this.state.curMultiticketDetails[i].flowCode === "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curMultiblockDetails[i].blockStatus ===
              "CHOOSE_FE_FOR_DROP" &&
            this.state.curMultiticketDetails[i].flowCode === "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          }

          const feFilter = this.state.users.filter((r) => {
            return r.playerId === this.state.data.player ? r : null;
          });

          this.setState({
            curFeName: feFilter[0].firstName + " " + feFilter[0].lastName,
          });

          // console.log("FE DETAILS----------> " + JSON.stringify(feFilter));

          let resultSet = {
            fes: [
              {
                feId: this.state.data.player,
                feType: "fe",
                feName:
                  feFilter[0].firstName +
                  " " +
                  feFilter[0].lastName +
                  " - " +
                  feFilter[0].userName,
                fePhone: feFilter[0].phone,
              },
            ],
            flowId: this.state.curMultiticketDetails[i].flowId,
            newTicketPriority: newTicketPriority,
            actionStatus: feAction,
            processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          };
          let resp = await setTicketActions(
            override,
            this.state.curMultiticketDetails[i],
            this.state.curMultiblockDetails[i],
            resultSet
          );
          if (resp.data.success === true) {
            if (resp.data.data) {
              this.handleTicketListReload();
              this.setState({ feStatus: "true" });
              toast.success("FE Assigned Successfully");
            } else {
              !resp.data.data &&
                toast.warning(() => <div>{resp.data.msg}</div>);
            }
          } else {
            toast.error(() => <div>{resp.data.msg}</div>);
          }
        }
      } else {
        let multiFes = [];
        if (
          (this.state.curticketDetails.flowId === "BulkQC" ||
            this.state.curticketDetails.flowId === "Bulk" ||
            this.state.curticketDetails.flowId === "Furniture" ||
            this.state.curticketDetails.flowId === "eWaste" ||
            this.state.curticketDetails.flowId === "DropOff") &&
          this.state.check
        ) {
          // alert("if.." + this.state.check);

          let override = { ...this.state.data };
          override["actionCode"] = "wFeAssign";
          override["action"] = "Assign Bulk FE to Block";
          this.setState({ override });
          let feAction = "";
          if (
            ((this.state.curblockDetails.blockStatus ===
              "HANDED_OVER_PACKING_MATERIALS" &&
              this.state.curticketDetails.flowCode !== "DRPOF") ||
              (this.state.curblockDetails.blockStatus === "APPOINTMENT_FIXED" &&
                this.state.curticketDetails.flowCode !== "DRPOF")) &&
            this.state.curblockDetails.flowStatus === "PICK_WEB"
          ) {
            feAction = "ASSIGNED_FE_FOR_PICKUP";
          } else if (
            this.state.curblockDetails.blockStatus === "CHOOSE_FE_FOR_DROP"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "APPOINTMENT_FIXED" &&
            this.state.curblockDetails.flowStatus === "DROP_WEB"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus ===
            "CHOOSE_FE_FOR_DROP_ASC" &&
            (this.state.curticketDetails.flowId === "PickAndDropTwoWay" ||
              this.state.curblockDetails.flowId === "PickAndDropTwoWay")
          ) {
            feAction = "ASSIGNED_FE_FOR_SERVICE_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "DROP_DONE_ASC" &&
            (this.state.curticketDetails.flowId === "PickAndDropTwoWay" ||
              this.state.curblockDetails.flowId === "PickAndDropTwoWay")
          ) {
            feAction = "ASSIGNED_FE_FOR_SERVICE_PICKUP";
            newTicketPriority = this.state.data.tkPriorityChange;
          } else if (
            this.state.curblockDetails.blockStatus === "PICKUP_DONE_ASC" &&
            (this.state.curticketDetails.flowId === "PickAndDropTwoWay" ||
              this.state.curblockDetails.flowId === "PickAndDropTwoWay")
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "APPOINTMENT_FIXED" &&
            this.state.curblockDetails.flowStatus === "PICK_WEB" &&
            this.state.curticketDetails.flowCode !== "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_PICKUP";
          } else if (
            this.state.curblockDetails.blockStatus === "APPOINTMENT_FIXED" &&
            this.state.curblockDetails.flowStatus === "DROP_WEB"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "APPOINTMENT_FIXED" &&
            this.state.curticketDetails.flowCode === "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus ===
            "HANDED_OVER_PACKING_MATERIALS" &&
            this.state.curticketDetails.flowCode === "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "CHOOSE_FE_FOR_DROP" &&
            this.state.curticketDetails.flowCode === "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          }

          let newTicketPriority = "";

          const feFilter = this.state.users.filter((r) => {
            return r.playerId === this.state.data.player ? r : null;
          });

          this.setState({
            curFeName: feFilter[0].firstName + " " + feFilter[0].lastName,
          });

          this.state.curMultiFETicketDetails.map((r) => {
            let FEs = {
              feId: r.playerId,
              feType: "fe",
              feName: r.firstName + " " + r.lastName + " - " + r.userName,
              fePhone: r.phone,
            };
            multiFes.push(FEs);
          });
          let resultSet = {
            fes: multiFes,
            flowId: this.state.curticketDetails.flowId,
            newTicketPriority: newTicketPriority,
            actionStatus: feAction,
            processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          };

          let resp = await setTicketActions(
            override,
            this.state.curticketDetails,
            this.state.curblockDetails,
            resultSet
          );
          if (resp.data.success === true) {
            if (resp.data.data) {
              this.handleTicketListReload();
              this.setState({ feStatus: "true" });
              toast.success("FE Assigned Successfully");
            } else {
              !resp.data.data &&
                toast.warning(() => <div>{resp.data.msg}</div>);
            }
          } else {
            toast.error(() => <div>{resp.data.msg}</div>);
          }
        } else {
          // alert("else");

          let override = { ...this.state.data };
          override["actionCode"] = "wFeAssign";
          override["action"] = "Assign FE to Block";
          this.setState({ override });
          let feAction = "";
          let newTicketPriority = "";

          if (
            this.state.curblockDetails.blockStatus ===
            "HANDED_OVER_PACKING_MATERIALS" &&
            this.state.curticketDetails.flowCode !== "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_PICKUP";
          } else if (
            this.state.curblockDetails.blockStatus === "RESCHEDULE_FE"
          ) {
            feAction = "CHOOSE_RE_APPOINTMENT_DATE";
          } else if (
            this.state.curblockDetails.blockStatus === "CHOOSE_FE_FOR_DROP" &&
            this.state.curticketDetails.flowId !== "PickAndDropTwoWay"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus ===
            "CHOOSE_FE_FOR_DROP_ASC" &&
            this.state.curticketDetails.flowId === "PickAndDropTwoWay"
          ) {
            feAction = "ASSIGNED_FE_FOR_SERVICE_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "DROP_DONE_ASC" &&
            this.state.curticketDetails.flowId === "PickAndDropTwoWay"
          ) {
            feAction = "ASSIGNED_FE_FOR_SERVICE_PICKUP";
            newTicketPriority = this.state.data.tkPriorityChange;
          } else if (
            this.state.curblockDetails.blockStatus === "PICKUP_DONE_ASC" &&
            this.state.curticketDetails.flowId === "PickAndDropTwoWay"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "APPOINTMENT_FIXED" &&
            this.state.curblockDetails.flowStatus === "PICK_WEB" &&
            this.state.curticketDetails.flowCode !== "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_PICKUP";
          } else if (
            this.state.curblockDetails.blockStatus === "APPOINTMENT_FIXED" &&
            this.state.curblockDetails.flowStatus === "DROP_WEB"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "APPOINTMENT_FIXED" &&
            this.state.curticketDetails.flowCode === "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus ===
            "HANDED_OVER_PACKING_MATERIALS" &&
            this.state.curticketDetails.flowCode === "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          } else if (
            this.state.curblockDetails.blockStatus === "CHOOSE_FE_FOR_DROP" &&
            this.state.curticketDetails.flowCode === "DRPOF"
          ) {
            feAction = "ASSIGNED_FE_FOR_DROP";
          }

          const feFilter = this.state.users.filter((r) => {
            return r.playerId === this.state.data.player ? r : null;
          });

          this.setState({
            curFeName: feFilter[0].firstName + " " + feFilter[0].lastName,
          });

          // console.log("FE DETAILS----------> " + JSON.stringify(feFilter));

          let resultSet = {
            fes: [
              {
                feId: this.state.data.player,
                feType: "fe",
                feName:
                  feFilter[0].firstName +
                  " " +
                  feFilter[0].lastName +
                  " - " +
                  feFilter[0].userName,
                fePhone: feFilter[0].phone,
              },
            ],
            flowId: this.state.curticketDetails.flowId,
            newTicketPriority: newTicketPriority,
            actionStatus: feAction,
            processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          };

          let resp = await setTicketActions(
            override,
            this.state.curticketDetails,
            this.state.curblockDetails,
            resultSet
          );
          if (resp.data.success === true) {
            if (resp.data.data) {
              this.handleTicketListReload();
              this.setState({ feStatus: "true" });
              toast.success("FE Assigned Successfully");
            } else {
              !resp.data.data &&
                toast.warning(() => <div>{resp.data.msg}</div>);
            }
          } else {
            toast.error(() => <div>{resp.data.msg}</div>);
          }
        }
      }
    } catch {
      console.log("Error in FeAssignment>>>>>>doSubmit");
    }
  }

  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };
  resizeTicketList = () => {
    $("#cocardsFe").css("max-height", $(window).height() - 262);
  };
  curFeAssignment = (feAssign) => {
    this.setState({ check: false });
    if (
      (this.state.curticketDetails.flowId === "BulkQC" ||
        this.state.curticketDetails.flowId === "Bulk" ||
        this.state.curticketDetails.flowId === "Furniture" ||
        this.state.curticketDetails.flowId === "eWaste" ||
        this.state.curticketDetails.flowId === "DropOff") &&
      this.state.curMultiFETicketDetails.length > 1
    ) {
      this.setState({
        playersId: this.state.curMultiFETicketDetails[0].playerId,
      });
      this.state.data.player = this.state.curMultiFETicketDetails[0].playerId;
    } else if (this.state.curMultiFETicketDetails.length < 1) {
      this.state.curMultiFETicketDetails = [];
      this.state.curMultiFETicketDetails.push(feAssign);
      this.setState({
        playersId: this.state.curMultiFETicketDetails[0].playerId,
      });
      this.state.data.player = this.state.curMultiFETicketDetails[0].playerId;
    } else {
      this.setState({ playersId: feAssign.playerId });
      this.state.data.player = feAssign.playerId;
    }
  };
  tkPriorityChange = [{ label: "Update Service Center Status", value: "high" }];

  onloadDocumentDetails = (r) => {
    this.setState({ documentDetails: "true" });
    this.setState({ openStatus: "false" });
  };

  closeModal = () => {
    this.setState({ openStatus: "false" });
    this.setState({ documentDetails: "false" });
  };

  handleCheckBoxClick = (e, t) => {
    try {
      if (e.target.checked) {
        this.setState({ check: e.target.checked });
        const index = this.state.curMultiFETicketDetails.findIndex(
          (object) => object.playerId === t.playerId
        );
        if (index === -1) {
          this.state.curMultiFETicketDetails.push(t);
        }
      } else {
        let index1 = this.state.curMultiFETicketDetails.indexOf(t);
        if (index1 > -1) {
          this.state.curMultiFETicketDetails.splice(index1, 1);
        }
      }
      if (
        this.state.curticketDetails.flowId === "BulkQC" ||
        this.state.curticketDetails.flowId === "Bulk" ||
        this.state.curticketDetails.flowId === "Furniture" ||
        this.state.curticketDetails.flowId === "eWaste" ||
        this.state.curticketDetails.flowId === "DropOff"
      ) {
        this.setState({
          playersId: this.state.curMultiFETicketDetails[0].playerId,
        });
        this.state.data.player = this.state.curMultiFETicketDetails[0].playerId;
      }
    } catch {
      console.log("Error in handleCheckBoxClick");
    }
  };

  loadPackingMaterialData = async () => {
    // alert("call");
    try {
      if (this.props.ticketDetails.flowId === "AdvanceExchange" && this.props.blockDetails.flowStatus === "DROP_WEB") {
        let blockData = this.props.blockDetails;
        let ticketData = this.props.ticketDetails;
        let res = await getPackingMaterialAndProdByJobIdAdvExc(blockData, ticketData);
        // console.log("jjjj", res.data.data.packingMaterialDetails)

        if (res !== undefined) {
          if (res.data.success === true && res.data.data) {
            // console.log("jjjj",res.data.data.packingMaterialDetails.length)
            // const extractedStatuses = res.data.data.packingMaterialDetails.flatMap(item =>
            //   item.productDetails.map(product => product.status)
            // );

            this.setState({ statuses: res.data.data.packingMaterialDetails });
            this.setState({ itemStatus: res.data.data.packingMaterialDetails[0].productDetails[0].status })

            this.getPackingMaterialAndProdStatus(
              res.data.data.packingMaterialDetails
            );
            this.setState({ confirmData: true });
          } else {
            this.setState({ confirmData: false });
          }
        } else {
          this.setState({ confirmData: false });
        }
      } else {
        let blockData = this.props.blockDetails;
        // let ticketData = this.props.ticketDetails;
        let res = await getPackingMaterialAndProdByJobId(blockData);
        // console.log("jjjj", res.data.data.packingMaterialDetails)

        if (res !== undefined) {
          if (res.data.success === true && res.data.data) {
            // console.log("jjjj",res.data.data.packingMaterialDetails.length)
            // const extractedStatuses = res.data.data.packingMaterialDetails.flatMap(item =>
            //   item.productDetails.map(product => product.status)
            // );

            this.setState({ statuses: res.data.data.packingMaterialDetails });
            this.setState({ itemStatus: res.data.data.packingMaterialDetails[0].productDetails[0].status })

            this.getPackingMaterialAndProdStatus(
              res.data.data.packingMaterialDetails
            );
            this.setState({ confirmData: true });
          } else {
            this.setState({ confirmData: false });
          }
        } else {
          this.setState({ confirmData: false });
        }
      }
    } catch {
      console.log(
        "Error in loadPackingMaterialData>>>>>>>>>>>>>>>>>>>>>>>>>>FeAssignment"
      );
    }
  };

  getPackingMaterialAndProdStatus = async (data) => {
    if (data.length > 0) {
      data.map((r) => {
        if (r.packMaterialId) {
          if (
            r.packMaterialItemStatus === "AssignedDropItems" ||
            r.packMaterialItemStatus === "itemLost"
          ) {
            this.setState({ dropItemsAssigned: true });
            // this.forceUpdate();
          } else {
            this.setState({ dropItemsAssigned: false });
          }
        } else {
          // console.log("productDetailsss", r.productDetails[0].itemStatus);
          if (
            r.productDetails[0].itemStatus === "AssignedDropItems" ||
            r.productDetails[0].itemStatus === "itemLost"
          ) {
            this.setState({ dropItemsAssigned: true });
            // this.forceUpdate();
          } else {
            this.setState({ dropItemsAssigned: false });
          }
        }
        if (
          !r.productDetail &&
          this.state.curblockDetails.flowStatus === "PICK_WEB"
        ) {
          this.setState({ dropItemsAssigned: true });
          this.setState({ confirmData: false });
        } else if (!r.productDetail &&
          this.props.ticketDetails.flowId === "DropOff") {
          this.setState({ dropItemsAssigned: true });
          this.setState({ confirmData: false });
        }
      });
    } else {
      this.setState({ dropItemsAssigned: true });
      this.setState({ confirmData: false });
    }
    // if (
    //   data.length ===0
    // ) {
    //   this.setState({ dropItemsAssigned: true });
    //   this.setState({ confirmData: false });
    // }
  };

  handleFeClick = (t) => {
    // console.log("clicked", t);
    this.setState({ playerDetails: t.playerId });
    this.setState({ userName: t.userName });
    this.setState({ currentStatus: true });
  };

  handleModalData = (data) => {
    // console.log('Data received from modal:', data);
    this.setState({ curMultiblockDetails: data })
  };

  render() {
    const ticketDetails = this.state.curticketDetails;
    const ticket = this.state.curMultiticketDetails;

    return (
      <>
        {this.state.confirmData === true &&
          this.state.dropItemsAssigned === false &&
          (this.state.curblockDetails.flowStatus === "DROP_WEB" && this.state.curticketDetails.flowId !== "DropOff") &&
          this.state.pageHide === "false" &&
          this.state.feStatus === "false" &&
          this.state.hideForm === "false" &&
          this.state.cancelStatus === "false" && (
            <div className="row ">
              <form onSubmit={this.handleSubmit}>
                <DropAssignItemTicket
                  productDetails={this.state.curProductDetailsProp}
                  ticketDetails={this.state.curticketDetails}
                  blockDetails={this.state.curblockDetails}
                  key={"p_" + this.state.curblockDetails.ticketBlockId}
                  onHandleGetConfirmStatus={this.loadPackingMaterialData}
                />
              </form>
            </div>
          )}
        {(this.state.dropItemsAssigned === true ||
          this.state.confirmData === false) && (
            <div className="row  ">
              {this.state.pageHide === "false" &&
                this.state.feStatus === "false" &&
                this.state.hideForm === "false" &&
                this.state.cancelStatus === "false" && (
                  <>
                    <div id="coAction" className="col-md-12">
                      <div className="form-subHeader">
                        <b>{this.state.curticketDetails.ticketNo}</b>
                        {this.state.designation === "Operations Head" && (
                          <div
                            className="pull-right"
                            style={{ marginTop: "-1%", marginRight: "23%" }}
                          >
                            {this.rCBHL(
                              "includeCancelTicket",
                              this.includeCancelTicket,
                              {
                                value: "sortKey",
                                label: "sort",
                              }
                            )}
                          </div>
                        )}
                        {this.state.data.includeCancelTicket === "yes" && (
                          <div
                            className="pull-right"
                            style={{ marginTop: "1%", marginRight: "-40%" }}
                          >
                            <button
                              title="Cancel Ticket"
                              className="badge badge-danger  pull-right"
                              type="button"
                              onClick={() => this.handleCancelTicket()}
                              style={{
                                fontSize: "82%",
                                borderRadius: "2.25rem ",
                                border: "none",
                                fontWeight: "bold",
                                marginRight: "6px",
                              }}
                            >
                              CANCEL TICKET
                            </button>
                          </div>
                        )}
                      </div>
                      {(this.state.curticketDetails.flowId === "DropOff" ||
                      (this.state.curticketDetails.flowId === "AdvanceExchange" && this.props.blockDetails.flowStatus === "PICK_WEB")) && (
                      <>
                        <>
                          <BarcodeProduct
                            productDetails={this.state.curProductDetailsProp}
                            ticketDetails={this.state.curticketDetails}
                            blockDetails={this.state.curblockDetails}
                            key={
                              "p_" + this.state.curblockDetails.ticketBlockId
                            }
                            onHandleGetConfirmStatus={
                              this.loadPackingMaterialData
                            }
                          />
                        </>
                      </>
                    )}


                      <div className="d-flex">
                        <div className="card col-md-5 pt-3">
                          {this.rCBHL(
                            "includeVehicleInfo",
                            this.includeVehicleInfo,
                            {
                              value: "sortKey",
                              label: "sort",
                            }
                          )}
                          {this.state.data.includeVehicleInfo === "yes" && (
                            <div
                              className="card-body  bg-light"
                              style={{
                                maxHeight: "1100px",
                                marginLeft: "-18px",
                                marginTop: "-36px",
                              }}
                            >
                              <SelectedTickets
                                ticketDetails={this.state.curticketDetails}
                                blockDetails={this.state.curblockDetails}
                                curAction={this.state.curAction}
                                // hidePage={this.state.hidePage}
                                bulkAssign={this.state.bulkAssign}
                                ticketMultiDetails={
                                  this.state.curMultiticketDetails
                                }
                                blockMultiDetails={this.state.curMultiblockDetails}
                                key={
                                  "fe_" + this.state.curblockDetails.ticketBlockId
                                }
                              />
                              <TicketVehicleInfo
                                ticketDetails={this.state.curticketDetails}
                                blockDetails={this.state.curblockDetails}
                                curAction={this.state.curAction}
                                // hidePage={this.state.hidePage}
                                bulkAssign={this.state.bulkAssign}
                                ticketMultiDetails={
                                  this.state.curMultiticketDetails
                                }
                                blockMultiDetails={this.state.curMultiblockDetails}
                                key={
                                  "fe_" + this.state.curblockDetails.ticketBlockId
                                }
                              />
                            </div>
                          )}
                        </div>
                        <div>
                          {((this.state.curticketDetails.flowId === "DropOff" && this.state.itemStatus !== "00available" &&
                            this.state.statuses.length >= this.state.productLength) ||
                            ((this.state.curticketDetails.flowId ===
                              "AdvanceExchange" &&
                              this.state.statuses.length >= 1 && this.state.itemStatus !== "00available"))) && (
                              <div>

                                <>

                                  <div className="row  ">
                                    <div className="card col-md-10">
                                      <div className="card-body  bg-light">
                                        <b>&nbsp;&nbsp;FE Assignment :</b>
                                        <form onSubmit={this.handleSubmit}>
                                          <div className="form-row border border-gray rounded  pt-1  mb-3 mt-1">
                                            <div className="col-8  ml-5 mr-4">
                                              <div className="row ">
                                                {this.state.users.map((t) => {
                                                  return (
                                                    <span
                                                      key={t.playerId + "spanCover1"}
                                                    >
                                                      <input
                                                        type="checkbox"
                                                        name={t.ticketNo}
                                                        value={t.ticketNo}
                                                        title="Bulk FE Selection"
                                                        onClick={(e) =>
                                                          this.handleCheckBoxClick(e, t)
                                                        }
                                                        className="multiInput ml-1"
                                                      />
                                                      <div
                                                        className="card mb-2 cursorPointor ml-5"
                                                        onClick={() =>
                                                          this.curFeAssignment(t)
                                                        }
                                                      >
                                                        <div
                                                          className="card-header page-todo task"
                                                          style={
                                                            t.playerId &&
                                                              t.playerId ===
                                                              this.state.playersId
                                                              ? {
                                                                backgroundColor:
                                                                  "rgb(255 225 202)",
                                                              }
                                                              : {
                                                                backgroundColor:
                                                                  "white",
                                                              }
                                                          }
                                                        >
                                                          <div className="col-6 zoom">
                                                            {t.display}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </span>
                                                  );
                                                })}
                                              </div>
                                            </div>

                                            {this.state.curblockDetails.blockStatus ===
                                              "DROP_DONE_ASC" &&
                                              this.state.curticketDetails.flowId ===
                                              "PickAndDropTwoWay" &&
                                              this.rCBHL(
                                                "tkPriorityChange",
                                                this.tkPriorityChange
                                              )}
                                          </div>
                                          {this.rBtnLP(
                                            "Submit",
                                            "Submit",
                                            "btn btn-primary btn-sm pull-right"
                                          )}
                                        </form>
                                      </div>
                                    </div>

                                    <div className="card col-md-5">
                                      {this.state.data.includeVehicleInfo === "yes" && (
                                        <div
                                          className="card-body  bg-light"
                                          style={{
                                            maxHeight: "1100px",
                                            marginLeft: "-18px",
                                            marginTop: "-36px",
                                          }}
                                        >
                                          {/* <TicketVehicleInfo
                                            ticketDetails={this.state.curticketDetails}
                                            blockDetails={this.state.curblockDetails}
                                            curAction={this.state.curAction}
                                            bulkAssign={this.state.bulkAssign}
                                            ticketMultiDetails={
                                              this.state.curMultiticketDetails
                                            }
                                            blockMultiDetails={
                                              this.state.curMultiblockDetails
                                            }
                                            key={
                                              "fe_" +
                                              this.state.curblockDetails.ticketBlockId
                                            }
                                          /> */}
                                        </div>
                                      )}
                                      {this.state.currentStatus === true && (
                                        <FeTicketDetails
                                          FeDetails={this.state.playerDetails}
                                          userName={this.state.userName}
                                          closeModal={this.closeModal}
                                        />
                                      )}
                                    </div>
                                  </div>

                                </>

                              </div>
                            )}
                          {this.state.curticketDetails.flowId !== "DropOff" &&
                            this.state.curticketDetails.flowId !==
                            "AdvanceExchange" && (
                              <div className="row  ">
                                <div className="card col-md-10">
                                  <div className="card-body  bg-light">
                                    <b>&nbsp;&nbsp;FE Assignment :</b>
                                    <form onSubmit={this.handleSubmit}>
                                      <div className="form-row border border-gray rounded  pt-1  mb-3 mt-1">
                                        <div className="col-8  ml-5 mr-4">
                                          <div className="row ">
                                            {this.state.users.map((t) => {
                                              return (
                                                <span key={t.playerId + "spanCover1"}>
                                                  {(this.state.curticketDetails
                                                    .flowId === "BulkQC" ||
                                                    this.state.curticketDetails
                                                      .flowId === "Bulk" ||
                                                    this.state.curticketDetails
                                                      .flowId === "Furniture" ||
                                                    this.state.curticketDetails
                                                      .flowId === "eWaste" ||
                                                    this.state.curticketDetails
                                                      .flowId === "Furniture" ||
                                                    this.state.curticketDetails
                                                      .flowId ===
                                                    "PickAndDropOneWay") && (
                                                      <input
                                                        type="checkbox"
                                                        name={t.ticketNo}
                                                        value={t.ticketNo}
                                                        title="Bulk FE Selection"
                                                        onClick={(e) =>
                                                          this.handleCheckBoxClick(e, t)
                                                        }
                                                        className="multiInput ml-3"
                                                      />
                                                    )}
                                                  <div
                                                    className="card mb-2 cursorPointor ml-5"
                                                    onClick={() =>
                                                      this.curFeAssignment(t)
                                                    }
                                                  >
                                                    <div
                                                      className="card-header page-todo task"
                                                      style={
                                                        t.playerId &&
                                                          t.playerId ===
                                                          this.state.playersId
                                                          ? {
                                                            backgroundColor:
                                                              "rgb(255 225 202)",
                                                          }
                                                          : {
                                                            backgroundColor:
                                                              "white",
                                                          }
                                                      }
                                                    >
                                                      <div className="col-6 zoom">
                                                        {t.display}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </span>
                                              );
                                            })}
                                          </div>
                                        </div>

                                        {this.state.curblockDetails.blockStatus ===
                                          "DROP_DONE_ASC" &&
                                          this.state.curticketDetails.flowId ===
                                          "PickAndDropTwoWay" &&
                                          this.rCBHL(
                                            "tkPriorityChange",
                                            this.tkPriorityChange
                                          )}
                                      </div>
                                      {this.rBtnLP(
                                        "Submit",
                                        "Submit",
                                        "btn btn-primary btn-sm pull-right"
                                      )}
                                    </form>
                                  </div>
                                </div>

                                {/* <div className="card col-md-5">
                                  {this.state.data.includeVehicleInfo === "yes" && (
                                    <div
                                      className="card-body  bg-light"
                                      style={{
                                        maxHeight: "1100px",
                                        marginLeft: "-18px",
                                        marginTop: "-36px",
                                      }}
                                    >
                                      <TicketVehicleInfo
                                        ticketDetails={this.state.curticketDetails}
                                        blockDetails={this.state.curblockDetails}
                                        curAction={this.state.curAction}
                                        bulkAssign={this.state.bulkAssign}
                                        ticketMultiDetails={
                                          this.state.curMultiticketDetails
                                        }
                                        blockMultiDetails={
                                          this.state.curMultiblockDetails
                                        }
                                        key={
                                          "fe_" +
                                          this.state.curblockDetails.ticketBlockId
                                        }
                                      />
                                    </div>
                                  )}
                                </div> */}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              <div
                className="draggable col-sm-12 col-md-8 col-lg-4"
                id="myDocumentDetails"
                onClick={this.handleView}
              >
                {
                  this.state.openStatus === "false" &&
                  this.state.documentDetails === "true" &&
                  (
                    <SelectedTickets
                      // key={currentRequest.refNo}
                      // data={currentRequest}
                      blockMultiDetails={this.state.curMultiblockDetails}
                      ticketMultiDetails={
                        this.state.curMultiticketDetails
                      }
                      sendDataToParent={this.handleModalData}
                      closeModal={this.closeModal}
                    />
                  )}
              </div>

              {this.state.feStatus === "true" &&
                this.state.bulkAssign === "notBulk" && (
                  <div className="jumbotron jumbotron-fluid">
                    <p className="lead text-center">
                      Ticket No: <b>{ticketDetails.ticketNo}</b> has been Assigned
                      for <b>{this.state.curFeName}</b> Successfully
                    </p>
                  </div>
                )}
              {this.state.feStatus === "true" &&
                this.state.bulkAssign === "bulk" && (
                  <div className="jumbotron jumbotron-fluid">
                    <p className="lead text-center">
                      Ticket No: <b>{ticketDetails.ticketNo}</b> has been Assigned
                      for <b>{this.state.curFeName}</b> Successfully
                    </p>
                  </div>
                )}
            </div>
          )

        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeAssignment);
