import _http from "./../_httpService";

import config from "./../../config.json";

const baseURL = config[config.build].apiURLValidata;

function api(path, subURL = "/4team/projects") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getProjects() {
  return _http.get(api(""), _http.headerJWT());
}

export function createProject(req) {
  const param = { ...req };
  delete param.projectId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveProject(req) {
  const param = { ...req };
  const id = param.projectId;
  delete param.projectId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteProject(req) {
  const param = { ...req };
  return _http.delete(api(param.projectId), {}, _http.headerJWT());
}
