import React from "react";
import { toast } from "react-toastify";

function toastSuccess(msg) {
  toast.success(() => <div>{msg}</div>);
}

function toastError(msg) {
  toast.error(() => <div>{msg}</div>);
}

function toastWarn(msg) {
  toast.warn(() => <div>{msg}</div>);
}

function toastInfo(msg) {
  toast.info(() => <div>{msg}</div>);
}

function toastMsg(res) {
  if (res.data && res.data.success && res.data.success === true) {
    toastSuccess(res.data.msg);
  } else {
    toastError(
      (res.data && res.data.msg) || "Error Happened in getting Error Msg"
    );
  }
}

const t = { toastSuccess, toastError, toastMsg, toastWarn, toastInfo };
export default t;
