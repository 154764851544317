export function getDropOffJSON(data, ticketScope) {
    try {
        let indentificationNumber = data.identificationNo.toString().replace(/(?:\r\n|\r|\n|\t)/g, '');

        const d = new Date().toISOString().slice(0, 10);
        const params = {
            primary: {
                ticketPriority: data.ticketPriority,
                flowId: data.flowId,
                retailerId: data.retailerId,
                retailerNo: data.retailerNo?.toString() || "",
                conComplaintNo: data.conComplaintNo?.toString() || "",
                LRNumber: data.LRNumber?.toString() || "",
                orderNo: data.orderNo?.toString() || "",
                invoiceNo: data.invoiceNo?.toString() || "",
                parentTicket: "",
                ticketDetails: data.ticketDetails,
                specialInstructions: data.specialInstructions,
                buyerContactNumber: "9854745862",
                dateOfTicket: d?.toString() || "",
                isAppointmentReq: data.isAppointmentReq,
                isInstallationReq: data.isInstallationReq,
                isPhysicalEval: data.isPhysicalEval,
                isTechEval: data.isTechEval,
                isPackingReq: data.isPackingReq,
                paymentMode: "cash",
                inFlowBy: "EXCEL",
                ticketScope: ticketScope

            },
            primary_details: {
                medical_equp: "",
                someFiled2: "",
            },
            products: [
                {
                    primary: {
                        productId: data.productId,
                        productCode: data.productCode,
                        brandId: data.brandId,
                        modelId: data.modelId,
                        brandName: data.brandName,
                        modelName: data.modelName,
                        brandCode: data.brandCode,
                        modelCode: data.modelCode,
                        productName: data.productName,
                        dateOfPurchase: data.dateOfPurchase,
                        identificationNo: indentificationNumber.trim(),
                        productDesc: data.productDesc,
                        problemDesc: data.problemDesc,
                        productValue: data.productValue?.toString() || "",
                        cost: data.cost?.toString() || "",
                        isUnderWarranty: data.isUnderWarranty,
                        accessories: "",
                        pickupType: "",
                    },
                    product_details: {
                        process_id: "",
                        ticketQc: "",
                    },
                    src_add: {
                        srcContactPerson: data.dstContactPerson,
                        srcOrg: "org",
                        srcAdd1: data.dstAdd1,
                        srcAdd2: data.dstAdd2,
                        srcLocation: data.dstLocation,
                        srcContact1: data.dstContact1?.toString() || "",
                        srcContact2: data.dstContact2?.toString() || "",
                        srcCity: data.dstCity,
                        srcState: data.dstState,
                        srcPincode: data.dstPincode?.toString() || "",
                        srcLandmark: data.dstLandmark,
                        srcEmailId: data.dstEmailId,
                        srcEmailIdAlt: data.dstEmailIdAlt,
                        srcType: data.dstType,
                    },
                    dst_add: {
                        dstContactPerson: data.dstContactPerson,
                        dstOrg: "org",
                        dstAdd1: data.dstAdd1,
                        dstAdd2: data.dstAdd2,
                        dstLocation: data.dstLocation,
                        dstContact1: data.dstContact1?.toString() || "",
                        dstContact2: data.dstContact2?.toString() || "",
                        dstCity: data.dstCity,
                        dstState: data.dstState,
                        dstPincode: data.dstPincode?.toString() || "",
                        dstLandmark: data.dstLandmark,
                        dstEmailId: data.dstEmailId,
                        dstEmailIdAlt: data.dstEmailIdAlt,
                        dstType: data.dstType,
                    },
                },
            ],
        };
        // console.log("getDropOffJSON params....." + JSON.stringify(params));

        return params;
    }
    catch {
        console.log("Error in ticket creation");
    }
}

export function getDropOffJSONBulk(data, ticketScope) {
    try {
        const d = new Date().toISOString().slice(0, 10);
        let bulkProducts = [];
        data.map((r) => {
            let products = {
                primary: {
                    productId: r.productId,
                    productCode: r.productCode,
                    brandId: r.brandId,
                    modelId: r.modelId,
                    brandName: r.brandName,
                    modelName: r.modelName,
                    brandCode: r.brandCode,
                    modelCode: r.modelCode,
                    productName: r.productName,
                    dateOfPurchase: r.dateOfPurchase,
                    identificationNo: r.identificationNo.toString().trim(),
                    productDesc: r.productDesc,
                    problemDesc: r.problemDesc,
                    productValue: r.productValue?.toString() || "",
                    cost: r.cost?.toString() || "",
                    isUnderWarranty: r.isUnderWarranty,
                    accessories: "",
                    pickupType: "",
                    productDimensions: {
                        productUnit: r.productDimensionUnit,
                        productLength: r.productLength,
                        productBreadth: r.productBreadth,
                        productHeight: r.productHeight
                    },
                    productWeight: {
                        productUnit: r.productWeightUnit,
                        productActualWeight: r.productActualWeight,
                        productVolumeWeight: r.productVolumeWeight
                    }
                },
                product_details: {
                    process_id: "",
                    ticketQc: "",
                    productBoxes: [
                        {
                            boxName: r.boxName,
                            boxSlNo: r.boxSlNo
                        }
                    ]
                },
                src_add: {
                    srcContactPerson: r.dstContactPerson,
                    srcOrg: "org",
                    srcAdd1: r.dstAdd1,
                    srcAdd2: r.dstAdd2,
                    srcLocation: r.dstLocation,
                    srcContact1: r.dstContact1?.toString() || "",
                    srcContact2: r.dstContact2?.toString() || "",
                    srcCity: r.dstCity,
                    srcState: r.dstState,
                    srcPincode: r.dstPincode?.toString() || "",
                    srcLandmark: r.dstLandmark,
                    srcEmailId: r.dstEmailId,
                    srcEmailIdAlt: r.dstEmailIdAlt,
                    srcType: r.dstType,
                    srcLatitude: r?.srcLatitude,
                    srcLongitude: r?.srcLongitude,
                },
                dst_add: {
                    dstContactPerson: r.dstContactPerson,
                    dstOrg: "org",
                    dstAdd1: r.dstAdd1,
                    dstAdd2: r.dstAdd2,
                    dstLocation: r.dstLocation,
                    dstContact1: r.dstContact1?.toString() || "",
                    dstContact2: r.dstContact2?.toString() || "",
                    dstCity: r.dstCity,
                    dstState: r.dstState,
                    dstPincode: r.dstPincode?.toString() || "",
                    dstLandmark: r.dstLandmark,
                    dstEmailId: r.dstEmailId,
                    dstEmailIdAlt: r.dstEmailIdAlt,
                    dstType: r.dstType,
                    dstLatitude: r?.dstLatitude,
                    dstLongitude: r?.dstLongitude
                },
            };
            bulkProducts.push(products);
        });
        const params = {
            primary: {
                ticketPriority: data[0].ticketPriority,
                flowId: data[0].flowId,
                retailerId: data[0].retailerId,
                retailerNo: data[0].retailerNo?.toString() || "",
                conComplaintNo: data[0].conComplaintNo?.toString() || "",
                LRNumber: data[0].LRNumber?.toString() || "",
                orderNo: data[0].orderNo?.toString() || "",
                invoiceNo: data[0].invoiceNo?.toString() || "",
                parentTicket: "",
                ticketDetails: data[0].ticketDetails,
                specialInstructions: data[0].specialInstructions,
                buyerContactNumber: "",
                dateOfTicket: d?.toString() || "",
                isAppointmentReq: data[0].isAppointmentReq,
                isInstallationReq: data[0].isInstallationReq,
                isPhysicalEval: data[0].isPhysicalEval,
                isTechEval: data[0].isTechEval,
                isPackingReq: data[0].isPackingReq,
                paymentMode: "cash",
                ticketScope: ticketScope,
                deliveryType: data[0].deliveryType,
                carrier: data[0].carrier

            },
            primary_details: {
                cus_field1: "",
                cus_field2: "",
            },
            products: bulkProducts,
        };
        return params;
    } catch {
        console.log("Error in ticket creation>>>>>>>>>>getDropOffJSON");
    }
}