import React from "react";
import FormLib from "./../../_common/FormLib";

class RoleGroups extends FormLib {
  state = {
    data: {
      roleGroupId: "",
      divisionId: "",
      groupName: "",
      status: "",
      sort: "",
    },
    errors: {},
    curDivision: {},
    curGroup: {},
  };

  isCur(id, cur) {
    if (id === cur) {
      return " curTasks ";
    }
    return " ";
  }

  componentDidMount() {
    this.setCurDivision(this.props.curDivision);
  }
  setCurDivision(d) {
    this.setState({ curDivision: d });
  }
  setCurGroup(g) {
    if (g.roleGroupId !== "") {
      g.status = 1;
      this.setState({ data: g });
    } else {
      this.resetData();
    }
    // console.log(JSON.stringify(this.state.data));
  }

  resetData() {
    this.setState({
      data: {
        roleGroupId: "",
        divisionId: "",
        groupName: "",
        status: "",
        sort: "",
      },
    });
  }
  render() {
    const roleDivs = this.props.roleDivs;
    const curDivision = this.props.curDivision;
    const curGroup = this.props.curGroup;
    const roleGroups = this.props.roleGroups;

    return (

      <div className="card">
        <h5 className="card-header ">
          Role Groups
          {curDivision.divisionId !== "" && !curGroup.roleGroupId && (
            <button
              type="button"
              className="btn btn-sm btn-primary pull-right"
              data-toggle="modal"
              data-target="#myGroupModel"
            >
              <i className={"fa fa-plus"} />
            </button>
          )}
          {curGroup.roleGroupId && curGroup.roleGroupId !== "" && (
            <>
              <button
                type="button"
                className="btn btn-sm btn-warning pull-right"
                onClick={() => this.props.onGroupUnload()}>
                <i className={"fa fa-unlink"} />
              </button>
              <button
                type="button"
                className="btn btn-sm btn-success pull-right"
                data-toggle="modal"
                data-target="#myGroupModel">
                <i className={"fa fa-pencil"} />
              </button>
            </>
          )}
        </h5>

        <div className="card-body">
          <div className="page-todo">
            <div className="tasks">
              {roleGroups.map((g) => (
                <div
                  className={"task nil " +
                    this.isCur(g.roleGroupId, curGroup.roleGroupId)}
                  key={g.roleGroupId}>
                  <div className="desc">
                    <span
                      className="span-click"
                      onClick={() => { this.props.onGroupChange(g) }}>
                      <div className="title">{g.groupName}</div>
                    </span>
                  </div>

                  <div className="pull-right">
                    <div className="menu">
                      <span
                        className={
                          "badge   badge-success pull-left " +
                          "badge-" +
                          g.status
                        }>
                        {g.sort + " : " + g.status}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RoleGroups;
