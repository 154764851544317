import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLPrevilages;
function api(path, subURL = "/teamMapping") {
    path = path !== "" ? "/" + path : "";
    return `${baseURL}${subURL}${path}`;
}

export function getServiceCenters(teamId) {
    return _http.get(api("getServiceCenter/" + teamId), _http.headerJWT());
}
