import React, { Component } from "react";
import Header from "./Header";
import { NavLink } from "react-router-dom";

import Li from "./Li";
import Nlk from "./Nlk";
import config from "../../config.json";

class Sidebar extends Component {
  isAdminUserValid = (user) => {
    if (
      (user["username"] === "opsadmin" ||
        user["username"] === "admin" ||
        user["designation"] === "Chief Operating Officer") &&
      user["code"] === "BIZ"
    ) {
      return true;
    }
    return false;
  };

  isUserCTO = (user) => {
    if (
      user["designation"] === "Chief Technical Officer" &&
      user["code"] === "BIZ"
    ) {
      return true;
    }
    return false;
  };

  isUserRetailerOps = (user) => {
    if (
      user["designation"] &&
      user["designation"] === "Operations - Retailers"
    ) {
      return true;
    }
    return false;
  };

  isUserRetailerLogin = (user) => {
    if (
      user["designation"] &&
      user["designation"] === "Retailer Operations Upload"
    ) {
      return true;
    }
    return false;
  };

  isUserConsultant = (user) => {
    if (user["username"] === "Amardeep") {
      return true;
    }
    return false;
  };

  isUserSoftwareDeveloper = (user) => {
    if (user["designation"] && user["designation"] === "Software Developer") {
      return true;
    }
    return false;
  };
  isUserCOO = (user) => {
    if (
      user["designation"] &&
      user["designation"] === "Chief Operating Officer"
    ) {
      return true;
    }
    return false;
  };
  isUserOps = (user) => {
    if (
      (user["roleCode"] && user["roleCode"] === "admin") ||
      user["roleCode"] === "hub_manager" ||
      user["roleCode"] === "coordinator"
    ) {
      return true;
    }
    return false;
  };
  isUserFinanceHead = (user) => {
    if (user["roleCode"] && user["roleCode"] === "finance_head") {
      return true;
    }
    return false;
  };
  isUserFinanceHeadNew = (user) => {
    if (user["designation"] && user["designation"] === "Finance Head") {
      return true;
    }
    return false;
  };
  isUserFinanceExecutive = (user) => {
    if (
      (user["designation"] && user["designation"] === "Finance Executive") ||
      user["designation"] === "Finance Manager"
    ) {
      return true;
    }
    return false;
  };

  isUserOpearationAdminHO = (user) => {
    if (user["designation"] && user["designation"] === "HO Operations Admin") {
      return true;
    }
    return false;
  };

  isUserOpearationAdminKAM = (user) => {
    if (user["designation"] && user["designation"] === "Key Accounts Manager") {
      return true;
    }
    return false;
  };
  isUserOpearationsHead = (user) => {
    if (user["designation"] && user["designation"] === "Operations Head") {
      return true;
    }
    if (
      user["username"] === "BLR0374" || //Vadhiraj
      user["username"] === "CHE001" || //Balaji
      user["username"] === "BLR0252" || //Avinash
      user["username"] === "BLR0196" || //Prasanna
      user["username"] === "DEL0093" || //Jagbir
      user["username"] === "KOL0025" || //Suvojith
      user["username"] === "BLR0271" ||
      user["username"] === "COO" ||
      user["username"] === "BLR0359" //Sangram
    ) {
      return true;
    }

    return false;
  };
  isUserHubRelated = (user) => {
    if (
      (user["designation"] && user["designation"] === "Hub Coordinator") ||
      user["designation"] === "Hub Manager"
    ) {
      return true;
    }
    return false;
  };

  isUserLifeLong = (user) => {
    if (user["id"] && user["id"] === "lifelongretailer@yopmail.com") {
      return true;
    }
    return false;
  };

  isUserBiz = (user) => {
    if (user["code"] === "BIZ") {
      return true;
    }
    return false;
  };

  isUserOmniCom = (user) => {
    if (user["code"] === "OFM") {
      return true;
    }
    return false;
  };

  isUserLFL = (user) => {
    if (user["code"] === "LFL") {
      return true;
    }
    return false;
  };

  isUserReliance = (user) => {
    if (user["username"] === "reliance") {
      return true;
    }
    return false;
  };
  isUserDocketUpdate = (user) => {
    if (user["username"] === "BLR0348" || user["username"] === "BLR0237") {
      return true;
    }
    return false;
  };

  isUserRelianceCustomerSupport = (user) => {
    if (
      user["username"] === "reliancesupport" ||
      user["username"] === "relianceservice"
    ) {
      return true;
    }
    return false;
  };

  render() {
    const { user } = this.props;

    return (
      <div id="sidebar-wrapper" className="visible-scrollbar">
        <Header user={user} />

        <ul className="sidebar-nav nav-pills nav-stacked" id="menu">
          {/* -----------------------------------Retailer Logins start------------------------------------ */}
          {user && this.isUserRetailerLogin(user) && (
            <>
              <Li faIcon="fa-upload" to="/ticketUpload" label="Ticket Upload" />
              <Li faIcon="fa-table" to="/misNewReport" label="Mis Report" />
              {/* <Li
                faIcon="fa-history"
                to="/TicketHistory/TicketHistory"
                label="Ticket History"
              /> */}
              <Li
                faIcon="fa-history"
                to="/MultiTicketHistory"
                label="Ticket History"
              />
              <Li
                app="TS"
                faIcon="fa-dashboard"
                to="/static-reports/clientReport"
                label="Client Report"
              />
            </>
          )}

          {user && this.isUserConsultant(user) && (
            <>
              <Li faIcon="fa-upload" to="/ticketUpload" label="Ticket Upload" />
              <Li
                faIcon="fa-spinner"
                to="/ticketProcess"
                label="Ticket Process"
              />
              <Li
                faIcon="fa-history"
                to="/MultiTicketHistory"
                label="Ticket History"
              />
              <Li faIcon="fa-table" to="/misNewReport" label="Mis Report" />
            </>
          )}

          {/* -----------------------------------Retailer Logins End------------------------------------ */}
          {user &&
            (this.isAdminUserValid(user) ||
              this.isUserLifeLong(user) ||
              this.isUserCTO(user)) && (
              <>
                {/* <Li
                  faIcon="fa-table"
                  to="/ticket/create/lifelong"
                  label="Lifelong Ticket Create"
                />

                <Li
                  faIcon="fa-table"
                  to="/ticket/lifelong/LifeLongTicketList"
                  label="Lifelong Tickets"
                /> */}
                {/* <Li faIcon="fa-table" to="/misOldReport" label="Mis Status" /> */}
                {/* <Li
                  faIcon="fa-table"
                  to="/MISDocketUpdate"
                  label="Mis Docket Update"
                /> */}
              </>
            )}

          {user &&
            (this.isAdminUserValid(user) ||
              this.isUserBiz(user) ||
              this.isUserOpearationAdminKAM(user) ||
              this.isUserOpearationAdminHO(user) ||
              this.isUserOpearationsHead(user) ||
              this.isUserHubRelated(user) ||
              this.isUserCTO(user)) &&
            !this.isUserConsultant(user) && (
              <>
                <li>
                  <Nlk faIcon="fa-dashboard" to="/" label="Expenses Module" />
                  <ul className="nav-pills nav-stacked">
                    <Li
                      faIcon="fa-arrow-right"
                      to="/v1/request/raise"
                      label="Raise"
                    />

                    <Li
                      faIcon="fa-arrow-right"
                      to="/v1/request/approve"
                      label="Process"
                    />
                    <Li
                      faIcon="fa-arrow-right"
                      to="/ticketActivities"
                      label="Ticket Activities"
                    />
                    {user && (this.isUserOpearationsHead(user) || this.isUserFinanceExecutive(user) || 
                    this.isUserOpearationAdminKAM(user) ) && (
                      <>
                        <Li
                          faIcon="fa-table"
                          to="/v1/request/reports"
                          label="Expense Reports"
                        />
                        <Li
                          faIcon="fa-table"
                          to="/v1/request/PaymentReports"
                          label="Payment Reports"
                        />
                      </>
                    )}
                  </ul>
                </li>
                {/* <li>
                  <a href={config[config.build]["apiDirect"]+"/invoice-tickets"}>
                    <span className="fa-stack  fa-lg pull-left">
                      <i className={"fa fa-inr fa-stack-1x"}></i>
                    </span>
                    {"Invoice management"}
                  </a>
                </li> */}
              </>
            )}
          {user &&
            (this.isAdminUserValid(user) ||
              this.isUserFinanceHead(user) ||
              this.isUserFinanceHeadNew(user) ||
              this.isUserFinanceExecutive(user) ||
              this.isUserCTO(user)) &&
            !this.isUserConsultant(user) && (
              <>
                <li>
                  <Nlk faIcon="fa-dashboard" to="/" label="Expenses Module" />
                  <ul className="nav-pills nav-stacked">
                    <Li
                      faIcon="fa-arrow-right"
                      to="/v1/request/raise"
                      label="Raise"
                    />

                    <Li
                      faIcon="fa-arrow-right"
                      to="/v1/request/approve"
                      label="Process"
                    />
                    <Li
                      faIcon="fa-arrow-right"
                      to="/ticketActivities"
                      label="Ticket Activities"
                    />

                    <Li
                      faIcon="fa-arrow-right"
                      to="/v1/request/excel-download"
                      label="Trans. Excel Download"
                    />
                    <Li
                      faIcon="fa-arrow-right"
                      to="/v1/request/excel-upload"
                      label="Trans. Excel Upload"
                    />
                    <Li
                      faIcon="fa-table"
                      to="/v1/request/reports"
                      label="Expense Reports"
                    />
                    <Li
                      faIcon="fa-table"
                      to="/v1/request/PaymentReports"
                      label="Payment Reports"
                    />
                    <Li
                      faIcon="fa-arrow-right"
                      to="/v1/request/emp-accounts"
                      label="Emp A/C Details"
                    />
                    <Li
                      faIcon="fa-arrow-right"
                      to="/v1/request/ven-accounts"
                      label="Vendor A/C Details"
                    />
                    <Li
                      faIcon="fa-arrow-right"
                      to="/v1/request/expenseHead-accounts"
                      label="Expense Head"
                    />
                    <Li
                      faIcon="fa-arrow-right"
                      to="/v1/request/emp-accounts-upload"
                      label="Emp Acc Upload"
                    />
                    <Li
                      faIcon="fa-arrow-right"
                      to="/v1/request/ven-accounts-upload"
                      label="Ven Acc Upload"
                    />
                  </ul>
                </li>
                <li>
                  <a
                    href={`${config[config.build]["apiDirect"]
                      }/invoice-tickets`}
                  >
                    <span className="fa-stack  fa-lg pull-left">
                      <i className={"fa fa-inr fa-stack-1x"}></i>
                    </span>
                    {"Invoice management"}
                  </a>
                </li>
              </>
            )}

          {/* 
          <li>
            <Nlk faIcon="fa-dashboard" to="/" label="Requests Module" />
            <ul className="nav-pills nav-stacked">
              <Li
                faIcon="fa-arrow-right"
                to="/v1/request/raise"
                label="Raise"
              />

              <Li
                faIcon="fa-arrow-right"
                to="/v1/request/approve"
                label="Process"
              /> */}
          {/* {user && user["roleCode"] === "finance_head" && (
                    <> */}
          {/* <Li
                faIcon="fa-arrow-right"
                to="/v1/request/excel-download"
                label="Trans. Excel Download"
              />
              <Li
                faIcon="fa-arrow-right"
                to="/v1/request/excel-upload"
                label="Trans. Excel Upload"
              />
              <Li
                faIcon="fa-table"
                to="/v1/request/reports"
                label="Request Reports"
              />
              <Li
                faIcon="fa-table"
                to="/v1/request/PaymentReports"
                label="Payment Reports"
              />
              <Li
                faIcon="fa-arrow-right"
                to="/v1/request/emp-accounts"
                label="Emp A/C Details"
              />
              <Li
                faIcon="fa-arrow-right"
                to="/v1/request/ven-accounts"
                label="Vendor A/C Details"
              />
              <Li
                faIcon="fa-arrow-right"
                to="/v1/request/expenseHead-accounts"
                label="Expense Head"
              />
              <Li
                faIcon="fa-arrow-right"
                to="/v1/request/emp-accounts-upload"
                label="Emp Acc Upload"
              />
              <Li
                faIcon="fa-arrow-right"
                to="/v1/request/ven-accounts-upload"
                label="Ven Acc Upload"
              />

            </ul>
          </li> */}

          {user &&
            (this.isAdminUserValid(user) ||
              this.isUserBiz(user) ||
              this.isUserCTO(user)) &&
            !this.isUserConsultant(user) && (
              <>
                <li>
                  <Nlk faIcon="fa-dashboard" to="/" label="Dashboards" />
                  <ul>
                    <li>
                      <a
                        href={`${config[config.build]["apiDirect"]
                          }/dashboards/count-by-status`}
                      >
                        <span className="fa-stack  fa-lg pull-left">
                          <i className={"fa fa-arrow-right fa-stack-1x "}></i>
                        </span>
                        {"Active Tickets"}
                      </a>
                    </li>
                    <Li
                      faIcon="fa-spinner"
                      to="/countByStatusHubswiseCharts"
                      label="Tickets status Hubwise"
                    />
                  </ul>
                </li>
                {/* <Li
                  faIcon="fa-upload"
                  to="/MaintenancePage"
                  label="Maintenance Page"
                /> */}
                <Li
                  faIcon="fa-upload"
                  to="/ticketUpload"
                  label="Ticket Upload"
                />
                <Li
                  faIcon="fa-spinner"
                  to="/ticketProcess"
                  label="Ticket Process"
                />
                {/* <Li
                  faIcon="fa-arrow-right"
                  to="/vehicleTrip"
                  label="Vehicle Trip Details"
                /> */}
                {/* <Li
                  faIcon="fa-arrow-right"
                  to="/VehicleTripDetailsNew"
                  label="Vehicle Trip Details New"
                /> */}
                {/* <Li
                  faIcon="fa-truck"
                  to="/vehicleTripMasterScreen"
                  label="Vehicle Master Screen"
                />
                <Li
                  faIcon="fa-truck"
                  to="/tripStart"
                  label="Trip Start"
                /> */}
                {/* <Li
                  faIcon="fa-truck"
                  to="/tripEnd"
                  label="Trip End"
                /> */}
                {/* <Li
                  faIcon="fa-truck"
                  to="/tripClose"
                  label="Trip Close"
                /> */}
                {/* <Li
                  faIcon="fa-truck"
                  to="/addAndRemoveTickets"
                  label="Add Or Remove Tickets"
                /> */}
                {/* <Li
                  faIcon="fa-truck"
                  to="/assignTicketsToTrip"
                  label="Assign Tickets To The Trip"
                />
                <Li
                  faIcon="fa-truck"
                  to="/assignTripToUsers"
                  label="Assign Trip To The Users"
                />
                <Li
                  faIcon="fa-truck"
                  to="/updateTheTrip"
                  label="Update The Trip"
                /> */}
                {/* <Li
                faIcon="fa-spinner"
                to="/ticketProcessNew"
                label="Ticket Process Bulk"
              /> */}
                {/* <Li
                faIcon="fa-spinner"
                to="/dragAndDrop"
                label="Drag and Drop Demo"
              /> */}

                <Li
                  faIcon="fa-regular fa-truck"
                  to="/linehaul"
                  label="Linehaul Process"
                />
                {/* <Li faIcon="fa-table" to="/misReport" label="Mis Report old" /> */}
              </>
            )}
          {user &&
            (this.isAdminUserValid(user) ||
              this.isUserRetailerOps(user) ||
              this.isUserBiz(user) ||
              this.isUserCTO(user)) &&
            !this.isUserConsultant(user) && (
              <>
                <Li faIcon="fa-table" to="/misNewReport" label="Mis Report" />
                {/* <Li
                  faIcon="fa-history"
                  to="/TicketHistory/TicketHistory"
                  label="Ticket History"
                /> */}
                <Li
                  faIcon="fa-history"
                  to="/MultiTicketHistory"
                  label="Ticket History"
                />
                {/* <Li
                  faIcon="fa-ticket"
                  to="/ticketSequencing"
                  label="TicketSequencing"
                /> */}
                {/* <Li
                  faIcon="fa-ticket"
                  to="/ticketSequencing"
                  label="TicketSequencing"
                /> */}
                {/* <Li
                  faIcon="fa-table"
                  to="/misReportLarge"
                  label="Mis Report Large"
                /> */}
              </>
            )}

          {user &&
            (this.isUserSoftwareDeveloper(user)) && (
              <>
                <li>
                  <Nlk faIcon="fa-dashboard" to="/" label="PLMS" />
                  <ul className="nav-pills nav-stacked">
                    <Li
                      faIcon="fa-dashboard"
                      to="/storyboard/board"
                      label="Story Board"
                    />
                    {/* <Li
                    faIcon="fa-dashboard"
                    to="/storyboard/story-gantt-chart"
                    label="Gantt chart"
                  /> */}

                    <Li
                      faIcon="fa-dashboard"
                      to="/storyboard/stories"
                      label="Stories"
                    />
                    <Li
                      faIcon="fa-dashboard"
                      to="/storyboard/tasks"
                      label="Tasks"
                    />

                    <Li
                      faIcon="fa-dashboard"
                      to="/storyboard/daily-reports"
                      label="Daily Reports"
                    />
                    <Li
                      faIcon="fa-dashboard"
                      to="/storyboard/daily-plans"
                      label="Daily Plans"
                    />
                    <Li
                      faIcon="fa-dashboard"
                      to="/storyboard/db-board"
                      label="DB Board"
                    />
                  </ul>
                </li>
                {/* <Li

                  faIcon="fa-arrow-right"
                  to="/dashboards"
                  label="Dashboards"
                /> */}
                <li>
                  <a
                    href={`${config[config.build]["apiDirect"]
                      }/dashboards/count-by-status`}
                  >
                    <span className="fa-stack  fa-lg pull-left">
                      <i className={"fa fa-arrow-right fa-stack-1x "}></i>
                    </span>
                    {"Dashboards"}
                  </a>
                </li>
                <li>
                  <a
                    href={
                      config[config.build]["apiDirect"] + "/invoice-tickets"
                    }
                  >
                    <span className="fa-stack  fa-lg pull-left">
                      <i className={"fa fa-inr fa-stack-1x"}></i>
                    </span>
                    {"Invoice management"}
                  </a>
                </li>

                <li>
                  <Nlk faIcon="fa-dashboard" to="/" label="Charts JS" />
                  <ul className="nav-pills nav-stacked">
                    <Li
                      faIcon="fa-arrow-right"
                      to="/demo/charts"
                      label="Charts"
                    />

                  </ul>
                </li>
                <li>
                  {/* <Nlk faIcon="fa-file" to="/" label="MIS Billing" /> */}
                  {/* <ul className="nav-pills nav-stacked">
                    <Li
                      faIcon="fa-arrow-right"
                      to="/misBilling/UploadBill"
                      label="Upload Bill"
                    />
                    <Li
                      faIcon="fa-arrow-right"
                      to="/misBilling/BillTickets"
                      label="Bill Tickets"
                    />
                    <Li
                      faIcon="fa-arrow-right"
                      to="/misBilling/UpdateBill"
                      label="Update Bill"
                    />
                  </ul> */}
                </li>

                {/* <Li faIcon="fa-dashboard" to="/auth/angJS" label="AngJS Login" /> */}

                <li>
                  {/* <Nlk faIcon="fa-inr" to="/" label="Transactions" /> */}
                  {/* <ul className="nav-pills nav-stacked">
                    <Li
                      faIcon="fa-table"
                      to="/loc-master/country"
                      label="Countries"
                    />
                    <Li faIcon="fa-table" to="/loc-master/state" label="States" />
                    <Li faIcon="fa-table" to="/loc-master/city" label="Cities" />
                  </ul> */}
                </li>

                {/* <Li faIcon="fa-dashboard" to="/bluedart" label="BlueDart" /> */}

                {/* <li>
                  <Nlk faIcon="fa-dashboard" to="/" label="Demo Components" />
                  <ul className="nav-pills nav-stacked">
                    <Li
                      faIcon="far fa-comments"
                      to="/support/chats"
                      label="Support Chats"
                    />
                    <Li
                      faIcon="far fa-comments"
                      to="/support/groups"
                      label="Support Groups"
                    />

                    <Li
                      faIcon="far fa-question"
                      to="/coreItAdmin/qaValidata/Questionaries"
                      label="Questionaries"
                    />
                    <Li
                      faIcon="fa-arrow-right"
                      to="/demo/charts"
                      label="Charts"
                    />

                    <Li faIcon="fa-arrow-right" to="/demo/form" label="Form" />
                    <Li
                      faIcon="fa-arrow-right"
                      to="/demo/TasksDemo"
                      label="TasksDemo"
                    />
                    <Li faIcon="fa-table" to="/demo/list" label="List" />
                    <Li
                      faIcon="fa-table"
                      to="/demo/list/client-side-pagination"
                      label="List - Client Side Pagination"
                    />
                    <Li
                      faIcon="fa-table"
                      to="/demo/list/server-side-pagination"
                      label="List - Server Side Pagination"
                    />
                    <Li
                      faIcon="fa-table"
                      to="/demo/dynamic-elements"
                      label="DynamicElements"
                    />

                    <Li
                      faIcon="fa-table"
                      to="/demo/MasterSync"
                      label="MasterSync"
                    />

                    <Li
                      faIcon="fa-table"
                      to="/demo/FileUpload"
                      label="FileUpload"
                    />

                    <Li
                      faIcon="fa-table"
                      to="/4Team/ProjectForm"
                      label="Project Form"
                    />
                  </ul>
                </li> */}

                {/* <Li
                  faIcon="fa-table"
                  to="/masters/RetailerFlows"
                  label="Retailer Flows"
                /> */}

                {/* <Li
                  faIcon="fa-dashboard"
                  to="/ticketCreation"
                  label="Ticket Creation"
                /> */}

                {/* <Li
                  faIcon="fa-upload"
                  to="/ticketUploadNew"
                  label="Ticket Upload New"
                /> */}

              </>
            )}
          {user &&
            (this.isUserCTO(user) ||
              // this.isAdminUserValid(user) ||
              this.isUserSoftwareDeveloper(user) ||
              user["username"] === "admin") && (
                <>
                </>
              // <li>
              //   <Nlk faIcon="fa-dashboard" to="#" label="Attendance" app="TS" />
              //   <ul>
              //     <Li
              //       app="TS"
              //       faIcon="fa-shelves"
              //       to="/attendance/roster"
              //       label="Roster"
              //     />
              //     <Li
              //       app="TS"
              //       faIcon="fa-shelves"
              //       to="/attendance/overrides"
              //       label="Overrides"
              //     />
              //     {/* <Li
              //     app="TS"
              //     faIcon="fa-shelves"
              //     to="/attendance/regularization"
              //     label="Regularization"
              //   /> */}
              //     <Li
              //       app="TS"
              //       faIcon="fa-shelves"
              //       to="/attendance/shiftMaster"
              //       label="Shift master"
              //     />
              //     <Li
              //       app="TS"
              //       faIcon="fa-shelves"
              //       to="/attendance/hubConfigMaster"
              //       label="Hub Configs master"
              //     />
              //     <Li
              //       app="TS"
              //       faIcon="fa-shelves"
              //       to="/attendance/userConfigMaster"
              //       label="User Configs master"
              //     />
              //   </ul>
              // </li>
             
            )}

          {user &&
            (this.isUserSoftwareDeveloper(user) ||
              this.isAdminUserValid(user) ||
              this.isUserHubRelated(user) ||
              this.isUserOpearationAdminKAM(user) ||
              this.isUserOpearationsHead(user) ||
              //  this.isUserRetailerLogin(user) ||
              this.isUserOpearationAdminHO(user) ||
              this.isUserOpearationAdminKAM(user) ||
              this.isUserOpearationsHead(user) ||
              this.isUserFinanceHead(user) ||
              this.isUserFinanceHeadNew(user) ||
              this.isUserFinanceExecutive(user) ||
              this.isUserCTO(user)) &&
            !this.isUserConsultant(user) && (
              <>
                <li>
                  <Nlk faIcon="fa-dashboard" to="/" label="Inventory" />
                  <ul className="nav-pills nav-stacked">
                    {/* <Li
                      faIcon="fa-shelves"
                      to="/inwardInventory"
                      label="Inward"
                    />
                    <Li
                      faIcon="fa-shelves"
                      to="/outwardInventory"
                      label="Outward"
                    /> */}
                    {/* <Li
                      faIcon="fa-shelves"
                      to="/stackInventory"
                      label="Storing"
                    /> */}
                    {/* <Li
                      faIcon="fa-dashboard"
                      to="/inventory"
                      label="Inventory"
                    /> */}
                    <Li
                      faIcon="fa-dashboard"
                      to="/barcodeMaster"
                      label="Barcode Master"
                    />
                    <Li
                      faIcon="fa-inventory"
                      to="/inventoryNewScreen"
                      label="Inventory Module"
                    />
                    <Li
                      faIcon="fa-inventory"
                      to="/stockManagement"
                      label="StockManagement"
                    />
                    {/* <Li
                      faIcon="fa-inventory"
                      to="/stockManagementFilter"
                      label="StockManagementFilter"
                    /> */}
                    {/* <Li
                      faIcon="fa-inventory"
                      to="/assigningPmToUser"
                      label="Assign PM To Users"
                    /> */}
                    <Li
                      faIcon="fa-inventory"
                      to="/assignBarcodesToUser"
                      label="Assign Barcodes To The Users"
                    />
                    {/* <Li
                      faIcon="fa-inventory"
                      to="/revertingBarcodesFromUsers"
                      label="Return Barcode Items"
                    /> */}
                    <Li
                      faIcon="fa-inventory"
                      to="/returnBarcodesFromUser"
                      label="Return Barcodes From User"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/AttachAdditionalBarcodes"
                      label="Attach Additional Barcodes"
                    />
                    {/* <Li
                      faIcon="fa-shelves"
                      to="/ticketInfo"
                      label="Ticket Information"
                    /> */}
                  </ul>
                </li>
                {/* 
                <li>
                  <Nlk
                    faIcon="fa-dashboard"
                    to="#"
                    label="Inventory Reports"
                    app="TS"
                  />
                  <ul>
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/inventory/hubwise"
                      label="Item stock summary"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/inventory/ticketwise"
                      label="Inventory details"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/inventory/trxhistory"
                      label="Inward inventory"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/inventory/stacking"
                      label="Inventory storage"
                    />
                  </ul>
                </li> */}

                <li>
                  <Nlk
                    faIcon="fa-dashboard"
                    to="#"
                    label="Reports (Live)"
                    app="TS"
                  />
                  <ul>
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/hubMatrix"
                      label="Hub Matrix"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/kamMatrixNew"
                      label="KAM User Matrix"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/ticketProcess/ticketCountByStatusHub"
                      label="Ticket counts by status"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/ticketInflowByTimeSlots"
                      label="Tickets Inflow by Time slots "
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/ticketProcessedByTimeSlots"
                      label="Tickets processed by Time slots "
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/activeRetailerMonth"
                      label="Active Clients Monthwise"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/lineHaulSummary"
                      label="Linehaul Summary Report"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/packingMaterialStats"
                      label="Packing material summary"
                    />

                    {/* <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/inventory/hubwise"
                      label="Item stock summary"
                    /> */}
                    {/* <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/inventory/ticketwise"
                      label="Inventory details"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/inventory/trxhistory"
                      label="Inward inventory"
                    /> */}
                    {/* <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/inventory/stacking"
                      label="Inventory storage"
                    /> */}

                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/location"
                      label="Location Reports"
                    />

                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/pincodes"
                      label="Serviceable Pincodes"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/pincodesAddressedSummary"
                      label="Pincodes Serviced"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/citiesActioned"
                      label="Cities Serviced"
                    />

                    {/* <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/hubMatrix"
                      label="Hub Matrix"
                    /> */}

                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/expenseRaisedReport"
                      label="Expenses Raised Report"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/expensePaymentReport"
                      label="Expenses Payment Report"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/fePerformanceSummary"
                      label="Fe Performance Summary"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/fePerformance"
                      label="Fe Performance Detailed"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/SmsReports"
                      label="SMS delivery details"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/inwardInventoryNewReport"
                      label="Inward Inventory Report"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/outwardSummary"
                      label="Outward Summary Report"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/inwardInventory"
                      label="Ticket Inventory Report"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/clientReport"
                      label="Client Report"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/slawReport"
                      label="Slaw Report"
                    />
                    <Li
                      app="TS"
                      faIcon="fa-shelves"
                      to="/static-reports/damagedBarcodeStatusReport"
                      label="Damaged Barcode Status Report"
                    />
                  </ul>
                  {/* {((user && this.isUserCTO(user)) ||
                    this.isAdminUserValid(user) ||
                    this.isUserSoftwareDeveloper(user) ||
                    user["username"] === "admin") && (
                      <>
                        <Nlk
                          faIcon="fa-dashboard"
                          to="#"
                          label="Reports (coming Soon)"
                          app="TS"
                        />
                        <ul>
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/expenseSummary"
                            label="Expense Summary Report"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/requestMatrix"
                            label="Expense Matrix"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/partPaymentFilterByNameNvendor"
                            label="Party Payments report"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/accountHeadWise"
                            label="Expense details"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/retailerWise"
                            label="Expenses Retailer wise"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/invoiceMappedToTickets"
                            label="Invoice report ticket wise"
                          />

                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/timeElapsedBetweenStatus"
                            label="Time Elapsed Between Status"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/hubWiseTotalDetails"
                            label="Hub Wise Total Details"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/discripancySummaryreport"
                            label="Discripancy Summary Report"
                          />

                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/requestsMappedToTickets"
                            label="Expense Mapped to Tickets"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/conveyencePerTicket"
                            label="Conveyence Reports"
                          />

                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/linehaulInAndOut"
                            label="Linehaul In And Out"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/ivrUsageReport"
                            label="IVR Usage Report"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/ticketPendingReport"
                            label="Ticket Pending Summary Report"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/tprByHubAndRetailer"
                            label=" Ticket Pending Detailed Report "
                          />
                        </ul>
                        <Nlk
                          faIcon="fa-dashboard"
                          to="#"
                          label="Reports (on Hold)"
                          app="TS"
                        />
                        <ul>
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/ticketProcess/ticketCountByStatusRetailer"
                            label="Retailer Wise"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/ticketCountByStatusRetailer"
                            label="Ticket Counts By Status-Retailer"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/stackAndRealTime"
                            label="Static And Real Time Report"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/linehaulRelatedReports"
                            label="LineHaul hubwise"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/linehaulRelatedReportsWithoutDate"
                            label="LineHauls (w/o date) retailer wise"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/linehaulRelatedReportsRetailerWise"
                            label="LineHaul retailerWise"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/discripancyReport"
                            label="LineHaul Discripancy Reports"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/discripancyDetailedReport"
                            label="LineHaul hubwise Discripancy"
                          />
                          <Li
                            app="TS"
                            faIcon="fa-shelves"
                            to="/static-reports/actualVsEstimated"
                            label="LineHaul delay report"
                          />
                        </ul>
                      </>
                    )} */}
                </li>

                <li>
                  <a
                    href={
                      config[config.build]["apiDirect"] + "/invoice-tickets"
                    }
                  >
                    <span className="fa-stack  fa-lg pull-left">
                      <i className={"fa fa-inr fa-stack-1x"}></i>
                    </span>
                    {"Invoice management"}
                  </a>
                </li>
              </>
            )}
          {user && this.isUserOmniCom(user) && (
            <>
              <Li faIcon="fa-table" to="/misNewReport" label="Mis Report" />
              {/* <Li
                faIcon="fa-history"
                to="/TicketHistory/TicketHistory"
                label="Ticket History"
              /> */}
              <Li
                faIcon="fa-history"
                to="/MultiTicketHistory"
                label="Ticket History"
              />
            </>
          )}

          {user && this.isUserLFL(user) && (
            <>
              {/* <Li
                faIcon="fa-table"
                to="/ticket/create/lifelong"
                label="Lifelong Ticket Create"
              />
              <Li
                faIcon="fa-table"
                to="/ticket/lifelong/LifeLongTicketList"
                label="Lifelong Tickets"
              /> */}

              <Li faIcon="fa-upload" to="/ticketUpload" label="Ticket Upload" />
              <Li faIcon="fa-table" to="/misNewReport" label="Mis Report" />
              {/* <Li
                faIcon="fa-history"
                to="/TicketHistory/TicketHistory"
                label="Ticket History"
              /> */}
              <Li
                faIcon="fa-history"
                to="/MultiTicketHistory"
                label="Ticket History"
              />
              <Li
                faIcon="fa-table"
                to="/MISDocketUpdate"
                label="Mis Docket Update"
              />
            </>
          )}

          {user && this.isUserReliance(user) && (
            <>
              <Li faIcon="fa-table" to="/misNewReport" label="Mis Report" />
              {/* <Li
                faIcon="fa-history"
                to="/TicketHistory/TicketHistory"
                label="Ticket History"
              /> */}
              <Li
                faIcon="fa-history"
                to="/MultiTicketHistory"
                label="Ticket History"
              />
            </>
          )}
          {user && this.isUserDocketUpdate(user) && (
            <>
              <Li
                faIcon="fa-table"
                to="/MISDocketUpdate"
                label="Mis Docket Update"
              />
            </>
          )}

          {user && this.isUserRelianceCustomerSupport(user) && (
            <>
              <Li
                faIcon="fa-upload"
                to="/ticketUploadNew"
                label="Ticket Upload"
              />
              {/* <Li
                faIcon="fa-history"
                to="/TicketHistory/TicketHistory"
                label="Ticket History"
              /> */}
              <Li
                faIcon="fa-history"
                to="/MultiTicketHistory"
                label="Ticket History"
              />
            </>
          )}

          {/* {user &&
            (this.isUserHubRelated(user) ||
              this.isUserOpearationAdminKAM(user) ||
              this.isUserOpearationsHead(user)) && (
              <li>
                <Nlk faIcon="fa-table" to="/" label="Operation Admin" />
                <ul className="nav-pills nav-stacked">
                  <Li
                    faIcon="fa fa-user"
                    to="/players/players"
                    label="Players"
                  />

                  <Li
                    faIcon="fa-dashboard"
                    to="/coreItAdmin/player-assign-unassign"
                    label="Player Assign Un-Assign"
                  />
                  <Li
                    faIcon="fa-mobile-phone"
                    to="/ivrSearch"
                    label="Ivr Records"
                  />
                </ul>
              </li>
            )} */}

          {/* {user &&
            (this.isAdminUserValid(user) ||
              this.isUserOpearationAdminHO(user) ||
              this.isUserCTO(user) ||
              this.isUserOpearationsHead(user)) && (
              <li>
                <Nlk faIcon="fa-table" to="/" label="Operation Admin HO" />
                <ul className="nav-pills nav-stacked">
                  <Li
                    faIcon="fa-arrow-right"
                    to="/packing/PackingMaterialStock"
                    label="Packing Material"
                  />
                </ul>
              </li>
            )} */}
          {/* {user &&
            (this.isAdminUserValid(user) || this.isUserHubRelated(user)) && (
              <li>
                <Nlk faIcon="fa-table" to="/" label="Operation Admin" />
                <ul className="nav-pills nav-stacked">
                  <Li
                    faIcon="fa fa-user"
                    to="/players/players"
                    label="Players"
                  />

                  <Li
                    faIcon="fa-dashboard"
                    to="/coreItAdmin/player-assign-unassign"
                    label="Player Assign Un-Assign"
                  />
                  <Li
                    faIcon="fa-mobile-phone"
                    to="/ivrSearch"
                    label="Ivr Records"
                  />
                </ul>
              </li>
            )} */}
            {}

          {/* user["designation"]  (user["roleCode"] === "it" || user["player1"]) &&*/}
          {user &&
            (this.isAdminUserValid(user) ||
              this.isUserCTO(user) ||
              this.isUserOpearationsHead(user) ||
              this.isUserOpearationAdminKAM(user)) && (
              <>
                <li>
                  <Nlk faIcon="fa-dashboard" to="/" label="Masters" />
                  <ul className="nav-pills nav-stacked">
                    {user &&
                      (this.isAdminUserValid(user) ||
                        this.isUserCTO(user) ||
                        this.isUserOpearationsHead(user)) && (
                        <>
                          <Li
                            faIcon="fa-table"
                            to="/players/clients"
                            label="Clients"
                          />
                          <Li
                            faIcon="fa-table"
                            to="/masterLocation/hub"
                            label="Hubs"
                          />
                        </>
                      )}
                    {user &&
                      (this.isAdminUserValid(user) ||
                        this.isUserCTO(user) ||
                        this.isUserOpearationsHead(user) ||
                        this.isUserOpearationAdminKAM(user)) && (
                        <>
                          <Li
                            faIcon="fa-table"
                            to="/masters/PincodesList"
                            label="Pincodes List"
                          />
                          <Li
                            faIcon="fa-table"
                            to="/masters/PincodesMaster"
                            label="Pincodes"
                          />
                          <Li
                            faIcon="fa-table"
                            to="/masters/masterFields"
                            label="Master Fields"
                          />
                        </>
                      )}
                  </ul>
                </li>
              </>
            )}
            {user &&
            (this.isUserOpearationsHead(user)) && (
              <li>
              <Nlk faIcon="fa-table" to="/" label="Operation Admin" />
              <ul className="nav-pills nav-stacked">
                <Li
                  faIcon="fa fa-user"
                  to="/players/players"
                  label="Players"
                />

                <Li
                  faIcon="fa-dashboard"
                  to="/coreItAdmin/player-assign-unassign"
                  label="Player Assign Un-Assign"
                />
                <Li
                  faIcon="fa-mobile-phone"
                  to="/ivrSearch"
                  label="Ivr Records"
                />
              </ul>
            </li>
            )}

{/* <li>
                <Nlk faIcon="fa-table" to="/" label="Operation Admin" />
                <ul className="nav-pills nav-stacked">
                  <Li
                    faIcon="fa fa-user"
                    to="/players/players"
                    label="Players"
                  />

                  <Li
                    faIcon="fa-dashboard"
                    to="/coreItAdmin/player-assign-unassign"
                    label="Player Assign Un-Assign"
                  />
                  <Li
                    faIcon="fa-mobile-phone"
                    to="/ivrSearch"
                    label="Ivr Records"
                  />
                </ul>
              </li> */}
          {user && (this.isAdminUserValid(user) || this.isUserCTO(user)) && (
            <>
              {/* <Li
                faIcon="fa-upload"
                to="/ticketUploadNew"
                label="Ticket Upload New"
              /> */}
              <li>
                <Nlk faIcon="fa-dashboard" to="/" label="Players" />
                <ul className="nav-pills nav-stacked">
                  <Li faIcon="fa-table" to="/players/teams" label="Teams" />
                  <Li faIcon="fa-table" to="/players/players" label="Players" />
                  <Li
                    faIcon="fa-table"
                    to="/players/teamContacts"
                    label="Team Contacts"
                  />
                  <Li faIcon="fa-table" to="/players/emps" label="Emps" />
                  <Li
                    faIcon="fa-table"
                    to="/players/empBankAccs"
                    label="EmpBankAccs"
                  />
                  <Li
                    faIcon="fa-table"
                    to="/players/teamBankAccs"
                    label="TeamBankAccs"
                  />
                  <Li
                    faIcon="fa-table"
                    to="/players/PlayerMobKey"
                    label="Player MobKey"
                  />
                </ul>
              </li>

              {user &&
                (this.isUserSoftwareDeveloper(user) ||
                  this.isAdminUserValid(user) ||
                  this.isUserCTO(user)) && (
                  <li>
                    <Nlk faIcon="fa-dashboard" to="/" label="Master Location" />
                    <ul className="nav-pills nav-stacked">
                      <Li
                        faIcon="fa-table"
                        to="/masterLocation/regions"
                        label="Regions"
                      />
                      <Li
                        faIcon="fa-table"
                        to="/masterLocation/zones"
                        label="Zones"
                      />
                      <Li
                        faIcon="fa-table"
                        to="/masterLocation/hub"
                        label="Hubs"
                      />
                      <Li
                        faIcon="fa-table"
                        to="/masterLocation/country"
                        label="Country"
                      />
                      <Li
                        faIcon="fa-table"
                        to="/masterLocation/states"
                        label="States"
                      />
                      <Li
                        faIcon="fa-table"
                        to="/masterLocation/city"
                        label="Cities"
                      />
                      {/* <Li
                      faIcon="fa-table"
                      to="/masterLocation/pincode"
                      label="Pincodes_PHP"
                    /> */}
                    </ul>
                  </li>
                )}
              <li>
                <Nlk faIcon="fa-dashboard" to="/" label="IVR" />
                <ul className="nav-pills nav-stacked">
                  <Li
                    faIcon="fa-table"
                    to="/ivr/ivr"
                    label="Exophone Mapping"
                  />
                  <Li
                    faIcon="fa-mobile-phone"
                    to="/ivrSearch"
                    label="Ivr Records"
                  />
                </ul>
              </li>
              {/* <Li faIcon="fa fa-undo" to="/resetMobileKey/resetMobileKey" label="Reset Mobile Key" /> */}

              <li>
                <Nlk faIcon="fa-dashboard" to="/" label="Master Pincodes" />
                <ul className="nav-pills nav-stacked">
                  <Li
                    faIcon="fa-table"
                    to="/masters/PincodesMaster"
                    label="Pincodes"
                  />
                  <Li
                    faIcon="fa-table"
                    to="/masters/CustomerPincodes"
                    label="Customer Pincodes"
                  />
                  <Li
                    faIcon="fa-table"
                    to="/masters/PincodesList"
                    label="Pincodes List"
                  />
                  {/* <Li
                    faIcon="fa-table"
                    to="/masterLocation/pincode"
                    label="Pincodes PHP"
                  /> */}
                </ul>
              </li>
              <li>
                <Nlk faIcon="fa-table" to="/" label="Master Products" />
                <ul className="nav-pills nav-stacked">
                  <Li
                    faIcon="fa-dashboard"
                    to="/products/product"
                    label="Products"
                  />
                  {/* <Li
                    faIcon="fa-dashboard"
                    to="/products/model"
                    label="Products Model"
                  /> */}
                  {/* <Li
                    faIcon="fa-table"
                    to="/masters/RetailerProducts"
                    label="Retailer Products"
                  /> */}
                </ul>
              </li>
              {user &&
                (this.isAdminUserValid(user) || this.isUserCTO(user)) && (
                  <li>
                    <Nlk faIcon="fa-table" to="/" label="IT Admin" />
                    <ul className="nav-pills nav-stacked">
                      <Li
                        faIcon="fa-dashboard"
                        to="/coreItAdmin/player-assign-unassign"
                        label="Player Assign Un-Assign"
                      />
                      {/* <Li
                        faIcon="fa-table"
                        to="/masters/teams/retailer-locations"
                        label="Retailer Locations"
                      /> */}
                      {/* <Li
                        faIcon="fa-table"
                        to="/coreItAdmin/appFeatures"
                        label="App Features"
                      /> */}
                      {/* <Li
                        faIcon="fa-table"
                        to="/coreItAdmin/appRoles"
                        label="App Roles"
                      /> */}
                      {/* <Li
                        faIcon="fa-table"
                        to="/coreItAdmin/prvMenus"
                        label="App Menus"
                      /> */}
                      <Li
                        faIcon="fa-dashboard"
                        to="/players/departments"
                        label="Designations"
                      />
                      <Li
                        faIcon="fa-dashboard"
                        to="/coreItAdmin/flows"
                        label="Flows"
                      />
                      <Li
                        faIcon="fa-dashboard"
                        to="/coreItAdmin/player-api-token"
                        label="Team Api Token"
                      />
                      <Li
                        faIcon="fa-dashboard"
                        to="/players/teamTypes"
                        label="Team Types"
                      />
                      <Li
                        faIcon="fa-dashboard"
                        to="/coreItAdmin/qaValidata"
                        label="QA-Validata"
                      />
                    </ul>
                  </li>
                )}
              <li>
                {/* <Nlk faIcon="fa-file" to="/" label="Packing" /> */}
                {/* <ul className="nav-pills nav-stacked"> */}
                  {/* <Li
                    faIcon="fa-arrow-right"
                    to="/packing/PackingMaterial"
                    label="Packing Material"
                  /> */}
                  {/* <Li
                    faIcon="fa-arrow-right"
                    to="/packing/PackingMaterialStock"
                    label="Packing Material"
                  /> */}
                {/* </ul> */}
              </li>

              {/* <Li
                faIcon="fa-dashboard"
                to="/ticketCreation"
                label="Ticket Creation"
              /> */}

              {/* <Li
                faIcon="fa-table"
                to="/masters/RetailerFlows"
                label="Retailer Flows"
              /> */}
              {/*
              <Li
                faIcon="fa-table"
                to="/coreItAdmin/roleDivs"
                label="Role Divs"
                />*/}
              {/* <li>
                <Nlk faIcon="fa-dashboard" to="/" label="Demo Components" />
                <ul className="nav-pills nav-stacked">
                  <Li
                    faIcon="far fa-comments"
                    to="/support/chats"
                    label="Support Chats"
                  />
                  <Li
                    faIcon="far fa-comments"
                    to="/support/groups"
                    label="Support Groups"
                  />

                  <Li
                    faIcon="far fa-question"
                    to="/coreItAdmin/qaValidata/Questionaries"
                    label="Questionaries"
                  />
                  <Li
                    faIcon="fa-arrow-right"
                    to="/demo/charts"
                    label="Charts"
                  />

                  <Li faIcon="fa-arrow-right" to="/demo/form" label="Form" />
                  <Li
                    faIcon="fa-arrow-right"
                    to="/demo/TasksDemo"
                    label="TasksDemo"
                  />
                  <Li faIcon="fa-table" to="/demo/list" label="List" />
                  <Li
                    faIcon="fa-table"
                    to="/demo/list/client-side-pagination"
                    label="List - Client Side Pagination"
                  />
                  <Li
                    faIcon="fa-table"
                    to="/demo/list/server-side-pagination"
                    label="List - Server Side Pagination"
                  />
                  <Li
                    faIcon="fa-table"
                    to="/demo/dynamic-elements"
                    label="DynamicElements"
                  />

                  <Li
                    faIcon="fa-table"
                    to="/demo/MasterSync"
                    label="MasterSync"
                  />

                  <Li
                    faIcon="fa-table"
                    to="/demo/FileUpload"
                    label="FileUpload"
                  />

                  <Li
                    faIcon="fa-table"
                    to="/4Team/ProjectForm"
                    label="Project Form"
                  />
                </ul>
              </li> */}

              {/* <Li faIcon="fa-dashboard" to="/bluedart" label="BlueDart" /> */}
              {/* <Li faIcon="fa-table" to="/misNewReport" label="Mis Report" /> */}

              {/* <li>
                <Nlk faIcon="fa-file" to="/" label="MIS Billing" />
                <ul className="nav-pills nav-stacked">
                  <Li
                    faIcon="fa-arrow-right"
                    to="/misBilling/UploadBill"
                    label="Upload Bill"
                  />
                  <Li
                    faIcon="fa-arrow-right"
                    to="/misBilling/BillTickets"
                    label="Bill Tickets"
                  />
                  <Li
                    faIcon="fa-arrow-right"
                    to="/misBilling/UpdateBill"
                    label="Update Bill"
                  />
                </ul>
              </li> */}

              {/* <li>
                <Nlk faIcon="fa-cogs" to="/" label=" Tx Settings" />
                <ul className="nav-pills nav-stacked">
                  <Li
                    faIcon="fa-table"
                    to="/tx/settings/costcenters"
                    label="Cost Center"
                  />
                </ul>
              </li> */}

              {/* <li>
                <Nlk faIcon="fa-inr" to="/" label="Transactions" />
                <ul className="nav-pills nav-stacked">
                  <Li
                    faIcon="fa-table"
                    to="/loc-master/country"
                    label="Countries"
                  />
                  <Li faIcon="fa-table" to="/loc-master/state" label="States" />
                  <Li faIcon="fa-table" to="/loc-master/city" label="Cities" />
                </ul>
              </li> */}
              {/* <Li faIcon="fa-dashboard" to="/user/add" label="Add User" /> */}
              <li>
                {/* <Nlk faIcon="fa-dashboard" to="/" label="Location Master" /> */}
                {/* <ul className="nav-pills nav-stacked">
                  <Li
                    faIcon="fa-table"
                    to="/loc-master/country"
                    label="Countries"
                  />
                  <Li faIcon="fa-table" to="/loc-master/state" label="States" />
                  <Li faIcon="fa-table" to="/loc-master/city" label="Cities" />
                  <Li faIcon="fa-table" to="/loc-master/pincode" label="Pincodes_PHP" />
                  <Li faIcon="fa-table" to="/loc-master/hub" label="Hubs" />
                </ul> */}
              </li>
              {/* <Li faIcon="fa-dashboard" to="/auth/angJS" label="AngJS Login" /> */}
            </>
          )}

          {/* <li>
            <a
              href="http://localhost:3000/help-desk"
              className=""
              activeClassName="active-now"
            >
              <span className="fa-stack  fa-lg pull-left">
                <i className=" fa-home  fa-stack-1x "></i>
              </span>
              Help Desk
            </a>
          </li> */}

          {user && this.isUserBiz(user) && (
            <li>
              <a href={`${config[config.build]["apiDirect"]}/help-desk`}>
                <span className="fa-stack  fa-lg pull-left">
                  <i className={"fa fa-question fa-stack-1x"}></i>
                </span>
                {"Helpdesk"}
              </a>
            </li>
          )}

          <hr />

          <Li faIcon="fa-sign-out" to="/logout" label="Logout" />

          <li>
            <span className="fa-stack fa-lg pull-left">
              <i className="fa fa-home fa-stack-1x "></i>
            </span>
            Bizlog
          </li>

          {/* 
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-dashboard fa-stack-1x "></i>
              </span>{" "}
              RoadMap
            </NavLink>
            <ul className="nav-pills nav-stacked">
              <li>
                <NavLink to="/z" className="">
                  List
                </NavLink>
              </li>
              <li>
                <NavLink to="/z/add" className="">
                  Add
                </NavLink>
              </li>
              <li>
                <NavLink to="/z/rolesCat" className="">
                  Roles Cat
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-dashboard fa-stack-1x "></i>
              </span>{" "}
              Users
            </NavLink>
            <ul className="nav-pills nav-stacked">
              <li>
                <NavLink to="/user" className="">
                  Users List
                </NavLink>
              </li>
              <li>
                <NavLink to="/user/add" className="">
                  Add Users
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-flag fa-stack-1x "></i>
              </span>
              Shortcut
            </NavLink>
            <ul className="nav-pills nav-stacked">
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link1
                </NavLink>
              </li>
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link2
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-cloud-download fa-stack-1x "></i>
              </span>
              Overview
            </NavLink>
            <ul className="nav-pills nav-stacked">
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link1
                </NavLink>
              </li>
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link2
                </NavLink>
                <ul className="nav-pills nav-stacked">
                  <li>
                    <NavLink to="/z" className="">
                      <span className="fa-stack fa-lg pull-left">
                        <i className="fa fa-flag fa-stack-1x "></i>
                      </span>
                      link1
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/z" className="">
                      <span className="fa-stack fa-lg pull-left">
                        <i className="fa fa-flag fa-stack-1x "></i>
                      </span>
                      link2
                    </NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/z" className="">
              {" "}
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-cart-plus fa-stack-1x "></i>
              </span>
              Events
            </NavLink>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-youtube-play fa-stack-1x "></i>
              </span>
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-wrench fa-stack-1x "></i>
              </span>
              Services
            </NavLink>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-server fa-stack-1x "></i>
              </span>
              Contact
            </NavLink>
          </li>

          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-dashboard fa-stack-1x "></i>
              </span>{" "}
              Dashboard
            </NavLink>
            <ul className="nav-pills nav-stacked">
              <li>
                <NavLink to="/z" className="">
                  link1
                </NavLink>
              </li>
              <li>
                <NavLink to="/z" className="">
                  link2
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-flag fa-stack-1x "></i>
              </span>
              Shortcut
            </NavLink>
            <ul className="nav-pills nav-stacked">
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link1
                </NavLink>
              </li>
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link2
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-cloud-download fa-stack-1x "></i>
              </span>
              Overview
            </NavLink>
            <ul className="nav-pills nav-stacked">
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link1
                </NavLink>
              </li>
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link2
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/z" className="">
              {" "}
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-cart-plus fa-stack-1x "></i>
              </span>
              Events
            </NavLink>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-youtube-play fa-stack-1x "></i>
              </span>
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-wrench fa-stack-1x "></i>
              </span>
              Services
            </NavLink>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-server fa-stack-1x "></i>
              </span>
              Contact
            </NavLink>
          </li>

          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-dashboard fa-stack-1x "></i>
              </span>{" "}
              Dashboard
            </NavLink>
            <ul className="nav-pills nav-stacked">
              <li>
                <NavLink to="/z" className="">
                  link1
                </NavLink>
              </li>
              <li>
                <NavLink to="/z" className="">
                  link2
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-flag fa-stack-1x "></i>
              </span>
              Shortcut
            </NavLink>
            <ul className="nav-pills nav-stacked">
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link1
                </NavLink>
              </li>
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link2
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-cloud-download fa-stack-1x "></i>
              </span>
              Overview
            </NavLink>
            <ul className="nav-pills nav-stacked">
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link1
                </NavLink>
              </li>
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link2
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/z" className="">
              {" "}
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-cart-plus fa-stack-1x "></i>
              </span>
              Events
            </NavLink>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-youtube-play fa-stack-1x "></i>
              </span>
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-wrench fa-stack-1x "></i>
              </span>
              Services
            </NavLink>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-server fa-stack-1x "></i>
              </span>
              Contact
            </NavLink>
          </li>

          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-dashboard fa-stack-1x "></i>
              </span>{" "}
              Dashboard
            </NavLink>
            <ul className="nav-pills nav-stacked">
              <li>
                <NavLink to="/z" className="">
                  link1
                </NavLink>
              </li>
              <li>
                <NavLink to="/z" className="">
                  link2
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-flag fa-stack-1x "></i>
              </span>
              Shortcut
            </NavLink>
            <ul className="nav-pills nav-stacked">
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link1
                </NavLink>
              </li>
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link2
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-cloud-download fa-stack-1x "></i>
              </span>
              Overview
            </NavLink>
            <ul className="nav-pills nav-stacked">
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link1
                </NavLink>
              </li>
              <li>
                <NavLink to="/z" className="">
                  <span className="fa-stack fa-lg pull-left">
                    <i className="fa fa-flag fa-stack-1x "></i>
                  </span>
                  link2
                </NavLink>
              </li>
            </ul>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-cart-plus fa-stack-1x "></i>
              </span>
              Events
            </NavLink>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-youtube-play fa-stack-1x "></i>
              </span>
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/z" className="">
              <span className="fa-stack fa-lg pull-left">
                <i className="fa fa-wrench fa-stack-1x "></i>
              </span>
              Services
            </NavLink>
          </li> */}
        </ul>
      </div>
    );
  }
}

export default Sidebar;