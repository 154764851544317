import React from "react";
import FormLib from "./../../_common/FormLib";
import "jquery-ui-dist/jquery-ui";
import {
  setTicketVehicleInfo,
  setTicketVehicleInfoUpload,
} from "./../../../services/Tickets/TicketProcessServices";
import { toast } from "react-toastify";
import { IstToUTC, IstToUTCDate } from "../../../utils/DateUtils";
import Joi from "joi-browser";
import { ticketListReload } from "./../../../store";
import SelectedTickets from "./Manifest/actions/SelectedTickets";

class TicketVehicleInfo extends FormLib {
  state = {
    data: {
      vehicleNo: "",
      tripStart: "",
      logisticsProvider: "",
      vehicleLoadEmpty: "",
      vehicleLoadWt: "",
      apprxVehicleLoad: "",
      logistics: "shortHaul",
      vehicleType: "twoWheeler",
      vehicleLoad: "PTL",
      servicedBy: "",
      selectedFilesList: [],
      includeFileInfo:""
    },
    users: [],
    errors: {},
    curticketDetails: {},
    curblockDetails: {},
    curAction: "",
    pageHide: "false",
    feStatus: "false",
    curStatus: "",
    cancelStatus: "false",
    curMultiFETicketDetails: [],
    curMultiblockDetails: {},
    curMultiticketDetails: {},
  };

  logistics = [
    {
      logisticsByDistance: "shortHaul",
      logisticsByLabel: "IntraCity ( Upto 150km )",
    },
    { logisticsByDistance: "longHaul", logisticsByLabel: "InterCity" },
  ];
  vehicleType = [
    { vehicleTypeByLoad: "twoWheeler", vehicleTypeByLabel: "Two Wheeler" },
    { vehicleTypeByLoad: "threeWheeler", vehicleTypeByLabel: "Three Wheeler" },
    { vehicleTypeByLoad: "fourWheeler", vehicleTypeByLabel: "Four Wheeler" },
  ];
  vehicleLoad = [
    { vehicleByLoad: "PTL", vehicleByLabel: "PTL" },
    { vehicleByLoad: "FTL", vehicleByLabel: "FTL" },
  ];
  servicedBy = [
    { servicedBy: "marketVehicle", servicedByLabel: "Market Vehicle" },
    { servicedBy: "vendorVehicle", servicedByLabel: "Vendor Vehicle" },
  ];
  stsConfig = {
    value: "sortKey",
    label: "sort",
  };
  // includeFileInfo = [{ sortKey: "yes", sort: "Needs to Capture photo??" }];

  schema = {
    vehicleNo: Joi.string()
      .regex(/^[A-Z]{2}\s?[0-9]{1,2}\s?[A-Z]{0,3}\s?[0-9]{4}$/)
      .required()
      .label("vehicleNo")
      .error(() => {
        return {
          message: "Allow only valid vehicle numbers (Eg.AB12CD3456)",
        };
      }),
    tripStart: Joi.string()
      .regex(/^[0-9]+$/)
      .required()
      .label("tripStart")
      .error(() => {
        return {
          message: "Allow only numbers",
        };
      }),
    logisticsProvider: Joi.string().required().label("logisticsProvider"),
    vehicleLoadEmpty: Joi.string()
      .required()
      .regex(/^[0-9]+$/)
      .optional()
      .label("vehicleLoadEmpty")
      .error(() => {
        return {
          message: "Allow only numbers",
        };
      }),
    vehicleLoadWt: Joi.string()
      .required()
      .regex(/^[0-9]+$/)
      .required()
      .label("vehicleLoadWt")
      .error(() => {
        return {
          message: "Allow only numbers",
        };
      }),
    apprxVehicleLoad: Joi.string()
      .required()
      .regex(/^[0-9]+$/)
      .optional()
      .label("apprxVehicleLoad")
      .error(() => {
        return {
          message: "Allow only numbers",
        };
      }),
    logistics: Joi.string().required().label("logistics"),
    vehicleType: Joi.string().required().label("vehicleType"),
    vehicleLoad: Joi.string().optional().label("vehicleLoad"),
    servicedBy: Joi.string().required().label("servicedBy"),
    includeFileInfo: Joi.optional().label("includeFileInfo"),
    selectedFilesList: Joi.optional().label("selectedFilesList"),
  };

  componentDidMount() {
    this.loadInit();
  }
  selectedFilesLists = (e) => {
    let fileObj = [];
    let fileArray = [];
    fileObj.push(e);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(fileObj[0][i]);
    }
    this.setState({ file: fileArray });

    let data = this.state.data;
    data.selectedFilesList = e;
    this.setState({ data });
  };

  deleteFile(e) {
    const s = this.state.file.filter((item, index) => index !== e);
    this.setState({ file: s });
    let data = this.state.data;
    data.selectedFilesList = s;
    this.setState({ data });
  }

  loadInit() {
    const curticketDetails = this.props.ticketDetails;
    const curblockDetails = this.props.blockDetails;
    const curAction = this.props.curAction;
    const curMultiticketDetails = this.props.ticketMultiDetails;
    const curMultiblockDetails = this.props.blockMultiDetails;
    const bulkAssign = this.props.bulkAssign;
    this.setState({ curticketDetails });
    this.setState({ curblockDetails });
    this.setState({ curAction });
    this.setState({ bulkAssign });
    this.setState({ curMultiticketDetails });
    this.setState({ curMultiblockDetails });
    let hoForm = localStorage.getItem("curHub");
    if (hoForm === "1lUeT6ivx3d1DXPKW74SdKyLQrM=") {
      this.setState({ hideForm: "true" });
    } else {
      this.setState({ hideForm: "false" });
    }
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({ designation: userInfo.designation });
  }

  async onVehicleInfo() {
    let curHub = localStorage.getItem("curHub");
    let date = IstToUTCDate(new Date());
    let now = new Date().valueOf(); // Unix timestamp in milliseconds
    let tripId = curHub + "-" + date + "-" + now;
    let override = { ...this.state.data };
    override["actionCode"] = "wUpdateVehicle";
    override["action"] = "Vehicle Details";
    this.setState({ override });
    let newTicketPriority = "";
    let blocks = [];
    // console.log("bulkkk",this.state.bulkAssign)

    if (this.state.bulkAssign === "bulk") {
      this.state.curMultiticketDetails.map((r,i) => {
        {r.blocks.map((m) => {

       
        // for(let i = 0; i < this.state.curMultiblockDetails.length ; i++){
          // console.log("hhhhhhhhhhh",i)
        let blockData = {
          processId: r.processId,
          ticketBlockId: m.ticketBlockId,
          flowStatus: m.flowStatus,
          blockStatus: m.blockStatus,
          flowId: r.flowId,
          direction: m.direction,
          tripSeq:(i+1).toString()
        };
        blocks.push(blockData);
      
      // }
    })}
      });
    
    } else {
      let blockData = {
        processId: this.state.curblockDetails.processId,
        ticketBlockId: this.state.curblockDetails.ticketBlockId,
        flowStatus: this.state.curblockDetails.flowStatus,
        blockStatus: this.state.curblockDetails.blockStatus,
        flowId: this.state.curblockDetails.flowId,
        direction: this.state.curblockDetails.direction,
        tripSeq:"1"
      };
      blocks.push(blockData);
    }

    let resultSet = {
      blocks: blocks,
      vehicleNo: this.state.data.vehicleNo,
      tripStart: this.state.data.tripStart,
      logisticsProvider: this.state.data.logisticsProvider,
      apprxVehicleLoad: this.state.data.apprxVehicleLoad,
      actionStatus: "VEHICLE_DETAILS",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
      tripId: tripId,
      travel: this.state.data.logistics,
      vehicleType: this.state.data.vehicleType,
      vehicleLoadType: this.state.data.vehicleLoad,
      vehicleLoadEmpty: this.state.data.vehicleLoadEmpty,
      vehicleLoadWt: this.state.data.vehicleLoadWt,
      servicedBy: this.state.data.servicedBy,
    };
    const data = {
      actionCode: override.actionCode,
      action: override.action,
      selectedFilesList: this.state.data.selectedFilesList,
      info: this.state.data.fileInfo,
      resultSet: resultSet,
    };
// console.log("dataaaaaaaaaaa",data)
    let resp = await setTicketVehicleInfoUpload(data);
    if (resp.data.success === true) {
      if (resp.data.data) {
        toast.success("Vehicle Details Fetched Successfully");
        let data = { ...this.state.data };
        data["vehicleNo"] = "";
        data["tripStart"] = "";
        data["logisticsProvider"] = "";
        data["apprxVehicleLoad"] = "";
        data["player"] = this.state.data.player;
        data["tkPriorityChange"] = this.state.data.tkPriorityChange;
        data["vehicleLoadEmpty"] = "";
        data["vehicleLoadWt"] = "";
        this.setState({ data });
      } else {
        !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
      }
    } else {
      toast.error(() => <div>{resp.data.msg}</div>);
    }

    //     if (resp.data.success === true) {
    //   if (resp.data.data) {
    //     toast.success("Vehicle Details Fetched Successfully");
    //     let data = { ...this.state.data };
    //     data["vehicleNo"] = "";
    //     data["tripStart"] = "";
    //     data["logisticsProvider"] = "";
    //     data["apprxVehicleLoad"] = "";
    //     data["player"] = this.state.data.player;
    //     data["tkPriorityChange"] = this.state.data.tkPriorityChange;
    //     data["vehicleLoadEmpty"] = "";
    //     data["vehicleLoadWt"] = "";
    //     this.setState({ data });
    //   } else {
    //     !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
    //   }
    // } else {
    //   toast.error(() => <div>{resp.data.msg}</div>);
    // }
    // for (var i = 0; i <= this.state.curMultiblockDetails.length; i++) {
    //   let resultSet = {
    //     vehicleNo: this.state.data.vehicleNo,
    //     tripStart: this.state.data.tripStart,
    //     logisticsProvider: this.state.data.logisticsProvider,
    //     apprxVehicleLoad: this.state.data.apprxVehicleLoad,
    //     flowId: this.state.curMultiticketDetails[i].flowId,
    //     newTicketPriority: newTicketPriority,
    //     actionStatus: "VEHICLE_DETAILS",
    //     processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    //     tripId: tripId,
    //     travel: this.state.data.logistics,
    //     vehicleType: this.state.data.vehicleType,
    //     vehicleLoadType: this.state.data.vehicleLoad,
    //     vehicleLoadEmpty: this.state.data.vehicleLoadEmpty,
    //     vehicleLoadWt: this.state.data.vehicleLoadWt,
    //   };

    //   let resp = await setTicketVehicleInfo(
    //     override,
    //     this.state.curMultiticketDetails[i],
    //     this.state.curMultiblockDetails[i],
    //     resultSet
    //   );
    //   if (resp.data.success === true) {
    //     if (resp.data.data) {
    //       // this.handleTicketListReload();
    //       toast.success("Vehicle Details Fetched Successfully");
    //       let data = { ...this.state.data };
    //       // data["vehicleNo"] = "";
    //       // data["logisticsProvider"] = "";
    //       this.setState({ data });
    //     } else {
    //       !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
    //     }
    //   } else {
    //     toast.error(() => <div>{resp.data.msg}</div>);
    //   }
    // }
    // } else {
    //   let resultSet = {
    //     vehicleNo: this.state.data.vehicleNo,
    //     tripStart: this.state.data.tripStart,
    //     logisticsProvider: this.state.data.logisticsProvider,
    //     apprxVehicleLoad: this.state.data.apprxVehicleLoad,
    //     flowId: this.state.curticketDetails.flowId,
    //     newTicketPriority: newTicketPriority,
    //     actionStatus: "VEHICLE_DETAILS",
    //     processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    //     tripId: tripId,
    //     travel: this.state.data.logistics,
    //     vehicleType: this.state.data.vehicleType,
    //     vehicleLoadType: this.state.data.vehicleLoad,
    //     vehicleLoadEmpty: this.state.data.vehicleLoadEmpty,
    //     vehicleLoadWt: this.state.data.vehicleLoadWt,
    //   };
    //   let resp = await setTicketVehicleInfo(
    //     override,
    //     this.state.curticketDetails,
    //     this.state.curblockDetails,
    //     resultSet
    //   );
    //   if (resp.data.success === true) {
    //     if (resp.data.data) {
    //       // this.handleTicketListReload();
    //       toast.success("Vehicle Details Fetched Successfully");
    //       let data = { ...this.state.data };
    //       data["vehicleNo"] = "";
    //       data["tripStart"] = "";
    //       data["logisticsProvider"] = "";
    //       data["apprxVehicleLoad"] = "";
    //       data["player"] = this.state.data.player;
    //       data["tkPriorityChange"] = this.state.data.tkPriorityChange;
    //       data["vehicleLoadEmpty"] = "";
    //       data["vehicleLoadWt"] = "";
    //       this.setState({ data });
    //     } else {
    //       !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
    //     }
    //   } else {
    //     toast.error(() => <div>{resp.data.msg}</div>);
    //   }
    // }
  }

  handleModalData = (data) => {
    // Do something with the data received from the modal
    // console.log('Data received from modal:', data);
    this.setState({curMultiblockDetails:data})
    // You can perform further operations with the data here
  };
  render() {
    // console.log("multiTicketDetailssssss",this.state.curMultiticketDetails)
    const { venText, venName } = this.state;
    const file = this.state.data.selectedFilesList;
    const currentRequest = this.state.ticketActions;
    const ticketRequest = this.props.ticketDetails;

    return (
      <>
        <b className="mt-5">&nbsp;&nbsp;Capture Vehicle Info :</b>
        <form onSubmit={this.handleSubmit}>
          <div className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3">
            {this.rRBL(
              "Travel :",
              "logistics",
              this.logistics,
              {
                value: "logisticsByDistance",
                label: "logisticsByLabel",
              },
              "col-12 mt-2 mb-1"
            )}
            {this.rRBL(
              "Vehicle Type :",
              "vehicleType",
              this.vehicleType,
              {
                value: "vehicleTypeByLoad",
                label: "vehicleTypeByLabel",
              },
              "col-12 mt-2 mb-1"
            )}
            {this.rRBL(
              "Vehicle Load :",
              "vehicleLoad",
              this.vehicleLoad,
              {
                value: "vehicleByLoad",
                label: "vehicleByLabel",
              },
              "col-4 mt-1 mb-1"
            )}
            {this.rRBL(
              "Serviced By :",
              "servicedBy",
              this.servicedBy,
              {
                value: "servicedBy",
                label: "servicedByLabel",
              },
              "col-8 mt-1 mb-1"
            )}
            {this.rTB("Vehicle No ", "vehicleNo", " col-6 mt-1 mb-3")}
            {/* {this.rNB("Trip Start (in Kms) ", "tripStart", " col-6 mt-1 mb-3")} */}

            {this.rTB(
              "Logistics Provider ",
              "logisticsProvider",
              " col-12 mt-1 mb-3"
            )}
            {this.rNB(
              "Vehicle Empty Load (in Kgs) ",
              "vehicleLoadEmpty",
              " col-6 mt-1 mb-4"
            )}
            {this.rNB(
              "Vehicle Loaded (in Kgs) ",
              "vehicleLoadWt",
              " col-6 mt-1 mb-4"
            )}

            {this.rNB(
              "Approximate Vehicle Load (in Kgs) ",
              "apprxVehicleLoad",
              " col-12 mt-1 mb-5"
            )}
          </div>
          {/* {this.rCBHL("includeFileInfo", this.includeFileInfo, {
            value: "sortKey",
            label: "sort",
          })} */}
          {this.state.data.includeFileInfo === "yes" && (
            <>
              <b className="mt-5">&nbsp;&nbsp;File Upload :</b>

              <div className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3">
                <div className="m-0 p-0 col-9 mt-2">
                  <label
                    htmlFor="refDocFiles"
                    className="form-label font-monospace"
                  >
                    Upload Files
                  </label>
                  <input
                    className="form-control form-control-sm "
                    multiple
                    id="refDocFiles"
                    type="file"
                    style={{ cursor: "pointer" }}
                    onChange={(e) => {
                      this.selectedFilesLists(e.target.files);
                    }}
                  />
                </div>
                {this.rTB("File Info ", "fileInfo", " col-12 mt-3 mb-3")}

                <div
                  className="col-12 page-todo overflow-auto"
                  style={{ height: "20rem" }}
                >
                  <div className="tasks">
                    <div className="form-group multi-preview">
                      {(this.state.file || []).map((url, index) => (
                        <div key={url}>
                          <li className="list-group-item" key={index}>
                            <p>
                              <a>{url.name}</a>
                            </p>{" "}
                            <img
                              className="mt-5"
                              src={URL.createObjectURL(url)}
                              alt={url.name}
                              key={index}
                              style={{ height: "50px", width: "100px" }}
                            />
                            <button
                              type="button"
                              style={{
                                position: "absolute",
                                marginLeft: "30px",
                              }}
                              onClick={() => this.deleteFile(index)}
                            >
                              delete
                            </button>
                          </li>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <SelectedTickets sendDataToParent={this.handleModalData}/>
                </div>
                {/* <div className="form-group preview">
    {file.length > 0 &&
      file.map((item, index) => {
        return (
          <div key={item}>
            <img src={item} alt="" />
            <button
              type="button"
              onClick={() => this.deleteFile(index)}
            >
              delete
            </button>
          </div>
        );
      })}
  </div> */}
              </div>
            </>
          )}
          <div>
            <button
              className="btn btn-primary btn-sm pull-right"
              onClick={() => this.onVehicleInfo()}
            >
              Submit
            </button>
            <a className="ml-3 mt-5">
              {"Note: Capture vehicle details before FE assignment."}
            </a>
          </div>
        </form>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(TicketVehicleInfo);

export default TicketVehicleInfo;
