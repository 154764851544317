import React from "react";

function CheckboxStatus({
  header,
  name,
  options = [],
  ctrls,
  inline = "",
  value,
  error,
  chkStatus,
  classAppend = "",
  isHeader = "",
  ...rest
}) {
  return (
    <div className={"form-group " + classAppend}>
      <div className={" form-checkbox " + inline}>
        {header !== "" && <div className="form-help"> {isHeader} </div>}
        {/* {JSON.stringify(options)} */}
        {options.map((op) => (
          <div className="checkbox" key={op[ctrls.value]}>
            <label>
              <input
                type="checkbox"
                name={name}
                value={op[ctrls.value]}
                {...rest}
                checked={chkStatus === true ? true : false}
              />
              <i className="helper"></i>
              {op[ctrls.label]}
            </label>
            {error && <span className="alert alert-danger">{error}</span>}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CheckboxStatus;
