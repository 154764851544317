import React, { Component } from "react";
import Joi from "joi-browser";

import Textbox from "./formComps/Textbox";
import Textarea from "./formComps/Textarea";
import Selectbox from "./formComps/Selectbox";
import Checkbox from "./formComps/Checkbox";
import CheckboxStatus from "./formComps/CheckboxStatus";
import RadioBtn from "./formComps/RadioBtn";
import Button from "./formComps/Button";
import SelectboxFilter from "./formComps/SelectboxFilter";
import CustomToolbar from "./formComps/CustomToolbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SelectboxAll from "./formComps/SelectboxAll";
import MultiSelectAllHubs from "./formComps/MultiSelectAllHubs";
import MultiSelectAll from "./formComps/MultiSelectAll";

import {
  currentDate,
  dateByDays,
  dateByWeeks,
  dateByMonths,
  dateByDaysIndia,
  IstToUTC,
} from "../../utils/DateUtils";

class FormLib extends Component {
  state = {
    data: {},
    errors: {},
    btnValue: "",
  };
  schema = {};

  reactQuillFormats = [
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "color",
    "background",
    "script",
    "header",
    "blockquote",
    "code-block",
    "indent",
    "list",
    "direction",
    "align",
    "link",
    "image",
    "video",
    "formula",
  ];

  reactQuillModules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };
  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    // console.log("schema value" + this.schema[name]);
    if (!this.schema[name]) return null;
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };
  submitAction = async (e) => {
    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors !== null) console.log(errors);
    if (errors) return;
    await this.doSubmit();
    // console.log("form submit end" + e);
    // e.target.disabled = false;
  };

  handleButtonClicked = (e) => {
    e.persist();

    this.setState({ btnValue: e.target.value }, async () => {
      const errors = this.validate();
      this.setState({ errors: errors || {} });
      if (errors !== null) console.log(errors);
      if (errors) return;
      e.target.disabled = true;
      try {
        await this.doSubmit();
        e.target.disabled = false;
      } catch (error) {
        e.target.disabled = false;
      }
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
  };
  localHandleChange = (e) => {};
  handleFileChange = (e) => {
    const { currentTarget: input } = e;
    const data = { ...this.state.data };
    data[input.name] = "";
    this.setState({ data });
  };
  handleEditorChange = (html) => {
    const data = { ...this.state.data };
    data["docs"] = html;

    this.setState({ data });
  };
  handleChange = (e) => {
    // const errors = { ...this.state.errors };
    // const errorMessage = this.validateProperty(input);
    // if (errorMessage) errors[input.name] = errorMessage;

    // else delete errors[input.name];
    const { currentTarget: input } = e;
    const data = { ...this.state.data };
    // data[input.name] = input.value;
    // console.log(e);

    if (e.target.multiple) {
      if (data[input.name].indexOf(input.value) > -1) {
        data[input.name].splice(data[input.name].indexOf(input.value), 1);
      } else {
        if (input.value !== "") {
          data[input.name].push(input.value);
        } else {
          data[input.name] = [];
        }
      }
    } else {
      data[input.name] = input.value;
    }

    if (e.target.type === "checkbox" && !e.target.checked) {
      data[input.name] = "";
    }

    this.setState({ data });
    this.localHandleChange(e);
  };
  removeNull = (array) => {
    return array.filter((x) => x !== "*");
  };
  localHandleChangeMulti = (value, name) => {};

  handleChangeReactMultiSelect = (value, name) => {
    let arrayValue = [];
    const data = { ...this.state.data };
    value.map((r) => {
      arrayValue.push(r.value);
    });

    data[name] = this.removeNull(arrayValue);
    this.setState({ data });
    this.localHandleChangeMulti(value, name);
  };
  handleValidation = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    this.setState({ errors });
    // console.log(this.state.errors);
  };
  localHandleOnBlur = (e) => {};

  rTB(label, name, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textbox
        label={label}
        name={name}
        classAppend={classAppend}
        type="text"
        value={data[name] || ""}
        error={errors[name]}
        autoComplete="off"
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }
  rTBBlur(label, name, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textbox
        label={label}
        name={name}
        classAppend={classAppend}
        type="text"
        value={data[name] || ""}
        error={errors[name]}
        autoComplete="off"
        onChange={this.handleChange}
        onBlur={(this.handleValidation, this.localHandleOnBlur)}
      />
    );
  }
  rTBD(label, name, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textbox
        label={label}
        name={name}
        classAppend={classAppend}
        type="text"
        value={data[name] || ""}
        error={errors[name]}
        autoComplete="off"
        onChange={this.handleChange}
        onBlur={this.handleValidation}
        disabled="false"
      />
    );
  }

  rTBH(label, name, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <input
        label={label}
        name={name}
        type="hidden"
        value={data[name] || ""}
        error={errors[name]}
        autoComplete="off"
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }

  rNB(label, name, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textbox
        label={label}
        name={name}
        classAppend={classAppend}
        type="number"
        min={0}
        value={data[name] || ""}
        error={errors[name]}
        autoComplete="off"
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }
  rNBm(label, name, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textbox
        label={label}
        name={name}
        classAppend={classAppend}
        type="number"
        min={1}
        value={data[name] || ""}
        error={errors[name]}
        autoComplete="off"
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }

  rPB(label, name, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textbox
        name={name}
        label={label}
        classAppend={classAppend}
        type="password"
        value={data[name] || ""}
        error={errors[name]}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }

  rDP(label, name, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textbox
        name={name}
        label={label}
        classAppend={classAppend + " raise-label "}
        type="date"
        value={data[name] || ""}
        error={errors[name]}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }

  rDPD(label, name, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textbox
        name={name}
        label={label}
        classAppend={classAppend + " raise-label "}
        type="date"
        value={data[name] || ""}
        error={errors[name]}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
        disabled="false"
      />
    );
  }
  addDays(myDate, days) {
    return new Date(myDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  rDPFMIS(label, name, classAppend = "") {
    const { data, errors } = this.state;
    let minDate = new Date();
    let maxDate = new Date();
    minDate.setDate(maxDate.getDate() - 365);
    let min = this.formatDate(minDate);
    let max = this.formatDate(maxDate);

    if (this.state.data.status === "custom") {
      return (
        <Textbox
          name={name}
          label={label}
          classAppend={classAppend + " raise-label "}
          type="date"
          min={min}
          max={max}
          value={data[name] || ""}
          error={errors[name]}
          onChange={this.handleChange}
          onBlur={this.handleValidation}
        />
      );
    } else {
      return (
        <Textbox
          name={name}
          label={label}
          classAppend={classAppend + " raise-label "}
          type="date"
          min={min}
          max={max}
          value={data[name] || ""}
          error={errors[name]}
          onChange={this.handleChange}
          onBlur={this.handleValidation}
          readOnly={true}
        />
      );
    }
  }

  rDPF(label, name, classAppend = "") {
    const { data, errors } = this.state;
    let minDate = new Date();
    let maxDate = new Date();
    minDate.setDate(maxDate.getDate() - 60);

    let min = this.formatDate(minDate);
    let max = this.formatDate(maxDate);
    return (
      <Textbox
        name={name}
        label={label}
        classAppend={classAppend + " raise-label "}
        type="date"
        min={min}
        max={max}
        value={data[name] || ""}
        error={errors[name]}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }

  formatDate(date) {
    let dd = String(date.getDate()).padStart(2, "0");
    let mm = String(date.getMonth() + 1).padStart(2, "0");
    let yyyy = date.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  rTP(label, name, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textbox
        name={name}
        label={label}
        classAppend={classAppend + " raise-label "}
        type="time"
        value={data[name] || ""}
        error={errors[name]}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }

  rDT(label, name, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textbox
        name={name}
        label={label}
        classAppend={classAppend + " raise-label "}
        type="datetime-local"
        value={data[name] || ""}
        error={errors[name]}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }

  rDTF(label, name, classAppend = "") {
    const { data, errors } = this.state;
    let UTCtime = IstToUTC(new Date()).slice(0, 16).replace(" ", "T");

    return (
      <Textbox
        name={name}
        label={label}
        classAppend={classAppend + " raise-label "}
        type="datetime-local"
        min={UTCtime}
        value={data[name] || ""}
        error={errors[name]}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }

  rFU(label, name, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textbox
        label={""}
        name={name}
        classAppend={classAppend}
        type="file"
        value={data[name] || ""}
        error={errors[name]}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
        onClick={this.handleFileChange}
      />
    );
  }

  sConfs = { value: "value", label: "label" };
  rSB(label, name, options = [], ctrls = this.sConfs, classAppend = "") {
    const { data, errors } = this.state;

    return (
      <Selectbox
        name={name}
        label={label}
        options={options}
        classAppend={classAppend}
        error={errors[name]}
        value={data[name]}
        ctrls={ctrls}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }
  sConfs = { value: "value", label: "label" };
  rSBD(label, name, options = [], ctrls = this.sConfs, classAppend = "") {
    const { data, errors } = this.state;

    return (
      <Selectbox
        name={name}
        label={label}
        options={options}
        classAppend={classAppend}
        error={errors[name]}
        value={data[name]}
        ctrls={ctrls}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
        disabled="false"
      />
    );
  }
  sConfs = { value: "value", label: "label" };
  rSBMulti(label, name, options = [], ctrls = this.sConfs, classAppend = "") {
    let transformed = [];
    if (label === "Hub") {
      transformed = options.map(({ hub, hubId }) => ({
        label: hub,
        value: hubId,
      }));
    } else if (label === "Client") {
      transformed = options.map(({ name, teamId }) => ({
        label: name,
        value: teamId,
      }));
    } else if (label === "ClientMis") {
      transformed = options.map(({ clients, teamId }) => ({
        label: clients,
        value: teamId,
      }));
    } else if (label === "ExpenseHead") {
      transformed = options.map(({ actionCat, actionCatId }) => ({
        label: actionCat,
        value: actionCatId,
      }));
    } else if (label === "Hubs") {
      transformed = options.map(({ HUB, HUB_ID }) => ({
        label: HUB,
        value: HUB_ID,
      }));
    }
    const { data, errors } = this.state;

    if (transformed.length) {
      return (
        <MultiSelectAll
          name={name}
          label={label}
          options={transformed}
          classAppend={classAppend}
          error={errors[name]}
          value={[data[name]]}
          ctrls={ctrls}
          handleChange={this.handleChangeReactMultiSelect}
          onBlur={this.handleValidation}
        />
      );
    }
  }

  sConfs = { value: "value", label: "label" };
  rSBMultiAE(label, name, options = [], ctrls = this.sConfs, classAppend = "") {
    let transformed = [];
    if (label === "Activities") {
      transformed = options.map(({ EXPENSE_HEAD, EXPENSE_HEAD_ID }) => ({
        label: EXPENSE_HEAD,
        value: EXPENSE_HEAD_ID,
      }));
    } else if (label === "Approver") {
      transformed = options.map(({ APPROVER_NAME, APPROVER_ID }) => ({
        label: APPROVER_NAME,
        value: APPROVER_ID,
      }));
    }
    const { data, errors } = this.state;

    if (transformed.length) {
      return (
        <MultiSelectAll
          name={name}
          label={label}
          options={transformed}
          classAppend={classAppend}
          error={errors[name]}
          value={[data[name]]}
          ctrls={ctrls}
          handleChange={this.handleChangeReactMultiSelect}
          onBlur={this.handleValidation}
        />
      );
    }
  }

  sConfs = { value: "value", label: "label" };
  rSBM(label, name, options = [], ctrls = this.sConfs, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Selectbox
        name={name}
        label={label}
        options={options}
        classAppend={classAppend}
        error={errors[name]}
        value={[data[name]]}
        ctrls={ctrls}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
        multiple="multiple"
      />
    );
  }

  // ============================================      Start SelectBoxFilter
  sBFConfs = { value: "value", label: "label" };
  rSBF(label, name, options = [], ctrls = this.sBFConfs, classAppend = "") {
    const { data, errors } = this.state;

    return (
      <SelectboxFilter
        name={name}
        label={label}
        options={options}
        classAppend={classAppend}
        error={errors[name]}
        value={data[name]}
        ctrls={ctrls}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }

  // ============================================      Start SelectBoxwithAllSelection
  sBAConfs = { value: "value", label: "label" };
  rSBA(label, name, options = [], ctrls = this.sBAConfs, classAppend = "") {
    const { data, errors } = this.state;

    return (
      <SelectboxAll
        name={name}
        label={label}
        options={options}
        classAppend={classAppend}
        error={errors[name]}
        value={data[name]}
        ctrls={ctrls}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }

  // ============================================      Start textArea
  rTA(label, name, classAppend = "") {
    return this.mTA(label, name, 2, classAppend);
  }
  rTAR(label, name, classAppend = "") {
    return this.mTAR(label, name, 1, classAppend);
  }
  rTA1(label, name, classAppend = "") {
    return this.mTA1(label, name, 15, classAppend);
  }
  rTA3(label, name, classAppend = "") {
    return this.mTA(label, name, 3, classAppend);
  }
  rTA4(label, name, classAppend = "") {
    return this.mTA(label, name, 4, classAppend);
  }
  rTA5(label, name, classAppend = "") {
    return this.mTA(label, name, 5, classAppend);
  }
  rTA6(label, name, classAppend = "") {
    return this.mTA(label, name, 6, classAppend);
  }
  rTA7(label, name, classAppend = "") {
    return this.mTA(label, name, 7, classAppend);
  }
  rTA8(label, name, classAppend = "") {
    return this.mTA(label, name, 8, classAppend);
  }
  rTA9(label, name, classAppend = "") {
    return this.mTA(label, name, 9, classAppend);
  }
  rTA10(label, name, classAppend = "") {
    return this.mTA(label, name, 10, classAppend);
  }

  mTA(label, name, rows = 2, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textarea
        label={label}
        name={name}
        rows={rows}
        value={data[name]}
        classAppend={classAppend}
        error={errors[name]}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }
  mTA1(label, name, rows = 15, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textarea
        label={label}
        name={name}
        rows={rows}
        value={data[name]}
        classAppend={classAppend}
        error={errors[name]}
        onChange={this.handleChange}
        onBlur={(this.handleValidation, this.localHandleOnBlur)}
      />
    );
  }
  mTAR(label, name, rows = 2, classAppend = "") {
    const { data, errors } = this.state;
    return (
      <Textarea
        label={label}
        name={name}
        rows={rows}
        value={data[name]}
        classAppend={classAppend}
        error={errors[name]}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }
  // ============================================      End textArea

  // ============================================      Start Checkbox
  cConfs = { value: "value", label: "label" };
  //ComboBox
  rCB(header, name, options = [], ctrls = this.cConfs, classAppend = "") {
    return this.mCB(header, name, options, ctrls, "", classAppend);
  }
  //ComboBox inLine
  rCBL(header, name, options = [], ctrls = this.cConfs, classAppend = "") {
    return this.mCB(header, name, options, ctrls, "form-inline", classAppend);
  }
  //ComboBox HeadLess
  rCBHL(name, options = [], ctrls = this.cConfs, classAppend = "") {
    return this.mCB("", name, options, ctrls, "", classAppend);
  }
  //ComboBox HeadLess Inline
  rCBHLL(name, options = [], ctrls = this.cConfs, classAppend = "") {
    return this.mCB("", name, options, ctrls, "form-inline", classAppend);
  }

  rCBHLLS(
    name,
    options = [],
    ctrls = this.cConfs,
    chkStatus = false,
    classAppend = ""
  ) {
    return this.mCBS(
      "",
      name,
      options,
      ctrls,
      chkStatus,
      "form-inline",
      classAppend
    );
  }

  mCB(head, name, ops = [], ctrls = this.cConfs, inline = "", clsAppend = "") {
    const { data, errors } = this.state;
    return (
      <Checkbox
        header={head}
        name={name}
        options={ops}
        ctrls={ctrls}
        inline={inline}
        value={data["name"]}
        error={errors["name"]}
        classAppend={clsAppend}
        onChange={this.handleChange}
      />
    );
  }

  mCBS(
    header,
    name,
    options = [],
    ctrls = this.cConfs,
    chkStatus = false,
    inline = "",
    classAppend = ""
  ) {
    const { data, errors } = this.state;
    return (
      <CheckboxStatus
        header={header}
        name={name}
        options={options}
        ctrls={ctrls}
        inline={inline}
        value={data["name"]}
        error={errors["name"]}
        classAppend={classAppend}
        onChange={this.handleChange}
        chkStatus={chkStatus}
      />
    );
  }

  // ============================================      End Checkbox

  // ============================================      Start Radio

  rConfs = { value: "value", label: "label" };
  //Radio Buttons
  rRB(header, name, options = [], ctrls = this.rConfs, classAppend = "") {
    return this.mRB(header, name, options, ctrls, "", classAppend);
  }
  //Radio Buttons Inline
  rRBL(header, name, options = [], ctrls = this.rConfs, classAppend = "") {
    return this.mRB(header, name, options, ctrls, " form-inline ", classAppend);
  }

  rRBLD(header, name, options = [], ctrls = this.rConfs, classAppend = "") {
    return this.mRBD(
      header,
      name,
      options,
      ctrls,
      " form-inline ",
      classAppend
    );
  }

  mRB(header, name, options = [], ctrls, inline = "", classAppend = "") {
    const { data, errors = {} } = this.state; //fixed errors is undefined issue
    return (
      <RadioBtn
        header={header}
        name={name}
        options={options}
        ctrls={ctrls}
        inline={inline}
        value={data[name]}
        error={errors[name]}
        classAppend={classAppend}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
      />
    );
  }

  mRBD(header, name, options = [], ctrls, inline = "", classAppend = "") {
    const { data, errors } = this.state;
    return (
      <RadioBtn
        header={header}
        name={name}
        options={options}
        ctrls={ctrls}
        inline={inline}
        value={data[name]}
        error={errors[name]}
        classAppend={classAppend}
        onChange={this.handleChange}
        onBlur={this.handleValidation}
        disabled="false"
      />
    );
  }
  // ============================================      End Radio

  rQuill(name) {
    // clsName = " btn-sm  btn-primary " + clsName;
    const { data, errors } = this.state;
    // console.log(data[name]);
    return (
      <>
        <CustomToolbar />
        <ReactQuill
          name={name}
          value={data[name] || ""}
          onChange={this.handleEditorChange}
          modules={this.reactQuillModules}
          formats={this.reactQuillFormats}
        />
      </>
    );
  }

  rBtnS(label, value, clsName = "") {
    clsName = " btn-sm  btn-primary " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnSP(label, value, clsName = "") {
    clsName = " btn-sm  btn-primary " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnSPD(label, value, clsName = "") {
    clsName = " btn-sm  btn-primary " + clsName;
    return this.mBtnD(label, value, clsName);
  }
  rBtnSPC(label, value, clsName = "") {
    clsName = " btn-sm  btn-primary " + clsName;
    return this.mBtnC(label, value, clsName);
  }
  rBtnSSc(label, value, clsName = "") {
    clsName = " btn-sm  btn-secondary " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnSS(label, value, clsName = "") {
    clsName = " btn-sm  btn-success " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnSD(label, value, clsName = "") {
    clsName = " btn-sm  btn-danger " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnSW(label, value, clsName = "") {
    clsName = " btn-sm  btn-warning " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnSI(label, value, clsName = "") {
    clsName = " btn-sm  btn-info " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnSL(label, value, clsName = "") {
    clsName = " btn-sm  btn-light " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnSDr(label, value, clsName = "") {
    clsName = " btn-sm  btn-dark " + clsName;
    return this.mBtn(label, value, clsName);
  }

  rBtnL(label, value, clsName = "") {
    clsName = " btn-primary " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnLP(label, value, clsName = "") {
    clsName = " btn-primary " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnLSc(label, value, clsName = "") {
    clsName = " btn-secondary " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnLS(label, value, clsName = "") {
    clsName = " btn-success " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnLD(label, value, clsName = "") {
    clsName = " btn-danger " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnLW(label, value, clsName = "") {
    clsName = " btn-warning " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnLI(label, value, clsName = "") {
    clsName = " btn-info " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnLL(label, value, clsName = "") {
    clsName = " btn-light " + clsName;
    return this.mBtn(label, value, clsName);
  }
  rBtnLDr(label, value, clsName = "") {
    clsName = " btn-dark " + clsName;
    return this.mBtn(label, value, clsName);
  }

  mBtn(label, value, clsName) {
    return (
      // <Button  label={label} clsName={clsName} />
      <Button
        label={label}
        value={value}
        // disabled={this.validate()}
        clsName={" btn " + clsName}
        onClick={this.handleButtonClicked}
      />
    );
  }
  mBtnD(label, value, clsName) {
    return (
      // <Button  label={label} clsName={clsName} />
      <Button
        label={label}
        value={value}
        disabled={true}
        clsName={" btn " + clsName}
        onClick={this.handleButtonClicked}
      />
    );
  }
  mBtnC(label, value, clsName) {
    return (
      // <Button  label={label} clsName={clsName} />
      <Button
        label={label}
        value={value}
        // disabled={this.validate()}
        clsName={" btn " + clsName}
        onClick={this.handleButtonClicked}
        data-dismiss="modal"
      />
    );
  }

  mResetBtn(method, clsName, title) {
    return (
      // <Button  label={label} clsName={clsName} />
      // <Button
      //   type="button"
      //   className={" btn btn-sm btn-warning  " + clsName}
      //   onClick={() => method()}
      // >
      //   <i className={"fa fa-unlink"} />f
      // </Button>

      <button
        type="button"
        title={title}
        className={" btn btn-sm btn-warning  " + clsName}
        onClick={() => method()}
      >
        <i className={"fa fa-refresh"} />
      </button>
    );
  }

  getCurrentDate() {
    return currentDate();
  }

  getDatebyDays(days = 0) {
    return dateByDays(days);
  }
  getDatebyDaysIndia(days = 0) {
    return dateByDaysIndia(days);
  }
  getDatebyWeeks(Weeks = 0) {
    return dateByWeeks(Weeks);
  }

  getDatebyMonths(Weeks = 0) {
    return dateByMonths(Weeks);
  }
}
export default FormLib;
