import React from "react";
import FormLib from "./../../_common/FormLib";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { ticketListReload } from "../../../store";
import { connect } from "react-redux";
import Table from "./../../_common/Table";
import Pagination from "../../_common/Pagination";
import { setTicketActionsProdStatus } from "./../../../services/Tickets/TicketProcessServices";
import { getProductImages } from "./../../../services/TicketHistory/TicketHistory";

class FurnitureFlowProcess extends FormLib {
  state = {
    errors: {},
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    pageHide: "false",
    pageCurrent: 1,
    pageSize: 1,
    setImages: [],
    wentWrong: "",
  };
  tableMeta = {
    key: "ticketProductId",
    columns: [
      { path: "productName", label: "Product Name" },
      { path: "identificationNo", label: "Identification Number" },
      {
        key: "playerFeId",
        action: (r) => (
          <>
            {r.firstName && (
              <>{r.firstName + " " + r.lastName + " | " + r.userName}</>
            )}
          </>
        ),
        icon: () => <>FE Info</>,
      },
      {
        key: "prodQcStatus",
        action: (r) => (
          <>
            {r.prodQcStatus && (
              <a target="_blank" rel="no referrer">
                {r.prodQcStatus === "assign" ? (
                  <>
                    <i class="fa fa-spinner fa-lg text-info ml-2">
                      {"   " + "InProcess"}
                    </i>
                  </>
                ) : r.prodQcStatus === "Yes" ? (
                  <i class="fa fa-check fa-lg text-success ml-2">
                    {" "}
                    {"   " + "YES"}
                  </i>
                ) : r.prodQcStatus === "No" ? (
                  <i class="fa fa-times-circle-o fa-lg text-danger ml-2">
                    {" "}
                    {"   " + "NO"}
                  </i>
                ) : r.prodQcStatus === "DeviceNotAvailable" ? (
                  <i class="fa fa-times-circle-o fa-lg text-danger ml-2">
                    {" "}
                    {"   " + "DEVICE NOT AVAILABLE"}
                  </i>
                ) : r.prodQcStatus === "NOT ATTEMPTED" ? (
                  <i class="fa fa-times-circle-o fa-lg text-danger ml-2">
                    {" "}
                    {"   " + "NOT ATTEMPTED"}
                  </i>
                ) : (
                  <>
                    <i class="fa fa-clock-o fa-lg text-danger ml-2">
                      {" "}
                      {"   " + "Pending"}
                    </i>
                    <button
                      className="badge badge-primary ml-3"
                      type="button"
                      onClick={() => this.handleNotAttempted(r)}
                    >
                      Not Attempted
                    </button>
                  </>
                )}
              </a>
            )}
          </>
        ),
        icon: () => <>Product Status</>,
      },

      {
        key: "productBoxes",
        action: (r) => {
          try {
            let box = r["productBoxes"];
            if (box) {
              try {
                let tdRows = box.map((i) => {
                  return (
                    <>
                      <tr>
                        <td style={{ color: "green" }}>{i["boxName"]}</td>
                        <td>
                          {i["boxStatus"] && (
                            <a target="_blank" rel="no referrer">
                              {i["boxStatus"] === "assign" ? (
                                <>
                                  <i class="fa fa-spinner fa-lg text-info ml-2">
                                    {"   " + "InProcess"}
                                  </i>
                                </>
                              ) : i["boxStatus"] ===
                                "receivedInGoodCondition" ? (
                                <i class="fa fa-check fa-lg text-success ml-2">
                                  {" "}
                                  {"   " + "YES"}
                                </i>
                              ) : i["boxStatus"] === "missing" ? (
                                <i class="fa fa-times-circle-o fa-lg text-danger ml-2">
                                  {" "}
                                  {"   " + "MISSING"}
                                </i>
                              ) : i["boxStatus"] === "damaged" ? (
                                <i class="fa fa-times-circle-o fa-lg text-danger ml-2">
                                  {" "}
                                  {"   " + "DAMAGED"}
                                </i>
                              ) : i["boxStatus"] === "NOT ATTEMPTED" ? (
                                <i class="fa fa-times-circle-o fa-lg text-danger ml-2">
                                  {" "}
                                  {"   " + "NOT ATTEMPTED"}
                                </i>
                              ) : (
                                <>
                                  <i class="fa fa-clock-o fa-lg text-danger ml-2">
                                    {" "}
                                    {"   " + "Pending"}
                                  </i>
                                  <button
                                    className="badge badge-primary ml-3"
                                    type="button"
                                    onClick={() => this.handleNotAttempted(r)}
                                  >
                                    Not Attempted
                                  </button>
                                </>
                              )}
                            </a>
                          )}
                        </td>
                      </tr>
                    </>
                  );
                });
                return tdRows;
              } catch {}
            } else {
              return <b>{"No"}</b>;
            }
          } catch {
            console.log("Error in Furniture Flow >>>>>>>>>>prodQcStatus");
          }
        },
        icon: () => <>Box Status</>,
      },
      {
        key: "ticketProductId",
        action: (r) => (
          <>
            <button
              className="badge badge-primary ml-3 cursorPointor"
              type="button"
              data-target="#myModalImage"
              data-backdrop="false"
              data-toggle="modal"
              aria-hidden="true"
              title="Images"
              onClick={() => this.handleProductImage(r)}
            >
              Click Me!
            </button>
          </>
        ),
        icon: () => <>Product Images</>,
      },
    ],
  };
  componentDidMount() {
    this.loadInit();
  }
  handleProductImage = async (data) => {
    try {
      this.setState({ loading: true });
      let res = await getProductImages(data.ticketProductId);
      if (res !== undefined) {
        if (
          res.data.success === true &&
          res.data.data !== [] &&
          res.data.data !== undefined
        ) {
          this.setState({ setImages: res.data.data });
        } else if (res.data.success === false) {
          toast.error("No Images Available for this Block");
        }
      } else {
        this.setState({ wentWrong: undefined });
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in TicketFiles>>>>>> loadImages");
    }
  };

  handleNotAttempted = async (productData) => {
    swal({
      title: "Are you sure want to update the Product as Not Attempted?",
      text: "Kindly Enter the Reason for Product Not Attempted",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: "input",
      placeholder: "Write something",
    }).then(async (handleNotAttempted) => {
      this.doNotAttempted(handleNotAttempted, productData);
    });
  };

  doNotAttempted = async (handleNotAttempted, productData) => {
    if (handleNotAttempted) {
      swal(
        "Product has been successfully Updated as Not Attempted with the following remarks: " +
          handleNotAttempted,
        {
          icon: "success",
        }
      );
      this.submitNotAttempted(handleNotAttempted, productData);
    } else {
      swal("Product not updated!", { icon: "error" });
    }
  };

  submitNotAttempted = async (handleNotAttempted, productData) => {
    let remarks = handleNotAttempted;
    let status = "NOT ATTEMPTED";
    let resp = await setTicketActionsProdStatus(
      remarks,
      this.state.curTicketDetails,
      this.state.curBlockDetails,
      productData,
      status
    );
    if (resp !== undefined) {
      if (resp.data.success === true) {
        if (resp.data.statusCode === 200) {
          this.props.handleNotAttemptProductVerification();
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
  };

  handleReleased = async (productData) => {
    swal({
      title: "Are you sure want to release the Product as InProcess?",
      text: "Kindly Enter the Reason for Product for Release",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: "input",
      placeholder: "Write something",
    }).then(async (handleReleased) => {
      this.doReleased(handleReleased, productData);
    });
  };

  doReleased = async (handleReleased, productData) => {
    if (handleReleased) {
      swal(
        "Product has been successfully Updated as Released with the following remarks: " +
          handleReleased,
        {
          icon: "success",
        }
      );
      this.submitReleased(handleReleased, productData);
    } else {
      swal("Product not updated!", { icon: "error" });
    }
  };

  submitReleased = async (handleReleased, productData) => {
    let remarks = handleReleased;
    let status = "open";
    let resp = await setTicketActionsProdStatus(
      remarks,
      this.state.curTicketDetails,
      this.state.curBlockDetails,
      productData,
      status
    );
    if (resp !== undefined) {
      if (resp.data.success === true) {
        if (resp.data.statusCode === 200) {
          this.props.handleNotAttemptProductVerification();
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
  };

  loadInit() {
    try {
      const curTicketDetails = this.props.ticketDetails;
      const curBlockDetails = this.props.blockDetails;
      const curProductDetails = this.props.productDetails;
      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails });
      // this.prodVerification(curProductDetails);
      console.log("curProductDetails.." + JSON.stringify(curProductDetails));
    } catch {
      console.log("Error in FurnitureFlowProcess>>>>>>loadInit");
    }
  }
  // prodVerification = async (curProductDetails) => {
  //     curProductDetails.map((r) => {
  //         if (r.prodQcStatus === "assign" || r.prodQcStatus === "open") {
  //             this.props.handleConfirmProductVerification();
  //         }
  //     })

  // }

  handlePageChange = (pageNo) => {
    this.setState({ pageCurrent: pageNo });
  };
  stringReplace(data) {
    return data
      .replace(/{/g, "")
      .replace(/}/g, "")
      .replace(/]/g, "")
      .replace(/"/g, "")
      .replace(/:/g, " : ")
      .replace(/,/g, " , ");
  }

  replaceString = (str) => {
    return str.replace(/_/g, " ");
  };
  render() {
    const ticketDetails = this.state.curTicketDetails;
    const productDetails = this.state.curProductDetails;
    return (
      <>
        {this.state.pageHide === "false" && (
          <div className="row">
            <div className="col-md-12">
              <div className="card ">
                <div className="card-body bg-light">
                  <div className="form-subHeader">
                    <b>
                      Ticket Products Verification - {ticketDetails.ticketNo}
                    </b>
                  </div>
                  <div className="row mt-4 ">
                    {/* {JSON.stringify(productDetails)} */}
                    <div className="container-fluid">
                      <Table
                        clsName="table table-striped table-bordered table-hover"
                        tableMeta={this.tableMeta}
                        data={productDetails}
                      />

                      {/* <Pagination
                                                pageNo={this.state.pageCurrent}
                                                perPage={this.state.pageSize}
                                                itemsCount={pageCount}
                                                onPageChange={this.handlePageChange}
                                            /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className="modal fade"
          id="myModalImage"
          role="dialog"
          aria-labelledby="myModalImage"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-dialog momodel modal-fluid modal-xl "
            style={{ marginLeft: "35%", marginTop: "0%" }}
            role="document"
          >
            <div
              className="modal-content-full-width modal-content"
              style={{ backgroundColor: " #e8e8e8" }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  <h5>
                    <b title="TicketNumber"> {ticketDetails.ticketNo}</b>
                  </h5>
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div class="container-fluid">
                  {!this.state.loading && this.state.setImages.length !== 0 && (
                    <div className="row mt-4 ">
                      <div className="col-md-12">
                        <div className="card ">
                          <div className="card-body bg-custom-6">
                            <div
                              className="form-subHeader text-center"
                              style={{
                                color: "#e6e8f1",
                                backgroundColor: "#8b9566",
                              }}
                            >
                              Before Packing Photos
                            </div>
                            <div className="row">
                              {this.state.setImages.map((t) => {
                                return (
                                  <>
                                    {t.actionCode !== "mSignature" &&
                                      t.actionCode !== "mPhotoId" &&
                                      t.actionCode !== "mPhotoAftrPackng" && (
                                        <div className="col-md-4">
                                          <span>
                                            <a
                                              href="javascript:void(0);"
                                              data-toggle="tooltip"
                                              title={this.stringReplace(
                                                t.geoLocation
                                              )}
                                            >
                                              <img
                                                src={t.imageUrl}
                                                style={{
                                                  width: "150px",
                                                  height: "150px",
                                                  margin: "3px",
                                                }}
                                              />
                                            </a>
                                            <br></br>
                                            <span
                                              className="badge badge-info link-over ml-2"
                                              style={{
                                                width: "auto",
                                                height: "auto",
                                                margin: "3px",
                                              }}
                                            >
                                              {t.action}
                                            </span>
                                          </span>
                                          <br></br>
                                          <br></br>
                                        </div>
                                      )}
                                  </>
                                );
                              })}
                            </div>
                            <div
                              className="form-subHeader text-center"
                              style={{
                                color: "#3d4154",
                                backgroundColor: "#89c0b1",
                              }}
                            >
                              After Packing Photos
                            </div>
                            <div className="row">
                              {this.state.setImages.map((t) => {
                                return (
                                  <>
                                    {t.actionCode === "mPhotoAftrPackng" && (
                                      <div className="col-md-4">
                                        <span>
                                          <a
                                            href="javascript:void(0);"
                                            data-toggle="tooltip"
                                            title={this.stringReplace(
                                              t.geoLocation
                                            )}
                                          >
                                            <img
                                              src={t.imageUrl}
                                              style={{
                                                width: "150px",
                                                height: "150px",
                                                margin: "3px",
                                              }}
                                            />
                                          </a>
                                          <br></br>
                                          <span
                                            className="badge badge-warning link-over ml-2"
                                            style={{
                                              width: "auto",
                                              height: "auto",
                                              margin: "3px",
                                            }}
                                          >
                                            {t.action}
                                          </span>
                                        </span>
                                        <br></br>
                                        <br></br>
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                            <div
                              className="form-subHeader text-center"
                              style={{
                                color: "#3d4154",
                                backgroundColor: "#8e9e6d",
                              }}
                            >
                              Id Photos
                            </div>
                            <div className="row">
                              {this.state.setImages.map((t) => {
                                return (
                                  <>
                                    {t.actionCode === "mPhotoId" && (
                                      <div className="col-md-4">
                                        <span>
                                          <a
                                            href="javascript:void(0);"
                                            data-toggle="tooltip"
                                            title={this.stringReplace(
                                              t.geoLocation
                                            )}
                                          >
                                            <img
                                              src={t.imageUrl}
                                              style={{
                                                width: "150px",
                                                height: "150px",
                                                margin: "3px",
                                              }}
                                            />
                                          </a>
                                          <br></br>
                                          <span
                                            className="badge badge-danger link-over ml-2"
                                            style={{
                                              width: "auto",
                                              height: "auto",
                                              margin: "3px",
                                            }}
                                          >
                                            {t.action}
                                          </span>
                                        </span>
                                        <br></br>
                                        <br></br>
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                            <div
                              className="form-subHeader text-center"
                              style={{
                                color: "#3d4154",
                                backgroundColor: "#8cbd6d",
                              }}
                            >
                              Signature
                            </div>
                            <div className="row">
                              {this.state.setImages.map((t) => {
                                return (
                                  <>
                                    {t.actionCode === "mSignature" && (
                                      <div className="col-md-4">
                                        <span>
                                          <a
                                            href="javascript:void(0);"
                                            data-toggle="tooltip"
                                            title={this.stringReplace(
                                              t.geoLocation
                                            )}
                                          >
                                            <img
                                              src={t.imageUrl}
                                              style={{
                                                width: "150px",
                                                height: "150px",
                                                margin: "3px",
                                              }}
                                            />
                                          </a>
                                          <br></br>
                                          <span
                                            className="badge badge-success link-over ml-2"
                                            style={{
                                              width: "auto",
                                              height: "auto",
                                              margin: "3px",
                                            }}
                                          >
                                            {this.replaceString(t.action)}
                                          </span>
                                        </span>
                                        <br></br>
                                        <br></br>
                                      </div>
                                    )}
                                  </>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!this.state.loading &&
                    this.state.setImages.length === 0 &&
                    this.state.wentWrong !== undefined && (
                      <div className="container-fluid jumbotron text-center">
                        <h4>No Images Available for this ticket...</h4>
                      </div>
                    )}
                  {!this.state.loading &&
                    this.state.wentWrong === undefined && (
                      <div className="container-fluid jumbotron text-center">
                        <h4>Something went wrong please try again...</h4>
                      </div>
                    )}
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FurnitureFlowProcess);
