// import axios from "axios";
// import { set } from "lodash";
import React, { useState, useEffect } from "react";
import {
  getStoresByHub,
  postInwardInventory,
  getItemListByTeamId,
  updateItemSkuCode,
  getItemBySkuBarcode,
  getItemBySku,
  getItemsWithNoSku,
} from "../../../services/stockManagement/inwardInventoryServices";
import { getRetailersMappedToInventory } from "../../../services/stockManagement/inventory";
import t from "../../../services/_toastService";
import ItemMaster from "./ItemMaster";
import UpdateSKUCode from "./UpdateSKUCode";
// import { toast } from "react-toastify";
import toast from "../../../services/_toastService"
import swal from 'sweetalert';
import { IoIosAddCircleOutline } from "react-icons/io";
import { GrSubtractCircle } from "react-icons/gr";
import { getActiveClients } from "../../../services/masterLocations/clientService";
import { log } from "joi-browser";

// const localtime = new Date();

// console.log( new Date().toISOString())

const InwardInventory = (props) => {
  const [invDesc, setInvDesc] = useState("");
  const [prodValue, setProdValue] = useState("");
  const [invDate, setInvDate] = useState(new Date().toISOString().slice(0, 10));
  const [invTime, setInvTime] = useState(new Date().toString().slice(16, 21));
  const [selectItem, setSelectItem] = useState({});

  const [selectItemCode, setItemCode] = useState("");

  const [selectItemId, setSelectedItemId] = useState({});

  const [qty, setQty] = useState(1);

  const { hubValue } = props;
  const { teamValue } = props;

  const [invList, setInvList] = useState([]);
  // console.log({ invList });
  const [res, setRes] = useState([]);
  const [hubsList, setHubsList] = useState([]);
  const [hubId, setHubId] = useState("");
  const [storesList, setStoresList] = useState([]);
  const [storeId, setStoreId] = useState("");
  const [itemList, setItemList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [selectRetailer, setSelectRetailer] = useState(props.teamValue);
  // console.log({rettttttt:JSON.stringify(selectRetailer)});
  const [stateRefValueDc, setRefValueDc] = useState([]);
  const [stateRefValueInvoice, setRefValueInvoice] = useState("");
  const [stateIsSerialYes, setIsSerialYes] = useState();

  const [stateIsSerialNo, setIsSerialNo] = useState(true);
  const [stateIsOverrideYes, setIsOverrideYes] = useState();
  const [stateIsOverrideNo, setIsOverrideNo] = useState(true);
  const [dataa, setData] = useState(false);
  // console.log(dataa);
  const [stateRefValue, setRefValue] = useState("");
  const [stateItemBarcode, setItemBarcode] = useState("");
  const [stateSelectedFilesList, setSelectedFilesList] = useState([]);
  const [qnt, setQnt] = useState("");
  const [sampleArray, setSampleArray] = useState([]);

  // let currDate = new Date().toISOString().split("T")[0];
  // console.log({ currDate });
  // let currHrsAndMin = new Date().getHours() + ":" + new Date().getMinutes();
  // console.log({ currHrsAndMin });

  // console.log({ invTime });
  // console.log({ currHrsAndMin });

  // console.log({
  //   invDesc,
  //   invDate,
  //   invTime,
  //   selectItem,
  //   qty,
  //   invList,
  //   res,
  //   hubsList,
  //   hubId,
  //   storesList,
  //   storeId,
  //   itemList,
  //   retailerList,
  //   selectRetailer,
  //   stateRefValueDc,
  //   stateRefValueInvoice,
  //   stateRefValue,
  //   stateSelectedFilesList,
  // });

  useEffect(() => {
    // getHubs()
    //   .then((response) => {
    //     const hubs = response.data.data.hubs;
    //     // console.log(hubs)
    //     setHubsList(hubs);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
    let hubList = JSON.parse(localStorage.getItem("hubs"));
    // console.log({ hubList });
    setHubsList(hubList);
    const filteredHubList = hubList.filter((hub) => {
      return hub.hubId === hubValue;
    });
    const selectedHub = {
      hubId: filteredHubList[0].hubId,
      hub: filteredHubList[0].hub,
    };
    setHubId(selectedHub);


    const getStores = async () => {
      const response = await getStoresByHub(selectedHub?.hubId);
      return response.data;
    };
    getStores()
      .then(({ data }) => {
        setStoresList(data.stores);
        setItemList([]);
        setInvList([]);
      })
      .catch((error) => console.error(error));


    // getRetailersMappedToInventory().then((response) => {
    //   const retailerList = response.data.data?.inventoryRetailerList;
    //   console.log({retailerList});
    //   if (retailerList) setRetailerList(retailerList);
    // });

    getActiveClients("active").then((resp) => {
      const retailerList = resp.data.data.clients;

      // console.log({ retailerList });
      if (retailerList) setRetailerList(retailerList);

      const filteredClientList = retailerList.filter((client) => {
        return client.TEAM_ID === teamValue;
      });
      // console.log({ filteredClientList });
      const selectedClient = {
        teamId: filteredClientList[0].TEAM_ID,
        name: filteredClientList[0].NAME,
      };
      setSelectRetailer(selectedClient);

      const onGetItemList = async () => {
        const response = await getItemListByTeamId(selectedClient?.teamId);
        if(response.data.data.itemsList.length > 0){
          toast.toastSuccess("Items list of the clients fetched successfully ")
        return response.data;
        }else {
          toast.toastError( "Items are not available for selected clients, so kindly create an items.")
        }
      };
      onGetItemList()
        .then(({ data }) => {
          const itemlist = data["itemsList"]?.map((item) => ({
            ...item,
            barcodeType: "",
            scanType: "",
            fromScan: "",
            toScan: "",
            prefix: "",
          }));

          setItemList(itemlist);
          setInvList([]);
        })
        .catch((err) => console.error(err));



    })



  }, [hubValue, teamValue]);

  const handleMapItemNo = async (selectItemCode) => {
    const params = {
      teamId: selectItem.teamId,
      itemId: selectItem.itemId,
      itemSkuBarcode: selectItemCode,
    };
    // console.log({ params });
    try {
      const resp = await updateItemSkuCode(params);
      if (resp.data.success) {
        toast.success(() => <div>{resp.data.msg}</div>);
        setQty(1)
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch (error) {
      // console.log("ERROR in handleMapItemNo");
      console.error(error);
    }
  };

  const handleMapItemSkuNo = async () => {
    const params = {
      teamId: selectItem.teamId,
      itemId: selectItem.itemId,
      itemSkuBarcode: selectItemCode,
    };
    // console.log({ params });
    try {
      const resp = await updateItemSkuCode(params);
      if (resp.data.success) {
        toast.success(() => <div>{resp.data.msg}</div>);
        setQty(1);
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch (error) {
      console.log("ERROR in handleMapItemSkuNo");
      console.error(error);
    }
  };

  const handleClickAdd = (e) => {
    if(selectItem.barcodeType === "sticker"){
      if( selectItem.fromScan.length === 7){
    // validating quantity field before adding to invList
    // validate qty only when isSerial === 'no'
    if (selectItem.isSerial === "no" || selectItem.isSerial === "both") {
      if (
        qty === "0" ||
        qty === undefined ||
        qty < 0 ||
        isNaN(+qty) ||
        qty === ""
      ) {

        setQty(1);
        alert(selectItem.isSerial)
        alert(qty)
        return alert("Invalid quantity");
      }
    }

    // validating item selection - should not be empty
    if (!selectItem.item) return alert("Please select an item");
    // validating if item is serial
    if (selectItem.isSerial === "yes") {
      if (!selectItem?.barcodeType) return alert("Please select barcode type");
      if (!selectItem?.scanType) return alert("Please select scan type");
      if (!selectItem?.fromScan)
        return alert("From scan is not allowed to be empty");
      if (selectItem?.scanType === "range" && !selectItem?.toScan)
        return alert("To scan is not allowed to be empty");
      if (selectItem.fromScan <= 0)
        return alert("From Scan should not be lesser than 1");

      if (selectItem.scanType === "sticker") {
        //* check for the validity of the sticker serial nos.
        console.log("check for the validity of the sticker serial nos");
      }
    }

    const filteredInvList = invList.filter((inv) => {
      return inv.itemId === selectItem.itemId;
    });
    console.log("filteredInvList", filteredInvList);
    // setQty(1);

    console.log("from handleClickAdd", filteredInvList.length);

    // const qnt = qty;
    console.log("Adding first item to invList", filteredInvList);
    // if invList is empty
    if (filteredInvList.length >= 0) {
      setQty(1)

      if (selectItem.isSerial === "yes") {
        const invListNew = [
          ...invList,
          {
            itemId: selectItem.itemId,
            qnt: 1,
            item: selectItem.item,
            isSerial: selectItem.isSerial,
            scanType: selectItem.scanType,
            barcodeType: selectItem.barcodeType,
            prefix: selectItem.prefix || "",
            fromScan: selectItem.fromScan || "",
            toScan: selectItem.toScan || "",
            itemSkuBarcode: selectItemCode
          },
        ];
        setInvList(invListNew);
      } else {
        let result = checkData(invList, selectItem.itemSkuBarcode);
        console.log("result Data ==>", result)
        console.log("invList", invList);

        if (result === false) {
          const invListNew = [
            ...invList,
            {
              itemId: selectItem.itemId,
              qnt: 1,
              item: selectItem.item,
              isSerial: selectItem.isSerial,
              scanType: selectItem.scanType,
              barcodeType: selectItem.barcodeType,
              prefix: selectItem.prefix,
              fromScan: selectItem.fromScan,
              toScan: selectItem.toScan,
              itemSkuBarcode: selectItemCode
            },
          ];
          setInvList(invListNew);
          console.log({ result });

        } else if (result === true) {

          doIncreaseQty(result, invList);

        }
      }

    } else {
      setQty(1);
    }
    // if (filteredInvList.length >= 1) {
    //   const invListNew1 = [
    //     ...invList,
    //     {
    //       itemId: selectItem.itemId,
    //       qnt: qnt,
    //       item: selectItem.item,
    //       isSerial: selectItem.isSerial,
    //       scanType: selectItem.scanType,
    //       barcodeType: selectItem.barcodeType,
    //       prefix: selectItem.prefix,
    //       fromScan: selectItem.fromScan,
    //       toScan: selectItem.toScan,
    //       itemSkuBarcode: selectItem.itemSkuBarcode
    //     },
    //   ];
    //   setInvList(invListNew1);
    // }
    setSelectItem({
      itemId: "",
      item: "",
      isSerial: "",
      scanType: "",
      barcodeType: "",
      prefix: "",
      fromScan: "",
      toScan: "",
    });

    setItemCode("")
  }else{
    alert("Barcode number should be exactly 7 digits long")
  }
  }else{
    if (selectItem.isSerial === "no" || selectItem.isSerial === "both") {
      if (
        qty === "0" ||
        qty === undefined ||
        qty < 0 ||
        isNaN(+qty) ||
        qty === ""
      ) {

        setQty(1);
        alert(selectItem.isSerial)
        alert(qty)
        return alert("Invalid quantity");
      }
    }

    // validating item selection - should not be empty
    if (!selectItem.item) return alert("Please select an item");
    // validating if item is serial
    if (selectItem.isSerial === "yes") {
      if (!selectItem?.barcodeType) return alert("Please select barcode type");
      if (!selectItem?.scanType) return alert("Please select scan type");
      if (!selectItem?.fromScan)
        return alert("From scan is not allowed to be empty");
      if (selectItem?.scanType === "range" && !selectItem?.toScan)
        return alert("To scan is not allowed to be empty");
      if (selectItem.fromScan <= 0)
        return alert("From Scan should not be lesser than 1");

      if (selectItem.scanType === "sticker") {
        //* check for the validity of the sticker serial nos.
        console.log("check for the validity of the sticker serial nos");
      }
    }

    const filteredInvList = invList.filter((inv) => {
      return inv.itemId === selectItem.itemId;
    });
    console.log("filteredInvList", filteredInvList);
    // setQty(1);

    console.log("from handleClickAdd", filteredInvList.length);

    // const qnt = qty;
    console.log("Adding first item to invList", filteredInvList);
    // if invList is empty
    if (filteredInvList.length >= 0) {
      setQty(1)

      if (selectItem.isSerial === "yes") {
        const invListNew = [
          ...invList,
          {
            itemId: selectItem.itemId,
            qnt: 1,
            item: selectItem.item,
            isSerial: selectItem.isSerial,
            scanType: selectItem.scanType,
            barcodeType: selectItem.barcodeType,
            prefix: selectItem.prefix || "",
            fromScan: selectItem.fromScan || "",
            toScan: selectItem.toScan || "",
            itemSkuBarcode: selectItemCode
          },
        ];
        setInvList(invListNew);
      } else {
        let result = checkData(invList, selectItem.itemSkuBarcode);
        console.log("result Data ==>", result)
        console.log("invList", invList);

        if (result === false) {
          const invListNew = [
            ...invList,
            {
              itemId: selectItem.itemId,
              qnt: 1,
              item: selectItem.item,
              isSerial: selectItem.isSerial,
              scanType: selectItem.scanType,
              barcodeType: selectItem.barcodeType,
              prefix: selectItem.prefix,
              fromScan: selectItem.fromScan,
              toScan: selectItem.toScan,
              itemSkuBarcode: selectItemCode
            },
          ];
          setInvList(invListNew);
          console.log({ result });

        } else if (result === true) {

          doIncreaseQty(result, invList);

        }
      }

    } else {
      setQty(1);
    }
    setSelectItem({
      itemId: "",
      item: "",
      isSerial: "",
      scanType: "",
      barcodeType: "",
      prefix: "",
      fromScan: "",
      toScan: "",
    });

    setItemCode("")
  }
  }
  


  const doIncreaseQty = async (res, i) => {
    // console.log("res",i)
    try {

      doSubQty(res, i)


    } catch (e) {
      console.log(e)
    }
  }

  const doSubQty = async (res, data) => {
    // console.log("data", data)
    if (data) {

      const toUpdate = invList.findIndex(item => item.itemSkuBarcode === selectItemCode);
      const quantity = invList[toUpdate].qnt;
      // Convert the string to a number before incrementing
      const currentQuantity = parseInt(quantity, 10);
      // Increment the number
      invList[toUpdate].qnt = currentQuantity + 1;

    }
  }


  const checkData = (arr, code) => {
    const { length } = arr;
    const id = length + 1;
    const found = arr.some(el => el.itemSkuBarcode === code);
    return found;
  }

  const handleSubmit = async (e) => {
    
    if (stateRefValueDc === "" || stateRefValueInvoice === "")
      return alert("Ref.Doc Type is empty");
    if (stateRefValue === "") return alert("Ref. Doc No is empty");
    if (invTime === "") return alert("Time is empty");
    if (invDesc === "") return alert("Description is empty");
    // if (prodValue === "") return alert("Product value is empty");
    if (invList?.length === 0) return alert("Items have not been added");

    

    const invListToSend = invList.map((inv) => {
      if (inv.isSerial === "yes" || inv.isSerial === "no") {
        const data = {
          itemId: inv.itemId,
          qnt: inv.qnt,
          item: inv.item,
          isSerial: inv.isSerial,
          scanType: inv.scanType,
          barcodeType: inv.barcodeType,
          prefix: inv.prefix,
          fromScan: inv.fromScan,
          toScan: inv.toScan,
        };
        return data;
      } else if (inv.isSerial === "both" && stateIsSerialYes === true) {
        const data = {
          itemId: inv.itemId,
          qnt: inv.qnt,
          item: inv.item,
          isSerial: "yes",
          scanType: inv.scanType,
          barcodeType: inv.barcodeType,
          prefix: inv.prefix,
          fromScan: inv.fromScan,
          toScan: inv.toScan,
        };
        return data;
      } else if (inv.isSerial === "both" && stateIsSerialNo === true) {
        const data = {
          itemId: inv.itemId,
          qnt: inv.qnt,
          item: inv.item,
          isSerial: "no",
          scanType: inv.scanType,
          barcodeType: inv.barcodeType,
          prefix: inv.prefix,
          fromScan: inv.fromScan,
          toScan: inv.toScan,
        };
        return data;
      }
    });

    const data = {
      invDateTime: `${invDate} ${invTime}`,
      invDesc: invDesc,
      prodValue: prodValue,
      inwardType: "inward",
      hubId: hubId.hubId,
      storeId: "Spares",
      items: invListToSend,
      selectedFileList: stateSelectedFilesList,
      refDocType: stateRefValueDc ? "DC" : "Invoice",
      refDocNumber: stateRefValue,
      retailerId: selectRetailer?.teamId,
    };

    try {

      const response = await postInwardInventory(data);
      setRefValueDc([]);

      // console.log({ response });
      if (response.data.success) {
        t.toastSuccess(response.data.msg);
        setRes(response.data.data);
        setInvList([]);
        setInvDesc("");
        setInvTime(new Date().toString().slice(16, 21));
        setSelectedFilesList([]);
        setRefValue("");
        setRefValueDc([]);
        setRefValueInvoice(null);
        
        const onGetItemList = async () => {
          const response = await getItemListByTeamId(selectRetailer.teamId);
          return response.data;
        };
        onGetItemList()
          .then(({ data }) => {
            const itemlist = data["itemsList"]?.map((item) => ({
              ...item,
              barcodeType: "",
              scanType: "",
              fromScan: "",
              toScan: "",
              prefix: "",
            }));
  
            setItemList(itemlist);
            setInvList([]);
          })
          .catch((err) => console.error(err));
      }
    } catch (error) {
      console.error(error);
      t.toastError(error?.msg ? error.msg : "Some error happened");
      setInvList([]);
      setInvDesc("");
      setInvTime(new Date().toString().slice(16, 21));
      setSelectedFilesList([]);
      setRefValue("");
      setRefValueDc([]);
      setRefValueInvoice(null);
    }
  };
  const handleChangeHub = (e) => {
    const hubSelected = JSON.parse(e.target.value);
    // console.log({ hubSelected });
    setHubId(hubSelected);
    setStoreId("");
    setSelectRetailer("");

    const getStores = async () => {
      const response = await getStoresByHub(hubSelected?.hubId);
      return response.data;
    };
    getStores()
      .then(({ data }) => {
        setStoresList(data.stores);
        setItemList([]);
        setInvList([]);
      })
      .catch((error) => console.error(error));
  };
  const handleChangeStore = (e) => {
    const storeSelected = JSON.parse(e.target.value);
    setStoreId(storeSelected);
  };

  const handleChangeItemSelect = (e) => {
    const itemId = e.target.value;
    const filteredItem = itemList.filter((item) => {
      return item.itemId === itemId;
    });
    const selectedItem = { ...filteredItem[0] };
    setSelectItem(selectedItem);
    setSelectedItemId(itemId);

    const filterInvList = invList.filter((inv) => {
      return inv.itemId === itemId;
    });

    if (filterInvList.length === 1) {
      setQty(filterInvList[0].qnt);
    } else {
      setQty(1);
    }
  };

  const handleChange = (e) => {
    setItemCode(e.target.value)
  }


  const handleChangeItemSelectByBarcode = async (e) => {
    const itemCode = e.target.value;
    setItemCode(itemCode);
    const retailer = teamValue;

    if (e.key === "Enter") {

      const resp = await getItemBySku(retailer, itemCode);
      // console.log({ resp });
      if (resp.data.success === true) {
        const selectedItem = { ...resp.data.data.itemDetails[0] };
        setSelectItem(selectedItem)
        setItemList(resp.data.data.itemDetails);
        setData(true);
      }
      else if (resp.data.success === false) {
        setData(false);
        const response = await getItemsWithNoSku(selectRetailer?.teamId);
        // console.log({ response });
        // console.log(response.data.data.itemsDetail.length);
        if (response.data.success === true && response.data.data.itemsDetail.length > 0) {
          setItemList(response.data.data.itemsDetail);
        } else {
          setItemList(response.data.data.itemsDetail || [])
          toast.toastError(response.data.msg)
        }
      }

    }
  };

  // console.log("array data =>", invList);
  const handleRemoveItem = (item) => {
    if (item.isSerial === "yes" && item.itemSkuBarcode !== "") {
      const newInvList = invList.filter(
        (inv) => inv.fromScan !== item.fromScan
      );
      setInvList(newInvList);
    } else if (item.isSerial === "yes") {
      const newInvList = invList.filter(
        (inv) => inv.fromScan !== item.fromScan
      );
      setInvList(newInvList);
    }

    else if (item.isSerial === "no") {
      const newInvList = invList.filter((inv) => inv.itemId !== item.itemId);
      setInvList(newInvList);
    } else {
      const newInvList = invList.filter((inv) => inv.itemId !== item.itemId);
      setInvList(newInvList);
    }
  };

  // const handleChangeRetailer = (e) => {
  //   const selectedRetailer = props.teamValue;
  //   console.log({selectedRetailer});
  //   const daaa = selectedRetailer.teamId;
  //   console.log({daaa});
  //   setSelectRetailer(selectedRetailer);

  //   const onGetItemList = async () => {
  //     const response = await getItemListByTeamId(selectedRetailer);
  //     return response.data;
  //   };
  //   onGetItemList()
  //     .then(({ data }) => {
  //       const itemlist = data["itemsList"]?.map((item) => ({
  //         ...item,
  //         barcodeType: "",
  //         scanType: "",
  //         fromScan: "",
  //         toScan: "",
  //         prefix: "",
  //       }));

  //       setItemList(itemlist);
  //       setInvList([]);
  //     })
  //     .catch((err) => console.error(err));
  // };
  const increaseQuantity = (index) => {
    const updatedInvList = [...invList];
    updatedInvList[index].qnt += 1;
    setInvList(updatedInvList);
    // console.log({ updatedInvList });
  };

  const decreaseQuantity = (index) => {
    const updatedInvList = [...invList];
    if (updatedInvList[index].qnt > 1) {
      updatedInvList[index].qnt -= 1;
      setInvList(updatedInvList);
      // console.log({ updatedInvList });

    }
  };
  const handleInwardQntChange = (e, inv) => {
    try {
      const inputValue = parseInt(e.target.value, 10) || 1;
      const updatedInvList = invList.map((i, iIndex) =>
        i.itemSkuBarcode === inv.itemSkuBarcode && i.itemId === inv.itemId
          ? { ...i, qnt: inputValue }
          : i
      );
      setInvList(updatedInvList);
    } catch (e) {
      console.log(e)
    }
  }

  const onClickPrintLabel = (event) => {
    try {
      const content = document.getElementById("labelContentToPrint");
      const pri = document.getElementById("ifmcontentstoprint").contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
      pri.close();
    } catch {
      console.log("Error in InwardInventory>>>>>>>>onClickPrintLabel");
    }
  };
  // console.log(invTime);
  // console.log(localtime.toString().slice(16, 21));

  return (
    <>
      <div className="container-fluid p-1 m-0">
        <h1 className="fs-4">Inward Inventory</h1>
        <div className="row m-0 p-0">

          <div className="col-12 m-0 p-0">
            <div className="row  m-0 p-2 ">
              {/* <div className="col-3 p-0 m-0 ml-1 mt-1 mb-3">
                <p className="font-monospace p-0 m-0 mt-2 ml-0">Hubs<span style={{ color: "red" }}>*</span></p>
                <select
                  className="form-select"
                  aria-label="select hub"
                  value={JSON.stringify(hubId)}
                  placeholder="Select retailer"
                  onChange={(e) => handleChangeHub(e)}
                >
                  <option key={"0"} value='{"hubId":null}'>
                    Select Hub
                  </option>

                  {hubsList.map((i) => {
                    return (
                      <option
                        key={i.hubId}
                        value={JSON.stringify({
                          hubId: i.hubId,
                          hub: i.hub,
                        })}
                      >
                        {i.hub}
                      </option>
                    );
                  })}
                </select>
              </div> */}

              {/* <div className="col-3 p-0 m-0 ml-1 mt-1 mb-3">
                <p className="font-monospace p-0 m-0 mt-2 ml-0">Stores<span style={{ color: "red" }}>*</span></p>
                <select
                  className="form-select"
                  aria-label="select hub"
                  value={JSON.stringify(storeId)}
                  placeholder="Select retailer"
                  onChange={(e) => handleChangeStore(e)}
                >
                  <option key={"0"} value='{"storeId":null}'>
                    Select Store
                  </option>

                  {storesList.map((i) => {
                    return (
                      <option
                        key={i.storeId}
                        value={JSON.stringify({
                          storeId: i.storeId,
                          store: i.store,
                        })}
                      >
                        {i.store}
                      </option>
                    );
                  })}
                </select>
              </div> */}
            </div>
            {/* {retailerList.length > 0 && hubId && storeId && ( */}
            <>
              {/* <div className="col-3 p-0 m-0 ml-2 mt-1 mb-3">
                  <p className="font-monospace p-0 m-0 mt-2 ml-1">Client<span style={{ color: "red" }}>*</span></p>

                  <select
                    className="form-select"
                    aria-label="select retailer"
                    value={JSON.stringify(selectRetailer)}
                    placeholder="Select retailer"
                    // onChange={(e) => handleChangeRetailer(e)}
                  >
                    <option key={"0"} value='{"teamId":null}'>
                      Select Client
                    </option>
                    {retailerList.map((i) => {
                      return (
                        <option
                          key={i.teamId}
                          // value={teamValue}
                          value={JSON.stringify({
                            teamId: i.TEAM_ID,
                            name: i.NAME,
                          })}
                        >
                          {i.NAME}
                        </option>
                      );
                    })}
                  </select>
                </div> */}
            </>
            {/* )} */}

            {/* inward date, time and description inputs */}
            <div className="row border m-0 p-2 ">
              <h4 style={{ fontSize: "1.2rem" }}>Inward Details :</h4>

              {/* In Date */}
              <div className="col-2 m-0 p-0 ml-1 mt-1 mb-3">
                <p className="font-monospace mb-1">In Date<span style={{ color: "red" }}>*</span></p>
                <input
                  className="form-control"
                  type="date"
                  placeholder={"In Date"}
                  aria-label="In Date"
                  max={new Date().toISOString().slice(0, 10)}
                  onChange={(e) => {
                    setInvDate(e.target.value);
                  }}
                  value={invDate}
                />
              </div>

              {/* In Time */}
              <div className="col-2 m-0  p-0 ml-1 mt-1 mb-3">
                <p className="font-monospace mb-1">In Time<span style={{ color: "red" }}>*</span></p>
                <input
                  className="form-control"
                  type="time"
                  placeholder={"In time"}
                  aria-label="In Time"
                  max={new Date().toString().slice(16, 21)}
                  onChange={(e) => {
                    if (e.target.value <= new Date().toString().slice(16, 21)) {
                      setInvTime(e.target.value);
                    } else {
                      setInvTime(new Date().toString().slice(16, 21));
                    }
                  }}
                  value={invTime}
                />
              </div>

              {/* Inward Description */}
              <div className="col-3 m-1 p-0 ml-1 mt-1 mb-3">
                <p className="font-monospace mb-1">Inward Description<span style={{ color: "red" }}>*</span></p>
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  aria-label="inward inventory description"
                  onChange={(e) => {
                    setInvDesc(e.target.value);
                  }}
                  value={invDesc}
                />
              </div>

              {/* <div className="row mt-2"> */}

              <div className="mx-3" style={{ display: "flex", flexDirection: "row" }}>

                <div className="form-check form-check-inline" style={{ display: "flex", flexDirection: "row", columnGap: "2rem", marginTop: "1.6rem" }}>
                  <p className="font-monospace mt-2">Ref.Doc Type:<span style={{ color: "red" }}>*</span></p>
                  <div >
                    <label className=" font-monospace form-check-label w-100">
                      <input
                        type="radio"
                        name="refDocType"
                        value="DC"
                        // checked={false}
                        className="  form-check-input"
                        id="refDocTypeDC"
                        onChange={(e) => {
                          setRefValueDc(e.target.checked ? true : false);
                          setRefValueInvoice(!e.target.checked ? true : false);
                        }}
                      />
                      DC
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <label className=" font-monospace form-check-label">
                      <input
                        type="radio"
                        name="refDocType"
                        value="Invoice"
                        // checked={false}
                        className=" form-check-input"
                        id="refDocTypeInvoice"
                        onChange={(e) => {
                          setRefValueInvoice(e.target.checked ? true : false);
                          setRefValueDc(!e.target.checked ? true : false);
                        }}
                      />
                      Invoice
                    </label>
                  </div>
                </div>
                <div>
                  <label className="font-monospace" style={{ marginTop: "2.1rem" }}>
                    Ref.Doc No<span style={{ color: "red" }}>*</span>
                    <input
                      type="text"
                      value={stateRefValue}
                      name="refDocNo"
                      id="refDocNo"
                      className="mx-2"
                      onChange={(e) => {
                        setRefValue(e.target.value);
                      }}
                    />
                  </label>
                </div>
                <div className="col-4">
                  <label
                    htmlFor="refDocFiles"
                    className="form-label font-monospace"
                  >
                    Upload Ref Doc Files
                  </label>
                  <input
                    className="form-control form-control-sm"
                    multiple
                    id="refDocFiles"
                    type="file"
                    onChange={(e) => {
                      setSelectedFilesList(e.target.files);
                    }}
                  />
                </div>

              </div>
              {/* </div> */}




            </div>
            {itemList.length !== 0 && (
              <>
                <div className="row m-0 p-2 border justify-content-between">
                  {/* items selectbox */}
                  <h4 style={{ fontSize: "1.2rem" }}>Item Details :</h4>
                  <div style={{ display: "flex", flexDirection: "row", columnGap: "1rem" }}>

                    <div className="col-3 m-0 p-1 ">
                      <label className="font-monospace">
                        Scan SKU
                        <input
                          type="text"
                          value={selectItemCode}
                          name="scanBarcode"
                          id="scanBarcode"
                          className="mx-2 p-1"
                          onKeyDown={(e) => {
                            handleChangeItemSelectByBarcode(e);
                            // setItemCode(e.target.value)
                          }}

                          onChange={(e) => handleChange(e)}
                        />
                      </label>
                    </div>

                    {/* <div className="col-4 m-2 p-0">
                    {dataa === true && (
                      <p>
                        {" "}
                        <div className="row">
                          <p className="col-2 font-monospace fw-bold mt-2">
                            isOverride:
                          </p>
                          <div className="col-2 p-2 form-check form-check-inline">
                            <label className=" font-monospace form-check-label w-100">
                              <input
                                type="radio"
                                name="isOverride"
                                value={stateIsOverrideYes}
                                checked={stateIsOverrideYes}
                                className="form-check-input"
                                // id="isOverrideYes"
                                data-target="#myBarcodeModalSlNo"
                                data-backdrop="false"
                                data-toggle="modal"
                                aria-hidden="true"
                                onChange={(e) => {
                                  setIsOverrideYes(
                                    e.target.checked ? true : false
                                  );
                                  // setIsOverrideNo(
                                  //   !e.target.checked ? true : false
                                  // );
                                }}
                              />
                              yes
                            </label>
                          </div>
                          <div className="col-2 form-check form-check-inline">
                            <label className=" font-monospace form-check-label">
                              <input
                                type="radio"
                                name="isOverride"
                                value={stateIsOverrideNo}
                                checked={stateIsOverrideNo}
                                className=" form-check-input"
                                id="isOverrideNo"
                                onChange={(e) => {
                                  setIsOverrideNo(
                                    e.target.checked ? true : false
                                  );
                                  // setIsOverrideYes(
                                  //   !e.target.checked ? true : false
                                  // );
                                }}
                              />
                              no
                            </label>
                          </div>
                        </div>
                      </p>
                    )}
                  </div> */}

                    <div className="col-4 p-0 m-0">
                      <select
                        className="form-select"
                        aria-label="select items"
                        value={selectItem.itemId}
                        placeholder="Select Items"
                        onChange={(e) => handleChangeItemSelect(e)}
                      >
                        <option
                          key={"0"}
                          // value='{"item":null}'
                          value=""
                        >
                          Select items*
                        </option>
                        {itemList.map((i) => {
                          return (
                            <>
                              <option
                                key={i.itemId}
                                // value={JSON.stringify({
                                //   itemId: i.itemId,
                                //   item: i.item,
                                // })}
                                value={i.itemId}
                              >
                                {i.item}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    {/* <div className="d-flex col-3 p-0 m-0 gap-2">
                      <div>

                        <p className="font-monospace mb-1 pt-2">Product Value<span style={{ color: "red" }}>*</span></p>
                      </div>
                      <div>

                        <input
                          className="form-control"
                          type="text"
                          placeholder=""
                          aria-label="Inwarding Product Value"
                          onChange={(e) => {
                            setProdValue(e.target.value);
                          }}
                          value={prodValue}
                        />
                      </div>
                    </div> */}

                    {/* <div className="col-3 p-0">
                      {dataa === false && selectItemCode && (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handleMapItemSkuNo}
                        >
                          Map
                        </button>
                      )}
                    </div> */}
                    <div>
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleClickAdd}
                      >
                        Add
                      </button>
                      {/* inventory items and qty display  */}
                    </div>


                  </div>

                  <div className="col-3 p-4">
                    {/* {(selectItem.isSerial === "yes" ||
                      selectItem.isSerial === "no") && (
                        <p>
                          {" "}
                          <span className="fw-bold">isBarcodeAvailable</span> :{" "}
                          {selectItem?.isSerial}
                        </p>
                      )} */}
                    {selectItem.isSerial === "both" && (
                      <p>
                        {" "}
                        <div className="row">
                          <p className="col-6 font-monospace fw-bold mt-2">
                            isBarcodeAvailable:
                          </p>
                          <div className="col-2 form-check form-check-inline px-">
                            <label className=" font-monospace form-check-label w-100">
                              <input
                                type="radio"
                                name="isSerial"
                                value={stateIsSerialYes}
                                checked={stateIsSerialYes}
                                className="  form-check-input"
                                id="isSerialTypeYes"
                                onChange={(e) => {
                                  setIsSerialYes(
                                    e.target.checked ? true : false
                                  );
                                  setIsSerialNo(
                                    !e.target.checked ? true : false
                                  );
                                }}
                              />
                              yes
                            </label>
                          </div>
                          <div className="col-2 form-check form-check-inline">
                            <label className=" font-monospace form-check-label">
                              <input
                                type="radio"
                                name="isSerial"
                                value={stateIsSerialNo}
                                checked={stateIsSerialNo}
                                className=" form-check-input"
                                id="isSerialTypeNo"
                                onChange={(e) => {
                                  setIsSerialNo(
                                    e.target.checked ? true : false
                                  );
                                  setIsSerialYes(
                                    !e.target.checked ? true : false
                                  );
                                }}
                              />
                              no
                            </label>
                          </div>
                        </div>
                      </p>
                    )}
                  </div>
                  {(selectItem.isSerial === "yes" ||
                    stateIsSerialYes === true) && (

                      <div>
                        <div style={{ display: "flex", flexDirection: "row", columnGap: "6rem" }}>
                          <div className="d-flex gap-4">
                            <div>
                              <p>Barcode Type <span style={{ color: "red" }}>*</span> </p>
                            </div>
                            <div>
                              <label
                                htmlFor="barcodeTypePrePrinted"
                                className="d-flex gap-2"
                              >
                                <input
                                  type="radio"
                                  name="barcodeType"
                                  checked={selectItem?.barcodeType === "prePrinted"}
                                  disabled={
                                    !selectItem?.isSerial ||
                                    selectItem.isSerial === "no"
                                  }
                                  id="barcodeTypePrePrinted"
                                  className="form-check"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectItem({
                                        ...selectItem,
                                        barcodeType: "prePrinted",
                                      });
                                    }
                                  }}
                                />
                                Pre-printed
                              </label>
                            </div>
                            <div>
                              <label
                                htmlFor="barcodeTypeSticker"
                                className="d-flex gap-2"
                              >
                                <input
                                  type="radio"
                                  checked={selectItem?.barcodeType === "sticker"}
                                  disabled={
                                    !selectItem?.isSerial ||
                                    selectItem.isSerial === "no"
                                  }
                                  name="barcodeType"
                                  id="barcodeTypeSticker"
                                  className="form-check"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectItem({
                                        ...selectItem,
                                        barcodeType: "sticker",
                                      });
                                    }
                                  }}
                                />
                                Sticker
                              </label>
                            </div>
                          </div>
                          <div className="d-flex gap-4">
                            <div><p>Scan Type <span style={{ color: "red" }}>*</span></p></div>
                            <div>
                              <label
                                htmlFor="scanTypeSingle"
                                className="d-flex gap-2"
                              >
                                <input
                                  type="radio"
                                  checked={selectItem?.scanType === "single"}
                                  disabled={
                                    !selectItem?.isSerial ||
                                    selectItem.isSerial === "no"
                                  }
                                  name="scanType"
                                  id="scanTypeSingle"
                                  className="form-check"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectItem({
                                        ...selectItem,
                                        scanType: "single",
                                      });
                                    }
                                  }}
                                />
                                Single
                              </label>
                            </div>
                            <div>
                              <label
                                htmlFor="scanTypeRange"
                                className="d-flex gap-2"
                              >
                                <input
                                  type="radio"
                                  checked={selectItem?.scanType === "range"}
                                  disabled={
                                    !selectItem?.isSerial ||
                                    selectItem.isSerial === "no"
                                  }
                                  name="scanType"
                                  id="scanTypeRange"
                                  className="form-check"
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectItem({
                                        ...selectItem,
                                        scanType: "range",
                                      });
                                    }
                                  }}
                                />
                                Range
                              </label>
                            </div>
                          </div>
                          <div className="d-flex gap-4">
                            <div>
                              <p >Barcode prefix</p>
                            </div>
                            <div>
                              <input
                                type="text"
                                // className="col-2 form-control "
                                disabled={
                                  !selectItem?.isSerial ||
                                  selectItem.isSerial === "no"
                                }
                                name="barcodePrefix"
                                value={selectItem.prefix}
                                id="barcodePrefix"
                                placeholder="prefix"
                                onChange={(e) => {
                                  setSelectItem({
                                    ...selectItem,
                                    prefix: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex gap-4 pt-3">
                          <div>
                            <p>Serial Numbers</p>
                          </div>
                          <div>
                            <input
                              type="text"
                              name="fromScan"
                              disabled={
                                !selectItem?.isSerial ||
                                selectItem.isSerial === "no"
                              }
                              id="fromScanSerial"
                              // className="form-control col-5"
                              placeholder="from scan"
                              value={selectItem?.fromScan}
                              onChange={(e) => {
                                setSelectItem({
                                  ...selectItem,
                                  fromScan: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div>
                            <input
                              type="text"
                              name="toScan"
                              disabled={
                                !selectItem?.isSerial ||
                                selectItem.isSerial === "no" ||
                                selectItem?.scanType === "single"
                              }
                              id="toScanSerial"
                              placeholder="to scan"
                              // className="form-control col-5"
                              value={selectItem?.toScan}
                              onChange={(e) => {
                                setSelectItem({
                                  ...selectItem,
                                  toScan: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                  {/* quantity textbox */}
                  {/* {(selectItem.isSerial === "no" ||
                    stateIsSerialNo === true) && (
                    <div className="col-2 mt-4 p-0">
                      <input
                        className="form-control"
                        type="number"
                        min={0}
                        disabled={
                          !(
                            !selectItem?.isSerial ||
                            selectItem.isSerial === "no" ||
                            stateIsSerialNo === true
                          )
                        }
                        placeholder="quantity"
                        aria-label="quantity"
                        value={qty}
                        onChange={(e) => {
                          const qnt = e.target.value;
                          setQty(qnt);
                        }}
                      />
                    </div>
                  )} */}

                  {/* Add items to inward inventory button */}

                </div>

                <div className="row mt-2 p-2 col-6">
                  <table className="table  table-striped m-1 p-0 border">
                    {/* <thead>
                       <tr>
                         <th scope="col">Item</th>
                         <th scope="col">FromScan</th>
                         <th scope="col">ToScan</th>
                         <th scope="col">Quantity</th>
                       </tr>
                     </thead> */}
                    <tbody>
                      {invList.map((inv) => {
                        // console.log({ invList });
                        return (
                          <tr key={inv.itemId} className="mt-1 p- col-6">
                            <td>{inv?.item}</td>
                            <td>
                              {inv.isSerial === "yes" || stateIsSerialYes === true ? (
                                <>{inv.fromScan}</>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>
                              {inv.isSerial === "yes" || stateIsSerialYes === true ? (
                                <>{inv.toScan}</>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td>

                              {((inv.isSerial === "no" || stateIsSerialNo === true) && inv.isSerial !== "yes") && (
                                <>
                                  <div key={inv.itemSkuBarcode && inv.itemId && inv.item}>
                                    <button
                                      style={{ border: "none", backgroundColor: "rgba(145, 117, 103, 0.05)" }}
                                      onClick={() => decreaseQuantity(invList.indexOf(inv))}
                                    >
                                      <GrSubtractCircle size="1.5rem" />
                                    </button>

                                    <input
                                      className='w-50'
                                      type='number'
                                      inputMode="text"
                                      name='outwardQnt'
                                      min={1}
                                      value={inv.qnt}
                                      onChange={(e) => handleInwardQntChange(e, inv)}
                                    />

                                    <button
                                      style={{ border: "none", backgroundColor: "rgba(145, 117, 103, 0.05)" }}
                                      onClick={() => increaseQuantity(invList.indexOf(inv))}
                                    >
                                      <IoIosAddCircleOutline size="1.5rem" />
                                    </button>
                                  </div>
                                </>
                              )}

                            </td>
                            {/* <td>
                              {((inv.isSerial === "no" || stateIsSerialNo === true) && inv.isSerial !== "yes") && (
                                <input
                                  key={inv.itemSkuBarcode && inv.itemId}
                                  className='w-50'
                                  type='number'
                                  name='outwardQnt'
                                  min={1}

                                  // value={this.state.outwardQnt}
                                  value={inv.qnt}
                                  onChange={(e) => {

                                    handleInwardQntChange(e, inv)
                                  }}
                                />

                              )}
                            </td> */}
                            {/* <span
                                 type="button"
                                 className="btn btn-danger m-0 px-3 btn-sm "
                                 onClick={() => handleRemoveItem(inv)}
                               >
                                 X
                               </span> */}
                            <td>
                              <span
                                className="badge badge-danger ml-2 mr-1 mt-2 "
                                onClick={() => handleRemoveItem(inv)}
                              >
                                X
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/* Save button */}
                

                <div className="col m-0 mt-1 p-0">
                  <button
                    type="button"
                    className="btn btn-primary m-0 p-0 px-3 py-2  "
                    disabled={itemList?.length === 0 ? true : false}
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
                
              </>
            )}

            {/* Server response screen */}
            {res?.invInwards && (
              <>
                <iframe
                  id="ifmcontentstoprint"
                  title="toPrint"
                  style={{
                    height: "0px",
                    width: "0px",
                    position: "absolute",
                  }}
                ></iframe>
                <div className="col-12 mt-1">
                  <div className="card" id="labelContentToPrint">
                    <div className="card-body">
                      {/* <p className='fs-5'>{res.invInwards.invTrxId}</p> */}
                      <p className="text-end font-monospace">
                        {res.invInwards.invDateTime.slice(0, 10)}{" "}
                        {res.invInwards.invDateTime.slice(11, 16)}{" "}
                      </p>
                      <p className="d-flex justify-content-between">
                        <span>
                          <span className="font-monospace">Store: </span>{" "}
                          {
                            storesList.filter(
                              (store) =>
                                store.storeId === res?.invInwards?.storeId
                            )[0]?.store
                          }
                        </span>
                        <span>
                          {" "}
                          <span className="font-monospace">Hub: </span>{" "}
                          {hubsList.filter((hub) => hub.hubId === hubId)[0].hub}
                        </span>
                      </p>

                      <p>
                        {" "}
                        <span className="font-monospace">Remarks: </span>{" "}
                        <span>{res.invInwards.invDesc}</span>
                      </p>
                      {/* <p className='fs-5'>Item List</p> */}
                      <div className="col card p-2">
                        <table>
                          <thead>
                            <tr>
                              <td className="font-monospace">Item</td>
                              <td className="font-monospace">Quantity</td>
                            </tr>
                          </thead>
                          <tbody>
                            {res?.invInwardsItems.map((i) => (
                              <tr key={i.invTrxItemsId}>
                                <td>
                                  {
                                    itemList.filter(
                                      (item) => item.itemId === i.itemId
                                    )[0]?.item
                                  }
                                </td>
                                <td>{i?.qnt}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-between">
                    <div className="col mt-3 p-1">
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={(e) => {
                          console.log("print the summary");
                          onClickPrintLabel(e);
                        }}
                      >
                        Print
                      </button>
                    </div>
                    <div className="col mt-3 p-1">
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={() => {
                          setRes([]);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* {mode === true && <ItemMaster />} */}
      {stateIsOverrideYes === true && (
        <UpdateSKUCode
          itemId={selectItem}
          itemCode={selectItemCode}
          handleSave={handleMapItemNo}
        // key={selectItemId}
        //  onItemChange = {handleItemChange}
        />
      )}
    </>
  );
};

export default InwardInventory;

