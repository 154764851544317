import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import $ from "jquery";
// import "bootstrap";
import {
  createPrvModules,
  savePrvModules,
  deletePrvModules,
} from "./../../../services/appFeatures/prvModulesServices";

class ModalModule extends FormLib {
  state = {
    data: {
      moduleId: "",
      divisionId: "",
      module: "",
      status: "active",
      sort: "",

    },
    errors: {},

    prvDivs: [],
    curDivision: {},
  };

  schema = {
    moduleId: Joi.optional().label("moduleId"),
    divisionId: Joi.string().required().label("Division*"),
    module: Joi.string().required().label("Module*"),
    status: Joi.string().required().label("Status*"),
    sort: Joi.string().required().label("Sort*"),
  };

  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createPrvModules(this.state.data);
      this.resetData(this.state.curDivision);
      this.props.onLoadPrvModules(this.state.curDivision.divisionId);
    }
    if (this.state.btnValue === "edit") {
      res = await savePrvModules(this.state.data);
      this.props.onLoadPrvModules(this.state.curDivision.divisionId, false);
    }
    if (this.state.btnValue === "delete") {
      res = await deletePrvModules(this.state.data);
      $("#curModuleButton").click();
      this.resetData(this.state.curDivision);
      this.props.onLoadPrvModules(this.state.curDivision.divisionId);
    }

    toast.toastMsg(res);
    // if (res.data && res.data.success && res.data.success === true) {
    //   toast.toastSuccess(res.data.msg);
    // } else {
    //   toast.toastError(
    //     (res.data && res.data.msg) || "Error Happened in getting Error Msg"
    //   );
    // }
  }

  componentDidMount() {
    this.setPrvDivs(this.props.prvDivs);
    this.setCurDivision(this.props.curDivision);
    this.setCurModule(this.props.curDivision, this.props.curModule);
  }
  setPrvDivs(prvDivs) {
    this.setState({ prvDivs: prvDivs });
  }
  setCurDivision(d) {
    this.setState({ curDivision: d });
  }
  setCurModule(d, m) {
    const mod = { ...m };
    delete mod.seq;
    if (m.moduleId && m.moduleId !== "") {
      this.setState({ data: mod });
    } else {
      this.resetData(d);
    }
  }

  resetData(d) {
    this.setState({
      data: {
        divisionId: d.divisionId || "",
        moduleId: "",
        module: "",
        status: "active",
        sort: "",
      },
    });
  }

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  sort = [
    { sortKey: "asc", sort: "ASC" },
    { sortKey: "dsc", sort: "DSC" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;

    const prvDivs = this.state.prvDivs;

    return (
      <div className="container-fluid">
        <div className="modal fade" id="myModuleModel">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Prv Modules</h4>
                <button
                  type="button"
                  className="close"
                  id="curModuleButton"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  {this.rTBH("ModuleId", "moduleId")}
                  {this.rSB("Division", "divisionId", prvDivs, {
                    value: "divisionId",
                    label: "division",
                  })}
                  {this.rTB("Module", "module")}
                  <br />
                  <div className="form-row">
                    {this.rRBL("Status", "status", this.status, this.stsConf, "col-12")}
                    {this.rRBL("Sort", "sort", this.sort, this.stsConf, "col-12")}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {this.state.data.moduleId === "" &&
                  this.rBtnSP("Create", "create")}
                {this.state.data.moduleId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {this.rBtnSD("Delete", "delete")}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalModule;
