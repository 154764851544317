import React from "react";
import FormLib from "../../../../_common/FormLib";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Joi from "joi-browser";
import TicketActionsInfo from "../../TicketActionsInfo";
import TicketVehicleInfo from "../../TicketVehicleInfo";


class SelectedTickets extends FormLib {
    state = {
        data: {
            hubId: ""
        },
        errors: {},
        ticketData: [],
        curticketDetails: {},
        curblockDetails: {},
        curAction: "",
        curMultiblockDetails: {},
        curMultiticketDetails: [],
    };

    // includeVehicleInfo = [{ sortKey: "yes", sort: "Create Trip" }];

    // schema = {
    //     includeVehicleInfo: Joi.optional().label("includeVehicleInfo"),
    // };


    componentDidMount() {
        this.loadInit();
        this.setState({ ticketData: this.props.ticketMultiDetails });

    }

    loadInit() {
        // console.log("ffffffffffff", this.props.bulkAssign)
        const curticketDetails = this.props.ticketDetails;
        const curblockDetails = this.props.blockDetails;
        const curAction = this.props.curAction;
        const curMultiticketDetails = this.props.ticketMultiDetails;
        this.setState({ curMultiticketDetails });



        const curMultiblockDetails = this.props.blockMultiDetails;
        const bulkAssign = this.props.bulkAssign;
        this.setState({ curticketDetails });
        this.setState({ curblockDetails });
        this.setState({ curAction });
        this.setState({ bulkAssign });
        this.setState({ curMultiblockDetails });
    }




    onDragEnd = (result) => {
        // console.log(result.destination.index);
        // console.log(result.draggableId);
        // console.log(result.source.index);
        if (!result.destination) {
            return;
        }

        const ticket = [...this.state.curMultiticketDetails]
        const [reorderedItem] = ticket.splice(result.source.index, 1);
        ticket.splice(result.destination.index, 0, reorderedItem);

        this.setState({ curMultiticketDetails: ticket });
        // console.log("this.state.ticketData",this.state.ticketData)
    };
    handleSendData = () => {
        // alert("call")
        const { sendDataToParent } = this.props;
        // const { inputValue } = this.state;
        const inputValue = this.state.ticketData
        // Send data to the parent component
        sendDataToParent(inputValue);
        this.props.closeModal()
    };

    render() {
        // console.log(this.state.curMultiticketDetails)
        return (
            <React.Fragment>
                <div>
                    <div >
                        <div className="row">
                            <div className="col">
                                <b className="mt-5">&nbsp;&nbsp;Selected Ticket List :</b>

                            </div>
                        </div>
                    </div>
                    <div>

                        <div className="m-4">
                            {this.props.bulkAssign === "bulk" && (
                                <DragDropContext onDragEnd={this.onDragEnd}>
                                    <Droppable droppableId="ticketData">
                                        {(provided) => (
                                            <div
                                                style={{ maxWidth: "20rem" }}
                                                ref={provided.innerRef} {...provided.droppableProps} >
                                                {this.state.curMultiticketDetails.map((x, index) => (

                                                    <Draggable key={x.processId} draggableId={x.processId.toString()} index={index}>
                                                        {(provided) => (
                                                            <div className='d-flex col-12'>
                                                                <div
                                                                    className='card bg-primary rounded-pill cursorPointor border border-primary text-light col-2 mr-1'
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    <div style={{ padding: "0.8rem 0rem 0.8rem 0rem", textAlign: "center" }}>
                                                                        <span>{index+1}</span>
                                                                    </div>
                                                                </div>

                                                                <div
                                                                    className='card bg-primary rounded-pill cursorPointer border border-primary text-light col-10'
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    <div style={{ padding: "0.8rem 0rem 0.8rem 0rem", textAlign: "center" }}>
                                                                        <span>{x.ticketNo}</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            )}
                            {this.props.bulkAssign === "notBulk" && (
                                <div
                                    className='card bg-primary rounded-pill cursorPointor border border-primary text-light'
                                >
                                    <div style={{ padding: "0.8rem 0rem 0.8rem 0rem", textAlign: "center" }}>{this.state.curticketDetails.ticketNo}</div>
                                </div>
                            )}

                        </div>
                        <div className="">
                            {this.rCBHL(
                                "includeVehicleInfo",
                                this.includeVehicleInfo,
                                {
                                    value: "sortKey",
                                    label: "sort",
                                }
                            )}
                            {this.state.data.includeVehicleInfo === "yes" && (
                                <div
                                    className="card-body  bg-light"
                                    style={{
                                        maxHeight: "1100px",
                                        marginLeft: "-18px",
                                        marginTop: "-36px",
                                    }}
                                >
                                    <TicketVehicleInfo
                                        ticketDetails={this.state.curticketDetails}
                                        blockDetails={this.state.curblockDetails}
                                        curAction={this.state.curAction}
                                        // hidePage={this.state.hidePage}
                                        bulkAssign={this.state.bulkAssign}
                                        ticketMultiDetails={
                                            this.state.curMultiticketDetails
                                        }
                                        blockMultiDetails={this.state.curMultiblockDetails}
                                        key={
                                            "fe_" + this.state.curblockDetails.ticketBlockId
                                        }
                                    />
                                </div>
                            )}
                        </div>


                    </div>
                </div>
            </React.Fragment>
        )
    }

}
export default SelectedTickets;