import React, { Component } from "react";
import _auth from "../../../services/_authService";
import LocationTitle from "../../_subhead/LocationTitle";

class Profile extends Component {
    state = {};
    componentDidMount() {
        const user = _auth.getCurrentUser();
        this.setState({ user });
    }
    render() {
        const { subHeading } = this.props;
        const user = this.state.user;
        return (
            <React.Fragment>
                <LocationTitle />
                <div className="pageContent">
                    <div className="container-fluid">
                        <br />
                        {user && (

                            <div className="card">
                                <div className="card-body col-5" style={{ "background-color": "#ebdacd" }}>
                                    <span style={{ color: 'Green', fontSize: '2rem', paddingBottom: "15%" }}>User Info ::</span>
                                    <h1><b>Code:</b> {user.code}</h1>
                                    <h1><b>UserName:</b> {user.username}| {user.firstname} | {user.lastname}</h1>
                                    <h1><b>Designation:</b> {user.designation}</h1>
                                    <h1><b>playerId:</b> {user.playerId}</h1>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default Profile;
