import React from "react";
import FormLib from "../../../_common/FormLib";
import LocationTitle from "../../../_subhead/LocationTitle";
import { getTeams } from "../../../../services/players/teamServices";
import Pagination from "../../../_common/Pagination";
import { getAllFlows } from "../../../../services/coreItAdmin/flowsService";
import { getAllProductsList } from "../../../../services/products/productServices";
import { getQAList, getQueAns } from "../../../../services/coreItAdmin/QA-service";
import toast from "../../../../services/_toastService";
import $ from "jquery";
import Joi from "joi-browser";



export default class QuestionAns extends FormLib {
    state = {
        loading: true,
        data: {
            productId: "",
            sync: "syn",
            remarks: "remark",
            question: "",
            options: "",
            status: "active",
        },
        pageLinks: {
            pageNo: 1,
            perPage: 0,
            itemsCount: 0,
            lastPage: 0,
        },
        errors: {},
        curFlow: { flowId: "" },
        curTeams: { teamId: "" },
        curProducts: { productId: "" },
        curQueType: { qaId: "" },
        flows: [],
        teams: [],
        products: [],
        QAList: [],
        formMode: "create",
        isRadioSelected: true,
    }

    qaType = [
        { "qaId": "ticketQueBasic", "qaType": "Basic Question" },
        { "qaId": "ticketQueTech", "qaType": "Technical Questions" },
        { "qaId": "ticketQuePhy", "qaType": "Physical Questions" },
        { "qaId": "ticketQuePop", "qaType": "Popup Questions" }
    ]

    status = [
        { sortKey: "active", sort: "active" },
        { sortKey: "inactive", sort: "Inactive" },
    ];
    stsConf = {
        value: "sortKey",
        label: "sort",
    };

    options1 = [
        { respPositive: "yes", response: "Positive" },
        { respNegative: "no", response: "Negative" },
        { respNeutral: "neutral", response: "Neutral" },
    ];
    options2 = [
        { respPositive: "yes", sort: "" },
        { respNegative: "no", sort: "" },
        { respNeutral: "neutral", sort: "" },
    ];
    options3 = [
        { respPositive: "yes", sort: "" },
        { respNegative: "no", sort: "" },
        { respNeutral: "neutral", sort: "" },
    ];


    elementType = [
        { "elemType": "radio", "typeName": "Radio " },
        // { "typeId": "checkBox", "typeName": "Check Box" },
        // { "typeId": "selectBox", "typeName": "Select Box" },
        { "elemType": "textBox", "typeName": "Text Box" },
        // { "typeId": "textArea", "typeName": "Text Area" }
    ]


    schema = {
        productId: Joi.optional().label("productId"),
        qaId: Joi.required().label("qaId"),
        flowId: Joi.required().label("flowId"),
        question: Joi.string().required().label("question"),
        remarks: Joi.string().optional().label("remarks"),
        sync: Joi.any().optional().label("sync"),
        status: Joi.string().required().label("status"),
        elemType: Joi.string().required().label("elemType"),
        option1: Joi.string().optional().label("option1"),
        option2: Joi.string().optional().label("option2"),
        option3: Joi.string().optional().label("option3"),
    };

    componentDidMount() {
        this.loadTeams();
        this.loadFlows();
        this.loadProducts();
        // this.loadQAList();
    }
    loadTeams = async () => {
        this.setState({ loading: true });
        const res = await getTeams();
        if (res.data.success === true) {
            this.setState({ teams: res.data.data.teams });
        } else {
            console.log("Error while loading teams");
        }
    }

    loadFlows = async () => {
        this.setState({ loading: true });
        const res = await getAllFlows();
        if (res.data.success === true) {
            this.setState({ flows: res.data.data.flows });
        } else {
            toast.toastError(res.data.msg);
        }
        this.setState({ loading: false });
    }

    loadProducts = async () => {
        this.setState({ loading: true });
        const res = await getAllProductsList();
        if (res.data.success === true) {
            this.setState({ products: res.data.data.products });
        } else {
            toast.toastError(res.data.msg);
        }
        this.setState({ loading: false });
    }



    // loadQAList = async () => {
    //     this.setState({ loading: true });
    //     const res = await getQAList();
    //     if (res.data.success === true) {
    //         this.setState({ QAList: res.data.data.faq });
    //     } else {
    //         toast.toastError(res.data.msg);
    //     }
    //     this.setState({ loading: false });
    // }

    handleCardClick = (r) => {
        // alert(JSON.stringify(r));
    }


    async doSubmit() {
        // console.log(JSON.stringify(this.state.data));
        let res;
        if (this.state.btnValue === "create") {
            alert("create..");
        }

    }

    searchQuestionaries = async () => {
        let flowId = this.state.curFlow;
        let prodId = this.state.curProducts;
        let queType = this.state.curQueType;
        // console.log("flowId...."+JSON.stringify(this.state.curFlow));
        // console.log("productId...."+JSON.stringify(this.state.curProducts));
        // console.log("queId...."+JSON.stringify(this.state.curQueType));

        this.setState({ loading: true });
        const res = await getQueAns(queType, prodId, flowId);
        if (this.state.curQueType === "ticketQueBasic") {

            if (res.data.success && res.data.data.ticketQueBasic.length != 0) {
                toast.toastSuccess(res.data.msg);
                this.setState({ QAList: res.data.data.ticketQueBasic });
            }
            else {
                toast.toastInfo("No data found!!!");
            }
        }
        if (this.state.curQueType === "ticketQueTech") {

            if (res.data.success && res.data.data.ticketQueTechRetailer.length != 0) {
                toast.toastSuccess(res.data.msg);
                this.setState({ QAList: res.data.data.ticketQueTechRetailer });
            }
            else {
                toast.toastInfo("No data found!!!");
            }
        }
        if (this.state.curQueType === "ticketQuePhy") {

            if (res.data.success && res.data.data.ticketQuePhy.length != 0) {
                toast.toastSuccess(res.data.msg);
                this.setState({ QAList: res.data.data.ticketQuePhy });
            }
            else {
                toast.toastInfo("No data found!!!");
            }
        }
        if (this.state.curQueType === "ticketQuePop") {

            if (res.data.success && res.data.data.ticketQuePop.length != 0) {
                toast.toastSuccess(res.data.msg);
                this.setState({ QAList: res.data.data.ticketQuePop });
            }
            else {
                toast.toastInfo("No data found!!!");
            }
        }
        // console.log("QAList....................."+JSON.stringify(this.state.QAList));
        this.setState({ loading: false });


    }
    handleFlowChange = (e) => {
        this.setState({ curFlow: e.target.value });
    }
    handleProductChange = (e) => {
        this.setState({ curProducts: e.target.value });
    }
    handleQueTypeChange = (e) => {
        this.setState({ curQueType: e.target.value });
    }

    localHandleChange = (e) => {

        if (e.target.id === "flowId") {
            this.handleFlowChange(e);
        }
        if (e.target.id === "productId") {
            this.handleProductChange(e);
        }
        if (e.target.id === "qaId") {
            this.handleQueTypeChange(e);
        }
        // alert(JSON.stringify(e.target.value));

    };
    onValueChange(e) {
        alert(JSON.stringify(e.target.value));
        // this.setState({
        //   selectedOption: event.target.value
        // });
    }

    render() {
        const qalist = this.state.QAList;

        return (
            <React.Fragment>
                <LocationTitle subHeading="Questions and Answers" />
                <div className="pageContent">
                    <div className="container-fluid">
                        <div className="card mb-4">
                            <div className="card-body pb-0">
                                <form >
                                    <div className="form-row form-group">
                                        <div className="col-4 mt-2">
                                            {this.rSB("Team", "teamId", this.state.teams, { value: "teamId", label: "name" })}
                                        </div>
                                        <div className="col-2">
                                            <span className="h6 pull-right"> {this.rCBHL("11212", [{ label: "Enable Retailer", value: "ddddddddddddddddd" }])}</span>
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-sm btn-outline-primary rounded-circle pull-right" onClick={() => alert("ADD new Qiestion")}>
                                                <i className="fa fa-plus " aria-hidden="true" />
                                            </button>
                                        </div>
                                        <div className="col-3">
                                            {this.rSB("Type", "qaId", this.qaType, { value: "qaId", label: "qaType" })}
                                        </div>
                                        <div className="col-3">
                                            {this.rSB("Product", "productId", this.state.products, { value: "productId", label: "product" })}

                                        </div>
                                        <div className="col-3">
                                            {this.rSB("Flow", "flowId", this.state.flows, { value: "flowId", label: "flowName" })}
                                        </div>
                                        <div className="col-3">
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-outline-info pull-left"
                                                title=" Search Questionaries "
                                                // disabled={this.state.data.hubSearch === ""}
                                                onClick={() => this.searchQuestionaries()}
                                            >
                                                <i className={"fa fa-search"} />
                                            </button>
                                            {/* {this.rBtnSP("Search", "create")} */}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                {qalist.map((r) => {

                                    return (
                                        <div className="card mb-1" key={r.questionId} style={{ "backgroundColor": "AliceBlue" }}>
                                            <div className="row no-gutters">
                                                <div className="col-1 pl-3">
                                                    <span className="m-2">
                                                        {/* {this.rCBHL("11212", [{ label: "", value: "selectedQA" }])} */}
                                                    </span>
                                                </div>
                                                <div className="col-11 border-left curTasks cursorPointor" onClick={() => this.handleCardClick(r)}>
                                                    <div className="card-body">
                                                        <span className="h6">{r.question}</span> <br />
                                                        {/* <span className="h6">{r.options}</span> */}
                                                        <span className="h6 pull-right">{r.elemType}</span>
                                                    </div>
                                                </div>
                                            </div>(
                                            {/* <span> {JSON.stringify(qalist)}</span> */}
                                        </div>

                                    )
                                })}
                            </div>
                            {this.state.formMode !== "view" && (

                                <div className="col-6">
                                    <div className="card pt-4">
                                        <div className="card-body">
                                            <form className="form-row">
                                                {this.rSB("Element Type", "elemType", this.elementType, { value: "elemType", label: "typeName" }, " col-12 ")}
                                                {this.rTB("Question", "question", "col-12")}

                                                <span className="col-3 h4 text-center">
                                                    <span className=" h6 badge badge-secondary">
                                                        Options
                                                    </span>
                                                </span>
                                                <span className="col-3 text-center h4">
                                                    <span className="badge badge-secondary">
                                                        <i className="fa fa-plus" aria-hidden="true" />
                                                    </span>
                                                </span>
                                                <span className="col-3 text-center h4">
                                                    <span className="badge badge-secondary">
                                                        <i className="fa fa-minus" aria-hidden="true" />
                                                    </span>
                                                </span>
                                                <span className="col-3 text-center h4">
                                                    <span className="badge badge-secondary">N</span>
                                                </span>
                                                {/* ***************************1st Line****************************** */}
                                                <div className="form-row col-12">

                                                    {/* <input type="text" disabled={this.state.isRadioSelected} /> */}
                                                    {this.rTB("Options", "option1", " col-3")}
                                                    <span className="col-3 text-center pl-4">
                                                        {/* {this.rRBL(
                                                    "Status *",
                                                    "status",
                                                    this.options1,
                                                )} */}
                                                        <label className="form-check-label">
                                                            <input type="radio" value="Yes" id="Yes" onChange={this.onValueChange} className="form-check-input cursorPointor" name="response" />
                                                        </label>
                                                    </span>
                                                    <span className="col-3 text-center pl-4">
                                                        <label className="form-check-label">
                                                            <input type="radio" value="No" id="No" onChange={this.onValueChange} className="form-check-input cursorPointor" name="response" />
                                                        </label>
                                                    </span>
                                                    <span className="col-3 text-center pl-4">
                                                        <label className="form-check-label">
                                                            <input type="radio" value="Neutral" id="Neutral" onChange={this.onValueChange} className="form-check-input cursorPointor" name="response" />
                                                        </label>
                                                    </span>
                                                </div>
                                                {/* ***************************2nd Line****************************** */}
                                                <div className="form-row col-12">
                                                    {this.rTB("Options", "option2", " col-3")}
                                                    <span className="col-3 text-center pl-4">
                                                        <label className="form-check-label">
                                                            <input type="radio" value="Yes" id="Yes" onChange={this.onValueChange} className="form-check-input cursorPointor" name="response1" />
                                                        </label>
                                                    </span>
                                                    <span className="col-3 text-center pl-4">
                                                        <label className="form-check-label">
                                                            <input type="radio" value="No" id="No" onChange={this.onValueChange} className="form-check-input cursorPointor" name="response1" />
                                                        </label>
                                                    </span>
                                                    <span className="col-3 text-center pl-4">
                                                        <label className="form-check-label">
                                                            <input type="radio" value="Neutral" id="Neutral" onChange={this.onValueChange} className="form-check-input cursorPointor" name="response1" />
                                                        </label>
                                                    </span>
                                                </div>
                                                {/* ***************************3rd Line****************************** */}
                                                <div className="form-row col-12">
                                                    {this.rTB("Options", "option3", " col-3")}
                                                    <span className="col-3 text-center pl-4">
                                                        <label className="form-check-label">
                                                            <input type="radio" value="Yes" id="Yes" onChange={this.onValueChange} className="form-check-input cursorPointor" name="response2" />
                                                        </label>
                                                    </span>
                                                    <span className="col-3 text-center pl-4">
                                                        <label className="form-check-label">
                                                            <input type="radio" value="No" id="No" onChange={this.onValueChange} className="form-check-input cursorPointor" name="response2" />
                                                        </label>
                                                    </span>
                                                    <span className="col-3 text-center pl-4">
                                                        <label className="form-check-label">
                                                            <input type="radio" value="Neutral" id="Neutral" onChange={this.onValueChange} className="form-check-input cursorPointor" name="response2" />
                                                        </label>
                                                    </span>
                                                </div>
                                                <span className=" mt-4 mb-6">
                                                    {this.rRBL(
                                                        "Status *",
                                                        "status",
                                                        this.status,
                                                        this.stsConf
                                                    )}

                                                </span>
                                            </form>
                                            <br /><br />
                                            {this.state.formMode === "create" &&
                                                this.rBtnSP("Create", "create")}
                                            {this.state.formMode === "edit" && (
                                                <>{this.rBtnSS("Edit", "edit")}</>
                                            )}
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-warning pull-right"
                                                // onClick={() => this.resetData()}
                                                title="Reset"
                                            >
                                                <i className={"fa fa-unlink"} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

