import swal from "@sweetalert/with-react";
import React from "react";
import Table from "../../../_common/Table";

const UpdateShipmentNoModal = ({
  shipmentDetails,
  handleSave,
  shipmentInputId,
  modalId,
}) => {
  const [shipmentNo, setShipmentNo] = React.useState("");
  const closeButtonRef = React.useRef();

  const moreInfo = shipmentDetails?.moreInfo
    ? JSON.parse(shipmentDetails.moreInfo)
    : {};

  const { ticketBlockId } = shipmentDetails;

  let productIdList = [];

  const {
    boxActualWeight,
    boxBreadth,
    boxHeight,
    boxLength,
    isFragile,
    boxVolumetricWeight,
    paymentMode,
    ...rest
  } = moreInfo;

  const productsCount = Object.keys(rest).filter((r) =>
    r.includes("zzlk")
  ).length;

  if (ticketBlockId in moreInfo) {
    productIdList = Object.keys(moreInfo[ticketBlockId]);
  }

  if (productIdList.length) {
    var {
      length,
      breadth,
      height,
      volumetricWt,
      actualWt,
      productCode,
      productName,
      identificationNo,
    } = moreInfo[ticketBlockId][productIdList[0]];
  }

  const handleClickSave = async (e) => {
    if (shipmentNo === "") {
      swal({ icon: "error", title: "shipment no cannot be empty" });
      return null;
    }

    const resp = await handleSave(shipmentNo);
    if (closeButtonRef.current && resp) closeButtonRef.current.click();
  };

  const handleClickClose = (e) => {
    setShipmentNo("");
  };


  // console.log({shipmentDetails, productIdList: productIdList, volumetricWt, actualWt, productCode, paymentMode, moreInfo, ticketBlockId})

  return (
    <div
      className="modal fade"
      id={modalId}
      data-bs-keyboard="false"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{shipmentDetails?.ticketNo}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            {/* <p style={{overflowWrap: "break-word"}}>{JSON.stringify(shipmentDetails)}</p> */}
            <div className="container">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title fs-5">Linehaul details</h4>
                  <p className="d-flex justify-content-between mb-0 pb-0">
                    <span>{"Linehaul Ref No"}</span>
                    <span>{shipmentDetails.linehaulSeq}</span>
                  </p>
                  <p className="d-flex justify-content-between mb-0 pb-0">
                    <span>{"Transportation Mode"}</span>
                    <span>{shipmentDetails.transMode}</span>
                  </p>
                  <p className="d-flex justify-content-between mb-0 pb-0">
                    <span>{"Logistics Provider"}</span>
                    <span>{(shipmentDetails.logisticsProvider)}</span>
                  </p>
                  {paymentMode && (
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Payment Mode"}</span>
                      <span>{paymentMode}</span>
                    </p>
                  )}
                  <p className="d-flex justify-content-between mb-0 pb-0">
                    <span>{"Destination Type"}</span>
                    <span>{shipmentDetails.destination}</span>
                  </p>
                  {shipmentDetails.vehicleNo && (
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Vehicle Number"}</span>
                      <span>{shipmentDetails.vehicleNo}</span>
                    </p>
                  )}

                  {
                    <h5 className="card-subtitle mb-2 fs-5 mt-2">
                      Box details
                    </h5>
                  }
                  <p className="d-flex justify-content-between mb-0 pb-0">
                    <span>{"Products count"}</span>
                    <span>{productsCount ? productsCount : ""}</span>
                  </p>
                  {
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Is Fragile"}</span>
                      <span>{isFragile ? isFragile : "NA"}</span>
                    </p>
                  }
                  {boxLength ? (
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Box Length (cm)"}</span>
                      <span>{boxLength}</span>
                    </p>
                  ) : null}
                  {boxBreadth ? (
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Box Breadth (cm)"}</span>
                      <span>{boxBreadth}</span>
                    </p>
                  ) : null}
                  {boxHeight ? (
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Box Height (cm)"}</span>
                      <span>{boxHeight}</span>
                    </p>
                  ) : null}

                  {
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Actual Weight (grams)"}</span>
                      <span>{boxActualWeight ? boxActualWeight : "NA"}</span>
                    </p>
                  }
                  {
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Volumetric Weight (grams)"}</span>
                      <span>
                        {boxLength && boxBreadth && boxHeight
                          ? (boxLength * boxBreadth * boxHeight) / 5
                          : "NA"}
                      </span>
                    </p>
                  }

                  {productIdList.length > 0 && (
                    <h5 className="card-subtitle mb-2 fs-5 mt-2">
                      Product details
                    </h5>
                  )}
                  {productCode ? (
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Product Code"}</span>
                      <span>{productCode}</span>
                    </p>
                  ) : null}
                  {productName ? (
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Product Name"}</span>
                      <span>{productName}</span>
                    </p>
                  ) : null}
                  {identificationNo ? (
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Product Id"}</span>
                      <span>{identificationNo}</span>
                    </p>
                  ) : null}
                  {length ? (
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Product Length (cm)"}</span>
                      <span>{length}</span>
                    </p>
                  ) : null}
                  {breadth ? (
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Product Breadth (cm)"}</span>
                      <span>{breadth}</span>
                    </p>
                  ) : null}
                  {height ? (
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Product Height (cm)"}</span>
                      <span>{height}</span>
                    </p>
                  ) : null}
                  {volumetricWt ? (
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Volumetric Weight (grams)"}</span>
                      <span>{volumetricWt}</span>
                    </p>
                  ) : null}
                  {actualWt ? (
                    <p className="d-flex justify-content-between mb-0 pb-0">
                      <span>{"Actual Weight (grams)"}</span>
                      <span>{actualWt}</span>
                    </p>
                  ) : null}


                  <h5 className="card-subtitle mb-2 fs-5 mt-2">
                    Source details
                  </h5>
                  <p className="d-flex justify-content-between mb-0 pb-0">
                    <span>{"Source Contact Person"}</span>
                    <span>{shipmentDetails.srcContactPerson}</span>
                  </p>
                  <p className="d-flex justify-content-between mb-0 pb-0">
                    <span>{"Source Contact No"}</span>
                    <span>{shipmentDetails.srcContact1}</span>
                  </p>
                  {shipmentDetails?.srcContact2 &&
                    shipmentDetails.srcContact2 !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Source Contact No Alternate"}</span>
                        <span>{shipmentDetails.srcContact2}</span>
                      </p>
                    )}
                  {shipmentDetails?.srcEmailId &&
                    shipmentDetails.srcEmailId !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Source Email Id"}</span>
                        <span>{shipmentDetails.srcEmailId}</span>
                      </p>
                    )}
                  {shipmentDetails?.srcEmailIdAlt &&
                    shipmentDetails.srcEmailIdAlt !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Source Email Id Alternate"}</span>
                        <span>{shipmentDetails.srcEmailIdAlt}</span>
                      </p>
                    )}
                  <p className="d-flex justify-content-between mb-0 pb-0">
                    <span>{"Address 1: "}</span>
                    <span>{shipmentDetails.srcAdd1}</span>
                  </p>
                  {shipmentDetails?.srcAdd2 &&
                    shipmentDetails.srcAdd2 !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Address 2: "}</span>
                        <span>{shipmentDetails.srcAdd2}</span>
                      </p>
                    )}
                  <p className="d-flex justify-content-between mb-0 pb-0">
                    <span>{"Source Pincode"}</span>
                    <span>{shipmentDetails.srcPincode}</span>
                  </p>
                  {shipmentDetails?.srcCity &&
                    shipmentDetails.srcCity !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Source City"}</span>
                        <span>{shipmentDetails.srcCity}</span>
                      </p>
                    )}
                  {shipmentDetails?.srcState &&
                    shipmentDetails.srcState !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Source State"}</span>
                        <span>{shipmentDetails.srcState}</span>
                      </p>
                    )}
                  {shipmentDetails?.srcLocation &&
                    shipmentDetails.srcLocation !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Source Location"}</span>
                        <span>{shipmentDetails.srcLocation}</span>
                      </p>
                    )}
                  {shipmentDetails?.srcLandmark &&
                    shipmentDetails.srcLandmark !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Source Landmark"}</span>
                        <span>{shipmentDetails.srcLandmark}</span>
                      </p>
                    )}

                  <h5 className="card-subtitle mb-2 fs-5 mt-2">
                    Destination details
                  </h5>
                  <p className="d-flex justify-content-between mb-0 pb-0">
                    <span>{"Destination Contact Person"}</span>
                    <span>{shipmentDetails.dstContactPerson}</span>
                  </p>
                  <p className="d-flex justify-content-between mb-0 pb-0">
                    <span>{"Destination Contact No"}</span>
                    <span>{shipmentDetails.dstContact1}</span>
                  </p>
                  {shipmentDetails?.dstContact2 &&
                    shipmentDetails.dstContact2 !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Destination Contact No Alternate"}</span>
                        <span>{shipmentDetails.dstContact2}</span>
                      </p>
                    )}
                  {shipmentDetails?.dstEmailId &&
                    shipmentDetails.dstEmailId !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Destination Email Id"}</span>
                        <span>{shipmentDetails.dstEmailId}</span>
                      </p>
                    )}
                  {shipmentDetails?.dstEmailIdAlt &&
                    shipmentDetails.dstEmailIdAlt !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Destination Email Id Alternate"}</span>
                        <span>{shipmentDetails.dstEmailIdAlt}</span>
                      </p>
                    )}
                  <p className="d-flex justify-content-between mb-0 pb-0">
                    <span>{"Address 1: "}</span>
                    <span>{shipmentDetails.dstAdd1}</span>
                  </p>
                  {shipmentDetails?.dstAdd2 &&
                    shipmentDetails.dstAdd2 !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Address 2: "}</span>
                        <span>{shipmentDetails.dstAdd2}</span>
                      </p>
                    )}
                  <p className="d-flex justify-content-between mb-0 pb-0">
                    <span>{"Destination Pincode"}</span>
                    <span>{shipmentDetails.dstPincode}</span>
                  </p>
                  {shipmentDetails?.dstCity &&
                    shipmentDetails.dstCity !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Destination City"}</span>
                        <span>{shipmentDetails.dstCity}</span>
                      </p>
                    )}
                  {shipmentDetails?.dstState &&
                    shipmentDetails.dstState !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Destination State"}</span>
                        <span>{shipmentDetails.dstState}</span>
                      </p>
                    )}
                  {shipmentDetails?.dstLocation &&
                    shipmentDetails.dstLocation !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Destination Location"}</span>
                        <span>{shipmentDetails.dstLocation}</span>
                      </p>
                    )}
                  {shipmentDetails?.dstLandmark &&
                    shipmentDetails.dstLandmark !== "null" && (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Destination Landmark"}</span>
                        <span>{shipmentDetails.dstLandmark}</span>
                      </p>
                    )}
                </div>
              </div>
            </div>

            <label htmlFor="inputShipmentNo" className="col clearfix ">
              <span className="ml-0 pl-0">Enter AWB/LR no</span>
              <span className="pull-right">{shipmentDetails.shipmentNo}</span>
            </label>
            <input
              type="text"
              id={shipmentInputId}
              autoComplete="false"
              className="form-control"
              value={shipmentNo}
              onChange={(e) => setShipmentNo(e.target.value)}
            />
          </div>
          <div className="modal-footer">
            <button
              ref={closeButtonRef}
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={handleClickClose}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleClickSave}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateShipmentNoModal;
