import _http from "./../_httpNoRejectService";
import config from "./../../config.json";
import { log } from "joi-browser";

const baseURL = config[config.build].apiURLEDashboard;

export function getStores(hubId) {
  try {
    const apiEndpoint = `${baseURL}/metrics/getStores/${hubId}`;
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
}

export function getItemStockListByStore(storeId) {
  try {
    const apiEndpoint = `${baseURL}/metrics/getItemStockList/${storeId}`;
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
}

export function getItemStockListByBays(storeId, itemid) {
  try {
    const apiEndpoint = `${baseURL}/metrics/getItemsStockLists/${storeId}/${itemid}`;
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
}

export function getRetailersMappedToInventory() {
  try {
    const apiEndpoint = `${config[config.build].apiURLInventory
      }/getInventoryRetailerList`;
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch (error) {
    console.log("Error in getRetailersMappedToInventory", error);
    return undefined;
  }
}

export function getRefDocFilesUploadedCount(invId) {
  try {
    const apiEndpoint = `${config[config.build].apiURLInventory
      }/getInwardFileStatus/${invId}`;
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch (error) {
    console.log("Error in getRetailersMappedToInventory", error);
    return undefined;
  }
}

export function postInwardRefFileUpload(req) {
  try {
    const formData = new FormData();
    const files = req.selectedFileList;
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i], files[i].name);
    }

    const fieldsData = { ...req };
    delete fieldsData.selectedFileList;

    const fields = JSON.stringify(fieldsData);
    formData.append("fields", fields);

    const apiEndpoint = `${baseURL}/metrics/inventory/inwardFileUpload`;
    return _http.post(apiEndpoint, formData, _http.headerJWT());
  } catch (error) {
    console.log("Error in getRetailersMappedToInventory", error);
    return undefined;
  }
}

export function getSlNoByItemAndBaysId(params) {
  try {
    const apiEndpoint = `${baseURL}/metrics/getSlNoByItemIdBayId`;
    return _http.put(apiEndpoint, params, _http.headerJWT());
  } catch (error) {
    return "Error while fetching barcodeSlNoId";
  }
}

export function getOverrideConfirmPickupStatus(jobId) {
  try {
    const apiEndpoint = `${config[config.build].apiURLInventory
      }/override/${jobId}`;
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch (error) {
    return "Error while fetching getOverrideConfirmPickupStatus API";
  }
}

export function bayToBayAssignment(params) {
  const url = `${config[config.build].apiURLInventory}/bayToBayAssignment`;
  // console.log("get stores by hubId", url);
  return _http.get(url, params, _http.headerJWT())
}


export function getBoxDetailedInformation() {
  const url = "https://mocki.io/v1/2ed58a70-169f-45c6-b514-2726e603565a ";
  return _http.get(url, _http.headerJWT())
}

export function getItemsStockListByHubIdAndClientId(params) {
  const url = `${config[config.build].apiURLInventory}/getItemsStockListByHubIdAndClientId`;
  return _http.put(url, params, _http.headerJWT())
}
export function getItemsStockListByHubIdAndClientIdForAllClients(params) {
  const url = `${config[config.build].apiURLInventory}/getItemsStockListByHubIdAndClientId`;
  return _http.put(url, params, _http.headerJWT())
}

export function getBarcodesListAndStatus(itemId, hubId) {
  // console.log({params});
  const url = `${baseURL}/metrics/getBarcodesList/${itemId}/${hubId}`
  console.log({ url });
  return _http.get(url, _http.headerJWT())
}

export function getTicketStockListByHubIdAndClientId(params, pageNo) {
  console.log(pageNo)
  const url = `${config[config.build].apiURLInventory}/getHubInventory/20/${pageNo}`;
  return _http.put(url, params, _http.headerJWT())
}
export function getSearchItemsStockListByHubIdAndClientId(params) {
  const url = `${config[config.build].apiURLInventory}/searchHubInventory`;
  return _http.put(url, params, _http.headerJWT())
}
export function insertScannedItems(params) {
  const url = `${config[config.build].apiURLInventory}/insertScannedItems`;
  return _http.post(url, params, _http.headerJWT())
}

export function getHubInventoryCount(params) {
  const url = `${config[config.build].apiURLInventory}/getTicketHubInventoryCount`;
  return _http.put(url,params, _http.headerJWT())
}

export function getHubInventoryCountData(params) {
  const url = `${config[config.build].apiURLInventory}/getTicketHubInventoryCountData`;
  return _http.put(url,params, _http.headerJWT())
}