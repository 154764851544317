import React from "react";
import FormLib from "./../../_common/FormLib";
import * as XLSX from "xlsx";
import Joi from "joi-browser";
import { ExportXLSXReqTrans } from "./../requestV1/ExportXLSX";
import {
    getBrandsInfo,
    getMasterData,
    getRetailersInfo,
    uploadExcelTicketData,
} from "../../../services/Tickets/TicketServices";
import {
    getProductsInfo,
    getModelsInfo,
} from "./../../../services/Tickets/TicketServices";
import { toast } from "react-toastify";
import { getFlows } from './../../../services/coreItAdmin/flowsService';
import TicketUploadNewModal from "./TicketUploadNewModal";
import TicketSubUploadNewModal from "./TicketSubUploadNewModal";



// require('./boo.css');

export default class TicketUploadNew extends FormLib {
    state = {
        data: { fileUpload: "", flow: "" },
        errors: {},
        excelFile: null,
        excelData: [],
        excelFileError: null,
        failedData: { data: [] },
        csvReport: {
            data: [],
            headers: [],
        },
        JSONData: [],
        exportFailedData: false,
        curData: { conComplaintNo: "" },
        curDataIndex: -1,
        userCode: "",
        userName: ""


    }
    schema = {
        fileUpload: Joi.string().required().label("fileUpload"),
        flow: Joi.string().required().label("flow"),
    };

    excelHeaders = [
        { key: "ticketPriority", label: "ticketPriority" },
        { key: "conComplaintNo", label: "conComplaintNo" },
        { key: "flowId", label: "flowId" },
        { key: "retailerCode", label: "retailerCode" },
        { key: "retailerId", label: "retailerId" },
        { key: "retailerName", label: "retailerName" },
        { key: "productCode", label: "productCode" },
        { key: "productId", label: "productId" },
        { key: "productName", label: "productName" },
        { key: "brandCode", label: "brandCode" },
        { key: "brandId", label: "brandId" },
        { key: "brandName", label: "brandName" },
        { key: "modelCode", label: "modelCode" },
        { key: "modelId", label: "modelId" },
        { key: "modelName", label: "modelName" },
        { key: "isAppointmentReq", label: "isAppointmentReq" },
        { key: "isInstallationReq", label: "isInstallationReq" },
        { key: "isPhysicalEval", label: "isPhysicalEval" },
        { key: "isTechEval", label: "isTechEval" },
        { key: "isPackingReq", label: "isPackingReq" },
        { key: "retailerNo", label: "retailerNo" },
        { key: "invoiceNo", label: "invoiceNo" },
        { key: "orderNo", label: "orderNo" },
        { key: "ticketDetails", label: "ticketDetails" },
        { key: "specialInstructions", label: "specialInstructions" },
        { key: "dateOfPurchase", label: "dateOfPurchase" },
        { key: "identificationNo", label: "identificationNo" },
        { key: "productDesc", label: "productDesc" },
        { key: "problemDesc", label: "problemDesc" },
        { key: "productValue", label: "productValue" },
        { key: "cost", label: "cost" },
        { key: "isUnderWarranty", label: "isUnderWarranty" },
        { key: "srcContactPerson", label: "srcContactPerson" },
        { key: "srcAdd1", label: "srcAdd1" },
        { key: "srcAdd2", label: "srcAdd2" },
        { key: "srcLocation", label: "srcLocation" },
        { key: "srcContact1", label: "srcContact1" },
        { key: "srcContact2", label: "srcContact2" },
        { key: "srcCity", label: "srcCity" },
        { key: "srcState", label: "srcState" },
        { key: "srcPincode", label: "srcPincode" },
        { key: "srcLandmark", label: "srcLandmark" },
        { key: "srcEmailId", label: "srcEmailId" },
        { key: "srcEmailIdAlt", label: "srcEmailIdAlt" },
        { key: "srcType", label: "srcType" },
        { key: "dstContactPerson", label: "dstContactPerson" },
        { key: "dstAdd1", label: "dstAdd1" },
        { key: "dstAdd2", label: "dstAdd2" },
        { key: "dstLocation", label: "dstLocation" },
        { key: "dstContact1", label: "dstContact1" },
        { key: "dstContact2", label: "dstContact2" },
        { key: "dstCity", label: "dstCity" },
        { key: "dstState", label: "dstState" },
        { key: "dstPincode", label: "dstPincode" },
        { key: "dstLandmark", label: "dstLandmark" },
        { key: "dstEmailId", label: "dstEmailId" },
        { key: "dstEmailIdAlt", label: "dstEmailIdAlt" },
        { key: "dstType", label: "dstType" }
    ];

    componentDidMount() {
        this.loadMasterData();
        this.loadFlows();
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.state.userCode = userInfo.code;
        this.state.userName = userInfo.username;
    }

    async loadMasterData() {
        try {
            let result = await getMasterData();
            if (result === "failure") {
                toast.error("kindly refresh the page again");
            }
        }
        catch {
            console.log("Error in TicketUploadNew>>>>>>loadMasterData");
        }
    }

    async loadFlows() {
        try {
            const res = await getFlows();
            if (res !== undefined) {
                if (res.data.success === true) {
                    this.setState({ flows: res.data.data.flows });
                } else {
                    toast.error(res.data.msg);
                }
            }
            this.setState({ loading: false });
        }
        catch {
            console.log("Error in TicketUploadNew>>>>>>loadFlows");
        }
    }

    localHandleChange = (e) => {
        try {

            this.handleValidation(e);

            this.setState({
                excelFile: null,
                excelFileError: null,
                excelData: [],
                processState: [],
                processStateCls: [],
                ticketState: [],
                ticketStateCls: [],
            });

            if (e.target.name === "fileUpload") {
                console.log("change in file upload");
                this.handleFile(e);
            }
        }
        catch {
            console.log("Error in TicketUploadNew>>>>>>localHandleChange");
        }
    };

    fileType = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    handleFile = (e) => {
        try {
            let selectedFile = e.target.files[0];
            if (selectedFile) {
                if (selectedFile && this.fileType.includes(selectedFile.type)) {
                    let reader = new FileReader();
                    reader.readAsArrayBuffer(selectedFile);
                    reader.onload = (e) => {
                        this.setState({ excelFileError: null });
                        this.setState({ excelFile: e.target.result });
                        // console.log(e.target.result);
                    };
                } else {
                    // setExcelFileError('Please select only excel file types');
                    // setExcelFile(null);
                    this.setState({
                        excelFileError: "Please select only excel file types",
                    });
                    this.setState({ excelFile: null });
                }
            } else {
                console.log("plz select your file");
            }
        }
        catch {
            console.log("Error in TicketUploadNew>>>>>>handleFile");
        }
    };

    handleSubmit = async (e) => {
        try {

            e.preventDefault();
            if (this.state.excelFile !== null) {
                const workbook = XLSX.read(this.state.excelFile, { type: "buffer" });
                const worksheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[worksheetName];
                const data = XLSX.utils.sheet_to_json(worksheet);
                //   setExcelData(data);
                // this.setState({ excelData: data });
                this.setState({
                    excelData: [],
                });
                this.setState({ exportFailedData: false });
                let tempExcelData = [];

                for (let r in data) {
                    let retailerCode = data[r]["retailerCode"];
                    let productCode = data[r]["productCode"];
                    let productName = data[r]["productName"];

                    let brandCode = data[r]["brandCode"];
                    let modelCode = data[r]["modelCode"];
                    let modelName = data[r]["modelName"];
                    let brandName = data[r]["brandName"];

                    let retailerInfo = JSON.parse(localStorage.getItem("retailersInfo"));
                    let productInfo = JSON.parse(localStorage.getItem("productsInfo"));
                    let brandInfo = JSON.parse(localStorage.getItem("brandsInfo"));
                    let modelInfo = JSON.parse(localStorage.getItem("modelsInfo"));

                    ////logic IN service
                    let curRetailer = await getRetailersInfo(retailerInfo, retailerCode);
                    let curProduct = await getProductsInfo(productInfo, productCode);
                    let curBrand = await getBrandsInfo(brandInfo, brandCode);
                    let curModel = await getModelsInfo(modelInfo, modelCode);

                    // console.log("R value====>" + curProduct);

                    if (
                        curRetailer !== "" &&
                        curProduct !== "" &&
                        curBrand !== "" &&
                        curModel !== ""
                    ) {
                        if (Array.isArray(curRetailer) && curRetailer.length > 0) {
                            data[r]["retailerId"] = curRetailer[0].teamId;
                            data[r]["retailerName"] = curRetailer[0].name;
                        } else {
                            data[r]["retailerId"] = "";
                            data[r]["retailerCode"] = "";
                            data[r]["retailerName"] = "";
                        }

                        if (Array.isArray(curProduct) && curProduct.length > 0) {
                            data[r]["productId"] = curProduct[0].productId;
                            data[r]["productName"] = curProduct[0].product;
                        } else {
                            data[r]["productId"] = "";
                            data[r]["productCode"] = productCode;
                            data[r]["productName"] = productName;
                        }

                        if (Array.isArray(curBrand) && curBrand.length > 0) {
                            data[r]["brandId"] = curBrand[0].prodBrandId;
                            data[r]["brandName"] = brandName;
                        } else {
                            data[r]["brandId"] = brandCode;
                            data[r]["brandCode"] = brandCode;
                            data[r]["brandName"] = brandName;
                        }

                        if (Array.isArray(curModel) && curModel.length > 0) {
                            data[r]["modelId"] = curModel[0].prodModelId;
                            data[r]["modelName"] = modelName;
                        } else {
                            data[r]["modelId"] = modelCode;
                            data[r]["modelCode"] = modelCode;
                            data[r]["modelName"] = modelName;
                        }

                        let productCode2 = data[r]["productCode2"];
                        let brandCode2 = data[r]["brandCode2"];
                        let modelCode2 = data[r]["modelCode2"];

                        let curProduct2 = await getProductsInfo(productInfo, productCode2);
                        let curBrand2 = await getBrandsInfo(brandInfo, brandCode2);
                        let curModel2 = await getModelsInfo(modelInfo, modelCode2);


                        if (Array.isArray(curProduct2) && curProduct2.length > 0) {
                            data[r]["productId2"] = curProduct2[0].productId;
                            data[r]["productName2"] = curProduct2[0].product;
                        } else {
                            data[r]["productId2"] = "";
                            data[r]["productCode2"] = "";
                            data[r]["productName2"] = "";
                        }

                        if (Array.isArray(curBrand2) && curBrand2.length > 0) {
                            data[r]["brandId2"] = curBrand2[0].prodBrandId;
                            data[r]["brandName2"] = curBrand2[0].brand;
                            data[r]["brandCode"] = brandCode;
                            data[r]["brandName"] = brandName;
                        } else {
                            data[r]["brandId2"] = "";
                            data[r]["brandCode2"] = "";
                            data[r]["brandName2"] = "";
                            data[r]["brandCode"] = brandCode;
                            data[r]["brandName"] = brandName;
                        }

                        if (Array.isArray(curModel2) && curModel2.length > 0) {
                            data[r]["modelId2"] = curModel2[0].prodModelId;
                            data[r]["modelName2"] = curModel2[0].model;
                            data[r]["modelCode"] = modelCode;
                            data[r]["modelName"] = modelName;
                        } else {
                            data[r]["modelId2"] = "";
                            data[r]["modelCode2"] = "";
                            data[r]["modelName2"] = "";
                            data[r]["modelCode"] = modelCode;
                            data[r]["modelName"] = modelName;
                        }

                    }
                    tempExcelData.push(data[r]);
                }
                // console.log("tempExcelData........." + JSON.stringify(tempExcelData));
                this.setState({
                    excelData: tempExcelData,
                });

            } else {
                //   setExcelData(null);
                this.setState({ excelData: [] });

                console.log("no data");
            }
        }
        catch {
            console.log("Error in excel upload");
        }
    };


    async handleAPIHit() {
        try {
            let processState;
            let processStateCls;
            let ticketState;
            let ticketStateCls;
            let res;
            this.setState({ processState: [] });
            this.setState({ ticketState: [] });


            let i = 0;
            for (let r in this.state.excelData) {
                processState = this.state.processState;
                processStateCls = this.state.processStateCls;
                ticketState = this.state.ticketState;
                ticketStateCls = this.state.ticketStateCls;
                if (Array.isArray(processStateCls)) {
                    processStateCls[i] = "info";
                }
                else {
                    processStateCls = ["info"];
                }
                if (Array.isArray(processState)) {
                    processState[i] = "Processing";
                }
                else {
                    processState = ["Processing"];
                }
                this.setState({ processState });
                if (Array.isArray(ticketStateCls)) {
                    ticketStateCls[i] = "info";
                }
                else {
                    ticketStateCls = ["info"];
                }
                if (Array.isArray(ticketState)) {
                    ticketState[i] = "Processing";
                }
                else {
                    ticketStateCls = ["info"];
                }
                this.setState({ ticketState });

                res = await uploadExcelTicketData(this.state.excelData[r]);
                // console.log("resulted data " + JSON.stringify(res));
                if (res.data.success === true) {
                    this.setState({ exportFailedData: true });
                    if (Array.isArray(processState)) {
                        processState[i] = res.data.msg;
                    }
                    else {
                        processState = [res.data.msg];
                    }
                    if (Array.isArray(processStateCls)) {
                        processStateCls[i] = "info";
                    }
                    else {
                        processStateCls = ["info"];
                    }
                    if (Array.isArray(ticketState)) {
                        ticketState[i] = res.data.data.ticketNo;
                    }
                    else {
                        ticketState = [res.data.data.ticketNo];
                    }
                    if (Array.isArray(ticketStateCls)) {
                        ticketStateCls[i] = "success";
                    }
                    else {
                        ticketStateCls = ["success"];

                    }
                } else if (res.data.success === false) {
                    if (Array.isArray(processState)) {
                        processState[i] = this.stringReplace(JSON.stringify(res.data.data));
                    }
                    else {
                        processState = [this.stringReplace(JSON.stringify(res.data.data))];
                    }
                    if (Array.isArray(processStateCls)) {
                        processStateCls[i] = "danger";
                    }
                    else {
                        processStateCls = ["danger"];
                    }
                    if (Array.isArray(ticketState)) {
                        ticketState[i] = res.data.msg;
                    }
                    else {
                        ticketState = [res.data.msg];
                    }
                    if (Array.isArray(ticketStateCls)) {
                        ticketStateCls[i] = "danger";
                    }
                    else {
                        ticketStateCls = ["danger"];
                    }

                    let failed = this.state.failedData;

                    failed["data"].push(this.state.excelData[r]);

                    this.setState({
                        failed,
                    });

                    // console.log("Failed2 " + JSON.stringify(this.state.failedData));

                    this.failedExcelData();
                }
                i++;
                this.setState({ processState });
                this.setState({ ticketState });
            }
        }
        catch {
            console.log("Error in TicketUploadNew>>>>>>handleAPIHit");
        }

    }
    sleep(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

    stringReplace(data) {
        return data.replace(/{/g, "").replace(/}/g, "").replace(/]/g, "").replace(/"/g, "").replace("productErrors:[", "").replace("primaryErrors:[", "").replace("productErrors:", "").replace("primaryErrors:", "").replace(/:/g, " : ").replace(/,/g, " , ");
    }

    async failedExcelData() {
        try {

            ///Excel
            let apiData = {};
            const { csvReport } = { ...this.state };
            apiData = this.state.failedData;

            // console.log("EXCELLLLLLL " + JSON.stringify(apiData));

            csvReport.filename = "financial_report.xlsx";
            csvReport.headers = this.excelHeaders;

            let transData = [];
            transData = apiData;
            this.setState({ csvReport });

            if (apiData["data"]) {
                const promises = apiData["data"];

                transData = await Promise.all(promises);
                // transData = promises;
                csvReport.data = transData;
                this.setState({ csvReport });
            } else {
                csvReport.data = [];
                this.setState({ csvReport });
            }
            ///Excel
        }
        catch {
            console.log("catch while excel upload");
        }
    }

    reSetFormData() {
        let data = {
            fileUpload: "",
            flow: ""
        };
        this.setState({ data });
        this.setState({ excelData: [] });
        this.setState({ exportFailedData: false });
    }

    addMoreTickets() {
        this.setState({ excelData: [] });
        this.setState({ exportFailedData: false });

    }

    createTicketForm() {
        this.state.curDataIndex = -1;
        this.setState({
            curData: {
                conComplaintNo: ""
            }
        });

    }

    handleOnTicket = (conComplaintNo, i) => {
        try {
            let curData;
            const filter = this.state.excelData.filter((r) => {
                r.ticketPriority = (r.ticketPriority).toLowerCase();
                return r.conComplaintNo === conComplaintNo ? r : null;
            });
            if (!filter[0]) {
                curData = { conComplaintNo: "" };
            } else {
                curData = filter[0];
            }

            this.setState({ curData });
            this.setState({ curDataIndex: i });
        }
        catch {
            console.log("Error in TicketUploadNew>>>>>>handleOnTicket");
        }
    };



    handleGetFormData = (formData) => {
        try {
            this.setState({ processState: [] });
            this.setState({ ticketState: [] });
            // const excelData = [];
            const excelData = [...this.state.excelData];
            if (this.state.curDataIndex >= 0) {
                excelData[this.state.curDataIndex] = formData[0];
            } else {
                excelData.push(formData[0]);
            }

            this.setState({ excelData });

            this.state.flows.map((r) => {
                if (r.flowId === formData[0].flowId) {
                    let data = {
                        flow: r.flowCode
                    };
                    this.setState({ data });
                }
            });
        }
        catch {
            console.log("Error in TicketUploadNew>>>>>>handleGetFormData");
        }
    }

    render() {
        return (
            <React.Fragment>
                <>
                    <div className="card">
                        <div className="card-body">
                            <form id="myform" onSubmit={this.handleSubmit}>
                                <div className="form-row">
                                    {this.state.userCode !== "REL" &&
                                        <>
                                            {this.rSB(
                                                "Flow *",
                                                "flow",
                                                this.state.flows,
                                                { value: "flowCode", label: "flowName" },
                                                " col-2 "
                                            )}
                                            {this.rFU(
                                                "Upload File",
                                                "fileUpload",
                                                "col-3 btn btn-primary btn-sm"
                                            )}
                                            <div className="col-3">
                                                {this.rBtnS("Upload", "upload", "col-3")}
                                            </div>
                                        </>
                                    }
                                    <div className=" col-md-2 ">
                                        {this.state.exportFailedData === false && this.state.userName === "reliancesupport" &&
                                            <button
                                                type="button"
                                                className="btn btn-lg btn-success pull-left"
                                                data-toggle="modal"
                                                data-target="#myTicketModal"
                                                title="Ticket Create"
                                                onClick={() => this.createTicketForm()}

                                            >
                                                <i className={"fa  fa-plus  "} style={{ fontSize: "22px", color: "orange" }}></i>
                                            </button>
                                        }
                                        {this.state.exportFailedData === false && this.state.userName === "relianceservice" &&
                                            <button
                                                type="button"
                                                className="btn btn-lg btn-warning pull-left"
                                                data-toggle="modal"
                                                data-target="#mySubTicketModal"
                                                title="Service Center Ticket Create"
                                            >
                                                <i className={"fa fa-backward"} style={{ fontSize: "22px", color: "green" }}></i>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </form>
                            <div className="col-3 pull-right">
                                {this.state.userCode !== "REL" &&
                                    <button
                                        className="btn  btn-info  btn-sm pull-right"
                                        onClick={() => this.reSetFormData()}
                                    >
                                        Reset Page
                                    </button>
                                }
                                {this.state.exportFailedData === true &&
                                    <button
                                        className="btn  btn-secondary  btn-sm pull-right"
                                        onClick={() => this.addMoreTickets()}
                                    >
                                        Add More Tickets
                                    </button>
                                }
                            </div>

                        </div>

                        <div className="viewer">
                            {this.state.excelData.length > 0 && (
                                <>
                                    {(this.state.data.flow === "PND1W" && this.state.excelData[0].flowId === "PickAndDropOneWay") && this.state.exportFailedData === false && <button
                                        className="btn  btn-info  btn-sm"
                                        onClick={() => this.handleAPIHit()}
                                    >
                                        Submit
                                    </button>}
                                    {(this.state.data.flow === "PND2W" && this.state.excelData[0].flowId === "PickAndDropTwoWay") && this.state.exportFailedData === false && <button
                                        className="btn  btn-info  btn-sm"
                                        onClick={() => this.handleAPIHit()}
                                    >
                                        Submit
                                    </button>}
                                    {(this.state.data.flow === "ADV" && this.state.excelData[0].flowId === "AdvanceExchange") && this.state.exportFailedData === false && <button
                                        className="btn  btn-info  btn-sm"
                                        onClick={() => this.handleAPIHit()}
                                    >
                                        Submit
                                    </button>}
                                    {(this.state.data.flow === "BUYBACK" && this.state.excelData[0].flowId === "BuyBack") && this.state.exportFailedData === false && <button
                                        className="btn  btn-info  btn-sm"
                                        onClick={() => this.handleAPIHit()}
                                    >
                                        Submit
                                    </button>}
                                    {(this.state.data.flow === "INSV1" && this.state.excelData[0].flowId === "InstallationV1") && this.state.exportFailedData === false && <button
                                        className="btn  btn-info  btn-sm"
                                        onClick={() => this.handleAPIHit()}
                                    >
                                        Submit
                                    </button>}
                                    {(this.state.data.flow === "DRPOF" && this.state.excelData[0].flowId === "DropOff") && this.state.exportFailedData === false && <button
                                        className="btn  btn-info  btn-sm"
                                        onClick={() => this.handleAPIHit()}
                                    >
                                        Submit
                                    </button>}
                                    {/* {JSON.stringify(this.state.excelData)} */}
                                    {this.state.data.flow === "PND1W" && this.state.excelData[0].flowId === "PickAndDropOneWay" && (<><div className="table-responsive">
                                        <table className="table table-responsive">
                                            <thead>
                                                <tr>
                                                    {(this.state.data.flow === "PND1W" && this.state.excelData[0].flowId === "PickAndDropOneWay") && this.state.exportFailedData === false && this.state.userName === "reliancesupport" &&
                                                        <th>Edit</th>
                                                    }
                                                    <th>Action</th>
                                                    <th>Ticket No.</th>
                                                    <th>Ticket Priority</th>
                                                    <th>Consumer Complaint Number</th>
                                                    <th>Flow Id</th>
                                                    <th>Retailer Code</th>
                                                    <th>Retailer Id</th>
                                                    <th>Retailer Name</th>
                                                    <th>Product Code</th>
                                                    <th>Product Id</th>
                                                    <th>Product Name</th>
                                                    <th>Brand Code</th>
                                                    <th>Brand Id</th>
                                                    <th>Brand Name</th>
                                                    <th>Model Code</th>
                                                    <th>Model Id</th>
                                                    <th>Model Name</th>
                                                    <th>isAppointment Required</th>
                                                    <th>isInstallation Required</th>
                                                    <th>isPhysical Evaluation Required</th>
                                                    <th>isTechnical Evaluation Required</th>
                                                    <th>isPacking Required</th>
                                                    <th>retailerNo</th>
                                                    <th>invoiceNo</th>
                                                    <th>orderNo</th>
                                                    <th>ticketDetails</th>
                                                    <th>specialInstructions</th>
                                                    <th>dateOfPurchase</th>
                                                    <th>identificationNo</th>
                                                    <th>productDesc</th>
                                                    <th>problemDesc</th>
                                                    <th>productValue</th>
                                                    <th>cost</th>
                                                    <th>isUnderWarranty</th>
                                                    <th>srcContactPerson</th>
                                                    <th>srcAdd1</th>
                                                    <th>srcAdd2</th>
                                                    <th>srcLocation</th>
                                                    <th>srcContact1</th>
                                                    <th>srcContact2</th>
                                                    <th>srcCity</th>
                                                    <th>srcState</th>
                                                    <th>srcPincode</th>
                                                    <th>srcLandmark</th>
                                                    <th>srcEmailId</th>
                                                    <th>srcEmailIdAlt</th>
                                                    <th>srcType</th>
                                                    <th>dstContactPerson</th>
                                                    <th>dstAdd1</th>
                                                    <th>dstAdd2</th>
                                                    <th>dstLocation</th>
                                                    <th>dstContact1</th>
                                                    <th>dstContact2</th>
                                                    <th>dstCity</th>
                                                    <th>dstState</th>
                                                    <th>dstPincode</th>
                                                    <th>dstLandmark</th>
                                                    <th>dstEmailId</th>
                                                    <th>dstEmailIdAlt</th>
                                                    <th>dstType</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.excelData.map((individualExcelData, i) => (
                                                    <tr key={individualExcelData.conComplaintNo}>
                                                        {(this.state.data.flow === "PND1W" && this.state.excelData[0].flowId === "PickAndDropOneWay") && this.state.exportFailedData === false && this.state.userName === "reliancesupport" &&
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-success pull-left"
                                                                    data-toggle="modal"
                                                                    data-target="#myTicketModal"
                                                                    onClick={() => this.handleOnTicket(individualExcelData.conComplaintNo, i)}
                                                                >
                                                                    <i className={"fa  fa-pencil"}></i>
                                                                </button>
                                                            </td>
                                                        }
                                                        <td>
                                                            <span
                                                                className={
                                                                    "badge badge-info badge-" + this.state.processStateCls?.[i]
                                                                    // "badge badge-" + this.state.processStateCls ? "badge badge-" + this.state.processStateCls?.[i] : "badge badge-" + this.state.processStateCls

                                                                }
                                                            >
                                                                {this.state.processState?.[i]}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span
                                                                className={
                                                                    "badge badge-success  badge-" + this.state.ticketStateCls?.[i]
                                                                }
                                                            >
                                                                {this.state.ticketState?.[i]}
                                                            </span>
                                                        </td>
                                                        <td>{individualExcelData.ticketPriority}</td>
                                                        <td>{individualExcelData.conComplaintNo}</td>
                                                        <td>{individualExcelData.flowId}</td>
                                                        <td>{individualExcelData.retailerCode}</td>
                                                        <td>{individualExcelData.retailerId}</td>
                                                        <td>{individualExcelData.retailerName}</td>
                                                        <td>{individualExcelData.productCode}</td>
                                                        <td>{individualExcelData.productId}</td>
                                                        <td>{individualExcelData.productName}</td>
                                                        <td>{individualExcelData.brandCode}</td>
                                                        <td>{individualExcelData.brandId}</td>
                                                        <td>{individualExcelData.brandName}</td>
                                                        <td>{individualExcelData.modelCode}</td>
                                                        <td>{individualExcelData.modelId}</td>
                                                        <td>{individualExcelData.modelName}</td>
                                                        <td>{individualExcelData.isAppointmentReq}</td>
                                                        <td>{individualExcelData.isInstallationReq}</td>
                                                        <td>{individualExcelData.isPhysicalEval}</td>
                                                        <td>{individualExcelData.isTechEval}</td>
                                                        <td>{individualExcelData.isPackingReq}</td>
                                                        <td>{individualExcelData.retailerNo}</td>
                                                        <td>{individualExcelData.invoiceNo}</td>
                                                        <td>{individualExcelData.orderNo}</td>
                                                        <td>{individualExcelData.ticketDetails}</td>
                                                        <td>{individualExcelData.specialInstructions}</td>
                                                        <td>{individualExcelData.dateOfPurchase}</td>
                                                        <td>{individualExcelData.identificationNo}</td>
                                                        <td>{individualExcelData.productDesc}</td>
                                                        <td>{individualExcelData.problemDesc}</td>
                                                        <td>{individualExcelData.productValue}</td>
                                                        <td>{individualExcelData.cost}</td>
                                                        <td>{individualExcelData.isUnderWarranty}</td>
                                                        <td>{individualExcelData.srcContactPerson}</td>
                                                        <td>{individualExcelData.srcAdd1}</td>
                                                        <td>{individualExcelData.srcAdd2}</td>
                                                        <td>{individualExcelData.srcLocation}</td>
                                                        <td>{individualExcelData.srcContact1}</td>
                                                        <td>{individualExcelData.srcContact2}</td>
                                                        <td>{individualExcelData.srcCity}</td>
                                                        <td>{individualExcelData.srcState}</td>
                                                        <td>{individualExcelData.srcPincode}</td>
                                                        <td>{individualExcelData.srcLandmark}</td>
                                                        <td>{individualExcelData.srcEmailId}</td>
                                                        <td>{individualExcelData.srcEmailIdAlt}</td>
                                                        <td>{individualExcelData.srcType}</td>
                                                        <td>{individualExcelData.dstContactPerson}</td>
                                                        <td>{individualExcelData.dstAdd1}</td>
                                                        <td>{individualExcelData.dstAdd2}</td>
                                                        <td>{individualExcelData.dstLocation}</td>
                                                        <td>{individualExcelData.dstContact1}</td>
                                                        <td>{individualExcelData.dstContact2}</td>
                                                        <td>{individualExcelData.dstCity}</td>
                                                        <td>{individualExcelData.dstState}</td>
                                                        <td>{individualExcelData.dstPincode}</td>
                                                        <td>{individualExcelData.dstLandmark}</td>
                                                        <td>{individualExcelData.dstEmailId}</td>
                                                        <td>{individualExcelData.dstEmailIdAlt}</td>
                                                        <td>{individualExcelData.dstType}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div></>)}
                                    {this.state.data.flow === "PND2W" && this.state.excelData[0].flowId === "PickAndDropTwoWay" && (<><div className="table-responsive">
                                        <table className="table table-responsive">
                                            <thead>
                                                <tr>
                                                    {/* {(this.state.data.flow === "PND2W" && this.state.excelData[0].flowId === "PickAndDropTwoWay") && this.state.exportFailedData === false &&
                                                        <th>Edit</th>
                                                    } */}
                                                    <th>Action</th>
                                                    <th>Ticket No.</th>
                                                    <th>Ticket Priority</th>
                                                    <th>Consumer Complaint Number</th>
                                                    <th>Flow Id</th>
                                                    <th>Retailer Code</th>
                                                    <th>Retailer Id</th>
                                                    <th>Retailer Name</th>
                                                    <th>Product Code</th>
                                                    <th>Product Id</th>
                                                    <th>Product Name</th>
                                                    <th>Brand Code</th>
                                                    <th>Brand Id</th>
                                                    <th>Brand Name</th>
                                                    <th>Model Code</th>
                                                    <th>Model Id</th>
                                                    <th>Model Name</th>
                                                    <th>isAppointment Required</th>
                                                    <th>isInstallation Required</th>
                                                    <th>isPhysical Evaluation Required</th>
                                                    <th>isTechnical Evaluation Required</th>
                                                    <th>isPacking Required</th>
                                                    <th>retailerNo</th>
                                                    <th>invoiceNo</th>
                                                    <th>orderNo</th>
                                                    <th>ticketDetails</th>
                                                    <th>specialInstructions</th>
                                                    <th>dateOfPurchase</th>
                                                    <th>identificationNo</th>
                                                    <th>productDesc</th>
                                                    <th>problemDesc</th>
                                                    <th>productValue</th>
                                                    <th>cost</th>
                                                    <th>isUnderWarranty</th>
                                                    <th>srcContactPerson</th>
                                                    <th>srcAdd1</th>
                                                    <th>srcAdd2</th>
                                                    <th>srcLocation</th>
                                                    <th>srcContact1</th>
                                                    <th>srcContact2</th>
                                                    <th>srcCity</th>
                                                    <th>srcState</th>
                                                    <th>srcPincode</th>
                                                    <th>srcLandmark</th>
                                                    <th>srcEmailId</th>
                                                    <th>srcEmailIdAlt</th>
                                                    <th>srcType</th>
                                                    <th>dstContactPerson</th>
                                                    <th>dstAdd1</th>
                                                    <th>dstAdd2</th>
                                                    <th>dstLocation</th>
                                                    <th>dstContact1</th>
                                                    <th>dstContact2</th>
                                                    <th>dstCity</th>
                                                    <th>dstState</th>
                                                    <th>dstPincode</th>
                                                    <th>dstLandmark</th>
                                                    <th>dstEmailId</th>
                                                    <th>dstEmailIdAlt</th>
                                                    <th>dstType</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.excelData.map((individualExcelData, i) => (
                                                    <tr key={individualExcelData.conComplaintNo}>
                                                        {/* {(this.state.data.flow === "PND2W" && this.state.excelData[0].flowId === "PickAndDropTwoWay") && this.state.exportFailedData === false &&

                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-success pull-left"
                                                                    data-toggle="modal"
                                                                    data-target="#myTicketModal"
                                                                // onClick={() => this.handleOnTicket(individualExcelData)}
                                                                >
                                                                    <i className={"fa  fa-pencil"}></i>
                                                                </button>
                                                            </td>
                                                        } */}
                                                        <td>
                                                            <span
                                                                className={
                                                                    "badge badge-" + this.state.processStateCls[i]
                                                                }
                                                            >
                                                                {this.state.processState[i]}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span
                                                                className={
                                                                    "badge badge-" + this.state.ticketStateCls[i]
                                                                }
                                                            >
                                                                {this.state.ticketState[i]}
                                                            </span>
                                                        </td>
                                                        <td>{individualExcelData.ticketPriority}</td>
                                                        <td>{individualExcelData.conComplaintNo}</td>
                                                        <td>{individualExcelData.flowId}</td>
                                                        <td>{individualExcelData.retailerCode}</td>
                                                        <td>{individualExcelData.retailerId}</td>
                                                        <td>{individualExcelData.retailerName}</td>
                                                        <td>{individualExcelData.productCode}</td>
                                                        <td>{individualExcelData.productId}</td>
                                                        <td>{individualExcelData.productName}</td>
                                                        <td>{individualExcelData.brandCode}</td>
                                                        <td>{individualExcelData.brandId}</td>
                                                        <td>{individualExcelData.brandName}</td>
                                                        <td>{individualExcelData.modelCode}</td>
                                                        <td>{individualExcelData.modelId}</td>
                                                        <td>{individualExcelData.modelName}</td>
                                                        <td>{individualExcelData.isAppointmentReq}</td>
                                                        <td>{individualExcelData.isInstallationReq}</td>
                                                        <td>{individualExcelData.isPhysicalEval}</td>
                                                        <td>{individualExcelData.isTechEval}</td>
                                                        <td>{individualExcelData.isPackingReq}</td>
                                                        <td>{individualExcelData.retailerNo}</td>
                                                        <td>{individualExcelData.invoiceNo}</td>
                                                        <td>{individualExcelData.orderNo}</td>
                                                        <td>{individualExcelData.ticketDetails}</td>
                                                        <td>{individualExcelData.specialInstructions}</td>
                                                        <td>{individualExcelData.dateOfPurchase}</td>
                                                        <td>{individualExcelData.identificationNo}</td>
                                                        <td>{individualExcelData.productDesc}</td>
                                                        <td>{individualExcelData.problemDesc}</td>
                                                        <td>{individualExcelData.productValue}</td>
                                                        <td>{individualExcelData.cost}</td>
                                                        <td>{individualExcelData.isUnderWarranty}</td>
                                                        <td>{individualExcelData.srcContactPerson}</td>
                                                        <td>{individualExcelData.srcAdd1}</td>
                                                        <td>{individualExcelData.srcAdd2}</td>
                                                        <td>{individualExcelData.srcLocation}</td>
                                                        <td>{individualExcelData.srcContact1}</td>
                                                        <td>{individualExcelData.srcContact2}</td>
                                                        <td>{individualExcelData.srcCity}</td>
                                                        <td>{individualExcelData.srcState}</td>
                                                        <td>{individualExcelData.srcPincode}</td>
                                                        <td>{individualExcelData.srcLandmark}</td>
                                                        <td>{individualExcelData.srcEmailId}</td>
                                                        <td>{individualExcelData.srcEmailIdAlt}</td>
                                                        <td>{individualExcelData.srcType}</td>
                                                        <td>{individualExcelData.dstContactPerson}</td>
                                                        <td>{individualExcelData.dstAdd1}</td>
                                                        <td>{individualExcelData.dstAdd2}</td>
                                                        <td>{individualExcelData.dstLocation}</td>
                                                        <td>{individualExcelData.dstContact1}</td>
                                                        <td>{individualExcelData.dstContact2}</td>
                                                        <td>{individualExcelData.dstCity}</td>
                                                        <td>{individualExcelData.dstState}</td>
                                                        <td>{individualExcelData.dstPincode}</td>
                                                        <td>{individualExcelData.dstLandmark}</td>
                                                        <td>{individualExcelData.dstEmailId}</td>
                                                        <td>{individualExcelData.dstEmailIdAlt}</td>
                                                        <td>{individualExcelData.dstType}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div></>)}
                                    {this.state.data.flow === "ADV" && this.state.excelData[0].flowId === "AdvanceExchange" && (<><div className="table-responsive"><table className="table table-responsive">
                                        <thead>
                                            <tr>
                                                {/* {(this.state.data.flow === "ADV" && this.state.excelData[0].flowId === "AdvanceExchange") && this.state.exportFailedData === false &&
                                                    <th>Edit</th>
                                                } */}
                                                <th>Action</th>
                                                <th>Ticket No.</th>
                                                <th>Ticket Priority</th>
                                                <th>Consumer Complaint Number</th>
                                                <th>Flow Id</th>
                                                <th>Retailer Code</th>
                                                <th>Retailer Id</th>
                                                <th>Retailer Name</th>
                                                <th>Product Code</th>
                                                <th>Product Id</th>
                                                <th>Product Name</th>
                                                <th>Brand Code</th>
                                                <th>Brand Id</th>
                                                <th>Brand Name</th>
                                                <th>Model Code</th>
                                                <th>Model Id</th>
                                                <th>Model Name</th>
                                                <th>isAppointment Required</th>
                                                <th>isInstallation Required</th>
                                                <th>isPhysical Evaluation Required</th>
                                                <th>isTechnical Evaluation Required</th>
                                                <th>isPacking Required</th>
                                                <th>isBasicEval Required</th>
                                                <th>retailerNo</th>
                                                <th>invoiceNo</th>
                                                <th>orderNo</th>
                                                <th>ticketDetails</th>
                                                <th>specialInstructions</th>
                                                <th>dateOfPurchase</th>
                                                <th>identificationNo</th>
                                                <th>productDesc</th>
                                                <th>problemDesc</th>
                                                <th>productValue</th>
                                                <th>cost</th>
                                                <th>isUnderWarranty</th>
                                                <th>isDoa</th>
                                                <th>Accessories</th>
                                                <th>srcContactPerson</th>
                                                <th>srcAdd1</th>
                                                <th>srcAdd2</th>
                                                <th>srcLocation</th>
                                                <th>srcContact1</th>
                                                <th>srcContact2</th>
                                                <th>srcCity</th>
                                                <th>srcState</th>
                                                <th>srcPincode</th>
                                                <th>srcLandmark</th>
                                                <th>srcEmailId</th>
                                                <th>srcEmailIdAlt</th>
                                                <th>srcType</th>
                                                <th>pickupSellerCode</th>
                                                <th>productId2</th>
                                                <th>productCode2</th>
                                                <th>productName2</th>
                                                <th>brandId2</th>
                                                <th>brandCode2</th>
                                                <th>brandName2</th>
                                                <th>modelId2</th>
                                                <th>modelCode2</th>
                                                <th>modelName2</th>
                                                <th>returnSellerCode</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.excelData.map((individualExcelData, i) => (
                                                <tr key={individualExcelData.conComplaintNo}>
                                                    {/* {(this.state.data.flow === "ADV" && this.state.excelData[0].flowId === "AdvanceExchange") && this.state.exportFailedData === false &&
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-success pull-left"
                                                                data-toggle="modal"
                                                                data-target="#myTicketModal"
                                                            // onClick={() => this.handleOnTicket(individualExcelData)}
                                                            >
                                                                <i className={"fa  fa-pencil"}></i>
                                                            </button>
                                                        </td>
                                                    } */}
                                                    <td>
                                                        <span
                                                            className={
                                                                "badge badge-" + this.state.processStateCls[i]
                                                            }
                                                        >
                                                            {this.state.processState[i]}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span
                                                            className={
                                                                "badge badge-" + this.state.ticketStateCls[i]
                                                            }
                                                        >
                                                            {this.state.ticketState[i]}
                                                        </span>
                                                    </td>
                                                    <td>{individualExcelData.ticketPriority}</td>
                                                    <td>{individualExcelData.conComplaintNo}</td>
                                                    <td>{individualExcelData.flowId}</td>
                                                    <td>{individualExcelData.retailerCode}</td>
                                                    <td>{individualExcelData.retailerId}</td>
                                                    <td>{individualExcelData.retailerName}</td>
                                                    <td>{individualExcelData.productCode}</td>
                                                    <td>{individualExcelData.productId}</td>
                                                    <td>{individualExcelData.productName}</td>
                                                    <td>{individualExcelData.brandCode}</td>
                                                    <td>{individualExcelData.brandId}</td>
                                                    <td>{individualExcelData.brandName}</td>
                                                    <td>{individualExcelData.modelCode}</td>
                                                    <td>{individualExcelData.modelId}</td>
                                                    <td>{individualExcelData.modelName}</td>
                                                    <td>{individualExcelData.isAppointmentReq}</td>
                                                    <td>{individualExcelData.isInstallationReq}</td>
                                                    <td>{individualExcelData.isPhysicalEval}</td>
                                                    <td>{individualExcelData.isTechEval}</td>
                                                    <td>{individualExcelData.isPackingReq}</td>
                                                    <td>{individualExcelData.isBasicEval}</td>
                                                    <td>{individualExcelData.retailerNo}</td>
                                                    <td>{individualExcelData.invoiceNo}</td>
                                                    <td>{individualExcelData.orderNo}</td>
                                                    <td>{individualExcelData.ticketDetails}</td>
                                                    <td>{individualExcelData.specialInstructions}</td>
                                                    <td>{individualExcelData.dateOfPurchase}</td>
                                                    <td>{individualExcelData.identificationNo}</td>
                                                    <td>{individualExcelData.productDesc}</td>
                                                    <td>{individualExcelData.problemDesc}</td>
                                                    <td>{individualExcelData.productValue}</td>
                                                    <td>{individualExcelData.cost}</td>
                                                    <td>{individualExcelData.isUnderWarranty}</td>
                                                    <td>{individualExcelData.isDoa}</td>
                                                    <td>{individualExcelData.accessories}</td>
                                                    <td>{individualExcelData.srcContactPerson}</td>
                                                    <td>{individualExcelData.srcAdd1}</td>
                                                    <td>{individualExcelData.srcAdd2}</td>
                                                    <td>{individualExcelData.srcLocation}</td>
                                                    <td>{individualExcelData.srcContact1}</td>
                                                    <td>{individualExcelData.srcContact2}</td>
                                                    <td>{individualExcelData.srcCity}</td>
                                                    <td>{individualExcelData.srcState}</td>
                                                    <td>{individualExcelData.srcPincode}</td>
                                                    <td>{individualExcelData.srcLandmark}</td>
                                                    <td>{individualExcelData.srcEmailId}</td>
                                                    <td>{individualExcelData.srcEmailIdAlt}</td>
                                                    <td>{individualExcelData.srcType}</td>
                                                    <td>{individualExcelData.pickupSellerCode}</td>
                                                    <td>{individualExcelData.productId2}</td>
                                                    <td>{individualExcelData.productCode2}</td>
                                                    <td>{individualExcelData.productName2}</td>
                                                    <td>{individualExcelData.brandId2}</td>
                                                    <td>{individualExcelData.brandCode2}</td>
                                                    <td>{individualExcelData.brandName2}</td>
                                                    <td>{individualExcelData.modelId2}</td>
                                                    <td>{individualExcelData.modelCode2}</td>
                                                    <td>{individualExcelData.modelName2}</td>
                                                    <td>{individualExcelData.returnSellerCode}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table></div></>)}
                                    {this.state.data.flow === "BUYBACK" && this.state.excelData[0].flowId === "BuyBack" && (<><div className="table-responsive"><table className="table table-responsive">
                                        <thead>
                                            <tr>
                                                {/* {(this.state.data.flow === "BUYBACK" && this.state.excelData[0].flowId === "BuyBack") && this.state.exportFailedData === false &&
                                                    <th>Edit</th>
                                                } */}
                                                <th>Action</th>
                                                <th>Ticket No.</th>
                                                <th>Ticket Priority</th>
                                                <th>Consumer Complaint Number</th>
                                                <th>Flow Id</th>
                                                <th>Retailer Code</th>
                                                <th>Retailer Id</th>
                                                <th>Retailer Name</th>
                                                <th>Product Code</th>
                                                <th>Product Id</th>
                                                <th>Product Name</th>
                                                <th>Brand Code</th>
                                                <th>Brand Id</th>
                                                <th>Brand Name</th>
                                                <th>Model Code</th>
                                                <th>Model Id</th>
                                                <th>Model Name</th>
                                                <th>isAppointment Required</th>
                                                <th>isPhysical Evaluation Required</th>
                                                <th>isTechnical Evaluation Required</th>
                                                <th>isPacking Required</th>
                                                <th>retailerNo</th>
                                                <th>invoiceNo</th>
                                                <th>orderNo</th>
                                                <th>ticketDetails</th>
                                                <th>specialInstructions</th>
                                                <th>dateOfPurchase</th>
                                                <th>identificationNo</th>
                                                <th>productDesc</th>
                                                <th>problemDesc</th>
                                                <th>productValue</th>
                                                <th>cost</th>
                                                <th>isUnderWarranty</th>
                                                <th>srcContactPerson</th>
                                                <th>srcAdd1</th>
                                                <th>srcAdd2</th>
                                                <th>srcLocation</th>
                                                <th>srcContact1</th>
                                                <th>srcContact2</th>
                                                <th>srcCity</th>
                                                <th>srcState</th>
                                                <th>srcPincode</th>
                                                <th>srcLandmark</th>
                                                <th>srcEmailId</th>
                                                <th>srcEmailIdAlt</th>
                                                <th>srcType</th>
                                                <th>buyerContactNumber</th>
                                                <th>paymentMode</th>
                                                <th>waitForNotification</th>
                                                <th>pickupSellerCode</th>
                                                <th>returnSellerCode</th>
                                                <th>maxValueToBeOffered</th>
                                                <th>estimatedProdValue</th>
                                                <th>boxPickUp</th>
                                                <th>batteryPickUp</th>
                                                <th>headPhonePickUp</th>
                                                <th>chargerPickUp</th>
                                                <th>othersPickUp</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.excelData.map((individualExcelData, i) => (
                                                <tr key={individualExcelData.conComplaintNo}>
                                                    {/* {(this.state.data.flow === "BUYBACK" && this.state.excelData[0].flowId === "BuyBack") && this.state.exportFailedData === false &&
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-success pull-left"
                                                                data-toggle="modal"
                                                                data-target="#myTicketModal"
                                                            // onClick={() => this.handleOnTicket(individualExcelData)}
                                                            >
                                                                <i className={"fa  fa-pencil"}></i>
                                                            </button>
                                                        </td>
                                                    } */}
                                                    <td>
                                                        <span
                                                            className={
                                                                "badge badge-" + this.state.processStateCls[i]
                                                            }
                                                        >
                                                            {this.state.processState[i]}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span
                                                            className={
                                                                "badge badge-" + this.state.ticketStateCls[i]
                                                            }
                                                        >
                                                            {this.state.ticketState[i]}
                                                        </span>
                                                    </td>
                                                    <td>{individualExcelData.ticketPriority}</td>
                                                    <td>{individualExcelData.conComplaintNo}</td>
                                                    <td>{individualExcelData.flowId}</td>
                                                    <td>{individualExcelData.retailerCode}</td>
                                                    <td>{individualExcelData.retailerId}</td>
                                                    <td>{individualExcelData.retailerName}</td>
                                                    <td>{individualExcelData.productCode}</td>
                                                    <td>{individualExcelData.productId}</td>
                                                    <td>{individualExcelData.productName}</td>
                                                    <td>{individualExcelData.brandCode}</td>
                                                    <td>{individualExcelData.brandId}</td>
                                                    <td>{individualExcelData.brandName}</td>
                                                    <td>{individualExcelData.modelCode}</td>
                                                    <td>{individualExcelData.modelId}</td>
                                                    <td>{individualExcelData.modelName}</td>
                                                    <td>{individualExcelData.isAppointmentReq}</td>
                                                    <td>{individualExcelData.isPhysicalEval}</td>
                                                    <td>{individualExcelData.isTechEval}</td>
                                                    <td>{individualExcelData.isPackingReq}</td>
                                                    <td>{individualExcelData.retailerNo}</td>
                                                    <td>{individualExcelData.invoiceNo}</td>
                                                    <td>{individualExcelData.orderNo}</td>
                                                    <td>{individualExcelData.ticketDetails}</td>
                                                    <td>{individualExcelData.specialInstructions}</td>
                                                    <td>{individualExcelData.dateOfPurchase}</td>
                                                    <td>{individualExcelData.identificationNo}</td>
                                                    <td>{individualExcelData.productDesc}</td>
                                                    <td>{individualExcelData.problemDesc}</td>
                                                    <td>{individualExcelData.productValue}</td>
                                                    <td>{individualExcelData.cost}</td>
                                                    <td>{individualExcelData.isUnderWarranty}</td>
                                                    <td>{individualExcelData.srcContactPerson}</td>
                                                    <td>{individualExcelData.srcAdd1}</td>
                                                    <td>{individualExcelData.srcAdd2}</td>
                                                    <td>{individualExcelData.srcLocation}</td>
                                                    <td>{individualExcelData.srcContact1}</td>
                                                    <td>{individualExcelData.srcContact2}</td>
                                                    <td>{individualExcelData.srcCity}</td>
                                                    <td>{individualExcelData.srcState}</td>
                                                    <td>{individualExcelData.srcPincode}</td>
                                                    <td>{individualExcelData.srcLandmark}</td>
                                                    <td>{individualExcelData.srcEmailId}</td>
                                                    <td>{individualExcelData.srcEmailIdAlt}</td>
                                                    <td>{individualExcelData.srcType}</td>
                                                    <td>{individualExcelData.buyerContactNumber}</td>
                                                    <td>{individualExcelData.paymentMode}</td>
                                                    <td>{individualExcelData.waitForNotification}</td>
                                                    <td>{individualExcelData.pickupSellerCode}</td>
                                                    <td>{individualExcelData.returnSellerCode}</td>
                                                    <td>{individualExcelData.maxValueToBeOffered}</td>
                                                    <td>{individualExcelData.estimatedProdValue}</td>
                                                    <td>{individualExcelData.boxPickUp}</td>
                                                    <td>{individualExcelData.batteryPickUp}</td>
                                                    <td>{individualExcelData.headPhonePickUp}</td>
                                                    <td>{individualExcelData.chargerPickUp}</td>
                                                    <td>{individualExcelData.othersPickUp}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table></div></>)}

                                    {this.state.data.flow === "INSV1" && this.state.excelData[0].flowId === "InstallationV1" && (<><div className="table-responsive"><table className="table table-responsive">
                                        <thead>
                                            <tr>
                                                {/* {(this.state.data.flow === "INSV1" && this.state.excelData[0].flowId === "InstallationV1") && this.state.exportFailedData === false &&
                                                    <th>Edit</th>
                                                } */}
                                                <th>Action</th>
                                                <th>Ticket No.</th>
                                                <th>Ticket Priority</th>
                                                <th>Consumer Complaint Number</th>
                                                <th>Flow Id</th>
                                                <th>Retailer Code</th>
                                                <th>Retailer Id</th>
                                                <th>Retailer Name</th>
                                                <th>Product Code</th>
                                                <th>Product Id</th>
                                                <th>Product Name</th>
                                                <th>Brand Code</th>
                                                <th>Brand Id</th>
                                                <th>Brand Name</th>
                                                <th>Model Code</th>
                                                <th>Model Id</th>
                                                <th>Model Name</th>
                                                <th>Ticket Type</th>
                                                {/* <th>Source GeoCodes</th>
                      <th>Dst GeoCodes</th> */}
                                                <th>isAppointment Required</th>
                                                <th>isPhysical Evaluation Required</th>
                                                <th>isTechnical Evaluation Required</th>
                                                <th>isPacking Required</th>
                                                <th>retailerNo</th>
                                                <th>invoiceNo</th>
                                                <th>orderNo</th>
                                                <th>ticketDetails</th>
                                                <th>specialInstructions</th>
                                                <th>dateOfPurchase</th>
                                                <th>identificationNo</th>
                                                <th>productDesc</th>
                                                <th>problemDesc</th>
                                                <th>productValue</th>
                                                <th>cost</th>
                                                <th>isUnderWarranty</th>
                                                <th>srcContactPerson</th>
                                                <th>srcAdd1</th>
                                                <th>srcAdd2</th>
                                                <th>srcLocation</th>
                                                <th>srcContact1</th>
                                                <th>srcContact2</th>
                                                <th>srcCity</th>
                                                <th>srcState</th>
                                                <th>srcPincode</th>
                                                <th>srcLandmark</th>
                                                <th>srcEmailId</th>
                                                <th>srcEmailIdAlt</th>
                                                <th>srcType</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {this.state.excelData.map((individualExcelData, i) => (
                                                <tr key={individualExcelData.conComplaintNo}>
                                                    {/* {(this.state.data.flow === "INSV1" && this.state.excelData[0].flowId === "InstallationV1") && this.state.exportFailedData === false &&
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-success pull-left"
                                                                data-toggle="modal"
                                                                data-target="#myTicketModal"
                                                            // onClick={() => this.handleOnTicket(individualExcelData)}
                                                            >
                                                                <i className={"fa  fa-pencil"}></i>
                                                            </button>
                                                        </td>
                                                    } */}
                                                    <td>
                                                        <span
                                                            className={
                                                                "badge badge-" + this.state.processStateCls[i]
                                                            }
                                                        >
                                                            {this.state.processState[i]}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span
                                                            className={
                                                                "badge badge-" + this.state.ticketStateCls[i]
                                                            }
                                                        >
                                                            {this.state.ticketState[i]}
                                                        </span>
                                                    </td>
                                                    <td>{individualExcelData.ticketPriority}</td>
                                                    <td>{individualExcelData.conComplaintNo}</td>
                                                    <td>{individualExcelData.flowId}</td>
                                                    <td>{individualExcelData.retailerCode}</td>
                                                    <td>{individualExcelData.retailerId}</td>
                                                    <td>{individualExcelData.retailerName}</td>
                                                    <td>{individualExcelData.productCode}</td>
                                                    <td>{individualExcelData.productId}</td>
                                                    <td>{individualExcelData.productName}</td>
                                                    <td>{individualExcelData.brandCode}</td>
                                                    <td>{individualExcelData.brandId}</td>
                                                    <td>{individualExcelData.brandName}</td>
                                                    <td>{individualExcelData.modelCode}</td>
                                                    <td>{individualExcelData.modelId}</td>
                                                    <td>{individualExcelData.modelName}</td>
                                                    <td>{individualExcelData.ticketType}</td>
                                                    {/* <td>{individualExcelData.srcGeoCodes}</td>
                        <td>{individualExcelData.dstGeoCodes}</td> */}
                                                    <td>{individualExcelData.isAppointmentReq}</td>
                                                    <td>{individualExcelData.isPhysicalEval}</td>
                                                    <td>{individualExcelData.isTechEval}</td>
                                                    <td>{individualExcelData.isPackingReq}</td>
                                                    <td>{individualExcelData.retailerNo}</td>
                                                    <td>{individualExcelData.invoiceNo}</td>
                                                    <td>{individualExcelData.orderNo}</td>
                                                    <td>{individualExcelData.ticketDetails}</td>
                                                    <td>{individualExcelData.specialInstructions}</td>
                                                    <td>{individualExcelData.dateOfPurchase}</td>
                                                    <td>{individualExcelData.identificationNo}</td>
                                                    <td>{individualExcelData.productDesc}</td>
                                                    <td>{individualExcelData.problemDesc}</td>
                                                    <td>{individualExcelData.productValue}</td>
                                                    <td>{individualExcelData.cost}</td>
                                                    <td>{individualExcelData.isUnderWarranty}</td>
                                                    <td>{individualExcelData.srcContactPerson}</td>
                                                    <td>{individualExcelData.srcAdd1}</td>
                                                    <td>{individualExcelData.srcAdd2}</td>
                                                    <td>{individualExcelData.srcLocation}</td>
                                                    <td>{individualExcelData.srcContact1}</td>
                                                    <td>{individualExcelData.srcContact2}</td>
                                                    <td>{individualExcelData.srcCity}</td>
                                                    <td>{individualExcelData.srcState}</td>
                                                    <td>{individualExcelData.srcPincode}</td>
                                                    <td>{individualExcelData.srcLandmark}</td>
                                                    <td>{individualExcelData.srcEmailId}</td>
                                                    <td>{individualExcelData.srcEmailIdAlt}</td>
                                                    <td>{individualExcelData.srcType}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table></div></>)}

                                    {this.state.data.flow === "DRPOF" && this.state.excelData[0].flowId === "DropOff" && (<><div className="table-responsive">
                                        <table className="table table-responsive">
                                            <thead>
                                                <tr>
                                                    {/* {(this.state.data.flow === "DRPOF" && this.state.excelData[0].flowId === "DropOff") && this.state.exportFailedData === false &&
                                                        <th>Edit</th>
                                                    } */}
                                                    <th>Action</th>
                                                    <th>Ticket No.</th>
                                                    <th>Ticket Priority</th>
                                                    <th>Consumer Complaint Number</th>
                                                    <th>Flow Id</th>
                                                    <th>Retailer Code</th>
                                                    <th>Retailer Id</th>
                                                    <th>Retailer Name</th>
                                                    <th>Product Code</th>
                                                    <th>Product Id</th>
                                                    <th>Product Name</th>
                                                    <th>Brand Code</th>
                                                    <th>Brand Id</th>
                                                    <th>Brand Name</th>
                                                    <th>Model Code</th>
                                                    <th>Model Id</th>
                                                    <th>Model Name</th>
                                                    <th>isAppointment Required</th>
                                                    <th>isInstallation Required</th>
                                                    <th>isPhysical Evaluation Required</th>
                                                    <th>isTechnical Evaluation Required</th>
                                                    <th>isPacking Required</th>
                                                    <th>retailerNo</th>
                                                    <th>invoiceNo</th>
                                                    <th>orderNo</th>
                                                    <th>ticketDetails</th>
                                                    <th>specialInstructions</th>
                                                    <th>dateOfPurchase</th>
                                                    <th>identificationNo</th>
                                                    <th>productDesc</th>
                                                    <th>problemDesc</th>
                                                    <th>productValue</th>
                                                    <th>cost</th>
                                                    <th>isUnderWarranty</th>
                                                    <th>srcContactPerson</th>
                                                    <th>srcAdd1</th>
                                                    <th>srcAdd2</th>
                                                    <th>srcLocation</th>
                                                    <th>srcContact1</th>
                                                    <th>srcContact2</th>
                                                    <th>srcCity</th>
                                                    <th>srcState</th>
                                                    <th>srcPincode</th>
                                                    <th>srcLandmark</th>
                                                    <th>srcEmailId</th>
                                                    <th>srcEmailIdAlt</th>
                                                    <th>srcType</th>
                                                    <th>dstContactPerson</th>
                                                    <th>dstAdd1</th>
                                                    <th>dstAdd2</th>
                                                    <th>dstLocation</th>
                                                    <th>dstContact1</th>
                                                    <th>dstContact2</th>
                                                    <th>dstCity</th>
                                                    <th>dstState</th>
                                                    <th>dstPincode</th>
                                                    <th>dstLandmark</th>
                                                    <th>dstEmailId</th>
                                                    <th>dstEmailIdAlt</th>
                                                    <th>dstType</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.excelData.map((individualExcelData, i) => (
                                                    <tr key={individualExcelData.conComplaintNo}>
                                                        {/* {(this.state.data.flow === "DRPOF" && this.state.excelData[0].flowId === "DropOff") && this.state.exportFailedData === false &&
                                                            <td>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-success pull-left"
                                                                    data-toggle="modal"
                                                                    data-target="#myTicketModal"
                                                                // onClick={() => this.handleOnTicket(individualExcelData)}
                                                                >
                                                                    <i className={"fa  fa-pencil"}></i>
                                                                </button>
                                                            </td>
                                                        } */}
                                                        <td>
                                                            <span
                                                                className={
                                                                    "badge badge-" + this.state.processStateCls[i]
                                                                }
                                                            >
                                                                {this.state.processState[i]}
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <span
                                                                className={
                                                                    "badge badge-" + this.state.ticketStateCls[i]
                                                                }
                                                            >
                                                                {this.state.ticketState[i]}
                                                            </span>
                                                        </td>
                                                        <td>{individualExcelData.ticketPriority}</td>
                                                        <td>{individualExcelData.conComplaintNo}</td>
                                                        <td>{individualExcelData.flowId}</td>
                                                        <td>{individualExcelData.retailerCode}</td>
                                                        <td>{individualExcelData.retailerId}</td>
                                                        <td>{individualExcelData.retailerName}</td>
                                                        <td>{individualExcelData.productCode}</td>
                                                        <td>{individualExcelData.productId}</td>
                                                        <td>{individualExcelData.productName}</td>
                                                        <td>{individualExcelData.brandCode}</td>
                                                        <td>{individualExcelData.brandId}</td>
                                                        <td>{individualExcelData.brandName}</td>
                                                        <td>{individualExcelData.modelCode}</td>
                                                        <td>{individualExcelData.modelId}</td>
                                                        <td>{individualExcelData.modelName}</td>
                                                        <td>{individualExcelData.isAppointmentReq}</td>
                                                        <td>{individualExcelData.isInstallationReq}</td>
                                                        <td>{individualExcelData.isPhysicalEval}</td>
                                                        <td>{individualExcelData.isTechEval}</td>
                                                        <td>{individualExcelData.isPackingReq}</td>
                                                        <td>{individualExcelData.retailerNo}</td>
                                                        <td>{individualExcelData.invoiceNo}</td>
                                                        <td>{individualExcelData.orderNo}</td>
                                                        <td>{individualExcelData.ticketDetails}</td>
                                                        <td>{individualExcelData.specialInstructions}</td>
                                                        <td>{individualExcelData.dateOfPurchase}</td>
                                                        <td>{individualExcelData.identificationNo}</td>
                                                        <td>{individualExcelData.productDesc}</td>
                                                        <td>{individualExcelData.problemDesc}</td>
                                                        <td>{individualExcelData.productValue}</td>
                                                        <td>{individualExcelData.cost}</td>
                                                        <td>{individualExcelData.isUnderWarranty}</td>
                                                        <td>{individualExcelData.srcContactPerson}</td>
                                                        <td>{individualExcelData.srcAdd1}</td>
                                                        <td>{individualExcelData.srcAdd2}</td>
                                                        <td>{individualExcelData.srcLocation}</td>
                                                        <td>{individualExcelData.srcContact1}</td>
                                                        <td>{individualExcelData.srcContact2}</td>
                                                        <td>{individualExcelData.srcCity}</td>
                                                        <td>{individualExcelData.srcState}</td>
                                                        <td>{individualExcelData.srcPincode}</td>
                                                        <td>{individualExcelData.srcLandmark}</td>
                                                        <td>{individualExcelData.srcEmailId}</td>
                                                        <td>{individualExcelData.srcEmailIdAlt}</td>
                                                        <td>{individualExcelData.srcType}</td>
                                                        <td>{individualExcelData.dstContactPerson}</td>
                                                        <td>{individualExcelData.dstAdd1}</td>
                                                        <td>{individualExcelData.dstAdd2}</td>
                                                        <td>{individualExcelData.dstLocation}</td>
                                                        <td>{individualExcelData.dstContact1}</td>
                                                        <td>{individualExcelData.dstContact2}</td>
                                                        <td>{individualExcelData.dstCity}</td>
                                                        <td>{individualExcelData.dstState}</td>
                                                        <td>{individualExcelData.dstPincode}</td>
                                                        <td>{individualExcelData.dstLandmark}</td>
                                                        <td>{individualExcelData.dstEmailId}</td>
                                                        <td>{individualExcelData.dstEmailIdAlt}</td>
                                                        <td>{individualExcelData.dstType}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div></>)}

                                    {(this.state.data.flow === "PND1W" && this.state.excelData[0].flowId !== "PickAndDropOneWay") &&
                                        (<div class="jumbotron jumbotron-fluid">
                                            <p class="lead text-center">Uploaded Excel Not Matched With The Selected Flow Kindly Check And Re-Upload</p>
                                        </div>)
                                    }
                                    {(this.state.data.flow === "PND2W" && this.state.excelData[0].flowId !== "PickAndDropTwoWay") &&
                                        (<div class="jumbotron jumbotron-fluid">
                                            <p class="lead text-center">Uploaded Excel Not Matched With The Selected Flow Kindly Check And Re-Upload</p>
                                        </div>)
                                    }
                                    {(this.state.data.flow === "ADV" && this.state.excelData[0].flowId !== "AdvanceExchange") &&
                                        (<div class="jumbotron jumbotron-fluid">
                                            <p class="lead text-center">Uploaded Excel Not Matched With The Selected Flow Kindly Check And Re-Upload</p>
                                        </div>)
                                    }
                                    {(this.state.data.flow === "BUYBACK" && this.state.excelData[0].flowId !== "BuyBack") &&
                                        (<div class="jumbotron jumbotron-fluid">
                                            <p class="lead text-center">Uploaded Excel Not Matched With The Selected Flow Kindly Check And Re-Upload</p>
                                        </div>)
                                    }
                                    {(this.state.data.flow === "INSV1" && this.state.excelData[0].flowId !== "InstallationV1") &&
                                        (<div class="jumbotron jumbotron-fluid">
                                            <p class="lead text-center">Uploaded Excel Not Matched With The Selected Flow Kindly Check And Re-Upload</p>
                                        </div>)
                                    }
                                    {(this.state.data.flow === "DRPOF" && this.state.excelData[0].flowId !== "DropOff") &&
                                        (<div class="jumbotron jumbotron-fluid">
                                            <p class="lead text-center">Uploaded Excel Not Matched With The Selected Flow Kindly Check And Re-Upload</p>
                                        </div>)
                                    }
                                    {this.state.exportFailedData === true && <ExportXLSXReqTrans
                                        csvData={this.state.csvReport.data}
                                        fileName={"Failed Data"}
                                    />}
                                </>
                            )}
                        </div>
                    </div>
                    <TicketUploadNewModal
                        onHandleGetFormData={this.handleGetFormData}
                        curData={this.state.curData}
                        key={this.state.curData["conComplaintNo"] + "" + this.state.flag}
                    />
                    <TicketSubUploadNewModal
                        onHandleGetFormData={this.handleGetFormData}
                    // tempExcelData={this.state.excelData}
                    // key={this.state.excelData["conComplaintNo"]}
                    />

                </>

            </React.Fragment>

        );

    }
}
