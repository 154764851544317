import React from "react";
import { toast } from "react-toastify";
import FormLib from "./../../_common/FormLib";
import Table from "./../../_common/Table";
import { IoBarcodeOutline, IoCopyOutline } from "react-icons/io5";
import BarCodePrintSVG from "./BarCodePrintSVG";

export default class PickUpVerificationTicket extends FormLib {
  state = {
    data: {},
    errors: {},
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
  };

  componentDidMount() {
    this.loadInit();
  }

  loadInit() {
    try {
      const curticketDetailsArr = [];
      const curBlockDetailsArr = [];
      const curTicketDetails = [
        ...curticketDetailsArr,
        this.props.ticketDetails,
      ];
      const curBlockDetails = [...curBlockDetailsArr, this.props.blockDetails];
      const curProductDetails = this.props.productDetails;
      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails });
    } catch {
      console.log("Error in PickUpVerificationTicket>>>>>>loadInit");
    }
  }

  tableMeta = {
    key: "ticketBlockId",
    columns: [
      {
        key: "ticketBlockId",
        actionKey: "bt",
        action: (r) => (
          <>
            <span
              className="badge badge-primary link-over"
              onClick={() => this.onBlockSelection(r)}
            >
              Confirm
            </span>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      {
        key: "ticketBlockId",
        actionKey: "sb",
        action: (r) => <>{this.rTB("Barcode", "tb_" + r["ticketId"], "")}</>,
        cusClass: " ",
        icon: () => <>Barcodes</>,
      },
      { path: "ticketBarcode", label: "Ticket Barcode" },
      { path: "ticketNo", label: "Ticket Number" },
    ],
  };

  onBlockSelection(r) {
    try {
      if (this.state.data["tb_" + r.ticketId] !== r.ticketBarcode) {
        toast.error(
          "Ticket Barcode Does not Match Kindly enter the correct Barcode"
        );
      } else {
        toast.success("Ticket Barcode Matched successfully");
      }
    } catch {
      console.log("Error in PickUpVerificationTicket>>>>>>onBlockSelection");
    }
  }

  render() {
    const ticketDetails = this.state.curTicketDetails;
    return (
      <>
        {" "}
        <div className="form-subHeader">
          Ticket PickedUp Barcode Verification
        </div>
        <div className="row">
          {this.props.loading && (
            <div className="container-fluid">
              <div> Loading Ticket...</div>
            </div>
          )}
          {!this.props.loading && (
            <>
              {/* <div className="container-fluid">
                            <Table
                                clsName="table table-striped table-bordered table-hover"
                                tableMeta={this.tableMeta}
                                data={ticketDetails}
                                dropMenu={[]}
                            />
                        </div> */}
            </>
          )}
        </div>
      </>
    );
  }
}
