import React from "react";
import FormLib from "./../../_common/FormLib";

class PrvFeatures extends FormLib {
  state = {
    data: {
      previlagesId: "",
      prvGroupId: "",
      prvCode: "",
      title: "",
      details: "",
      status: "active",
      sort: "",
      
    },
    errors: {},
      prvFeatures: [],
    //  curTitle:{},
  };
  isCur(id, cur) {
    if (id === cur) {
      return " curTasks ";
    }
    return " ";
  }
  
  render() {
    const prvFeatures = this.props.prvFeatures;
    const curGroup = this.props.curGroup;
    const curTitle = this.props.curTitle;

    return (
      <div className="card ">
        <h5 className="card-header">
          Prv Features
          {/* {JSON.stringify(curTitle)} */}
          {curGroup.prvGroupId && !curTitle.previlagesId && (
            <button
              type="button"
              className="btn btn-sm btn-primary pull-right"
              data-toggle="modal"
              data-target="#myFeatureModel"
            >
              <i className={"fa  fa-plus  "}></i>
            </button>
          )}
          {curTitle.previlagesId && curTitle.previlagesId !== "" && (
            <>
              <button
                type="button"
                className="btn btn-sm btn-warning pull-right"
                data-toggle="modal"
                onClick={() => this.props.onFeatureUnload(curTitle)}
              >
                <i className={"fa  fa-unlink  "}></i>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-success pull-right"
                data-toggle="modal"
                data-target="#myFeatureModel"
              >
                <i className={"fa  fa-pencil  "}></i>
              </button>

            
            </>
          )}
        </h5>
        <div className="card-body">
          <div className="page-todo">
            {prvFeatures.map((f) => (
              <div
                className={"task nil " + this.isCur(f.previlagesId, curTitle.previlagesId)}
                key={f.previlagesId}
              >
                <div className="desc">
                  <span
                    className="span-click"
                    onClick={() => this.props.onFeatureChange(f)}
                  >
                    <div className="title">{f.title}</div>
                  </span>
                </div>

                <div className="pull-right">
                  <div className="menu">
                    <span
                      className={
                        "badge   badge-success pull-left " +
                        "badge-" +
                        f.status
                      }
                    >
                      {f.sort + " : " + f.status}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default PrvFeatures;
