import React from "react";
import ZTitle from "../../_subhead/ZTitle";
import FormLib from "../../_common/FormLib";

import { uploadBill } from "./../../../services/misBilling/billServices";
import { toast } from "react-toastify";
import Table from "./../../_common/Table";
import { dropMenuFull } from "./../../../utils/DropMenuUtils";
import ReactTooltip from "react-tooltip";

class UploadBill extends FormLib {
  state = {
    // Initially, no file is selected
    selectedFile: null,
    failureTickets: [],
    successTickets: [],
    updateTickets: [],
  };

  // On file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  };

  // On file upload (click the upload button)
  async onFileUpload() {
    const uploadedBill = await uploadBill(this.state.selectedFile);
    // console.log("File uploaded " + JSON.stringify(uploadedBill));
    if (uploadedBill.data.success === true) {
      this.setState({ selectedFile: null });
      document.getElementById("fileUpload").reset();
      this.setState({ failureTickets: uploadedBill.data.data.failTicket });
      this.setState({ successTickets: uploadedBill.data.data.successTicket });
      this.setState({ updateTickets: uploadedBill.data.data.updateTicket });
      // console.log("----->" + this.state.failureTickets);
      toast.success(() => (
        <div>
          {uploadedBill.data.data.successTicketCount} {uploadedBill.data.msg}
        </div>
      ));
    } else {
      toast.error(() => <div>{uploadedBill.data.msg}</div>);
    }
  }

  // File content to be displayed after
  // file upload is complete
  fileData = () => {
    if (this.state.selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>

          <p>File Name: {this.state.selectedFile.name}</p>

          {/* <p>File Type: {this.state.selectedFile.type}</p> */}

          <p>
            Last Modified:{" "}
            {this.state.selectedFile.lastModifiedDate.toDateString()}
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <br />
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  };

  tableMeta = {
    key: "rlTicketNo",
    columns: [
      { path: "rlTicketNo", label: "Ticket Number" },
      { path: "billStatus", label: "Reason" },
    ],
  };

  stringReplace(data) {
    // console.log("---------------------------->" + data.replace(/"/g, " "));
    return data.replace(/"/g, " ").replace("[", "").replace("]", "");
  }

  render() {
    const failureJSON = this.state.failureTickets;
    const successJSON = this.state.successTickets;
    const updateJSON = this.state.updateTickets;

    return (
      <React.Fragment>
        <ZTitle subHeading="MIS  :: Bill Upload" />
        <div className="pageContent">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <form id="fileUpload">
                  <input
                    type="file"
                    accept=".xlsx"
                    className="btn btn-primary btn-sm"
                    onChange={this.onFileChange}
                  />
                </form>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => this.onFileUpload()}
                >
                  Upload
                </button>
              </div>
              {this.fileData()}
              <br />
              {failureJSON.length > 0 && (
                <div className="row">
                  <h2>Failure Tickets: [{failureJSON.length}]</h2>
                  {this.props.loading && (
                    <div className="container-fluid">
                      <div> Loading users...</div>
                    </div>
                  )}
                  {!this.props.loading && (
                    <div className="container-fluid">
                      <Table
                        clsName="table table-striped table-bordered table-hover"
                        tableMeta={this.tableMeta}
                        data={failureJSON}
                        dropMenu={dropMenuFull()}
                      />
                    </div>
                  )}
                </div>
              )}
              <br />
              {successJSON.length > 0 && (
                <div className="row">
                  <button
                    className="btn btn-success btn-sm"
                    data-tip
                    data-for="successTip"
                  >
                    Success Tickets [{successJSON.length}]
                  </button>

                  <ReactTooltip id="successTip" place="right" effect="solid">
                    {this.stringReplace(JSON.stringify(successJSON))}
                  </ReactTooltip>
                </div>
              )}
              <br />
              {updateJSON.length > 0 && (
                <div className="row">
                  <button
                    className="btn btn-secondary btn-sm"
                    data-tip
                    data-for="updatedTip"
                  >
                    Updated Tickets [{updateJSON.length}]
                  </button>

                  <ReactTooltip id="updatedTip" place="right" effect="solid">
                    {this.stringReplace(JSON.stringify(updateJSON))}
                  </ReactTooltip>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default UploadBill;
