import React from "react";
import FormLib from "./../../_common/FormLib";

import ProjectModules from "./ProjectModules";
import ProjectUserStories from "./ProjectUserStories";
import ProjectTasks from "./ProjectTasks";
import ProjectTasksInfo from "./ProjectTasksInfo";

import ModalProject from "./ModalProject";
import ModalModule from "./ModalModule";
import ModalUserstory from "./ModalUserstory";
import ModalTasks from "./ModalTask";
import ModalReport from "./ModalReport";
import ModalDayPlan from "./ModalDayPlan";

import toast from "../../../services/_toastService";

import { getProjects } from "./../../../services/plms/projectServices";
import { getModules } from "./../../../services/plms/moduleServices";
import {
  getUserstories,
  getUserstoriesByModuleIds,
} from "./../../../services/plms/userstoriesServices";
import { getTasks } from "./../../../services/plms/taskServices";

class StoryBoard extends FormLib {
  state = {
    data: { projects: "" },
    errors: {},
    projects: [],
    modules: [],
    userstories: [],
    tasks: [],
    curProject: { projectId: "" },
    curModule: {},
    curModulesList: [],
    curUserstory: {},
    curTask: {},
    isTaskShow: true,
    isProjectShow: true,
  };
  schema = {};

  toggleIsTaskShow = () => {
    if (this.state.isTaskShow) {
      this.setState({ isTaskShow: false });
    } else {
      this.setState({ isTaskShow: true });
    }
  };

  toggleIsProjectShow = () => {
    if (this.state.isProjectShow) {
      this.setState({ isProjectShow: false });
    } else {
      this.setState({ isProjectShow: true });
    }
  };

  componentDidMount() {
    this.loadProjects();
  }

  loadProjects = async (m) => {
    const res = await getProjects();
    if (res.data.success === true) {
      this.setState({ projects: res.data.data.projects });
      //toast.toastSuccess("Project Loaded Successfully");
    } else {
      toast.toastError("failed");
    }
  };

  loadModules = async (projectId, modReset = true) => {
    if (modReset === true) {
      this.setState({ userstories: [], tasks: [] });
      this.setState({ curUserstory: {}, curTask: {} });
      this.setState({ curModule: {} });
      this.setState({ curModulesList: [] });
    }
    let curProject;
    const filter = this.state.projects.filter((r) => {
      return r.projectId === projectId ? r : null;
    });

    if (!filter[0]) {
      curProject = { projectId: "" };
    } else {
      curProject = filter[0];
    }

    this.setState({ curProject: curProject });

    if (projectId !== "") {
      const res = await getModules(projectId);
      this.setState({ modules: res.data.data.projectModules });
    } else {
      this.setState({ modules: [] });
    }
    this.setState({ data: { projects: curProject.projectId } });
  };

  setCurModules = (m) => {
    let curModules = [...this.state.curModulesList];

    if (curModules.indexOf(m["moduleId"]) >= 0) {
      curModules.splice(curModules.indexOf(m["moduleId"]), 1);
    } else {
      curModules.push(m["moduleId"]);
    }

    this.setState({ curModulesList: curModules });
    return curModules;
  };

  loadUserstories = async (m, modReset = "true") => {
    let curModules = [...this.state.curModulesList];
    if (modReset === "true") {
      this.setState({ tasks: [] });
      this.setState({ curModule: m });
      this.setState({ curUserstory: {} });
      curModules = this.setCurModules(m);
    }
    if (modReset === "downwards") {
      this.setState({ tasks: [] });
      this.setState({ curModule: m });
      this.setState({ curUserstory: {} });
    }
    // console.log(JSON.stringify(m));
    if (curModules.length > 0) {
      // console.log(1234);
      const res = await getUserstoriesByModuleIds(curModules);
      this.setState({ userstories: res.data.data.userstories });
    } else {
      // console.log(4321);
      this.setState({ userstories: [] });
    }
  };

  loadTasks = async (u, modReset = true) => {
    if (modReset === true) {
      this.setState({ curUserstory: u });
      this.setState({ curTask: {} });
    }

    if (u["userstoryId"] !== "") {
      const res = await getTasks(u["userstoryId"]);
      this.setState({ tasks: res.data.data.tasks });
    } else {
      this.setState({ tasks: [] });
    }
  };

  handleProjectChange(e) {
    this.loadModules(e.target.value);
  }

  handleModuleChange = (m) => {
    this.loadUserstories(m);
  };
  handleUserstoryChange = (u) => {
    this.loadTasks(u);
  };
  handleTaskChange = (t) => {
    this.setState({ curTask: t });
  };

  handleModuleUnload = () => {
    this.setState({ userstories: [], tasks: [] });
    this.setState({
      curModule: {},
      curModulesList: [],
      curUserstory: {},
      curTask: {},
    });
  };
  handleUserstoryUnload = () => {
    this.setState({ tasks: [] });
    this.setState({ curUserstory: {}, curTask: {} });
  };
  handleTaskUnload = () => {
    this.setState({ curTask: {} });
  };

  localHandleChange = (e) => {
    if (e.target.id === "projects") {
      this.handleProjectChange(e);
    }
  };
  isCur(id, cur) {
    if (id === cur) {
      return " curTasks ";
    }
    return " ";
  }
  render() {
    try {
      return (
      <React.Fragment>
        <div className="pageContent">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10">
                {this.rSB("Projects", "projects", this.state.projects, {
                  value: "projectId",
                  label: "project",
                })}
              </div>
              <div className="menu col-md-2 ">
                {this.state.curProject.projectId === "" && (
                  <button
                    type="button"
                    className="btn btn-sm btn-info pull-left"
                    data-toggle="modal"
                    data-target="#myProjectModel"
                  >
                    <i className={"fa fa-plus"} />
                  </button>
                )}
                {this.state.curProject.projectId !== "" && (
                  <button
                    type="button"
                    className="btn btn-sm btn-success pull-left in-line"
                    data-toggle="modal"
                    data-target="#myProjectModel"
                  >
                    <i className={"fa fa-pencil"} />
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-sm btn-secondary pull-left"
                  onClick={() => this.loadProjects()}
                >
                  <i className={"fa fa-refresh"} />
                </button>

                <button
                  type="button"
                  className="btn btn-sm btn-warning pull-right"
                  onClick={() => this.toggleIsTaskShow()}
                >
                  T
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-warning pull-right"
                  onClick={() => this.toggleIsProjectShow()}
                >
                  P
                </button>
              </div>
            </div>

            <div className="card-deck">
              {this.state.isProjectShow && (
                <div className="card  col-2" style={{ margin: 0, padding: 2 }}>
                  <h5 className="card-header"></h5>
                  <div className="card-body" style={{ padding: 2 }}>
                    <div className="page-todo">
                      <div className="tasks">
                        {this.state.projects.map(
                          (pr) =>
                            pr.status === "active" && (
                              <div
                                key={pr.projectId}
                                className={
                                  "task nil " +
                                  this.isCur(
                                    pr.projectId,
                                    this.state.curProject.projectId
                                  )
                                }
                              >
                                <div
                                  className="desc"
                                  style={{ width: "100%", fontSize: "10px" }}
                                >
                                  <span
                                    className="span-click"
                                    onClick={() =>
                                      this.loadModules(pr.projectId)
                                    }
                                  >
                                    <div
                                      className="title"
                                      style={{ fontSize: "10px" }}
                                    >
                                      {pr.project}
                                    </div>
                                  </span>
                                </div>
                              </div>
                            )
                        )}
                      </div>
                      {/* 
                      {this.state.projects.map((mi) => (
                        <div
                          className={
                            "task nil " + this.loadModules(mi.projectId)
                          }
                          key={mi.projectId}
                        >
                          <div className="desc">
                            <span
                              className="span-click"
                              onClick={() => this.loadModules(mi.projectId)}
                            >
                              <div className="title">{"prModule"}</div>
                            </span>
                            <div>{"modOwner"}</div>
                          </div>
                        </div>
                      ))}
                     */}
                    </div>
                  </div>
                </div>
              )}
              <ProjectModules
                modules={this.state.modules}
                curProject={this.state.curProject}
                curModule={this.state.curModule}
                curModulesList={this.state.curModulesList}
                onModuleChange={this.handleModuleChange}
                onModuleUnload={this.handleModuleUnload}
              />
              <ProjectUserStories
                userstories={this.state.userstories}
                curModule={this.state.curModule}
                curUserstory={this.state.curUserstory}
                onUserstoryChange={this.handleUserstoryChange}
                onUserstoryUnload={this.handleUserstoryUnload}
              />

              {this.state.isTaskShow && (
                <ProjectTasks
                  tasks={this.state.tasks}
                  curUserstory={this.state.curUserstory}
                  curTask={this.state.curTask}
                  onTaskChange={this.handleTaskChange}
                  onTaskUnload={this.handleTaskUnload}
                />
              )}
            </div>
            <div className="row">
              {this.state.curTask.taskId && (
                <ProjectTasksInfo
                  tasks={this.state.tasks}
                  curUserstory={this.state.curUserstory}
                  curTask={this.state.curTask}
                  onTaskUnload={this.handleTaskUnload}
                  key={
                    "pti_" +
                    (this.state.curTask["taskId"] ||
                      this.state.curUserstory["userstoryId"])
                  }
                />
              )}
            </div>
          </div>
        </div>
        <>
          <ModalProject
            curProject={this.state.curProject}
            onloadProjects={this.loadProjects}
            key={this.state.curProject["projectId"]}
          />
          <ModalModule
            projects={this.state.projects}
            curProject={this.state.curProject}
            curModule={this.state.curModule}
            onLoadModules={this.loadModules}
            key={
              this.state.curModule["moduleId"] ||
              "m_" + this.state.curProject["projectId"]
            }
          />
          <ModalUserstory
            modules={this.state.modules}
            curModule={this.state.curModule}
            curUserstory={this.state.curUserstory}
            onLoadUserstories={this.loadUserstories}
            key={
              this.state.curUserstory["userstoryId"] ||
              "u_" + this.state.curModule["moduleId"]
            }
          />
          <ModalTasks
            userstories={this.state.userstories}
            curUserstory={this.state.curUserstory}
            curTask={this.state.curTask}
            onLoadTasks={this.loadTasks}
            key={
              "mt_" +
              (this.state.curTask["taskId"] ||
                this.state.curUserstory["userstoryId"])
            }
          />
          <ModalReport
            userstories={this.state.userstories}
            curUserstory={this.state.curUserstory}
            curTask={this.state.curTask}
            key={
              "mr_" +
              (this.state.curTask["taskId"] ||
                this.state.curUserstory["userstoryId"])
            }
          />
          <ModalDayPlan
            userstories={this.state.userstories}
            curUserstory={this.state.curUserstory}
            curTask={this.state.curTask}
            key={
              "mdp_" +
              (this.state.curTask["taskId"] ||
                this.state.curUserstory["userstoryId"])
            }
          />
        </>
      </React.Fragment>
    );
    } catch (error) {
      return null;
    }
    
  }
}
export default StoryBoard;
