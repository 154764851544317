import React from "react";
import { connect } from "react-redux";

import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import { ticketListReload } from "../../../store";

import { setTicketActions } from "./../../../services/Tickets/TicketProcessServices";
import { toast } from "react-toastify";
import { IstToUTC } from "../../../utils/DateUtils";

class CustomerAvailability extends FormLib {
  state = {
    data: {
      customer_availability: "",
    },
    customer_availability: [
      {
        customer_availability: "Available",
        customer_availabilityId: "AVAILABLE",
      },
      {
        customer_availability: "Reschedule",
        customer_availabilityId: "RESCHEDULE",
      },
      {
        customer_availability: "Reject Service",
        customer_availabilityId: "REJECT_SERVICE",
      },
    ],
    errors: {},
    curticketDetails: {},
    curblockDetails: {},
    actionCode: "wAptmCusAvailable",
    action: "Checking Consumer Availability",
  };

  schema = {
    customer_availability: Joi.string()
      .required()
      .label("Customer Availability"),
  };

  componentDidMount() {
    this.loadInit();
    this.getlocalDateTime();
  }

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }

  loadInit() {
    const curticketDetails = this.props.ticketDetails;
    const curblockDetails = this.props.blockDetails;

    this.setState({ curticketDetails });
    this.setState({ curblockDetails });
  }

  async doSubmit() {
    let override = { ...this.state.data };
    override["actionCode"] = "wAptmCusAvailable";
    override["action"] = "Checking Consumer Availability";
    // this.setState({ override });
    let resultSet = {
      isAvailable: this.state.data.customer_availability,
      actionStatus: this.state.data.customer_availability,
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    // console.log("cameeeeeeeeeeee " + JSON.stringify(this.state.data));
    // console.log("Block Details " + JSON.stringify(this.state.curblockDetails));

    let resp = await setTicketActions(
      override,
      this.state.curticketDetails,
      this.state.curblockDetails,
      resultSet
    );

    if (resp.data.success === true) {
      resp.data.data && this.handleTicketListReload();
      !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
    } else {
      toast.error(() => <div>{resp.data.msg}</div>);
    }
  }

  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };

  render() {
    return (
      <>
        <div className="row mt-4 ">
          <div id="coAction" className="col-md-12">
            <div className="card ">
              <div className="card-body  bg-light">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-row mt-5">
                    {this.rSB(
                      "Customer Availability *",
                      "customer_availability",
                      this.state.customer_availability,
                      {
                        value: "customer_availabilityId",
                        label: "customer_availability",
                      },
                      " col-md-4 "
                    )}
                  </div>
                  {this.rBtnLP(
                    "Submit",
                    "Submit",
                    "btn btn-primary btn-sm pull-right"
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerAvailability);
