import React from "react";

import FormLib from "../../_common/FormLib";
import $ from "jquery";

class CostCentersForm extends FormLib {
  state = {
    data: { costcentername: "" },
  };

  schema = {};
  willChange() {
    let _colors = $("._select_color_drop li");
    for (var i = _colors.length - 1; i >= 0; i--) {
      $(_colors[i]).click(function () {
        var color_text = $(this).find("span").attr("_text_display");
        var elemnt = $(this).closest("._select_color_drop").prev();
        elemnt.find("span.color").remove();
        $(this).find("span").clone().appendTo(elemnt);
        var contents = $(elemnt).contents();
        if (contents.length > 0) {
          if (contents.get(0).nodeType === Node.TEXT_NODE) {
            $(elemnt).html(color_text).append(contents.slice(1));
          }
        }
        if ($("[name=_color]").val() === undefined) {
          elemnt
            .next()
            .append(
              "<input type='hidden' name='_color' value='" + color_text + "'>"
            );
        } else {
          $("[name=_color]").val(color_text);
        }
      });
    }
  }

  subHeading = "User";
  state = {
    data: {
      username: "",
      password: "",
      pickupDate: this.getDatebyDays(1),
      pickupTime: "14:00",
    },
    errors: {},
  };
  colors = [
    { key: "blue", value: "Blue" },
    { key: "orange", value: "Orange" },
    { key: "red", value: "Red" },
    { key: "green", value: "Green" },
  ];

  facilities = [
    { label: "Active", value: "Active" },
    { label: "InActive", value: "InActive" },
  ];

  render() {
    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            <form onSubmit={this.handleSubmit}>
              <div className="form-row">
                {this.rTB("Cost Center Name", "costcentername", "col-md-12")}
              </div>
              <hr />
              {/*{this.rSB("Choose Color", "name",this.colors,"col-md-2")}*/}
              <div className="row">
                <div className="col-md-12">
                  <label for="" className="">
                    Choose Color
                  </label>
                  <div className="dropdown">
                    <button
                      className="btn _select_color dropdown-toggle"
                      type="button"
                      id="dropdownMenu1"
                      onClick={this.willChange}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Green
                      <span
                        _text_display="Green"
                        className="color green"
                      ></span>
                    </button>
                    <ul
                      className="dropdown-menu _select_color_drop"
                      aria-labelledby="dropdownMenu1"
                    >
                      <li>
                        <span
                          _text_display="Green"
                          className="color green"
                        ></span>
                      </li>
                      <li>
                        <span _text_display="Red" className="color red"></span>
                      </li>
                      <li>
                        <span
                          _text_display="Yellow"
                          className="color yellow"
                        ></span>
                      </li>
                      <li>
                        <span
                          _text_display="Brown"
                          className="color brown"
                        ></span>
                      </li>
                      <li>
                        <span
                          _text_display="Orange"
                          className="color orange"
                        ></span>
                      </li>
                      <li>
                        <span
                          _text_display="Pink"
                          className="color pink"
                        ></span>
                      </li>
                      <li>
                        <span
                          _text_display="Silver"
                          className="color silver"
                        ></span>
                      </li>
                      <li>
                        <span
                          _text_display="Bule"
                          className="color blue"
                        ></span>
                      </li>
                      <li>
                        <span
                          _text_display="TEAL"
                          className="color TEAL"
                        ></span>
                      </li>
                      <li>
                        <span
                          _text_display="NAVY"
                          className="color NAVY"
                        ></span>
                      </li>
                      <li>
                        <span
                          _text_display="PURPLE"
                          className="color PURPLE"
                        ></span>
                      </li>
                      <li>
                        <span
                          _text_display="OLIVE"
                          className="color OLIVE"
                        ></span>
                      </li>
                      <li>
                        <span
                          _text_display="LIME"
                          className="color LIME"
                        ></span>
                      </li>
                      <input type="hidden" name="_color" value="Green" />
                    </ul>
                  </div>
                </div>
              </div>

              <hr />
              <div className="form-row col-md-6">
                {this.rRBL("Status", "status", this.facilities)}
              </div>

              <div className="button-container">
                <button type="button" className="button">
                  <span>Submit</span>
                </button>
              </div>

              <div className="form-group col-md-12 text-center">
                <p className="mt-5 mb-3 text-muted">&copy; Bizlog</p>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default CostCentersForm;
