import React from "react";
import { Provider } from "react-redux";

import { ToastContainer } from "react-toastify";

import store from "./store/store";

import "./assets/style.css";
import "react-toastify/dist/ReactToastify.css";

import "./assets/formStyle.css";
import "./assets/widgetTasks.css";
import "./assets/co.css";
// import "./assets/widgetCardList.css";

import AppGate from "./components/_theme/AppGate";

function App() {
  return (
    <React.Fragment>
      <Provider store={store}>
        <ToastContainer />

        <AppGate />
      </Provider>
    </React.Fragment>
  );
}

export default App;
