import React, { Component } from "react";
import _auth from "../../../services/_authService";
import LocationTitle from "../../_subhead/LocationTitle";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import { changePasswords } from "../../../services/players/playerServices";

class changePassword extends FormLib {
  state = {
    data: {
      newPassword: "",
      comfirmPassword: "",
    },
    curPlayer: {},
    errors: {},
  };

  componentDidMount() {
    const user = _auth.getCurrentUser();
    this.setState({ user });
  }
  schema = {
    newPassword: Joi.string().required().label("Password "),
    comfirmPassword: Joi.any()
      .valid(Joi.ref("newPassword"))
      .required()
      .label("Comfirm Password ")
      .options({ language: { any: { allowOnly: "must match password" } } }),
  };

  doSubmit = async () => {
    let res;
    if (this.state.btnValue === "create") {
      res = await changePasswords(this.state.data, this.state.user);
    }
    if (res.data.success) {
      toast.toastSuccess(res.data.msg);
      this.resetData();
    } else {
      toast.toastError(res.data.msg);
    }
  };

  resetData = () => {
    this.setState({
      data: {
        newPassword: "",
        comfirmPassword: "",
      },
    });
  };
  render() {
    const { subHeading } = this.props;
    const user = this.state.user;
    // const { firstname, lastname } = this.state.user;
    return (
      <React.Fragment>
        <LocationTitle />
        <div className="pageContent">
          <div className="container-fluid">
            <br />
            {user && (
              <div className="card">
                <div
                  className="card-body col-5"
                  style={{ "background-color": "#ebdacd" }}
                >
                  <span
                    style={{
                      color: "Green",
                      fontSize: "2rem",
                      paddingBottom: "40%",
                    }}
                  >
                    Change Password ::
                  </span>

                  <form>
                    <b className="mb-6">
                      {" "}
                      {user.firstname} {user.lastname}{" "}
                    </b>

                    <div className="form-row mt-3">
                      {this.rPB("Password", "newPassword", "col-12 ")}
                      <br /> <br />
                      {this.rPB(
                        "Comfirm Password",
                        "comfirmPassword",
                        "col-12"
                      )}
                    </div>
                  </form>
                  <br />
                  {this.rBtnSP("Submit", "create")}
                  <button
                    type="button"
                    className="btn btn-sm btn-warning pull-right"
                    onClick={() => this.resetData()}
                    title="Reset"
                  >
                    <i className={"fa fa-unlink"} />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default changePassword;
