import React from "react";
import FormLib from "../../_common/FormLib";
import Table from "../../_common/Table";
import { setTicketActions } from "./../../../services/Tickets/TicketProcessServices";
import { toast } from "react-toastify";
import { IstToUTC } from "../../../utils/DateUtils";
import {
  getItemListByStoreBays,
  getItemStockListByHubId,
  getStockByItem,
} from "./../../../services/stockManagement/inwardInventoryServices";
import Joi from "joi-browser";
import { cond } from "lodash";

export default class InventoryBlock extends FormLib {
  state = {
    data: {},
    errors: {},
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    curProdDesc: "",
    storeBays: [],
    itemsStockList: [],
    itemsStockListInfo: [],
    storeBaysId: "",
    qnt: "",
    selectedStockItems: [],
  };
  // includeInventory = [{ sortKey: "yes", sort: "Need Inventory??" }];
  // schema = {
  //   includeInventory: Joi.string().optional().label("includeInventory"),
  // };
  componentDidMount() {
    this.loadInit();
    this.loadstoreBays();
    this.getlocalDateTime();
  }
  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }
  loadstoreBays = async () => {
    try {
      let curHub = localStorage.getItem("curHub");
      let res = await getItemStockListByHubId(curHub);
      if (res.data.data) {
        this.setState({ storeBays: res.data.data.storeBaysId });
      }
    } catch {
      console.log("Error in InventoryBlock>>>>>>>loadstoreBays");
    }
  };

  loadItems = async (storeBaysId) => {
    try {
      let curRetailer = this.props.ticketDetails.retailerId;
      let res = await getItemListByStoreBays(curRetailer, storeBaysId);
      if (res.data.data) {
        this.setState({ itemsStockList: res.data.data.itemsList });
      }
    } catch {
      console.log("Error in InventoryBlock>>>>>>>loadStores");
    }
  };

  loadItemsStock = async (storeBaysId, itemId) => {
    try {
      let res = await getStockByItem(storeBaysId, itemId);
      if (res.data.data) {
        this.setState({ itemsStockListInfo: res.data.data.itemsStockList });
      }
    } catch {
      console.log("Error in InventoryBlock>>>>>>>loadStores");
    }
  };

  loadInit() {
    const curTicketDetails = this.props.ticketDetails;
    const curBlockDetails = this.props.blockDetails;
    const curProductDetails = this.props.productDetails;
    const curProdDesc = curProductDetails[0]["productDesc"];
    this.setState({ curTicketDetails });
    this.setState({ curBlockDetails });
    this.setState({ curProductDetails });
    this.setState({ curProdDesc });
  }

  tableMetaInventory = {
    key: "itemId",
    columns: [
      {
        key: "itemId",
        actionKey: "bt",
        action: (r) => (
          <>
            <span
              className="badge badge-primary link-over"
              onClick={() => this.onBlockSelection(r)}
            >
              Add
            </span>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },

      {
        key: "itemId",
        actionKey: "sb",
        action: (r) => (
          <>
            {r.isSerial === "no" && (
              <>{this.rNBm("Quantity ", "qnt_" + r["itemId"], "col-5")}</>
            )}
            {r.isSerial === "yes" && (
              <>
                <>{this.rTB("Serial", "serial_" + r["itemId"], "col-6")} </>
              </>
            )}
          </>
        ),
        label: "Quantity ",
        icon: () => <>Quantity / Serial</>,
        cusClass: " ",
      },
      { path: "stock", label: "Stock" },
      { path: "storeBaysId", label: "storeBaysId" },
    ],
  };

  onBlockSelection = async (r) => {
    if (!this.state.data["qnt_" + r["itemId"]]) {
      this.state.data["qnt_" + r["itemId"]] = "1";
    }
    if (r.isSerial === "yes" && this.state.data["serial_" + r["itemId"]]) {
      this.state.data["qnt_" + r["itemId"]] = "1";
      const filteredInvList = this.state.selectedStockItems.filter((inv) => {
        return (
          inv.itemId === r.itemId &&
          inv.storeBaysId === r.storeBaysId &&
          inv.serial === this.state.data["serial_" + r["itemId"]]
        );
      });
      if (filteredInvList.length === 1) {
        const invListNew = this.state.selectedStockItems.map((inv) => {
          return inv.itemId === r.itemId &&
            inv.storeBaysId === r.storeBaysId &&
            inv.serial === this.state.data["serial_" + r["itemId"]]
            ? {
                itemId: r.itemId,
                item: r.item,
                storeBaysId: r.storeBaysId,
                qnt: this.state.data["qnt_" + r["itemId"]],
                serial: this.state.data["serial_" + r["itemId"]],
                validation: {
                  isReq: "yes",
                },
              }
            : inv;
        });
        this.setState({ selectedStockItems: invListNew });
      } else {
        let stockItem = {
          itemId: r.itemId,
          item: r.item,
          storeBaysId: r.storeBaysId,
          qnt: this.state.data["qnt_" + r["itemId"]],
          serial: this.state.data["serial_" + r["itemId"]],
          validation: {
            isReq: "yes",
          },
        };
        this.state.selectedStockItems.push(stockItem);
        this.setState({ selectedStockItems: this.state.selectedStockItems });
      }
    } else if (r.isSerial === "no") {
      this.state.data["serial_" + r["itemId"]] = "";
      const filteredInvList = this.state.selectedStockItems.filter((inv) => {
        return (
          inv.itemId === r.itemId &&
          inv.storeBaysId === r.storeBaysId &&
          inv.serial === this.state.data["serial_" + r["itemId"]]
        );
      });
      if (filteredInvList.length === 1) {
        const invListNew = this.state.selectedStockItems.map((inv) => {
          return inv.itemId === r.itemId &&
            inv.storeBaysId === r.storeBaysId &&
            inv.serial === this.state.data["serial_" + r["itemId"]]
            ? {
                itemId: r.itemId,
                item: r.item,
                storeBaysId: r.storeBaysId,
                qnt: this.state.data["qnt_" + r["itemId"]],
                serial: this.state.data["serial_" + r["itemId"]],
                validation: {
                  isReq: "yes",
                },
              }
            : inv;
        });
        this.setState({ selectedStockItems: invListNew });
      } else {
        let stockItem = {
          itemId: r.itemId,
          item: r.item,
          storeBaysId: r.storeBaysId,
          qnt: this.state.data["qnt_" + r["itemId"]],
          serial: this.state.data["serial_" + r["itemId"]],
          validation: {
            isReq: "yes",
          },
        };
        this.state.selectedStockItems.push(stockItem);
        this.setState({ selectedStockItems: this.state.selectedStockItems });
      }
    } else {
      toast.error("Enter proper Data");
    }
  };
  handleRemoveItem = (item) => {
    let selectedStockItems = this.state.selectedStockItems.filter(
      (inv) =>
        inv.itemId + inv.storeBaysId + inv.serial !==
        item.itemId + item.storeBaysId + item.serial
    );
    this.setState({ selectedStockItems: selectedStockItems });
  };
  handleInventorySubmit = async () => {
    try {
      let override = { ...this.state.data };
      override["actionCode"] = "wInventoryBlockAssign";
      override["action"] = "Assign Inventory to Block";
      // this.setState({ override });
      let resultSet = {
        jobInventory: this.state.selectedStockItems,
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        ticketBlockId: this.state.curBlockDetails.ticketBlockId,
        actionStatus: "BLOCK_INVENTORY_ASSIGNED",
      };

      let resp = await setTicketActions(
        override,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        resultSet,
        this.state.curTicketDetails.ticketProductId
      );
      if (resp.data.success === true) {
        resp.data.data &&
          resp.data.data &&
          toast.success(() => <div>{resp.data.msg}</div>);
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in Inventory Block");
    }
  };

  localHandleChange = async (e) => {
    try {
      if (e.target.name === "materialType") {
        if (e.target.value) {
          let materialType = e.target.value;
          let res = { ...this.state.data };
          res["hubId"] = localStorage.getItem("curHub");
          res["materialType"] = materialType;
          await this.getBarcode(res, this.state.pageLinks.pageNo);
        } else {
          let res = { ...this.state.data };
          res["hubId"] = localStorage.getItem("curHub");
          res["materialType"] = "all";
          await this.getBarcode(res, this.state.pageLinks.pageNo);
        }
      }
      if (e.target.name === "baysType") {
        let storeBaysId = e.target.value;
        this.loadItems(storeBaysId);
        this.setState({ storeBaysId });
      }
      if (e.target.name === "itemType") {
        let itemId = e.target.value;
        this.loadItemsStock(this.state.storeBaysId, itemId);
      }
    } catch {
      console.log("Error in PackingAssignment>>>>>>localHandleChange");
    }
  };

  render() {
    const productDetails = this.state.curProductDetails;
    const curProdDesc = this.state.curProdDesc;
    return (
      <div className="col-12">
        {/* <div className="form-subHeader">Products Barcodes</div> */}
        {this.props.loading && (
          <div className="container-fluid">
            <div> Loading Tickets...</div>
          </div>
        )}
        {!this.props.loading && (
          <>
            <div className="container-fluid mt-5">
              {/* {this.state.curTicketDetails.retailerCode === "DRP" && ( */}

              <>
                {/* {this.rCBHL("includeInventory", this.includeInventory, {
                  value: "sortKey",
                  label: "sort",
                })} */}
                {this.state.data.includeInventory === "yes" && (
                  <>
                    <div className="form-subHeader mt-5">
                      <b>
                        {"Job Inventory :: "} {curProdDesc}
                      </b>
                    </div>

                    <div className="form-row mt-5">
                      {this.rSB(
                        "Store Bay's :",
                        "baysType",
                        this.state.storeBays,
                        { value: "storeBaysId", label: "baysName" },
                        " col-3 mt-2"
                      )}
                      {this.rSB(
                        "Items Stock List :",
                        "itemType",
                        this.state.itemsStockList.filter(
                          (item) => !item.item.startsWith("used")
                        ),
                        { value: "itemId", label: "item" },
                        " col-3 mt-2"
                      )}
                      <div className="col-8 mt-2">
                        {this.state.itemsStockListInfo && (
                          <Table
                            clsName="table table-striped table-bordered table-hover"
                            tableMeta={this.tableMetaInventory}
                            data={this.state.itemsStockListInfo}
                            dropMenu={[]}
                          />
                        )}
                      </div>

                      <div className="col-4 mt-2">
                        <table className="table  table-striped m-1 p-0 border">
                          <tbody>
                            {this.state.selectedStockItems.map((inv) => {
                              return (
                                <tr
                                  key={
                                    inv.itemId + inv.storeBaysId + inv.serial
                                  }
                                  className="m-0 p-0"
                                >
                                  <td>{inv?.item}</td>
                                  <td>{inv?.storeBaysId}</td>
                                  <td>{inv?.serial}</td>
                                  <td>
                                    {inv?.qnt}{" "}
                                    <span
                                      className="badge badge-danger ml-4 mr-1 mt-2 "
                                      style={{ cursor: "pointer" }}
                                      onClick={() => this.handleRemoveItem(inv)}
                                    >
                                      X
                                    </span>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                        {this.state.selectedStockItems.length > 0 && (
                          <button
                            type="button"
                            className="btn btn-sm btn-success pull-right mt-2"
                            onClick={this.handleInventorySubmit}
                          >
                            Submit Inventory
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </>
            </div>
          </>
        )}
      </div>
    );
  }
}
