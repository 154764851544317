import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLPrevilages;
function api(path, subURL = "/players/team-bank-accs") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getTeamBankAccs() {
  return _http.get(api(""), _http.headerJWT());
}
export function createTeamBankAccs(req) {
  const param = { ...req };
  delete param.accTeamId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveTeamBankAccs(req) {
  const param = { ...req };
  const id = param.accTeamId;
  //delete param.teamId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteTeamBankAccs(req) {
  const param = { ...req };
  return _http.delete(api(param.accTeamId), _http.headerJWT());
}
