import React from "react";
import FormLib from "../../_common/FormLib";
import swal from "sweetalert";
import Joi from "joi-browser";
import Table from "../../_common/Table";
import Pagination from "../../_common/Pagination";

import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";

import { getActiveZones } from "../../../services/masterLocations/zonesServices";

import { getActiveStates } from "../../../services/masterLocations/statesServices";

import {
  getCities,
  getCitiesAll,
  getActiveCities,
  createCity,
  saveCity,
  deleteCity,
} from "../../../services/masterLocations/cityServices";
class City extends FormLib {
  state = {
    data: {
      cityId: "",
      city: "",
      parentCity: "",
      zoneId: "",
      stateId: "",
      status: "active",
    },
    errors: {},
    formMode: "create",
    cities: [],
    selectCities: [],
    zones: [],
    states: [],
    loading: true,
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };
  schema = {
    cityId: Joi.optional().label("City Id"),
    city: Joi.string().regex(/^[a-zA-Z ]*$/).required().label("City").error(() => {
      return {
        message: 'Allow only characters.',
      }
    }),
    parentCity: Joi.optional().label("Parent City"),
    zoneId: Joi.string().required().label("Zone Id *"),
    stateId: Joi.string().required().label("State Id *"),
    status: Joi.string().required().label("Status"),
  };

  loadActiveZones = async () => {
    let list = await getActiveZones();
    if (list.data.data.zones !== 0) {
      let data = list.data.data.zones;
      data.sort((a, b) => { return a.zone.localeCompare(b.zone) });
      this.setState({ zones: data });
      data.map(n => n.zone = this.capitalize(n.zone));
    }

  };

  loadActiveStates = async () => {
    let list = await getActiveStates();
    if (list.data.data.states !== 0) {
      let data = list.data.data.states;
      let dataf = data.map(n => n.state = this.capitalize(n.state));
      this.setState({ states: list.data.data.states });
    }

  };

  componentDidMount() {
    // this.loadZones();
    // this.loadStates();
    this.loadCities(this.state.pageLinks.pageNo);
    this.loadActiveStates();
    this.loadActiveZones();
    this.loadSelectCities();
  }
  async loadCities(pageNo) {
    this.setState({ loading: true });
    const res = await getCitiesAll(pageNo);
    if (res.data.success === true) {
      this.setState({ cities: res.data.data.linkedhashmap });
      this.setState({ pageLinks: res.data.data.pageLinks });
    } else {
      toast.toastError(res.data.msg);
      this.setState({ cities: [] });
      this.setState({
        pageLinks: {
          "pageNo": 1,
          "perPage": 0,
          "itemsCount": 0,
          "lastPage": 0
        }
      });
    }
    this.setState({ loading: false });
  }
  async loadSelectCities() {
    this.setState({ loading: true });
    const res = await getActiveCities();

    if (res.data.success === true) {
      this.setState({ selectCities: res.data.data.cities });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }
  async loadActiveStates() {
    this.setState({ loading: true });
    const res = await getActiveStates();
    if (res.data.success) {
      this.setState({ states: res.data.data.states });

      let data = res.data.data.states;
      // console.log("********************"+data);
      //toast.toastSuccess("Packing Material Loaded Successfully");
    } else {
      toast.toastError("failed");
    }
    this.setState({ loading: false });
  }
  async loadActiveZones() {
    this.setState({ loading: true });
    const res = await getActiveZones();
    if (res.data.success === true) {
      // console.log(res.data.data.zones);
      this.setState({ zones: res.data.data.zones });
      // console.log(this.state.zones);
      //toast.toastSuccess("Packing Material Loaded Successfully");
    } else {
      toast.toastError("failed");
    }
    this.setState({ loading: false });
  }

  capitalize(str) {
    if (str != null) {
      return str[0].toUpperCase() + str.slice(1);
    }
  }

  tableMeta = {
    key: "cityId",
    columns: [
      {
        key: "CITY_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnCity(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true" /> Edit
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleView(r)}
              >
                <i className="fa fa-binoculars" aria-hidden="true" /> View
              </button>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(r)}
              >
                <i className="fa fa-trash" aria-hidden="true" /> Delete
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },

      // { path: "state", label: "State" },


      {
        key: "state",
        action: (r) => <>{this.capitalize(r.state)}</>,
        cusClass: " ",
        icon: () => <>State</>
      },

      {
        key: "zone",
        action: (r) => <>{this.capitalize(r.zone)}</>,
        cusClass: " ",
        icon: () => <>Zone</>
      },
      {
        key: "city",
        action: (r) => <>{this.capitalize(r.city)}</>,
        cusClass: " ",
        icon: () => <>City</>
      },

      { path: "status", label: "Status" },
    ],
  };
  handleOnCity(r) {
    // console.log("inside handle on city");
    let cities = {};
    cities.cityId = r.cityId;
    cities.city = r.city;
    cities.parentCity = r.parentCity;
    cities.zoneId = r.zoneId;
    cities.stateId = r.stateId;
    cities.status = r.status;
    this.setState({ data: cities, formMode: "edit" });
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/masterLocation/city/" + pageNo);
    this.loadCities(pageNo);
  };

  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createCity(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveCity(this.state.data);
      this.resetData();
    }

    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadCities(this.state.pageLinks.pageNo);
    } else {
      toast.toastError(res.data.msg);
    }
  }

  doSubmitDelete = async (handleDelete, r) => {
    if (handleDelete) {
      await deleteCity(r);
      this.loadCities(this.state.pageLinks.pageNo);

      swal("City has been deleted!", {
        icon: "success",
      });
    } else {
      swal("City is safe!");
    }
  };

  handleDelete = async (r) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, r);
    });
  };

  handleView = (r) => {
    this.setState({ curCity: r, formMode: "view" });
  };

  resetData() {
    this.setState({
      data: {
        cityId: "",
        city: "",
        parentCity: "",
        zoneId: "",
        stateId: "",
        status: "active",
      },
      formMode: "create",
    });
  }
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Cities" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-6">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Cities</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.cities}
                    dropMenu={[]}
                  />
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              )}
            </div>

            <div className="col-md-6">
              {this.state.formMode === "view" && (
                <div className="card">
                  <div className="card-body">
                    <>
                      {this.state.curCity["city"].trim()}
                      <div className="pull-right">
                        <span className={"badge badge-light pull-left ml-1 "}>
                          {/* {this.state.curCity["cityId"]} */}
                        </span>

                        {this.state.curCity["status"] === 'active' &&
                          <span className={"badge badge-success pull-left  mr-1 "} >
                            {this.state.curCity["status"]}
                          </span>
                        }
                        {this.state.curCity["status"] === 'inactive' &&
                          <span className={"badge badge-danger pull-left  mr-1 "} >
                            {this.state.curCity["status"]}
                          </span>
                        }
                      </div>
                    </>
                  </div>
                </div>
              )}
              {this.state.formMode !== "view" && (
                <div className="card pt-3">
                  <div className="card-body">
                    <form>
                      <div className="form-row">
                        {this.rTBH("CityId", "cityId", "col-2")}
                        {this.rSB(
                          "Zones *",
                          "zoneId",
                          this.state.zones,
                          { value: "zoneId", label: "zone" },
                          " col-md-6 "
                        )}
                        {this.rSB(
                          "States *",
                          "stateId",
                          this.state.states,
                          { value: "stateId", label: "state" },
                          " col-md-6 "
                        )}

                        {this.rSB(
                          "Parent City *",
                          "parentCity",
                          this.state.selectCities,
                          { value: "cityId", label: "city" },
                          " col-md-6 mt-1"
                        )}
                        {this.rTB("City", "city", "col-6")}
                        {/* {this.rTB("ParentCity", "parentCity", "col-4")} */}
                      </div>
                      <div className="form-row">
                        {/* {this.rTB("ZoneId", "zoneId", "col-2")}
                  {this.rTB("StateId", "stateId", "col-2")} */}
                        {this.rRBL(
                          "Status",
                          "status",
                          this.status,
                          this.stsConf
                        )}
                      </div>
                    </form>

                    {this.state.formMode === "create" &&
                      this.rBtnSP("Create", "create")}
                    {this.state.formMode === "edit" && (
                      <>
                        {this.rBtnSS("Edit", "edit")}
                      </>
                    )}
                    {this.mResetBtn(() => this.resetData(), " pull-right ", "Reset")}
                  </div>
                </div>
              )}

            </div>

            {/* <div className="col-md-5">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="form-row">
                      {this.rTBH("CityId", "cityId", "col-2")}
                      {this.rSB(
                        "Zones *",
                        "zoneId",
                        this.state.zones,
                        { value: "zoneId", label: "zone" },
                        " col-md-6 "
                      )}
                      {this.rSB(
                        "States *",
                        "stateId",
                        this.state.states,
                        { value: "stateId", label: "state" },
                        " col-md-6 "
                      )}
                      {this.rTB("City", "city", "col-6")}
                      {this.rSB(
                        "Parent City *",
                        "parentCity",
                        this.state.cities,
                        { value: "cityId", label: "city" },
                        " col-md-6 "
                      )}
                      {/* {this.rTB("ParentCity", "parentCity", "col-4")} 
                    </div>
                    <div className="form-row">
                      {/* {this.rTB("ZoneId", "zoneId", "col-2")}
                  {this.rTB("StateId", "stateId", "col-2")} 
                      {this.rRBL("Status", "status", this.status, this.stsConf)}
                    </div>
                  </form>
                  {this.state.data.cityId === "" &&
                    this.rBtnSP("Create", "create")}
                  {this.state.data.cityId !== "" && (
                    <>
                      {this.rBtnSS("Edit", "edit")}
                      {this.mResetBtn(() => this.resetData(), " pull-right ")}
                    </>
                  )}
                </div>
              </div>
                  </div>*/}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default City;
