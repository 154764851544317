import React from "react";
import FormLib from "./../../_common/FormLib";
import toast from "../../../services/_toastService";
import "./ticketStyle.css";
import { MisReportHistory } from "./data.js";
import {
  IstToUTC,
  epoch2ddmmyyyyhhmmss,
  IstToUTCTicketHistory,
} from "./../../../utils/DateUtils";

import {
  getTicketProcess,
  getTicketMisHistory,
  getTicketRemarks,
  getTicketJobStatus,
} from "./../../../services/TicketHistory/TicketHistory";
import TicketInfo from "./TIcketInfo";
import BlockStatusRow from "./BlockStatusRow";
import IvrRecordsCard from "./IvrRecordsCard";
import { getIVRCallReportByTicketNo } from "../../../services/ivr/IvrSearchService";
import { getSMSByTicketBlockId } from "../../../services/sms/smsServices";
import SMSRecordsCard from "./SMSRecordsCard";
import { setTicketStatus } from "./../../../services/Tickets/TicketProcessServices";
import { ticketListReload } from "../../../store";
import { connect } from "react-redux";
import Joi from "joi-browser";
// import _ from "lodash";

class TicketProcess extends FormLib {
  state = {
    data: {
      flowStatus: "",
      status: "",
    },
    errors: {},
    curMisReportHistory: [],
    curProcessId: "",
    curBlockId: "",
    curProductId: "",
    wentWrong: "",
    TicketRemarks: [],
    toggleTicketInfo: false,
    smsRecords: [],
    JobStatuses: [],
    isUserBIZ: false,
  };

  flowStatus = [
    { flowStatus: "PICK_WEB", flowStatusByLabel: "PICK" },
    { flowStatus: "DROP_WEB", flowStatusByLabel: "DROP" },
  ];
  schema = {
    flowStatus: Joi.string().required().label("FlowStatus"),
    status: Joi.string().required().label("Status"),
  };

  async componentDidMount() {
    // this.loadMISReport();
    this.getIvrRecords();
    this.getTicketRemarks();
    await this.getTicketMisHistory();
    this.getOTPRecords();
    this.getJobStatus();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo["code"] === "BIZ") {
      this.setState({ isUserBIZ: true });
    }
    this.setState({ designation: userInfo.designation });
  }

  async getJobStatus() {
    try {
      const ticketBlockId = this.props.ticketProductDetails.ticketBlockId;
      let data = this.state.data;
      data["flowStatus"] = this.props.ticketProductDetails.flowStatus;

      this.setState({ data });

      let object = {};

      let resp = await getTicketJobStatus(
        ticketBlockId,
        this.state.data.flowStatus
      );

      if (resp !== undefined) {
        if (resp.data.success === true) {
          object = {
            options: resp.data.data.JobStatuses.map((jobStatus, index) => ({
              Status: this.replaceString(jobStatus),
            })),
          };
          resp.data.data.JobStatuses?.length > 0 &&
            this.setState({
              JobStatuses: object.options,
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  localHandleChange = async (e) => {
    try {
      if (e.target.name === "Status") {
        let data = this.state.data;
        data["status"] = this.replaceOriginalString(e.target.value);
        this.setState({ data });
      } else if (e.target.name === "flowStatus") {
        const ticketBlockId = this.props.ticketProductDetails.ticketBlockId;
        let object = {};
        let data = this.state.data;
        data["flowStatus"] = e.target.value;
        this.setState({ data });
        let resp = await getTicketJobStatus(
          ticketBlockId,
          this.state.data.flowStatus
        );

        if (resp !== undefined) {
          if (resp.data.success === true) {
            object = {
              options: resp.data.data.JobStatuses.map((jobStatus, index) => ({
                Status: this.replaceString(jobStatus),
              })),
            };
            if (resp.data.data.JobStatuses?.length > 0) {
              this.setState({
                JobStatuses: object.options,
              });
            } else {
              this.setState({
                JobStatuses: [],
              });
            }
            // resp.data.data.JobStatuses?.length > 0 &&
            //   this.setState({
            //     JobStatuses: object.options,
            //   });
          }
        }
      }
    } catch {
      console.log("TicketProcess >>>>>>>>>> localHandleChange");
    }
  };
  async doSubmit() {
    try {
      const ticketProductDeatils = this.props.ticketProductDetails;
      const processAt = IstToUTC(new Date()).slice(0, 19).replace(" ", "T");

      let resp = await setTicketStatus(
        this.state.data,
        ticketProductDeatils,
        processAt
      );
      if (resp.data.success === true) {
        if (resp.data) {
          toast.toastSuccess(resp.data.msg);
          this.handleTicketListReload();
          this.getTicketMisHistory();
          this.getJobStatus();
        } else {
          toast.toastError(resp.data.msg);
        }
      } else {
        toast.toastError(resp.data.msg);
      }
    } catch {
      console.log("Ticket Process >>>>>>>>>>>>> doSubmit");
    }
  }

  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };

  replaceString = (str) => {
    if (str) {
      str = str.replace(/_/g, " ");
      return str.replace("BLOCK", "JOB");
    }
  };
  replaceStringUser = (str, resultSet) => {
    // if (str) {
    // str.filter(function (item) {
    //   return item.blockStatus !== "PRODUCT_STATUS";
    // });
    str = str.replace(/_/g, " ");
    str = str.replace("BLOCK", "JOB");
    // }
    if (str === "AVAILABLE FOR PROCESS") {
      var conv1 = JSON.parse(resultSet);
      var conv2 = conv1.flowStatus;
    }
    switch (true) {
      case str === "AVAILABLE FOR PROCESS" && conv2 === "PICK_MOB":
        str = "PICKUP UNDER PROCESS";
        return str;
      case str === "AVAILABLE FOR PROCESS" && conv2 === "DROP_MOB":
        str = "DROP UNDER PROCESS";
        return str;
      case str === "PICKUP DONE":
        str = "PICKUP DONE";
        return str;
      case str === "DROP DONE":
        str = "DROP DONE";
        return str;
      case str === "PRODUCT STATUS" && conv2 === "PICK_MOB":
        str = "PICKUP SCAN";
        return str;
      case str === "PRODUCT STATUS" && conv2 === "DROP_MOB":
        str = "DROP SCAN";
        return str;
      case str === "FE JOB END":
        str = "PiCKUP PROCESS COMPLETED BY FE";
        return str;
      case str === "FE JOB END DROPTIME":
        str = "DROP PROCESS COMPLETED BY FE";
        return str;
      case str === "CHOOSE FE FOR DROP":
        str = "CHOOSE FE FOR DROP";
        return str;
      case str === "ASSIGNED FE FOR DROP":
        str = "OUT FOR DROP";
        return str;
      case str === "ASSIGNED FE FOR PICKUP":
        str = "OUT FOR PICKUP";
        return str;
      case str === "LINEHAUL":
        str = "READY FOR LINEHAUL";
        return str;

      default:
        return str;
    }
  };

  replaceOriginalString = (str) => {
    if (str) {
      str = str.replace(/ /g, "_");
      return str.replace("JOB", "BLOCK");
    }
  };

  async loadMISReport() {
    try {
      this.setState({ loading: true });

      const processId = this.props.curProcessId;
      const ticketNo = this.props.curTicket;
      if (processId !== "") {
        let res = await getTicketProcess(processId);
        if (res !== undefined && res.data.data !== undefined) {
          if (
            res.data.success === true &&
            res.data.data.MisReportHistory.length !== 0
          ) {
            this.setState({
              curMisReportHistory: res.data.data.MisReportHistory,
            });
            this.setState({
              curProcessId: res.data.data.MisReportHistory[0].processId,
            });
            this.setState({
              curBlockId: res.data.data.MisReportHistory[0].ticketBlockId,
            });
            this.setState({
              curProductId: res.data.data.MisReportHistory[0].ticketProductId,
            });
          } else if (res.data.success === false) {
            toast.error("No Data Available for this Block");
          }
        } else {
          this.setState({ wentWrong: undefined });
        }
      }

      this.setState({ loading: false });
    } catch {
      console.log("Error in TicketProcess>>>>>> loadMISReport");
    }
  }
  async getTicketMisHistory() {
    try {
      this.setState({ loading: true });
      const ticketNo = this.props.curTicket;
      this.state.curMisReportHistory = [];

      const respDst = await getTicketMisHistory(ticketNo);
      if (respDst !== undefined) {
        if (respDst.data.success === true && respDst.data.data) {
          if (respDst.data.data.MisReportHistory) {
            this.setState({
              curMisReportHistory: respDst.data.data.MisReportHistory,
            });
            if (respDst.data.data.MisReportHistory[0]) {
              this.setState({
                curProcessId: respDst.data.data.MisReportHistory[0].processId,
              });
              this.setState({
                curBlockId: respDst.data.data.MisReportHistory[0].ticketBlockId,
              });
            }
          }
        } else if (respDst.data.success === false) {
          toast.error("No Details Available for this Ticket");
        }
      } else {
        this.setState({ wentWrong: undefined });
      }

      this.setState({ loading: false });
    } catch {
      console.log("Error in TicketProcess>>>>>> getTicketMisHistory");
    }
  }

  // replaceString = (str) => {
  //   if (str) {
  //     str = str.replace(/_/g, " ");
  //     return str.replace("BLOCK", "JOB");
  //   }
  // };

  // ------------------IVR Records----------------------------------//

  async getIvrRecords() {
    try {
      const ticketNo = this.props.curTicket;
      let resp = await getIVRCallReportByTicketNo(ticketNo);
      if (resp !== undefined) {
        if (resp.data.success === true) {
          resp.data.data.ivrCallDetails?.length > 0 &&
            this.setState((prevState) => ({
              ...prevState,
              ivrRecords: resp.data.data.ivrCallDetails,
            }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  // -------------------- SMS OTP Records -------------------------//
  async getOTPRecords() {
    try {
      const resp = await getSMSByTicketBlockId(this.state.curBlockId);

      if (resp.data.success) {
        // const otpRecordsSorted = _.sortBy(resp.data.data.otpReports, ["createdAt"])
        this.setState({ smsRecords: resp.data.data.otpReports });
        return;
      }
      this.setState({ smsRecords: [] });
    } catch (error) {
      // console.log(error);
      this.setState({ smsRecords: [] });
    }
  }
  async getTicketRemarks() {
    try {
      this.state.TicketRemarks = [];
      const ticketNo = this.props.curTicket;

      const resRemarks = await getTicketRemarks(ticketNo);
      if (resRemarks.data.success === true && resRemarks.data.data) {
        this.setState({
          TicketRemarks: resRemarks.data.data.ticketRemarks,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  isSMSRecordsVisible() {
    const userInfoJSON = localStorage.getItem("userInfo");
    if (!userInfoJSON) return false;
    const userInfo = JSON.parse(userInfoJSON);

    if (!userInfo?.code) return false;
    if (userInfo.code !== "BIZ") return false;
    if (!userInfo?.status) return false;
    if (!userInfo?.username) return false;
    if (userInfo.username === "admin") return true;
    if (!userInfo?.designation) return false;
    if (
      userInfo.designation === "Key Accounts Manager" ||
      userInfo.designation === "HO Operations Admin" ||
      userInfo.designation === "Operations Head"
    )
      return true;
    return false;
  }

  render() {
    const curTicket = this.props.curTicket;
    const ivrRecords = this.state.ivrRecords;
    const curMisReportHistory = this.state.curMisReportHistory.filter(function (
      item
    ) {
      if (item.blockStatus === "PRODUCT_ASSIGN") {
        return item.blockStatus !== "PRODUCT_ASSIGN";
      } else if (item.blockStatus === "PRODUCT_STATUS") {
        return item.blockStatus !== "PRODUCT_STATUS";
      } else {
        return item.blockStatus;
      }
    });

    return (
      <React.Fragment>
        {this.state.loading && (
          <div className="container-fluid">
            <div> Loading Details...</div>
          </div>
        )}
        {this.state.designation === "Operations Head" &&
          // this.props.ticketProductDetails.blockStatus !== "LINEHAUL" &&
          this.props.ticketProductDetails.blockStatus !==
            "LINEHAUL_IN_TRANSIT" && (
            <div className="row  ">
              <div className="col-md-12 mb-1">
                <div
                  className="card-body  bg-light"
                  style={{
                    backgroundColor: "#f5f0f0",
                  }}
                >
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-row">
                      <div className=" col-3 border border-gray rounded pt-1 ps-0  pl-2">
                        <p style={{ color: "grey", fontStyle: "normal" }}>
                          Current Status :
                        </p>
                        <p className={"badge badge-success badge-normal"}>
                          {this.replaceString(
                            this.props.ticketProductDetails.blockStatus
                          )}
                        </p>
                      </div>
                      <div className="col-3 border border-gray rounded pt-3 ps-0 pb-1">
                        {this.rRBL(
                          "Flow ?",
                          "flowStatus",
                          this.flowStatus,
                          {
                            value: "flowStatus",
                            label: "flowStatusByLabel",
                          },
                          "col-12 mt-2 mb-1"
                        )}
                      </div>
                      <div className="col-3 border border-gray rounded pt-3 ps-0 pb-1">
                        {this.rSB(
                          "Change Job Status To:",
                          "Status",
                          this.state.JobStatuses,
                          { value: "Status", label: "Status" },
                          " col-12"
                        )}
                      </div>
                      <div className="col-2 border border-gray rounded pt-3 ps-0 pb-1">
                        {this.rBtnS(
                          "Change Status",
                          "Submit",
                          "btn btn-success  btn-sm ml-4"
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}

        {!this.state.loading && curMisReportHistory.length !== 0 && (
          <div className="row" style={{ backgroundColor: "#e7e7e8" }}>
            <div className="col-md-9 mt-3 mb-1" style={{ textAlign: "center" }}>
              <div>
                <b>Ticket No: </b>{" "}
                <div
                  className="d-inline"
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    color: this.state.toggleTicketInfo ? "black" : "#a50c0c",
                  }}
                  onClick={() => {
                    this.setState({
                      toggleTicketInfo: !this.state.toggleTicketInfo,
                    });
                  }}
                >
                  {curTicket}
                  <TicketInfo
                    toggleTicketInfo={this.state.toggleTicketInfo}
                    curBlockId={this.state.curBlockId}
                    curProcessId={this.state.curProcessId}
                  />
                </div>
                <br />
                {/* <b>Product ID: </b> {this.state.curProductId}{" "} */}
              </div>{" "}
              <br />
            </div>
            {this.isSMSRecordsVisible() && (
              <div className="col-md-12">
                {this.state.smsRecords && (
                  <SMSRecordsCard smsRecords={this.state.smsRecords} />
                )}
              </div>
            )}
            <div className="col-md-9 mt-3 mb-1 ml-5">
              <ul className="cbp_tmtimeline">
                {curMisReportHistory && (
                  <>
                    {curMisReportHistory.map((t, index) => {
                      return (
                        <>
                          {t.blockStatus && (
                            <li key={index}>
                              {t.processedAt && (
                                <time
                                  className="cbp_tmtime"
                                  dateTime="2022-04-04T03:45"
                                >
                                  <span>
                                    {IstToUTCTicketHistory(t.processedAt)}
                                  </span>{" "}
                                </time>
                              )}
                              {!t.processedAt && (
                                <time
                                  className="cbp_tmtime"
                                  dateTime="2022-04-04T03:45"
                                >
                                  <span>{"--"}</span>{" "}
                                </time>
                              )}

                              <div className="cbp_tmicon bg-info">
                                <i className="zmdi zmdi-label"></i>
                              </div>
                              {t.blockStatus !== "null" && (
                                <div className="cbp_tmlabel">
                                  <h2>
                                    <a
                                      style={{
                                        color: "#75bb06",
                                        position: "relative",
                                      }}
                                    >
                                      {/* { console.log("in Blocks status", t.resultSet)} */}
                                      <b>
                                        {this.replaceStringUser(
                                          t.blockStatus,
                                          t.resultSet
                                        )}
                                      </b>{" "}
                                      <BlockStatusRow curMisReportHistory={t} />
                                    </a>
                                  </h2>
                                  <time
                                    className="cbp_tmtime "
                                    dateTime="2022-04-04T03:45"
                                  >
                                    <span
                                      className="pull-right"
                                      style={{
                                        marginRight: "-520px",
                                        color: "#c6bcbc",
                                        fontSize: "10px",
                                        marginTop: "-44px",
                                      }}
                                    >
                                      {IstToUTCTicketHistory(t.createdAt)}
                                    </span>{" "}
                                    <span
                                      className="pull-right"
                                      style={{
                                        marginRight: "-520px",
                                        color: "#c6bcbc",
                                        fontSize: "10px",
                                        marginTop: "-10px",
                                      }}
                                    >
                                      {t.firstName} {t.lastName} | {t.userName}{" "}
                                      {this.state.isUserBIZ && " | " + t.phone}
                                    </span>{" "}
                                  </time>
                                </div>
                              )}
                            </li>
                          )}
                        </>
                      );
                    })}
                  </>
                )}
              </ul>
            </div>
            {this.state.TicketRemarks.length > 0 && (
              <div className="col-md-4">
                <div className="card " style={{ marginLeft: "65%" }}>
                  <div className="card-body bg-custom-6">
                    <h1
                      style={{
                        backgroundColor: "#ced4da",
                        textAlign: "center",
                        marginTop: "-20px",
                        marginLeft: "-20px",
                        marginRight: "-20px",
                        padding: "5px",
                      }}
                    >
                      Remarks
                    </h1>
                    {this.state.TicketRemarks.map((t, index) => {
                      return (
                        <div key={index}>
                          <p>
                            {">> " + t.remark} |{" "}
                            {epoch2ddmmyyyyhhmmss(t.createdAt)}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-12">
              {ivrRecords && (
                <IvrRecordsCard
                  ticketNo={curTicket}
                  ivrRecords={this.state.ivrRecords}
                />
              )}
            </div>
          </div>
        )}

        {!this.state.loading &&
          curMisReportHistory.length === 0 &&
          this.state.wentWrong !== undefined && (
            <div className="container-fluid jumbotron text-center">
              <h4>Process not happened for this Ticket...</h4>
            </div>
          )}
        {!this.state.loading && this.state.wentWrong === undefined && (
          <div className="container-fluid jumbotron text-center">
            <h4>Something went wrong please try again...</h4>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TicketProcess);
