import React from "react";
import FormLib from "./../../_common/FormLib";

class PrvModules extends FormLib {
  state = {
    data: {
      moduleId: "",
      divisionId: "",
      module: "",
      status: "active",
      sort: "",
    },
    errors: {},
    curDivision: {},
    // prvModules: [],
  };

  isCur(id, cur) {
    if (id === cur) {
      return " curTasks ";
    }
    return " ";
  }

  componentDidMount() {
    this.setCurDivision(this.props.curDivision);
  }
  setCurDivision(d) {
    this.setState({ curDivision: d });
  }
  setCurModule(m, d) {
    if (m.moduleId !== "") {
      m.status = 1;
      this.setState({ data: m });
    } else {
      this.resetData(d);
    }
    // console.log(JSON.stringify(this.state.data));
  }

  resetData(d) {
    this.setState({
      data: {
        moduleId: "",
        divisionId: d.divisionId || "",
        module: "",
        status: "active",
        sort: "",
      },
    });
  }
  render() {
    const prvModules = this.props.prvModules;
    const curDivision = this.props.curDivision;
    const curModule = this.props.curModule;

    return (
      <div className="card  ">
        <h5 className="card-header">
          Prv Modules
          {/* <pre>{JSON.stringify(this.state.prvModules)}</pre> */}
          {curDivision.divisionId !== "" && !curModule.moduleId && (
            <button
              type="button"
              className="btn btn-sm btn-primary pull-right"
              data-toggle="modal"
              data-target="#myModuleModel"
            >
              <i className={"fa  fa-plus  "}></i>
            </button>
          )}
          {curModule.moduleId && curModule.moduleId !== "" && (
            <>
              <button
                type="button"
                className="btn btn-sm btn-warning pull-right"
                onClick={() => this.props.onModuleUnload()}
              >
                <i className={"fa  fa-unlink  "}></i>
              </button>
              <button
                type="button"
                className="btn btn-sm btn-success pull-right"
                data-toggle="modal"
                data-target="#myModuleModel"
              >
                <i className={"fa  fa-pencil  "}></i>
              </button>
            </>
          )}
        </h5>

        <div className="card-body">
          <div className="page-todo">
            <div className=" tasks">
              {prvModules.map((m) => (
                <div
                  className={
                    "task nil " + this.isCur(m.moduleId, curModule.moduleId)
                  }
                  key={m.moduleId}
                >
                  <div className="desc">
                    <span
                      className="span-click"
                      onClick={() => this.props.onModuleChange(m)}
                    >
                      <div className="title">{m.module}</div>
                    </span>

                  </div>

                  <div className="pull-right">
                    <div className="menu">
                      <span
                        className={
                          "badge   badge-success pull-left " +
                          "badge-" +
                          m.status
                        }
                      >
                        {m.sort + " : " + m.status}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrvModules;
