import React from "react";
import ZTitle from "./../../_subhead/ZTitle";
import toast from "../../../services/_toastService";
import FormLib from "../../_common/FormLib";
import { getMisOldTickets } from "../../../services/Tickets/create/MisOldService";
import Joi from "joi-browser";
import Pagination from "./../../_common/Pagination";
import Table from "./../../_common/Table";
import { getClients } from "./../../../services/masters/hubServices";
import { ExportXLSX } from './../requestV1/ExportXLSX';
import { getMappedStatus } from "./statusMapping";


class MisOldReport extends FormLib {

  state = {
    data: {
      retailerId: "",
      fromDate: this.getDatebyDays(0),
      toDate: this.getDatebyDays(0),
      cusComplaintNo: "",
      status: "all",
      ticketNo: "",
    },
    clients: [],
    errors: {},
    loading: true,
    // formMode: "create",
    misTickets: [],
    cusMisTicket: {},
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    csvReport: {
      headers: [],
      misReports: [],
    },
  };
  schema = {

    retailerId: Joi.string().required().label("retailerId"),
    fromDate: Joi.string().required().label("From Date"),
    toDate: Joi.string().required().label("To Date"),
    cusComplaintNo: Joi.optional().label("Order No"),
    ticketNo: Joi.optional().label("ticketNo"),
    status: Joi.optional().label("Status"),
  };

  async doSubmit() {
    this.loadTickets(this.state.pageLinks.pageNo);
    let res = await getMisOldTickets(this.state.data, this.state.pageLinks.pageNo);
    if (res.data.status === 1) {
      if (res.data.data) {
        this.setState({ report: res.data.data });
        ///Excel
        let apiData = {};
        const { csvReport } = { ...this.state };
        apiData = this.state.report;

        csvReport.filename = "financial_report.xlsx";
        csvReport.headers = this.excelHeaders;

        let transData = [];
        transData = apiData;
        this.setState({ csvReport });

        if (apiData) {
          const promises = apiData;
          transData = await Promise.all(promises);
          csvReport.data = transData;
          this.setState({ csvReport });
        } else {
          csvReport.data = [];
          this.setState({ csvReport });
        }
      }
    }
    ///Excel
  }
  componentDidMount() {

    this.loadClients();
  }

  loadClients = async () => {
    const res = await getClients();
    // console.log(JSON.stringify(res));

    if (res.data.success === 1) {
      this.setState({ clients: res.data.data.clients });
      toast.toastSuccess(res.data.msg);

    } else {
      toast.toastError(res.data.msg);
    }
  };

  loadTickets = async (pageNo) => {
    let res;
    this.setState({ loading: true });
    res = await getMisOldTickets(this.state.data, pageNo);
    // this.resetData();

    if (res.data.status === 1) {
      if (res.data.data) {
        toast.toastSuccess(res.data.msg);
        this.setState({ misTickets: res.data.data });
        this.setState({ pageLinks: res.data.pageLinks });
      } else {
        toast.toastWarn("No Data");
        this.setState({ misTickets: [] });
        this.setState({
          pageLinks: {
            "pageNo": 1,
            "perPage": 0,
            "itemsCount": 0,
            "lastPage": 0
          }
        });
      }
      // this.loadCountry();
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };


  localHandleChange = (e) => {
    if (e.target.id === "teamId") {
      this.handleTeamChange(e);
    }
  };
  handlePageChange = (pageNo) => {
    this.props.history.push("/misOldReport/" + pageNo);
    this.loadTickets(pageNo);
  };

  status = [
    { sortKey: "all", sort: "All" },
    { sortKey: "RepairPartIsuue", sort: "Part Pending" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  tableMeta = {
    key: "countryId",
    columns: [
      {
        key: "COUNTRY_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <a
                className="dropdown-item"
                type="button"
                target="_blank"
                href={
                  "https://test.bizlog.in/#/task/" + r.PROCESS_ID_ + "/view"
                }
              >
                <i className="fa fa-binoculars" aria-hidden="true"></i> More
                Details
              </a>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      { path: "RL_TICKET_NO_", label: "Ticket No." },
      { path: "CONSUMER_NAME_", label: "Consumer" },
      { path: "TELEPHONE_NUMBER_", label: "Phone" },
      { path: "PRODUCT_NAME_", label: "Product" },
      { path: "RETAILER_", label: "Retailer" },
      { path: "NATURE_OF_COMPLAINT_", label: "Nature Of Complaint" },
      { path: "ORDER_NUMBER_", label: "Order Number" },
      { path: "CONSUMER_COMPLAINT_NUMBER_", label: "Consumer Req No" },
      { path: "CREATE_DATE_", label: "Created at" },
      // { path: "DOCKET_NO_", label: "Docket No" },


      {
        key: "tokenExpiryDate",
        action: (
          r,
          ticketStatusMapp = getMappedStatus(r.RETAILER_)
        ) => (
          <>
            {ticketStatusMapp[r.CURRENT_STATUS_]
              ? ticketStatusMapp[r.CURRENT_STATUS_]
              : "=>" + r.CURRENT_STATUS_}
          </>
        ),
        cusClass: " ",
        icon: () => <>Status</>,
      },
    ],
  };


  render() {

    const { pageNo, perPage, count } = this.state.pageLinks;
    return (
      <React.Fragment>
        <>
          <ZTitle subHeading="Report  :: Mis Status" />
          <div className="pageContent">
            <div className="card">
              <div className="card-body">
                <form onSubmit={this.handleSubmit}>
                  <div className="form-row mt-2">
                    {this.rDP("Start Date *", "fromDate", " col-md-3 ")}
                    {this.rDP("End Date *", "toDate", " col-md-3 ")}
                    {this.rSB(
                      "Retailers",
                      "retailerId",
                      this.state.clients,
                      { value: "client", label: "client" },
                      " col-md-3 "
                    )}
                    {this.rSB(
                      "Status",
                      "status",
                      this.status,
                      this.stsConf,
                      " col-md-2 "
                    )}
                    {this.rTB("Order No", "cusComplaintNo", "col-md-3")}
                    {this.rTB("Ticket No", "ticketNo", "col-md-3")}
                    <span>
                      {this.rBtnS(
                        "Search",
                        "Search",
                        "btn btn-primary  btn-sm"
                      )}
                    </span>
                  </div>
                </form>
              </div>
            </div>
            {this.state.loading && (
              <div>
                <div>Loading Tickets</div>
              </div>
            )}

            {!this.state.loading && (
              <div className="container-fluid" >
                <div className="col-3 form-group ">
                  <br />
                  <ExportXLSX
                    csvData={this.state.csvReport}
                    header={this.state.csvReport.headers}
                    fileName={
                      "MIS - [" +
                      this.state.data.fromDate +
                      "-" +
                      this.state.data.toDate +
                      "]"
                    }
                  />
                </div>

                <div style={{ overflow: "auto" }}>
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.misTickets}
                    dropMenu={[]}
                  />

                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={count}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              </div>

            )}
          </div>
        </>
      </React.Fragment>
    );
  }
}
export default MisOldReport;