import React from "react";
import FormLib from "./../../_common/FormLib";
import toast from "../../../services/_toastService";

import {
  assign,
  unassign,
} from "../../../services/appRoles/RolesFeaturesServices";

class RolesFeatures extends FormLib {
  state = {
    data: {},
    errors: {},
    curRole: { roleId: "" },
  };

  componentDidMount() {
    this.setState({ curRole: this.props.curRole });
  }
  localHandleChange = (e) => {
    this.asignUnAsignDesignationRole(e);
  };

  asignUnAsignDesignationRole = (e) => {
    let setData = {};
    setData.roleId = this.state.curRole.roleId;
    if (e.target.checked) {
      setData.previlagesId = e.target.value;
      this.assignUnassign("assign", setData);
    } else {
      setData.previlagesId = e.target.value;
      this.setState({ data: setData });
      this.assignUnassign("unAssign", setData);
    }
  };

  assignUnassign = async (type, setData) => {
    if (type === "assign") {
      const res = await assign(setData);
      if (res.data.success) {
        toast.toastSuccess(res.data.msg);
      } else {
        toast.toastError(res.data.msg);
      }
    } else {
      const res = await unassign(setData);
      if (res.data.success) {
        toast.toastSuccess(res.data.msg);
      } else {
        toast.toastError(res.data.msg);
      }
    }
    this.props.onFeatureChange(this.props.curRole);
  };

  setCheckBox = (r) => {
    console.log(r);
  };

  chkStatus = false;

  render() {
    const features = this.props.features;
    return (
      <div className="card  ">
        <h5 className="card-header pb-4">Roles Features</h5>

        <div className="card-body">
          <div className="page-todo">
            <div className="tasks">
              {features.map((r) => {
                if (r.roleId === null) {
                  this.chkStatus = false;
                } else {
                  this.chkStatus = true;
                }
                return (
                  <div className="task nil" key={r.featureId}>
                    <div className="desc">
                      <span className="title">
                        {this.rCBHLLS(
                          "cb_" + r.featureId,
                          [{ label: r.title, value: r.featureId }],
                          { value: "value", label: "label" },
                          this.chkStatus
                        )}
                      </span>
                      <div>
                        <span
                          className={
                            "badge badge-success pull-left mr-1 " +
                            "badge-" +
                            r.division
                          }
                        >
                          {r.division}
                        </span>
                        <span
                          className={
                            "badge badge-success pull-left mr-1 " +
                            "badge-" +
                            r.module
                          }
                        >
                          {r.module}
                        </span>
                        <span
                          className={
                            "badge badge-success pull-left mr-1 " +
                            "badge-" +
                            r.groupName
                          }
                        >
                          {r.groupName}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RolesFeatures;
