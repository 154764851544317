import _http from "./../_httpService";

import config from "./../../config.json";

const baseURL = config[config.build].apiURLValidata;

function api(path, subURL = "/4team/tasks") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function createTask(req) {
  const param = { ...req };
  param.estimatedTime += ":00";
  param.actualTime += ":00";

  delete param.taskId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveTask(req) {
  const param = { ...req };

  const id = param.taskId;
  delete param.taskId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteTask(param) {
  return _http.delete(api(param.taskId), _http.headerJWT());
}

export function getTasks(taskId) {
  return _http.get(api("by-userstory/" + taskId), _http.headerJWT());
  // return _http.get(api(""), _http.headerJWT());
}
export async function getTasksByProjStatus(param, pageNo = 1) {
  if (param.typeList && param.typeList === "tasks") {
    return await _http.get(
      api("by-project-status/" + param.status, "/4team/tasks"),
      _http.headerJWT()
    );
  } else {
    return await _http.get(
      api("by-project-status/" + param.status, "/4team/user-stories"),
      _http.headerJWT()
    );
  }
}
