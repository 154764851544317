import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import $ from "jquery";

import {
  createPrvMenuGroups,
  savePrvMenuGroups,
  deletePrvMenuGroups,
} from "./../../../services/menus/PrvMenuGroupsServices";

class ModalMenuGroup extends FormLib {
  state = {
    data: {
      menuGroupId: "",
      menuType: "",
      groupCode: "",
      groupName: "",
      status: "active",
    },
    errors: {},
    // menuGroups: [],
    // curGroupName: {},

  };

  schema = {
    menuGroupId: Joi.optional().label("MenuGroupId"),
    menuType: Joi.string().required().label("MenuType"),
    groupCode: Joi.string().required().label("MenuGroupCode"),
    groupName: Joi.string().required().label("GroupName"),
    status: Joi.string().required().label("Status*"),
  };

  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createPrvMenuGroups(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await savePrvMenuGroups(this.state.data);
    }
    if (this.state.btnValue === "delete") {
      res = await deletePrvMenuGroups(this.state.data);
      $("#curGroupButton").click();
      this.resetData();
    }
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.props.onloadPrvMenuGroups();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  componentDidMount() {
    this.setCurGroupName(this.props.curGroupName);
  }

  setCurGroupName(g) {


    if (g.menuGroupId !== "") {
      g.status = "active";
      this.setState({ data: g });
    } else {
      this.resetData();
    }
  }

  resetData() {
    this.setState({
      data: {
        menuGroupId: "",
        menuType: "",
        groupCode: "",
        groupName: "",
        status: "active",
      },
    });
  }

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;
    // {"==>"+this.state.data.roleGroupId + "<=="}
    return (
      <div className="container-fluid">
        <div className="modal fade" id="myGroupModel">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Prv MenuGroups</h4>

                <button
                  type="button"
                  className="close"
                  id="curGroupButton"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  {this.rTBH("MenuGroupId", "menuGroupId")}
                  {this.rTB("MenuType", "menuType")}
                  {this.rTB("MenuGroupCode", "groupCode")}
                  {this.rTB("GroupName", "groupName")}
                  <div className="form-row">
                    {this.rRBL("Status", "status", this.status, this.stsConf, "col-12")}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {/* {"==>"+this.state.data.menuGroupId + "<=="} */}
                {this.state.data.menuGroupId === "" &&
                  this.rBtnSP("Create", "create")}

                {this.state.data.menuGroupId !== "" && (

                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {this.rBtnSD("Delete", "delete")}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalMenuGroup;
