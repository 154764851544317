import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import $ from "jquery";

import {
  getEmps,
  createEmps,
  saveEmps,
  deleteEmps,
} from "../../../services/players/empsServices";

import toast from "../../../services/_toastService";

class EmpCodeForm extends FormLib {
  state = {
    data: {
      empCode: this.props.curEmp.empCode,
    },
    errors: {},
    curEmp:{}
  };

  schema = {
    empCode: Joi.string().required().label("Emp code "),
  };

  componentDidMount() {
    this.serCurTeam(this.props.curEmp);
    
  }
  serCurTeam(d){
    this.setState({curEmp : d});
  }
 

  async doSubmit() {
    let res;
    
    if (this.state.btnValue === "edit") {
      res = await saveEmps(this.state.data,this.props.curEmp.playerId);
      this.resetData();
      this.props.onloadCurTeam(this.props.currentTeam.teamId, this.props.curPage.pageNo);
    }
    
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadEmps();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  resetData() {
    this.setState({
      data: {
        empCode: "",
      },
    });
  }

  render() {


    return (
      <React.Fragment>
       
          <div className="card pt-4">
              <div className="card-body p-3">
                <form>
                  <div className="h6 ml-2">
                    Employee Name : {this.state.curEmp.firstName + " " +this.state.curEmp.lastName}
                    {/* {JSON.stringify( this.state.curEmp)} */}
                  </div>
                  <div className="form-row mt-5">
                    {/* {this.rTB("PlayerId", "playerId", "col-6")} */}
                    {this.rTB("Emp code", "empCode", "col-12")}
                  </div><br />

                </form>
            {this.state.data.playerId !== "" && (
              <>
                {this.rBtnSS("Edit", "edit")}
                {/* {this.rBtnSD("Delete", "delete")} */}
              </>
            )}
            </div>
        </div>
      </React.Fragment>
    );
  }
}
export default EmpCodeForm;
