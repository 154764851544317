import React from "react";
import FormLib from "./../../../_common/FormLib";
import Table from "./../../../_common/Table";
// import toast from "./../../../../services/_toastService";
import { toast } from "react-toastify";

import {
  getCompleted,
  getLinehaulTicktes,
} from "../../../../services/linehaul/linehaulServices";
import dateFormat from "dateformat";
import { epoch2ddmmyyyyhhmmss } from "../../../../utils/DateUtils";
import Pagination from "./../../../_common/Pagination";
import Joi from "joi-browser";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import DocumentDetails from "./actions/DocumentDetails";
import ExpenseRise from "./actions/ExpenseRise";

export default class CompletedManifest extends FormLib {
  state = {
    completed: [],
    errors: {},
    data: { search: "" },
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    documentDetails: false,
    openStatus: "true",
    currentRequest: {},
  };
  schema = {
    search: Joi.optional().label("search"),
  };
  componentDidMount() {
    this.getLinehaulList(this.state.pageLinks.pageNo);
    if (this.props?.refreshListCallback) {
      this.props.refreshListCallback(this.getLinehaulList);
    }
  }

  getLinehaulList = async (pageNo = this.state.pageLinks.pageNo) => {
    try {
      this.setState({ loading: true });
      let selectedHub = localStorage.getItem("curHub");
      let params = {
        srcHubId: selectedHub,
        destHubId: selectedHub,
        searchString: this.state.data.search,
      };
      const res = await getCompleted(params, pageNo);
      if (res.data.success === true) {
        if (res.data.data.completedShipment != 0) {
          this.setState({ completed: res.data.data.completedShipment });
          this.setState({ pageLinks: res.data.data.pageLinks });
        } else {
          toast.info(res.data.msg);
          this.state.completed = [];
        }
      } else {
        toast.error(res.data.msg);
        this.setState({
          pageLinks: {
            pageNo: 1,
            perPage: 0,
            itemsCount: 0,
            lastPage: 0,
          },
        });
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in OutgoingManifest>>>>>> getLinehaulList");
    }
  };

  replaceString = (str) => {
    return str.replace(/_/g, " ");
  };
  tableMeta = {
    key: "shipmentNo",
    columns: [
      { path: "linehaulSeq", label: "Linehaul Ref No" },
      // { path: "shipmentNo", label: "Shipment No." },
      
      {
        // ***************************************************dfdfdfd**********************************************************

        // path: "REF_ID",  label: "Ref No."

        key: "ticketNo",
        action: (r) => (
          <>
            <span
              className="badge badge-success link-over"
              onClick={() => this.onloadDocumentDetails(r)}
              title="Click to View/Upload files"
            >
              {/* {r["ticketNo"] && r["ticketNo"].substring(0, 30)}{" "} */}
              {r["multiTicketIds"]
                ? r["multiTicketIds"].substring(0, 30)
                : r["multiTicketIds"]}
            </span>
            <div class="icon-badge-container ">
              <div
                class="icon-badge cursorPointor"
                style={{
                  marginLeft: "96%",
                  marginTop: "-14%",
                  position: "relative",
                  backgroundColor: "#ff6c00",
                }}
                title="Number of Tickets"
              >
                {r["multiTicketIds"] &&(r["multiTicketIds"].split(",").length)}
              </div>
            </div>
          </>
        ),
        icon: () => <>Ticket Number</>,
        label: "#",
      },
      {
        key: "ticketCreationDate",
        action: (r) => <>{epoch2ddmmyyyyhhmmss(r.ticketCreationDate)}</>,
        cusClass: " ",
        icon: () => <>Ticket Creation Date</>,
      },
      {
        key: "shipmentTrackingURL",
        action: (r) => {
          if (r.shipmentNo && r.logisticsProvider === "Delhivery") {
            return (
              <>
                <a
                  href={`https://www.delhivery.com/track/lr/${r.shipmentNo}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {r.shipmentNo}
                </a>
              </>
            );
          }
          return <>{r.shipmentNo}</>;
        },
        icon: () => <>LH docket</>,
      },
     
      {
        key: "createdAt",
        action: (r) => <>{epoch2ddmmyyyyhhmmss(r.createdAt)}</>,
        cusClass: " ",
        icon: () => <>Manifest Creation Date</>,
      },
      // { path: "ShipmentCompleted_date", label: "Shipment Completed Date" },
      { path: "srcLocation", label: "Source Location" },
      { path: "dstLocation", label: "Drop Location" },
      // { path: "logisticsProvider", label: "Logistics Provider" },
      {
        key: "logisticsProvider",
        action: (r) => <>{this.replaceString(r.logisticsProvider)}</>,
        cusClass: " ",
        icon: () => <>Logistics Provider</>,
      },
      { path: "destination", label: "Destination type" },
      {
        key: "Expense",
        action: (r) => (
          <>
            <div className="d-flex justify-content-center">
              <>
                <i
                  className="fa fa-telegram cursorPointor ="
                  style={{ fontSize: "30px", color: "green" }}
                  onClick={() => this.handleExpenseRise(r)}
                  title="Raise Expense"
                ></i>
              </>
            </div>
          </>
        ),
        icon: () => (
          <div className="d-flex justify-content-center">Raise Expense</div>
        ),
      },
    ],
  };

  handleSearch = () => {
    let pageLinks = this.state.pageLinks;
    pageLinks["pageNo"] = "1";
    this.setState({ pageLinks });
    this.getLinehaulList(this.state.pageLinks.pageNo);
  };

  handlePageChange = (pageNo) => {
    // this.props.history.push("/ticketProcess/"+ pageNo);
    this.getLinehaulList(pageNo);
  };

  onloadDocumentDetails = (r) => {
    this.setState({ documentDetails: "true" });
    this.setState({ openStatus: "false" });
    this.setState({ currentRequest: r });
  };

  closeModal = () => {
    this.setState({ openStatus: "false" });
    this.setState({ documentDetails: "false" });
  };
  // handleView = () => {
  //   $("#myDocumentDetails").draggable();
  // };
  handleExpenseRise = async (r) => {
    try {
      let resp = await getLinehaulTicktes(r.linehaulSeq);
      if (resp.data.success === true) {
        if (resp.data.data) {
          toast.success("Ticket Action Fetched Successfully");
          this.setState({ ticketDetails: resp.data.data });
        } else {
          !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
      this.setState({ documentDetails: "false" });
      this.setState({ openStatus: "true" });
      this.setState({ ticketStatus: "false" });

      this.setState({ currentRequest: r });
    } catch {
      console.log("CompletedManifest ----------->>> handleExpenseRise");
    }
  };

  render() {
    const completedJSON = this.state.completed;
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    const currentRequest = this.state.currentRequest;
    const ticketRequest = this.state.ticketDetails;

    // console.log({completedJSON})
    return (
      <>
        <div>
          <div className="form-subHeader">Completed Manifest</div>
          <div className="form-row col-12">
            <div className="col-4">
              {this.rTB("Search Ticket No.", "search")}
            </div>
            <div className="col-4">
              <button
                className="btn  btn-primary  btn-sm"
                onClick={() => this.handleSearch(this.state.data.search)}
              >
                Search
              </button>
            </div>
          </div>
          <div className="form-row col-12">
            {this.props.loading && (
              <div className="container-fluid">
                <div> Loading users...</div>
              </div>
            )}
            {!this.props.loading && (
              <>
                <Table
                  clsName="table table-striped table-bordered table-hover"
                  tableMeta={this.tableMeta}
                  data={completedJSON}
                  dropMenu={[]}
                />
                {this.state.completed.length !== 0 && (
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {this.state.completed.length === 0 && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">No Tickets Found</p>
          </div>
        )}
        <div
          className="draggable col-10 ml-5 mr-5"
          id="myDocumentDetails"
          onClick={this.handleView}
        >
          {this.state.openStatus === "false" &&
            this.state.documentDetails === "true" && (
              <DocumentDetails
                key={currentRequest.ticketNo}
                data={currentRequest}
                // requestStatusChange={this.handleRequestStatusChange}
                // user={this.state.user}
                closeModal={this.closeModal}
              />
            )}
        </div>
        <div
          className="draggable  col-sm-12 col-md-8 col-lg-8 "
          id="myModalApprove"
          onClick={this.handleView}
        >
          {
            // this.state.TicketActionsDatas &&
            this.state.openStatus === "true" &&
              this.state.documentDetails === "false" &&
              this.state.ticketStatus === "false" && (
                <ExpenseRise
                  key={currentRequest.ticketNo}
                  data={currentRequest}
                  type="report"
                  ticketDetails={ticketRequest}
                  // ticketActionId={currentRequest.ticketActionId}
                  // requestChange={this.handleRequestChange}
                  // user={this.state.user}
                  closeModal={this.closeModal}
                />
              )
          }
        </div>
      </>
    );
  }
}
