import React from "react";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { paymentsDownloded } from "./../../../services/requestV1/requestServices";

export const ExportXLSX = ({ csvData, header, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (csvData, fileName) => {
    await handleDownloadClicked(csvData);

    const ws = XLSX.utils.json_to_sheet(csvData, { header });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleDownloadClicked = async (items) => {
    // console.log("submit export");
    let resp;
    resp = await paymentsDownloded(items);
    // console.log(resp);
    return null;
  };

  return (
    <button
      className="btn btn-primary btn-sm"
      onClick={(e) => exportToCSV(csvData, fileName)}
    >
      Export
    </button>
  );
};

export const ExportXLSXReqTrans = ({ csvData, header, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData, { header });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button
      className="btn btn-primary btn-sml"
      onClick={(e) => exportToCSV(csvData, fileName)}
    >
      Export
    </button>
  );
};

export const ExportXLSXTally = ({ csvData, header, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData, { header });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button
      className="btn btn-primary btn-sml"
      onClick={(e) => exportToCSV(csvData, fileName)}
    >
      Export Tally
    </button>
  );
};


export const ExportXLSXMIS = ({ csvData, header, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (csvData, fileName) => {
    await handleDownloadClicked(csvData);

    const ws = XLSX.utils.json_to_sheet(csvData, { header });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleDownloadClicked = async (items) => {
    // console.log("submit export");
    let resp;
    resp = await paymentsDownloded(items);
    // console.log(resp);
    return null;
  };

  return (
    <button
      className="btn btn-primary btn-sm"
      onClick={(e) => exportToCSV(csvData, fileName)}
      style={{ width: "66px", marginLeft: "92%", backgroundColor: "#048b20", borderColor: "rgb(132 119 4)" }}
    >
      Export
    </button>
  );
};

export const ExportXLSXReqLinehaul = ({ csvData, header, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData, { header });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button
      className="btn btn-primary btn-sm"
      onClick={(e) => exportToCSV(csvData, fileName)}
      style={{ position: "relative", width: "66px", marginLeft: "105%", marginTop: "-15%", backgroundColor: "#048b20", borderColor: "rgb(132 119 4)" }}

    >
      Export
    </button>
  );
};



export const ExportXLSXReqTicketHistory = ({ csvData, header, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData, { header });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <button
      className="btn btn-primary btn-sm"
      onClick={(e) => exportToCSV(csvData, fileName)}
      style={{ position: "relative", width: "66px", marginLeft: "105%", marginTop: "-15%", backgroundColor: "#048b20", borderColor: "rgb(132 119 4)" }}

    >
      Export
    </button>
  );
};