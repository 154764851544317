
import FormLib from "../../_common/FormLib";
import React from "react";
import ZTitle from "./../../_subhead/ZTitle";
// import toast from "../../../services/_toastService";

import Joi from "joi-browser";
import Table from "./../../_common/Table";
import { dropMenuFull } from "./../../../utils/DropMenuUtils";

import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import Pagination from "../../_common/Pagination";

import { getVehicleDetails } from "../../../services/Tickets/TicketProcessServices";
import { toast } from "react-toastify";
import DocumentDetails from "./actions/DocumentDetails";
import StartDocumentDetails from "./actions/StartDocumentDetails";
import FileDetails from "./actions/FileDetails";
import TicketsList from "./actions/TicketsList";
import { ExportXLSXReqLinehaul } from "../requestV1/ExportXLSX";

class VehicleTrip extends FormLib {
  state = {
    data: {
      startDate: this.getDatebyDays(0),
      endDate: this.getDatebyDays(0),
      searchString: "",
    },
    TicketVehiclesDatas: [],
    TicketActionsData: [],

    userCode: "",
    errors: {},
    hubs: [],
    clients: [],
    actionsList: [],
    currentRequest: {},
    openStatus: true,
    documentDetails: false,
    ticketStatus: true,
    currentRequests: {},
    currentTicketRequest: {},
    openStatuses: true,
    ticketListStatus: true,
    startDocumentDetails: false,
    ticketsList: false,
    ticketStatuses: true,
    statusOfTicketList: true,
    ticketCurrentRequest: {},
    ticketOpenStatus: true,
    ticketDetails: false,
    ticketsStatus: true,
    showUpdateBtn: false,
    vehicleTempArray: [],
    isModalOpen: false,
    filterdTripId: [],
    searchTripId: "",
    searchVehNo: "",
    searchVehType: "",
    searchTravel: "",
    searchVehLoadType: "",
    searchTicketNo: "",
    searchFlow: "",
    searchLogisticProvider: "",
    fileName: "",
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },

    pageLinksExport: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };

  schema = {
    startDate: Joi.string().required().label("Start Date"),
    endDate: Joi.string().required().label("End Date"),
    searchString: Joi.optional().label("searchString"),
  };

  componentDidMount() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.state.userCode = userInfo.code;
    this.state.userInfoTeamId = userInfo.teamId;
    this.setState({ loading: true });
  }

  async doSubmit() {
    try {
      let resp = await getVehicleDetails(
        this.state.data,
        this.state.pageLinks.pageNo
      );
      // console.log({ resp });

      if (resp.data.success === true) {
        if (resp.data.data) {
          this.setState({
            TicketVehiclesDatas: resp.data.data.vehicleTripDetails,
          });
          this.setState({
            filterdTripId: resp.data.data.vehicleTripDetails,
          });
          this.setState({ pageLinks: resp.data.data.pageLinks });
          toast.success("Vehicle Details Fetched Successfully");
          this.setState({ loading: false });
          this.setState({pageLinks: { ...this.state.pageLinks, pageNo: 1 },})
        } else {
          !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
          this.setState({ loading: true });
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
        this.setState({ loading: true });
      }

      this.state.vehicleTempArray = [];
      const res = await getVehicleDetails(this.state.data, 1);
      if (res.data.success === true) {
        if (res.data.data.vehicleTripDetails !== 0) {
          res.data.data.pageLinks &&
            this.setState({ pageLinksExport: res.data.data.pageLinks });
        } else {
          toast.info(() => <div>{res.data.msg}</div>);
        }
      } else {
        toast.error(resp.data.msg);
      }

      for (
        var i = this.state.pageLinksExport.pageNo;
        i <= this.state.pageLinksExport.lastPage;
        i++
      ) {
        const res = await getVehicleDetails(this.state.data, i);
        if (res) {
          if (res.data.success === true && res.data.data.vehicleTripDetails) {
            this.setState({ report: res.data.data.vehicleTripDetails });
            this.state.report.map(async (r) => {
              this.state.vehicleTempArray.push(r);
            });

          } else {
            const res = await getVehicleDetails(this.state.data, i);
            if (res.data.success === true && res.data.data.vehicleTripDetails) {
              this.setState({ report: res.data.data.vehicleTripDetails });
              this.state.report.map(async (r) => {
                this.state.vehicleTempArray.push(r);
              });

            }
          }
        } else {
          const res = await getVehicleDetails(this.state.data, i);
          if (res.data.success === true && res.data.data.vehicleTripDetails) {
            this.setState({ report: res.data.data.vehicleTripDetails });
            this.state.report.map(async (r) => {
              this.state.vehicleTempArray.push(r);
            });

          }
        }
      }

      this.setState({ report: this.state.vehicleTempArray });
      this.formatExcelReport(this.state.report);



    } catch {
      console.log("Error in VehicleTrip>>>>>> getVehicleDetails");
    }
  }

  handleModalSubmit = () => {
    this.doSubmit();
  };

  handleModalClose = () => {
    this.setState({ isModalOpen: false });
  };

  formatExcelReport = async (r) => {

    this.setState({ formatData: r });
  };

  excelHeaders = [
    "vehicleNo",
    "flowId",
    "travel",
    "logisticsProvider",
    "vehicleLoadType",
    "vehicleType",
    "tripStart",
    "tripEnd",
    "distanceTravelled",
    "vehicleLoadWt"
  ];


  onTextChanged = (e) => {
    const value = e.target.value;
    this.setState(() => ({
      fileName: value,
    }));
  };


  tableMeta = {
    key: "tripId",
    columns: [


      {
        key: "Trip_Id",
        action: (r) => (
          <>
            <span>{r.tripId}</span>
          </>
        ),
        cusClass: " ",
        icon: () => (
          <div>
            <p>TripId</p>
            <input
              type="text"
              name="searchTripId"
              id="searchTripId"
              placeholder="search"
              value={this.state.searchTripId}
              onChange={(e) => {
                this.setState({ searchTripId: e.target.value });
                const filterdTripId =
                  this.state.vehicleTempArray?.filter((trip) => {
                    const tripId = trip.tripId;
                    return tripId
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  });
                this.setState({ filterdTripId });
              }}
            />
          </div>
        ),
      },
      {
        key: "vehicleNo",
        action: (r) => (
          <>
            <span>{r.vehicleNo}</span>
          </>
        ),
        cusClass: " ",
        icon: () => (
          <div>
            <p>Vehicle Number</p>
            <input
              type="text"
              name="searchVehNo"
              id="searchVehNo"
              style={{ maxWidth: "5rem" }}
              placeholder="search"
              value={this.state.searchVehNo}
              onChange={(e) => {
                this.setState({ searchVehNo: e.target.value });
                const filterdTripId =
                  this.state.vehicleTempArray?.filter((trip) => {
                    const tripId = trip.vehicleNo;
                    return tripId
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  });
                this.setState({ filterdTripId });
              }}
            />
          </div>
        ),
      },
      {
        key: "vehicleType",
        action: (r) => (
          <>
            <span>{r.vehicleType}</span>
          </>
        ),
        cusClass: " ",
        icon: () => (
          <div>
            <p>Vehicle Type</p>
            <input
              type="text"
              name="searchVehType"
              id="searchVehType"
              style={{ maxWidth: "6rem" }}
              placeholder="search"
              value={this.state.searchVehType}
              onChange={(e) => {
                this.setState({ searchVehType: e.target.value });
                const filterdTripId =
                  this.state.vehicleTempArray?.filter((trip) => {
                    const tripId = trip.vehicleType;
                    return tripId
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  });
                this.setState({ filterdTripId });
              }}
            />
          </div>
        ),
      },
      {
        key: "travel",
        action: (r) => (
          <>
            <span>{r.travel}</span>
          </>
        ),
        cusClass: " ",
        icon: () => (
          <div>
            <p>Travel</p>
            <input
              type="text"
              name="searchTravel"
              id="searchTravel"
              style={{ maxWidth: "5rem" }}
              placeholder="search"
              value={this.state.searchTravel}
              onChange={(e) => {
                this.setState({ searchTravel: e.target.value });
                const filterdTripId =
                  this.state.vehicleTempArray?.filter((trip) => {
                    const tripId = trip.travel;
                    return tripId
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  });
                this.setState({ filterdTripId });
              }}
            />
          </div>
        ),
      },
      {
        key: "vehicleLoadWt",
        action: (r) => (
          <>
            <div className="d-flex justify-content-center">
              <>
                <a>{r.vehicleLoadWt}</a>
              </>
            </div>
          </>
        ),
        icon: () => (
          <div className="d-flex justify-content-center">
            Vehicle Load (Kgs)
          </div>
        ),
      },
      {
        key: "vehLoadType",
        action: (r) => (
          <>
            <span>{r.vehicleLoadType}</span>
          </>
        ),
        cusClass: " ",
        icon: () => (
          <div>
            <p>Vehicle LoadType</p>
            <input
              type="text"
              name="searchVehLoadType"
              id="searchVehLoadType"
              style={{ maxWidth: "3rem" }}
              placeholder="search"
              value={this.state.searchVehLoadType}
              onChange={(e) => {
                this.setState({ searchVehLoadType: e.target.value });
                const filterdTripId =
                  this.state.vehicleTempArray?.filter((trip) => {
                    const tripId = trip.vehicleLoadType;
                    return tripId
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  });
                this.setState({ filterdTripId });
              }}
            />
          </div>
        ),
      },
      {
        key: "ticketNo",
        action: (r) => (
          <>
            <span
              className="badge badge-success link-over"
              onClick={() => this.onloadTicketsList(r)}
              title="Click to View Tickets"
            >
              {r["ticketsOftheTrip"]
                ? r["ticketsOftheTrip"].substring(0, 30)
                : r["tripId"]}
            </span>

          </>
        ),
        icon: () => (
          <div>
            <p>Ticket Number</p>
            <input
              type="text"
              name="searchTicketNo"
              id="searchTicketNo"
              placeholder="search"
              value={this.state.searchTicketNo}
              onChange={(e) => {
                this.setState({ searchTicketNo: e.target.value });
                const filterdTripId =
                  this.state.vehicleTempArray?.filter((trip) => {
                    const tripId = trip.ticketsOftheTrip;
                    return tripId
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  });
                this.setState({ filterdTripId });
              }}
            /></div>
        ),
      },
      // {
      //   key: "flowId",
      //   action: (r) => (
      //     <>
      //       <span>{r.flowId}</span>
      //     </>
      //   ),
      //   cusClass: " ",
      //   icon: () => (
      //     <div>
      //       <p>Flow</p>
      //       <input
      //         type="text"
      //         name="searchFlow"
      //         id="searchFlow"
      //         style={{ maxWidth: "5rem" }}
      //         placeholder="search"
      //         value={this.state.searchFlow}
      //         onChange={(e) => {
      //           this.setState({ searchFlow: e.target.value });
      //           const filterdTripId =
      //             this.state.vehicleTempArray?.filter((trip) => {
      //               const tripId = trip.flowId;
      //               return tripId
      //                 .toLowerCase()
      //                 .includes(e.target.value.toLowerCase());
      //             });
      //           this.setState({ filterdTripId });
      //         }}
      //       />
      //     </div>
      //   ),
      // },
      {
        key: "logisticsProvider",
        action: (r) => (
          <>
            <span>{r.logisticsProvider}</span>
          </>
        ),
        cusClass: " ",
        icon: () => (
          <div>
            <p>Logistic Provider</p>
            <input
              type="text"
              name="searchLogisticProvider"
              id="searchLogisticProvider"
              style={{ maxWidth: "3rem" }}
              placeholder="search"
              value={this.state.searchLogisticProvider}
              onChange={(e) => {
                this.setState({ searchLogisticProvider: e.target.value });
                const filterdTripId =
                  this.state.vehicleTempArray?.filter((trip) => {
                    const tripId = trip.logisticsProvider;
                    return tripId
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase());
                  });
                this.setState({ filterdTripId });
              }}
            />
          </div>
        ),
      },
      { path: "driverName", label: "driverName" },
      {
        key: "tripStart",
        action: (r) => {
          if (

            r.tripStart === "null" ||
            r.tripStart === null ||
            r.tripStart === ""
          ) {
            return (
              <div className="clearfix tripStart">
                {0}

                <i
                  className="fa fa-pencil fa-2x pull-right fs-5 edit-tripStart link-over"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  aria-hidden="true"
                  title="Add TripStart"
                  onClick={() => this.onloadStartDocumentDetails(r)}
                />
              </div>
            );
          } else {
            return (
              <>
                <a>{r.tripStart}</a>
              </>
            );
          }
        },
        icon: () => (
          <div className="d-flex justify-content-center">Trip Start (Kms)</div>
        ),
      },
      // { path: "tripStart", label: "Trip Start" },

      // {
      //   key: "tripEnd",
      //   action: (r) => (
      //     <>
      //       <div className="d-flex justify-content-center">
      //         {r.tripStart === r.tripEnd && (
      //           <>
      //             <>{0} </>
      //           </>
      //         )}
      //         {r.tripStart !== r.tripEnd && (
      //           <>
      //             <a>{r.tripEnd}</a>
      //           </>
      //         )}
      //       </div>
      //     </>
      //   ),
      //   icon: () => (
      //     <div className="d-flex justify-content-center">Trip End (Kms)</div>
      //   ),
      // },

      {
        key: "tripEnd",
        action: (r) => {
          if (
            r.tripEnd === r.tripStart ||
            r.tripEnd === "null" ||
            r.tripEnd === null ||
            r.tripEnd === ""
          ) {
            return (
              <div className="clearfix tripEnd">
                {0}

                {/* <i
                  className="fa fa-pencil fa-2x pull-right fs-5 edit-tripEnd"
                  data-bs-toggle="modal"
                  data-bs-target="#staticBackdrop"
                  aria-hidden="true"
                  title="Add TripEnd"
                  onClick={() => this.onloadDocumentDetails(r)}
                /> */}
              </div>
            )

          } else {
            return (

              <>
                <a>{r.tripEnd}</a>
              </>
            )
          }

        },
        icon: () => <>Trip End (Kms)</>,
      },
      {
        key: "distanceTravelled",
        action: (r) => (
          <>
            <div className="d-flex justify-content-center">
              <>
                <a>{r.distanceTravelled}</a>
              </>
            </div>
          </>
        ),
        icon: () => (
          <div className="d-flex justify-content-center">
            Distance Travelled (Kms)
          </div>
        ),
      },
      {
        key: "ticketNo",
        action: (r) => (
          <>
            {
              r.tripStatus !== "close" ? (
                <i
                  className="fa fa-pencil fa-2x pull-right fs-5 closeTheTrip link-over"
                  aria-hidden="true"
                  title="Close the trip"
                  onClick={() => this.onloadTicketDetails(r)}
                />
              ) : (
                <span className="closed-text">Closed</span>
              )
            }

          </>
        ),
        icon: () => (
          <div className="d-flex justify-content-center">Trip Closure</div>
        ),
      },
      // { path: "tripClose", label: "Trip Close" },
      // { path: "tripEnd", label: "Trip End" },
      // { path: "distanceTravelled", label: "Distance Travelled" },
      // { path: "vehicleLoadWt", label: "Vehicle Load (Kgs)" },
    ],
  };

  onloadDocumentDetails = (r) => {
    this.setState({ documentDetails: "true" });
    this.setState({ openStatus: "false" });
    this.setState({ ticketStatus: "true" });

    this.setState({ currentRequest: r });
  };

  onloadStartDocumentDetails = (r) => {
    this.setState({ startDocumentDetails: "true" });
    this.setState({ openStatuses: "false" });
    this.setState({ ticketStatuses: "true" });

    this.setState({ currentRequests: r });
  };
  onloadTicketsList = (r) => {
    this.setState({ ticketsList: "true" });
    this.setState({ ticketListStatus: "false" });
    this.setState({ statusOfTicketList: "true" });

    this.setState({ currentTicketRequest: r });
  };

  onloadTicketDetails = (r) => {
    this.setState({ ticketDetails: "true" });
    this.setState({ ticketOpenStatus: "false" });
    this.setState({ ticketsStatus: "true" });

    this.setState({ ticketCurrentRequest: r });
  };

  handlePageChange = (pageNo) => {
    this.props.history.push("/VehicleTrip/" + pageNo);
    let pageLinks = this.state.pageLinks;
    pageLinks["pageNo"] = pageNo;
    this.setState({ pageLinks });
    this.doSubmit();
  };
  closeModal = () => {
    this.setState({ openStatus: "false" });
    this.setState({ documentDetails: "false" });
    this.setState({ openStatuses: "false" });
    this.setState({ startDocumentDetails: "false" });
    this.setState({ ticketsList: "false" });
    this.setState({ ticketOpenStatus: "false" });
    this.setState({ ticketDetails: "false" });
    this.setState({ ticketListStatus: "false" });
  };
  render() {

    const reportJSON = this.state.TicketVehiclesDatas;
    // const ticketActionJSON = this.state.TicketActionsData;
    const currentRequest = this.state.currentRequest;
    const currentRequests = this.state.currentRequests;
    const currentTicketRequest = this.state.currentTicketRequest;
    const ticketCurrentRequest = this.state.ticketCurrentRequest;
    console.log({ hhjhhojopsohigdujb: this.state.vehicleTempArray });
    // console.log({ hhsgdvjgukhsflisr: this.state.TicketVehiclesDatas });
    // console.log(this.state.ticketCurrentRequest)
    // console.log({ ghgh: this.state.TicketVehiclesDatas });

    // const ticketRequest = this.state.ticketDetails;
    // const ticketActions = this.state.ticketActions;

    const { pageNo, perPage, count } = this.state.pageLinks;

    return (
      <>
        <ZTitle subHeading="Vehicle Trip Details" />
        <div className="pageContent">
          <div
            className="card"
            style={{ backgroundColor: "rgb(210 126 49 / 0%)" }}
          >
            <div
              className="card-body"
              style={{
                backgroundColor: "white",
                maxWidth: "97%",
                marginLeft: "1%",
              }}
            >
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-11">
                    <div className="form-row mt-2 ">
                      {this.rDPF("Start Date *", "startDate", " col-md-2 ")}
                      {this.rDPF("End Date *", "endDate", " col-md-2 ")}
                      {this.rTB(
                        "Search vehicle number",
                        "searchString",
                        "col-md-2"
                      )}
                    </div>
                  </div>
                  <div
                    className="col-1 pull-right"
                    style={{ paddingTop: "65px", paddingLeft: "37px" }}
                  >
                    {this.rBtnSP("Submit", "Submit")}
                  </div>
                </div>
              </form>
            </div>
            {reportJSON.length > 0 && (
              <div className="form-row mt-5">
                {this.props.loading && (
                  <div className="container-fluid">
                    <div> Loading Vehicle Details...</div>
                  </div>
                )}
                <div
                  className="col-2 form-group "
                  style={{ position: "relative", left: "10%" }}
                >
                  <input
                    value={this.state.fileName}
                    name="fileName"
                    onChange={this.onTextChanged}
                    type="text"
                    autoComplete="off"
                  />
                  <label htmlFor="fileName" className="control-label">
                    FileName *
                  </label>
                  <i className="bar"></i>
                  <ExportXLSXReqLinehaul
                    csvData={this.state.formatData}
                    header={this.excelHeaders}
                    fileName={this.state.fileName + "-" + this.getDatebyDays(0)}
                  />
                </div>
                {!this.props.loading && (
                  <>
                    <div className="container-fluid">
                      <div style={{ overflow: "auto" }}>
                        {/* {(this.state.searchTripId !== "" || this.state.searchVehNo !== "") && (
                          <Table
                            clsName="table table-striped table-bordered table-hover"
                            tableMeta={this.tableMeta}
                            data={this.state.filterdTripId}
                            dropMenu={[]}
                          />
                        )}{
                          (this.state.searchTripId === "" || this.state.searchVehNo === "") && (
                            <Table
                              clsName="table table-striped table-bordered table-hover"
                              tableMeta={this.tableMeta}
                              data={this.state.TicketVehiclesDatas}
                              dropMenu={[]}
                            />
                          )
                        } */}
                        {this.state.searchTripId !== "" ||
                          this.state.searchVehNo !== "" ||
                          this.state.searchVehType !== "" ||
                          this.state.searchTravel !== "" ||
                          this.state.searchVehLoadType !== "" ||
                          this.state.searchTicketNo !== "" ||
                          this.state.searchFlow !== "" ||
                          this.state.searchLogisticProvider !== "" ? (
                          <Table
                            clsName="table table-striped table-bordered table-hover"
                            tableMeta={this.tableMeta}
                            data={this.state.filterdTripId}
                            dropMenu={[]}
                          />
                        ) : (
                          <Table
                            clsName="table table-striped table-bordered table-hover"
                            tableMeta={this.tableMeta}
                            data={this.state.TicketVehiclesDatas}
                            dropMenu={[]}
                          />
                        )}

                      </div>
                    </div>
                    <Pagination
                      pageNo={pageNo}
                      perPage={perPage}
                      itemsCount={count}
                      onPageChange={this.handlePageChange}
                    />
                  </>
                )}
              </div>
            )}

            <div
              className="draggable col-sm-12 col-md-8 col-lg-4"
              id="myDocumentDetails"
              onClick={this.handleView}
            >
              {this.state.TicketVehiclesDatas &&
                this.state.openStatus === "false" &&
                this.state.documentDetails === "true" &&
                this.state.ticketStatus === "true" && (
                  <DocumentDetails
                    key={currentRequest.refNo}
                    data={currentRequest}
                    closeModal={this.closeModal}
                  />
                )}
            </div>
            <div
              className="draggable col-sm-12 col-md-8 col-lg-4"
              id="myDocumentDetails"
              onClick={this.handleView}
            >
              {this.state.TicketVehiclesDatas &&
                this.state.ticketListStatus === "false" &&
                this.state.ticketsList === "true" &&
                this.state.statusOfTicketList === "true" && (
                  <TicketsList
                    key={currentTicketRequest.ticketsOftheTrip}
                    data={currentTicketRequest}
                    // closeModal={this.closeModal}
                    closeModal={() => this.setState({ ticketsList: false })}
                    submit={() => this.doSubmit()}
                  />
                )}
            </div>
            <div
              className="draggable col-sm-12 col-md-8 col-lg-4"
              id="myDocumentDetails"
              onClick={this.handleView}
            >
              {this.state.TicketVehiclesDatas &&
                this.state.openStatuses === "false" &&
                this.state.startDocumentDetails === "true" &&
                this.state.ticketStatuses === "true" && (
                  <StartDocumentDetails
                    key={currentRequests.refNo}
                    data={currentRequests}
                    // closeModal={this.closeModal}
                    closeModal={() => this.setState({ startDocumentDetails: false })}
                    submit={() => this.doSubmit()}
                  />
                )}
            </div>
            <div
              className="draggable col-sm-12 col-md-8 col-lg-4"
              id="myDocumentDetails"
              onClick={this.handleView}
            >
              {this.state.TicketVehiclesDatas &&
                this.state.ticketOpenStatus === "false" &&
                this.state.ticketDetails === "true" &&
                this.state.ticketsStatus === "true" && (
                  <FileDetails
                    key={ticketCurrentRequest.tripId}
                    data={ticketCurrentRequest}
                    // closeModal={this.closeModal}
                    closeModal={() => this.setState({ ticketDetails: false })}
                    submit={() => this.doSubmit()}
                  />
                )}
            </div>
          </div>
        </div>
        {this.state.loading === true && (
          <div className="container-fluid jumbotron text-center">
            <h4>No Data Available...</h4>
          </div>
        )}
      </>
    );
  }
}

export default VehicleTrip;