import React from "react";
import FormLib from "./../../_common/FormLib";
import toast from "../../../services/_toastService";
import { assign, unassign } from '../../../services/coreItAdmin/PlayerRolesServices';


class PlayerRolesMapper extends FormLib {

    state = {
        data: {},
        errors: {},
        curPlayer: {},
    };

    componentDidMount() {
        this.setState({ curPlayer: this.props.curPlr });
    }

    localHandleChange = (e) => {
        this.asignUnAsignPlayersRole(e);
    }

    asignUnAsignPlayersRole = (e) => {
        let paramData = {};
        paramData.playerId = this.state.curPlayer.playerId;
        if (e.target.checked) {
            paramData.roleId = e.target.value;
            // paramData.isPrimary = "true";
            this.assignUnassign('assign', paramData);
        } else {
            paramData.roleId = e.target.value;
            // paramData.isPrimary = "false";
            this.assignUnassign('unAssign', paramData);
        }
    }

    assignUnassign = async (type, paramData) => {
        if (type === 'assign') {
            const res = await assign(paramData);
            if (res.data.success) {
                toast.toastSuccess(res.data.msg);
            } else {
                toast.toastError(res.data.msg);
            }
        } else {
            const res = await unassign(paramData);
            if (res.data.success) {
                toast.toastSuccess(res.data.msg);
            } else {
                toast.toastError(res.data.msg);
            }
        }
        this.props.onLoadRole(this.state.curPlayer.playerId);
    }

    chkStatus = false;
    render() {
        const roles = this.props.roles;
        return (
            <div className="card overflow-auto" style={{height:'30rem'}}>
                <div className="card-body">
                    <div className="page-todo">
                        <div className="tasks">
                            {roles.map((r) => {
                                this.chkStatus = r.playerId === null ? false : true;
                                return <div className="task nil" key={r.roleId}>
                                    <div className="desc">
                                        <span>
                                            <div className="title">
                                                {this.rCBHLLS(
                                                    "cb_" + r.roleId,
                                                    [{ label: r.role, value: r.roleId }],
                                                    { value: "value", label: "label" },
                                                    this.chkStatus,
                                                    "m-0"
                                                )}
                                                </div>
                                        </span>
                                        <div>
                                            <span className={"badge badge-success pull-left mr-1 " + "badge-" +
                                                r.division}>{r.division}
                                            </span>
                                            <span className={"badge badge-success pull-left mr-1 " + "badge-" +
                                                r.groupName}>{r.groupName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PlayerRolesMapper;