import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLPrevilages;
function api(path, subURL = "/designations") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getDesignations(departmentId) {
  return _http.get(api("get-designations/" + departmentId), _http.headerJWT());
}
export function createDesignations(req) {
  const param = { ...req };
  delete param.designationId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveDesignations(req) {
  const param = { ...req };
  const id = param.designationId;
  //delete param.cityId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteDesignations(req) {
  const param = { ...req };
  const id = param.designationId;
  return _http.delete(api(id), _http.headerJWT());
}
