import React from "react";
import FormLib from "../../_common/FormLib";
import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";

import { getTeams } from "../../../services/players/teamServices";
import { getPlayersByTeam, getPlayersSearch } from "../../../services/players/playerServices";
import TeamPlayer from "./TeamPlayer"
import AssignPlayer from "./AssignPlayer"

class PlayerMapper extends FormLib {
    state = {
        data: {
            searchPlayer: ""
        },
        pageLinks: {
            pageNo: 1,
            perPage: 0,
            itemsCount: 0,
            lastPage: 0,
        },
        loading: true,
        teams: [],
        players: [],
        errors: {},
        curTeam: { teamId: "" },
        curPlayer: { playerId: "" },
    };
    componentDidMount() {
        this.loadTeams();
    }

    status = [
        { sortKey: "location", sort: "Location" },
        { sortKey: "roles", sort: "Roles" },
    ];
    stsConf = {
        value: "sortKey",
        label: "sort",
    };

    loadTeams = async () => {
        this.setState({ loading: true });
        const res = await getTeams();
        if (res.data.success === true) {
            this.setState({ teams: res.data.data.teams });
        } else {
            console.log("Errer while loading teams");
        }
    }

    loadPlayers = async (teamId, pageNo) => {
        this.setState({ loading: true });
        const res = await getPlayersByTeam(teamId, pageNo);
        if (res.data.success === true) {
            if (res.data.data.players.length != 0) {
                this.setState({ players: res.data.data.players });
                this.setState({ pageLinks: res.data.data.pageLinks });
                toast.toastSuccess(res.data.msg);
            } else {
                toast.toastInfo("No data found for selected team");
                this.setState({ players: [] });
            }
        } else {
            toast.toastError(res.data.msg);
            this.setState({ players: [] });
        }
        this.setState({ loading: false });
    }

    search = async () => {
        let param = {
            "searchString": this.state.data.searchPlayer,
            "teamId": this.state.curTeam.teamId
        }
        const res = await getPlayersSearch(param, this.state.pageLinks.pageNo);
        if (res.data.success) {
            if (res.data.data.players != 0) {
                this.setState({ players: res.data.data.players });
                this.setState({ pageLinks: res.data.data.pageLinks });
            } else {
                toast.toastInfo("No Data found !!!");
            }
        } else {
            toast.toastError(res.data.msg);
        }
    }


    loadCurrectPlayer = async (teamId, modReset = true) => {

        if (modReset === true) {
            this.setState({ players: [] });
            this.setState({ curTeam: { teamId: "" } });
        }

        let currentTeam;
        const filter = this.state.teams.filter((r) => {
            return r.teamId === teamId ? r : null;
        });
        if (!filter[0]) {
            currentTeam = { teamId: "" };
        } else {
            currentTeam = filter[0];
        }

        this.setState({ curTeam: currentTeam });
        if (teamId !== "") {
            this.loadPlayers(teamId, this.state.pageLinks.pageNo);
        } else {
            this.setState({ players: [] });
        }
    };

    handleTeamChange = (e) => {
        this.loadCurrectPlayer(e.target.value);
    }

    localHandleChange = (e) => {
        if (e.target.id === "teamId") {
            this.handleTeamChange(e);
        }
    };

    handlePageChange = (pageNo) => {
        this.props.history.push("/coreItAdmin/player-assign-unassign/" + pageNo);
        this.loadPlayers(this.state.curTeam.teamId, pageNo);
    };

    onPlayerChangeHandle = (r) => {
        this.loadAsignData(r);
    }

    loadAsignData = (r) => {
        this.setState({ curPlayer: r });
    }


    render() {
        return (
            <React.Fragment>
                <LocationTitle subHeading="States :: Player Assign Un-assign" />
                <div className="pageContent">
                    <form>
                        <div className="form-row ml-2">
                            <div className="col-6 mt-1">
                                {this.rSB("Team", "teamId", this.state.teams, { value: "teamId", label: "name" })}
                            </div>

                            {this.state.curTeam.teamId !== "" && this.state.players.length != 0 &&
                                <>
                                    <div className="col-4">
                                        {this.rTB("Search Player", "searchPlayer")}
                                    </div>
                                    <div className="col-2">
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-outline-info pull-left"
                                            title=" Search by name | username"
                                            disabled={this.state.data.searchPlayer === ""}
                                            onClick={() => this.search()}
                                        >
                                            <i className={"fa fa-search"} />
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-outline-info pull-left"
                                            onClick={() =>
                                                this.loadPlayers(
                                                    this.state.curTeam.teamId,
                                                    this.state.pageLinks.pageNo
                                                )
                                            }
                                            title="Refresh"
                                        >
                                            <i className={"fa fa-refresh"} />
                                        </button>

                                    </div>
                                </>
                            }
                        </div>
                    </form>
                    {this.state.curTeam.teamId === '' &&
                        <div class="jumbotron jumbotron-fluid">
                            <p class="lead text-center">Select Team</p>
                        </div>
                    }

                    {this.state.curTeam.teamId !== '' && this.state.players.length == 0 &&
                        <div class="jumbotron jumbotron-fluid">
                            <p class="lead text-center">No data found for selected team</p>
                        </div>
                    }

                    {this.state.curTeam.teamId !== '' && this.state.players.length != 0 &&
                        <div className="row">
                            <div className="col-6">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col">
                                            {this.state.curTeam.teamId !== "" &&
                                                <TeamPlayer
                                                    playerList={this.state.players}
                                                    pageLinks={this.state.pageLinks}
                                                    onPlayerSelect={this.onPlayerChangeHandle}
                                                    onPageChange={this.handlePageChange}
                                                    curPlr={this.state.curPlayer}
                                                />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="container">
                                    <div className="card-deck col">
                                        <AssignPlayer
                                            key={this.state.curPlayer['playerId']}
                                            curPlayer={this.state.curPlayer}
                                            onPlayerSelect={this.onPlayerChangeHandle}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default PlayerMapper;