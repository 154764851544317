import React, { Component } from "react";
import FormLib from "../../../_common/FormLib";
import LocationTitle from "../../../_subhead/LocationTitle";
import Joi from "joi-browser";
import { getClients, getHub } from "../../../../services/masters/hubServices";
import { toast } from "react-toastify";
import { data } from "jquery";
import {
    getBarcodeData,
    getUsers,
    getActiveClients,
    assignUser,
    getBarcodeDataV1,
    getFreshBarcodes,
    assignFreshBarcodesToTheUser,
} from "../../../../services/masterLocations/clientService";
import Table from "../../../_common/Table";
import Pagination from "../../../_common/Pagination";
import swal from "sweetalert";

class AssignBarcodesToUser extends FormLib {
    state = {
        data: {
            fromScan: "",
            endScan: "",
            hubId: "5067",
            clientId: "",
            barcodeType: "sticker",
            barcodeType1: "freshBarcodes",
            playerId: "",
        },
        hubs: [],
        playerId: "",
        clients: [],
        usersList: [],
        errors: {},
        formMode: "",
        barcodeData: [],
        tempBarcodes:[],
        searchText: "",
        filteredBarcodeData: [],
        barcodeType1: [
            { barcodeType1Id: "freshBarcodes", barcodeType1: "Fresh Barcodes" },
            // { barcodeType1Id: "itemBarcodes", barcodeType1: "ItemBarcodes" }
        ],
        pageLinks: {
            pageNo: 1,
            perPage: 0,
            itemsCount: 0,
            lastPage: 0,
        },
    };

    componentDidMount() {
        this.loadClients();
        this.loadHubs();
        this.allBarcodes();
    }

    schema = {
        fromScan: Joi.optional().label("Starting Barcode Number"),
        endScan: Joi.optional().label("Ending Barcode Number"),
        playerId: Joi.optional().label("playerId"),
        clientId: Joi.string().required().label("Client"),
        hubId: Joi.string().required().label("Hub"),
        barcodeType: Joi.string().required().label("barcodeType"),
        barcodeType1: Joi.string().optional().label("barcodeType1"),
    };
    barcodeType = [
        { sortKey: "prePrinted", sort: "PrePrints" },
        { sortKey: "sticker", sort: "Stickers" },
    ];
    stsConfig = {
        value: "sortKey",
        label: "sort",
    };
    async loadClients() {
        this.setState({ loading: true });
        const res = await getActiveClients("active");
        if (res.data.success === true) {
            this.setState({ clients: res.data.data.clients });
        } else {
            toast.toastError(res.data.msg);
        }
        this.setState({ loading: false });
    }

    async loadHubs() {
        try {
            this.setState({ loading: true });
            let hubList = JSON.parse(localStorage.getItem("hubs"));
            if (hubList.length > 0) {
                this.setState({ hubs: hubList });
            } else {
                return "No Hubs Found";
            }
        } catch {
            console.log("error in loading hubs");
        }
        this.setState({ loading: false });
    }

    async loadUsers() {
        try {
            let hubId = this.state.data.hubId;
            const users = await getUsers(hubId);
            // alert(JSON.stringify(users))

            if (users.data.success === true) {
                this.setState({ usersList: users.data.data.playersHubs });
            } else {
                toast.toastError(users.data.msg);
            }
        } catch (error) {
            return "failed to fetch users data";
        }
    };

    async allBarcodes() {
    try {
        let params = {
            fromScan: this.state.data.fromScan,
            endScan: this.state.data.endScan,
            hubId: this.state.data.hubId,
            clientId: this.state.data.clientId,
            barcodeType: this.state.data.barcodeType,
            barcodeType1: this.state.data.barcodeType1,
        };

        let tempBarcodes = [];
        const getBarcodesWithTimeout = async (params, pageNo) => {
            return new Promise(async (resolve, reject) => {
                try {
                    let barcodes = await getFreshBarcodes(params, pageNo);
                    resolve(barcodes.data.data.freshBarcodeData);
                } catch (error) {
                    reject(error);
                }
            });
        };

        const startTime = Date.now();

        const timeoutDuration = 3000;

        for (let i = this.state.pageLinks.pageNo; i <= this.state.pageLinks.lastPage; i++) {
            if (Date.now() - startTime > timeoutDuration) {
                console.warn("Timeout exceeded while fetching barcodes");
                break;
            }
            let barcodes = await Promise.race([
                getBarcodesWithTimeout(params, i),
                new Promise((resolve, reject) => {
                    setTimeout(() => reject(new Error('Timeout')), timeoutDuration);
                })
            ]);
            tempBarcodes.push(...barcodes);
        }
        this.setState({ tempBarcodes }, () => {
        });

    } catch (error) {
        console.error("Failed to fetch barcode data:", error);
    }
}


    reset() {
        this.setState({
            data: {
                fromScan: "",
                endScan: "",
                clientId: "",
                hubId: "5067",
                barcodeType: "sticker",
                barcodeType1: "freshBarcodes",
                playerId: ""
            },
            barcodeData: [],
        });
    }

    async doSubmit() {
        if (this.state.data.fromScan !== "" && this.state.data.endScan === "") {
            this.state.data.endScan = this.state.data.fromScan
        }
        let params = {
            fromScan: this.state.data.fromScan,
            endScan: this.state.data.endScan,
            hubId: this.state.data.hubId,
            clientId: this.state.data.clientId,
            barcodeType: this.state.data.barcodeType,
            barcodeType1: this.state.data.barcodeType1,
        };

        let data;
        // if ((this.state.data.fromScan === "" && this.state.data.endScan === "") || (this.state.data.fromScan !== "" && this.state.data.endScan !== "")) {
        if (params.fromScan !== "" && params.endScan !== "") {

            if (parseInt(params.fromScan) <= parseInt(params.endScan)) {

                data = await getFreshBarcodes(params, 1);
                if (data.data.success === true) {
                    this.setState({ barcodeData: data.data.data.freshBarcodeData });
                    this.setState({ pageLinks: data.data.data.pageLinks });
                    this.setState({ filteredBarcodeData: data.data.data.freshBarcodeData });
                    this.setState({ searchText: "" })

                    this.setState({ formMode: "submit" });
                    this.loadUsers();
                    this.allBarcodes();
                    toast.success(() => <div>{data.data.msg}</div>);

                    // this.reset();
                } else {
                    this.setState({ loading: false });
                    this.setState({ filteredBarcodeData: [] });
                    this.setState({
                        pageLinks: {
                            pageNo: 1,
                            perPage: 0,
                            itemsCount: 0,
                            lastPage: 0,
                        },
                    });
                }
            } else {
                this.setState({ loading: false });
                toast.error(() => <div>{"fromScan value cannot be greater than endScan value"}</div>);
                this.setState({ filteredBarcodeData: [] });
                this.setState({
                    pageLinks: {
                        pageNo: 1,
                        perPage: 0,
                        itemsCount: 0,
                        lastPage: 0,
                    },
                });
            }
        } else {
            data = await getFreshBarcodes(params, this.state.pageLinks.pageNo);
            if (data.data.success === true) {
                this.setState({ barcodeData: data.data.data.freshBarcodeData });
                this.setState({ pageLinks: data.data.data.pageLinks });
                this.setState({ filteredBarcodeData: data.data.data.freshBarcodeData });
                this.setState({ searchText: "" })

                this.setState({ formMode: "submit" });
                this.loadUsers();
                this.allBarcodes();
                toast.success(() => <div>{data.data.msg}</div>);

                // this.reset();
            } else {
                this.setState({ loading: false });
                // toast.error(data.data.msg);
                this.setState({ filteredBarcodeData: [] });
                this.setState({
                    pageLinks: {
                        pageNo: 1,
                        perPage: 0,
                        itemsCount: 0,
                        lastPage: 0,
                    },
                });
            }
        }
        this.setState({ loading: true });



        // }
        // else {
        //     this.setState({ filteredBarcodeData: [] })
        //     swal({
        //         text: "fromScan and toScan value is mandatory",
        //         icon: "error",
        //         buttons: true,
        //         dangerMode: true,
        //     })
        // }
    }

    localHandleChange = (e) => {
        if (e.target.id === "playerId") {
            this.setState({ playerId: e.target.value });
        }
    };
    handlePageChange = (pageNo) => {
        this.props.history.push("/assignBarcodesToUser/" + pageNo);
        let pageLinks = this.state.pageLinks;
        pageLinks["pageNo"] = pageNo;
        this.setState({ pageLinks });
        this.doSubmit();
    };

    tableMeta = {
        key: "slNo",
        columns: [
            {
                key: "sl_No1",
                action: (r) => <div className="btn-group pull-right"></div>,
                icon: () => <i className="fa fa-table " aria-hidden="true" />,
            },
            {
                key: "sl_No2",
                action: (r) => (
                    <>
                        <span>{r.slNo}</span>
                    </>
                ),
                cusClass: " ",
                icon: () => (
                    <div>
                        <p>Barcode</p>
                        <input
                            type="text"
                            name="searchBarcode"
                            id="searchBarcode"
                            placeholder="Search"
                            value={this.state.searchText}
                            onChange={(e) => {
                                const searchText = e.target.value;
                                this.setState({ searchText }, () => {
                                    let filteredBarcodeData;

                                    if (searchText.trim() === "") {
                                        filteredBarcodeData = this.state.barcodeData;
                                    } else {
                                        filteredBarcodeData = this.state.tempBarcodes?.filter((item) => {
                                            const slNo = item.slNo.toString();
                                            return slNo.toLowerCase().includes(searchText.toLowerCase());
                                        });
                                    }

                                    this.setState({ filteredBarcodeData });
                                });
                            }}
                        />
                    </div>
                ),
            },
            {
                key: "sl_No3",
                action: (r) => (
                    <>

                        {this.state.data.barcodeType1 === "itemBarcodes" && (

                            <>
                                {r.item}

                            </>
                        )}
                    </>
                ),
                icon: () => (
                    this.state.data.barcodeType1 === "itemBarcodes" && <>Item</>
                )
            },

            { path: "barcodeType", label: "Type" },
            {
                key: "sl_No4",
                action: (r) => (
                    <>
                        {this.state.playerId && this.state.data.barcodeType1 === "itemBarcodes" && (
                            <button
                                className="btn btn-success btn-sm"
                                type="button"
                                onClick={() => this.assignItem(r)}
                            >
                                Assign
                            </button>
                        )}
                        {this.state.playerId && this.state.data.barcodeType1 === "freshBarcodes" && (
                            <button
                                className="btn btn-success btn-sm"
                                type="button"
                                onClick={() => this.assignBarcodes(r)}
                            >
                                Assign
                            </button>
                        )}
                    </>
                ),
                icon: () => <>Assign</>,
            },
        ],
    };

    assignItem = async (data) => {
        try {
            const res = await assignUser(this.state.data, data, this.state.playerId);

            if (res.data.success === true) {
                toast.success(() => <div>{res.data.msg}</div>);
                this.doSubmit();

            } else {
                toast.error(() => <div>{res.data.msg}</div>);
            }
        } catch (error) {
            return "failed to assign users data";
        }
    };
    assignBarcodes = async (data) => {
        try {
            const res = await assignFreshBarcodesToTheUser(this.state.data, data, this.state.playerId);

            if (res.data.success === true) {
                toast.success(() => <div>{res.data.msg}</div>);
                this.doSubmit();

            } else {
                toast.error(() => <div>{res.data.msg}</div>);
            }
        } catch (error) {
            return "failed to assign users data";
        }
    };

    render() {
        const { pageNo, perPage, itemsCount } = this.state.pageLinks;

        return (
            <React.Fragment>
                <LocationTitle subHeading="Assign PackingMaterial/products To User" />
                <form onSubmit={this.handleSubmit}>
                    <div className="pageContent">
                        <div className="card">
                            <div className="d-flex" style={{ columnGap: "1rem" }}>
                                <div style={{ maxWidth: "14rem" }}>
                                    {this.rSB(
                                        "Client *",
                                        "clientId",
                                        this.state.clients,
                                        { value: "TEAM_ID", label: "NAME" }
                                        //   "col-6"
                                    )}
                                </div>
                                <div>
                                    {this.rSB(
                                        "Hub *",
                                        "hubId",
                                        this.state.hubs,
                                        { value: "hubId", label: "hub" }
                                        //   "col-12"
                                    )}
                                </div>
                                <div>
                                    {this.rSB(
                                        "Barcode Type1 *",
                                        "barcodeType1",
                                        this.state.barcodeType1,
                                        { value: "barcodeType1Id", label: "barcodeType1" }
                                        //   "col-12"
                                    )}
                                </div>

                                <div>{this.rTB("FromScan ", "fromScan")}</div>
                                <div>{this.rTB("ToScan", "endScan")}</div>
                                <div>
                                    {this.rRBL(
                                        "BarcodeType *",
                                        "barcodeType",
                                        this.barcodeType,
                                        this.stsConfig
                                    )}
                                </div>
                                <div>
                                    {this.rBtnSP("Submit", "submit")}
                                    {this.mResetBtn(() => this.reset(), " pull-right ", "Reset")}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>

                <div>
                    {this.state.filteredBarcodeData.length !== 0 &&
                        this.state.formMode === "submit" && (
                            <div
                                className="col-2"
                                style={{ paddingTop: "0.5rem", paddingLeft: "2rem" }}
                            >
                                {this.rSB("Users *", "playerId", this.state.usersList, {
                                    value: "playerId",
                                    label: "userName",
                                })}
                            </div>
                        )}

                    <div style={{ maxWidth: "40rem", paddingLeft: "2rem" }}>
                        {this.state.barcodeData.length !== 0 &&
                            this.state.formMode === "submit" && (
                                <div>
                                    <Table
                                        clsName="table table-striped tabele-bordered table-hover"
                                        tableMeta={this.tableMeta}
                                        data={this.state.filteredBarcodeData}
                                        dropMenu={[]}
                                    />
                                    <Pagination
                                        pageNo={pageNo}
                                        perPage={perPage}
                                        itemsCount={itemsCount}
                                        onPageChange={this.handlePageChange}
                                    />
                                </div>
                            )}
                    </div>
                    <div>
                        {this.state.formMode === "submit" &&
                            this.state.filteredBarcodeData.length === 0 && (
                                <div className="jumbotron jumbotron-fluid">
                                    <p className="lead text-center">No barcodes Found</p>
                                    {/* <p aria-disabled></p> */}
                                </div>
                            )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default AssignBarcodesToUser;




