import jwtDecode from "jwt-decode";
import _http from "./_httpService";
import config from "../config.json";
import toast from "./_toastService";

const apiEndpoint = config[config.build].apiURLPrevilages + "/authenticate";

const tokenKey = "token";

_http.setJwt(getJwt());

export async function login(code, username, password) {
  // console.log(JSON.stringify(password));

  let CryptoJS = require("crypto-js");

  // Encrypt
  let ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(password),
    "my-secret-key@123"
  ).toString();
  //log encrypted data
  console.log("Encrypt Data -");
  // console.log(ciphertext);

  // Decrypt
  let bytes = CryptoJS.AES.decrypt(ciphertext, "my-secret-key@123");
  let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  //log decrypted Data
  console.log("decrypted Data -");
  // console.log(decryptedData);

  const { data } = await _http.post(apiEndpoint, {
    code,
    username,
    password,
  });

  if (data.status === "0") {
    toast.toastError(data.msg);
  }

  //Code
  localStorage.setItem("code", code);
  //userInfo
  localStorage.setItem("userInfo", JSON.stringify(data.data["userInfo"]));

  //menu-group
  localStorage.setItem(
    "menuGroup",
    JSON.stringify(data.data["menuGroupCodes"])
  );
  //menu
  localStorage.setItem("menu", JSON.stringify(data.data["menuCodes"]));
  //features
  localStorage.setItem("features", JSON.stringify(data.data["features"]));

  //regions
  localStorage.setItem("regions", JSON.stringify(data.data["regions"]));
  //zones
  localStorage.setItem("zones", JSON.stringify(data.data["zones"]));
  //hubs
  localStorage.setItem("hubs", JSON.stringify(data.data["hubs"]));

  //token
  localStorage.setItem(tokenKey, data.data["jwtToken"]);

  return data;
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem("userInfo");

  localStorage.removeItem("menuGroup");
  localStorage.removeItem("menu");
  localStorage.removeItem("features");

  localStorage.removeItem("regions");
  localStorage.removeItem("zones");
  localStorage.removeItem("hubs");
}

export function getCode() {
  // console.log("=========>");
  try {
    let code = localStorage.getItem("code");
    return code;
  } catch (ex) {
    return null;
  }
}

export function getCurrentUser() {
  try {
    const jwt = jwtDecode(localStorage.getItem(tokenKey));
    return jwt["user"];
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}
async function getUserHubsIds() {
  try {
    let hubs = localStorage.getItem("hubs") || [];
    hubs = JSON.parse(hubs);
    const hubIds = await hubs.map((r) => {
      return r.hubId;
    });

    return hubIds;
  } catch (ex) {
    return [];
  }
}
const authActions = {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getUserHubsIds,
  getJwt,
  getCode,
};
export default authActions;
