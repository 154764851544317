import React from "react";
import axios from "axios";
import logger from "./_logService";
import { toast } from "react-toastify";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (expectedError) {
    logger.log(error);
    toast.warning(() => (
      <div>
        <b>Bad Request.</b>
        <br />
        {error.response.data.msg}
      </div>
    ));
  }

  return error.response;
});

function setJwt(jwt) {
  // console.log("httService-setJWT");
  // axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
}

const headerJWT = () => {
  const token = localStorage.getItem("token");
  if (token !== null) {
    return {
      headers: {
        Authorization: "Bearer " + token,
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
      },
    };
  }
  return {
    headers: {
      "Cache-Control": "no-cache, no-store, must-revalidate",
      Pragma: "no-cache",
      Expires: "0",
    },
  };
};

const httpActions = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  headerJWT,
};

export default httpActions;
