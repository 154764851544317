import * as act from "./uiTypes";

// const getInitState = () => {
//   return {};
// };

const initialState = {
  cur_country: {
    countryId: "7772de33f858e4ca9d696e6877846f0a",
    country: "India",
  },
  cur_states: {
    stateId: "432776e0c64c5aa238ed5fe60c080b1a",
    state: "Karnataka",
  },
  cur_cities: { cityId: "d415913a82620438635a19dabf63de2f", city: "Bengaluru" },
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case act.SET_CUR_COUNTRY:
      return {
        ...state,
        cur_country: action.payload.country,
      };

    default:
      return state;
  }
};

export default uiReducer;
