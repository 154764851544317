import _http from "./../_httpService";

import config from "./../../config.json";

const baseURL = config[config.build].apiURLValidata;

function api(path, subURL = "/4team/user-stories") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

function apiEx(path, subURL = "/4team/user-stories") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function createUserstory(req) {
  const param = { ...req };

  delete param.userstoryId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveUserstory(req) {
  const param = { ...req };

  const id = param.userstoryId;
  delete param.userstoryId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteUserstory(param) {
  return _http.delete(api(param.userstoryId), _http.headerJWT());
}

export function getUserstories(moduleId) {
  return _http.get(api("by-module/" + moduleId), _http.headerJWT());
  // return _http.get(api(""), _http.headerJWT());
}

export function getUserstoriesByModuleIds(moduleIds) {
  const param = { moduleId: moduleIds };
  return _http.post(
    "https://test-api.bizlog.in:1337/plms/4team/user-stories/by-modules",
    param,
    _http.headerJWT()
  );

  // return _http.get(api(""), _http.headerJWT());
}

export function getStories(param) {
  // return _http.put(api("daily-reports/" + pageNo), param, _http.headerJWT());
  // return _http.get(api(pageNo), param, _http.headerJWT());
  // return reports;
  return _http.get(api("by-project-status/" + param.status), _http.headerJWT());
}
