import _http from "./../_httpService";
import config from "./../../config.json";

const baseURL = config[config.build].apiURLCore;

function api(path, subURL = "") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getBarcodes() {
  return _http.get(api("tracker/settings/barcodes/all/2/1;"), _http.headerJWT());
}

export function createBarcode(req) {
  const param = { ...req };
  delete param.barcodeId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function createBulkBarcode(req) {
  // let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  // const param = { ...req, teamId: userInfo.teamId };
  const param = { ...req };
  return _http.post(
    api("tracker/settings/barcodes/generate/multiple"),
    param,
    _http.headerJWT()
  );
}


export function saveBarcode(req) {
  const param = { ...req };
  const id = param.barcodeId;
  delete param.barcodeId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteBarcode(req) {
  const param = { ...req };
  return _http.delete(api(param.barcodeId), {}, _http.headerJWT());
}
export function getBarcodesByPackMaterialId(packMaterialId, pageNo) {
  try {
    return _http.get(
      api(
        "tracker/settings/barcodes/get-barcodes-packmaterial/" +
        packMaterialId +
        "/" +
        50 +
        "/" +
        pageNo
      ),
      _http.headerJWT()
    );
  }
  catch {
    return undefined;
  }
}

export function searchBarcode(req, pageNo) {
  // alert("req...." + JSON.stringify(req));
  const param = {
    materialType: "",
    searchString: "",
    hubId: "",
    status: "",
    teamId: ""
  };
  // console.log();
  if (
    req.hasOwnProperty("packMaterialId") &&
    req.hasOwnProperty("searchString") &&
    req.hasOwnProperty("hubId") &&
    req.hasOwnProperty("teamId") &&
    req.hasOwnProperty("status")
  ) {
    param.materialType = req.packMaterialId;
    param.hubId = req.hubId;
    param.teamId = req.teamId;
    param.status = req.status;
    param.searchString = req.searchString;
  } else if (
    req.hasOwnProperty("packMaterialId") &&
    req.hasOwnProperty("hubId") &&
    req.hasOwnProperty("teamId") &&
    req.hasOwnProperty("status")
  ) {
    param.materialType = req.packMaterialId;
    param.hubId = req.hubId;
    param.teamId = req.teamId;
    param.status = req.status;
  } else if (
    req.hasOwnProperty("packMaterialId") &&
    req.hasOwnProperty("hubId") &&
    req.hasOwnProperty("teamId") &&
    req.hasOwnProperty("searchString") &&
    req.hasOwnProperty("status")
  ) {
    param.materialType = req.packMaterialId;
    param.hubId = req.hubId;
    param.teamId = req.teamId;
    param.searchString = req.searchString;
    param.status = req.status;
  } else if (
    req.hasOwnProperty("packMaterialId") &&
    req.hasOwnProperty("status")
  ) {
    param.materialType = req.packMaterialId;
    param.status = req.status;
  } else if (
    req.hasOwnProperty("searchString") &&
    req.hasOwnProperty("packMaterialId") &&
    req.hasOwnProperty("status")
  ) {
    param.searchString = req.searchString;
    param.materialType = req.packMaterialId;
    param.status = req.status;

  } else if (
    req.hasOwnProperty("hubId") &&
    req.hasOwnProperty("teamId") &&
    req.hasOwnProperty("packMaterialId") &&
    req.hasOwnProperty("status")
  ) {
    param.hubId = req.hubId;
    param.teamId = req.teamId;
    param.materialType = req.packMaterialId;
    param.status = req.status;
  } else if (
    req.hasOwnProperty("status") &&
    req.hasOwnProperty("packMaterialId")
  ) {
    param.status = req.status;
    param.materialType = req.packMaterialId;
  } else if (
    req.hasOwnProperty("searchString") &&
    req.hasOwnProperty("teamId") &&
    req.hasOwnProperty("hubId")
  ) {
    param.searchString = req.searchString;
    param.hubId = req.hubId;
    param.teamId = req.teamId;
  } else if (req.hasOwnProperty("status") && req.hasOwnProperty("teamId") && req.hasOwnProperty("hubId")) {
    param.status = req.status;
    param.hubId = req.hubId;
    param.teamId = req.teamId;

  } else if (
    req.hasOwnProperty("searchString") &&
    req.hasOwnProperty("status")
  ) {
    param.searchString = req.searchString;
    param.status = req.status;
  } else if (req.hasOwnProperty("searchString")) {
    param.searchString = req.searchString;
  } else if (req.hasOwnProperty("hubId")) {
    param.hubId = req.hubId;
  } else if (req.hasOwnProperty("status")) {
    param.status = req.status;
  } else if (req.hasOwnProperty("packMaterialId")) {
    param.materialType = req.packMaterialId;
  }
  return _http.put(
    api("tracker/settings/barcodes/getbarcodes/new/" + 50 + "/" + pageNo),
    param,
    _http.headerJWT()
  );
}


// -------------------------------------------Dore--------------------------------------------//
export function transferBulkBarcode(req) {

  const param = { ...req };
  return _http.post(
    api("tracker/settings/barcodes/transfer/multiple"),
    param,
    _http.headerJWT()
  );
}
export function getAvailableCountByHubIdPackMaterialId(hubId, packMaterialId) {
  return _http.get(api("tracker/settings/barcodes/count/available/barcodes/" + hubId + "/" + packMaterialId), _http.headerJWT());
}

export function getIssuedCountByHubIdPackMaterialId(hubId, packMaterialId) {
  return _http.get(api("tracker/settings/barcodes/count/issued/barcodes/" + hubId + "/" + packMaterialId), _http.headerJWT());
}

export function getDamagedCountByHubIdPackMaterialId(hubId, packMaterialId) {
  return _http.get(api("tracker/settings/barcodes/count/damaged/barcodes/" + hubId + "/" + packMaterialId), _http.headerJWT());
}

export function getScrapedCountByHubIdPackMaterialId(hubId, packMaterialId) {
  return _http.get(api("tracker/settings/barcodes/count/scraped/barcodes/" + hubId + "/" + packMaterialId), _http.headerJWT());
}

export function updateBulkBarcodeStatus(req) {

  const param = { ...req };
  return _http.put(
    api("tracker/settings/barcodes/update-status/bulkBarcode"),
    param,
    _http.headerJWT()
  );
}