import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
// import { getProjects } from '../../../services/plms/projectServices';
import { getTasktype } from "../../../services/plms/tasktypeServices";

class TaskCreate extends FormLib {
  state = {
    data: {
      username: "",
      password: "",
      pickupDate: this.getDatebyDays(1),
      pickupTime: "14:00",

      taskId: "",
      userStory: "",
      task: "",
      taskType: "",
      startDate: "",
      dueDate: "",
      endDate: "",
      taskPoint: "",
      owner: "",
      estimatedTime: "",
      actualTime: "",
      status: "",
    },
    errors: {},
    tasktype: [],
  };

  schema = {
    ticket1: Joi.string().label("Ticket 1"),
    ticket2: Joi.string().label("Ticket 2"),
  };
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];

  async componentDidMount() {
    let resp = await getTasktype();
    console.log(resp.data.tasktype);
    // console.log("hiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii")
    this.setState({ tasktype: resp.data.tasktype });
  }

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="">
                <div className="card-body"></div>
                <div className=" fluid-container page-todo bootstrap snippets bootdeys">
                  <form onSubmit={this.handleSubmit} className="">
                    <div className="row">
                      <div className="col-4">
                        {this.rSB("Tasktype", "name", this.state.tasktype, {
                          value: "taskId",
                          label: "taskId",
                        })}
                      </div>
                      {this.rTB("Task Name", "taskname", "col-8")}
                    </div>
                  </form>
                </div>

                <div className="form-row">
                  {this.rTB(" Point", "Point", "col-2")}
                  {this.rTP("Estimated Time", "estimatedTime", "col-md-5")}
                  {/* {this.rTP("Estimated Time", "pickupTime", "col-md-5")} */}
                  {this.rTP("Actual Time", "actualTime", "col-md-5")}
                  {/* {this.rTP("Actual Time", "pickupTime", "col-md-4")} */}
                </div>

                <div className="form-row">
                  {/* {this.rDP("Start Date", "pickupDate", "col-md-4")}
                    {this.rDP("Due Date", "pickupDate", "col-md-4")}
                    {this.rDP("End Date", "pickupDate", "col-md-4")} */}
                  {this.rDP("Due Date", "dueDate", "col-md-4")}
                  {this.rDP("End Date", "endDate", "col-md-4")}
                  {this.rDP("Start Date", "startDate", "col-md-4")}
                </div>

                <div className="form-row">
                  {this.rTB("Owner", "owner", "col-4")}
                  {this.rRBL("Status", "status", this.status, {
                    value: "sortKey",
                    label: "sort",
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </React.Fragment>
    );
  }
}
export default TaskCreate;
