import React, { Component } from "react";
import _ from "lodash";
import DropDownMenu from "./DropDownMenu";

// columns: array
// sortColumn: object
// onSort: function

class TableBody extends Component {
  renderCell = (item, column) => {
    if (column.action) return column.action(item);

    return _.get(item, column.path);
  };

  createKey = (item, column, key) => {
    return (
      "td_" +
      item[key] +
      "_" +
      (column.path || column.key) +
      (column.actionKey || "")
    );
  };

  render() {
    const { columns, key } = this.props.tableMeta;
    const dropMenu = this.props.dropMenu;
    return (
      <tbody>
        {this.props.data.map((d, i) => (
          <tr key={"tr_" + d[key] + "_" + i}>
            {columns.map((c) => (
              <td key={this.createKey(d, c, key)}>{this.renderCell(d, c)}</td>
            ))}
            {this.dropMenu > 0 && (
              <td className="actions">
                <DropDownMenu menuItems={dropMenu} menuKey={d[key]} />
              </td>
            )}
          </tr>
        ))}
      </tbody>
    );
  }
}

export default TableBody;
