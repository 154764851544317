import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";

import {
  createProject,
  saveProject,
  deleteProject,
} from "./../../../services/plms/projectServices";

import * as _plms from "./../../../services/plms/_plmsServices";

class ModalProject extends FormLib {
  state = {
    data: {
      projectId: "",
      project: "",
      projectCode: "",
      prOwner: "",
      startDate: "",
      dueDate: "",
      endDate: "",
      remarks: "",
      status: "active",
    },
    errors: {},
    owners: [],
    ownerConf: {},
  };

  schema = {
    projectId: Joi.optional().label("ProjectId"),

    project: Joi.string().required().label("Project*"),
    projectCode: Joi.string().required().label("Project Code*"),
    prOwner: Joi.string().required().label("Owner*"),
    startDate: Joi.string().required().label("startDate*"),
    remarks: Joi.string().required().label("Remarks*"),
    status: Joi.string().required().label("Status*"),

    dueDate: Joi.optional().label("Due Date"),
    endDate: Joi.optional().label("End Date"),
  };

  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createProject(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveProject(this.state.data);
    }
    if (this.state.btnValue === "delete") {
      res = await deleteProject(this.state.data);
      this.resetData();
    }
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.props.onloadProjects();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  componentDidMount() {
    this.setCurProject(this.props.curProject);
    this.setOwners();
  }

  setOwners = () => {
    this.setState({ owners: _plms.users.owners });
    this.setState({ ownerConf: _plms.users.ownerConf });
  };

  setCurProject(p) {
    if (p.projectId !== "") {
      p.status = "active";
      this.setState({ data: p });
    } else {
      this.resetData();
    }
  }

  resetData() {
    this.setState({
      data: {
        projectId: "",
        project: "",
        projectCode: "",
        prOwner: "BLR0000",
        startDate: "",
        dueDate: "",
        endDate: "",
        remarks: "",
        status: "active",
      },
    });
  }

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;
    return (
      <div className="container-fluid">
        <div className="modal fade" id="myProjectModel">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Project</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  <div className="form-row">
                    {this.rTBH("ProjectId", "projectId", "col-4")}
                    {this.rTB("Project", "project", "col-4")}
                    {this.rTB("Project Code", "projectCode", "col-4")}
                    {this.rSB(
                      "Owner*",
                      "prOwner",
                      this.state.owners,
                      this.state.ownerConf,
                      " col-4 "
                    )}
                  </div>

                  <div className="form-row">
                    {this.rDP("Start Date", "startDate", "col-md-4")}
                    {this.rDP("Due Date", "dueDate", "col-md-4")}
                    {this.rDP("End Date", "endDate", "col-md-4")}
                    {this.rTA("Remarks", "remarks", " form-group col-md-12 ")}
                  </div>

                  <div className="form-row">
                    {this.rRBL("Status", "status", this.status, this.stsConf)}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {this.state.data.projectId === "" &&
                  this.rBtnSP("Create", "create")}
                {this.state.data.projectId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {/* {this.rBtnSD("Delete", "delete")} */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalProject;
