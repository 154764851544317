import _http from "./../../_httpService";
import config from "./../../../config.json";
import axios from "axios";

const apiURLPHP = config[config.build].apiURLPHP;
const apiURLCore = config[config.build].apiURLCore;

// const baseURL = "https://rl.bizlog.in:8443/rlogistics-execution/rlservice";
//const baseURL = "https://test.bizlog.in:9443/rlogistics-execution/rlservice";
const baseURL = "https://test-api.bizlog.in:8443/core"

function api(path, subURL = "") {
  path = path !== "" ? "/" + path : "";
  return `${apiURLCore}${subURL}${path}`;
}

const productCodeMap = {

  "MixerGrinder": "MIX",
  "ExerciseBike": "FIT",
  "Treadmill": "FIT",
  "GasStove": "GST",
  "Cycles": "CYC"
};



export function createLifelongTicket(req) {
  const data = { ...req };

  let param = {
    primary: {},
    primary_details: {},
    products: [
      {
        primary: {},
        product_details: {},
        src_add: {},
        dst_add: {},
      },
    ],
  };

  param.primary["ticketPriority"] = data["levelOfIrritation"];
  param.primary["flowId"] = "InstallationV1";
  param.primary["retailerId"] = "6f9d70b6-f7c4-4c84-969e-0a17227beec6";
  param.primary["retailerNo"] = "";
  param.primary["conComplaintNo"] = data["consumerComplaintNumber"];
  param.primary["orderNo"] = data["consumerComplaintNumber"];
  param.primary["invoiceNo"] = data["invoiceNo"];
  param.primary["parentTicket"] = "parentTicket";
  param.primary["ticketDetails"] = "ticket details";
  param.primary["specialInstructions"] = "instructions";
  param.primary["buyerContactNumber"] = "";
  param.primary["isAppointmentReq"] = "yes";
  param.primary["isInstallationReq"] = "yes";
  param.primary["isPhysicalEval"] = "no";
  param.primary["isTechEval"] = "no";
  param.primary["isPackingReq"] = "no";
  param.primary["paymentMode"] = "cash";

  param.primary_details["ticketType"] = data['natureOfComplaint']

  param.products[0].primary["productId"] = "";
  param.products[0].primary["productCode"] = productCodeMap[data["productName"]];
  param.products[0].primary["brandName"] = data["brand"];
  param.products[0].primary["modelName"] = data["model"];
  param.products[0].primary["productName"] = data["productName"];
  param.products[0].primary["dateOfPurchase"] = data["dateOfPurchase"];
  param.products[0].primary["identificationNo"] = data["identificationNo"];
  param.products[0].primary["productDesc"] = data["productDescription"];
  param.products[0].primary["problemDesc"] = data["problemDescription"];
  param.products[0].primary["productValue"] = "";
  param.products[0].primary["cost"] = "";
  param.products[0].primary["isUnderWarranty"] = data["isUnderWarranty"];
  param.products[0].primary["accessories"] = "";
  param.products[0].primary["pickupType"] = "";
  param.products[0].primary["grade"] = "";
  param.products[0].primary["estWeight"] = "";

  param.products[0].src_add["srcContactPerson"] = data["consumerName"];
  param.products[0].src_add["srcPincode"] = data["pincode"];
  param.products[0].src_add["srcState"] = data["srcState"];
  param.products[0].src_add["srcAdd1"] = data["addressLine1"];
  param.products[0].src_add["srcAdd2"] = data["addressLine2"];
  param.products[0].src_add["srcLandmark"] = data["landmark"];
  param.products[0].src_add["srcCity"] = data["city"];
  param.products[0].src_add["srcEmailId"] = data["emailId"];
  param.products[0].src_add["srcContact1"] = data["telephoneNumber"];

  param.products[0].dst_add["dstContactPerson"] = data["consumerName"];
  param.products[0].dst_add["dstPincode"] = data["pincode"];
  param.products[0].dst_add["dstState"] = data["dstState"];
  param.products[0].dst_add["dstAdd1"] = data["addressLine1"];
  param.products[0].dst_add["dstAdd2"] = data["addressLine2"];
  param.products[0].dst_add["dstLandmark"] = data["landmark"];
  param.products[0].dst_add["dstCity"] = data["city"];
  param.products[0].dst_add["dstEmailId"] = data["emailId"];
  param.products[0].dst_add["dstContact1"] = data["telephoneNumber"];

  //  console.log(data)
  //  console.log(JSON.stringify(param))

  return _http.put(api("api/ticket/create/InstallationV1"), param, _http.headerJWT());
}


export async function createLifelongTicket_BACKUP(req, apiToken, retailerId) {
  const data = { ...req };
  data.productDescription = data.natureOfComplaint;
  data.natureOfComplaint = "installation";

  let param = {};
  param.retailerId = retailerId;
  param.apiToken = apiToken;

  // const endUrl = api("ticket/create/installation");
  const endUrl = api("api/ticket/create/InstallationV1");
  //   return _http.post(api(endUrl; param, _http.headerJWT());

  const loginFormData = new FormData();
  loginFormData.append("retailerId", retailerId);
  loginFormData.append("apiToken", apiToken);
  loginFormData.append("fields", JSON.stringify(data));
  // make axios post request
  const response = await axios({
    method: "post",
    url: endUrl,
    data: loginFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });
  return response;
}

export function getLifelongTickets(payload, pageNo = 1, perpage = 50) {
  return _http.put(
    apiURLPHP +
    "/requestV1/lifelong-reports-mis.php?pageNo=" +
    pageNo +
    "&perPage=" +
    perpage,
    payload,
    _http.headerJWT()
  );
}

export function updateDocket(req) {
  const param = { ...req };
  const id = param.countryId;
  //delete param.cityId;
  return _http.post(
    apiURLPHP + "/requestV1/lifelong-reports-mis-dock-create.php",
    param,
    _http.headerJWT()
  );
}
