import _http from "./../_httpNoRejectService";
import config from "./../../config.json";
import { getPickAndDrop1WayJSON, getBulkPickAndDrop1WayJSON } from "./flows/pickAndDrop1Way";
import { getPickAndDrop2WayJSON } from "./flows/pickAndDrop2Way";
import { getAdvanceExchangeJSON } from "./flows/advanceExchange";
import { getBuyBackJSON } from "./flows/buyBack";
import { getInstallationV1JSON } from "./flows/installationv1";
import { getDropOffJSON, getDropOffJSONBulk } from "./flows/dropOff";
import { getBulkQCJSON, getBulkJSON, getEwasteJSON } from "./flows/bulk";
import { getFurnitureJSON } from "./flows/furniture";

const apiDevEndpoint = config[config.build].apiURLPrevilages;
const apiCoreEndpoint = config[config.build].apiURLCore;
const apiMisEndPoint = config[config.build].apiURLMis;
const apiCodeEndPointEx = config[config.build].apiURLCoreExp;
const apiECoreEndPoint = config[config.build].apiURLECore;


const apiURLCorePHP = config[config.build].apiURLCorePHP;

export function getTicketDetails(params, curPage) {
  try {
    const apiEndpoint =
      apiCoreEndpoint +
      "/ticketDetails/get-tickets/by-hub/" +
      "10" +
      "/" +
      curPage;
    return _http.post(apiEndpoint, params, _http.headerJWT());
  } catch {
    return undefined;
  }
}

export function getTicketDetailsNewOne(params, curPage) {
  try {
    const apiEndpoint =
      apiCoreEndpoint +
      "/ticketDetails/get-tickets/by-hub/new/pincode-filter/" +
      "10" +
      "/" +
      curPage;

    // const apiEndpoint = "http://192.168.3.133:9090/new/ticketDetails/get-tickets/by-hub/new/pincode-filter/10/" + curPage
    return _http.post(apiEndpoint, params, _http.headerJWT());
  } catch {
    return undefined;
  }
}

export function getTicketDetailsNew(params, curPage) {
  try {
    const apiEndpoint =
      apiCoreEndpoint +
      "/new/ticketDetails/get-tickets/by-hub/new/pincode-filter/" +
      "10" +
      "/" +
      curPage;

    // const apiEndpoint = "http://192.168.3.133:9090/new/ticketDetails/get-tickets/by-hub/new/pincode-filter/10/" + curPage
    return _http.post(apiEndpoint, params, _http.headerJWT());
  } catch {
    return undefined;
  }
}

export function getRetailerMISFormat(reatilerId) {
  const apiEndpoint =
    apiURLCorePHP + "/pincodes/mis/format.php?retailerId=" + reatilerId;
  return _http.get(apiEndpoint, _http.headerJWT());
}

export function getMISDetails(params) {
  let payload = { ...params };
  if (payload["ticketStatus"] === "All") {
    payload["ticketStatus"] = "";
  }
  payload["startDate"] = params["startDate"] + " 00:00:00";
  payload["endDate"] = params["endDate"] + " 23:59:59";
  const apiEndpoint = apiMisEndPoint + "/ticket/mis/report";
  return _http.put(apiEndpoint, payload, _http.headerJWT());
}
//--------------------------new-------------------------------//
export function getMISDetailsNew(params, pageNo) {
  let payload = { ...params };
  if (payload["ticketStatus"] === "All") {
    payload["ticketStatus"] = "";
  }
  payload["startDate"] = params["startDate"] + " 00:00:00";
  payload["endDate"] = params["endDate"] + " 23:59:59";
  // const apiEndpoint = apiMisEndPoint + "/ticket/mis/report/new/" + "100/" + pageNo;
  const apiEndpoint =
    apiMisEndPoint + "/ticket/mis/report/include/dstHub/" + "100/" + pageNo;
  return _http.put(apiEndpoint, payload, _http.headerJWT());
}
// export function getHubs(pageNo) {
//   return _http.get(api("" + "10/" + pageNo), _http.headerJWT());
// }
//--------------------------new-------------------------------//

// -----------------------------------------------------------------------------------------------------------------//
export function getMISDocketUpdate(params) {
  const apiEndpoint = apiMisEndPoint + "/ticket/update/docket";
  return _http.put(apiEndpoint, params, _http.headerJWT());
}

export function updateDocket(params) {
  // console.log("services......." + JSON.stringify(params));
  const apiEndpoint = apiMisEndPoint + "/ticket/mis/update";
  return _http.post(apiEndpoint, params, _http.headerJWT());
}
// ----------------------------------------------------------------------------------------------------------------//
export function getPlayersbyRole(data) {
  const apiEndpoint = apiDevEndpoint + "/players-hubs/players-list/" + data;
  return _http.get(apiEndpoint, _http.headerJWT());
}

export function getPlayersbyRoleCount(data) {
  const apiEndpoint =
    apiCodeEndPointEx + "/players-hubs/players-list/count/" + data;
  return _http.get(apiEndpoint, _http.headerJWT());
}

export function getTicketListData(playerId) {
  const apiEndpoint = `${apiCoreEndpoint}/ticketDetails/get-tickets/by-player/${playerId}`;
  return _http.get(apiEndpoint, _http.headerJWT());
}
export function getTicketsListAssignedToFe(playerId) {
  const apiEndpoint = `${apiECoreEndPoint}/getTicketDetailsByFePlayerId/${playerId}`;
  return _http.get(apiEndpoint, _http.headerJWT());
}



export function getTripDetailsByPlayerId(playerId) {
  const apiEndpoint = `${apiECoreEndPoint}/getTripDetailsByFeplayerId/${playerId}`;
  return _http.get(apiEndpoint, _http.headerJWT());
}
export function getTicketsListByTripId(tripId) {
  const apiEndpoint = `${apiECoreEndPoint}/getTicketDetailsByTripId/${tripId}`;
  return _http.get(apiEndpoint, _http.headerJWT());
}




export function getTicketsCountOfFe(playerId) {
  const apiEndpoint = `${apiECoreEndPoint}/getTicketCountOfEachFe/${playerId}`;
  return _http.get(apiEndpoint, _http.headerJWT());
}
export function getTicketCountForFes(hubId) {
  const apiEndpoint = `${apiECoreEndPoint}/players-hubs/players-list/count/${hubId}`;
  return _http.get(apiEndpoint, _http.headerJWT());
}

export async function getMasterData() {
  const retailer = await _http.get(
    apiDevEndpoint + "/teams",
    _http.headerJWT()
  );
  const product = await _http.get(
    apiCoreEndpoint + "/tracker/settings/products",
    _http.headerJWT()
  );
  const brand = await _http.get(
    apiCoreEndpoint + "/tracker/settings/products-cat-brands",
    _http.headerJWT()
  );
  const model = await _http.get(
    apiCoreEndpoint + "/tracker/settings/products-cat-models",
    _http.headerJWT()
  );

  if (retailer !== undefined) {
    if (
      retailer.data.success === true &&
      product.data.success === true &&
      brand.data.success === true &&
      model.data.success === true
    ) {
      const retailers = JSON.stringify(retailer.data.data.teams);
      localStorage.setItem("retailersInfo", retailers);

      const products = JSON.stringify(product.data.data.products);
      localStorage.setItem("productsInfo", products);

      const brands = JSON.stringify(brand.data.data.productsCatBrands);
      localStorage.setItem("brandsInfo", brands);

      const models = JSON.stringify(model.data.data.productsCatModels);
      localStorage.setItem("modelsInfo", models);

      return "success";
    }
  }

  return "failure";
}

export function getRetailersInfo(data, retailerCode) {
  const curRetailer = data.filter((c) => {
    return c.code === retailerCode ? c : "";
  });
  return curRetailer;
}
export function getProductsInfo(data, productCode) {
  const curProduct = data.filter((c) => {
    return c.prodCode === productCode ? c : "";
  });
  return curProduct;
}
export function getBrandsInfo(data, brandCode) {
  const curBrand = data.filter((c) => {
    return c.brandCode === brandCode ? c : "";
  });
  return curBrand;
}
export function getModelsInfo(data, modelCode) {
  const curModel = data.filter((c) => {
    return c.modelCode === modelCode ? c : "";
  });
  return curModel;
}

export function getImages(processId) {
  try {
    const apiEndpoint = apiMisEndPoint + "/attachment/get/" + processId;
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
}

export async function uploadExcelTicketDataBulk(data, ticketScope) {
  let params;
  let apiEndpoint;
  switch (data[0].flowId) {
    case "BulkQC":
      params = getBulkQCJSON(data, ticketScope);
      apiEndpoint = apiCoreEndpoint + "/api/ticket/create/bulkQC";
      return await _http.put(apiEndpoint, params, _http.headerJWT());
    case "Bulk":
      params = getBulkJSON(data, ticketScope);
      apiEndpoint = apiCoreEndpoint + "/api/ticket/create/bulk";
      return await _http.put(apiEndpoint, params, _http.headerJWT());
    case "Furniture":
      params = getFurnitureJSON(data, ticketScope);
      apiEndpoint = apiCoreEndpoint + "/api/ticket/create/Furniture";
      return await _http.put(apiEndpoint, params, _http.headerJWT());
    case "eWaste":
      params = getEwasteJSON(data, ticketScope);
      apiEndpoint = apiCoreEndpoint + "/api/ticket/create";
      return await _http.put(apiEndpoint, params, _http.headerJWT());
    case "DropOff":
      params = getDropOffJSONBulk(data, ticketScope);
      apiEndpoint = apiCoreEndpoint + "/api/ticket/create/dropOff";
      return await _http.put(apiEndpoint, params, _http.headerJWT());
    case "PickAndDropOneWay":
      params = getBulkJSON(data, ticketScope);
      apiEndpoint = apiCoreEndpoint + "/api/ticket/create/bulk";
      return await _http.put(apiEndpoint, params, _http.headerJWT());


    default:
      let resp = {
        data: {
          success: false,
          statusCode: 200,
          msg: "Validations Failed",
          data: "Incorrect Flow Id",
        },
      };
      return resp;
  }
}

export async function uploadExcelTicketData(data, ticketScope) {
  let params;
  let apiEndpoint;

  switch (data.flowId) {
    case "PickAndDropOneWay":
      params = getPickAndDrop1WayJSON(data, ticketScope);
      apiEndpoint = apiCoreEndpoint + "/api/ticket/create/PickAndDropOneWay";
      return await _http.put(apiEndpoint, params, _http.headerJWT());

    case "PickAndDropTwoWay":
      params = getPickAndDrop2WayJSON(data, ticketScope);
      apiEndpoint = apiCoreEndpoint + "/api/ticket/create/PickAndDropTwoWay";
      return await _http.put(apiEndpoint, params, _http.headerJWT());

    case "AdvanceExchange":
      params = getAdvanceExchangeJSON(data, ticketScope);
      apiEndpoint = apiCoreEndpoint + "/api/ticket/create/AdvanceExchange";
      return await _http.put(apiEndpoint, params, _http.headerJWT());

    case "BuyBack":
      params = getBuyBackJSON(data, ticketScope);
      apiEndpoint = apiCoreEndpoint + "/api/ticket/create/BuyBack";
      return await _http.put(apiEndpoint, params, _http.headerJWT());

    case "InstallationV1":
      params = getInstallationV1JSON(data, ticketScope);
      apiEndpoint = apiCoreEndpoint + "/api/ticket/create/InstallationV1";
      return await _http.put(apiEndpoint, params, _http.headerJWT());

    case "DropOff":
      params = getDropOffJSON(data, ticketScope);
      apiEndpoint = apiCoreEndpoint + "/api/ticket/create/dropOff";
      return await _http.put(apiEndpoint, params, _http.headerJWT());
    default:
      let resp = {
        data: {
          success: false,
          statusCode: 200,
          msg: "Validations Failed",
          data: "Incorrect Flow Id",
        },
      };
      return resp;
  }
}

export function getTicketsList(query) {
  try {
    const apiEndpoint = apiCoreEndpoint + "/getTickets/" + query;
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
  // return _http.get(apiCoreEndpoint + "/getTickets/" + query);
}

export function getTicketInfo(ticketNo) {
  try {
    const apiEndpoint = apiMisEndPoint + "/ticket/track/ticket/" + ticketNo + "?result=all";
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
  // return _http.get(apiCoreEndpoint + "/getTickets/" + query);
}

export function getTicketMisHistory(ticketNo) {
  try {
    const apiEndpoint = apiMisEndPoint + "/ticket/track/history/" + ticketNo + "?result=all";
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
  // return _http.get(apiCoreEndpoint + "/getTickets/" + query);
}

export function getTicketsCounts(params) {
  const apiEndpoint =
    apiCoreEndpoint + "/ticketDetails/get-ticketCounts/by-hub";
  return _http.put(apiEndpoint, params, _http.headerJWT());
}
export function getTicketsCountsNew(params) {
  const apiEndpoint =
    apiCoreEndpoint + "/new/ticketDetails/get-ticketCounts/by-hub";
  return _http.put(apiEndpoint, params, _http.headerJWT());
}
export function getPincodesList(curHub) {
  const apiEndpoint = apiCoreEndpoint + "/ticketDetails/get-pincodes/";
  try {
    const apiEndpoint =
      apiCoreEndpoint + "/new/ticketDetails/get-pincodes/" + curHub;
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
}
//  'https://test-api.bizlog.in:8443/core/ticketDetails/playersDetails/23_02_09_zzlk1675930157233T233Z9df37e6e70ce139651e2ce7a1aa170be'
export function getFEInfo(ticketBlockId) {
  try {
    const apiEndpoint =
      apiCoreEndpoint + "/ticketDetails/playersDetails/" + ticketBlockId;
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
}

export function getPincodesListByStatus(curHub, status) {
  const apiEndpoint = apiCoreEndpoint + "/ticketDetails/get-pincodes/";
  try {
    const apiEndpoint =
      apiCoreEndpoint + "/ticketDetails/get-pincodes/" + curHub + "/" + status;
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
}
export function getTripStatusByTripId(tripId,ticketBlockId) {
 
  try {
    const apiEndpoint =
    apiECoreEndPoint + `/getTripStatusByTripId/${tripId}/${ticketBlockId}`
    return _http.get(apiEndpoint, _http.headerJWT());
  } catch {
    return undefined;
  }
}

// https://test-api.bizlog.in:8443/core/ticketDetails/get-ticketCounts/by-hub
