import { getQuestionFormat } from "./tataCliqQueFormat";

export function getPickAndDrop1WayJSON(data, ticketScope) {
    try {
        let inFlowBy = "";
        if (data.retailerCode === "REL") {
            inFlowBy = "FORM";
        }
        else {
            inFlowBy = "EXCEL";
        }

        if (data.retailerId === "24a36528-0749-4cd9-a6e9-cf8fec19d04b") {
            let reason = data.problemDesc.trim();
            getQuestionFormat(reason, data);
        }

        let indentificationNumber = data.identificationNo.toString().replace(/(?:\r\n|\r|\n|\t)/g, '');
        let qcQquestions = [];

        function getParamObject(key, value) {
            if (key === "product_not_used" || key === "product_not_damaged") {
                return {
                    "id": "QC_" + key,
                    "text": "Is the " + key + " ??",
                    "check": [
                        {
                            "field": key,
                            "valid_value": [value]
                        }
                    ]
                }
            }
            else {
                return {
                    "id": "QC_" + key,
                    "text": "Is the " + key + " is Matching??",
                    "check": [
                        {
                            "field": key,
                            "valid_value": [value]
                        }
                    ]
                }
            }
        }
        const d = new Date().toISOString().slice(0, 10);
        if (data["ticketQc"] && data["ticketQc"].toLowerCase() === "yes") {
            let text = JSON.stringify(data, function (key, value) {
                if (key.includes('QC__')) {
                    let params = getParamObject(key.substring(4), value);
                    qcQquestions.push(params);
                } else {
                    // alert("false");
                    return value;
                }
            });
        }
        let accDetails = [];
        function getParamAccessories(key) {
            if (key) {
                return {
                    "label": key,
                    "status": "Yes",
                    "accessories": false
                }
            }
        }

        if (data["accessories"]) {
            let temp = new Array();
            temp = data["accessories"].split(",");
            temp.map((data) => {
                if (data) {
                    let params = getParamAccessories(data);
                    accDetails.push(params);
                }
            })


        }

        const params = {
            primary: {
                ticketPriority: data.ticketPriority,
                flowId: data.flowId,
                retailerId: data.retailerId,
                retailerNo: data.retailerNo?.toString() || "",
                conComplaintNo: data.conComplaintNo?.toString() || "",
                LRNumber: data.LRNumber?.toString() || "",
                orderNo: data.orderNo?.toString() || "",
                invoiceNo: data.invoiceNo?.toString() || "",
                parentTicket: data.parentTicket,
                ticketDetails: data.ticketDetails,
                specialInstructions: data.specialInstructions,
                buyerContactNumber: "9854745862",
                dateOfTicket: d?.toString() || "",
                isAppointmentReq: data.isAppointmentReq,
                isInstallationReq: data.isInstallationReq,
                isPhysicalEval: data.isPhysicalEval,
                isTechEval: data.isTechEval,
                isPackingReq: data.isPackingReq,
                paymentMode: "cash",
                inFlowBy: inFlowBy,
                ticketScope: ticketScope,
                deliveryType: data.deliveryType,
                carrier: data.carrier

            },
            primary_details: {
                medical_equp: "",
                someFiled2: "",
            },
            products: [
                {
                    primary: {
                        productId: data.productId,
                        productCode: data.productCode,
                        brandId: data.brandId,
                        modelId: data.modelId,
                        brandName: data.brandName,
                        modelName: data.modelName,
                        brandCode: data.brandCode,
                        modelCode: data.modelCode,
                        productName: data.productName,
                        dateOfPurchase: data.dateOfPurchase,
                        identificationNo: indentificationNumber.trim(),
                        productDesc: data.productDesc,
                        problemDesc: data.problemDesc,
                        productValue: data.productValue?.toString() || "",
                        cost: data.cost?.toString() || "",
                        isUnderWarranty: data.isUnderWarranty,
                        accessories: "",
                        pickupType: "",
                        productDimensions: {
                            productUnit: data.productDimensionUnit,
                            productLength: data.productLength,
                            productBreadth: data.productBreadth,
                            productHeight: data.productHeight
                        },
                        productWeight: {
                            productUnit: data.productWeightUnit,
                            productActualWeight: data.productActualWeight,
                            productVolumeWeight: data.productVolumeWeight
                        }
                    },
                    product_details: {
                        process_id: "",
                        ticketQc: { questions: qcQquestions },
                        accessoryDetails: accDetails,
                        productBoxes: [
                            {
                                boxName: data.boxName,
                                boxSlNo: data.boxSlNo
                            }
                        ]


                    },
                    src_add: {
                        srcContactPerson: data.srcContactPerson,
                        srcOrg: data.srcOrg,
                        srcAdd1: data.srcAdd1,
                        srcAdd2: data.srcAdd2,
                        srcLocation: data.srcLocation,
                        srcContact1: data.srcContact1?.toString() || "",
                        srcContact2: data.srcContact2?.toString() || "",
                        srcCity: data.srcCity,
                        srcState: data.srcState,
                        srcPincode: data.srcPincode?.toString() || "",
                        srcLandmark: data.srcLandmark,
                        srcEmailId: data.srcEmailId?.toString() || "",
                        srcEmailIdAlt: data.srcEmailIdAlt,
                        srcType: data.srcType,
                        srcLatitude: data?.srcLatitude,
                        srcLongitude: data?.srcLongitude,
                    },
                    dst_add: {
                        dstContactPerson: data.dstContactPerson,
                        dstOrg: data.dstOrg,
                        dstAdd1: data.dstAdd1,
                        dstAdd2: data.dstAdd2,
                        dstLocation: data.dstLocation,
                        dstContact1: data.dstContact1?.toString() || "",
                        dstContact2: data.dstContact2?.toString() || "",
                        dstCity: data.dstCity,
                        dstState: data.dstState,
                        dstPincode: data.dstPincode?.toString() || "",
                        dstLandmark: data.dstLandmark,
                        dstEmailId: data.dstEmailId,
                        dstEmailIdAlt: data.dstEmailIdAlt,
                        dstType: data.dstType,
                        dstLatitude: data?.dstLatitude,
                        dstLongitude: data?.dstLongitude
                    },
                },
            ],
        };
        // console.log("getPickAndDrop1WayJSON params....." + JSON.stringify(params));

        return params;
    }
    catch {
        console.log("Error in ticket creation");
    }
}



export function getBulkPickAndDrop1WayJSON(data, ticketScope) {
    try {
        let inFlowBy = "";
        if (data[0].retailerCode === "REL") {
            inFlowBy = "FORM";
        }
        else {
            inFlowBy = "EXCEL";
        }

        if (data[0].retailerId === "24a36528-0749-4cd9-a6e9-cf8fec19d04b") {
            let reason = data[0].problemDesc.trim();
            getQuestionFormat(reason, data[0]);
        }

        let indentificationNumber = data[0].identificationNo.toString().replace(/(?:\r\n|\r|\n|\t)/g, '');
        let qcQquestions = [];

        function getParamObject(key, value) {
            if (key === "product_not_used" || key === "product_not_damaged") {
                return {
                    "id": "QC_" + key,
                    "text": "Is the " + key + " ??",
                    "check": [
                        {
                            "field": key,
                            "valid_value": [value]
                        }
                    ]
                }
            }
            else {
                return {
                    "id": "QC_" + key,
                    "text": "Is the " + key + " is Matching??",
                    "check": [
                        {
                            "field": key,
                            "valid_value": [value]
                        }
                    ]
                }
            }
        }
        const d = new Date().toISOString().slice(0, 10);
        if (data[0]["ticketQc"] && data[0]["ticketQc"].toLowerCase() === "yes") {
            let text = JSON.stringify(data[0], function (key, value) {
                if (key.includes('QC__')) {
                    let params = getParamObject(key.substring(4), value);
                    qcQquestions.push(params);
                } else {
                    // alert("false");
                    return value;
                }
            });
        }
        let accDetails = [];
        function getParamAccessories(key) {
            if (key) {
                return {
                    "label": key,
                    "status": "Yes",
                    "accessories": false
                }
            }
        }

        if (data[0]["accessories"]) {
            let temp = new Array();
            temp = data[0]["accessories"].split(",");
            temp.map((data) => {
                if (data) {
                    let params = getParamAccessories(data);
                    accDetails.push(params);
                }
            })


        }
        const dates = new Date().toISOString().slice(0, 10);
        let bulkProducts = [];
        data.map((r) => {
            let products = {
                primary: {
                    productId: r.productId,
                    productCode: r.productCode,
                    brandId: r.brandId,
                    modelId: r.modelId,
                    brandName: r.brandName,
                    modelName: r.modelName,
                    brandCode: r.brandCode,
                    modelCode: r.modelCode,
                    productName: r.productName,
                    dateOfPurchase: r.dateOfPurchase,
                    identificationNo: r.identificationNo.toString().trim(),
                    productDesc: r.productDesc,
                    problemDesc: r.problemDesc,
                    productValue: r.productValue?.toString() || "",
                    cost: r.cost?.toString() || "",
                    isUnderWarranty: r.isUnderWarranty,
                    accessories: "",
                    pickupType: "",
                    productDimensions: {
                        productUnit: r.productDimensionUnit,
                        productLength: r.productLength,
                        productBreadth: r.productBreadth,
                        productHeight: r.productHeight
                    },
                    productWeight: {
                        productUnit: r.productWeightUnit,
                        productActualWeight: r.productActualWeight,
                        productVolumeWeight: r.productVolumeWeight
                    }
                },
                product_details: {
                    process_id: "",
                    ticketQc: { questions: qcQquestions },
                    accessoryDetails: accDetails,
                    productBoxes: [
                        {
                            boxName: r.boxName,
                            boxSlNo: r.boxSlNo
                        }
                    ]



                },
                src_add: {
                    srcContactPerson: r.srcContactPerson,
                    srcOrg: "org",
                    srcAdd1: r.srcAdd1,
                    srcAdd2: r.srcAdd2,
                    srcLocation: r.srcLocation,
                    srcContact1: r.srcContact1?.toString() || "",
                    srcContact2: r.srcContact2?.toString() || "",
                    srcCity: r.srcCity,
                    srcState: r.srcState,
                    srcPincode: r.srcPincode?.toString() || "",
                    srcLandmark: r.srcLandmark,
                    srcEmailId: r.srcEmailId,
                    srcEmailIdAlt: r.srcEmailIdAlt,
                    srcType: r.srcType,
                    srcLatitude: r?.srcLatitude,
                    srcLongitude: r?.srcLongitude,
                },
                dst_add: {
                    dstContactPerson: r.dstContactPerson,
                    dstOrg: "org",
                    dstAdd1: r.dstAdd1,
                    dstAdd2: r.dstAdd2,
                    dstLocation: r.dstLocation,
                    dstContact1: r.dstContact1?.toString() || "",
                    dstContact2: r.dstContact2?.toString() || "",
                    dstCity: r.dstCity,
                    dstState: r.dstState,
                    dstPincode: r.dstPincode?.toString() || "",
                    dstLandmark: r.dstLandmark,
                    dstEmailId: r.dstEmailId,
                    dstEmailIdAlt: r.dstEmailIdAlt,
                    dstType: r.dstType,
                    dstLatitude: r?.dstLatitude,
                    dstLongitude: r?.dstLongitude
                },
            };
            bulkProducts.push(products);
        });
        const params = {
            primary: {
                ticketPriority: data[0].ticketPriority,
                flowId: data[0].flowId,
                retailerId: data[0].retailerId,
                retailerNo: data[0].retailerNo?.toString() || "",
                conComplaintNo: data[0].conComplaintNo?.toString() || "",
                LRNumber: data[0].LRNumber?.toString() || "",
                orderNo: data[0].orderNo?.toString() || "",
                invoiceNo: data[0].invoiceNo?.toString() || "",
                parentTicket: data[0].parentTicket,
                ticketDetails: data[0].ticketDetails,
                specialInstructions: data[0].specialInstructions,
                buyerContactNumber: "9854745862",
                dateOfTicket: dates?.toString() || "",
                isAppointmentReq: data[0].isAppointmentReq,
                isInstallationReq: data[0].isInstallationReq,
                isPhysicalEval: data[0].isPhysicalEval,
                isTechEval: data[0].isTechEval,
                isPackingReq: data[0].isPackingReq,
                paymentMode: "cash",
                inFlowBy: inFlowBy,
                ticketScope: ticketScope,
                deliveryType: data[0].deliveryType,
                carrier: data[0].carrier

            },
            primary_details: {
                medical_equp: "",
                someFiled2: "",
            },
            products: bulkProducts
        };
        // console.log("getPickAndDrop1WayJSON params....." + JSON.stringify(params));

        return params;
    }
    catch {
        console.log("Error in ticket creation");
    }
}