import React from "react";
import FormLib from "./../../_common/FormLib";

class ProjectModuleform extends FormLib {
  state = {
    data: {
      username: "",
      password: "",
      pickupDate: this.getDatebyDays(1),
      pickupTime: "14:00",
    },
    errors: {},
  };
  project = [
    { key: "rnd", value: "Bluedart" },
    { key: "finance", value: "Digimart" },
    { key: "sales", value: "Servify" },
    { key: "it", value: "IBM" },
    // {projectId: "iy48arunt0jyoy33xgpmw5hjd_001"},
    // {projectCode: "a1"},
    // {project: "BlueDart"},
    // {prOwner: "Manoj"},
    // {startDate:" "},
    // {dueDate:" "},
    // {endDate:" "},
    // {remarks: ""},
    // {status: "true"},
    // {createdBy: "superAdmin"},
    // {createdAt:1633008865000},
    // {updatedBy: "superAdmin"},
    // {updatedAt: 1633008865000},
  ];
  render() {
    return (
      <React.Fragment>
        <div className=" fluid-container page-todo bootstrap snippets bootdeys">
          <form onSubmit={this.handleSubmit} className="projectModuleForm">
            <div className="row">
              <div className="col-md-12">
                {this.rSB("Project", "name", this.state.projects, {
                  value: "projectId",
                  label: "project",
                })}
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#myModal3"
                >
                  Add
                </button>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default ProjectModuleform;
