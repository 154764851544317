import React from "react";
import FormLib from "../../_common/FormLib";
import toast from "../../../services/_toastService";
import {
  assign,
  unassign,
} from "../../../services/coreItAdmin/PlayerMenusServices";

class PlayerMenusMapper extends FormLib {
  state = {
    data: {},
    errors: {},
    curPlayer: {},
  };

  componentDidMount() {
    this.setState({ curPlayer: this.props.curPlr });
  }

  localHandleChange = (e) => {
    this.asignUnAsignPlayersMenus(e);
  };

  asignUnAsignPlayersMenus = (e) => {
    let paramData = {};
    paramData.playerId = this.state.curPlayer.playerId;
    if (e.target.checked) {
      paramData.hubId = e.target.value;
      paramData.isPrimary = "true";
      this.assignUnassign("assign", paramData);
    } else {
      paramData.hubId = e.target.value;
      paramData.isPrimary = "false";
      this.assignUnassign("unAssign", paramData);
    }
  };

  assignUnassign = async (type, paramData) => {
    if (type === "assign") {
      const res = await assign(paramData);
      if (res.data.success) {
        toast.toastSuccess(res.data.msg);
      } else {
        toast.toastError(res.data.msg);
      }
      // console.log(res.data);
    } else {
      const res = await unassign(paramData);
      // console.log(res.data);
      if (res.data.success) {
        toast.toastSuccess(res.data.msg);
      } else {
        toast.toastError(res.data.msg);
      }
    }
  };
  render() {
    const menus = this.props.menus;
    return (
      <div className="card overflow-auto" style={{ height: "30rem" }}>
        <div className="card-body">
          <div className="page-todo">
            <div className="tasks">
              {menus.map((r) => {
                return (
                  <div className="task nil" key={r.designationId}>
                    <div className="desc">
                      <span>
                        <div className="title">
                          {this.rCBHL("hl", [
                            { label: r.designation, value: r.designationId },
                          ])}
                        </div>
                      </span>
                      <div>
                        <span
                          className={
                            "badge badge-success pull-left mr-1 " +
                            "badge-" +
                            r.department
                          }
                        >
                          {r.department}
                        </span>
                        <span
                          className={
                            "badge badge-success pull-left mr-1 " +
                            "badge-" +
                            r.teamtype
                          }
                        >
                          {r.teamtype}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PlayerMenusMapper;
