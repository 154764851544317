import React, { useState, useEffect } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const MultiSelectAll = ({
  name,
  label,
  error,
  options,
  value,
  inline = "",
  ctrls,
  handleChange,
  classAppend = "",
  ...rest
}) => {
  // const MultiSelectAll = () => {
  const [selectedOptions, setSelectedOptions] = useState(options);
  const [allOps, setAllOps] = useState([]);

  // useEffect(() => {
  //     setAllOps([{ label: "All", value: "*" }, ...options]);
  // }, []);

  useEffect(() => {
    setSelectedOptions([{ label: "All", value: "*" }, ...options]);
  }, []);

  useEffect(() => {
    handleChange(selectedOptions, name);
  }, [selectedOptions]);

  function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.some((o) => o.value === "*")) {
      return `${placeholderButtonLabel}: All`;
    } else {
      return `${placeholderButtonLabel}: ${value.length} selected`;
    }
  }

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }

  if (label === "Hub") {
    return (
      <>
        <ReactMultiSelectCheckboxes
          options={[{ label: "All", value: "*" }, ...options]}
          placeholderButtonLabel="Hubs"
          getDropdownButtonLabel={getDropdownButtonLabel}
          value={selectedOptions}
          onChange={onChange}
          setState={setSelectedOptions}
        />
      </>
    );
  } else if (label === "Client") {
    return (
      <ReactMultiSelectCheckboxes
        options={[{ label: "All", value: "*" }, ...options]}
        placeholderButtonLabel="Clients"
        getDropdownButtonLabel={getDropdownButtonLabel}
        value={selectedOptions}
        onChange={onChange}
        setState={setSelectedOptions}
      />
    );
  } 
  else if (label === "ClientMis") {
    return (
      <ReactMultiSelectCheckboxes
        options={[{ label: "All", value: "*" }, ...options]}
        placeholderButtonLabel="Clients"
        getDropdownButtonLabel={getDropdownButtonLabel}
        value={selectedOptions}
        onChange={onChange}
        setState={setSelectedOptions}
      />
    );
  }else if (label === "Fields") {
    return (
      <ReactMultiSelectCheckboxes
        options={[{ label: "All", value: "*" }, ...options]}
        placeholderButtonLabel="Fields"
        getDropdownButtonLabel={getDropdownButtonLabel}
        value={selectedOptions}
        onChange={onChange}
        setState={setSelectedOptions}
      />
    );
  }else if (label === "ExpenseHead") {
    return (
      <ReactMultiSelectCheckboxes
        options={[{ label: "All", value: "*" }, ...options]}
        placeholderButtonLabel="Expense Head"
        getDropdownButtonLabel={getDropdownButtonLabel}
        value={selectedOptions}
        onChange={onChange}
        setState={setSelectedOptions}
      />
    );
  } else if (label === "Activities") {
    return (
      <ReactMultiSelectCheckboxes
        options={[{ label: "All", value: "*" }, ...options]}
        placeholderButtonLabel="ExpenseHead"
        getDropdownButtonLabel={getDropdownButtonLabel}
        value={selectedOptions}
        onChange={onChange}
        setState={setSelectedOptions}
      />
    );
  } else if (label === "Approver") {
    return (
      <ReactMultiSelectCheckboxes
        options={[{ label: "All", value: "*" }, ...options]}
        placeholderButtonLabel="Approver"
        getDropdownButtonLabel={getDropdownButtonLabel}
        value={selectedOptions}
        onChange={onChange}
        setState={setSelectedOptions}
      />
    );
  } else if (label === "Hubs") {
    return (
      <ReactMultiSelectCheckboxes
        options={[{ label: "All", value: "*" }, ...options]}
        placeholderButtonLabel="Hubs"
        getDropdownButtonLabel={getDropdownButtonLabel}
        value={selectedOptions}
        onChange={onChange}
        setState={setSelectedOptions}
      />
    );
  }
};
export default MultiSelectAll;
