import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import _auth from "./../../services/_authService";

class SubHeader extends Component {
  state = {};
  componentDidMount() {
    const user = _auth.getCurrentUser();
    this.setState({ user });
  }
  render() {
    const { subHeading } = this.props;
    const user = this.state.user;
    return (
      <nav className="sub-header navbar navbar-fixed fixed-title">
        <div className="container-fluid">
          <div className="navbar-header">
            <h1>{subHeading} </h1>
          </div>

          <ul className="nav nav-pills">
            {/* {this.props.quickMenu.map((qMenu) => (
              <li className="nav-item" key={qMenu.path || qMenu.key}>
                <NavLink to={qMenu.path} className="nav-link">
                  <i className={"fa " + qMenu.icon} aria-hidden="true"></i>
                </NavLink>
              </li>
            ))}

            <li>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn dropdown-icon "
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-bars"></i>
                </button>

                <div className="dropdown-menu dropdown-menu-right">
                  {this.props.subMenu.map((sMenu) => (
                    <NavLink
                      className="dropdown-item"
                      key={sMenu.path || sMenu.key}
                      to={sMenu.path}
                    >
                      <i className={"fa " + sMenu.icon} aria-hidden="true"></i>{" "}
                      {sMenu.label}
                    </NavLink>
                  ))}
                </div>
              </div>
            </li>

            <li style={{ marginLeft: 10 }}>
              <div className="btn-group">
                <button
                  type="button"
                  className="btn dropdown-icon "
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-home"></i>
                </button>

                <div className="dropdown-menu dropdown-menu-right">
                  {this.props.homeMenu.map((hMenu) => (
                    <NavLink
                      className="dropdown-item"
                      key={hMenu.path || hMenu.key}
                      to={hMenu.path}
                    >
                      <i className={"fa " + hMenu.icon} aria-hidden="true"></i>{" "}
                      {hMenu.label}
                    </NavLink>
                  ))}
                </div>
              </div>
            </li> */}

            <li>
              {user && (
                <ul id="nav-user">
                  <NavLink
                    className="nav-link dropdown-toggle-nocaret"
                    data-toggle="dropdown"
                    to="#"
                  >
                    <span className="user-profile">
                      <img
                        src="https://cdn0.iconfinder.com/data/icons/pinterest-ui-flat/48/Pinterest_UI-18-512.png"
                        width="30px"
                        height="30px"
                        className="img-circle rounded-circle"
                        alt="Assets"
                      />
                    </span>
                  </NavLink>
                  <ul className="dropdown-menu dropdown-menu-right">
                    <li className="dropdown-item user-details">
                      <div className="media">
                        <div className="avatar">
                          <img
                            className="align-self-start mr-3"
                            src="./img/bizlog.svg"
                            alt="Bizlog"
                          />
                        </div>
                        {/* <img src="./img/bizlog.svg" alt="Bizlog" /> */}
                        <div className="media-body">
                          <h5 className="mt-2 user-title">
                            <b>{user.firstName}</b>
                          </h5>
                          <p className="user-subtitle">{user.username}</p>
                          <p>{user.role}</p>
                        </div>
                      </div>
                    </li>
                    <li className="dropdown-divider"></li>

                    <NavLink to="/profile" className="dropdown-item">
                      <i className="fa fa-user" aria-hidden="true"></i> User
                      Info
                    </NavLink>
                    <NavLink to="/changePassword" className="dropdown-item">
                      <i className="fa fa-user" aria-hidden="true"></i> Change
                      Password
                    </NavLink>
                    <li className="dropdown-divider"></li>
                    <NavLink to="/logout" className="dropdown-item">
                      <i className="fa fa-sign-out" aria-hidden="true"></i>{" "}
                      Logout
                    </NavLink>
                  </ul>
                </ul>
              )}
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default SubHeader;
