import React from "react";
import FormLib from "./../../_common/FormLib";

import toast from "../../../services/_toastService";

import RoleGroups from "./RoleGroups";
import Roles from "./Roles";

import ModalDivision from "./ModalDivision";
import ModalGroup from "./ModalGroup";
import ModalRole from "./ModalRole";
import RolesFeatures from "./RolesFeatures";
import PrevilagesTitle from "../../_subhead/PrevilagesTitle";

import { getRoleDivs } from "./../../../services/appRoles/roleDivsServices";
import { getRoleGroups } from "./../../../services/appRoles/roleGroupsServices";
import { getRoles } from "./../../../services/appRoles/rolesServices";
import { getFeatures } from "./../../../services/appRoles/RolesFeaturesServices";

class AppRoles extends FormLib {
  state = {
    data: {},
    errors: {},
    roleDivs: [],
    roleGroups: [],
    roles: [],
    features: [],
    curDivision: { divisionId: "" },
    curGroup: { roleGroupId: "" },
    curRole: { roleId: "" },
    curFeatures: {},
  };
  schema = {};

  componentDidMount() {
    this.loadDivisions();
  }

  loadDivisions = async (m) => {
    const res = await getRoleDivs();
    if (res.data.success === true) {
      let data = res.data.data.roleDivs;
      data.sort((a, b) => { return a.division.localeCompare(b.division) });
      this.setState({ roleDivs: data });
      toast.toastSuccess("Division Loaded Successfully");
    } else {
      toast.toastError("failed");
    }
  };

  loadCurGroups = async (divisionId, modReset = true) => {
    if (modReset === true) {
      this.setState({ roles: [] });
      this.setState({ curGroup: { roleGroupId: "" } });
      this.setState({ curRole: { roleId: "" } });
    }
    let curDiv;
    const filter = this.state.roleDivs.filter((r) => {
      return r.divisionId === divisionId ? r : null;
    });

    if (!filter[0]) {
      curDiv = { divisionId: "" };
    } else {
      curDiv = filter[0];
    }

    this.setState({ curDivision: curDiv });

    if (divisionId !== "") {
      this.loadGroups(divisionId);
    } else {
      this.setState({ roleGroups: [] });
    }
  };



  loadGroups = async (divisionId) => {
    if (divisionId !== "") {
      const res = await getRoleGroups(divisionId);
      this.setState({ roleGroups: res.data.data.roleGroups });
    } else {
      this.setState({ roleGroups: [] });
    }
  };



  loadRoles = async (g, modReset = true) => {
    // console.log("g............"+JSON.stringify(g))
    if (modReset === true) {
      this.setState({ curGroup: g });
      this.setState({ curRole: { roleId: "" } });
    }
    if (g.roleGroupId !== "") {
      const res = await getRoles(g.roleGroupId);
      this.setState({ roles: res.data.data.roles });
    } else {
      this.setState({ roles: [] });
    }
  };

  loadRole = async (g) => {
    const res = await getRoles(g.roleGroupId);
    this.setState({ roles: res.data.data.roles });
  }

  // -------------------------------------

  loadRolesFeatures = async (g, modReset = true) => {
    if (modReset === true) {
      this.setState({ curRole: g });
      this.setState({ curFeatures: {} });
    }
    if (g["roleId"] !== "") {
      const res = await getFeatures(g["roleId"]);
      let data = res.data.data.rolesFeatures;
      data.sort((a, b) => {
        return a.title.localeCompare(b.title);
      });
      this.setState({ features: data });
      toast.toastSuccess(res.data.msg);
    } else {
      this.setState({ features: [] });
    }
  };

  // ------------------------------------------------

  handleDivisionChange = (e) => {
    this.loadCurGroups(e.target.value);
    this.setState({ features: [] });
  };

  handleGroupChange = (g) => {
    this.loadRoles(g);
    this.setState({ features: [] });
  };

  handleRoleChange = (r) => {
    // this.loadRoles(r);
    // this.setState({ curRole: r });
    this.loadRolesFeatures(r);
  };
  handleRolesUpload = () => {
    this.setState({ features: [] });
    this.setState({ curRole: { roleId: "" }, curFeatures: {} });
  };

  handleFeaturesChanges = (r) => {
    this.loadRolesFeatures(r);
  };

  handleGroupUnload = () => {
    this.setState({ roles: [] });
    this.setState({ curGroup: { roleGroupId: "" }, curRole: { roleId: "" } });
    this.handleRoleUnload();
  };

  handleRoleUnload = () => {
    this.setState({ roles: [] });
    this.setState({ features: [] });
    this.setState({ curRole: { roleId: "" }, curFeatures: {} });
  };

  localHandleChange = (e) => {
    if (e.target.id === "roleDivs") {
      this.handleDivisionChange(e);
    }
  };

  render() {
    return (
      <React.Fragment>
        <PrevilagesTitle subHeading="App Roles" />
        <div className="pageContent">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10">
                {this.rSB("Role Divisions", "roleDivs", this.state.roleDivs, {
                  value: "divisionId",
                  label: "division",
                })}
              </div>
              <div className="menu col-md-2 ">
                {this.state.curDivision.divisionId === "" && (
                  <button
                    type="button"
                    className="btn btn-sm btn-info pull-left"
                    data-toggle="modal"
                    data-target="#myDivisionModel"
                  >
                    <i className={"fa  fa-plus"} />
                  </button>
                )}
                {this.state.curDivision.divisionId !== "" && (
                  <button
                    type="button"
                    className="btn btn-sm btn-success pull-left in-line"
                    data-toggle="modal"
                    data-target="#myDivisionModel"
                  >
                    <i className={"fa  fa-pencil"} />
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-sm btn-secondary pull-left"
                  onClick={() => this.loadDivisions()}
                >
                  <i className={"fa fa-refresh"} />
                </button>
              </div>
            </div>
            <div className="card-deck">
              <RoleGroups
                roleGroups={this.state.roleGroups}
                curDivision={this.state.curDivision}
                curGroup={this.state.curGroup}
                onGroupChange={this.handleGroupChange}
                onGroupUnload={this.handleGroupUnload}
              />

              <Roles
                roles={this.state.roles}
                curGroup={this.state.curGroup}
                curRole={this.state.curRole}
                onRoleChange={this.handleRoleChange}
                onRoleUnload={this.handleRoleUnload}
              />

              <RolesFeatures
                features={this.state.features}
                curRole={this.state.curRole}
                onFeatureChange={this.handleFeaturesChanges}
                key={this.state.curRole["roleId"]}
              />
            </div>
          </div>
        </div>

        <ModalDivision
          curDivision={this.state.curDivision}
          onloadDivisions={this.loadDivisions}
          key={this.state.curDivision["divisionId"]}
        />

        <ModalGroup
          roleDivs={this.state.roleDivs}
          curDivision={this.state.curDivision}
          curGroup={this.state.curGroup}
          onLoadGroups={this.loadGroups}
          key={
            this.state.curGroup["roleGroupId"] ||
            "m_" + this.state.curDivision["divisionId"]
          }
        />
        <ModalRole
          roleGroups={this.state.roleGroups}
          curGroup={this.state.curGroup}
          curRole={this.state.curRole}
          onLoadRoles={this.loadRole}
          key={
            this.state.curRole["roleId"] ||
            "r_" + this.state.curGroup["roleGroupId"]
          }
        />
      </React.Fragment>
    );
  }
}
export default AppRoles;
