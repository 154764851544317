import React from "react";
import FormLib from "./../../_common/FormLib";
import toast from "../../../services/_toastService";
import { getHubs } from "../../../services/coreItAdmin/PlayerHubsServices";
import { getRetailers } from "../../../services/coreItAdmin/PlayerRetailersServices";
import { getZones } from "../../../services/coreItAdmin/PlayerZonesServices";
import { getRegions } from "../../../services/coreItAdmin/PlayerRegionsServices";
import { getRoles } from "../../../services/coreItAdmin/PlayerRolesServices";
import { getDesignations } from "../../../services/coreItAdmin/PlayerDesignationsServices";
import { getFeatures } from "../../../services/coreItAdmin/PlayerFeaturesServices";
import { getMenus } from "../../../services/coreItAdmin/PlayerMenusServices";
import PlayerHubMapper from "./PlayerHubMapper";
import PlayerRetailersMapper from "./PlayerRetailersMapper";
import PlayerZonesMapper from "./PlayerZonesMapper";
import PlayerRegionsMapper from "./PlayerRegionsMapper";
import PlayerRolesMapper from "./PlayerRolesMapper";
import PlayerDesignationsMapper from "./PlayerDesignationsMapper";
import PlayerFeaturesMapper from "./PlayerFeaturesMapper";
import PlayerMenusMapper from "./PlayerMenusMapper";

class AssignPlayer extends FormLib {
  state = {
    data: {},
    mode: "",
    typeOfAsign: "",
    errors: {},
    curPlayer: {},
    retailers: [],
    zones: [],
    regions: [],
    hubs: [],
    roles: [],
    designations: [],
    features: [],
    menus: [],
  };

  status = [
    { sortKey: "location", sort: "Location type" },
    { sortKey: "roles", sort: "Roles type" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  componentDidMount() {
    this.setCurrentPlayer(this.props.curPlayer);
  }

  setCurrentPlayer = (g) => {
    this.setState({ curPlayer: g });
  };

  localHandleChange = (e) => {
    if (e.target.id === "status0") {
      this.setState({ typeOfAsign: e.target.value });
      this.reset();
    } else {
      this.setState({ typeOfAsign: e.target.value });
      this.reset();
    }
  };

  reset = () => {
    this.setState({ hubs: [] });
    this.setState({ retailers: [] });
    this.setState({ zones: [] });
    this.setState({ regions: [] });
    this.setState({ roles: [] });
    this.setState({ designations: [] });
    this.setState({ features: [] });
    this.setState({ menus: [] });
    this.setState({ mode: "" });
  };
  onModeChange = (mode) => {
    this.setState({ mode: mode });

    switch (mode) {
      case "Hubs":
        this.loadHubs(this.state.curPlayer.playerId);
        break;
      case "Retailers":
        this.loadRetailers(this.state.curPlayer.playerId);
        break;
      case "Zones":
        this.loadZones(this.state.curPlayer.playerId);
        break;
      case "Regions":
        this.loadRegions(this.state.curPlayer.playerId);
        break;
      case "Roles":
        this.loadRoles(this.state.curPlayer.playerId);
        break;
      case "Features":
        this.loadFeatures(this.state.curPlayer.playerId);
        break;
      case "Menus":
        this.loadMenus(this.state.curPlayer.playerId);
        break;
      case "Designation":
        this.loadDesignations(this.state.curPlayer.playerId);
        break;
    }
  };

  loadHubs = async (id) => {
    this.setState({ loading: true });
    const res = await getHubs(id);
    if (res.data.success === true) {
      let data = res.data.data.playersHubs;
      data.sort((a, b) => {
        return a.hub.localeCompare(b.hub);
      });
      this.setState({ hubs: data });
      // toast.toastSuccess(res.data.msg);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  loadRetailers = async (id) => {
    this.setState({ loading: true });
    const res = await getRetailers(id);
    if (res.data.success === true) {
      let data = res.data.data.playersRetailers;
      data.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });

      this.setState({ retailers: data });
      toast.toastSuccess(res.data.msg);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  loadZones = async (id) => {
    this.setState({ loading: true });
    const res = await getZones(id);
    if (res.data.success === true) {
      let data = res.data.data.playersZones;
      data.sort((a, b) => {
        return a.zone.localeCompare(b.zone);
      });
      this.setState({ zones: data });
      toast.toastSuccess(res.data.msg);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  loadRegions = async (id) => {
    this.setState({ loading: true });
    const res = await getRegions(id);
    if (res.data.success === true) {
      let data = res.data.data.playersRegions;
      data.sort((a, b) => {
        return a.region.localeCompare(b.region);
      });
      this.setState({ regions: data });
      toast.toastSuccess(res.data.msg);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  loadRoles = async (id) => {
    this.setState({ loading: true });
    const res = await getRoles(id);
    if (res.data.success === true) {
      let data = res.data.data.playersRoles;
      data.sort((a, b) => {
        return a.role.localeCompare(b.role);
      });

      this.setState({ roles: data });
      toast.toastSuccess(res.data.msg);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  loadDesignations = async (id) => {
    this.setState({ loading: true });
    const res = await getDesignations(id);
    if (res.data.success === true) {
      let data = res.data.data.playersDesignations;
      data.sort((a, b) => {
        return a.designation.localeCompare(b.designation);
      });
      this.setState({ designations: data });
      toast.toastSuccess(res.data.msg);
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  loadFeatures = async (id) => {
    toast.toastSuccess("API Not Done");
    // this.setState({ loading: true });
    // const res = await getFeatures(id);
    // if (res.data.success === true) {
    //     // this.setState({ features: res.data.data.playersDesignations });
    //     toast.toastSuccess(res.data.msg);
    // } else {
    //     toast.toastError(res.data.msg);
    // }
    // this.setState({ loading: false });
  };

  loadMenus = async (id) => {
    toast.toastSuccess("API Not Done");
    // this.setState({ loading: true });
    // const res = await getMenus(id);
    // if (res.data.success === true) {
    //     // this.setState({ menus: res.data.data.playersDesignations });
    //     toast.toastSuccess(res.data.msg);
    // } else {
    //     toast.toastError(res.data.msg);
    // }
    // this.setState({ loading: false });
  };

  render() {
    const curPlayer = this.props.curPlayer;

    return (
      <>
        {curPlayer.playerId !== "" && (
          <div className="container">
            <div className="row">
              <div className="card ">
                <div className=" pt-4 pl-4">
                  <div className="row font-weight-bold text-dark justify-content-between ">
                    <div className="col-4">
                      <div className="mt-3">
                       
                        {curPlayer.firstname + " " + curPlayer.lastname}
                      </div>
                      <div className="">
                        {curPlayer.username !== "" &&
                          <span > {curPlayer.username}</span>
                        }
                        {curPlayer.username == "" &&
                          <span className="h5">  ----</span>
                        }
                      </div>
                    </div>
                    <div className="col-4">
                      {this.rRBL("", "status", this.status, this.stsConf)}
                    </div>
                  </div>
                </div>

                {this.state.typeOfAsign === "location" && (
                  <>
                    <hr />
                    <div className="row mb-3">
                      <div className="container-fluid">
                        <div className="text-center">
                          <button
                            onClick={() => this.onModeChange("Hubs")}
                            className={
                              this.state.mode === "Hubs"
                                ? "btn btn-md btn-info"
                                : "btn btn-md btn-outline-info"
                            }
                            style={{ width: "6rem" }}
                          >
                            Hubs
                          </button>
                          <button
                            onClick={() => this.onModeChange("Retailers")}
                            className={
                              this.state.mode === "Retailers"
                                ? "btn btn-md btn-info"
                                : "btn btn-md btn-outline-info"
                            }
                            style={{ width: "6rem" }}
                          >
                            Retailers
                          </button>
                          <button
                            onClick={() => this.onModeChange("Zones")}
                            className={
                              this.state.mode === "Zones"
                                ? "btn btn-md btn-info"
                                : "btn btn-md btn-outline-info"
                            }
                            style={{ width: "6rem" }}
                          >
                            Zones
                          </button>
                          <button
                            onClick={() => this.onModeChange("Regions")}
                            className={
                              this.state.mode === "Regions"
                                ? "btn btn-md btn-info"
                                : "btn btn-md btn-outline-info"
                            }
                            style={{ width: "6rem" }}
                          >
                            Regions
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {this.state.typeOfAsign === "roles" && (
                  <>
                    <hr />
                    <div className="row mb-3">
                      <div className="container">
                        <div className="text-center">
                          <button
                            onClick={() => this.onModeChange("Roles")}
                            className={
                              this.state.mode === "Roles"
                                ? "btn btn-md btn-info"
                                : "btn btn-md btn-outline-info"
                            }
                            style={{ width: "6rem" }}
                          >
                            Roles
                          </button>
                          <button
                            onClick={() => this.onModeChange("Features")}
                            className={
                              this.state.mode === "Features"
                                ? "btn btn-md btn-info"
                                : "btn btn-md btn-outline-info"
                            }
                            style={{ width: "6rem" }}
                          >
                            Features
                          </button>
                          <button
                            onClick={() => this.onModeChange("Menus")}
                            className={
                              this.state.mode === "Menus"
                                ? "btn btn-md btn-info"
                                : "btn btn-md btn-outline-info"
                            }
                            style={{ width: "6rem" }}
                          >
                            Menus
                          </button>
                          <button
                            onClick={() => this.onModeChange("Designation")}
                            className={
                              this.state.mode === "Designation"
                                ? "btn btn-md btn-info"
                                : "btn btn-md btn-outline-info"
                            }
                          >
                            Designation
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {this.state.typeOfAsign === "" && (
              <div className="container-fluid jumbotron text-center">
                <h4>Please select type of assign...</h4>
              </div>
            )}

            <div className="row">
              {this.state.mode === "Hubs" && (
                <PlayerHubMapper
                  hubs={this.state.hubs}
                  curPlr={this.state.curPlayer}
                  onLoadHubs={this.loadHubs}
                />
              )}
              {this.state.mode === "Retailers" && (
                <PlayerRetailersMapper
                  retailers={this.state.retailers}
                  curPlr={this.state.curPlayer}
                  onLoadRetailers={this.loadRetailers}
                />
              )}
              {this.state.mode === "Zones" && (
                <PlayerZonesMapper
                  zones={this.state.zones}
                  curPlr={this.state.curPlayer}
                  onLoadZone={this.loadZones}
                />
              )}
              {this.state.mode === "Regions" && (
                <PlayerRegionsMapper
                  regions={this.state.regions}
                  curPlr={this.state.curPlayer}
                  onLoadRegion={this.loadRegions}
                />
              )}
              {this.state.mode === "Roles" && (
                <PlayerRolesMapper
                  roles={this.state.roles}
                  curPlr={this.state.curPlayer}
                  onLoadRole={this.loadRoles}
                />
              )}
              {this.state.mode === "Designation" && (
                <PlayerDesignationsMapper
                  designations={this.state.designations}
                  curPlr={this.state.curPlayer}
                  onLoadDesignation={this.loadDesignations}
                />
              )}
              {this.state.mode === "Features" && (
                <PlayerFeaturesMapper
                  features={this.state.features}
                  curPlr={this.state.curPlayer}
                />
              )}
              {this.state.mode === "Features" && (
                <PlayerMenusMapper
                  menus={this.state.menus}
                  curPlr={this.state.curPlayer}
                />
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default AssignPlayer;
