import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import {
  setTicketActions,
  setTicketActionsNew,
  phoneCall,
  phoneCallInfo,
} from "./../../../services/Tickets/TicketProcessServices";
import { ticketListReload } from "./../../../store";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { IstToUTC } from "../../../utils/DateUtils";
import HoProcess from "./HoProcess";
import { setTicketActionsTicketCancel } from "./../../../services/Tickets/TicketProcessServices";
import swal from "sweetalert";
class AptSchedular extends FormLib {
  state = {
    data: {
      assignToHo: "no",
      callStatus: "",
      appointmentDate: IstToUTC(new Date()).slice(0, 16).replace(" ", "T"),
      remarks: "null",
    },

    callStatus: [
      {
        callStatusId: "AVAILABLE",
        callStatus: "Available",
      },
      {
        callStatusId: "RESCHEDULE",
        callStatus: "Reschedule",
      },
      {
        callStatusId: "REJECT_SERVICE",
        callStatus: "Reject Service",
      },
      {
        callStatusId: "INVALID_NO",
        callStatus: "Invalid No",
      },
      {
        callStatusId: "RINGING_NOT_PICKING",
        callStatus: "Ringing Not Picking",
      },
      {
        callStatusId: "CALL_BACK_LATER",
        callStatus: "Call Back Later",
      },
    ],

    errors: {},
    curticketDetails: {},
    curblockDetails: {},
    curProductDetails: {},
    pageHide: "false",
    allowAptFix: "false",
    hideForm: "false",
    aptStatus: "false",
    remarksOption: "false",
    cancelStatus: "false",
    contactStatus: "false",
  };
  rescheduleBy = [
    { sortKey: "Customer", sort: "Customer" },
    { sortKey: "Bizlog", sort: "Bizlog" },
    { sortKey: "Client", sort: "Client" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  includeCancelTicket = [
    { sortKey: "yes", sort: "Do you want to cancel Ticket??" },
  ];
  assignToHo = [
    { request_typeId: "yes", assignToHo: "Yes" },
    { request_typeId: "no", assignToHo: "No" },
  ];

  schema = {
    assignToHo: Joi.string().optional().label("Assign To Ho"),
    callStatus: Joi.string().required().label("Call Status"),
    appointmentDate: Joi.date()
      .min("now")
      .required()
      .label("Appointment Date")
      .error(() => {
        return {
          message:
            "Value must be " +
            IstToUTC(new Date()).slice(0, 19).replace("T", " ") +
            " or later",
        };
      }),
    remarks: Joi.string().required().label("remarks"),
    includeCancelTicket: Joi.string().optional().label("includeCancelTicket"),
    rescheduleBy: Joi.string().optional().label("rescheduleBy"),
  };

  componentDidMount() {
    try {
      this.getlocalDateTime();
      this.loadInit();
      let pageHide = this.props.hideAction;
      this.setState({ pageHide });
      this.setState({ curStatus: this.props.curStatus });
      let hoForm = localStorage.getItem("curHub");
      if (hoForm === "1lUeT6ivx3d1DXPKW74SdKyLQrM=") {
        this.setState({ hideForm: "true" });
      } else if (
        (this.props.blockDetails.blockStatus === "LINEHAUL" ||
          this.props.blockDetails.blockStatus === "LINEHAUL_IN_TRANSIT") &&
        hoForm !== "1lUeT6ivx3d1DXPKW74SdKyLQrM="
      ) {
        this.setState({ pageHide: "true" });
      } else {
        this.setState({ hideForm: "false" });
      }
    } catch {
      console.log("Error in AptScheduler>>>componentDidMount");
    }
  }
  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }

  handleCancelTicket = async () => {
    swal({
      title: "Are you sure want to Cancel the Ticket?",
      text: "Kindly Enter the Reason for Ticket Cancellation",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: "input",
      placeholder: "Write something",
    }).then(async (handleCancelTicket) => {
      this.doCancelTicket(handleCancelTicket);
    });
  };

  doCancelTicket = async (handleCancelTicket) => {
    if (handleCancelTicket) {
      // alert(handleRTO)
      swal(
        "Ticket has been successfully Cancelled with the following remarks: " +
          handleCancelTicket,
        {
          icon: "success",
        }
      );
      this.submitCancelTicket(handleCancelTicket);
    } else {
      swal("Ticket not cancelled!", { icon: "error" });
    }
  };

  submitCancelTicket = async (handleCancelTicket) => {
    let resultSet = {
      remarks: handleCancelTicket,
      actionStatus: "OPS_CANCALLATION",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    let data = this.state.data;
    data = {
      actionCode: "wOPSCancelled",
      action: "Ticket Cancelled",
    };
    this.setState({ data });

    let resp = await setTicketActionsTicketCancel(
      this.state.data,
      this.state.curticketDetails,
      this.state.curblockDetails,
      resultSet
    );
    if (resp !== undefined) {
      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ cancelStatus: "true" });
          // this.setState({ rtoStatus: "false" });
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
  };

  loadInit() {
    try {
      // alert(JSON.stringify(this.props.blockDetails));

      const curticketDetails = this.props.ticketDetails;
      const curblockDetails = this.props.blockDetails;
      const curProductDetails = this.props.ticketDetails.products;
      this.setState({ curticketDetails });
      this.setState({ curblockDetails });
      this.setState({ curProductDetails });
      let hoForm = localStorage.getItem("curHub");
      if (
        curblockDetails.blockStatus === "LINEHAUL" &&
        hoForm !== "1lUeT6ivx3d1DXPKW74SdKyLQrM="
      ) {
        this.setState({ pageHide: "true" });
      }
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.setState({ designation: userInfo.designation });
    } catch {
      console.log("Error in AptScheduler>>>loadInit");
    }
  }

  async doSubmit() {
    try {
      if (
        !this.state.data.rescheduleBy &&
        this.state.data.callStatus === "Reschedule" &&
        this.state.curticketDetails.retailerCode === "CPL"
      ) {
        swal({
          icon: "error",
          title: "Please select Reschedule by option",
          dangerMode: true,
        }).then((res) => {
          const resByCustRadio = document.getElementById("byCustomer");
          resByCustRadio.focus();
        });
        return null;
      }

      let override = { ...this.state.data };
      override["actionCode"] = "wAptmFixing";
      override["action"] = "Fixing Appointment with Consumer";
      // this.setState({ override });
      if (this.state.data.callStatus === "INVALID_NO") {
        let resultSet = {
          assignToHo: "yes",
          callStatus: this.state.data.callStatus,
          appointmentDate: this.state.data.appointmentDate,
          actionStatus: this.state.data.callStatus,
          remarks: this.state.data.remarks,
          processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        };
        if (
          this.state.curticketDetails.flowId === "BulkQC" ||
          this.state.curticketDetails.flowId === "Bulk" ||
          this.state.curticketDetails.flowId === "DropOff" 
        ) {
          let resp = await setTicketActions(
            override,
            this.state.curticketDetails,
            this.state.curblockDetails,
            resultSet
          );

          if (resp.data.success === true) {
            if (resp.data.data) {
              this.handleTicketListReload();
              this.setState({ aptStatus: "true" });
            } else {
              toast.warning(() => <div>{resp.data.msg}</div>);
            }
          } else {
            toast.error(() => <div>{resp.data.msg}</div>);
          }
        } else {
          let resp = await setTicketActions(
            override,
            this.state.curticketDetails,
            this.state.curblockDetails,
            resultSet
          );

          if (resp.data.success === true) {
            if (resp.data.data) {
              this.handleTicketListReload();
              this.setState({ aptStatus: "true" });
            } else {
              toast.warning(() => <div>{resp.data.msg}</div>);
            }
          } else {
            toast.error(() => <div>{resp.data.msg}</div>);
          }
        }
      } else {
        let resultSet = {
          assignToHo: this.state.data.assignToHo,
          callStatus: this.state.data.callStatus,
          appointmentDate: this.state.data.appointmentDate,
          actionStatus: this.state.data.callStatus,
          remarks:
            this.state.data.remarks + " :: " + this.state.data.rescheduleBy,
          processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        };
        if (
          this.state.curticketDetails.flowId === "BulkQC" ||
          this.state.curticketDetails.flowId === "Bulk" ||
          this.state.curticketDetails.flowId === "DropOff" 

        ) {
          let resp = await setTicketActions(
            override,
            this.state.curticketDetails,
            this.state.curblockDetails,
            resultSet
          );

          if (resp.data.success === true) {
            if (resp.data.data) {
              this.handleTicketListReload();
              this.setState({ aptStatus: "true" });
            } else {
              toast.warning(() => <div>{resp.data.msg}</div>);
            }
          } else {
            toast.error(() => <div>{resp.data.msg}</div>);
          }
        } else {
          let resp = await setTicketActions(
            override,
            this.state.curticketDetails,
            this.state.curblockDetails,
            resultSet
          );

          if (resp.data.success === true) {
            if (resp.data.data) {
              this.handleTicketListReload();
              this.setState({ aptStatus: "true" });
            } else {
              toast.warning(() => <div>{resp.data.msg}</div>);
            }
          } else {
            toast.error(() => <div>{resp.data.msg}</div>);
          }
        }
      }
    } catch {
      console.log("Error in AptScheduler>>>doSubmit");
    }
  }

  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };

  handleCall = async () => {
    try {
      let selectedHub = localStorage.getItem("curHub");
      let toContactPersonNo = "";
      let fromContactPerson = "";

      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      fromContactPerson = userInfo.phoneNo;

      if (
        ((this.state.curblockDetails.flowStatus === "DROP_WEB" &&
          this.state.curblockDetails.direction === "DTD") ||
          (this.state.curblockDetails.flowStatus === "PICK_WEB" &&
            this.state.curblockDetails.direction === "RTO")) &&
        this.state.curticketDetails.flowId !== "AdvanceExchange"
      ) {
        toContactPersonNo = this.state.curProductDetails[0].dstContact1;
      } else if (
        ((this.state.curblockDetails.flowStatus === "PICK_WEB" &&
          this.state.curblockDetails.direction === "DTD") ||
          (this.state.curblockDetails.flowStatus === "DROP_WEB" &&
            this.state.curblockDetails.direction === "RTO")) &&
        this.state.curticketDetails.flowId !== "AdvanceExchange"
      ) {
        toContactPersonNo = this.state.curProductDetails[0].srcContact1;
      } else if (
        ((this.state.curblockDetails.flowStatus === "DROP_WEB" &&
          this.state.curblockDetails.direction === "DTD") ||
          (this.state.curblockDetails.flowStatus === "PICK_WEB" &&
            this.state.curblockDetails.direction === "RTO")) &&
        this.state.curticketDetails.flowId === "AdvanceExchange"
      ) {
        toContactPersonNo = this.state.curProductDetails[1].dstContact1;
      } else if (
        ((this.state.curblockDetails.flowStatus === "PICK_WEB" &&
          this.state.curblockDetails.direction === "DTD") ||
          (this.state.curblockDetails.flowStatus === "DROP_WEB" &&
            this.state.curblockDetails.direction === "RTO")) &&
        this.state.curticketDetails.flowId === "AdvanceExchange"
      ) {
        toContactPersonNo = this.state.curProductDetails[1].srcContact1;
      }

      let params = {
        fromNO: fromContactPerson,
        toNo: toContactPersonNo,
        hubId: selectedHub,
        ticketNo: this.state.curticketDetails.ticketNo,
        blockStatus: this.state.curblockDetails.blockStatus,
        processId: this.state.curblockDetails.processId,
        blockId: this.state.curblockDetails.ticketBlockId,
      };

      let resp = await phoneCall(params);

      if (resp.data.success === true) {
        resp.data && toast.success(() => <div>{resp.data.msg}</div>);
        !resp.data && toast.warning(() => <div>{resp.data.msg}</div>);
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in AptScheduler>>>handleCall");
    }
  };

  handleAlternateCall = async () => {
    try {
      let selectedHub = localStorage.getItem("curHub");
      let toContactPersonNo = "";
      let fromContactPerson = "";

      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      fromContactPerson = userInfo.phoneNo;

      if (fromContactPerson === null) {
        toast.error("Alternate Phone Number of Coordinator is not Available");
      }

      if (
        ((this.state.curblockDetails.flowStatus === "DROP_WEB" &&
          this.state.curblockDetails.direction === "DTD") ||
          (this.state.curblockDetails.flowStatus === "PICK_WEB" &&
            this.state.curblockDetails.direction === "RTO")) &&
        this.state.curticketDetails.flowId !== "AdvanceExchange"
      ) {
        toContactPersonNo = this.state.curProductDetails[0].dstContact2;
      } else if (
        ((this.state.curblockDetails.flowStatus === "PICK_WEB" &&
          this.state.curblockDetails.direction === "DTD") ||
          (this.state.curblockDetails.flowStatus === "DROP_WEB" &&
            this.state.curblockDetails.direction === "RTO")) &&
        this.state.curticketDetails.flowId !== "AdvanceExchange"
      ) {
        toContactPersonNo = this.state.curProductDetails[0].srcContact2;
      } else if (
        ((this.state.curblockDetails.flowStatus === "DROP_WEB" &&
          this.state.curblockDetails.direction === "DTD") ||
          (this.state.curblockDetails.flowStatus === "PICK_WEB" &&
            this.state.curblockDetails.direction === "RTO")) &&
        this.state.curticketDetails.flowId === "AdvanceExchange"
      ) {
        toContactPersonNo = this.state.curProductDetails[1].dstContact2;
      } else if (
        ((this.state.curblockDetails.flowStatus === "PICK_WEB" &&
          this.state.curblockDetails.direction === "DTD") ||
          (this.state.curblockDetails.flowStatus === "DROP_WEB" &&
            this.state.curblockDetails.direction === "RTO")) &&
        this.state.curticketDetails.flowId === "AdvanceExchange"
      ) {
        toContactPersonNo = this.state.curProductDetails[1].srcContact2;
      }

      if (toContactPersonNo === null) {
        toast.error(
          "Alternate Phone Number of Destination Contact Person is not Available"
        );
      }

      if (fromContactPerson !== null && toContactPersonNo !== null) {
        let params = {
          fromNO: fromContactPerson,
          toNo: toContactPersonNo,
          hubId: selectedHub,
          ticketNo: this.state.curticketDetails.ticketNo,
          blockStatus: this.state.curblockDetails.blockStatus,
          processId: this.state.curblockDetails.processId,
          blockId: this.state.curblockDetails.ticketBlockId,
        };

        let resp = await phoneCall(params);

        if (resp.data.success === true) {
          resp.data && toast.success(() => <div>{resp.data.msg}</div>);
          !resp.data && toast.warning(() => <div>{resp.data.msg}</div>);
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      }
    } catch {
      console.log("Error in AptScheduler>>>handleAlternateCall");
    }
  };

  localHandleChange = (e) => {
    try {
      if (e.target.name === "callStatus") {
        if (e.target.value === "AVAILABLE" || e.target.value === "RESCHEDULE") {
          this.state.allowAptFix = "true";
        } else {
          this.state.allowAptFix = "false";
          this.schema.appointmentDate = Joi.date()
            .optional()
            .label("Appointment Date");
        }
      }
      if (e.target.name === "callStatus") {
        if (e.target.value !== "AVAILABLE") {
          this.state.remarksOption = "true";
        } else {
          this.state.data.remarks = "";
          this.state.remarksOption = "false";
        }
      }
    } catch {
      console.log("Error in AptScheduler>>>localHandleChange");
    }
  };

  handleContactInfo = async (e) => {
    try {
      swal({
        title: "Are you sure want to fetch Contact Details ?",
        text: "Kindly Enter the Reason ",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: "input",
        placeholder: "Write something",
      }).then(async (handleContactInfo) => {
        this.doContactInfo(handleContactInfo, e);
      });
    } catch {
      console.log("Error in AptScheduler>>>>>>handleContactInfo");
    }
  };

  doContactInfo = async (handleContactInfo, e) => {
    try {
      if (handleContactInfo) {
        swal(
          "Contact Details Requested Successfully with the following remarks: " +
            handleContactInfo,
          {
            icon: "success",
          }
        );
        this.submitContactInfo(handleContactInfo, e);
      } else {
        swal("Contact Details Requested Cancelled!", { icon: "error" });
      }
    } catch {
      console.log("Error in AptSchedular>>>>>>handleContactInfo");
    }
  };

  submitContactInfo = async (handleContactInfo, e) => {
    try {
      let toContactPersonNo = "";
      let fromContactPerson = "";

      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      fromContactPerson = userInfo.phoneNo;

      if (fromContactPerson === null) {
        toast.error("Alternate Phone Number of Coordinator is not Available");
      }
      if (e === "contact1") {
        if (
          ((this.state.curblockDetails.flowStatus === "DROP_WEB" &&
            this.state.curblockDetails.direction === "DTD") ||
            (this.state.curblockDetails.flowStatus === "PICK_WEB" &&
              this.state.curblockDetails.direction === "RTO")) &&
          this.state.curticketDetails.flowId !== "AdvanceExchange"
        ) {
          toContactPersonNo = this.state.curProductDetails[0].dstContact1;
        } else if (
          ((this.state.curblockDetails.flowStatus === "PICK_WEB" &&
            this.state.curblockDetails.direction === "DTD") ||
            (this.state.curblockDetails.flowStatus === "DROP_WEB" &&
              this.state.curblockDetails.direction === "RTO")) &&
          this.state.curticketDetails.flowId !== "AdvanceExchange"
        ) {
          toContactPersonNo = this.state.curProductDetails[0].srcContact1;
        } else if (
          ((this.state.curblockDetails.flowStatus === "DROP_WEB" &&
            this.state.curblockDetails.direction === "DTD") ||
            (this.state.curblockDetails.flowStatus === "PICK_WEB" &&
              this.state.curblockDetails.direction === "RTO")) &&
          this.state.curticketDetails.flowId === "AdvanceExchange"
        ) {
          toContactPersonNo = this.state.curProductDetails[1].dstContact1;
        } else if (
          ((this.state.curblockDetails.flowStatus === "PICK_WEB" &&
            this.state.curblockDetails.direction === "DTD") ||
            (this.state.curblockDetails.flowStatus === "DROP_WEB" &&
              this.state.curblockDetails.direction === "RTO")) &&
          this.state.curticketDetails.flowId === "AdvanceExchange"
        ) {
          toContactPersonNo = this.state.curProductDetails[1].srcContact1;
        }
        this.setState({ toContactPersonNo });
      } else {
        if (
          ((this.state.curblockDetails.flowStatus === "DROP_WEB" &&
            this.state.curblockDetails.direction === "DTD") ||
            (this.state.curblockDetails.flowStatus === "PICK_WEB" &&
              this.state.curblockDetails.direction === "RTO")) &&
          this.state.curticketDetails.flowId !== "AdvanceExchange"
        ) {
          toContactPersonNo = this.state.curProductDetails[0].dstContact2;
        } else if (
          ((this.state.curblockDetails.flowStatus === "PICK_WEB" &&
            this.state.curblockDetails.direction === "DTD") ||
            (this.state.curblockDetails.flowStatus === "DROP_WEB" &&
              this.state.curblockDetails.direction === "RTO")) &&
          this.state.curticketDetails.flowId !== "AdvanceExchange"
        ) {
          toContactPersonNo = this.state.curProductDetails[0].srcContact2;
        } else if (
          ((this.state.curblockDetails.flowStatus === "DROP_WEB" &&
            this.state.curblockDetails.direction === "DTD") ||
            (this.state.curblockDetails.flowStatus === "PICK_WEB" &&
              this.state.curblockDetails.direction === "RTO")) &&
          this.state.curticketDetails.flowId === "AdvanceExchange"
        ) {
          toContactPersonNo = this.state.curProductDetails[1].dstContact2;
        } else if (
          ((this.state.curblockDetails.flowStatus === "PICK_WEB" &&
            this.state.curblockDetails.direction === "DTD") ||
            (this.state.curblockDetails.flowStatus === "DROP_WEB" &&
              this.state.curblockDetails.direction === "RTO")) &&
          this.state.curticketDetails.flowId === "AdvanceExchange"
        ) {
          toContactPersonNo = this.state.curProductDetails[1].srcContact2;
        }
        this.setState({ toContactPersonNo });
      }

      let selectedHub = localStorage.getItem("curHub");
      let params = {
        hubId: selectedHub,
        ticketNo: this.state.curticketDetails.ticketNo,
        blockStatus: this.state.curblockDetails.blockStatus,
        processId: this.state.curblockDetails.processId,
        blockId: this.state.curblockDetails.ticketBlockId,
        contact: e,
        remarks: handleContactInfo,
      };

      let resp = await phoneCallInfo(params);

      if (resp.data.success === true) {
        resp.data && toast.success(() => <div>{resp.data.msg}</div>);
        !resp.data && toast.warning(() => <div>{resp.data.msg}</div>);
        this.setState({ contactStatus: "true" });
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in submitContactInfo");
    }
  };

  render() {
    const ticketDetails = this.state.curticketDetails;

    return (
      <>
        <div className="row mt-4 ">
          {this.state.hideForm === "false" &&
            this.state.pageHide === "false" &&
            this.state.aptStatus === "false" &&
            this.state.cancelStatus === "false" && (
              <>
                <div id="coAction" className="col-md-12">
                  <div className="form-subHeader">
                    <b>{this.state.curticketDetails.ticketNo}</b>
                    {this.state.designation === "Operations Head" && (
                      <div
                        className="pull-right"
                        style={{ marginTop: "-1%", marginRight: "23%" }}
                      >
                        {this.rCBHL(
                          "includeCancelTicket",
                          this.includeCancelTicket,
                          {
                            value: "sortKey",
                            label: "sort",
                          }
                        )}
                      </div>
                    )}
                    {this.state.data.includeCancelTicket === "yes" && (
                      <div
                        className="pull-right"
                        style={{ marginTop: "1%", marginRight: "-40%" }}
                      >
                        <button
                          title="Cancel Ticket"
                          className="badge badge-danger  pull-right"
                          type="button"
                          onClick={() => this.handleCancelTicket()}
                          style={{
                            fontSize: "82%",
                            borderRadius: "2.25rem ",
                            border: "none",
                            fontWeight: "bold",
                            marginRight: "6px",
                          }}
                        >
                          CANCEL TICKET
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="card ">
                    <div className="card-body  bg-light">
                      <div className="col-md-12 ">
                        <span
                          className="fa-stack fa-lg pull-left"
                          title="Primary Number"
                        >
                          <i
                            className="fa fa-phone fa-stack-2x cursorPointor"
                            style={{ color: "blue" }}
                            onClick={this.handleCall}
                          ></i>
                        </span>

                        {(this.state.curProductDetails &&
                          this.state.curblockDetails.flowStatus ===
                            "DROP_WEB" &&
                          this.state.curProductDetails[0].dstContact2 !== "") ||
                          (this.state.curProductDetails &&
                            this.state.curblockDetails.flowStatus ===
                              "PICK_WEB" &&
                            this.state.curProductDetails[0].srcContact2 !==
                              "" && (
                              <>
                                <span
                                  className="fa-stack fa-lg pull-left"
                                  title="Alternate Number"
                                >
                                  <i
                                    className="fa fa-phone fa-stack-2x cursorPointor primary"
                                    style={{ color: "green" }}
                                    onClick={this.handleAlternateCall}
                                  ></i>
                                </span>
                              </>
                            ))}
                        <span
                          className=" pull-left mt-5"
                          title="Primary Number"
                        >
                          <button
                            onClick={() => this.handleContactInfo("contact1")}
                            className={"badge badge-primary "}
                            title="Primary Number"
                            style={{
                              fontSize: "73%",
                              borderRadius: "2.25rem ",
                              border: "none",
                              fontWeight: "normal",
                              marginLeft: "-55px ",
                            }}
                          >
                            Primary No.
                          </button>
                        </span>
                        {(this.state.curProductDetails &&
                          this.state.curblockDetails.flowStatus ===
                            "DROP_WEB" &&
                          this.state.curProductDetails[0].dstContact2 !== "") ||
                          (this.state.curProductDetails &&
                            this.state.curblockDetails.flowStatus ===
                              "PICK_WEB" &&
                            this.state.curProductDetails[0].srcContact2 !==
                              "" && (
                              <>
                                <span
                                  className=" pull-left mt-5 mr-3"
                                  title="Alternative Number"
                                >
                                  <button
                                    onClick={() =>
                                      this.handleContactInfo("contact2")
                                    }
                                    className={"badge badge-success "}
                                    title="Alternative Number"
                                    style={{
                                      fontSize: "73%",
                                      borderRadius: "2.25rem ",
                                      border: "none",
                                      fontWeight: "normal",
                                      marginLeft: "3px ",
                                    }}
                                  >
                                    Alternative No.
                                  </button>
                                </span>
                              </>
                            ))}
                        <span className="pull-left mt-5" title="Primary Number">
                          <i
                            style={{
                              fontWeight: "bold",
                              backgroundColor: "#dbd6d6",
                              marginTop: "15px",
                            }}
                          >
                            {this.state.toContactPersonNo}
                          </i>
                        </span>
                      </div>

                      <form onSubmit={this.handleSubmit}>
                        <div
                          className="form-row pt-2"
                          style={{ marginLeft: "14%" }}
                        >
                          {this.rSB(
                            "Call Status *",
                            "callStatus",
                            this.state.callStatus,
                            { value: "callStatusId", label: "callStatus" },
                            " col-md-3 "
                          )}

                          {/* {this.rCBL("includeDstHub", this.includeDstHub, {
                                                value: "sortKey",
                                                label: "sort",
                                            })} */}
                          {/* {this.state.data.callStatus !== 'AVAILABLE' && this.rRBL(
                      "Assign to Ho *",
                      "assignToHo",
                      this.assignToHo,
                      {
                        value: "request_typeId",
                        label: "assignToHo",
                      },
                      "col-md-3"
                    )} */}
                          {this.state.allowAptFix === "true" &&
                            this.rDTF(
                              "Appointment Date *",
                              "appointmentDate",
                              "col-md-3"
                            )}
                          {this.state.remarksOption === "true" &&
                            this.rTAR("Remarks *", "remarks", "col-md-3")}

                          {this.state.data.callStatus === "RESCHEDULE" &&
                            this.state.curticketDetails.retailerCode ===
                              "CPL" && (
                              <>
                                {this.rRBL(
                                  "RescheduleBy*",
                                  "rescheduleBy",
                                  this.rescheduleBy,
                                  this.stsConf
                                )}
                              </>
                            )}
                        </div>
                        {/* {this.state.data.callStatus === "RESCHEDULE" &&
                          this.state.curticketDetails.retailerCode ===
                            "CPL" && (
                            <div
                              className="form-row m-0 p-0 ml-5 mb-3 mt-3"
                              onChange={(e) =>
                                this.setState({ rescheduleBy: e.target.value })
                              }
                            >
                              <p
                                // className="d-inline-block m-0 p-0 mr-0 mb-4 ml-5"
                                style={{ marginLeft: "110px" }}
                              >
                                Reschedule by:
                              </p>
                              <div class="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  name="rescheduleBy"
                                  type="radio"
                                  id="byCustomer"
                                  checked={
                                    this.state.rescheduleBy === "Customer"
                                  }
                                  value="Customer"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="byCustomer"
                                >
                                  Customer
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  name="rescheduleBy"
                                  type="radio"
                                  id="byBizlog"
                                  checked={this.state.rescheduleBy === "Bizlog"}
                                  value="Bizlog"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="byBizlog"
                                >
                                  Bizlog
                                </label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  name="rescheduleBy"
                                  type="radio"
                                  id="byClient"
                                  checked={this.state.rescheduleBy === "Client"}
                                  value="Client"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="byClient"
                                >
                                  Client
                                </label>
                              </div>
                            </div>
                          )} */}
                        {this.rBtnLP(
                          "Submit",
                          "Submit",
                          "btn btn-primary btn-sm pull-right"
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
        {/* {this.state.hideForm === "false" && this.state.pageHide === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Appointment Process
            </p>
          </div>
        )} */}

        {this.state.aptStatus === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Ticket No: <b>{ticketDetails.ticketNo}</b> Initiate Process
              Completed Successfully
            </p>
          </div>
        )}

        {this.state.hideForm === "true" && (
          <HoProcess
            curticketDetails={this.state.curticketDetails}
            curblockDetails={this.state.curblockDetails}
            curProductDetails={this.state.curProductDetails}
            hideForm={this.state.hideForm}
          />
        )}
        {/* {this.state.hideForm === "true" && (
          <button
            className="btn  btn-primary  btn-sm pull-right"
            onClick={() => this.handleTicketListReload()}
          >
            Move to Coordinator
          </button>
        )} */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AptSchedular);
