import { combineReducers } from "@reduxjs/toolkit";
import usersReducer from "./user/userReducer";
import {
  countryReducer,
  stateReducer,
  cityReducer,
} from "./location/locationReducer";

export default combineReducers({
  users: usersReducer,
  countries: countryReducer,
  states: stateReducer,
  cities: cityReducer,
});
