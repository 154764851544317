import React from "react";
import { connect } from "react-redux";
import FormLib from "./../../_common/FormLib";
import TicketsList from "./TicketsList";
import TicketsListNew from "./TicketsListNew";
import TicketActions from "./TicketActions";
import AptSchedular from "./AptSchedular";
import CustomerAvailability from "./CustomerAvailability";
import FeAssignment from "./FeAssignment";
import BlockCreation from "./BlockCreation";
import PackingAssignment from "./PackingAssignment";
import PickUpNew from "./PickUpNew";
import Drop from "./Drop";
import TicketClosure from "./TicketClosure";
import OutForProcess from "./OutForProcess";
import FilesView from "./FilesView";
import { resheduleFENull } from "../../../services/Tickets/TicketProcessServices";
import swal from "sweetalert";

import { ticketListReload } from "../../../store";
import FeAssignmentNew from "./FeAssignmentNew";
import { toast } from "react-toastify";
import { setTicketActionsTicketCancel } from "./../../../services/Tickets/TicketProcessServices";
import { IstToUTC } from "../../../utils/DateUtils";
class ticketProcessWrapperNew extends FormLib {
  state = {
    data: { includeCancelTicket: "no" },

    curAction: "",
    curTicket: {},
    curBlock: {},
    curMultiTicket: {},
    curMultiBlock: {},
    ticketEnabled: "false",

    errors: {},
  };

  includeCancelTicket = [
    { sortKey: "yes", sort: "Do you want to cancel Ticket??" },
  ];
  componentDidMount() {
    this.handleTicketListReload();
    this.getlocalDateTime();
  }

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }

  handleCancelTicket = async () => {
    swal({
      title: "Are you sure want to Cancel the Ticket?",
      text: "Kindly Enter the Reason for Ticket Cancellation",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: "input",
      placeholder: "Write something",
    }).then(async (handleCancelTicket) => {
      this.doCancelTicket(handleCancelTicket);
    });
  };

  doCancelTicket = async (handleCancelTicket) => {
    if (handleCancelTicket) {
      // alert(handleRTO)
      swal(
        "Ticket has been successfully Cancelled with the following remarks: " +
          handleCancelTicket,
        {
          icon: "success",
        }
      );
      this.submitCancelTicket(handleCancelTicket);
    } else {
      swal("Ticket not cancelled!", { icon: "error" });
    }
  };

  submitCancelTicket = async (handleCancelTicket) => {
    let resultSet = {
      comments: handleCancelTicket,
      actionStatus: "OPS_CANCALLATION",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    let data = this.state.data;
    data = {
      actionCode: "wOPSCancelled",
      action: "Ticket Cancelled",
    };
    this.setState({ data });

    let resp = await setTicketActionsTicketCancel(
      this.state.data,
      this.state.curTicket,
      this.state.curBlock,
      resultSet
    );
    // if (resp !== undefined) {
    //     if (resp.data.success === true) {
    //         if (resp.data.data) {
    //             this.handleTicketListReload();
    //             this.setState({ pageHide: "true" });
    //             this.setState({ closeStatus: "true" });
    //             this.setState({ rtoStatus: "RTO INITIATED" });
    //             // this.setState({ rtoStatus: "false" });
    //         } else {
    //             toast.warning(() => <div>{resp.data.msg}</div>);
    //         }
    //     } else {
    //         toast.error(() => <div>{resp.data.msg}</div>);
    //     }
    // }
  };

  handleRequestStatusChange = async (mode) => {
    if (this.state.curAction === "") {
      await this.sleep(1);
      this.setState({ curAction: mode });
    } else {
      this.setState({ curAction: mode });
    }
  };

  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  setCurrentTicket = async (b, t, value) => {
    this.setState({ bulkAssign: value });
    if (value === "notBulk") {
      if (this.state.curTicket.ticketNo === t.ticketNo) {
        this.setState({
          curAction: "",
          curTicket: {},
          curBlock: {},
          ticketEnabled: "true",
        });
        await this.sleep(1);
        this.setState({ curTicket: t, curBlock: b, ticketEnabled: "true" });
        this.setActionDecider(b, t);
      } else {
        this.setState({ curTicket: t, curBlock: b, ticketEnabled: "true" });
        this.setActionDecider(b, t);
      }
    } else {
      this.setState({
        curAction: "",
        ticketEnabled: "true",
      });
      await this.sleep(1);
      this.setState({
        curMultiTicket: t,
        curMultiBlock: b,
        ticketEnabled: "true",
      });
      this.setMultiActionDecider(b, t);
    }
  };

  handleTicketListReload = async () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };

  async setActionDecider(b, t) {
    // console.log("isPackingReq........." + JSON.stringify(t.isPackingReq));
    // console.log(
    //   "isAppointmentReq........." + JSON.stringify(t.isAppointmentReq)
    // );

    try {
      if (
        !t.isAppointmentReq ||
        t.isAppointmentReq === null ||
        t.isAppointmentReq === undefined ||
        t.isAppointmentReq === "null" ||
        t.isAppointmentReq === ""
      ) {
        t.isAppointmentReq = "no";
      } else if (
        !t.isPackingReq ||
        t.isPackingReq === null ||
        t.isPackingReq === undefined ||
        t.isPackingReq === "null" ||
        t.isPackingReq === ""
      ) {
        t.isPackingReq = "no";
      }
      if (
        b.blockStatus === "RESCHEDULE_FE_AT_PICKUP" ||
        b.blockStatus === "RESCHEDULE_FE_AT_DROP"
      ) {
        let params = {
          ticketBlockId: b.ticketBlockId,
        };
        let resp = await resheduleFENull(params);
      }
      // console.log("curBlockDetails........." + JSON.stringify(b.playerFeId));

      if (
        (!b.feAppointmentDateTime ||
          b.feAppointmentDateTime === null ||
          b.feAppointmentDateTime === undefined ||
          b.feAppointmentDateTime === "null" ||
          b.feAppointmentDateTime === "") &&
        (b.blockStatus === "ASSIGNED_FE_FOR_PICKUP" ||
          b.blockStatus === "ASSIGNED_FE_FOR_DROP" ||
          b.blockStatus === "ASSIGNED_FE_FOR_SERVICE_PICKUP" ||
          b.blockStatus === "ASSIGNED_FE_FOR_SERVICE_DROP" ||
          // b.blockStatus === "RESCHEDULE_FE_AT_PICKUP" ||
          // b.blockStatus === "RESCHEDULE_FE_AT_DROP" ||
          b.blockStatus === "CHOOSE_RE_APPOINTMENT_DATE" ||
          b.blockStatus === "ASSIGNED_FE_FOR_SERVICE_DROP") &&
        t.isAppointmentReq.toLowerCase() === "yes" &&
        b.blockStatus !== "APPOINTMENT_FIXED" &&
        t.isAppointmentReq.toLowerCase() === "yes"
      ) {
        this.handleRequestStatusChange("Appointment");
        this.setState({ hideInitiateAction: "false" });
      } else {
        this.setState({ hideInitiateAction: "true" });
      }

      if (
        ((!b.playerFeId ||
          b.playerFeId === null ||
          b.playerFeId === undefined ||
          b.playerFeId === "null" ||
          b.playerFeId === "") &&
          b.blockStatus === "APPOINTMENT_FIXED" &&
          t.isPackingReq.toLowerCase() === "no") ||
        ((!b.playerFeId ||
          b.playerFeId === null ||
          b.playerFeId === undefined ||
          b.playerFeId === "null" ||
          b.playerFeId === "") &&
          b.blockStatus === "APPOINTMENT_FIXED" &&
          t.products[0].prodStatus === "PRODUCT_BARCODE_ASSIGNED") ||
        ((!b.playerFeId ||
          b.playerFeId === null ||
          b.playerFeId === undefined ||
          b.playerFeId === "null" ||
          b.playerFeId === "") &&
          b.blockStatus === "HANDED_OVER_PACKING_MATERIALS") ||
        b.blockStatus === "BLOCK_CREATED" ||
        b.blockStatus === "RESCHEDULE_FE_AT_PICKUP" ||
        b.blockStatus === "RESCHEDULE_FE_AT_DROP" ||
        b.blockStatus === "CHOOSE_RE_APPOINTMENT_DATE" ||
        b.blockStatus.startsWith("IDLE_UNTIL") ||
        b.blockStatus === "IDLE_FOR_15MIN" ||
        b.blockStatus === "ASSIGN_TO_HO" ||
        b.blockStatus === "PART_RECEIVED" ||
        b.blockStatus === "RTO_INITIATE" ||
        b.blockStatus === "RTO_REJECT" ||
        b.blockStatus === "CHOOSE_FE_FOR_DROP" ||
        b.blockStatus === "CHOOSE_FE_FOR_DROP_ASC" ||
        b.blockStatus === "DROP_DONE_ASC" ||
        b.blockStatus === "PICKUP_DONE_ASC"
      ) {
        this.handleRequestStatusChange("Appointment");
        this.setState({ hidePage: "false" });
      } else {
        this.setState({ hidePage: "true" });
      }

      if (
        ((t.products[0].prodStatus !== "PRODUCT_BARCODE_ASSIGNED" &&
          t.isPackingReq.toLowerCase() === "yes" &&
          b.blockStatus === "APPOINTMENT_FIXED" &&
          b.flowStatus === "PICK_WEB" &&
          (t.flowCode !== "INSV1" ||
            (t.flowCode === "INSV1" && t.retailerCode === "DRP")) &&
          b.blockStatus === "APPOINTMENT_FIXED" &&
          t.flowCode !== "DRPOF") ||
          (b.blockStatus === "APPOINTMENT_FIXED" &&
            t.isPackingReq.toLowerCase() === "yes") ||
          ((b.blockStatus === "ASSIGNED_FE_FOR_PICKUP" ||
            b.blockStatus === "ASSIGNED_FE_FOR_DROP" ||
            b.blockStatus === "ASSIGNED_FE_FOR_SERVICE_PICKUP" ||
            b.blockStatus === "ASSIGNED_FE_FOR_SERVICE_DROP" ||
            b.blockStatus === "CHOOSE_RE_APPOINTMENT_DATE") &&
            t.isPackingReq.toLowerCase() === "yes" &&
            t.isAppointmentReq.toLowerCase() === "no") ||
          ((b.blockStatus === "ASSIGNED_FE_FOR_PICKUP" ||
            b.blockStatus === "ASSIGNED_FE_FOR_DROP" ||
            b.blockStatus === "ASSIGNED_FE_FOR_SERVICE_PICKUP" ||
            b.blockStatus === "ASSIGNED_FE_FOR_SERVICE_DROP" ||
            b.blockStatus === "CHOOSE_RE_APPOINTMENT_DATE") &&
            t.isPackingReq.toLowerCase() === "yes" &&
            t.isAppointmentReq.toLowerCase() === "no")) &&
        t.products[0].prodStatus !== "PRODUCT_BARCODE_ASSIGNED"
      ) {
        this.handleRequestStatusChange("Appointment");
        this.setState({ hidePackingAction: "false" });
      } else {
        this.setState({ hidePackingAction: "true" });
      }
      if (
        ((b.playerFeId &&
          b.playerFeId !== "null" &&
          (b.blockStatus === "ASSIGNED_FE_FOR_PICKUP" ||
            b.blockStatus === "ASSIGNED_FE_FOR_DROP") &&
          t.isAppointmentReq.toLowerCase() === "no" &&
          t.isPackingReq.toLowerCase() === "no") ||
          (b.playerFeId &&
            b.playerFeId !== "null" &&
            t.isAppointmentReq.toLowerCase() === "yes" &&
            b.feAppointmentDateTime &&
            t.isPackingReq.toLowerCase() === "no") ||
          (b.playerFeId &&
            b.playerFeId !== "null" &&
            t.isAppointmentReq.toLowerCase() === "no" &&
            t.isPackingReq.toLowerCase() === "yes" &&
            t.products[0].prodStatus === "PRODUCT_BARCODE_ASSIGNED") ||
          (b.playerFeId &&
            b.blockStatus === "HANDED_OVER_PACKING_MATERIALS" &&
            t.isAppointmentReq.toLowerCase() === "yes" &&
            t.isPackingReq.toLowerCase() === "yes") ||
          (b.playerFeId &&
            b.blockStatus === "APPOINTMENT_FIXED" &&
            t.isAppointmentReq.toLowerCase() === "yes" &&
            t.isPackingReq.toLowerCase() === "yes" &&
            t.products[0].prodStatus === "PRODUCT_BARCODE_ASSIGNED") ||
          (b.playerFeId &&
            (b.blockStatus === "ASSIGNED_FE_FOR_PICKUP" ||
              b.blockStatus === "ASSIGNED_FE_FOR_DROP") &&
            t.isAppointmentReq.toLowerCase() === "yes" &&
            b.feAppointmentDateTime &&
            t.isPackingReq.toLowerCase() === "yes" &&
            t.products[0].prodStatus) ||
          (b.playerFeId &&
            (b.blockStatus === "ASSIGNED_FE_FOR_SERVICE_DROP" ||
              b.blockStatus === "ASSIGNED_FE_FOR_SERVICE_PICKUP") &&
            t.isAppointmentReq.toLowerCase() === "yes" &&
            b.feAppointmentDateTime &&
            t.isPackingReq.toLowerCase() === "yes" &&
            t.products[0].prodStatus) ||
          (b.playerFeId &&
            b.playerFeId !== "null" &&
            t.isAppointmentReq.toLowerCase() === "no" &&
            t.isPackingReq.toLowerCase() === "no")) &&
        b.blockStatus !== "BLOCK_CLOSED"
      ) {
        this.handleRequestStatusChange("OutForProcess");
        this.setState({ hideProcessAction: "false" });
      } else {
        this.setState({ hideProcessAction: "true" });
      }

      //--------------------------------------------------------------------------------------------------------------------------------------------//
      if (
        b.blockStatus === "PICKUP_DONE" ||
        b.blockStatus === "PICKUP_CONFIRM" ||
        b.blockStatus === "PICK_UP_DONE_EXCHANGE_SUCCESSFULLY"
      ) {
        this.handleRequestStatusChange("PickUp");
        this.setState({ hidePickUpAction: "false" });
      } else {
        this.setState({ hidePickUpAction: "true" });
      }

      if (b.blockStatus === "DROP_DONE" && t.flowCode !== "DRPOF") {
        this.handleRequestStatusChange("Drop");
        this.setState({ hideDropAction: "false" });
      } else {
        this.setState({ hideDropAction: "true" });
      }

      if (
        (b.blockStatus === "DROP_DONE" && t.flowCode === "DRPOF") ||
        b.blockStatus === "DROP_CONFIRMED" ||
        b.blockStatus === "OLD_PRODUCT_DAMAGED_EXCHANGE_CANCELLED" ||
        b.blockStatus === "PROBLEM_SOLVED_DURING_INITIAL_CALL" ||
        b.blockStatus === "REJECT_SERVICE_FE_AT_PICKUP" ||
        b.blockStatus === "REJECT_SERVICE_FE_AT_DROP" ||
        b.blockStatus === "INSTALL_SERVICE_DONE" ||
        b.blockStatus === "RTO_REQUEST" ||
        b.blockStatus === "RESCHEDULE_REQUEST_FE_AT_PICKUP" ||
        b.blockStatus === "RESCHEDULE_REQUEST_FE_AT_DROP" ||
        b.blockStatus === "EVALUATION_RESULT_DONE"
      ) {
        this.handleRequestStatusChange("Closure");
        this.setState({ hideCloseAction: "false" });
      } else {
        this.setState({ hideCloseAction: "true" });
      }
    } catch {
      console.log("Error in SetDecider");
    }
  }

  setMultiActionDecider(b, t) {
    this.handleRequestStatusChange("Appointment");
    this.setState({ hidePage: "false" });
    this.setState({ hideInitiateAction: "true" });
    this.setState({ hidePackingAction: "true" });
    this.setState({ hidePickUpAction: "true" });
    this.setState({ hideDropAction: "true" });
    this.setState({ hideCloseAction: "true" });
    this.setState({ hideProcessAction: "true" });
  }

  render() {
    return (
      <React.Fragment>
        <div className="pageContent co-pageContent">
          <div className="container-fluid">
            <div className="row">
              {/* <div className="col-4">
                <TicketsList
                  onTicketChange={this.setCurrentTicket}


                  loadCount={
                    this.props.ticketListReloadState.ticketProcess
                      .ticketListReload
                  }
                  key={
                    this.props.ticketListReloadState.ticketProcess
                      .ticketListReload
                  }
                />
              </div> */}
              <div className="col-4">
                <TicketsListNew
                  onTicketChange={this.setCurrentTicket}
                  loadCount={
                    this.props.ticketListReloadState.ticketProcess
                      .ticketListReload
                  }
                  key={
                    this.props.ticketListReloadState.ticketProcess
                      .ticketListReload
                  }
                />
              </div>
              <div className="col-8">
                {/* {this.state.curTicket.ticketNo &&
                                    <div className="form-subHeader">
                                        <b>{this.state.curTicket.ticketNo}</b>
                                        <div style={{ marginTop: "-3%", marginLeft: "213px" }}>

                                            {this.rCBHL("includeCancelTicket", this.includeCancelTicket, {
                                                value: "sortKey",
                                                label: "sort",
                                            })}
                                            {this.state.data.includeCancelTicket === "yes" && (
                                                <button
                                                    title="Cancel Ticket"
                                                    className="badge badge-info  ml-8"
                                                    type="button"
                                                    onClick={() => this.handleCancelTicket()}
                                                    style={{ fontSize: "82%", borderRadius: "2.25rem ", border: "none", fontWeight: "bold", marginRight: "6px" }}
                                                >
                                                    CANCEL TICKET
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                } */}
                {/* <TicketActions key={this.state.curTicket.processId ? this.state.curTicket.processId : 123} curAction={this.state.curAction} onModeChange={this.handleRequestStatusChange} /> */}

                {this.state.curAction === "Block" && (
                  <BlockCreation
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    key={this.state.curBlock.ticketBlockId}
                  />
                )}

                {this.state.curAction === "Appointment" && (
                  <>
                    <AptSchedular
                      ticketDetails={this.state.curTicket}
                      blockDetails={this.state.curBlock}
                      hideAction={this.state.hideInitiateAction}
                      curStatus={this.state.curBlock.blockStatus}
                      key={"apt_" + this.state.curBlock.ticketBlockId}
                    />
                  </>
                )}
                {this.state.curAction === "Appointment" && (
                  <PackingAssignment
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    hideAction={this.state.hidePackingAction}
                    curStatus={this.state.curBlock.blockStatus}
                    key={"pack_" + this.state.curBlock.ticketBlockId}
                  />
                )}

                {this.state.hidePage === "false" &&
                  this.state.curAction === "Appointment" && (
                    <FeAssignmentNew
                      ticketDetails={this.state.curTicket}
                      blockDetails={this.state.curBlock}
                      ticketMultiDetails={this.state.curMultiTicket}
                      blockMultiDetails={this.state.curMultiBlock}
                      curAction={this.state.curAction}
                      hidePage={this.state.hidePage}
                      bulkAssign={this.state.bulkAssign}
                      key={"fe_" + this.state.curBlock.ticketBlockId}
                    />
                  )}

                {this.state.hidePage === "true" &&
                  this.state.curAction === "Appointment" && (
                    <FeAssignmentNew
                      curStatus={this.state.curBlock.blockStatus}
                      hidePage={this.state.hidePage}
                      key={"fe_" + this.state.curBlock.ticketBlockId}
                    />
                  )}

                {this.state.curAction === "PickUp" && (
                  <PickUpNew
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    hideAction={this.state.hidePickUpAction}
                    curStatus={this.state.curBlock.blockStatus}
                    key={"pickUp_" + this.state.curBlock.ticketBlockId}
                  />
                )}

                {this.state.curAction === "Files" && (
                  <FilesView
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    key={
                      "img_" +
                      this.state.curBlock.ticketBlockId +
                      this.state.curBlock.processId
                    }
                  />
                )}

                {this.state.curAction === "Drop" && (
                  <Drop
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    hideAction={this.state.hideDropAction}
                    curStatus={this.state.curBlock.blockStatus}
                    key={"drop_" + this.state.curBlock.ticketBlockId}
                  />
                )}

                {this.state.curAction === "Closure" && (
                  <TicketClosure
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    hideAction={this.state.hideCloseAction}
                    curStatus={this.state.curBlock.blockStatus}
                    key={"close_" + this.state.curBlock.ticketBlockId}
                  />
                )}
                {this.state.curAction === "OutForProcess" && (
                  <OutForProcess
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    hideAction={this.state.hideProcessAction}
                    curStatus={this.state.curBlock.blockStatus}
                    key={"close_" + this.state.curBlock.ticketBlockId}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ticketProcessWrapperNew);
