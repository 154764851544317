import _http from "../_httpService";

import config from "../../config.json";

const apiEndpoint = config[config.build].apiURLMasterLoc + "/pincodes";
const apiPHPEndpoint = config[config.build].apiURLCorePHP + "/pincodes";


export function uploadPincodes(pincode, params) {
  return _http.put(apiEndpoint + "/save/" + pincode, params, _http.headerJWT());
}

export function uploadCusPincodes(pincode, params) {
  return _http.put(
    apiEndpoint + "/pincode-retailer/" + pincode,
    params,
    _http.headerJWT()
  );
}

export function getPincodesList(params, pageNo) {
  return _http.put(apiEndpoint + "/get/200/" + pageNo, params, _http.headerJWT());
}

export function getPincodesFromPHP(pincode) {
  return _http.get(apiPHPEndpoint + "/pincode.php?pin=" + pincode, _http.headerJWT());
}
