import React from "react";

function SelectboxFilter({
  name,
  label,
  error,
  options = [],
  value,
  inline = "",
  ctrls,
  classAppend = "",
  ...rest
}) {
  return (
    <React.Fragment>
      <div className={"form-group " + classAppend}>
        <input name={name} list={options} {...rest} autoComplete="off" />
        <datalist id={options}>
          {options.map((op) => (
            <option
              key={op[ctrls.value]}
              data-value={op[ctrls.value]}
              value={op[ctrls.label]}
            >
              {op[ctrls.label]}
            </option>
          ))}
        </datalist>
        {label && (
          <label htmlFor={name} className="control-label">
            {label}
          </label>
        )}
        <i className="bar"></i>
        {error && <span className="alert alert-danger">{error}</span>}
      </div>
    </React.Fragment>
  );
}

export default SelectboxFilter;
