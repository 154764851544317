import _http from "../_httpService";
import config from "../../config.json";

const baseURL = config[config.build].apiURLPrevilages;

function api(path, subURL = "/designations-roles") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getRoles(req) {
  return _http.get(api(req), _http.headerJWT());
}

export function assign(req){
  const param ={...req};
  return _http.put(api("role/assign"),param, _http.headerJWT());
}
export function unassign(req){
  const param ={...req};
  return _http.put(api("role/un-assign"),param, _http.headerJWT());
}