import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import Table from "../../_common/Table";

import dateFormat from "dateformat";
import {
  createReport,
  getDailyReports,
} from "./../../../services/plms/reportServices";
import _auth from "./../../../services/_authService";

class ModalReport extends FormLib {
  state = {
    data: {
      taskId: "",
      reportFrom: this.getDatebyDays(0) + "T12:00",
      reportTo: this.getDatebyDays(0) + "T12:00",
      reportBy: "",
      remarks: "",
      status: "00open",
    },
    errors: {},
    loading: false,
    reports: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };

  schema = {
    taskId: Joi.string().required().label("taskId"),
    reportFrom: Joi.string().required().label("reportFrom"),
    reportTo: Joi.string().required().label("reportTo"),
    reportBy: Joi.string().required().label("reportBy"),
    remarks: Joi.string().required().label("remarks"),
    status: Joi.string().required().label("status"),
  };
  componentDidMount() {
    this.resetData(this.props.curTask, _auth.getCurrentUser());
    this.getReports();
  }
  async getReports() {
    const user = _auth.getCurrentUser();
    this.setState({ loading: true });
    let resp = await getDailyReports(
      {
        reportFrom: this.getDatebyDays(0),
        reportTo: this.getDatebyDays(0),
        reportBy: user.username || "",
      },
      1
    );
    if (resp !== undefined) {
      if (resp.data.success === true) {
        resp.data.data.reports &&
          this.setState({ reports: resp.data.data.reports });
        resp.data.data.pageLinks &&
          this.setState({ pageLinks: resp.data.data.pageLinks });
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
    this.setState({ loading: false });
  }
  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createReport(this.state.data);
      this.resetData(this.props.curTask, _auth.getCurrentUser());
      this.getReports();
    }

    toast.toastMsg(res);
  }

  resetData(t, user) {
    this.setState({
      data: {
        taskId: t.taskId || "",
        reportFrom: this.getDatebyDays(0) + "T12:00",
        reportTo: this.getDatebyDays(0) + "T12:00",
        reportBy: user.username || "",
        remarks: "",
        status: "00open",
      },
    });
  }

  status = [
    { sortKey: "00open", sort: "Open" },
    { sortKey: "08plan", sort: "Plan" },
    { sortKey: "10wip", sort: "WIP" },
    { sortKey: "15hold", sort: "Hold" },
    { sortKey: "20dev", sort: "Dev" },
    { sortKey: "25test", sort: "Test" },
    { sortKey: "30done", sort: "Done" },
    { sortKey: "99pipe", sort: "Pipe" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  tableMeta = {
    key: "reportId",
    columns: [
      { path: "project", label: "Project" },
      { path: "prModule", label: "Module" },
      { path: "usTitle", label: "US" },
      { path: "taskType", label: "taskType" },
      { path: "task", label: "Task" },

      {
        key: "reportFrom",
        action: (r) => <>{dateFormat(r.reportFrom, "dd-mm-yyyy")}</>,
        label: "reportFrom",
      },
      {
        key: "reportTo",
        action: (r) => <>{dateFormat(r.reportTo, "dd-mm-yyyy")}</>,
        label: "reportTo",
      },

      { path: "status", label: "status" },
      { path: "reportBy", label: "reportBy" },
      { path: "remarks", label: "remarks" },
    ],
  };
  render() {
    const { itemsCount } = this.state.pageLinks;

    return (
      <div className="container-fluid">
        <div className="modal fade" id="myReportModel">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">
                  <span style={{ color: "#ccc" }}>Reporting ::</span>
                  {this.props.curTask.task}
                </h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  {this.rTBH("taskId", "taskId", "col-4")}
                  <div className="form-row">
                    {this.rTB("reportBy", "reportBy", "col-md-3")}

                    {this.rDT("reportFrom", "reportFrom", "col-md-3")}

                    {this.rDT("reportTo", "reportTo", "col-md-3")}
                  </div>
                  <div className="form-row">
                    {this.rRBL("Status", "status", this.status, this.stsConf)}
                  </div>
                  <div className="form-row">
                    {this.rTA8("Report", "remarks", " col-md-12 ")}
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                {this.state.data.taskId !== "" &&
                  this.rBtnSP("Create", "create")}
              </div>
              <div className="row">
                {this.state.loading && (
                  <div className="container-fluid">
                    <div> Loading Reports...</div>
                  </div>
                )}
                {!this.state.loading && itemsCount > 0 && (
                  <div className="container-fluid">
                    <Table
                      clsName="table table-striped table-bordered table-hover"
                      tableMeta={this.tableMeta}
                      data={this.state.reports}
                      dropMenu={[]}
                    />
                  </div>
                )}
                {!this.state.loading && itemsCount === 0 && (
                  <div className="alert alert-info col-12" role="alert">
                    <div className="text-center"> No Records Found! </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalReport;
