import React from "react";
import FormLib from "./../../_common/FormLib";
import DbFields from "./DbFields";

class DbTables extends FormLib {
  state = {
    data: {},
    errors: {},
    curTable: {},
    fields: [],
  };

  getFields = () => {
    this.setState({
      fields: [
        {
          fieldId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7001",
          tableId: "Table 001",
          table: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7dfc",
          field: "FIELD_NAME",
          type: "VARCHAR(45)",
          isNull: "no",
          key: "yes",
          default: "ok",
          extra: "moreInfo",
          details: "details",
          sort: "1.2",
          constrainName: "",
          pFieldId: "",
          pField: "",
          pTable: "",
        },
        {
          fieldId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7002",
          tableId: "Table 002",
          table: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7dfc",
          field: "FIELD_NAME",
          type: "VARCHAR(45)",
          isNull: "yes",
          key: "no",
          default: "ok",
          extra: "",
          details: "details",
          sort: "2.2",
          constrainName: "",
          pFieldId: "",
          pField: "",
          pTable: "",
        },
        {
          fieldId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7003",
          tableId: "Table 003",
          table: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7dfc",
          field: "FIELD_NAME",
          type: "VARCHAR(45)",
          isNull: "no",
          key: "no",
          default: "ok",
          extra: "moreInfo",
          details: "details",
          sort: "3.2",
          constrainName: "abc",
          pFieldId: "dfsdfsdfsd",
          pField: "parentField",
          pTable: "parentTable",
        },
        {
          fieldId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7004",
          tableId: "Table 004",
          table: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7dfc",
          field: "FIELD_NAME",
          type: "VARCHAR(45)",
          isNull: "yes",
          key: "no",
          default: "ok",
          extra: "moreInfo",
          details: "details",
          sort: "4.2",
          constrainName: "",
          pFieldId: "",
          pField: "",
          pTable: "",
        },
        {
          fieldId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7005",
          tableId: "Table 005",
          table: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7dfc",
          field: "FIELD_NAME",
          type: "VARCHAR(45)",
          isNull: "yes",
          key: "no",
          default: "ok",
          extra: "moreInfo",
          details: "details",
          sort: "5.2",
          constrainName: "",
          pFieldId: "",
          pField: "",
          pTable: "",
        },
        {
          fieldId: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7006",
          tableId: "Table 006",
          table: "prev1640758851151TZ63d2e1b1529247509d1f5a0d416d7dfc",
          field: "FIELD_NAME",
          type: "VARCHAR(45)",
          isNull: "yes",
          key: "no",
          default: "ok",
          extra: "moreInfo",
          details: "details",
          sort: "6.2",
          constrainName: "",
          pFieldId: "",
          pField: "",
          pTable: "",
        },
      ],
    });
  };
  unlinkFields = () => {
    this.setState({ fields: [] });
  };

  render() {
    const tables = this.props.tables;
    return (
      <>
        <div
          className=" col-md-3 myTableDrag"
          style={{ top: 50 + this.props.top * 45 }}
        >
          <div className="card">
            <h5 className="card-header">
              {tables.table}
              <span
                className="badge  badge-secondary mr-1 pull-right"
                onClick={() => this.getFields()}
              >
                <i className={"fa  fa-refresh  "}></i>
              </span>
              <span
                className="badge  badge-warning mr-1  pull-right"
                onClick={() => this.unlinkFields()}
              >
                <i className={"fa  fa-unlink  "}></i>
              </span>
              <span
                className="badge  badge-success mr-1  pull-right"
                data-toggle="modal"
                data-target="#myModuleModel"
              >
                <i className={"fa  fa-pencil  "}></i>
              </span>
            </h5>

            {this.state.fields.length > 0 && (
              <DbFields fields={this.state.fields} />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default DbTables;
