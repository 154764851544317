import React from "react";
import FormLib from "./../../../_common/FormLib";
import Table from "./../../../_common/Table";
import Pagination from "./../../../_common/Pagination";
import {
  getIncoming,
  blockStatus,
  updateShipmentNo,
  getLinehaulTicktes,
  updateBarcodeStatus,
  blockStatusv1,
  boxMissing,
  boxDamage,
} from "./../../../../services/linehaul/linehaulServices";
import dateFormat from "dateformat";
import { epoch2ddmmyyyyhhmmss } from "../../../../utils/DateUtils";


import { toast } from "react-toastify";
import Joi from "joi-browser";
import UpdateShipmentNoModal from "./UpdateShipmentNoModal";
import { IstToUTC } from "../../../../utils/DateUtils";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import DocumentDetails from "./actions/DocumentDetails";
import ExpenseRise from "./actions/ExpenseRise";
import LinehaulProdDetails from "../actions/LinehaulProdDetails";
import swal from "sweetalert";
import { GrDocumentMissing } from "react-icons/gr";

export default class IncomingManifest extends FormLib {
  state = {
    incoming: [],
    errors: {},
    data: {
      search: "",
    },
    incomingShipmentsFiltered: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    currentShipment: {},
    documentDetails: false,
    openStatus: "true",
    currentRequest: {},
    curBoxStatus:false,
    linehaulBlockId:"",
    direction:"",
    boxBarcode:""
  };
  schema = {
    search: Joi.optional().label("search"),
  };
  componentDidMount() {
    this.getLinehaulList(this.state.pageLinks.pageNo);
    if (this.props?.refreshListCallback) {
      this.props.refreshListCallback(this.getLinehaulList);
    }
  }

  getLinehaulList = async (pageNo = this.state.pageLinks.pageNo) => {
    try {
      this.setState({ loading: true });
      let selectedHub = localStorage.getItem("curHub");
      let params = {
        destHubId: selectedHub,
        searchString: this.state.data.search,
      };
      let resp = await getIncoming(params, pageNo);
      if (resp.data.success === true) {
        if (resp.data.data.incomingShipment.length !== 0) {
          this.setState({ incoming: resp.data.data.incomingShipment });
          this.setState({
            incomingShipmentsFiltered: resp.data.data.incomingShipment,
          });

          resp.data.data.pageLinks &&
            this.setState({ pageLinks: resp.data.data.pageLinks });
          resp.data.data.incomingShipment &&
            toast.success(() => <div>{resp.data.msg}</div>);
          !resp.data.data.incomingShipment &&
            toast.warning(() => <div>No Incoming Manifests</div>);
        } else {
          toast.info(() => <div>{resp.data.msg}</div>);
          this.state.incomingShipmentsFiltered = [];
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in IncomingManifest>>>>>> localHandleChange");
    }
  };

  handleSaveBarcodeNo = async (barcodeNumber) => {
    try {
      const boxBarcode = this.state.currentShipment.boxBarcode;
      const boxId = this.state.currentShipment.boxId;
      const boxBarcodeId = this.state.currentShipment.boxBarcodeId;
      const ticketLineHaulId = this.state.currentShipment.ticketLineHaulId;
      // console.log(barcodeNumber);
      // console.log(boxBarcode)
      if(barcodeNumber === boxBarcode.toString()){
      const params = {
        boxId: boxId,
        boxBarcodeId:boxBarcodeId,
        boxBarcode: boxBarcode,
        lineHaulId: ticketLineHaulId,
        boxStatus: "lineHaulBoxVerified",
      };
      const resp = await updateBarcodeStatus(params);
      // console.log("ggggggggggggggggg",resp)


      if (resp.data.success === true) {
        this.setState({ showUpdateBarcodeBtn: true });
        toast.success(() => <div>{resp.data.msg}</div>);
        this.getLinehaulList(this.state.pageLinks.pageNo);
        return true;
      }else{        
        toast.error(() => <div>{resp.data.msg}</div>);
        return false;
      }
    }else{
      toast.error(() => "Wrong barcode")
    }
    } catch (error) {
      console.log("ERROR in handleSaveBarcodeNo");
      console.error(error);
    }
  };

  tableMeta = {
    key: "shipmentNo",
    columns: [
      { path: "linehaulSeq", label: "Linehaul Ref No" },
      // { path: "ticketNo", label: "Ticket Number" },
      {
        // ***************************************************dfdfdfd**********************************************************

        // path: "REF_ID",  label: "Ref No."

        key: "ticketNo",
        action: (r) => (
          <>
            <span
              className="badge badge-success link-over"
              onClick={() => this.onloadDocumentDetails(r)}
              title="Click to View/Upload files"
            >
              {/* {r["ticketNo"] && r["ticketNo"].substring(0, 30)}{" "} */}
              {r["multiTicketIds"]
                ? r["multiTicketIds"].substring(0, 30)
                : r["multiTicketIds"]}
            </span>
            <div class="icon-badge-container ">
              <div
                class="icon-badge cursorPointor"
                style={{
                  marginLeft: "96%",
                  marginTop: "-14%",
                  position: "relative",
                  backgroundColor: "#ff6c00",
                }}
                title="Number of Tickets"
              >
                {r["multiTicketIds"] &&(r["multiTicketIds"].split(",").length)}
              </div>
            </div>
          </>
        ),
        icon: () => <>Ticket Number</>,
        label: "#",
      },
      // { path: "blockCode", label: "Ticket Block" },
      // { path: "shipmentNo", label: "Shipment No." },
      {
        key: "ticketCreationDate",
        action: (r) => <>{epoch2ddmmyyyyhhmmss(r.ticketCreationDate)}</>,
        cusClass: " ",
        icon: () => <>Ticket Creation Date</>,
      },
      {
        key: "shipmentTrackingURL",
        action: (r) => {
          if (r.shipmentNo && r.logisticsProvider === "Delhivery") {
            return (
              <>
                <a
                  href={`https://www.delhivery.com/track/lr/${r.shipmentNo}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {r.shipmentNo}
                </a>
              </>
            );
          }
          return <>{r.shipmentNo}</>;
        },
        icon: () => <>LH docket</>,
      },
     
      {
        key: "createdAt",
        action: (r) => <>{epoch2ddmmyyyyhhmmss(r.createdAt)}</>,
        cusClass: " ",
        icon: () => <>Manifest Creation Date</>,
      },
      { path: "transMode", label: "Delivery Type" },
      { path: "srcLocation", label: "Source Location" },
      // { path: "dstLocation", label: "Drop Location" },
      // {
      //   key: "shipmentNo",
      //   actionKey: "sh",
      //   action: (r) => (
      //     <div>
      //       {r.shipmentNo || r["transMode"] === "road" ? (
      //         r.destination !== "customer" && (
      //           <>
      //             <button
      //               className="btn  btn-success  btn-sm link-over"
      //               onClick={() => this.onBlockSelection(r, "05RECEIVED")}
      //             >
      //               Received
      //             </button>
      //             <button
      //               className="btn  btn-danger  btn-sm link-over"
      //               onClick={() => this.onBlockSelection(r, "10DAMAGE")}
      //             >
      //               Damaged
      //             </button>
      //             <button
      //               className="btn  btn-warning  btn-sm link-over"
      //               onClick={() => this.onBlockSelection(r, "15MISSING")}
      //             >
      //               Missing
      //             </button>
      //           </>
      //         )
      //       ) : (
      //         <div className=".flex-column">Shipment No NOT yet updated</div>
      //       )}
      //     </div>
      //   ),
      //   cusClass: " ",
      //   icon: () => <i className="fa fa-cogs" aria-hidden="true"></i>,
      // },
      {
        key: "shipmentNo",
        actionKey: "sh",
        action: (r) => {
          const  result = (r.info !== null && r.info !== "") 
          return (
          <>
          {(result )  ? (
          <>
          {r.shipmentNo && r.transMode !== "road" &&(
            <div>
            {r.boxBarcode &&
            r.boxStatus === "lineHaulBoxManifested"  && 
            r.destination !== "customer" ? (
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div style={{marginTop: 5}}>   
                <input
                  type="text"
                  name={`sh_${r.linehaulSeq}`}
                  id={`sh_${r.linehaulSeq}`}
                  placeholder="Scan Box Barcode"
                  className="shipmentNoInputManifest"
                  onFocus={() => {
                    // console.log({focusOn: r.linehaulSeq})
                    this.setState({ currentShipment: r });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (!e.target.value) return;
                      this.handleSaveBarcodeNo(e.target.value);
                    }
                  }}
                />
                </div>
                <div>
                <p style={{fontSize: 20}}>
                  <GrDocumentMissing 
                    onClick={() => {
                      this.handleDelete(r, "15MISSING")
                    }}
                /></p>
                </div>
              </div>
            ) : (
              <div>
                {(r.boxStatus === "lineHaulBoxVerified" ) ? (
                  <>
                    
                      
                        <>
                          <button
                            className="btn  btn-success  btn-sm link-over"
                            onClick={() =>
                              this.onBlockSelection(r, "05RECEIVED")
                            }
                          >
                            Received
                          </button>
                          {/* <button
                            className="btn  btn-danger  btn-sm link-over"
                            onClick={() => this.handleDamage(r, "10DAMAGE")}
                          >
                            Damaged
                          </button>
                          <button
                            className="btn  btn-warning  btn-sm link-over"
                            onClick={() =>
                              this.handleMissing(r, "15MISSING")
                            }
                          >
                            Missing
                          </button> */}
                        </>
                  </>
                ):(
                  <div>
                    {(( !r.boxBarcodeId ) || r.boxStatus === "lineHaulBoxReceived") &&(
                        <button
                        className="btn  btn-success  btn-sm link-over"
                        onClick={() =>
                        this.onProdBlockSelection(r)
                        }
                       >
                       Scan & Verify
                      </button>
                    )}
                  </div>
                )}

              </div>
            )}
            </div>
         )} 
         {( r.transMode === "road")  &&(
            <div>
            {r.boxBarcode &&
            r.boxStatus === "lineHaulBoxCreated"  && 
            r.destination !== "customer" ? (
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <div style={{marginTop: 5}}>   
                <input
                  type="text"
                  name={`sh_${r.linehaulSeq}`}
                  id={`sh_${r.linehaulSeq}`}
                  placeholder="Scan Box Barcode"
                  className="shipmentNoInputManifest"
                  onFocus={() => {
                    // console.log({focusOn: r.linehaulSeq})
                    this.setState({ currentShipment: r });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (!e.target.value) return;
                      this.handleSaveBarcodeNo(e.target.value);
                    }
                  }}
                />
                </div>
                <div>
                <p style={{fontSize: 20}}>
                  <GrDocumentMissing 
                    onClick={() => {
                      this.handleDelete(r, "15MISSING")
                    }}
                /></p>
                </div>
              </div>
            ) : (
              <div>
                {(r.boxStatus === "lineHaulBoxVerified" ) ? (
                  <>
                    
                      
                        <>
                          <button
                            className="btn  btn-success  btn-sm link-over"
                            onClick={() =>
                              this.onBlockSelection(r, "05RECEIVED")
                            }
                          >
                            Received
                          </button>
                          {/* <button
                            className="btn  btn-danger  btn-sm link-over"
                            onClick={() => this.handleDamage(r, "10DAMAGE")}
                          >
                            Damaged
                          </button>
                          <button
                            className="btn  btn-warning  btn-sm link-over"
                            onClick={() =>
                              this.handleMissing(r, "15MISSING")
                            }
                          >
                            Missing
                          </button> */}
                        </>
                  </>
                ):(
                  <div>
                    {(!r.boxBarcode || r.boxStatus === "lineHaulBoxReceived") &&(
                        <button
                        className="btn  btn-success  btn-sm link-over"
                        onClick={() =>
                        this.onProdBlockSelection(r)
                        }
                       >
                       Scan & Verify
                      </button>
                    )}
                  </div>
                )}

              </div>
            )}
            </div>
         )}
           {!r.shipmentNo &&(
            <div>Shipment not updated</div>
          )} 
            {/* {!r.boxBarcode &&

          <button
           className="btn  btn-success  btn-sm link-over"
          //  onClick={() =>
          //  this.onBlockSelection(r, "05RECEIVED")
          //  }
          >
          Scan & Verify
         </button>

            } */}
          </>
          ):(
            <div>
            {r.shipmentNo || r["transMode"] === "road" ? (
              r.destination !== "customer" && (
                <>
                  <button
                    className="btn  btn-success  btn-sm link-over"
                    onClick={() => this.onBlockSelectionV1(r, "05RECEIVED")}
                  >
                    Received
                  </button>
                  <button
                    className="btn  btn-danger  btn-sm link-over"
                    onClick={() => this.onBlockSelectionV1(r, "10DAMAGE")}
                  >
                    Damaged
                  </button>
                  <button
                    className="btn  btn-warning  btn-sm link-over"
                    onClick={() => this.onBlockSelectionV1(r, "15MISSING")}
                  >
                    Missing
                  </button>
                </>
              )
            ) : (
              <div className=".flex-column">Shipment No NOT yet updated</div>
            )}
          </div>
          )}

          
          </>
        )},
        cusClass: " ",
        icon: () => <i className="fa fa-cogs" aria-hidden="true"></i>,
      },
      { path: "destination", label: "Destination Type" },
      {
        key: "Expense",
        action: (r) => (
          <>
            <div className="d-flex justify-content-center">
              <>
                <i
                  className="fa fa-telegram cursorPointor ="
                  style={{ fontSize: "30px", color: "green" }}
                  onClick={() => this.handleExpenseRise(r)}
                  title="Raise Expense"
                ></i>
              </>
            </div>
          </>
        ),
        icon: () => (
          <div className="d-flex justify-content-center">Raise Expense</div>
        ),
      },
    ],
  };
  onloadDocumentDetails = (r) => {
    this.setState({ documentDetails: "true" });
    this.setState({ openStatus: "false" });
    this.setState({ currentRequest: r });
  };

  handleDamage  = async (r) => {
    let params = {
      boxId:r.boxId,
      boxBarcode:r.boxBarcode,
      boxStatus:"lineHaulBoxDamaged",
      lineHaulId:r.ticketLineHaulId,
      damagedConfirmation:false,
      srcHubId:r.srcHubId,
      dstHubId:r.dstHubId,
      direction:r.direction,
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
      dropDate:IstToUTC(new Date()).slice(0, 10),
      dropTime:IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
      status:"10DAMAGE"
    }
    // console.log("missing",params)
    const resp = await boxDamage(params)
    if(resp.data.statusCode === 428){
    swal({
      title: resp.data.msg,
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDeleteV1(handleDelete, r);
    });
  }
  };

  doSubmitDeleteV1 = async (handleDelete, r) => {
    if (handleDelete) {
      // alert(JSON.stringify(r));

      let params = {
        boxId:r.boxId,
        boxBarcode:r.boxBarcode,
        boxStatus:"lineHaulBoxDamaged",
        lineHaulId:r.ticketLineHaulId,
        damagedConfirmation:true,
        srcHubId:r.srcHubId,
        dstHubId:r.dstHubId,
        direction:r.direction,
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        dropDate:IstToUTC(new Date()).slice(0, 10),
        dropTime:IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        status:"10DAMAGE"
      }
      // console.log("missing",params)
      const resp = await boxDamage(params)
      if(resp.data.success === true){
        toast.success(() => <div>{resp.data.msg}</div>);
        this.getLinehaulList(this.state.pageLinks.pageNo);

      swal("Box Verification Done", {
        icon: "success",
      });
    } else {
      swal("Box is Safe");
    }
  }
  };

  handleDelete  = async (r) => {
    let params = {
      boxId:r.boxId,
      boxBarcode:r.boxBarcode,
      boxStatus:"lineHaulBoxMissing",
      lineHaulId:r.ticketLineHaulId,
      missingConfirmation:false,
      srcHubId:r.srcHubId,
      dstHubId:r.dstHubId,
      direction:r.direction,
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
      dropDate:IstToUTC(new Date()).slice(0, 10),
      dropTime:IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
      status:"15MISSING"
    }
    // console.log("missing",params)
    const resp = await boxMissing(params)
    if(resp.data.statusCode === 428){
    swal({
      title: resp.data.msg,
      text: "Once processed, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, r);
    });
  }
  };

  doSubmitDelete = async (handleDelete, r) => {
    // alert(handleDelete)
    if (handleDelete !== null) {
      // alert(JSON.stringify(r));

      let params = {
        boxId:r.boxId,
        boxBarcode:r.boxBarcode,
        boxStatus:"lineHaulBoxMissing",
        lineHaulId:r.ticketLineHaulId,
        missingConfirmation:true,
        srcHubId:r.srcHubId,
        dstHubId:r.dstHubId,
        direction:r.direction,
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        dropDate:IstToUTC(new Date()).slice(0, 10),
        dropTime:IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        status:"15MISSING"
      }
      // console.log("missing",params)
      const resp = await boxMissing(params)
      if(resp.data.success === true){
        toast.success(() => <div>{resp.data.msg}</div>);
        this.getLinehaulList(this.state.pageLinks.pageNo);

      swal("Box Verification Done", {
        icon: "success",
      });
    } else {
      swal("Box is Safe");
    }
  }
  else {
    swal("Box is Safe");
  }
  };

  closeModal = () => {
    this.setState({ openStatus: "false" });
    this.setState({ documentDetails: "false" });
    this.setState({ curBoxStatus: false });
  };
  // handleView = () => {
  //   $("#myDocumentDetails").draggable();
  // };

  handleExpenseRise = async (r) => {
    try {
      let resp = await getLinehaulTicktes(r.linehaulSeq);
      if (resp.data.success === true) {
        if (resp.data.data) {
          toast.success("Ticket Action Fetched Successfully");
          this.setState({ ticketDetails: resp.data.data });
        } else {
          !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
      this.setState({ documentDetails: "false" });
      this.setState({ openStatus: "true" });
      this.setState({ ticketStatus: "false" });

      this.setState({ currentRequest: r });
    } catch {
      console.log("IncomingManifest ----------->>> handleExpenseRise");
    }
  };

   async onBlockSelectionV1(r, status) {
    try {
      this.setState({ loading: true });
      let params = {
        ticketLineHaulId: r.ticketLineHaulId,
        ticketBlockId: r.ticketBlockId,
        status: status,
        direction: r.direction,
        dropTime: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        dropDate: IstToUTC(new Date()).slice(0, 10),
      };
      let resp = await blockStatus(params);
      if (resp.data.success === true) {
        toast.success(() => <div>{resp.data.msg}</div>);
        this.getLinehaulList(this.state.pageLinks.pageNo);
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
      this.setState({ loading: false });
    } catch {
      console.log("Error in IncomingManifest>>>>>> onBlockSelection");
    }
  };

  async onBlockSelection(r,status) {
    // console.log("hhhhhhhhhhh",r)
    try {
      this.setState({ loading: true });
      this.setState({linehaulBlockId:r.ticketLineHaulId})
      this.setState({boxBarcode:r.boxBarcode});
      if(status === "05RECEIVED"){
      let params = {
        boxBarcodeId:r.boxBarcodeId,
        boxBarcode:r.boxBarcode,
        boxId:r.boxId,
        // ticketLinehaulBlockId: r.ticketLinehaulBlockId,
        // ticketBlockId: r.ticketBlockId,
        // status: status,
        // direction: r.direction,
        // dropTime: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        // dropDate: IstToUTC(new Date()).slice(0, 10),
        lineHaulId: r.ticketLineHaulId,
        boxStatus:"lineHaulBoxReceived" ,

        // srcHubId: r.srcHubId,
        // dstHubId: r.dstHubId,
        // boxId: r.boxId,
        // multiTicketIds: r.multiTicketIds,
        // processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T")
      };
      let resp = await blockStatusv1(params);
      if (resp.data.success === true) {
        toast.success(() => <div>{resp.data.msg}</div>);
        this.setState({curBoxStatus:true})
        this.getLinehaulList(this.state.pageLinks.pageNo);
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
      this.setState({ loading: false });

    }
    // else if(status === "15MISSING"){
    //   let params = {
    //     boxId:r.boxId,
    //     boxBarcode:r.boxBarcode,
    //     boxStatus:"lineHaulBoxMissing",
    //     lineHaulId:r.ticketLineHaulId,
    //     missingConfirmation:false,
    //     srcHubId:r.srcHubId,
    //     dstHubId:r.dstHubId,
    //     direction:r.direction,
    //     processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    //     dropDate:IstToUTC(new Date()).slice(0, 10),
    //     dropTime:IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    //     status:"15MISSING"
    //   }
    //   console.log("missing",params)
    //   const resp = await boxMissing(params)
    //   if(resp.data.success === true){
    //     toast.success(() => <div>{resp.data.msg}</div>);
    //     this.getLinehaulList(this.state.pageLinks.pageNo);

    //   }

    //   this.setState({ loading: false });
    // }
    } catch {
      console.log("Error in IncomingManifest>>>>>> onBlockSelection");
    }
  }

  async onProdBlockSelection(r){
    // console.log("jjj", r)
    this.setState({linehaulBlockId:r.ticketLineHaulId});
    this.setState({direction:r.direction});
    this.setState({boxBarcode:r.boxBarcode})
    this.setState({curBoxStatus:true});

  }

  async openShipmentNoModal(r) {
    //*call to open shipment modal
    try {
      // console.log("rowDetails", r)
      this.setState({ currentShipment: r });
      const shipmentInput = document.getElementById("inputShipmentNo");
      if (shipmentInput) {
        shipmentInput.focus();
      }
    } catch (error) {
      console.error(error);
    }
  }

  // handleSearch = (data) => {
  //   alert(data);
  //   let incoming = this.state.incoming;
  //   let incomingShipmentsFiltered = incoming.filter((c) => {
  //     return c.shipmentNo === data || c.srcLocation === data ? c : null;
  //   });

  //   if (incomingShipmentsFiltered !== undefined) {
  //     this.setState({
  //       incomingShipmentsFiltered: incomingShipmentsFiltered,
  //     });
  //   }
  //   console.log(JSON.stringify(this.state.incomingShipmentsFiltered));

  // };

  handleSearch = () => {
    let pageLinks = this.state.pageLinks;
    pageLinks["pageNo"] = "1";
    this.setState({ pageLinks });
    this.getLinehaulList(this.state.pageLinks.pageNo);
  };

  handlePageChange = (pageNo) => {
    // this.props.history.push("/ticketProcess/"+ pageNo);
    this.getLinehaulList(pageNo);
  };

  handleSaveShipmentNo = async (shipmentNumber) => {
    // this.getLinehaulList(this.state.pageLinks.pageNo);
    const ticketLineHaulId = this.state.currentShipment.ticketLineHaulId;
    try {
      const resp = await updateShipmentNo(ticketLineHaulId, shipmentNumber);

      if (resp.data.success) {
        const updatedIncomingShipment =
          this.state.incomingShipmentsFiltered.map((s) => ({
            ...s,
            shipmentNo:
              s.ticketLineHaulId === this.state.currentShipment.ticketLineHaulId
                ? shipmentNumber
                : s.shipmentNo,
          }));
        this.setState({ incomingShipmentsFiltered: updatedIncomingShipment });
        toast.success(() => <div>{resp.data.msg}</div>);
        return true;
      }
      toast.error(() => <div>{resp.data.msg}</div>);
      return false;
    } catch (error) {
      console.log("ERROR in handleSaveShipmentNo");     
      console.error(error);
    }
  };

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    const incomingJSON = this.state.incomingShipmentsFiltered;
    const currentRequest = this.state.currentRequest;
    const ticketRequest = this.state.ticketDetails;
    // console.log({bbb:this.state.linehaulBlockId});

    // console.log({incomingJSON})
    return (
      <>
        <div className="form-subHeader">Incoming Manifest</div>
        <div className="form-row col-12">
          <div className="col-4">{this.rTB("Search Ticket No.", "search")}</div>
          <div className="col-4">
            <button
              className="btn  btn-primary  btn-sm"
              onClick={() => this.handleSearch(this.state.data.search)}
            >
              Search
            </button>
          </div>
        </div>
        <div className="form-row col-12">
          {this.props.loading && (
            <div className="container-fluid pull-right">
              <div> Loading users...</div>
            </div>
          )}
          {!this.props.loading && (
            <>
              <Table
                clsName="table table-striped table-bordered table-hover"
                tableMeta={this.tableMeta}
                data={incomingJSON}
                dropMenu={[]}
              />
              {this.state.incomingShipmentsFiltered.length !== 0 && (
                <Pagination
                  pageNo={pageNo}
                  perPage={perPage}
                  itemsCount={itemsCount}
                  onPageChange={this.handlePageChange}
                />
              )}
            </>
          )}
        </div>

        {this.state.incomingShipmentsFiltered.length === 0 && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">No Tickets Found</p>
          </div>
        )}
        <div
          className="draggable col-10 ml-5 mr-5"
          id="myDocumentDetails"
          onClick={this.handleView}
        >
          {this.state.openStatus === "false" &&
            this.state.documentDetails === "true" && (
              <DocumentDetails
                key={currentRequest.ticketNo}
                data={currentRequest}
                // requestStatusChange={this.handleRequestStatusChange}
                // user={this.state.user}
                closeModal={this.closeModal}
              />
            )}
        </div>

        <div
          className="draggable"
          id="myProdDetails"
          onClick={this.handleView}
        >
          {this.state.curBoxStatus === true &&
            (
              <LinehaulProdDetails
                key={currentRequest.ticketNo}
                data={this.state.linehaulBlockId}
                direction={this.state.direction}
                boxBarcode={this.state.boxBarcode}
                requestStatusChange={this.getLinehaulList}
                // user={this.state.user}
                closeModal={this.closeModal}
              />
            )}
        </div>

        <div
          className="draggable  col-sm-12 col-md-8 col-lg-8 "
          id="myModalApprove"
          onClick={this.handleView}
        >
          {
            // this.state.TicketActionsDatas &&
            this.state.openStatus === "true" &&
              this.state.documentDetails === "false" &&
              this.state.ticketStatus === "false" && (
                <ExpenseRise
                  key={currentRequest.ticketNo}
                  data={currentRequest}
                  type="report"
                  ticketDetails={ticketRequest}
                  // ticketActionId={currentRequest.ticketActionId}
                  // requestChange={this.handleRequestChange}
                  // user={this.state.user}
                  closeModal={this.closeModal}
                />
              )
          }
        </div>

        <UpdateShipmentNoModal
          shipmentDetails={this.state.currentShipment}
          handleSave={this.handleSaveShipmentNo}
          shipmentInputId="inputShipmentNo"
          modalId="staticBackdrop"
        />
      </>
    );
  }
}

