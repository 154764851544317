import React from "react";

function Selectbox({
  name,
  label,
  error,
  options = [],
  value,
  inline = "",
  ctrls,
  classAppend = "",
  ...rest
}) {
  let opStyle = { color: "#333" };

  return (
    <React.Fragment>
      <div className={"form-group mdb-select md-form" + classAppend}>
        <select id={name} name={name} value={value} {...rest}>
          <option value="">Select</option>

          {options.map((op) => {
            if (
              Array.isArray(value) &&
              value[0].indexOf(op[ctrls.value]) > -1
            ) {
              opStyle = { color: "#333", backgroundColor: "#9bcaf8" };
              return (
                <option
                  value={op[ctrls.value]}
                  key={op[ctrls.value]}
                  style={opStyle}
                >
                  {op[ctrls.label]}
                </option>
              );
            } else {
              opStyle = { color: "#333" };
              return (
                <option
                  value={op[ctrls.value]}
                  key={op[ctrls.value]}
                  style={opStyle}
                >
                  {op[ctrls.label]}
                </option>
              );
            }
          })}
        </select>
        {label && (
          <label htmlFor={name} className="control-label">
            {label}
          </label>
        )}
        <i className="bar"></i>
        {error && <span className="alert alert-danger">{error}</span>}
      </div>
    </React.Fragment>
  );
}

export default Selectbox;
