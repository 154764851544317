import React from "react";
import ZTitle from "../../_subhead/ZTitle";

import DropDownMenu from "../../_common/DropDownMenu";
import { dropMenuFull, dropMenuBasic } from "../../../utils/DropMenuUtils";
import { getModules } from "./../../../services/plms/moduleServices";
import { getUserstories } from "./../../../services/plms/userstoriesServices";
import { getTasks } from "../../../services/plms/taskServices";
import FormLib from "../../_common/FormLib";
import ProjectModuleform from "./ProjectModuleform";
import ProjectModal from "./ProjectModal";
import TaskModal from "./TaskModal";
import UserModal from "./UserModal";
import AddModal from "./AddModal";
import ReportModal from "./ReportModal";

class GreenBoard extends FormLib {
  state = {
    data: {
      username: "",
      password: "",
      pickupDate: this.getDatebyDays(1),
      pickupTime: "14:00",
    },
    errors: {},
    modules: [],
    userstories: [],
    task: [],
    // tasktype:[]
  };

  async componentDidMount() {
    // this.handleLoadModules();
    // this.handleLoadUserStories();
    // this.handleLoadTask();
  }
  catch(ex) {}

  async handleLoadModules() {
    let resp = await getModules();
    this.setState({
      modules: resp.data.modules,
    });
  }

  async handleLoadUserStories() {
    let resp = await getUserstories();
    this.setState({
      userstories: resp.data.userstories,
    });
  }

  async handleLoadTask() {
    let resp = await getTasks();
    this.setState({
      task: resp.data.task,
    });
  }

  //   async componentDidMount() {
  //     let resp = await getTaskstype();
  //     console.log(resp.data.tasktype);
  //     this.setState({tasktype: resp.data.tasktype });
  // }

  tasksList = [
    {
      key: "key1",
      title: "Ledger Creation API",
      OwnerName: "OwnerName",
      points: "8",
      status: "open",
      type: "api",
    },
    {
      key: "key2",
      title: "Ledger Creation API",
      OwnerName: "OwnerName",
      points: "8",
      status: "open",
      type: "web",
    },
    {
      key: "key3",
      title: "Ledger Creation API",
      OwnerName: "OwnerName",
      points: "8",
      status: "open",
      type: "db",
    },
    {
      key: "key4",
      title: "Ledger Creation API",
      OwnerName: "OwnerName",
      points: "8",
      status: "open",
      type: "mob",
    },
    {
      key: "key5",
      title: "Ledger Creation API",
      OwnerName: "OwnerName",
      points: "8",
      status: "open",
      type: "api",
    },
    {
      key: "key6",
      title: "Ledger Creation API",
      OwnerName: "OwnerName",
      points: "8",
      status: "open",
      type: "web",
    },
  ];

  userList = [
    {
      key: "001",
      title: "Ledger Creation",
      OwnerName: "Bluedart",
      points: "5",
      status: "open",
    },
    {
      key: "002",
      title: "Ledger Creation",
      OwnerName: "Servify",
      points: "5",
      status: "open",
    },
    {
      key: "003",
      title: "Ledger Creation",
      OwnerName: "DigiMart",
      points: "5",
      status: "open",
    },
    {
      key: "004",
      title: "Ledger Creation",
      OwnerName: "DigiMart",
      points: "5",
      status: "open",
    },
    {
      key: "005",
      title: "Ledger Creation",
      OwnerName: "DigiMart",
      points: "5",
      status: "open",
    },
    {
      key: "006",
      title: "Ledger Creation",
      OwnerName: "DigiMart",
      points: "5",
      status: "open",
    },
  ];

  moduleList = [
    {
      key: "001",
      title: "Finance Module",
      Name: "Team memeber name",
    },
    {
      key: "001",
      title: "Finance Module",
      Name: "Team memeber name",
    },
    {
      key: "001",
      title: "Finance Module",
      Name: "Team memeber name",
    },
    {
      key: "001",
      title: "Finance Module",
      Name: "Team memeber name",
    },
    {
      key: "001",
      title: "Finance Module",
      Name: "Team memeber name",
    },
    {
      key: "001",
      title: "Finance Module",
      Name: "Team memeber name",
    },
  ];

  render() {
    return (
      <React.Fragment>
        <ZTitle subHeading="Project:: Module" />
        <div className="container-fluid">
          <div className="pageContent">
            <div className="row">
              <div className="col-md-8">
                <ProjectModuleform />
              </div>
              <div className="menu col-md-3 ">
                <AddModal />
              </div>

              <div className="menu col-md-1 pull-right">
                <DropDownMenu
                  menuItems={dropMenuBasic("/v1/request/raise")}
                  menuKey=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="pageContent">
          <div className="container-fluid">
            <div className="card-deck">
              <div className="card  ">
                <h5 className="card-header">
                  Modules
                  <span className="pull-right">
                    {" "}
                    <DropDownMenu
                      menuItems={dropMenuFull("/v1/request/raise")}
                      menuKey=""
                    />
                  </span>
                  {/* <div className="container-fluid col-md-3 pull-right">
                <button type="button"   className="btn btn-sm btn-primary" data-toggle="modal" data-target="#myModal">
                    M
                </button>
                </div> */}
                  <button
                    href="#link1"
                    type="button"
                    className="btn btn-sm btn-primary pull-right"
                    onClick={() => this.handleLoadModules()}
                  >
                    +
                  </button>
                  <span className="pull-right">
                    <ProjectModal />
                  </span>
                </h5>

                <div className="card-body">
                  <div className="page-todo">
                    <div className=" tasks">
                      <div className="module-list">
                        {this.state.modules.map((mi) => (
                          <div className="task nil" key={mi.moduleId}>
                            <div className="desc">
                              <div className="title">
                                <span className="badge badge-info  mr-1 taskType">
                                  {mi.type}
                                </span>
                                {mi.prModule}
                              </div>
                              <div>
                                {mi.Name}
                                <span className="badge badge-primary ml-1">
                                  {mi.status}
                                </span>
                              </div>
                            </div>
                            <div className="rightCol">
                              <div className="menu">
                                <DropDownMenu
                                  menuItems={dropMenuBasic("/v1/request/raise")}
                                  menuKey={mi.key}
                                />
                              </div>
                              <div>trx-001</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card  ">
                <h5 className="card-header">
                  UserStories
                  <span className="pull-right">
                    {" "}
                    <DropDownMenu
                      menuItems={dropMenuFull("/v1/request/raise")}
                      menuKey=""
                    />
                  </span>
                  <button
                    href="#link1"
                    type="button"
                    className="btn btn-sm btn-primary pull-right"
                    onClick={() => this.handleLoadUserStories()}
                  >
                    +
                  </button>
                  <span className="pull-right">
                    {" "}
                    <UserModal />
                  </span>
                </h5>
                <div className="card-body">
                  <div className="page-todo">
                    <div className=" tasks">
                      <div className="user-list">
                        {/* <div className="desc">
                      <div className="title">Ledger Creation</div>
                      <div>
                        OwnerName | 8 Pts |
                        <span className="badge badge-primary  ml-1 ">Open</span>
                      </div>
                    </div> */}

                        {this.state.userstories.map((mi) => (
                          <div className="task nil" key={mi.userstoryId}>
                            <div className="desc">
                              <div className="title">
                                <span className="badge badge-info  mr-1 taskType">
                                  {mi.type}
                                </span>
                                {mi.usOwner}
                              </div>
                              <div>
                                {mi.OwnerName} | 8 pts |
                                <span className="badge badge-primary  ml-1 ">
                                  Open
                                </span>
                                {/* | {mi.points} Pts | */}
                                <span className="badge badge-primary ml-1">
                                  {mi.status}
                                </span>
                              </div>
                            </div>
                            <div className="rightCol">
                              <div className="menu">
                                <DropDownMenu
                                  menuItems={dropMenuBasic("/v1/request/raise")}
                                  menuKey={mi.key}
                                />
                              </div>
                              <div>trx-001</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card ">
                <h5 className="card-header">
                  Tasks
                  <span className="pull-right">
                    {" "}
                    <DropDownMenu
                      menuItems={dropMenuFull("/v1/request/raise")}
                      menuKey=""
                    />
                  </span>
                  <button
                    href="#link1"
                    type="button"
                    className="btn btn-sm btn-primary pull-right"
                    onClick={() => this.handleLoadTask()}
                  >
                    +
                  </button>
                  <span className="pull-right">
                    {" "}
                    <TaskModal />
                  </span>
                  <span className="pull-right">
                    {" "}
                    <ReportModal />
                  </span>
                  {/* <ReportCreate/> */}
                </h5>
                <div className="card-body">
                  <div className="page-todo">
                    <div className=" tasks">
                      <div className="task-list">
                        {this.state.task.map((mi) => (
                          <div className="task nil" key={mi.taskId}>
                            <div className="desc">
                              <div className="title">
                                <span className="badge badge-info  mr-1 taskType">
                                  {mi.type}
                                </span>
                                <span className="badge badge-info  mr-1 taskType">
                                  API
                                </span>

                                {mi.tskOwner}
                              </div>
                              <div>
                                {mi.OwnerName}|8 pts|
                                <span className="badge badge-primary  ml-1 ">
                                  Open
                                </span>
                                <span className="badge badge-primary ml-1">
                                  {mi.status}
                                </span>
                              </div>
                              {/* <div className="desc">
                      <div className="title">
                        <span className="badge badge-info  mr-1 taskType">API</span>
                        Ledger Creation API
                      </div>
                      <div>
                        OwnerName | 8 Pts |
                        <span className="badge badge-primary  ml-1 ">Open</span>
                      </div>
                    </div> */}
                            </div>
                            <div className="rightCol">
                              <div className="menu">
                                <div className="menu">
                                  <DropDownMenu
                                    menuItems={dropMenuBasic(
                                      "/v1/request/raise"
                                    )}
                                    menuKey={mi.key}
                                  />
                                </div>
                              </div>
                              <div>trx-001</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default GreenBoard;
