import _http from "./../_httpNoRejectService";
import config from "./../../config.json";

const baseURL = config[config.build].apiURLInventory;

export function createBarcodes(req) {
  try {
    const apiEndpoint = `${baseURL}/invSlClient/create`;
    return _http.post(apiEndpoint, req, _http.headerJWT());
  } catch (error) {
    console.log("Error in createBarcodes", error);
  }
}
export function createBarcodesV1(req) {
  try {
    const apiEndpoint = `${baseURL}/invSlClient/createV1`;
    return _http.post(apiEndpoint, req, _http.headerJWT());
  } catch (error) {
    console.log("Error in createBarcodes", error);
  }
}

export function getBarcodesReport(params, pageNo) {
  try {
    const apiEndpoint = `${baseURL}/invSlClient/report/50/${pageNo}`;
    return _http.put(apiEndpoint, params, _http.headerJWT());
  } catch (error) {
    console.log("Error in getBarcodesReport", error);
  }
}

export function updateTheDamagedBarcodeStatus(params){

  try {
    return _http.put(
      `${baseURL}/updateTheDamagedBarcodeStatus`,
      params,
      _http.headerJWT()
    );
  } catch (error) {
    return "failed to fetch an api";
  }
}
