import React from 'react'

const TicketInfo = ({
    curProcessId,
    curBlockId,
    toggleTicketInfo
}) => {
try {
  const styleList = {
    'position': 'absolute',
    'display': toggleTicketInfo? 'block': 'none',
    'zIndex': 999,
    'textAlign': 'center',
    'backgroundColor': 'lightgray',
    'padding': '0.5rem',
    'top': '-2.5rem',
    'left': '12rem',
    'borderRadius': '0.5rem'
  }  
  return (
        <div>
            <p className={"ticketInfo"} style={styleList}>
                            <b>Process ID: </b> {curProcessId} <br />
                            <b>Block ID: </b>   
                            {curBlockId} <br />
            </p>
        </div>
  )
  
} catch (error) {
  console.log("<============= ERROR in TIcketInfo ===========>")
  console.error(error)
  return null
}
}

export default TicketInfo