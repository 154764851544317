import React from 'react'

import AddList from './AddProjectList';

class TaskModal extends React.Component {
    render() {
        return (
            <div className="task-modal">
                {/* <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#myModal3">
                    Add
                </button> */}

                <div className="modal fade" id="myModal3">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Project</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div class="modal-body p-0">
                            <AddList/>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TaskModal;

