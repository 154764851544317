import jwtDecode from "jwt-decode";
import _http from "./_httpService";
import config from "../config.json";
import toast from "./_toastService";

const apiEndpoint = config[config.build].apiURLCorePHP + "/auth/login.php";

const tokenKey = "token";

_http.setJwt(getJwt());

export async function login(code, username, password) {
  const { data } = await _http.post(apiEndpoint, { username, password });

  if (data.status === "0") {
    toast.toastError(data.msg);
  }

  data.data.user.token = data.data.token;
  const jwt = data.data.token;
  localStorage.setItem(tokenKey, jwt);

  const user = JSON.stringify(data.data.user);
  localStorage.setItem("userInfo", user);

  const privileges = JSON.stringify(data.data.privileges);
  localStorage.setItem("privileges", privileges);

  return data;
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt);
}

export function logout() {
  localStorage.removeItem(tokenKey);
  localStorage.removeItem("userInfo");
  localStorage.removeItem("privileges");
}

export function getCurrentUser() {
  try {
    const jwt = jwtDecode(localStorage.getItem(tokenKey));
    return jwt["user"];
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey);
}
const authActions = {
  login,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
};
export default authActions;
