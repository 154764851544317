import * as act from "./ticketProcessTypes";

const ticketListReloadState = {
  ticketListReload: 0,
};

export const ticketListReloadReducer = (
  state = ticketListReloadState,
  action
) => {
  switch (action.type) {
    case act.TICKET_LIST_RELOAD:
      return {
        ticketListReload: action.payload.ticketListReloadCnt + 1,
      };

    default:
      return state;
  }
};

export default ticketListReloadReducer;
