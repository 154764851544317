import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";

import Table from "../../_common/Table";
import Pagination from "../../_common/Pagination";

import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";
import {
  getEmpAccounts,
  createEmpAccount,
  saveEmpAccount,
  deleteEmpAccount,
} from "../../../services/requestV1/AccountServices";
class Accounts extends FormLib {
  state = {
    data: {
      ACCOUNT_ID: "",
      PLAYER_CODE: "",
      NAME: "",
      ACCOUNT_TYPE: "",
      ACCOUNT_NO: "",
      IFSC: "",
      BANK: "",
      BRANCH: "",
    },
    errors: {},
    empAccounts: [],
    loading: true,
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };
  schema = {
    ACCOUNT_ID: Joi.optional().label("Account Id"),

    PLAYER_CODE: Joi.required().label("Player Code"),
    ACCOUNT_TYPE: Joi.string().required().label("account type"),
    ACCOUNT_NO: Joi.required().label("Account No"),
    NAME: Joi.string().required().label("Name"),
    IFSC: Joi.string().required().label("IFSC"),
    BANK: Joi.string().required().label("Bank"),
    BRANCH: Joi.optional().label("Branch"),
  };
  componentDidMount() {
    this.loadEmpAccounts();
  }
  async loadEmpAccounts() {
    this.setState({ loading: true });
    const res = await getEmpAccounts();
    if (res.data.status === 1) {
      this.setState({ empAccounts: res.data.data });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }
  handlePageChange = (pageNo) => {
    this.props.history.push("/accounts/accounts" + pageNo);

    this.loadEmpAccounts();
  };

  handleOnEmpAccount(r) {
    //console.log("inside handle on EmpAccount");
    let empAccounts = {};
    empAccounts.ACCOUNT_ID = r.ACCOUNT_ID;
    empAccounts.ACCOUNT_TYPE = r.ACCOUNT_TYPE;
    empAccounts.PLAYER_CODE = r.PLAYER_CODE;
    empAccounts.ACCOUNT_NO = r.ACCOUNT_NO;
    empAccounts.NAME = r.NAME;
    empAccounts.IFSC = r.IFSC;
    empAccounts.BANK = r.BANK;
    empAccounts.BRANCH = r.BRANCH;
    this.setState({ data: empAccounts });
  }

  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createEmpAccount(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await saveEmpAccount(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "delete") {
      res = await deleteEmpAccount(this.state.data);
      this.resetData();
    }
    if (res.data.status === 1) {
      toast.toastSuccess(res.data.msg);
      this.loadEmpAccounts();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  resetData() {
    this.setState({
      data: {
        ACCOUNT_ID: "",
        PLAYER_CODE: "",
        NAME: "",
        ACCOUNT_TYPE: "",
        ACCOUNT_NO: "",
        IFSC: "",
        BANK: "",
        BRANCH: "",
      },
    });
  }

  tableMeta = {
    key: "ACCOUNT_ID",
    columns: [
      { path: "PLAYER_CODE", label: "Emp Code" },
      { path: "NAME", label: "Name" },
      { path: "ACCOUNT_TYPE", label: "Account Type" },
      { path: "ACCOUNT_NO", label: "Account No" },
      { path: "IFSC", label: "IFSE" },
      { path: "BANK", label: "BANK" },
      { path: "BRANCH", label: "Branch" },
      {
        key: "edit",
        action: (r) => (
          <>
            <span
              onClick={() => this.handleOnEmpAccount(r)}
              class="badge  badge-success mr-1 p-1 pull-right"
            >
              <i className={"fa  fa-edit"}></i>
            </span>
          </>
        ),
        label: "Action",
      },
    ],
  };
  actVal = [
    { sortKey: "SB Account", sort: "SB Account" },
    { sortKey: "Current", sort: "Current" },
  ];
  actConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Account::List" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Accounts</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.empAccounts}
                    dropMenu={[]}
                  />
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              )}
            </div>
            <div className="col-md-6 col-sm-12">
              <form>
                <div className="form-row">
                  {this.rTB("ACCOUNT_ID", "ACCOUNT_ID", "col-1")}
                  {this.rTB("Name", "NAME", "col-3")}
                  {this.rTB("Emp Code", "PLAYER_CODE", "col-3")}
                  <div className="col-3">
                    {this.rSB("Account Type", "ACCOUNT_TYPE", this.actVal, {
                      value: "sortKey",
                      label: "sort",
                    })}
                  </div>
                  {this.rTB("AccountNo", "ACCOUNT_NO", "col-3")}
                </div>
                <div className="form-row">
                  {this.rTB("IFSC", "IFSC", "col-3")}
                  {this.rTB("Bank", "BANK", "col-3")}
                  {this.rTB("Branch", "BRANCH", "col-3")}
                </div>
              </form>
              {this.state.data.ACCOUNT_ID === "" &&
                this.rBtnSP("Create", "create")}
              {this.state.data.ACCOUNT_ID !== "" && (
                <>
                  {this.rBtnSS("Edit", "edit")}
                  {this.rBtnSD("Delete", "delete")}
                </>
              )}
            </div>
          </div>
          {/* <span>{JSON.stringify(this.state.data)}</span> */}
        </div>
      </React.Fragment>
    );
  }
}
export default Accounts;
