import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import AngLogin from "./../pages/auth/AngLogin";
import Login from "./../pages/auth/Login";
import LoginPHP from "./../pages/auth/LoginPHP";
import AppWrapper from "./AppWrapper";

class AppGate extends Component {
  state = {};

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/auth/angJS/:jwt" component={AngLogin} />

          <Route path="/login" exact component={Login} />
          <Route path="/plms" exact component={LoginPHP} />

          <Route path="/" exact component={Login} />
          <Route path="/" component={AppWrapper} />
        </Switch>
      </React.Fragment>
    );
  }
}
export default AppGate;
