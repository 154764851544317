// ReturnBarcodesFromUser

import React, { Component } from "react";
import FormLib from "../../../_common/FormLib";
import LocationTitle from "../../../_subhead/LocationTitle";
import Joi from "joi-browser";
import Table from "../../../_common/Table";
import {
  getActiveClients,
  getAssignedProduct,
  getAssignedProductV1,
  getUsers,
  revertAssignedProduct,
  revertFreshBarcodes,
} from "../../../../services/masterLocations/clientService";
import { toast } from "react-toastify";
import Pagination from "../../../_common/Pagination";
import { getRetailerInfo } from "../../../../services/TicketHistory/TicketHistory";

class ReturnBarcodesFromUser extends FormLib {
  state = {
    data: {
      hubId: "5067",
      userId: "",
      teamId: "",
      barcodeType: "freshBarcodes",
      fromScan: "",
      endScan: "",
    },
    hubs: [],
    usersList: [],
    clients: [],
    errors: {},
    mode: "",
    userAssignedBarcode: [],
    slNoId: "",
    searchText: "",
    filteredBarcodeData: [],
    barcodeType: [
      { barcodeTypeId: "freshBarcodes", barcodeType: "Fresh Barcodes" },
      // { barcodeTypeId: "itemBarcodes", barcodeType: "ItemBarcodes" }
    ],
    tempBarcodes: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };

  componentDidMount() {
    this.loadHubs();
    this.loadClients();
    this.loadUsers();
    this.allBarcodes();
  }

  schema = {
    hubId: Joi.string().required().label("Hubs *"),
    teamId: Joi.string().required().label("Clients *"),
    userId: Joi.string().required().label("User *"),
    barcodeType: Joi.string().optional().label("barcodeType"),
    fromScan: Joi.optional().label("Starting Barcode Number"),
    endScan: Joi.optional().label("Ending Barcode Number"),
  };

  async loadClients() {
    this.setState({ loading: true });
    // const res = await getTeams();
    // // if (res !== undefined) {
    // //   if (res.data.success === true) {
    // //     this.setState({ teams: res.data.data.teams });
    // //   } else {
    // //     toast.toastError(res.data.msg);
    // //   }
    // // }
    // if (res !== undefined) {
    //   if (res.data.success === true) {
    //     this.setState({ teams: res.data.data.teams });
    //     this.state.data.teamId = res.data.data.teams[8]["teamId"];

    //     this.setState({ curTeam: res.data.data.teams[8] });

    //     this.setState({
    //       data: {
    //         ...this.state.data,
    //         // hubId: this.state.data.hubId,
    //         teamId: res.data.data.teams[8]["teamId"],
    //         // status: this.state.data.status,
    //         // packMaterialId: this.state.data.packMaterialId
    //       },
    //     });
    //   } else {
    //     toast.toastError(res.data.msg);
    //   }
    // }

    try {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let playerId = userInfo.playerId;
      let resp = await getRetailerInfo(playerId);
      if (resp !== undefined) {
        if (
          resp.data.success === true &&
          resp.data.data.retailersList.length >= 1
        ) {
          this.setState({ teams: resp.data.data.retailersList });
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in retailerList");
    }
    this.setState({ loading: false });
  }
  async loadHubs() {
    try {
      this.setState({ loading: true });
      let hubList = JSON.parse(localStorage.getItem("hubs"));

      if (hubList.length > 0) {
        this.setState({ hubs: hubList });
      } else {
        return "No Hubs Found";
      }
    } catch {
      console.log("error in loading hubs");
    }
    this.setState({ loading: false });
  }

  async loadUsers() {
    try {
      let hubId = this.state.data.hubId;
      const res = await getUsers(hubId);

      if (res.data.success === true) {
        this.setState({ usersList: res.data.data.playersHubs });
      } else {
        toast.toastError(res.data.msg);
      }
    } catch (error) {
      return "failed to fetch users data";
    }
  }

  async allBarcodes() {
    try {
      let tempBarcodes = [];
      // let data = this.state.data;
      let data = {
        hubId: this.state.data.hubId,
        userId: this.state.data.userId,
        clientId: this.state.data.teamId,
        barcodeType: this.state.data.barcodeType,
        fromScan: this.state.data.fromScan,
        endScan: this.state.data.endScan,
      };

      for (
        let i = this.state.pageLinks.pageNo;
        i <= this.state.pageLinks.lastPage;
        i++
      ) {
        let barcode = await getAssignedProductV1(data, i);
        tempBarcodes.push(...barcode.data.data.freshBarcodeData);
      }
      this.setState({ tempBarcodes }, () => {});
    } catch (error) {
      console.error("Failed to fetch barcode data:", error);
    }
  }

  localHandleChange = (e) => {
    if (e.target.id === "hubId") {
      this.setState({ hubId: e.target.value }, () => {
        this.loadUsers();
      });
    }
  };

  async doSubmit() {
    let params = {
      hubId: this.state.data.hubId,
      userId: this.state.data.userId,
      clientId: this.state.data.teamId,
      barcodeType: this.state.data.barcodeType,
      fromScan: this.state.data.fromScan,
      endScan: this.state.data.endScan,
    };
    if (this.state.data.fromScan !== "" && this.state.data.endScan === "") {
      this.state.data.endScan = this.state.data.fromScan;
    }

    let userAssignedProduct;
    // if ((this.state.data.fromScan === "" && this.state.data.endScan === "") || (this.state.data.fromScan !== "" && this.state.data.endScan !== "")) {
    if (this.state.data.fromScan !== "" && this.state.data.endScan !== "") {
      if (
        parseInt(this.state.data.fromScan) <= parseInt(this.state.data.endScan)
      ) {
        userAssignedProduct = await getAssignedProductV1(params, 1);
        if (userAssignedProduct.data.success === true) {
          this.setState({
            userAssignedBarcode: userAssignedProduct.data.data.freshBarcodeData,
          });
          this.setState({ pageLinks: userAssignedProduct.data.data.pageLinks });
          this.setState({
            filteredBarcodeData: userAssignedProduct.data.data.freshBarcodeData,
          });
          this.setState({ searchText: "" });

          this.setState({ formMode: "submit" });
          this.allBarcodes();
          toast.success(() => <div>{userAssignedProduct.data.msg}</div>);

          // this.reset();
        } else {
          this.setState({ loading: false });
          this.setState({ filteredBarcodeData: [] });
          this.setState({
            pageLinks: {
              pageNo: 1,
              perPage: 0,
              itemsCount: 0,
              lastPage: 0,
            },
          });
        }
      } else {
        this.setState({ loading: false });
        toast.error(() => (
          <div>{"fromScan value cannot be greater than endScan value"}</div>
        ));
        this.setState({ filteredBarcodeData: [] });
        this.setState({
          pageLinks: {
            pageNo: 1,
            perPage: 0,
            itemsCount: 0,
            lastPage: 0,
          },
        });
      }
    } else {
      let params = {
        hubId: this.state.data.hubId,
        userId: this.state.data.userId,
        clientId: this.state.data.teamId,
        barcodeType: this.state.data.barcodeType,
        fromScan: this.state.data.fromScan,
        endScan: this.state.data.endScan,
      };
      userAssignedProduct = await getAssignedProductV1(
        params,
        this.state.pageLinks.pageNo
      );
      if (userAssignedProduct.data.success === true) {
        this.setState({
          userAssignedBarcode: userAssignedProduct.data.data.freshBarcodeData,
        });
        this.setState({ pageLinks: userAssignedProduct.data.data.pageLinks });
        this.setState({
          filteredBarcodeData: userAssignedProduct.data.data.freshBarcodeData,
        });
        this.setState({ searchText: "" });

        this.setState({ formMode: "submit" });
        this.allBarcodes();
        toast.success(() => <div>{userAssignedProduct.data.msg}</div>);

        // this.reset();
      } else {
        this.setState({ loading: false });
        // toast.error(data.data.msg);
        this.setState({ filteredBarcodeData: [] });
        this.setState({
          pageLinks: {
            pageNo: 1,
            perPage: 0,
            itemsCount: 0,
            lastPage: 0,
          },
        });
      }
    }
    this.setState({ loading: true });
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/returnBarcodesFromUser/" + pageNo);
    let pageLinks = this.state.pageLinks;
    pageLinks["pageNo"] = pageNo;
    this.setState({ pageLinks });
    this.doSubmit();
  };
  tableMeta = {
    key: "itemId",
    columns: [
      {
        key: "itemId",
        action: (r) => <div className="btn-group pull-right"></div>,
        icon: () => <i className="fa fa-table " aria-hidden="true" />,
      },
      {
        key: "sl_No2",
        action: (r) => (
          <>
            <span>{r.slNo}</span>
          </>
        ),
        cusClass: " ",
        icon: () => (
          <div>
            <p>Barcode</p>
            <input
              type="text"
              name="searchBarcode"
              id="searchBarcode"
              placeholder="Search"
              value={this.state.searchText}
              onChange={(e) => {
                const searchText = e.target.value;
                this.setState({ searchText }, () => {
                  let filteredBarcodeData;

                  if (searchText.trim() === "") {
                    filteredBarcodeData = this.state.userAssignedBarcode;
                  } else {
                    filteredBarcodeData = this.state.tempBarcodes?.filter(
                      (item) => {
                        const slNo = item.slNo.toString();
                        return slNo
                          .toLowerCase()
                          .includes(searchText.toLowerCase());
                      }
                    );
                  }

                  this.setState({ filteredBarcodeData });
                });
              }}
            />
          </div>
        ),
      },
      // { path: "item", label: "Item" },
      {
        key: "sl_No3",
        action: (r) => (
          <>{this.state.data.barcodeType === "itemBarcodes" && <>{r.item}</>}</>
        ),
        icon: () => this.state.data.barcodeType === "itemBarcodes" && <>Item</>,
      },
      { path: "user", label: "User" },

      {
        key: "playerId",
        action: (r) => (
          <>
            {this.state.data.barcodeType === "itemBarcodes" && (
              <button
                className="btn btn-success btn-sm"
                type="button"
                onClick={() => this.revertItemBarcode(r)}
              >
                Revert
              </button>
            )}
            {this.state.data.barcodeType === "freshBarcodes" && (
              <button
                className="btn btn-primary btn-sm"
                type="button"
                onClick={() => this.revertFreshBarcode(r)}
              >
                Revert
              </button>
            )}
          </>
        ),
        icon: () => <>Assign</>,
      },

      // {
      //   key: "playerId",
      //   action: (r) => (
      //     <>
      //       <button
      //         className="btn btn-success btn-sm"
      //         type="button"
      //         onClick={() => this.revertItemBarcode(r)}
      //       >
      //         Revert
      //       </button>
      //     </>
      //   ),
      //   icon: () => <>Revert</>,
      // },
    ],
  };

  revertItemBarcode = async (data) => {
    try {
      let params = {
        clientId: this.state.data.teamId,
        hubId: this.state.data.hubId,
        userId: this.state.data.userId,
        slNoId: data.slNoId,
      };
      const res = await revertAssignedProduct(params);
      if (res.data.success === true) {
        toast.success(() => <div>{res.data.msg}</div>);
        this.doSubmit();
      } else {
        toast.error(() => <div>{res.data.msg}</div>);
      }
    } catch (error) {
      return "failed to assign users data";
    }
  };

  revertFreshBarcode = async (data) => {
    try {
      let params = {
        clientId: this.state.data.teamId,
        hubId: this.state.data.hubId,
        userId: this.state.data.userId,
        slNoIds: [data.slNoId],
      };
      const res = await revertFreshBarcodes(params);
      if (res.data.success === true) {
        toast.success(() => <div>{res.data.msg}</div>);
        this.doSubmit();
      } else {
        toast.error(() => <div>{res.data.msg}</div>);
      }
    } catch (error) {
      return "failed to assign users data";
    }
  };

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    // const returnData = this.state.filteredBarcodeData;

    return (
      <React.Fragment>
        <LocationTitle subHeading="Return barcode Items :" />
        <form onSubmit={this.handleSubmit}>
          <div className="pageContent">
            <div className="form-row">
              <div className="col-2">
                {this.rSB("Clients *", "teamId", this.state.teams, {
                  value: "teamId",
                  label: "clients",
                })}
              </div>
              <div className="col-2">
                {this.rSB("Hubs *", "hubId", this.state.hubs, {
                  value: "hubId",
                  label: "hub",
                })}
              </div>
              <div className="col-2">
                {this.rSB(
                  "Barcode Type *",
                  "barcodeType",
                  this.state.barcodeType,
                  { value: "barcodeTypeId", label: "barcodeType" }
                  //   "col-12"
                )}
              </div>
              <div className="col-1">{this.rTB("FromScan ", "fromScan")}</div>
              <div className="col-1">{this.rTB("ToScan", "endScan")}</div>
              <div className="col-2">
                {this.rSB("Users *", "userId", this.state.usersList, {
                  value: "playerId",
                  label: "userName",
                })}
              </div>
              <div style={{ paddingLeft: "1rem" }}>
                {this.rBtnSP("Submit", "submit")}
              </div>
            </div>
          </div>
        </form>

        <div style={{ maxWidth: "50rem", paddingLeft: "2rem" }}>
          <div>
            {this.state.userAssignedBarcode.length !== 0 &&
              this.state.formMode === "submit" && (
                <div>
                  {" "}
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.filteredBarcodeData}
                    dropMenu={[]}
                  />
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              )}
          </div>
          <div>
            {this.state.filteredBarcodeData.length === 0 &&
              this.state.mode === "submit" && (
                <div className="jumbotron jumbotron-fluid">
                  <p className="lead text-center">No barcodes Found</p>
                </div>
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ReturnBarcodesFromUser;
