import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLMasterLoc;
function api(path, subURL = "/hubs") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getHubs(pageNo) {
  return _http.get(api("" + "100/" + pageNo), _http.headerJWT());
}

export function getHubsAll() {
  try {
    return _http.get(api(""), _http.headerJWT());
  }
  catch {
    return undefined;
  }
}

export function getSearchHubs(searchString, pageNo) {

  return _http.put(
    api("searchHub" + "/100/" + pageNo),
    searchString,
    _http.headerJWT()
  );
}
export function createHub(req) {
  const param = { ...req };
  delete param.hubId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function  saveHub(req) {
  const param = { ...req };
  const id = param.hubId;
  //delete param.hubId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteHub(req) {
  const param = { ...req };
  console.log({api});
  return _http.delete(api(param.hubId), _http.headerJWT());
}

export function getHubDetails(id) {
  return _http.get(api("details/" + id), _http.headerJWT());
}