export function getInstallationV1JSON(data, ticketScope) {
    try {
        const d = new Date().toISOString().slice(0, 10);
        let indentificationNumber = data.identificationNo.toString().replace(/(?:\r\n|\r|\n|\t)/g, '');

        const params = {
            primary: {
                ticketPriority: data.ticketPriority,
                flowId: data.flowId,
                retailerId: data.retailerId,
                retailerNo: data.retailerNo?.toString() || "",
                conComplaintNo: data.conComplaintNo?.toString() || "",
                LRNumber: data.LRNumber?.toString() || "",
                orderNo: data.orderNo?.toString() || "",
                invoiceNo: data.invoiceNo?.toString() || "",
                parentTicket: "",
                ticketDetails: data.ticketDetails,
                specialInstructions: data.specialInstructions,
                buyerContactNumber: "9854745862",
                dateOfTicket: d?.toString() || "",
                isAppointmentReq: data.isAppointmentReq,
                isInstallationReq: data.isInstallationReq,
                isPhysicalEval: data.isPhysicalEval,
                isTechEval: data.isTechEval,
                isPackingReq: data.isPackingReq,
                paymentMode: "cash",
                inFlowBy: "EXCEL",
                ticketScope: ticketScope,
                deliveryType: data.deliveryType,
                carrier: data.carrier


            },
            primary_details: {
                ticketType: data.ticketType,
                serviceLevel: data.serviceLevel

            },
            products: [
                {
                    primary: {
                        productId: data.productId,
                        productCode: data.productCode,
                        brandId: data.brandId,
                        modelId: data.modelId,
                        brandName: data.brandName,
                        modelName: data.modelName,
                        brandCode: data.brandCode,
                        modelCode: data.modelCode,
                        productName: data.productName,
                        dateOfPurchase: data.dateOfPurchase,
                        identificationNo: indentificationNumber.trim(),
                        productDesc: data.productDesc,
                        problemDesc: data.problemDesc,
                        productValue: data.productValue?.toString() || "",
                        cost: data.cost?.toString() || "",
                        isUnderWarranty: data.isUnderWarranty,
                        productDimensions: {
                            productUnit: data.productDimensionUnit,
                            productLength: data.productLength,
                            productBreadth: data.productBreadth,
                            productHeight: data.productHeight
                        },
                        productWeight: {
                            productUnit: data.productWeightUnit,
                            productActualWeight: data.productActualWeight,
                            productVolumeWeight: data.productVolumeWeight
                        }
                    },
                    product_details: {
                        srcGeoCodes: data.srcGeoCodes,
                        dstGeoCodes: data.dstGeoCodes,
                        productBoxes: [
                            {
                                boxName: data.boxName,
                                boxSlNo: data.boxSlNo
                            }
                        ]

                    },
                    src_add: {
                        srcContactPerson: data.srcContactPerson,
                        srcOrg: "org",
                        srcAdd1: data.srcAdd1,
                        srcAdd2: data.srcAdd2,
                        srcLocation: data.srcLocation,
                        srcContact1: data.srcContact1?.toString() || "",
                        srcContact2: data.srcContact2?.toString() || "",
                        srcCity: data.srcCity,
                        srcState: data.srcState,
                        srcPincode: data.srcPincode?.toString() || "",
                        srcLandmark: data.srcLandmark,
                        srcEmailId: data.srcEmailId,
                        srcEmailIdAlt: data.srcEmailIdAlt,
                        srcType: data.srcType,
                        srcLatitude: data?.srcLatitude,
                        srcLongitude: data?.srcLongitude
                    },
                    dst_add: {
                        dstContactPerson: data.srcContactPerson,
                        dstOrg: "org",
                        dstAdd1: data.srcAdd1,
                        dstAdd2: data.srcAdd2,
                        dstLocation: data.srcLocation,
                        dstContact1: data.srcContact1?.toString() || "",
                        dstContact2: data.srcContact2?.toString() || "",
                        dstCity: data.srcCity,
                        dstState: data.srcState,
                        dstPincode: data.srcPincode?.toString() || "",
                        dstLandmark: data.srcLandmark,
                        dstEmailId: data.srcEmailId,
                        dstEmailIdAlt: data.srcEmailIdAlt,
                        dstType: data.srcType,
                        dstLatitude: data?.dstLatitude,
                        dstLongitude: data?.dstLongitude
                    },
                },
            ],
        };
        return params;
    }
    catch {
        console.log("Error in ticket creation");
    }
}