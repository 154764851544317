import React from 'react'
import FormLib from '../../../_common/FormLib'
import { getTripDetails } from '../../../../services/Tickets/TicketProcessServices';
import Table from '../../../_common/Table';

class TicketsList extends FormLib {

    state = {
        data: {
            ticketList: "",
        },
        errors: {},
        currentTicketRequest: {},
        listOfTickets: "",
        currentRequest: [],
        fileRequest: [],
    }

    // componentDidMount() {
    //     this.loadData(this.props.data);
    // }

    componentDidMount() {
        this.loadData()
    }

    // loadData = async (r) => {
    //     try {
    //         this.setState({ currentRequest: this.props.data });
    //     } catch {
    //         console.log("ticket list>>>>>>>>>>>>loadData");
    //     }
    // };

    loadData = async () => {
        // console.log(this.props.data.tripId)
        try {
            const resp = await getTripDetails(this.props.data.tripId)
            // console.log({ resp });
            // console.log("response", resp.data.data.tripAndTktDetails.files)
            this.setState({ currentRequest: resp.data.data.tripAndTktDetails.ticketDetails })
            this.setState({ fileRequest: resp.data.data.tripAndTktDetails.files })
        } catch (error) {
            console.log(error)
        }

    }
    tableMeta = {
        key: "tripId",
        columns: [
            { path: "ticketNo", label: "TicketNo" },
            { path: "blockStatus", label: "Ticket Status" },
            { path: "flowId", label: "Flow" }
        ]
    }

    render() {
        let ticketList = this.props.data.ticketsOftheTrip;
        // console.log({ ticketList });
        // let ticketData = ticketList.split(",");
        const imgStyle = {
            border: "1px solid #d3c4c4",
            width: "130px",
            height: "130px",
            "line-height": "130px",
            float: "left",
            cursor: "pointer",
            "font-size": "48px",
            color: "#d7caca",
            "background-color": "#f9f1f1",
            "border-radius": "6px",
        };

        return (
            <React.Fragment>
                <div
                    className="card"
                >
                    <div className="card-header bg-success text-white mb-4">
                        <div className="row">
                            <div className="col">
                                <span style={{ fontSize: "1.3rem" }}>Ticket List</span>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={this.props.closeModal}
                                    style={{
                                        display: "inline",
                                        float: "right",
                                        marginTop: "-0.4rem",
                                    }}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div
                        className="container   overflow-auto"
                        style={{ height: "26.7rem" }}
                    >
                        {/* {ticketData?.map((ticket) => (
                            <p key={ticket} className="p-0 mb-3">
                                <span
                                    className="bg-success text-white px-1 rounded"
                                    style={{ fontSize: "1rem" }}
                                >{` ${ticket} `}</span>
                            </p>
                        ))} */}
                        <div className="m-2" style={{ fontSize: 15, fontWeight: "bold" }}>
                            Tickets And Its Status:
                        </div>
                        {!this.props.loading && (
                            <>
                                <div className="container-fluid">
                                    <div style={{ overflow: "auto" }}>
                                        <Table
                                            clsName="table table-striped table-bordered table-hover"
                                            tableMeta={this.tableMeta}
                                            data={this.state.currentRequest}
                                            dropMenu={[]}
                                        />
                                    </div>
                                </div>

                            </>
                        )}
                        {/* {this.state.currentRequest.map((r) => {
                            return (
                                <div className="col m-4" style={{ fontWeight: "bold" }}>
                                    <div> <span className='bg-secondary rounded p-1'>{r.ticketNo}</span> - <span className='bg-success rounded p-1'>{r.blockStatus}</span></div>
                                </div>

                            )

                        })} */}

                        {/* <div className="row fixTableHead" style={{ height: "20rem", overflow: "scroll" }}> */}

                        {/* <div className="m-5 row fixTableHead" style={{ fontSize: 15, fontWeight: "bold", height: "20rem", overflow: "scroll" }}>
                            View Files :
                        </div> */}
                        <h4>View Files</h4>
                        <div style={{ display: "flex", flexWrap: "wrap", columnGap:"3rem" }} className="ml-5">
                            {this.state.fileRequest.map((r) => {
                                return (
                                    <div key={r} style={{ flexBasis: "30%", marginBottom: "10px" }}>
                                        {r.fileType === "jpg" && (
                                            <>
                                                <p>
                                                    <a
                                                        href={r.fileS3Path}
                                                        data-toggle="tooltip"
                                                        title={""}
                                                        style={{ display: "inline-block", marginRight: "1px" }}
                                                    >
                                                        <img
                                                            src={r.fileS3Path}
                                                            style={{
                                                                width: "10px",
                                                                height: "10px",
                                                                margin: "3px",

                                                            }}
                                                        />
                                                    </a>

                                                    <br></br>
                                                    <h6 className='text-info'>description :{r.info}</h6>
                                                </p>
                                            </>
                                        )}
                                        {r.fileType === "jpeg" && (
                                            <>
                                                <p>
                                                    <a
                                                        href={r.fileS3Path}
                                                        data-toggle="tooltip"
                                                        title={""}
                                                    >
                                                        <img
                                                            src={r.fileS3Path}
                                                            style={{
                                                                width: "130px",
                                                                height: "130px",
                                                                margin: "3px",
                                                            }}
                                                        />
                                                    </a>
                                                    <br></br>
                                                    <h6 className='text-info'>description :{r.info}</h6>
                                                </p>
                                            </>
                                        )}
                                        {r.fileType === "png" && (
                                            <>
                                                <p>
                                                    <a
                                                        href={r.fileS3Path}
                                                        data-toggle="tooltip"
                                                        title={""}
                                                    >
                                                        <img
                                                            src={r.fileS3Path}
                                                            style={{
                                                                width: "130px",
                                                                height: "130px",
                                                                margin: "3px",
                                                            }}
                                                        />
                                                    </a>
                                                    <br></br>
                                                    <h6 className='text-info'>description :{r.info}</h6>

                                                </p>
                                            </>
                                        )}
                                        {r.fileType === "gif" && (
                                            <>
                                                <p>
                                                    <a
                                                        href={r.fileS3Path}
                                                        data-toggle="tooltip"
                                                        title={""}
                                                    >
                                                        <img
                                                            src={r.fileS3Path}
                                                            style={{
                                                                width: "130px",
                                                                height: "130px",
                                                                margin: "3px",
                                                            }}
                                                        />
                                                    </a>
                                                    <br></br>
                                                        <h6 className='text-info'>description :{r.info}</h6>
                                                </p>
                                            </>
                                        )}
                                        {r.fileType === "xlsx" && (
                                            <>
                                                <p>
                                                    <a
                                                        href={r.fileS3Path}
                                                        target="_self"
                                                        title="Click to download"
                                                    >
                                                        <i
                                                            className="fa fa-file-excel-o text-center"
                                                            style={imgStyle}
                                                        ></i>
                                                    </a>
                                                    <br></br>
                                                    <h6 className='text-info'>description :{r.info}</h6>

                                                </p>
                                            </>
                                        )}
                                        {r.fileType === "txt" && (
                                            <>
                                                <p>
                                                    <a
                                                        href={r.fileS3Path}
                                                        target="_self"
                                                        title="Click to download"
                                                    >
                                                        <i
                                                            className="fa fa-file-text mb-1 text-center"
                                                            style={imgStyle}
                                                        ></i>
                                                    </a>
                                                    <br></br>
                                                    <h6 className='text-info'>description :{r.info}</h6>

                                                </p>
                                            </>
                                        )}
                                        {r.fileType === "pdf" && (
                                            <>
                                                <p>
                                                    <a
                                                        href={r.fileS3Path}
                                                        target="_self"
                                                        title="Click to download"
                                                    >
                                                        <i
                                                            className="fa fa-file-pdf-o mb-1 text-center"
                                                            style={imgStyle}
                                                        ></i>
                                                    </a>
                                                    <br></br>
                                                    <h6 className='text-info'>description :{r.info}</h6>

                                                </p>
                                            </>
                                        )}
                                        {r.fileType === "docx" && (
                                            <>
                                                <p>
                                                    <a
                                                        href={r.fileS3Path}
                                                        target="_self"
                                                        title="Click to download"
                                                    >
                                                        <i
                                                            className="fa fa-file-word-o mb-1 text-center"
                                                            style={imgStyle}
                                                        ></i>
                                                    </a>
                                                    <br></br>
                                                    <h6 className='text-info'>description :{r.info}</h6>

                                                </p>
                                            </>
                                        )}
                                        {r.fileType === "bmp" && (
                                            <>
                                                <p>
                                                    <a
                                                        href={r.fileS3Path}
                                                        target="_self"
                                                        title="Click to download"
                                                    >
                                                        <i
                                                            className="fa fa-file-word-o mb-1 text-center"
                                                            style={imgStyle}
                                                        ></i>
                                                    </a>
                                                    <br></br>
                                                    <h6 className='text-info'>description :{r.info}</h6>


                                                </p>
                                            </>
                                        )}
                                    </div>
                                )
                            })}
                        </div>
                        {/* </div> */}
                    </div>

                    {/* <div className="fixTableHead"
                        style={{ height: "20rem", overflow: "scroll" }}> */}

                    {/* </div> */}



                </div >
            </React.Fragment >
        )
    }
};

export default TicketsList;
