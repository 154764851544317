import React from "react";
import FormLib from "../../_common/FormLib";
import LocationTitle from "../../_subhead/LocationTitle";
import Joi from "joi-browser";
import { getPincodesFromPHP } from "../../../services/masterLocations/pincodeServices";

class PincodesMasters extends FormLib {
  state = {
    data: {
      pincode: [560056],
    },
    processState: [],
    errors: {},
    tableData: [],
  };

  schema = {
    pincode: Joi.string().required().label("Pincodes"),
  };

  doSubmit() {
    this.setState({ processState: [] });
    let pinArry = this.state.data.pincode.split(",");
    let processState = [];
    let t = pinArry.map((c) =>
      c.split(", ").reduce((p, n) => p && !!n.match(/^\d{6}$/), true)
    );
    for (let i = 0; i < pinArry.length; i++) {
      // console.log("case:", pinArry[i], t[i] ? "pass" : "fail");
      processState[i] = {
        action: "submit",
        serviceType: "",
        hub: "",
        city: "",
        region: "",
      };

      if ((pinArry[i], t[i] === "fail")) {
        let errors = this.state.errors;
        errors["pincode"] = `Pincode must be a 6 digits Pincode`;
        this.setState({
          errors,
        });
      }
    }
    const tableData = pinArry.map((p) => {
      return {
        pincode: p,
      };
    });

    this.setState({ processState });
    this.setState({ tableData });
  }

  async handleAPIHit() {
    let processState = [];
    let res;
    this.setState({ processState: [] });

    let i = 0;
    for (let r in this.state.tableData) {
      processState = this.state.processState;
      processState[i]["action"] = "Processing";
      this.setState({ processState });
      res = await getPincodesFromPHP(this.state.tableData[r].pincode);
      processState[i]["action"] = res.data.msg;
      processState[i]["serviceType"] = res.data.data.serviceType;
      processState[i]["hub"] = res.data.data.hub;
      processState[i]["hubCode"] = res.data.data.hubCode;

      processState[i]["city"] = res.data.data.city;
      processState[i]["distance"] = res.data.data.distance;

      processState[i]["hubId"] = res.data.data.hubId;
      processState[i]["cityId"] = res.data.data.cityId;
      i++;
    }
    this.setState({ processState });
  }
  render() {
    return (
      <React.Fragment>
        <LocationTitle subHeading="Pincode Values PHP" />
        <div className="pageContent">
          <div className="card">
            <div className="card-body">
              <form id="myform" onSubmit={this.handleSubmit}>
                <div className="form-row">
                  {this.rTA("Pincodes", "pincode", "col-12")}
                </div>
                <div className="row pull-right">
                  {this.rBtnSP("Stage", "Stage", "btn-primary btn-sm")}
                </div>
              </form>
            </div>

            <table className="col-12 table table-responsive">
              <thead>
                <tr>
                  <th>Pincodes</th>
                  <th>Service</th>
                  <th>Hub</th>
                  <th>HubCode</th>
                  <th>City</th>
                  <th>distance</th>
                  <th>hubId</th>
                  <th>cityId</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.tableData.map((tableData, i) => (
                  <tr key={tableData.pincode}>
                    <td>{tableData.pincode}</td>
                    <td>{this.state.processState[i]["serviceType"]}</td>
                    <td>{this.state.processState[i]["hub"]}</td>
                    <td>{this.state.processState[i]["hubCode"]}</td>
                    <td>{this.state.processState[i]["city"]}</td>
                    <td>{this.state.processState[i]["distance"]}</td>
                    <td>{this.state.processState[i]["hubId"]}</td>
                    <td>{this.state.processState[i]["cityId"]}</td>
                    <td>{this.state.processState[i]["action"]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />
          <button
            className="btn  btn-info  btn-sm pull-right"
            onClick={() => this.handleAPIHit()}
          >
            Submit
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default PincodesMasters;
