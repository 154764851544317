import React from "react";
import FormLib from "../../_common/FormLib";
import swal from "sweetalert";
import Joi from "joi-browser";
import $ from "jquery";
import Table from "../../_common/Table";
import Pagination from "../../_common/Pagination";

import LocationTitle from "../../_subhead/LocationTitle";
import toast from "../../../services/_toastService";
import { getHubs, saveHub, deleteHub } from "../../../services/ivr/IvrService";
import { getCities } from "../../../services/masterLocations/cityServices";
class Ivr extends FormLib {
  state = {
    data: {
      hubId: "",
      ivrNo: "",
      hub: "",
      city: "",
      status: "active",
      hubCode: "",
    },
    errors: {},
    formMode: "create",
    hubs: [],
    cities: [],
    loading: true,
  };

  schema = {
    hubId: Joi.optional().label("HubId"),
    ivrNo: Joi.optional().label("Ivr Number"),
    hub: Joi.string().required().label("Hub"),
    city: Joi.optional().label("City"),
    status: Joi.string().required().label("Status"),
    // hubCode:Joi.optional().labal("HubCode")
    hubCode: Joi.string().required().label("Hub Code"),
  };
  componentDidMount() {
    $("#hubCode").attr("disabled", "disabled");
    $("#hub").attr("disabled", "disabled");
    $("#city").attr("disabled", "disabled");

    this.loadHubs();
    //this.loadCities();
  }
  tableMeta = {
    key: "hubId",
    columns: [
      {
        key: "HUB_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnHub(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>

              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleDelete(r)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i> Delete
              </button>
            </div>
          </div>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      { path: "ivrNo", label: "IvrNo" },
      { path: "hub", label: "Hub" },
      { path: "hubCode", label: "Code" },
      { path: "city", label: "City" },
      { path: "status", label: "Status" },
    ],
  };
  handleOnHub(r) {
    let hubs = {};
    hubs.hubId = r.hubId;
    hubs.hub = r.hub;
    hubs.city = r.city;
    hubs.hubCode = r.hubCode;
    hubs.ivrNo = r.ivrNo;
    hubs.status = r.status;
    this.setState({ data: hubs, formMode: "edit" });
  }
  handlePageChange = (pageNo) => {
    this.props.history.push("/location/hub" + pageNo);

    this.loadHubs();
  };
  async doSubmit() {
    let res;

    if (this.state.btnValue === "edit") {
      res = await saveHub(this.state.data);
      this.resetData();
    }

    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadHubs();
    } else {
      toast.toastError(res.data.msg);
    }
  }
  doSubmitDelete = async (handleDelete, r) => {
    if (handleDelete) {
      alert(JSON.stringify(r));
      await deleteHub(r);
      this.loadHubs();

      swal("Hub has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Hub is safe!");
    }
  };
  handleDelete = async (r) => {
    swal({
      title: "Are you sure want to Delete?",
      text: "Once deleted, you will not be able to recover",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then(async (handleDelete) => {
      this.doSubmitDelete(handleDelete, r);
    });
  };

  async loadHubs() {
    this.setState({ loading: true });
    const res = await getHubs();
    if (res.data.success === true && res.data.data.mlHubsIvr) {
      this.setState({ hubs: res.data.data.mlHubsIvr });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }
  async loadCities() {
    this.setState({ loading: true });
    const res = await getCities();
    if (res.data.success === true) {
      this.setState({ cities: res.data.data.cities });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }
  resetData() {
    this.setState({
      data: {
        hubId: "",
        hub: "",
        hubCode: "",
        city: "",
        ivrNo: " ",
        status: "active",
      },
      formMode: "create",
    });
  }
  typConf = {
    value: "sortKey",
    label: "sort",
  };
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    return (
      <React.Fragment>
        <LocationTitle subHeading="IVR" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-8 col-sm-12">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Ivr</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.hubs}
                    dropMenu={[]}
                  />
                </div>
              )}
            </div>
            <div className="col-md-4 col-sm-12">
              {this.state.formMode === "view" && (
                <div className="card">
                  <div className="card-body">
                    <>
                      {this.state.curHub["hub"].trim()}
                      <br />
                      <div className="pull-right">
                        <span className={"badge   badge-light pull-left ml-1 "}>
                          {this.state.curHub["hubId"]}
                        </span>
                        <span
                          className={"badge   badge-success pull-left  mr-1 "}
                        >
                          {this.state.curHub["status"]}
                        </span>
                      </div>
                    </>
                  </div>
                </div>
              )}
              {this.state.formMode !== "view" && (
                <div className="card">
                  <div className="card-body">
                    <form>
                      <div className="form-row">
                        {this.rTBH("HubId", "hubId", "col-2")}
                        {this.rTB("Hub Code", "hubCode", "col-4")}
                        {this.rTB("Hub", "hub", "col-8")}
                        {this.rTB("City", "city", " col-md-12 ")}
                      </div>

                      <div className="form-row">
                        {this.rRBL(
                          "Status",
                          "status",
                          this.status,
                          this.stsConf
                        )}
                      </div>

                      <div className="form-row">
                        {this.rTB("Ivr No", "ivrNo", "col-12")}
                      </div>
                    </form>

                    {this.state.formMode === "edit" && (
                      <>
                        {this.rBtnSS("Save", "edit")}
                        {this.mResetBtn(() => this.resetData(), " pull-right ")}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <span>{JSON.stringify(this.state.data)}</span> */}
        </div>
      </React.Fragment>
    );
  }
}
export default Ivr;
