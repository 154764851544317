import * as act from "./userInfoTypes";

const initialState = {
  userInfo: [],
};

const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case act.SET_USERINFO:
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        error: "",
      };
    case act.SET_USERINFO_FAILURE:
      return {
        loading: false,
        users: action.payload.users,
        error: "",
      };

    default:
      return state;
  }
};

export default userInfoReducer;
