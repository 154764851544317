import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLPrevilages;
function api(path, subURL = "/prv-features") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getPrvFeatures(prvGroupId) {
  return _http.get(api("get-features/" + prvGroupId), _http.headerJWT());
}
export function createPrvFeatures(req) {
  const param = { ...req };
  delete param.previlagesId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function savePrvFeatures(req) {
  const param = { ...req };
  const id = param.previlagesId;
  //delete param.cityId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deletePrvFeatures(req) {
  const param = { ...req };
  return _http.delete(api(param.previlagesId), _http.headerJWT());
}
