import { getPlayersbyRole } from "../../../../services/Tickets/TicketServices";
import { updateTheDamagedBarcodeStatus } from "../../../../services/stockManagement/barcodeServices";
import { epoch2ddmmyyyyhhmmss, epoch2ddmmyyyyhhmmssAsTS, epoch2yyyymmddhhmmss } from "../../../../utils/DateUtils";
import FormLib from "../../../_common/FormLib";
import toast from '../../../../services/_toastService'
import swal from "sweetalert";
class ModalBarcodeStatus extends FormLib {

    state = {
        data: {
            barcodeRemarks: "",
            // hubs: this.props.barcodeDetails.hubId,
            hubs: "",
            players: "",
            remarksList: ""
        },
        errors: {},
        hubList: [],
        playersList: [],
        // barcodeDetails: this.props.barcodeDetails,
    }

    componentDidMount() {
        let hubList = JSON.parse(localStorage.getItem("hubs"));
        this.setState({ hubList });
    }

    localHandleChange = async (e) => {
        if (e.target.name === 'hubs') {
            this.setState({ playersList: [] })
            const playerList = await getPlayersbyRole(e.target.value);
            if (playerList.data.data.playersHubs.length > 0) {
                this.setState({ playersList: playerList.data.data.playersHubs })
            }
        }
    }

    remarksList = [
        { label: "Barcode Torned", value: "Barcode Torned" },
        { label: "Overwrite on Barcode", value: "Overwrite on Barcode" },
        { label: "Barcode Erased", value: "Barcode Erased" },
        { label: "Barcode Wet", value: "Barcode Wet" },
        { label: "Missing", value: "Missing" },
        // Barcode Torned
        // Overwrite on Barcode
        // Barcode Erased
        // Barcode Wet
        // Missing
    ]

    handleUpdateStatus = async () => {

        // if(this.props.bulkDamage === false){
        //     const paramArr = []
        //     const params = {
        //         clientId: this.props.barcodeDetails.clientId,
        //         barcodeHubId: this.props.barcodeDetails.hubId,
        //         hubId: this.state.data.hubs,
        //         slNoId: this.props.barcodeDetails.slNoId,
        //         playerId: this.state.data.players,
        //         status: "04damaged",
        //         remarks: this.state.data.remarksList,
        //         createdBy: this.state.data.players,
        //         createdAt: epoch2ddmmyyyyhhmmss(Date.now()),
        //         updatedBy: this.state.data.players,
        //         updatedAt: epoch2ddmmyyyyhhmmss(Date.now()),
        //     }

        //     console.log("params ====> ",params)

        //     if(params['playerId'] === "" || params['remarks'] === "" || params['hubId'] === ""){
        //         swal("Select all fields", { icon: "error" });
        //     }

        //     paramArr.push(params)
        //     console.log("paramArr==>paramArr",paramArr)

        //     const resp = await updateTheDamagedBarcodeStatus(paramArr);
        //     if(resp.data.success === true){
        //         console.log("rep=>", resp)
        //         toast.toastSuccess(resp.data.msg);
        //         this.props.loadDetails();
        //         this.props.closeModal();
        //     }
        // }
        // else{

        if (this.state.data.hubs === "" || this.state.data.remarksList === "" || this.state.data.players === "") {
            swal("All fields are Mandatory", { icon: "error" });
        }
        else if (this.props.barcodeDetails.status === "05used") {
            const paramArr = this.props.bulkDamageArr
            console.log("paramArr",this.props.bulkDamageArr)
            paramArr.map((f) => {
                f['hubId'] = this.state.data.hubs;
                f['playerId'] = this.state.data.players;
                f['status'] = "04damaged";
                f['remarks'] = this.state.data.remarksList;
                f['createdBy'] = this.state.data.players
                f['createdAt'] = epoch2ddmmyyyyhhmmssAsTS(Date.now())
                f['updatedBy'] = this.state.data.players
                f['updatedAt'] = epoch2ddmmyyyyhhmmssAsTS(Date.now())
            })
            console.log("this.props.bulkDamageArr1", paramArr)
            const resp = await updateTheDamagedBarcodeStatus(paramArr);
            if (resp.data.success === true) {
                console.log("rep=>", resp)
                toast.toastSuccess(resp.data.msg);
                this.props.loadDetails();
                    swal("Barcode Damaged Successfully and please attach the new Barcode in Attach additional Barcode Screen", { icon: "success" })
                this.props.closeModal();

            }
        }
        else{
        const paramArr = this.props.bulkDamageArr
            paramArr.map((f) => {
                f['hubId'] = this.state.data.hubs;
                f['playerId'] = this.state.data.players;
                f['status'] = "04damaged";
                f['remarks'] = this.state.data.remarksList;
                f['createdBy'] = this.state.data.players
                f['createdAt'] = epoch2ddmmyyyyhhmmssAsTS(Date.now())
                f['updatedBy'] = this.state.data.players
                f['updatedAt'] = epoch2ddmmyyyyhhmmssAsTS(Date.now())
            })
            console.log("this.props.bulkDamageArr", paramArr)
            const resp = await updateTheDamagedBarcodeStatus(paramArr);
            if (resp.data.success === true) {
                console.log("rep=>", resp)
                toast.toastSuccess(resp.data.msg);
                this.props.loadDetails();
                this.props.closeModal();
            }
            }

        // console.log("this.props.bulkDamageArr====>finalArr",finalArr)
        // }

    }


    render() {
        // console.log("this.state.props.bulkDamageArr", this.props.barcodeDetails.status)
        console.log("paramArr",this.props.bulkDamageArr)
        return (
            <div style={{ height: "15rem", width: "60rem", backgroundColor: "#fff", position: "absolute", marginLeft: "15rem", borderRadius: "8px" }}>
                <div className="bg-success mt-1 w-100" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderRadius: "8px" }}>
                    <h3 className="text-dark mt-2 ml-2">Update Barcode Status</h3>
                    <button onClick={this.props.closeModal} className="btn btn-danger" style={{ borderRadius: "8px" }}>X</button>
                </div>
                {/* <div style={{ display: "flex", flexDirection: "column", }}> */}
                <div className="" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly", marginTop: "5rem" }}>
                    {this.state.hubList.length > 0 && (
                        this.rSB("Hubs *", "hubs", this.state.hubList, {
                            value: "hubId",
                            label: "hub",
                        }))}
                    {
                        this.rSB("Players *", "players", this.state.playersList, {
                            value: "playerId",
                            label: "firstName",
                        }
                        )}
                    {this.rSB("Remarks *", "remarksList", this.remarksList, {
                        value: "value",
                        label: "label",
                    }
                    )}
                    <div>
                        <button className="btn btn-primary"
                            onClick={() => this.handleUpdateStatus()}
                        >Update</button>
                    </div>
                    {/* </div> */}
                </div>
                {/* <div className=" col" style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                        <div className="mt-3 w-50"> */}
                {/* {this.rSB("Remarks *", "remarksList", this.remarksList, {
                                value: "value",
                                label: "label",
                            }
                            )} */}
                {/* </div> */}
                {/* <div>
                            <button className="btn btn-primary" 
                            onClick={() => this.handleUpdateStatus()}
                            >Update</button>
                        </div> */}
                {/* </div> */}

            </div>


            // </div>
        )
    }
}

export default ModalBarcodeStatus;