import React, { Component } from "react";
import { NavLink } from "react-router-dom";


class DropDownMenu extends Component {

  render() {
    const menuItems= this.props.menuItems;
     const menuKey  = this.props.menuKey;
    return (
      <React.Fragment>
        <button
          type="button"
          className="btn dropdown-icon  lib-drop-down-menu "
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-h"></i>
        </button>

        <div className="dropdown-menu dropdown-menu-right">

         {menuItems.map((mi) => (
            
            <NavLink to={mi.toLinks+"/"+menuKey} className="dropdown-item" type="button" key={mi.label}>
                <i className={"fa " + mi.icon} aria-hidden="true"></i> {mi.label}
            </NavLink>
            
            ))} 
          


        </div>
      </React.Fragment>
    );
  }
}

export default DropDownMenu;
