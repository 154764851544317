import React from "react";
import FormLib from "./../../_common/FormLib";

import PrvMenus from "./PrvMenus";

import ModalMenuGroup from "./ModalMenuGroup";
import ModalMenu from "./ModalMenu";
import MenuFeatures from"./MenuFeatures";
import PrevilagesTitle from "../../_subhead/PrevilagesTitle";

import toast from "../../../services/_toastService";

import { getPrvMenuGroups } from "./../../../services/menus/PrvMenuGroupsServices";
import { getPrvMenus } from "./../../../services/menus/PrvMenusServices";
import { getFeatures } from "./../../../services/menus/MenuFeaturesServices";

class Menus extends FormLib {
  state = {
    data: {},
    errors: {},
    menuGroups: [],
    menus: [],
    curGroupName: {menuGroupId: ""},
    curMenu: {menuId: ""},
    curFeatures: {},
    menuFeatures:[]
  };
  schema = {};

  componentDidMount() {
    this.loadPrvMenuGroups();
  }

  loadPrvMenuGroups = async (m) => {
    const res = await getPrvMenuGroups();
    if (res.data.success === true) {
      this.setState({ menuGroups: res.data.data.menuGroups });
      toast.toastSuccess("PrvMenuGroups Loaded Successfully");
    } else {
      toast.toastError("failed");
    }
  };

  loadPrvMenus = async (menuGroupId, modReset = true) => {

    if (modReset === true) {
      this.setState({ menus: [] });
      this.setState({ curMenu: {menuId:""} });
    }
    let curGroupName;
    const filter = this.state.menuGroups.filter((r) => {
      return r.menuGroupId === menuGroupId ? r : null;
    });

    if (!filter[0]) {
      curGroupName = { menuGroupId: "" };
    } else {
      curGroupName = filter[0];
    }

    this.setState({ curGroupName: curGroupName });

    if (menuGroupId !== "") {
      const res = await getPrvMenus(menuGroupId);
      this.setState({ menus: res.data.data.menus });
    } else {
      this.setState({ menus: [] });
    }
  };

  // -------------------------------------

  loadMenuFeatures = async (g, modReset = true) => {
    if (modReset === true) {
      this.setState({ curMenu: g });
      this.setState({ curFeatures: {} });
    }
   
    if (g["menuId"] !== "") {
      const res = await getFeatures(g["menuId"]);
      let data = res.data.data.menuFeatures ;
      data.sort((a, b) =>{ return a.title.localeCompare(b.title)});
      this.setState({ menuFeatures: data});
    } else {
      this.setState({ menuFeatures: [] });
    }
  };

  // -------------------------------------

  handleMenuGroupChange(e) {
    this.loadPrvMenus(e.target.value);
    this.handleMenuUnload();
    
  }

  handleMenuChange = (m) => {
    // this.setState({ curMenu: m });
    this.loadMenuFeatures(m);
    this.setState({ menuFeatures: [] });
  };

  handleMenuUnload = () => {
    // this.setState({ menus: [] });
    this.setState({ menuFeatures: [] });
    this.setState({ curMenu: { menuId: "" }, curFeatures: {} });
    this.setState({ menuFeatures: [] });
  };

  localHandleChange = (e) => {
    if (e.target.id === "menuGroups") {
      this.handleMenuGroupChange(e);
    }
  };

  render() {
    return (
      <React.Fragment>
         <PrevilagesTitle subHeading="App Menus" />
        <div className="pageContent">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10">
                {this.rSB("MenuGroups", "menuGroups", this.state.menuGroups, {
                  value: "menuGroupId",
                  label: "groupName",
                })}
              </div>
              <div className="menu col-md-2 ">
                {this.state.curGroupName.menuGroupId === "" && (
                  <button
                    type="button"
                    className="btn btn-sm btn-info pull-left"
                    data-toggle="modal"
                    data-target="#myGroupModel"
                  >
                    <i className={"fa fa-plus"}/>
                  </button>
                )}
                {this.state.curGroupName.menuGroupId !== "" && (
                  <button
                    type="button"
                    className="btn btn-sm btn-success pull-left in-line"
                    data-toggle="modal"
                    data-target="#myGroupModel"
                  >
                    <i className={"fa fa-pencil"}/>
                  </button>
                )}
                <button
                  type="button"
                  className="btn btn-sm btn-secondary pull-left"
                  onClick={() => this.loadPrvMenuGroups()}
                >
                  <i className={"fa fa-refresh"}/>
                </button>
              </div>
            </div>

            <div className="card-deck">
              <PrvMenus
                menus={this.state.menus}
                curGroupName={this.state.curGroupName}
                curMenu={this.state.curMenu}
                onMenuChange={this.handleMenuChange}
                onMenuUnload={this.handleMenuUnload}
              />

              <MenuFeatures
                loadMenuFeatures={this.state.menuFeatures}
                curMenu={this.state.curMenu}
                onMenuChange={this.handleMenuChange}
                key={this.state.curMenu["menuId"]}
              />
            </div>
          </div>
        </div>
        <>
          <ModalMenuGroup
            curGroupName={this.state.curGroupName}
            onloadPrvMenuGroups={this.loadPrvMenuGroups}
            key={this.state.curGroupName["menuGroupId"]}
          />
          <ModalMenu
            menuGroups={this.state.menuGroups}
            curGroupName={this.state.curGroupName}
            curMenu={this.state.curMenu}
            onLoadPrvMenus={this.loadPrvMenus}
            key={
              this.state.curMenu["menuId"] ||
              "m_" + this.state.curGroupName["menuGroupId"]
            }
          />
          
        </>
      </React.Fragment>
    );
  }
}
export default Menus;
