import React from "react";
import formats from "./ToolbarOptions.js";

const renderOptions = (formatData, i) => {
  const { className, options } = formatData;
  return (
    <select className={className} key={"edtSelect" + i}>
      <option selected="selected"></option>
      {options.map((value, index) => {
        return <option value={value} key={"op_" + value + index}></option>;
      })}
    </select>
  );
};
const renderSingle = (formatData, i) => {
  const { className, value } = formatData;
  return (
    <button className={className} value={value} key={"edtBtn" + i}></button>
  );
};
const CustomToolbar = () => (
  <div id="toolbar">
    {formats.map((classes, index) => {
      return (
        <span className="ql-formats" key={"sn_" + index}>
          {classes.map((formatData, index) => {
            return formatData.options
              ? renderOptions(formatData, index)
              : renderSingle(formatData, index);
          })}
        </span>
      );
    })}
  </div>
);
export default CustomToolbar;
