import React from "react";
import FormLib from "./../../../_common/FormLib";
import toast from "../../../../services/_toastService";
import { assign, unassign } from "../../../../services/players/DesignationsRolesServices";


class DesignationsRoles extends FormLib {

  state = {
    data: {},
    errors: {},
    curDesignation: { designationId: "" },
    designationRoles: []
  };

  isCur(id, cur) {
    if (id === cur) {
      return "curTasks";
    }
    return " ";
  }
  componentDidMount() {
    this.setDesignationRole(this.props.loadDesignationRoles);
    this.setState({ curDesignation: this.props.curDesignation });
  }

  setDesignationRole = (g) => {
    this.setState({ designationRoles: g });
  }

  localHandleChange = (e) => {
    this.asignUnAsignDesignationRole(e);
  }

  asignUnAsignDesignationRole = (e) => {
    let setData = {};
    setData.designationId = this.state.curDesignation.designationId;
    if (e.target.checked) {
      setData.roleId = e.target.value;
      // setData.status = "active";
      setData.count = 0;
      this.assignUnassign('assign', setData);
    } else {
      setData.roleId = e.target.value;
      // setData.status = "inactive";
      setData.count = 0;
      this.assignUnassign('unAssign', setData);
    }
  }

  assignUnassign = async (type, setData) => {

    if (type === 'assign') {
      const res = await assign(setData);
      if (res.data.success) {
        toast.toastSuccess(res.data.msg);
      } else {
        toast.toastError(res.data.msg);
      }
    } else {
      const res = await unassign(setData);
      if (res.data.success) {
        toast.toastSuccess(res.data.msg);
      } else {
        toast.toastError(res.data.msg);
      }
    }
    this.props.onDesignationChange(this.props.curDesignation);
  }
  chkStatus = false;

  render() {
    const rolesData = this.props.loadDesignationRoles;
    return (
      <div className="card">
        <h5 className="card-header">Designations Roles</h5>
        <div className="card-body">
          <div className="page-todo">
            <div className="tasks">

              {rolesData.map((r) => {
                this.chkStatus = r.designationId === null ? false : true;

                return (<div className="task nil" key={r.roleId}>
                  <div className="desc">
                    <span>
                      <div className="title">
                        {this.rCBHLLS(
                          "cb_" + r.roleId,
                          [{ label: r.role, value: r.roleId }],
                          { value: "value", label: "label" },
                          this.chkStatus,
                          "m-0"
                        )}
                      </div>
                    </span>
                    <div>
                      <span className={"badge badge-success pull-left mr-1 " + "badge-" +
                        r.division}>{r.division}
                      </span>
                      {/* <span className={"badge   badge-success pull-left mr-1 " + "badge-" +
                            r.module}>{r.module}
                          </span> */}
                      <span className={"badge badge-success pull-left mr-1 " + "badge-" +
                        r.groupName}>{r.groupName}
                      </span>
                    </div>
                  </div>
                </div>
                )})}
            </div>
          </div>
        </div>

      </div>
    );
  }

}

export default DesignationsRoles;