import React from "react";
import LocationTitle from "../../_subhead/LocationTitle";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import Table from "../../_common/Table";
import toast from "../../../services/_toastService";
import Pagination from "./../../_common/Pagination";
import ModelProductsCategory from "./ModelProductsCategory";
import ModelProductsSubCategory from "./ModelProductsSubCategory";

import {
  createProduct,
  updateProduct,
  deleteProduct,
  getProductsBySearch
} from "../../../services/products/productServices";
import {
  getProductsSub,
  getAllSubProducts
} from "../../../services/products/productSubCategoryServices";
import { getProductCat } from "../../../services/products/productCategoryServices";
// import ProductsCategoryModels from "./ProductsCategoryModels";


class Products extends FormLib {
  state = {
    loading: true,
    data: {
      productId: "",
      product: "",
      prodCatId: "",
      prodCode: "",
      prodDesc: "",
      status: "active",
      productSearch:""
    },
    errors: {},
    productsCategory: [],
    products: [],
    productsSub:[],
    productsSubAll: [],
    curProductsCategory: { prodCatId: "" },
    curProductsSubCategory: { prodSubCatId: "" },

    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };
  schema = {
    prodCatId: Joi.optional().label("prodCatId"),
    prodSubCatId:Joi.optional().label("prodSubCatId"),
    productId: Joi.optional().label("productId"),
    productSearch: Joi.optional().label("productSearch"),
    status: Joi.optional().label("Product Status"),
    product: Joi.string().required().label("Product Name "),
    prodCode: Joi.string().required().label("Product Code"),
    prodDesc: Joi.string().required().label("Product Desc"),
  };

  tableMeta = {
    key: "productId",
    columns: [
      {
        key: "PRODUCT_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnAction(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      // { path: "productId", label: "Product Id" },
      { path: "product", label: "Name" },
      // { path: "prodCatId", label: "Product Category Id" },
      { path: "prodCode", label: "Code" },
      //   { path: "prodDesc", label: "Product Desc" },
      { path: "status", label: "Status" },
    ],
  };
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  handleOnAction = (r) => {
    let onEditParam = {};
    onEditParam.productId = r.productId;
    onEditParam.prodCatId = r.prodCatId;
    onEditParam.prodSubCatId = r.prodSubCatId;
    onEditParam.product = r.product;
    onEditParam.prodCode = r.prodCode;
    onEditParam.prodDesc = r.prodDesc;
    onEditParam.status = r.status;
    this.setState({ data: onEditParam });
  };

  componentDidMount() {
    this.loadProductCat();
  }

  async loadProductCat() {
    this.setState({ loading: true });
    const res = await getProductCat();
    if (res.data.success === true) {
      this.setState({ productsCategory: res.data.data.productsCategory });
    } else {
      console.log("Error while getting Product Category : " + res.data.msg);
    }
    this.setState({ loading: false });
  };

  async loadProducts(prodCatId, pageNo) {
    this.setState({ loading: true });
    const res = await getProductsSub(prodCatId, pageNo);
    if (res.data.success === true) {
      if (res.data.data.length != 0) {
        this.setState({ productsSub: res.data.data.productscategorysub });
        this.setState({ pageLinks: res.data.data.pageLinks });
        toast.toastSuccess(res.data.msg);
      } else {
        toast.toastInfo("No product found for this category");
        this.setState({ productsSub: [] });
        this.setState({
          pageLinks: {
            pageNo: 1,
            perPage: 0,
            itemsCount: 0,
            lastPage: 0,
          },
        });
      }
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };


  handlePageChange = (pageNo) => {
    this.props.history.push("/products/product/" + pageNo);
    this.loadProducts(this.state.curProductsCategory.prodCatId, pageNo);
  };

  loadCurrectCategory = async (prodCatId, modReset = true) => {
    if (modReset === true) {
      this.setState({ productsSubAll: [] });
      this.setState({ curProductsCategory: { prodCatId: "" } });
    }
    let curProductCat;
    const filter = this.state.productsCategory.filter((r) => {
      return r.prodCatId === prodCatId ? r : null;
    });

    if (!filter[0]) {
      curProductCat = { prodCatId: "" };
    } else {
      curProductCat = filter[0];
    }

    this.setState({ curProductsCategory: curProductCat });

    if (prodCatId !== "") {
      this.loadProducts(prodCatId, this.state.pageLinks.pageNo);
    } else {
      this.setState({ productsSub: [] });
    }
  };

  handleCategoryChange = (e) => {
    this.loadCurrectCategory(e.target.value);
    this.state.curProductsSubCategory.prodSubCatId="";

  };

  localHandleChange = (e) => {
    if (e.target.id === "prodCatId") {
      this.handleCategoryChange(e);
    }
    if (e.target.id === "prodSubCatId") {
      this.handleSubCategoryChange(e);
    }
  };

  handleSubCategoryChange = (e) => {
    this.loadCurrectSubCategory(e.target.value);
    
  };


  // -------------------------------------------product sub category----------------------------------------//

  async loadProductsSubAll(prodSubCatId, pageNo) {
    this.setState({ loading: true });
    const res = await getAllSubProducts(prodSubCatId, pageNo);
    if (res.data.success === true) {
      if (res.data.data.length != 0) {
        this.setState({ productsSubAll: res.data.data.products });
        this.setState({ pageLinks: res.data.data.pageLinks });
        toast.toastSuccess(res.data.msg);
      } else {
        toast.toastInfo("No product found for this category");
        this.setState({ productsSubAll: [] });
        this.setState({
          pageLinks: {
            pageNo: 1,
            perPage: 0,
            itemsCount: 0,
            lastPage: 0,
          },
        });
      }
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  };

  
  handlePageChange = (pageNo) => {
    this.props.history.push("/products/product/" + pageNo);
    this.loadProductsSubAll(this.state.curProductsSubCategory.prodSubCatId, pageNo);
  };

  loadCurrectSubCategory = async (prodSubCatId, modReset = true) => {
    if (modReset === true) {
      this.setState({ productsSubAll: [] });
      this.setState({ curProductsSubCategory: { prodSubCatId: "" } });
    }
    let curProductSubCat;
    const filter = this.state.productsSub.filter((r) => {
      return r.prodSubCatId === prodSubCatId ? r : null;
    });

    if (!filter[0]) {
      curProductSubCat = { prodSubCatId: "" };
    } else {
      curProductSubCat = filter[0];
    }

    this.setState({ curProductsSubCategory: curProductSubCat });

    if (prodSubCatId !== "") {
      this.loadProductsSubAll(prodSubCatId, this.state.pageLinks.pageNo);
    } else {
      this.setState({ productsSubAll: [] });
    }
  };



  // -------------------------------------------product sub category----------------------------------------//

  resetData = () => {
    let data = {
      prodCatId: this.state.curProductsCategory.prodCatId,
      productId: "",
      product: "",
      prodCode: "",
      prodDesc: "",
      prodSubCatId:this.state.curProductsSubCategory.prodSubCatId,
      status: "active",
    }
    this.setState({ data });
  };

  

  search = async () => {
    let param = {
      "searchString": this.state.data.productSearch,
      "prodSubCatId":this.state.curProductsSubCategory.prodSubCatId
    }

    let res = await getProductsBySearch(param, this.state.pageLinks.pageNo);
    if (res.data.success) {
      if (res.data.data.products.length != 0) {
        this.setState({ productsSubAll: res.data.data.products });
        this.setState({ pageLinks: res.data.data.pageLinks });
      } else {
        toast.toastInfo("No Data found !!!");
      }
    } else {
      toast.toastError(res.data.msg);
    }
  }

  doSubmit = async () => {
    let res;
    if (this.state.btnValue === "create") {
      res = await createProduct(this.state.data);
    }
    if (this.state.btnValue === "edit") {
      res = await updateProduct(this.state.data);
    }
    if (this.state.btnValue === "delete") {
      res = await deleteProduct(this.state.data);
    }

    if (res.data.success === true) {
      this.loadProductsSubAll(
        this.state.curProductsSubCategory.prodSubCatId,
        this.state.pageLinks.pageNo
      );
      this.resetData();
      toast.toastSuccess(res.data.msg);
    } else {
      toast.toastError(res.data.msg);
    }
  };

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Products" />
        <div className="pageContent">
          <div className="row">
            <div className="col-4 ml-3 mr-10">
              {this.rSB(
                "Product Category",
                "prodCatId",
                this.state.productsCategory,
                {
                  value: "prodCatId",
                  label: "category",
                }
              )}
            </div>
            <div className="menu col-2 "style= {{ marginRight: "-42px"}}>
              {this.state.curProductsCategory.prodCatId === "" && (
                <button
                  type="button"
                  title="Add Category"
                  className="btn btn-sm btn-info pull-left"
                  data-toggle="modal"
                  data-target="#myMaterialModal"
                >
                  <i className={"fa fa-plus"} />
                </button>
              )}
              {this.state.curProductsCategory.prodCatId !== "" && (
                <button
                  type="button"
                  className="btn btn-sm btn-success pull-left"
                  data-toggle="modal"
                  data-target="#myMaterialModal"
                >
                  <i className={"fa fa-pencil"} />
                </button>
              )}
              <button
                type="button"
                className="btn btn-sm btn-secondary pull-left"
                onClick={() =>this.loadProductCat()}
                title="Refresh Product Category"
              >
                <i className={"fa fa-refresh"} />
              </button>
            </div>


{/*   // -------------------------------------------product sub category----------------------------------------// */}
            {this.state.curProductsCategory.prodCatId !== "" &&  (
                          <div className="col-4 ">
                          {this.rSB(
                            "Product Sub Category",
                            "prodSubCatId",
                            this.state.productsSub,
                            {
                              value: "prodSubCatId",
                              label: "subCat",
                            }
                          )}
                        </div>
                        )}

            <div className="menu col-2 ">
              {this.state.curProductsCategory.prodCatId !== "" && this.state.curProductsSubCategory.prodSubCatId === "" && (
                <button
                  type="button"
                  title="Add Category"
                  className="btn btn-sm btn-info pull-left"
                  data-toggle="modal"
                  data-target="#myMaterialSubModal"
                >
                  <i className={"fa fa-plus"} />
                </button>
              )}
              {this.state.curProductsCategory.prodCatId !== "" && this.state.curProductsSubCategory.prodSubCatId !== "" && (
                <button
                  type="button"
                  className="btn btn-sm btn-success pull-left"
                  data-toggle="modal"
                  data-target="#myMaterialSubModal"
                >
                  <i className={"fa fa-pencil"} />
                </button>
              )}
               {this.state.curProductsCategory.prodCatId !== "" && (
              <button
                type="button"
                className="btn btn-sm btn-secondary pull-left"
                onClick={() =>
                  this.loadProducts(
                    this.state.curProductsCategory.prodCatId,
                    this.state.pageLinks.pageNo
                  )
                  
                }
                title="Refresh Product Sub Category"
              >
                <i className={"fa fa-refresh"} />
              </button>
                )} 
            </div>

{/*   // -------------------------------------------product sub category----------------------------------------//*/}

            {this.state.curProductsSubCategory.prodSubCatId !== "" &&  this.state.productsSubAll.length !==0 &&(
              <>
                <div className="col-3 ml-3">
                  {this.rTB("Search...", "productSearch")}
                </div>
                <div className="col-1">
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-info pull-left"
                    title=" Search by name | code"
                    disabled={this.state.data.productSearch===""}
                    onClick={() => this.search()}
                  >
                    <i className={"fa fa-search"} />
                  </button>
                </div>

              </>
            )
            }
          </div>
          {this.state.curProductsCategory.prodCatId === "" && (
            <div className="jumbotron jumbotron-fluid">
              <p className="lead text-center">Select Product Category</p>
            </div>
          )}

          {this.state.curProductsSubCategory.prodSubCatId === "" && (
            <div className="jumbotron jumbotron-fluid">
              <p className="lead text-center">Select Sub Product Category</p>
            </div>
          )}
          {this.state.curProductsSubCategory.prodSubCatId !== "" &&   (
            <div className="row">
              <div className="col-md-6">
                {this.state.loading && (
                  <div className="container-fluid">
                    <h1>Products Loading...</h1>
                  </div>
                )}
                {!this.state.loading && (
                  <div className="container-fluid">
                    <Table
                      clsName="table table-striped tabele-bordered table-hover"
                      tableMeta={this.tableMeta}
                      data={this.state.productsSubAll}
                      dropMenu={[]}
                    />
                    <Pagination
                      pageNo={pageNo}
                      perPage={perPage}
                      itemsCount={itemsCount}
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                )}
              </div>
              <div className="col-6">
                <div className="card pt-3">
                  <div className="card-body">
                    <form>
                      <div className="form-row">
                        <div className="col-4">
                          {this.rTB("Product Name", "product")}
                        </div>
                        <div className="col-4">
                          {this.rTB("Product Code", "prodCode")}
                        </div>
                        <div className="col-4">
                          {this.rRBL(
                            "Status",
                            "status",
                            this.status,
                            this.stsConf
                          )}
                        </div>

                        <div className="col-12">
                          {this.rTA("Product Desc", "prodDesc")}
                        </div>
                      </div>

                    </form>
                    {this.state.data.productId === "" &&
                      this.rBtnSP("Create", "create")}
                    {this.state.data.productId !== "" && (
                      <>
                        {this.rBtnSS("Edit", "edit")}
                        {this.rBtnSD("Delete", "delete")}
                      </>
                    )}
                    <button
                      type="button"
                      className="btn btn-sm btn-warning pull-right"
                      onClick={() => this.resetData()}
                      title="Reset"
                    >
                      <i className={"fa fa-unlink"} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <ModelProductsCategory
          curProductsCategory={this.state.curProductsCategory}
          onLoadProductCat={this.loadProductCat}
          key={this.state.curProductsCategory.prodCatId}
        />
      {this.state.curProductsCategory.prodCatId !== "" && 
        <ModelProductsSubCategory
          curProductsCategory={this.state.curProductsCategory}
          curProductsSubCategory={this.state.curProductsSubCategory}
          key={this.state.curProductsSubCategory.prodSubCatId}
        />
         } 
     
      </React.Fragment>
    );
  }
}
export default Products;
