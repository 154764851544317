import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";

class ModuleCreate extends FormLib {
  state = {
    data: {
      username: "",
      password: "",
      pickupDate: this.getDatebyDays(1),
      pickupTime: "14:00",

      moduleowner: "",
      projectid: "",
      projectmodule: "",
      moduleid: "",
    },
    errors: {},
  };
  //   gender = [
  //     { label: "Male", value: "male" },
  //     { label: "Female", value: "female" },
  //   ];
  schema = {
    ticket1: Joi.string().label("Ticket 1"),
    ticket2: Joi.string().label("Ticket 2"),
  };

  render() {
    //const [selectedDate, setSelectedDate] = useState(null);

    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="">
                <div className="card-body"></div>
                <div className="form-row">
                  {this.rTB(" Module", "module", "col-12")}
                </div>
                <div className="form-row">
                  {this.rTB(" Owner", "owner", "col-12")}
                </div>
              </div>

              <div className="p-2">{this.rBtnSS("Save", "Save")}</div>
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* </div> */}
      </React.Fragment>
    );
  }
}
export default ModuleCreate;

//import React, { Component } from 'react'
//import ModuleCreate from './ModuleCreate';
//  class ModuleCreate extends React.Component {
//     render() {
//         return (
//             <div className="card-deck">

// <div className="card col-md-6 ">
//                     <form>
//   <div className="form-group">
//     <label for="exampleInputEmail1">Email address</label>
//     <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email"/>
//     <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
//   </div>
//   <div className="form-group">
//     <label for="exampleInputPassword1">Password</label>
//     <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password"/>
//   </div>
//   <div className="form-check">
//     <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
//     <label className="form-check-label" for="exampleCheck1">Check me out</label>
//   </div>
//   <button type="submit" className="btn btn-primary">Submit</button>
// </form>
//   </div>
//   </div>

//         );
//     }
// }

// export default ModuleCreate;
