import React, { Component } from 'react'
import FormLib from '../../../_common/FormLib';
import swal from "sweetalert";
import { getLinehaulProductsV1 } from '../../../../services/linehaul/linehaulServices';
import Table from '../../../_common/Table';

export default class UpdateShipmentNoModalV1 extends FormLib {
    state = {
        shipmentNo: "",
        linehaulProps: {}
    }


    handleClickClose = (e) => {
        this.setState({ shipmentNo: "" });
    };

    handleClickSave = async (e) => {
        if (this.state.shipmentNo === "") {
            swal({ icon: "error", title: "shipment no cannot be empty" });
            return null;
        }
        await this.props.handleSave(this.state.shipmentNo);
        // if (closeButtonRef.current && resp) closeButtonRef.current.click();
    }

    tableMeta = {
        key: "ticketLinehaulId",

        columns: [
            { path: "ticketNo", label: "Ticket No" },
            { path: "prdCode", label: "Product Code" },
            { path: "prdName", label: "Product Name" },
            // { path: "productActualWeight", label: "Product Actual Weight" },
            {
                key: "ticketLinehaulId",
                action: (r) => 
                <>
                {r.productLinehaulActualWeight === "0" ? r.productActualWeight : r.productLinehaulActualWeight}                
                </>,
                cusClass: " ",
                icon: () => <>Product Actual Weight</>,
              },
            // { path: "productBreadth", label: "Product Breadth" },
            {
                key: "ticketLinehaulId",
                action: (r) => 
                <>
                {r.productLinehaulL === "0" ? r.productLength : r.productLinehaulL}                
                </>,
                cusClass: " ",
                icon: () => <>Product Length</>,
              },
            // { path: "productHeight", label: "Product Height" },
            {
                key: "ticketLinehaulId",
                action: (r) => 
                <>
                {r.productLinehaulB === "0" ? r.productBreadth : r.productLinehaulB}                
                </>,
                cusClass: " ",
                icon: () => <>Product Breadth</>,
              },
            // { path: "productLength", label: "Product Length" },
            {
                key: "ticketLinehaulId",
                action: (r) => 
                <>
                {r.productLinehaulH === "0" ? r.productHeight : r.productLinehaulH}                
                </>,
                cusClass: " ",
                icon: () => <>Product Height</>,
              },

              {
                key: "ticketLinehaulId",
                action: (r) => 
                <>
                {r.productLinehaulVolumetricWeight === "0" ? r.productVolumetricWeight : r.productLinehaulVolumetricWeight}                
                </>,
                cusClass: " ",
                icon: () => <>Product Volumetric Weight</>,
              },
            // { path: "productVolumetricWeight", label: "Product VolWt" },
            // { path: "productActualWeight", label: "Product AcWt" },
            { path: "prdBarcode", label: "Product Barcode" },
        ]

    }


    render() {

        const moreInfo = this.props.shipmentDetails?.moreInfo
            ? JSON.parse(this.props.shipmentDetails.moreInfo)
            : {};

        return (
            <div
                className="modal fade"
                id={this.props.modalId}
                data-bs-keyboard="false"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
                tabIndex="-1"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.shipmentDetails?.ticketNo}</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <div className="modal-body">
                            {/* <p style={{overflowWrap: "break-word"}}>{JSON.stringify(shipmentDetails)}</p> */}
                            <div className="container">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title fs-5">Linehaul details</h4>
                                        <p className="d-flex justify-content-between mb-0 pb-0">
                                            <span>{"Linehaul Ref No"}</span>
                                            <span>{this.props.shipmentDetails.linehaulSeq}</span>
                                        </p>
                                        <p className="d-flex justify-content-between mb-0 pb-0">
                                            <span>{"Transportation Mode"}</span>
                                            <span>{this.props.shipmentDetails.transMode}</span>
                                        </p>
                                        <p className="d-flex justify-content-between mb-0 pb-0">
                                            <span>{"Logistics Provider"}</span>
                                            <span>{(this.props.shipmentDetails.logisticsProvider)}</span>
                                        </p>
                                        {moreInfo.paymentMode && (
                                            <p className="d-flex justify-content-between mb-0 pb-0">
                                                <span>{"Payment Mode"}</span>
                                                <span>{moreInfo.paymentMode}</span>
                                            </p>
                                        )}
                                        <p className="d-flex justify-content-between mb-0 pb-0">
                                            <span>{"Destination Type"}</span>
                                            <span>{this.props.shipmentDetails.destination}</span>
                                        </p>
                                        {this.props.shipmentDetails.vehicleNo && (
                                            <p className="d-flex justify-content-between mb-0 pb-0">
                                                <span>{"Vehicle Number"}</span>
                                                <span>{this.props.shipmentDetails.vehicleNo}</span>
                                            </p>
                                        )}

                                        {
                                            <h5 className="card-subtitle mb-2 fs-5 mt-2">
                                                Box details
                                            </h5>
                                        }
                                        <p className="d-flex justify-content-between mb-0 pb-0">
                                            <span>{"Products count"}</span>
                                            <span>{this.props.productArr.length ? this.props.productArr.length : ""}</span>
                                        </p>
                                        {
                                            <p className="d-flex justify-content-between mb-0 pb-0">
                                                <span>{"Is Fragile"}</span>
                                                <span>{this.props.shipmentDetails.isFragile ? this.props.shipmentDetails.isFragile : "NA"}</span>
                                            </p>
                                        }
                                            <p className="d-flex justify-content-between mb-0 pb-0">
                                                <span>{"Box Length (cm)"}</span>
                                        {this.props.shipmentDetails.boxL ? (
                                                <span>{this.props.shipmentDetails.boxL}</span>
                                                ) : "NA"}
                                            </p>
                                            <p className="d-flex justify-content-between mb-0 pb-0">
                                                <span>{"Box Breadth (cm)"}</span>
                                        {this.props.shipmentDetails.boxB ? (
                                                <span>{this.props.shipmentDetails.boxB}</span>
                                                ) : "NA"}
                                            </p>
                                            <p className="d-flex justify-content-between mb-0 pb-0">
                                                <span>{"Box Height (cm)"}</span>
                                        {this.props.shipmentDetails.boxW ? (
                                                <span>{this.props.shipmentDetails.boxW}</span>
                                                ) : "NA"
                                        }
                                            </p>

                                        {
                                            <p className="d-flex justify-content-between mb-0 pb-0">
                                                <span>{"Actual Weight (grams)"}</span>
                                                <span>{this.props.shipmentDetails.boxActualWeight ? this.props.shipmentDetails.boxActualWeight : "NA"}</span>
                                            </p>
                                        }
                                        {
                                            <p className="d-flex justify-content-between mb-0 pb-0">
                                                <span>{"Volumetric Weight (grams)"}</span>
                                                <span>
                                                    {this.props.shipmentDetails.boxLength && this.props.shipmentDetails.boxBreadth && this.props.shipmentDetails.boxHeight
                                                        ? (this.props.shipmentDetails.boxLength * this.props.shipmentDetails.boxBreadth * this.props.shipmentDetails.boxHeight) / 5
                                                        : "NA"}
                                                </span>
                                            </p>
                                        }

                                        {/* {productIdList.length > 0 && (
                      <h5 className="card-subtitle mb-2 fs-5 mt-2">
                        Product details
                      </h5>
                    )}
                    {productCode ? (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Product Code"}</span>
                        <span>{productCode}</span>
                      </p>
                    ) : null}
                    {productName ? (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Product Name"}</span>
                        <span>{productName}</span>
                      </p>
                    ) : null}
                    {identificationNo ? (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Product Id"}</span>
                        <span>{identificationNo}</span>
                      </p>
                    ) : null}
                    {length ? (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Product Length (cm)"}</span>
                        <span>{length}</span>
                      </p>
                    ) : null}
                    {breadth ? (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Product Breadth (cm)"}</span>
                        <span>{breadth}</span>
                      </p>
                    ) : null}
                    {height ? (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Product Height (cm)"}</span>
                        <span>{height}</span>
                      </p>
                    ) : null}
                    {volumetricWt ? (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Volumetric Weight (grams)"}</span>
                        <span>{volumetricWt}</span>
                      </p>
                    ) : null}
                    {actualWt ? (
                      <p className="d-flex justify-content-between mb-0 pb-0">
                        <span>{"Actual Weight (grams)"}</span>
                        <span>{actualWt}</span>
                      </p>
                    ) : null} */}
                                        {this.props.productArr.length > 0 && (
                                            <>
                                                <h5 className="card-subtitle mb-2 fs-5 mt-2">
                                                    Product details
                                                </h5>
                                                <div style={{ overflow: "scroll", height: 150 }}>
                                                    <Table
                                                        clsName="table table-striped table-bordered table-hover"
                                                        tableMeta={this.tableMeta}
                                                        data={this.props.productArr}
                                                        dropMenu={[]}
                                                    />
                                                </div>
                                            </>
                                        )}


                                        <h5 className="card-subtitle mb-2 fs-5 mt-2">
                                            Source details
                                        </h5>
                                        <p className="d-flex justify-content-between mb-0 pb-0">
                                            <span>{"Source Contact Person"}</span>
                                            <span>{this.props.shipmentDetails.srcContactPerson}</span>
                                        </p>
                                        <p className="d-flex justify-content-between mb-0 pb-0">
                                            <span>{"Source Contact No"}</span>
                                            <span>{this.props.shipmentDetails.srcContact1}</span>
                                        </p>
                                        {this.props.shipmentDetails?.srcContact2 &&
                                            this.props.shipmentDetails.srcContact2 !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Source Contact No Alternate"}</span>
                                                    <span>{this.props.shipmentDetails.srcContact2}</span>
                                                </p>
                                            )}
                                        {this.props.shipmentDetails?.srcEmailId &&
                                            this.props.shipmentDetails.srcEmailId !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Source Email Id"}</span>
                                                    <span>{this.props.shipmentDetails.srcEmailId}</span>
                                                </p>
                                            )}
                                        {this.props.shipmentDetails?.srcEmailIdAlt &&
                                            this.props.shipmentDetails.srcEmailIdAlt !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Source Email Id Alternate"}</span>
                                                    <span>{this.props.shipmentDetails.srcEmailIdAlt}</span>
                                                </p>
                                            )}
                                        <p className="d-flex justify-content-between mb-0 pb-0">
                                            <span>{"Address 1: "}</span>
                                            <span>{this.props.shipmentDetails.srcAdd1}</span>
                                        </p>
                                        {this.props.shipmentDetails?.srcAdd2 &&
                                            this.props.shipmentDetails.srcAdd2 !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Address 2: "}</span>
                                                    <span>{this.props.shipmentDetails.srcAdd2}</span>
                                                </p>
                                            )}
                                        <p className="d-flex justify-content-between mb-0 pb-0">
                                            <span>{"Source Pincode"}</span>
                                            <span>{this.props.shipmentDetails.srcPincode}</span>
                                        </p>
                                        {this.props.shipmentDetails?.srcCity &&
                                            this.props.shipmentDetails.srcCity !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Source City"}</span>
                                                    <span>{this.props.shipmentDetails.srcCity}</span>
                                                </p>
                                            )}
                                        {this.props.shipmentDetails?.srcState &&
                                            this.props.shipmentDetails.srcState !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Source State"}</span>
                                                    <span>{this.props.shipmentDetails.srcState}</span>
                                                </p>
                                            )}
                                        {this.props.shipmentDetails?.srcLocation &&
                                            this.props.shipmentDetails.srcLocation !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Source Location"}</span>
                                                    <span>{this.props.shipmentDetails.srcLocation}</span>
                                                </p>
                                            )}
                                        {this.props.shipmentDetails?.srcLandmark &&
                                            this.props.shipmentDetails.srcLandmark !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Source Landmark"}</span>
                                                    <span>{this.props.shipmentDetails.srcLandmark}</span>
                                                </p>
                                            )}

                                        <h5 className="card-subtitle mb-2 fs-5 mt-2">
                                            Destination details
                                        </h5>
                                        <p className="d-flex justify-content-between mb-0 pb-0">
                                            <span>{"Destination Contact Person"}</span>
                                            <span>{this.props.shipmentDetails.dstContactPerson}</span>
                                        </p>
                                        <p className="d-flex justify-content-between mb-0 pb-0">
                                            <span>{"Destination Contact No"}</span>
                                            <span>{this.props.shipmentDetails.dstContact1}</span>
                                        </p>
                                        {this.props.shipmentDetails?.dstContact2 &&
                                            this.props.shipmentDetails.dstContact2 !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Destination Contact No Alternate"}</span>
                                                    <span>{this.props.shipmentDetails.dstContact2}</span>
                                                </p>
                                            )}
                                        {this.props.shipmentDetails?.dstEmailId &&
                                            this.props.shipmentDetails.dstEmailId !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Destination Email Id"}</span>
                                                    <span>{this.props.shipmentDetails.dstEmailId}</span>
                                                </p>
                                            )}
                                        {this.props.shipmentDetails?.dstEmailIdAlt &&
                                            this.props.shipmentDetails.dstEmailIdAlt !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Destination Email Id Alternate"}</span>
                                                    <span>{this.props.shipmentDetails.dstEmailIdAlt}</span>
                                                </p>
                                            )}
                                        <p className="d-flex justify-content-between mb-0 pb-0">
                                            <span>{"Address 1: "}</span>
                                            <span>{this.props.shipmentDetails.dstAdd1}</span>
                                        </p>
                                        {this.props.shipmentDetails?.dstAdd2 &&
                                            this.props.shipmentDetails.dstAdd2 !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Address 2: "}</span>
                                                    <span>{this.props.shipmentDetails.dstAdd2}</span>
                                                </p>
                                            )}
                                        <p className="d-flex justify-content-between mb-0 pb-0">
                                            <span>{"Destination Pincode"}</span>
                                            <span>{this.props.shipmentDetails.dstPincode}</span>
                                        </p>
                                        {this.props.shipmentDetails?.dstCity &&
                                            this.props.shipmentDetails.dstCity !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Destination City"}</span>
                                                    <span>{this.props.shipmentDetails.dstCity}</span>
                                                </p>
                                            )}
                                        {this.props.shipmentDetails?.dstState &&
                                            this.props.shipmentDetails.dstState !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Destination State"}</span>
                                                    <span>{this.props.shipmentDetails.dstState}</span>
                                                </p>
                                            )}
                                        {this.props.shipmentDetails?.dstLocation &&
                                            this.props.shipmentDetails.dstLocation !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Destination Location"}</span>
                                                    <span>{this.props.shipmentDetails.dstLocation}</span>
                                                </p>
                                            )}
                                        {this.props.shipmentDetails?.dstLandmark &&
                                            this.props.shipmentDetails.dstLandmark !== "null" && (
                                                <p className="d-flex justify-content-between mb-0 pb-0">
                                                    <span>{"Destination Landmark"}</span>
                                                    <span>{this.props.shipmentDetails.dstLandmark}</span>
                                                </p>
                                            )}
                                    </div>
                                </div>
                            </div>

                            <label htmlFor="inputShipmentNo" className="col clearfix ">
                                <span className="ml-0 pl-0">Enter AWB/LR no</span>
                                <span className="pull-right">{this.props.shipmentDetails.shipmentNo}</span>
                            </label>
                            <input
                                type="text"
                                id={this.props.shipmentInputId}
                                autoComplete="false"
                                className="form-control"
                                value={this.state.shipmentNo}
                                onChange={(e) => {
                                    this.setState({ shipmentNo: e.target.value })
                                }}
                            />
                        </div>
                        <div className="modal-footer">
                            <button
                                // ref={closeButtonRef}
                                type="button"
                                className="btn btn-secondary"
                                data-bs-dismiss="modal"
                                onClick={this.handleClickClose}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.handleClickSave}
                            >
                                Save changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
