import React, { forwardRef } from "react";
import Barcode from "react-barcode";
import dateFormat from "dateformat";
import html2canvas from "html2canvas";
import FormLib from "./../../_common/FormLib";
import { epoch2ddmmyyyyhhmmss } from "../../../utils/DateUtils";

// const { packingNo } = props;
// const now = new Date();
// const localTimeStamp = now.toLocaleString()
// console.log(now.toLocaleString())
// const fDate = dateFormat(now.toLocaleString(), "isoDateTime")
// console.log(fDate);

export default class BarCodePrintSVG1 extends FormLib {
  state = {
    data: {},
    errors: {},
    curProductDetails: [],
    curTicketDetails: [],
    curBlockDetails: [],
  };

  onclick = (event) => {
    try {
      // alert('call')
      // window.print()
      const content = document.getElementById("barcode-content");
      const pri = document.getElementById("ifmcontentstoprint").contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
      pri.close();
    } catch {
      console.log("Error in BarcodePrintSVG>>>>>>>>onClick");
    }
  };

  onClickPrintLabel = (event) => {
    try {
      const content = document.getElementById("labelContentToPrint");
      const pri = document.getElementById("ifmcontentstoprint").contentWindow;
      pri.document.open();
      pri.document.write(content.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
      pri.close();
    } catch {
      console.log("Error in BarcodePrintSVG>>>>>>>>onClickPrintLabel");
    }
  };

  componentDidMount() {
    this.loadInit();
  }

  loadInit() {
    try {
      //   const barcodeDetails = this.props.barcodeDetails;
      // const printBarcode = this.props.printBarcode;
      // const curProductDetails = this.props.productDetails;
      // const curTicketDetails = this.props.ticketDetails;
      // const curBlockDetails = this.props.blockDetails
      // this.setState({ curProductDetails });
      // this.setState({ curTicketDetails });
      // this.setState({ curBlockDetails });
      // this.setState({ printBarcode });
      // const now = new Date();
      // const localTimeStamp = now.toLocaleString();
      // const fDate = dateFormat(now.toLocaleString(), "isoDateTime");
    } catch {
      console.log("Error in BarcodePrintSVG>>>>>>>>loadInit");
    }
  }

  render() {
    console.log("wefhbuidhf", this.props.barcodeDetails);
    return (
      <React.Fragment>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.props.closeModal}
        >
          <span aria-hidden="true">Print Barcode &times;</span>
        </button>
        <div className="card">
          <div className="card-header bg-success text-white ">
            <div className="row">
              <div className="col-8"></div>
              <div className="col-4 text-right"></div>
            </div>
            <div className="row">
              <div className="col-8">{"Print Barcode"}</div>
              <div className="col-4 text-right"></div>
            </div>
          </div>
          <div className="container mt-4" id="barcode-content">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "auto",
                margin: "0",
                padding: "0",
                transform: "scale(0.9)",
              }}
            >
              <Barcode
                fontSize={8}
                margin={0}
                marginTop={1}
                marginBottom={3}
                width={2}
                height={40}
                value={this.props.barcodeDetails.boxBarcode}
              />

              <span
                style={{
                  fontFamily: "monospace",
                  fontSize: "8px",
                }}
              ></span>
            </div>
          </div>
          <button
            type="button"
            className="mt-1 btn btn-outline-secondary"
            onClick={this.onclick}
            style={{ marginLeft: "43%", width: "150px" }}
            title="Print Barcode"
          >
            Print Barcode
          </button>

          <div className="border border-2 col-7 mt-3">
            <div id="labelContentToPrint">
              <div
                style={{
                  width: "350px",
                  height: "450px",
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: "180px",
                }}
              >
                <div
                  style={{
                    marginBottom: "1rem",
                    paddingInline: "1.5rem",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "0.5rem",
                  }}
                >
                  <Barcode
                    margin={0}
                    marginTop={3}
                    marginBottom={0}
                    marginLeft={120}
                    height={80}
                    width={3}
                    displayValue={true}
                    background="transparent"
                    value={this.props.barcodeDetails.boxBarcode}
                  />
                </div>

                <div
                  className="d-flex justify-content-between mb-1 row"
                  style={{
                    fontWeight: "bold",
                    fontSize: "13px",
                    marginBottom: "10px",
                  }}
                >
                  <div className="d-flex flex-column col-8">
                    <p>
                      Linehaul Ref No: {this.props.barcodeDetails.linehaulSeq}
                    </p>
                    <p>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontSize: "13px",
                        }}
                      >
                        Shipment No: {this.props.barcodeDetails.shipmentNo}
                      </span>
                      <span
                        style={{
                          fontSize: "1.1rem",
                          fontWeight: "normal",
                        }}
                      ></span>
                    </p>
                  </div>
                </div>
                <div>
                  {/* <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "0",
                    }}
                  >
                    SHIP TO
                  </p> */}
                  {/* <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "-2",
                    }}
                  >
                    Ticket No: {this.props.barcodeDetails.ticketNo}
                  </p> */}
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "-2",
                    }}
                  >
                    Ticket Creation Date:{" "}
                    {epoch2ddmmyyyyhhmmss(
                      this.props.barcodeDetails.ticketCreationDate
                    )}
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "-2",
                    }}
                  >
                    Shipment Creation Date:{" "}
                    {epoch2ddmmyyyyhhmmss(
                      this.props.barcodeDetails.createdAt
                    )}
                  </p>
                  
                  
                </div>

                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <p
                      style={{
                        fontWeight: "bold",
                        fontSize: "13px",
                        marginBottom: "-2",
                      }}
                    >
                      Ticket No. :{" "}
                    </p>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {this.props.barcodeDetails.multiTicketIds
                      .split(",")
                      .map((m) => (
                        <p
                          style={{
                            fontWeight: "bold",
                            fontSize: "13px",
                            marginBottom: -1,
                            marginLeft: 5,
                          }}
                        >
                          {m}
                        </p>
                      ))}
                  </div>
                </div>

                <div>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      marginBottom: "0",
                    }}
                  >
                    Source Address:
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "0",
                    }}
                  >
                    {this.props.barcodeDetails.srcContactPerson},
                    {this.props.barcodeDetails.srcAdd1},
                    {this.props.barcodeDetails.srcAdd2},<br />
                    {this.props.barcodeDetails.srcLocation},
                    {this.props.barcodeDetails.srcPincode},
                    {this.props.barcodeDetails.srcContact1}
                  </p>
                  {/* <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "0",
                    }}
                  >
                    Contact1 : {this.props.barcodeDetails.srcContact1}
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "0",
                    }}
                  >
                    Contact2 : {this.props.barcodeDetails.srcContact2}
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "0",
                    }}
                  >
                    Location: : {this.props.barcodeDetails.srcLocation}
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "0",
                    }}
                  >
                    State: {this.props.barcodeDetails.srcState}
                  </p> */}
                </div>
                <div style={{ marginTop: "15px" }}>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "15px",
                      marginBottom: "0",
                    }}
                  >
                    Destination Address:
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "13px",
                      marginBottom: "0",
                    }}
                  >
                    {this.props.barcodeDetails.dstContactPerson}
                    {this.props.barcodeDetails.dstAdd1},
                    {this.props.barcodeDetails.dstAdd2},<br />
                    {this.props.barcodeDetails.dstLocation},
                    {this.props.barcodeDetails.dstPincode},
                    {this.props.barcodeDetails.dstContact1}
                  </p>
                  {/* <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginBottom: "0",
                    }}
                  >
                    Contact1 : {this.props.barcodeDetails.dstContact1}
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginBottom: "0",
                    }}
                  >
                    Contact2 : {this.props.barcodeDetails.dstContact2}
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginBottom: "0",
                    }}
                  >
                    Location: : {this.props.barcodeDetails.dstLocation
}
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      marginBottom: "0",
                    }}
                  >
                    State: {this.props.barcodeDetails.dstState
}
                  </p> */}
                </div>

                {/* <div>
                  <p className="mb-1">
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {" ITEM CODE: "}
                    </span>
                  </p>

                  <p className="mb-1">
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {"DESCRIPTION: "}
                    </span>
                  </p>

                  <p>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {" PRODUCT NAME: "}
                    </span>
                  </p>
                </div>
                <div>
                  <p>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      {"PICKUP LOCATION: "}
                    </span>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
          <button
            type="button"
            className="mt-5 btn btn-outline-secondary"
            onClick={this.onClickPrintLabel}
            style={{ marginLeft: "43%", width: "150px", marginBottom: "30px" }}
            title="Print Label"
          >
            Print Label
          </button>
        </div>
      </React.Fragment>
    );
  }
}
