export function currentDate(separator = "-") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${date}`;
}

export function dateByDays(days = 0, separator = "-") {
  let newDate = new Date(Date.now() + days * 24 * 60 * 60 * 1000);
  // newDate.add
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;
}

// var currentDate = new Date();
// currentDate.AddDays(days)

export function dateByWeeks(week = 0, separator = "-") {
  let today = new Date();
  let nextweek = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7
  );
  let lastWeek = new Date(nextweek);
  let date = lastWeek.getDate();
  let month = lastWeek.getMonth() + 1;
  let year = lastWeek.getFullYear();
  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;
}
export function dateByMonths(week = 0, separator = "-") {
  let today = new Date();
  let nextweek = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate()
  );
  let lastWeek = new Date(nextweek);
  let date = lastWeek.getDate();
  let month = lastWeek.getMonth() + 1;
  let year = lastWeek.getFullYear();
  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}`;
}

export function dateByDaysIndia(days = 0, separator = "-") {
  let newDate = new Date();
  let date = newDate.getDate() + days;
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${date}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${year}`;
}

export function yyyymmdd(curDate) {
  let tempDate = curDate;
  // console.log("DATE======> " + tempDate);
  let day = tempDate.substring(0, 2);
  let month = tempDate.substring(3, 5);
  let year = tempDate.substring(6);
  let newDate = `${year}-${month}-${day}`;
  return newDate; // Change This Line
}
export function ddmmyyyy(curDate) {
  let tempDate = [...curDate];
  let day = tempDate.substring(0, 2);
  let month = tempDate.substring(3, 5);
  let year = tempDate.substring(6);
  let newDate = `${day}-${month}-${year}`;
  return newDate.split(",").join(""); // Change This Line
}

export function tDate2yyyymmddhhmmss(curDate) {
  try {
    let tempDate = curDate;
    let year = tempDate.substring(0, 4);
    let month = tempDate.substring(5, 7);
    let day = tempDate.substring(8, 10);

    let hh = tempDate.substring(11, 13);
    let mm = tempDate.substring(14, 16);
    let ss = tempDate.substring(17, 19);
    if (ss.trim() === "") {
      ss = "00";
    }
    let newDate = `${year}-${month}-${day} ${hh}:${mm}:${ss}`;
    return newDate; // Change This Line
  } catch (e) {
    return "" + curDate;
  }
}
export function tDate2yyyymmdd(curDate) {
  try {
    let tempDate = curDate;
    let year = tempDate.substring(0, 4);
    let month = tempDate.substring(5, 7);
    let day = tempDate.substring(8, 10);

    let newDate = `${year}-${month}-${day}`;
    return newDate; // Change This Line
  } catch (e) {
    return "" + curDate;
  }
}
export function epoch2ddmmyyyy(epoch, separator = "-") {
  if (epoch) {
    let newDate = new Date(epoch);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${year}`;
  } else {
    return epoch;
  }
}

export function epoch2ddmmyy(epoch, separator = "-") {
  if (epoch) {
    let newDate = new Date(epoch);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let lastTwoDigitYear = year.toString().substring(2);


    return `${date < 10 ? `0${date}` : `${date}`}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${lastTwoDigitYear}`;

  } else {
    return epoch;
  }
}



export function epoch2ddmmyyyyhhmmss(epoch, separator = "-") {
  try {
    if (epoch !== null) {
      let newDate = new Date(epoch);
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let lastTwoDigitYear = year.toString().substring(2);
      let hh = newDate.getHours();
      let mm = newDate.getMinutes();
      let ss = newDate.getSeconds();
      return `${date < 10 ? `0${date}` : `${date}`}${separator}${month < 10 ? `0${month}` : `${month}`
        }${separator}${lastTwoDigitYear} ${hh}:${mm}:${ss}`;
    } else {
      return "";
    }
  } catch (e) {
    return "";
  }
}

export function epoch2ddmmyyyyhhmmssAsTS(epoch, separator = "-") {
  try {
    if (epoch !== null) {
      let newDate = new Date(epoch);
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let hh = newDate.getHours();
      let mm = newDate.getMinutes();
      let ss = newDate.getSeconds();
      return `${year}${separator}${month < 10 ? `0${month}` : `${month}`
        }${separator}${date} ${hh}:${mm}:${ss}`;
    } else {
      return "";
    }
  } catch (e) {
    return "";
  }
}

export function epoch2yyyymmddhhmmss(epoch, separator = "-") {
  try {
    if (epoch !== null) {
      let newDate = new Date(epoch);
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let lastTwoDigitYear = year.toString().substring(2);
      let hh = newDate.getHours();
      let mm = newDate.getMinutes();
      let ss = newDate.getSeconds();
      return `${lastTwoDigitYear}${separator}${month < 10 ? `0${month}` : `${month}`
    }${separator}${date < 10 ? `0${date}` : `${date}`}${separator}${separator} ${hh}:${mm}:${ss}`;
    } else {
      return "";
    }
  } catch (e) {
    return "";
  }
}


export function epoch2convertddmmyyyyhhmmss(epoch, separator = "-") {
  try {
    if (epoch !== null) {
      let newDate = new Date(epoch);
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let lastTwoDigitYear = year.toString().substring(2);
      let hh = newDate.getHours();
      let mm = newDate.getMinutes();
      let ss = newDate.getSeconds();
      return `${date}${separator}${month < 10 ? `0${month}` : `${month}`
        }${separator}${lastTwoDigitYear}  ${hh}:${mm}:${ss}`;
    } else {
      return "";
    }
  } catch (e) {
    return "";
  }
}

export function epoch2hhmmss(epoch, separator = "-") {
  try {
    if (epoch !== null) {
      let newDate = new Date(epoch);
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      let hh = newDate.getHours();
      let mm = newDate.getMinutes();
      let ss = newDate.getSeconds();
      return `${hh}:${mm}:${ss}`;
    } else {
      return "" + epoch;
    }
  } catch (e) {
    return "" + epoch;
  }
}

export function convertyyyymmddToddmmyyyy(curDate) {
  // try {
  //   if (data !== null) {
  //     let input = "";
  //     input = data.split(" ");
  //     if (input[0]) {
  //       const [year, month, day] = input.split("-");
  //       return `${day}/${month}/${year}`;
  //     } else {
  //       return "Else Er." + data;
  //     }
  //   }
  // } catch (e) {
  //   console.log("error", e);
  //   return "Ex Er." + data + e;
  // }

  try {
    let tempDate = curDate;
    let year = tempDate.substring(0, 4);
    let month = tempDate.substring(5, 7);
    let day = tempDate.substring(8, 10);

    let newDate = `${day}/${month}/${year}`;
    return newDate; // Change This Line
  } catch (e) {
    return "";
  }
}

export function IstToUTCTicketHistory(curDate) {
  try {
    let currDate = new Date(curDate);
    currDate = getUTCTimeTicketHistory(currDate);

    return currDate;
  } catch {
    return "";
  }
}

function getUTCTimeTicketHistory(d) {
  let utcTime = new Date(d);

  var dd = utcTime.getDate();
  var mm = utcTime.getMonth() + 1;
  var yyyy = utcTime.getFullYear();
  let lastTwoDigitYear = yyyy.toString().substring(2);

  var hh = utcTime.getHours();
  var min = utcTime.getMinutes();
  var ss = utcTime.getSeconds();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  if (hh < 10) {
    hh = "0" + hh;
  }
  if (min < 10) {
    min = "0" + min;
  }
  if (ss < 10) {
    ss = "0" + ss;
  }

  return dd + "-" + mm + "-" + lastTwoDigitYear + " " + hh + ":" + min + ":" + ss;
}

export function IstToUTC(curDate) {
  try {
    let currDate = new Date(curDate);
    currDate = getUTCTime(currDate);

    return currDate;
  } catch {
    return "";
  }
}

function getUTCTime(d) {
  let utcTime = new Date(d);

  var dd = utcTime.getDate();
  var mm = utcTime.getMonth() + 1;
  var yyyy = utcTime.getFullYear();
  var hh = utcTime.getHours();
  var min = utcTime.getMinutes();
  var ss = utcTime.getSeconds();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  if (hh < 10) {
    hh = "0" + hh;
  }
  if (min < 10) {
    min = "0" + min;
  }
  if (ss < 10) {
    ss = "0" + ss;
  }

  return yyyy + "-" + mm + "-" + dd + " " + hh + ":" + min + ":" + ss;
}

export function IstToUTCDate(curDate) {
  try {
    let currDate = new Date(curDate);
    currDate = getUTCDate(currDate);

    return currDate;
  } catch {
    return "";
  }
}
function getUTCDate(d) {
  let utcTime = new Date(d);

  var dd = utcTime.getDate();
  var mm = utcTime.getMonth() + 1;
  var yyyy = utcTime.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  return yyyy + "" + mm + "" + dd;
}

export function epoch2yyyymmdd(epoch, separator = "-") {
  if (epoch) {
    let newDate = new Date(epoch);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
  } else {
    return epoch;
  }
}
export function epoch2yyyymmddhhmmssv1(epoch, separator = "-") {
  if (epoch) {
    let newDate = new Date(epoch);
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hh = newDate.getHours();
      let mm = newDate.getMinutes();
      let ss = newDate.getSeconds();

      if (hh < 10) {
        hh = "0" + hh;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }

      if (ss < 10) {
        ss = "0" + ss;
      }

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date} ${hh}:${mm}:${ss}`;
  } else {
    return epoch;
  }
}
