import React from "react";
import { toast } from "react-toastify";
import FormLib from "./../../_common/FormLib";
import Table from "./../../_common/Table";
import Products from "./../products/Products";
import { IoBarcodeOutline, IoCopyOutline } from "react-icons/io5";
import BarCodePrintSVG from "./BarCodePrintSVG";
import { IstToUTC } from "../../../utils/DateUtils";
import { setTicketActions } from "./../../../services/Tickets/TicketProcessServices";
import {
  getItemListByTeamId,
  getItemListByStoreBays,
  getItemStockListByHubId,
  getStockByItem,
} from "./../../../services/stockManagement/inwardInventoryServices";
import swal from "@sweetalert/with-react";
export default class InventoryBlockVerificationInventory extends FormLib {
  state = {
    data: {},
    errors: {},
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    printBarcode: "",
    storeBays: [],
    itemsStockList: [],
    itemsStockListInfo: [],
    storeBaysId: "",
    qnt: "",
    selectedStockItems: [],
    selectedVerifiedStockItems: [],
    finalQnt: 0,
    jobFeInventory: [],
    jobInventory: [],
    inventoryVerified: "false",
    oldInventory: "false",
  };
  tableMetaTDS = {
    columns: [
      { path: "tdsOutBefore", label: "TDS out before" },
      { path: "tdsInAfter", label: "TDS in after" },
      { path: "tdsOutAfter", label: "TDS out after" },
    ],
  };
  tableMetaInventory = {
    key: "itemId",
    columns: [
      {
        key: "itemId",
        actionKey: "bt",
        action: (r) => (
          <>
            <span
              className="badge badge-primary link-over"
              onClick={() => this.onBlockSelection(r)}
            >
              Add
            </span>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      { path: "itemCode", label: "Code" },
      { path: "item", label: "Item" },
      {
        key: "itemId",
        actionKey: "sb",
        action: (r) => (
          <>
            {r.isSerial === "no" && (
              <>{this.rNBm("Quantity ", "bc_" + r["itemId"], "col-5")}</>
            )}
            {r.isSerial === "yes" && (
              <>
                <>{this.rTB("Serial", "serial_" + r["itemId"], "col-6")} </>
              </>
            )}
          </>
        ),
        label: "Quantity ",
        icon: () => <>Quantity / Serial</>,
        cusClass: " ",
      },
    ],
  };

  //   tableMetaVerificationInventory = {
  //     key: "itemId",
  //     columns: [
  //       {
  //         key: "itemId",
  //         actionKey: "bt",
  //         action: (r) => (
  //           <>
  //             <span
  //               className="badge badge-primary link-over"
  //               onClick={() => this.onBlockInventoryConfirm(r)}
  //             >
  //               Confirm
  //             </span>
  //           </>
  //         ),
  //         icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
  //       },

  //       {
  //         key: "itemId",
  //         actionKey: "sb",
  //         action: (r) => (
  //           <>
  //             {r.feSerial === "" && (
  //               <>{this.rNB("Quantity ", "qnt_" + r["itemId"], "col-5")}</>
  //             )}
  //             {r.feSerial !== "" && (
  //               <>
  //                 <>{this.rTB("Serial", "serial_" + r["itemId"], "col-6")} </>
  //               </>
  //             )}
  //           </>
  //         ),
  //         label: "Quantity ",
  //         icon: () => <>Quantity / Serial</>, // Actual quantity of the item remained unused
  //         cusClass: " ",
  //       },
  //       {
  //         key: "itemId",
  //         actionKey: "sv",
  //         action: (r) => (
  //           <>
  //             {r.feSerial === "" && (
  //               <>
  //                 <>{r.feQnt} </>
  //               </>
  //             )}
  //             {r.feSerial !== "" && (
  //               <>
  //                 <>{r.feQnt + "/" + r.feSerial} </>
  //               </>
  //             )}
  //           </>
  //         ),
  //         label: "Quantity ",
  //         icon: () => <>FE Quantity / Serial</>, // actual quantity items consumed by the FE for the job
  //         cusClass: " ",
  //       },

  //       {
  //         key: "itemId",
  //         actionKey: "skv",
  //         action: (r) => (
  //           <>
  //             {r.serial === "" && (
  //               <>
  //                 <>{r.qnt} </>
  //               </>
  //             )}
  //             {r.serial !== "" && (
  //               <>
  //                 <>{r.qnt + " / " + r.serial} </>
  //               </>
  //             )}
  //           </>
  //         ),
  //         label: "Quantity ",
  //         icon: () => <>Actual Quantity / Serial</>, // Gross quantity of the items assigned for the Job
  //         cusClass: " ",
  //       },
  //       { path: "itemId", label: "ItemId" },
  //     ],
  //   };

  tableMetaUnusedInventory = {
    key: "itemId",
    columns: [
      { path: "item", label: "Item" },
      {
        key: "itemId",
        actionKey: "skv",
        action: (r) => (
          <>
            {r.serial === "" && (
              <>
                <>{r.qnt} </>
              </>
            )}
            {r.serial !== "" && (
              <>
                <>{r.qnt + " / " + r.serial} </>
              </>
            )}
          </>
        ),
        label: "Quantity ",
        icon: () => <>Assigned Spares Quantity/Serial </>, // Gross quantity of the items assigned for the Job
        cusClass: " ",
      },
      {
        key: "itemId",
        actionKey: "sv",
        action: (r) => (
          <>
            {/* {r.feSerial === "" && (
              <>
                <>{r.feQnt} </>
              </>
            )}
            {r.feSerial !== "" && (
              <>
                <>{r.feQnt + "/" + r.feSerial} </>
              </>
            )} */}

            {r.feQnt && !r.feSerial && (
              <>
                <>{r.feQnt} </>
              </>
            )}
            {r.feQnt && r.feSerial && (
              <>
                <>{r.feQnt + "/" + r.feSerial} </>
              </>
            )}
            {/* {r.feSerial !== "" && (
              <>
                <>{r.feQnt + "/" + r.feSerial} </>
              </>
            )} */}
            {!r.feSerial && !r.feQnt && (
              <>
                <>{"Not used"} </>
              </>
            )}
          </>
        ),
        label: "Quantity ",
        icon: () => <> Consumed Spares Quantity/Serial</>, // actual quantity items consumed by the FE for the job
        cusClass: " ",
      },
      {
        key: "itemId",
        actionKey: "skvu",
        action: (r) => (
          <>
            {r.serial === "" && (
              <>
                <>{r.qnt - r.feQnt} </>
              </>
            )}
            {r.isUsed === false && (
              <>
                <>{r.serial} </>
              </>
            )}
            {r.isUsed === true && r.serial !== "" && (
              <>
                <>{0} </>
              </>
            )}

            {/* {r.feQnt && !r.feSerial && (
              <>
                <>{r.feQnt} </>
              </>
            )}
            {r.feQnt && r.feSerial && (
              <>
                <>{r.feQnt + "/" + r.feSerial} </>
              </>
            )}
    
            {!r.feSerial && !r.feQnt && (
              <>
                <>{"Not used"} </>
              </>
            )} */}
          </>
        ),
        label: "Quantity ",
        icon: () => <>Unused Spares Quantity/Serial</>, // Unused items quantity to be returned by the FE
        cusClass: " ",
      },
      {
        key: "itemId",
        actionKey: "sb",
        action: (r) => (
          <>
            {r.isSerial === "no" && (
              <>{this.rNB("Quantity ", "qnt_" + r["itemId"], "col-5")}</>
            )}
            {r.isSerial === "yes" && r.isUsed === false && (
              <>
                <>
                  {this.rTB(
                    "Serial",
                    "serial_" + r["itemId"] + r["serial"],
                    "col-6"
                  )}{" "}
                </>
              </>
            )}
          </>
        ),
        label: "Quantity ",
        icon: () => <>Verified Consumed Spares Quantity/Serial</>, // Actual quantity of the item remained unused
        cusClass: " ",
      },
      {
        key: "itemId",
        actionKey: "bt",
        action: (r) => (
          <>
            {r.isSerial === "no" && (
              <span
                className="badge badge-primary link-over"
                onClick={() => this.onBlockInventoryConfirm(r)}
              >
                Confirm
              </span>
            )}
            {r.isSerial === "yes" && r.isUsed === false && (
              <span
                className="badge badge-primary link-over"
                onClick={() => this.onBlockInventoryConfirm(r)}
              >
                Confirm
              </span>
            )}
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
    ],
  };

  tableMetaAdditionalInventory = {
    key: "itemId",
    columns: [
      { path: "item", label: "Item" },
      { path: "qnt", label: "Quantity" },
    ],
  };

  componentDidMount() {
    this.loadInit();
    this.loadstoreBays();
    this.getlocalDateTime();
  }
  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }
  loadstoreBays = async () => {
    try {
      let curHub = localStorage.getItem("curHub");
      let res = await getItemStockListByHubId(curHub);
      if (res.data.data) {
        this.setState({ storeBays: res.data.data.storeBaysId });
      }
    } catch {
      console.log("Error in InventoryBlock>>>>>>>loadstoreBays");
    }
  };

  loadItems = async (storeBaysId) => {
    try {
      let curRetailer = this.props.ticketDetails.retailerId;
      let res = await getItemListByTeamId(curRetailer);
      if (res.data.data) {
        this.setState({ itemsStockList: res.data.data.itemsList });
      }
    } catch {
      console.log("Error in InventoryBlock>>>>>>>loadStores");
    }
  };

  loadItemsStock = async (storeBaysId, itemId) => {
    let itemsStockList = this.state.itemsStockList;

    let itemsStockListInfo = itemsStockList.filter((r) => {
      if (r.itemId === itemId) return r;
    });
    // "storeBaysId": "comp001",

    itemsStockListInfo[0]["storeBaysId"] = storeBaysId;

    this.setState({ itemsStockListInfo: [itemsStockListInfo[0]] });
    // try {
    //   let res = await getStockByItem(storeBaysId, itemId);
    //   if (res.data.data) {
    //     this.setState({ itemsStockListInfo: res.data.data.itemsStockList });
    //   }
    // } catch {
    //   console.log("Error in InventoryBlock>>>>>>>loadStores");
    // }
  };

  loadInit() {
    try {
      const curTicketDetails = this.props.ticketDetails;
      const curBlockDetails = this.props.blockDetails;
      const curProductDetails = this.props.productDetails;
      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails });
      const parsedResultSet = JSON.parse(curBlockDetails.blockInventoryFe);
      const parsedResultSetInventory = JSON.parse(
        curBlockDetails.blockInventory
      );
      this.setState({ jobInventory: parsedResultSetInventory });
      const curJobFeInventory = parsedResultSet?.jobFeInventory;
      // this.setState({ jobFeInventory: parsedResultSet.jobFeInventory });
      // if rejected or cancelled, setting jobFeInventory with items from jobInventory with feQnt=0 and feSerial=inv.serial.
      this.setState({
        jobFeInventory: curJobFeInventory?.length
          ? curJobFeInventory
          : parsedResultSetInventory.jobInventory.map((inv) => ({
              ...inv,
              feQnt: "0",
              feSerial: inv.serial,
            })),
      });

      // Inventory verification screen disabled if already verified
      const blockInventoryVerified = curBlockDetails?.blockInventoryVerified;
      const blockInventoryUnused = curBlockDetails?.blockInventoryUnused;
      if (blockInventoryVerified || blockInventoryUnused) {
        this.setState({ inventoryVerified: "true" });
      }
    } catch {
      console.log("Error in InventoryBlockVerificationInventory>>>>>>loadInit");
    }
  }

  onBlockSelection = async (r) => {
    if (!this.state.data["bc_" + r["itemId"]]) {
      this.state.data["bc_" + r["itemId"]] = "1";
    }
    if (r.isSerial === "yes" && this.state.data["serial_" + r["itemId"]]) {
      this.state.data["bc_" + r["itemId"]] = "1";
      const filteredInvList = this.state.selectedStockItems.filter((inv) => {
        return (
          inv.itemId === r.itemId &&
          inv.storeBaysId === r.storeBaysId &&
          inv.serial === this.state.data["serial_" + r["itemId"]]
        );
      });
      if (filteredInvList.length === 1) {
        const invListNew = this.state.selectedStockItems.map((inv) => {
          return inv.itemId === r.itemId &&
            inv.storeBaysId === r.storeBaysId &&
            inv.serial === this.state.data["serial_" + r["itemId"]]
            ? {
                itemId: r.itemId,
                item: r.item,
                storeBaysId: r.storeBaysId,
                qnt: this.state.data["bc_" + r["itemId"]],
                serial: this.state.data["serial_" + r["itemId"]],
                validation: {
                  isReq: "yes",
                },
                isSerial: r.isSerial,
                isUsed: r.isUsed,
              }
            : inv;
        });
        this.setState({ selectedStockItems: invListNew });
      } else {
        let stockItem = {
          itemId: r.itemId,
          item: r.item,
          storeBaysId: r.storeBaysId,
          qnt: this.state.data["bc_" + r["itemId"]],
          serial: this.state.data["serial_" + r["itemId"]],
          validation: {
            isReq: "yes",
          },
          isSerial: r.isSerial,
          isUsed: r.isUsed,
        };
        this.state.selectedStockItems.push(stockItem);
        this.setState({ selectedStockItems: this.state.selectedStockItems });
      }
    } else if (r.isSerial === "no") {
      this.state.data["serial_" + r["itemId"]] = "";
      const filteredInvList = this.state.selectedStockItems.filter((inv) => {
        return (
          inv.itemId === r.itemId &&
          inv.storeBaysId === r.storeBaysId &&
          inv.serial === this.state.data["serial_" + r["itemId"]]
        );
      });
      if (filteredInvList.length === 1) {
        const invListNew = this.state.selectedStockItems.map((inv) => {
          return inv.itemId === r.itemId &&
            inv.storeBaysId === r.storeBaysId &&
            inv.serial === this.state.data["serial_" + r["itemId"]]
            ? {
                itemId: r.itemId,
                item: r.item,
                storeBaysId: r.storeBaysId,
                qnt: this.state.data["bc_" + r["itemId"]],
                serial: this.state.data["serial_" + r["itemId"]],
                validation: {
                  isReq: "yes",
                },
                isSerial: r.isSerial,
                isUsed: r.isUsed,
              }
            : inv;
        });
        this.setState({ selectedStockItems: invListNew });
      } else {
        let stockItem = {
          itemId: r.itemId,
          item: r.item,
          storeBaysId: r.storeBaysId,
          qnt: this.state.data["bc_" + r["itemId"]],
          serial: this.state.data["serial_" + r["itemId"]],
          validation: {
            isReq: "yes",
          },
          isSerial: r.isSerial,
          isUsed: r.isUsed,
        };
        this.state.selectedStockItems.push(stockItem);
        this.setState({ selectedStockItems: this.state.selectedStockItems });
      }
    } else {
      toast.error("Enter proper Data");
    }
  };
  handleRemoveItem = (item) => {
    let selectedStockItems = this.state.selectedStockItems.filter(
      (inv) =>
        inv.itemId + inv.storeBaysId + inv.serial !==
        item.itemId + item.storeBaysId + item.serial
    );
    this.setState({ selectedStockItems: selectedStockItems });
  };
  handleInventorySubmit = async () => {
    try {
      let override = { ...this.state.data };
      let resultSet = {};
      if (
        this.state.curBlockDetails.blockStatus ===
        "RESCHEDULE_REQUEST_FE_AT_PICKUP"
      ) {
        override["actionCode"] = "wInventoryBlockAddition";
        override["action"] = "Allocate additional items";
        // this.setState({ override });
        resultSet = {
          jobInventory: this.state.selectedStockItems,
          processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          ticketBlockId: this.state.curBlockDetails.ticketBlockId,
          actionStatus: "BLOCK_EXTRA_INVENTORY_ALLOCATION",
        };
      } else {
        override["actionCode"] = "wBlockOldInventoryReturn";
        override["action"] = "Return Old Inventory to Block";
        // this.setState({ override });
        resultSet = {
          jobOldInventory: this.state.selectedStockItems,
          processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          ticketBlockId: this.state.curBlockDetails.ticketBlockId,
          actionStatus: "BLOCK_OLD_INVENTORY_RETURN",
        };
      }

      let resp = await setTicketActions(
        override,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        resultSet,
        this.state.curTicketDetails.ticketProductId
      );
      if (resp.data.success === true) {
        this.state.curBlockDetails.blockStatus !==
          "RESCHEDULE_REQUEST_FE_AT_PICKUP" &&
          this.setState({ oldInventory: "true" });
        resp.data.data &&
          resp.data.data &&
          toast.success(() => <div>{resp.data.msg}</div>);
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in InventoryBlock>>>>>>handleInventorySubmit");
    }
  };
  onBlockInventoryConfirm = async (r) => {
    this.setState({ inventoryVerified: "false" });

    // let finalQnt = r.qnt - r.feQnt;
    // console.log("feQnt json",JSON.stringify(r.feQnt))
    // console.log("real r.qnt", r.qnt, typeof(+r.qnt))
    // console.log("coordinator entered quantity",this.state.data["qnt_"+r["itemId"]], typeof(this.state.data["qnt_"+r["itemId"]]))

    if (!this.state.data["qnt_" + r["itemId"]]) {
      this.state.data["qnt_" + r["itemId"]] = "0";
    }

    if (+this.state.data["qnt_" + r["itemId"]] > +r.qnt) {
      toast.error("Cannot enter more than the assigned quantity");
      this.state.data["qnt_" + r["itemId"]] = "";
      return null;
    }
    if (
      "" + r.feQnt !== "" + this.state.data["qnt_" + r["itemId"]] &&
      !this.state.data["serial_" + r["itemId"] + r["serial"]]
    ) {
      toast.error("Verified data is not correct");
    }
    if (!this.state.data["serial_" + r["itemId"] + r["serial"]]) {
      this.state.data["serial_" + r["itemId"] + r["serial"]] = "";
    }
    if (this.state.data["serial_" + r["itemId"] + r["serial"]] !== r.serial) {
      toast.error("Verified data is not correct");
    }
    // if (this.state.data["serial_" + r["itemId"]] === r.serial) {
    //   this.state.data["qnt_" + r["itemId"]] = 1;
    // }

    const filteredInvList = this.state.selectedVerifiedStockItems.filter(
      (inv) => {
        return inv.itemId === r.itemId && inv.serial === r.serial;
      }
    );
    if (filteredInvList.length === 1) {
      const invListNew = this.state.selectedVerifiedStockItems.map((inv) => {
        return inv.itemId === r.itemId && inv.serial === r.serial
          ? {
              itemId: r.itemId,
              item: r.item,
              storeBaysId: r.storeBaysId,
              qnt: r.qnt,
              serial: r.serial,
              validation: {
                isReq: "yes",
              },
              isSerial: r.isSerial,
              isUsed: r.isUsed,

              feQnt: r.feQnt,
              feSerial: r.serial,
              verifiedQnt: this.state.data["qnt_" + r["itemId"]],
              verifiedSerial:
                this.state.data["serial_" + r["itemId"] + r["serial"]],
            }
          : inv;
      });

      this.setState({ selectedVerifiedStockItems: invListNew });
    } else {
      let stockItem = {
        itemId: r.itemId,
        item: r.item,
        storeBaysId: r.storeBaysId,
        qnt: r.qnt,
        serial: r.serial,
        validation: {
          isReq: "yes",
        },
        isSerial: r.isSerial,
        isUsed: r.isUsed,

        feQnt: r.feQnt,
        feSerial: r.serial,
        verifiedQnt: this.state.data["qnt_" + r["itemId"]],
        verifiedSerial: this.state.data["serial_" + r["itemId"] + r["serial"]],
      };

      this.state.selectedVerifiedStockItems.push(stockItem);
      this.setState({
        selectedVerifiedStockItems: this.state.selectedVerifiedStockItems,
      });
    }
    // }
    // else {
    //   toast.error("Verified data is not correct");

    // }
  };
  handleRemoveVerifiedItem = (item) => {
    let selectedVerifiedStockItems =
      this.state.selectedVerifiedStockItems.filter(
        (inv) =>
          inv.itemId + inv.storeBaysId + inv.serial !==
          item.itemId + item.storeBaysId + item.serial
      );
    this.setState({ selectedVerifiedStockItems: selectedVerifiedStockItems });
  };

  handleVerifiedInventorySubmit = async () => {
    try {
      const jobInventory = this.state.jobInventory.jobInventory;
      const selectedInventory = this.state.selectedVerifiedStockItems;

      // if (selectedInventory?.length !== jobInventory.length) {
      //   swal({
      //     icon: "error",
      //     title: "Kindly select all items",
      //     dangerMode: true,
      //   }).then((res) => {
      //     return null;
      //   });
      //   return null;
      // }
      const isRejectScreen =
        this.state.curBlockDetails.blockStatus ===
        "REJECT_SERVICE_FE_AT_PICKUP";

      let resultSet = {};
      let override = { ...this.state.data };
      if (isRejectScreen) {
        override["actionCode"] = "wBlockUnusedInventoryReturn";
        override["action"] = "Block Unused Inventory Returned";
        const unUsedInventoryReturn = this.state.selectedVerifiedStockItems.map(
          (inv) => {
            return {
              itemId: inv.itemId,
              item: inv.item,
              storeBaysId: inv.storeBaysId,
              qnt: inv.qnt,
              serial: inv.serial,
              unUsedQnt: "" + inv.verifiedQnt,
              unUsedSerial: inv.verifiedSerial,
              validation: { isReq: "yes" },
              isSerial: inv.isSerial,
              isUsed: inv.isUsed,
            };
          }
        );
        resultSet = {
          jobUnusedInventory: unUsedInventoryReturn,
          processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          ticketBlockId: this.state.curBlockDetails.ticketBlockId,
          actionStatus: "BLOCK_UNUSED_INV_RETURNED",
        };
      } else {
        override["actionCode"] = "wBlockInventoryVerified";
        override["action"] = "Block Inventory Verfied";
        // this.setState({ override });
        resultSet = {
          jobVerifiedInventory: this.state.selectedVerifiedStockItems,
          processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          ticketBlockId: this.state.curBlockDetails.ticketBlockId,
          actionStatus: "BLOCK_INVENTORY_VERIFIED",
        };
      }
      let resp = await setTicketActions(
        override,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        resultSet,
        this.state.curTicketDetails.ticketProductId
      );
      if (resp.data.success === true) {
        this.setState({ inventoryVerified: "true" });
        this.props.handleConfirmInventoryVerification();

        resp.data.data &&
          resp.data.data &&
          toast.success(() => <div>{resp.data.msg}</div>);
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
        if (
          resp.data.msg === "Unused Inventory is already verified" ||
          resp.data.msg === "INVENTORY IS ALREADY VERIFIED"
        ) {
          this.setState({ inventoryVerified: "true" });
        }
      }
    } catch {
      console.log("Error in handleVerifiedInventorySubmit");
    }
  };

  localHandleChange = async (e) => {
    try {
      if (e.target.name === "materialType") {
        if (e.target.value) {
          let materialType = e.target.value;
          let res = { ...this.state.data };
          res["hubId"] = localStorage.getItem("curHub");
          res["materialType"] = materialType;
          await this.getBarcode(res, this.state.pageLinks.pageNo);
        } else {
          let res = { ...this.state.data };
          res["hubId"] = localStorage.getItem("curHub");
          res["materialType"] = "all";
          await this.getBarcode(res, this.state.pageLinks.pageNo);
        }
      }
      if (e.target.name === "baysType") {
        let storeBaysId = e.target.value;
        this.loadItems(storeBaysId);
        this.setState({ storeBaysId });
      }
      if (e.target.name === "itemType") {
        let itemId = e.target.value;
        this.loadItemsStock(this.state.storeBaysId, itemId);
      }
    } catch {
      console.log("Error in InventoryBlock>>>>>>localHandleChange");
    }
  };

  render() {
    // const productDetails = this.state.curProductDetails;
    // console.log("pickUpVerificationInventory", this.state);

    const isRescheduleScreen =
      this.state.curBlockDetails.blockStatus ===
      "RESCHEDULE_REQUEST_FE_AT_PICKUP";
    const isRejectScreen =
      this.state.curBlockDetails.blockStatus === "REJECT_SERVICE_FE_AT_PICKUP";
    return (
      <>
        <div className="col-md-12">
          {this.props.loading && (
            <div className="container-fluid">
              <div> Loading Tickets...</div>
            </div>
          )}
          {!this.props.loading && (
            <>
              <div className="container-fluid">
                {/* when items were consumed by FE for the installation or servicing */}
                {this.state.jobFeInventory.length > 0 &&
                  !isRescheduleScreen && (
                    <>
                      {/*-------- showing items assigned to FE - jobInventory ---------------- */}
                      {/* <div className="form-subHeader mt-5">
                        <b>Items assigned to FE ::</b>
                      </div>
                      <div className="col-8 mt-2">
                        {this.state.jobInventory.jobInventory?.length > 0 && (
                          <Table
                            clsName="table table-striped table-bordered table-hover"
                            tableMeta={this.tableMetaAdditionalInventory}
                            data={this.state.jobInventory.jobInventory}
                            dropMenu={[]}
                          />
                        )}
                      </div> */}

                      {isRejectScreen ? (
                        <>
                          <div className="form-subHeader ml-2">
                            Job Unused Inventory Return Verification ::
                          </div>
                          {this.state.inventoryVerified === "true" ? (
                            <p className="text-muted ml-3">
                              Unused Inventory Return Verification already Done
                            </p>
                          ) : (
                            <Table
                              clsName="table table-striped table-bordered table-hover"
                              tableMeta={this.tableMetaUnusedInventory}
                              data={this.state.jobFeInventory}
                              dropMenu={[]}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {this.state.curBlockDetails?.tdsInAfter > 0 &&
                            this.state.curBlockDetails?.tdsOutAfter > 0 &&
                            this.state.curBlockDetails.tdsOutBefore > 0 && (
                              <>
                                <div className="form-subHeader ml-2">
                                  TDS verification
                                </div>
                                <Table
                                  clsName="table table-striped table-bordered table-hover"
                                  tableMeta={this.tableMetaTDS}
                                  data={[
                                    {
                                      tdsInAfter:
                                        this.state.curBlockDetails.tdsInAfter.split(
                                          " "
                                        )[0],
                                      tdsOutAfter:
                                        this.state.curBlockDetails.tdsOutAfter.split(
                                          " "
                                        )[0],
                                      tdsOutBefore:
                                        this.state.curBlockDetails.tdsOutBefore.split(
                                          " "
                                        )[0],
                                    },
                                  ]}
                                  dropMenu={[]}
                                />
                              </>
                            )}

                          <div className="form-subHeader ml-2">
                            Job Inventory Verification ::
                          </div>
                          {this.state.inventoryVerified === "true" ? (
                            <p className="text-muted ml-3">
                              Inventory Return Verification already Done
                            </p>
                          ) : (
                            <Table
                              clsName="table table-striped table-bordered table-hover"
                              // tableMeta={this.tableMetaVerificationInventory}
                              tableMeta={this.tableMetaUnusedInventory}
                              data={this.state.jobFeInventory}
                              dropMenu={[]}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}

                {this.state.inventoryVerified === "false" && (
                  <div className="col-4 mt-2">
                    <table className="table  table-striped m-1 p-0 border">
                      <tbody>
                        {this.state.selectedVerifiedStockItems.map((inv) => {
                          return (
                            <tr key={inv.itemId} className="m-0 p-0">
                              <td>{inv?.item}</td>
                              <td>{inv?.storeBaysId}</td>
                              <td>{inv?.verifiedSerial}</td>
                              <td>
                                {inv?.verifiedQnt}{" "}
                                <span
                                  className="badge badge-danger ml-4 mr-1 mt-2 "
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.handleRemoveVerifiedItem(inv)
                                  }
                                >
                                  X
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {this.state.selectedVerifiedStockItems.length > 0 && (
                      <button
                        type="button"
                        className="btn btn-sm btn-success pull-right mb-2"
                        onClick={this.handleVerifiedInventorySubmit}
                      >
                        Submit Verified Inventory
                      </button>
                    )}
                  </div>
                )}

                {this.state.jobInventory &&
                  this.state.oldInventory === "false" &&
                  !isRescheduleScreen &&
                  !isRejectScreen && (
                    <>
                      <div className="form-subHeader mt-5">
                        <b>Job Old Item Inventory ::</b>
                      </div>

                      <div className="form-row">
                        {this.rSB(
                          "Store Bay's :",
                          "baysType",
                          this.state.storeBays,
                          { value: "storeBaysId", label: "baysName" },
                          " col-3 mt-2"
                        )}
                        {this.rSB(
                          "Items Stock List :",
                          "itemType",
                          this.state.itemsStockList.filter((item) =>
                            item.item.startsWith("used")
                          ),
                          { value: "itemId", label: "item" },
                          " col-3 mt-2"
                        )}
                        <div className="col-8 mt-2">
                          {/* {JSON.stringify(this.state.itemsStockListInfo)} */}
                          {this.state.itemsStockListInfo && (
                            <Table
                              clsName="table table-striped table-bordered table-hover"
                              tableMeta={this.tableMetaInventory}
                              data={this.state.itemsStockListInfo}
                              dropMenu={[]}
                            />
                          )}
                        </div>

                        <div className="col-4 mt-2">
                          <table className="table  table-striped m-1 p-0 border">
                            <tbody>
                              {this.state.selectedStockItems.map((inv) => {
                                return (
                                  <tr
                                    key={
                                      inv.itemId + inv.storeBaysId + inv.serial
                                    }
                                    className="m-0 p-0"
                                  >
                                    <td>{inv?.item}</td>
                                    <td>{inv?.storeBaysId}</td>
                                    <td>{inv?.serial}</td>
                                    <td>
                                      {inv?.qnt}{" "}
                                      <span
                                        class="badge badge-danger ml-4 mr-1 mt-2 "
                                        onClick={() =>
                                          this.handleRemoveItem(inv)
                                        }
                                      >
                                        X
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          {this.state.selectedStockItems.length > 0 && (
                            <button
                              type="button"
                              className="btn btn-sm btn-success pull-right mt-2"
                              onClick={this.handleInventorySubmit}
                            >
                              Submit Old Item Inventory
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                {this.state.jobInventory &&
                  this.state.oldInventory === "false" &&
                  isRescheduleScreen && (
                    <>
                      <div className="form-subHeader mt-1">
                        <b>Existing items assigned to FE ::</b>
                      </div>
                      <div className="col-8 mt-2">
                        {this.state.jobInventory.jobInventory?.length > 0 && (
                          <Table
                            clsName="table table-striped table-bordered table-hover"
                            tableMeta={this.tableMetaAdditionalInventory}
                            data={this.state.jobInventory.jobInventory}
                            dropMenu={[]}
                          />
                        )}
                      </div>

                      <div className="form-subHeader mt-5">
                        <b>Job Additional Items Inventory ::</b>
                      </div>

                      <div className="form-row">
                        {this.rSB(
                          "Store Bay's :",
                          "baysType",
                          this.state.storeBays,
                          { value: "storeBaysId", label: "baysName" },
                          " col-3 mt-2"
                        )}
                        {this.rSB(
                          "Items Stock List :",
                          "itemType",
                          this.state.itemsStockList,
                          { value: "itemId", label: "item" },
                          " col-3 mt-2"
                        )}
                        <div className="col-8 mt-2">
                          {this.state.itemsStockListInfo && (
                            <Table
                              clsName="table table-striped table-bordered table-hover"
                              tableMeta={this.tableMetaInventory}
                              data={this.state.itemsStockListInfo}
                              dropMenu={[]}
                            />
                          )}
                        </div>

                        <div className="col-4 mt-2">
                          <table className="table  table-striped m-1 p-0 border">
                            <tbody>
                              {this.state.selectedStockItems.map((inv) => {
                                return (
                                  <tr
                                    key={
                                      inv.itemId + inv.storeBaysId + inv.serial
                                    }
                                    className="m-0 p-0"
                                  >
                                    <td>{inv?.item}</td>
                                    <td>{inv?.storeBaysId}</td>
                                    <td>{inv?.serial}</td>
                                    <td>
                                      {inv?.qnt}{" "}
                                      <span
                                        class="badge badge-danger ml-4 mr-1 mt-2 "
                                        onClick={() =>
                                          this.handleRemoveItem(inv)
                                        }
                                      >
                                        X
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          {this.state.selectedStockItems.length > 0 && (
                            <button
                              type="button"
                              className="btn btn-sm btn-success pull-right mt-2"
                              onClick={this.handleInventorySubmit}
                            >
                              Submit Additional Items Inventory
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
