import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";

class ReportCreate extends FormLib {
  state = {
    data: {
      username: "",
      password: "",
      pickupDate: this.getDatebyDays(1),
      pickupTime: "14:00",

      reportId: "",
      taskId: "",
      from: "",
      to: "",
      status: "",
      reportBy: "",
      remarks: "",
    },
    errors: {},
  };

  schema = {
    ticket1: Joi.string().label("Ticket 1"),
    ticket2: Joi.string().label("Ticket 2"),
  };
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="">
                <div className="card-body">
                  <div className="form-row">
                    {this.rTB("Task Name", "taskname", "col-12")}
                    {/* {this.rTB("Report Id", "reportId", "col-3")} */}
                  </div>
                  <div className="form-row">
                    {this.rTB("From", "from", "col-6")}
                    {this.rTB("To", "to", "col-6")}
                  </div>

                  <div className="form-group col-md-12">
                    <textarea required="required"></textarea>
                    <label htmlFor="textarea" className="control-label">
                      Remark
                    </label>
                    <i className="bar"></i>
                  </div>

                  <div className="form-row">
                    {this.rRBL("Status", "status", this.status, {
                      value: "sortKey",
                      label: "sort",
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ReportCreate;
