import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLPrevilages;
function api(path, subURL = "/players/team-contacts") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getTeamContacts() {
  return _http.get(api(""), _http.headerJWT());
}
export function getTeamContactsAll(pageNo) {
  try {

    return _http.get(api("10/" + pageNo), _http.headerJWT());
  }
  catch {
    return undefined;
  }
}
export function createTeamContacts(req) {
  const param = { ...req };
  delete param.contactId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveTeamContacts(req) {
  const param = { ...req };
  const id = param.contactId;
  //delete param.teamId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteTeamContacts(req) {
  const param = { ...req };
  return _http.delete(api(param.contactId), _http.headerJWT());
}
