import React from "react";
import FormLib from "./../../_common/FormLib";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";

import {
  epoch2ddmmyyyyhhmmss,
  epoch2hhmmss,
  epoch2ddmmyyyy,
  tDate2yyyymmddhhmmss,
  epoch2convertddmmyyyyhhmmss,
  epoch2ddmmyy,
} from "./../../../utils/DateUtils";
import { toast } from "react-toastify";
import Pagination from "./../../_common/Pagination";
import Joi from "joi-browser";
import ReactTooltip from "react-tooltip";
import * as XLSX from "xlsx";
import ZTitle from "../../_subhead/ZTitle";

// import LineHaulWrapper from "./Manifest/LineHaulWrapper";
import {
  multiTicketSearch,
  getRetailerInfo,
  getTicketHistoryDetails,
} from "../../../services/TicketHistory/TicketHistory";
import TicketHistory from "./TicketHistory";
import swal from "sweetalert";
import { ExportXLSXReqTicketHistory } from "../requestV1/ExportXLSX";

class MultiTicketHistory extends FormLib {
  state = {
    btnValue: "unassigned",
    data: {
      search: "",
      fileUpload: "",
      ticketScope: "ticket",
    },
    errors: {},
    ticketDetails: [],
    curBlockDetails: [],
    curTicketDetails: [],
    pageLinks: {
      pageNo: 1,
      perPage: 5,
      itemsCount: 0,
      lastPage: 0,
    },
    pageLinksExport: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    excelFile: null,
    failedData: { data: [] },
    csvReport: {
      data: [],
      headers: [],
    },
    JSONData: [],
    exportFailedData: false,
    successData: false,
    excelFileError: null,
    excelData: [],
    retailerList: [],
    ticketHistoryTempArray: [],
    fileName: "",
    mapBlockStatus: {
      BLOCK_CREATED: "CREATED",
      APPOINTMENT_FIXED: "APPOINTMENT_FIXED",
      CHOOSE_RE_APPOINTMENT_DATE: "CHOOSE_RE_APPOINTMENT_DATE",
      PROBLEM_SOLVED_DURING_INITIAL_CALL: "PROBLEM_SOLVED_DURING_INITIAL_CALL",
      ASSIGN_TO_HO: "ASSIGN_ TO_HO",
      IDLE_FOR_15MIN: "IDLE_FOR_15MIN",
      IDLE_FOR_15MIN: "IDLE_FOR_15MIN",
      PRODUCT_BARCODE_ASSIGNED: "PRODUCT_BARCODE_ASSIGNED",
      BLOCK_BARCODE_ASSIGNED: "BLOCK_BARCODE_ASSIGNED",
      TICKET_BARCODE_ASSIGNED: "TICKET_BARCODE_ASSIGNED",
      HANDED_OVER_PACKING_MATERIALS: "HANDED_OVER_PACKING_MATERIALS",
      ASSIGNED_FE_FOR_PICKUP: "ASSIGNED_FE_FOR_PICKUP",
      ASSIGNED_FE_FOR_DROP: "ASSIGNED_FE_FOR_DROP",
      AVAILABLE_FOR_PROCESS: "AVAILABLE_FOR_PROCESS",
      RESCHEDULE_FE: "RESCHEDULE_FE",
      RESCHEDULE_FE_AT_PICKUP: "RESCHEDULE_FE_AT_PICKUP",
      RESCHEDULE_FE_AT_DROP: "RESCHEDULE_FE_AT_DROP",
      REJECT_FE: "REJECT_FE",
      REJECT_SERVICE_FE_AT_PICKUP: "REJECT_SERVICE_FE_AT_PICKUP",
      REJECT_SERVICE_FE_AT_DROP: "REJECT_SERVICE_FE_AT_DROP",
      PICKUP_DONE: "PICKUP_DONE",
      LINEHAUL: "READY_FOR_LINEHAUL",
      CHOOSE_FE_FOR_DROP: "CHOOSE_FE_FOR_DROP",
      ASSIGN_TO_HO: "ASSIGN_TO_HO",
      LINEHAUL_IN_TRANSIT: "LINEHAUL_IN_TRANSIT",
      CHOOSE_FE_FOR_DROP: "CHOOSE_FE_FOR_DROP",
      ASSIGN_TO_HO: "ASSIGN_TO_HO",
      ASSIGN_TO_HO: "ASSIGN_TO_HO",
      DROP_CONFIRMED: "DROP_CONFIRMED",
      DROP_DONE: "DROP_DONE",
      RTO_INITIATE: "RTO_INITIATE",
      BLOCK_CLOSED: "COMPLETED_SUCCESSFULLY",
      QC_FAIL: "QC_FAILED",
    },
  };

  ticketScope = [
    { sortKey: "ticket", sort: "TicketNo." },
    { sortKey: "conComplaintNo", sort: "ConComplaintNo." },
    { sortKey: "LRNumber", sort: "LRNo." },
    { sortKey: "invoiceNo", sort: "InvoiceNo." },
    { sortKey: "orderNo", sort: "OrderNo." },
  ];
  schema = {
    search: Joi.optional().label("search"),
    fileUpload: Joi.string().optional().label("fileUpload"),
    ticketScope: Joi.optional().label("ticketScope"),
  };

  componentDidMount() {
    this.resizeTicketList();
    this.retailerList();
  }

  retailerList = async () => {
    try {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let playerId = userInfo.playerId;
      let resp = await getRetailerInfo(playerId);
      if (resp !== undefined) {
        if (
          resp.data.success === true &&
          resp.data.data.retailersList.length >= 1
        ) {
          let retailer = [];
          resp.data.data.retailersList.map((data) => {
            retailer.push(data.teamId);
          });
          this.setState({ retailerList: retailer });
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
          this.setState({ curAction: "TicketHistoryNotLoad" });
        }
      }
    } catch {
      console.log("Error in retailerList");
    }
  };

  localHandleChange = (e) => {
    try {
      if (e.target.name === "fileUpload") {
        this.handleFile(e);
      }
    } catch {
      console.log("Error in TicketList>>>>>>localHandleChange");
    }
  };

  handlePageChange = (pageNo) => {
    try {
      let pageLinks = this.state.pageLinks;
      pageLinks["pageNo"] = pageNo;
      this.setState({ pageLinks });
      this.handleSearch("pageChange");
    } catch {
      console.log("Error in MultiTicketSearch>>>>>>handlePageChange");
    }
  };

  resizeTicketList = () => {
    $("#cocard").css("max-height", $(window).height() - 68);
    $("#cocardHistory").css("max-height", $(window).height() - 15);
  };

  setCurBlockTicket = (b, t) => {
    this.setState({ curBlockDetails: b });
    this.setState({ curTicketDetails: t });
    this.setState({ curAction: "TicketHistory" });
    this.setState({ hideTicketHistory: "true" });
  };

  replaceString = (str) => {
    if (str) {
      str = str.replace(/_/g, " ");
      return str.replace("BLOCK", "JOB");
    }
  };

  async doSubmit() {
    try {
      let selectedHub = localStorage.getItem("curHub");
      let selectedBlockStatus = localStorage.getItem("blockStatus");
      let flowStatus = localStorage.getItem("flow");
      let order = localStorage.getItem("order");
      let pincode = localStorage.getItem("pincode");
      let player = localStorage.getItem("player");

      //   this.getTicketlist(
      //     selectedHub,
      //     selectedBlockStatus,
      //     this.state.btnValue,
      //     this.state.pageLinks.pageNo,
      //     order,
      //     flowStatus,
      //     pincode,
      //     player
      //   );
    } catch {
      console.log("Error in TicketList>>>>>>doSubmit");
    }
  }

  handleSearch = async (type) => {
    try {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      let retailerId = userInfo.teamId;
      let ticketScope = this.state.data.ticketScope;
      let searchTickets = this.state.data.search.replace(/\n/g, " ");
      let nameArr = [];
      if (searchTickets.includes(" ") || searchTickets.includes(",")) {
        nameArr = searchTickets.split(/[ ,]+/);
      } else {
        nameArr.push(searchTickets);
      }
      if (type === "search") {
        let pageLinks = this.state.pageLinks;
        pageLinks["pageNo"] = "1";
        this.setState({ pageLinks });
      }
      nameArr = nameArr.filter(function (e) { return e });
      if (nameArr.length > 50) {
        swal({
          icon: "error",
          title: "The number of Tickets search is maximum (more than 50)",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null;
      }
      let resp = await multiTicketSearch(
        nameArr,
        ticketScope,
        this.state.retailerList,
        this.state.pageLinks.pageNo
      );
      if (resp !== undefined) {
        if (resp.data.success === true && resp.data.data.tickets.length >= 1) {
          this.doSubmitReportFetch(
            nameArr,
            ticketScope,
            this.state.retailerList
          );
          resp.data.data.tickets &&
            this.setState({ ticketDetails: resp.data.data.tickets });
          resp.data.data.pageLinks &&
            this.setState({ pageLinks: resp.data.data.pageLinks });
          resp.data.data.tickets &&
            toast.success(() => <div>{"Tickets fetched successfully"}</div>);
          !resp.data.data.tickets && toast.warning(() => <div>No Tickets</div>);
          this.setState({ curAction: "TicketHistoryLoad" });
          this.setState({ fileName: "" });
        } else {
          toast.error(() => <div>{"Kindly check the searching number"}</div>);
          this.setState({ curAction: "TicketHistoryNotLoad" });
        }
      }
    } catch {
      console.log("Error in TicketList>>>>>>handleSearch");
    }
  };

  navigateToHistory = async (t) => {
    // this.setState({ curAction: "TicketHistory" });
    // this.setState({ curBlockDetails: b });
    // this.setState({ curTicketDetails: t });
    // this.setState({ curAction: "TicketHistory" });
    // this.setState({ hideTicketHistory: "true" });
  };

  handleFile = (e) => {
    try {
      // alert(e.target.files[0]);
      let selectedFile = e.target.files[0];
      if (selectedFile) {
        if (selectedFile && this.fileType.includes(selectedFile.type)) {
          let reader = new FileReader();
          reader.readAsArrayBuffer(selectedFile);
          reader.onload = (e) => {
            this.setState({ excelFileError: null });
            this.setState({ excelFile: e.target.result });
            // console.log(e.target.result);
          };
        } else {
          // setExcelFileError('Please select only excel file types');
          // setExcelFile(null);
          this.setState({
            excelFileError: "Please select only excel file types",
          });
          this.setState({ excelFile: null });
        }
      } else {
        console.log("plz select your file");
      }
    } catch {
      console.log("Error in TicketUpload>>>>>>handleFile");
    }
  };
  handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (this.state.excelFile !== null) {
        const workbook = XLSX.read(this.state.excelFile, { type: "buffer" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);

        const worksheetName1 = workbook.SheetNames[1];
        const worksheet1 = workbook.Sheets[worksheetName1];
        const data1 = XLSX.utils.sheet_to_json(worksheet1);
        this.setState({
          excelData: data,
        });
        // console.log(JSON.stringify(data));
      } else {
        this.setState({ excelData: [] });
        // toast.error("Kindly Enter the Ticket Number");
        console.log("no data");
      }
    } catch {
      console.log("Error in MultiTicketSearch");
    }
  };

  async doSubmitReportFetch(nameArr, ticketScope, retailerList) {
    try {
      this.state.ticketHistoryTempArray = [];
      const res = await getTicketHistoryDetails(
        nameArr,
        ticketScope,
        retailerList,
        1
      );
      if (res.data.success === true) {
        if (res.data.data.misData !== 0) {
          res.data.data.pageLinks &&
            this.setState({ pageLinksExport: res.data.data.pageLinks });
        } else {
          toast.info(() => <div>{res.data.msg}</div>);
        }
      } else {
        toast.error(res.data.msg);
      }

      for (
        var i = this.state.pageLinksExport.pageNo;
        i <= this.state.pageLinksExport.lastPage;
        i++
      ) {
        const res = await getTicketHistoryDetails(
          nameArr,
          ticketScope,
          retailerList,
          i
        );
        if (res) {
          if (res.data.success === true && res.data.data.misData) {
            this.setState({ report: res.data.data.misData });
            this.state.report.map(async (r) => {
              this.state.ticketHistoryTempArray.push(r);
            });
          } else {
            const res = await getTicketHistoryDetails(
              nameArr,
              ticketScope,
              retailerList,
              i
            );
            if (res.data.success === true && res.data.data.misData) {
              this.setState({ report: res.data.data.misData });
              this.state.report.map(async (r) => {
                this.state.ticketHistoryTempArray.push(r);
              });
            }
          }
        } else {
          const res = await getTicketHistoryDetails(this.state.data, i);
          if (res.data.success === true && res.data.data.misData) {
            this.setState({ report: res.data.data.misData });
            this.state.report.map(async (r) => {
              this.state.ticketHistoryTempArray.push(r);
            });
          }
        }
      }

      this.setState({ report: this.state.ticketHistoryTempArray });
      this.formatExcelReport(this.state.report);
    } catch {
      console.log("Error in MultiTicketHistory>>>>>> getTicketHistoryDetails");
    }
  }
  stringPickUpFECode = (str) => {
    try {
      if (str) {
        let myArray = str.split(" - ");
        return myArray[1];
      }
    } catch (e) {
      console.log("Error in MultiTicketHistory>>>>>> stringPickUpFECode....");
    }
  };
  stringPickUpFEName = (str) => {
    try {
      if (str) {
        let myArray = str.split(" - ");
        return myArray[0];
      }
    } catch (e) {
      console.log("Error in MultiTicketHistory>>>>>> stringPickUpFEName...");
    }
  };
  stringDropFECode = (str) => {
    try {
      if (str) {
        let myArray = str.split(" - ");
        return myArray[1];
      }
    } catch (e) {
      console.log("Error in MultiTicketHistory>>>>>> stringDropFECode....");
    }
  };
  stringDropFEName = (str) => {
    try {
      if (str) {
        let myArray = str.split(" - ");
        return myArray[0];
      }
    } catch (e) {
      console.log("Error in MultiTicketHistory>>>>>> stringDropFEName...");
    }
  };
  mapBlockStatus = (str, closedStatus, prodQcStatus, flowStatus) => {
    try {
      let status = this.replaceBlockSatus(
        str,
        closedStatus,
        prodQcStatus,
        flowStatus
      );
      if (this.state.mapBlockStatus[status]) {
        return this.replaceString(this.state.mapBlockStatus[status]);
      }
      return this.replaceString(status);
    } catch {
      console.log("Error in MISNewReport>>>>>> mapBlockStatus");
    }
  };
  replaceBlockSatus = (str, closedStatus, prodQcStatus, flowStatus) => {
    // console.log("calling");
    // console.log("  ............flowStatus" + flowStatus);

    try {
      if (!str) return;
      // if (prodQcStatus) {
      //   if (prodQcStatus === "No") {
      //     str = "QC_FAILED";
      //     return str;
      //   } else if (prodQcStatus === "Yes") {
      //     str = "QC_PASS";
      //     return str;
      //   } else if (prodQcStatus === "DeviceNotAvailable") {
      //     str = "DEVICE_NOT_AVAILABLE";
      //     return str;
      //   } else if (prodQcStatus === "NOT ATTEMPTED") {
      //     str = "PRODUCT_NOT_ATTEMPTED";
      //     return str;
      //   } else if (prodQcStatus === "assign") {
      //     str = "PRODUCT_EVAL_PENDING";
      //     return str;
      //   }
      // }
      if (
        str.includes("BLOCK_CLOSED") &&
        this.state.selectedTeamId === "6f9d70b6-f7c4-4c84-969e-0a17227beec6"
      ) {
        if (
          closedStatus &&
          (closedStatus.includes("Ticket Closed Successfully") ||
            closedStatus.includes("RTO DONE"))
        ) {
          str = "PRODUCT_PICKUP_HAS_BEEN_COMPLETED";
          return str;
        }
      }
      if (
        str.includes("IDLE_UNTIL_") &&
        this.state.selectedTeamId !== "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2"
      ) {
        str = "RESCHEDULED_BY_CUSTOMER";
        return str;
      } else if (
        str.includes("IDLE_UNTIL_") &&
        this.state.selectedTeamId === "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2" &&
        flowStatus === "PICK_WEB"
      ) {
        str = "PICKUP_PENDING";
        console.log("called");
        return str;
      } else if (
        str.includes("IDLE_UNTIL_") &&
        this.state.selectedTeamId === "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2" &&
        flowStatus === "DROP_WEB"
      ) {
        str = "DROP_PENDING";
        return str;
      } else if (
        str.includes("BLOCK_CLOSED") &&
        this.state.selectedTeamId === "24a36528-0749-4cd9-a6e9-cf8fec19d04b"
      ) {
        if (
          closedStatus &&
          (closedStatus.includes("Ticket Closed Successfully") ||
            closedStatus.includes("RTO DONE"))
        ) {
          str = "COMPLETED_SUCCESSFULLY";
          return str;
        }
        // else if (closedStatus && closedStatus.includes("Lost")) {
        //     str = "PRODUCT_LOST";
        //     return str;

        // }
        // else if (closedStatus && closedStatus.includes("Damaged")) {
        //     str = "PRODUCT_DAMAGED";
        //     return str;

        // }
        else if (closedStatus && closedStatus.includes("QC FAIL")) {
          str = "QC_FAILED";
          return str;
        } else {
          str = "CANCELLED_";
          return str;
        }
      } else if (
        str.includes("BLOCK_CLOSED") &&
        this.state.selectedTeamId !== "24a36528-0749-4cd9-a6e9-cf8fec19d04b"
      ) {
        if (
          closedStatus &&
          (closedStatus.includes("Ticket Closed Successfully") ||
            closedStatus.includes("RTO DONE")) &&
          this.state.selectedTeamId !== "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2"
        ) {
          str = "COMPLETED_SUCCESSFULLY";
          return str;
        } else if (
          closedStatus &&
          (closedStatus.includes("Ticket Closed Successfully") ||
            closedStatus.includes("RTO DONE")) &&
          this.state.selectedTeamId === "242d0e85-d8c7-4d28-8e2e-1a5aa3ba21a2"
        ) {
          str = "CLOSED";
          return str;
        } else if (
          (closedStatus && closedStatus.includes("Lost in Hub")) ||
          closedStatus.includes("Lost in Linehaul")
        ) {
          str = "PRODUCT_LOST";
          return str;
        } else if (closedStatus && closedStatus.includes("Damaged")) {
          str = "PRODUCT_DAMAGED";
          return str;
        } else if (closedStatus && closedStatus.includes("QC FAIL")) {
          str = "QC_FAILED";
          return str;
        } else if (
          (closedStatus && closedStatus.includes("Pickup and Drop Done")) ||
          closedStatus.includes("Installation Done") ||
          closedStatus.includes("Service Done") ||
          closedStatus.includes("QC Done")
        ) {
          str = "COMPLETED_SUCCESSFULLY";
          return str;
        } else if (
          (closedStatus &&
            closedStatus.includes("Customer Rejected At Drop Time")) ||
          closedStatus.includes("Customer Rejected At Pickup Time")
        ) {
          str = "REJECTED";
          return str;
        } else {
          str = "CANCELLED_";
          return str;
        }
      } else {
        return str;
      }
    } catch (error) {
      console.error(error);
      console.log("Error in MISNewReport>>>>>> replaceBlockSatus");
    }
  };

  formatExcelReport = async (r) => {
    try {
      let formatedMIS = await Promise.all(
        r.filter(async (t) => {
          //t.created_at = await epoch2ddmmyyyyhhmmss(t.created_at);
          t.created_date = await epoch2ddmmyy(t.created_at);
          // t.created_time = await epoch2hhmmss(t.created_at);
          delete t.created_at;
          t.pickupFe = this.stringPickUpFEName(t.pickupFeName);
          t.pickupFeCode = this.stringPickUpFECode(t.pickupFeName);
          delete t.pickupFeName;
          t.dropFe = this.stringDropFEName(t.dropFeName);
          t.dropFeCode = this.stringDropFECode(t.dropFeName);
          delete t.dropFeName;
          t.updated_at = await epoch2convertddmmyyyyhhmmss(t.updated_at);
          // t.pickup_time = await epoch2hhmmss(t.pickupTime);
          // t.drop_time = await epoch2hhmmss(t.dropTime);
          t.linehaulDispatchedAt = await epoch2convertddmmyyyyhhmmss(
            t.linehaulDispatchedAt
          );
          t.clientCurrentStatus = this.mapBlockStatus(
            t.blockStatus,
            t.closedStatus,
            t.prodQcStatus,
            t.flowStatus
          );
          t.blockStatus = this.replaceString(t.blockStatus);
          t.JobStatus = t.blockStatus;
          delete t.blockStatus;
          t.attempt1At = await epoch2convertddmmyyyyhhmmss(t.attempt1At);
          t.attempt2At = await epoch2convertddmmyyyyhhmmss(t.attempt2At);
          t.attempt3At = await epoch2convertddmmyyyyhhmmss(t.attempt3At);
          t.attempt4At = await epoch2convertddmmyyyyhhmmss(t.attempt4At);
          t.attempt5At = await epoch2convertddmmyyyyhhmmss(t.attempt5At);
          t.confirmation_date = await epoch2ddmmyy(t.feAppointmentDateTime);
          t.l = t.creationProductLength;
          t.h = t.creationProductHeight;
          t.b = t.creationProductBreadth;
          delete t.feAppointmentDateTime;

          delete t.blockInventoryVerified;

          delete t.p1Start;
          delete t.uom;
          delete t.creationProductVolumeWeight;
          delete t.creationProductActualWeight;
          delete t.creationProductWeightUnit;
          delete t.creationProductHeight;
          delete t.creationProductBreadth;
          delete t.creationProductLength;
          delete t.creationProductDimensionsUnit;
          delete t.dstServicability;
          delete t.PROD_EVL_REMARKS;
          delete t.PROD_EVL_RESULT;

          delete t.p1End;
          delete t.prdBoxVerified;
          delete t.productCusField;
          delete t.physicalEvlResult;
          delete t.technicalEvlResult;
          delete t.accessoryDetails;

          delete t.productDetails;
          t.pickup_date = await epoch2ddmmyy(t.pickupTime);
          delete t.pickupTime;

          t.drop_date = await epoch2ddmmyy(t.dropTime);
          delete t.dropTime;
          delete t.srcServicability;
          delete t.flowStatus;
          // delete t.closedStatus;
          // delete t.closedRemarks;
          delete t.modelId;
          delete t.brandId;
          delete t.misTicketId;
          delete t.processId;
          delete t.ticketPriority;
          delete t.flowId;
          delete t.retailerId;
          delete t.retailerNo;
          delete t.ticketScope;
          delete t.lrNo;
          delete t.partAdd1;
          delete t.partAdd2;
          delete t.partAdd3;
          delete t.misTicketId;
          delete t.actionStatus;
          delete t.processId;
          delete t.ticketPriority;
          delete t.flowId;
          delete t.retailerId;
          delete t.retailerNo;
          delete t.invoiceNo;
          delete t.parentTicket;
          delete t.ticketDetails;
          delete t.dateOfTicket;
          delete t.isAppointmentReq;
          delete t.isInstallationReq;
          delete t.isPhysicalEval;
          delete t.isTechEval;
          delete t.isPackingReq;
          delete t.invoiceNo;
          delete t.parentTicket;
          delete t.ticketDetails;
          delete t.specialInstructions;
          delete t.dateOfTicket;
          delete t.isAppointmentReq;
          delete t.isInstallationReq;
          delete t.isPhysicalEval;
          delete t.isTechEval;
          delete t.isPackingReq;
          delete t.primaryDetails;
          delete t.flowConfig;
          delete t.retailerCode;
          delete t.ticketBarcode;
          delete t.misBlockId;
          delete t.ticketBlockId;
          delete t.blockCode;
          delete t.syncStatus;
          delete t.blockBarcode;
          delete t.pickupDate;
          delete t.pickupFeType;
          delete t.pickupFeId;
          delete t.pickupRemarks;
          delete t.dropFeId;
          delete t.dropDate;
          delete t.dropFeType;
          delete t.dropRemarks;
          delete t.closedDate;
          delete t.closedTime;
          delete t.closedBy;
          delete t.closedById;
          delete t.closedByName;
          delete t.vehicleNo;
          delete t.weight;
          delete t.playerFeId;
          delete t.playerFeType;
          delete t.feAppointmentDateTime;
          delete t.misProdId;
          delete t.ticketProductId;
          delete t.prdBarcode;
          delete t.prodStatus;
          delete t.productCodeR;
          delete t.productR;
          delete t.brandCode;
          delete t.brandCodeR;
          delete t.brandR;
          delete t.modelCode;
          delete t.modelCodeR;
          delete t.modelR;
          delete t.productId;
          delete t.dateOfPurchase;
          delete t.cost;
          delete t.isUnderWarranty;
          delete t.productDetails;
          delete t.srcAddressId;
          delete t.srcLocation;
          delete t.srcState;
          delete t.srcLandmark;
          delete t.srcEmailIdAlt;
          delete t.srcType;
          delete t.dstAddressId;
          delete t.dstLocation;
          delete t.dstAdd2;
          delete t.dstContact2;
          delete t.dstState;
          delete t.dstLandmark;
          delete t.dstEmailId;
          delete t.dstEmailIdAlt;
          delete t.dstType;
          delete t.dstEmailId;
          delete t.product;
          delete t.hubId;
          delete t.docketNo;
          delete t.created_by;
          delete t.srcHubId;
          delete t.dstHubId;
          delete t.curHub;
          delete t.cusField8;
          delete t.cusField9;
          delete t.cusField10;
          delete t.productValue;
          delete t.productDesc;
          delete t.problemDesc;
          delete t.pickupFeName;
          delete t.dropFeName;
          // delete t.closedRemarks;
          delete t.cusField1;
          delete t.cusField2;
          delete t.cusField3;
          delete t.cusField4;
          delete t.cusField5;
          delete t.cusField6;
          delete t.cusField7;
          delete t.images;
          delete t.updated_by;
          delete t.blockInventory;
          delete t.blockInventoryFe;
          delete t.blockInventoryVerified;
          delete t.blockInventoryReturns;
          delete t.p1Start;
          delete t.p1End;
          delete t.p2Start;
          delete t.p2End;
          delete t.d1Start;
          delete t.d1End;
          delete t.d2Start;
          delete t.d2End;
          delete t.tdsOutBefore;
          delete t.tdsInAfter;
          delete t.tdsOutAfter;
          delete t.bargainValue;
          delete t.dropLatLong;
          delete t.quoteValue;
          delete t.agreedValue;
          delete t.reQuoteValue;
          delete t.pickUpLatLong;
          delete t.technicalEvlResult;
          delete t.technicalEvlRemark;
          delete t.physicalEvlResult;
          delete t.physicalEvlRemarkdelete;
          delete t.isGradingReq;
          delete t.ticketScope;
          delete t.lrNo;
          delete t.orderNo;
          delete t.isDocumentReq;
          delete t.isGradingReq;
          delete t.isSegregationReq;
          delete t.isSortingReq;
          delete t.isAuditReq;
          delete t.isWeighmentReq;
          delete t.flowName;
          delete t.retailerName;
          delete t.flowStatus;
          delete t.logisticsProvider;
          delete t.attempt2Remark;
          delete t.attempt2At;
          delete t.attempt3Remark;
          delete t.attempt3At;
          delete t.attempt4Remark;
          delete t.attempt4At;
          delete t.attempt5Remark;
          delete t.attempt5At;
          delete t.productCode;
          delete t.brand;
          delete t.model;
          delete t.brandId;
          delete t.modelId;
          delete t.productName;
          delete t.prodQcStatus;
          delete t.invoiceDate;
          delete t.srcOrg;
          delete t.srcAdd1;
          delete t.srcAdd2;
          delete t.srcContact2;
          delete t.srcCity;
          delete t.srcPincode;
          delete t.srcEmailId;
          delete t.srcServicability;
          delete t.dstOrg;
          delete t.dstAdd1;
          delete t.dstCity;
          delete t.dstPincode;
          delete t.deliveryType;
          delete t.PROD_EVL_TYPE;
          delete t.physicalEvlRemark;

          // this.state.removeExcelColumn.map((s) => {
          //   console.log(t[s]);
          //   delete t[s];
          // });

          return t;
        })
      );

      this.setState({ formatData: formatedMIS });
    } catch {
      console.log("Error in multiTicketHistory>>>>>> formatExcelReport");
    }
  };
  excelHeaders = [
    "created_date",
    // "created_time",
    "ticketNo",
    "conComplaintNo",
    "identificationNo",
    "srcHub",
    "dstHub",
    "JobStatus",
    "clientCurrentStatus",
    "closedStatus",
    "closedRemarks",
    "updated_at",
    "NoOfAttempts",
    "attempt1At",
    "attempt1Remark",
    "confirmation_date",
    "srcContactPerson",
    "srcContact1",
    "dstContactPerson",
    "dstContact1",
    "pickup_date",
    "pickupFe",
    "pickupFeCode",
    "drop_date",
    "dropFe",
    "dropFeCode",
    "linehaulDispatchedAt",
    "linehaulAwb",
    "l",
    "b",
    "h",
  ];
  onTextChanged = (e) => {
    const value = e.target.value;
    this.setState(() => ({
      fileName: value,
    }));
  };

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <ZTitle subHeading="Tickets History" />

        <div className="pageContent co-pageContent">
          <div className="container-fluid">
            <div className="row">
              <div className="col-4">
                <div id="cocard" className="col-12  ml-1 mr-4">
                  <div id="primary-ticket-search" className="row">
                    <div
                      className="form-row "
                      style={{
                        marginTop: "25px",
                        marginLeft: "5px",
                        marginBottom: "-25px",
                        position: "relative",
                      }}
                    >
                      {this.rRBL(
                        "Search by :",
                        "ticketScope",
                        this.ticketScope,
                        {
                          value: "sortKey",
                          label: "sort",
                        }
                      )}
                    </div>

                    <div
                      className="col-12 pull-right"
                      style={{ marginLeft: "-2%" }}
                    >
                      <form onSubmit={this.handleSubmit}>
                        <div className="form-row">
                          <div className="form-group col-md-12">
                            {/* {this.rTB(
                              "Search Tickets number",
                              "search",
                              "mt-3"
                            )} */}
                            {this.rTA(
                              "Search .....",
                              "search",
                              "mt-3 border highlights mb-1"
                            )}
                            {/* <p> */}
                            <font color="#c0bebe">
                              For multiple search use space commas or newlines *
                            </font>
                            {/* </p> */}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-2 pull-right">
                            {/* <i
                              className="fa fa-search cursorPointor fa-lg pr-1"
                              style={{ marginLeft: "-1px" }}
                              title="Search Tickets"
                              onClick={this.handleSearch}
                            ></i> */}
                            <div className=" ">
                              <button
                                className="btn btn-outline-success py-0 col "
                                style={{
                                  fontSize: "14px",
                                  textAlign: "left",
                                  position: "relative",
                                  left: "570%",
                                  marginTop: "-100%",
                                }}
                                title="Search by Ticket scope"
                                // onClick={this.handleSearch("search")}
                                onClick={() => this.handleSearch("search")}
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                        {/* <div className="form-row">
                          <div className="form-group col-md-9">
                            {this.rFU(
                              "Upload File",
                              "fileUpload",
                              "col-12btn btn-primary btn-sm"
                            )}
                          </div>
                          <div className="form-group col-md-3">
                            {this.rBtnS("Upload", "upload", "col-12")}
                          </div>
                        </div> */}
                      </form>
                    </div>
                    {(this.state.curAction === "TicketHistoryLoad" ||
                      this.state.curAction === "TicketHistory") && (
                      <div
                        className="col-6 form-group "
                        style={{
                          position: "relative",
                          left: "31%",
                          marginTop: "-31px",
                        }}
                      >
                        <input
                          value={this.state.fileName}
                          name="fileName"
                          onChange={this.onTextChanged}
                          type="text"
                          autoComplete="off"
                        />
                        <label htmlFor="fileName" className="control-label">
                          FileName *
                        </label>
                        <i className="bar"></i>
                        <ExportXLSXReqTicketHistory
                          csvData={this.state.formatData}
                          header={this.excelHeaders}
                          fileName={
                            this.state.fileName + "-" + this.getDatebyDays(0)
                          }
                        />
                      </div>
                    )}
                    {(this.state.curAction === "TicketHistoryLoad" ||
                      this.state.curAction === "TicketHistory") && (
                      <div className="col-12">
                        {this.state.loading && (
                          <div className="container-fluid">
                            <div> Loading Tickets...</div>
                          </div>
                        )}
                        {!this.state.loading && itemsCount >= 0 && (
                          <>
                            <div
                              className="container-fluid pull-right "
                              style={{ marginTop: "-8%" }}
                            >
                              <Pagination
                                pageNo={pageNo}
                                perPage={perPage}
                                itemsCount={itemsCount}
                                NameOfComp="Tickets"
                                onPageChange={(pageNo) =>
                                  this.handlePageChange(pageNo)
                                }
                              />
                              {!this.state.loading && itemsCount === 0 && (
                                <div className="alert alert-info" role="alert">
                                  <div> No Records Found! </div>
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  {(this.state.curAction === "TicketHistoryLoad" ||
                    this.state.curAction === "TicketHistory") && (
                    <div>
                      {this.state.ticketDetails?.map((t, index) => {
                        return (
                          <div className="row">
                            <div className="col-11">
                              <span key={t.processId + "spanCover1" + index}>
                                {t.blocks?.map((b, index) => {
                                  return (
                                    <span
                                      key={
                                        b.ticketBlockId + "spanCover1" + index
                                      }
                                    >
                                      {/* {this.state.data.includeVehicleInfo === "yes" && (
                              <input
                                type="checkbox"
                                name={t.ticketNo}
                                value={t.ticketNo}
                                title="Bulk Ticket Selection"
                                onClick={(e) =>
                                  this.handleCheckBoxClick(e, b, t)
                                }
                                className="multiInput"
                              />
                            )} */}
                                      {/* {(this.state.btnValue === "assigned" ||
                              this.state.btnValue === "unassigned" ||
                              this.state.btnValue === "rescheduled" ||
                              this.state.btnValue === "cancelled" ||
                              this.state.btnValue === "completed" ||
                              this.state.btnValue === "field") &&
                              ((b.blockStatus === "APPOINTMENT_FIXED" &&
                                ((t.products &&
                                  t.products[0].prodStatus !== null &&
                                  t.retailerCode !== "DRP") ||
                                  (t.retailerCode === "DRP" &&
                                    b.blockInventory !== null))) ||
                                (b.blockStatus === "APPOINTMENT_FIXED" &&
                                  t.flowCode === "DRPOF") ||
                                b.blockStatus ===
                                  "HANDED_OVER_PACKING_MATERIALS" ||
                                b.blockStatus === "CHOOSE_FE_FOR_DROP" ||
                                b.blockStatus === "CHOOSE_FE_FOR_DROP_ASC" ||
                                b.blockStatus === "DROP_DONE_ASC" ||
                                b.blockStatus === "PICKUP_DONE_ASC" ||
                                (b.blockStatus === "APPOINTMENT_FIXED" &&
                                  b.flowStatus === "DROP_WEB") ||
                                (b.blockStatus === "APPOINTMENT_FIXED" &&
                                  t.flowCode === "INSV1" &&
                                  t.retailerCode !== "DRP" &&
                                  t.retailerCode !== "EFL" &&
                                  b.blockStatus !== "INSTALL_SERVICE_DONE") ||
                                (b.blockStatus === "APPOINTMENT_FIXED" &&
                                  b.blockStatus === "PART_RECEIVED") ||
                                (b.blockStatus === "APPOINTMENT_FIXED" &&
                                  b.blockStatus === "RTO_INITIATE") ||
                                (b.blockStatus === "APPOINTMENT_FIXED" &&
                                  b.blockStatus === "RTO_REJECT") ||
                                (t.flowId === "BulkQC" &&
                                  b.blockStatus === "APPOINTMENT_FIXED") ||
                                (b.blockStatus === "APPOINTMENT_FIXED" &&
                                  t.flowId === "AdvanceExchange") ||
                                (b.blockStatus === "APPOINTMENT_FIXED" &&
                                  (t.isPackingReq || "").toLowerCase() !==
                                    "yes")) &&
                              t.flowId !== "BulkQC" &&
                              t.flowId !== "Bulk" &&
                              t.flowId !== "Furniture" &&
                              t.flowId !== "eWaste" && (
                                <input
                                  type="checkbox"
                                  name={t.ticketNo}
                                  value={t.ticketNo}
                                  title="Bulk Ticket Selection"
                                  onClick={(e) =>
                                    this.handleCheckBoxClick(e, b, t)
                                  }
                                  className="multiInput"
                                />
                              )} */}
                                      <div
                                        className="card mb-2 cursorPointor"
                                        onClick={() =>
                                          this.setCurBlockTicket(b, t)
                                        }
                                        key={
                                          t.processId + b.ticketBlockId + index
                                        }
                                      >
                                        <div
                                          className="card-header page-todo task"
                                          style={
                                            this.state.curBlockDetails
                                              .ticketBlockId &&
                                            this.state.curBlockDetails
                                              .ticketBlockId === b.ticketBlockId
                                              ? { backgroundColor: "#cfedda" }
                                              : { backgroundColor: "white" }
                                          }
                                        >
                                          <div className="row">
                                            <div className="col-12">
                                              <a>
                                                <i
                                                  id="btn1"
                                                  // data-target="#myModal"
                                                  // data-backdrop="false"
                                                  // data-toggle="modal"
                                                  // className="cursorPointor"
                                                  aria-hidden="true"
                                                  // title="Ticket Details"
                                                >
                                                  <b
                                                    style={{ color: "#737CA1" }}
                                                  >
                                                    {t.ticketNo}{" "}
                                                  </b>
                                                </i>
                                              </a>{" "}
                                              {this.state.data.ticketScope ===
                                                "conComplaintNo" && (
                                                <>
                                                  |{" "}
                                                  <span title="conComplaintNo">
                                                    {t.conComplaintNo}
                                                  </span>
                                                </>
                                              )}
                                              {this.state.data.ticketScope ===
                                                "LRNumber" && (
                                                <>
                                                  |{" "}
                                                  <span title="LRNumber">
                                                    {t.LrNumber}
                                                  </span>
                                                </>
                                              )}
                                              {this.state.data.ticketScope ===
                                                "invoiceNo" && (
                                                <>
                                                  |{" "}
                                                  <span title="invoiceNo">
                                                    {t.invoiceNo}
                                                  </span>
                                                </>
                                              )}
                                              {this.state.data.ticketScope ===
                                                "orderNo" && (
                                                <>
                                                  |{" "}
                                                  <span title="orderNumber">
                                                    {t.orderNo}
                                                  </span>
                                                </>
                                              )}
                                              {b.blockStatus && (
                                                <span
                                                  className={
                                                    "badge badge-success badge-normal pull-right"
                                                  }
                                                >
                                                  {this.replaceString(
                                                    b.blockStatus
                                                  )}
                                                </span>
                                              )}
                                            </div>
                                          </div>

                                          <div className="row">
                                            <div className="col-12">
                                              <span
                                                className={
                                                  t.ticketPriority === "high"
                                                    ? "badge badge-danger"
                                                    : "badge badge-primary badge-05assign"
                                                }
                                              >
                                                {t.ticketPriority}
                                              </span>
                                              <span className="badge badge-info mr-1 ml-1">
                                                {t.flowCode}
                                              </span>
                                              {t.flowId ===
                                                "InstallationV1" && (
                                                <span className="badge badge-warning mr-1 ml-1">
                                                  {t.primaryDetails.ticketType}
                                                </span>
                                              )}
                                              {t.flowId === "InstallationV1" &&
                                                t.retailerCode === "DRP" &&
                                                t.primaryDetails &&
                                                t.primaryDetails
                                                  .serviceLevel && (
                                                  <span className="badge badge-success badge-normal mr-1 ml-1">
                                                    {
                                                      t.primaryDetails
                                                        .serviceLevel
                                                    }
                                                  </span>
                                                )}
                                              <span>
                                                {epoch2ddmmyy(t.dateOfTicket)}
                                              </span>{" "}
                                              {(b.flowStatus === "PICK_WEB" ||
                                                b.flowStatus === "PICK_MOB") &&
                                                t.products && (
                                                  <span
                                                    className="badge badge-secondary mr-1 ml-1"
                                                    data-tip={
                                                      t.products[0].srcAdd1 +
                                                      " : " +
                                                      t.products[0].srcAdd2
                                                    }
                                                  >
                                                    {"Pickup-" +
                                                      t.products[0].srcPincode}
                                                    <ReactTooltip />
                                                  </span>
                                                )}
                                              {(b.flowStatus === "DROP_WEB" ||
                                                b.flowStatus === "DROP_MOB") &&
                                                t.products && (
                                                  <span
                                                    className="badge badge-secondary  mr-1 ml-1"
                                                    data-tip={
                                                      t.products[0].dstAdd1 +
                                                      " : " +
                                                      t.products[0].dstAdd2
                                                    }
                                                  >
                                                    {"Drop-" +
                                                      t.products[0].dstPincode}
                                                    <ReactTooltip
                                                      multiline={true}
                                                    />
                                                  </span>
                                                )}
                                              {/* {(b.flowStatus === "DROP_WEB" ||
                                              b.flowStatus === "DROP_MOB") &&
                                              t.products && (
                                                <span
                                                  className="badge badge-secondary  mr-1 ml-1"
                                                  data-tip={
                                                    t.products[0].dstAdd1 +
                                                    " : " +
                                                    t.products[0].dstAdd2
                                                  }
                                                >
                                                  {"Drop-" +
                                                    t.products[0].dstPincode}
                                                  <ReactTooltip
                                                    multiline={true}
                                                  />
                                                </span>
                                              )} */}
                                              <span className="pull-right">
                                                {t.retailerNickName}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  );
                                })}
                              </span>
                            </div>
                            {/* <div className="col-1">
                            <span
                              className="badge badge-info mr-0 ml-0 mt-4 cursorPointor"
                              title="Ticket History"
                              onClick={() => this.navigateToHistory(t)}
                              style={{ backgroundColor: "#a7aba8" }}
                            >
                              <i class="fa fa-history fa-2x "></i>
                            </span>
                          </div> */}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-8">
                {this.state.curAction === "TicketHistory" && (
                  <TicketHistory
                    ticketDetails={this.state.curTicketDetails}
                    blockDetails={this.state.curBlockDetails}
                    hideAction={this.state.hideTicketHistory}
                    curStatus={this.state.curBlockDetails.blockStatus}
                    key={"FE_" + this.state.curBlockDetails.ticketBlockId}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MultiTicketHistory;
