export function getAdvanceExchangeJSON(data, ticketScope) {
    try {
        const d = new Date().toISOString().slice(0, 10);
        let indentificationNumber = data.identificationNo.toString().replace(/(?:\r\n|\r|\n|\t)/g, '');
        // console.log("ADV EXC============> " + JSON.stringify(data));
        const params = {
            "primary": {
                "ticketPriority": data.ticketPriority,
                "flowId": data.flowId,
                "retailerId": data.retailerId,
                "retailerNo": data.retailerNo?.toString() || "",
                "conComplaintNo": data.conComplaintNo?.toString() || "",
                "LRNumber": data.LRNumber?.toString() || "",
                "orderNo": data.orderNo?.toString() || "",
                "invoiceNo": data.invoiceNo?.toString() || "",
                "parentTicket": data.parentTicket,
                "ticketDetails": data.ticketDetails,
                "specialInstructions": data.specialInstructions,
                "isPhysicalEval": data.isPhysicalEval,
                "isAppointmentReq": data.isAppointmentReq,
                "isInstallationReq": data.isInstallationReq,
                "isTechEval": data.isTechEval,
                "isPackingReq": data.isPackingReq,
                "isBasicEval": data.isBasicEval,
                "inFlowBy": "EXCEL",
                "ticketScope": ticketScope,
                "deliveryType": data.deliveryType,
                "carrier": data.carrier


            },
            "products": [
                {
                    "primary": {
                        "productId": data.productId,
                        "productCode": data.productCode,
                        "brandId": data.brandId,
                        "modelId": data.modelId,
                        "brandName": data.brandName,
                        "modelName": data.modelName,
                        "brandCode": data.brandCode,
                        "modelCode": data.modelCode,
                        "productName": data.productName,
                        "dateOfPurchase": "",
                        "identificationNo": "",
                        "productDesc": "",
                        "problemDesc": "",
                        "productValue": "",
                        "cost": "",
                        "isUnderWarranty": "",
                        "productType": "exchange",
                        "isDoa": "",
                        "pickupSellerCode": data.pickupSellerCode,
                        "accessories": ""
                    },
                    "src_add": {
                        "srcContactPerson": "",
                        "srcOrg": "",
                        "srcAdd1": "",
                        "srcAdd2": "",
                        "srcLocation": "",
                        "srcContact1": "",
                        "srcContact2": "",
                        "srcCity": "",
                        "srcState": "",
                        "srcPincode": "",
                        "srcLandmark": "",
                        "srcEmailId": "",
                        "srcEmailIdAlt": "",
                        "srcType": ""
                    },
                    "dst_add": {
                        "dstContactPerson": data.srcContactPerson,
                        "dstOrg": data.srcOrg,
                        "dstAdd1": data.srcAdd1,
                        "dstAdd2": data.srcAdd2,
                        "dstLocation": data.srcLocation,
                        "dstContact1": data.srcContact1?.toString() || "",
                        "dstContact2": data.srcContact2?.toString() || "",
                        "dstCity": data.srcCity,
                        "dstState": data.srcState,
                        "dstPincode": data.srcPincode?.toString() || "",
                        "dstLandmark": data.srcLandmark,
                        "dstEmailId": data.srcEmailId,
                        "dstEmailIdAlt": data.srcEmailIdAlt,
                        "dstType": data.srcType
                    }
                },
                {
                    "primary": {
                        "productId": data.productId2,
                        "productCode": data.productCode2,
                        "brandId": data.brandId2,
                        "modelId": data.modelId2,
                        "productName": data.productName2,
                        "dateOfPurchase": data.dateOfPurchase,
                        "identificationNo": indentificationNumber.trim(),
                        "productDesc": data.productDesc,
                        "problemDesc": data.problemDesc,
                        "productValue": data.productValue?.toString() || "",
                        "cost": data.cost?.toString() || "",
                        "isUnderWarranty": data.isUnderWarranty,
                        "productType": "return",
                        "returnSellerCode": data.returnSellerCode
                    },
                    "src_add": {
                        "srcContactPerson": data.srcContactPerson,
                        "srcOrg": data.srcOrg,
                        "srcAdd1": data.srcAdd1,
                        "srcAdd2": data.srcAdd2,
                        "srcLocation": data.srcLocation,
                        "srcContact1": data.srcContact1?.toString() || "",
                        "srcContact2": data.srcContact2?.toString() || "",
                        "srcCity": data.srcCity,
                        "srcState": data.srcState,
                        "srcPincode": data.srcPincode?.toString() || "",
                        "srcLandmark": data.srcLandmark,
                        "srcEmailId": data.srcEmailId,
                        "srcEmailIdAlt": data.srcEmailIdAlt,
                        "srcType": data.srcType
                    },
                    "dst_add": {
                        "dstContactPerson": "",
                        "dstOrg": "",
                        "dstAdd1": "",
                        "dstAdd2": "",
                        "dstLocation": "",
                        "dstContact1": "",
                        "dstContact2": "",
                        "dstCity": "",
                        "dstState": "",
                        "dstPincode": "",
                        "dstLandmark": "",
                        "dstEmailId": "",
                        "dstEmailIdAlt": "",
                        "dstType": ""
                    }
                }
            ]
        };

        // console.log("PARAMS =================>" + JSON.stringify(params))
        return params;
    }
    catch {
        console.log("Error in ticket creation");
    }
}