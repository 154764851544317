import React from "react";
import FormLib from "./../../_common/FormLib";
import Pagination from "./../../_common/Pagination";

class TeamPlayer extends FormLib {
    state = {
        data: {},
        players: [],
        errors: {},
        curPlayer: {},
    };

    componentDidMount() {
        this.loadPlayer(this.props.playerList);
    }

    isCur(id, cur) {
        return id === cur ? " curTasks" : "";
    }

    loadPlayer = (e) => {
        this.setState({ players: e });
    }

    handlePageChange = (pageNo) => {
        this.props.onPageChange(pageNo);
    };


    render() {
        const { pageNo, perPage, itemsCount } = this.props.pageLinks;
        const playerList = this.props.playerList;
        const curPlr = this.props.curPlr;
        return (
            <>
                {playerList.length !== 0 &&
                    <>
                        <div className="card">
                            <h4 className="card-header text-center bg-primary text-white">Player List</h4>
                        </div>
                        {playerList.map((r) => {
                            return (
                                <div className={"card cursorPointor " + this.isCur(r.playerId, curPlr.playerId)}
                                    onClick={() => this.props.onPlayerSelect(r)}
                                >
                                    <div className="card-body ">
                                        <div className="cursorPointor"
                                            key={r.playerId}
                                        >
                                            <div className="desc">
                                                <span className="font-weight-bold"> Player Name  &nbsp;:
                                                    <span className="ml-2 p-1"> {r.firstname + ' ' + r.lastname}</span>
                                                </span>
                                                {/* <span className="font-weight-bold pull-right">Emp Id :
                                                    <span className="badge badge-success ml-2 p-1" > {r.playerId}</span>
                                                </span> */}
                                            </div>
                                            <span className="font-weight-bold text-md-start">Username &nbsp;&nbsp;
                                                {r.username !== "" &&
                                                    <span className="ml-2 p-1">:  &nbsp;&nbsp;&nbsp;{r.username}</span>
                                                }
                                                {r.username == "" &&
                                                    <span className="ml-2 p-1 h5">:  &nbsp;&nbsp;&nbsp;------</span>
                                                }

                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        <div className="card-footer">
                            <Pagination
                                pageNo={pageNo}
                                perPage={perPage}
                                itemsCount={itemsCount}
                                onPageChange={this.handlePageChange}
                            />
                        </div>
                    </>

                }
            </>
        );
    }
}
export default TeamPlayer;