import React, { Fragment, Component } from "react";
import { toast } from "react-toastify";
import FormLib from "./../../_common/FormLib";
import Table from "./../../_common/Table";
import Tables from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHeader from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  getPackingMaterialAndProdByJobId,
  getPackingMaterialAndProdByJobIdAdvExc,
  verifyingPackingMaterialAndProduct,
} from "./../../../services/Tickets/TicketProcessServices";
import swal from "sweetalert";

export default class DropVerificationProduct extends FormLib {
  state = {
    data: {},
    errors: {},
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    dropConfirmData: [],
    confirmData: false,
    count: 0,
    isOverride: false,
  };

  componentDidMount() {
    this.loadInit();
    this.loadTableData();
    this.loadPackingMaterialData();
  }

  loadTableData() {
    if (this.props.ticketDetails.flowId === "AdvanceExchange") {
      this.tableMeta.columns.push(this.advExcColumn);
    } else if (this.props.ticketDetails.flowId === "BuyBack") {
      this.tableMeta.columns.push(this.buyBackColumn);
    }
  }

  loadInit() {
    const curTicketDetails = this.props.ticketDetails;
    const curBlockDetails = this.props.blockDetails;
    const curProductDetails = this.props.productDetails;
    this.setState({ curTicketDetails });
    this.setState({ curBlockDetails });
    this.setState({ curProductDetails });
  }

  tableMeta = {
    key: "ticketProductId",
    columns: [
      {
        key: "ticketProductId",
        actionKey: "bt",
        action: (r) => (
          <>
            <span
              className="badge badge-primary link-over"
              onClick={() => this.onBlockSelection(r)}
            >
              Confirm
            </span>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      {
        key: "ticketProductId",
        actionKey: "db",
        action: (r) => (
          <>{this.rTB("Barcode", "db_" + r["ticketProductId"], "")}</>
        ),
        cusClass: " ",
        icon: () => <>Barcodes</>,
      },
      {
        key: "ticketProductId",
        actionKey: "dI",
        action: (r) => (
          <>{this.rTB("Identification No", "dI_" + r["ticketProductId"], "")}</>
        ),
        cusClass: " ",
        icon: () => <>Identification No</>,
      },
      { path: "prdBarcode", label: "Product Barcode" },
      { path: "identificationNo", label: "Identification No" },
      { path: "productName", label: "Product" },
    ],
  };
  tableMeta1 = {
    key: "ticketProductId",
    columns: [
      {
        key: "ticketProductId",
        actionKey: "bt",
        action: (r) => (
          <>
            <span
              className="badge badge-primary link-over"
              onClick={() => this.onBlockSelection(r)}
            >
              Confirm
            </span>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },

      {
        key: "ticketProductId",
        actionKey: "dI",
        action: (r) => (
          <>{this.rTB("Identification No", "dI_" + r["ticketProductId"], "")}</>
        ),
        cusClass: " ",
        icon: () => <>Identification No</>,
      },
      { path: "identificationNo", label: "Identification No" },
      { path: "productName", label: "Product" },
    ],
  };
  advExcColumn = {
    key: "ticketProductId",
    actionKey: "pT",
    action: (r) => <>{r.productType.toUpperCase()}</>,
    cusClass: " ",
    icon: () => <>Product Type</>,
  };

  buyBackColumn = {
    key: "ticketProductId",
    actionKey: "pV",
    action: (r) => <>{r.productValue}</>,
    cusClass: " ",
    icon: () => <>Product Value</>,
  };

  onBlockSelection = async (r) => {
    if (
      (this.state.curTicketDetails.isPackingReq || "").toLowerCase() === "yes"
    ) {
      if (this.state.data["db_" + r.ticketProductId] !== r.prdBarcode) {
        toast.error(
          "Product Barcode Does not Match Kindly enter the correct Barcode"
        );
      } else {
        toast.success("Product Barcode Matched successfully");
      }
      if (this.state.data["dI_" + r.ticketProductId] !== r.identificationNo) {
        toast.error(
          "Product Identification No Does not Match Kindly enter the correct Identification No"
        );
      } else {
        toast.success("Product Identification No Matched successfully");
      }
    } else {
      if (this.state.data["dI_" + r.ticketProductId] !== r.identificationNo) {
        toast.error(
          "Product Identification No Does not Match Kindly enter the correct Identification No"
        );
      } else {
        toast.success("Product Identification No Matched successfully");
      }
    }
  };

  loadPackingMaterialData = async () => {
    try {
      let blockData = this.props.blockDetails;
      let ticketData = this.props.ticketDetails;
      let res = await getPackingMaterialAndProdByJobIdAdvExc(blockData, ticketData);
      if (res !== undefined) {
        if (res.data.success === true && res.data.data ) {
          this.setState({
            pickUpConfirmData: res.data.data.packingMaterialDetails,
          });
          // this.getPackingMaterialAndProdStatus(
          //   res.data.data.packingMaterialDetails
          // );
          this.setState({ confirmData: true });
        } else {
          this.setState({ confirmData: false });
        }
      } else {
        this.setState({ confirmData: false });
      }
    } catch {
      console.log(
        "Error in loadPackingMaterialData>>>>>>>>>>>>>>>>>>>>>>>>>>PickUpVerificationProduct"
      );
    }
  };

  onCellSelection = async (r) => {
    try {
      let curHub = localStorage.getItem("curHub");
      if (r.packMaterialId) {
       
        let packingParams = {
          packing: true,
          slNoId: r.packMaterialId,
          hubId: curHub,
          status: "itemsDropVerified",
        };
        let res = await verifyingPackingMaterialAndProduct(packingParams);
        if (res.data.success === true && res.data.data ) {
          toast.success(() => <div>{res.data.msg}</div>);
          this.props.onHandleGetConfirmStatus();
        } else {
          toast.error(() => <div>{res.data.msg}</div>);
          // this.raisedDropModal(packingParams);
        }
      
      } else {

       
          let productParams = {
            packing: false,
            slNoId: r.productDetails[0].slNoId,
            hubId: curHub,
            status: "itemsDropVerified",
          };
  
          let res = await verifyingPackingMaterialAndProduct(productParams);
          if (res.data.success === true && res.data.data ) {
            toast.success(() => <div>{res.data.msg}</div>);
            this.props.onHandleGetConfirmStatus();
          }
        }
      
    } catch {
      console.log("Error in PickUpVerificationProduct>>>>>>onCellSelection");
    }
  };

  render() {
    const productDetails = this.state.curProductDetails;
    let ticketDetails = this.state.curTicketDetails;
    // console.log("uuuuuuuuuuuuu",this.props.blockDetails.blockStatus)

    return (
      <>
        {" "}
        <div className="form-subHeader">
          Products Droped Barcodes Verification
        </div>
        <div className="row">
          {this.props.loading && (
            <div className="container-fluid">
              <div> Loading Tickets...</div>
            </div>
          )}
          {!this.props.loading && (
            <>
              <div className="container-fluid">
                {this.state.confirmData === false && (
                  <>
                    {(ticketDetails.isPackingReq || "").toLowerCase() ===
                      "yes" && (
                      <Table
                        clsName="table table-striped table-bordered table-hover"
                        tableMeta={this.tableMeta}
                        data={productDetails}
                        dropMenu={[]}
                      />
                    )}
                    {(ticketDetails.isPackingReq || "").toLowerCase() !==
                      "yes" && (
                      <Table
                        clsName="table table-striped table-bordered table-hover"
                        tableMeta={this.tableMeta1}
                        data={productDetails}
                        dropMenu={[]}
                      />
                    )}
                  </>
                )}
                {this.state.confirmData === true && (
                  <>
                    <Tables className="table table table-bordered table-hover">
                      <TableHeader>
                        <TableRow>
                          <TableCell></TableCell>
                          {/* {ticketDetails.flowId === "Bulk" && (
                            <>
                              <TableCell>Packing Barcode</TableCell>
                            </>
                          )} */}
                          {/* {ticketDetails.flowId === "Bulk" && (
                            <>
                              <TableCell>Packing Name</TableCell>
                            </>
                          )} */}
                          <TableCell>Barcode</TableCell>

                          <TableCell>Product</TableCell>
                          <TableCell>Product Code</TableCell>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {this.state.pickUpConfirmData.map((item) => (
                          <Fragment>
                            <>
                              <TableRow>
                                <TableCell
                                  style={{ textAlign: "center" }}
                                  rowSpan={item.productDetails.length + 1}
                                >
                                  {/* <span> */}
                                  <div className="row">
                                    <div className="col-3">
                                      <span
                                        className="badge badge-primary link-over"
                                        onClick={() =>
                                          this.onCellSelection(item)
                                        }
                                      >
                                        Confirm Item
                                      </span>{" "}
                                    </div>
                                  </div>
                                </TableCell>
                              </TableRow>

                              {item.productDetails.map((detail) => (
                                <TableRow>
                                  {/* {ticketDetails.flowId === "Bulk" && (
                                    <>
                                      <TableCell
                                        style={{
                                          color: "transparent",
                                          textShadow: "0 0 8px #000",
                                        }}
                                      >
                                        {item?.packMaterialSl}
                                      </TableCell>
                                      <TableCell>
                                        {item?.packMaterialName}
                                      </TableCell>
                                    </>
                                  )} */}

                                  <TableCell
                                    style={{
                                      color: "transparent",
                                      textShadow: "0 0 8px #000",
                                    }}
                                  >
                                    {detail?.slNo}
                                  </TableCell>
                                  <TableCell>{detail?.productName}</TableCell>
                                  <TableCell>{detail?.productCode}</TableCell>
                                </TableRow>
                              ))}
                            </>
                          </Fragment>
                        ))}
                      </TableBody>
                    </Tables>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}
