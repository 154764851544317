import React, { Component } from 'react'
import FormLib from '../../_common/FormLib';
import { getBarcodeDetailsByHubTeamAndSlNo, getOutwardProdDetails, getOutwardProdDetailsBySlNo, postOutwardInventory } from '../../../services/stockManagement/inwardInventoryServices';
import Table from '../../_common/Table';
import Pagination from '../../_common/Pagination';
import swal from 'sweetalert';
import { epoch2ddmmyyyyhhmmss } from '../../../utils/DateUtils';
import { getActiveClients, getCarriers } from '../../../services/masterLocations/clientService';
import { toast } from 'react-toastify';
import { Joi } from 'joi-browser';

class OutwardInventory extends FormLib {

    state = {
        barcode: "",
        barcodeInTable: "",
        AWBNumber: "",
        invoiceNumber: "",
        carrier: [],
        carrierData: "",
        outwardDescription: "",
        // prodValue: "",
        outwardQnt: 1,
        responseData: [],
        barcodeData: [],
        filepath: [],
        clients: [],
        teamId: "",
    }

    async loadClients() {
        this.setState({ loading: true });
        const res = await getActiveClients("active");

        if (res.data.success === true) {
            this.setState({ clients: res.data.data.clients });
        } else {
            toast.toastError(res.data.msg);
        }
        this.setState({ loading: false });
    }


    componentDidMount() {
        this.loadClients();
        this.loadCarriers();
    }

    async loadCarriers() {
        this.setState({ loading: true });

        const carResp = await getCarriers
            ();
        if (carResp.data.success === true) {
            this.setState({ carrier: carResp.data.data.carrierResp })
        } else {
            toast.toastError(carResp.data.msg);
        }
    }

    doIncrease = async (res, i) => {
        try {
            if (res) {
                swal(
                    "qnt increased successfully by 1....",
                    {
                        icon: "success",
                    },
                );

                this.doSub(res, i)

            }
            else {
                swal("Failed in Increment", { icon: "error" });
            }
        } catch (e) {
            console.log(e)
        }
    }

    doSub = async (res, data) => {
        if (data) {
            const toUpdate = this.state.barcodeData.findIndex(item => item.itemSkuBarcode === data.itemSkuBarcode);
            const quantity = this.state.barcodeData[toUpdate].outwardQnt;
            if (this.state.barcodeData[toUpdate].outwardQnt < this.state.barcodeData[toUpdate].qnt) {

                const currentQuantity = parseInt(quantity, 10);
                this.state.barcodeData[toUpdate].outwardQnt = currentQuantity + 1
                this.setState({ outwardQnt: currentQuantity + 1 })
                // this.setState({ barcodeData: this.state.responseData }) 
            }
            else {
                swal({
                    icon: "error",
                    title: "Outward quantity should be less than Actual quantity ",
                    dangerMode: true,
                }).then((res) => {
                    return null;
                });
                return null;
            }
        }


    }

    handleChange = async (e) => {
        this.setState({
            barcode: e.target.value
        })
    }


    handleChangeBarcode = async (e) => {
        try {
            // this.setState({
            //     barcode: e.target.value
            // })
            //    alert('call')

            if (e.key === "Enter") {
                e.preventDefault()
                // if (!e.target.value) return;
                // this.handleSaveBarcodeNo(e.target.value);
                // const resp = await getOutwardProdDetails(e.target.value, this.props.teamValue,this.props.hubValue);
                const params = {
                    slNo: e.target.value,
                    teamId: this.props.teamValue,
                    hubId: this.props.hubValue
                }
                const slNoIdResp = await getBarcodeDetailsByHubTeamAndSlNo(params);
                if (slNoIdResp.data.data.barcodeData.length > 0) {
                    // console.log("slNoIdResp", slNoIdResp.data.data.barcodeData[0].slNoId)
                    const slNoId = slNoIdResp.data.data.barcodeData[0].slNoId;

                    const resp = await getOutwardProdDetailsBySlNo(slNoId, this.props.teamValue, this.props.hubValue);
                    if (resp.data.success === true) {
                        // eslint-disable-next-line array-callback-return
                        resp.data.data.outwardItemDetails.map((m) => {
                            if (this.state.barcodeData.length > 0) {
                                let result = this.checkData(this.state.barcodeData, m.slNoId, m.teamId);

                                if (result === false) {
                                    this.dataPush(m)

                                }

                                else if (result === true) {


                                    // swal({
                                    //     icon: "warning",
                                    //     title: 'Are you sure To Increase Quantity By 1?',
                                    //     // text: 'Some text.',
                                    //     type: 'warning',
                                    //     buttons:['no', 'yes'],
                                    //     showCancelButton: true,
                                    //     confirmButtonColor: '#DD6B55',
                                    //     confirmButtonText: 'Yes!',
                                    //     cancelButtonText: 'No.'
                                    // }).then(async (res) => {   

                                    //         this.doIncrease(res, m);

                                    // });
                                    swal({
                                        icon: "error",
                                        title: "Already Exists"
                                    })
                                }

                                // })
                            }
                            else if (this.state.barcodeData.length === 0) {
                                this.dataPush(m)

                            }
                            this.setState({
                                barcodeData: this.state.responseData,
                            });
                            this.setState({ barcode: "" })

                        })

                    }
                }else{
                    swal({
                        icon: "error",
                        title: "Given barcode doesnot exist in the hub for a given client"
                    })
                }
            }

        } catch (e) {
            // console.log(e)
        }
    }

    checkData = (arr, code, client) => {
        const { length } = arr;
        const id = length + 1;
        // console.log({ arr });
        const found = arr.some(el => el.slNoId === code && el.teamId === client);
        return found;
    }
    dataPush = (data) => {
        this.state.responseData.push(data)
        this.setState({ barcodeDate: this.state.responseData })
        this.setState({ barcodeInTable: "" })
    }


    handleOutwardQntChange = (e, r) => {
        try {

            if (e.target.value > r.qnt) {
                swal({
                    icon: "error",
                    title: "Outward quantity should be less than Actual quantity ",
                    dangerMode: true,
                }).then((res) => {
                    return null;
                });
                return null;
            }
            if (e.target.value <= 0) {
                swal({
                    icon: "error",
                    title: "Outward quantity should be greater than 0 ",
                    dangerMode: true,
                }).then((res) => {
                    return null;
                });
                return null;
            }

            this.state.barcodeData.map((i) => {
                if (r.itemSkuBarcode === i.itemSkuBarcode && r.storeBaysId === i.storeBaysId) {
                    i.outwardQnt = e.target.value
                    this.setState({ outwardQnt: e.target.value })
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    removeItem = async (data) => {

        if (data.itemId) {
            let newData = this.state.responseData.filter(
                (r) => data.slNoId !== r.slNoId
            );
            this.setState({ responseData: newData })
            this.setState({ barcodeData: newData })
        }
    }

    handleQuantityChange = (item, action) => {
        const toUpdate = this.state.barcodeData.findIndex(
            (i) => i.itemSkuBarcode === item.itemSkuBarcode
        );

        if (toUpdate !== -1) {
            const currentQuantity = this.state.barcodeData[toUpdate].outwardQnt;

            if (action === 'increment' && currentQuantity < item.qnt) {
                this.state.barcodeData[toUpdate].outwardQnt = currentQuantity + 1;
                this.setState({ barcodeData: [...this.state.barcodeData] });
            } else if (action === 'decrement' && currentQuantity > 1) {
                this.state.barcodeData[toUpdate].outwardQnt = currentQuantity - 1;
                this.setState({ barcodeData: [...this.state.barcodeData] });
            }
        }
    };

    tableMeta = {
        key: "itemSkuBarcode",
        columns: [
            // {path:"itemSkuBarcode",label:"Item Sku"},
            {
                key: "itemSkuBarcode",
                action: (r) => (
                    <>
                        <span>{r.slNo}</span>
                    </>
                ),
                cusClass: " ",
                icon: () => (
                    <div>
                        <p>Barcode</p>
                        <input
                            type='text'
                            name="searchSlNo"
                            id="searchSlNo"
                            placeholder='search'
                            value={this.state.barcodeInTable}
                            onChange={(e) => {
                                this.setState({ barcodeInTable: e.target.value });
                                const barcodeData =
                                    this.state.responseData?.filter((i) => {
                                        const sku = i.slNo;
                                        return sku
                                            .toLowerCase()
                                            .includes(e.target.value.toLowerCase())
                                    });
                                this.setState({ barcodeData })
                            }}
                        />
                    </div>
                )
            },
            // { path: "client", label: "Client" },
            // { path: "hub", label: "Hub" },
            // { path: "storeCode", label: "Store" },
            // { path: "baysName", label: "Bay" },
            // { path: "slNo", label: "Barcode" },
            { path: "item", label: "Item" },
            // { path: "qnt", label: "qnt" },
            {
                key: "invDateTime",
                // path: "invDateTime", label: "Inward Date & Time"
                action: (r) => epoch2ddmmyyyyhhmmss(r.invDateTime),
                icon: () => (
                    <p>Inward Date & Time</p>
                )
            },
            // {
            //     key: "outwardQnt",
            //     action: (r) => (
            //         <>
            //             <div className="col m-1 p-0">
            //                 <input
            //                     key={r.itemSkuBarcode && r.storeBaysId}
            //                     className='w-50'
            //                     type='number'
            //                     name='outwardQnt'
            //                     min={1}
            //                     max={r.qnt}
            //                     // value={this.state.outwardQnt}
            //                     value={r.outwardQnt}
            //                     onChange={(e) => {
            //                         this.handleOutwardQntChange(e, r)
            //                     }}
            //                 />
            //             </div>
            //         </>
            //     ),
            //     cusClass: "",
            //     icon: () => (
            //         <p>Outward Qnt</p>
            //     )
            // },
            {
                key: "outwardQnt",
                action: (r) => (
                    <>
                        {/* <div className="col p-0">
                      <button
                        onClick={() => this.handleQuantityChange(r, 'decrement')}
                        className='btn btn-dark '
                        style={{ fontSize:"10px", padding:"5px 20px", marginRight:"1.5rem"}}
                        disabled={r.outwardQnt <= 1}
                      >
                        -
                      </button> */}
                        <span>1</span>
                        {/* <button
                        onClick={() => this.handleQuantityChange(r, 'increment')}
                        className='btn btn-dark '
                        style={{fontSize:"10px", padding:"5px 20px", marginLeft:"1.5rem"}}
                        disabled={r.outwardQnt >= r.qnt}
                      >
                        +
                      </button>
                    </div> */}
                    </>
                ),
                cusClass: "",
                icon: () => (
                    <p>Outward Qnt</p>
                ),
            },
            {
                key: "outwardQnt",
                action: (r) => (
                    <>
                        <div className="d-flex justify-content-center">
                            <>
                                <i
                                    className="fa fa-times cursorPointor ="
                                    style={{ fontSize: "20px", color: "red" }}
                                    onClick={() => this.removeItem(r)}
                                    title="Remove Item"
                                ></i>
                            </>
                        </div>
                    </>
                ),
                icon: () => (
                    <div className="d-flex justify-content-center">Remove Item</div>
                ),
            },
        ]
    }

    // schema = {
    //     teamId: Joi.string().required().label("clientId"),    }

    handleOutwardClick = async () => {
        const data = {
            outwardData: this.state.barcodeData,
            AWBNumber: this.state.AWBNumber,
            invoiceNumber: this.state.invoiceNumber,
            filesSelected: this.state.filepath,
            carrier: this.state.carrierData,
            outwardDesc: this.state.outwardDescription,
            // prodValue: this.state.prodValue
        }
        try {

            if (this.state.AWBNumber === "") {
                swal({
                    icon: "error",
                    title:
                        "AWB number is mandatory",
                    dangerMode: true,
                }).then((res) => {
                    return null;
                });
                return null;
                // return alert("AWB number is mandatory")
            }
            if (this.state.invoiceNumber === "") {
                swal({
                    icon: "error",
                    title:
                        "invoiceNumber is mandatory",
                    dangerMode: true,
                }).then((res) => {
                    return null;
                });
                return null;
            }
            // if (this.state.prodValue === "") {
            //     swal({
            //         icon: "error",
            //         title:
            //             "Product Value Is Mandatory",
            //         dangerMode: true,
            //     }).then((res) => {
            //         return null;
            //     });
            //     return null;
            // }
            if (this.state.carrierData === "") {
                swal({
                    icon: "error",
                    title:
                        "carrier is mandatory",
                    dangerMode: true,
                }).then((res) => {
                    return null;
                });
                return null;
            }

            const response = await postOutwardInventory(data)

            if (response.data.success === true) {

                this.setState({
                    barcodeData: [],
                    responseData: [],
                    AWBNumber: "",
                    invoiceNumber: "",
                    outwardDescription: "",
                    // prodValue: "",
                    filepath: [],
                })
                swal({
                    icon: "success",
                    title: "Outwarded Successfully",
                }).then((res) => {
                    return null;
                });
                return null;
            }
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const teamData = this.props.teamValue;
        console.log({ data: this.state.barcodeData });

        return (
            <div style={{ backgroundColor: "#f2f3f8" }}>
                <div className='ms-4'>
                    <h1 className='fs-4 MT-2'>Outward Inventory</h1>
                </div>

                <div className='d-flex flex-row ' style={{ columnGap: "4rem", marginLeft: "3rem", marginTop: "1.5rem" }}>
                    {/* <div style={{ marginTop: "0.5rem" }}><text className='fs-6'>Clients : <span style={{ color: "red" }}>*</span>  </text>

                        <select className='p-1 col-4'
                            onChange={(e) => this.setState({ teamId: e.target.value })}
                            value={teamData}
                        >
                            <option>Select</option>
                            {this.state.clients.map((c) => (
                                <option key={c.TEAM_ID} value={c.TEAM_ID}>{c.NAME}</option>
                            ))}
                        </select></div> */}
                    <div className="p-2" style={{ marginLeft: "-1rem" }}> <text className='fs-6'>Barcode : <span style={{ color: "red" }}>*</span>  </text>

                        <input className='col-8' placeholder='Enter Barcode' type="text" value={this.state.barcode}
                            onChange={(e) => this.handleChange(e)}
                            onKeyDown={(e) => {
                                this.handleChangeBarcode(e)
                            }}
                        />
                    </div>
                </div>

                <div className='m-5 h-50 '>

                    <Table
                        clsName="table table-striped table-bordered table-hover"
                        tableMeta={this.tableMeta}
                        data={this.state.barcodeData}
                        dropMenu={[]}
                    />
                </div>
                {this.state.barcodeData.length > 0 && (
                    <>
                        {/* <div className='col-2'>
                                    <div className='d-flex flex-column' style={{ columnGap: 10 }}>
                                        <label className='fs-6'>Product Value:  <span style={{ color: "red" }}>*</span></label>
                                        <input
                                            className='p-1'
                                            type='text'
                                            onChange={(e) => this.setState({ prodValue: e.target.value })}
                                        />
                                    </div>
                                </div> */}

                        <div className='row ms-5 mx-5 border border-black pt-5'>
                            <div className='col-12 row'>
                                <div className='col-2'>
                                    <div className='d-flex flex-column row' style={{ columnGap: 5 }}>
                                        <text className='fs-6'> AWB Number: <span style={{ color: "red" }}>*</span></text>
                                        <input className='p-1'
                                            onChange={(e) => this.setState({ AWBNumber: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div className='d-flex flex-column' style={{ columnGap: 5 }}>
                                        <text className='fs-6'>Invoice Number: <span style={{ color: "red" }}>*</span></text>
                                        <input className='p-1'
                                            onChange={(e) => this.setState({ invoiceNumber: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='col-2'>
                                    <div>
                                        <text className='fs-6'>Carrier : <span style={{ color: "red" }}>*</span>  </text>

                                    </div>
                                    <div>
                                        <select className='p-1 col-12'
                                            onChange={(e) => this.setState({ carrierData: e.target.value })}
                                        >
                                            <option>Select</option>
                                            {this.state.carrier.map((c) => (
                                                <option key={c.carrierId} value={c.carrierName}>{c.carrierName}</option>
                                            ))}
                                        </select>

                                    </div>
                                </div>
                                <div className='col-1'>
                                    <div className='d-flex flex-row mb-3' >
                                        <div>
                                            <div>
                                                <label className='fs-6'>Upload Ref Docs Files: <span style={{ color: "red" }}>*</span></label>
                                            </div>
                                            <div>

                                                <input type="file" id="avatar" name="avatar" multiple
                                                    onChange={(e) => {
                                                        this.setState({ filepath: e.target.files })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-2' style={{ marginLeft: "7rem" }}>
                                    <div className='d-flex flex-column mb-3 '>
                                        <label className='fs-6'>Outward Description:</label>
                                        <input
                                            className='p-1'
                                            type='text'
                                            style={{ width: "10rem" }}
                                            onChange={(e) => this.setState({ outwardDescription: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className='col-1 pull-right mt-4 mb-3'>
                                    <button className='btn btn-primary'
                                        onClick={this.handleOutwardClick}
                                    >Outward</button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}
export default OutwardInventory;