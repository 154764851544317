import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import LocationTitle from '../../_subhead/LocationTitle';
import Joi from 'joi-browser';
import { getPlayersbyRole, getTripDetailsByPlayerId, getTicketsListByTripId } from '../../../services/Tickets/TicketServices';
import toast from "../../../services/_toastService"
import { updateTripSequence } from '../../../services/Tickets/TicketProcessServices';
import FormLib from '../../_common/FormLib';

class TicketSequencing extends FormLib {

    state = {
        data: {
            hubId: ""
        },
        users: [],
        hubs: [],
        errors: {},
        btnValue: "",
        ticketData: [],
        selectedPlayerId: "",
        selectedTripId: "",
        ticketCountData: [],
        ticketList: [],
        ticketsListData: [],
        tripsList: [],
        ticketsListByTripId: [],
        tripId: "",
    };

    schema = {
        hubId: Joi.string().required().label("hubId")
    };

    componentDidMount() {
        this.loadHubs();
        this.getPlayers();
    }

    async loadHubs() {
        try {
            let hubList = JSON.parse(localStorage.getItem("hubs"));
            this.setState({ hubs: hubList });
            let curHub = localStorage.getItem("curHub");
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    hubId: curHub || "",
                }
            }));
        } catch {
            console.log("Error in loading hubs");
        }
    }

    async getPlayers() {
        try {
            let hub = localStorage.getItem("curHub");
            let resp = await getPlayersbyRole(hub);

            if (resp.data.data) {
                const filter = resp.data.data.playersHubs.filter((r) => {
                    if (!r.rlastName) {
                        r.rlastName = "";
                    }
                    r["display"] = r.firstName + " " + r.rlastName + " / " + r.userName;
                    return r;
                });
                if (resp.data.success === true) {
                    resp.data.data.playersHubs && this.setState({ users: filter });

                    !resp.data.data.playersHubs &&
                        toast.warning(() => <div>{resp.data.msg}</div>);
                } else {
                    toast.error(() => <div>{resp.data.msg}</div>);
                }
            }
        } catch {
            console.log("Error in getPlayers");
        }
    }

    async getTripList(e, t) {
        try {
            let tripListApi = await getTripDetailsByPlayerId(t.playerId);
            if (tripListApi.data.success === true && tripListApi.data.data.tripDetails.length > 0) {
                const tripIdData = tripListApi.data.data.tripDetails
                    .map((x) => x.tripId).filter((tripId) => tripId !== null);
                this.setState({
                    tripsList: tripListApi.data.data.tripDetails, ticketData: tripIdData,
                    selectedTripId: "", selectedPlayerId: t.playerId
                });
                toast.toastSuccess(tripListApi.data.msg)
            } else {
                this.setState({ tripsList: [] ,selectedPlayerId: t.playerId});
                toast.toastError("No Trips Found For Selected Field Engineer")
            }
        } catch (error) {
            console.log("Error in getTripList")
        }
    }

    async TicketsListBtyTrip(e, x) {
        try {
            let ticketsListByTrip = await getTicketsListByTripId(x.tripId);
            if (ticketsListByTrip.data.success === true && ticketsListByTrip.data.data.tripAndTktDetails.ticketDetails.length > 0) {
                const ticketIdData = ticketsListByTrip.data.data.tripAndTktDetails.ticketDetails
                    .map((x) => x.ticketNo).filter((ticketNo) => ticketNo !== null);
                this.setState({
                    ticketsListByTripId: ticketsListByTrip.data.data.tripAndTktDetails.ticketDetails,
                    ticketData: ticketIdData, selectedTripId: x.tripId, tripId: ticketsListByTrip.data.data.tripAndTktDetails.tripDetails.tripId
                });
                toast.toastSuccess(ticketsListByTrip.data.msg)
            } else {
                this.setState({ ticketsListByTripId: [] });
                toast.toastError("No Trips Found For Selected Field Engineer")
            }
        } catch (error) {
            console.log("Error in get Tickets List By tripId")
        }
    }

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const ticketData = [...this.state.ticketsListByTripId]
        const [reorderedItem] = ticketData.splice(result.source.index, 1);
        ticketData.splice(result.destination.index, 0, reorderedItem);
        this.setState({ ticketsListByTripId: ticketData });
    };

    async onSubmitTckList() {
        let blocks = [];
        this.state.ticketsListByTripId.map((r, i) => {
            let blockData = {
                ticketBlockId: r.ticketBlockId,
                tripId: this.state.tripId,
                tripSeq: (i + 1).toString()
            };
            blocks.push(blockData)
        })

        let resp = await updateTripSequence(blocks);
        if (resp.data.success === true) {
            toast.toastSuccess(resp.data.msg)
            await this.TicketsListBtyTrip(null, { tripId: this.state.selectedTripId });
        } else {
            toast.toastError(resp.data.msg)
        }
    }

    render() {
        return (
            <React.Fragment>
                <LocationTitle subHeading="Ticket Sequencing" />
                <div className="pageContent">
                    <div className="row col-12">
                        {!this.state.loading && (
                            <>
                                <div className="d-flex row">
                                    <div className=" col-4 card-body  bg-light">
                                        <b>&nbsp;&nbsp;Field Engineers :</b>
                                        <div className="form-row border border-gray rounded  pt-1  mb-3 mt-1">
                                            <div className="col-12  ml-5 mr-4">
                                                <div className="row ">
                                                    {this.state.users.map((t, index) => (
                                                        <span key={t.playerId + "spanCover1"}>
                                                            <div
                                                                className={`card-header page-todo task col-9 ${t.playerId === this.state.selectedPlayerId
                                                                    ? 'selected'
                                                                    : ''
                                                                    }`}
                                                                style={
                                                                    t.playerId &&
                                                                        t.playerId ===
                                                                        this.state.selectedPlayerId
                                                                        ? {
                                                                            backgroundColor:
                                                                                "rgb(255 225 202)"
                                                                        }
                                                                        : {
                                                                            backgroundColor:
                                                                                "white",
                                                                        }
                                                                }
                                                            >
                                                                <div
                                                                    className="cursorPointor zoom"
                                                                    onClick={(e) => {
                                                                        this.getTripList(e, t)
                                                                    }}
                                                                >
                                                                    {t.display}
                                                                </div>
                                                            </div>
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 card-body  bg-light'>
                                        {
                                            this.state.tripsList.length > 0 && (
                                                <b>&nbsp;&nbsp;Trips List :</b>
                                            )
                                        }
                                        <div className="form-row border border-gray rounded  pt-1  mb-3 mt-1">
                                            <div className="col-12  ml-5 mr-4">
                                                <div className="row ">
                                                    {this.state.tripsList
                                                        .filter((x) => x.tripId && x.tripId.length > 0)
                                                        .map((x, index) => (
                                                            <>
                                                                <span key={x.tripId + "spanCover2"}>
                                                                    <div
                                                                        className={`card-header page-todo task col-9 ${x.tripId === this.state.selectedTripId
                                                                            ? 'selected'
                                                                            : ''
                                                                            }`}
                                                                        style={
                                                                            x.tripId &&
                                                                                x.tripId ===
                                                                                this.state.selectedTripId
                                                                                ? {
                                                                                    backgroundColor:
                                                                                        "rgb(255 225 202)"
                                                                                }
                                                                                : {
                                                                                    backgroundColor:
                                                                                        "white",
                                                                                }
                                                                        }
                                                                    >
                                                                        <div
                                                                            className="cursorPointor zoom"
                                                                            onClick={(e) => {
                                                                                this.TicketsListBtyTrip(e, x);
                                                                            }}
                                                                        >
                                                                            {x.tripId}
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            </>
                                                        ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4 ">
                                        <DragDropContext onDragEnd={this.onDragEnd}>
                                            <Droppable droppableId="ticketsListByTripId">
                                                {(provided) => (
                                                    <div
                                                        style={{ maxWidth: "20rem" }}
                                                        ref={provided.innerRef} {...provided.droppableProps}>

                                                        {
                                                            this.state.tripsList.length > 0 && this.state.selectedTripId && this.state.ticketsListByTripId.length > 0 && (
                                                                <h3 className='pb-4'>Ticket priority sequence :</h3>
                                                            )
                                                        }
                                                        {this.state.tripsList.length > 0 && this.state.selectedTripId && this.state.ticketsListByTripId
                                                            .filter((x) => x.ticketNo && x.ticketNo.length > 0)
                                                            .map((x, index) => (
                                                                <Draggable key={x.processId} draggableId={x.processId.toString()} index={index}>
                                                                    {(provided) => (
                                                                        <div className='d-flex col-12'>
                                                                            <div
                                                                                className='card bg-secondary rounded-pill cursorPointer border border-primary text-light col-2 mr-2'
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                ref={provided.innerRef}
                                                                            >
                                                                                <div style={{ padding: "0.8rem 0rem 0.8rem 0rem", textAlign: "center" }}>
                                                                                    <span>{x.ticketSequencing}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div
                                                                                className='card bg-secondary rounded-pill cursorPointer border border-primary text-light col-10'
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                                ref={provided.innerRef}
                                                                            >
                                                                                <div style={{ padding: "0.8rem 0rem 0.8rem 0rem", textAlign: "center" }}>
                                                                                    <span>{x.ticketNo}</span>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            )
                                                            )}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                        {this.state.tripsList.length > 0 &&  this.state.selectedTripId && this.state.ticketsListByTripId.length > 0 && (

                                            <button type="submit" className="btn btn-sm btn-primary pull-right" onClick={() => this.onSubmitTckList()}>Update The Sequence</button>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default TicketSequencing;