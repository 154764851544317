import React from "react";

function Checkbox({
  header,
  name,
  options = [],
  ctrls,
  inline = "",
  value,
  error,
  classAppend = "",
  isHeader = "",
  ...rest
}) {
  return (
    <div className={"form-group " + classAppend}>
      <div className={" form-checkbox " + inline} style={{ marginTop: "0%", marginLeft: "20px" }}>
        {header !== "" && <div className="form-help"> {isHeader} </div>}
        {/* {JSON.stringify(options)} */}
        {options.map((op) => (
          <div className="checkbox" key={op[ctrls.value]}>
            <label>
              <input
                type="checkbox"
                name={name}
                value={op[ctrls.value]}
                {...rest}
              />
              <i className="helper"></i>
              {op[ctrls.label]}
            </label>
            {error && <span className="alert alert-danger">{error}</span>}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Checkbox;
