import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLPrevilages;
function api(path, subURL = "/prv-modules") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getPrvModules(divisionId) {
  return _http.get(api("get-modules/" + divisionId), _http.headerJWT());
}
export function createPrvModules(req) {
  const param = { ...req };
  delete param.moduleId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function savePrvModules(req) {
  const param = { ...req };
  const id = param.moduleId;
  //delete param.cityId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deletePrvModules(req) {
  const param = { ...req };
  const id = param.moduleId;
  return _http.delete(api(id), _http.headerJWT());
}
