export const COUNTRY_CREATE = "COUNTRY_CREATE";
export const COUNTRY_UPDATE = "COUNTRY_UPDATE";
export const COUNTRY_DELETE = "COUNTRY_DELETE";
export const COUNTRY_LIST_ALL = "COUNTRY_LIST_ALL";
export const COUNTRY_DETAILS = "COUNTRY_DETAILS";

export const STATE_CREATE = "STATE_CREATE";
export const STATE_UPDATE = "STATE_UPDATE";
export const STATE_DELETE = "STATE_DELETE";
export const STATE_LIST_ALL_BY_COUNTRY = "STATE_LIST_ALL_BY_COUNTRY";
export const STATE_DETAILS = "STATE_DETAILS";

export const CITY_CREATE = "CITY_CREATE";
export const CITY_UPDATE = "CITY_UPDATE";
export const CITY_DELETE = "CITY_DELETE";
export const CITY_LIST_ALL_BY_STATE = "CITY_LIST_ALL_BY_STATE";
export const CITY_DETAILS = "CITY_DETAILS";

/////////// Not Considered ///////////////
// export const COUNTRY_TRASH_LIST = "COUNTRY_LIST_TRASH";
// export const COUNTRY_TRASH_DELETE = "COUNTRY_DELETE";
// export const COUNTRY_TRASH = "COUNTRY_TRASH";

// export const COUNTRY_LIST_SORT = "COUNTRY_LIST_SORT";
// export const COUNTRY_LIST_ACTIVE = "COUNTRY_LIST_ACTIVE";

// export const COUNTRY_LIST_ALL = "COUNTRY_DELETE";

/////////////////^^^^^^^^^^^^^^////////////
