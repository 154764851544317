import React from "react";

function Button({ label, value, clsName = "", ...rest }) {
  return (
    <button className={"btn " + clsName} {...rest} value={value}>
      {label}
    </button>
  );
}

export default Button;
