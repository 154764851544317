import React from "react";
import FormLib from "../../_common/FormLib";

import { toast } from "react-toastify";
import Table from "../../_common/Table";
import { getItemStockListByHubId } from "./../../../services/stockManagement/inwardInventoryServices";
import InventorySlNo from "./barcode/InventorySlNo";
import { bayToBayAssignment, getSlNoByItemAndBaysId } from "../../../services/stockManagement/inventory";

class StroresBaysItems extends FormLib {
  state = {
    data: {
      itemId: "",
      hubId: "",
      baysType: "",
      store: "",
    },
    errors: {},
    curHub: {},
    onBaysSelection: false,
    onItemSelectionData: false,
    storeBaysId: "",
    storeBays: [],
    baysData: [],

    //
    barcodeSlNo: {},
    barcodeData: {},
    barcodeSlNumbers: [],
    barcodeDataList: [],
    loading: true,
  };

  isCur(id, cur) {
    if (id === cur) {
      return " curTasks ";
    }
    return " ";
  }

  componentDidMount() {
    this.setCurHub(this.props.curHub);
    // this.loadSlNoId();
  }
  setCurHub(p) {
    this.setState({ curHub: p });
  }
  setCurStore(m) {
    if (m.itemId !== "") {
      this.setState({ data: m });
    } else {
      this.resetData();
    }
  }

  tableMeta = {
    key: "reportId",
    columns: [
      { path: "baysName", label: "Bay" },
      { path: "baysType", label: "type" },

      {
        key: "item",
        action: (r) => (
          <>
            <i
              data-target="#myBarcodeModalSlNo"
              data-backdrop="false"
              data-toggle="modal"
              aria-hidden="true"
            >
              <span
                className="badge badge-light link-over "
                onClick={() => this.onItemSelection(r)}
              >
                {r.item}
              </span>
            </i>
          </>
        ),
        label: "item",
        icon: () => "item",
      },

      { path: "stock", label: "Stock" },
      { path: "retailerCode", label: "retailerCode" },
      {
        key: "baysName",
        action: (r) => (
          <>
            <span
              className="badge badge-warning link-over"
              onClick={() => this.onBaySelection(r)}
            >
              Transfer Items
            </span>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
    ],
  };

  onBaySelection = (data) => {

    this.setState({ baysData: data });
    this.loadstoreBays();
    this.setState({ onBaysSelection: true });
  };

  loadstoreBays = async () => {
    try {
      let curInventoryHub = localStorage.getItem("inventoryHub");
      let res = await getItemStockListByHubId(curInventoryHub);
      if (res.data.data) {
        this.setState({ storeBays: res.data.data.storeBaysId });
      }
    } catch {
      console.log("Error in InventoryBlock>>>>>>>loadstoreBays");
    }
  };

  onItemSelection = (data) => {
    // this.setState({ hubId: e.target.value }, () => {
    //   this.loadUsers();
    // });

    this.setState({ barcodeData: data }, () => {
      this.loadSlNoId();
    });

    this.setState({ onItemSelectionData: true });
  };

  async loadSlNoId() {
    this.setState({ loading: true });
    let params = {
      itemId: this.state.barcodeData.itemId,
      storeBaysId: this.state.barcodeData.storeBaysId,
      hubId: this.state.barcodeData.hubId,
    };

    const res = await getSlNoByItemAndBaysId(params);
    // console.log(
    //   this.props.baysItemsList,
    //   "------------------------------------------------->"
    // );
    if (res.data.success === true) {
      this.setState({
        barcodeSlNumbers: res.data.data.slNoIdItemAndBayIdDetails,
      });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  resetData() {
    this.setState({
      data: {
        hubId: "",
        itemId: "",
      },
    });
  }
  handleSubmitItem = async () => {
    let curInventoryHub = localStorage.getItem("inventoryHub");

    let resultSet = {
      clientId: this.state.baysData.retailerId,
      curStoreBayId: this.state.baysData.storeBaysId,
      toStoreBayId: this.state.data.baysType,
      isSerial: this.state.baysData.isSerial,
      itemId: this.state.baysData.itemId,
      serialNo: this.state.data["serial_" + this.state.baysData["itemId"]],
      qnt: this.state.data["qnt_" + this.state.baysData["itemId"]],
      curStoreId: this.state.baysData.storeId,
      ToStoreId: this.state.data.store,
      curHubId: curInventoryHub,
      ToHubId: "",
    };
    // console.log("resultSet........." + JSON.stringify(resultSet));

    const resp = await bayToBayAssignment(resultSet);
    if (resp.data.success === true) {
      this.setState({ onBaysSelection: false });
      this.props.onReload(this.state.baysData.itemId);
      this.setState({ baysData: [] });
    }
  };

  render() {
    const baysItemsList = this.props.baysItemsList;
    const stores = this.props.stores;

    return (
      <>
        <div className="card  col-12" style={{ margin: 0, padding: 2 }}>
          <h5 className="card-header">Bays & Items</h5>
          {baysItemsList && baysItemsList.length > 0 && (
            <div className="card-body" style={{ padding: 2 }}>
              <Table
                clsName="table table-striped border  table-hover"
                tableMeta={this.tableMeta}
                data={baysItemsList}
                dropMenu={[]}
              />
              <h5 className="card-header">Transfer Items from Bay</h5>
              {this.state.onBaysSelection === true && (
                <>
                  <div className="card-body" style={{ padding: 2 }}>
                    <div className="form-row mt-5">
                      {this.rSB(
                        "Stores * :",
                        "store",
                        stores,
                        { value: "storeId", label: "store" },
                        " col-6 mt-2"
                      )}
                      {this.rSB(
                        "Transfer Bay's *:",
                        "baysType",
                        this.state.storeBays,
                        { value: "storeBaysId", label: "baysName" },
                        " col-6 mt-2"
                      )}

                      <>
                        {this.state.baysData.isSerial === "no" && (
                          <>
                            {this.rNBm(
                              "Quantity ",
                              "qnt_" + this.state.baysData["itemId"],
                              "col-5"
                            )}
                          </>
                        )}
                        {this.state.baysData.isSerial === "yes" && (
                          <>
                            <>
                              {this.rTB(
                                "Serial",
                                "serial_" + this.state.baysData["itemId"],
                                "col-6"
                              )}{" "}
                            </>
                          </>
                        )}
                      </>
                      <div className="col mt-2">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.handleSubmitItem}
                          disabled={
                            this.state.data.store === "" ||
                            this.state.data.baysType === ""
                          }
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {this.state.onItemSelectionData === true && (
            <InventorySlNo
              barcodeSlNo={this.state.barcodeSlNumbers}
              key={this.state.barcodeSlNumbers.slNoId}
            />
          )}
        </div>
      </>
    );
  }
}

export default StroresBaysItems;
