import _http from "./../_httpService";

import config from "./../../config.json";
import { tDate2yyyymmddhhmmss } from "./../../utils/DateUtils";

const baseURL = config[config.build].apiURLValidata;

function api(path, subURL = "/4team/dayPlan") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function createPlan(req) {
  const param = { ...req };
  delete param.planId;
  param.planDate = tDate2yyyymmddhhmmss(param.planDate + "T00:00");

  return _http.post(api(""), param, _http.headerJWT());
}

export function getPlans() {
  return _http.get(api(""), _http.headerJWT());
}

export function getDailyPlans(req, pageNo) {
  try {
    // return _http.put(api("daily-plans/" + pageNo), param, _http.headerJWT());
    // return _http.get(api(pageNo), param, _http.headerJWT());
    // return plans;
    const param = { ...req };
    param.planDateFrom = tDate2yyyymmddhhmmss(param.planDate + "T00:00");
    param.planDateTo = tDate2yyyymmddhhmmss(param.planDate + "T23:59:59");
    return _http.put(api(pageNo), param, _http.headerJWT());

  }
  catch {
    return undefined;
  }
}

export function getDailyPlansReport(req, pageNo) {
  // return _http.put(api("daily-plans/" + pageNo), param, _http.headerJWT());
  // return _http.get(api(pageNo), param, _http.headerJWT());
  // return plans;
  const param = { ...req };
  param.planDateFrom = tDate2yyyymmddhhmmss(param.planDateFrom + "T00:00");
  param.planDateTo = tDate2yyyymmddhhmmss(param.planDateTo + "T23:59:59");
  return _http.put(api(pageNo), param, _http.headerJWT());
}
