import React from "react";
import FormLib from "./../../_common/FormLib";
import { getImages } from "./../../../services/Tickets/TicketServices";
import DropVerificationProduct from "./DropVerificationProduct";
import DropVerificationBlock from "./DropVerificationBlock";
import DropVerificationTicket from "./DropVerificationTicket";
import {
  setTicketActions,
  setTicketActionsTicketCancel,
  getPackingMaterialAndProdByJobId,
  getPhotoCapturedData,
  getPackingMaterialAndProdByJobIdAdvExc,
} from "./../../../services/Tickets/TicketProcessServices";
import { ticketListReload } from "../../../store";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { IstToUTC } from "../../../utils/DateUtils";
import swal from "sweetalert";
import Joi from "joi-browser";
import BulkQCProcess from "./BulkQCProcess";
import BlockCreation from "./BlockCreation";
import FurnitureFlowProcess from "./FurnitureFlowProcess";
class Drop extends FormLib {
  state = {
    data: {},
    errors: {},
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    setImages: [],
    cancelStatus: "false",
    pageHide: "false",
    dropStatus: "false",
    isProductVerified: false,
    isProductVerifiedFurn: false,
    isProductNotAttempted: "false",
    mode: "",
    curAction: "",
    photoAvailable: false,
    dropItemsVerified: false,
  };
  componentDidMount() {
    this.loadPackingMaterialData();
    this.loadInit();
    let pageHide = this.props.hideAction;
    this.setState({ pageHide });
    this.setState({ curStatus: this.props.curStatus });
    this.loadImages();
    this.getlocalDateTime();
    this.getNoOfPhotos();
  }


  async getNoOfPhotos() {
    let deliveryType = "";
    if(this.props.ticketDetails.deliveryType === null){
      deliveryType = "default"
    }
    else{
      deliveryType = this.props.ticketDetails.deliveryType
    }
    const params = {
      teamId: this.props.ticketDetails.retailerId,
      flowId: this.props.ticketDetails.flowId,
      flowStatus: "DROP_MOB",
      deliveryType: deliveryType
    }

    // console.log(params)
    const resp = await getPhotoCapturedData(params)
    const data = JSON.stringify(resp.data.data.resultSet)
    // console.log("resp=======>NoOfPhotos",data )
    const respArr = [];
    const finalArr = [];
    resp.data.data.resultSet.map((res) => {
      const data = JSON.parse(res.resultSet)
      return respArr.push(data)
    })

    // console.log("respArr", respArr)

    // console.log("DroprespArr", respArr[0].afterActivity.images)
    const afterActivityImages = respArr[0].afterActivity.images;
    afterActivityImages.map((r) => {
      finalArr.push(r.actionCode)
      return finalArr
    })
    const beforeActivityImages = respArr[0].beforeActivity.images;
    beforeActivityImages.map((r) => {
      finalArr.push(r.actionCode)
      return finalArr
    })
    // console.log("final Array======>", finalArr)
    this.setState({initialPhotoValidationArr: finalArr});
  }


  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }
  schema = {
    includeCancelTicket: Joi.string().optional().label("includeCancelTicket"),
  };
  includeCancelTicket = [
    { sortKey: "yes", sort: "Do you want to cancel Ticket??" },
  ];
  handleCancelTicket = async () => {
    swal({
      title: "Are you sure want to Cancel the Ticket?",
      text: "Kindly Enter the Reason for Ticket Cancellation",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: "input",
      placeholder: "Write something",
    }).then(async (handleCancelTicket) => {
      this.doCancelTicket(handleCancelTicket);
    });
  };
  doCancelTicket = async (handleCancelTicket) => {
    if (handleCancelTicket) {
      // alert(handleRTO)
      swal(
        "Ticket has been successfully Cancelled with the following remarks: " +
          handleCancelTicket,
        {
          icon: "success",
        }
      );
      this.submitCancelTicket(handleCancelTicket);
    } else {
      swal("Ticket not cancelled!", { icon: "error" });
    }
  };
  submitCancelTicket = async (handleCancelTicket) => {
    let resultSet = {
      remarks: handleCancelTicket,
      actionStatus: "OPS_CANCALLATION",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    let data = this.state.data;
    data = {
      actionCode: "wOPSCancelled",
      action: "Ticket Cancelled",
    };
    this.setState({ data });
    let resp = await setTicketActionsTicketCancel(
      this.state.data,
      this.state.curTicketDetails,
      this.state.curBlockDetails,
      resultSet
    );
    if (resp !== undefined) {
      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ cancelStatus: "true" });
          // this.setState({ rtoStatus: "false" });
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
  };
  loadInit() {
    const curTicketDetails = this.props.ticketDetails;
    const curBlockDetails = this.props.blockDetails;
    const curProductDetails = this.props.ticketDetails.products;
    const curProductDetailsFurn = this.props.blockDetails.products;
    this.prodVerificationFurniture(curProductDetailsFurn);
    this.prodVerification(curProductDetails);
    this.setState({ curTicketDetails });
    this.setState({ curBlockDetails });
    this.setState({ curProductDetails });
    this.setState({ curProductDetailsFurn });
    let hoForm = localStorage.getItem("curHub");
    if (hoForm === "1lUeT6ivx3d1DXPKW74SdKyLQrM=") {
      this.setState({ hideForm: "true" });
    } else {
      this.setState({ hideForm: "false" });
    }
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.setState({ designation: userInfo.designation });
  }
  doSubmit = async () => {
    let override = { ...this.state.data };
    override["actionCode"] = "wDropConfirm";
    override["action"] = "Confirm Drop";
    // this.setState({ override });
    let resultSet = {
      blockId: this.state.curBlockDetails.ticketBlockId,
      processId: this.state.curBlockDetails.processId,
      actionStatus: "DROP_CONFIRMED",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    if (
      this.state.curTicketDetails.flowId === "eWaste" ||
      this.state.curTicketDetails.flowId === "Bulk" ||
      this.state.curTicketDetails.flowId === "Furniture"
      // this.state.curTicketDetails.flowId === "DropOff" ||
      // this.state.curTicketDetails.flowId === "PickAndDropOneWay"

    ) {
      if (
        this.state.isProductVerified === true &&
        this.state.curTicketDetails.flowId !== "Furniture"
      ) {
        swal({
          icon: "error",
          title: "Kindly Check Products Status",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null;
      } else if (
        this.state.isProductVerifiedFurn === true &&
        this.state.curTicketDetails.flowId === "Furniture"
      ) {
        swal({
          icon: "error",
          title: "Kindly Check Products Status",
          dangerMode: true,
        }).then((res) => {
          return null;
        });
        return null;
      }
    }
    let resp = await setTicketActions(
      override,
      this.state.curTicketDetails,
      this.state.curBlockDetails,
      resultSet
    );
    if (resp.data.success === true) {
      if (resp.data.data) {
        this.handleTicketListReload();
        this.setState({ dropStatus: "true" });
      } else {
        toast.warning(() => <div>{resp.data.msg}</div>);
      }
    } else {
      toast.error(() => <div>{resp.data.msg}</div>);
    }
  };
  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };
  async loadImages() {
    const processId = this.props.ticketDetails.processId;
    let res = await getImages(processId);
    if (res !== undefined) {
      if (res.data.success === true && res.data.data) {
        this.setState({ setImages: res.data.data.images });
        res.data.data.images.map((t) => {
          if (t.action === "POD") {
            this.setState({ photoAvailable: true });
          }
        });
      } else if (res.data.success === false) {
        toast.error("No Images Available for this Block");
      }
    }
  }
  replaceString = (str) => {
    return str.replace(/_/g, " ");
  };
  stringReplace(data) {
    return data
      .replace(/{/g, "")
      .replace(/}/g, "")
      .replace(/]/g, "")
      .replace(/"/g, "")
      .replace(/:/g, " : ")
      .replace(/,/g, " , ");
  }
  LatLong(data) {
    let result = JSON.parse(data);
    if (result) {
      return "[ " + result["Latitude"] + " , " + result["Longitude"] + " ]";
    }
  }
  prodVerification = async (curProductDetails) => {
    curProductDetails.map((r) => {
      if (r.prodQcStatus === "assign" || r.prodQcStatus === "open") {
        this.handleConfirmProductVerification();
      }
    });
  };
  prodVerificationFurniture = async (curProductDetails) => {
    if (curProductDetails) {
      curProductDetails.map((r) => {
        if (r.prodQcStatus === "assign" || r.prodQcStatus === "open") {
          this.handleConfirmProductVerificationFurn();
        } else if (r.prodQcStatus === "No") {
          this.setState({ curAction: "Block" });
          this.setState({ isProductVerifiedFurn: true });
        }
      });
    }
  };
  handleConfirmProductVerification = () => {
    this.setState({ isProductVerified: true });
  };
  handleConfirmProductVerificationFurn = () => {
    this.setState({ isProductVerifiedFurn: true });
  };
  handleNotAttemptProductVerification = () => {
    this.handleTicketListReload();
    this.setState({ isProductVerified: false });
    this.setState({ isProductNotAttempted: "true" });
  };
  handleBlockCreate = () => {
    this.handleTicketListReload();
  };
  onModeChange(mode) {
    this.setState({ mode: mode });
  }
  loadPackingMaterialData = async () => {
    try {
      let blockData = this.props.blockDetails;
      let ticketData = this.props.ticketDetails
      let res = await getPackingMaterialAndProdByJobIdAdvExc(blockData,ticketData);
      if (res !== undefined) {
        if (res.data.success === true && res.data.data) {
          this.getPackingMaterialAndProdStatus(
            res.data.data.packingMaterialDetails
          );
          this.setState({ confirmData: true });
        } else {
          this.setState({ confirmData: false });
        }
      } else {
        this.setState({ confirmData: false });
      }
    } catch {
      console.log(
        "Error in loadPackingMaterialData>>>>>>>>>>>>>>>>>>>>>>>>>>PickUp"
      );
    }
  };
  getPackingMaterialAndProdStatus = async (data) => {
    if (data.length > 0) {

    data.map((r) => {
      console.log(r.productDetails[0].itemStatus)
      if (r.packMaterialId) {
        if (r.packMaterialItemStatus === "itemsDropVerified") {
          this.setState({ dropItemsVerified: true });
          // this.forceUpdate();
        }
        else{
          this.setState({ dropItemsVerified: false });
        }
      } else {
        if (r.productDetails[0].itemStatus  === "itemsDropVerified") {
          this.setState({ dropItemsVerified: true });
          // this.forceUpdate();
        }
        else{
          this.setState({ dropItemsVerified: false });
        }
      }
    });
  } else {
    this.setState({ dropItemsAssigned: true });
    this.setState({ confirmData: false });
  }
  };
  render() {
    const ticketDetails = this.state.curTicketDetails;
    console.log({dropItemsVerified :this.state.dropItemsVerified })
    return (
      <>
        {/* <div className="row  ">
          {(this.state.curAction === "Block" && this.state.curTicketDetails.flowId === "Furniture") && (
            <div className="col-12">
              <button
                onClick={() => this.onModeChange("Enable")}
                className={
                  this.state.mode === "Enable"
                    ? " btn btn-info btn-sm pull-right"
                    : "btn  btn-warning btn-sm pull-right"
                }
              // className="btn btn-light btn-sm pull-right"
              >
                Create Jobs
              </button>
            </div>
          )}
        </div> */}
        <div className="row  ">
          {this.state.pageHide === "false" &&
            this.state.dropStatus === "false" &&
            this.state.hideForm === "false" &&
            this.state.cancelStatus === "false" &&
            this.state.isProductNotAttempted === "false" && (
              <>
                <div className="row">
                  {this.state.curAction === "Block" &&
                    this.state.curTicketDetails.flowId === "Furniture" && (
                      <BlockCreation
                        ticketDetails={this.state.curTicketDetails}
                        blockDetails={this.state.curBlockDetails}
                        key={this.state.curBlockDetails.ticketBlockId}
                        handleBlockCreate={this.handleBlockCreate}
                      />
                    )}
                </div>
                {this.state.setImages.length !== 0 && (
                  <div className="col-md-12">
                    <div className="card ">
                      <div className="card-body bg-light">
                        <div className="form-subHeader">
                          <b>{this.state.curTicketDetails.ticketNo}</b>
                          {this.state.designation === "Operations Head" && (
                            <div
                              className="pull-right"
                              style={{ marginTop: "-1%", marginRight: "23%" }}
                            >
                              {this.rCBHL(
                                "includeCancelTicket",
                                this.includeCancelTicket,
                                {
                                  value: "sortKey",
                                  label: "sort",
                                }
                              )}
                            </div>
                          )}
                          {this.state.data.includeCancelTicket === "yes" && (
                            <div
                              className="pull-right"
                              style={{ marginTop: "1%", marginRight: "-40%" }}
                            >
                              <button
                                title="Cancel Ticket"
                                className="badge badge-danger  pull-right"
                                type="button"
                                onClick={() => this.handleCancelTicket()}
                                style={{
                                  fontSize: "82%",
                                  borderRadius: "2.25rem ",
                                  border: "none",
                                  fontWeight: "bold",
                                  marginRight: "6px",
                                }}
                              >
                                CANCEL TICKET
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="form-subHeader">
                          Before Packing Photos Verification
                        </div>
                        {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode !== "mSignature" && t.actionCode !== "mPhotoId" && t.actionCode !== "mPhotoAftrPackng" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode !== "mSignature" &&
                                  t.actionCode !== "mPhotoId" &&
                                  t.actionCode !== "mPhotoAftrPackng" && (
                                    <div className="col-md-4">
                                      <span>
                                        <a
                                          href="javascript:void(0);"
                                          data-toggle="tooltip"
                                          title={this.stringReplace(
                                            t.geoLocation
                                          )}
                                        >
                                          <img
                                            src={t.imageUrl}
                                            style={{
                                              width: "150px",
                                              height: "150px",
                                              margin: "3px",
                                            }}
                                          />
                                        </a>
                                        <br></br>
                                        <span
                                          className="badge badge-info link-over ml-2"
                                          style={{
                                            width: "auto",
                                            height: "auto",
                                            margin: "3px",
                                          }}
                                        >
                                          {t.action}
                                        </span>
                                      </span>
                                      <br></br>
                                      {this.LatLong(t.geoLocation)}
                                      <br></br>
                                    </div>
                                  )}
                              </>
                            );
                          })}
                        </div>
                        {ticketDetails.flowCode !== "PND2W" && (
                          <div className="form-subHeader mt-2">
                            After Packing Photos Verification
                          </div>
                        )}
                        {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode === "mPhotoAftrPackng" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}>
                                    <img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a>
                                    <span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode === "mPhotoAftrPackng" && (
                                  <div className="col-md-4">
                                    <span>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="tooltip"
                                        title={this.stringReplace(
                                          t.geoLocation
                                        )}
                                      >
                                        <img
                                          src={t.imageUrl}
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            margin: "3px",
                                          }}
                                        />
                                      </a>
                                      <br></br>
                                      <span
                                        className="badge badge-warning link-over ml-2"
                                        style={{
                                          width: "auto",
                                          height: "auto",
                                          margin: "3px",
                                        }}
                                      >
                                        {t.action}
                                      </span>
                                    </span>
                                    <br></br>
                                    {this.LatLong(t.geoLocation)}
                                    <br></br>
                                    <br></br>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                        <div className="form-subHeader mt-2">
                          Photo Id Verification
                        </div>
                        {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode === "mPhotoId" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{t.action}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode === "mPhotoId" && (
                                  <div className="col-md-4">
                                    <span>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="tooltip"
                                        title={this.stringReplace(
                                          t.geoLocation
                                        )}
                                      >
                                        <img
                                          src={t.imageUrl}
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            margin: "3px",
                                          }}
                                        />
                                      </a>
                                      <br></br>
                                      <span
                                        className="badge badge-danger link-over ml-2"
                                        style={{
                                          width: "auto",
                                          height: "auto",
                                          margin: "3px",
                                        }}
                                      >
                                        {t.action}
                                      </span>
                                    </span>
                                    <br></br>
                                    {this.LatLong(t.geoLocation)}
                                    <br></br>
                                    <br></br>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                        <div className="form-subHeader mt-2">
                          Signature Verification
                        </div>
                        {/* {
                                this.state.setImages.map((t) => {
                                    return <> {t.actionCode === "mSignature" && (<><span><a href="javascript:void(0);" data-toggle="tooltip" title={this.stringReplace(t.geoLocation)}><img src={t.imageUrl} style={{ width: "150px", height: "150px", margin: "3px" }} /></a><span className='badge badge-primary link-over ml-2' style={{ width: "auto", height: "auto", margin: "3px" }}>{this.replaceString(t.action)}</span>
                                    </span></>)}
                                    </>
                                })
                            } */}
                        <div className="row">
                          {this.state.setImages.map((t) => {
                            return (
                              <>
                                {t.actionCode === "mSignature" && (
                                  <div className="col-md-4">
                                    <span>
                                      <a
                                        href="javascript:void(0);"
                                        data-toggle="tooltip"
                                        title={this.stringReplace(
                                          t.geoLocation
                                        )}
                                      >
                                        <img
                                          src={t.imageUrl}
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            margin: "3px",
                                          }}
                                        />
                                      </a>
                                      <br></br>
                                      <span
                                        className="badge badge-success link-over ml-2"
                                        style={{
                                          width: "auto",
                                          height: "auto",
                                          margin: "3px",
                                        }}
                                      >
                                        {this.replaceString(t.action)}
                                      </span>
                                    </span>
                                    <br></br>
                                    {this.LatLong(t.geoLocation)}
                                    <br></br>
                                    <br></br>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-12">
                  <div className="form-subHeader">
                    <b>{ticketDetails.ticketNo}</b>
                  </div>
                  <div className="card ">
                    <div className="card-body bg-light">
                      <div className="row mb-2">
                        <div className="col-3">
                          <span
                            className={"badge badge-info"}
                            style={{ fontSize: "12px" }}
                          >
                            <b>{ticketDetails.orderNo}</b>
                          </span>
                        </div>
                        <div className="col-3">
                          <span
                            className={"badge badge-info"}
                            style={{ fontSize: "12px" }}
                          >
                            <b>{ticketDetails.ticketNo}</b>
                          </span>
                        </div>
                        <div className="col-3">
                          <span
                            className={"badge badge-info"}
                            style={{ fontSize: "12px" }}
                          >
                            <b>{ticketDetails.flowName}</b>
                          </span>
                        </div>
                        <div className="col-3">
                          <span
                            className={"badge badge-info"}
                            style={{ fontSize: "12px" }}
                          >
                            <b>{ticketDetails.retailerName}</b>
                          </span>
                        </div>
                        {(this.state.curTicketDetails.flowId === "Bulk" ||
                          this.state.curTicketDetails.flowId === "eWaste"
                          // this.state.curTicketDetails.flowId === "DropOff"||
                          // this.state.curTicketDetails.flowId === "PickAndDropOneWay"
                          ) && (
                          <BulkQCProcess
                            ticketDetails={this.state.curTicketDetails}
                            blockDetails={this.state.curBlockDetails}
                            productDetails={this.state.curProductDetails}
                            key={
                              "bt_" + this.state.curBlockDetails.ticketBlockId
                            }
                            handleNotAttemptProductVerification={
                              this.handleNotAttemptProductVerification
                            }
                          />
                        )}
                        {this.state.curTicketDetails.flowId === "Furniture" && (
                          <FurnitureFlowProcess
                            ticketDetails={this.state.curTicketDetails}
                            blockDetails={this.state.curBlockDetails}
                            productDetails={this.state.curBlockDetails.products}
                            key={
                              "bt_" + this.state.curBlockDetails.ticketBlockId
                            }
                            handleNotAttemptProductVerification={
                              this.handleNotAttemptProductVerification
                            }
                          />
                        )}
                      </div>
                      <form onSubmit={this.handleSubmit}>
                        <DropVerificationProduct
                          productDetails={this.state.curProductDetails}
                          ticketDetails={this.state.curTicketDetails}
                          blockDetails={this.state.curBlockDetails}
                          key={"p_" + this.state.curBlockDetails.ticketBlockId}
                          onHandleGetConfirmStatus={
                            this.loadPackingMaterialData
                          }
                        />
                        <DropVerificationBlock
                          productDetails={this.state.curProductDetails}
                          ticketDetails={this.state.curTicketDetails}
                          blockDetails={this.state.curBlockDetails}
                          key={"b_" + this.state.curBlockDetails.ticketBlockId}
                        />
                        <DropVerificationTicket
                          productDetails={this.state.curProductDetails}
                          ticketDetails={this.state.curTicketDetails}
                          blockDetails={this.state.curBlockDetails}
                          key={"t_" + this.state.curBlockDetails.ticketBlockId}
                        />
                        {(this.state.dropItemsVerified === true ||
                          this.state.confirmData === false) && (
                           <>
                            {this.rBtnSP(
                              "Confirm Drop",
                              "Confirm",
                              "pull-right mt-2"
                            )}
                           </> 
                         )} 
                         {/* {this.state.photoAvailable === false && (
                          <>
                            {this.rBtnSPD(
                              "Confirm Pickup",
                              "Confirm",
                              "pull-right mt-2"
                            )}
                          </>
                        )}  */}
                      </form>
                    </div>
                  </div>
                </div>
              </>
            )}
        </div>
        {this.state.pageHide === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Kindly Complete the Previous Process
            </p>
          </div>
        )}
        {this.state.dropStatus === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Ticket No: <b>{ticketDetails.ticketNo}</b> Drop Verification
              Process Completed Successfully
            </p>
          </div>
        )}
        {this.state.isProductNotAttempted === "true" && (
          <div className="jumbotron jumbotron-fluid">
            <p className="lead text-center">
              Ticket No: <b>{ticketDetails.ticketNo}</b> Product has been
              Successfully Updated.
            </p>
          </div>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Drop);