import React from "react";
import FormLib from "./../../_common/FormLib";
import {assign,unassign} from "../../../services/menus/MenuFeaturesServices";
import toast from "../../../services/_toastService";

class MenuFeatures extends FormLib {
    state = {
        data: {},
        errors: {},
        curMenu: {menuId: ""},
        menuFeatures:[]
    };
    componentDidMount() {
        this.setCurrentFeature(this.props.loadMenuFeatures);
        this.setState({curMenu : this.props.curMenu});
       
    }

    setCurrentFeature=(m)=>{
        this.setState({menuFeatures : m});
       
    }

    localHandleChange = (e) => {
        this.asignUnAsignMenuFeatures(e);
    }

    asignUnAsignMenuFeatures = (e) => {
        let setData = {};
        setData.menuId = this.state.curMenu.menuId;
        if (e.target.checked) {
            setData.previlagesId = e.target.value;
            setData.status = "active";
            this.assignUnassign('assign',setData);
        } else {
            setData.previlagesId = e.target.value;
            setData.status = "inactive";
            this.setState({data:setData});
            this.assignUnassign('unAssign',setData);
        }
    }

    assignUnassign = async (type,setData) => {
        if (type === 'assign') {
            const res = await assign(setData);
            if(res.data.success){
                toast.toastSuccess(res.data.msg);
            }else{
                toast.toastError(res.data.msg);
            }
        } else {
            const res = await unassign(setData);
            if(res.data.success){
                toast.toastSuccess(res.data.msg);
            }else{
                toast.toastError(res.data.msg);
            }
        }
        this.props.onMenuChange(this.props.curMenu)
    }
    chkStatus = false;
    render() {
        const curMenu = this.props.curMenu;
        const features = this.props.loadMenuFeatures;

        return (
            <div className="card ">
                <h5 className="card-header">Menus Features</h5>
                <div className="card-body">
                    <div className="page-todo">
                        <div className="tasks">
                            {features.map((r) => {
                                this.chkStatus = r.menuId === null ? false : true;
                               return <div className="task nil" key={r.featureId}>
                                    <div className="desc">
                                        <span>
                                            <div className="title">
                                                {/* {this.rCBHL("hl", [{ label: r.title, value: r.featureId }])} */}

                                               {this.rCBHLLS(
                                                   "cb_" + r.featureId,
                                                   [{ label: r.title, value: r.featureId }],
                                                   { value: "value", label: "label" },
                                                   this.chkStatus,
                                                   "m-0"
                                               )}
                                                
                                                </div>
                                        </span>
                                        <div>
                                            <span className={"badge   badge-success pull-left mr-1 " + "badge-" +
                                                r.division}>{r.division}
                                            </span>
                                            <span className={"badge   badge-success pull-left mr-1 " + "badge-" +
                                                r.module}>{r.module}
                                            </span>
                                            <span className={"badge   badge-success pull-left mr-1 " + "badge-" +
                                                r.groupName}>{r.groupName}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}
export default MenuFeatures;