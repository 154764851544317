import * as app from "../appReducer/appReducerActions";
import * as act from "./locationTypes";

import {
  getCountriesAll,
  getStatesAll,
  getCitiesAll,
} from "./../../services/masterLocations/locationServices";

const CNT = "countries";
const STA = "states";
const CIT = "cities";

export const setFailedCountry = (error) => {
  return async (dispatch) => {
    dispatch(app.setError(CNT, error));
    dispatch({
      type: act.COUNTRY_LIST_ALL,
      payload: { countries: [] },
    });
  };
};
export const fetchCountries = () => {
  return async (dispatch) => {
    dispatch(app.setLoading(CNT));
    try {
      const response = await getCountriesAll();
      const countries = response.data.data;
      dispatch({
        type: act.COUNTRY_LIST_ALL,
        payload: { countries },
      });
      dispatch(app.setSuccess(CNT));
    } catch (error) {
      dispatch(setFailedCountry(error.message));
    }
  };
};

export const fetchStates = (countryId) => {
  return async (dispatch) => {
    dispatch(app.setLoading(STA));
    try {
      const response = await getStatesAll(countryId);
      const states = response.data.data;
      dispatch({
        type: act.STATE_LIST_ALL_BY_COUNTRY,
        payload: { states },
      });
      dispatch(app.setSuccess(STA));
    } catch (error) {
      dispatch(setFailedCountry(error.message));
    }
  };
};

export const fetchCities = (stateId) => {
  return async (dispatch) => {
    dispatch(app.setLoading(CIT));
    try {
      const response = await getCitiesAll(stateId);
      const cities = response.data.data;
      dispatch({
        type: act.CITY_LIST_ALL_BY_STATE,
        payload: { cities },
      });
      dispatch(app.setSuccess(CIT));
    } catch (error) {
      dispatch(setFailedCountry(error.message));
    }
  };
};
