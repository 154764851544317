import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import $ from "jquery";

import {
  createPrvFeatures,
  savePrvFeatures,
  deletePrvFeatures,
} from "./../../../services/appFeatures/prvFeaturesServices";

class ModalFeature extends FormLib {
  state = {
    data: {
      previlagesId: "",
      prvGroupId: "",
      prvCode: "",
      title: "",
      details: "",
      status: "active",
      sort: "",
    },
    errors: {},
    prvGroups: [],
    curGroup: {},
    curTitle: {},
  };

  schema = {
    previlagesId: Joi.optional().label("PrevilagesId"),
    prvGroupId: Joi.string().required().label("PrvGroupId"),
    prvCode: Joi.string().required().label("FeaCode"),
    title: Joi.string().required().label("Title"),
    details: Joi.optional().label("Details"),
    status: Joi.string().required().label("Status"),
    sort: Joi.optional().label("Sort"),
  };
  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createPrvFeatures(this.state.data);
      this.resetData(this.props.curTitle);
      this.props.onLoadPrvFeatures(this.props.curGroup);
    }
    if (this.state.btnValue === "edit") {
      res = await savePrvFeatures(this.state.data);
      this.props.onLoadPrvFeatures(this.props.curGroup, false);
    }
    if (this.state.btnValue === "delete") {
      res = await deletePrvFeatures(this.state.data);
      $("#curTitleButton").click();

      this.resetData(this.props.curGroup);
      this.props.onLoadPrvFeatures(this.props.curGroup);
    }
    toast.toastMsg(res);
  }
  componentDidMount() {
    this.setPrvGroups(this.props.prvGroups);
    this.setCurGroup(this.props.curGroup);
    this.setCurTitle(this.props.curGroup, this.props.curTitle);
  }
  setPrvGroups(prvGroups) {
    this.setState({ prvGroups: prvGroups });
  }
  setCurGroup(g) {
    this.setState({ curGroup: g });
  }
  setCurTitle(g, f) {
    if (f.previlagesId && f.previlagesId !== "") {
      this.setState({ data: f });
    } else {
      this.resetData(g);
    }
  }

  resetData(g) {
    this.setState({
      data: {
        previlagesId: "",
        prvGroupId: g.prvGroupId || "",
        prvCode: "",
        title: "",
        details: "",
        status: "active",
        sort: "",
      },
    });
  }
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  sort = [
    { sortKey: "asc", sort: "ASC" },
    { sortKey: "dsc", sort: "DSC" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;

    return (
      <div className="container-fluid">
        <div className="modal fade" id="myFeatureModel">
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Prv Features</h4>
                <button
                  type="button"
                  className="close"
                  id="curTitleButton"
                  data-dismiss="modal"
                >
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  {this.rTBH("previlagesId", "previlagesId")}
                  {this.rSB(
                    "Group*",
                    "prvGroupId",
                    this.props.prvGroups,
                    {
                      value: "prvGroupId",
                      label: "groupName",
                    })}
                  {this.rTB("FeaCode", "prvCode")}
                  {this.rTB("Title", "title")}
                  {this.rTB("Details", "details")}


                  <div className="form-row">
                    {this.rRBL("Status*", "status", this.status, this.stsConf, "col-12")}
                    {this.rRBL("Sort", "sort", this.sort, this.stsConf, "col-12")}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {/* {"==>"+this.state.data.previlagesId + "<=="} */}
                {this.state.data.previlagesId === "" &&
                  this.rBtnSP("Create", "create")}
                {this.state.data.previlagesId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {this.rBtnSD("Delete", "delete")}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalFeature;
