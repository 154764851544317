import React from "react";
import FormLib from "./../../_common/FormLib";
import BarcodeProduct from "./BarcodeProduct";
import Table from "./../../_common/Table";
import BarcodeTicket from "./BarcodeTicket";
import BarcodeBlock from "./BarcodeBlock";
import Joi from "joi-browser";
import {
  getBarcodesList,
  setTicketActions,
} from "./../../../services/Tickets/TicketProcessServices";
import Pagination from "./../../_common/Pagination";
import { toast } from "react-toastify";
import { getPackingMaterials } from "./../../../services/packing/PackingMaterialService";
import { ticketListReload } from "./../../../store";
import { connect } from "react-redux";
import { getHubsAll } from "../../../services/masterLocations/hubServices";
import { IstToUTC } from "../../../utils/DateUtils";
import {
  getTicketInfo,
  getPlayersbyRole,
} from "../../../services/Tickets/TicketServices";
import { setTicketActionsOutForProcess } from "../../../services/Tickets/TicketProcessServices";
import swal from "sweetalert";
import { setTicketActionsTicketCancel } from "./../../../services/Tickets/TicketProcessServices";

class OutForProcess extends FormLib {
  state = {
    // data: {
    //     player: "",
    //     tkPriorityChange: "",
    // },
    users: [],
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    TicketProductDetails: [],
    TicketPrimarytDetails: [],
    processStatus: "false",
    feStatus: "false",
    feStatusAssign: "false",
    cancelStatus: "false",
  };
  includeCancelTicket = [
    { sortKey: "yes", sort: "Do you want to cancel Ticket??" },
  ];
  schema = {
    player: Joi.string().optional().label("FE Assignment"),
    tkPriorityChange: Joi.optional().label("tkPriorityChange"),
    includeCancelTicket: Joi.string().optional().label("includeCancelTicket"),
  };
  componentDidMount() {
    this.loadInit();
    this.getPlayers();
    let pageHide = this.props.hideAction;
    this.setState({ pageHide });
    this.setState({ curStatus: this.props.curStatus });
    this.getlocalDateTime();
  }
  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }
  async getPlayers() {
    try {
      let hub = localStorage.getItem("curHub");
      let resp = await getPlayersbyRole(hub);
      // let resp = await getPlayersbyRoleCount(hub);

      if (resp.data.data) {
        let users = resp.data.data.playersHubs;

        const filter = resp.data.data.playersHubs.filter((r) => {
          if (!r.rlastName) {
            r.rlastName = "";
          }
          r["display"] = r.firstName + " " + r.rlastName + " / " + r.userName; //+ " / " + r.count;
          return r;
        });

        if (resp.data.success === true) {
          resp.data.data.playersHubs !== [] && this.setState({ users: filter });
          !resp.data.data.playersHubs === [] &&
            toast.warning(() => <div>{resp.data.msg}</div>);
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      }
    } catch {
      console.log("Error in getPlayers");
    }
  }

  loadInit() {
    try {
      const curTicketDetails = this.props.ticketDetails;
      const curBlockDetails = this.props.blockDetails;
      const curProductDetails = this.props.ticketDetails.products;
      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails });
      this.getTicketInfo(curTicketDetails.ticketNo);
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.setState({ designation: userInfo.designation });
    } catch {
      console.log("Error in OutForProcess>>>>>>loadInit");
    }
  }
  async getTicketInfo(ticketNo) {
    try {
      this.state.TicketProductDetails = [];
      this.state.TicketPrimarytDetails = [];
      const respDst = await getTicketInfo(ticketNo);
      if (respDst !== undefined) {
        if (respDst.data.success === true && respDst.data.data !== []) {
          this.setState({
            TicketPrimarytDetails:
              respDst.data.data.TicketDetails.primaryDetails,
          });
          this.setState({
            TicketProductDetails:
              respDst.data.data.TicketDetails.productDetails[0],
          });
        } else if (respDst.data.success === false) {
          toast.error("No Details Available for this Ticket");
        }
      }
    } catch {
      console.log("Error in OutForProces>>>>>>>>>>>getTicketInfo");
    }
  }

  onBarcodeChange(text) {
    try {
      navigator.clipboard.writeText(text);
    } catch {
      console.log("Error in OutForProcess>>>>>>onBarcodeChange");
    }
  }

  async doSubmit() {
    try {
      let override = { ...this.state.data };
      override["actionCode"] = "wOutForProcess";
      override["action"] = "Out For Process";
      this.setState({ override });
      let feAction = "";
      let newTicketPriority = "";
      if (
        this.state.curBlockDetails.flowStatus === "PICK_WEB" &&
        this.state.curTicketDetails.flowId === "PickAndDropTwoWay"
      ) {
        feAction = "ASSIGNED_FE_FOR_SERVICE_PICKUP";
      } else if (
        this.state.curBlockDetails.flowStatus === "DROP_WEB" &&
        this.state.curTicketDetails.flowId === "PickAndDropTwoWay"
      ) {
        feAction = "ASSIGNED_FE_FOR_SERVICE_DROP";
      } else if (this.state.curBlockDetails.flowStatus === "PICK_WEB") {
        feAction = "ASSIGNED_FE_FOR_PICKUP";
      } else if (this.state.curBlockDetails.flowStatus === "DROP_WEB") {
        feAction = "ASSIGNED_FE_FOR_DROP";
      } else if (this.state.curTicketDetails.flowCode === "DRPOF") {
        feAction = "ASSIGNED_FE_FOR_DROP";
      }

      const feFilter = this.state.users.filter((r) => {
        return r.playerId === this.state.curBlockDetails.playerFeId ? r : null;
      });

      this.setState({
        curFeName: feFilter[0].firstName + " " + feFilter[0].lastName,
      });

      let resultSet = {
        feId: this.state.curBlockDetails.playerFeId,
        feName: feFilter[0].firstName + " " + feFilter[0].lastName,
        fePhone: feFilter[0].phone,
        feType: "fe",
        flowId: this.state.curTicketDetails.flowId,
        newTicketPriority: newTicketPriority,
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        actionStatus: feAction,
      };
      let resp = await setTicketActionsOutForProcess(
        override,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        resultSet
      );
      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ processStatus: "true" });
          this.setState({ feStatus: "true" });
          this.setState({ feStatusAssign: "false" });
          toast.success("Ticket Assigned Successfully");
        } else {
          !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in OutForProcess>>>>>>doSubmit");
    }
  }
  async handlebtn(e) {
    try {
      this.setState({ btnValue: e });
      if (e === "changeFE") {
        this.setState({ feStatusAssign: "true" });
        this.setState({ feStatus: "false" });
      } else if (e === "changeFESubmit") {
        let override = { ...this.state.data };
        override["actionCode"] = "wFeAssign";
        override["action"] = "Assign FE to Block";
        this.setState({ override });
        let feAction = "";
        let newTicketPriority = "";
        const feFilter = this.state.users.filter((r) => {
          return r.playerId === this.state.playersId ? r : null;
        });

        this.setState({
          curFeName: feFilter[0].firstName + " " + feFilter[0].lastName,
        });

        let resultSet = {
          feId: this.state.playersId,
          feName: feFilter[0].firstName + " " + feFilter[0].lastName,
          fePhone: feFilter[0].phone,
          feType: "fe",
          flowId: this.state.curTicketDetails.flowId,
          newTicketPriority: newTicketPriority,
          processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          actionStatus: this.state.curBlockDetails.actionStatus,
        };
        let resp = await setTicketActionsOutForProcess(
          override,
          this.state.curTicketDetails,
          this.state.curBlockDetails,
          resultSet
        );
        if (resp.data.success === true) {
          if (resp.data.data) {
            this.handleTicketListReload();
            this.setState({ feStatus: "true" });
            this.setState({ feStatusAssign: "false" });

            toast.success("FE Changed Successfully");
          } else {
            !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
          }
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      } else if (e === "releaseFE") {
        this.setState({ feStatusAssign: "false" });
        // this.setState({ feStatus: "false" });
      }
    } catch {
      console.log("Error in OutForProcess>>>>>>handlebtn");
    }
  }
  curFeAssignment = (feAssign) => {
    try {
      // let ticketCount = 0;
      // if (this.state.bulkAssign === "bulk") {
      //     if (this.state.curMultiticketDetails.length === undefined) {
      //         this.ticketCount = feAssign.count + 0;
      //     }
      //     else {
      //         this.ticketCount = feAssign.count + this.state.curMultiticketDetails.length;
      //     }
      // }
      // else {
      //     this.ticketCount = feAssign.count + 1;
      // }

      // this.setState({ selectedTicketCount: this.ticketCount })
      this.setState({ playersId: feAssign.playerId });
      // this.state.data.player = feAssign.playerId;
    } catch {
      console.log("Error in OutForProcess>>>>>>curFeAssignment");
    }
  };

  handleChangeFE = async () => {
    try {
      swal({
        title: "Are you sure want to Change the FE ?",
        text: "Kindly Enter the Reason for Change",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        closeOnClickOutside: false,
        closeOnEsc: false,
        content: "input",
        placeholder: "Write something",
      }).then(async (handleChangeFE) => {
        this.doChangeFE(handleChangeFE);
      });
    } catch {
      console.log("Error in OutForProcess>>>>>>handleChangeFE");
    }
  };

  doChangeFE = async (handleChangeFE) => {
    try {
      if (handleChangeFE) {
        // alert(handleRejectRTO)
        swal(
          "FE has been successfully Changed with the following remarks: " +
            handleChangeFE,
          {
            icon: "success",
          }
        );
        this.submitChangeFE(handleChangeFE);
      } else {
        swal("FE Change Cancelled!", { icon: "error" });
      }
    } catch {
      console.log("Error in OutForProcess>>>>>>doChangeFE");
    }
  };

  submitChangeFE = async (handleChangeFE) => {
    try {
      let override = { ...this.state.data };
      override["actionCode"] = "wFeAssign";
      override["action"] = "Assign FE to Block";
      this.setState({ override });
      let feAction = "";
      let newTicketPriority = "";
      const feFilter = this.state.users.filter((r) => {
        return r.playerId === this.state.playersId ? r : null;
      });

      this.setState({
        curFeName: feFilter[0].firstName + " " + feFilter[0].lastName,
      });

      let resultSet = {
        feId: this.state.playersId,
        feName: feFilter[0].firstName + " " + feFilter[0].lastName,
        fePhone: feFilter[0].phone,
        feType: "fe",
        flowId: this.state.curTicketDetails.flowId,
        newTicketPriority: newTicketPriority,
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        actionStatus: this.state.curBlockDetails.actionStatus,
        remarks: handleChangeFE,
      };
      let resp = await setTicketActionsOutForProcess(
        override,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        resultSet
      );
      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ feStatus: "true" });
          this.setState({ feStatusAssign: "false" });

          toast.success("FE Changed Successfully");
        } else {
          !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch {
      console.log("Error in submitChangeFE");
    }
  };

  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };
  handleCancelTicket = async () => {
    swal({
      title: "Are you sure want to Cancel the Ticket?",
      text: "Kindly Enter the Reason for Ticket Cancellation",
      icon: "warning",
      buttons: true,
      dangerMode: true,
      closeOnClickOutside: false,
      closeOnEsc: false,
      content: "input",
      placeholder: "Write something",
    }).then(async (handleCancelTicket) => {
      this.doCancelTicket(handleCancelTicket);
    });
  };

  doCancelTicket = async (handleCancelTicket) => {
    if (handleCancelTicket) {
      // alert(handleRTO)
      swal(
        "Ticket has been successfully Cancelled with the following remarks: " +
          handleCancelTicket,
        {
          icon: "success",
        }
      );
      this.submitCancelTicket(handleCancelTicket);
    } else {
      swal("Ticket not cancelled!", { icon: "error" });
    }
  };

  submitCancelTicket = async (handleCancelTicket) => {
    let resultSet = {
      remarks: handleCancelTicket,
      actionStatus: "OPS_CANCALLATION",
      processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
    };
    let data = this.state.data;
    data = {
      actionCode: "wOPSCancelled",
      action: "Ticket Cancelled",
    };
    this.setState({ data });

    let resp = await setTicketActionsTicketCancel(
      this.state.data,
      this.state.curTicketDetails,
      this.state.curBlockDetails,
      resultSet
    );
    if (resp !== undefined) {
      if (resp.data.success === true) {
        if (resp.data.data) {
          this.handleTicketListReload();
          this.setState({ cancelStatus: "true" });
          // this.setState({ rtoStatus: "false" });
        } else {
          toast.warning(() => <div>{resp.data.msg}</div>);
        }
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    }
  };

  render() {
    const ticketDetails = this.state.curTicketDetails;
    return (
      <>
        <div className="row ">
          {this.state.pageHide === "false" &&
            this.state.processStatus === "false" &&
            this.state.feStatus === "false" &&
            (this.state.curBlockDetails.flowStatus === "PICK_WEB" ||
              this.state.curBlockDetails.flowStatus === "DROP_WEB") &&
            this.state.cancelStatus === "false" && (
              <div className="col-md-12">
                <div className="form-subHeader">
                  <b>
                    {"Out For Process"} -||- {ticketDetails.ticketNo}{" "}
                  </b>
                  {/* {this.state.designation === "Operations Head" && (
                                        <div className="pull-right" style={{ marginTop: "-1%", marginRight: "23%" }}>
                                            {this.rCBHL("includeCancelTicket", this.includeCancelTicket, {
                                                value: "sortKey",
                                                label: "sort",
                                            })}
                                        </div>
                                    )}
                                    {this.state.data.includeCancelTicket === "yes" && (
                                        <div className="pull-right" style={{ marginTop: "1%", marginRight: "-40%" }}>
                                            <button
                                                title="Cancel Ticket"
                                                className="badge badge-danger  pull-right"
                                                type="button"
                                                onClick={() => this.handleCancelTicket()}
                                                style={{ fontSize: "82%", borderRadius: "2.25rem ", border: "none", fontWeight: "bold", marginRight: "6px" }}
                                            >
                                                CANCEL TICKET
                                            </button>
                                        </div>
                                    )} */}
                </div>
                <div className="card ">
                  <div className="card-body  bg-custom-9">
                    <form onSubmit={this.handleSubmit}>
                      <h1
                        style={{
                          // backgroundColor: "#ddb2c8",
                          textAlign: "center",
                          marginTop: "-20px",
                          marginLeft: "-20px",
                          marginRight: "-20px",
                          padding: "5px",
                          marginBottom: "1rem",
                        }}
                      >
                        Ticket Process Details
                      </h1>
                      {(this.state.curBlockDetails.flowStatus === "PICK_WEB" ||
                        this.state.curBlockDetails.flowStatus === "PICK_MOB") &&
                        this.state.curTicketDetails.flowId === "BulkQC" && (
                          <p>
                            <b>FE Info :</b> {"BULK FE Assignment Done"}
                            <div></div>
                          </p>
                        )}
                      {(this.state.curBlockDetails.flowStatus === "PICK_WEB" ||
                        this.state.curBlockDetails.flowStatus === "PICK_MOB") &&
                        this.state.curTicketDetails.flowId !== "BulkQC" && (
                          <p>
                            <b>FE Info :</b>{" "}
                            {this.state.TicketProductDetails.pickUpFeName} |{" "}
                            {this.state.curBlockDetails.playerFeId}
                            <div>
                              <button
                                onClick={() => this.handlebtn("changeFE")}
                                // onClick={() => this.handleChangeFE()}

                                className={
                                  this.state.btnValue === "changeFE"
                                    ? " btn btn-warning btn-sm pull-right "
                                    : "btn btn-info btn-sm pull-right "
                                }
                                title="Cahnge FE"
                                style={{
                                  fontSize: "120%",
                                  borderRadius: "2.25rem ",
                                  border: "none",
                                  fontWeight: "normal",
                                  marginTop: "-20px",
                                }}
                              >
                                Change FE<b></b>
                              </button>
                            </div>
                            {/* <div >
                                                    <button
                                                        onClick={() => this.handlebtn("releaseFE")}
                                                        className={
                                                            this.state.btnValue === "releaseFE"
                                                                ? " btn btn-warning btn-sm pull-right "
                                                                : "btn btn-info btn-sm pull-right "

                                                        }
                                                        title="Release FE"
                                                        style={{ fontSize: "120%", borderRadius: "2.25rem ", border: "none", fontWeight: "normal", marginTop: "-20px" }}
                                                    >
                                                        Release FE<b></b>

                                                    </button>
                                                </div> */}
                          </p>
                        )}
                      {(this.state.curBlockDetails.flowStatus === "DROP_WEB" ||
                        this.state.curBlockDetails.flowStatus === "DROP_MOB") &&
                        this.state.curTicketDetails.flowId !== "BulkQC" && (
                          <p>
                            <b>FE Info :</b>{" "}
                            {this.state.TicketProductDetails.dropFeName} |{" "}
                            {this.state.curBlockDetails.playerFeId}
                            <div>
                              <button
                                onClick={() => this.handlebtn("changeFE")}
                                className={
                                  this.state.btnValue === "changeFE"
                                    ? " btn btn-warning btn-sm pull-right "
                                    : "btn btn-info btn-sm pull-right "
                                }
                                title="Change FE"
                                style={{
                                  fontSize: "120%",
                                  borderRadius: "2.25rem ",
                                  border: "none",
                                  fontWeight: "normal",
                                  marginTop: "-20px",
                                }}
                              >
                                Change FE<b></b>
                              </button>
                            </div>
                            {/* <div >
                                                    <button
                                                        onClick={() => this.handlebtn("releaseFE")}
                                                        className={
                                                            this.state.btnValue === "releaseFE"
                                                                ? " btn btn-warning btn-sm pull-right "
                                                                : "btn btn-info btn-sm pull-right "

                                                        }
                                                        title="Release FE"
                                                        style={{ fontSize: "120%", borderRadius: "2.25rem ", border: "none", fontWeight: "normal", marginTop: "-20px" }}
                                                    >
                                                        Release FE<b></b>

                                                    </button>
                                                </div> */}
                          </p>
                        )}

                      <p>
                        <b>Appointment Req : </b>
                        {this.state.curTicketDetails.isAppointmentReq && (
                          <>
                            {this.state.curTicketDetails.isAppointmentReq.toUpperCase()}
                          </>
                        )}
                      </p>
                      <p>
                        <b>Packing Req : </b>
                        {this.state.curTicketDetails.isPackingReq && (
                          <>
                            {this.state.curTicketDetails.isPackingReq.toUpperCase()}
                          </>
                        )}
                      </p>
                      {(this.state.curBlockDetails.flowStatus === "PICK_WEB" ||
                        this.state.curBlockDetails.flowStatus ===
                          "DROP_WEB") && (
                        <>
                          {this.rBtnLP(
                            "Out For Process",
                            "Out For Process",
                            "btn btn-success btn-sm pull-right"
                          )}
                        </>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            )}

          {this.state.processStatus === "true" && (
            <div className="jumbotron jumbotron-fluid">
              <p className="lead text-center">
                Ticket No: <b>{ticketDetails.ticketNo}</b> Out For Process
                Completed Successfully
              </p>
            </div>
          )}
        </div>
        {this.state.feStatusAssign === "true" && (
          <div className="row ">
            <div id="coAction" className="col-md-12">
              <div className="card ">
                <div className="card-body  bg-light">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-row mt-5">
                      <b>&nbsp;&nbsp;FE Assignment :</b>
                      <div id="cocardsFe" className="col-6  ml-1 mr-4">
                        <div id="primary-ticket-search" className="row ">
                          {this.state.users.map((t) => {
                            return (
                              <span key={t.playerId + "spanCover1"}>
                                <div
                                  className="card mb-2 cursorPointor "
                                  onClick={() => this.curFeAssignment(t)}
                                >
                                  <div
                                    className="card-header page-todo task"
                                    style={
                                      t.playerId &&
                                      t.playerId === this.state.playersId
                                        ? {
                                            backgroundColor: "rgb(255 225 202)",
                                          }
                                        : { backgroundColor: "white" }
                                    }
                                  >
                                    <div className="col-6 zoom">
                                      {t.display}
                                    </div>
                                  </div>
                                </div>
                              </span>
                            );
                          })}
                        </div>
                      </div>

                      {this.state.curBlockDetails.blockStatus ===
                        "DROP_DONE_ASC" &&
                        this.state.curTicketDetails.flowId ===
                          "PickAndDropTwoWay" &&
                        this.rCBHL("tkPriorityChange", this.tkPriorityChange)}
                    </div>
                    <div>
                      <button
                        // onClick={() => this.handlebtn("changeFESubmit")}
                        onClick={() => this.handleChangeFE()}
                        className={
                          this.state.btnValue === "changeFESubmit"
                            ? " btn btn-warning btn-sm pull-right "
                            : "btn btn-info btn-sm pull-right "
                        }
                        title="Change FE"
                        style={{
                          fontSize: "120%",
                          borderRadius: "2.25rem ",
                          border: "none",
                          fontWeight: "normal",
                          marginTop: "-20px",
                        }}
                      >
                        Submit Change<b></b>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* {(this.state.feStatus === "true" && this.state.feStatusAssign === "false") && (
                    <div className="jumbotron jumbotron-fluid">
                        <p className="lead text-center">
                            Ticket No: <b>{ticketDetails.ticketNo}</b> FE Changed Successfully
                        </p>
                    </div>
                )} */}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutForProcess);
