import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLCorePHP;

function api(path, subURL = "/pincodes/mis/format.php?retailerId=") {
  path = path !== "" ? "" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getRetailer(retailerID) {
  return _http.get(api(retailerID));
}