import React from 'react';
import { getRetailer } from '../../../services/masters/hubServices';
import FormLib from './../../_common/FormLib';
import ZTitle from './../../_subhead/ZTitle';
import Joi from 'joi-browser';
import { uploadCusPincodes } from '../../../services/masterLocations/pincodeServices';
import { getTeams } from './../../../services/players/teamServices';
import { toast } from 'react-toastify';

class CustomerPincodes extends FormLib {

  state = {
    data: { client: "", type: "DA" },
    clients: [],
    errors: {},
    processState: [],
    tableData: [],
    error: null
  }

  schema = {
    client: Joi.string().required().label("client"),
    type: Joi.string().required().label("Service Type"),
    pincode: Joi.string().required().label("Pincodes"),
  };

  type = [
    { service_typeId: "DA", type: "DA" },
    { service_typeId: "ODA", type: "ODA" },
    { service_typeId: "NDA", type: "NDA" },
  ];

  componentDidMount() {
    this.loadRetailers();
  }


  async loadRetailers() {
    const res = await getTeams();
    if (res.data.success === true) {
      this.setState({ clients: res.data.data.teams });
    } else {
      toast.toastError(res.data.msg);
    }
  }

  localHandleChange = (e) => {

    if (e.target.name === "pincode") {

      // console.log((e.target.value).length);
      if ((e.target.value).length >= 7) {
        let errors = this.state.errors;
        errors["pincode"] =
          `Pincode must be a 6 digits Pincode`;
        this.setState({
          errors,
        });
      } else {
        let errors = this.state.errors;
        delete errors["pincode"];
        this.setState({
          errors,
        });
      }

      this.validateProperty(e.target.name, e.target.value);
    }
    if (e.target.name === "client") {
      const { value } = e.target;
      let data = this.state.data;
      let retailer = this.state;

      // find the clients with the current selected value
      const clients = retailer.clients.find((c) => c.name === value);

      if (clients !== undefined) {
        // console.log("------>bhanthu " + JSON.stringify(clients.teamId));
        data["client"] = clients.teamId;
        this.setState({
          data,
        });
      }
      this.validateProperty(e.target.name, e.target.value);
    }
  }


  doSubmit() {
    this.setState({ processState: [] });
    // console.log("------1-----> " + JSON.stringify(this.state.data))
    let pinArry = this.state.data.pincode.split(',');
    const tableData = pinArry.map((p) => {
      return {
        pincode: p,
        client: this.state.data.client,
        type: this.state.data.type,
      }
    });
    this.setState({ tableData });
  }

  async handleAPIHit() {
    let processState;
    let res;
    this.setState({ processState: [] });

    let params = {
      type: this.state.data.type,
      retailerId: this.state.data.client
    }

    let i = 0;
    for (let r in this.state.tableData) {
      processState = this.state.processState;
      processState[i] = "Processing";
      this.setState({ processState });
      res = await uploadCusPincodes(this.state.tableData[r].pincode, params);
      // console.log("bhandhe res" + JSON.stringify(r))
      processState[i] = res.data.msg;
      i++;
      this.setState({ processState });
    }
  }




  render() {

    return (
      <React.Fragment>
        <ZTitle subHeading="Pincodes  :: Customer Pincodes" />
        <div className="pageContent">
          <div className="card">
            <div className="card-body">
              <form id="myform" onSubmit={this.handleSubmit}>
                <div className="form-row">
                  {this.rSBF(
                    "Clients *",
                    "client",
                    this.state.clients,
                    { value: "teamId", label: "name" },
                    " col-md-3 "
                  )}
                  {this.rRBL(
                    "Service Type *",
                    "type",
                    this.type,
                    {
                      value: "service_typeId",
                      label: "type",
                    },
                    "col-3"
                  )}
                </div>
                <div className="form-row">
                  {this.rTA(
                    "Pincodes *",
                    "pincode",
                    "col-12"
                  )}
                </div>
                <div className="row pull-right">
                  {this.rBtnSP(
                    "Stage",
                    "Stage",
                    "btn-primary btn-sm"
                  )}
                </div>
              </form>
            </div>


            <table className="col-12 table table-responsive">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Pincodes</th>
                  <th>Service Type</th>
                </tr>
              </thead>
              <tbody>
                {this.state.tableData.map(
                  (tableData, i) => (
                    <tr
                      key={tableData.pincode}
                    >
                      <td>{this.state.processState[i]}</td>
                      <td>{tableData.pincode}</td>
                      <td>{tableData.type}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>

          </div>
          <br />
          <button
            className="btn  btn-info  btn-sm pull-right"
            onClick={() => this.handleAPIHit()}
          >
            Submit
          </button>
        </div>
      </React.Fragment>
    );
  }

}
export default CustomerPincodes;
