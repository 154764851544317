import React from "react";

function Textbox({ name, label, error, classAppend = "", ...rest }) {
  return (
    <div className={classAppend + " form-group "}>
      <input id={name} name={name} {...rest} />
      <label htmlFor={name} className="control-label">
        {label}
      </label>
      <i className="bar"></i>
      {error && <span className="alert alert-danger">{error}</span>}
    </div>
  );
}

export default Textbox;
