import React, { useState, useEffect } from "react";
import toast from "../../../services/_toastService";
import _ from "lodash";
import { faker } from "@faker-js/faker";

import BarChart from "./../../_charts/BarChart";

import { getCountByStatusHub } from "./../../../services/dashboards/metrics";
import { components } from "react-select";
import { Component } from "react";

const CountByStatusHub = (props) => {
  const [stateCount, setCount] = useState(0);
  const [stateloading, setLoading] = useState(false);
  const [stateMetrics, setMetrics] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    setCount(stateCount + 1);
    document.title = `You clicked ${stateCount} times`;
    handleCountByStatusHub(props.hubs);
  }, []);

  const handleCountByStatusHub = async (hubs) => {
    setLoading(true);
    const res = await getCountByStatusHub({
      curDate: new Date(), // "2022-11-19",
      hubs: hubs,
      retailers: [],
      flows: [],
    });

    /*
[
        "202514",
        "564800",
        "5067",
        "185584",

        /*
        202514
        564828
        934742
        944330

        564800
        3486014
        bhiw1653314900933TZ986eb68f946e4a75b6614536b3dce3a9
        3486275

        185584
        5067
        7524
        blr1658330070905TZe2f734719dd24bf4af7decc2cdf86552
        

        // "7524",
        // "3486014",
        // "944330",
        // "564828",

        // "3486275",
        // "15165614",
        // "37119150",
        // "66750808",

        // "24632521",
        // "blr1658330763471TZbe6c98f3ae3c495c82198a53cb86c7d2",
        // "934742",
        // "bhiw1653314900933TZ986eb68f946e4a75b6614536b3dce3a9",
      
    */

    // "3486275", "118720657", "5067"
    console.info(res);
    if (res && res["data"] && res["data"]["success"] === true) {
      //   this.setState({ countries: res.data.data.countries });
      
      // let chartMetrics = await buildParams(res.data.data.metrics);
      let chartMetrics = await buildParams(res.data.data.countByStatusHub);
      console.info(JSON.stringify(chartMetrics));
      setMetrics(chartMetrics);
      setCount(stateCount + 1);
    } else {
      toast.toastError(res.data.msg);
    }
    setLoading({ loading: false });
  };

  const colorCodes = (status) => {
    if (status == "00-Intiate") {
      return "rgba(139, 224, 177, 0.5)";
    }
    if (status == "01-In Field") {
      return "rgba(53, 162, 235, 0.5)";
    }
    if (status == "02-Field Ops Done") {
      return "rgba(148, 178, 105, 0.65)";
    }
    if (status == "03-Field Ops Done -Ve") {
      return "rgba(244, 220, 39, 0.65)";
    }
    if (status == "04-Linehaul") {
      return "rgba(255, 99, 132, 0.5)";
    }
    if (status == "05-Linehaul-in-Transit") {
      return "rgba(6, 105, 45, 0.65)";
    }
    if (status == "95-Idle") {
      return "rgba(91, 123, 147, 0.65)";
    }
    if (status == "99-Closed") {
      return "rgba(174, 174, 174, 0.65)";
    }
    return "rgba(92, 96, 98, 0.65)";
  };

  const groupTicketStatus = (status) => {
    if (status.substring(0, 5) == "IDLE_") {
      return "95-Idle";
    }

    if (
      status == "APPOINTMENT_FIXED" ||
      status == "HANDED_OVER_PACKING_MATERIALS" ||
      status == "BLOCK_CREATED" ||
      status == "CHOOSE_FE_FOR_DROP" ||
      status == "CHOOSE_FE_FOR_DROP_ASC" ||
      status == "PART_RECEIVED" ||
      status == "RELEASE_FE_AT_DROP" ||
      status == "RELEASE_FE_AT_SERVICE_DROP" ||
      status == "RELEASE_FE_AT_SERVICE_PICKUP" ||
      status == "RTO_INITIATE" ||
      status == "RTO_REJECT"
    ) {
      return "00-Intiate";
    }

    if (
      status == "ASSIGNED_FE_FOR_PICKUP" ||
      status == "ASSIGNED_FE_FOR_DROP" ||
      status == "ASSIGNED_FE_FOR_SERVICE_DROP" ||
      status == "ASSIGNED_FE_FOR_SERVICE_PICKUP" ||
      status == "FE_ASSIGNED_FOR_PICKUP"
    ) {
      return "01-In Field";
    }

    if (
      status == "PICKUP_DONE" ||
      status == "PICK_UP_DONE_EXCHANGE_SUCCESSFULLY" ||
      status == "PICKUP_DONE_ASC" ||
      status == "DROP_DONE" ||
      status == "DROP_DONE_ASC" ||
      status == "DROP_CONFIRMED" ||
      status == "INSTALL_SERVICE_DONE"
    ) {
      return "02-Field Ops Done";
    }

    if (
      status == "REJECT_SERVICE_FE_AT_PICKUP" ||
      status == "REJECT_SERVICE_FE_AT_DROP" ||
      status == "PART_MISSING" ||
      status == "RESCHEDULE_FE_AT_PICKUP" ||
      status == "RESCHEDULE_AT_PICKUP" ||
      status == "REJECT_FE" ||
      status == "REJECT_SERVICE_AT_PICKUP" ||
      status == "RESCHEDULE_FE_AT_DROP" ||
      status == "RELEASE_FE_AT_CUSTOMER_DROP" ||
      status == "RTO_REQUEST" ||
      status == "OLD_PRODUCT_DAMAGED_EXCHANGE_CANCELLED"
    ) {
      return "03-Field Ops Done -Ve";
    }

    if (status == "LINEHAUL") {
      return "04-Linehaul";
    }

    if (status == "LINEHAUL_IN_TRANSIT") {
      return "05-Linehaul-in-Transit";
    }

    if (
      status == "BLOCK_CLOSED" ||
      status == "PROBLEM_SOLVED_DURING_INITIAL_CALL"
    ) {
      return "99-Closed";
    }
    return status;
  };

  const buildParams = async (metrics) => {
    let rowSets = [];
    let datasets = [];

    let singleSet = {};
    // ==================================
    let labels = [];
    let blockStatus = [];

    let curTicketStatus = "";
    let preSet = {};
    console.log("metrics", metrics)
    metrics.map((r) => {
      if (
        r.blockStatus &&
        r.blockStatus != "BLOCK_CREATED" &&
        r.blockStatus != "BLOCK_CLOSED"
      ) {
        if (labels.indexOf(r.hub) < 0) {
          labels.push(r.hub);
        }

        curTicketStatus = groupTicketStatus(r.blockStatus);

        if (blockStatus.indexOf(curTicketStatus) < 0) {
          blockStatus.push(curTicketStatus);
        }

        if (!preSet[r.hub]) {
          preSet[r.hub] = {};
        }

        if (!preSet[r.hub][curTicketStatus]) {
          preSet[r.hub][curTicketStatus] = 0;
        }

        preSet[r.hub][curTicketStatus] =
          preSet[r.hub][curTicketStatus] + r.counts;
      }
    });
    // +++++++++++++++++++++++++++++++++++++++++
    // metrics.map((r) => {
    //   if (r.blockStatus && r.blockStatus != "BLOCK_CREATED") {
    //     labels.push(r.hub);
    //     singleSet = {};

    //     curTicketStatus = groupTicketStatus(r.blockStatus);

    //     if (!rowSets[curTicketStatus]) {
    //       rowSets[curTicketStatus] = {};
    //     }
    //     if (!rowSets[curTicketStatus]["data"]) {
    //       rowSets[curTicketStatus]["data"] = [];
    //     }
    //     //        Array.indexOf(searchElement, fromIndex);

    //     rowSets[curTicketStatus]["label"] = curTicketStatus;
    //     rowSets[curTicketStatus]["backgroundColor"] = "rgba(53, 162, 235, 0.5)";
    //     rowSets[curTicketStatus]["data"].push(r.ticketCounts);
    //   }

    //   if (!preSet[r.hub]) {
    //     console.log(r.hub);
    //     preSet[r.hub] = {};
    //   } else {
    //     console.log("<==" + r.hub);
    //   }

    //   if (!preSet[r.hub][curTicketStatus]) {
    //     preSet[r.hub][curTicketStatus] = 0;
    //   }

    //   preSet[r.hub][curTicketStatus] =
    //     preSet[r.hub][curTicketStatus] + r.ticketCounts;

    //   // datasets.push(singleSet[curTicketStatus]);
    // });

    labels = _.sortBy(_.uniq(labels));
    blockStatus = _.sortBy(_.uniq(blockStatus));

    // console.log(rowSets);
    // console.log("====>" + JSON.stringify(preSet));

    // rowSets.map((k->r) => {
    //   console.log("======================================>");
    //   datasets.push(r);
    // });

    // console.log({blockStatus, labels})

    blockStatus.map((s) => {
      let rowSet = {
        label: s,
        data: [],
        backgroundColor: colorCodes(s),
      };
      labels.map((k) => {
        // console.log(`${key}: ${rowSets[key]}`);
        if (preSet[k][s]) {
          rowSet.data.push(preSet[k][s]);
        } else {
          rowSet.data.push(0);
        }
      });
      datasets.push(rowSet);
    });

    // for (const key in rowSets) {
    //   // console.log(`${key}: ${rowSets[key]}`);
    //   datasets.push(rowSets[key]);
    // }
    return {
      labels,

      datasets,
    };
  };

  return (
    <div className="row">
      <div className="col-12" style={{ height: 250 }}>
        <BarChart
          title={"Count By Hubs"}
          chartData={stateMetrics}
          key={stateCount}
        />
      </div>
    </div>
  );
};

export default CountByStatusHub;

// console.log(r.blockStatus.substring(0, 4));
// if (!datasets["IDLE"]) {
//   datasets["IDLE"] = {};
// }
// if (!datasets["IDLE"]["data"]) {
//   datasets["IDLE"]["data"] = [];
// }

// datasets["IDLE"]["label"] = "IDLE";
// datasets["IDLE"]["backgroundColor"] = "rgba(53, 162, 235, 0.5)";

// if (!datasets["IDLE"]["data"][r.hub]) {
//   datasets["IDLE"]["data"][r.hub] = 0;
// }
// datasets["IDLE"]["data"][r.hub] += r.ticketCounts;
