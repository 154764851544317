import React from "react";
import FormLib from "./../../_common/FormLib";

import { toast } from "react-toastify";
import Table from "../../_common/Table";
import InventorySlNo from "./barcode/InventorySlNo";

class StoresItems extends FormLib {
  state = {
    data: {
      itemId: "",
      hubId: "",
    },
    errors: {},
    curHub: {},
    fStoreItemsList: [],
    fSelectedClient: "",
    clientList: [],
    // data1:[],
  };

  isCur(id, cur) {
    if (id === cur) {
      return " curTasks ";
    }
    return " ";
  }

  componentDidMount() {
    this.setCurHub(this.props.curHub);
  }
  setCurHub(p) {
    this.setState({ curHub: p });
  }
  setCurStore(m) {
    if (m.itemId !== "") {
      this.setState({ data: m });
    } else {
      this.resetData();
    }
    // console.log(JSON.stringify(this.state.data));
  }

  tableMeta = {
    key: "reportId",
    columns: [
      // { path: "retailerCode", label: "Retailer" },
      {
        key: "retailerCode",
        action: (r) => <>{<span>{r?.retailerCode}</span>}</>,
        label: "Client",
        icon: () => (
          <div className="d-flex flex-column">
            <p>Client</p>
            <select
              name="selectClient"
              id="selectClient"
              onChange={(e) => this.handleFilterSelect(e.target.value)}
              value={this.props.teamValue}
            >
              <option value="">All</option>
              {this.state.clientList.map((client) => (
                <option key={client} value={client}>
                  {client}
                </option>
              ))}
            </select>
          </div>
        ),
      },

      {
        key: "item",
        action: (r) => (
          <>
            {
              <span
                style={{ cursor: "pointer" }}
                onClick={() => this.props.onItemChange(r)}
              >
                {r.item}
              </span>
            }
          </>
        ),
        label: "item",
        icon: () => "item",
      },
      { path: "itemCode", label: "itemCode" },

      { path: "stock", label: "Stock" },
      { path: "bays", label: "Bays" },

      /*
      {
        key: "DateTime",
        action: (r) => <>{dateFormat(r.reportFrom, "dd-mm-yyyy")}</>,
        label: "fromDateTime",
        icon: () => "fromDateTime",
      },

      {
        key: "taskId",
        action: (r) => (
          <>
            {r["remarks"]}
            
            <div className="pull-right">
              <span
                className={
                  "badge   badge-success pull-right ml-1 " +
                  "badge-" +
                  r["repStatus"]
                }
              >
                {r["repStatus"].substring(2)}
              </span>
            </div>
          </>
        ),
        label: "Tasks",
        cusClass: " ",
        icon: () => "Remarks",
      },
      {
        key: "reportBy",
        action: (r) => <>{_plms.getUserByCode(r.reportBy)}</>,
        label: "reportBy",
        icon: () => "reportBy",
      },
      */
    ],
  };

  resetData() {
    this.setState({
      data: {
        hubId: "",
        itemId: "",
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.storeItemsList !== prevProps.storeItemsList) {
      this.setState({ fStoreItemsList: this.props.storeItemsList });
      const tempSet = new Set();
      this.props?.storeItemsList?.map((item) => {
        tempSet.add(item?.retailerCode);
      });
      this.setState({ clientList: Array.from(tempSet) });
    }
  }

  handleFilterSelect(selectedClient) {
    if (selectedClient === "") {
      this.setState({ fStoreItemsList: this.props.storeItemsList });
      return;
    }
    const filteredStoreItemsList = this.props.storeItemsList.filter(
      (item) => item.retailerCode === selectedClient
    );

    this.setState({fStoreItemsList: filteredStoreItemsList})
  }

  render() {
    // const storeItemsList = this.props.storeItemsList;
    const fStoreItemsList = this.state.fStoreItemsList;
    
    // const curHub = this.props.curHub;
    // const curStore = this.props.curStore;

    // console.log({state: this.state, props: this.props, fStoreItemsList})

    return (
      <div className="card  col-5" style={{ margin: 0, padding: 2 }}>
        <h5 className="card-header">Items</h5>

        {fStoreItemsList && fStoreItemsList.length > 0 && (
          <div className="card-body" style={{ padding: 2 }}>
            <Table
              clsName="table table-striped border table-hover"
              tableMeta={this.tableMeta}
              data={fStoreItemsList}
              dropMenu={[]}
            />
          </div>
        )}
      </div>
    );
  }
}

export default StoresItems;
