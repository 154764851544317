import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import { ticketListReload } from "./../../../store";
import { connect } from "react-redux";
import { IstToUTC } from "../../../utils/DateUtils";
import {
  getTicketInfo,
  getPlayersbyRole,
  getFEInfo,
  getTripStatusByTripId,
} from "../../../services/Tickets/TicketServices";
import {
  setTicketActionsOutForProcess,
  setTicketActions,
} from "../../../services/Tickets/TicketProcessServices";
import swal from "sweetalert";
import { setTicketActionsTicketCancel } from "./../../../services/Tickets/TicketProcessServices";
import { deletePlayer } from "./../../../services/players/PlayerMobKeyService";
import { blockStatus } from "./../../../services/linehaul/linehaulServices";
import toast from "../../../services/_toastService"

class FEInfo extends FormLib {
  state = {
    data: {
      actionCode: "mCustDetails",
      action: "RELEASE_TICKET",
    },
    users: [],
    curTicketDetails: [],
    tripIdData: [],
    curBlockDetails: [],
    curProductDetails: [],
    TicketProductDetails: [],
    TicketPrimarytDetails: [],
    processStatus: "false",
    feStatus: "false",
    feStatusAssign: "false",
    cancelStatus: "false",
    FEDetails: [],
  };
  includeCancelTicket = [
    { sortKey: "yes", sort: "Do you want to cancel Ticket??" },
  ];
  schema = {
    player: Joi.string().optional().label("FE Assignment"),
    tkPriorityChange: Joi.optional().label("tkPriorityChange"),
    includeCancelTicket: Joi.string().optional().label("includeCancelTicket"),
  };
  componentDidMount() {
    this.loadInit();
    // this.getPlayers();
    let pageHide = this.props.hideAction;
    this.setState({ pageHide });
    this.setState({ curStatus: this.props.curStatus });
    this.getlocalDateTime();
  }

  async getlocalDateTime() {
    fetch("https://worldtimeapi.org/api/ip")
      .then((response) => response.json())
      .then((data) => {
        this.setState({ localDateTime: data });
      });
  }

  loadInit() {
    try {
      const curTicketDetails = this.props.ticketDetails;
      const curBlockDetails = this.props.blockDetails;
      const curProductDetails = this.props.ticketDetails.products;
      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails });
      this.getTicketInfo(curTicketDetails.ticketNo);
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.setState({ designation: userInfo.designation });
      this.loadFEInfo();
    } catch {
      console.log("Error in FEInfo>>>>>>loadInit");
    }
  }
  async loadFEInfo() {
    try {
      this.state.FEDetails = [];
      const respDst = await getFEInfo(this.props.blockDetails.ticketBlockId);
      if (respDst !== undefined) {
        if (respDst.data.success === true && respDst.data.data !== []) {
          this.setState({ FEDetails: respDst.data.data.playersDetails });
        } else if (respDst.data.success === false) {
          toast.error("No Details Available for this Ticket");
        }
      }
    } catch {
      console.log("Error in OutForProces>>>>>>>>>>>getTicketInfo");
    }
  }
  async getTicketInfo(ticketNo) {
    try {
      this.state.TicketProductDetails = [];
      this.state.TicketPrimarytDetails = [];
      const respDst = await getTicketInfo(ticketNo);
      if (respDst !== undefined) {
        if (respDst.data.success === true && respDst.data.data !== []) {
          this.setState({
            TicketPrimarytDetails:
              respDst.data.data.TicketDetails.primaryDetails,
          });
          this.setState({
            tripIdData: respDst.data.data.TicketDetails.productDetails
          })
          this.setState({
            TicketProductDetails:
              respDst.data.data.TicketDetails.productDetails[0],
          });
        } else if (respDst.data.success === false) {
          toast.error("No Details Available for this Ticket");
        }
      }
    } catch {
      console.log("Error in OutForProces>>>>>>>>>>>getTicketInfo");
    }
  }

  async handlebtn(e) {
    try {
      this.setState({ btnValue: e });
      if (e === "changeFE") {
        this.setState({ feStatusAssign: "true" });
        this.setState({ feStatus: "false" });
      } else if (e === "changeFESubmit") {
        let override = { ...this.state.data };
        override["actionCode"] = "wFeAssign";
        override["action"] = "Assign FE to Block";
        this.setState({ override });
        let feAction = "";
        let newTicketPriority = "";
        const feFilter = this.state.users.filter((r) => {
          return r.playerId === this.state.playersId ? r : null;
        });

        this.setState({
          curFeName: feFilter[0].firstName + " " + feFilter[0].lastName,
        });

        let resultSet = {
          feId: this.state.playersId,
          feName: feFilter[0].firstName + " " + feFilter[0].lastName,
          fePhone: feFilter[0].phone,
          feType: "fe",
          flowId: this.state.curTicketDetails.flowId,
          newTicketPriority: newTicketPriority,
          processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
          actionStatus: this.state.curBlockDetails.actionStatus,
        };
        let resp = await setTicketActionsOutForProcess(
          override,
          this.state.curTicketDetails,
          this.state.curBlockDetails,
          resultSet
        );
        if (resp.data.success === true) {
          if (resp.data.data) {
            this.handleTicketListReload();
            this.setState({ feStatus: "true" });
            this.setState({ feStatusAssign: "false" });

            toast.success("FE Changed Successfully");
          } else {
            !resp.data.data && toast.warning(() => <div>{resp.data.msg}</div>);
          }
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      } else if (e === "releaseFE") {
        this.setState({ feStatusAssign: "false" });
        this.handleReleaseFE();
      }
    } catch {
      console.log("Error in FEInfo>>>>>>handlebtn");
    }
  }
  curFeAssignment = (feAssign) => {
    try {
      this.setState({ playersId: feAssign.playerId });
    } catch {
      console.log("Error in FEInfo>>>>>>curFeAssignment");
    }
  };

  handleTicketListReload = () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };

  handleReleaseFE = async (t) => {
    const tripValue = this.state.tripIdData
    const tripIdIndex = tripValue.map((x) => {
      return x.tripId
    })
    const tripId = tripIdIndex[0]

    const blockId222 =this.state.curProductDetails.map((y)=>{
      return y.ticketBlockId
    });
    const ticketBlockId = blockId222[0]

    if (tripId === null) {
      try {
        swal({
          title: "Are you sure want to Release the FE ?",
          text: "Kindly Enter the Reason for Release",
          icon: "warning",
          buttons: true,
          dangerMode: true,
          closeOnClickOutside: false,
          closeOnEsc: false,
          content: "input",
          placeholder: "Write something",
        }).then(async (handleReleaseFE) => {
          this.doReleaseFE(handleReleaseFE, t);
        });
      } catch {
        console.log("Error in OutForProcess>>>>>>handleReleaseFE");
      }
    } else {

      const tripStatusResp = await getTripStatusByTripId(tripId,ticketBlockId);
      if(tripStatusResp.data.success === true && tripStatusResp.data.data.tripStatus.tripStatus === "started"){
        try {
          swal({
            title: "Are you sure want to Release the FE ?",
            text: "Kindly Enter the Reason for Release",
            icon: "warning",
            buttons: true,
            dangerMode: true,
            closeOnClickOutside: false,
            closeOnEsc: false,
            content: "input",
            placeholder: "Write something",
          }).then(async (handleReleaseFE) => {
            this.doReleaseFE(handleReleaseFE, t);
          });
        } catch {
          console.log("Error in OutForProcess>>>>>>handleReleaseFE");
        }
      }else{
        toast.toastError("Trip Not Yet Started So, Kindly Start The Trip")
      }
    }
  };

  doReleaseFE = async (handleReleaseFE, t) => {
    try {
      if (handleReleaseFE) {
        swal(
          "FE has been successfully Releases FE with the following remarks: " +
            handleReleaseFE,
          {
            icon: "success",
          }
        );
        this.submitReleaseFE(handleReleaseFE, t);
      } else {
        swal("FE Change Cancelled!", { icon: "error" });
      }
    } catch {
      console.log("Error in OutForProcess>>>>>>doReleaseFE");
    }
  };

  submitReleaseFE = async (handleReleaseFE, t) => {
    try {
      let feAction = "";

      if (
        (this.state.curBlockDetails.flowStatus === "PICK_MOB" ||
          this.state.curBlockDetails.flowStatus === "PICK_WEB") &&
        this.state.curTicketDetails.flowId !== "PickAndDropTwoWay"
      ) {
        feAction = "RELEASE_FE_AT_PICKUP";
      } else if (
        (this.state.curBlockDetails.flowStatus === "DROP_MOB" ||
          this.state.curBlockDetails.flowStatus === "DROP_WEB") &&
        this.state.curTicketDetails.flowId !== "PickAndDropTwoWay"
      ) {
        feAction = "RELEASE_FE_AT_DROP";
      } else if (
        (this.state.curBlockDetails.flowStatus === "PICK_MOB" ||
          this.state.curBlockDetails.flowStatus === "PICK_WEB") &&
        this.state.curTicketDetails.flowId === "PickAndDropTwoWay" &&
        this.state.curBlockDetails.blockStatus === "ASSIGNED_FE_FOR_PICKUP"
      ) {
        feAction = "RELEASE_FE_AT_PICKUP";
      } else if (
        (this.state.curBlockDetails.flowStatus === "DROP_MOB" ||
          this.state.curBlockDetails.flowStatus === "DROP_WEB") &&
        this.state.curTicketDetails.flowId === "PickAndDropTwoWay" &&
        this.state.curBlockDetails.blockStatus === "ASSIGNED_FE_FOR_DROP"
      ) {
        feAction = "RELEASE_FE_AT_CUSTOMER_DROP";
      } else if (
        (this.state.curBlockDetails.flowStatus === "PICK_MOB" ||
          this.state.curBlockDetails.flowStatus === "PICK_WEB") &&
        this.state.curTicketDetails.flowId === "PickAndDropTwoWay" &&
        this.state.curBlockDetails.blockStatus ===
          "ASSIGNED_FE_FOR_SERVICE_PICKUP"
      ) {
        feAction = "RELEASE_FE_AT_SERVICE_PICKUP";
      } else if (
        (this.state.curBlockDetails.flowStatus === "DROP_MOB" ||
          this.state.curBlockDetails.flowStatus === "DROP_WEB") &&
        this.state.curTicketDetails.flowId === "PickAndDropTwoWay" &&
        this.state.curBlockDetails.blockStatus ===
          "ASSIGNED_FE_FOR_SERVICE_DROP"
      ) {
        feAction = "RELEASE_FE_AT_SERVICE_DROP";
      }
      let resultSet = {
        remark: handleReleaseFE,
        actionStatus: feAction,
        processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        ReleaseDateTime: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
        playerFeId: t.playerId,
      };
      let data = this.state.data;
      data = {
        actionCode: "mCustDetails",
        action: "RELEASE TICKET",
      };
      this.setState({ data });

      let resp = await setTicketActions(
        this.state.data,
        this.state.curTicketDetails,
        this.state.curBlockDetails,
        resultSet
      );
      if (resp !== undefined) {
        if (resp.data.success === true) {
          if (resp.data.data) {
            this.setState({ feStatus: "true" });
            this.setState({ processStatus: "true" });
            this.handleTicketListReload();
          } else {
            toast.warning(() => <div>{resp.data.msg}</div>);
          }
        } else {
          toast.error(() => <div>{resp.data.msg}</div>);
        }
      }
    } catch {
      console.log("Error in submitReleaseFE");
    }
  };

  render() {
    const ticketDetails = this.state.curTicketDetails;
    return (
      <>
        <div className="row ">
          {this.state.pageHide === "false" &&
            this.state.cancelStatus === "false" &&
            this.state.processStatus === "false" && (
              <div className="col-md-12">
                <div className="form-subHeader">
                  <b>{ticketDetails.ticketNo} </b>
                </div>
                {this.state.FEDetails.map((t) => {
                  return (
                    <div className="card ">
                      <div className="card-body  bg-custom-9">
                        <form onSubmit={this.handleSubmit}>
                          {(this.state.curBlockDetails.flowStatus ===
                            "PICK_WEB" ||
                            this.state.curBlockDetails.flowStatus ===
                              "PICK_MOB") && (
                            <p>
                              <b>FE :</b> {t.playerFeName}
                              <div>
                                <button
                                  onClick={() => this.handleReleaseFE(t)}
                                  className={
                                    this.state.btnValue === "releaseFE"
                                      ? " btn btn-warning btn-sm pull-right "
                                      : "btn btn-danger btn-sm pull-right "
                                  }
                                  title="Release FE"
                                  style={{
                                    fontSize: "120%",
                                    borderRadius: "2.25rem ",
                                    border: "none",
                                    fontWeight: "normal",
                                    marginTop: "-20px",
                                  }}
                                >
                                  Release FE<b></b>
                                </button>
                              </div>
                            </p>
                          )}
                          {(this.state.curBlockDetails.flowStatus ===
                            "DROP_WEB" ||
                            this.state.curBlockDetails.flowStatus ===
                              "DROP_MOB") && (
                            <p>
                              <b>FE :</b> {t.playerFeName}
                              <div>
                                <button
                                  onClick={() => this.handleReleaseFE(t)}
                                  className={
                                    this.state.btnValue === "releaseFE"
                                      ? " btn btn-warning btn-sm pull-right "
                                      : "btn btn-danger btn-sm pull-right "
                                  }
                                  title="Release FE"
                                  style={{
                                    fontSize: "120%",
                                    borderRadius: "2.25rem ",
                                    border: "none",
                                    fontWeight: "normal",
                                    marginTop: "-20px",
                                  }}
                                >
                                  Release FE<b></b>
                                </button>
                              </div>
                            </p>
                          )}

                          {(this.state.curBlockDetails.flowStatus ===
                            "PICK_WEB" ||
                            this.state.curBlockDetails.flowStatus ===
                              "DROP_WEB") && (
                            <>
                              {this.rBtnLP(
                                "Out For Process",
                                "Out For Process",
                                "btn btn-success btn-sm pull-right"
                              )}
                            </>
                          )}
                        </form>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

          {this.state.processStatus === "true" && (
            <div className="jumbotron jumbotron-fluid">
              <p className="lead text-center">
                Ticket No: <b>{ticketDetails.ticketNo}</b> Released Successfully
              </p>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FEInfo);
