import React, { Component } from "react";
import FormLib from "../../../_common/FormLib";
import LocationTitle from "../../../_subhead/LocationTitle";
import Joi from "joi-browser";
import { getClients, getHub } from "../../../../services/masters/hubServices";
import { toast } from "react-toastify";
import { data } from "jquery";
import {
  getBarcodeData,
  getUsers,
  getActiveClients,
  assignUser,
  getBarcodeDataV1,
} from "../../../../services/masterLocations/clientService";
import Table from "../../../_common/Table";
import Pagination from "../../../_common/Pagination";
import swal from "sweetalert";

class AssigningPmToUsers extends FormLib {
  state = {
    data: {
      fromScan: "",
      endScan: "",
      hubId: "5067",
      clientId: "",
      barcodeType: "prePrinted",
      playerId:"",
    },
    hubs: [],
    playerId: "",
    clients: [],
    usersList: [],
    errors: {},
    formMode: "",
    barcodeData: [],
    searchText: "",
    filteredBarcodeData: [],
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };

  componentDidMount() {
    this.loadClients();
    this.loadHubs();
    // this.loadUsers();
  }

  schema = {
    fromScan: Joi.optional().label("Starting Barcode Number"),
    endScan: Joi.optional().label("Ending Barcode Number"),
    playerId:Joi.optional().label("playerId"),
    clientId: Joi.string().required().label("Client"),
    hubId: Joi.string().required().label("Hub"),
    barcodeType: Joi.string().required().label("barcodeType"),
  };
  barcodeType = [
    { sortKey: "prePrinted", sort: "PrePrints" },
    { sortKey: "sticker", sort: "Stickers" },
  ];
  stsConfig = {
    value: "sortKey",
    label: "sort",
  };
  async loadClients() {
    this.setState({ loading: true });
    const res = await getActiveClients("active");
    // console.log({ res });

    if (res.data.success === true) {
      this.setState({ clients: res.data.data.clients });
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
  }

  async loadHubs() {
    try {
      this.setState({ loading: true });
      let hubList = JSON.parse(localStorage.getItem("hubs"));
      // console.log({ hubList });
      if (hubList.length > 0) {
        this.setState({ hubs: hubList });
      } else {
        return "No Hubs Found";
      }
    } catch {
      console.log("error in loading hubs");
    }
    this.setState({ loading: false });
  }

  async loadUsers() {
    try {
      let hubId = this.state.data.hubId;
      const users = await getUsers(hubId);

      if (users.data.success === true) {
        this.setState({ usersList: users.data.data.playersHubs });
      } else {
        toast.toastError(users.data.msg);
      }
    } catch (error) {
      return "failed to fetch users data";
    }
  };



  reset() {
    this.setState({
      data: {
        fromScan: "",
        endScan: "",
        clientId: "",
        hubId: "5067",
        barcodeType: "prePrinted",
      },
      // usersList: [],
      barcodeData: [],
    });
    // this.loadUsers()
  }

  async doSubmit() {
    let params = {
      fromScan: this.state.data.fromScan,
      endScan: this.state.data.endScan,
      hubId: this.state.data.hubId,
      clientId: this.state.data.clientId,
      barcodeType: this.state.data.barcodeType,
    };

    let data;
    if((this.state.data.fromScan ==="" && this.state.data.endScan === "")  ||(this.state.data.fromScan !=="" && this.state.data.endScan !== "")){
    data = await getBarcodeDataV1(params, this.state.pageLinks.pageNo);
    if (data.data.success === true) {
      this.setState({ barcodeData: data.data.data.barcodeData });
      this.setState({ pageLinks: data.data.data.pageLinks });
      this.setState({ filteredBarcodeData: data.data.data.barcodeData });

      this.setState({ formMode: "submit" });
      this.loadUsers();
      toast.success(() => <div>{data.data.msg}</div>);

      // this.reset();
    } else {
      this.setState({ loading: false });
      // toast.error(data.data.msg);
      this.setState({ filteredBarcodeData: [] });
      this.setState({
        pageLinks: {
          pageNo: 1,
          perPage: 0,
          itemsCount: 0,
          lastPage: 0,
        },
      });
    }
    this.setState({ loading: true });
  }
  else{
    this.setState({barcodeData:[]})
    swal({
      text: "fromScan and toScan value is mandatory",
      icon: "error",
      buttons: true,
      dangerMode: true,
    })
  }
  }

  localHandleChange = (e) => {
    if (e.target.id === "playerId") {
      this.setState({ playerId: e.target.value });
    }
  };
  handlePageChange = (pageNo) => {
    this.props.history.push("/assigningPmToUser/" + pageNo);
    let pageLinks = this.state.pageLinks;
    pageLinks["pageNo"] = pageNo;
    this.setState({ pageLinks });
    this.doSubmit();
  };

  tableMeta = {
    key: "slNo",
    columns: [
      {
        key: "sl_No1",
        action: (r) => <div className="btn-group pull-right"></div>,
        icon: () => <i className="fa fa-table " aria-hidden="true" />,
      },
      {
        key: "sl_No2",
        action: (r) => (
          <>
            <span>{r.slNo}</span>
          </>
        ),
        cusClass: " ",
        icon: () => (
          <div>
            <p>Barcode</p>
            <input
              type="text"
              name="searchBarcode"
              id="searchBarcode"
              placeholder="Search"
              value={this.state.searchText}
              onChange={(e) => {
                this.setState({ searchText: e.target.value });
                const filteredBarcodeData = this.state.barcodeData?.filter(
                  (item) => {
                    const slNo = item.slNo;
                    return slNo.includes(e.target.value);
                  }
                );
                this.setState({ filteredBarcodeData });
              }}
            />
          </div>
        ),
      },
      { path: "item", label: "Item " },
      { path: "barcodeType", label: "Type" },
      {
        key: "sl_No3",
        action: (r) => (
          <>
            {this.state.playerId && (
              <button
                className="btn btn-success btn-sm"
                type="button"
                onClick={() => this.assignItem(r)}
              >
                Assign
              </button>
            )}
          </>
        ),
        icon: () => <>Assign</>,
      },
    ],
  };

  assignItem = async (data) => {
    try {
      const res = await assignUser(this.state.data, data, this.state.playerId);

      if (res.data.success === true) {
        toast.success(() => <div>{res.data.msg}</div>);
        this.doSubmit();

      } else {
        toast.error(() => <div>{res.data.msg}</div>);
      }
    } catch (error) {
      return "failed to assign users data";
    }
  };

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    // console.log({ dataBeforeFilter: this.state.barcodeData });
    // console.log({ dataAfterFilter: this.state.filteredBarcodeData });

    return (
      <React.Fragment>
        <LocationTitle subHeading="Assign PackingMaterial/products To User" />
        <form onSubmit={this.handleSubmit}>
          <div className="pageContent">
            <div className="card">
              <div className="d-flex" style={{ columnGap: "2rem" }}>
                <div style={{ maxWidth: "14rem" }}>
                  {this.rSB(
                    "Client *",
                    "clientId",
                    this.state.clients,
                    { value: "TEAM_ID", label: "NAME" }
                    //   "col-6"
                  )}
                </div>
                <div>
                  {this.rSB(
                    "Hub *",
                    "hubId",
                    this.state.hubs,
                    { value: "hubId", label: "hub" }
                    //   "col-12"
                  )}
                </div>

                <div>{this.rTB("FromScan ", "fromScan")}</div>
                <div>{this.rTB("ToScan", "endScan")}</div>
                <div>
                  {this.rRBL(
                    "BarcodeType *",
                    "barcodeType",
                    this.barcodeType,
                    this.stsConfig
                  )}
                </div>
                <div>
                  {this.rBtnSP("Submit", "submit")}
                  {this.mResetBtn(() => this.reset(), " pull-right ", "Reset")}
                </div>
              </div>
            </div>
          </div>
        </form>

        <div>
          {this.state.barcodeData.length !== 0 &&
            this.state.formMode === "submit" && (
              <div
                className="col-2"
                style={{ paddingTop: "1.5rem", paddingLeft: "2rem" }}
              >
                {this.rSB("Users *", "playerId", this.state.usersList, {
                  value: "playerId",
                  label: "firstName",
                })}
              </div>
            )}

          <div style={{ maxWidth: "50rem", paddingLeft: "2rem" }}>
            {this.state.barcodeData.length !== 0 &&
              this.state.formMode === "submit" && (
                <div>
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.filteredBarcodeData}
                    dropMenu={[]}
                  />
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              )}
          </div>
          <div>
            {this.state.formMode === "submit" &&
              this.state.filteredBarcodeData.length === 0 && (
                <div className="jumbotron jumbotron-fluid">
                  <p className="lead text-center">No barcodes Found</p>
                  {/* <p aria-disabled></p> */}
                </div>
              )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AssigningPmToUsers;
