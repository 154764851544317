import React from "react";

function RadioBtn({
  header,
  name,
  options = [],
  ctrls,
  inline = "",
  value,
  error,
  classAppend = "",
  ...rest
}) {
  return (
    <div className={"form-group " + classAppend}>
      <div className={inline + " form-radio"}>
        <div className="form-help"> {header} </div>
        {options.map((op,i) => (
          <div className="radio"    key={op[ctrls.value]}>
            <label>
              <input
                type="radio"
                value={op[ctrls.value]}
                name={name}
                id={name+i}
                checked={op[ctrls.value]===value}
                {...rest}
              />
              <i className="helper"></i>
              {op[ctrls.label]}
            </label>
          </div>
        ))}
        {error && <span className="alert alert-danger">{error}</span>}
      </div>
    </div>
  );
}

export default RadioBtn;
