import React from "react";
import FormLib from "./../../_common/FormLib";

class PrvMenus extends FormLib {
  state = {
    data: {
      menuId: "",
      menuGroupId: "",
      menuCode: "",
      menu: "",
      status: "active",
    },
    errors: {},
    menus: [],
    curGroupName: {},
    curMenu: {},
    // prvModules: [],
  };

  isCur(id, cur) {
    if (id === cur) {
      return " curTasks ";
    }
    return " ";
  }

  componentDidMount() {
    this.setCurGroupName(this.props.curGroupName);
    this.setCurMenu(this.props.curMenu);

  }
  setCurGroupName(g) {
    this.setState({ curGroupName: g });
  }
  setCurMenu(m) {
    if (m.menuId !== "") {
      m.status = 1;
      this.setState({ data: m });
    } else {
      this.resetData();
    }
    // console.log(JSON.stringify(this.state.data));
  }

  resetData() {
    this.setState({
      data: {
        menuId: "",
        menuGroupId: "",
        menuCode: "",
        menu: "",
        status: "active",
      },
    });
  }
  render() {
    const menus = this.props.menus;
    const curGroupName = this.props.curGroupName;
    const curMenu = this.props.curMenu;

    return (
      <div className="card  ">
        <h5 className="card-header">
          Prv Menus
          {/* <pre>{JSON.stringify(this.state.curMenu)}</pre> */}
          {curGroupName.menuGroupId !== "" && !curMenu.menuId && (
            <button
              type="button"
              className="btn btn-sm btn-primary pull-right"
              data-toggle="modal"
              data-target="#myMenuModel"
            >
              <i className={"fa  fa-plus"} />
            </button>
          )}
          {curMenu.menuId && curMenu.menuId !== "" && (
            <>
              <button
                type="button"
                className="btn btn-sm btn-warning pull-right"
                onClick={() => this.props.onMenuUnload()}
              >
                <i className={"fa  fa-unlink"} />
              </button>
              <button
                type="button"
                className="btn btn-sm btn-success pull-right"
                data-toggle="modal"
                data-target="#myMenuModel"
              >
                <i className={"fa  fa-pencil"} />
              </button>
            </>
          )}
        </h5>

        <div className="card-body">
          <div className="page-todo">
            <div className=" tasks">
              {menus.map((m) => (
                <div
                  className={
                    "task nil " + this.isCur(m.menuId, curMenu.menuId)
                  }
                  key={m.menuId}>
                  <div className="desc">
                    <span
                      className="span-click"
                      onClick={() => this.props.onMenuChange(m)}>
                      <div className="title">{m.menu}</div>
                    </span>
                  </div>
                  <div className="pull-right">
                    <div className="menu">
                      <span
                        className={
                          "badge   badge-success pull-left " +
                          "badge-" +
                          m.status
                        }
                      >
                        {m.status}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PrvMenus;
