import React from "react";

import FormLib from "../../_common/FormLib";

import Table from "./../../_common/Table";
import { format } from "date-fns";



class SMSRecordsCard extends FormLib {

  tableMeta = {
    key: "smsId",
    columns: [
      { path: "blockStatus", label: "BlockStatus" },
      { path: "otp", label: "OTP" },
      { path: "preStatus", label: "PreStatus" },
      
      {key: "smsId",
        action: (r) => <>{(r?.postStatus ? this.postStatusMap[r.postStatus] : "")}</>,
        icon: () => <>PostStatus</>
    },
    { path: "recipient", label: "Recipient" },
    // { path: "dateUpdated", label: "Date Updated" },
    {key: "createdAt",
        action: (r) => <>{format(new Date(r.createdAt ), "dd-MM-yyyy \n HH:mm:ss")}</>,
        icon: () => <>Created At</>
    },
   
    ],
  };

  postStatusMap = {
      D: "Delivered",
      U: "Not delivered",
      P: "Pending",
      I: "Invalid number",
      E: "Expired",
      "?": "En route",
      B: "DND",
    };


  render() {
    return (
      <>
        <div
          className="card"
          style={{ backgroundColor: "transparent", marginTop: "-30px", marginLeft: "19.4%",
          marginBottom: "1%"
        }}
        >
          <div
            className="card-body "
            style={{
              backgroundColor: "transparent",
              width: "66%",
              marginTop: "1rem",
              paddingTop: "0px"
            }}
          >


            <div className="row" style={{ fontSize: "10px", padding: "0", margin: "0" }}>
              {this.props.smsRecords.length > 0 ? (
                <div className="container-fluid ps-0">
                  <p className="fs-6 mb-0 p-0" style={{ backgroundColor: "transparent", fontWeight: "500", marginBottom: '2px' }}>SMS  records</p>
                  <Table
                    clsName="table table-striped table-bordered table-hover p-0 m-0"
                    tableMeta={this.tableMeta}
                    data={this.props.smsRecords}
                    dropMenu={[]}
                  />
                </div>
              )
                : <p>SMS records not found!</p>

              }

            </div>
          </div>
        </div>


      </>
    );
  }
}

export default SMSRecordsCard;
