import React from "react";
import FormLib from "../../_common/FormLib";
import ZTitle from "../../_subhead/ZTitle";

import Joi from "joi-browser";
import { getStories } from "./../../../services/plms/userstoriesServices";
import { toast } from "react-toastify";
import Table from "../../_common/Table";

import Pagination from "../../_common/Pagination";

export default class StoryList extends FormLib {
  state = {
    data: {
      status: "active",
    },
    errors: {},
    loading: false,
    stories: [],
    storiesFilter: [],
    filter: null,
    filterFlag: "On",
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };

  schema = {
    status: Joi.string().required().label("Start Date"),
  };

  setFilterFlag() {
    this.state.filterFlag === "On"
      ? this.setState({ filterFlag: "Off" })
      : this.setState({ filterFlag: "On" });
  }

  addFilterByProject = (r) => {
    if (r === null) {
      this.setState({ storiesFilter: this.state.stories });
      this.setState({ filter: null });
    } else if (r["projectId"]) {
      const storiesFilter = this.state.stories.filter((u) => {
        return r["projectId"] === u["projectId"] ? u : null;
      });
      this.setState({ storiesFilter });
      this.setState({ filter: r });
    }
  };
  addFilterByModule = (r) => {
    const storiesFilter = this.state.stories.filter((u) => {
      return r["moduleId"] === u["moduleId"] ? u : null;
    });
    this.setState({ storiesFilter });
    this.setState({ filter: r });
  };

  async componentDidMount() {
    this.getStories(this.state.data, this.state.pageLinks.pageNo);
  }

  async doSubmit() {
    await this.getStories(this.state.data, this.state.pageLinks.pageNo);
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/storyboard/stories/" + pageNo);
    this.getStories(this.state.data, pageNo);
  };

  async getStories(params, pageNo) {
    this.setState({ loading: true });
    let resp = await getStories(params, pageNo);

    if (resp.data.success === true) {
      resp.data.data.userstories &&
        this.setState({ stories: resp.data.data.userstories });
      if (this.state.filterFlag === "On") {
        this.addFilterByProject(this.state.filter);
      } else {
        this.addFilterByProject(null);
      }

      resp.data.data.pageLinks &&
        this.setState({ pageLinks: resp.data.data.pageLinks });
      resp.data.data.userstories &&
        toast.success(() => <div>{resp.data.msg}</div>);
      !resp.data.data.userstories && toast.warning(() => <div>No Stories</div>);
    } else {
      toast.error(() => <div>{resp.data.msg}</div>);
    }
    this.setState({ loading: false });
  }

  tableMeta = {
    key: "userstoryId",
    columns: [
      {
        key: "projectId",
        action: (r) => (
          <>
            <span onClick={() => this.addFilterByProject(r)}>
              {r["project"].trim()}
            </span>
          </>
        ),
        label: "Project",
      },

      {
        key: "moduleId",
        action: (r) => (
          <>
            <span onClick={() => this.addFilterByModule(r)}>
              {r["prModule"].trim()}
            </span>
            <br />
            <span
              className={
                "badge   badge-success pull-left mr-3 " +
                "badge-" +
                r["modStatus"]
              }
            >
              {r["modStatus"].substring(2)}
            </span>
          </>
        ),
        label: "Module",
      },

      {
        key: "userstoryId",
        action: (r) => (
          <>
            {r["usTitle"].trim()}
            <br />
            <div className="pull-right">
              <span
                className={
                  "badge   badge-success pull-left  mr-1 " +
                  "badge-" +
                  r.usComplexity
                }
              >
                {r.usComplexity}
              </span>
              <span className={"badge   badge-light pull-left ml-1 "}>
                {r["usOwner"]}
              </span>
              <span className={"badge   badge-light pull-left ml-1 "}>
                {r["usPoints"] + " pts"}
              </span>

              <span
                className={
                  "badge   badge-success pull-right ml-1 " +
                  "badge-" +
                  r["usStatus"]
                }
              >
                {r["usStatus"].substring(2)}
              </span>
              <span
                className={
                  "badge   badge-success pull-right mr-1 " +
                  "badge-" +
                  r.usPriority
                }
              >
                {r.usPriority}
              </span>
            </div>
          </>
        ),
        label: "Stories",
      },
    ],
  };
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;

    const stories = this.state.storiesFilter;
    return (
      <React.Fragment>
        <ZTitle subHeading="Stories  " />
        <div className="pageContent">
          <div className="card">
            <div className="card-body">
              <form id="myForm" onSubmit={this.handleSubmit}>
                <div className="form-row">
                  {this.rRBL("Status", "status", this.status, this.stsConf)}
                  <div className="col-md-4">
                    {this.rBtnS("Submit", "Submit", "btn btn-primary  btn-sm")}
                  </div>
                </div>
              </form>
              <span
                className="badge   badge-primary pull-right ml-1 badge-10wip"
                onClick={() => this.setFilterFlag()}
              >
                {this.state.filterFlag}
              </span>
              <span
                className="badge   badge-success pull-right ml-1 badge-10wip"
                onClick={() => this.addFilterByProject(null)}
              >
                Reset
              </span>

              <br />
              <div className="row">
                {this.state.loading && (
                  <div className="container-fluid">
                    <div> Loading Stories...</div>
                  </div>
                )}
                {!this.state.loading && (
                  <div className="container-fluid">
                    <Table
                      clsName="table table-striped table-bordered table-hover"
                      tableMeta={this.tableMeta}
                      data={stories}
                      dropMenu={[]}
                    />
                    <Pagination
                      pageNo={pageNo}
                      perPage={perPage}
                      itemsCount={itemsCount}
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                )}
                {this.state.loading && stories.length === 0 && (
                  <div className="alert alert-info col-12" role="alert">
                    <div className="text-center"> No Records Found! </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
