import React from "react";

import FormLib from "../../../_common/FormLib";
import Joi from "joi-browser";
import {
  additionalRequest,
  getVendorsList,
} from "../../../../services/requestV1/requestServices";
import { toast } from "react-toastify";
import dateFormat from "dateformat";
import { getEmployeesList } from "./../../../../services/requestV1/requestServices";

class RequestRunning extends FormLib {
  state = {
    data: {
      player_type: "employee",
      player_code: "",
      req_amt: "",
      remarks: "",
    },
    errors: {},
    requestDetails: {},
    empSuggestions: [],
    selectedEmpCode: "",
    empText: "",
    venSuggestions: [],
    selectedVenCode: "",
    venText: "",
  };

  schema = {
    player_type: Joi.string().required().label("Raise Type"),
    player_code: Joi.string().required().label("Employee Code"),
    req_amt: Joi.number().required().min(0).label("Required Amount"),
    remarks: Joi.string().required().label("Remarks"),
  };

  player_type = [
    { player_typeId: "employee", player_type: "Employee" },
    { player_typeId: "vendor", player_type: "Vendor" },
  ];

  componentDidMount() {
    this.setRequest();
  }

  setRequest() {
    this.setState({ requestDetails: this.props.data });
  }

  async doSubmit() {
    const params = {
      request_id: this.state.requestDetails.REQUEST_ID,
      ...this.state.data,
    };

    // console.log("----------------> " + JSON.stringify(params));
    const additionalRequestraise = await additionalRequest(params);
    if (additionalRequestraise.data.status === 1) {
      toast.success(() => <div>{additionalRequestraise.data.msg}</div>);
      document.getElementById("myForm").reset();
      this.setState({ data: "" });
    } else {
      toast.error(() => <div>{additionalRequestraise.data.msg}</div>);
    }
  }

  onVenTextChanged = (e) => {
    const value = e.target.value;
    let venSuggestions = [];

    if (value.length > 1) {
      this.getVendorsList(value);
    }

    this.setState(() => ({
      venSuggestions,
      venText: value,
      selectedVenCode: "",
    }));

    let data = this.state.data;

    data.player_code = value;
    this.setState({ data });
  };

  venSuggestionsSelected(value) {
    let vendorlist = this.state.data;
    vendorlist["player_code"] = value;
    this.setState(() => ({
      vendorlist,
      venText: value,
      venSuggestions: [],
      selectedVenCode: value,
    }));
  }

  renderVenSuggestions() {
    const { venSuggestions } = this.state;
    if (venSuggestions.length === 0) {
      return null;
    }

    return (
      <div className="srchList">
        <ul>
          {venSuggestions.map((item) => (
            <li
              key={item.VEN_CODE}
              onClick={() => this.venSuggestionsSelected(item.VEN_CODE)}
            >
              {item.VEN_CODE} [{item.NAME}]
            </li>
          ))}
        </ul>
      </div>
    );
  }

  async getVendorsList(value) {
    let venSuggestions = [];
    let vendorsList = await getVendorsList(value);
    this.setState({ items: vendorsList.data.data.venListbyCode });
    venSuggestions = this.state.items;
    this.setState(() => ({
      venSuggestions,
      venText: value,
    }));
  }

  onEmpTextChanged = (e) => {
    const value = e.target.value;
    let empSuggestions = [];

    if (value.length > 1) {
      this.getEmployeesList(value);
    }

    this.setState(() => ({
      empSuggestions,
      empText: value,
      selectedEmpCode: "",
    }));

    let data = this.state.data;

    data.player_code = value;
    this.setState({ data });
  };

  empSuggestionsSelected(value) {
    let employeelist = this.state.data;
    employeelist["player_code"] = value;
    this.setState(() => ({
      employeelist,
      empText: value,
      empSuggestions: [],
      selectedEmpCode: value,
    }));
  }

  renderEmpSuggestions() {
    const { empSuggestions } = this.state;
    if (empSuggestions.length === 0) {
      return null;
    }

    return (
      <div className="srchList">
        <ul>
          {empSuggestions.map((item) => (
            <li
              key={item.PLAYER_CODE}
              onClick={() => this.empSuggestionsSelected(item.PLAYER_CODE)}
            >
              {item.PLAYER_CODE} [{item.NAME}]
            </li>
          ))}
        </ul>
      </div>
    );
  }

  async getEmployeesList(value) {
    let empSuggestions = [];
    let employeesList = await getEmployeesList(value);
    this.setState({ items: employeesList.data.data.empListbyCode });
    empSuggestions = this.state.items;
    this.setState(() => ({
      empSuggestions,
      empText: value,
    }));
  }

  render() {
    const user = this.props.user;
    const { empText } = this.state;
    const { venText } = this.state;
    return (
      <React.Fragment>
       <div style={{marginTop:"-4rem", position: "fixed"}}>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.props.closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="card">
          <div className="card-header bg-primary text-white">
            <div className="row">
              <div className="col-6">{this.state.requestDetails.M_CLIENT}</div>
              <div className="col-6 text-right">
                {dateFormat(this.state.requestDetails.CREATED_AT, "dd-mm-yyyy")}
              </div>
            </div>

            <div className="row">
              <div className="col-8">
                {this.state.requestDetails.REF_ID} [
                {this.state.requestDetails.M_HUB}]
              </div>
              <div className="col-4 text-right">
                #{this.state.requestDetails.RE_PREFIX}
                {this.state.requestDetails.SEQ}
              </div>
            </div>
          </div>
          <div className="card-body border-top">
            <div className="row">
              <div className="col-4">
                <span className="req-label">Request Type </span>
                <span className="req-value">
                  {this.state.requestDetails.REQUEST_TYPE}
                </span>
              </div>
              {this.state.requestDetails.PLAYER_TYPE === "employee" && (
                <div className="col-4">
                  <span className="req-label">Employee</span>
                  <span className="req-value">
                    {this.state.requestDetails.E_NAME} [
                    {this.state.requestDetails.PLAYER_CODE}]
                  </span>
                </div>
              )}
              {this.state.requestDetails.PLAYER_TYPE === "vendor" && (
                <div className="col-4">
                  <span className="req-label">Vendor</span>
                  <span className="req-value">
                    {this.state.requestDetails.V_NAME} [
                    {this.state.requestDetails.PLAYER_CODE}]
                  </span>
                </div>
              )}
              <div className="col-4">
                <span className="req-label">Flow </span>
                <span className="req-value">
                  {this.state.requestDetails.M_FLOW}
                </span>
              </div>
            </div>
          </div>

          <div class="panel-group widget-request">
            <div className="panel panel-info">
              <label>Activity Details</label>
              <div className="panel-body">
                {this.state.requestDetails.M_ACTIVITY}
              </div>
            </div>
            <div class="panel panel-info">
              <label>Request Details</label>
              <div class="panel-body">
                {this.state.requestDetails.REQUEST_DETAILS}
              </div>
            </div>
          </div>
          <div className="card-body row">
            <div className="col-6">
              <span className="req-label">Total Amt Approved </span>
              <span className="req-value">
                {this.state.requestDetails.AMT_APPROVED}
              </span>
            </div>
            <div className="col-6 text-right">
              <span className="req-label">Paid Amount </span>
              <span className="req-value">
                {this.state.requestDetails.PAID}
              </span>
            </div>
          </div>

          {user &&
            (user["roleCode"] === "coordinator" ||
              user["roleCode"] === "hub_manager" ||
              user["playerId"] === "player1") && (
              <div className="card-body">
                <form id="myForm" onSubmit={this.handleSubmit} style={{maxHeight:"12rem"}}>
                  <div className="row" >
                    <div className="col-md-6">
                      {this.rRBL(
                        "User Type *",
                        "player_type",
                        this.player_type,
                        {
                          value: "player_typeId",
                          label: "player_type",
                        }
                      )}
                    </div>
                    {this.state.data.player_type === "employee" && (
                      <div className="col-md-6 form-group">
                        <input
                          value={empText}
                          name="player_code"
                          onChange={this.onEmpTextChanged}
                          type="text"
                          autoComplete="off"
                        />
                        <label htmlFor="player_code" className="control-label">
                          Employee Code *
                        </label>
                        <i className="bar"></i>
                        {this.renderEmpSuggestions()}
                      </div>
                    )}
                    {this.state.data.player_type === "vendor" && (
                      <div className="col form-group">
                        <input
                          value={venText}
                          name="player_code"
                          onChange={this.onVenTextChanged}
                          type="text"
                          autoComplete="off"
                        />
                        <label htmlFor="player_code" className="control-label">
                          Vendor Code *
                        </label>
                        <i className="bar"></i>

                        {this.renderVenSuggestions()}
                      </div>
                    )}
                    {this.rTB("Required Amount", "req_amt", "col-md-12")}
                  </div>
                  {this.rTA("Remarks", "remarks")}
                </form>
              </div>
            )}
          {user &&
            (user["roleCode"] === "coordinator" ||
              user["roleCode"] === "hub_manager" ||
              user["playerId"] === "player1") && (
              <div className="p-1">
                {((this.state.data.player_type === "employee" &&
                  this.state.selectedEmpCode) ||
                  (this.state.data.player_type === "vendor" &&
                    this.state.selectedVenCode)) &&
                  this.rBtnS(
                    "Raise ",
                    "Raise",
                    "btn-primary  btn-sm btn-block"
                  )}
                {this.state.data.player_type === "employee" &&
                  !this.state.selectedEmpCode && (
                    <span className="alert alert-info text-center btn-block">
                      Invalid Employee Code
                    </span>
                  )}

                {this.state.data.player_type === "vendor" &&
                  !this.state.selectedVenCode && (
                    <span className="alert alert-info text-center btn-block">
                      Invalid Vendor Code
                    </span>
                  )}
              </div>
            )}
        </div>
        </div>
      </React.Fragment>
    );
  }
}
export default RequestRunning;
