import React from "react";
import $ from "jquery";
import FormLib from "../../../_common/FormLib";
import { IstToUTC, IstToUTCDate } from "../../../../utils/DateUtils";
import Joi from "joi-browser";
import toast from "./../../../../services/_toastService";
import { setTicketVehicleInfoUpdateStart } from "./../../../../services/Tickets/TicketProcessServices";
// import {
//   getDocuments,
//   getSaveFile,
// } from "../../../../../services/requestV1/requestServices";
// import { getLinehaulTicktes } from "../../../../../services/linehaul/linehaulServices";

class StartDocumentDetails extends FormLib {
  state = {
    data: {
      tripStart: "",
      fileInfo: "",
      selectedFilesList: [],
    },
    errors: {},
    currentRequests: {},
    user: {},
    selectedFile: null,
    ticketDetails: [],
  };
  schema = {
    tripStart: Joi.string()
      .regex(/^[0-9]+$/)
      .required()
      .label("tripStart")
      .error(() => {
        return {
          message: "Allow only numbers",
        };
      }),
    selectedFilesList: Joi.required().label("selectedFilesList"),
  };
  onFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  componentDidMount() {
    this.loadData(this.props.data);
  }

  loadData = async (r) => {
    try {
      this.setState({ currentRequests: this.props.data });
    } catch {
      console.log("DocumentsDetails>>>>>>>>>>>>loadData");
    }
  };
  selectedFilesLists = (e) => {
    let fileObj = [];
    let fileArray = [];
    fileObj.push(e);
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(fileObj[0][i]);
    }
    this.setState({ file: fileArray });

    let data = this.state.data;
    data.selectedFilesList = e;
    this.setState({ data });
  };

  deleteFile(e) {
    const s = this.state.file.filter((item, index) => index !== e);
    this.setState({ file: s });
    let data = this.state.data;
    data.selectedFilesList = s;
    this.setState({ data });
  }
  async onVehicleInfo() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"))

    let curHub = localStorage.getItem("curHub");
    let date = IstToUTCDate(new Date());
    let now = new Date().valueOf(); // Unix timestamp in milliseconds
    let tripId = curHub + "-" + date + "-" + now;
    let playerId = userInfo.playerId;
    let override = { ...this.state.data };
    override["actionCode"] = "wUpdateVehicle";
    override["action"] = "Vehicle Details";
    this.setState({ override });

    if (this.state.data.tripStart === "" || this.state.data.tripStart === null) {
      toast.toastWarn("kindly enter Trip Start field")
    }
    else if (this.state.data.fileInfo === "" ) {
      toast.toastWarn("file and file info is mandatory")    }
    else {
      let resultSet = {
        tripStart: this.state.data.tripStart,
        tripId: this.state.currentRequests.tripId,
      };
      // console.log("resultSet", this.state.data.selectedFilesList)
      const data = {
        selectedFilesList: this.state.data.selectedFilesList,
        info: this.state.data.fileInfo,
        playerType:"coordinator",
        playerId:playerId,  
        resultSet: resultSet,
      };
      let resp = await setTicketVehicleInfoUpdateStart(data);
      if (resp.data.success === true) {
        if (resp.data.data) {
          toast.toastSuccess(resp.data.msg);

          let data = { ...this.state.data };
          data["tripStart"] = "";
          data["fileInfo"] = "";
          this.setState({ data, file: [] });
          // this.setState({ data });
          this.props.submit();
          this.props.closeModal();
        } else {
          !resp.data.data && toast.warning(resp.data.msg);
        }
      } else {
        toast.error(resp.data.msg);
      }
    }

  }

  render() {
    const fileStyle = {
      width: "130px",
      height: "130px",
      float: "left",
      cursor: "pointer",
      border: "1px solid #d3c4c4",
      "border-radius": "6px",
    };
    const imgStyle = {
      border: "1px solid #d3c4c4",
      width: "130px",
      height: "130px",
      "line-height": "130px",
      float: "left",
      cursor: "pointer",
      "font-size": "48px",
      color: "#d7caca",
      "background-color": "#f9f1f1",
      "border-radius": "6px",
    };

    return (
      <React.Fragment>
        <div
          className="card "
        // style={{ width: "88%", height: "100%", position: "relative" }}
        >
          <div className="card-header bg-success text-white">
            <div className="row">
              <div className="col">
                Update Trip Start
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                  style={{
                    display: "inline",
                    float: "right",
                    marginTop: "-0.4rem",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-body mt-0 ">
            <div className="row">
              <div className="col">
                <div
                  className="container   overflow-auto"
                  style={{ height: "50rem" }}
                >
                  <>
                    <b className="mt-5">&nbsp;&nbsp;Vehicle Info : <span style={{color:"red"}}>
                      *</span></b>
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3">
                        {this.rNB(
                          "Trip Start (in Kms) *",
                          "tripStart",
                          " col-6 mt-1 mb-3"
                        )}
                      </div>

                      <>
                        <b className="mt-5">&nbsp;&nbsp;File Upload : <span style={{color:"red"}}>*</span></b>

                        <div className="form-row col-12 border border-gray rounded pt-3 ps-3 mb-3">
                          <div className="m-0 p-0 col-9 mt-2">
                            <label
                              htmlFor="refDocFiles"
                              className="form-label font-monospace"
                            >
                              Upload Files <span style={{color:"red"}}>*</span>
                            </label>
                            <input
                              className="form-control form-control-sm "
                              multiple
                              id="refDocFiles"
                              type="file"
                              style={{ cursor: "pointer" }}
                              onChange={(e) => {
                                this.selectedFilesLists(e.target.files);
                              }}
                            />
                          </div>
                          {this.rTB(
                            "File Info *",
                            "fileInfo",
                            " col-12 mt-3 mb-3"
                          )}

                          <div
                            className="col-12 page-todo overflow-auto"
                            style={{ height: "10rem" }}
                          >
                            <div className="tasks">
                              <div className="form-group multi-preview">
                                {(this.state.file || []).map((url, index) => (
                                  <div key={url}>
                                    <li className="list-group-item" key={index}>
                                      <p>
                                        <a>{url.name}</a>
                                      </p>{" "}
                                      <img
                                        className="mt-5"
                                        src={URL.createObjectURL(url)}
                                        alt={url.name}
                                        key={index}
                                        style={{
                                          height: "50px",
                                          width: "100px",
                                        }}
                                      />
                                      <button
                                        type="button"
                                        style={{
                                          position: "absolute",
                                          marginLeft: "30px",
                                        }}
                                        onClick={() => this.deleteFile(index)}
                                      >
                                        delete
                                      </button>
                                    </li>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          {/* <div className="form-group preview">
    {file.length > 0 &&
      file.map((item, index) => {
        return (
          <div key={item}>
            <img src={item} alt="" />
            <button
              type="button"
              onClick={() => this.deleteFile(index)}
            >
              delete
            </button>
          </div>
        );
      })}
  </div> */}
                        </div>
                      </>

                      <div>
                        <button
                          className="btn btn-primary btn-sm pull-right"
                          onClick={() => this.onVehicleInfo()}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default StartDocumentDetails;
