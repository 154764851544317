import React from "react";

const BlockStatusRow = ({ curMisReportHistory }) => {
  const history = curMisReportHistory;
  try {
    try {
      var parsedHist = JSON.parse(history.resultSet);
    } catch (error) {
      console.error("Error in parsing the json- resulSet");
      return null;
    }
    const objArr = [];

    if (parsedHist?.resultSet) {
      const {
        actionStatus,
        ticketBlockId,
        ticketProductId,
        processId,
        feId,
        blockId,
        LatLong,
        LatLongAdd,
        barcode,
        ...rest
      } = parsedHist.resultSet;
      for (const property in rest) {
        objArr.push({
          key: property,
          value: `${
            typeof rest[property] === "object"
              ? JSON.stringify(rest[property])
              : rest[property]
          }`,
        });
      }
      const bizCode = localStorage.getItem("code");

      // display LatLong and LatLong property exclusively for Bizlog.
      if (bizCode === "BIZ") {
        LatLong && objArr.push({ key: "LatLong", value: LatLong });
        LatLongAdd && objArr.push({ key: "LatLongAdd", value: LatLongAdd });
      }
    } else {
      //   console.log("---------->" + JSON.stringify(parsedHist));
      const { barcode, ...rest } = parsedHist;
      //   console.log("rest---------->" + JSON.stringify(rest));

      for (const property in parsedHist) {
        objArr.push({
          key: property,
          value: `${
            typeof parsedHist[property] === "object"
              ? JSON.stringify(parsedHist[property])
              : parsedHist[property]
          }`,
        });
      }
      // const bizCode = localStorage.getItem('code')

      // // display LatLong and LatLong property exclusively for Bizlog.
      // if (bizCode === 'BIZ') {
      //     LatLong && objArr.push({ key: "LatLong", value: LatLong });
      //     LatLongAdd && objArr.push({ key: 'LatLongAdd', value: LatLongAdd });
      // }
    }

    // const objArr = []
    // for (const property in rest) {
    //     objArr.push({ key: property, value: `${typeof rest[property] === 'object' ? JSON.stringify(rest[property]) : rest[property]}` })
    // }
    // const bizCode = localStorage.getItem('code')

    // // display LatLong and LatLong property exclusively for Bizlog.
    // if (bizCode === 'BIZ') {
    //     LatLong && objArr.push({ key: "LatLong", value: LatLong });
    //     LatLongAdd && objArr.push({ key: 'LatLongAdd', value: LatLongAdd });
    // }
    return (
      <div className="curMisHist">
        {
          <table>
            <tbody>
              {objArr.map((item, index) => (
                <tr key={index} className="m-0 p-0">
                  <td>{`${item.key}`}</td>
                  <td>{`${item.value}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        }
      </div>
    );
  } catch (error) {
    console.log("<========== ERROR in BlockStatusRow ===========>");
    console.error(error);
    return null;
  }
};

export default BlockStatusRow;
