import React, { Component } from "react";
import FormLib from "../../_common/FormLib";
import Table from "../../_common/Table";
import Pagination from "../../_common/Pagination";
class TicketProductSummary extends FormLib {
  state = {
    errors: {},
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    pageHide: "false",
    pageCurrent: 1,
    pageSize: 1,
  };
  tableMeta = {
    key: "ticketProductId",
    columns: [
      { path: "productName", label: "Product Name" },
      { path: "identificationNo", label: "Identification Number" },
      {
        key: "playerFeId",
        action: (r) => (
          <>
            {r.firstName && (
              <>{r.firstName + " " + r.lastName + " | " + r.userName}</>
            )}
          </>
        ),
        icon: () => <>FE Info</>,
      },
      {
        key: "prodQcStatus",
        action: (r) => (
          <>
            {r.prodQcStatus && (
              <a target="_blank" rel="no referrer">
                {r.prodQcStatus === "assign" ? (
                  <>
                    <i class="fa fa-spinner fa-lg text-info ml-2">
                      {"   " + "InProcess"}
                    </i>
                  </>
                ) : r.prodQcStatus === "Yes" ? (
                  <i class="fa fa-check fa-lg text-success ml-2">
                    {" "}
                    {"   " + "YES"}
                  </i>
                ) : r.prodQcStatus === "No" ? (
                  <i class="fa fa-times-circle-o fa-lg text-danger ml-2">
                    {" "}
                    {"   " + "NO"}
                  </i>
                ) : r.prodQcStatus === "DeviceNotAvailable" ? (
                  <i class="fa fa-times-circle-o fa-lg text-danger ml-2">
                    {" "}
                    {"   " + "DEVICE NOT AVAILABLE"}
                  </i>
                ) : r.prodQcStatus === "NOT ATTEMPTED" ? (
                  <i class="fa fa-times-circle-o fa-lg text-danger ml-2">
                    {" "}
                    {"   " + "NOT ATTEMPTED"}
                  </i>
                ) : (
                  <>
                    <i class="fa fa-clock-o fa-lg text-danger ml-2">
                      {" "}
                      {"   " + "Pending"}
                    </i>
                    {/* <button
                                                className="badge badge-primary ml-3"
                                                type="button"
                                                onClick={() => this.handleNotAttempted(r)}
                                            >
                                                Not Attempted
                                            </button> */}
                  </>
                )}
              </a>
            )}
          </>
        ),
        icon: () => <>Product Status</>,
      },
      { path: "productLength", label: "Product Length" },
      { path: "productBreadth", label: "Product Breadth" },
      { path: "productHeight", label: "Product Height" },
      { path: "productVolumeWeight", label: "Product Vol Weight" },
      { path: "prdBarcode", label: "Product Barcode" },
    ],
  };
  componentDidMount() {
    this.loadInit();
  }
  loadInit() {
    try {
      const curTicketDetails = this.props.TicketPrimarytDetails;
      const curProductDetails = this.props.ticketProductDetails;
      this.setState({ curTicketDetails });
      this.setState({ curProductDetails });
      // this.prodVerification(curProductDetails);
    } catch {
      console.log("Error in TicketProductSummary>>>>>>loadInit");
    }
  }
  render() {
    const ticketNo = this.props.curTicket;
    const productDetails = this.state.curProductDetails;
    return (
      <>
        {this.state.pageHide === "false" && (
          <div className="row">
            <div className="col-md-12">
              <div className="card ">
                <div className="card-body bg-light">
                  {/* <div className="form-subHeader col-9 ml-5">
                                        <b>Ticket Products Summary - {ticketNo}</b>
                                    </div> */}
                  <div className="row mt-4 col-9 ml-5">
                    <div className="container-fluid">
                      <Table
                        clsName="table table-striped table-bordered table-hover"
                        tableMeta={this.tableMeta}
                        data={productDetails}
                      />

                      {/* <Pagination
                                                pageNo={this.state.pageCurrent}
                                                perPage={this.state.pageSize}
                                                itemsCount={pageCount}
                                                onPageChange={this.handlePageChange}
                                            /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default TicketProductSummary;
