import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLPrevilages;
function api(path, subURL = "/teams") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

function apiTeamType(path, subURL = "") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getTeams() {
  try {

    return _http.get(api("active"), _http.headerJWT());
  }
  catch {
    return undefined;
  }
}
export function getTeamsByRetailer(typeId, pageNo) {
  return _http.get(
    apiTeamType("all/" + typeId + "/10/" + pageNo),
    _http.headerJWT()
  );
}
export function getTeamsAll(pageNo) {
  return _http.get(api("all/10/" + pageNo), _http.headerJWT());
}
export function createTeam(req) {
  const param = { ...req };
  // delete param.teamId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveTeam(req) {
  const param = { ...req };
  const id = param.teamId;
  //delete param.teamId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteTeam(req) {
  const param = { ...req };
  return _http.delete(api(param.teamId), _http.headerJWT());
}
