import React from "react";
import ZTitle from "./../../_subhead/ZTitle";
import {
  getHubs,
  getHubsAll,
} from "./../../../services/masterLocations/hubServices";
import toast from "../../../services/_toastService";
import FormLib from "../../_common/FormLib";
import ExportXLSXReqTrans from "./../requestV1/ExcelDownload";
import {
  getRegions,
  getZones,
} from "./../../../services/masterLocations/locationServices";
import Joi from "joi-browser";
import { getMISDetails } from "../../../services/Tickets/TicketServices";
import Table from "./../../_common/Table";
import { dropMenuFull } from "./../../../utils/DropMenuUtils";
import { getTeams } from "./../../../services/players/teamServices";
import { ExportXLSX } from "./../requestV1/ExportXLSX";
import {
  epoch2ddmmyyyyhhmmss,
  tDate2yyyymmddhhmmss,
  IstToUTC,
} from "../../../utils/DateUtils";

import dateFormat from "dateformat";

class MisReport extends FormLib {
  state = {
    data: {
      startDate: this.state.sortKey,
      endDate: this.getDatebyDays(0),
      hubId: [{ value: "3486275" }, { value: "188645625" }],
      retailerId: "",
      searchString: "",
      ticketStatus: "PROCESSING",
      // region: "",
      // zone: "",
    },
    userCode: "",
    userInfoTeamId: "",
    errors: {},
    regions: [],
    zones: [],
    hubs: [],
    clients: [],
    report: [],
    csvReport: {
      data: [],
      headers: [],
    },
    dateStatus: "",
  };

  schema = {
    startDate: Joi.string().required().label("Start Date"),
    endDate: Joi.string().required().label("End Date"),
    hubId: Joi.optional().label("Hub"),
    retailerId: Joi.optional().label("retailerId"),
    searchString: Joi.optional().label("searchString"),
    ticketStatus: Joi.string().optional().label("ticketStatus"),
    status: Joi.optional().label("status"),
    // zone: Joi.optional().label("Zone"),
  };

  ticketStatus = [
    { sortKey: "CLOSED", sort: "Closed" },
    { sortKey: "PROCESSING", sort: "In Process" },
    { sortKey: "All", sort: "All" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  status = [
    { sortKey: this.getDatebyDays(0), sort: "Today" },
    { sortKey: this.getDatebyWeeks(0), sort: "Past_Week" },
    { sortKey: this.getDatebyMonths(0), sort: "Past_Month" },
    { sortKey: "Custom", sort: "Custom" },
  ];

  excelHeaders = [
    "created_at",
    "ticketNo",
    "conComplaintNo",
    "retailerName",
    "productCode",
    "productName",
    "brand",
    "model",
    "productValue",
    "identificationNo",
    "productDesc",
    "srcContactPerson",
    "srcContact1",
    "srcAdd1",
    "srcCity",
    "srcPincode",
    "dstContactPerson",
    "dstContact1",
    "dstAdd1",
    "dstCity",
    "dstPincode",
    "srcHubId",
    "dstHubId",
    "pickupTime",
    "pickupFeName",
    "dropTime",
    "dropFeName",
    "linehaulDispatchedAt",
    "linehaulAwb",
    "blockStatus",
    "updated_at",
    "updated_by",
    "closedRemarks",
    "modelId",
    "brandId",
  ];

  buildTableMeta = () => {
    const basicColumns = [];
  };

  tableMeta = {
    key: "misTicketId" + 1,
    columns: [
      {
        key: "created_at",
        action: (r) => <>{r.created_at}</>,
        icon: () => <>created_at</>,
      },
      {
        key: "ticketNo",
        action: (r) => <b>{r.ticketNo}</b>,
        icon: () => <>Ticket No</>,
      },
      { path: "conComplaintNo", label: "conComplaintNo" },
      { path: "retailerName", label: "retailerName" },
      { path: "productCode", label: "productCode" },
      { path: "productName", label: "Product Name" },
      { path: "brand", label: "brand" },
      { path: "model", label: "model" },
      { path: "productValue", label: "productValue" },
      { path: "identificationNo", label: "identificationNo" },
      { path: "productDesc", label: "productDesc" },
      { path: "srcContactPerson", label: "srcContactPerson" },
      { path: "srcContact1", label: "srcContact1" },
      { path: "srcAdd1", label: "srcAdd1" },
      { path: "srcCity", label: "srcCity" },
      { path: "srcPincode", label: "srcPincode" },
      { path: "dstContactPerson", label: "dstContactPerson" },
      { path: "dstContact1", label: "dstContact1" },
      { path: "dstAdd1", label: "dstAdd1" },
      { path: "dstCity", label: "dstCity" },
      { path: "dstPincode", label: "dstPincode" },
      { path: "srcHubId", label: "srcHubId" },
      { path: "dstHubId", label: "dstHubId" },

      {
        key: "pickupTime",
        action: (r) => <>{epoch2ddmmyyyyhhmmss(r.pickupTime)}</>,
        icon: () => <>Pickup Date&Time</>,
      },
      { path: "pickupFeName", label: "pickupFeName" },
      {
        key: "dropTime",
        action: (r) => <>{epoch2ddmmyyyyhhmmss(r.dropTime)}</>,
        icon: () => <>drop Date&Time</>,
      },
      { path: "dropFeName", label: "dropFeName" },
      {
        key: "linehaulDispatchedAt",
        action: (r) => <>{epoch2ddmmyyyyhhmmss(r.linehaulDispatchedAt)}</>,
        icon: () => <>Dispatched Date&Time</>,
      },
      // { path: "linehaulDispatchedAt", label: "linehaulDispatchedAt" },
      { path: "linehaulAwb", label: "linehaulAwb" },
      {
        key: "blockStatus",
        action: (r) => <b>{this.replaceString(r.blockStatus)}</b>,
        cusClass: "",
        icon: () => <>Current Status</>,
      },
      {
        key: "updated_at",
        action: (r) => <>{r.updated_at}</>,
        icon: () => <>updated_at</>,
      },
      // { path: "docketNo", label: "docketNo" },
      { path: "updated_by", label: "updatedBy" },
      { path: "closedRemarks", label: "closedRemarks" },
      { path: "modelId", label: "modelId" },
      { path: "brandId", label: "brandId" },
    ],
  };

  replaceString = (str) => {
    return str.replace(/_/g, " ");
  };

  componentDidMount() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.state.userCode = userInfo.code;
    this.state.userInfoTeamId = userInfo.teamId;

    this.loadHubs();
    this.loadRetailers();
  }

  async loadHubs() {
    const res = await getHubsAll();
    if (res !== undefined) {
      if (res.data.success === true) {
        this.setState({ hubs: res.data.data.hubs });
      } else {
        toast.toastError(res.data.msg);
      }
    }
  }

  async loadRetailers() {
    const res = await getTeams();
    if (res !== undefined) {
      if (res.data.success === true) {
        this.setState({ clients: res.data.data.teams });
      } else {
        toast.toastError(res.data.msg);
      }
    }
  }

  async doSubmit() {
    this.setState({ report: [] });
    let data = this.state.data;
    if (this.state.userCode !== "BIZ") {
      data["retailerId"] = this.state.userInfoTeamId;
      this.setState({
        data,
      });
    }
    let res = await getMISDetails(this.state.data);
    if (res.data.success) {
      if (res.data.data.reportsList.length !== 0) {
        toast.toastSuccess(res.data.msg);
        this.setState({ report: res.data.data.reportsList });

        ///Excel
        this.formatMISReport(this.state.report);

        let apiData = {};
        const { csvReport } = { ...this.state };
        apiData = this.state.report;

        csvReport.filename = "financial_report.xlsx";
        csvReport.headers = this.excelHeaders;

        let transData = [];
        transData = apiData;
        this.setState({ csvReport });

        if (apiData) {
          const promises = apiData;

          transData = await Promise.all(promises);
          csvReport.data = transData;
          this.setState({ csvReport });
        } else {
          csvReport.data = [];
          this.setState({ csvReport });
        }
        ///Excel
      } else {
        toast.toastWarn("No Data");
      }
      // this.loadCountry();
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });
    // this.setState({ report: res.data.data.reportsList });
  }

  formatMISReport = async (r) => {
    let formatedMIS = await Promise.all(
      r.filter(async (t) => {
        // let t = { ...trx };
        delete t.partAdd1;
        delete t.partAdd2;
        delete t.partAdd3;
        delete t.cusAdd1;
        delete t.cusAdd2;
        delete t.cusAdd3;
        delete t.cusAdd4;
        delete t.cusAdd5;

        delete t.misTicketId;
        delete t.actionStatus;
        delete t.processId;
        delete t.ticketPriority;
        delete t.flowId;
        delete t.retailerId;
        delete t.retailerNo;
        delete t.orderNo;
        delete t.invoiceNo;
        delete t.parentTicket;
        delete t.ticketDetails;
        delete t.dateOfTicket;
        delete t.isAppointmentReq;
        delete t.isInstallationReq;
        delete t.isPhysicalEval;
        delete t.isTechEval;
        delete t.isPackingReq;
        delete t.orderNo;
        delete t.invoiceNo;
        delete t.parentTicket;
        delete t.ticketDetails;
        delete t.specialInstructions;
        delete t.dateOfTicket;
        delete t.isAppointmentReq;
        delete t.isInstallationReq;
        delete t.isPhysicalEval;
        delete t.isTechEval;
        delete t.isPackingReq;
        delete t.primaryDetails;
        delete t.flowConfig;
        delete t.flowName;
        // delete t.retailerName;
        delete t.retailerCode;
        delete t.ticketBarcode;
        delete t.misBlockId;
        delete t.ticketBlockId;
        delete t.blockCode;
        delete t.syncStatus;
        delete t.blockBarcode;
        delete t.pickupDate;
        delete t.pickupFeType;
        delete t.pickupFeId;
        // delete t.pickupFeName;
        delete t.pickupRemarks;
        delete t.dropFeId;
        delete t.dropDate;
        delete t.dropFeType;
        // delete t.dropFeName;
        delete t.dropRemarks;
        delete t.closedDate;
        delete t.closedTime;
        delete t.closedBy;
        delete t.closedById;
        delete t.closedByName;
        delete t.closedStatus;
        delete t.vehicleNo;
        delete t.weight;
        delete t.playerFeId;
        delete t.playerFeType;
        delete t.feAppointmentDateTime;
        delete t.misProdId;
        delete t.ticketProductId;
        delete t.prdBarcode;
        delete t.prodStatus;
        delete t.productCodeR;
        delete t.productR;
        delete t.brandCode;
        delete t.brandCodeR;
        delete t.brandR;
        delete t.modelCode;
        delete t.modelCodeR;
        delete t.modelR;
        delete t.productId;
        // delete t.brandId;
        // delete t.modelId;
        // delete t.productName;
        delete t.dateOfPurchase;
        delete t.problemDesc;
        // delete t.productValue;
        delete t.cost;
        delete t.isUnderWarranty;
        delete t.productDetails;
        delete t.srcAddressId;
        delete t.srcAdd2;
        delete t.srcLocation;
        delete t.srcContact2;
        delete t.srcState;
        delete t.srcLandmark;
        delete t.srcEmailId;
        delete t.srcEmailIdAlt;
        delete t.srcType;
        delete t.dstAddressId;
        delete t.dstLocation;
        delete t.dstAdd2;
        delete t.dstContact2;
        delete t.dstState;
        delete t.dstLandmark;
        delete t.dstEmailId;
        delete t.dstEmailIdAlt;
        delete t.dstType;
        delete t.dstEmailId;
        delete t.product;
        delete t.hubId;
        delete t.docketNo;
        delete t.created_by;

        t.created_at = await epoch2ddmmyyyyhhmmss(t.created_at);
        t.updated_at = await epoch2ddmmyyyyhhmmss(t.updated_at);
        t.pickupTime = await epoch2ddmmyyyyhhmmss(t.pickupTime);
        t.dropTime = await epoch2ddmmyyyyhhmmss(t.dropTime);
        t.linehaulDispatchedAt = await epoch2ddmmyyyyhhmmss(
          t.linehaulDispatchedAt
        );

        return t;
      })
    );

    this.setState({ formatMIS: formatedMIS });
  };

  localHandleChange = (e) => {
    if (e.target.name === "retailerId") {
      const { value } = e.target;
      let data = this.state.data;
      let retailer = this.state;

      // find the clients with the current selected value
      const clients = retailer.clients.find((c) => c.name === value);

      if (clients !== undefined && this.state.userCode === "BIZ") {
        data["retailerId"] = clients.teamId;
        this.setState({
          data,
        });
      }
      // else if (this.state.userCode !== "BIZ") {
      //   data["retailerId"] = this.state.userInfoTeamId;
      //   this.setState({
      //     data,
      //   });
      // }
      this.validateProperty(e.target.name, e.target.value);
    }
  };
  render() {
    const reportJSON = this.state.report;
    return (
      <>
        <ZTitle subHeading="Report  :: MIS" />
        <div className="pageContent">
          <div className="card">
            <div className="card-body" style={{ backgroundColor: "white" }}>
              {/* {JSON.stringify(this.state.data)} */}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="col-11">
                    <>
                      {this.rRBL("Date Selection:", "startDate", this.status, {
                        value: "sortKey",
                        label: "sort",
                      })}
                    </>
                    <div className="form-row mt-2 ">
                      {this.rDPFMIS("Start Date *", "startDate", " col-md-2 ")}
                      {this.rDPFMIS("End Date *", "endDate", " col-md-2 ")}
                      {/* {this.rSB(
                    "Region",
                    "region",
                    this.state.regions,
                    { value: "regionId", label: "region" },
                    " col-md-3 "
                  )}
                  {this.rSB(
                    "Zone",
                    "zone",
                    this.state.zones,
                    { value: "zoneId", label: "zone" },
                    " col-md-3 "
                  )} */}
                      <div style={{ paddingLeft: "15px" }}>
                        {this.rSBMulti(
                          "Hub",
                          "hubId",
                          this.state.hubs,
                          { value: "hubId", label: "hub" },
                          " col-md-2 "
                        )}
                      </div>
                      {/* {this.rSB(
                        "Hub",
                        "hubId",
                        this.state.hubs,
                        { value: "hubId", label: "hub" },
                        " col-md-2 "
                      )} */}
                      <div
                        style={{ paddingLeft: "15px", paddingRight: "15px" }}
                      >
                        {this.state.userCode === "BIZ" && (
                          <>
                            {this.rSBMulti(
                              "Client",
                              "retailerId",
                              this.state.clients,
                              { value: "teamId", label: "name" },
                              " col-md-2 "
                            )}
                          </>
                        )}
                      </div>
                      {this.rTB("Search string", "searchString", "col-md-2")}
                      {this.rSB(
                        "Ticket Status",
                        "ticketStatus",
                        this.ticketStatus,
                        this.stsConf,
                        " col-md-2 "
                      )}
                    </div>
                  </div>
                  <div
                    className="col-1 pull-right"
                    style={{ paddingTop: "4%" }}
                  >
                    {this.rBtnSP("Submit", "Submit")}
                  </div>
                </div>
              </form>
            </div>
            {/* {reportJSON.length === 0 && (
              <div class="jumbotron jumbotron-fluid">
                <p class="lead text-center">No data found</p>
              </div>
            )} */}
            {reportJSON.length > 0 && (
              <div className="form-row">
                {this.props.loading && (
                  <div className="container-fluid">
                    <div> Loading Tickets...</div>
                  </div>
                )}
                {!this.props.loading && (
                  <>
                    <ExportXLSX
                      csvData={this.state.formatMIS}
                      header={this.excelHeaders}
                      fileName={
                        "MIS-" +
                        this.state.data.startDate +
                        "-" +
                        this.state.data.endDate +
                        ""
                      }
                    />
                    <div className="container-fluid" id="cocard">
                      <Table
                        clsName="table table-striped table-bordered table-hover"
                        tableMeta={this.tableMeta}
                        data={reportJSON}
                        dropMenu={dropMenuFull()}
                      />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default MisReport;
