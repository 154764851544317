import _http from "../_httpService";

import config from "../../config.json";


const baseURL = config[config.build].apiURLCore;
function api(path, subURL = "/tracker/settings") {
    path = path !== "" ? "/" + path : "";
    return `${baseURL}${subURL}${path}`;
}

export function getProductCat() {
    return _http.get(api("products-category"), _http.headerJWT());
}

export function createProductCat(req) {
    const param = { ...req }
    delete param.prodCatId;
    return _http.post(api("products-category"), param, _http.headerJWT());
}

export function updateProductCat(req) {
    const param = { ...req }
    let url = "products-category/" + req.prodCatId;
    return _http.put(api(url), param, _http.headerJWT());
}

export function deleteProductCat(req) {
    let url = "products-category/" + req.prodCatId;
    return _http.delete(api(url), _http.headerJWT());
}
