import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";
import {createBrand,updateBrand,deleteBrand} from "../../../services/products/ProductsCatBrandsServices";

class ModelProductsBrand extends FormLib {

    state = {
        data: {
            prodBrandId: "",
            brand: "",
            brandCode: "",
            status: "active"
        },
        errors: {},
    };
    status = [
        { sortKey: "active", sort: "Active" },
        { sortKey: "inactive", sort: "Inactive" },
    ];
    stsConf = {
        value: "sortKey",
        label: "sort",
    };
    schema = {
        prodBrandId: Joi.optional().label("prodBrandId"),
        brand: Joi.string().required().label("Brand Name "),
        brandCode: Joi.string().required().label("brand Code"),
        status: Joi.string().required().label("Status"),
    };


     doSubmit = async()=> {
        let res;
        if (this.state.btnValue === "create") {
            res = await createBrand(this.state.data);
            this.resetData();
        }
        if (this.state.btnValue === "edit") {
            res = await updateBrand(this.state.data);
            this.resetData();
        }
        if (this.state.btnValue === "delete") {
            res = await deleteBrand(this.state.data);
            this.resetData();
        }
        if (res.data.success === true) {
            toast.toastSuccess(res.data.msg);
            this.loadProducts();
            this.props.onloadBrand();
        } else {
            toast.toastError(res.data.msg);
        }
    }

    resetData = () => {
        this.setState({
            data: {
                prodBrandId: "",
                brand: "",
                brandCode: "",
                status: "active"
            }
        });
    }
    componentDidMount() { 
        this.setCurBrand(this.props.curProductsBrand);
    }
    setCurBrand = (p) =>{
        if (p.prodCatId !== "") {
          this.setState({ data: p });
        } else {
          this.resetData();
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="modal fade" id="myMaterialModal">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Product Category Brand </h4>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>

                            <div className="modal-body ">
                                <form>
                                    <div className="form-row">
                                        {this.rTB("Brand Name", "brand", "col-12")}
                                        {this.rTB("Brand Code", "brandCode", "col-12")}
                                        <br/> <br/>
                                        {this.rRBL("Status", "status", this.status, this.stsConf,"col-12")}
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                {this.state.data.prodBrandId === "" &&
                                    this.rBtnSP("Create", "create")}
                                {this.state.data.prodBrandId !== "" && (
                                    <>
                                        {this.rBtnSS("Edit", "edit")}
                                        {this.rBtnSD("Delete", "delete")}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ModelProductsBrand;