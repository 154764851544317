import React from "react";
import FormLib from "../../../_common/FormLib";
import dateFormat from "dateformat";

class RequestRejected extends FormLib {
  state = {
    requestDetails: {},
  };

  componentDidMount() {
    this.setRequest();
  }

  setRequest() {
    this.setState({ requestDetails: this.props.data });
  }

  render() {
    return (
      <React.Fragment>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.props.closeModal}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="card">
          <div className="card-header bg-danger text-white">
            <div className="row">
              <div className="col-6">{this.state.requestDetails.M_CLIENT}</div>
              <div className="col-6 text-right">
                {dateFormat(this.state.requestDetails.CREATED_AT, "dd-mm-yyyy")}
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                {this.state.requestDetails.REF_ID} [
                {this.state.requestDetails.M_HUB}]
              </div>
              <div className="col-4 text-right">
                #{this.state.requestDetails.RE_PREFIX}
                {this.state.requestDetails.SEQ}
              </div>
            </div>
          </div>
          <div className="card-body border-top">
            <div className="row">
              <div className="col-4">
                <span className="req-label">Request Type </span>
                <span className="req-value">
                  {this.state.requestDetails.REQUEST_TYPE}
                </span>
              </div>
              {this.state.requestDetails.PLAYER_TYPE === "employee" && (
                <div className="col-4">
                  <span className="req-label">Employee</span>
                  <span className="req-value">
                    {this.state.requestDetails.E_NAME} [
                    {this.state.requestDetails.PLAYER_CODE}]
                  </span>
                </div>
              )}
              {this.state.requestDetails.PLAYER_TYPE === "vendor" && (
                <div className="col-4">
                  <span className="req-label">Vendor</span>
                  <span className="req-value">
                    {this.state.requestDetails.V_NAME} [
                    {this.state.requestDetails.PLAYER_CODE}]
                  </span>
                </div>
              )}
              <div className="col-4">
                <span className="req-label">Flow </span>
                <span className="req-value">
                  {this.state.requestDetails.M_FLOW}
                </span>
              </div>
            </div>
          </div>

          <div class="panel-group widget-request">
            <div className="panel panel-info">
              <label>Activity Details</label>
              <div className="panel-body">
                {this.state.requestDetails.M_ACTIVITY}
              </div>
            </div>
            <div class="panel panel-info">
              <label>Request Details</label>
              <div class="panel-body">
                {this.state.requestDetails.REQUEST_DETAILS}
              </div>
            </div>
            <div class="panel panel-info">
              <label className="label-approve">Approve Remarks</label>
              <div class="panel-body">
                {this.state.requestDetails.APPROV_REMARK}
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <span className="req-label">Total Amount </span>
                <span className="req-value">
                  {this.state.requestDetails.AMT}
                </span>
              </div>
              <div className="col-6 text-right">
                <span className="req-label">Requested Amount </span>
                <span className="req-value">
                  {this.state.requestDetails.REQ_AMT}
                </span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default RequestRejected;
