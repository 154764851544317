import React, { Component } from "react";
import SubHeader from "../_theme/SubHeader";
import { getHomeMenu } from "./comhead/previlageMenu";

class PrevilagesTitle extends Component {
  state = {};
  quickMenu = [
    { path: "/z/add", icon: "fa-plus" },
    { path: "/z", icon: "fa-table" },
    { path: "/z/print", icon: "fa-print" },
  ];
  subMenu = [
    { path: "/z/view", label: "View", icon: "fa-binoculars" },
    { path: "/z/edit", label: "Edit", icon: "fa-pencil" },
    { path: "/z/delete", label: "Delete", icon: "fa-trash" },
    { path: "/z/export", label: "Export", icon: "fa-table" },
    { path: "/z/print", label: "Print", icon: "fa-print" },
    { path: "/z/pdf", label: "PDF", icon: "fa-file-pdf-o" },
  ];
  render() {
    const homeMenu = [...getHomeMenu()];

    return (
      <React.Fragment>
        <SubHeader
          subHeading={this.props.subHeading}
          quickMenu={this.quickMenu}
          subMenu={this.subMenu}
          homeMenu={homeMenu}
        />
      </React.Fragment>
    );
  }
}
export default PrevilagesTitle;
