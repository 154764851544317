import React, { Component } from "react";

import Sidebar from "./Sidebar";
import Routes from "../Routes";

class AppMain extends Component {
  state = {};
  sidebarResize() {
    // window.requestAnimationFrame(function () {
    //   $("#sidebar-wrapper").css(
    //     "min-height",
    //     $("#page-content-wrapper").innerHeight() + 27
    //   );
    //   // console.log($("#page-content-wrapper").innerHeight());
    // });
  }
  render() {
    return (
      <React.Fragment>
        <div id="wrapper" className="toggled-2">
          <Sidebar user={this.props.user} />
          <Routes />
          {this.sidebarResize()}
        </div>
      </React.Fragment>
    );
  }
}
export default AppMain;
