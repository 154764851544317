import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";

class AddList extends FormLib {
  state = {
    data: {
      username: "",
      password: "",
      pickupDate: this.getDatebyDays(1),
      pickupTime: "14:00",

      projectId: "",
      projectCode: "",
      project: "",
      owner: "",
      startDate: "",
      dueDate: "",
      endDate: "",
      remarks: "",
      status: "",
    },
    errors: {},
  };

  schema = {
    ticket1: Joi.string().label("Ticket 1"),
    ticket2: Joi.string().label("Ticket 2"),
  };

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="">
                <div className="card-body"></div>
                <div className="form-row">
                  {this.rTB("Project", "project", "col-4")}
                  {this.rTB("Project Code", "projectCode", "col-4")}
                  {this.rTB("Owner", "owner", "col-4")}
                </div>

                <div className="form-row">
                  {this.rDP("Due Date", "dueDate", "col-md-4")}
                  {this.rDP("End Date", "endDate", "col-md-4")}
                  {this.rDP("Start Date", "startDate", "col-md-4")}
                  {/* <div className="form-row">
                    {this.rDP("Start Date", "pickupDate", "col-md-4")}
                    {this.rDP("Due Date", "pickupDate", "col-md-4")}
                    {this.rDP("End Date", "pickupDate", "col-md-4")}
                    </div> */}

                  <div className="form-group col-md-12">
                    <textarea required="required"></textarea>
                    <label htmlFor="textarea" className="control-label">
                      Remark
                    </label>
                    <i className="bar"></i>
                  </div>
                </div>

                <div className="form-row">
                  {this.rRBL("Status", "status", this.status, {
                    value: "sortKey",
                    label: "sort",
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </React.Fragment>
    );
  }
}
export default AddList;
