import React from "react";
import ZTitle from "../../_subhead/ZTitle";

import DropDownMenu from "./../../_common/DropDownMenu";
import FormLib from "../../_common/FormLib";
import Pagination from "./../../_common/Pagination";
import { dropMenuBasic } from "./../../../utils/DropMenuUtils";

import _ from "lodash";
import "./../../../assets/bluedart.css";
import bizlog_logo from "./../../../assets/img/bizlog.png";
import blue_dart_logo from "./../../../assets/img/blue_dart_image.jpg";
import barcode from "./../../../assets/img/barcode.jpg";
import jsPDF from "jspdf";

import {
  initData,
  setData,
  getSchema,
  stdSettings,
  getShipmentsByStatus,
  processShipment,
  getHubs,
  getHubLists,
} from "./../../../services/bluedart/bluedartServices";
class BDWrapper extends FormLib {
  state = {
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
    mode: "open",
    shipments: [],
    resetData: {},
    data: {
      bdShipmentsId: "",
      crediRefNo: "",
      noTickets: "1",
      ticket0: "",

      shArea: "",
      shClientName: "",
      shPincode: "",
      shAdd1: "",
      shAdd2: "",
      shAdd3: "",
      shMob: "",
      shPhone: "",
      shEmail: "",

      cnConsiName: "",
      cnPincode: "",
      cnAdd1: "",
      cnAdd2: "",
      cnAdd3: "",
      cnMob: "",
      cnPhone: "",
      cnEmail: "",

      pickDate: this.getDatebyDays(1),
      pickTime: "14:00",
      prPieces: "1",
      prWeight: "",

      length0: "",
      breadth0: "",
      height0: "",

      status: "open",

      prCode: "A",
      prSubProd: "P",
      pdfLb: "A4 Single Copy",

      delNet: "yes",
      pickUp: "yes",
      proType: "dox",
      forcePu: "no",
      isRvp: "yes",
      wayBill: "req",
      shippingHub: "",
      consigneeHub: "",
    },
    errors: {},
    stdSettings: {},
    hubs: [],
  };

  tableMeta = {
    key: "userId",
    columns: [
      { path: "shClientName", label: "Client Name" },
      { path: "shEmail", label: "Location" },
      { path: "shArea", label: "Tickets" },
      { path: "cnMob", label: "Weight/Dimentions" },
    ],
  };

  formSet(mi) {
    const data = setData(mi, this.state.data);
    this.setState({ data: data });
  }

  handlePageChange = (pageNo) => {
    this.props.history.push("/bluedart/" + pageNo);
    this.loadShipments(this.state.mode, pageNo);
  };

  getHubFilter(hubId) {
    const curHub = this.state.hubs.filter((r) => {
      return r.hubId === hubId ? r : null;
    });

    return curHub[0];
  }
  localHandleChange = (e) => {
    if (e.target.name === "shippingHub") {
      let curAdd = {
        areaCode: "",
        contactName: "",
        pincode: "",
        add1: "",
        add2: "",
        add3: "",
        mobile: "",
        phone: "",
        email: "",
      };
      if (e.target.value !== "") curAdd = this.getHubFilter(e.target.value);
      const data = { ...this.state.data };
      data.shArea = curAdd.areaCode;
      data.shClientName = curAdd.contactName;
      data.shPincode = curAdd.pincode;
      data.shAdd1 = curAdd.add1;
      data.shAdd2 = curAdd.add2;
      data.shAdd3 = curAdd.add3;
      data.shMob = curAdd.mobile;
      data.shPhone = curAdd.phone;
      data.shEmail = curAdd.email;

      this.setState({ data: data });
    }

    if (e.target.name === "consigneeHub") {
      let curAdd = {
        contactName: "",
        pincode: "",
        add1: "",
        add2: "",
        add3: "",
        mobile: "",
        phone: "",
        email: "",
      };
      if (e.target.value !== "") curAdd = this.getHubFilter(e.target.value);

      const data = { ...this.state.data };
      data.cnConsiName = curAdd["contactName"];
      data.cnPincode = curAdd["pincode"];
      data.cnAdd1 = curAdd["add1"];
      data.cnAdd2 = curAdd["add2"];
      data.cnAdd3 = curAdd["add3"];
      data.cnMob = curAdd["mobile"];
      data.cnPhone = curAdd["phone"];
      data.cnEmail = curAdd["email"];

      this.setState({ data: data });
    }
  };

  async doSubmit() {
    if (this.state.btnValue === "reset") {
      this.setState({ data: this.state.resetData });
      return null;
    }

    const res = await processShipment(this.state.data, this.state.btnValue);

    if (this.state.btnValue === "book") {
      console.log("Handle AWB Shipment Booking");
      // console.log(res);
    }
    // console.log(res);
    this.handlePageChange(1);
    // document.getElementById("myForm").reset();
    // this.setState({data:this.state.resetData});
  }

  loadShipments = async (mode, pageNo = 1) => {
    try {
      this.setState({ shipments: [] });
      const res = await getShipmentsByStatus(mode, pageNo);
      this.setState({ shipments: res.data.data });
      this.setState({ mode: mode });
      this.setState({ pageLinks: res.data.pageLinks });
    } catch (ex) { }
  };

  loadhubs = async () => {
    const hubs = await getHubs();
    this.setState({ hubs: hubs });
  };
  loadhubList = async () => {
    const hubs = await getHubLists();
    this.setState({ hubs: hubs });
  };

  componentDidMount() {
    const pageNo = this.props.match.params.pageNo
      ? parseInt(this.props.match.params.pageNo)
      : 1;
    this.loadShipments("open", pageNo);
    this.loadhubs();
    this.setState({ stdSettings: stdSettings() });
    this.setState({ data: initData() });
    this.schema = getSchema();
    this.setState({ resetData: this.state.data });
  }
  generatePDF = () => {
    //p -porterate, pt- points, a4- page size
    var doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: [750, 800],
    });
    doc.html(document.querySelector("#print"), {
      callback: function (pdf) {
        pdf.save("mypdf.pdf");
      },
    });
  };

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    // if (itemsCount === 0) return <p>No Data Found</p>;
    return (
      <React.Fragment>
        <ZTitle subHeading="Bluedart :: Shipments" />
        <div className="pageContent">
          <div className="row">
            <div className="col-12 col-md-4 col-lg-4 col-lg-4">
              <React.Fragment>
                <div className="navbar_  b-b flex-row container-fluid">
                  <div className="d-flex_ p-2 text-center flex justify-content-center">
                    <div className="btn-group">
                      <button
                        href="#link1"
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={() => this.loadShipments("open")}
                      >
                        Open
                      </button>
                      <button
                        href="#link2"
                        type="button"
                        className="btn btn-sm btn-success"
                        onClick={() => this.loadShipments("intransit")}
                      >
                        In-transit
                      </button>
                      <button
                        href="#link2"
                        type="button"
                        className="btn btn-sm btn-info"
                        onClick={() => this.loadShipments("closed")}
                      >
                        Closed
                      </button>
                      <button
                        href="#link3"
                        type="button"
                        className="btn btn-sm btn-warning"
                        onClick={() => this.loadShipments("rejected")}
                      >
                        Rejected
                      </button>
                    </div>
                    <div className="card-body">
                      <div
                        className="form-subHead"
                        data-toggle="collapse"
                        href="#search"
                        aria-expanded="false"
                        aria-controls="setting"
                      >
                        Search
                        <i
                          className="fa fa-angle-down fa-1x text-danger ml-1 pull-right"
                          aria-hidden="true"
                        ></i>
                      </div>
                      {
                        <div className="collapse" id="search">
                          <br />
                          <div className="form-row">
                            {this.rTB("Search", "search", "col-6")}
                            {this.rSB(
                              "Hub",
                              "hubLists",
                              this.state.hubs,
                              { value: "hubId", label: "hub" },
                              "col-6"
                            )}
                          </div>
                          <div className="form-row">
                            {this.rRBL(
                              "Hub Type",
                              "hubType",
                              this.state.stdSettings.hubType,
                              { value: "value", label: "label" },
                              "col-10"
                            )}
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>

                {this.props.loading && (
                  <div className="container-fluid">
                    <div> Loading Shipments...</div>
                  </div>
                )}
                {!this.props.loading && itemsCount && (
                  <div className="container-fluid">
                    <div className="page-todo">
                      <div className="col-md-12 tasks">
                        <div className="priority low">
                          <span> {this.state.mode} </span>
                        </div>
                        <div className="task-list">
                          {this.state.shipments.map((mi) => (
                            <div className="task nil" key={mi.bdShipmentsId}>
                              <div className="desc">
                                <div
                                  className="title"
                                  onClick={() => this.formSet(mi)}
                                >
                                  {mi.shClientName}
                                </div>
                                <div>
                                  {mi.sh_area} {mi.crediRefNo}
                                </div>
                                <div>{mi.tickets} </div>
                              </div>
                              <div className="rightCol">
                                <div className="menu">
                                  <DropDownMenu
                                    menuItems={dropMenuBasic(
                                      "/v1/request/raise"
                                    )}
                                    menuKey={mi.key}
                                  />
                                  {mi.docketNo !== "null" && (
                                    <p>{mi.docketNo}</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <br />
                    <Pagination
                      itemsCount={itemsCount}
                      perPage={perPage}
                      pageNo={pageNo}
                      onPageChange={this.handlePageChange}
                    />
                  </div>
                )}
              </React.Fragment>
            </div>
            <div className="col-12 col-md-8 col-lg-8 col-lg-8">
              <React.Fragment>
                <form
                  id="shipmentForm"
                  name={"bdForm"}
                  method="post"
                  onSubmit={this.handleSubmit}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-md-12 col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div>
                              <strong>Tickets</strong>
                            </div>
                            <br />
                            <div className="row">
                              {this.rTB("No of Ticket", "noTickets", "col-6")}

                              {/* {this.rTB("ID", "id", "col-1")} */}
                              <div className="col-6 ">
                                <div className="form-row">
                                  {this.rDP(
                                    "Pickup Date",
                                    "pickDate",
                                    "col-md-6"
                                  )}
                                  {this.rTP(
                                    "Pickup Time",
                                    "pickTime",
                                    "col-md-6"
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-row">
                              {_.times(this.state.data.noTickets || 1, (i) => (
                                <span
                                  style={{
                                    display: "inline-block",
                                    marginRight: 4,
                                  }}
                                  key={i}
                                >
                                  {this.rTB(
                                    "Ticket No-" + (i + 1),
                                    "ticket" + i
                                  )}
                                </span>
                              ))}
                            </div>
                          </div>

                          <div className="card-body border-top">
                            <label className="mb-3 mt-3">
                              <strong>Product Item Details</strong>
                            </label>
                            <div className="row">
                              <div className="col-4">
                                <div className="form-row">
                                  {this.rTB("Weight", "prWeight", "col-6")}
                                  {this.rTB(
                                    "Packed Boxes",
                                    "prPieces",
                                    "col-6"
                                  )}
                                </div>
                              </div>
                              <div className="col-8">
                                {_.times(this.state.data.prPieces || 1, (i) => (
                                  <span key={i}>
                                    <div className="form-row">
                                      {this.rTB(
                                        "L-" + (i + 1),
                                        "length" + i,
                                        "col-4"
                                      )}
                                      {this.rTB(
                                        "B-" + (i + 1),
                                        "breadth" + i,
                                        "col-4"
                                      )}
                                      {this.rTB(
                                        "H-" + (i + 1),
                                        "height" + i,
                                        "col-4"
                                      )}
                                    </div>
                                  </span>
                                ))}
                              </div>
                            </div>
                          </div>

                          <div className="card-body border-top">
                            <div className="row">
                              <div className="col-7">
                                <label className="mb-3 mt-3">
                                  <strong> Shipment Details </strong>
                                </label>
                              </div>
                              <div className="col-5">
                                {this.rSB("", "shippingHub", this.state.hubs, {
                                  value: "hubId",
                                  label: "hub",
                                })}
                              </div>
                            </div>

                            <div className="form-row">
                              {this.rTB("Area Code", "shArea", "col-2")}
                              {this.rTB("Client Name", "shClientName", "col-6")}
                              {this.rTB("Pincode", "shPincode", "col-4")}
                            </div>
                            <div className="form-row">
                              {this.rTB("Address 1", "shAdd1", "col-4")}
                              {this.rTB("Address 2", "shAdd2", "col-4")}
                              {this.rTB("Address 3", "shAdd3", "col-4")}
                            </div>
                            <div className="form-row">
                              {this.rTB("Mobile", "shMob", "col-4")}
                              {this.rTB("Phone", "shPhone", "col-4")}
                              {this.rTB("Email", "shEmail", "col-4")}
                            </div>
                          </div>
                          <div className="card-body border-top">
                            <div className="row">
                              <div className="col-7">
                                <label className="mb-3 mt-3">
                                  <strong> Consignee Details </strong>
                                </label>
                              </div>
                              <div className="col-5">
                                {this.rSB("", "consigneeHub", this.state.hubs, {
                                  value: "hubId",
                                  label: "hub",
                                })}
                              </div>
                            </div>

                            <div className="form-row">
                              {this.rTB(
                                "Consignee Name",
                                "cnConsiName",
                                "col-8"
                              )}
                              {this.rTB("Pincode", "cnPincode", "col-4")}
                            </div>
                            <div className="form-row">
                              {this.rTB("Address 1", "cnAdd1", "col-4")}
                              {this.rTB("Address 2", "cnAdd2", "col-4")}
                              {this.rTB("Address 3", "cnAdd3", "col-4")}
                            </div>
                            <div className="form-row">
                              {this.rTB("Mobile", "cnMob", "col-4")}
                              {this.rTB("Phone", "cnPhone", "col-4")}
                              {this.rTB("Email", "cnEmail", "col-4")}
                            </div>
                          </div>

                          <div className="card-body" style={{ marginTop: -25 }}>
                            {(this.state.data.status === "open" ||
                              this.state.data.status === "") &&
                              this.rBtnS(
                                "Save",
                                "save",
                                " btn-success  btn-sm"
                              )}

                            {this.state.data.status === "open" &&
                              this.state.data.bdShipmentsId !== "" &&
                              this.rBtnS(
                                "Book",
                                "book",
                                " btn-primary  btn-sm"
                              )}
                            {this.state.data.status === "inTransit" &&
                              this.rBtnS(
                                "Close",
                                "close",
                                " btn-success  btn-sm"
                              )}

                            {this.state.data.status === "open" &&
                              this.state.data.bdShipmentsId !== "" &&
                              this.rBtnS(
                                "Reject",
                                "reject",
                                " btn-danger  btn-sm center-block"
                              )}
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              PDF
                            </button>

                            {this.state.mode === "open" &&
                              this.rBtnS(
                                "Reset",
                                "reset",
                                " btn-warning  btn-sm pull-right"
                              )}
                          </div>

                          <div className="card-body">
                            <div
                              className="form-subHead"
                              data-toggle="collapse"
                              href="#setting"
                              aria-expanded="false"
                              aria-controls="setting"
                            >
                              Standard Settings
                              <i
                                className="fa fa-angle-down fa-1x text-danger ml-1 pull-right"
                                aria-hidden="true"
                              ></i>
                            </div>
                            {
                              <div className="collapse" id="setting">
                                <br />
                                <br />
                                <div className="form-row">
                                  {this.rTB("Product Code", "prCode", "col-4")}
                                  {this.rTB(
                                    "Sub Product",
                                    "prSubProd",
                                    "col-4"
                                  )}
                                  {this.rSB(
                                    "PDF Label Size",
                                    "pdfLb",
                                    this.state.stdSettings.copy,
                                    { value: "value", label: "label" },
                                    "col-4"
                                  )}
                                </div>
                                <div className="form-row">
                                  {this.rRBL(
                                    "Dedicated Delivery Network",
                                    "delNet",
                                    this.state.stdSettings.delNet,
                                    { value: "value", label: "label" },
                                    "col-4"
                                  )}
                                  {this.rRBL(
                                    "Register Pickup",
                                    "pickUp",
                                    this.state.stdSettings.pickUp,
                                    { value: "value", label: "label" },
                                    "col-4"
                                  )}
                                  {this.rRBL(
                                    "Product Type",
                                    "proType",
                                    this.state.stdSettings.proType,
                                    { value: "value", label: "label" },
                                    "col-4"
                                  )}
                                </div>
                                <div className="form-row">
                                  {this.rRBL(
                                    "Force PU",
                                    "forcePu",
                                    this.state.stdSettings.forcePu,
                                    { value: "value", label: "label" },
                                    "col-4"
                                  )}
                                  {this.rRBL(
                                    "Is isRvp",
                                    "isRvp",
                                    this.state.stdSettings.isRvp,
                                    { value: "value", label: "label" },
                                    "col-4"
                                  )}
                                  {this.rRBL(
                                    "wayBill PDF File",
                                    "wayBill",
                                    this.state.stdSettings.wayBill,
                                    { value: "value", label: "label" },
                                    "col-4"
                                  )}
                                </div>
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="card-body ">
                  <label>
                    <strong>Response</strong>
                  </label>
                  <div className="form-row">
                    {this.rTB("Token#", "token", "col-4")}
                    {this.rTB("AWB No", "awb", "col-4")}
                    {this.rTB("Dest Scrcd", "scrcd", "col-4")}
                  </div>
                  <div className="form-row">
                    {this.rTB("Req PU", "reqPU", "col-4")}
                    {this.rTB("Dest Area", "destArea", "col-4")}
                    {this.rTB("PU Dt", "puDt", "col-4")}
                  </div>
                </div>
              </React.Fragment>
            </div>
          </div>
        </div>
        {/*--model start--*/}
        <div className="modal fade" id="myModal" role="dialog">
          <div className="modal-dialog ">
            <div className="modal-content" style={{ width: 750 }}>
              <div className="modal-header">
                <h4 className="modal-title">PDF Format</h4>
                <button type="button" class="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="container" id="print" style={{ padding: 20 }}>
                  <div class="row border-one">
                    <div className="col-lg-5 col-sm-5 col-md-5">
                      <div>
                        <img
                          src={blue_dart_logo}
                          class="img-fluid"
                          alt="blue dart"
                        />
                      </div>
                      <h3>DART APEX (Prepaid)</h3>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-4 border-two">
                      <img
                        src={bizlog_logo}
                        className="img-fluid"
                        alt="BIZLOG"
                      />
                    </div>
                    <div className="col-lg-4 col-sm-4 col-md-4 border-two">
                      Origin: BOM /SPZ
                      <br />
                      Destination: DEL /OKD
                    </div>
                  </div>
                  <div class="row border-one">
                    <div className="col-lg-6 col-sm-6 col-md-6">
                      <table>
                        <tr>
                          <td>Shipper</td>
                          <td>:</td>
                          <td> 119792 / Mahesh</td>
                        </tr>
                        <tr>
                          <td>Sender</td>
                          <td>:</td>
                          <td>Manoj</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>:</td>
                          <td>
                            Office number 24, 1st Floor Navyug Ind Est, JB Nagar
                            Andheri East, Mumbai
                          </td>
                        </tr>
                        <tr>
                          <td>Pincode</td>
                          <td>:</td>
                          <td>400059</td>
                        </tr>
                        <tr>
                          <td>Tel/Mob</td>
                          <td>:</td>
                          <td> 9930444735</td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-md-6 border-two">
                      <table>
                        <tr>
                          <td>TO</td>
                          <td>:</td>
                          <td> 119792 / Mahesh</td>
                        </tr>
                        <tr>
                          <td>Attention</td>
                          <td>:</td>
                          <td>Manoj</td>
                        </tr>
                        <tr>
                          <td>Address</td>
                          <td>:</td>
                          <td>Head office Bizloag Bangalore-1</td>
                        </tr>
                        <tr>
                          <td>Pincode</td>
                          <td>:</td>
                          <td>567890</td>
                        </tr>
                        <tr>
                          <td>Tel/Mob</td>
                          <td>:</td>
                          <td> 234567</td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div className="row border-one">
                    <div className="col-lg-3 col-sm-3 col-md-3">
                      <table>
                        <tr>
                          <td>Pickup Date</td>
                          <td>:</td>
                          <td> 08-10-2021</td>
                        </tr>
                        <tr>
                          <td>Time</td>
                          <td>:</td>
                          <td>10.00AM</td>
                        </tr>
                        <tr>
                          <td>Emp No</td>
                          <td>:</td>
                          <td>2030</td>
                        </tr>
                        <tr>
                          <td>Sign</td>
                          <td>:</td>
                          <td>____________</td>
                        </tr>
                        <tr>
                          <td>PUR</td>
                          <td>:</td>
                          <td> 234567</td>
                        </tr>
                        <tr>
                          <td>Pack Type</td>
                          <td>:</td>
                          <td>Box</td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-md-6 border-two">
                      <div>
                        <img
                          src={barcode}
                          class="img-fluid"
                          alt="BIZLOG BARCODE"
                        />
                      </div>
                      <h2 className="text-center">89250217291</h2>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 border-two">
                      <table>
                        <tr>
                          <td>Dox/NonDox</td>
                          <td>:</td>
                          <td> IBM-323-21</td>
                        </tr>
                        <tr>
                          <td>No.of Pkg</td>
                          <td>:</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>Act Wt(kg)</td>
                          <td>:</td>
                          <td>20</td>
                        </tr>
                        <tr>
                          <td>Dim Wt(kg)</td>
                          <td>:</td>
                          <td>25</td>
                        </tr>
                        <tr>
                          <td>Chg Wt(kg)</td>
                          <td>:</td>
                          <td>00</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="row border-one">
                    <div className="col-lg-3 col-sm-3 col-md-3">
                      <table>
                        <tr>
                          <td>Transaction Code:</td>
                          <td> 08-10-2021</td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-md-6 border-two">
                      <table>
                        <tr>
                          <td>Ref # </td>
                          <td>:</td>
                          <td> IBM-323-21</td>
                        </tr>
                        <tr>
                          <td>Spl Inst</td>
                          <td>:</td>
                          <td>5</td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-lg-3 col-sm-3 col-md-3 border-two">
                      <table>
                        <tr>
                          <td>Declared Value</td>
                          <td>:</td>
                          <td> 20000.00</td>
                        </tr>
                        <tr>
                          <td>Collectable Amt</td>
                          <td>:</td>
                          <td>5</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div className="row border-one">
                    <div className="col-lg-6 col-sm-6 col-md-6">
                      Commodity:
                      <br />
                    </div>
                  </div>
                  <div className="row border-one">
                    <div className="col-lg-6 col-sm-6 col-md-6">
                      Delivery Details:
                      <br />
                    </div>
                  </div>
                  <div className="row border-one">
                    <div className="col-lg-6 col-sm-6 col-md-6">
                      <table>
                        <tr>
                          <td>Delivery Date</td>
                          <td>:</td>
                          <td>30-10-2021</td>
                        </tr>
                        <tr>
                          <td>Time</td>
                          <td>:</td>
                          <td>5PM</td>
                        </tr>
                        <tr>
                          <td>Name</td>
                          <td>:</td>
                          <td></td>
                        </tr>
                      </table>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-md-6">
                      Consignee Signature:
                    </div>
                  </div>
                  <div className="row">
                    <div>
                      Staple it along with the shipment so that the barcode
                      portion of the document is facing outward multiple printed
                      copied (2) of this document are required for shipping.
                      <br />
                      Warning: use only the original printouts for shipping to
                      ensure the timely delivery of your package
                      <br />
                      Terms and condition: Please refer our website :-
                      www.Bizlog.In/conditions.Html
                    </div>
                  </div>
                  {/*print End*/}
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    className="btn btn-success"
                    //data-dismiss="modal"
                    onClick={this.generatePDF}
                  >
                    Download
                  </button>
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*--model end--*/}
      </React.Fragment>
    );
  }
}
export default BDWrapper;
