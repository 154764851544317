import React from "react";
import ZTitle from "./../../_subhead/ZTitle";
import FormLib from "../../_common/FormLib";
import toast from "../../../services/_toastService";
import Joi from "joi-browser";
import { getMISDocketUpdate } from "../../../services/Tickets/TicketServices";
import Table from "./../../_common/Table";
import { dropMenuFull } from "./../../../utils/DropMenuUtils";
import { getTeams } from "./../../../services/players/teamServices";
import { ExportXLSX } from "./../requestV1/ExportXLSX";
import {
  epoch2ddmmyyyyhhmmss,
  tDate2yyyymmddhhmmss,
} from "../../../utils/DateUtils";
import MISDocketList from "./MISDocketList";
import dateFormat from "dateformat";

class MisDocketUpdate extends FormLib {
  state = {
    data: {
      //   startDate: this.getDatebyDays(0),
      //   endDate: this.getDatebyDays(0),
      //   hubId: "",
      retailerId: "",
      conComplaintNo: "",
      status: "Part Received",
      // region: "",
    },
    errors: {},
    regions: [],
    zones: [],
    hubs: [],
    clients: [],
    report: [],
    cusMisTicket: {},
    csvReport: {
      data: [],
      headers: [],
    },
    userCode: "",
    userInfoTeamId: "",
  };

  schema = {
    retailerId: Joi.optional().label("retailerId"),
    conComplaintNo: Joi.optional().label("conComplaintNo"),
    status: Joi.optional().label("Status"),
  };

  handleOnDocket(r) {
    this.setState({ cusMisTicket: r });
    // console.log("handleOnDocket.........."+JSON.stringify(this.state.cusMisTicket['orderNo']));
  }

  handleView = (r) => {
    this.setState({ curCountry: r, formMode: "view" });
  };

  excelHeaders = [
    { key: "ticketNo", label: "ticketNo" },
    { key: "conComplaintNo", label: "conComplaintNo" },
    { key: "blockStatus", label: "currentStatus" },
    { key: "docketNo", label: "docketNo" },
    { key: "retailerName", label: "retailerName" },
    { key: "pickupTime", label: "pickUpTime" },
    { key: "dropTime", label: "dropTime" },
    { key: "closedRemarks", label: "closedRemarks" },
    { key: "productCode", label: "productCode" },
    { key: "brand", label: "brand" },
    { key: "model", label: "model" },
    { key: "identificationNo", label: "identificationNo" },
    { key: "productDesc", label: "productDesc" },
    { key: "srcContactPerson", label: "srcContactPerson" },
    { key: "srcAdd1", label: "srcAdd1" },
    { key: "srcContact1", label: "srcContact1" },
    { key: "srcCity", label: "srcCity" },
    { key: "srcPincode", label: "srcPincode" },
    { key: "dstContactPerson", label: "dstContactPerson" },
    { key: "dstAdd1", label: "dstAdd1" },
    { key: "dstContact1", label: "dstContact1" },
    { key: "dstCity", label: "dstCity" },
    { key: "dstPincode", label: "dstPincode" },
    { key: "hubId", label: "hubId" },
    { key: "srcHubId", label: "srcHubId" },
    { key: "dstHubId", label: "dstHubId" },
    { key: "created_by", label: "createdBy" },
    { key: "created_at", label: "created_at" },
    { key: "updated_by", label: "updatedBy" },
    { key: "updated_at", label: "updatedAt" },
  ];

  tableMeta = {
    key: "misTicketId" + 1,
    columns: [
      {
        key: "COUNTRY_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnDocket(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Update
                Docket
              </button>
              <a
                className="dropdown-item"
                type="button"
                target="_blank"
                href={"https://test.bizlog.in/#/task/" + r.processId + "/view"}
              >
                <i className="fa fa-binoculars" aria-hidden="true"></i> More
                Details
              </a>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },

      {
        key: "ticketNo",
        action: (r) => <b>{r.ticketNo}</b>,
        icon: () => <>Ticket No</>,
      },
      { path: "conComplaintNo", label: "conComplaintNo" },
      {
        key: "blockStatus",
        action: (r) => <b>{this.replaceString(r.blockStatus)}</b>,
        cusClass: "",
        icon: () => <>Current Status</>,
      },
      {
        key: "pickupTime",
        action: (r) => <>{epoch2ddmmyyyyhhmmss(r.pickupTime)}</>,
        icon: () => <>Pickup Date&Time</>,
      },
      { path: "pickupFeName", label: "pickupFeName" },
      {
        key: "dropTime",
        action: (r) => <>{epoch2ddmmyyyyhhmmss(r.dropTime)}</>,
        icon: () => <>drop Date&Time</>,
      },
      { path: "dropFeName", label: "dropFeName" },

      { path: "retailerName", label: "retailerName" },
      { path: "productCode", label: "productCode" },
      { path: "docketNo", label: "docketNo" },
      { path: "brand", label: "brand" },
      { path: "model", label: "model" },
      { path: "identificationNo", label: "identificationNo" },
      { path: "productDesc", label: "productDesc" },
      { path: "srcContactPerson", label: "srcContactPerson" },
      { path: "srcAdd1", label: "srcAdd1" },
      { path: "srcContact1", label: "srcContact1" },
      { path: "srcCity", label: "srcCity" },
      { path: "srcPincode", label: "srcPincode" },
      { path: "dstContactPerson", label: "dstContactPerson" },
      { path: "dstAdd1", label: "dstAdd1" },
      { path: "dstContact1", label: "dstContact1" },
      { path: "dstCity", label: "dstCity" },
      { path: "dstPincode", label: "dstPincode" },
      { path: "hubId", label: "hubId" },
      { path: "srcHubId", label: "srcHubId" },
      { path: "dstHubId", label: "dstHubId" },
      { path: "closedRemarks", label: "closedRemarks" },
      { path: "created_by", label: "createdBy" },
      { path: "created_at", label: "created_at" },
      { path: "updated_by", label: "updatedBy" },
      { path: "updated_at", label: "updated_at" },

      // { path: "dropFeType", label: "dropFeType" },
      // { path: "dropFeId", label: "dropFeId" },
      // { path: "dropFeName", label: "dropFeName" },
      // { path: "dropRemarks", label: "dropRemarks" },
      // { path: "closedDate", label: "closedDate" },
      // { path: "closedTime", label: "closedTime" },
      // { path: "closedBy", label: "closedBy" },
      // { path: "closedById", label: "closedById" },
      // { path: "closedByName", label: "closedByName" },
      // { path: "vehicleNo", label: "vehicleNo" },
      // { path: "weight", label: "weight" },
      // { path: "orderNo", label: "orderNo" },
      // { path: "invoiceNo", label: "invoiceNo" },
      // { path: "ticketBarcode", label: "ticketBarcode" },
      // { path: "parentTicket", label: "parentTicket" },
      // { path: "ticketDetails", label: "ticketDetails" },
      // { path: "specialInstructions", label: "specialInstructions" },
      // {
      //   key: "dateOfTicket",
      //   action: (r) => <>{dateFormat(r.dateOfTicket, "dd-mm-yyyy")}</>,
      //   cusClass: "",
      //   icon: () => <>dateOfTicket</>,
      // },
      // { path: "primaryDetails", label: "primaryDetails" },
      // { path: "ticketProductId", label: "ticketProductId" },
      // { path: "productTypeId", label: "productTypeId" },
      // { path: "productCat", label: "productCat" },

      // { path: "problemDesc", label: "problemDesc" },
      // { path: "productValue", label: "productValue" },
      // { path: "cost", label: "cost" },
      // { path: "isUnderWarranty", label: "isUnderWarranty" },
      // { path: "productDetails", label: "productDetails" },
      // { path: "contact1", label: "contact1" },
      // { path: "contact2", label: "contact2" },
      // { path: "srcAddressId", label: "srcAddressId" },

      // { path: "dstLandmark", label: "dstLandmark" },
      // { path: "dstEmailId", label: "dstEmailId" },
      // { path: "dstEmaiIdAlt", label: "dstEmaiIdAlt" },
      // { path: "pdstEmailIdAlt", label: "pdstEmailIdAlt" },
      // { path: "pdstContactPerson", label: "pdstContactPerson" },
      // { path: "ticketPriority", label: "ticketPriority" },
      // { path: "psrcContactPerson", label: "psrcContactPerson" },
      // { path: "psrcAddressId", label: "psrcAddressId" },
      // { path: "psrcState", label: "psrcState" },
      // { path: "pdstLandmark", label: "pdstLandmark" },
      // { path: "psrcAdd2", label: "psrcAdd2" },
      // { path: "pdstAdd1", label: "pdstAdd1" },
      // { path: "psrcCity", label: "psrcCity" },
      // { path: "pdstContact1", label: "pdstContact1" },
      // { path: "psrcPincode", label: "psrcPincode" },
      // { path: "pdstAddressId", label: "pdstAddressId" },
      // { path: "psrcAdd1", label: "psrcAdd1" },
      // { path: "psrcLandmark", label: "psrcLandmark" },
      // { path: "psrcLocation", label: "psrcLocation" },
      // { path: "pdstLocation", label: "pdstLocation" },
      // { path: "pdstContact2", label: "pdstContact2" },
      // { path: "pdstCity", label: "pdstCity" },
      // { path: "pdstPincode", label: "pdstPincode" },
      // { path: "psrcEmailAlt", label: "psrcEmailAlt" },
      // { path: "pdstEmailId", label: "pdstEmailId" },
      // { path: "psrcEmailId", label: "psrcEmailId" },
      // { path: "pdstAdd2", label: "pdstAdd2" },
      // { path: "pdstIsHub", label: "pdstIsHub" },
      // { path: "psrcIsHub", label: "psrcIsHub" },
      // { path: "pdstState", label: "pdstState" },
      // { path: "pdstState", label: "pdstState" },
    ],
  };

  replaceString = (str) => {
    return str.replace(/_/g, " ");
  };
  componentDidMount() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.state.userCode = userInfo.code;
    this.state.userInfoTeamId = userInfo.teamId;

    this.loadRetailers();
  }
  async loadRetailers() {
    const res = await getTeams();
    if (res !== undefined) {
      if (res.data.success === true) {
        this.setState({ clients: res.data.data.teams });
      } else {
        toast.toastError(res.data.msg);
      }
    }
  }

  async doSubmit() {
    this.loadTickets();
  }

  loadTickets = async () => {
    let res;
    this.setState({ loading: true });
    let data = this.state.data;
    if (this.state.userCode !== "BIZ") {
      data["retailerId"] = this.state.userInfoTeamId;
      this.setState({
        data,
      });
    }
    res = await getMISDocketUpdate(this.state.data);

    if (res.data.success) {
      if (res.data.data.reportsList.length !== 0) {
        toast.toastSuccess(res.data.msg);
        this.setState({ report: res.data.data.reportsList });
      } else {
        toast.toastWarn("No Data");
      }
      // this.loadCountry();
    } else {
      toast.toastError(res.data.msg);
    }
    this.setState({ loading: false });

    // this.setState({ report: res.data.data.reportsList });
    // console.log("submit.........."+JSON.stringify(this.state.data));

    // ///Excel
    this.formatMISReport(this.state.report);

    let apiData = {};
    const { csvReport } = { ...this.state };
    apiData = this.state.report;

    csvReport.filename = "financial_report.xlsx";
    csvReport.headers = this.excelHeaders;

    let transData = [];
    transData = apiData;
    this.setState({ csvReport });

    if (apiData) {
      const promises = apiData;

      transData = await Promise.all(promises);
      csvReport.data = transData;
      this.setState({ csvReport });
    } else {
      csvReport.data = [];
      this.setState({ csvReport });
    }
    ///Excel
  };

  formatMISReport = async (r) => {
    let formatedMIS = await Promise.all(
      r.filter(async (t) => {
        // let t = { ...trx };
        delete t.misTicketId;
        delete t.actionStatus;
        // delete t.processId;
        delete t.ticketPriority;
        delete t.flowId;
        delete t.retailerId;
        delete t.retailerNo;
        // delete t.orderNo;
        delete t.invoiceNo;
        delete t.parentTicket;
        delete t.ticketDetails;
        delete t.dateOfTicket;
        delete t.isAppointmentReq;
        delete t.isInstallationReq;
        delete t.isPhysicalEval;
        delete t.isTechEval;
        delete t.isPackingReq;
        // delete t.orderNo;
        delete t.invoiceNo;
        delete t.parentTicket;
        delete t.ticketDetails;
        delete t.specialInstructions;
        delete t.dateOfTicket;
        delete t.isAppointmentReq;
        delete t.isInstallationReq;
        delete t.isPhysicalEval;
        delete t.isTechEval;
        delete t.isPackingReq;
        delete t.primaryDetails;
        delete t.flowConfig;
        delete t.flowName;
        // delete t.retailerName;
        delete t.retailerCode;
        delete t.ticketBarcode;
        delete t.misBlockId;
        // delete t.ticketBlockId;
        delete t.blockCode;
        delete t.syncStatus;
        delete t.blockBarcode;
        delete t.pickupDate;
        delete t.pickupFeType;
        delete t.pickupFeId;
        delete t.pickupFeName;
        delete t.pickupRemarks;
        delete t.dropFeId;
        delete t.dropDate;
        delete t.dropFeType;
        delete t.dropFeName;
        delete t.dropRemarks;
        delete t.closedDate;
        delete t.closedTime;
        delete t.closedBy;
        delete t.closedById;
        delete t.closedByName;
        delete t.closedStatus;
        delete t.vehicleNo;
        delete t.weight;
        delete t.playerFeId;
        delete t.playerFeType;
        delete t.feAppointmentDateTime;
        delete t.misProdId;
        delete t.ticketProductId;
        delete t.prdBarcode;
        delete t.prodStatus;
        delete t.productCodeR;
        delete t.productR;
        delete t.brandCode;
        delete t.brandCodeR;
        delete t.brandR;
        delete t.modelCode;
        delete t.modelCodeR;
        delete t.modelR;
        delete t.productId;
        delete t.brandId;
        delete t.modelId;
        delete t.productName;
        delete t.dateOfPurchase;
        delete t.problemDesc;
        delete t.productValue;
        delete t.cost;
        delete t.isUnderWarranty;
        delete t.productDetails;
        delete t.srcAddressId;
        delete t.srcAdd2;
        delete t.srcLocation;
        delete t.srcContact2;
        delete t.srcState;
        delete t.srcLandmark;
        delete t.srcEmailId;
        delete t.srcEmailIdAlt;
        delete t.srcType;
        delete t.dstAddressId;
        delete t.dstLocation;
        delete t.dstAdd2;
        delete t.dstContact2;
        delete t.dstState;
        delete t.dstLandmark;
        delete t.dstEmailId;
        delete t.dstEmailIdAlt;
        delete t.dstType;
        delete t.dstEmailId;

        t.created_at = await epoch2ddmmyyyyhhmmss(t.created_at);
        t.updated_at = await epoch2ddmmyyyyhhmmss(t.updated_at);
        t.pickupTime = await epoch2ddmmyyyyhhmmss(t.pickupTime);
        t.dropTime = await epoch2ddmmyyyyhhmmss(t.dropTime);

        return t;
      })
    );

    this.setState({ formatMIS: formatedMIS });
  };
  localHandleChange = (e) => {
    if (e.target.name === "retailerId") {
      const { value } = e.target;
      let data = this.state.data;
      let retailer = this.state;

      // find the clients with the current selected value
      const clients = retailer.clients.find((c) => c.name === value);

      if (clients !== undefined) {
        data["retailerId"] = clients.teamId;
        this.setState({
          data,
        });
      }
      this.validateProperty(e.target.name, e.target.value);
    }
  };

  status = [
    { sortKey: "PART_RECEIVED", sort: "Part Received" },
    { sortKey: "PART_MISSING", sort: "Part Missing" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  render() {
    const reportJSON = this.state.report;
    return (
      <>
        <ZTitle subHeading="Report  :: MIS Docket Update" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-9">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={this.handleSubmit}>
                    <div className="form-row mt-2">
                      {this.rSB(
                        "Status",
                        "status",
                        this.status,
                        this.stsConf,
                        " col-md-2 "
                      )}
                      {/* {this.rDPF("Start Date *", "startDate", " col-md-3 ")}
                  {this.rDPF("End Date *", "endDate", " col-md-3 ")} */}
                      {/* {this.rSB(
                    "Region",
                    "region",
                    this.state.regions,
                    { value: "regionId", label: "region" },
                    " col-md-3 "
                  )}
                  {this.rSB(
                    "Zone",
                    "zone",
                    this.state.zones,
                    { value: "zoneId", label: "zone" },
                    " col-md-3 "
                  )} */}
                      {/* {this.rSB(
                    "Hub",
                    "hubId",
                    this.state.hubs,
                    { value: "hubId", label: "hub" },
                    " col-md-3 "
                  )} */}
                      {this.state.userCode === "BIZ" && (
                        <>
                          {this.rSBF(
                            "Client",
                            "retailerId",
                            this.state.clients,
                            { value: "teamId", label: "name" },
                            " col-md-3 "
                          )}
                        </>
                      )}
                      {this.rTB(
                        "Consumer Complaint Number",
                        "conComplaintNo",
                        "col-md-3"
                      )}
                    </div>
                    <div className="col-9">
                      {this.rBtnSP("Submit", "Submit")}
                    </div>
                  </form>
                </div>
                {reportJSON.length > 0 && (
                  <div className="form-row">
                    {this.props.loading && (
                      <div className="container-fluid">
                        <div> Loading Tickets...</div>
                      </div>
                    )}
                    {!this.props.loading && (
                      <>
                        <div className="container-fluid">
                          <div className="fixTableHead">
                            <Table
                              clsName="table table-striped table-bordered table-hover"
                              tableMeta={this.tableMeta}
                              data={reportJSON}
                              dropMenu={dropMenuFull()}
                            />
                          </div>
                        </div>
                        {/* <ExportXLSX
                      csvData={this.state.formatMIS}
                      fileName={
                        "MIS - [" +
                        this.state.data.startDate +
                        "-" +
                        this.state.data.endDate +
                        "]"
                      }
                    /> */}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-3">
              {this.state.cusMisTicket["processId"] && (
                <MISDocketList
                  curMisTicket={this.state.cusMisTicket}
                  key={this.state.cusMisTicket["processId"]}
                  handleLoadTickets={this.loadTickets}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MisDocketUpdate;
