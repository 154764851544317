import _http from "../_httpService";

import config from "../../config.json";

const apiURLCorePHP = config[config.build].apiURLCorePHP;

const apiDevEndPoint = config[config.build].apiURLMasterLoc;

function buildUrl(path) {
  return `${apiURLCorePHP}/${path}`;
}

export function getCountriesAll() {
  return _http.get(buildUrl("country/listAll"), _http.headerJWT());
}

export function getStatesAll(countryId) {
  return _http.get(buildUrl("state/listAll/" + countryId), _http.headerJWT());
}

export function getCitiesAll(stateId) {
  return _http.get(buildUrl("city/listAll/" + stateId), _http.headerJWT());
}

export function getCities() {
  return _http.get(
    apiDevEndPoint + "/cities",
    _http.headerJWT()
  );
}

export function getRegions() {
  return _http.get(
    apiDevEndPoint + "/regions",
    _http.headerJWT()
  );
}

export function getZones() {
  return _http.get(
    apiDevEndPoint + "/zones",
    _http.headerJWT()
  );
}
