import _http from "../_httpService";

import config from "../../config.json";

const apiURLInventory = config[config.build].apiURLInventory;
const apiURLPrevilages = config[config.build].apiURLPrevilages;
function api(path, subURL = "") {
  path = path !== "" ? "/" + path : "";
  return `${apiURLInventory}${subURL}${path}`;
}

export function getActiveClients(status) {
  const apiEndpoint = `${apiURLInventory}/getClients/${status}`;
  // console.log(apiEndpoint);
  return _http.get(apiEndpoint, _http.headerJWT());
}

export function getActiveCategoryList(status) {
  const apiEndpoint = `${apiURLInventory}/getActiveItemCategory/${status}`;
  return _http.get(apiEndpoint, _http.headerJWT());
}

export function getSubCategoryList(catId1) {
  const apiEndpoint = `${apiURLInventory}/getSubCatbyId/${catId1}`;
  return _http.get(apiEndpoint, _http.headerJWT());
}

export function getUOM(status) {
  const apiEndpoint = `${apiURLInventory}/getActiveUom/${status}`;
  return _http.get(apiEndpoint, _http.headerJWT());
}

export function createInvItems(req) {
  const param = { ...req };
  // console.log({ param });
  return _http.post(`${apiURLInventory}/saveInvItem`, param, _http.headerJWT());
}

export function getItemsList() {
  return _http.get(`${apiURLInventory}/getAllInvItemListV1`, _http.headerJWT());
}
export function getItemsListV1(pageNo) {
  return _http.get(`${apiURLInventory}/getAllInvItemList/50/${pageNo}`, _http.headerJWT());
}

export function deleteItems(itemId) {
  // console.log(" =======================> "+JSON.stringify(itemId));
  return _http.delete(`${apiURLInventory}/${itemId}`, _http.headerJWT());
}

export function getSearchItems(params) {
  try {
    // console.log({ params });
    return _http.put(
      `${apiURLInventory}/getInvItemMasterData`,
      params,
      _http.headerJWT()
    );
  } catch (error) {
    return error;
  }
}
export function getSearchItemsV1(params,pageNo) {
  try {
    // console.log({ params });
    return _http.put(
      `${apiURLInventory}/getInvItemMasterDataV1/50/${pageNo}`,
      params,
      _http.headerJWT()
    );
  } catch (error) {
    return error;
  }
}
export function updateItemsData(param) {
  // console.log(" =======================> "+JSON.stringify(itemId));
  try {
    return _http.put(
      `${apiURLInventory}/updateInvItemsV1`,
      param,
      _http.headerJWT()
    );
  } catch (error) {
    return "error while fetching an update api";
  }
}

export function getBarcodeData(params) {
  // alert("params........." + JSON.stringify(params));
  try {
    return _http.put(
      `${apiURLInventory}/getBarcodeSlNumbers`,
      params,
      _http.headerJWT()
    );
  } catch (error) {
    return "error in getBarcode Details api";
  }
}
export function getBarcodeDataV1(params,pageNo) {
  // alert("params........." + JSON.stringify(params));
  try {
    return _http.put(
      `${apiURLInventory}/getBarcodeSlNumbersV2/50/${pageNo}`,
      params,
      _http.headerJWT()
    );
  } catch (error) {
    return "error in getBarcode Details api pageNo";
  }
}

export function getUsers(hubId) {
  // console.log({ hubId });
  try {
    return _http.get(
      `${apiURLPrevilages}/players-hubs/players-list/${hubId}`,
      _http.headerJWT()
    );
  } catch (error) {
    return error.message;
  }
}

export function assignUser(stateData, formData, userId) {
  let param = {
    itemId: formData.itemId,
    hubId: stateData.hubId,
    userId: userId,
    slNoId: formData.slNoId,
    remarks: "testing",
  };
  try {
    return _http.put(
      `${apiURLInventory}/assignItemsForUser`,
      param,
      _http.headerJWT()
    );
  } catch (error) {
    return "error while fetching an update api";
  }
}
export function assignFreshBarcodesToTheUser(stateData, formData, userId) {
  let param = {
    userId: userId,
    hubId: stateData.hubId,
    slNoIds: [formData.slNoId],
    remarks: "testing",
  };
  try {
    return _http.put(
      `${apiURLInventory}/assignBarcodesForUser`,
      param,
      _http.headerJWT()
    );
  } catch (error) {
    return "error while fetching an update api";
  }
}

export function getAssignedProduct(params) {
  try {
    return _http.put(
      `${apiURLInventory}/getAssignedProductsToUser`,
      params,
      _http.headerJWT()
    );
  } catch (error) {
    return "failed to fetch api";
  }
};
export function getAssignedProductV1(params,pageNo) {
  try {
    return _http.put(
      `${apiURLInventory}/getBarcodesAssignedToFe/10/${pageNo}`,
      params,
      _http.headerJWT()
    );
  } catch (error) {
    return "failed to fetch api";
  }
};
export function getFreshBarcodes(params,pageNo) {
  try {
    return _http.put(
      `${apiURLInventory}/getBarcodesAvailableForAssignment/50/${pageNo}`,
      params,
      _http.headerJWT()
    );
  } catch (error) {
    return "failed to fetch freshBarcodes api";
  }
};

export function revertAssignedProduct(params) {
 
  try {
    return _http.put(
      `${apiURLInventory}/revertAssignedBarcodeItemsFromUser`,
      params,
      _http.headerJWT()
    );
  } catch (error) {
    return "failed to fetch an api";
  }
};
export function revertFreshBarcodes(params) {
 
  try {
    return _http.put(
      `${apiURLInventory}/revertAssignedBarcodesFromUser`,
      params,
      _http.headerJWT()
    );
  } catch (error) {
    return "failed to fetch an api";
  }
};


export function isDefaultStatus(params){

  try {
    return _http.put(
      `${apiURLInventory}/updateMakeDefault`,
      params,
      _http.headerJWT()
    );
  } catch (error) {
    return "failed to fetch an api";
  }
}

export function getCarriers(){
  try {
    return _http.get(
      `${apiURLInventory}/getCarriers`,
      _http.headerJWT()
    );
  } catch (error) {
    return "failed to fetch carrier api"
  }
}
