import _http from "../_httpService";

import config from "../../config.json";

const apiEndpoint = config[config.build].apiURLPHP + "/requestV1";

const apiPrevEndpoint = config[config.build].apiURLPrevilages;

const apiCoreEndpoint = config[config.build].apiURLPHP;

const apiLocEndpoint = config[config.build].apiURLMasterLoc;

const apiURLEExpenseV1 = config[config.build].apiURLEExpenseV1;

function buildUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getHubsActive() {
  try {
    return _http.get(apiURLEExpenseV1 + "/hubs", _http.headerJWT());
  } catch {
    return undefined;
  }
}

export function getHubs() {
  try {
    return _http.get(
      apiCoreEndpoint + "/requestV1/hubs.php",
      _http.headerJWT()
    );
  } catch {
    return undefined;
  }
}
export function getClients() {
  try {
    return _http.get(apiURLEExpenseV1 + "/clients", _http.headerJWT());
  } catch {
    return undefined;
  }
}
export function getActivity() {
  try {
    return _http.get(apiURLEExpenseV1 + "/activities", _http.headerJWT());
  } catch {
    return undefined;
  }
}
export function getFlows() {
  try {
    return _http.get(apiURLEExpenseV1 + "/flows", _http.headerJWT());
  } catch {
    return undefined;
  }
}
export function getApprover() {
  try {
    return _http.get(apiURLEExpenseV1 + "/approvers", _http.headerJWT());
  } catch {
    return undefined;
  }
}
export function getHub(id) {
  return _http.get(buildUrl(id), _http.headerJWT());
}

export function getRetailer() {
  return _http.get(apiPrevEndpoint + "/requestV1/teams", _http.headerJWT());
}
