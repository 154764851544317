import React, { Fragment, Component } from "react";
import { toast } from "react-toastify";
import FormLib from "./../../_common/FormLib";
import Table from "./../../_common/Table";
import Products from "./../products/Products";
import { IoBarcodeOutline, IoCopyOutline } from "react-icons/io5";
import BarCodePrintSVG from "./BarCodePrintSVG";
import { pickUpConfirm } from "./pickupConfirm";
// import TableHeader from "../../_common/TableHeader";
// import TableBody from "../../_common/TableBody";

import Tables from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHeader from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { packingtListReload } from "../../../store";
import {
  getPackingMaterialAndProdByJobId,
  getPackingMaterialAndProdByJobIdAdvExc,
  inwardInventoryForNegativeCases,
  verifyingPackingMaterialAndProduct,
  deattachingBarcodeFromItem,
  deattachingSlNoInwardingItem,
} from "./../../../services/Tickets/TicketProcessServices";
import { async } from "rxjs";
import swal from "sweetalert";
import { IstToUTC } from "../../../utils/DateUtils";

export default class PickUpVerificationProduct extends FormLib {
  state = {
    data: {},
    errors: {},
    curTicketDetails: [],
    curBlockDetails: [],
    curProductDetails: [],
    printBarcode: "",
    pickUpConfirmData: [],
    confirmData: false,
    count: 0,
    isOverride: false,
  };

  componentDidMount() {
    this.loadInit();
    this.loadTableData();
    this.loadPackingMaterialData();
    this.loadPackingMaterialData1();
  }

  loadTableData() {
    try {
      if (this.props.ticketDetails.flowId === "AdvanceExchange") {
        this.tableMeta.columns.push(this.advExcColumn);
      } else if (this.props.ticketDetails.flowId === "BuyBack") {
        this.tableMeta.columns = [
          ...this.tableMeta.columns,
          ...this.buyBackColumn,
        ];
      }
    } catch {
      console.log("Error in PickUpVerificationProduct>>>>>>loadTableData");
    }
  }

  loadInit = async () => {
    try {
      // console.log(
      //   "ticketDetails..........." +
      //     JSON.stringify(this.props.ticketDetails.flowId)
      // );
      const curTicketDetails = this.props.ticketDetails;
      const curBlockDetails = this.props.blockDetails;
      const curProductDetails = this.props.productDetails;
      this.setState({ curTicketDetails });
      this.setState({ curBlockDetails });
      this.setState({ curProductDetails });
    } catch {
      console.log("Error in PickUpVerificationProduct>>>>>>loadInit");
    }
  };

  loadPackingMaterialData = async () => {
    try {
      if (this.props.blockDetails.flowStatus === "DROP_WEB") {
        let blockData = this.props.blockDetails;
        let ticketData = this.props.ticketDetails;
        let res = await getPackingMaterialAndProdByJobIdAdvExc(
          blockData,
          ticketData
        );
        // console.log(res.data.data.packingMaterialDetails);
        if (res !== undefined) {
          if (res.data.success === true && res.data.data) {
            this.setState({
              pickUpConfirmData: res.data.data.packingMaterialDetails,
            });
            this.setState({
              itemStatus:
                res.data.data.packingMaterialDetails[0].productDetails[0]
                  .itemStatus,
            });
            // this.getPackingMaterialAndProdStatus(
            //   res.data.data.packingMaterialDetails
            // );
            this.setState({ confirmData: true });
          } else {
            this.setState({ confirmData: false });
          }
        } else {
          this.setState({ confirmData: false });
        }
      } else {
        let blockData = this.props.blockDetails;
        let ticketData = this.props.ticketDetails;
        let res = await getPackingMaterialAndProdByJobIdAdvExc(
          blockData,
          ticketData
        );
        // console.log(res.data.data.packingMaterialDetails);
        if (res !== undefined) {
          if (res.data.success === true && res.data.data) {
            this.setState({
              pickUpConfirmData: res.data.data.packingMaterialDetails,
            });
            this.setState({
              itemStatus:
                res.data.data.packingMaterialDetails[0].productDetails[0]
                  .itemStatus,
            });
            // this.getPackingMaterialAndProdStatus(
            //   res.data.data.packingMaterialDetails
            // );
            this.setState({ confirmData: true });
          } else {
            this.setState({ confirmData: false });
          }
        } else {
          this.setState({ confirmData: false });
        }
      }
    } catch {
      console.log(
        "Error in loadPackingMaterialData>>>>>>>>>>>>>>>>>>>>>>>>>>PickUpVerificationProduct"
      );
    }
  };
  loadPackingMaterialData1 = async () => {
    try {
      if (this.props.blockDetails.flowStatus === "DROP_WEB") {
        let blockData = this.props.blockDetails;
        let ticketData = this.props.ticketDetails;
        let res = await getPackingMaterialAndProdByJobIdAdvExc(
          blockData,
          ticketData
        );
        // console.log(res.data.data.packingMaterialDetails);
        if (res !== undefined) {
          if (res.data.success === true && res.data.data) {
            this.setState({
              pickUpConfirmData: res.data.data.packingMaterialDetails,
            });
            this.setState({
              itemStatus:
                res.data.data.packingMaterialDetails[0].productDetails[0]
                  .itemStatus,
            });
            // this.getPackingMaterialAndProdStatus(
            //   res.data.data.packingMaterialDetails
            // );
            this.setState({ confirmData: true });
          } else {
            this.setState({ confirmData: false });
          }
        } else {
          this.setState({ confirmData: false });
        }
      } else if (this.props.ticketDetails.flowId === "AdvanceExchange" && this.props.blockDetails.blockStatus === "REJECT_SERVICE_FE_AT_PICKUP") {
        let blockData = this.props.blockDetails;
        // let ticketData = this.props.ticketDetails;
        let res = await getPackingMaterialAndProdByJobId(
          blockData,
          // ticketData
        );
        console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh", res.data.success);
        if (res !== undefined) {
          if (res.data.success === true && res.data.data) {
            this.setState({
              pickUpConfirmData: res.data.data.packingMaterialDetails,
            });
            this.setState({
              itemStatus:
                res.data.data.packingMaterialDetails[0].productDetails[0]
                  .itemStatus,
            });
            // this.getPackingMaterialAndProdStatus(
            //   res.data.data.packingMaterialDetails
            // );
            this.setState({ confirmData: true });
          } else {
            this.setState({ confirmData: false });
          }
        } else {
          this.setState({ confirmData: false });
        }

      } else if (this.props.ticketDetails.flowId === "AdvanceExchange" && this.props.blockDetails.blockStatus === "RESCHEDULE_REQUEST_FE_AT_PICKUP") {
        let blockData = this.props.blockDetails;
        // let ticketData = this.props.ticketDetails;
        let res = await getPackingMaterialAndProdByJobId(
          blockData,
          // ticketData
        );
        console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh", res.data.success);
        if (res !== undefined) {
          if (res.data.success === true && res.data.data) {
            this.setState({
              pickUpConfirmData: res.data.data.packingMaterialDetails,
            });
            this.setState({
              itemStatus:
                res.data.data.packingMaterialDetails[0].productDetails[0]
                  .itemStatus,
            });
            // this.getPackingMaterialAndProdStatus(
            //   res.data.data.packingMaterialDetails
            // );
            this.setState({ confirmData: true });
          } else {
            this.setState({ confirmData: false });
          }
        } else {
          this.setState({ confirmData: false });
        }

      }
      else {
        let blockData = this.props.blockDetails;
        let ticketData = this.props.ticketDetails;
        let res = await getPackingMaterialAndProdByJobIdAdvExc(
          blockData,
          ticketData
        );
        // console.log(res.data.data.packingMaterialDetails);
        if (res !== undefined) {
          if (res.data.success === true && res.data.data) {
            this.setState({
              pickUpConfirmData: res.data.data.packingMaterialDetails,
            });
            this.setState({
              itemStatus:
                res.data.data.packingMaterialDetails[0].productDetails[0]
                  .itemStatus,
            });
            // this.getPackingMaterialAndProdStatus(
            //   res.data.data.packingMaterialDetails
            // );
            this.setState({ confirmData: true });
          } else {
            this.setState({ confirmData: false });
          }
        } else {
          this.setState({ confirmData: false });
        }
      }
    } catch {
      console.log(
        "Error in loadPackingMaterialData>>>>>>>>>>>>>>>>>>>>>>>>>>PickUpVerificationProduct"
      );
    }
  };
  // getPackingMaterialAndProdStatus = async (data) => {
  //   data.map((r) => {
  //     if (r.packMaterialId) {
  //       if (r.packMaterialItemStatus !== null) {
  //         this.props.onHandleGetConfirmStatus();
  //       }
  //     } else {
  //       if (r.productDetails[0].itemStatus !== null) {
  //         this.props.onHandleGetConfirmStatus();
  //       }
  //     }
  //   });
  // };

  tableMeta = {
    key: "ticketProductId",
    columns: [
      {
        key: "ticketProductId",
        actionKey: "bt",
        action: (r) => (
          <>
            <span
              className="badge badge-primary link-over"
              onClick={() => this.onBlockSelection(r)}
            >
              Confirm
            </span>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      {
        key: "ticketProductId",
        actionKey: "pb",
        action: (r) => (
          <>{this.rTB("Barcode", "pb_" + r["ticketProductId"], "")}</>
        ),
        cusClass: " ",
        icon: () => <>Barcodes</>,
      },
      {
        key: "ticketProductId",
        actionKey: "pI",
        action: (r) => (
          <>{this.rTB("Identification No", "pI_" + r["ticketProductId"], "")}</>
        ),
        cusClass: " ",
        icon: () => <>Identification No</>,
      },
      // { path: "prdBarcode", label: "Product Barcode" },
      {
        key: "prdBarcode",
        actionKey: "pI",
        action: (r) => (
          <>
            <a>{r.prdBarcode}</a>
            <button
              className="border-0 fs-5 ms-3 bg-transparent"
              title="Print Barcode"
              onClick={() => this.onPrintBarcode(r.prdBarcode)}
            >
              <IoBarcodeOutline />
            </button>
          </>
        ),
        cusClass: " ",
        icon: () => <>Product Barcode</>,
      },
      { path: "identificationNo", label: "Identification No" },

      { path: "productName", label: "Product" },
    ],
  };

  tableMeta1 = {
    key: "ticketProductId",
    columns: [
      {
        key: "ticketProductId",
        actionKey: "bt",
        action: (r) => (
          <>
            <span
              className="badge badge-primary link-over"
              onClick={() => this.onBlockSelection(r)}
            >
              Confirm
            </span>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      {
        key: "ticketProductId",
        actionKey: "pI",
        action: (r) => (
          <>{this.rTB("Identification No", "pI_" + r["ticketProductId"], "")}</>
        ),
        cusClass: " ",
        icon: () => <>Identification No</>,
      },
      // { path: "prdBarcode", label: "Product Barcode" },

      { path: "identificationNo", label: "Identification No" },

      { path: "productName", label: "Product" },
    ],
  };

  tableMeta2 = {
    key: "ticketBlockId",
    columns: [
      {
        key: "ticketBlockId",
        actionKey: "bt",
        action: (r) => (
          <>
            <span
              className="badge badge-primary link-over"
              onClick={() => this.onBlockSelection(r)}
            >
              Confirm
            </span>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },

      { path: "blockStatus", label: "blockStatus" },
      {
        key: "ticketBlockId",
        actionKey: "bt",
        action: (r) => (
          <>
            <a>{r.products[0].brandName}</a>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
    ],
  };

  advExcColumn = {
    key: "ticketProductId",
    actionKey: "pT",
    action: (r) => <>{r.productType.toUpperCase()}</>,
    cusClass: " ",
    icon: () => <>Product Type</>,
  };

  buyBackColumn = [
    {
      key: "ticketProductId",
      actionKey: "pV",
      action: (r) => <>{r.productValue}</>,
      cusClass: " ",
      icon: () => <>Product Value</>,
    },
    {
      key: "ticketProductId",
      actionKey: "qV",
      action: (r) => <>{r.quoteValue}</>,
      cusClass: " ",
      icon: () => <>Quote Value</>,
    },
  ];

  onBlockSelection = async (r) => {
    try {
      if (
        (this.state.curTicketDetails.isPackingReq || "").toLowerCase() === "yes"
      ) {
        if (this.state.data["pb_" + r.ticketProductId] !== r.prdBarcode) {
          toast.error(
            "Product Barcode Does not Match Kindly enter the correct Barcode"
          );
        } else {
          toast.success("Product Barcode Matched successfully");
        }
        if (this.state.data["pI_" + r.ticketProductId] !== r.identificationNo) {
          toast.error(
            "Product Identification No Does not Match Kindly enter the correct Identification No"
          );
        } else {
          toast.success("Product Identification No Matched successfully");
        }
      } else {
        if (this.state.data["pI_" + r.ticketProductId] !== r.identificationNo) {
          toast.error(
            "Product Identification No Does not Match Kindly enter the correct Identification No"
          );
        } else {
          toast.success("Product Identification No Matched successfully");
        }
      }
    } catch {
      console.log("Error in PickUpVerificationProduct>>>>>>onBlockSelection");
    }
  };

  onCellSelection = async (r) => {
    try {
      let curHub = localStorage.getItem("curHub");

      if (r.packMaterialId) {
        if (this.state.data["pb_" + r.packMaterialSl] !== r.packMaterialSl) {
          swal({
            icon: "error",
            title:
              "Packing Barcode Does not Match Kindly enter the correct Barcode",
            dangerMode: true,
          }).then((res) => {
            return null;
          });
          return null;
        }
        else {
          if (this.props.blockDetails.blockStatus === "RESCHEDULE_REQUEST_FE_AT_PICKUP" && this.props.ticketDetails.flowId !== "AdvanceExchange") {
            let productParams = {
              slNoId: r.productDetails[0].slNoId,
              itemId: r.productDetails[0].itemId,
              processId: this.state.curTicketDetails.processId,
              ticketBlockId: r.productDetails[0].ticketBlockId,
              ticketProductId: r.productDetails[0].ticketProductId,
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            // console.log("paramssssssssssssss",productParams)

            let res = await deattachingBarcodeFromItem(productParams);
            if (res.data.success === true && res.data.data !== []) {

              toast.success(() => <div>{res.data.msg}</div>);
              this.props.onHandleGetConfirmStatus();
            }
          } else if (this.props.blockDetails.blockStatus === "RESCHEDULE_REQUEST_FE_AT_PICKUP" && this.props.ticketDetails.flowId === "AdvanceExchange") {
            let productParams = {
              slNoId: r.productDetails[0].slNoId,
              itemId: r.productDetails[0].itemId,
              hubId: curHub,
              itemStatus: "itemsRescheduled",
              retailerId: r.productDetails[0].retailerId,
              processId: this.state.curTicketDetails.processId,
              ticketBlockId: r.productDetails[0].ticketBlockId,
              ticketProductId: r.productDetails[0].ticketProductId,
              ticketStatus : "RESCHEDULE_REQUEST_FE_AT_PICKUP",
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            let res = await deattachingSlNoInwardingItem(productParams);

            if (res.data.success === true && res.data.data !== []) {
              toast.success(() => <div>{res.data.msg}</div>);
              localStorage.setItem("inventoryInward", true);
              this.props.onHandleGetConfirmStatus();
              this.loadPackingMaterialData1();

            }
          } else if (this.props.blockDetails.blockStatus === "RESCHEDULE_REQUEST_FE_AT_DROP" && this.props.ticketDetails.flowId === "DropOff") {
            let productParams = {
              slNoId: r.productDetails[0].slNoId,
              itemId: r.productDetails[0].itemId,
              hubId: curHub,
              itemStatus: "itemsRescheduled",
              retailerId: r.productDetails[0].retailerId,
              processId: this.state.curTicketDetails.processId,
              ticketBlockId: r.productDetails[0].ticketBlockId,
              ticketProductId: r.productDetails[0].ticketProductId,
              ticketStatus : "RESCHEDULE_REQUEST_FE_AT_DROP",
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            let res = await deattachingSlNoInwardingItem(productParams);

            if (res.data.success === true && res.data.data !== []) {
              toast.success(() => <div>{res.data.msg}</div>);
              localStorage.setItem("inventoryInward", true);
              this.props.onHandleGetConfirmStatus();
              this.loadPackingMaterialData1();

            }
          }
          else if (this.props.blockDetails.blockStatus === "RESCHEDULE_REQUEST_FE_AT_DROP" && this.props.ticketDetails.flowId !== "DropOff") {
            if (this.state.itemStatus !== "itemsRescheduled") {
              let productParams = {
                packing: false,
                slNoId: r.productDetails[0].slNoId,
                hubId: curHub,
                status: "itemsRescheduled",
                isOverride: this.state.isOverride,
                processId: this.state.curTicketDetails.processId,
                processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
              };

              let res = await inwardInventoryForNegativeCases(productParams);
              if (res.data.success === true && res.data.data !== []) {
                toast.success(() => <div>{res.data.msg}</div>);
                this.props.onHandleGetConfirmStatus();
                this.loadPackingMaterialData1();
              }
            } else if (this.state.itemStatus === "itemsRescheduled") {
              toast.warning(() => <div>{"item already inwarded"}</div>)
            }

          }
          else if (this.props.blockDetails.blockStatus === "REJECT_SERVICE_FE_AT_PICKUP" && this.props.ticketDetails.flowId !== "AdvanceExchange") {
            let productParams = {
              slNoId: r.productDetails[0].slNoId,
              itemId: r.productDetails[0].itemId,
              processId: this.state.curTicketDetails.processId,
              ticketBlockId: r.productDetails[0].ticketBlockId,
              ticketProductId: r.productDetails[0].ticketProductId,
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            // console.log("paramssssssssssssss",productParams)

            let res = await deattachingBarcodeFromItem(productParams);
            if (res.data.success === true && res.data.data !== []) {
              toast.success(() => <div>{res.data.msg}</div>);
              this.props.onHandleGetConfirmStatus();
            }
          } else if (this.props.blockDetails.blockStatus === "REJECT_SERVICE_FE_AT_PICKUP" && this.props.ticketDetails.flowId === "AdvanceExchange") {
            let productParams = {
              slNoId: r.productDetails[0].slNoId,
              itemId: r.productDetails[0].itemId,
              hubId: curHub,
              itemStatus: "itemsRejected",
              retailerId: r.productDetails[0].retailerId,
              processId: this.state.curTicketDetails.processId,
              ticketBlockId: r.productDetails[0].ticketBlockId,
              ticketStatus : "REJECT_SERVICE_FE_AT_PICKUP",
              ticketProductId: r.productDetails[0].ticketProductId,
              
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            let res = await deattachingSlNoInwardingItem(productParams);

            if (res.data.success === true && res.data.data !== []) {
              toast.success(() => <div>{res.data.msg}</div>);
              localStorage.setItem("inventoryInward", true);
              this.props.onHandleGetConfirmStatus();
              this.loadPackingMaterialData1();

            }
          } else if (this.props.blockDetails.blockStatus === "REJECT_SERVICE_FE_AT_DROP" && this.props.ticketDetails.flowId === "DropOff") {
            let productParams = {
              slNoId: r.productDetails[0].slNoId,
              itemId: r.productDetails[0].itemId,
              hubId: curHub,
              itemStatus: "itemsRejected",
              retailerId: r.productDetails[0].retailerId,
              processId: this.state.curTicketDetails.processId,
              ticketBlockId: r.productDetails[0].ticketBlockId,
              ticketProductId: r.productDetails[0].ticketProductId,
              ticketStatus : "REJECT_SERVICE_FE_AT_DROP",
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            let res = await deattachingSlNoInwardingItem(productParams);

            if (res.data.success === true && res.data.data !== []) {
              toast.success(() => <div>{res.data.msg}</div>);
              localStorage.setItem("inventoryInward", true);
              this.props.onHandleGetConfirmStatus();
              this.loadPackingMaterialData1();

            }
          }
          else if (this.props.blockDetails.blockStatus === "REJECT_SERVICE_FE_AT_DROP" && this.props.ticketDetails.flowId !== "DropOff") {
            if (this.state.itemStatus !== "itemsRejected") {
              let productParams = {
                packing: false,
                slNoId: r.productDetails[0].slNoId,
                hubId: curHub,
                status: "itemsRejected",
                isOverride: this.state.isOverride,
                processId: this.state.curTicketDetails.processId,
                processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
              };

              let res = await inwardInventoryForNegativeCases(productParams);
              if (res.data.success === true && res.data.data !== []) {
                toast.success(() => <div>{res.data.msg}</div>);
                this.props.onHandleGetConfirmStatus();
                this.loadPackingMaterialData1();

              }
            } else if (this.state.itemStatus === "itemsRejected") {
              toast.warning(() => <div>{"item already inwarded"}</div>)
            }

          } else if (this.props.blockDetails.blockStatus === "RTO_REQUEST") {
            if (this.state.itemStatus !== "itemsRequestedForRTO") {
              let productParams = {
                packing: false,
                slNoId: r.productDetails[0].slNoId,
                hubId: curHub,
                status: "itemsRequestedForRTO",
                isOverride: this.state.isOverride,
                processId: this.state.curTicketDetails.processId,
                processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
              };

              let res = await inwardInventoryForNegativeCases(productParams);
              if (res.data.success === true && res.data.data !== []) {
                toast.success(() => <div>{res.data.msg}</div>);
                this.props.onHandleGetConfirmStatus();
                this.loadPackingMaterialData1();
              }
            } else if (this.state.itemStatus === "itemsRequestedForRTO") {
              toast.warning(() => <div>{"item already inwarded"}</div>)
            }
          }
          else {
            let packingParams = {
              packing: true,
              slNoId: r.packMaterialId,
              hubId: curHub,
              status: "itemsPickUpVerified",
              isOverride: this.state.isOverride,
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            let res = await verifyingPackingMaterialAndProduct(packingParams);
            if (res.data.success === true && res.data.data !== []) {
              toast.success(() => <div>{res.data.msg}</div>);
              this.props.onHandleGetConfirmStatus();
            }
          }
        }
      } else {
        if (
          JSON.parse(this.state.data["pb_" + r.productDetails[0].ticketProductId]) !==
          r.productDetails[0].slNo
        ) {
          swal({
            icon: "error",
            title:
              "Product Barcode Does not Match Kindly enter the correct Barcode",
            dangerMode: true,
          }).then((res) => {
            return null;
          });
          return null;
        }
        else {
          if ((this.props.blockDetails.blockStatus === "RESCHEDULE_REQUEST_FE_AT_PICKUP" && this.props.ticketDetails.flowId !== "AdvanceExchange")) {
            let productParams = {
              slNoId: r.productDetails[0].slNoId,
              itemId: r.productDetails[0].itemId,
              processId: this.state.curTicketDetails.processId,
              ticketBlockId: r.productDetails[0].ticketBlockId,
              ticketProductId: r.productDetails[0].ticketProductId,
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            // console.log("paramssssssssssssss",productParams)

            let res = await deattachingBarcodeFromItem(productParams);
            if (res.data.success === true && res.data.data !== []) {
              toast.success(() => <div>{res.data.msg}</div>);
              this.props.onHandleGetConfirmStatus();
            }
          } else if (this.props.blockDetails.blockStatus === "RESCHEDULE_REQUEST_FE_AT_PICKUP" && this.props.ticketDetails.flowId === "AdvanceExchange") {
            let productParams = {
              slNoId: r.productDetails[0].slNoId,
              itemId: r.productDetails[0].itemId,
              hubId: curHub,
              itemStatus: "itemsRescheduled",
              retailerId: r.productDetails[0].retailerId,
              processId: this.state.curTicketDetails.processId,
              ticketBlockId: r.productDetails[0].ticketBlockId,
              ticketProductId: r.productDetails[0].ticketProductId,
              ticketStatus : "RESCHEDULE_REQUEST_FE_AT_PICKUP",
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            // console.log("paramssssssssssssss",productParams)
            let res = await deattachingSlNoInwardingItem(productParams);
            // console.log("ttttttttttttttttttttt",res)
            if (res.data.success === true && res.data.data !== []) {
              toast.success(() => <div>{res.data.msg}</div>);
              this.props.onHandleGetConfirmStatus();
              this.props.onHandleGetConfirmStatuses();


            }
          } else if (this.props.blockDetails.blockStatus === "RESCHEDULE_REQUEST_FE_AT_DROP" && this.props.ticketDetails.flowId === "DropOff") {
            let productParams = {
              slNoId: r.productDetails[0].slNoId,
              itemId: r.productDetails[0].itemId,
              hubId: curHub,
              itemStatus: "itemsRescheduled",
              retailerId: r.productDetails[0].retailerId,
              processId: this.state.curTicketDetails.processId,
              ticketBlockId: r.productDetails[0].ticketBlockId,
              ticketProductId: r.productDetails[0].ticketProductId,
              ticketStatus : "RESCHEDULE_REQUEST_FE_AT_DROP",
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            let res = await deattachingSlNoInwardingItem(productParams);

            if (res.data.success === true && res.data.data !== []) {
              toast.success(() => <div>{res.data.msg}</div>);
              localStorage.setItem("inventoryInward", true);
              this.props.onHandleGetConfirmStatus();
              this.props.onHandleGetConfirmStatuses();
              this.loadPackingMaterialData1();

            }
          }
          else if (this.props.blockDetails.blockStatus === "RESCHEDULE_REQUEST_FE_AT_DROP" && this.props.ticketDetails.flowId !== "DropOff") {
            if (r.productDetails[0].itemStatus !== "itemsRescheduled") {
              let productParams = {
                packing: false,
                slNoId: r.productDetails[0].slNoId,
                hubId: curHub,
                status: "itemsRescheduled",
                isOverride: this.state.isOverride,
                processId: this.state.curTicketDetails.processId,
                processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
              };

              let res = await inwardInventoryForNegativeCases(productParams);
              if (res.data.success === true && res.data.data !== []) {
                toast.success(() => <div>{res.data.msg}</div>);
                this.props.onHandleGetConfirmStatus();
                this.props.onHandleGetConfirmStatuses();
                this.loadPackingMaterialData1();
              }
            } else if (r.productDetails[0].itemStatus === "itemsRescheduled") {
              toast.warning(() => <div>{"item already inwarded"}</div>)
            }
          }
          else if (this.props.blockDetails.blockStatus === "REJECT_SERVICE_FE_AT_PICKUP" && this.props.ticketDetails.flowId !== "AdvanceExchange") {
            let productParams = {
              slNoId: r.productDetails[0].slNoId,
              itemId: r.productDetails[0].itemId,
              processId: this.state.curTicketDetails.processId,
              ticketBlockId: r.productDetails[0].ticketBlockId,
              ticketProductId: r.productDetails[0].ticketProductId,
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            // console.log("paramssssssssssssss",productParams)

            let res = await deattachingBarcodeFromItem(productParams);
            if (res.data.success === true && res.data.data !== []) {
              toast.success(() => <div>{res.data.msg}</div>);
              this.props.onHandleGetConfirmStatus();
            }
          } else if (this.props.blockDetails.blockStatus === "REJECT_SERVICE_FE_AT_PICKUP" && this.props.ticketDetails.flowId === "AdvanceExchange") {
            let productParams = {
              slNoId: r.productDetails[0].slNoId,
              itemId: r.productDetails[0].itemId,
              hubId: curHub,
              itemStatus: "itemsRejected",
              retailerId: r.productDetails[0].retailerId,
              processId: this.state.curTicketDetails.processId,
              ticketBlockId: r.productDetails[0].ticketBlockId,
              ticketProductId: r.productDetails[0].ticketProductId,
              ticketStatus : "REJECT_SERVICE_FE_AT_PICKUP",
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            let res = await deattachingSlNoInwardingItem(productParams);

            if (res.data.success === true && res.data.data !== []) {
              toast.success(() => <div>{res.data.msg}</div>);
              localStorage.setItem("inventoryInward", true);
              this.props.onHandleGetConfirmStatus();
              this.loadPackingMaterialData1();

            }
          } else if (this.props.blockDetails.blockStatus === "REJECT_SERVICE_FE_AT_DROP" && this.props.ticketDetails.flowId === "DropOff") {
            let productParams = {
              slNoId: r.productDetails[0].slNoId,
              itemId: r.productDetails[0].itemId,
              hubId: curHub,
              itemStatus: "itemsRejected",
              retailerId: r.productDetails[0].retailerId,
              processId: this.state.curTicketDetails.processId,
              ticketBlockId: r.productDetails[0].ticketBlockId,
              ticketProductId: r.productDetails[0].ticketProductId,
              ticketStatus : "REJECT_SERVICE_FE_AT_DROP",
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            let res = await deattachingSlNoInwardingItem(productParams);

            if (res.data.success === true && res.data.data !== []) {
              toast.success(() => <div>{res.data.msg}</div>);
              localStorage.setItem("inventoryInward", true);
              this.props.onHandleGetConfirmStatus();
              this.loadPackingMaterialData1();

            }
          }
          else if (this.props.blockDetails.blockStatus === "REJECT_SERVICE_FE_AT_DROP" && this.props.ticketDetails.flowId !== "DropOff") {
            if (r.productDetails[0].itemStatus !== "itemsRejected") {
              let productParams = {
                packing: false,
                slNoId: r.productDetails[0].slNoId,
                hubId: curHub,
                status: "itemsRejected",
                isOverride: this.state.isOverride,
                processId: this.state.curTicketDetails.processId,
                processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
              };

              let res = await inwardInventoryForNegativeCases(productParams);
              if (res.data.success === true && res.data.data !== []) {
                toast.success(() => <div>{res.data.msg}</div>);
                this.props.onHandleGetConfirmStatus();
                this.loadPackingMaterialData1();
              }
            } else if (r.productDetails[0].itemStatus === "itemsRejected") {
              toast.warning(() => <div>{"item already inwarded"}</div>)
            }
          } else if (this.props.blockDetails.blockStatus === "RTO_REQUEST") {
            if (r.productDetails[0].itemStatus !== "itemsRequestedForRTO") {
              let productParams = {
                packing: false,
                slNoId: r.productDetails[0].slNoId,
                hubId: curHub,
                status: "itemsRequestedForRTO",
                isOverride: this.state.isOverride,
                processId: this.state.curTicketDetails.processId,
                processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
              };

              let res = await inwardInventoryForNegativeCases(productParams);
              if (res.data.success === true && res.data.data !== []) {
                toast.success(() => <div>{res.data.msg}</div>);
                this.props.onHandleGetConfirmStatus();
                this.loadPackingMaterialData1();
              }
            } else if (r.productDetails[0].itemStatus === "itemsRequestedForRTO") {
              toast.warning(() => <div>{"item already inwarded"}</div>)
            }
          }
          else {
            let productParams = {
              packing: false,
              slNoId: r.productDetails[0].slNoId,
              hubId: curHub,
              status: "itemsPickUpVerified",
              isOverride: this.state.isOverride,
              processAt: IstToUTC(new Date()).slice(0, 19).replace(" ", "T"),
            };

            let res = await verifyingPackingMaterialAndProduct(productParams);
            if (res.data.success === true && res.data.data !== []) {
              toast.success(() => <div>{res.data.msg}</div>);
              this.props.onHandleGetConfirmStatus();
            }
          }
        }
      }
    } catch {
      console.log("Error in PickUpVerificationProduct>>>>>>onCellSelection");
    }
  };
  onPrintBarcode(barcode) {
    try {
      this.setState({ printBarcode: barcode });
    } catch {
      console.log("Error in PickUpVerificationProduct>>>>>>onPrintBarcode");
    }
  }
  handlePackingListReload = () => {
    this.props.packingListReload(
      this.props.packingListReloadState.barcodeVerification.packingListReload
    );
  };

  render() {
    const productDetails = this.state.curProductDetails;
    let ticketDetails = this.state.curTicketDetails;
    // console.log(this.props, "kkkkkkkkkkkkkkkk");
    return (
      <>
        {" "}
        <div className="form-subHeader">
          {/* (this.props.blockDetails.blockStatus !== "RESCHEDULE_REQUEST_FE_AT_PICKUP" || 
                      this.props.blockDetails.blockStatus !== "REJECT_FE_AT_PICKUP") && */}
          Products PickedUp Barcodes Verification
        </div>
        <div className="row">
          {this.props.loading && (
            <div className="container-fluid">
              <div> Loading Tickets...</div>
            </div>
          )}
          {!this.props.loading && (
            <>
              <div className="container-fluid">
                {this.state.confirmData === false && this.props.ticketDetails.flowId != "AdvanceExchange" && (
                  <>
                    {(ticketDetails.isPackingReq || "").toLowerCase() ===
                      "yes" && (
                        <Table
                          clsName="table table-striped table-bordered table-hover"
                          tableMeta={this.tableMeta}
                          data={productDetails}
                          dropMenu={[]}
                        />
                      )}
                    {(ticketDetails.isPackingReq || "").toLowerCase() !==
                      "yes" && (
                        <Table
                          clsName="table table-striped table-bordered table-hover"
                          tableMeta={this.tableMeta1}
                          data={productDetails}
                          dropMenu={[]}
                        />
                      )}
                  </>
                )}
                {this.state.confirmData === true && (
                  <>
                    <Tables className="table table table-bordered table-hover">
                      <TableHeader>
                        <TableRow>
                          <TableCell></TableCell>
                          {/* {ticketDetails.flowId === "Bulk" && (
                            <>
                              <TableCell>Scan Packing Barcode</TableCell>
                            </>
                          )} */}
                          {ticketDetails.flowId != "Bulk" && (
                            <>
                              <TableCell>Scan Product Barcode</TableCell>
                            </>
                          )}
                          {(ticketDetails.flowId === "Bulk" ||
                            ticketDetails.flowId === "DropOff") && (
                              <>
                                {/* <TableCell>Scan Product Barcode</TableCell> */}
                              </>
                            )}
                          {/* {ticketDetails.flowId === "Bulk" && (
                            <>
                              <TableCell>Packing Barcode</TableCell>
                              <TableCell>Packing Name</TableCell>
                            </>
                          )} */}
                          <TableCell>Barcode</TableCell>
                          <TableCell>Product</TableCell>
                          <TableCell>Product Code</TableCell>
                          {ticketDetails.flowId === "AdvanceExchange" && (
                            <TableCell>Product Type</TableCell>
                          )}
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {this.state.pickUpConfirmData.map((item) => (
                          <Fragment>
                            <>
                              <TableRow>
                                <TableCell
                                  style={{ textAlign: "center" }}
                                  rowSpan={item.productDetails.length + 1}
                                >
                                  {/* <span> */}
                                  <div className="row">
                                    <div className="col-3">
                                      <span
                                        className="badge badge-primary link-over"
                                        onClick={() =>
                                          this.onCellSelection(item)
                                        }
                                      >
                                        Confirm
                                      </span>
                                    </div>
                                  </div>
                                </TableCell>
                                {/* {ticketDetails.flowId === "Bulk" && (
                                  <>
                                    <TableCell
                                      style={{ textAlign: "center" }}
                                      rowSpan={item.productDetails.length + 1}
                                    >
                                      <div className="row">
                                        {item.packMaterialId !== "" && (
                                          <div className="col-9">
                                            {this.rTB(
                                              "Barcode",
                                              "pb_" + item["packMaterialSl"],
                                              ""
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </TableCell>
                                   
                                  </>
                                )} */}
                              </TableRow>

                              {item.productDetails.map((detail) => (
                                <TableRow>
                                  <TableCell>
                                    {item.packMaterialId === "" && (
                                      <div className="col-9">
                                        {this.rTB(
                                          "Barcode",
                                          "pb_" + detail["ticketProductId"],
                                          ""
                                        )}
                                      </div>
                                    )}
                                  </TableCell>
                                  {/* {ticketDetails.flowId === "Bulk" && (
                                    <>
                                      <TableCell
                                        style={{
                                          color: "transparent",
                                          textShadow: "0 0 8px #000",
                                        }}
                                      >
                                        {item?.packMaterialSl}
                                      </TableCell>
                                      <TableCell>
                                        {item?.packMaterialName}
                                      </TableCell>
                                    </>
                                  )} */}

                                  <TableCell
                                    style={{
                                      color: "transparent",
                                      textShadow: "0 0 8px #000",
                                    }}
                                  >
                                    {detail?.slNo}
                                  </TableCell>
                                  <TableCell>{detail?.productName}</TableCell>
                                  <TableCell>{detail?.productCode}</TableCell>

                                  {ticketDetails.flowId ===
                                    "AdvanceExchange" && (
                                      <TableCell>{detail?.productType}</TableCell>
                                    )}
                                </TableRow>
                              ))}
                            </>
                          </Fragment>
                        ))}
                      </TableBody>
                    </Tables>
                  </>
                )}
              </div>
            </>
          )}
        </div>
        {this.state.printBarcode && (
          <BarCodePrintSVG
            printBarcode={this.state.printBarcode}
            productDetails={this.state.curProductDetails}
            ticketDetails={this.state.curTicketDetails}
            blockDetails={this.state.curBlockDetails}
            key={
              "t_" +
              this.state.curBlockDetails.ticketBlockId +
              this.state.printBarcode
            }
          />
        )}
        <iframe
          title="toPrintContainer"
          id="ifmcontentstoprint"
          style={{ height: "0px", width: "0px", position: "absolute" }}
        ></iframe>
      </>
    );
  }
}
