import React from 'react'

import {
  Gantt,
  Task,
  EventOption,
  StylingOption,
  ViewMode,
  DisplayOption
} from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import swal from '@sweetalert/with-react';
import { getTasks } from '../../../services/plms/taskServices';

const currentDate = new Date();

// const demoUserStory = {
//                 "usColumn": "noSprint",
//                 "prStatus": "active",
//                 "usRoles": "operations",
//                 "prOwner": "Sateesh",
//                 "usTitle": "Item Master",
//                 "endDate": null,
//                 "usPoints": 8.0,
//                 "dueDate": null,
//                 "usAcceptCriteria": "na",
//                 "prModule": "Inventory",
//                 "remarks": "na",
//                 "podOwner": "BLR0000",
//                 "usEdgeCase": "na",
//                 "moduleId": "modu1668352268793TZc6bd47497add4add83c1ba073486a84f",
//                 "usDesc": "na",
//                 "usComplexity": "normal",
//                 "projectCode": "QA001",
//                 "usOwner": "INT0011",
//                 "sort": 1.0,
//                 "usStatus": "99pipe",
//                 "userstoryId": "jira2plms4story0247",
//                 "usPriority": "normal",
//                 "project": "Inventory",
//                 "modStatus": "00open",
//                 "projectId": "proj1652073821297TZf1da81edf5a342b5900677f6fa3799e1",
//                 "startDate": 1663612200000
//             }

// const demoTasks = [
//             {
//                 "taskId": "jira2plms4task0050",
//                 "task": "request list by status paginate-GET",
//                 "taskType": "API",
//                 "startDate": "2022-10-18",
//                 "dueDate": null,
//                 "endDate": null,
//                 "tskPoints": 4,
//                 "tskOwner": "BLR0000",
//                 "estimatedTime": "00:00:00",
//                 "actualTime": "00:00:00",
//                 "status": "99pipe",
//                 "testUrl": "na",
//                 "sort": 1.0,
//                 "request": "na",
//                 "response": "na",
//                 "specialPoints": "na",
//                 "moreInfo": "na",
//                 "userstoryId": "jira2plms4story0247"
//             },
//             {
//                 "taskId": "task1670562749099TZbf6fd69a114542809c4f9cdb54a583ad",
//                 "task": "get All Item List Api",
//                 "taskType": "API",
//                 "startDate": "2022-12-09",
//                 "dueDate": "2022-12-04",
//                 "endDate": "2022-12-04",
//                 "tskPoints": 14,
//                 "tskOwner": "BLR0000",
//                 "estimatedTime": "02:02:00",
//                 "actualTime": "02:02:00",
//                 "status": "30done",
//                 "testUrl": "",
//                 "sort": 10.0,
//                 "request": "",
//                 "response": "",
//                 "specialPoints": "",
//                 "moreInfo": "",
//                 "userstoryId": "jira2plms4story0247"
//             }
//         ]

const intializeTaskList = (userStory, tasks) => {

    const userStoryStartDate = userStory?.startDate ?  new Date(userStory.startDate) : new Date();
    const userStoryDueDate = userStory?.dueDate ? new Date(userStory.dueDate) : new Date();
    const userStoryTask = 
    {
      start: userStoryStartDate,
      end: userStoryDueDate,
      name: userStory?.usTitle ?  userStory.usTitle : "Undefined",
      id: userStory?.userstoryId? userStory.userstoryId : "ProjectSample",
      progress: 0,
      type: "project",

      hideChildren: false
    }

   

    const childTasks = tasks?.map((task)=>{
        
        const childTaskStartDate = task?.startDate ? new Date(new Date(task.startDate).getFullYear(), new Date(task.startDate).getMonth(), new Date(task.startDate).getDate()) : new Date();
        const childTaskDueDate = task?.dueDate ? new Date(new Date(task.dueDate).getFullYear(), new Date(task.dueDate).getMonth(), new Date(task.dueDate).getDate()) : new Date();

        return {
            start: childTaskStartDate,
            end: childTaskDueDate,
            name: task?.task ? task.task : "undefined",
            id: task.taskId,
            progress: 0,
            type: "task",
            project: userStory?.userstoryId
            }
    })
   const ganttTasks = [userStoryTask, ...childTasks ]
//  const ganttTasksDemo =[
//     {
//       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
//       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
//       name: "Some Project",
//       id: "ProjectSample",
//       progress: 25,
//       type: "project",

//       hideChildren: false
//     },
//     {
//       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
//       end: new Date(
//         currentDate.getFullYear(),
//         currentDate.getMonth(),
//         2,
//         12,
//         28
//       ),
//       name: "Idea",
//       id: "Task 0",
//       progress: 45,
//       type: "task",
//       project: "ProjectSample"
//     },
//     {
//       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2),
//       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4, 0, 0),
//       name: "Research",
//       id: "Task 1",
//       progress: 25,
//       dependencies: ["Task 0"],
//       type: "task",
//       project: "ProjectSample"
//     },
//     {
//       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 4),
//       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8, 0, 0),
//       name: "Discussion with team",
//       id: "Task 2",
//       progress: 10,
//       dependencies: ["Task 1"],
//       type: "task",
//       project: "ProjectSample"
//     },
//     {
//       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
//       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 9, 0, 0),
//       name: "Developing",
//       id: "Task 3",
//       progress: 2,
//       dependencies: ["Task 2"],
//       type: "task",
//       project: "ProjectSample"
//     },
//     {
//       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 8),
//       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 10),
//       name: "Review",
//       id: "Task 4",
//       type: "task",
//       progress: 70,
//       dependencies: ["Task 2"],
//       project: "ProjectSample"
//     },
//     {
//       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
//       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
//       name: "Release",
//       id: "Task 6",
//       progress: currentDate.getMonth(),
//       type: "milestone",
//       dependencies: ["Task 4"],
//       project: "ProjectSample"
//     },
//     {
//       start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 18),
//       end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 19),
//       name: "Party Time",
//       id: "Task 9",
//       progress: 0,
//       isDisabled: true,
//       type: "task"
//     }
//   ];

  return ganttTasks
}



const StoryGanttChart = ({userStory}) => {
    const [stateTaskList, setTaskList] = React.useState([]);
    const [stateError, setError] = React.useState("")

    React.useEffect(()=>{

        initialize()

    }, [userStory])


    const initialize = async () => {
        try {
            const userStoryId = userStory?.userstoryId;
            const resp = await getTasks(userStoryId);
            if (resp.data?.success){
                const tasks = resp.data.data?.tasks;
                const taskList = intializeTaskList(userStory, tasks)
                // const taskList = intializeTaskList(demoUserStory, demoTasks)
                setTaskList(taskList)
            }
        } catch (error) {
            console.error(error)
            swal({
                icon: "error",
                title: "Some error happend. Please try again!"
            })
            return;
        }
    }

  return (
    
        <>
       { stateTaskList.length>0 ? 
        
     <Gantt tasks={stateTaskList} viewMode={ViewMode.Week} />:
     <div style={{
        height: "50%",
        textAlign: "center"
     }}>Nothing to show!</div>
     }
    </>
    
  )
};

export default StoryGanttChart;