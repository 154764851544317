import React from "react";
import FormLib from "./../../_common/FormLib";

class ProjectTasks extends FormLib {
  state = {
    data: {
      username: "",
      password: "",
      pickupDate: this.getDatebyDays(1),
      pickupTime: "14:00",
    },
    errors: {},
    task: [],
  };
  isCur(id, cur) {
    if (id === cur) {
      return " curTasks ";
    }
    return " ";
  }
  renderButtonStyle(status) {
    switch (status) {
      case "wip":
        return "badge-warning";
      case "done":
        return "badge-light";
      default:
        return "badge-info";
    }
  }
  render() {
    const tasks = this.props.tasks;
    const curUserstory = this.props.curUserstory;
    const curTask = this.props.curTask;

    return (
      <>
        <div className="card " style={{ margin: 0, padding: 2 }}>
          <h5 className="card-header">
            Tasks
            {curUserstory.userstoryId && !curTask.taskId && (
              <button
                type="button"
                className="btn btn-sm btn-primary pull-right"
                data-toggle="modal"
                data-target="#myTaskModel"
              >
                <i className={"fa  fa-plus  "} />
              </button>
            )}
            {curTask.taskId && curTask.taskId !== "" && (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-primary pull-right mr-0"
                  data-toggle="modal"
                  data-target="#myDayPlanModel"
                >
                  <i className={"fa fa-flag-checkered"} />
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-warning pull-right"
                  data-toggle="modal"
                  onClick={() => this.props.onTaskUnload(curTask)}
                >
                  <i className={"fa fa-unlink"} />
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-success pull-right"
                  data-toggle="modal"
                  data-target="#myTaskModel"
                >
                  <i className={"fa fa-pencil"} />
                </button>

                <button
                  type="button"
                  className="btn btn-sm btn-dark pull-right"
                  data-toggle="modal"
                  data-target="#myReportModel"
                >
                  <i className={"fa fa-hospital-o"} />
                </button>
              </>
            )}
          </h5>
          <div className="card-body" style={{ padding: 2 }}>
            <div className="page-todo">
              {tasks.map((mi) => (
                <div
                  className={
                    "task nil " + this.isCur(mi.taskId, curTask.taskId)
                  }
                  key={mi.taskId}
                >
                  <div className="desc">
                    <span
                      className="span-click"
                      onClick={() => this.props.onTaskChange(mi)}
                    >
                      <div className="title">{mi.task}</div>
                      <div>
                        <span
                          className={
                            "badge   badge-success pull-left mr-1 " +
                            "badge-" +
                            mi.taskType
                          }
                        >
                          {mi.taskType}
                        </span>
                        |{" " + mi.tskOwner} | {mi.tskPoints} pts
                      </div>
                      {/* <div className="desc">
                      <div className="title">
                        <span className="badge badge-info  mr-1 taskType">API</span>
                        Ledger Creation API
                      </div>
                      <div>
                        OwnerName | 8 Pts |
                        <span className="badge badge-primary  ml-1 ">Open</span>
                      </div>
                    </div> */}
                    </span>
                  </div>

                  <div className="pull-right">
                    <div className="menu">
                      <span
                        className={
                          "badge   badge-success pull-left " +
                          "badge-" +
                          mi.status
                        }
                      >
                        {mi.sort + " : " + mi.status.substring(2)}
                      </span>
                    </div>
                    <div className="pr-2">{mi.seq}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProjectTasks;
