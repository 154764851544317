export const SET_PREVILAGE = "SET_PREVILAGE";
export const SET_PREVILAGE_FAILURE = "SET_PREVILAGE_FAILURE";

export const UNSET_PREVILAGE = "UNSET_PREVILAGE";
export const UNSET_PREVILAGE_FAILURE = "UNSET_PREVILAGE_FAILURE";

// export const SET_PREVILAGE_REQUEST = "SET_PREVILAGE_REQUEST";
// export const SET_PREVILAGE_FAILURE = "SET_PREVILAGE_FAILURE";

// export const UNSET_PREVILAGE_REQUEST = "UNSET_PREVILAGE_REQUEST";
