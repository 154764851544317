import _http from "../_httpService";

import config from "../../config.json";
const baseURL = config[config.build].apiURLMasterLoc;
function api(path, subURL = "/zones") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function getZones() {
  return _http.get(api("details"), _http.headerJWT());
}

export function getActiveZones() {
  return _http.get(api("active"), _http.headerJWT());
}

export function createZones(req) {
  const param = { ...req };
  delete param.zoneId;
  return _http.post(api(""), param, _http.headerJWT());
}
export function saveZones(req) {
  const param = { ...req };
  const id = param.zoneId;
  //delete param.cityId;
  return _http.put(api(id), param, _http.headerJWT());
}
export function deleteZones(req) {
  const param = { ...req };
  return _http.delete(api(param.zoneId), _http.headerJWT());
}
