import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { faker } from "@faker-js/faker";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

let labels = [];

export const data = {
  labels,
  datasets: [],
};

const BarChart = (props) => {
  data.labels = props.chartData.labels;
  data.datasets = props.chartData.datasets;

  if (props.title) {
    options.plugins.title.text = props.title;
  }

  return <Bar options={options} data={data} />;
};

export default BarChart;
