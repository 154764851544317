import React from "react";
import FormLib from "../../../_common/FormLib";
import {
  assignBarcodeToItemQnt,
  getInventoryBarcodeDetails,
  getItemDetails,
} from "../../../../services/stockManagement/inwardInventoryServices";
import Table from "../../../_common/Table";
import { toast } from "react-toastify";

class BarcodeProductModal extends FormLib {
  state = {
    data: {
      description: "",
    },
    errors: {},
    hubs: [],
    itemDetails: [],
    selectItem: [],
    qnt: "",
    barcode: "",
    selectedStockItem: [],
    productDetails: {},
    modal: "",
  };

  tableMeta = {
    key: "itemId",
    columns: [
      {
        key: "itemId",
        actionKey: "bt",
        action: (r) => (
          <>
            <span
              className="badge badge-primary link-over"
              onClick={() => this.onBlockSelection(r)}
            >
              Add
            </span>
          </>
        ),
        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },

      {
        key: "itemId",
        // actionKey: "sb",
        action: (r) => (
          <>
            <div>{this.rTB("Serial", "serial", "")}</div>
          </>
        ),
        label: "Scan Barcode",
        icon: () => <>Scan Barcode</>,
        cusClass: " ",
      },

      {
        key: "itemId",
        // actionKey: "sb",
        action: (r) => (
          <>
            <>{this.rNBm("Quantity ", "qnt", "")}</>
          </>
        ),
        label: "Quantity ",
        icon: () => <>Quantity</>,
        cusClass: " ",
      },
      ,
      { path: "item", label: "Item" },
    ],
  };

  async componentDidMount() {
    this.setState({ productDetails: this.props.data });
    this.loadItems();
  }

  async loadItems() {
    try {
      let resp = await getItemDetails(this.props.hubId, this.props.retailerId);
      console.log("resp", resp.data.data.itemsQntList);
      if (resp.data.success === true) {
        this.setState({ itemDetails: resp.data.data.itemsQntList });
      }
    } catch {
      console.log("Error >>>>>> loadHubs");
    }
  }

  localHandleChange = (e) => {
    console.log(e.target.id);
    if (e.target.id === "itemDetails") {
      this.setState({ qnt: "1" });
      this.loadSelectedItem(e.target.value);
    } else if (e.target.id === "qnt") {
      this.setState({ qnt: e.target.value });
    } else {
      this.setState({ barcode: e.target.value });
    }
  };

  loadSelectedItem = (r) => {
    this.state.itemDetails.map((i) => {
      if (i.itemId === r) {
        const dataArray = [i];

        console.log(dataArray);
        this.setState({ selectItem: dataArray });
      }
    });
  };

  onBlockSelection = async () => {
    // alert(this.state.qnt)
    try {
      let resp = await getInventoryBarcodeDetails(this.state.barcode);
      if (resp.data.success === true) {
        this.state.selectItem.map((r) => {
          let selectedItemDetails = {
            item: r.item,
            itemId: r.itemId,
            storeBaysId: r.storeBaysId,
            quantity: this.state.qnt,
            slNo: resp.data.data.slNo[0].slNo,
            slNoId: resp.data.data.slNo[0].slNoId,
            ticketProductId: this.state.productDetails.ticketProductId,
            processId: this.state.productDetails.processId,
            ticketBlockId: this.state.productDetails.ticketBlockId,
            hubId: this.props.hubId,
            retailerId: this.props.retailerId,
          };
          const data = [selectedItemDetails];
          this.setState({ selectedStockItem: data });
        });
      }
    } catch (error) {}
  };
  handleRemoveItem = (item) => {
    let selectedStockItem = this.state.selectedStockItem.filter(
      (inv) =>
        inv.itemId + inv.storeBaysId + inv.slNo !==
        item.itemId + item.storeBaysId + item.slNo
    );
    this.setState({ selectedStockItem: selectedStockItem });
  };

  handleInventoryItemSubmit = async () => {
    try {
      let params = {
        slNoId: this.state.selectedStockItem[0].slNoId,
        itemId: this.state.selectedStockItem[0].itemId,
        ticketProductId: this.state.selectedStockItem[0].ticketProductId,
        quantity: this.state.selectedStockItem[0].quantity,
        hubId: this.state.selectedStockItem[0].hubId,
        retailerId: this.state.selectedStockItem[0].retailerId,
        bayId: this.state.selectedStockItem[0].storeBaysId,
        processId: this.state.selectedStockItem[0].processId,
        ticketBlockId: this.state.selectedStockItem[0].ticketBlockId,
      };
      console.log("params", params);
      let resp = await assignBarcodeToItemQnt(params);
      console.log("resp", resp);
      if (resp.data.success === true) {
        toast.success(() => <div>{resp.data.msg}</div>);
        this.setState(this.props.closeModal);
        this.props.onHandleGetConfirmStatus();
        this.props.onHandleGetStatus();
      } else {
        toast.error(() => <div>{resp.data.msg}</div>);
      }
    } catch (error) {}
  };

  render() {
    return (
      <React.Fragment>
        <div className="card w-100">
          <div className="card-header bg-success text-white">
            <div className="row">
              <div className="col">
                Item quantity
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={this.props.closeModal}
                  style={{
                    display: "inline",
                    float: "right",
                    marginTop: "-0.4rem",
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          </div>
          <div className="card-body mt-0 ">
            <div
              style={{
                paddingTop: "0.75rem",
                width: "9rem",
                height: "2.5rem",
              }}
            >
              {this.rSB("Item Details", "itemDetails", this.state.itemDetails, {
                value: "itemId",
                label: "item",
              })}
            </div>
          </div>
          <div className="col-12 mt-2">
            <Table
              clsName="table table-striped table-bordered table-hover"
              tableMeta={this.tableMeta}
              data={this.state.selectItem}
              dropMenu={[]}
            />
          </div>
          <div className="col-4 mt-2">
            <table className="table  table-striped m-1 p-0 border">
              <tbody>
                {this.state.selectedStockItem.map((inv) => {
                  return (
                    <tr
                      key={inv.itemId + inv.storeBaysId + inv.serial}
                      className="m-0 p-0"
                    >
                      <td>{inv?.item}</td>
                      <td>{inv?.storeBaysId}</td>
                      <td>{inv?.slNo}</td>
                      <td>
                        {inv?.quantity}{" "}
                        <span
                          className="badge badge-danger ml-4 mr-1 mt-2 mb-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.handleRemoveItem(inv)}
                        >
                          X
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {this.state.selectedStockItem.length > 0 && (
              <button
                type="button"
                className="btn btn-sm btn-success pull-right mt-2"
                onClick={this.handleInventoryItemSubmit}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BarcodeProductModal;
