import React from "react";
import FormLib from "./../../_common/FormLib";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { ticketListReload } from "../../../store";
import { connect } from "react-redux";
import Table from "./../../_common/Table";
import Pagination from "../../_common/Pagination";
import { setTicketActionsProdStatus } from "./../../../services/Tickets/TicketProcessServices";

class TicketProductSummaryFurn extends FormLib {
    state = {
        errors: {},
        curTicketDetails: [],
        curProductDetails: [],
        pageHide: "false",
        pageCurrent: 1,
        pageSize: 1,

    };
    tableMeta = {
        key: "ticketProductId",
        columns: [
            { path: "productName", label: "Product Name" },
            { path: "identificationNo", label: "Identification Number" },
            {
                key: "playerFeId",
                action: (r) => (
                    <>
                        {r.firstName && (
                            <>
                                {r.firstName + " " + r.lastName + " | " + r.userName}
                            </>
                        )}
                    </>
                ),
                icon: () => <>FE Info</>,
            },
            {
                key: "prodQcStatus",
                action: (r) => (
                    <>
                        {r.prodQcStatus && (
                            <a target="_blank" rel="no referrer">
                                {r.prodQcStatus === "assign" ? (
                                    <>
                                        <i class="fa fa-spinner fa-lg text-info ml-2">{"   " + "InProcess"}</i>

                                    </>
                                ) : r.prodQcStatus === "Yes" ? (
                                    <i class="fa fa-check fa-lg text-success ml-2"> {"   " + "YES"}</i>
                                ) : r.prodQcStatus === "No" ? (
                                    <i class="fa fa-times-circle-o fa-lg text-danger ml-2"> {"   " + "NO"}</i>
                                ) : r.prodQcStatus === "DeviceNotAvailable" ? (
                                    <i class="fa fa-times-circle-o fa-lg text-danger ml-2"> {"   " + "DEVICE NOT AVAILABLE"}</i>
                                )
                                    : r.prodQcStatus === "NOT ATTEMPTED" ? (
                                        <i class="fa fa-times-circle-o fa-lg text-danger ml-2"> {"   " + "NOT ATTEMPTED"}</i>
                                    ) : (
                                        <>
                                            <i class="fa fa-clock-o fa-lg text-danger ml-2"> {"   " + "Pending"}</i>
                                            {/* <button
                                                className="badge badge-primary ml-3"
                                                type="button"
                                                onClick={() => this.handleNotAttempted(r)}
                                            >
                                                Not Attempted
                                            </button> */}
                                        </>
                                    )}
                            </a>
                        )}

                    </>
                ),
                icon: () => <>Product Status</>,
            },

            {
                key: "productBoxes",
                action: (r) => {
                    try {
                        let box = r["productBoxes"];
                        if (box) {
                            try {
                                let tdRows = box.map((i) => {
                                    return (
                                        <>
                                            <tr>
                                                <td style={{ color: "green" }}>{i["boxName"]}</td>
                                                <td>
                                                    {i["boxStatus"] && (
                                                        <a target="_blank" rel="no referrer">
                                                            {i["boxStatus"] === "assign" ? (
                                                                <>
                                                                    <i class="fa fa-spinner fa-lg text-info ml-2">{"   " + "InProcess"}</i>

                                                                </>
                                                            ) : i["boxStatus"] === "receivedInGoodCondition" ? (
                                                                <i class="fa fa-check fa-lg text-success ml-2"> {"   " + "YES"}</i>
                                                            ) : i["boxStatus"] === "missing" ? (
                                                                <i class="fa fa-times-circle-o fa-lg text-danger ml-2"> {"   " + "MISSING"}</i>
                                                            ) : i["boxStatus"] === "damaged" ? (
                                                                <i class="fa fa-times-circle-o fa-lg text-danger ml-2"> {"   " + "DAMAGED"}</i>
                                                            )
                                                                : i["boxStatus"] === "NOT ATTEMPTED" ? (
                                                                    <i class="fa fa-times-circle-o fa-lg text-danger ml-2"> {"   " + "NOT ATTEMPTED"}</i>
                                                                ) : (
                                                                    <>
                                                                        <i class="fa fa-clock-o fa-lg text-danger ml-2"> {"   " + "Pending"}</i>
                                                                        {/* <button
                                                                            className="badge badge-primary ml-3"
                                                                            type="button"
                                                                            onClick={() => this.handleNotAttempted(r)}
                                                                        >
                                                                            Not Attempted
                                                                        </button> */}
                                                                    </>
                                                                )}
                                                        </a>
                                                    )}

                                                </td>
                                            </tr>
                                        </>
                                    );
                                });
                                return tdRows;
                            }
                            catch {

                            }
                        } else {
                            return <b>{"No"}</b>;
                        }
                    } catch {
                        console.log("Error in Furniture Flow >>>>>>>>>>prodQcStatus");
                    }
                },
                icon: () => <>Box Status</>,
            },


        ],
    };
    componentDidMount() {
        this.loadInit();
    }











    loadInit() {
        try {
            const curTicketDetails = this.props.ticketPrimarytDetails;
            const curProductDetails = this.props.ticketProductDetails;
            this.setState({ curTicketDetails });
            this.setState({ curProductDetails });
            // this.prodVerification(curProductDetails);
        }
        catch {
            console.log("Error in TicketProductSummaryFurn>>>>>>loadInit");
        }
    }
    // prodVerification = async (curProductDetails) => {
    //     curProductDetails.map((r) => {
    //         if (r.prodQcStatus === "assign" || r.prodQcStatus === "open") {
    //             this.props.handleConfirmProductVerification();
    //         }
    //     })

    // }

    handlePageChange = (pageNo) => {
        this.setState({ pageCurrent: pageNo });
    };

    render() {
        const ticketDetails = this.state.curTicketDetails;
        const productDetails = this.state.curProductDetails;
        return (
            <>
                {this.state.pageHide === "false" && (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card ">
                                <div className="card-body bg-light">
                                    <div className="form-subHeader">
                                        <b>Ticket Products Verification - {ticketDetails.ticketNo}</b>
                                    </div>
                                    <div className="row mt-4 ">
                                        {/* {JSON.stringify(productDetails)} */}
                                        <div className="container-fluid">
                                            <Table
                                                clsName="table table-striped table-bordered table-hover"
                                                tableMeta={this.tableMeta}
                                                data={productDetails}
                                            />

                                            {/* <Pagination
                                                pageNo={this.state.pageCurrent}
                                                perPage={this.state.pageSize}
                                                itemsCount={pageCount}
                                                onPageChange={this.handlePageChange}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        )
    }


}

const mapStateToProps = (state) => {
    return {
        ticketListReloadState: state.ticketProcess,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(TicketProductSummaryFurn);