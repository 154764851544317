import React from "react";
import ZTitle from "../../_subhead/ZTitle";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import * as XLSX from "xlsx";
import { uploadExcelData } from "./../../../services/requestV1/requestServices";

class ExcelUpload extends FormLib {
  subHeading = "User";
  state = {
    data: { fileUpload: "" },
    errors: {},
    excelFile: null,
    excelFileError: null,
    excelData: null,
    processState: [],
    processStateCls: [],
  };
  schema = {
    fileUpload: Joi.string().required().label("fileUpload"),
  };

  localHandleChange = (e) => {
    this.handleValidation(e);

    this.setState({
      excelFile: null,
      excelFileError: null,
      excelData: null,
      processState: [],
      processStateCls: [],
    });

    if (e.target.name === "fileUpload") {
      console.log("change in file upload");
      this.handleFile(e);
    }
  };

  // handle File
  fileType = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  handleFile = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      // console.log(selectedFile.type);
      if (selectedFile && this.fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          //   setExcelFileError(null);
          //   setExcelFile(e.target.result);
          this.setState({ excelFileError: null });
          this.setState({ excelFile: e.target.result });
          // console.log(e.target.result);
        };
      } else {
        // setExcelFileError('Please select only excel file types');
        // setExcelFile(null);
        this.setState({
          excelFileError: "Please select only excel file types",
        });
        this.setState({ excelFile: null });
      }
    } else {
      console.log("plz select your file");
    }
  };

  doSubmit() {
    console.log(this.state.data);
  }

  //submit function
  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.excelFile !== null) {
      const workbook = XLSX.read(this.state.excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data = XLSX.utils.sheet_to_json(worksheet);
      //   setExcelData(data);
      this.setState({ excelData: data });

      // console.log(data);
    } else {
      //   setExcelData(null);
      this.setState({ excelData: null });

      console.log("no data");
    }
  };
  async handleAPIHit() {
    let processState;
    let processStateCls;
    let res;
    this.setState({ processState: [] });

    let i = 0;
    for (let r in this.state.excelData) {
      processState = this.state.processState;
      processStateCls = this.state.processStateCls;

      processStateCls[i] = "info";
      processState[i] = "Processing";
      this.setState({ processState });
      // console.log(JSON.stringify(r));
      if (
        this.state.excelData[r]["UTR NO"] &&
        this.state.excelData[r]["UTR NO"] !== "" &&
        this.state.excelData[r]["Reference_no"] &&
        this.state.excelData[r]["Reference_no"] !== "" &&
        this.state.excelData[r]["Acct_Debit_date"] &&
        this.state.excelData[r]["Acct_Debit_date"] !== ""
      ) {
        res = await uploadExcelData(this.state.excelData[r]);
        processState[i] = res.data.msg;
        processStateCls[i] = "info";
      } else {
        processState[i] = "No UTR | Ref No | Date";
        processStateCls[i] = "danger";
      }
      i++;
      this.setState({ processState });
    }
  }
  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  render() {
    return (
      <React.Fragment>
        <ZTitle subHeading="Expenses :: Excel Upload" />
        <div className="pageContent">
          <div className="card">
            <div className="card-body">
              <form onSubmit={this.handleSubmit}>
                <div className="form-row">
                  {this.rFU("Upload File", "fileUpload", "col-md-4")}
                  {/* <input type='file' name="fileUpload" className='form-control' onChange={()=> this.handleFile} required></input> */}
                  <div className="col-md-4">
                    {this.rBtnS("Upload", "upload", "col-md-4")}
                  </div>
                </div>
              </form>
              <hr />

              <div className="viewer">
                {this.state.excelData === null && <> No file selected</>}
                {this.state.excelData !== null && (
                  <>
                    <button
                      className="btn  btn-info  btn-sm"
                      onClick={() => this.handleAPIHit()}
                    >
                      Submit
                    </button>
                    <div className="table-responsive">
                      <table className="table table-responsive">
                        <thead>
                          <tr>
                            <th>Action</th>
                            <th>File Sequence Num</th>
                            <th>Pymt Prod Type Code</th>
                            <th>Pymt Mode</th>
                            <th>Debit Acct No</th>
                            <th>Beneficiary Name</th>
                            <th>Beneficiary Account No</th>
                            <th>Bene IFSC Code</th>
                            <th>Amount</th>
                            <th>Debit narration</th>
                            <th>Credit Narration</th>
                            <th>Mobile Number</th>
                            <th>Email Id</th>
                            <th>Remark</th>
                            <th style={{ width: 300 }}>Pymt_Date</th>
                            <th>Reference No</th>
                            <th>Addl_info1</th>
                            <th>Addl_info2</th>
                            <th>Addl_info3</th>
                            <th>Addl_info4</th>
                            <th>Addl_info5</th>
                            <th>Status</th>
                            <th style={{ width: "300" }}>Current_step</th>
                            <th>File_name</th>
                            <th>Rejected_by</th>
                            <th>Rejected_Reason</th>
                            <th>Acct_Debit_date</th>
                            <th>Customer Ref No</th>
                            <th>UTR No</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <Data excelData={this.state.excelData} /> */}
                          {this.state.excelData.map(
                            (individualExcelData, i) => (
                              <tr
                                key={
                                  individualExcelData.Beneficiary_Account_No +
                                  individualExcelData.Reference_no
                                }
                              >
                                <td>
                                  <span
                                    className={
                                      "badge badge-" +
                                      this.state.processStateCls[i]
                                    }
                                  >
                                    {this.state.processState[i]}
                                  </span>
                                </td>
                                <td>{individualExcelData.File_Sequence_Num}</td>
                                <td>
                                  {individualExcelData.Pymt_Prod_Type_Code}
                                </td>
                                <td>{individualExcelData.Pymt_Mode}</td>
                                <td>{individualExcelData.Debit_Acct_no}</td>

                                <td>
                                  {individualExcelData["Beneficiary Name"]}
                                </td>
                                <td>
                                  {
                                    individualExcelData[
                                    "Beneficiary Account No"
                                    ]
                                  }
                                </td>
                                <td>{individualExcelData.Bene_IFSC_Code}</td>
                                <td>{individualExcelData.Amount}</td>
                                <td>
                                  {individualExcelData["Debit narration"]}
                                </td>
                                <td>
                                  {individualExcelData["Credit narration"]}
                                </td>
                                <td>{individualExcelData.Mobile_Numder}</td>
                                <td>{individualExcelData.Email_id}</td>
                                <td>{individualExcelData.Remark}</td>
                                <td>{individualExcelData.Pymt_Date}</td>
                                <td>{individualExcelData.Reference_no}</td>
                                <td>{individualExcelData.Addl_info1}</td>
                                <td>{individualExcelData.Addl_info2}</td>
                                <td>{individualExcelData.Addl_info3}</td>
                                <td>{individualExcelData.Addl_info4}</td>
                                <td>{individualExcelData.Addl_info5}</td>
                                <td>{individualExcelData.STATUS}</td>
                                <td>{individualExcelData.Current_Step}</td>
                                <td>{individualExcelData.File_name}</td>
                                <td>{individualExcelData.Rejected_by}</td>
                                <td>{individualExcelData.Rejection_Reason}</td>
                                <td>{individualExcelData.Acct_Debit_date}</td>
                                <td>{individualExcelData.Customer_Ref_No}</td>
                                <td>{individualExcelData["UTR NO"]}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </div>
              <br />

              <div className="form-group col-md-12 text-center">
                <p className="mt-5 mb-3 text-muted">&copy; Bizlog</p>
              </div>
            </div>
          </div>

          <div className="container-fluid"></div>
        </div>
      </React.Fragment>
    );
  }
}
export default ExcelUpload;
