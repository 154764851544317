import React from "react";
import LocationTitle from "../../_subhead/LocationTitle";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import Table from "../../_common/Table";
import toast from "../../../services/_toastService";
import Pagination from "./../../_common/Pagination";
import {getProductModel,
    createProductModel,
    updateProductModel,
    deleteProductModel,
    getModelBySearch} from "../../../services/products/productCatModelServices";
import {getBrands} from "../../../services/products/ProductsCatBrandsServices";
import ModelProductsBrand from "./ModelProductsBrand";
import {getAllProducts} from "../../../services/products/productServices";


class ProductsCategoryModels extends FormLib{
    state = {
        loading: true,
        data: {
            prodModelId: "", 
            prodBrandId: "", 
            productId: "",
            model: "",
            modelCode: "", 
            status: "active",  
            modelSearch:""          
        },
        errors: {},
        productCatBrands: [],
        productModels: [],
        products: [],
        pageLinks: {
            pageNo: 1,
            perPage: 0,
            itemsCount: 0,
            lastPage: 0,
        },
        curProductsBrand: { prodBrandId: "" },
        isDatafound :true
    }
    schema = {
        prodModelId: Joi.optional().label("prodModelId"),
        prodBrandId: Joi.optional().label("prodBrandId"),
        modelSearch: Joi.optional().label("modelSearch"),
        productId: Joi.string().required().label("Product"),
        model: Joi.string().required().label("Model"),
        modelCode: Joi.string().required().label("Model Code"),
        status: Joi.string().required().label("status"),
    };
    componentDidMount() {
        this.loadProductBrands();
        this.loadProducts();
    }

    resetData() {
        this.setState({
            data: {
                prodBrandId: this.state.curProductsBrand.prodBrandId,
                prodModelId: "",
                productId: "",
                model: "",
                modelCode: "",
                status: "active",   
                modelSearch:""           
            }
        });
    }

    status = [
        { sortKey: "active", sort: "Active" },
        { sortKey: "inactive", sort: "Inactive" },
    ];
    stsConf = {
        value: "sortKey",
        label: "sort",
    };
  

    tableMeta = {
        key: "prodModelId",
        columns: [
            {
                key: "PROD_MODEL_ID",
                action: (r) => (
                  <div className="btn-group pull-right">
                    <button
                      type="button"
                      className="btn dropdown-icon"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fa fa-ellipsis-v"></i>
                    </button>
        
                    <div className="dropdown-menu dropdown-menu-left">
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => this.handleOnAction(r)}
                      >
                        <i className="fa fa-pencil" aria-hidden="true"></i> Edit
                      </button>
                    </div>
                  </div>
                ),
        
                icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
              },
            // { path: "prodModelId", label: "Prod Model Id" },
            // { path: "prodBrandId", label: "Prod Brand Id" },
            { path: "product", label: "Product" },
            { path: "brand", label: "Brand" },
            { path: "model", label: "Model Name" },
            { path: "modelCode", label: "Code" },
            { path: "status", label: "Status" },
            
        ],
    };

    loadProductBrands = async () => {
        this.setState({ loading: true });
        const res = await getBrands();
        if (res.data.success === true) {
            this.setState({ productCatBrands: res.data.data.productsCatBrands });
        } else {
            console.log("Error while get Product Category : " + res.data.msg);
        }
        this.setState({ loading: false });
    }

    loadProducts = async () => {
        this.setState({ loading: true });
        const res = await getAllProducts();
        if (res.data.success === true) {
            this.setState({ products: res.data.data.products });
        } else {
            console.log("Error while get Product Category : " + res.data.msg);
        }
        this.setState({ loading: false });
    }

    loadProductModel = async (prodBrandId, pageNo) => {
        this.setState({ loading: true });
        const res = await getProductModel(prodBrandId, pageNo);
        if (res.data.success === true) {
            if (res.data.data.length != 0) {
                this.setState({ productModels: res.data.data.productscatmodels });
                this.setState({ pageLinks: res.data.data.pageLinks });
                toast.toastSuccess(res.data.msg);
            } else {
                toast.toastInfo("No product found for this brand");
                this.setState({ productModels: [] });
                this.setState({
                    pageLinks: {
                        "pageNo": 1,
                        "perPage": 0,
                        "itemsCount": 0,
                        "lastPage": 0
                    }
                });
            }
        } else {
            console.log("Error while get Product Category : " + res.data.msg);
        }
        this.setState({ loading: false });
    }

    handleOnAction= (r) => {
        let onEditParam = {};
        onEditParam.prodModelId = r.prodModelId;
        onEditParam.prodBrandId = r.prodBrandId;
        onEditParam.productId = r.productId;
        onEditParam.model = r.model;
        onEditParam.modelCode = r.modelCode;
        onEditParam.status = r.status;
        this.setState({ data: onEditParam });
    }

    handlePageChange = (pageNo) => {
        this.props.history.push("/products/model/"+ pageNo);
        this.loadProductModel(this.state.curProductsBrand.prodBrandId,pageNo);
    };

    loadCurrectCategory = async (prodBrandId,modReset = true) => {
        if (modReset === true) {
            this.setState({ productsCategory: [] });
            this.setState({ curProductsBrand: { prodBrandId: ""} });
          }
        let curProductBrand;
        const filter = this.state.productCatBrands.filter((r) => {
          return r.prodBrandId === prodBrandId ? r : null;
        });
    
        if (!filter[0]) {
            curProductBrand = { prodBrandId: "" };
        } else {
            curProductBrand = filter[0];
        }
        this.setState({ curProductsBrand: curProductBrand });

        if (prodBrandId !== "") {
          this.loadProductModel(prodBrandId,this.state.pageLinks.pageNo);
        } else {
          this.setState({ products: []});
        }
    };

    handleCategoryChange = (e) => {
        this.loadCurrectCategory(e.target.value);
    }

    localHandleChange = (e) => {
        if (e.target.id === "prodBrandId") {
            this.handleCategoryChange(e);
        }
    }

    refresh=()=> {
        this.loadProductBrands();
        this.loadProductModel(this.state.curProductsBrand.prodBrandId, this.state.pageLinks.pageNo);
        this.resetData();
        this.setState({ productModels: [] });
        this.setState({
            pageLinks: {
                "pageNo": 1,
                "perPage": 0,
                "itemsCount": 0,
                "lastPage": 0
            }
        });
    }

    doSubmit = async () => {
        let res;
        if (this.state.btnValue === "create") {
            res = await createProductModel(this.state.data);
            this.resetData();
        }

        if (this.state.btnValue === "edit") {
            res = await updateProductModel(this.state.data);
            this.resetData();
        }

        if (this.state.btnValue === "delete") {
            res = await deleteProductModel(this.state.data);
            this.resetData();
        }

        if (res.data.success === true) {
            toast.toastSuccess(res.data.msg);
            this.loadProductModel(this.state.curProductsBrand.prodBrandId, this.state.pageLinks.pageNo);
        } else {
            toast.toastError(res.data.msg);
        }
    }

    search = async () => {
        let param = {
          "searchString": this.state.data.modelSearch,
          "prodBrandId": this.state.curProductsBrand.prodBrandId
        }
        let res = await getModelBySearch(param, this.state.pageLinks.pageNo);
        if (res.data.success) {
            if(res.data.data.models.length){
                this.setState({ productModels: res.data.data.models });
                this.setState({ pageLinks: res.data.data.pageLinks });
            }else{
                toast.toastInfo("No Data found");
            }
        } else {
          toast.toastError(res.data.msg);
        }
      }



    render() {
        const { pageNo, perPage, itemsCount } = this.state.pageLinks;
        return (
            <React.Fragment>
                <LocationTitle subHeading="Products Model" />
                <div className="pageContent">
                    <div className="row">
                        <div className="col-6">
                            {this.rSB(
                                "Product Category Brands",
                                "prodBrandId",
                                this.state.productCatBrands,
                                {
                                    value: "prodBrandId", 
                                    label: "brand",
                                }
                            )}
                        </div>
                        <div className="menu col-md-2 ">
                            {this.state.curProductsBrand.prodBrandId === "" && (
                                <button
                                    type="button"
                                    className="btn btn-sm btn-info pull-left"
                                    data-toggle="modal"
                                    data-target="#myMaterialModal">
                                    <i className={"fa fa-plus"} />
                                </button>
                            )}
                            {this.state.curProductsBrand.prodBrandId !== "" && (
                                <button
                                    type="button"
                                    className="btn btn-sm btn-success pull-left"
                                    data-toggle="modal"
                                    data-target="#myMaterialModal">
                                    <i className={"fa  fa-pencil"} />
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-sm btn-secondary pull-left"
                            onClick={() => {this.refresh()}}>
                                <i className={"fa fa-refresh"} />
                            </button>
                        </div>
                        {this.state.curProductsBrand.prodBrandId !== "" && this.state.productModels.length != 0 && (
                            <>
                                <div className="col-3">
                                    {this.rTB("Search...", "modelSearch")}
                                </div>
                                <div className="col-1">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-outline-info pull-left"
                                        title=" Search by name | code"
                                        disabled={this.state.data.modelSearch===""}
                                        onClick={() => this.search()}
                                    >
                                        <i className={"fa fa-search"} />
                                    </button>
                                </div>

                            </>
                        )
                        }


                    </div>

                    {this.state.curProductsBrand.prodBrandId === "" && (
                        <div class="jumbotron jumbotron-fluid">
                          <p class="lead text-center">Select Product Category Brand</p>
                      </div>
                    )}
                    {this.state.curProductsBrand.prodBrandId !== "" && (
                        <div className="row">
                            <div className="col-md-6">
                                {this.state.loading && (
                                    <div className="container-fluid">
                                        <h1>Loading...</h1>
                                    </div>
                                )}
                                {!this.state.loading && (
                                    <div className="container-fluid">
                                        <Table
                                            clsName="table table-striped tabele-bordered table-hover"
                                            tableMeta={this.tableMeta}
                                            data={this.state.productModels}
                                            dropMenu={[]}
                                        />
                                        <Pagination
                                            pageNo={pageNo}
                                            perPage={perPage}
                                            itemsCount={itemsCount}
                                            onPageChange={this.handlePageChange}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="col-md-6">
                                <div className="card p-2">
                                    <div className="card-body">
                                    <form>
                                        <div className="form-row">
                                            <div className="col-6">
                                                {this.rTB("Model Name", "model")}
                                            </div>
                                            <div className="col-6 mt-1">
                                                {this.rSB("Product", "productId", this.state.products, { value: "productId", label: "product" })}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-6">  {this.rTB("Model Code", "modelCode")} </div>
                                            <div className="col-6"> {this.rRBL("Status", "status", this.status, this.stsConf)} </div>
                                        </div>
                                    </form>
                                        <br />
                                        {this.state.data.prodModelId === "" && this.rBtnSP("Create", "create")}
                                        {this.state.data.prodModelId !== "" && (
                                            <>
                                                {this.rBtnSS("Edit", "edit")}
                                                {this.rBtnSD("Delete", "delete")}
                                              
                                            </>
                                        )}
                                        <button type="button" className="btn btn-sm btn-warning pull-right"
                                            onClick={() => this.resetData()}
                                            title="Reset">
                                            <i className={"fa fa-unlink"} />
                                        </button>
                                    
                                </div>
                                </div>
                            </div>
                        </div>
                    )}
                    
                </div>
                <ModelProductsBrand curProductsBrand={this.state.curProductsBrand} 
                key={this.state.curProductsBrand.prodBrandId}
                onloadBrand = {this.loadProductBrands}
                />
            </React.Fragment>
        ); 
    }
}

export default ProductsCategoryModels;