import Joi from "joi-browser";
import FormLib from "../../../_common/FormLib";
import React from "react";
import { getActiveClients } from "../../../../services/masterLocations/clientService";
import toast from "../../../../services/_toastService";
import LocationTitle from "../../../_subhead/LocationTitle";
import InwardInventory from "../InwardInventory";
import OutwardInventory from "../OutwardInventory";
import Inventory from "../Inventory";
import StoresItems from "../StoresItems";
import NewInventory from "./NewInventory";
import HubInwardItem from "../HubInwardItem";

class InventoryNewScreen extends FormLib {
    state = {
        data: {
            hubId: "",
            teamId: "",
        },
        hubs: [],
        clients: [],
        errors: {},
        btnValue: "",
        mode: "Inventory",
    };

    constructor(props) {
        super(props);
        // Bind the event handler in the constructor if needed
        this.onModeChange = this.onModeChange.bind(this);
    }

    onModeChange = (mode) => {
        // console.log("Changing mode to", mode);
        this.setState({ mode: mode });
    }

    componentDidMount() {
        this.loadClients();
        this.loadHubs();
    }

    schema = {

        teamId: Joi.string().required().label("clientId"),
        hubId: Joi.string().required().label("hubId"),


    }
    async loadClients() {
        this.setState({ loading: true });
        const clientResp = await getActiveClients("active");

        if (clientResp.data.success === true) {
            this.setState({ clients: clientResp.data.data.clients });
        } else {
            toast.toastError(clientResp.data.msg);
        }
        this.setState({ loading: false });
    }

    async loadHubs() {
        try {
            let hubList = JSON.parse(localStorage.getItem("hubs"));
            this.setState({ hubs: hubList });
            let curHub = localStorage.getItem("curHub");
            // console.log({ curHub });
            this.setState(prevState => ({
                data: {
                    ...prevState.data,
                    hubId: curHub || "", // Set to an empty string if curHub is undefined
                }
            }));
        } catch {
            console.log("Error in newInventoryScreen>>>>>> loadHubs");
        }
    }

    refreshListCallback = (callback) => {
        this.setState({ refreshCallback: callback });
    };

    render() {
        // console.log("Current mode:", this.state.mode);

        return (
            <React.Fragment>
                <LocationTitle subHeading="Inventory" />
                <div className="pageContent">
                    <div className="row">
                        {!this.state.loading && (
                            <>
                                <div className="col-12 ml-3">
                                    <div className="form-row col-12">
                                        {this.rSB(
                                            "Client *",
                                            "teamId",
                                            this.state.clients,
                                            { value: "TEAM_ID", label: "NAME" },
                                            " col-md-3"
                                        )}
                                        {this.rSB(
                                            "Hub *",
                                            "hubId",
                                            this.state.hubs,
                                            { value: "hubId", label: "hub" },
                                            " col-md-2 ",
                                            this.state.data.hubId
                                        )}
                                    </div>
                                    {this.state.data.hubId !== "" && this.state.data.teamId !== "" && (
                                        <div style={{ display: "flex", flexDirection: "row", marginTop: "2rem", backgroundColor: "#0d6efd", maxWidth: "90vw" }}>
                                            <div>
                                                <button onClick={this.onModeChange.bind(this, "Inventory")}
                                                    className={
                                                        this.state.mode === "Inventory"
                                                            ? " btn btn-warning btn-sm "
                                                            : "btn  btn-default btn-sm "
                                                    }
                                                    style={{ paddingLeft: "6.5rem", paddingRight: "6.5rem", color: "#fff" }}
                                                >
                                                    Inventory
                                                </button>
                                            </div>
                                            <div>
                                                <button onClick={() => this.onModeChange("Inward")}
                                                    className={
                                                        this.state.mode === "Inward"
                                                            ? " btn btn-warning btn-sm "
                                                            : "btn  btn-default btn-sm "
                                                    }
                                                    style={{ paddingLeft: "6.5rem", paddingRight: "6.5rem", color: "#fff" }}
                                                >
                                                    Inward
                                                </button>
                                            </div>
                                            <div>
                                                <button onClick={() => this.onModeChange("Outward")}
                                                    className={
                                                        this.state.mode === "Outward"
                                                            ? " btn btn-warning btn-sm "
                                                            : "btn  btn-default btn-sm "
                                                    }
                                                    style={{ paddingLeft: "6.5rem", paddingRight: "6.5rem", color: "#fff" }}
                                                >
                                                    Outward
                                                </button>
                                            </div>
                                            <div>
                                                <button onClick={() => this.onModeChange("Hub Inward")}
                                                    className={
                                                        this.state.mode === "Hub Inward"
                                                            ? " btn btn-warning btn-sm "
                                                            : "btn  btn-default btn-sm "
                                                    }
                                                    style={{ paddingLeft: "6.5rem", paddingRight: "6.5rem", color: "#fff" }}
                                                >
                                                    Hub Inward Items
                                                </button>
                                            </div>

                                        </div>
                                    )}


                                </div>
                            </>
                        )}
                    </div>

                    <hr />
                    {this.state.data.hubId !== "" && this.state.data.teamId !== "" && (
                        <div className="tab-content">
                            {this.state.mode === "Inward" && (
                                <InwardInventory
                                    refreshListCallback={this.refreshListCallback}
                                    hubValue={this.state.data.hubId}
                                    teamValue={this.state.data.teamId}
                                />
                            )}
                            {this.state.mode === "Outward" && (
                                <OutwardInventory
                                    refreshListCallback={this.refreshListCallback}
                                    hubValue={this.state.data.hubId}
                                    teamValue={this.state.data.teamId}
                                />
                            )}
                            {this.state.mode === "Inventory" && (
                                <NewInventory
                                    refreshListCallback={this.refreshListCallback}
                                    hubValue={this.state.data.hubId}
                                    teamValue={this.state.data.teamId}
                                />
                            )}
                            {this.state.mode === "Hub Inward" && (
                                <HubInwardItem
                                    refreshListCallback={this.refreshListCallback}
                                    hubValue={this.state.data.hubId}
                                    teamValue={this.state.data.teamId}
                                />
                            )}
                        </div>
                    )}


                    {/* {this.state.mode === "Inventory" && (
                    <StoresItems
                        teamValue={this.state.data.teamId} />
                    )} */}

                </div>


            </React.Fragment>
        )
    }
}

export default InventoryNewScreen;