import React from "react";
import FormLib from "./../../_common/FormLib";
import Table from "./../../_common/Table";
import { assignPrdToBlock, loadTicketJobs } from './../../../services/Tickets/TicketProcessServices';
import { toast } from 'react-toastify';
import { IstToUTC } from "../../../utils/DateUtils";

export default class BlockAssignment extends FormLib {
    state = {
        data: {},
        errors: {},
        curProductDetails: [],
        curTicketDetails: [],
        curBlockDetails: []
    };



    componentDidMount() {
        this.loadInit();
        this.loadTicketJobs();
    }

    loadInit() {
        const curProductDetails = this.props.productDetails;
        const curTicketDetails = this.props.ticketDetails;
        this.setState({ curProductDetails });
        this.setState({ curTicketDetails });
    }
    loadTicketJobs = async () => {
        let resp = await loadTicketJobs(this.props.ticketDetails.ticketNo);
        if (resp.data.success === true) {
            this.setState({ curBlockDetails: resp.data.data[0].blocks })

        } else {
            toast.error(() => <div>{resp.data.msg}</div>);
        }
    }

    tableMeta = {
        key: "ticketProductId",
        columns: [
            {
                key: "ticketProductId",
                actionKey: "bt",
                action: (r) => (
                    <>
                        <span
                            className="badge badge-primary link-over"
                            onClick={() =>
                                this.onBlockSelection("sb_" + r["ticketProductId"], r)
                            }
                        >
                            Save
                        </span>
                    </>
                ),
                icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
            },
            {
                key: "ticketProductId",
                actionKey: "sb",
                action: (r) => (
                    <>
                        {this.rSB(
                            "",
                            "sb_" + r["ticketProductId"],
                            this.state.curBlockDetails,
                            { value: "ticketBlockId", label: "blockCode" },
                            ""
                        )}
                    </>
                ),

                icon: () => "Jobs",
                cusClass: " ",
            },
            { path: "productName", label: "Product Name" },
            { path: "identificationNo", label: "Identification No" },
            { path: "srcCity", label: "Source City" },
            { path: "srcPincode", label: "Source Pincode" },
            { path: "dstCity", label: "Destination City" },
            { path: "dstPincode", label: "Destination Pincode" },
        ],
    };
    localHandleChange = (e) => {
        if (e.target.id) {
            this.setState({ ticketBlockId: e.target.value })
        }
    };
    onBlockSelection = (sbId, r) => {
        this.assignBlock(r);
    };

    async assignBlock(data) {
        let params =
        {
            "ticketBlockId": this.state.ticketBlockId,
            "ticketProductId": data.ticketProductId
        }
        let resp = await assignPrdToBlock(params);
        if (resp.data.success === true) {
            this.props.handleBlockAssignment();
            resp.data && toast.success(() => <div>{resp.data.msg}</div>);
            !resp.data && toast.warning(() => <div>{resp.data.msg}</div>);
        } else {
            toast.error(() => <div>{resp.data.msg}</div>);
        }
    }
    render() {
        const productDetails = this.state.curProductDetails;
        return (
            <>
                {this.props.loading && (
                    <div className="container-fluid">
                        <div> Loading Tickets...</div>
                    </div>
                )}
                {!this.props.loading && (
                    <>
                        <div className="container-fluid">
                            <Table
                                clsName="table table-striped table-bordered table-hover"
                                tableMeta={this.tableMeta}
                                data={productDetails}
                                dropMenu={[]}
                            />
                        </div>
                    </>
                )}
            </>
        );
    }
}
