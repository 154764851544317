import _http from "./../_httpService";

import config from "./../../config.json";
import { tDate2yyyymmddhhmmss } from "./../../utils/DateUtils";

const baseURL = config[config.build].apiURLValidata;

function api(path, subURL = "/4team/reports") {
  path = path !== "" ? "/" + path : "";
  return `${baseURL}${subURL}${path}`;
}

export function createReport(req) {
  const param = { ...req };
  delete param.reportId;
  param.reportFrom = tDate2yyyymmddhhmmss(param.reportFrom);
  param.reportTo = tDate2yyyymmddhhmmss(param.reportTo);

  return _http.post(api(""), param, _http.headerJWT());
}

export function getReports() {
  return _http.get(api(""), _http.headerJWT());
}

export function getDailyReports(req, pageNo) {
  // return _http.put(api("daily-reports/" + pageNo), param, _http.headerJWT());
  // return _http.get(api(pageNo), param, _http.headerJWT());
  // return reports;
  try {

    const param = { ...req };
    param.reportFrom = tDate2yyyymmddhhmmss(param.reportFrom + "T00:00");
    param.reportTo = tDate2yyyymmddhhmmss(param.reportTo + "T23:59");
    return _http.put(api(pageNo), param, _http.headerJWT());
  }
  catch {
    return undefined;
  }
}
