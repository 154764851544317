import _http from "./../_httpService";

import config from "./../../config.json";

const apiURLCorePHP = config[config.build].apiURLCorePHP + "tasktype.php";

// function buildUrl(id) {
//   return `${apiEndpoint}/${id}`;
// }

export function getTasktype() {
  return _http.get(apiURLCorePHP, _http.headerJWT());
}
