import React from "react";
import FormLib from "./../../_common/FormLib";
import Joi from "joi-browser";
import toast from "../../../services/_toastService";

import {
  createPackingMaterial,
  savePackingMaterial,
  deletePackingMaterial,
} from "./../../../services/packing/PackingMaterialService";

class ModalPackingMaterial extends FormLib {
  state = {
    data: {
      packMaterialId: "",
      packCode: "",
      name: "",
      type: "",
      cost: "",
      sizeMin: "",
      sizeMax: "",
      lmax: "",
      bmin: "",
      bmax: "",
      lmin: "",
      wmax: "",
      wmin: "",
      status: "",
    },
    errors: {},
  };

  schema = {
    packMaterialId: Joi.optional().label("PackMaterialId"),
    packCode: Joi.number().required().label("PackCode"),
    name: Joi.string().required().label("Packing Material Name"),
    type: Joi.string().required().label("Packing Material Type"),
    cost: Joi.number().required().label("cost"),
    sizeMin: Joi.number().required().label("Min Size"),
    sizeMax: Joi.number().required().label("Max Size"),
    lmax: Joi.number().required().label("Min Length"),
    lmin: Joi.number().required().label("Min Length"),
    bmin: Joi.number().required().label("Min Breadth"),
    bmax: Joi.number().required().label("Max Breadth"),
    wmax: Joi.number().required().label("Max Width"),
    wmin: Joi.number().required().label("Min Width"),
    status: Joi.string().required().label("Status"),
  };
  componentDidMount() {
    this.setCurMaterial(this.props.curPackingMaterial);
  }

  setCurMaterial(p) {
    if (p.packMaterialId !== "") {
      this.setState({ data: p });
    } else {
      this.resetData();
    }
  }

  async doSubmit() {
    // console.log(JSON.stringify(this.state.data));
    let res;
    if (this.state.btnValue === "create") {
      res = await createPackingMaterial(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await savePackingMaterial(this.state.data);
    }
    if (this.state.btnValue === "delete") {
      res = await deletePackingMaterial(this.state.data);
      this.resetData();
    }
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.props.onloadMaterials();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  resetData() {
    this.setState({
      data: {
        packMaterialId: "",
        packCode: "",
        name: "",
        type: "",
        cost: "",
        sizeMin: "",
        sizeMax: "",
        lmax: "",
        lmin: "",
        bmin: "",
        bmax: "",
        wmax: "",
        wmin: "",
        status: "active",
      },
    });
  }
  materialType = [
    {
      sortKey: "Cover",
      sort: "Cover",
    },
    {
      sortKey: "Box",
      sort: "Box",
    },
    {
      sortKey: "Pouch",
      sort: "Pouch",
    },
    {
      sortKey: "Wrap",
      sort: "Wrap",
    },
    {
      sortKey: "Tape",
      sort: "Tape",
    },
    {
      sortKey: "Others",
      sort: "Others",
    },
  ];
  materialTypeConf = {
    value: "sortKey",
    label: "sort",
  };
  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };
  render() {
    // const tasks = this.props.tasks;
    // const curUserstoryId = this.props.curUserstoryId;
    return (
      <div className="container-fluid">
        <div className="modal fade" id="myMaterialModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Packing Material</h4>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>

              <div className="modal-body ">
                <form>
                  <div className="form-row">
                    {this.rTBH("PackMaterialId", "packMaterialId", "col-3")}
                    <div className="col-3">
                      {this.rSB(
                        "Packing Material Type",
                        "type",
                        this.materialType,
                        {
                          value: "sortKey",
                          label: "sort",
                        }
                      )}
                    </div>
                    {this.rTB("Packing Material Name", "name", "col-6")}
                    {this.rTB("Packing Material Code", "packCode", "col-3")}
                  </div>
                  <div className="form-row">
                    {this.rTB("Minimum Size", "sizeMin", "col-6")}
                    {this.rTB("Maximun Size", "sizeMax", "col-6")}
                  </div>
                  <div className="form-row">
                    {this.rTB("Minimum Length", "lmin", "col-6")}
                    {this.rTB("Maximum Length", "lmax", "col-6")}
                  </div>
                  <div className="form-row">
                    {this.rTB("Minimum Breadth", "bmin", "col-6")}
                    {this.rTB("Maximum Breadth", "bmax", "col-6")}
                  </div>
                  <div className="form-row">
                    {this.rTB("Minimum Width", "wmin", "col-6")}
                    {this.rTB("Maximum Width", "wmax", "col-6")}
                  </div>
                  <div className="form-row">
                    {this.rTB("Cost", "cost", "col-3")}
                    {this.rRBL("Status", "status", this.status, this.stsConf)}
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                {this.state.data.packMaterialId === "" &&
                  this.rBtnSP("Create", "create")}
                {this.state.data.packMaterialId !== "" && (
                  <>
                    {this.rBtnSS("Edit", "edit")}
                    {this.rBtnSD("Delete", "delete")}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ModalPackingMaterial;
