import React from "react";
import { connect } from "react-redux";
import FormLib from "./../../_common/FormLib";
import TicketsList from "./TicketsList";
import TicketActions from "./TicketActions";
import AptSchedular from "./AptSchedular";
import CustomerAvailability from "./CustomerAvailability";
import FeAssignment from "./FeAssignment";
import BlockCreation from "./BlockCreation";
import PackingAssignment from "./PackingAssignment";
import PickUp from "./PickUp";
import Drop from "./Drop";
import TicketClosure from "./TicketClosure";
import FEInfo from "./FEInfo";

import FilesView from "./FilesView";
import ZTitle from "./../../_subhead/ZTitle";
import { ticketListReload } from "../../../store";
import TicketHistory from "./../TicketHistory/TicketHistory";
import TicketActionsInfo from "./TicketActionsInfo";

class ticketProcessWrapper extends FormLib {
  state = {
    curAction: "",
    curTicket: {},
    curBlock: {},
    curMultiTicket: {},
    curMultiBlock: {},
    ticketEnabled: "false",
  };

  componentDidMount() {
    this.handleTicketListReload();
  }

  handleRequestStatusChange = async (mode) => {
    if (this.state.curAction === "") {
      await this.sleep(1);
      this.setState({ curAction: mode });
    } else {
      this.setState({ curAction: mode });
    }
  };

  sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }
  setCurrentTicket = async (b, t, value, ticketData, captureInfo) => {
    this.setState({ bulkAssign: value });
    // this.setState({ ticketHistory: ticketData });
    if (value === "notBulk") {
      if (this.state.curTicket.ticketNo === t.ticketNo) {
        this.setState({
          curAction: "",
          curTicket: {},
          curBlock: {},
          ticketEnabled: "true",
        });
        await this.sleep(1);
        this.setState({ curTicket: t, curBlock: b, ticketEnabled: "true" });
        this.setActionDecider(b, t, ticketData, captureInfo);
      } else {
        this.setState({ curTicket: t, curBlock: b, ticketEnabled: "true" });
        this.setActionDecider(b, t, ticketData, captureInfo);
      }
    } else {
      this.setState({
        curAction: "",
        curMultiTicket: {},
        curMultiBlock: {},
        ticketEnabled: "true",
      });
      await this.sleep(1);
      this.setState({
        curMultiTicket: t,
        curMultiBlock: b,
        ticketEnabled: "true",
      });
      this.setMultiActionDecider(b, t, captureInfo);
    }
  };
  setMultiActionDecider(b, t, captureInfo) {
    if (captureInfo === false) {
      this.handleRequestStatusChange("Appointment");
      this.setState({ hidePage: "false" });
      this.setState({ hideInitiateAction: "true" });
      this.setState({ hidePackingAction: "true" });
      this.setState({ hidePickUpAction: "true" });
      this.setState({ hideDropAction: "true" });
      this.setState({ hideCloseAction: "true" });
      this.setState({ hideProcessAction: "true" });
      this.setState({ hideTicketHistory: "true" });
    } else {
      this.handleRequestStatusChange("TicketActions");
      this.setState({ hidePage: "true" });
      this.setState({ hideInitiateAction: "true" });
      this.setState({ hidePackingAction: "true" });
      this.setState({ hidePickUpAction: "true" });
      this.setState({ hideDropAction: "true" });
      this.setState({ hideCloseAction: "true" });
      this.setState({ hideProcessAction: "true" });
      this.setState({ hideTicketHistory: "true" });
      this.setState({ hideTicketActions: "false" });
    }
  }

  handleTicketListReload = async () => {
    this.props.ticketListReload(
      this.props.ticketListReloadState.ticketProcess.ticketListReload
    );
  };

  containsNumbers(str) {
    return /\d/.test(str);
  }

  setActionDecider = async (b, t, ticketData, captureInfo) => {
    if (
      !t.isPackingReq ||
      t.isPackingReq === null ||
      t.isPackingReq === undefined ||
      t.isPackingReq === "null" ||
      t.isPackingReq === "" ||
      this.containsNumbers(t.isPackingReq) === true ||
      t.isPackingReq.toLowerCase() !== "yes"
    ) {
      t.isPackingReq = "no";
    }
    if (
      !t.isAppointmentReq ||
      t.isAppointmentReq === null ||
      t.isAppointmentReq === undefined ||
      t.isAppointmentReq === "null" ||
      t.isAppointmentReq === "" ||
      this.containsNumbers(t.isAppointmentReq) === true ||
      t.isAppointmentReq.toLowerCase() !== "yes"
    ) {
      t.isAppointmentReq = "no";
    }
    if (ticketData === "ticketHistory") {
      this.handleRequestStatusChange("");
      await this.sleep(1);
      this.handleRequestStatusChange("TicketHistory");
      this.setState({ hideTicketHistory: "false" });
    } else {
      this.setState({ hideTicketHistory: "true" });
    }
    if (captureInfo === true && ticketData !== "ticketHistory") {
      this.handleRequestStatusChange("");
      await this.sleep(1);
      this.handleRequestStatusChange("TicketActions");
      this.setState({ hideTicketActions: "false" });
    } else {
      this.setState({ hideTicketActions: "true" });
    }

    if (
      (b.blockStatus === "BLOCK_CREATED" ||
        b.blockStatus === "RESCHEDULE_FE_AT_PICKUP" ||
        b.blockStatus === "RESCHEDULE_FE_AT_DROP" ||
        b.blockStatus === "CHOOSE_RE_APPOINTMENT_DATE" ||
        b.blockStatus.startsWith("IDLE_UNTIL") ||
        b.blockStatus === "IDLE_FOR_15MIN" ||
        b.blockStatus === "ASSIGN_TO_HO" ||
        b.blockStatus === "PART_RECEIVED" ||
        b.blockStatus === "RTO_INITIATE" ||
        b.blockStatus === "RTO_REJECT" ||
        b.blockStatus === "PICKUP_DONE" ||
        b.blockStatus === "PICKUP_CONFIRM" ||
        b.blockStatus === "PICK_UP_DONE_EXCHANGE_SUCCESSFULLY" ||
        b.blockStatus === "LINEHAUL" ||
        b.blockStatus === "LINEHAUL_IN_TRANSIT") &&
      ticketData !== "ticketHistory" &&
      captureInfo === false
    ) {
      this.handleRequestStatusChange("Appointment");
      this.setState({ hideInitiateAction: "false" });
    } else {
      this.setState({ hideInitiateAction: "true" });
    }

    if (
      ((t.products && t.products[0].prodStatus === null) ||
        (t.retailerCode === "DRP" && b.blockInventory === null) ||
        (t.retailerCode === "EFL" && b.blockInventory === null)) &&
      b.blockStatus === "APPOINTMENT_FIXED" &&
      b.flowStatus === "PICK_WEB" &&
      (t.flowCode !== "INSV1" ||
        (t.flowCode === "INSV1" && t.retailerCode === "DRP") ||
        (t.flowCode === "INSV1" && t.retailerCode === "EFL")) &&
      b.blockStatus === "APPOINTMENT_FIXED" &&
      t.flowCode !== "DRPOF" &&
      b.blockStatus === "APPOINTMENT_FIXED" &&
      t.flowId !== "BulkQC" &&
      b.blockStatus === "APPOINTMENT_FIXED" &&
      t.flowId !== "AdvanceExchange" &&
      b.blockStatus === "APPOINTMENT_FIXED" &&
      t.isPackingReq.toLowerCase() === "yes" &&
      ticketData !== "ticketHistory" &&
      captureInfo === false
    ) {
      this.handleRequestStatusChange("Appointment");
      this.setState({ hidePackingAction: "false" });
    } else {
      this.setState({ hidePackingAction: "true" });
    }

    if (
      ((b.blockStatus === "APPOINTMENT_FIXED" &&
        ((t.products &&
          t.products[0].prodStatus !== null &&
          t.retailerCode !== "DRP") ||
          (t.retailerCode === "DRP" && b.blockInventory !== null) ||
          (t.products &&
            t.products[0].prodStatus !== null &&
            t.retailerCode !== "EFL") ||
          (t.retailerCode === "EFL" && b.blockInventory !== null))) ||
        (b.blockStatus === "APPOINTMENT_FIXED" && t.flowCode === "DRPOF") ||
        b.blockStatus === "HANDED_OVER_PACKING_MATERIALS" ||
        b.blockStatus === "CHOOSE_FE_FOR_DROP" ||
        b.blockStatus === "CHOOSE_FE_FOR_DROP_ASC" ||
        b.blockStatus === "DROP_DONE_ASC" ||
        b.blockStatus === "PICKUP_DONE_ASC" ||
        (b.blockStatus === "APPOINTMENT_FIXED" &&
          b.flowStatus === "DROP_WEB") ||
        (b.blockStatus === "APPOINTMENT_FIXED" &&
          t.flowCode === "INSV1" &&
          t.retailerCode !== "DRP" &&
          t.retailerCode !== "EFL" &&
          b.blockStatus !== "INSTALL_SERVICE_DONE") ||
        (b.blockStatus === "APPOINTMENT_FIXED" &&
          b.blockStatus === "PART_RECEIVED") ||
        (b.blockStatus === "APPOINTMENT_FIXED" &&
          b.blockStatus === "RTO_INITIATE") ||
        (b.blockStatus === "APPOINTMENT_FIXED" &&
          b.blockStatus === "RTO_REJECT") ||
        (t.flowId === "BulkQC" && b.blockStatus === "APPOINTMENT_FIXED") ||
        (b.blockStatus === "APPOINTMENT_FIXED" &&
          t.flowId === "AdvanceExchange") ||
        (b.blockStatus === "APPOINTMENT_FIXED" &&
          t.isPackingReq.toLowerCase() !== "yes")) &&
      ticketData !== "ticketHistory" &&
      captureInfo === false
    ) {
      this.handleRequestStatusChange("Appointment");
      this.setState({ hidePage: "false" });
    } else {
      this.setState({ hidePage: "true" });
    }

    if (
      (b.blockStatus === "PICKUP_DONE" ||
        b.blockStatus === "PICKUP_CONFIRM" ||
        b.blockStatus === "PICK_UP_DONE_EXCHANGE_SUCCESSFULLY") &&
      ticketData !== "ticketHistory" &&
      captureInfo === false
    ) {
      this.handleRequestStatusChange("PickUp");
      this.setState({ hidePickUpAction: "false" });
    } else {
      this.setState({ hidePickUpAction: "true" });
    }

    if (
      b.blockStatus === "DROP_DONE" &&
      t.flowCode !== "DRPOF" &&
      ticketData !== "ticketHistory" &&
      captureInfo === false
    ) {
      this.handleRequestStatusChange("Drop");
      this.setState({ hideDropAction: "false" });
    } else {
      this.setState({ hideDropAction: "true" });
    }

    if (
      ((b.blockStatus === "DROP_DONE" && t.flowCode === "DRPOF") ||
        b.blockStatus === "DROP_CONFIRMED" ||
        b.blockStatus === "OLD_PRODUCT_DAMAGED_EXCHANGE_CANCELLED" ||
        b.blockStatus === "PROBLEM_SOLVED_DURING_INITIAL_CALL" ||
        b.blockStatus === "REJECT_SERVICE_FE_AT_PICKUP" ||
        b.blockStatus === "REJECT_SERVICE_FE_AT_DROP" ||
        b.blockStatus === "INSTALL_SERVICE_DONE" ||
        b.blockStatus === "RTO_REQUEST" ||
        b.blockStatus === "RESCHEDULE_REQUEST_FE_AT_PICKUP" ||
        b.blockStatus === "RESCHEDULE_REQUEST_FE_AT_DROP" ||
        b.blockStatus === "EVALUATION_RESULT_DONE" ||
        b.blockStatus === "LINEHAUL_TRANSIT_LOST" ) &&
      ticketData !== "ticketHistory" &&
      captureInfo === false
    ) {
      this.handleRequestStatusChange("Closure");
      this.setState({ hideCloseAction: "false" });
    } else {
      this.setState({ hideCloseAction: "true" });
    }
    if (
      (b.blockStatus === "ASSIGNED_FE_FOR_DROP" ||
        b.blockStatus === "ASSIGNED_FE_FOR_PICKUP" ||
        b.blockStatus === "AVAILABLE_FOR_PROCESS" ||
        b.blockStatus === "ASSIGNED_FE_FOR_SERVICE_PICKUP" ||
        b.blockStatus === "ASSIGNED_FE_FOR_SERVICE_DROP") &&
      ticketData !== "ticketHistory" &&
      captureInfo === false
    ) {
      this.handleRequestStatusChange("FEInfo");
      this.setState({ hideFEAction: "false" });
    } else {
      this.setState({ hideFEAction: "true" });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* <ZTitle subHeading="Ticket Process" /> */}

        <div className="pageContent co-pageContent">
          <div className="container-fluid">
            <div className="row">
              <div className="col-4">
                <TicketsList
                  onTicketChange={this.setCurrentTicket}
                  loadCount={
                    this.props.ticketListReloadState.ticketProcess
                      .ticketListReload
                  }
                  key={
                    this.props.ticketListReloadState.ticketProcess
                      .ticketListReload
                  }
                />
              </div>
              <div className="col-8">
                {/* <TicketActions key={this.state.curTicket.processId ? this.state.curTicket.processId : 123} curAction={this.state.curAction} onModeChange={this.handleRequestStatusChange} /> */}

                {this.state.curAction === "Block" && (
                  <BlockCreation
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    key={this.state.curBlock.ticketBlockId}
                  />
                )}

                {this.state.curAction === "Appointment" && (
                  <>
                    <AptSchedular
                      ticketDetails={this.state.curTicket}
                      blockDetails={this.state.curBlock}
                      hideAction={this.state.hideInitiateAction}
                      curStatus={this.state.curBlock.blockStatus}
                      key={"apt_" + this.state.curBlock.ticketBlockId}
                    />
                  </>
                )}
                {this.state.curAction === "Appointment" && (
                  <PackingAssignment
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    hideAction={this.state.hidePackingAction}
                    curStatus={this.state.curBlock.blockStatus}
                    key={"pack_" + this.state.curBlock.ticketBlockId}
                  />
                )}

                {this.state.hidePage === "false" &&
                  this.state.curAction === "Appointment" && (
                    <FeAssignment
                      ticketDetails={this.state.curTicket}
                      blockDetails={this.state.curBlock}
                      curAction={this.state.curAction}
                      hidePage={this.state.hidePage}
                      bulkAssign={this.state.bulkAssign}
                      ticketMultiDetails={this.state.curMultiTicket}
                      blockMultiDetails={this.state.curMultiBlock}
                      key={"fe_" + this.state.curBlock.ticketBlockId}
                    />
                  )}

                {this.state.hidePage === "true" &&
                  this.state.curAction === "Appointment" && (
                    <FeAssignment
                      curStatus={this.state.curBlock.blockStatus}
                      hidePage={this.state.hidePage}
                      bulkAssign={this.state.bulkAssign}
                      ticketMultiDetails={this.state.curMultiTicket}
                      blockMultiDetails={this.state.curMultiBlock}
                      key={"fe_" + this.state.curBlock.ticketBlockId}
                    />
                  )}

                {this.state.curAction === "PickUp" && (
                  <PickUp
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    hideAction={this.state.hidePickUpAction}
                    curStatus={this.state.curBlock.blockStatus}
                    key={"pickUp_" + this.state.curBlock.ticketBlockId}
                  />
                )}

                {this.state.curAction === "Files" && (
                  <FilesView
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    key={
                      "img_" +
                      this.state.curBlock.ticketBlockId +
                      this.state.curBlock.processId
                    }
                  />
                )}

                {this.state.curAction === "Drop" && (
                  <Drop
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    hideAction={this.state.hideDropAction}
                    curStatus={this.state.curBlock.blockStatus}
                    key={"drop_" + this.state.curBlock.ticketBlockId}
                  />
                )}

                {this.state.curAction === "Closure" && (
                  <TicketClosure
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    hideAction={this.state.hideCloseAction}
                    curStatus={this.state.curBlock.blockStatus}
                    key={"close_" + this.state.curBlock.ticketBlockId}
                  />
                )}
                {this.state.curAction === "FEInfo" && (
                  <FEInfo
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    hideAction={this.state.hideFEAction}
                    curStatus={this.state.curBlock.blockStatus}
                    key={"FE_" + this.state.curBlock.ticketBlockId}
                  />
                )}
                {this.state.curAction === "TicketHistory" && (
                  <TicketHistory
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    hideAction={this.state.hideTicketHistory}
                    curStatus={this.state.curBlock.blockStatus}
                    key={"FE_" + this.state.curBlock.ticketBlockId}
                  />
                )}
                {this.state.curAction === "TicketActions" && (
                  <TicketActionsInfo
                    ticketDetails={this.state.curTicket}
                    blockDetails={this.state.curBlock}
                    curAction={this.state.curAction}
                    hidePage={this.state.hideTicketActions}
                    bulkAssign={this.state.bulkAssign}
                    ticketMultiDetails={this.state.curMultiTicket}
                    blockMultiDetails={this.state.curMultiBlock}
                    key={"fe_" + this.state.curBlock.ticketBlockId}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ticketListReloadState: state.ticketProcess,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ticketListReload: (cnt) => dispatch(ticketListReload(cnt)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ticketProcessWrapper);
