import React from "react";
import FormLib from "../../_common/FormLib";
import Joi from "joi-browser";
import LocationTitle from "../../_subhead/LocationTitle";
import Table from "../../_common/Table";
import Pagination from "./../../_common/Pagination";
import { dropMenuFull } from "./../../../utils/DropMenuUtils";

import toast from "../../../services/_toastService";
import {
  getPackingMaterials,
  createPackingMaterial,
  savePackingMaterial,
  deletePackingMaterial,
} from "./../../../services/packing/PackingMaterialService";
class packingMaterial extends FormLib {
  state = {
    data: {
      packMaterialId: "",
      packCode: "",
      name: "",
      type: "",
      cost: "",
      sizeMin: "",
      sizeMax: "",
      status: "",
      bmin: "",
      bmax: "",
      lmax: "",
      lmin: "",
      wmax: "",
      wmin: "",
    },
    errors: {},
    packingmaterials: [],
    loading: true,
    pageLinks: {
      pageNo: 1,
      perPage: 0,
      itemsCount: 0,
      lastPage: 0,
    },
  };
  schema = {
    packMaterialId: Joi.optional().label("PackMaterial Id"),
    packCode: Joi.required().label("Pack Code"),
    name: Joi.string().required().label("Name"),
    type: Joi.optional().label("type"),
    cost: Joi.required().label("cost"),
    sizeMin: Joi.required().label("Size Min"),
    sizeMax: Joi.required().label("Size Max"),
    bmin: Joi.required().label("B Min"),
    bmax: Joi.required().label("B Max"),
    lmax: Joi.required().label("L Max"),
    lmin: Joi.required().label("L Min"),
    wmax: Joi.required().label("W Max"),
    wmin: Joi.required().label("W Min"),
    status: Joi.string().required().label("Satus"),
  };
  componentDidMount() {
    this.loadPackingMaterial();
  }

  async loadPackingMaterial() {
    this.setState({ loading: true });
    const res = await getPackingMaterials();
    if (res.data.success === true) {
      // console.log(res.data.data.packingmaterials);
      this.setState({ packingmaterials: res.data.data.packingmaterials });
      // console.log(this.state.packingmaterials);
      //toast.toastSuccess("Packing Material Loaded Successfully");
    } else {
      toast.toastError("failed");
    }
    this.setState({ loading: false });
  }
  handleOnPackingMaterials(r) {
    let packingmaterials = {};
    packingmaterials.packMaterialId = r.packMaterialId;
    packingmaterials.packCode = r.packCode;
    packingmaterials.name = r.name;
    packingmaterials.type = r.type;
    packingmaterials.cost = r.cost;
    packingmaterials.sizeMin = r.sizeMin;
    packingmaterials.sizeMax = r.sizeMax;
    packingmaterials.bmin = r.bmin;
    packingmaterials.bmax = r.bmax;
    packingmaterials.lmax = r.lmax;
    packingmaterials.lmin = r.lmin;
    packingmaterials.wmax = r.wmax;
    packingmaterials.wmin = r.wmin;
    packingmaterials.status = r.status;
    this.setState({ data: packingmaterials });
  }
  resetData() {
    this.setState({
      data: {
        packMaterialId: "",
        packCode: "",
        name: "",
        type: "",
        cost: "",
        sizeMin: "",
        sizeMax: "",
        bmin: "",
        bmax: "",
        lmax: "",
        lmin: "",
        wmax: "",
        wmin: "",
        status: "active",
      },
    });
  }

  tableMeta = {
    key: "packMaterialId",
    columns: [
      {
        key: "PACK_MATERIAL_ID",
        action: (r) => (
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn dropdown-icon"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fa fa-ellipsis-v"></i>
            </button>

            <div className="dropdown-menu dropdown-menu-left">
              <button
                className="dropdown-item"
                type="button"
                onClick={() => this.handleOnPackingMaterials(r)}
              >
                <i className="fa fa-pencil" aria-hidden="true"></i> Edit
              </button>
            </div>
          </div>
        ),

        icon: () => <i className="fa fa-cogs " aria-hidden="true"></i>,
      },
      { path: "packCode", label: "Pack Code" },
      { path: "name", label: "Name" },
      { path: "type", label: "Type" },
      { path: "sizeMin", label: "Size Min" },
      { path: "sizeMax", label: "Size Max" },
      { path: "bmin", label: "B Min" },
      { path: "bmax", label: "B Max" },
      { path: "lmin", label: "L Min" },
      { path: "lmax", label: "L Max" },
      { path: "wmin", label: "W Min" },
      { path: "wmax", label: "w max" },
      { path: "status", label: "status" },

    ],
  };
  handlePageChange = (pageNo) => {
    this.props.history.push("/packing/packingmaterial" + pageNo);
    this.loadPackingMaterial();
  };
  async doSubmit() {
    let res;
    if (this.state.btnValue === "create") {
      res = await createPackingMaterial(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "edit") {
      res = await savePackingMaterial(this.state.data);
      this.resetData();
    }
    if (this.state.btnValue === "delete") {
      res = await deletePackingMaterial(this.state.data);
      this.resetData();
    }
    if (res.data.success === true) {
      toast.toastSuccess(res.data.msg);
      this.loadPackingMaterial();
    } else {
      toast.toastError(res.data.msg);
    }
  }

  status = [
    { sortKey: "active", sort: "Active" },
    { sortKey: "inactive", sort: "Inactive" },
  ];
  stsConf = {
    value: "sortKey",
    label: "sort",
  };

  render() {
    const { pageNo, perPage, itemsCount } = this.state.pageLinks;
    return (
      <React.Fragment>
        <LocationTitle subHeading="Packing Material::List" />
        <div className="pageContent">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              {this.state.loading && (
                <div className="container-fluid">
                  <div>Loading Packing Material...</div>
                </div>
              )}
              {!this.state.loading && (
                <div className="container-fluid">
                  <Table
                    clsName="table table-striped tabele-bordered table-hover"
                    tableMeta={this.tableMeta}
                    data={this.state.packingmaterials}
                    dropMenu={[]}
                  />
                  <Pagination
                    pageNo={pageNo}
                    perPage={perPage}
                    itemsCount={itemsCount}
                    onPageChange={this.handlePageChange}
                  />
                </div>
              )}
            </div>

            <div className="col-md-6 col-sm-12">
              <form>
                <div className="form-row">
                  {this.rTBH("PackMaterialId", "packMaterialId", "col-1")}
                  {this.rTB("PackCode", "packCode", "col-2")}
                  {this.rTB("type", "type", "col-2")}
                  {this.rTB("Name", "name", "col-2")}
                  {this.rTB("Cost", "cost", "col-2")}
                  {this.rTB("SizeMin", "sizeMin", "col-2")}
                  {this.rTB("SizeMax", "sizeMax", "col-2")}
                </div>
                <div className="form-row">
                  {this.rTB("Bmin", "bmin", "col-2")}
                  {this.rTB("Bmax", "bmax", "col-2")}
                  {this.rTB("Wmax", "wmax", "col-2")}
                  {this.rTB("Wmin", "wmin", "col-2")}
                  {this.rTB("Lmin", "lmin", "col-2")}
                  {this.rTB("Lmin", "lmax", "col-2")}
                </div>
                <div className="form-row">
                  {this.rRBL("Status", "status", this.status, this.stsConf)}
                </div>
              </form>
              {this.state.data.packMaterialId === "" &&
                this.rBtnSP("Create", "create")}
              {this.state.data.packMaterialId !== "" && (
                <>
                  {this.rBtnSS("Edit", "edit")}
                  {this.rBtnSD("Delete", "delete")}
                </>
              )}
            </div>
          </div>
          {/* <span>{JSON.stringify(this.state.data)}</span> */}
        </div>
      </React.Fragment>
    );
  }
}

export default packingMaterial;
