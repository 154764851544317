import React from "react";

import FormLib from "../../../_common/FormLib";
import Joi from "joi-browser";
import { requestCall } from "../../../../services/requestV1/requestServices";
import { toast } from "react-toastify";
import dateFormat from "dateformat";

class RequestUTR extends FormLib {
  state = {
    data: {},
    errors: {},
    transactionRequest: {},
  };

  schema = {
    acc_debt_date: Joi.string().required().label("Acc Debt Date"),
    utr_no: Joi.string().required().label("Payment Remarks"),
  };

  componentDidMount() {
    this.setPayment();
  }

  setPayment() {
    this.setState({ transactionRequest: this.props.data });
  }

  async doSubmit() {
    const params = {
      request_id: this.state.transactionRequest.REQUEST_ID,
      approve_id: this.state.transactionRequest.APPROVE_ID,
      approv_amt: this.state.transactionRequest.APPROV_AMT,
      ...this.state.data,
      status: this.state.btnValue,
    };
    // console.log(params, this.state.btnValue);
    let requestRaised = await requestCall(params, this.state.btnValue);
    if (requestRaised.data.success === true) {
      toast.success(() => <div>{requestRaised.data.msg}</div>);
      document.getElementById("myForm").reset();
      this.setState({ data: "" });
    } else {
      toast.error(() => <div>{requestRaised.data.msg}</div>);
    }
    this.props.requestStatusChange();
  }

  render() {
    const user = this.props.user;
    return (
      <React.Fragment>
        <div style={{marginTop:"-4rem", position:"fixed"}}>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={this.props.closeModal}
        >
          <span aria-hidden="true">Mark Paid &times;</span>
        </button>
        <div className="card">
          <div className="card-header bg-warning text-white">
            <div className="row">
              <div className="col-6">
                {this.state.transactionRequest.M_CLIENT}
              </div>
              <div className="col-6 text-right">
                {dateFormat(
                  this.state.transactionRequest.CREATED_AT,
                  "dd-mm-yyyy"
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                {this.state.transactionRequest.REF_ID} [
                {this.state.transactionRequest.M_HUB}]
              </div>
              <div className="col-4 text-right">
                #{this.state.transactionRequest.RE_PREFIX}
                {this.state.transactionRequest.SEQ}
              </div>
            </div>
          </div>

          <div className="card-body border-top">
            <div className="row">
              <div className="col-4">
                <span className="req-label">Request Type </span>
                <span className="req-value">
                  {this.state.transactionRequest.REQUEST_TYPE}
                </span>
              </div>
              {this.state.transactionRequest.PLAYER_TYPE === "employee" && (
                <div className="col-4">
                  <span className="req-label">Employee</span>
                  <span className="req-value">
                    {this.state.transactionRequest.E_NAME} [
                    {this.state.transactionRequest.PLAYER_CODE}]
                  </span>
                </div>
              )}
              {this.state.transactionRequest.PLAYER_TYPE === "vendor" && (
                <div className="col-4">
                  <span className="req-label">Vendor</span>
                  <span className="req-value">
                    {this.state.transactionRequest.V_NAME} [
                    {this.state.transactionRequest.PLAYER_CODE}]
                  </span>
                </div>
              )}
              <div className="col-4">
                <span className="req-label">Flow </span>
                <span className="req-value">
                  {this.state.transactionRequest.M_FLOW}
                </span>
              </div>
            </div>
          </div>

          <div class="panel-group widget-request">
            <div className="panel panel-info">
              <label>Activity Details</label>
              <div className="panel-body">
                {this.state.transactionRequest.M_ACTIVITY}
              </div>
            </div>
            <div class="panel panel-info">
              <label>Request Details</label>
              <div class="panel-body">
                {this.state.transactionRequest.REQUEST_DETAILS}
              </div>
            </div>

            <div class="panel panel-info">
              <label className="label-approve">Approve Remarks</label>
              <div class="panel-body">
                {this.state.transactionRequest.APPROV_REMARK}
              </div>
            </div>
          </div>

          <div className="card-body row">
            <div className="col-6">
              <span className="req-label">Total Amt Approved </span>
              <span className="req-value">
                {this.state.transactionRequest.AMT_APPROVED}
              </span>
            </div>
            <div className="col-6 text-right">
              <span className="req-label">Requested Amount </span>
              <span className="req-value">
                {this.state.transactionRequest.REQ_AMT}
              </span>
            </div>
            <div className="col-6 mt-3">
              <span className="req-label">Approved Amount </span>
              <span className="req-value">
                {this.state.transactionRequest.APPROV_AMT}
              </span>
            </div>
          </div>
          {user &&
            (user["roleCode"] === "finance_head" ||
              (user["designation"] && user["designation"] === "Finance Head") ||
              user["designation"] === "Finance Executive" ||
              user["designation"] === "Finance Manager" ||
              user["playerId"] === "player1") && (
              <div className="card-body border-top">
                <form id="myForm" onSubmit={this.handleSubmit}>
                  {/* <div className="form-row row">
                      {this.rRBL(
                        "Payment Type",
                        "payment_type",
                        this.payment_type,
                        {
                          value: "payment_typeId",
                          label: "payment_type",
                        }
                      )}
                    </div> */}

                  {this.rTB("UTR No.", "utr_no", "col-md-6")}
                  {this.rDP("Acc Debt. Date", "acc_debt_date", "col-md-6")}
                  {/* {this.rTA("Payment Remarks", "approv_remark")} */}
                </form>
              </div>
            )}
          {user &&
            (user["roleCode"] === "finance_head" ||
              (user["designation"] && user["designation"] === "Finance Head") ||
              user["designation"] === "Finance Executive" ||
              user["designation"] === "Finance Manager" ||
              user["playerId"] === "player1") && (
              <div className="p-1">
                {this.rBtnS(
                  "Update UTR",
                  "paid",
                  "btn btn-success btn-sm btn-block"
                )}
              </div>
            )}
        </div>
        </div>
      </React.Fragment>
    );
  }
}
export default RequestUTR;
